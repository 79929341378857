import React from "react";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import Dropdown from "../../components/Dropdowns/DropdownForMixedAttributes";
import InvestedgeTextField from "../../components/textfield/InvestedgeTextField";

export default function MeetingSummary({
  meetings,
  meeting,
  setMeeting,
  meetingSummaryQuery,
  setMeetingSummaryQuery,
  handleMeetingSummary,
}) {
  return (
    <div>
      <h1 className="card-heading text-base mt-8">Get Meeting Summary</h1>
      <div className="w-[41%] mt-4">
        <Dropdown
          options={meetings}
          selected={meeting}
          setSelected={setMeeting}
          attribute1="topic_of_the_meeting"
          attribute2="date"
          labelName="Select Meeting"
        />
      </div>
      <div className="w-[41%] mt-6">
        <InvestedgeTextField
          value={meetingSummaryQuery}
          onChange={(e) => setMeetingSummaryQuery(e.target.value)}
          label="Enter Query Here"
          fullWidth
        />
      </div>
      <div className="w-[41%] mt-6 flex justify-end">
        <InvestedgeButton onClick={handleMeetingSummary}>
          Submit
        </InvestedgeButton>
      </div>
    </div>
  );
}
