import React, { useState, useEffect, useRef } from "react";
import EditField from "../EditField";
import "../../static/css/existing/dashboard_styles.css";
import Camera from "../../assets/img/camera.svg";
import Profile from "../../assets/img/defaultprofilepic.png";
import EditFieldAbout from "../about/EditFieldAbout";
import InvestedgeButtonOutlined from "../../components/buttons/InvestedgeButtonOutlined";
// import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  // GetOtp,
  GetSettingsGeneral,
  PostImageGeneral,
  SettingsUserDetails,
  PostSettingsGeneral,
  PostEventLoggingData,
} from "../../actions/actions";
import ChangePasswordModal from "./ChangePasswordModal";
import InvestedgeTextField from "../../components/textfield/InvestedgeTextField";
import InvestedgeDatepicker from "../../components/datepicker/InvestedgeDatepicker";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import dayjs from "dayjs";
export default function GeneralContent() {
  /* For password */

  const generalBackendData = useSelector((state) => state.settingsGeneral);
  const profilePicData = useSelector((state) => state.postSettingsGeneral);
  // const history = useHistory();
  const dispatch = useDispatch();
  const imageInputRef = useRef();
  useEffect(() => {
    dispatch(GetSettingsGeneral());
    dispatch(SettingsUserDetails());
  }, [profilePicData, dispatch]);
  useEffect(() => {
    setGeneralBody({
      name: generalBackendData.username,
      email: generalBackendData.email,
      contact: generalBackendData.contact,
      dob: generalBackendData.dob,
    });
  }, [generalBackendData]);

  const [generalBody, setGeneralBody] = useState({
    name: generalBackendData.username,
    email: generalBackendData.email,
    contact: generalBackendData.contact,
    dob: generalBackendData.dob,
  });

  /* For profile picture */
  const [editpicture, setEditpicture] = useState(true);
  const [profilePicture, setProfilePicture] = useState(null);
  const handleeditpicture = () => {
    dispatch(
      PostEventLoggingData({
        module_name: "Settings",
        event_type: "Edit button",
        url: window.location.href,
        note: `Editing profile picture`,
      })
    );
    setEditpicture(false);
  };
  const handlesavepicture = () => {
    profilePicture &&
      dispatch(
        PostImageGeneral({
          file: profilePicture,
          contact: generalBackendData.contact,
          dob: generalBackendData.dob,
        })
      );
    dispatch(
      PostEventLoggingData({
        module_name: "Settings",
        event_type: "Save button",
        url: window.location.href,
        note: `Saving profile picture`,
      })
    );

    setEditpicture(true);
  };
  const handlecancelpicture = () => {
    setEditpicture(true);
  };
  const [edit, setEdit] = useState(true);
  const [disable, setdisable] = useState(true);
  const [modal, setModal] = useState(false);
  const handleeditbutton = () => {
    dispatch(
      PostEventLoggingData({
        module_name: "Settings",
        event_type: "Edit button",
        url: window.location.href,
        note: `Editing Data`,
      })
    );
    setEdit(false);
    setdisable(!disable);
  };
  const handlesavebutton = () => {
    dispatch(
      PostEventLoggingData({
        module_name: "Settings",
        event_type: "Save button",
        url: window.location.href,
        note: `Saving Data`,
      })
    );
    dispatch(PostSettingsGeneral(generalBody));
    setEdit(true);
    setdisable(!disable);
  };
  const handlecancelbutton = () => {
    setEdit(true);
    setdisable(!disable);
  };

  const return_date = (val) => {
    try {
      let temp = dayjs(new Date(val).toISOString());
      return temp;
    } catch (err) {
      return val;
    }
  };
  return (
    <>
      <div className="grid grid-cols-12 ">
        <div className="col-span-4">
          {editpicture ? (
            <img
              src={
                generalBackendData.profile_pic
                  ? generalBackendData.profile_pic
                  : Profile
              }
              alt="profilepic"
              className="rounded-[100%] w-[80px] h-[80px]"
            />
          ) : (
            <div className="rounded-[100%] w-[80px] h-[80px] relative bg-neutralBlack">
              <img
                src={
                  generalBackendData.profile_pic
                    ? generalBackendData.profile_pic
                    : Profile
                }
                alt="profilepic"
                className="rounded-[100%] w-[80px] h-[80px]"
              />
              <div
                style={{
                  background: "rgba(0, 0, 0, 0.5)",
                }}
                className="absolute bottom-0 w-full h-full rounded-[100%] flex justify-center items-center"
              >
                <img
                  className=" w-[25px] h-[20px]"
                  src={Camera}
                  onClick={() => imageInputRef.current.click()}
                  alt="err"
                />
                <input
                  ref={imageInputRef}
                  className="hidden"
                  onInput={() =>
                    setProfilePicture(imageInputRef.current.files[0])
                  }
                  type="file"
                  accept="image/png,image/jpg,image/jpeg"
                />
              </div>
            </div>
          )}
        </div>
        <div className="col-span-2 flex justify-between">
          {editpicture ? (
            <InvestedgeButtonOutlined
              className="mx-auto h-1/2 my-auto"
              onClick={handleeditpicture}
            >
              Edit
            </InvestedgeButtonOutlined>
          ) : (
            <InvestedgeButtonOutlined
              className="mx-auto h-1/2 my-auto"
              color="success"
              onClick={handlesavepicture}
            >
              Save
            </InvestedgeButtonOutlined>
          )}
        </div>
        <div className="col-span-2 flex justify-between">
          {editpicture ? null : (
            <InvestedgeButtonOutlined
              className="h-1/2 my-auto"
              color="error"
              onClick={handlecancelpicture}
            >
              Cancel
            </InvestedgeButtonOutlined>
          )}
        </div>
      </div>

      <EditFieldAbout
        label="Name"
        value={generalBackendData.username}
        type="text"
        dispatchingSave={() => {
          dispatch(
            PostEventLoggingData({
              module_name: "Settings",
              event_type: "Save button",
              url: window.location.href,
              note: `Saving Data`,
            })
          );
          dispatch(PostSettingsGeneral(generalBody));
        }}
        fieldWidth="300px"
      />

      <EditFieldAbout
        label="Email"
        value={generalBackendData.email}
        type="text"
        dispatchingSave={() => {
          dispatch(
            PostEventLoggingData({
              module_name: "Settings",
              event_type: "Save button",
              url: window.location.href,
              note: `Saving Data`,
            })
          );
          dispatch(PostSettingsGeneral(generalBody));
        }}
        fieldWidth="300px"
      />
      <EditField
        label="Phone number"
        value="contact"
        formBody={generalBody}
        setformBody={setGeneralBody}
        type="phone number"
        dispatchingSave={() => {
          dispatch(
            PostEventLoggingData({
              module_name: "Settings",
              event_type: "Save button",
              url: window.location.href,
              note: `Saving Data`,
            })
          );
          generalBody.contact.length === 10 &&
            dispatch(PostSettingsGeneral(generalBody));
        }}
        fieldWidth="300px"
      />
      <div className="grid grid-cols-12 mt-[1.5rem]">
        <div className="col-span-4">
          <InvestedgeDatepicker
            format="DD/MM/YYYY"
            disabled={disable}
            label="Date of birth"
            value={return_date(generalBody.dob)}
            className="font-inter font-normal w-full mr-[0.5rem]"
            onChange={(date) => {
              var dd = String(new Date(date).getDate()).padStart(2, "0");
              var mm = String(new Date(date).getMonth() + 1).padStart(2, "0");
              var yyyy = new Date(date).getFullYear();
              let newformBody = { ...generalBody };
              newformBody.dob = yyyy + "-" + mm + "-" + dd;
              setGeneralBody(newformBody);
            }}
            renderInput={(params) => <InvestedgeTextField {...params} />}
          />
        </div>
        <div className="col-span-2 flex justify-between">
          {edit ? (
            <InvestedgeButtonOutlined
              className="mx-auto"
              onClick={handleeditbutton}
            >
              Edit
            </InvestedgeButtonOutlined>
          ) : (
            <InvestedgeButtonOutlined
              className="mx-auto"
              color="success"
              onClick={handlesavebutton}
            >
              Save
            </InvestedgeButtonOutlined>
          )}
        </div>
        <div className="col-span-2 flex justify-between">
          {edit ? null : (
            <InvestedgeButtonOutlined
              color="error"
              onClick={handlecancelbutton}
            >
              Cancel
            </InvestedgeButtonOutlined>
          )}
        </div>
      </div>
      <ChangePasswordModal modal={modal} setModal={setModal} />
      <InvestedgeButton className="mt-6" onClick={() => setModal(true)}>
        Change Password
      </InvestedgeButton>
    </>
  );
}
