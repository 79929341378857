import React from "react";
import PieChart from "../../../dashboard/PieChart";
import ProgressBar from "./ProgressBar";
export default function DebtSection({ analytics }) {
  const keys = [];
  const values = [];
  // const colorPalette = [
  //   "#56A1E5",
  //   "#F7CE6B",
  //   "#ED6D85",
  //   "#F1A354",
  //   "#6CBDBF",
  //   "#F66D44",
  //   "#FEAE65",
  //   "#A6A69D",
  //   "#AADEA7",
  //   "#64C2A6",
  //   "#2D87BB",
  // ];

  const bgGradients = [
    "linear-gradient(223.78deg, #09C3E7 0%, #7669F0 106.87%)",
    "linear-gradient(225deg, #9C2979 0%, #F15E75 100%)",
    "linear-gradient(108.81deg, #FF9F38 -27.65%, #FE5C44 125%)",
    "linear-gradient(108.81deg, #FFD166 -27.65%, #C26F0D 125%)",
  ];
  for (const property in analytics.top5_debt_instruments) {
    keys.push(property);
    values.push((analytics.top5_debt_instruments[property] * 100).toFixed(1));
  }
  const currentratingKeys = [];
  const currentratingValues = [];
  const proposedratingKeys = [];
  const proposedratingValues = [];
  for (let i = 0; i < analytics.rating_allocation_before[0].length; i++) {
    currentratingKeys.push(analytics.rating_allocation_before[0][i]);
    currentratingValues.push(
      (analytics.rating_allocation_before[1][i] * 100).toFixed(1)
    );
  }
  for (let i = 0; i < analytics.rating_allocation_after[0].length; i++) {
    proposedratingKeys.push(analytics.rating_allocation_after[0][i]);
    proposedratingValues.push(
      (analytics.rating_allocation_after[1][i] * 100).toFixed(1)
    );
  }
  const currentdata = {
    labels: currentratingKeys,

    datasets: [
      {
        data: currentratingValues,
        backgroundColor: [
          "#F8CE6C",
          "#ED6D84",
          "#A155B9",
          "#56A1E5",
          "#6CBDC0",
        ],
        borderColor: ["#F8CE6C", "#ED6D84", "#A155B9", "#56A1E5", "#6CBDC0"],
        borderWidth: 1,
      },
    ],
  };
  const proposeddata = {
    labels: proposedratingKeys,

    datasets: [
      {
        data: proposedratingValues,
        backgroundColor: [
          "#F8CE6C",
          "#ED6D84",
          "#A155B9",
          "#56A1E5",
          "#6CBDC0",
        ],
        borderColor: ["#F8CE6C", "#ED6D84", "#A155B9", "#56A1E5", "#6CBDC0"],
        borderWidth: 1,
      },
    ],
  };
  const pieoptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      labels: [
        {
          render: "percentage",
          position: "outside",
          fontFamily: "Inter,sans-serif",
          fontColor: "#323232",
          textMargin: 8,
          fontSize: 14,
        },
      ],
      datalabels: {
        display: false,
      },
    },
  };

  const currentSecuritiesData = [];
  const proposedSecuritiesData = [];
  for (const property in analytics.top5_sector_before)
    currentSecuritiesData.push({
      label: property,
      value: (analytics.top5_sector_before[property] * 100).toFixed(1),
    });
  for (const property in analytics.top5_sector_after)
    proposedSecuritiesData.push({
      label: property,
      value: (analytics.top5_sector_after[property] * 100).toFixed(1),
    });
  return (
    <>
      <div className="w-full flex justify-between">
        <div className="w-[49%]">
          <h2 className="font-medium text-lg mt-[16px]">Current Debt Quants</h2>
          <div className="bg-[#FAFAFA] mt-[16px] p-[16px] rounded-md flex">
            <div className="w-[50%] text-center border-r border-r-dividerColor border-r border-[#e0dddd] py-[16px] px-0">
              <h3 className="font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0">
                {`${(analytics.debt_quants_before.gross_ytm * 100).toFixed(
                  1
                )} %`}
              </h3>
              <label className="font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                Gross YTM
              </label>
            </div>
            <div className="w-[50%] text-center py-[16px] px-[0px]">
              <h3 className="font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#1982f8] mb-0">
                {`${analytics.debt_quants_before.duration.toFixed(1)} Yrs`}
              </h3>
              <label className="font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                Duration
              </label>
            </div>
          </div>
        </div>
        <div className="w-[49%]">
          <h2 className="font-medium text-lg mt-[16px]">
            Proposed Debt Quants
          </h2>
          <div className="bg-[#FAFAFA] mt-[16px] p-[16px] rounded-md flex">
            <div className="w-[50%] text-center border-r border-r-dividerColor border-r border-[#e0dddd] py-[16px] px-0">
              <h3 className="font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0">
                {`${(analytics.debt_quants_after.gross_ytm * 100).toFixed(
                  1
                )} %`}
              </h3>
              <label className="font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                Gross YTM
              </label>
            </div>
            <div className="w-[50%] text-center py-[16px] px-[0px]">
              <h3 className="font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#1982f8] mb-0">
                {`${analytics.debt_quants_after.duration.toFixed(1)} Yrs`}
              </h3>
              <label className="font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                Duration
              </label>
            </div>
          </div>
        </div>
      </div>
      <h2 className="font-medium text-lg mt-[16px]">Credit Rating</h2>
      <div className="w-full flex justify-between mt-[16px]">
        <div className="w-[49%] bg-[#fafafa] min-h-[250px] p-[1rem]">
          <h4 className=" font-inter not-italic leading-[16px]  font-medium text-[14px] tracking-[0em] text-left text-neutralBlack">
            Portfolio 1
          </h4>
          <PieChart
            data={currentdata}
            options={pieoptions}
            width={200}
            height={200}
          />
        </div>
        <div className="w-[49%] bg-[#fafafa] min-h-[250px] p-[1rem]">
          <h4 className=" font-inter not-italic leading-[16px] font-medium text-[14px] tracking-[0em] text-left text-neutralBlack">
            Portfolio 2
          </h4>
          <PieChart
            data={proposeddata}
            options={pieoptions}
            width={200}
            height={200}
          />
        </div>
      </div>
      <h2 className="font-medium text-lg mt-[16px]">Top 5 Securities</h2>
      <div className="w-full flex justify-between mt-[16px]">
        <div className="w-[49%] bg-[#fafafa] min-h-[250px] p-[1rem]">
          <h4 className="leading-[16px] font-inter not-italic font-medium text-[14px] tracking-[0em] text-left text-neutralBlack">
            Portfolio 1
          </h4>
          {currentSecuritiesData.map((product, index) => (
            <ProgressBar
              key={index}
              bg={bgGradients[index % bgGradients.length]}
              progress={product.value}
              label={product.label}
            />
          ))}
        </div>
        <div className="w-[49%] bg-[#fafafa] min-h-[250px] p-[1rem]">
          <h4 className="leading-[16px] font-inter not-italic font-medium text-[14px] tracking-[0em] text-left text-neutralBlack">
            Portfolio 2
          </h4>
          {proposedSecuritiesData.map((product, index) => (
            <ProgressBar
              key={index}
              bg={bgGradients[index % bgGradients.length]}
              progress={product.value}
              label={product.label}
            />
          ))}
        </div>
      </div>
    </>
  );
}
