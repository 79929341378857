const urls = {
  blogsURL: process.env.REACT_APP_blogsURL,
  contactURL: process.env.REACT_APP_contactURL,
  subscriptionURL: process.env.REACT_APP_subscriptionURL,
  userURL: process.env.REACT_APP_userURL,
  marketFaqURL: process.env.REACT_APP_marketFaqURL,
  marketSectorsURL: process.env.REACT_APP_marketSectorsURL,
  savedFaqURL: process.env.REACT_APP_savedFaqURL,
  trendingFaqURL: process.env.REACT_APP_trendingFaqURL,
  loginUserURL: process.env.REACT_APP_loginUserURL,
  refreshTokenURL: process.env.REACT_APP_refreshTokenURL,
  thirdPartyLoginURL: process.env.REACT_APP_thirdPartyLoginURL,
  userDetailsURL: process.env.REACT_APP_userDetailsURL,
  forgetPasswordURL: process.env.REACT_APP_forgetPasswordURL,
  forgetPasswordConfirmURL: process.env.REACT_APP_forgetPasswordConfirmURL,
  marketEventsURL: process.env.REACT_APP_marketEventsURL,
  marketsExpertsURL: process.env.REACT_APP_marketExpertsURL,
  marketExpertsConsolidatedURL:
    process.env.REACT_APP_marketExpertsConsolidatedURL,
  marketConversationURL: process.env.REACT_APP_marketConversationURL,
  researchURL: process.env.REACT_APP_researchURL,
  researchFundhouseURL: process.env.REACT_APP_researchFundhouseURL,
  researchAssetAllocationURL: process.env.REACT_APP_researchAssetAllocationURL,
  productInputURL: process.env.REACT_APP_productInputURL,
  postProductInputURL: process.env.REACT_APP_postProductInputURL,
  settingsUserURL: process.env.REACT_APP_settingsUserURL,
  settingsChangePasswordURL: process.env.REACT_APP_settingsChangePasswordURL,
  organizationURL: process.env.REACT_APP_organizationURL,
  organizationMemberURL: process.env.REACT_APP_organizationMemberURL,
  rminfoURL: process.env.REACT_APP_rminfoURL,
  teamleadURL: process.env.REACT_APP_teamleadURL,
  operationsdataURL: process.env.REACT_APP_operationsdataURL,
  ipsURL: process.env.REACT_APP_ipsURL,
  getOperationsLeadURL: process.env.REACT_APP_getOperationsLeadURL,
  getRmsURL: process.env.REACT_APP_getRmsURL,

  // Calculators
  marriagePlannerURL: process.env.REACT_APP_marriagePlannerURL,
  childEducationPlannerURL: process.env.REACT_APP_childEducationPlannerURL,
  retirementPlannerURL: process.env.REACT_APP_retirementPlannerURL,
  automatedSipPlannerURL: process.env.REACT_APP_automatedSipPlannerURL,
  automatedSwitchPlannerURL: process.env.REACT_APP_automatedSwitchPlannerURL,
  automatedCashflowPlannerURL:
    process.env.REACT_APP_automatedCashflowPlannerURL,
  wealthCreationURL: process.env.REACT_APP_wealthCreationURL,
  buildYourOwnGoalURL: process.env.REACT_APP_buildYourOwnGoalURL,

  // AUM Urls
  annualTarget: process.env.REACT_APP_annualTarget,
  aumBreakup: process.env.REACT_APP_aumBreakup,
  aumBreakupProduct: process.env.REACT_APP_aumBreakupProduct,
  aumBreakupAsset: process.env.REACT_APP_aumBreakupAsset,
  aumBreakupClient: process.env.REACT_APP_aumBreakupClient,
  dashboardAllClientsURL: process.env.REACT_APP_dashboardAllClientsURL,
  dashboardYourClientsURL: process.env.REACT_APP_dashboardYourClientsURL,
  birthdayThisMonthURL: process.env.REACT_APP_birthdayThisMonthURL,

  // PopularProducts Urls
  popularProducts: process.env.REACT_APP_popularProducts,

  // Portfolio Proposal Urls
  allocationPreference: process.env.REACT_APP_allocationPreference,
  analyticsAPI: process.env.REACT_APP_analyticsAPI,
  pptAPI: process.env.REACT_APP_pptAPI,
  proposalAdvanceAnalyticsAPI:
    process.env.REACT_APP_proposalAdvanceAnalyticsAPI,
  proposalHistoryURL: process.env.REACT_APP_proposalHistoryURL,

  // Client Input URLs
  getClientsURL: process.env.REACT_APP_getClientsURL,
  getClientInputRmsURL: process.env.REACT_APP_getClientInputRmsURL,
  clientInputPostNewURL: process.env.REACT_APP_clientInputPostNewURL,
  clientInputUploadProfilePictureURL:
    process.env.REACT_APP_clientInputUploadProfilePictureURL,
  clientInputUploadFileURL: process.env.REACT_APP_clientInputUploadFileURL,
  clientInputDownloadTemplateURL:
    process.env.REACT_APP_clientInputDownloadTemplateURL,
  prospectToClientURL: process.env.REACT_APP_prospectToClientURL,

  //  product InsightData
  comparisonData: process.env.REACT_APP_comparisonData,

  // productreckonerData
  productreckonerData: process.env.REACT_APP_productreckonerData,

  // productonepager
  productonepager: process.env.REACT_APP_productonepager,

  // logging
  eventLoggingURL: process.env.REACT_APP_eventLoggingURL,

  // Client Dashboard URLs
  dashboardPortfolioURL: process.env.REACT_APP_dashboardPortfolioURL,
  dashboardPortfolioSummaryURL:
    process.env.REACT_APP_dashboardPortfolioSummaryURL,
  dashboardHoldingReportURL: process.env.REACT_APP_dashboardHoldingReportURL,
  dashboardInsightsURL: process.env.REACT_APP_dashboardInsightsURL,
  dashboardAnalyticsURL: process.env.REACT_APP_dashboardAnalyticsURL,
  dashboardPerformanceURL: process.env.REACT_APP_dashboardPerformanceURL,
  dashboardDetailsURL: process.env.REACT_APP_dashboardDetailsURL,
  dashboardInvestmentURL: process.env.REACT_APP_dashboardInvestmentURL,
  dashboardRedeemCapitalURL: process.env.REACT_APP_dashboardRedeemCapitalURL,
  dashboardDeployCapitalURL: process.env.REACT_APP_dashboardDeployCapitalURL,
  dashboardSalesPitchURL: process.env.REACT_APP_dashboardSalesPitchURL,
  dashboardSuitableProductsURL:
    process.env.REACT_APP_dashboardSuitableProductsURL,
  dashboardMFCentralOTPURL: process.env.REACT_APP_dashboardMFCentralOTPURL,
  dashboardMFCentralPayloadURL:
    process.env.REACT_APP_dashboardMFCentralPayloadURL,
  dashboardMFPortfolioURL: process.env.REACT_APP_dashboardMFPortfolioURL,
  dashboardMFTransactionURL: process.env.REACT_APP_dashboardMFTransactionURL,
  dashboardMFPortfolioDeleteURL:
    process.env.REACT_APP_dashboardMFPortfolioDeleteURL,
  dashboardMFNavURL: process.env.REACT_APP_dashboardMFNavURL,
  dashboardStocksPortfolioURL:
    process.env.REACT_APP_dashboardStocksPortfolioURL,
  dashboardStocksPortfolioDeleteURL:
    process.env.REACT_APP_dashboardStocksPortfolioDeleteURL,
  dashboardBondsPortfolioURL: process.env.REACT_APP_dashboardBondsPortfolioURL,
  dashboardBondsPortfolioDeleteURL:
    process.env.REACT_APP_dashboardBondsPortfolioDeleteURL,
  dashboardPmsPortfolioURL: process.env.REACT_APP_dashboardPmsPortfolioURL,
  dashboardPmsPortfolioDeleteURL:
    process.env.REACT_APP_dashboardPmsPortfolioDeleteURL,
  dashboardAifPortfolioURL: process.env.REACT_APP_dashboardAifPortfolioURL,
  dashboardAifPortfolioDeleteURL:
    process.env.REACT_APP_dashboardAifPortfolioDeleteURL,
  dashboardUnlistedPortfolioURL:
    process.env.REACT_APP_dashboardUnlistedPortfolioURL,
  dashboardUnlistedPortfolioDeleteURL:
    process.env.REACT_APP_dashboardUnlistedPortfolioDeleteURL,
  dashboardCashPortfolioURL: process.env.REACT_APP_dashboardCashPortfolioURL,
  dashboardCashPortfolioDeleteURL:
    process.env.REACT_APP_dashboardCashPortfolioDeleteURL,
  dashboardDepositsPortfolioURL:
    process.env.REACT_APP_dashboardDepositsPortfolioURL,
  dashboardDepositsPortfolioDeleteURL:
    process.env.REACT_APP_dashboardDepositsPortfolioDeleteURL,
  dashboardGoldPortfolioURL: process.env.REACT_APP_dashboardGoldPortfolioURL,
  dashboardGoldPortfolioDeleteURL:
    process.env.REACT_APP_dashboardGoldPortfolioDeleteURL,
  dashboardRealEstatePortfolioURL:
    process.env.REACT_APP_dashboardRealEstatePortfolioURL,
  dashboardRealEstatePortfolioDeleteURL:
    process.env.REACT_APP_dashboardRealEstatePortfolioDeleteURL,
  dashboardOffshorePortfolioURL:
    process.env.REACT_APP_dashboardOffshorePortfolioURL,
  dashboardOffshorePortfolioDeleteURL:
    process.env.REACT_APP_dashboardOffshorePortfolioDeleteURL,
  dashboardBenchmarkPriceURL: process.env.REACT_APP_dashboardBenchmarkPriceURL,
  dashboardPortfolioListURL: process.env.REACT_APP_dashboardPortfolioListURL,
  dashboardMeetingSummaryURL: process.env.REACT_APP_dashboardMeetingSummaryURL,
  dashboardPostMeetingsURL: process.env.REACT_APP_dashboardPostMeetingsURL,
  dashboardAskMeAnythingURL: process.env.REACT_APP_dashboardAskMeAnythingURL,
  dashboardPortfolioAnalysisURL:
    process.env.REACT_APP_dashboardPortfolioAnalysisURL,
  // Review Portfolio URLs
  reviewPortfolioGetSummaryURL:
    process.env.REACT_APP_reviewPortfolioGetSummaryURL,
  reviewPortfolioPatchSummaryURL:
    process.env.REACT_APP_reviewPortfolioPatchSummaryURL,
  reviewPortfolioGetAnalyticsURL:
    process.env.REACT_APP_reviewPortfolioGetAnalyticsURL,
  reviewPortfolioGetIpsDataURL:
    process.env.REACT_APP_reviewPortfolioGetIpsDataURL,
  reviewPortfolioGetIAAURL: process.env.REACT_APP_reviewPortfolioGetIAAURL,
  reviewPortfolioGetIpstrackerURL:
    process.env.REACT_APP_reviewPortfolioGetIpstrackerURL,
  reviewPortfolioActionablesURL:
    process.env.REACT_APP_reviewPortfolioActionablesURL,
  reviewPortfolioGetAnalyticsFinalURL:
    process.env.REACT_APP_reviewPortfolioGetAnalyticsFinalURL,
  reviewPortfolioGetPPTURL: process.env.REACT_APP_reviewPortfolioGetPptURL,
  mutualFundAnalyticsGetPptURL:
    process.env.REACT_APP_mutualFundAnalyticsGetPptURL,
  reviewHistoryURL: process.env.REACT_APP_reviewHistoryURL,
  reviewCounterURL: process.env.REACT_APP_reviewCounterURL,
  productPerformanceURL: process.env.REACT_APP_productPerformanceURL,
  reviewAdvanceAnalyticsAPI: process.env.REACT_APP_reviewAdvanceAnalyticsAPI,

  // Model Portfolio URLs
  modelPortfolioURL: process.env.REACT_APP_modelPortfolioURL,
  modelPortfolioAnalyticsURL: process.env.REACT_APP_modelPortfolioAnalyticsURL,
  modelPortfolioBookmarkURL: process.env.REACT_APP_modelPortfolioBookmarkURL,
  modelPortfolioDescriptionURL:
    process.env.REACT_APP_modelPortfolioDescriptionURL,
  modelPortfolioDescriptionDeleteURL:
    process.env.REACT_APP_modelPortfolioDescriptionDeleteURL,
  modelPortfolioDeleteURL: process.env.REACT_APP_modelPortfolioDeleteURL,
  modelPortfolioDescriptionDuplicateURL:
    process.env.REACT_APP_modelPortfolioDescriptionDuplicateURL,

  //SIP portfolio URLs
  sipallocationPreferenceURL: process.env.REACT_APP_sipallocationPreferenceURL,
  sipanalyticsURL: process.env.REACT_APP_sipanalyticsURL,
  sippptURL: process.env.REACT_APP_sippptURL,
  sipHistoryURL: process.env.REACT_APP_sipHistoryURL,
  sipAdvanceAnalyticsAPI: process.env.REACT_APP_sipAdvanceAnalyticsAPI,
  sipProjectionsURL: process.env.REACT_APP_sipProjectionsURL,
  //Goal Mapping
  goalsListURL: process.env.REACT_APP_goalsListURL,
  goalsDeleteURL: process.env.REACT_APP_goalsDeleteURL,
  goalsInflationAdjustedURL: process.env.REACT_APP_goalsInflationAdjustedURL,
  goalsScenarioAnalysisURL: process.env.REACT_APP_goalsScenarioAnalysisURL,
  goalsReturnPercentURL: process.env.REACT_APP_goalsReturnPercentURL,
  goalsAssetAllocationURL: process.env.REACT_APP_goalsAssetAllocationURL,
  //InvestedgeGPT
  investedgegptMarketsURL: process.env.REACT_APP_investedgegptMarketsURL,
  investedgegptProductGenericURL:
    process.env.REACT_APP_investedgegptProductGenericURL,
  investedgegptProductSpecificURL:
    process.env.REACT_APP_investedgegptProductSpecificURL,
  //AcquireClients
  acquireClientsGenerateLinkURL:
    process.env.REACT_APP_acquireClientsGenerateLinkURL,
  acquireClientsLinkStatusURL:
    process.env.REACT_APP_acquireClientsLinkStatusURL,
  acquireClientsMFCentralRequestURL:
    process.env.REACT_APP_acquireClientsMFCentralRequestURL,
  acquireClientsMFCentralPayloadURL:
    process.env.REACT_APP_acquireClientsMFCentralPayloadURL,
  acquireClientsCamCasURL: process.env.REACT_APP_acquireClientsCamCasURL,
  acquireClientsAnalyticsURL: process.env.REACT_APP_acquireClientsAnalyticsURL,
  acquireClientsPPTURL: process.env.REACT_APP_acquireClientsPPTURL,
  acquireClientsCamsPPTURL: process.env.REACT_APP_acquireClientsCamsPPTURL,
  acquireClientsDashboardURL: process.env.REACT_APP_acquireClientsDashboardURL,
  acquireClientsManageLinksURL:
    process.env.REACT_APP_acquireClientsManageLinksURL,
  acquireClientsDeactivateLinkURL:
    process.env.REACT_APP_acquireClientsDeactivateLinkURL,
  acquireClientsGetLogoURL: process.env.REACT_APP_acquireClientsGetLogoURL,
};

export default urls;
