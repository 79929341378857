class Auth {
  constructor() {
    /*const userdata = localStorage.getItem("userdata1");
    this.userdata1 = userdata ? JSON.parse(userdata) : {};*/
    this.authenticated = false;
    if (localStorage.getItem("accessToken")) {
      this.authenticated = true;
    }
  }

  login(cb) {
    this.authenticated = true;
    cb();
  }

  logout(cb) {
    this.authenticated = false;
    cb();
  }

  isAuthenticated(userdata, module) {
    //const token = localStorage.getItem("userDataStateToken");
    //this.authenticated =
    // this.token && userdata && userdata[module] ? true : false;
    //if (module === "common" && this.token) this.authenticated = true;
    if (module === "common") return this.authenticated;
    return this.authenticated && userdata && userdata[module] ? true : false;
  }
}
const auth = new Auth();
export default auth;
