import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetProposalHistory,
  GetReviewHistory,
  GetSIPHistory,
} from "../../actions/actions";
import PreviousHistoryModalRow from "./PreviousHistoryModalRow";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InvestedgeModal from "../../components/modal/InvestedgeModal";
import { IoIosClose } from "react-icons/io";
import InvestedgeSearchField from "../../components/searchfield/InvestedgeSearchField";
import InvestedgeDatepicker from "../../components/datepicker/InvestedgeDatepicker";
import InvestedgeTextField from "../../components/textfield/InvestedgeTextField";
import InvestedgePagination from "../../components/pagination/InvestedgePagination";
import { Bars } from "react-loader-spinner";
import dayjs from "dayjs";
export default function PreviousHistoryModal({ historyModal, onClose }) {
  const [type, setType] = useState("Portfolio Proposal");
  const [fromDate, setFromDate] = useState(new Date("01-01-2022"));
  const [toDate, setToDate] = useState(new Date());
  const [client, setClient] = useState("");
  const dispatch = useDispatch();
  const options = ["Portfolio Proposal", "Portfolio Review", "SIP Proposal"];
  const proposal_loading = useSelector(
    (state) => state.proposalHistory.loading
  );
  const review_loading = useSelector((state) => state.reviewHistory.loading);
  const sip_loading = useSelector((state) => state.sipHistory.loading);
  const proposal_count = useSelector((state) => state.proposalHistory.count);
  const review_count = useSelector((state) => state.reviewHistory.count);
  const sip_count = useSelector((state) => state.sipHistory.count);
  const proposalHistory = useSelector((state) =>
    state.proposalHistory.results.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    })
  );
  const [proposalList, setProposalList] = useState([]);

  const reviewHistory = useSelector((state) =>
    state.reviewHistory.results.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    })
  );
  const sipHistory = useSelector((state) =>
    state.sipHistory.results.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    })
  );
  const dispatchingFunction = () => {
    switch (type) {
      case "Portfolio Proposal":
        setProposalList(proposalHistory);
        break;
      case "Portfolio Review":
        setProposalList(reviewHistory);
        break;
      case "SIP Proposal":
        setProposalList(sipHistory);
        break;

      default:
        break;
    }
  };
  useEffect(() => {
    dispatchingFunction(); // eslint-disable-next-line
  }, [reviewHistory, proposalHistory, sipHistory]);

  useEffect(() => {
    var from_dd = String(fromDate.getDate()).padStart(2, "0");
    var from_mm = String(fromDate.getMonth() + 1).padStart(2, "0");
    var from_yyyy = fromDate.getFullYear();

    var to_dd = String(toDate.getDate()).padStart(2, "0");
    var to_mm = String(toDate.getMonth() + 1).padStart(2, "0");
    var to_yyyy = toDate.getFullYear();

    switch (type) {
      case "Portfolio Proposal":
        dispatch(
          GetProposalHistory({
            params: {
              from_date: `${from_yyyy}-${from_mm}-${from_dd}`,
              to_date: `${to_yyyy}-${to_mm}-${to_dd}`,
              name: client,
              page: 1,
              size: proposalsPerPage,
            },
          })
        );
        break;
      case "Portfolio Review":
        dispatch(
          GetReviewHistory({
            params: {
              from_date: `${from_yyyy}-${from_mm}-${from_dd}`,
              to_date: `${to_yyyy}-${to_mm}-${to_dd}`,
              name: client,
              page: 1,
              size: proposalsPerPage,
            },
          })
        );
        break;
      case "SIP Proposal":
        dispatch(
          GetSIPHistory({
            params: {
              from_date: `${from_yyyy}-${from_mm}-${from_dd}`,
              to_date: `${to_yyyy}-${to_mm}-${to_dd}`,
              name: client,
              page: 1,
              size: proposalsPerPage,
            },
          })
        );
        break;

      default:
        break;
    }
    setPageNumber(1); // eslint-disable-next-line
  }, [client, toDate, fromDate, type]);

  useEffect(() => {
    dispatchingFunction(); // eslint-disable-next-line
  }, [type]);

  const [pageNumber, setPageNumber] = useState(1);
  const proposalsPerPage = 5;
  // const pagesVisited = pageNumber * proposalsPerPage;
  const pageCount =
    type === "Portfolio Proposal"
      ? Math.ceil(proposal_count / proposalsPerPage)
      : type === "Portfolio Review"
      ? Math.ceil(review_count / proposalsPerPage)
      : Math.ceil(sip_count / proposalsPerPage);
  const changePage = (e, val) => {
    var from_dd = String(fromDate.getDate()).padStart(2, "0");
    var from_mm = String(fromDate.getMonth() + 1).padStart(2, "0");
    var from_yyyy = fromDate.getFullYear();

    var to_dd = String(toDate.getDate()).padStart(2, "0");
    var to_mm = String(toDate.getMonth() + 1).padStart(2, "0");
    var to_yyyy = toDate.getFullYear();

    switch (type) {
      case "Portfolio Proposal":
        dispatch(
          GetProposalHistory({
            params: {
              from_date: `${from_yyyy}-${from_mm}-${from_dd}`,
              to_date: `${to_yyyy}-${to_mm}-${to_dd}`,
              name: client,
              page: val,
              size: proposalsPerPage,
            },
          })
        );
        break;

      case "Portfolio Review":
        dispatch(
          GetReviewHistory({
            params: {
              from_date: `${from_yyyy}-${from_mm}-${from_dd}`,
              to_date: `${to_yyyy}-${to_mm}-${to_dd}`,
              name: client,
              page: val,
              size: proposalsPerPage,
            },
          })
        );
        break;

      case "SIP Proposal":
        dispatch(
          GetSIPHistory({
            params: {
              from_date: `${from_yyyy}-${from_mm}-${from_dd}`,
              to_date: `${to_yyyy}-${to_mm}-${to_dd}`,
              name: client,
              page: val,
              size: proposalsPerPage,
            },
          })
        );
        break;

      default:
        break;
    }

    setPageNumber(val);
  };

  const return_date = (val) => {
    try {
      let temp = dayjs(new Date(val).toISOString());
      return temp;
    } catch (err) {
      return val;
    }
  };
  return (
    <InvestedgeModal
      aria-labelledby="Previous History Modal"
      aria-describedby="Previous History Modal"
      sx={{
        width: "80vw",
        marginRight: "auto",
        marginLeft: "auto",
        minHeight: "80vh",
        padding: "1rem",
      }}
      open={historyModal}
      handleclose={onClose}
    >
      <div
        className="flex justify-between border-0 pl-0"
        style={{ paddingTop: "0" }}
      >
        <h5 className="card-heading">Previous Downloads</h5>
        <IoIosClose size={30} onClick={onClose} />
      </div>
      <div>
        <div className="w-[20%] mt-4">
          <FormControl
            fullWidth
            size="small"
            sx={{
              "& .MuiInputBase-inputSizeSmall": {
                paddingTop: "10px",
                paddingBottom: "10px",
              },
            }}
          >
            <InputLabel>
              <span className="font-inter font-normal text-sm">{null}</span>
            </InputLabel>
            <Select
              value={type}
              label={null}
              onChange={(e) => setType(e.target.value)}
              className="font-inter text-sm"
            >
              {options.map((option, ind) => (
                <MenuItem value={option} id={ind} key={ind}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="mt-[16px] flex justify-between w-full">
          <div className="w-[20%]">
            <InvestedgeSearchField
              fullWidth
              onChange={(e) => setClient(e.target.value)}
              placeholder="Client Name"
              value={client}
            />
          </div>
          <div className="flex justify-end">
            <InvestedgeDatepicker
              format="DD/MM/YYYY"
              label="From"
              value={return_date(fromDate)}
              className="w-1/3"
              onChange={(date) => setFromDate(new Date(date))}
              maxDate={dayjs(new Date().toISOString())}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              renderInput={(params) => <InvestedgeTextField {...params} />}
            />
            <InvestedgeDatepicker
              format="DD/MM/YYYY"
              label="To"
              className="w-1/3 ml-4"
              value={return_date(toDate)}
              minDate={return_date(fromDate)}
              onChange={(date) => setToDate(new Date(date))}
              maxDate={dayjs(new Date().toISOString())}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              renderInput={(params) => <InvestedgeTextField {...params} />}
            />
          </div>
        </div>
      </div>
      {(type === "Portfolio Proposal" && proposal_loading) ||
      (type === "Portfolio Review" && review_loading) ||
      (type === "SIP Proposal" && sip_loading) ? (
        <div className="h-[400px] flex justify-center items-center">
          <Bars color="#1982f8" height="60" width="60" />
        </div>
      ) : (
        <table className="mt-[32px] w-full investedge-table">
          <colgroup>
            <col width="30%" />
            <col width="15%" />
            <col width="15%" />
            <col width="40%" />
          </colgroup>
          <tbody className="font-sm">
            <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
              <th className=" py-3 text-left font-medium">Client Name</th>
              <th className=" py-3 font-medium">Date</th>
              <th className=" py-3 font-medium">Time</th>
              <th className=" py-3 font-medium">Download</th>
            </tr>
            {proposalList
              // .slice(pagesVisited, pagesVisited + proposalsPerPage)
              .map((element, index) => (
                <PreviousHistoryModalRow
                  element={element}
                  key={index}
                  type={type}
                />
              ))}
          </tbody>
        </table>
      )}
      <div className="w-full flex justify-end absolute bottom-4 right-4">
        <InvestedgePagination
          count={pageCount}
          defaultPage={1}
          page={pageNumber}
          onChange={changePage}
          color="primary"
        />
      </div>
    </InvestedgeModal>
  );
}
