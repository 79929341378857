import OtpInput from "react-otp-input";

const InvestedgeOTP = ({
  numInputs,
  value,
  separator,
  onChange,
  isInputNum,
  disabled,
  shouldAutoFocus,
  isInputSecure,
  className,
  style,
  containerStyle,
  inputStyle,
  focusStyle,
  disabledStyle,
  error,
  helperText,
}) => (
  <div>
    <OtpInput
      numInputs={numInputs}
      value={value}
      separator={separator}
      onChange={onChange}
      isInputNum={isInputNum}
      isDisabled={disabled}
      shouldAutoFocus={shouldAutoFocus}
      isInputSecure={isInputSecure}
      style={style}
      containerStyle={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        ...containerStyle,
      }}
      inputStyle={{
        outline: "none",
        backgroundColor: "#06071B",
        borderRadius: 6,
        width: 40,
        height: 40,
        color: "#ffffff",
        border: "2px solid #ffffff",
        ...inputStyle,
        ...(!disabled &&
          error && {
            border: "2px solid #df6868",
          }),
        ...(disabled && {
          color: "grey",
          border: "2px solid grey",
          cursor: "not-allowed",
        }),
      }}
      focusStyle={{
        ...(!disabled && {
          ...(!error && {
            border: "2px solid #1982F8",
          }),
          ...(error && {
            border: "2px solid #df6868",
          }),
        }),
        ...(disabled && {
          color: "grey",
          border: "2px solid grey",
          cursor: "not-allowed",
        }),
        ...focusStyle,
      }}
      disabledStyle={disabledStyle}
      className={` ${className}`}
    />
    {helperText && (
      <span
        className={`font-inter-regular text-xs${
          error ? " text-[#df6868]" : " text-white"
        }`}
      >
        {helperText}
      </span>
    )}
  </div>
);

export default InvestedgeOTP;
InvestedgeOTP.defaultProps = {
  numInputs: 6,
  value: "",
  separator: <div className="w-[10px]" />,
  onChange: () => {},
  error: false,
  helperText: "",
  isInputNum: false,
  disabled: false,
  shouldAutoFocus: false,
  isInputSecure: false,
  style: {},
  containerStyle: {},
  inputStyle: {},
  focusStyle: {},
  disabledStyle: {},
  className: "",
};
