import React, { useState } from "react";
import { useSelector } from "react-redux";
import InvestedgePagination from "../../../../components/pagination/InvestedgePagination";
export default function SellList({ portfolioProducts, portfolioValue }) {
  const preferenceData = useSelector((state) =>
    portfolioProducts.length === 0
      ? state.portfolioReviewGetActionable.frontend_table
      : portfolioProducts
  );
  const buylist = preferenceData.filter((mf) => mf.tag === "Sell");
  // const buylist = [
  //   {
  //     name: "Mirae Asset Emerging Bluechip-Reg(G)",
  //     product_category: "Equity - MF",
  //     current_weight: "3.32",
  //     final_weight: "3.32",
  //     rationale:
  //       "This fund weights more than 10% of the overall portfolio. To reduce the concentration risk, we recommend selling it. Also, Basis your IPS, the current asset allocation is different from the recommended one. Hence, we are selling this fund to match the asset allocation.",
  //   },
  //   {
  //     name: "Mirae Asset Tax Saver Fund-Reg(G)",
  //     product_category: "Equity - MF",
  //     current_weight: "3.32",
  //     final_weight: "3.32",
  //     rationale:
  //       "This fund weights more than 10% of the overall portfolio. To reduce the concentration risk, we recommend selling it. Also, Basis your IPS, the current asset allocation is different from the recommended one. Hence, we are selling this fund to match the asset allocation.",
  //   },
  //   {
  //     name: "Mirae Asset Midcap Fund-Reg(G)",
  //     product_category: "Equity - MF",
  //     current_weight: "3.32",
  //     final_weight: "3.32",
  //     rationale:
  //       "This fund weights more than 10% of the overall portfolio. To reduce the concentration risk, we recommend selling it. Also, Basis your IPS, the current asset allocation is different from the recommended one. Hence, we are selling this fund to match the asset allocation.",
  //   },
  //   {
  //     name: "Motilal Oswal Nasdaq 100 FOF-Reg(G)	E",
  //     product_category: "Equity - MF",
  //     current_weight: "3.32",
  //     final_weight: "3.32",
  //     rationale:
  //       "This fund weights more than 10% of the overall portfolio. To reduce the concentration risk, we recommend selling it. Also, Basis your IPS, the current asset allocation is different from the recommended one. Hence, we are selling this fund to match the asset allocation.",
  //   },
  //   {
  //     name: "PGIM India Flexi Cap Fund-Reg(G)",
  //     product_category: "Equity - MF",
  //     current_weight: "3.32",
  //     final_weight: "3.32",
  //     rationale:
  //       "This fund weights more than 10% of the overall portfolio. To reduce the concentration risk, we recommend selling it. Also, Basis your IPS, the current asset allocation is different from the recommended one. Hence, we are selling this fund to match the asset allocation.",
  //   },
  //   {
  //     name: "PGIM India Flexi Cap Fund-Reg(G)",
  //     product_category: "Equity - MF",
  //     current_weight: "3.32",
  //     final_weight: "3.32",
  //     rationale:
  //       "This fund weights more than 10% of the overall portfolio. To reduce the concentration risk, we recommend selling it. Also, Basis your IPS, the current asset allocation is different from the recommended one. Hence, we are selling this fund to match the asset allocation.",
  //   },
  //   {
  //     name: "Parag Parikh Flexi Cap Fund-Reg(G)",
  //     product_category: "Equity - MF",
  //     current_weight: "3.32",
  //     final_weight: "3.32",
  //     rationale:
  //       "This fund weights more than 10% of the overall portfolio. To reduce the concentration risk, we recommend selling it. Also, Basis your IPS, the current asset allocation is different from the recommended one. Hence, we are selling this fund to match the asset allocation.",
  //   },
  //   {
  //     name: "Kotak Small Cap Fund(G)",
  //     product_category: "Equity - MF",
  //     current_weight: "3.32",
  //     final_weight: "3.32",
  //     rationale:
  //       "This fund weights more than 10% of the overall portfolio. To reduce the concentration risk, we recommend selling it. Also, Basis your IPS, the current asset allocation is different from the recommended one. Hence, we are selling this fund to match the asset allocation.",
  //   },
  //   {
  //     name: "PGIM India Flexi Cap Fund-Reg(G)",
  //     product_category: "Equity - MF",
  //     current_weight: "3.32",
  //     final_weight: "3.32",
  //     rationale:
  //       "This fund weights more than 10% of the overall portfolio. To reduce the concentration risk, we recommend selling it. Also, Basis your IPS, the current asset allocation is different from the recommended one. Hence, we are selling this fund to match the asset allocation.",
  //   },
  //   {
  //     name: "PGIM India Flexi Cap Fund-Reg(G)",
  //     product_category: "Equity - MF",
  //     current_weight: "3.32",
  //     final_weight: "3.32",
  //     rationale:
  //       "This fund weights more than 10% of the overall portfolio. To reduce the concentration risk, we recommend selling it. Also, Basis your IPS, the current asset allocation is different from the recommended one. Hence, we are selling this fund to match the asset allocation.",
  //   },
  //   {
  //     name: "PGIM India Flexi Cap Fund-Reg(G)",
  //     product_category: "Equity - MF",
  //     current_weight: "3.32",
  //     final_weight: "3.32",
  //     rationale:
  //       "This fund weights more than 10% of the overall portfolio. To reduce the concentration risk, we recommend selling it. Also, Basis your IPS, the current asset allocation is different from the recommended one. Hence, we are selling this fund to match the asset allocation.",
  //   },
  //   {
  //     name: "PGIM India Flexi Cap Fund-Reg(G)",
  //     product_category: "Equity - MF",
  //     current_weight: "3.32",
  //     final_weight: "3.32",
  //     rationale:
  //       "This fund weights more than 10% of the overall portfolio. To reduce the concentration risk, we recommend selling it. Also, Basis your IPS, the current asset allocation is different from the recommended one. Hence, we are selling this fund to match the asset allocation.",
  //   },
  //   {
  //     name: "PGIM India Flexi Cap Fund-Reg(G)",
  //     product_category: "Equity - MF",
  //     current_weight: "3.32",
  //     final_weight: "3.32",
  //     rationale:
  //       "This fund weights more than 10% of the overall portfolio. To reduce the concentration risk, we recommend selling it. Also, Basis your IPS, the current asset allocation is different from the recommended one. Hence, we are selling this fund to match the asset allocation.",
  //   },
  //   {
  //     name: "PGIM India Flexi Cap Fund-Reg(G)",
  //     product_category: "Equity - MF",
  //     current_weight: "3.32",
  //     final_weight: "3.32",
  //     rationale:
  //       "This fund weights more than 10% of the overall portfolio. To reduce the concentration risk, we recommend selling it. Also, Basis your IPS, the current asset allocation is different from the recommended one. Hence, we are selling this fund to match the asset allocation.",
  //   },
  // ];
  const [pageNumber, setPageNumber] = useState(0);
  const clientsPerPage = 8;
  const pagesVisited = pageNumber * clientsPerPage;
  const displaydata =
    Array.isArray(buylist) &&
    buylist.slice(pagesVisited, pagesVisited + clientsPerPage).map((pid) => {
      return (
        <tr key={pid.product_id} style={{ borderBottom: "1px solid #e5e5e5" }}>
          <td className=" font-inter not-italic font-normal tracking-[0em] p-[1rem] text-left pl-[1rem] text-sm text-[#6a6a76]">
            {pid.product_name}
          </td>
          <td className=" font-inter not-italic font-normal tracking-[0em] p-[1rem] text-center text-sm text-[#6a6a76]">
            {`${pid.asset_class} - ${pid.product_type}`}
          </td>
          <td className=" font-inter not-italic font-normal tracking-[0em] p-[1rem] text-center text-sm text-[#6a6a76]">
            {(pid.current_weight * 100).toFixed(1)}
          </td>
          <td className=" font-inter not-italic font-normal tracking-[0em] p-[1rem] text-center text-sm text-[#ff0000] ">
            {`${(
              (parseInt(pid.proposed_market_value) / portfolioValue) *
              100
            ).toFixed(1)}`}
          </td>
          <td className=" font-inter not-italic font-normal tracking-[0em] p-[1rem]  text-sm text-[#6a6a76] text-left pl-0">
            {pid.rationale}
          </td>
        </tr>
      );
    });

  const pageCount = Math.ceil(buylist.length / clientsPerPage);
  const changePage = (e, val) => {
    setPageNumber(val - 1);
  };
  return (
    <div className="w-[100%] flex">
      <div className="p-4 rounded-[8px] bg-white my-[4px] w-full min-h-[380px] relative">
        <table className="w-full mt-[2rem] investedge-table">
          <colgroup>
            <col width="20%" />
            <col width="13%" />
            <col width="13%" />
            <col width="13%" />
            <col width="41%" />
          </colgroup>
          <tbody>
            <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
              <th className="text-[#3D3D3D] font-medium text-sm mb-[16px] pl-[1rem] text-left">
                Scheme Name
              </th>
              <th className="text-[#3D3D3D] font-medium text-sm mb-[16px] text-center">
                Product Category &amp; <br /> Asset Class
              </th>
              <th className="text-[#3D3D3D] font-medium text-sm mb-[16px] text-center">
                Current Weight
              </th>
              <th className="text-[#3D3D3D] font-medium text-sm mb-[16px] text-center">
                Final Weight
              </th>
              <th className="text-[#3D3D3D] font-medium text-sm mb-[16px]">
                Rationale
              </th>
            </tr>
            {displaydata}
          </tbody>
        </table>
        <div className="flex justify-end mt-5">
          <InvestedgePagination
            count={pageCount}
            defaultPage={1}
            page={pageNumber + 1}
            onChange={changePage}
            color="primary"
          />
        </div>
      </div>
    </div>
  );
}
