import React from "react";
import Projections from "./Projections";

export default function Summary({
  parsedData,
  sipparsedData,
  purposePortfolio,
  formValue,
}) {
  const denotionParser = (denotion, value) => {
    if (denotion === "K") return parseInt(value * 1000).toLocaleString("en-IN");
    else if (denotion === "L")
      return parseInt(value * 100000).toLocaleString("en-IN");
    else return parseInt(value * 10000000).toLocaleString("en-IN");
  };
  const portfolioValue = denotionParser(
    formValue.proposal_value.denomination[2],
    parseInt(formValue.proposal_value.amount)
  );
  const sipportfolioValue = denotionParser(
    formValue.sip_proposal_value.denomination,
    parseInt(formValue.sip_proposal_value.amount)
  );
  return (
    <div className="px-8 rounded-[8px] w-full bg-white pt-8 pb-4 mt-[16px]">
      <h2 className="card-heading">
        Portfolio for{" "}
        <span className="text-primary-200">{purposePortfolio}</span>
      </h2>
      <div className="flex w-full justify-between mt-6">
        <div className="w-[48%]">
          <p className="text-neutralBlack text-base font-medium">Lumpsum</p>
          <div className="w-full bg-[#fafafa] py-5 px-0 flex items-center mt-4">
            <div className="w-[50%] text-center border-r border-r-dividerColor">
              <h3 className="font-inter text-[24px] text-[#1982f8] font-semibold leading-[20px] tracking-[0em] text-center mb-0">
                {portfolioValue}
              </h3>
              <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-6">
                Total Lumpsum
              </label>
            </div>
            <div className=" w-[50%] text-center">
              <h3 className=" font-inter text-[#6a6a76] text-[24px] font-semibold leading-[20px] tracking-[0em] text-center mb-0">
                {`${formValue.investment_horizon} Yrs`}
              </h3>
              <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-6">
                Horizon
              </label>
            </div>
          </div>
          <div className="w-full bg-[#fafafa] p-5 pt-0 mt-4 scrollablediv h-[200px] overflow-auto relative">
            <div className="w-full flex justify-between border-b border-b-neutralBlack pb-2 sticky top-0 z-10 bg-[#fafafa] pt-5">
              <p className="text-neutralBlack text-sm">Lumpsum Investment</p>
              <p className="text-neutralBlack text-sm">Amount (INR)</p>
            </div>
            {parsedData.map((product, index) => (
              <div key={index} className="w-full flex justify-between py-2">
                <p className="text-neutralBlack text-sm">{product.name}</p>
                <p className="text-neutralBlack text-sm">
                  {denotionParser(
                    formValue.proposal_value.denomination[2],
                    (product.individual_asset_pct *
                      parseInt(formValue.proposal_value.amount)) /
                      100
                  )}
                </p>{" "}
              </div>
            ))}
          </div>
          <div className="bg-[#fafafa] p-5 px-6 pt-2">
            <div className="w-full flex justify-between border-t border-t-neutralBlack pt-5">
              <p className="text-neutralBlack text-sm">Total</p>
              <p className="text-primary-200 text-sm">{portfolioValue}</p>
            </div>
          </div>
        </div>
        <div className="w-[48%]">
          <p className="text-neutralBlack text-base font-medium">SIP</p>
          <div className="w-full bg-[#fafafa] py-5 px-0 flex items-center mt-4">
            <div className="w-[50%] text-center border-r border-r-dividerColor">
              <h3 className="font-inter text-[24px] text-[#1982f8] font-semibold leading-[20px] tracking-[0em] text-center mb-0">
                {sipportfolioValue} / month
              </h3>
              <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-6">
                SIP amount
              </label>
            </div>
            <div className=" w-[50%] text-center">
              <h3 className=" font-inter text-[#6a6a76] text-[24px] font-semibold leading-[20px] tracking-[0em] text-center mb-0">
                {`${formValue.investment_horizon} Yrs`}
              </h3>
              <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-6">
                Horizon
              </label>
            </div>
          </div>
          <div className="w-full bg-[#fafafa] p-5 pt-0 mt-4 scrollablediv h-[200px] overflow-auto relative">
            <div className="w-full flex justify-between border-b border-b-neutralBlack pb-2 sticky top-0 z-10 bg-[#fafafa] pt-5">
              <p className="text-neutralBlack text-sm">SIP</p>
              <p className="text-neutralBlack text-sm">Amount (INR)</p>
            </div>
            {sipparsedData.map((product, index) => (
              <div key={index} className="w-full flex justify-between py-2">
                <p className="text-neutralBlack text-sm">{product.name}</p>
                <p className="text-neutralBlack text-sm">
                  {denotionParser(
                    formValue.sip_proposal_value.denomination,
                    (product.individual_asset_pct *
                      parseInt(formValue.sip_proposal_value.amount)) /
                      100
                  )}
                </p>{" "}
              </div>
            ))}
          </div>
          <div className="bg-[#fafafa] p-5 px-6 pt-2">
            <div className="w-full flex justify-between border-t border-t-neutralBlack pt-5">
              <p className="text-neutralBlack text-sm">Total</p>
              <p className="text-primary-200 text-sm">{sipportfolioValue}</p>
            </div>
          </div>
        </div>
      </div>
      <h2 className="card-heading mt-8">Projections</h2>
      <Projections formValue={formValue} />
    </div>
  );
}
