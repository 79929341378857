import React from "react";
import AfterloginApp from "../AfterloginApp";
import "../../static/css/existing/dashboard_styles.css";
import { FundHeader } from "./ProductOnepagerConst/FundHeadingCard";
import FundDetail from "./ProductOnepagerConst/FundDetailCard";
import { AllControl } from "./ProductOnepagerConst/NewProductonepager/HandleScreen";
import { SearchBarOnepager } from "./ProductOnepagerConst/OnepagerSearch";
import { useSelector } from "react-redux";
const ProductOnepager = () => {
  const fundData = useSelector((store) => store.getProductOnepagerData);
  console.log(fundData);
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Client Input"}
        description={
          "Browse for portfolio insights, actionable, cash flow planning, and much more"
        }
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className="bg-[#fff] p-[1rem] rounded-[00.5rem] h-full w-full min-h-[calc(100vh_-_116px)]">
            <SearchBarOnepager />
            <div className="rounded-[0.5rem] border-[1px]   mt-2">
              <FundHeader fundData={fundData} />
              <FundDetail fundData={fundData} />
            </div>
            <AllControl fundData={fundData} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductOnepager;
