import React from "react";
import { GraphColorPalette } from "../../../../../components/colors/graphColorPalette";
import PieChart from "../../../../dashboard/PieChart";
import ProgressBar from "./ProgressBar";
export default function AlternateSection({ analytics }) {
  const LegendItem = ({ color, text }) => {
    return (
      <div className="flex ml-[4px]">
        <div
          className={`w-[7px] h-[7px] block mt-[7px] `}
          style={{ backgroundColor: color }}
        />
        <p className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] ml-[0.2rem] mb-0">
          {text}
        </p>
      </div>
    );
  };
  const keys = [];
  const values = [];
  const colorPalette = GraphColorPalette;

  const bgGradients = [
    "linear-gradient(223.78deg, #09C3E7 0%, #7669F0 106.87%)",
    "linear-gradient(225deg, #9C2979 0%, #F15E75 100%)",
    "linear-gradient(108.81deg, #FF9F38 -27.65%, #FE5C44 125%)",
    "linear-gradient(108.81deg, #FFD166 -27.65%, #C26F0D 125%)",
  ];
  for (const property in analytics.alternate_product_categories) {
    keys.push(property);
    values.push(
      (analytics.alternate_product_categories[property] * 100).toFixed(1)
    );
  }
  const liquidityKeys = [];
  const liquidityValues = [];
  for (const property in analytics.alternate_liqudity_allocation) {
    liquidityKeys.push(property);
    liquidityValues.push(
      (analytics.alternate_liqudity_allocation[property] * 100).toFixed(1)
    );
  }
  const assetdata = {
    labels: liquidityKeys,

    datasets: [
      {
        data: liquidityValues,
        backgroundColor: colorPalette,
        borderColor: colorPalette,
        borderWidth: 1,
      },
    ],
  };
  const pieoptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      labels: {
        render: "value",
        fontColor: "#fff",
      },
      datalabels: {
        display: false,
      },
    },
  };
  return (
    <div className="w-full flex justify-between mt-4">
      <div className=" w-[49.5%] h-[278px]">
        <h4 className=" font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-[0.5rem]">
          Liquidity Allocation (in %)
        </h4>
        <div className="bg-[#FAFAFA] w-[100%] p-[1rem] min-h-full">
          <div className="w-full flex justify-center items-center">
            <PieChart
              data={assetdata}
              options={pieoptions}
              width={190}
              height={190}
            />
          </div>

          <div className=" flex justify-evenly w-full z-[100] pt-[0.4rem] flex-wrap">
            {liquidityKeys.map((key, index) => (
              <LegendItem key={index} color={colorPalette[index]} text={key} />
            ))}
          </div>
        </div>
      </div>
      <div className=" w-[49.5%] min-h-[278px]">
        <h4 className=" font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-2">
          Product Allocation
        </h4>
        <div className="bg-[#FAFAFA] w-[100%] p-[1rem] min-h-full">
          {keys.map((sector, index) => (
            <ProgressBar
              key={index}
              bg={bgGradients[index % bgGradients.length]}
              progress={values[index]}
              label={sector}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
