import { GET_DASHBOARD_DETAILS } from "../actions/type";
const initialState = {
  dob: new Date(),
  general_details: {
    id: 52,
    client_id_id: "",
    gender: "",
    marital_status: "",
    pan_number: "",
    occupation: "",
    sector: "",
    address: "",
    pincode: "",
    family_id_id: "",
    is_primary: true,
    relation_with_primary: "Self",
  },
  tax_details: {
    user_id: "eeae8174-4fdd-4264-a105-c8f67bbe1ed8",
    taxResidency: "I",
    birthCountry: "O",
    residencyStatus: "I",
    nationality: "I",
    investmentEntity: "I",
    isProfitMaking: true,
    incomeTaxSlab: "200",
    marginalTaxRate: "22",
    carryFwdShortTermCapitalLoss: {
      amount: 0,
      denomination: "L",
    },
    carryFwdLongTermCapitalLoss: {
      amount: 0,
      denomination: "L",
    },
    curentShortTermCapitalLoss: {
      amount: 0,
      denomination: "L",
    },
    currentLongTermCapitalLoss: {
      amount: 0,
      denomination: "L",
    },
  },
};
export default function GetDashboardDetails(state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_DETAILS:
      return action.payload;
    default:
      return state;
  }
}
