import axios from "axios";
import urls from "./urls";
//request interceptor to add the auth token header to requests

axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    let refreshToken = localStorage.getItem("refreshToken");
    if (
      refreshToken &&
      error.response.status === 401 &&
      !originalRequest._retry &&
      originalRequest.url !== process.env.REACT_APP_refreshTokenURL &&
      originalRequest.url !== process.env.REACT_APP_settingsChangePasswordURL
    ) {
      originalRequest._retry = true;
      return await axios
        .post(urls.refreshTokenURL, {
          refresh: refreshToken,
        })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("accessToken", res.data.access);
            return axios(originalRequest);
          }
        })
        .catch((error) => {
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("accessToken");
          window.location.pathname = "/login";
        });
    }
    return Promise.reject(error);
  }
);
//functions to make api calls
const api = {
  contact: (body) => {
    return axios.post(urls.contactURL, body);
  },
  login: (body) => {
    return axios.post(urls.loginUserURL, body);
  },
  refreshToken: (body) => {
    return axios.post(urls.refreshTokenURL, body);
  },
  thirdPartyLogin: (body) => {
    return axios.post(urls.thirdPartyLoginURL, body);
  },
  userDetailsToken: (config) => {
    return axios.get(urls.userDetailsURL, config);
  },
  forgetPassword: (body) => {
    return axios.post(urls.forgetPasswordURL, body);
  },
  forgetPasswordConfirm: (body) => {
    return axios.post(urls.forgetPasswordConfirmURL, body);
  },
  marketEvents: () => {
    return axios.get(urls.marketEventsURL);
  },
  marketFAQ: (body) => {
    return axios.get(urls.marketFaqURL, body);
  },
  trendingFaq: () => {
    return axios.get(urls.trendingFaqURL);
  },
  marketSector: () => {
    return axios.get(urls.marketSectorsURL);
  },
  getsavedFAQ: () => {
    return axios.get(urls.savedFaqURL);
  },
  postsavedFAQ: (body) => {
    return axios.post(urls.savedFaqURL, body);
  },
  marketExperts: () => {
    return axios.get(urls.marketsExpertsURL);
  },
  marketExpertsConsolidated: () => {
    return axios.get(urls.marketExpertsConsolidatedURL);
  },
  marketConversation: () => {
    return axios.get(urls.marketConversationURL);
  },
  getResearch: () => {
    return axios.get(urls.researchURL);
  },
  getFundhouseResearch: () => {
    return axios.get(urls.researchFundhouseURL);
  },
  getIps: (body) => {
    return axios.get(urls.ipsURL, body);
  },
  postResearch: (body) => {
    return axios.post(urls.researchURL, body);
  },
  postResearchAssets: (body) => {
    return axios.post(urls.researchAssetAllocationURL, body);
  },
  getProductInput: (body) => {
    return axios.get(urls.productInputURL, body);
  },
  postProductInput: (body) => {
    return axios.patch(urls.postProductInputURL, body);
  },
  postUserSettings: (body) => {
    return axios.post(urls.settingsUserURL, body);
  },
  organizationDetails: () => {
    return axios.get(urls.organizationURL);
  },
  organizationMembers: () => {
    return axios.get(urls.organizationMemberURL);
  },
  // AUM APIs
  annualTarget: () => {
    return axios.get(urls.annualTarget);
  },
  aumBreakup: () => {
    return axios.get(urls.aumBreakup);
  },
  aumBreakupProduct: () => {
    return axios.get(urls.aumBreakupProduct);
  },
  aumBreakupAsset: () => {
    return axios.get(urls.aumBreakupAsset);
  },
  aumBreakupClient: () => {
    return axios.get(urls.aumBreakupClient);
  },
  dashboardAllClients: () => {
    return axios.get(urls.dashboardAllClientsURL);
  },
  dashboardYourClients: () => {
    return axios.get(urls.dashboardYourClientsURL);
  },
  birthdayThisMonth: () => {
    return axios.get(urls.birthdayThisMonthURL);
  },
  // PopularProducts APIs
  popularProducts: () => {
    return axios.get(urls.popularProducts);
  },
  // productonepager APIs
  productonepager: (body) => {
    return axios.get(urls.productonepager, body);
  },
  // logging APIs
  eventLogging: (body) => {
    return axios.post(urls.eventLoggingURL, body);
  },
  // Settings APIs
  settingsAbout: () => {
    return axios.get(urls.organizationURL);
  },
  settingsGeneral: () => {
    return axios.get(urls.settingsUserURL);
  },
  postSettingsGeneral: (body) => {
    return axios.patch(urls.settingsUserURL, body);
  },
  patchSettingsGeneral: (body) => {
    return axios.patch(urls.settingsUserURL, body);
  },
  settingsModule: () => {
    return axios.get(urls.organizationMemberURL);
  },
  settingsOperations: () => {
    return axios.get(urls.operationsdataURL);
  },
  settingsRminfo: () => {
    return axios.get(urls.rminfoURL);
  },
  settingsgetTeamLead: () => {
    return axios.get(urls.teamleadURL);
  },
  settingspatchTeamLead: (body) => {
    return axios.patch(urls.rminfoURL, body);
  },
  settingsgetOperationsArray: () => {
    return axios.get(urls.getOperationsLeadURL);
  },
  settingsgetRms: () => {
    return axios.get(urls.getRmsURL);
  },

  // Proposal
  allocationPreference: (body) => {
    return axios.post(urls.allocationPreference, body);
  },
  analyticsAPI: (body) => {
    return axios.post(urls.analyticsAPI, body);
  },
  pptAPI: (body) => {
    return axios.post(urls.pptAPI, body);
  },
  proposalHistory: (body) => {
    return axios.get(urls.proposalHistoryURL, body);
  },
  proposalAdvanceAnalytics: (body) => {
    return axios.post(urls.proposalAdvanceAnalyticsAPI, body);
  },
  // SIP Proposal
  sipallocationPreference: (body) => {
    return axios.post(urls.sipallocationPreferenceURL, body);
  },
  sipanalytics: (body) => {
    return axios.post(urls.sipanalyticsURL, body);
  },
  sipppt: (body) => {
    return axios.post(urls.sippptURL, body);
  },
  sipHistory: (body) => {
    return axios.get(urls.sipHistoryURL, body);
  },
  sipAdvanceAnalytics: (body) => {
    return axios.post(urls.sipAdvanceAnalyticsAPI, body);
  },
  // Client Input
  getClients: (body) => {
    return axios.get(urls.getClientsURL, body);
  },
  getClientInputRms: () => {
    return axios.get(urls.getClientInputRmsURL);
  },
  clientInputPostNew: (body) => {
    return axios.post(urls.clientInputPostNewURL, body);
  },
  clientInputUploadProfilePicture: (body) => {
    return axios.patch(urls.clientInputUploadProfilePictureURL, body);
  },
  clientInputUploadFile: (body) => {
    return axios.post(urls.clientInputUploadFileURL, body);
  },
  clientInputDownloadTemplate: () => {
    return axios.get(urls.clientInputDownloadTemplateURL);
  },
  //  productsinsight
  comparisonData: (body) => {
    return axios.get(urls.comparisonData, body);
  },
  //  productreckonerData
  productreckonerData: () => {
    return axios.get(urls.productreckonerData);
  },
  // Client Dashboard
  dashboardPortfolio: (body) => {
    return axios.post(urls.dashboardPortfolioURL, body);
  },
  dashboardPortfolioSummary: (body) => {
    return axios.post(urls.dashboardPortfolioSummaryURL, body);
  },
  dashboardHoldingReport: (body) => {
    return axios.post(urls.dashboardHoldingReportURL, body);
  },
  dashboardInsights: (body) => {
    return axios.post(urls.dashboardInsightsURL, body);
  },
  dashboardAnalytics: (body) => {
    return axios.post(urls.dashboardAnalyticsURL, body);
  },
  dashboardPerformance: (body) => {
    return axios.post(urls.dashboardPerformanceURL, body);
  },
  dashboardGetDetails: (body) => {
    return axios.get(urls.dashboardDetailsURL, body);
  },
  dashboardPostDetails: (body) => {
    return axios.put(urls.dashboardDetailsURL, body);
  },
  dashboardGetInvestment: (body) => {
    return axios.get(urls.dashboardInvestmentURL, body);
  },
  dashboardPostInvestment: (body) => {
    return axios.put(urls.dashboardInvestmentURL, body);
  },
  dashboardRedeemCapital: (body) => {
    return axios.post(urls.dashboardRedeemCapitalURL, body);
  },
  dashboardDeployCapital: (body) => {
    return axios.post(urls.dashboardDeployCapitalURL, body);
  },
  dashboardMFCentralOtp: (body) => {
    return axios.post(urls.dashboardMFCentralOTPURL, body);
  },
  dashboardMFCentralPayload: (body) => {
    return axios.post(urls.dashboardMFCentralPayloadURL, body);
  },
  dashboardSuitableProducts: (body) => {
    return axios.post(urls.dashboardSuitableProductsURL, body);
  },
  dashboardMFPortfolio: (body) => {
    return axios.get(urls.dashboardMFTransactionURL, body);
  },
  dashboardStocksPortfolio: (body) => {
    return axios.get(urls.dashboardStocksPortfolioURL, body);
  },
  dashboardCashPortfolio: (body) => {
    return axios.get(urls.dashboardCashPortfolioURL, body);
  },
  dashboardBondsPortfolio: (body) => {
    return axios.get(urls.dashboardBondsPortfolioURL, body);
  },
  dashboardPmsPortfolio: (body) => {
    return axios.get(urls.dashboardPmsPortfolioURL, body);
  },
  dashboardAifPortfolio: (body) => {
    return axios.get(urls.dashboardAifPortfolioURL, body);
  },
  dashboardUnlistedPortfolio: (body) => {
    return axios.get(urls.dashboardUnlistedPortfolioURL, body);
  },
  dashboardDepositsPortfolio: (body) => {
    return axios.get(urls.dashboardDepositsPortfolioURL, body);
  },
  dashboardGoldPortfolio: (body) => {
    return axios.get(urls.dashboardGoldPortfolioURL, body);
  },
  dashboardRealEstatePortfolio: (body) => {
    return axios.get(urls.dashboardRealEstatePortfolioURL, body);
  },
  dashboardOffshorePortfolio: (body) => {
    return axios.get(urls.dashboardOffshorePortfolioURL, body);
  },
  dashboardBenchmarkPrice: (body) => {
    return axios.get(urls.dashboardBenchmarkPriceURL, body);
  },
  dashboardPortfolioList: (body) => {
    return axios.get(urls.dashboardPortfolioListURL, body);
  },
  // Review Portfolio
  reviewPortfolioGetSummary: (body) => {
    return axios.get(urls.reviewPortfolioGetSummaryURL, body);
  },
  reviewPortfolioPatchSummary: (body) => {
    return axios.patch(urls.reviewPortfolioPatchSummaryURL, body);
  },
  reviewPortfolioGetAnalytics: (body) => {
    return axios.post(urls.reviewPortfolioGetAnalyticsURL, body);
  },
  reviewPortfolioGetIpsData: (body) => {
    return axios.get(urls.reviewPortfolioGetIpsDataURL, body);
  },
  reviewPortfolioGetIAA: (body) => {
    return axios.post(urls.reviewPortfolioGetIAAURL, body);
  },
  reviewPortfolioGetIpstracker: (body) => {
    return axios.post(urls.reviewPortfolioGetIpstrackerURL, body);
  },
  reviewPortfolioGetActionable: (body) => {
    return axios.post(urls.reviewPortfolioActionablesURL, body);
  },
  reviewPortfolioGetAnalyticsFinal: (body) => {
    return axios.post(urls.reviewPortfolioGetAnalyticsFinalURL, body);
  },
  reviewPortfolioPostActionable: (body) => {
    return axios.post(urls.reviewPortfolioActionablesURL, body);
  },
  reviewPortfolioGetPPT: (body) => {
    return axios.post(urls.reviewPortfolioGetPPTURL, body);
  },
  mutualFundAnalyticsGetPPT: (body) => {
    return axios.post(urls.mutualFundAnalyticsGetPptURL, body);
  },
  reviewHistory: (body) => {
    return axios.get(urls.reviewHistoryURL, body);
  },
  reviewAdvanceAnalytics: (body) => {
    return axios.post(urls.reviewAdvanceAnalyticsAPI, body);
  },
  // Model Portfolio
  postModelPortfolioBookmark: (body) => {
    return axios.post(urls.modelPortfolioBookmarkURL, body);
  },
  getModelPortfolio: () => {
    return axios.get(urls.modelPortfolioDescriptionURL);
  },
  postModelPortfolio: (body) => {
    return axios.post(urls.modelPortfolioDescriptionURL, body);
  },
  deleteModelPortfolio: (body) => {
    return axios.post(urls.modelPortfolioDescriptionDeleteURL, body);
  },
  patchModelPortfolio: (body) => {
    return axios.patch(urls.modelPortfolioDescriptionURL, body);
  },
  getModelPortfolioAnalytics: (body) => {
    return axios.post(urls.modelPortfolioAnalyticsURL, body);
  },
  getModelPortfolioProducts: (body) => {
    return axios.get(urls.modelPortfolioURL, body);
  },
  postModelPortfolioProducts: (body) => {
    return axios.post(urls.modelPortfolioURL, body);
  },
  patchModelPortfolioProducts: (body) => {
    return axios.patch(urls.modelPortfolioURL, body);
  },
  deleteModelPortfolioProducts: (body) => {
    return axios.post(urls.modelPortfolioDeleteURL, body);
  },
  //Goal Mapping
  getGoalsList: (body) => {
    return axios.get(urls.goalsListURL, body);
  },
  getGoalDetail: (id, body) => {
    return axios.get(urls.goalsListURL + id, body);
  },
  //Instant Review
  acquireClientsAnalytics: (body) => {
    return axios.post(
      `${urls.acquireClientsAnalyticsURL}/${body.requestid}/`,
      body
    );
  },
  acquireClientsPPT: (body) => {
    return axios.post(`${urls.acquireClientsPPTURL}/${body.requestid}/`, body);
  },
  acquireClientsCamsPPT: (body) => {
    return axios.post(`${urls.acquireClientsCamsPPTURL}/${body.rmID}/`, body);
  },
  acquireClientsDashboard: (body) => {
    return axios.get(`${urls.acquireClientsDashboardURL}/${body.type}/`, body);
  },
};
export default api;
