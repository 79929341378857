import { PATCH_REVIEW_PORTFOLIO_SUMMARY } from "../actions/type";
const initialState = {};
export default function PatchReviewPortfolioSummary(
  state = initialState,
  action
) {
  switch (action.type) {
    case PATCH_REVIEW_PORTFOLIO_SUMMARY:
      return action.payload;
    default:
      return state;
  }
}
