import React from "react";
import { PortfolioInputSidebarData } from "./portfolioInputSidebarData";
import Lock from "../assets/img/lock_locked_blue.svg";
// import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OpenSnackbar } from "../actions/actions";
import InvestedgeButton from "../components/buttons/InvestedgeButton";
export default function PortfolioInputSidebar({ route, setRoute }) {
  // const location = useLocation();
  const portfolioList = useSelector(
    (store) => store.dashboardPortfolioPriceList
  );
  const dispatch = useDispatch();
  return (
    <>
      <nav className="flex w-[17%] flex-col justify-start border border-globalBorderColor p-4 px-2 mt-[1rem] left-0 duration-[350ms] z-[10] overflow-y-auto h-[80vh] rounded-md">
        <InvestedgeButton
          className="w-full mx-auto"
          onClick={() => setRoute("welcomescreen")}
        >
          Upload
        </InvestedgeButton>
        {PortfolioInputSidebarData.map((item, index) => {
          return (
            <div
              onClick={() => {
                !item.locked
                  ? setRoute(item.path)
                  : dispatch(
                      OpenSnackbar({
                        severity: "info",
                        message: "Feature will be coming soon",
                      })
                    );
              }}
              className="flex items-center text-neutralBlack justify-between font-inter p-[0.5rem] list-none pb-0 w-full font-medium cursor-pointer no-underline text-[16px] text-sm mt-2 duration-[250ms]"
              key={index}
            >
              <div
                className={
                  route === item.path ? "border-b-[2px] border-primary-200" : ""
                }
              >
                {item.title}
              </div>
              {item.locked ? (
                <img src={Lock} alt="err" />
              ) : (
                portfolioList[item.value]
              )}
            </div>
          );
        })}
      </nav>
    </>
  );
}
