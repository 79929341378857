import React, { useEffect, useState } from "react";
import PieChart from "../../rmdashboard/dashboard/PieChart";
import DoughnutChart from "../../components/charts/DoughnutChart";
import Dropdown from "../../components/Dropdowns/DropdownWhiteSingle";
import { useSelector } from "react-redux";
import ProgressBar from "./AnalyticsProgressBar";
import { GraphColorPalette } from "../../components/colors/graphColorPalette";
export default function Analytics({ myref }) {
  const data = useSelector((state) => state.dashboardAnalytics);
  const sectorKeys = [];
  const sectorValues = [];
  for (const property in data.sector_allocation) {
    sectorKeys.push(property);
    sectorValues.push(data.sector_allocation[property]);
  }
  const productKeys = [];
  const productValues = [];
  for (const property in data.product_allocation) {
    productKeys.push(property);
    productValues.push((data.product_allocation[property] * 100).toFixed(1));
  }
  const creditKeys = [];
  const creditValues = [];
  for (const property in data.credit_quality) {
    creditKeys.push(property);
    creditValues.push((data.credit_quality[property] * 100).toFixed(1));
  }
  const [product, setProduct] = useState("All");
  const productSet = new Set(
    Array.isArray(data.top_holding)
      ? data.top_holding.map((product) => product.type)
      : []
  );
  const [holdingsData, setHoldingsData] = useState([]);
  useEffect(() => {
    if (product === "All") {
      let newHoldings = data.top_holding.sort((a, b) => {
        return b.value - a.value;
      });
      setHoldingsData(newHoldings);
    } else
      setHoldingsData(data.top_holding.filter((item) => item.type === product));
  }, [data, product]);

  const bgGradients = [
    "linear-gradient(223.78deg, #09C3E7 0%, #7669F0 106.87%)",
    "linear-gradient(225deg, #9C2979 0%, #F15E75 100%)",
    "linear-gradient(108.81deg, #FF9F38 -27.65%, #FE5C44 125%)",
    "linear-gradient(108.81deg, #FFD166 -27.65%, #C26F0D 125%)",
  ];
  const assetdata = {
    labels: ["Alternate", "Equity", "Debt"],
    datasets: [
      {
        data: [
          (data.asset_allocation.alternate * 100).toFixed(1),
          (data.asset_allocation.equity * 100).toFixed(1),
          (data.asset_allocation.debt * 100).toFixed(1),
        ],
        backgroundColor: GraphColorPalette,
        borderColor: "#fff",
        borderWidth: 1,
      },
    ],
  };
  const productdata = {
    labels: productKeys,
    datasets: [
      {
        data: productValues,
        backgroundColor: GraphColorPalette,
        borderColor: "#fff",
        borderWidth: 1,
      },
    ],
  };
  const creditdata = {
    labels: creditKeys,
    datasets: [
      {
        data: creditValues,
        backgroundColor: GraphColorPalette,
        borderColor: "#fff",
        borderWidth: 1,
      },
    ],
  };
  const liquiditydata = {
    labels: ["Liquid", "Semi-Liquid", "Illquid"],
    datasets: [
      {
        data: [
          (data.liquidity["Liquid"] * 100).toFixed(1),
          (data.liquidity["Semi-Liquid"] * 100).toFixed(1),
          (data.liquidity["Illiquid"] * 100).toFixed(1),
        ],
        backgroundColor: GraphColorPalette,
        borderColor: "#fff",
        borderWidth: 1,
      },
    ],
  };

  const mcapdata = {
    labels: ["Large Cap", "Mid Cap", "Small Cap"],
    datasets: [
      {
        data: [
          (data.mcap_allocation["Large Cap"] * 100).toFixed(1),
          (data.mcap_allocation["Mid Cap"] * 100).toFixed(1),
          (data.mcap_allocation["Small Cap"] * 100).toFixed(1),
        ],
        backgroundColor: GraphColorPalette,
        borderColor: "#fff",
        borderWidth: 1,
      },
    ],
  };
  // const maturitydata = {
  //   labels: [">7", ">3<=7", ">1<=3", ">0<=1"],
  //   datasets: [
  //     {
  //       label: "Maturity Profile",
  //       backgroundColor: [
  //         "#ED6D85",
  //         "#56A1E5",
  //         "#F7CE6B",
  //         "#F1A354",
  //         "#6CBDBF",
  //         "#FFA3B9",
  //       ],
  //       borderColor: [
  //         "#ED6D85",
  //         "#56A1E5",
  //         "#F7CE6B",
  //         "#F1A354",
  //         "#6CBDBF",
  //         "#FFA3B9",
  //       ],
  //       borderWidth: 1,
  //       barThickness: 20,
  //       data: [6.2, 8.9, 4.0, 3.87],
  //       borderRadius: 10,
  //     },
  //   ],
  // };
  const pieoptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      labels: {
        render: function () {},
        fontColor: "#fff",
        display: "auto",
      },
      datalabels: {
        position: "outside",
        fontFamily: "Inter,sans-serif",
        formatter: function (value) {
          return parseFloat(value).toFixed(1) + "%";
        },
        color: "#fff",
        font: { size: 12, family: "'Inter', sans-serif" },
        display: "auto",
      },
    },
  };
  const doughnutoptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      labels: {
        render: function () {},
        fontColor: "#fff",
        display: "auto",
      },
      datalabels: {
        position: "outside",
        fontFamily: "Inter,sans-serif",
        formatter: function (value) {
          return parseFloat(value).toFixed(1) + "%";
        },
        color: "#fff",
        font: { size: 12, family: "'Inter', sans-serif" },
        display: "auto",
      },
    },
  };
  const mcapoptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      labels: [
        {
          render: function (args) {
            return `${args.value}%`;
          },
          fontColor: "#fff",
        },
      ],
      datalabels: {
        display: false,
      },
    },
  };
  // const horizontalBarOptions = {
  //   maintainAspectRatio: false,
  //   cornerRadius: 20,
  //   legend: {
  //     display: false,
  //   },
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           fontColor: "#fff",
  //           beginAtZero: true,
  //           mirror: true,
  //           padding: -16,
  //           z: 8,
  //         },
  //         gridLines: {
  //           display: false,
  //         },
  //       },
  //     ],
  //     xAxes: [
  //       {
  //         ticks: {
  //           beginAtZero: true,
  //           display: false,
  //         },
  //         gridLines: {
  //           display: false,
  //         },
  //       },
  //     ],
  //   },
  //   plugins: {
  //     datalabels: {
  //       display: true,
  //       color: "#323232",
  //       align: "end",
  //       anchor: "end",
  //       font: { size: "12" },
  //     },
  //   },
  // };
  const liquidityoptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      labels: {
        render: function (args) {
          return `${args.value}%`;
        },

        fontColor: "#fff",
      },
      datalabels: {
        display: false,
      },
    },
  };
  const LegendItem = ({ color, text, ind = 1 }) => {
    return (
      <div className={ind ? "flex ml-[1rem]" : "flex"}>
        <div
          className="w-[7px] h-[7px] block mt-[7px]"
          style={{ backgroundColor: color }}
        />
        <p className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] ml-[0.2rem] mb-[0rem]">
          {text}
        </p>
      </div>
    );
  };
  return (
    <div ref={myref} id="client-dashoard-analytics" className="mt-[2rem]">
      <h3 className="card-heading">Analytics</h3>
      <div className="mt-[1rem]">
        <div className="flex justify-between">
          <div className="w-[32.2%] h-[304px] bg-[#f3f3f3] rounded-[4px] p-[1rem] ">
            <h4 className="font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-4">
              Asset Allocation
            </h4>
            <PieChart
              data={assetdata}
              options={pieoptions}
              width={180}
              height={180}
            />
            <div className="flex justify-center w-full z-[100] mt-[8px]">
              <LegendItem color={"#56A1E5"} text={"Equity"} ind={0} />
              <LegendItem color={"#F7CE6B"} text={"Debt"} />
              <LegendItem color={"#ED6D85"} text={"Alternates"} />
            </div>
          </div>
          <div className="w-[32.2%] rounded-[4px] p-[1rem]  bg-[#f3f3f3] h-[304px]">
            <h4 className="font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-4">
              Product Allocation
            </h4>
            <DoughnutChart
              data={productdata}
              options={doughnutoptions}
              width={180}
              height={180}
            />
            <div className="flex justify-center w-full z-[100] mt-[8px] flex-wrap">
              {productKeys.map((productKeys, index) => (
                <LegendItem
                  key={index}
                  color={GraphColorPalette[index]}
                  text={productKeys}
                  ind={index}
                />
              ))}
            </div>
          </div>
          <div className="w-[32.2%] rounded-[4px] p-[1rem]  bg-[#f3f3f3] h-[304px]">
            <h4 className="font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-4">
              Liquidity
            </h4>
            <PieChart
              data={liquiditydata}
              options={liquidityoptions}
              width={180}
              height={180}
            />
            <div className="flex justify-center w-full z-[100] mt-[8px]">
              <LegendItem color={"#ED6D85"} text={"Liquid"} ind={0} />
              <LegendItem color={"#56A1E5"} text={"Semi-Liquid"} />
              <LegendItem color={"#F7CE6B"} text={"Il-Liquid"} />
            </div>
          </div>
        </div>

        <div className="flex justify-between mt-[1rem]">
          <div className="w-[32.2%] rounded-[4px] p-[1rem] h-[600px] bg-[#f3f3f3] ">
            <div className="flex justify-between">
              <h4 className="font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-0 align-middle">
                Top Holdings
              </h4>
              <div className="w-[40%]">
                <Dropdown
                  options={["All", ...productSet]}
                  selected={product}
                  setSelected={setProduct}
                />
              </div>
            </div>
            {holdingsData.slice(0, 10).map((product, index) => (
              <ProgressBar
                key={index}
                bg={bgGradients[index % bgGradients.length]}
                progress={(product.value * 100).toFixed(1)}
                label={product.label}
              />
            ))}
          </div>
          <div className="flex justify-between w-[66.2%] flex-wrap">
            <div className="w-[48.7%]">
              <div className="w-[100%] rounded-[4px] p-[1rem] bg-[#f3f3f3] h-[274px] ">
                <h4 className="font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-4">
                  Mcap Allocation
                </h4>
                <DoughnutChart
                  data={mcapdata}
                  options={mcapoptions}
                  width={180}
                  height={180}
                />
                <div className="flex justify-center w-full z-[100] mt-[8px]">
                  <LegendItem color={"#ED6D85"} text={"Large Cap"} ind={0} />
                  <LegendItem color={"#56A1E5"} text={"Mid Cap"} />
                  <LegendItem color={"#F7CE6B"} text={"Small Cap"} />
                </div>
              </div>
              <div className="w-full h-[310px] bg-[#f3f3f3] mt-[1rem] rounded-[4px] p-[1rem] ">
                <h4 className="font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-4">
                  Credit Quality
                </h4>
                <PieChart
                  data={creditdata}
                  options={pieoptions}
                  width={180}
                  height={180}
                />
                <div className="flex justify-center w-full z-[100] mt-[8px] flex-wrap">
                  {creditKeys.map((label, index) => (
                    <LegendItem
                      key={index}
                      color={GraphColorPalette[index]}
                      text={label}
                      ind={index}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div className="w-[48.7%] h-[600px] bg-[#f3f3f3] rounded-[4px] p-[1rem] ">
              <h4 className="font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-0">
                Sector Allocation
              </h4>
              {sectorKeys.map((sector, index) => (
                <ProgressBar
                  key={index}
                  bg={bgGradients[index % bgGradients.length]}
                  progress={(sectorValues[index] * 100).toFixed(1)}
                  label={sector}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
