import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import Logo1 from "../assets/img/logo_with_b.png";
import { ClickAwayListener, MenuItem } from "@mui/material";
import "../static/css/existing/dashboard_styles.css";
import * as MdIcons from "react-icons/md";
import { FiSettings, FiMenu } from "react-icons/fi";
import MenuCross from "../assets/img/header_menu_cross_black.svg";
import auth from "../auth";
import DefaultProfilePic from "../assets/img/defaultprofilepic.png";
import { useHistory } from "react-router";
import { SidebarData } from "./sidebarData";
import { useDispatch, useSelector } from "react-redux";
import Submenu from "./Submenu";
import { EmptyUserDetails } from "../actions/actions";
import MobileSubmenu from "./MobileSubMenu";
import InvestedgePopper from "../components/popper/InvestedgePopper";
import { BiLogOut } from "react-icons/bi";
function Header() {
  const pathnames = new Map();
  pathnames.set("Masters", {
    "/clientinput": true,
    "/research": true,
    "/productinput": true,
    "/calculators": true,
    "/modelportfolio": true,
    "/modelportfolio/open": true,
    "/calculator/marriage_expense_planner": true,
    "/calculator/child_education_planner": true,
    "/calculator/retirement_planner": true,
    "/calculator/wealth_creation": true,
    "/calculator/build_your_own_goal": true,
    "/calculator/automated_investment_planner": true,
    "/calculator/automated_switch_planner": true,
    "/calculator/automated_cashflow_planner": true,
  });
  pathnames.set("Product Insights", {
    "/productinsights/screener": true,
    "/productinsights/comparision": true,
    "/productinsights/suitableproducts": true,
  });
  pathnames.set("Settings", {
    "/settings/general": true,
    "/settings/access": true,
    "/settings/module": true,
    "/settings/about": true,
  });
  pathnames.set("Market Insights", {
    "/expertsview": true,
    "/latestmarketevent": true,
    "/faq": true,
    "/marketmodule": true,
  });
  pathnames.set("Portfolio Insights", {
    "/portfolioproposal/create": true,
    "/portfolioproposal/simulate": true,
    "/portfolioproposal/review": true,
    "/portfolioproposal/reviewstepper": true,
    "/portfolioproposal/createpitchbook": true,
  });
  pathnames.set("InvestedgeGPT", {
    "/investgpt/products": true,
    "/investgpt/clients": true,
  });
  pathnames.set("Instant Review", {
    "/instant-review/dashboard": true,
    "/instant-review": true,
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const profilePopper = React.useRef(null);
  const history = useHistory();
  const handleToggle = () => {
    setAnchorEl((prevAnchor) => (prevAnchor ? null : profilePopper.current));
  };
  const handleclose = (event) => {
    setAnchorEl(null);
  };
  const handlecloseLog = (event) => {
    auth.logout(() => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      history.push("/");
    });
    setAnchorEl(null);
    dispatch(EmptyUserDetails());
  };

  const userdata = useSelector((state) => state.userdata);
  const aboutData = useSelector((state) => state.settingsAbout);
  var initials = "";
  if (userdata) {
    var nameArray = userdata?.username?.split(" ") || [];
    for (var i = 0; i < nameArray.length; i++) {
      if (i <= 1) {
        initials = initials + nameArray[i][0];
      }
    }
  }
  const [sidenavbar, setSidenavbar] = useState(false);
  return (
    <>
      <header
        className="h-[60px] sticky top-0 z-[99] bg-white"
        style={{ filter: "drop-shadow(0px 8px 16px rgba(3, 78, 242, 0.08))" }}
      >
        <div id="headerdashboard" className="flex mx-auto max-w-[1480px]">
          <div className="logo ">
            <Link to="/investgpt/markets">
              <img
                style={{
                  height: "39px",
                  marginTop: "auto",
                  marginBottom: "auto",
                  objectFit: "contain",
                }}
                src={aboutData.logo}
                alt=""
                className="img-fluid"
              />
            </Link>
          </div>
          <div className="flex mr-auto justify-evenly items-end sticky font-inter transition-all duration-300 h-full z-50 ml-[2rem]">
            {SidebarData.map((item, index) => {
              return (
                <Submenu
                  item={item}
                  pathnames={pathnames}
                  key={index}
                  userdata={userdata}
                />
              );
            })}
          </div>
          <nav className="dashboard-nav-menu flex justify-end">
            <ul>
              <li
                className="lg:hidden flex justify-center items-center"
                onClick={() => setSidenavbar(true)}
              >
                <FiMenu
                  size={20}
                  className="w-[20px] cursor-pointer mx-[8px]"
                />
              </li>
              <li className="flex justify-center items-center hover:-rotate-12 hover:-translate-x-0.5 transition-all hover:text-primary-200">
                <Link to="/settings/general" className="hover:text-primary-200">
                  <FiSettings
                    size={18}
                    color={
                      pathnames
                        .get("Settings")
                        .hasOwnProperty(location.pathname)
                        ? "#1982F8"
                        : "#3D3D3D"
                    }
                    className="w-[20px] cursor-pointer mx-[8px]"
                  />
                </Link>
              </li>

              <li className="flex justify-center items-center">
                <img
                  src={
                    userdata.profile_pic
                      ? userdata.profile_pic
                      : DefaultProfilePic
                  }
                  alt="err"
                  className="w-[35px] h-[35px] rounded-full mx-[8px]"
                />
              </li>
              <li className="flex justify-center items-center">
                <div
                  ref={profilePopper}
                  onClick={handleToggle}
                  className="mypopoverarrow"
                  aria-describedby="header-profile-popper"
                >
                  <MdIcons.MdKeyboardArrowDown size={25} />
                </div>

                <InvestedgePopper
                  id="header-profile-popper"
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  placement="bottom-end"
                  className="z-[100]"
                  offset={[0, 20]}
                  arrow="true"
                >
                  <ClickAwayListener onClickAway={handleclose}>
                    <div>
                      <div className="w-[200px] p-[1rem] pb-2">
                        <img
                          src={
                            userdata.profile_pic
                              ? userdata.profile_pic
                              : DefaultProfilePic
                          }
                          alt="err"
                          className="w-[50px] h-[50px] rounded-full mx-auto"
                        />

                        <div className="font-inter text-sm text-neutralBlack mt-[10px] font-medium text-center">
                          {userdata.username}
                        </div>
                      </div>
                      <div className="border-t border-[#f5f5f5]">
                        <MenuItem
                          onClick={handlecloseLog}
                          className=" border-t border-[#f5f5f5]"
                        >
                          <Link
                            className="cursor-pointer flex items-center space-x-[10px]"
                            to="/"
                          >
                            <BiLogOut
                              color="#3d3d3d"
                              className="w-[20px] h-[20px]"
                            />
                            <span className="font-inter text-sm text-[#3d3d3d]">
                              Logout
                            </span>
                          </Link>
                        </MenuItem>
                      </div>
                    </div>
                  </ClickAwayListener>
                </InvestedgePopper>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <div
        className={
          sidenavbar
            ? "lg:hidden duration-300 bg-white w-[100vw] h-[100%] fixed top-0 z-[100] right-0 p-8 overflow-hidden"
            : "lg:hidden bg-white w-[100%] h-[100%] fixed top-0 right-[-100%] duration-700 z-[100] p-8"
        }
      >
        <div className="py-[20px] flex justify-between items-center border-b border-b-[#3D3D3D] divide-[#3D3D3D]">
          <p className="font-poppins font-normal text-[#3D3D3D] mb-0">Menu</p>
          <img src={MenuCross} alt="err" onClick={() => setSidenavbar(false)} />
        </div>
        <div className="mt-6">
          {SidebarData.map((item, index) => {
            return (
              <MobileSubmenu
                item={item}
                pathnames={pathnames}
                key={index}
                userdata={userdata}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Header;
