import React from "react";
import Cloud from "../../../assets/img/bluecloud.svg";
import Lock from "../../../assets/img/lock_locked_blue.svg";
import { useDispatch } from "react-redux";
import { OpenSnackbar } from "../../../actions/actions";
export default function UploadStatementCards({
  handleCardClick,
  label,
  locked,
}) {
  const dispatch = useDispatch();
  return (
    <div
      onClick={(e) => {
        !locked
          ? handleCardClick(e, label)
          : dispatch(
              OpenSnackbar({
                severity: "info",
                message: "Feature will be coming soon",
              })
            );
      }}
      className="upload-statement-card relative"
    >
      {locked && (
        <img src={Lock} alt="err" className="absolute top-[8px] right-[8px]" />
      )}
      <img
        className="w-[25px] h-[25px] opacity-[0.9px]"
        src={Cloud}
        alt="err"
      />
      <p className="text-center text-[14px] mb-0">
        {`Upload ${label} statement`}
      </p>
    </div>
  );
}
