import React, { useState } from "react";
import parse from "html-react-parser";
import { useDispatch } from "react-redux";
import { PostEventLoggingData } from "../../../actions/actions";
import MarketFaqModel from "./MarketFaqModel";
export default function MarketFaq({ data, index }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  return (
    <>
      <MarketFaqModel data={data} open={open} onClose={() => setOpen(false)} />
      <div
        key={data.id}
        style={
          index === 0
            ? {
                padding: "1rem",
                borderRadius: "10px",
              }
            : {
                padding: "1rem",
                marginTop: "1rem",
                borderRadius: "10px",
              }
        }
        className="border border-globalBorderColor"
      >
        <h5 className="text-[16px] font-medium font-inter leading-[20px] tracking-[0em] text-[#000] mb-[1rem]">
          {data.question}
        </h5>

        <h2 className="text-sm mr-3 font-inter  font-normal leading-[20px] tracking-[0em] text-left mb-0">
          {parse(data.answer.slice(0, 450))}
        </h2>
        <p
          className="text-[13px] font-inter text-primary-200 cursor-pointer inline-block font-normal leading-[16px] tracking-[0em] text-left"
          onClick={() => {
            setOpen(true);
            dispatch(
              PostEventLoggingData({
                module_name: "Market Insights",
                event_type: "Information",
                url: window.location.href,
                note: `${data.question} viewed`,
              })
            );
          }}
        >
          Read more
        </p>
        <div className="flex flex-row gap-4 mt-3 mb-2">
          {Object.keys(data.tags).map((e) => (
            <div
              key={data.tags[e]}
              className="pl-3 pr-3 text-[12px] font-inter font-normal leading-[23px] bg-[#EDF5FE] text-primary-300 rounded-[4px] "
            >
              {data.tags[e]}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
