import { GET_RESEARCH } from "../actions/type";
const initialState = [
  {
    id: null,
    published_date: null,
    equity_score: 0,
    debt_score: 0,
    alternate_score: 0,
    largecap_score: 0,
    midcap_score: 0,
    small_cap_score: 0,
    credit_score: 0,
    duration_score: 0,
    alternate_volatility_score: 0,
    is_active: 0,
    org_id: 0,
  },
];
export default function GetResearch(state = initialState, action) {
  switch (action.type) {
    case GET_RESEARCH:
      return action.payload;
    default:
      return state;
  }
}
