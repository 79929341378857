import React from "react";
import { IntroductionData } from "../onePagerConsts";
import "../../../static/css/existing/dashboard_styles.css";

export default function introductionComponent() {
  return (
    <>
      <div
        style={{
          padding: "1rem",
          backgroundColor: "#fff",
          borderRadius: "6px",
          boxShadow:
            " 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(0, 0, 0, 0.05)",
          minheight: "274px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h3 className="portfolio-proposal-type-card-heading">
              DSP Flexi Cap Fund
            </h3>
            <p
              className="dashboard-body-product-details-tag"
              style={{ marginTop: "0px", marginRight: "1rem" }}
            >
              Equity mutual fund
            </p>
            <p
              className="dashboard-body-product-details-tag"
              style={{ marginTop: "0px" }}
            >
              Flexi Cap
            </p>
          </div>

          <div
            style={{
              justifyContent: "space-around",
              display: "flex",
              textAlign: "center",
              width: "70%",
            }}
          >
            {IntroductionData.map((elem) => {
              return <p>{elem.category}</p>;
            })}

            <div style={{ textAlign: "center" }}>
              <h3 className="portfolio-proposal-type-card-heading">
                ₹ 12,253 cr
              </h3>
              <p
                className="mb-0 text-muted text-xs"
                style={{ color: "#939393", fontFamily: "Inter , sans-serif" }}
              >
                AUM
              </p>
            </div>

            <div style={{ textAlign: "center" }}>
              <h3 className="portfolio-proposal-type-card-heading">
                ₹ 69.8234
              </h3>
              <p
                className="mb-0 text-muted text-xs"
                style={{ color: "#939393", fontFamily: "Inter , sans-serif" }}
              >
                EXPENSE RATIO
              </p>
            </div>

            <div style={{ textAlign: "center" }}>
              <h3 className="portfolio-proposal-type-card-heading">23.14%</h3>
              <p
                className="mb-0 text-muted text-xs"
                style={{ color: "#939393", fontFamily: "Inter , sans-serif" }}
              >
                3Y CAGR
              </p>
            </div>

            <div style={{ textAlign: "center" }}>
              <h3 className="portfolio-proposal-type-card-heading">1/5</h3>
              <p
                className="mb-0 text-muted text-xs"
                style={{ color: "#939393", fontFamily: "Inter , sans-serif" }}
              >
                Rating
              </p>
            </div>

            <div style={{ textAlign: "center" }}>
              <h3 className="portfolio-proposal-type-card-heading">5</h3>
              <p
                className="mb-0 text-muted text-xs"
                style={{ color: "#939393", fontFamily: "Inter , sans-serif" }}
              >
                Strategy
              </p>
            </div>

            <div style={{ textAlign: "center" }}>
              <h3
                className="portfolio-proposal-type-card-heading"
                style={{ fontFamily: "Inter , sans-serif" }}
              >
                All
              </h3>
              <p
                className="mb-0 text-muted text-xs"
                style={{ color: "#939393", fontFamily: "Inter , sans-serif" }}
              >
                Category
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
