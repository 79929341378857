import React, { useRef, useState } from "react";
import InvestedgeToggleButtonGroup from "../../components/toggle/InvestedgeToggle";
import Add from "../../assets/img/commisionsadd.svg";
import Subtract from "../../assets/img/commisionssubtract.svg";
import { Checkbox, FormControlLabel, Rating } from "@mui/material";
import { Star } from "@mui/icons-material";
import GreenRationale from "../../assets/img/rationale_type_green.svg";
import GreyRationale from "../../assets/img/rationale_type_grey.svg";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import InvestedgePopper from "../../components/popper/InvestedgePopper";
import Tick from "../../assets/img/confirm_tick.svg";
import { OpenSnackbar } from "../../actions/actions";
import { useDispatch } from "react-redux";

export default function ProductInputRow({
  pid,
  handleCommissionChange,
  handleCustomCommissionChange,
  tabs,
  handleRecommendationChange,
  handleChangeRow,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [view, setView] = useState(`${pid.product_view}`);
  const popperRef = useRef(null);
  const dispatch = useDispatch();
  return (
    <tr className="min-h-[72px] border-b border-[#e0dddd]">
      <td
        className="dashboard-body-second-card-table-data leading-5"
        style={{
          paddingLeft: "1rem",
          textAlign: "left",
        }}
      >
        <div>{pid.name}</div>
        <FormControlLabel
          control={
            <Checkbox
              checked={pid.is_popular}
              size="small"
              onChange={(e) => {
                handleChangeRow(pid.product_id, e.target.checked, "is_popular");
              }}
            />
          }
          label={
            <span className="font-inter text-xs text-primary-200">Popular</span>
          }
        />
      </td>
      <td className="dashboard-body-second-card-table-data">
        {`${pid.asset_type} - ${pid.product_type}`}
      </td>
      <td className="dashboard-body-second-card-table-data leading-5">
        {pid.category}
      </td>
      <td>
        <div className="flex justify-center">
          <img
            src={Subtract}
            alt="err"
            style={{ marginRight: "5%" }}
            onClick={() => handleCommissionChange(pid.product_id, -5.0)}
          />
          <input
            className="w-[30%] h-[30px] text-center font-inter shadow-none bg-inputFieldBg text-sm border-none text-neutralBlack"
            value={pid.commissions}
            onChange={(e) => {
              const re = /^\d*[0-9]+\d*$/;
              if (e.target.value === "" || re.test(e.target.value))
                handleCustomCommissionChange(
                  pid.product_id,
                  parseInt(e.target.value)
                );
            }}
          />
          <img
            src={Add}
            alt="err"
            className="ml-[5%]"
            onClick={() => handleCommissionChange(pid.product_id, 5.0)}
          />
        </div>
      </td>
      <td className="flex flex-row justify-center items-center h-full min-h-[72px]">
        <InvestedgeToggleButtonGroup
          tabs={tabs}
          value={pid.recommendation}
          onChange={(e, val) => handleRecommendationChange(pid.product_id, val)}
          exclusive
        />
        {/* <div
                className="font-inter text-[14px] font-medium tracking-[-0.009em] text-center w-[30%] py-[0.25rem] px-[0rem] cursor-pointer"
                style={
                  pid.recommendation === "Buy"
                    ? {
                        borderRadius: "10px 0 0 10px",
                        backgroundColor: "#1982F8",
                        color: "white",
                      }
                    : {
                        borderRadius: "10px 0 0 10px",
                        border: "1px solid #999999",
                      }
                }
                onClick={() =>
                  handleRecommendationChange(pid.product_id, "Buy")
                }
              >
                Buy
              </div>
              <div
                className="font-inter text-[14px] font-medium tracking-[-0.009em] text-center w-[30%] py-[0.25rem] px-[0rem] cursor-pointer"
                style={
                  pid.recommendation === "Hold"
                    ? {
                        backgroundColor: "#1982F8",
                        color: "white",
                      }
                    : { border: "1px solid #999999" }
                }
                onClick={() =>
                  handleRecommendationChange(pid.product_id, "Hold")
                }
              >
                Hold
              </div>
              <div
                className="font-inter text-[14px] font-medium tracking-[-0.009em] text-center w-[30%] py-[0.25rem] px-[0rem] cursor-pointer"
                style={
                  pid.recommendation === "Sell"
                    ? {
                        backgroundColor: "#1982F8",
                        color: "white",
                        borderRadius: "0 10px 10px 0",
                      }
                    : {
                        borderRadius: "0 10px 10px 0",
                        border: "1px solid #999999",
                      }
                }
                onClick={() =>
                  handleRecommendationChange(pid.product_id, "Sell")
                }
              >
                Sell
              </div> */}
      </td>
      <td className="">
        <Rating
          name="simple-controlled"
          value={pid.product_rating}
          onChange={(e, value) => {
            handleChangeRow(pid.product_id, value, "product_rating");
          }}
          icon={<Star fontSize="inherit" color="primary" />}
        />
      </td>
      <td className="flex flex-row justify-center items-center h-full min-h-[72px]">
        <img
          src={pid.product_view ? GreenRationale : GreyRationale}
          alt="err"
          ref={popperRef}
          onClick={(e) =>
            setAnchorEl((prevAnchor) => (prevAnchor ? null : popperRef.current))
          }
        />
      </td>
      <InvestedgePopper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="right-start"
        className="z-[100]"
        offset={[0, 20]}
        arrow="true"
      >
        <div className="bg-[#fff] p-[16px] w-[350px]">
          <div className="w-full flex justify-between">
            <h2 className="text-xl font-medium font-inter">Rationale</h2>
            <button
              type="button"
              className="close"
              onClick={() => setAnchorEl(null)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <textarea
            className="w-full mt-[16px] text-sm p-[16px] border border-[#e5e5e5] bg-[#fafafa] h-[130px] rounded"
            value={view}
            onChange={(e) =>
              e.target.value.length > 200
                ? dispatch(
                    OpenSnackbar({
                      severity: "info",
                      message:
                        "Comments can have maximum length of 200 characters",
                    })
                  )
                : setView(e.target.value)
            }
          />
          <div className="w-full flex justify-end mt-4">
            <InvestedgeButton
              onClick={() => {
                handleChangeRow(pid.product_id, view, "product_view");
                setAnchorEl(null);
              }}
            >
              <img
                className="w-[14px] object-contain mr-[0.25rem]"
                src={Tick}
                alt="err"
              />
              Confirm
            </InvestedgeButton>
          </div>
        </div>
      </InvestedgePopper>
    </tr>
  );
}
