import { GET_MARKET_EVENTS } from "../actions/type";
const initialState = [];
export default function marketEventsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MARKET_EVENTS:
      return action.payload;
    default:
      return state;
  }
}
