import React from "react";
import AfterloginApp from "../../AfterloginApp";
import { useState } from "react";
import CalculatorBanner from "../CalculatorBanner";
import BannerImage from "../../../assets/img/wealth_creation_banner.svg";
import { Line } from "react-chartjs-2";
import axios from "axios";
import urls from "../../../urls";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import { InputAdornment, OutlinedInput } from "@mui/material";
import DropdownForAttributes from "../../../components/Dropdowns/DropdownForAttributes";
import InvestedgeTextField from "../../../components/textfield/InvestedgeTextField";
export default function WealthCreation() {
  const [planningData, setPlanningData] = useState({
    moneyRequiredDenotion: "₹ L",
    moneyRequired: "10",
    time: "5",
    currentInvestmentsDenotion: "₹ L",
    currentInvestments: "5",
    returns: "8",
  });
  const numToMonthMap = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };
  const dateParser = (date) => {
    var dd = String(date.getDate()).padStart(2, "0");
    var mm = String(date.getMonth() + 1).padStart(2, "0");
    var yyyy = date.getFullYear();
    return dd + " " + numToMonthMap[parseInt(mm)].slice(0, 3) + " " + yyyy;
  };
  const denotionParser = (denotion, value) => {
    if (denotion[2] === "K") return parseInt(value) * 1000;
    else if (denotion[2] === "L") return parseInt(value) * 100000;
    else return value * 10000000;
  };
  const [backendData, setBackendData] = useState({
    future_value_of_current_investments: 0.0,
    future_value_of_current_investments_date: "12-2023",
    monthly_investment_required: 0.0,
    monthly_investment_required_date: "12-2023",
    graph: {
      dates: [],
      value1: {
        label: "Total Amount Invested",
        values: [],
      },
      value2: {
        label: "Expected Investment Value",
        values: [],
      },
      value3: {
        label: "Value of Existing Investment",
        values: [],
      },
    },
  });
  const handleCalculate = () => {
    axios
      .post(urls.wealthCreationURL, {
        corpus_to_be_created: denotionParser(
          planningData.moneyRequiredDenotion,
          planningData.moneyRequired
        ),
        time_availible_to_create_wealth: parseInt(planningData.time),
        current_investments_set_aside: denotionParser(
          planningData.currentInvestmentsDenotion,
          planningData.currentInvestments
        ),
        expected_return: parseFloat(planningData.returns) / 100,
      })
      .then((response) => setBackendData(response.data))
      .catch(() =>
        setBackendData({
          future_value_of_current_investments: 0.0,
          future_value_of_amount_required_date: "12-2023",
          future_value_of_amount_required: 0.0,
          future_value_of_current_investments_date: "12-2023",
          monthly_investment_required: 0.0,
          monthly_investment_required_date: "12-2023",
          graph: {
            dates: [],
            value1: {
              label: "Total Amount Invested",
              values: [],
            },
            value2: {
              label: "Expected Investment Value",
              values: [],
            },
            value3: {
              label: "Value of Existing Investment",
              values: [],
            },
          },
        })
      );
  };
  const data = {
    backgroundColor: "#fafafa",
    labels: backendData.graph.dates,
    datasets: [
      {
        label: "Total Amount Invested",
        //data: backendData.graph.value3.values,
        data: backendData.graph.value1.values,
        fill: false,
        spanGaps: true,
        borderColor: "#1982f8",
        tension: 0.1,
        opacity: "0.3",
        borderWidth: 3,
      },
      {
        label: "Expected Investment Value ",
        data: backendData.graph.value2.values.map(function (num, idx) {
          return num + backendData.graph.value3.values[idx];
        }),
        // data: backendData.graph.value2.values,
        borderColor: "#34D399",
        borderWidth: 3,
        tension: 0.1,
        opacity: "0.3",
        fill: true,
        spanGaps: true,
      },
    ],
  };
  const options = {
    animation: {
      duration: 1000,
    },

    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            autoSkip: true,
            maxTicksLimit: 7,
            maxRotation: 0,
            fontSize: 10,
            callback: (val) => dateParser(new Date(val)),
          },
          gridLines: {
            color: "white",
          },
        },
      ],
      yAxes: [
        {
          stepSize: 100,
          type: "linear",
          display: true,
          gridLines: {
            color: "white",
          },
          ticks: {
            callback: (val) => val.toLocaleString("en-IN"),
          },
        },
      ],
    },
    elements: {
      bar: {
        borderWidth: 3,
      },
      point: {
        radius: 0,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: false,
    },
    plugins: {
      title: {
        display: true,
      },
      labels: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      points: {
        display: false,
      },

      interaction: {
        mode: "index",
        intersect: false,
      },
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem, data) =>
          dateParser(new Date(data.labels[tooltipItem[0].index])),
      },
    },
  };
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Product Input"}
        description={
          "Change the view on different products across asset class, product type, and categories"
        }
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className="main-panel h-full">
            <div>
              <div className="h-[158px]">
                <CalculatorBanner
                  heading={"Wealth Creation"}
                  description={
                    "Help your client in identifying the investment requirements for creating wealth using this calculator."
                  }
                  image={BannerImage}
                />
              </div>
              <div className="portfolio-proposal-body-card min-h-[74%] flex justify-between mt-[20px] border-none shadow-none">
                <div className="w-[49%] ">
                  <div className="w-full flex justify-between items-center mt-[0.5rem]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">Corpus to be Created</p>
                    </div>

                    <div className="w-[30%] flex justify-between">
                      <div className="w-[40%]">
                        <DropdownForAttributes
                          labelName={null}
                          options={["₹ L", "₹ K", "₹ C"]}
                          selected={planningData}
                          setSelected={setPlanningData}
                          attribute="moneyRequiredDenotion"
                        />
                      </div>
                      <div className="w-[58%]">
                        <InvestedgeTextField
                          variant="outlined"
                          fullWidth
                          style={{
                            fontFamily: "Inter,sans-sarif",
                            fontWeight: 400,
                          }}
                          label={null}
                          value={planningData.moneyRequired}
                          onChange={(e) => {
                            const re = /^\d*[0-9]+\d*$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            )
                              setPlanningData({
                                ...planningData,
                                moneyRequired: e.target.value,
                              });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex justify-between items-center mt-[20px]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">
                        Time Available to Create Wealth
                      </p>
                    </div>
                    <OutlinedInput
                      size="small"
                      className="text-sm"
                      required
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "17.4%",
                      }}
                      value={planningData.time}
                      onChange={(e) => {
                        const re = /^\d*[0-9]+\d*$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setPlanningData({
                            ...planningData,
                            time: e.target.value,
                          });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <span className="text-sm font-inter">yrs</span>
                        </InputAdornment>
                      }
                    />
                  </div>
                  <div className="w-full flex justify-between items-center mt-[20px]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">
                        Current Investments set aside for the Wealth
                      </p>
                    </div>

                    <div className="w-[30%] flex justify-between">
                      <div className="w-[40%]">
                        <DropdownForAttributes
                          labelName={null}
                          options={["₹ L", "₹ K", "₹ C"]}
                          selected={planningData}
                          setSelected={setPlanningData}
                          attribute="currentInvestmentsDenotion"
                        />
                      </div>
                      <div className="w-[58%]">
                        <InvestedgeTextField
                          variant="outlined"
                          fullWidth
                          style={{
                            fontFamily: "Inter,sans-sarif",
                            fontWeight: 400,
                          }}
                          label={null}
                          value={planningData.currentInvestments}
                          onChange={(e) => {
                            const re = /^\d*[0-9]+\d*$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            )
                              setPlanningData({
                                ...planningData,
                                currentInvestments: e.target.value,
                              });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex justify-between items-center mt-[20px]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">Expected Return</p>
                    </div>
                    <OutlinedInput
                      size="small"
                      className="text-sm"
                      required
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "17.4%",
                      }}
                      value={planningData.returns}
                      onChange={(e) => {
                        const re = /^\d*\.?\d*$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setPlanningData({
                            ...planningData,
                            returns: e.target.value,
                          });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <span className="text-sm font-inter">%</span>
                        </InputAdornment>
                      }
                    />
                  </div>
                  <InvestedgeButton
                    onClick={handleCalculate}
                    className="mt-8"
                    disabled={
                      !planningData.moneyRequiredDenotion.length ||
                      !planningData.moneyRequired.length ||
                      !planningData.time.length ||
                      !planningData.currentInvestmentsDenotion.length ||
                      !planningData.currentInvestments.length ||
                      !planningData.returns.length
                    }
                  >
                    Calculate
                  </InvestedgeButton>
                </div>
                <div className="w-[45%] pl-[1rem]">
                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      <p className="font-inter text-[#6a6a76] mb-[4px]">
                        Investment Required
                      </p>
                      <p className="font-inter font-semibold text-[24px] text-success-200 mb-[4px]">
                        {`₹ ${parseInt(
                          backendData.monthly_investment_required
                        ).toLocaleString("en-IN")}`}
                      </p>
                      <p className="font-inter font-medium text-[#3D3D3D] mb-[4px]">
                        Monthly
                      </p>
                    </div>
                    <div>
                      <p className="font-inter text-[#6a6a76] mb-[4px]">
                        Current Investments
                      </p>
                      <p className="font-inter font-semibold text-[24px] text-[#3D3D3D] mb-[4px]">
                        {`₹ ${parseInt(
                          backendData.future_value_of_current_investments
                        ).toLocaleString("en-IN")}`}
                      </p>
                      <p className="font-inter font-medium text-[#3D3D3D] mb-[4px]">
                        {`in ${
                          numToMonthMap[
                            backendData.future_value_of_current_investments_date.split(
                              "-"
                            )[0]
                          ]
                        } ${
                          backendData.future_value_of_current_investments_date.split(
                            "-"
                          )[1]
                        }`}
                      </p>
                    </div>
                  </div>
                  <div className="w-[95%] bg-[#fafafa] p-[16px] mt-[16px]">
                    <Line data={data} options={options} height={270} />
                    <div className="flex justify-center w-full mt-[12px]">
                      <div className="flex items-center ml-[16px]">
                        <div className="bg-primary-200 rounded w-[14px] h-[14px]" />
                        <h2 className="text-xs ml-[8px] mb-0">
                          Total Amount Invested
                        </h2>
                      </div>
                      <div className="flex items-center ml-[16px]">
                        <div className="bg-success-200 rounded w-[14px] h-[14px]" />
                        <h2 className="text-xs ml-[8px] mb-0">
                          Expected Investment Value
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
