import React, { useRef, useState } from "react";
import Menu from "../../assets/img/three_dots_menu.svg";
import View from "../../assets/img/view_portfolio.svg";
import Edit from "../../assets/img/edit_portfolio.svg";
import Delete from "../../assets/img/delete_portfolio.svg";
import Tag from "../../assets/img/tag_goal.svg";
import DefaultPortfolioPic from "../../assets/img/defaultGoal.svg";
import InvestedgePopper from "../../components/popper/InvestedgePopper";
import { MenuItem } from "@mui/material";
import DeletePortfolioModal from "./DeletePortfolioModal";
import ProgressBar from "./ProgressBar";
import TagInvestmentIndex from "./TagInvestments/TagInvestmentIndex";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  GetDashboardPortfolioList,
  GetGoalDetails,
  GetGoalsList,
  GetReviewPortfolioSummary,
} from "../../actions/actions";
export default function GoalCard({ goal }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tagsOpen, setTagsOpen] = useState(false);
  const [deletePortfolio, setDeletePortfolio] = useState(false);
  const dispatch = useDispatch();
  const popperRef = useRef(null);
  return (
    <div
      className="rounded-[4px] bg-gradient-180 from-[#fafafa] to-[#f6f6f6] p-[1rem] min-h-[155px] z-0  w-full shadow-none m-0 duration-300 relative"
      onMouseLeave={() => setAnchorEl(null)}
    >
      <div className="flex justify-end items-start">
        <div className="flex justify-center items-center w-[97%] flex-col">
          <img
            className="h-[60px] w-[60px] rounded-[100%] mt-2"
            src={DefaultPortfolioPic}
            alt="err"
          />
          <p className="font-inter w-full text-sm not-italic leading-[19px] mb-0 tracking-[0em] text-center text-neutralBlack mt-[1rem]">
            {goal.goal_title}
          </p>
          <ProgressBar progress={goal.goal_percent_attained * 100} />
        </div>
        <img
          src={Menu}
          alt="err"
          onClick={() =>
            setAnchorEl((prevAnchor) => (prevAnchor ? null : popperRef.current))
          }
          className="absolute top-4 right-4"
          ref={popperRef}
          aria-describedby="existing-goal-popper"
        />

        <InvestedgePopper
          id="existing-goal-popper"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="bottom-end"
          className="z-[100]"
          offset={[0, 20]}
          arrow="true"
        >
          <Link
            to={{
              pathname: "/goalplanning/view",
              state: {
                goal: goal,
              },
            }}
          >
            <MenuItem
              className="flex"
              key={0}
              onClick={() => {
                dispatch(
                  GetGoalDetails(goal.id, {
                    params: {
                      client_id: localStorage.getItem("clientId"),
                    },
                  })
                );
                setAnchorEl(null);
              }}
            >
              <img src={View} alt="err" className="mr-4" />
              View Goal
            </MenuItem>
          </Link>
          <MenuItem
            key={1}
            className="flex"
            onClick={() => {
              setAnchorEl(null);
              setTagsOpen(true);
            }}
          >
            <img src={Tag} alt="err" className="mr-4" />
            Tag investments
          </MenuItem>
          <Link
            to={{
              pathname: "/goalplanning/create",
              state: {
                goal: goal,
              },
            }}
          >
            <MenuItem
              key={3}
              className="flex"
              onClick={() => {
                setAnchorEl(null);
              }}
            >
              <img src={Edit} alt="err" className="mr-4" />
              Edit Goal
            </MenuItem>
          </Link>

          <MenuItem
            key={4}
            className="flex"
            onClick={() => {
              setAnchorEl(null);
              setDeletePortfolio(true);
            }}
          >
            <img src={Delete} alt="err" className="mr-4" /> Delete Goal
          </MenuItem>
        </InvestedgePopper>
        <DeletePortfolioModal
          open={deletePortfolio}
          onClose={() => setDeletePortfolio(false)}
          goal={goal}
        />
        <TagInvestmentIndex
          open={tagsOpen}
          id={goal.id}
          name={goal.goal_title}
          onClose={() => {
            dispatch(
              GetGoalsList({
                params: {
                  client_id: localStorage.getItem("clientId"),
                },
              })
            );
            dispatch(
              GetDashboardPortfolioList({
                params: {
                  client_id: localStorage.getItem("clientId"),
                },
              })
            );
            dispatch(
              GetReviewPortfolioSummary({
                params: {
                  client_id: localStorage.getItem("clientId"),
                },
              })
            );
            setTagsOpen(false);
          }}
        />
      </div>
    </div>
  );
}
