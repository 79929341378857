import { GET_SETTINGS_TEAMLEAD } from "../actions/type";
const initialState = [];
export default function GetTeamLead(state = initialState, action) {
  switch (action.type) {
    case GET_SETTINGS_TEAMLEAD:
      return action.payload;
    default:
      return state;
  }
}
