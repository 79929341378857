import { GET_REVIEW_PORTFOLIO_ACTIONABLE } from "../actions/type";
const initialState = {
  initial_portfolio: {
    "Mutual Fund": [],
    Cash: [],
  },
  final_portfolio: {
    "Mutual Fund": [],
  },
  actions: [],
  frontend_table: [],
  analytics: {
    compare: {
      asset_allocation_before: {
        Equity: 0.909,
        Debt: 0.091,
        Alternate: 0,
      },
      asset_allocation_after: {
        Equity: 0.61,
        Debt: 0.351,
        Alternate: 0.039,
      },
      overall_product_allocation_before: {
        "Mutual Fund": 1.0,
      },
      overall_product_allocation_after: {
        "Mutual Fund": 1.0,
      },
      overall_prod_count_before: 25,
      overall_prod_count_after: 16,
      overall_liquidity_before: {
        Liquid: 1.0,
        "Semi-Liquid": 0,
        Illiquid: 0,
      },
      overall_liquidity_after: {
        Liquid: 1.0000000000000002,
        "Semi-Liquid": 0,
        Illiquid: 0,
      },
      ovarall_cagr_before: 0.1390016654470913,
      ovarall_cagr_after: 0.10797876333068812,
      bm_blended_returns_before: 0.116184968,
      bm_blended_returns_after: 0.10297305500000001,
      mkt_cap_allocation_before: {
        "Large Cap": [0.7305818365075817, 0.7768197345124772],
        "Mid Cap": [0.1778322451625022, 0.14793277839108115],
        "Small Cap": [0.09158591832991604, 0.07524748709644165],
      },
      mkt_cap_allocation_after: {
        "Large Cap": [0.8073528773326747, 0.7768197345124772],
        "Mid Cap": [0.12885762653618338, 0.14793277839108115],
        "Small Cap": [0.06378949613114189, 0.07524748709644165],
      },
      rating_allocation_before: [
        ["AA", "AAA", "Cash", "SOV"],
        [
          0.013934047867509902, 0.6384440267601342, 0.19264196172856718,
          0.1549799636437887,
        ],
      ],
      rating_allocation_after: [
        ["AA", "AAA", "Cash", "SOV"],
        [
          0.16085644713288286, 0.5993623531829784, 0.06906329848779424,
          0.17071790119634453,
        ],
      ],
      top5_sector_before: {
        Financials: 0.32138986068386044,
        "Information Technology": 0.1550274468370718,
        "Consumer Discretionary": 0.14025637779459202,
        Materials: 0.09678051314362708,
        "Consumer Staples": 0.06848736965507304,
      },
      top5_sector_after: {
        Financials: 0.318675105903597,
        "Information Technology": 0.1688281126915019,
        "Consumer Discretionary": 0.12810998566028944,
        Materials: 0.0911147891458336,
        "Consumer Staples": 0.07500903343811668,
      },
      top5_stocks_before: {
        "ICICI Bank Ltd.": 0.05536056917662413,
        "HDFC Bank Ltd.": 0.04859720049789165,
        "Infosys Ltd.": 0.04070172851954362,
        "ITC Ltd.": 0.034309833173017286,
        "Housing Development Finance Corporation Ltd.": 0.0337739184929343,
      },
      top5_stocks_after: {
        "ICICI Bank Ltd.": 0.05486024828765781,
        "HDFC Bank Ltd.": 0.05271204487647018,
        "Infosys Ltd.": 0.04895301632205761,
        "Tata Consultancy Services Ltd.": 0.03931697298412578,
        "Reliance Industries Ltd.": 0.03596234306247932,
      },
      debt_quants_before: {
        duration: 0.1711075995890459,
        gross_ytm: 0.04818438487065824,
        maturity: 0.25480454860471874,
      },
      debt_quants_after: {
        duration: 0.5480806603081236,
        gross_ytm: 0.06115945590798005,
        maturity: 0.9549785382388535,
      },
      debt_top_5_sec_before: {
        "91 Days Treasury Bill - 09-Jun-2022": 0.06618537219280797,
        "91 Days Treasury Bill - 23-Jul-2022": 0.05553111030457004,
        "HDFC Bank Ltd. (29-Jun-22)": 0.0471505261862897,
        "Reliance Retail Ventures Ltd. -91D (10-Jun-22)": 0.04448189030605777,
        "Tata Steel Ltd. -91D (28-Jun-22)": 0.0443751448708485,
      },
      debt_top_5_sec_after: {
        "GOI FRB 22-Sep-2033": 0.028204489455873196,
        "08.92% Madhya Pradesh SDL - 8-Aug-2022": 0.028130034085172635,
        "06.84% GOI - 19-Dec-2022": 0.024409961443531643,
        "Axis Bank Ltd. (11-Nov-22)": 0.022790596689214085,
        "Shriram Transport Finance Company Ltd. SR-K 03 OP 2 (16-Jun-2023)": 0.02152342982436519,
      },
    },
  },
  portfolio_value: "",
};
export default function GetReviewPortfolioActionable(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_REVIEW_PORTFOLIO_ACTIONABLE:
      return action.payload;
    default:
      return state;
  }
}
