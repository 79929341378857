import React, { useState, useEffect } from "react";
import InvestedgeLogo from "../assets/img/investedge_header.svg";
import Illustration from "../assets/img/login_illustration.svg";
import { Link } from "react-router-dom";
import WhiteLogo from "../assets/img/beyondirr_header_logo.png";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../assets/css/style.css";
// import "../assets/vendor/icofont/icofont.min.css";
import BackToHome from "../assets/img/mobile_back_to_home.svg";
import { GetOtp, EmptyInvalidEmail } from "../actions/actions";
function GenerateOTP() {
  const [email, setEmail] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const invalidEmail = useSelector((state) => state.invalidEmail);
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(GetOtp(email, history));
  };
  useEffect(() => {
    dispatch(EmptyInvalidEmail());
  }, [dispatch]);

  return (
    <>
      <div
        className="p-[20px] bg-primarybg-300 md:hidden"
        style={{ boxShadow: "inset 0px -1px 0px #1F2032" }}
      >
        <div className="flex">
          <Link to="/login" className="mt-[6px]">
            <img src={BackToHome} alt="err" />
          </Link>

          <div className="flex ml-[20px]">
            <img
              src={InvestedgeLogo}
              alt="logo"
              className="w-4"
              style={{
                filter:
                  "drop-shadow(0px 0px 40px #F02763) drop-shadow(0px 0px 24px #D74E77)",
              }}
            />
            <p className="font-montserrat font-sm leading-[21px] font-medium text-[#FCFCFD] ml-2 mb-0">
              InvestEdge
            </p>
          </div>
        </div>
      </div>
      <div
        className="min-w-[100vw] relative md:hidden bg-primarybg-300 p-[20px] pt-[32px]"
        style={{ minHeight: "calc(100vh - 61px)" }}
      >
        <p className="mb-0  text-xs font-montserrat  text-[#B1B5C4]">
          Email Id
        </p>
        <input
          name="username"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          type="email"
          placeholder="someone@domain.com"
          className="w-full h-[48px] px-3 py-[16px] rounded-xl bg-primarybg-100 focus:border-2 focus:border-primaryblue-200 border border-[#D9D9D9] placeholder:font-montserrat placeholder:text-[#B1B5C4] text-[#B1B5C4] mt-2"
        />

        <button
          type="submit"
          className="bg-primaryblue-200 w-full text-center py-[12px] rounded-[90px] font-montserrat text-[#FCFCFD] text-sm  mt-[24px]"
          onClick={(e) => handleSubmit(e)}
        >
          Generate OTP
        </button>
        <div className="flex justify-center items-center absolute bottom-0 w-full h-[40px] bg-transparent left-0">
          <Link
            to="/"
            exact="true"
            className="hover:text-[#FCFCF4] active:text-[#B1B5C4] decoration-[#FCFCF4]"
          >
            <p className="font-light text-sm text-[#9B9CA4] hover:text-[#FCFCF4]  mb-0">
              Home
            </p>
          </Link>
          <Link
            to="/about"
            exact="true"
            className="hover:text-[#FCFCF4] active:text-[#B1B5C4] decoration-[#FCFCF4]"
          >
            <p className="font-light text-sm text-[#9B9CA4] hover:text-[#FCFCF4] mb-0 ml-[16px]">
              About
            </p>
          </Link>
          <Link
            to="/terms"
            exact="true"
            className="hover:text-[#FCFCF4] active:text-[#B1B5C4] decoration-[#FCFCF4]"
          >
            <p className="font-light text-sm text-[#9B9CA4] hover:text-[#FCFCF4] mb-0 ml-[16px]">
              Terms of Use
            </p>
          </Link>
          <a
            href={process.env.REACT_APP_beyondirrhome}
            className="hover:text-[#FCFCF4] active:text-[#B1B5C4] decoration-[#FCFCF4]"
          >
            <p className="font-light text-sm text-[#9B9CA4] hover:text-[#FCFCF4] mb-0 ml-[16px]">
              BeyondIRR
            </p>
          </a>
          <a
            href={process.env.REACT_APP_investlane}
            className="hover:text-[#FCFCF4] active:text-[#B1B5C4] decoration-[#FCFCF4]"
          >
            <p className="font-light text-sm text-[#9B9CA4] hover:text-[#FCFCF4] mb-0 ml-[16px]">
              InvestLane
            </p>
          </a>
        </div>
      </div>
      <div
        className="hidden md:flex md:fixed md:justify-between md:top-0 md:z-50 md:w-full md:px-20 md:py-5"
        style={{ boxShadow: "inset 0px -1px 0px #1F2032" }}
      >
        <a href={process.env.REACT_APP_beyondirrhome}>
          <img src={WhiteLogo} alt="err" className="h-[35px]" />
        </a>

        <Link to="/" className="flex w-[170px] items-center">
          <img src={InvestedgeLogo} alt="err" className="ml-6 w-5" />
          <p className="font-montserrat font-medium text-[#FCFCFD] ml-3 mb-0">
            InvestEdge
          </p>
        </Link>
      </div>
      <div className="bg-primarybg-300 min-h-full min-w-full pt-28 pb-10 px-20 hidden md:flex justify-between">
        <div className="pt-[1%]">
          <p className="text-[#FCFCFD] font-montserrat font-medium text-2xl leading-[36px] pt-4 mb-0">
            Now you are the market, product and portfolio
            <br /> expert.
          </p>
          <p className="font-montserrat text-sm leading-[21px] text-[#B1B5C4]">
            The fastest way for any financial advisor to impress wealth clients.
            Access market views,
            <br /> product stories, and portfolio insights on InvestEdge and
            leave your clients impressed with
            <br /> your capabilities and deliverables.
          </p>
          <img src={Illustration} alt="err" className="w-[80%]" />
        </div>
        <div className="bg-primarybg-100 min-h-[75%] min-w-[40%] rounded-xl flex flex-col justify-center items-center px-[64px]">
          <h2 className="font-montserrat text-2xl leading-[36px] text-[#FCFCFD]">
            Forgot Password
          </h2>
          <p className="font-montserrat text-[#B1B5C4] leading-[24px]">
            Provide email address with registered account.
          </p>
          <div className="pt-6 w-full">
            <p className="mb-0 uppercase text-xs font-montserrat  text-[#B1B5C4]">
              Email Id
            </p>
            <input
              name="username"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              type="email"
              placeholder="someone@domain.com"
              className="w-full h-[48px] px-3 py-[16px] rounded-xl bg-primarybg-100 focus:border-2 focus:border-primaryblue-200 border border-[#D9D9D9] placeholder:font-montserrat placeholder:text-[#B1B5C4] text-[#B1B5C4] mt-2"
            />
          </div>
          {invalidEmail && invalidEmail.email ? (
            <div
              className="form-group mb-0 mt-2"
              style={{
                fontSize: "13px",
                color: "red",
              }}
            >
              No registered account found
            </div>
          ) : invalidEmail && invalidEmail.token ? (
            <div
              className="form-group mb-0 mt-2"
              style={{
                fontSize: "13px",
                color: "red",
              }}
            >
              Something went wrong. Try again later
            </div>
          ) : (
            <></>
          )}

          <button
            type="submit"
            className="bg-primaryblue-200 w-full text-center py-[16px] rounded-[90px] font-montserrat text-[#FCFCFD]  mt-[24px]"
            onClick={(e) => handleSubmit(e)}
          >
            Generate OTP
          </button>
        </div>
      </div>
      <div className="hidden md:flex justify-center items-center absolute bottom-0 w-full h-[40px] bg-transparent left-[-20px]">
        <Link
          to="/"
          exact="true"
          className="hover:text-[#FCFCF4] active:text-[#B1B5C4] decoration-[#FCFCF4]"
        >
          <p className="font-light text-sm text-[#9B9CA4] hover:text-[#FCFCF4]  mb-0">
            Home
          </p>
        </Link>
        <Link
          to="/about"
          exact="true"
          className="hover:text-[#FCFCF4] active:text-[#B1B5C4] decoration-[#FCFCF4]"
        >
          <p className="font-light text-sm text-[#9B9CA4] hover:text-[#FCFCF4] mb-0 ml-[16px]">
            About
          </p>
        </Link>
        <Link
          to="/terms"
          exact="true"
          className="hover:text-[#FCFCF4] active:text-[#B1B5C4] decoration-[#FCFCF4]"
        >
          <p className="font-light text-sm text-[#9B9CA4] hover:text-[#FCFCF4] mb-0 ml-[16px]">
            Terms of Use
          </p>
        </Link>
        <a
          href={process.env.REACT_APP_beyondirrhome}
          className="hover:text-[#FCFCF4] active:text-[#B1B5C4] decoration-[#FCFCF4]"
        >
          <p className="font-light text-sm text-[#9B9CA4] hover:text-[#FCFCF4] mb-0 ml-[16px]">
            BeyondIRR
          </p>
        </a>
        <a
          href={process.env.REACT_APP_investlane}
          className="hover:text-[#FCFCF4] active:text-[#B1B5C4] decoration-[#FCFCF4]"
        >
          <p className="font-light text-sm text-[#9B9CA4] hover:text-[#FCFCF4] mb-0 ml-[16px]">
            InvestLane
          </p>
        </a>
      </div>
    </>
  );
}

export default GenerateOTP;
