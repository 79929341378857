import React, { useState, useEffect, useRef } from "react";
import SearchIcon from "../../assets/img/global_search_icon.svg";
import MicrophoneIcon from "../../assets/img/bluemicrophone.svg";
import GreyMicrophoneIcon from "../../assets/img/greymicrophone.svg";
import axios from "axios";
import urls from "../../urls";
import {
  MarketFAQS,
  PostEventLoggingData,
  GetTrendingFAQS,
  GetSavedFAQS,
} from "../../actions/actions";
import { useLocation, useHistory } from "react-router-dom";
import BoyNotFound from "../../assets/img/searchscreennotfound.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SearchResultQuestion from "./SearchResultQuestion";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import "../../static/css/existing/dashboard_styles.css";
import AfterloginApp from "../AfterloginApp";
import { Bars } from "react-loader-spinner";
import ClickAwayListener from "react-click-away-listener";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import InvestedgePagination from "../../components/pagination/InvestedgePagination";
import InvestedgePopper from "../../components/popper/InvestedgePopper";
import { MenuItem } from "@mui/material";
export default function SearchResults() {
  const inputDivRef = useRef(null);
  const { listening, transcript } = useSpeechRecognition();
  const dispatch = useDispatch();
  const history = useHistory();
  const [question, setquestion] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const faqs = useSelector((state) => state.faqdata);
  const trendingFaqData = useSelector((state) => state.trendingFaqs);
  const savedFaqData = useSelector((state) => state.savedFaqs);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const searchedquestion = queryParams.get("searchedquestion");
  const filteredFAQ = faqs.filter(
    (faq) =>
      faq.question &&
      faq.question.toLowerCase().includes(searchedquestion.toLowerCase())
  );
  const [pageNumber, setPageNumber] = useState(0);
  const questionsPerPage = 3;
  const pagesVisited = pageNumber * questionsPerPage;
  let savedFaqIds = {};
  for (let i = 0; i < savedFaqData.length; i++)
    savedFaqIds[savedFaqData[i].id] = true;
  useEffect(() => {
    dispatch(MarketFAQS({ params: { query: searchedquestion } }));
    dispatch(GetTrendingFAQS());
    dispatch(GetSavedFAQS());
    // eslint-disable-next-line
  }, [searchedquestion]);
  useEffect(() => {
    setquestion(transcript);
  }, [transcript]);

  const displayquestions = faqs
    .slice(pagesVisited, pagesVisited + questionsPerPage)
    .map((faq, ind) => (
      <SearchResultQuestion key={ind} faq={faq} savedFaqIds={savedFaqIds} />
    ));
  const pageCount = Math.ceil(faqs.length / questionsPerPage);
  const changePage = (event, value) => {
    setPageNumber(value - 1);
    dispatch(
      PostEventLoggingData({
        module_name: "Search",
        event_type: "Pagination",
        url: window.location.href,
        note: searchedquestion,
      })
    );
  };

  const handleSearch = (e) => {
    e.preventDefault();
    history.push({
      pathname: `/searchresults`,
      search: `?searchedquestion=${question}`,
    });
  };
  const [faqData, setfaqData] = useState([]);

  useEffect(() => {
    question.length >= 3 && setfaqData(["loading"]);
    question.length >= 3 &&
      axios
        .get(urls.marketFaqURL, { params: { query: question } })
        .then((response) => {
          setfaqData(response.data);
        })
        .catch(() => {
          setfaqData([]);
        });
    if (question.length >= 3) setAnchorEl(inputDivRef.current);
    else setAnchorEl(null);
  }, [question]);

  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen md:!h-full md:!min-h-full h-auto">
          <div className="bg-[#fff] p-[2rem] rounded-[0.5rem] h-full w-full ">
            <form
              onSubmit={handleSearch}
              className="flex md:!w-[68.5%] w-full justify-center"
            >
              <div
                ref={inputDivRef}
                aria-describedby="search-screen-results-popper"
                className="w-full border border-globalBorderColor py-[0.25rem] px-[1rem] pr-[1rem] flex rounded-[6px] justify-between items-center relative rounded-r-none hover:border-neutralBlack"
              >
                <img src={SearchIcon} alt="err" />
                <input
                  placeholder="Search for view on Markets, Sectors, and Economy"
                  value={question}
                  className="border-none shadow-none w-[calc(100%_-_24px_-_40px)] ml-[0.5rem] text-[14px] placeholder:text-placeholder placeholder:font-normal md:placeholder:text-base placeholder:text-sm"
                  onChange={(e) => setquestion(e.target.value)}
                />
                <img
                  src={listening ? GreyMicrophoneIcon : MicrophoneIcon}
                  alt="err"
                  className="w-[20px] object-contain"
                  onClick={
                    listening
                      ? SpeechRecognition.stopListening
                      : SpeechRecognition.startListening
                  }
                />
              </div>
              <InvestedgeButton
                className="h-[45px] rounded-l-none w-[12%]"
                type="submit"
                onClick={() =>
                  dispatch(
                    PostEventLoggingData({
                      module_name: "Search",
                      event_type: "Search button",
                      url: window.location.href,
                      note: question,
                    })
                  )
                }
              >
                Search
              </InvestedgeButton>
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <InvestedgePopper
                  id="search-screen-results-popper"
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  className="h-[300px] md:w-[41.7%] w-[70%]"
                  placement="bottom-start"
                >
                  {faqData[0] === "loading" ? (
                    <div className="h-[300px] flex justify-center items-center">
                      <Bars color="#1982F8" height="60" width="60" />
                    </div>
                  ) : faqData.length ? (
                    faqData.slice(0, 7).map((option, ind) => (
                      <Link
                        to={{
                          pathname: "/searchviewquestion",
                          state: {
                            faq: option.id,
                            savedFaqIds: savedFaqIds,
                          },
                        }}
                        key={ind}
                        className="hover:text-primary-200 hover:bg-secondary-100"
                      >
                        <MenuItem
                          key={ind}
                          onClick={() => setAnchorEl(null)}
                          className="font-inter py-[1rem] text-neutralBlack "
                        >
                          {option.question}
                        </MenuItem>
                      </Link>
                    ))
                  ) : (
                    <div className="h-[300px] font-inter flex items-center justify-center font-medium text-xl text-neutralBlack">
                      No data found
                    </div>
                  )}
                </InvestedgePopper>
              </ClickAwayListener>
            </form>

            <div className="h-[93%]">
              <div className="grid grid-cols-10 md:gap-16 gap-0 pt-[2rem] h-full">
                {faqs.length === 0 ? (
                  <div className="w-full md:w-[96%] col-span-10 md:col-span-7 min-h-full flex flex-col justify-end items-center">
                    <div className="flex flex-col w-full items-start">
                      <p className="font-inter text-[15px] font-medium leading-[25px] mb-[0.25rem] tracking-[0.02em]">
                        Your search
                        <span className="text-[20px]">{searchedquestion} </span>
                        did not yield any results.
                      </p>
                      <ul className="ml-4 mt-4 list-[initial]">
                        <li className="text-[14px] font-inter">
                          Make sure that all words are spelled correctly.
                        </li>
                        <li className="text-[14px] font-inter">
                          Try different keywords.
                        </li>
                        <li className="text-[14px] font-inter">
                          Try more general keywords.
                        </li>
                      </ul>
                      <p className="font-inter mt-4 text-[14px] font-medium leading-[25px] mb-[0.25rem] tracking-[0.02em]">
                        Additionally, our system has taken note of your search
                        query and we shall evaluate the same and add response to
                        the query if found relevant.
                      </p>
                    </div>
                    <img src={BoyNotFound} alt="err" />
                  </div>
                ) : (
                  <div className="w-full md:w-[96%] col-span-10 md:col-span-7 h-full flex-col flex justify-between">
                    <div>
                      <h4 className="card-heading">Relevant Results</h4>
                      <div className="mt-[1.5rem] min-h-full">
                        {displayquestions}
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-span-10 md:col-span-3 relative">
                  <h3 className="font-inter text-[15px] font-medium leading-[25px] tracking-[0.02em] text-neutralBlack">
                    People also ask
                  </h3>
                  <div className="pt-[1rem]">
                    {trendingFaqData.slice(0, 5).map((item, index) => {
                      return (
                        <Link
                          to={{
                            pathname: "/searchviewquestion",
                            state: {
                              faq: item.id,
                              savedFaqIds: savedFaqIds,
                            },
                          }}
                          key={index}
                        >
                          <p className="font-inter text-sm leading-5 tracking-[0.02em] text-primary-200 mb-[0.5rem]">
                            {item.question}
                          </p>
                        </Link>
                      );
                    })}
                  </div>
                  {filteredFAQ.length !== 0 && (
                    <div className="w-full hidden md:flex justify-end absolute -bottom-4">
                      <InvestedgePagination
                        count={pageCount}
                        defaultPage={1}
                        page={pageNumber + 1}
                        onChange={changePage}
                        color="primary"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
