import React from "react";
import AfterloginApp from "../AfterloginApp";
// CSS
import "../../static/css/existing/dashboard_styles.css";

// Charts
import LineChart from "../../components/charts/LineChart";

// Components
import NavBarComponent from "./onePagerMutualFunds/navBar";
import Searchbar from "./onePagerMutualFunds/Searchbaronepager";
import strategyComponent from "./onePagerMutualFunds/strategy";
import whatsGoodComponent from "./onePagerMutualFunds/whatsGood";
import whatsBadComponent from "./onePagerMutualFunds/whatsBad";
import categoryComponent from "./onePagerMutualFunds/category";
import performanceComponent from "./onePagerMutualFunds/performance";
import TrailingReturnsComponent from "./onePagerMutualFunds/trailingReturns";
import portfolioComponent from "./onePagerMutualFunds/portfolio";
import quantsComponent from "./onePagerMutualFunds/quants";
import topHoldingsComponent from "./onePagerMutualFunds/topHolding";
import fundDetailsComponent from "./onePagerMutualFunds/fundDetails";
import taxationComponent from "./onePagerMutualFunds/taxation";
import faqComponent from "./onePagerMutualFunds/faq";
import introductionComponent from "./onePagerMutualFunds/introduction";

export default function Onepager() {
  //   useEffect(() => {
  //     $("a.active-tab").click(function () {
  //       $("a.active-tab").css({
  //         color: "#323232",
  //         "border-bottom": "",
  //       });
  //       $(this).css({ color: "#0000F5", "border-bottom": "4px solid #0000F5" });
  //     });
  //   }, []);

  //   useEffect(() => {
  //     $("h4.active").click(function () {
  //       $("h4.active").css({
  //         padding: "0px",
  //         borderRadius: "0px",
  //         borderLeft: "none",
  //         color: "#000",
  //         background: "none",
  //         cursor: "pointer",
  //         transition: "all 100ms ease-in",
  //       });
  //       $(this).css({
  //         padding: "12px",
  //         borderRadius: "3px",
  //         borderLeft: "5px solid #304aca",
  //         color: "#000",
  //         background: "#DFDEFF",
  //         cursor: "pointer",
  //         transition: "all 100ms ease-in",
  //       });
  //     });
  //   }, []);
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Client Input"}
        description={
          "Browse for portfolio insights, actionable, cash flow planning, and much more"
        }
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div
            style={{
              display: "flex",
            }}
          >
            <div>
              {/* #1 Contains NavBar items */}
              {NavBarComponent()}

              {/* #2 Contains Highlighted NavBar items */}
              {/* {navHighlightedBarComponent()} */}
            </div>

            <div
              style={{
                marginRight: "-33px",
                paddingTop: "0",
                marginLeft: "17%",
                boxShadow: "none",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#fff",
                  padding: "1rem",
                  borderTopLeftRadius: "0.5rem",
                  borderTopRightRadius: "0.5rem",
                  boxShadow:
                    "2px -2px 6px rgb(0 0 0 / 5%),-2px 0px 6px rgb(0 0 0 / 5%)",
                }}
              >
                {/* #4 Contains Search bar */}
                {Searchbar()}

                {/* #3 Contains Introduction component items */}
                {introductionComponent()}

                <h4
                  id="strategy"
                  className="onepager-inside-card-data"
                  style={{
                    color: "rgb(128, 128, 229)",
                    paddingTop: "1.5rem",
                    paddingBottom: "0.7rem",
                  }}
                >
                  Strategy
                </h4>
                {/* #4 Contains Strategy Items */}
                {strategyComponent()}

                <h4
                  id="good"
                  className="onepager-inside-card-data"
                  style={{
                    color: "rgb(128, 128, 229)",
                    paddingTop: "1.5rem",
                    paddingBottom: "0.7rem",
                  }}
                >
                  What’s Good?
                </h4>
                {/* #5 Contains What's Good card items */}
                {whatsGoodComponent()}

                <h4
                  id="bad"
                  className="onepager-inside-card-data"
                  style={{
                    color: "rgb(128, 128, 229)",
                    paddingTop: "1.5rem",
                    paddingBottom: "0.7rem",
                  }}
                >
                  What’s Bad?
                </h4>
                {/* #6 Contains What's Bad card items */}
                {whatsBadComponent()}

                <h4
                  id="category"
                  className="onepager-inside-card-data"
                  style={{
                    color: "rgb(128, 128, 229)",
                    paddingTop: "1.5rem",
                  }}
                >
                  Category
                </h4>
                {/* #7 Contains Category card items */}
                {categoryComponent()}

                <h4
                  id="performance"
                  className="onepager-inside-card-data"
                  style={{
                    color: "rgb(128, 128, 229)",
                    paddingTop: "1.5rem",
                    paddingBottom: "0.7rem",
                  }}
                >
                  Performance
                </h4>
                {/* #8 Contains performance card text */}
                {performanceComponent()}
                <div
                  style={{
                    padding: "1rem",

                    backgroundColor: "#fff",
                    borderRadius: "6px",
                    boxShadow:
                      " 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(0, 0, 0, 0.05)",
                    minheight: "274px",
                    marginTop: "1.5rem",
                  }}
                >
                  <h4 id="performance" className="text-sm  mt-3 mb-3">
                    NAV Chart
                  </h4>
                  <LineChart />
                </div>
                {/* #9 Contains Trailing Return table headers */}
                {TrailingReturnsComponent()}

                <h4
                  id="portfolio"
                  className="onepager-inside-card-data"
                  style={{
                    color: "rgb(128, 128, 229)",
                    paddingTop: "1rem",
                  }}
                >
                  Portfolio
                </h4>
                {/* #10 Contains portfolio card data */}
                {portfolioComponent()}

                {/* #11 Contains top Holdings card */}
                {topHoldingsComponent()}
                <h4
                  id="fund"
                  className="onepager-inside-card-data"
                  style={{
                    color: "rgb(128, 128, 229)",
                    paddingTop: "1.5rem",
                    paddingBottom: "0.7rem",
                  }}
                >
                  Fund Details
                </h4>
                {/* #12 Contains quants card items */}
                {fundDetailsComponent()}

                {/* #13 Contains quants card items */}
                {quantsComponent()}
                <h4
                  id="taxation"
                  className="onepager-inside-card-data"
                  style={{
                    color: "rgb(128, 128, 229)",
                    paddingTop: "1rem",
                  }}
                >
                  Taxation
                </h4>
                {/* #14 Contains taxation card text */}
                {taxationComponent}

                <h4
                  id="faqs"
                  className="onepager-inside-card-data"
                  style={{
                    color: "rgb(128, 128, 229)",
                    paddingTop: "1rem",
                  }}
                >
                  FAQ
                </h4>
                {/* #15 Contains FAQs card text */}
                {faqComponent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
