import React from "react";
import { useState } from "react";
import ArrowLeft from "../../../../assets/img/left_arrow_icon.svg";
import ArrowDown from "../../../../assets/img/down_arrow_icon.svg";
import TextField from "@mui/material/TextField";
export default function OthersRow({
  fund,
  id,
  removeothersarray,
  othersarray,
  setothersarray,
}) {
  const [open, setOpen] = useState(fund.name === "New Fund" ? true : false);

  // let handleChange = (i, e) => {
  //   let newothersarray = [...othersarray];
  //   newothersarray[i][e.target.name] = e.target.value;
  //   setothersarray(newothersarray);
  // };
  return (
    <>
      <tr className=" w-full bg-[#f5f5f5] mt-[0.5rem] flex justify-between items-center p-[0.1rem] h-[50px] pr-[0.21rem] rounded-[4px]">
        <td className=" font-medium text-[15px] text-[#000]">{fund.name}</td>
        <td>
          <tr>
            <td className=" font-medium text-[14px]">₹ {fund.price}</td>
            <td
              className=" font-medium text-[15px] text-primary-200 mr-[1rem]"
              onClick={() => removeothersarray(id)}
            >
              Delete
            </td>
            <td
              className="p-[0px] w-[20px] pl-[0.3rem]"
              onClick={() => setOpen(!open)}
            >
              {open || fund.name === "New Fund" ? (
                <img className="block" src={ArrowDown} alt="err" />
              ) : (
                <img className="block" src={ArrowLeft} alt="err" />
              )}
            </td>
          </tr>
        </td>
      </tr>
      {open || fund.name === "New Fund" ? (
        <>
          <tr className="flex w-full">
            <td className="w-[50%]">
              <TextField
                required
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Name"
                type="text"
              />
            </td>
            <td className="w-[25%]">
              <TextField
                required
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Quantity"
                type="text"
              />
            </td>
            <td className="w-[25%]">
              <TextField
                required
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Cost"
                type="text"
              />
            </td>
          </tr>
          <tr className="flex w-full">
            <td className="w-[23%]">
              <TextField
                required
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Purchase date"
                type="text"
              />
            </td>
            <td className="w-[23%]">
              <TextField
                required
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Current Value"
                type="text"
              />
            </td>
          </tr>
        </>
      ) : (
        ""
      )}
    </>
  );
}
