import { useSelector } from "react-redux";
export const RiskAndQuants = () => {
  const fundData = useSelector((store) => store.getProductOnepagerData);
  return (
    <div className=" w-[72%] pt-[60px]">
      <h3 className=" card-heading mb-6">Risk & Quants </h3>
      <div className="flex justify-between">
        <div>
          <p className="font-inter font-medium text-[24px] leading-[29px] text-left tracking-[-3%] align-top mb-[0.4rem]">
            {fundData.json_field.risk_ratios.data.std_dev
              ? `${fundData.json_field.risk_ratios.data.std_dev.toFixed(2)}%`
              : "0%"}
          </p>
          <span className="font-inter  text-[14px] leading-[17px] text-center tracking-[-3%] align-top text-[#6A6A76]">
            Standard Div
          </span>
        </div>
        <div>
          <p className="font-inter font-medium text-[24px] leading-[29px] mb-[0.4rem] text-left tracking-[-3%] align-top">
            {fundData.json_field.risk_ratios.data.sharpe
              ? fundData.json_field.risk_ratios.data.sharpe.toFixed(2)
              : 0}
          </p>
          <span className="font-inter  text-[14px] leading-[17px] text-center tracking-[-3%] align-top text-[#6A6A76]">
            Sharpe Ratio
          </span>
        </div>
        <div>
          <p className="font-inter font-medium text-[24px] leading-[29px] mb-[0.4rem] text-left tracking-[-3%] align-top">
            {fundData.json_field.risk_ratios.data.beta
              ? fundData.json_field.risk_ratios.data.beta.toFixed(2)
              : 0}
          </p>
          <span className="font-inter  text-[14px] leading-[17px] text-center tracking-[-3%] align-top text-[#6A6A76]">
            Beta
          </span>
        </div>
        <div>
          <p className="font-inter font-medium text-[24px] leading-[29px] mb-[0.4rem] text-left tracking-[-3%] align-top">
            {fundData.json_field.debt_quants.data.gross_ytm
              ? fundData.json_field.debt_quants.data.gross_ytm
              : 0}
          </p>
          <span className="font-inter  text-[14px] leading-[17px] text-center tracking-[-3%] align-top text-[#6A6A76]">
            Gross YTM
          </span>
        </div>
        <div>
          <p className="font-inter font-medium text-[24px] leading-[29px] mb-[0.4rem] text-left tracking-[-3%] align-top">
            {fundData.json_field.debt_quants.data.mod_duration_yr
              ? `${fundData.json_field.debt_quants.data.mod_duration_yr}Y`
              : "0Y"}
          </p>
          <span className="font-inter  text-[14px] leading-[17px] text-center tracking-[-3%] align-top text-[#6A6A76]">
            Mod. Duration
          </span>
        </div>
        <div>
          <p className="font-inter font-medium text-[24px] leading-[29px] mb-[0.4rem] text-left tracking-[-3%] align-top">
            {fundData.json_field.debt_quants.data.avg_maturity_yr
              ? `${fundData.json_field.debt_quants.data.avg_maturity_yr}Y`
              : "0Y"}
          </p>
          <span className="font-inter  text-[14px] leading-[17px] text-center tracking-[-3%] align-top text-[#6A6A76]">
            Avg Maturity
          </span>
        </div>
      </div>
    </div>
  );
};
