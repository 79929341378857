export const chartData = [
  {
    end: 240,
    date: "01-01-2015",
  },
  {
    end: 243,

    date: "02-01-2015",
  },
  {
    end: 242,

    date: "03-01-2015",
  },
  {
    end: 244,

    date: "04-01-2015",
  },
  {
    end: 247,

    date: "05-01-2015",
  },
  {
    end: 248,

    date: "06-01-2015",
  },
  {
    end: 242,

    date: "07-01-2015",
  },
  {
    end: 244,
    date: "08-01-2015",
  },
  {
    end: 240,

    date: "09-01-2015",
  },
  {
    end: 243,
    date: "10-01-2015",
  },
  {
    end: 970,
    date: "11-01-2015",
  },
  {
    end: 973,
    date: "12-01-2015",
  },
  {
    end: 977,
    date: "13-01-2015",
  },
  {
    end: 978,
    date: "14-01-2015",
  },
  {
    end: 260,
    date: "15-01-2015",
  },
  {
    end: 977,
    date: "16-01-2015",
  },
  {
    end: 260,
    date: "17-01-2015",
  },
  {
    end: 262,
    date: "18-01-2015",
  },
  {
    end: 270,

    date: "19-01-2015",
  },
  {
    end: 273,

    date: "20-01-2015",
  },
  {
    end: 274,
    date: "21-01-2015",
  },
  {
    end: 276,
    date: "22-01-2015",
  },
  {
    end: 278,
    date: "23-01-2015",
  },
  {
    end: 278,
    date: "24-01-2015",
  },
  {
    end: 280,
    date: "25-01-2015",
  },
  {
    end: 273,
    date: "26-01-2015",
  },
  {
    end: 276,
    date: "27-01-2015",
  },
  {
    end: 283,
    date: "28-01-2015",
  },
  {
    end: 293,
    date: "29-01-2015",
  },
  {
    end: 280,
    date: "30-01-2015",
  },
  {
    end: 286,
    date: "31-01-2015",
  },
  {
    end: 290,
    date: "01-02-2015",
  },
  {
    end: 293,
    date: "02-02-2015",
  },
  {
    end: 295,
    date: "03-02-2015",
  },
  {
    end: 298,
    date: "04-02-2015",
  },
  {
    end: 293,
    date: "05-02-2015",
  },
  {
    end: 297,
    date: "06-02-2015",
  },
  {
    end: 300,
    date: "07-02-2015",
  },
  {
    end: 293,
    date: "08-02-2015",
  },
  {
    end: 300,
    date: "09-02-2015",
  },
  {
    end: 303,
    date: "10-02-2015",
  },
  {
    end: 307,
    date: "11-02-2015",
  },
  {
    end: 310,
    date: "12-02-2015",
  },
  {
    end: 293,
    date: "13-02-2015",
  },
  {
    end: 300,
    date: "14-02-2015",
  },
  {
    end: 307,
    date: "15-02-2015",
  },
  {
    end: 310,
    date: "16-02-2015",
  },
  {
    end: 320,
    date: "17-02-2015",
  },
  {
    end: 313,
    date: "18-02-2015",
  },
  {
    end: 315,
    date: "19-02-2015",
  },
  {
    end: 320,
    date: "20-02-2015",
  },
  {
    end: 322,
    date: "21-02-2015",
  },
  {
    end: 321,
    date: "22-02-2015",
  },
  {
    end: 324,
    date: "23-02-2015",
  },
  {
    end: 300,
    date: "24-02-2015",
  },
  {
    end: 313,
    date: "25-02-2015",
  },
  {
    end: 315,
    date: "26-02-2015",
  },
  {
    end: 320,
    date: "27-02-2015",
  },
  {
    end: 323,
    date: "28-02-2015",
  },
  {
    end: 322,
    date: "01-03-2015",
  },
  {
    end: 325,
    date: "02-03-2015",
  },
  {
    end: 327,
    date: "03-03-2015",
  },
  {
    end: 340,
    date: "04-03-2015",
  },
  {
    end: 345,
    date: "05-03-2015",
  },
  {
    end: 340,
    date: "06-03-2015",
  },
  {
    end: 343,
    date: "07-03-2015",
  },
  {
    end: 346,
    date: "08-03-2015",
  },
  {
    end: 356,
    date: "09-03-2015",
  },
  {
    end: 353,
    date: "10-03-2015",
  },
  {
    end: 355,
    date: "11-03-2015",
  },
  {
    end: 359,
    date: "12-03-2015",
  },
  {
    end: 353,
    date: "13-03-2015",
  },
  {
    end: 363,
    date: "14-03-2015",
  },
  {
    end: 366,
    date: "15-03-2015",
  },
  {
    end: 364,
    date: "16-03-2015",
  },
  {
    end: 369,
    date: "17-03-2015",
  },
  {
    end: 370,
    date: "18-03-2015",
  },
  {
    end: 373,
    date: "19-03-2015",
  },
  {
    end: 380,
    date: "20-03-2015",
  },
  {
    end: 366,
    date: "21-03-2015",
  },
  {
    end: 369,
    date: "22-03-2015",
  },
  {
    end: 376,
    date: "23-03-2015",
  },
  {
    end: 379,
    date: "24-03-2015",
  },
  {
    end: 381,
    date: "25-03-2015",
  },
  {
    end: 384,
    date: "26-03-2015",
  },
  {
    end: 389,
    date: "27-03-2015",
  },
  {
    end: 390,
    date: "28-03-2015",
  },
  {
    end: 393,
    date: "29-03-2015",
  },
  {
    end: 394,
    date: "30-03-2015",
  },
  {
    end: 401,
    date: "31-03-2015",
  },
  {
    end: 403,
    date: "01-04-2015",
  },
  {
    end: 405,
    date: "02-04-2015",
  },
  {
    end: 410,
    date: "03-04-2015",
  },
  {
    end: 408,
    date: "04-04-2015",
  },
  {
    end: 411,
    date: "05-04-2015",
  },
  {
    end: 413,
    date: "06-04-2015",
  },
  {
    end: 423,
    date: "07-04-2015",
  },
  {
    end: 417,
    date: "08-04-2015",
  },
  {
    end: 420,
    date: "09-04-2015",
  },
  {
    end: 423,
    date: "10-04-2015",
  },
  {
    end: 444,
    date: "11-04-2015",
  },
  {
    end: 440,
    date: "12-04-2015",
  },
  {
    end: 430,
    date: "13-04-2015",
  },
  {
    end: 422,
    date: "14-04-2015",
  },
  {
    end: 428,
    date: "15-04-2015",
  },
  {
    end: 430,
    date: "16-04-2015",
  },
  {
    end: 423,
    date: "17-04-2015",
  },
  {
    end: 438,
    date: "18-04-2015",
  },
  {
    end: 437,
    date: "19-04-2015",
  },
  {
    end: 423,
    date: "20-04-2015",
  },
  {
    end: 420,
    date: "21-04-2015",
  },
  {
    end: 423,
    date: "22-04-2015",
  },
  {
    end: 414,
    date: "23-04-2015",
  },
  {
    end: 423,
    date: "24-04-2015",
  },
  {
    end: 429,
    date: "25-04-2015",
  },
  {
    end: 420,
    date: "26-04-2015",
  },
  {
    end: 413,
    date: "27-04-2015",
  },
  {
    end: 408,
    date: "28-04-2015",
  },
  {
    end: 404,
    date: "29-04-2015",
  },
  {
    end: 389,
    date: "30-04-2015",
  },
  {
    end: 400,
    date: "01-05-2015",
  },
  {
    end: 410,
    date: "02-05-2015",
  },
  {
    end: 413,
    date: "03-05-2015",
  },
  {
    end: 420,
    date: "04-05-2015",
  },
  {
    end: 426,
    date: "05-05-2015",
  },
  {
    end: 429,
    date: "06-05-2015",
  },
  {
    end: 440,
    date: "07-05-2015",
  },
  {
    end: 450,
    date: "08-05-2015",
  },
  {
    end: 440,
    date: "09-05-2015",
  },
  {
    end: 444,
    date: "10-05-2015",
  },
  {
    end: 448,
    date: "11-05-2015",
  },
  {
    end: 454,
    date: "12-05-2015",
  },
  {
    end: 464,
    date: "13-05-2015",
  },
  {
    end: 469,
    date: "14-05-2015",
  },
  {
    end: 470,
    date: "15-05-2015",
  },
  {
    end: 474,
    date: "16-05-2015",
  },
  {
    end: 478,
    date: "17-05-2015",
  },
  {
    end: 490,
    date: "18-05-2015",
  },
  {
    end: 449,
    date: "19-05-2015",
  },
  {
    end: 464,
    date: "20-05-2015",
  },
  {
    end: 470,
    date: "21-05-2015",
  },
  {
    end: 474,
    date: "22-05-2015",
  },
  {
    end: 479,
    date: "23-05-2015",
  },
  {
    end: 484,
    date: "24-05-2015",
  },
  {
    end: 470,
    date: "25-05-2015",
  },
  {
    end: 474,
    date: "26-05-2015",
  },
  {
    end: 490,
    date: "27-05-2015",
  },
  {
    end: 494,
    date: "28-05-2015",
  },
  {
    end: 498,
    date: "29-05-2015",
  },
  {
    end: 500,
    date: "30-05-2015",
  },
  {
    end: 504,
    date: "31-05-2015",
  },
  {
    end: 508,
    date: "01-06-2015",
  },
  {
    end: 510,
    date: "02-06-2015",
  },
  {
    end: 510,
    date: "03-06-2015",
  },
  {
    end: 514,
    date: "04-06-2015",
  },
  {
    end: 524,
    date: "05-06-2015",
  },
  {
    end: 528,
    date: "06-06-2015",
  },
  {
    end: 534,
    date: "07-06-2015",
  },
  {
    end: 533,
    date: "08-06-2015",
  },
  {
    end: 538,
    date: "09-06-2015",
  },
  {
    end: 540,
    date: "10-06-2015",
  },
  {
    end: 544,
    date: "11-06-2015",
  },
  {
    end: 542,
    date: "12-06-2015",
  },
  {
    end: 546,
    date: "13-06-2015",
  },
  {
    end: 548,
    date: "14-06-2015",
  },
  {
    end: 550,
    date: "15-06-2015",
  },
  {
    end: 540,
    date: "16-06-2015",
  },
  {
    end: 545,
    date: "17-06-2015",
  },
  {
    end: 547,
    date: "18-06-2015",
  },
  {
    end: 564,
    date: "19-06-2015",
  },
  {
    end: 568,
    date: "20-06-2015",
  },
  {
    end: 574,
    date: "21-06-2015",
  },
  {
    end: 566,
    date: "22-06-2015",
  },
  {
    end: 568,
    date: "23-06-2015",
  },
  {
    end: 563,
    date: "24-06-2015",
  },
  {
    end: 580,
    date: "25-06-2015",
  },
  {
    end: 584,
    date: "26-06-2015",
  },
  {
    end: 594,
    date: "27-06-2015",
  },
  {
    end: 590,
    date: "28-06-2015",
  },
  {
    end: 592,
    date: "29-06-2015",
  },
  {
    end: 597,
    date: "30-06-2015",
  },
  {
    end: 600,
    date: "01-07-2015",
  },
  {
    end: 603,
    date: "02-07-2015",
  },
  {
    end: 607,
    date: "03-07-2015",
  },
  {
    end: 603,
    date: "04-07-2015",
  },
  {
    end: 610,
    date: "05-07-2015",
  },
  {
    end: 613,
    date: "06-07-2015",
  },
  {
    end: 617,
    date: "07-07-2015",
  },
  {
    end: 633,
    date: "08-07-2015",
  },
  {
    end: 620,
    date: "09-07-2015",
  },
  {
    end: 623,
    date: "10-07-2015",
  },
  {
    end: 627,
    date: "11-07-2015",
  },
  {
    end: 629,
    date: "12-07-2015",
  },
  {
    end: 634,
    date: "13-07-2015",
  },
  {
    end: 637,
    date: "14-07-2015",
  },
  {
    end: 639,
    date: "15-07-2015",
  },
  {
    end: 640,
    date: "16-07-2015",
  },
  {
    end: 643,
    date: "17-07-2015",
  },
  {
    end: 647,
    date: "18-07-2015",
  },
  {
    end: 644,
    date: "19-07-2015",
  },
  {
    end: 643,
    date: "20-07-2015",
  },
  {
    end: 647,
    date: "21-07-2015",
  },
  {
    end: 650,
    date: "22-07-2015",
  },
  {
    end: 655,
    date: "23-07-2015",
  },
  {
    end: 653,
    date: "24-07-2015",
  },
  {
    end: 657,
    date: "25-07-2015",
  },
  {
    end: 660,
    date: "26-07-2015",
  },
  {
    end: 666,
    date: "27-07-2015",
  },
  {
    end: 663,
    date: "28-07-2015",
  },
  {
    end: 669,
    date: "29-07-2015",
  },
  {
    end: 668,
    date: "30-07-2015",
  },
  {
    end: 671,
    date: "31-07-2015",
  },
  {
    end: 673,
    date: "01-08-2015",
  },
  {
    end: 676,
    date: "02-08-2015",
  },
  {
    end: 678,
    date: "03-08-2015",
  },
  {
    end: 679,
    date: "04-08-2015",
  },
  {
    end: 670,
    date: "05-08-2015",
  },
  {
    end: 671,
    date: "06-08-2015",
  },
  {
    end: 676,
    date: "07-08-2015",
  },
  {
    end: 677,
    date: "08-08-2015",
  },
  {
    end: 680,
    date: "09-08-2015",
  },
  {
    end: 681,
    date: "10-08-2015",
  },
  {
    end: 684,
    date: "11-08-2015",
  },
  {
    end: 686,
    date: "12-08-2015",
  },
  {
    end: 688,
    date: "13-08-2015",
  },
  {
    end: 671,
    date: "14-08-2015",
  },
  {
    end: 681,
    date: "15-08-2015",
  },
  {
    end: 691,
    date: "16-08-2015",
  },
  {
    end: 688,
    date: "17-08-2015",
  },
  {
    end: 689,
    date: "18-08-2015",
  },
  {
    end: 694,
    date: "19-08-2015",
  },
  {
    end: 695,
    date: "20-08-2015",
  },
  {
    end: 698,
    date: "21-08-2015",
  },
  {
    end: 701,
    date: "22-08-2015",
  },
  {
    end: 711,
    date: "23-08-2015",
  },
  {
    end: 691,
    date: "24-08-2015",
  },
  {
    end: 697,
    date: "25-08-2015",
  },
  {
    end: 699,
    date: "26-08-2015",
  },
  {
    end: 691,
    date: "27-08-2015",
  },
  {
    end: 700,
    date: "28-08-2015",
  },
  {
    end: 704,
    date: "29-08-2015",
  },
  {
    end: 708,
    date: "30-08-2015",
  },
  {
    end: 703,
    date: "01-09-2015",
  },
  {
    end: 709,
    date: "02-09-2015",
  },
  {
    end: 711,
    date: "03-09-2015",
  },
  {
    end: 733,
    date: "04-09-2015",
  },
  {
    end: 713,
    date: "05-09-2015",
  },
  {
    end: 717,
    date: "06-09-2015",
  },
  {
    end: 722,
    date: "07-09-2015",
  },
  {
    end: 723,
    date: "08-09-2015",
  },
  {
    end: 727,
    date: "09-09-2015",
  },
  {
    end: 729,
    date: "10-09-2015",
  },
  {
    end: 731,
    date: "11-09-2015",
  },
  {
    end: 733,
    date: "12-09-2015",
  },
  {
    end: 735,
    date: "13-09-2015",
  },
  {
    end: 738,
    date: "14-09-2015",
  },
  {
    end: 744,
    date: "15-09-2015",
  },
  {
    end: 745,
    date: "16-09-2015",
  },
  {
    end: 748,
    date: "17-09-2015",
  },
  {
    end: 755,
    date: "18-09-2015",
  },
  {
    end: 733,
    date: "19-09-2015",
  },
  {
    end: 723,
    date: "20-09-2015",
  },
  {
    end: 648,
    date: "21-09-2015",
  },
  {
    end: 731,
    date: "22-09-2015",
  },
  {
    end: 737,
    date: "23-09-2015",
  },
  {
    end: 691,
    date: "24-09-2015",
  },
  {
    end: 697,
    date: "25-09-2015",
  },
  {
    end: 699,
    date: "26-09-2015",
  },
  {
    end: 691,
    date: "27-09-2015",
  },
  {
    end: 700,
    date: "28-09-2015",
  },
  {
    end: 704,
    date: "29-09-2015",
  },
  {
    end: 709,
    date: "30-09-2015",
  },
  {
    end: 709,
    date: "31-09-2015",
  },

  {
    end: 740,
    date: "01-10-2015",
  },
  {
    end: 745,
    date: "02-10-2015",
  },
  {
    end: 755,
    date: "03-10-2015",
  },
  {
    end: 757,
    date: "04-10-2015",
  },
  {
    end: 766,
    date: "05-10-2015",
  },
  {
    end: 750,
    date: "06-10-2015",
  },
  {
    end: 751,
    date: "07-10-2015",
  },
  {
    end: 759,
    date: "08-10-2015",
  },
  {
    end: 763,
    date: "10-10-2015",
  },
  {
    end: 767,
    date: "10-10-2015",
  },
  {
    end: 768,
    date: "11-10-2015",
  },
  {
    end: 771,
    date: "12-10-2015",
  },
  {
    end: 774,
    date: "13-10-2015",
  },
  {
    end: 778,
    date: "14-10-2015",
  },
  {
    end: 766,
    date: "15-10-2015",
  },
  {
    end: 780,
    date: "16-10-2015",
  },
  {
    end: 778,
    date: "17-10-2015",
  },
  {
    end: 785,
    date: "18-10-2015",
  },
  {
    end: 783,
    date: "19-10-2015",
  },
  {
    end: 788,
    date: "20-10-2015",
  },
  {
    end: 798,
    date: "21-10-2015",
  },
  {
    end: 791,
    date: "22-10-2015",
  },
  {
    end: 797,
    date: "23-10-2015",
  },
  {
    end: 801,
    date: "24-10-2015",
  },
  {
    end: 807,
    date: "25-10-2015",
  },
  {
    end: 809,
    date: "26-10-2015",
  },
  {
    end: 811,
    date: "27-10-2015",
  },
  {
    end: 800,
    date: "28-10-2015",
  },
  {
    end: 804,
    date: "29-10-2015",
  },
  {
    end: 810,
    date: "30-10-2015",
  },
  {
    end: 817,
    date: "31-10-2015",
  },

  {
    end: 820,
    date: "01-11-2015",
  },
  {
    end: 821,
    date: "02-11-2015",
  },
  {
    end: 825,
    date: "03-11-2015",
  },
  {
    end: 827,
    date: "04-11-2015",
  },
  {
    end: 830,
    date: "05-11-2015",
  },
  {
    end: 822,
    date: "06-11-2015",
  },
  {
    end: 832,
    date: "07-11-2015",
  },
  {
    end: 800,
    date: "08-11-2015",
  },
  {
    end: 783,
    date: "09-11-2015",
  },
  {
    end: 797,
    date: "10-11-2015",
  },
  {
    end: 788,
    date: "11-11-2015",
  },
  {
    end: 771,
    date: "12-11-2015",
  },
  {
    end: 774,
    date: "13-11-2015",
  },
  {
    end: 778,
    date: "14-11-2015",
  },
  {
    end: 766,
    date: "15-11-2015",
  },
  {
    end: 780,
    date: "16-11-2015",
  },
  {
    end: 778,
    date: "17-11-2015",
  },
  {
    end: 785,
    date: "18-11-2015",
  },
  {
    end: 783,
    date: "19-11-2015",
  },
  {
    end: 788,
    date: "20-11-2015",
  },
  {
    end: 798,
    date: "21-11-2015",
  },
  {
    end: 791,
    date: "22-11-2015",
  },
  {
    end: 797,
    date: "23-11-2015",
  },
  {
    end: 801,
    date: "24-11-2015",
  },
  {
    end: 807,
    date: "25-11-2015",
  },
  {
    end: 809,
    date: "26-11-2015",
  },
  {
    end: 811,
    date: "27-11-2015",
  },
  {
    end: 800,
    date: "28-11-2015",
  },
  {
    end: 804,
    date: "29-11-2015",
  },
  {
    end: 820,
    date: "30-11-2015",
  },
  {
    end: 840,
    date: "31-11-2015",
  },

  {
    end: 845,
    date: "01-12-2015",
  },
  {
    end: 850,
    date: "02-12-2015",
  },
  {
    end: 855,
    date: "03-12-2015",
  },
  {
    end: 859,
    date: "04-12-2015",
  },
  {
    end: 851,
    date: "05-12-2015",
  },
  {
    end: 865,
    date: "06-12-2015",
  },
  {
    end: 869,
    date: "07-12-2015",
  },
  {
    end: 877,
    date: "08-12-2015",
  },
  {
    end: 871,
    date: "09-12-2015",
  },
  {
    end: 877,
    date: "10-12-2015",
  },
  {
    end: 888,
    date: "11-12-2015",
  },
  {
    end: 881,
    date: "12-12-2015",
  },
  {
    end: 874,
    date: "13-12-2015",
  },
  {
    end: 888,
    date: "14-12-2015",
  },
  {
    end: 896,
    date: "15-12-2015",
  },
  {
    end: 890,
    date: "16-12-2015",
  },
  {
    end: 908,
    date: "17-12-2015",
  },
  {
    end: 900,
    date: "18-12-2015",
  },
  {
    end: 906,
    date: "19-12-2015",
  },
  {
    end: 909,
    date: "20-12-2015",
  },
  {
    end: 900,
    date: "21-12-2015",
  },
  {
    end: 891,
    date: "22-12-2015",
  },
  {
    end: 911,
    date: "23-12-2015",
  },
  {
    end: 921,
    date: "24-12-2015",
  },
  {
    end: 927,
    date: "25-12-2015",
  },
  {
    end: 930,
    date: "26-12-2015",
  },
  {
    end: 932,
    date: "27-12-2015",
  },
  {
    end: 940,
    date: "28-12-2015",
  },
  {
    end: 933,
    date: "29-12-2015",
  },
  {
    end: 940,
    date: "30-12-2015",
  },
  {
    end: 944,
    date: "31-12-2015",
  },
  // -- 2016 start

  {
    end: 948,
    date: "01-01-2016",
  },
  {
    end: 949,
    date: "02-01-2016",
  },
  {
    end: 951,
    date: "03-01-2016",
  },
  {
    end: 997,
    date: "04-01-2016",
  },
  {
    end: 958,
    date: "05-01-2016",
  },
  {
    end: 952,
    date: "06-01-2016",
  },
  {
    end: 954,
    date: "07-01-2016",
  },
  {
    end: 950,
    date: "08-01-2016",
  },
  {
    end: 953,
    date: "09-01-2016",
  },
  {
    end: 968,
    date: "10-01-2016",
  },
  {
    end: 973,
    date: "11-01-2016",
  },
  {
    end: 977,
    date: "12-01-2016",
  },
  {
    end: 978,
    date: "13-01-2016",
  },
  {
    end: 260,
    date: "14-01-2016",
  },
  {
    end: 977,
    date: "15-01-2016",
  },
  {
    end: 260,
    date: "16-01-2016",
  },
  {
    end: 262,
    date: "17-01-2016",
  },
  {
    end: 1000,
    date: "18-01-2016",
  },
  {
    end: 1003,
    date: "19-01-2016",
  },
  {
    end: 1000,
    date: "20-01-2016",
  },
  {
    end: 1004,
    date: "21-01-2016",
  },
  {
    end: 1006,
    date: "22-01-2016",
  },
  {
    end: 1008,
    date: "23-01-2016",
  },
  {
    end: 1008,
    date: "24-01-2016",
  },
  {
    end: 280,
    date: "25-01-2016",
  },
  {
    end: 1003,
    date: "26-01-2016",
  },
  {
    end: 1006,
    date: "27-01-2016",
  },
  {
    end: 1113,
    date: "28-01-2016",
  },
  {
    end: 1113,
    date: "29-01-2016",
  },
  {
    end: 280,
    date: "30-01-2016",
  },
  {
    end: 286,
    date: "31-01-2016",
  },
  {
    end: 1110,
    date: "01-02-2016",
  },
  {
    end: 1113,
    date: "02-02-2016",
  },
  {
    end: 1115,
    date: "03-02-2016",
  },
  {
    end: 1118,
    date: "04-02-2016",
  },
  {
    end: 1113,
    date: "05-02-2016",
  },
  {
    end: 1117,
    date: "06-02-2016",
  },
  {
    end: 300,
    date: "07-02-2016",
  },
  {
    end: 1113,
    date: "08-02-2016",
  },
  {
    end: 1200,
    date: "09-02-2016",
  },
  {
    end: 1202,
    date: "10-02-2016",
  },
  {
    end: 1207,
    date: "11-02-2016",
  },
  {
    end: 1210,
    date: "12-02-2016",
  },
  {
    end: 1012,
    date: "112-02-2016",
  },
  {
    end: 1200,
    date: "14-02-2016",
  },
  {
    end: 1207,
    date: "15-02-2016",
  },
  {
    end: 1210,
    date: "16-02-2016",
  },
  {
    end: 1220,
    date: "17-02-2016",
  },
  {
    end: 1212,
    date: "18-02-2016",
  },
  {
    end: 1215,
    date: "19-02-2016",
  },
  {
    end: 1220,
    date: "20-02-2016",
  },
  {
    end: 1222,
    date: "21-02-2016",
  },
  {
    end: 1221,
    date: "22-02-2016",
  },
  {
    end: 1224,
    date: "212-02-2016",
  },
  {
    end: 1200,
    date: "24-02-2016",
  },
  {
    end: 1212,
    date: "25-02-2016",
  },
  {
    end: 1215,
    date: "26-02-2016",
  },
  {
    end: 1220,
    date: "27-02-2016",
  },
  {
    end: 1222,
    date: "28-02-2016",
  },
  {
    end: 1222,
    date: "1-03-2016",
  },
  {
    end: 1225,
    date: "02-03-2016",
  },
  {
    end: 1227,
    date: "03-03-2016",
  },
  {
    end: 340,
    date: "04-03-2016",
  },
  {
    end: 345,
    date: "05-03-2016",
  },
  {
    end: 340,
    date: "06-03-2016",
  },
  {
    end: 343,
    date: "07-03-2016",
  },
  {
    end: 346,
    date: "08-03-2016",
  },
  {
    end: 356,
    date: "09-03-2016",
  },
  {
    end: 353,
    date: "10-03-2016",
  },
  {
    end: 355,
    date: "11-03-2016",
  },
  {
    end: 359,
    date: "12-03-2016",
  },
  {
    end: 353,
    date: "13-03-2016",
  },
  {
    end: 363,
    date: "14-03-2016",
  },
  {
    end: 366,
    date: "15-03-2016",
  },
  {
    end: 364,
    date: "16-03-2016",
  },
  {
    end: 369,
    date: "17-03-2016",
  },
  {
    end: 370,
    date: "18-03-2016",
  },
  {
    end: 373,
    date: "19-03-2016",
  },
  {
    end: 380,
    date: "20-03-2016",
  },
  {
    end: 366,
    date: "21-03-2016",
  },
  {
    end: 369,
    date: "22-03-2016",
  },
  {
    end: 376,
    date: "23-03-2016",
  },
  {
    end: 379,
    date: "24-03-2016",
  },
  {
    end: 381,
    date: "25-03-2016",
  },
  {
    end: 384,
    date: "26-03-2016",
  },
  {
    end: 389,
    date: "27-03-2016",
  },
  {
    end: 390,
    date: "28-03-2016",
  },
  {
    end: 393,
    date: "29-03-2016",
  },
  {
    end: 394,
    date: "30-03-2016",
  },
  {
    end: 401,
    date: "31-03-2016",
  },
  {
    end: 403,
    date: "01-04-2016",
  },
  {
    end: 405,
    date: "02-04-2016",
  },
  {
    end: 410,
    date: "03-04-2016",
  },
  {
    end: 408,
    date: "04-04-2016",
  },
  {
    end: 411,
    date: "05-04-2016",
  },
  {
    end: 413,
    date: "06-04-2016",
  },
  {
    end: 423,
    date: "07-04-2016",
  },
  {
    end: 417,
    date: "08-04-2016",
  },
  {
    end: 420,
    date: "09-04-2016",
  },
  {
    end: 423,
    date: "10-04-2016",
  },
  {
    end: 444,
    date: "11-04-2016",
  },
  {
    end: 440,
    date: "12-04-2016",
  },
  {
    end: 430,
    date: "13-04-2016",
  },
  {
    end: 422,
    date: "14-04-2016",
  },
  {
    end: 428,
    date: "15-04-2016",
  },
  {
    end: 430,
    date: "16-04-2016",
  },
  {
    end: 423,
    date: "17-04-2016",
  },
  {
    end: 438,
    date: "18-04-2016",
  },
  {
    end: 437,
    date: "19-04-2016",
  },
  {
    end: 423,
    date: "20-04-2016",
  },
  {
    end: 420,
    date: "21-04-2016",
  },
  {
    end: 423,
    date: "22-04-2016",
  },
  {
    end: 414,
    date: "23-04-2016",
  },
  {
    end: 423,
    date: "24-04-2016",
  },
  {
    end: 429,
    date: "25-04-2016",
  },
  {
    end: 420,
    date: "26-04-2016",
  },
  {
    end: 413,
    date: "27-04-2016",
  },
  {
    end: 408,
    date: "28-04-2016",
  },
  {
    end: 404,
    date: "29-04-2016",
  },
  {
    end: 389,
    date: "30-04-2016",
  },
  {
    end: 400,
    date: "01-05-2016",
  },
  {
    end: 410,
    date: "02-05-2016",
  },
  {
    end: 413,
    date: "03-05-2016",
  },
  {
    end: 420,
    date: "04-05-2016",
  },
  {
    end: 426,
    date: "05-05-2016",
  },
  {
    end: 429,
    date: "06-05-2016",
  },
  {
    end: 440,
    date: "07-05-2016",
  },
  {
    end: 450,
    date: "08-05-2016",
  },
  {
    end: 440,
    date: "09-05-2016",
  },
  {
    end: 444,
    date: "10-05-2016",
  },
  {
    end: 448,
    date: "11-05-2016",
  },
  {
    end: 454,
    date: "12-05-2016",
  },
  {
    end: 464,
    date: "13-05-2016",
  },
  {
    end: 469,
    date: "14-05-2016",
  },
  {
    end: 470,
    date: "15-05-2016",
  },
  {
    end: 474,
    date: "16-05-2016",
  },
  {
    end: 478,
    date: "17-05-2016",
  },
  {
    end: 490,
    date: "18-05-2016",
  },
  {
    end: 449,
    date: "19-05-2016",
  },
  {
    end: 464,
    date: "20-05-2016",
  },
  {
    end: 470,
    date: "21-05-2016",
  },
  {
    end: 474,
    date: "22-05-2016",
  },
  {
    end: 479,
    date: "23-05-2016",
  },
  {
    end: 484,
    date: "24-05-2016",
  },
  {
    end: 470,
    date: "25-05-2016",
  },
  {
    end: 474,
    date: "26-05-2016",
  },
  {
    end: 490,
    date: "27-05-2016",
  },
  {
    end: 494,
    date: "28-05-2016",
  },
  {
    end: 498,
    date: "29-05-2016",
  },
  {
    end: 500,
    date: "30-05-2016",
  },
  {
    end: 504,
    date: "31-05-2016",
  },
  {
    end: 508,
    date: "01-06-2016",
  },
  {
    end: 510,
    date: "02-06-2016",
  },
  {
    end: 510,
    date: "03-06-2016",
  },
  {
    end: 514,
    date: "04-06-2016",
  },
  {
    end: 524,
    date: "05-06-2016",
  },
  {
    end: 528,
    date: "06-06-2016",
  },
  {
    end: 534,
    date: "07-06-2016",
  },
  {
    end: 533,
    date: "08-06-2016",
  },
  {
    end: 538,
    date: "09-06-2016",
  },
  {
    end: 540,
    date: "10-06-2016",
  },
  {
    end: 544,
    date: "11-06-2016",
  },
  {
    end: 542,
    date: "12-06-2016",
  },
  {
    end: 546,
    date: "13-06-2016",
  },
  {
    end: 548,
    date: "14-06-2016",
  },
  {
    end: 550,
    date: "15-06-2016",
  },
  {
    end: 540,
    date: "16-06-2016",
  },
  {
    end: 545,
    date: "17-06-2016",
  },
  {
    end: 547,
    date: "18-06-2016",
  },
  {
    end: 564,
    date: "19-06-2016",
  },
  {
    end: 568,
    date: "20-06-2016",
  },
  {
    end: 574,
    date: "21-06-2016",
  },
  {
    end: 566,
    date: "22-06-2016",
  },
  {
    end: 568,
    date: "23-06-2016",
  },
  {
    end: 563,
    date: "24-06-2016",
  },
  {
    end: 580,
    date: "25-06-2016",
  },
  {
    end: 584,
    date: "26-06-2016",
  },
  {
    end: 594,
    date: "27-06-2016",
  },
  {
    end: 590,
    date: "28-06-2016",
  },
  {
    end: 592,
    date: "29-06-2016",
  },
  {
    end: 597,
    date: "30-06-2016",
  },
  {
    end: 600,
    date: "01-07-2016",
  },
  {
    end: 603,
    date: "02-07-2016",
  },
  {
    end: 607,
    date: "03-07-2016",
  },
  {
    end: 603,
    date: "04-07-2016",
  },
  {
    end: 610,
    date: "05-07-2016",
  },
  {
    end: 613,
    date: "06-07-2016",
  },
  {
    end: 617,
    date: "07-07-2016",
  },
  {
    end: 633,
    date: "08-07-2016",
  },
  {
    end: 620,
    date: "09-07-2016",
  },
  {
    end: 623,
    date: "10-07-2016",
  },
  {
    end: 627,
    date: "11-07-2016",
  },
  {
    end: 629,
    date: "12-07-2016",
  },
  {
    end: 634,
    date: "13-07-2016",
  },
  {
    end: 637,
    date: "14-07-2016",
  },
  {
    end: 639,
    date: "15-07-2016",
  },
  {
    end: 640,
    date: "16-07-2016",
  },
  {
    end: 643,
    date: "17-07-2016",
  },
  {
    end: 647,
    date: "18-07-2016",
  },
  {
    end: 644,
    date: "19-07-2016",
  },
  {
    end: 643,
    date: "20-07-2016",
  },
  {
    end: 647,
    date: "21-07-2016",
  },
  {
    end: 650,
    date: "22-07-2016",
  },
  {
    end: 655,
    date: "23-07-2016",
  },
  {
    end: 653,
    date: "24-07-2016",
  },
  {
    end: 657,
    date: "25-07-2016",
  },
  {
    end: 660,
    date: "26-07-2016",
  },
  {
    end: 666,
    date: "27-07-2016",
  },
  {
    end: 663,
    date: "28-07-2016",
  },
  {
    end: 669,
    date: "29-07-2016",
  },
  {
    end: 668,
    date: "30-07-2016",
  },
  {
    end: 671,
    date: "31-07-2016",
  },
  {
    end: 673,
    date: "01-08-2016",
  },
  {
    end: 676,
    date: "02-08-2016",
  },
  {
    end: 678,
    date: "03-08-2016",
  },
  {
    end: 679,
    date: "04-08-2016",
  },
  {
    end: 670,
    date: "05-08-2016",
  },
  {
    end: 671,
    date: "06-08-2016",
  },
  {
    end: 676,
    date: "07-08-2016",
  },
  {
    end: 677,
    date: "08-08-2016",
  },
  {
    end: 680,
    date: "09-08-2016",
  },
  {
    end: 681,
    date: "10-08-2016",
  },
  {
    end: 684,
    date: "11-08-2016",
  },
  {
    end: 686,
    date: "12-08-2016",
  },
  {
    end: 688,
    date: "13-08-2016",
  },
  {
    end: 671,
    date: "14-08-2016",
  },
  {
    end: 681,
    date: "15-08-2016",
  },
  {
    end: 691,
    date: "16-08-2016",
  },
  {
    end: 688,
    date: "17-08-2016",
  },
  {
    end: 689,
    date: "18-08-2016",
  },
  {
    end: 694,
    date: "19-08-2016",
  },
  {
    end: 695,
    date: "20-08-2016",
  },
  {
    end: 698,
    date: "21-08-2016",
  },
  {
    end: 701,
    date: "22-08-2016",
  },
  {
    end: 711,
    date: "23-08-2016",
  },
  {
    end: 691,
    date: "24-08-2016",
  },
  {
    end: 697,
    date: "25-08-2016",
  },
  {
    end: 699,
    date: "26-08-2016",
  },
  {
    end: 691,
    date: "27-08-2016",
  },
  {
    end: 700,
    date: "28-08-2016",
  },
  {
    end: 704,
    date: "29-08-2016",
  },
  {
    end: 708,
    date: "30-08-2016",
  },
  {
    end: 703,
    date: "01-09-2016",
  },
  {
    end: 709,
    date: "02-09-2016",
  },
  {
    end: 711,
    date: "03-09-2016",
  },
  {
    end: 733,
    date: "04-09-2016",
  },
  {
    end: 713,
    date: "05-09-2016",
  },
  {
    end: 717,
    date: "06-09-2016",
  },
  {
    end: 722,
    date: "07-09-2016",
  },
  {
    end: 723,
    date: "08-09-2016",
  },
  {
    end: 727,
    date: "09-09-2016",
  },
  {
    end: 729,
    date: "10-09-2016",
  },
  {
    end: 731,
    date: "11-09-2016",
  },
  {
    end: 733,
    date: "12-09-2016",
  },
  {
    end: 735,
    date: "13-09-2016",
  },
  {
    end: 738,
    date: "14-09-2016",
  },
  {
    end: 744,
    date: "15-09-2016",
  },
  {
    end: 745,
    date: "16-09-2016",
  },
  {
    end: 748,
    date: "17-09-2016",
  },
  {
    end: 755,
    date: "18-09-2016",
  },
  {
    end: 733,
    date: "19-09-2016",
  },
  {
    end: 723,
    date: "20-09-2016",
  },
  {
    end: 648,
    date: "21-09-2016",
  },
  {
    end: 731,
    date: "22-09-2016",
  },
  {
    end: 737,
    date: "23-09-2016",
  },
  {
    end: 691,
    date: "24-09-2016",
  },
  {
    end: 697,
    date: "25-09-2016",
  },
  {
    end: 699,
    date: "26-09-2016",
  },
  {
    end: 691,
    date: "27-09-2016",
  },
  {
    end: 700,
    date: "28-09-2016",
  },
  {
    end: 704,
    date: "29-09-2016",
  },
  {
    end: 709,
    date: "30-09-2016",
  },
  {
    end: 709,
    date: "31-09-2016",
  },

  {
    end: 740,
    date: "01-10-2016",
  },
  {
    end: 745,
    date: "02-10-2016",
  },
  {
    end: 755,
    date: "03-10-2016",
  },
  {
    end: 757,
    date: "04-10-2016",
  },
  {
    end: 766,
    date: "05-10-2016",
  },
  {
    end: 750,
    date: "06-10-2016",
  },
  {
    end: 751,
    date: "07-10-2016",
  },
  {
    end: 759,
    date: "08-10-2016",
  },
  {
    end: 763,
    date: "10-10-2016",
  },
  {
    end: 767,
    date: "10-10-2016",
  },
  {
    end: 768,
    date: "11-10-2016",
  },
  {
    end: 771,
    date: "12-10-2016",
  },
  {
    end: 774,
    date: "13-10-2016",
  },
  {
    end: 778,
    date: "14-10-2016",
  },
  {
    end: 766,
    date: "15-10-2016",
  },
  {
    end: 780,
    date: "16-10-2016",
  },
  {
    end: 778,
    date: "17-10-2016",
  },
  {
    end: 785,
    date: "18-10-2016",
  },
  {
    end: 783,
    date: "19-10-2016",
  },
  {
    end: 788,
    date: "20-10-2016",
  },
  {
    end: 798,
    date: "21-10-2016",
  },
  {
    end: 791,
    date: "22-10-2016",
  },
  {
    end: 797,
    date: "23-10-2016",
  },
  {
    end: 801,
    date: "24-10-2016",
  },
  {
    end: 807,
    date: "25-10-2016",
  },
  {
    end: 809,
    date: "26-10-2016",
  },
  {
    end: 811,
    date: "27-10-2016",
  },
  {
    end: 800,
    date: "28-10-2016",
  },
  {
    end: 804,
    date: "29-10-2016",
  },
  {
    end: 810,
    date: "30-10-2016",
  },
  {
    end: 817,
    date: "31-10-2016",
  },

  {
    end: 820,
    date: "01-11-2016",
  },
  {
    end: 821,
    date: "02-11-2016",
  },
  {
    end: 825,
    date: "03-11-2016",
  },
  {
    end: 827,
    date: "04-11-2016",
  },
  {
    end: 830,
    date: "05-11-2016",
  },
  {
    end: 822,
    date: "06-11-2016",
  },
  {
    end: 832,
    date: "07-11-2016",
  },
  {
    end: 800,
    date: "08-11-2016",
  },
  {
    end: 783,
    date: "09-11-2016",
  },
  {
    end: 797,
    date: "10-11-2016",
  },
  {
    end: 788,
    date: "11-11-2016",
  },
  {
    end: 771,
    date: "12-11-2016",
  },
  {
    end: 774,
    date: "13-11-2016",
  },
  {
    end: 778,
    date: "14-11-2016",
  },
  {
    end: 766,
    date: "15-11-2016",
  },
  {
    end: 780,
    date: "16-11-2016",
  },
  {
    end: 778,
    date: "17-11-2016",
  },
  {
    end: 785,
    date: "18-11-2016",
  },
  {
    end: 783,
    date: "19-11-2016",
  },
  {
    end: 788,
    date: "20-11-2016",
  },
  {
    end: 798,
    date: "21-11-2016",
  },
  {
    end: 791,
    date: "22-11-2016",
  },
  {
    end: 797,
    date: "23-11-2016",
  },
  {
    end: 801,
    date: "24-11-2016",
  },
  {
    end: 807,
    date: "25-11-2016",
  },
  {
    end: 809,
    date: "26-11-2016",
  },
  {
    end: 811,
    date: "27-11-2016",
  },
  {
    end: 800,
    date: "28-11-2016",
  },
  {
    end: 804,
    date: "29-11-2016",
  },
  {
    end: 820,
    date: "30-11-2016",
  },
  {
    end: 840,
    date: "31-11-2016",
  },

  {
    end: 845,
    date: "01-12-2016",
  },
  {
    end: 850,
    date: "02-12-2016",
  },
  {
    end: 855,
    date: "03-12-2016",
  },
  {
    end: 859,
    date: "04-12-2016",
  },
  {
    end: 851,
    date: "05-12-2016",
  },
  {
    end: 865,
    date: "06-12-2016",
  },
  {
    end: 869,
    date: "07-12-2016",
  },
  {
    end: 877,
    date: "08-12-2016",
  },
  {
    end: 871,
    date: "09-12-2016",
  },
  {
    end: 877,
    date: "10-12-2016",
  },
  {
    end: 888,
    date: "11-12-2016",
  },
  {
    end: 881,
    date: "12-12-2016",
  },
  {
    end: 874,
    date: "13-12-2016",
  },
  {
    end: 888,
    date: "14-12-2016",
  },
  {
    end: 896,
    date: "15-12-2016",
  },
  {
    end: 890,
    date: "16-12-2016",
  },
  {
    end: 908,
    date: "17-12-2016",
  },
  {
    end: 900,
    date: "18-12-2016",
  },
  {
    end: 906,
    date: "19-12-2016",
  },
  {
    end: 909,
    date: "20-12-2016",
  },
  {
    end: 900,
    date: "21-12-2016",
  },
  {
    end: 891,
    date: "22-12-2016",
  },
  {
    end: 911,
    date: "23-12-2016",
  },
  {
    end: 921,
    date: "24-12-2016",
  },
  {
    end: 927,
    date: "25-12-2016",
  },
  {
    end: 930,
    date: "26-12-2016",
  },
  {
    end: 932,
    date: "27-12-2016",
  },
  {
    end: 940,
    date: "28-12-2016",
  },
  {
    end: 933,
    date: "29-12-2016",
  },
  {
    end: 940,
    date: "30-12-2016",
  },
  {
    end: 944,
    date: "31-12-2016",
  },
  // -- 2017 end

  {
    end: 243,
    date: "01-01-2017",
  },
  {
    end: 242,
    date: "02-01-2017",
  },
  {
    end: 244,
    date: "03-01-2017",
  },
  {
    end: 247,
    date: "04-01-2017",
  },
  {
    end: 248,
    date: "05-01-2017",
  },
  {
    end: 242,
    date: "06-01-2017",
  },
  {
    end: 244,
    date: "07-01-2017",
  },
  {
    end: 240,
    date: "08-01-2017",
  },
  {
    end: 243,
    date: "09-01-2017",
  },
  {
    end: 248,
    date: "10-01-2017",
  },
  {
    end: 973,
    date: "11-01-2017",
  },
  {
    end: 977,
    date: "12-01-2017",
  },
  {
    end: 978,
    date: "13-01-2017",
  },
  {
    end: 260,
    date: "14-01-2017",
  },
  {
    end: 977,
    date: "15-01-2017",
  },
  {
    end: 260,
    date: "16-01-2017",
  },
  {
    end: 262,
    date: "17-01-2017",
  },
  {
    end: 270,
    date: "18-01-2017",
  },
  {
    end: 273,
    date: "19-01-2017",
  },
  {
    end: 270,
    date: "20-01-2017",
  },
  {
    end: 274,
    date: "21-01-2017",
  },
  {
    end: 276,
    date: "22-01-2017",
  },
  {
    end: 278,
    date: "23-01-2017",
  },
  {
    end: 278,
    date: "24-01-2017",
  },
  {
    end: 280,
    date: "25-01-2017",
  },
  {
    end: 273,
    date: "26-01-2017",
  },
  {
    end: 276,
    date: "27-01-2017",
  },
  {
    end: 283,
    date: "28-01-2017",
  },
  {
    end: 293,
    date: "29-01-2017",
  },
  {
    end: 280,
    date: "30-01-2017",
  },
  {
    end: 286,
    date: "31-01-2017",
  },
  {
    end: 290,
    date: "01-02-2017",
  },
  {
    end: 293,
    date: "02-02-2017",
  },
  {
    end: 295,
    date: "03-02-2017",
  },
  {
    end: 298,
    date: "04-02-2017",
  },
  {
    end: 293,
    date: "05-02-2017",
  },
  {
    end: 297,
    date: "06-02-2017",
  },
  {
    end: 300,
    date: "07-02-2017",
  },
  {
    end: 293,
    date: "08-02-2017",
  },
  {
    end: 300,
    date: "09-02-2017",
  },
  {
    end: 303,
    date: "10-02-2017",
  },
  {
    end: 307,
    date: "11-02-2017",
  },
  {
    end: 310,
    date: "12-02-2017",
  },
  {
    end: 293,
    date: "13-02-2017",
  },
  {
    end: 300,
    date: "14-02-2017",
  },
  {
    end: 307,
    date: "15-02-2017",
  },
  {
    end: 310,
    date: "16-02-2017",
  },
  {
    end: 320,
    date: "17-02-2017",
  },
  {
    end: 313,
    date: "18-02-2017",
  },
  {
    end: 315,
    date: "19-02-2017",
  },
  {
    end: 320,
    date: "20-02-2017",
  },
  {
    end: 322,
    date: "21-02-2017",
  },
  {
    end: 321,
    date: "22-02-2017",
  },
  {
    end: 324,
    date: "23-02-2017",
  },
  {
    end: 300,
    date: "24-02-2017",
  },
  {
    end: 313,
    date: "25-02-2017",
  },
  {
    end: 315,
    date: "26-02-2017",
  },
  {
    end: 320,
    date: "27-02-2017",
  },
  {
    end: 323,
    date: "28-02-2017",
  },
  {
    end: 322,
    date: "1-03-2017",
  },
  {
    end: 325,
    date: "02-03-2017",
  },
  {
    end: 327,
    date: "03-03-2017",
  },
  {
    end: 340,
    date: "04-03-2017",
  },
  {
    end: 345,
    date: "05-03-2017",
  },
  {
    end: 340,
    date: "06-03-2017",
  },
  {
    end: 343,
    date: "07-03-2017",
  },
  {
    end: 346,
    date: "08-03-2017",
  },
  {
    end: 356,
    date: "09-03-2017",
  },
  {
    end: 353,
    date: "10-03-2017",
  },
  {
    end: 355,
    date: "11-03-2017",
  },
  {
    end: 359,
    date: "12-03-2017",
  },
  {
    end: 353,
    date: "13-03-2017",
  },
  {
    end: 363,
    date: "14-03-2017",
  },
  {
    end: 366,
    date: "15-03-2017",
  },
  {
    end: 364,
    date: "16-03-2017",
  },
  {
    end: 369,
    date: "17-03-2017",
  },
  {
    end: 370,
    date: "18-03-2017",
  },
  {
    end: 373,
    date: "19-03-2017",
  },
  {
    end: 380,
    date: "20-03-2017",
  },
  {
    end: 366,
    date: "21-03-2017",
  },
  {
    end: 369,
    date: "22-03-2017",
  },
  {
    end: 376,
    date: "23-03-2017",
  },
  {
    end: 379,
    date: "24-03-2017",
  },
  {
    end: 381,
    date: "25-03-2017",
  },
  {
    end: 384,
    date: "26-03-2017",
  },
  {
    end: 389,
    date: "27-03-2017",
  },
  {
    end: 390,
    date: "28-03-2017",
  },
  {
    end: 393,
    date: "29-03-2017",
  },
  {
    end: 394,
    date: "30-03-2017",
  },
  {
    end: 401,
    date: "31-03-2017",
  },
  {
    end: 403,
    date: "01-04-2017",
  },
  {
    end: 405,
    date: "02-04-2017",
  },
  {
    end: 410,
    date: "03-04-2017",
  },
  {
    end: 408,
    date: "04-04-2017",
  },
  {
    end: 411,
    date: "05-04-2017",
  },
  {
    end: 413,
    date: "06-04-2017",
  },
  {
    end: 423,
    date: "07-04-2017",
  },
  {
    end: 417,
    date: "08-04-2017",
  },
  {
    end: 420,
    date: "09-04-2017",
  },
  {
    end: 423,
    date: "10-04-2017",
  },
  {
    end: 444,
    date: "11-04-2017",
  },
  {
    end: 440,
    date: "12-04-2017",
  },
  {
    end: 430,
    date: "13-04-2017",
  },
  {
    end: 422,
    date: "14-04-2017",
  },
  {
    end: 428,
    date: "15-04-2017",
  },
  {
    end: 430,
    date: "16-04-2017",
  },
  {
    end: 423,
    date: "17-04-2017",
  },
  {
    end: 438,
    date: "18-04-2017",
  },
  {
    end: 437,
    date: "19-04-2017",
  },
  {
    end: 423,
    date: "20-04-2017",
  },
  {
    end: 420,
    date: "21-04-2017",
  },
  {
    end: 423,
    date: "22-04-2017",
  },
  {
    end: 414,
    date: "23-04-2017",
  },
  {
    end: 423,
    date: "24-04-2017",
  },
  {
    end: 429,
    date: "25-04-2017",
  },
  {
    end: 420,
    date: "26-04-2017",
  },
  {
    end: 413,
    date: "27-04-2017",
  },
  {
    end: 408,
    date: "28-04-2017",
  },
  {
    end: 404,
    date: "29-04-2017",
  },
  {
    end: 389,
    date: "30-04-2017",
  },
  {
    end: 400,
    date: "01-05-2017",
  },
  {
    end: 410,
    date: "02-05-2017",
  },
  {
    end: 413,
    date: "03-05-2017",
  },
  {
    end: 420,
    date: "04-05-2017",
  },
  {
    end: 426,
    date: "05-05-2017",
  },
  {
    end: 429,
    date: "06-05-2017",
  },
  {
    end: 440,
    date: "07-05-2017",
  },
  {
    end: 450,
    date: "08-05-2017",
  },
  {
    end: 440,
    date: "09-05-2017",
  },
  {
    end: 444,
    date: "10-05-2017",
  },
  {
    end: 448,
    date: "11-05-2017",
  },
  {
    end: 454,
    date: "12-05-2017",
  },
  {
    end: 464,
    date: "13-05-2017",
  },
  {
    end: 469,
    date: "14-05-2017",
  },
  {
    end: 470,
    date: "15-05-2017",
  },
  {
    end: 474,
    date: "16-05-2017",
  },
  {
    end: 478,
    date: "17-05-2017",
  },
  {
    end: 490,
    date: "18-05-2017",
  },
  {
    end: 449,
    date: "19-05-2017",
  },
  {
    end: 464,
    date: "20-05-2017",
  },
  {
    end: 470,
    date: "21-05-2017",
  },
  {
    end: 474,
    date: "22-05-2017",
  },
  {
    end: 479,
    date: "23-05-2017",
  },
  {
    end: 484,
    date: "24-05-2017",
  },
  {
    end: 470,
    date: "25-05-2017",
  },
  {
    end: 474,
    date: "26-05-2017",
  },
  {
    end: 490,
    date: "27-05-2017",
  },
  {
    end: 494,
    date: "28-05-2017",
  },
  {
    end: 498,
    date: "29-05-2017",
  },
  {
    end: 500,
    date: "30-05-2017",
  },
  {
    end: 504,
    date: "31-05-2017",
  },
  {
    end: 508,
    date: "01-06-2017",
  },
  {
    end: 510,
    date: "02-06-2017",
  },
  {
    end: 510,
    date: "03-06-2017",
  },
  {
    end: 514,
    date: "04-06-2017",
  },
  {
    end: 524,
    date: "05-06-2017",
  },
  {
    end: 528,
    date: "06-06-2017",
  },
  {
    end: 534,
    date: "07-06-2017",
  },
  {
    end: 533,
    date: "08-06-2017",
  },
  {
    end: 538,
    date: "09-06-2017",
  },
  {
    end: 540,
    date: "10-06-2017",
  },
  {
    end: 544,
    date: "11-06-2017",
  },
  {
    end: 542,
    date: "12-06-2017",
  },
  {
    end: 546,
    date: "13-06-2017",
  },
  {
    end: 548,
    date: "14-06-2017",
  },
  {
    end: 550,
    date: "15-06-2017",
  },
  {
    end: 540,
    date: "16-06-2017",
  },
  {
    end: 545,
    date: "17-06-2017",
  },
  {
    end: 547,
    date: "18-06-2017",
  },
  {
    end: 564,
    date: "19-06-2017",
  },
  {
    end: 568,
    date: "20-06-2017",
  },
  {
    end: 574,
    date: "21-06-2017",
  },
  {
    end: 566,
    date: "22-06-2017",
  },
  {
    end: 568,
    date: "23-06-2017",
  },
  {
    end: 563,
    date: "24-06-2017",
  },
  {
    end: 580,
    date: "25-06-2017",
  },
  {
    end: 584,
    date: "26-06-2017",
  },
  {
    end: 594,
    date: "27-06-2017",
  },
  {
    end: 590,
    date: "28-06-2017",
  },
  {
    end: 592,
    date: "29-06-2017",
  },
  {
    end: 597,
    date: "30-06-2017",
  },
  {
    end: 600,
    date: "01-07-2017",
  },
  {
    end: 603,
    date: "02-07-2017",
  },
  {
    end: 607,
    date: "03-07-2017",
  },
  {
    end: 603,
    date: "04-07-2017",
  },
  {
    end: 610,
    date: "05-07-2017",
  },
  {
    end: 613,
    date: "06-07-2017",
  },
  {
    end: 617,
    date: "07-07-2017",
  },
  {
    end: 633,
    date: "08-07-2017",
  },
  {
    end: 620,
    date: "09-07-2017",
  },
  {
    end: 623,
    date: "10-07-2017",
  },
  {
    end: 627,
    date: "11-07-2017",
  },
  {
    end: 629,
    date: "12-07-2017",
  },
  {
    end: 634,
    date: "13-07-2017",
  },
  {
    end: 637,
    date: "14-07-2017",
  },
  {
    end: 639,
    date: "15-07-2017",
  },
  {
    end: 640,
    date: "16-07-2017",
  },
  {
    end: 643,
    date: "17-07-2017",
  },
  {
    end: 647,
    date: "18-07-2017",
  },
  {
    end: 644,
    date: "19-07-2017",
  },
  {
    end: 643,
    date: "20-07-2017",
  },
  {
    end: 647,
    date: "21-07-2017",
  },
  {
    end: 650,
    date: "22-07-2017",
  },
  {
    end: 655,
    date: "23-07-2017",
  },
  {
    end: 653,
    date: "24-07-2017",
  },
  {
    end: 657,
    date: "25-07-2017",
  },
  {
    end: 660,
    date: "26-07-2017",
  },
  {
    end: 666,
    date: "27-07-2017",
  },
  {
    end: 663,
    date: "28-07-2017",
  },
  {
    end: 669,
    date: "29-07-2017",
  },
  {
    end: 668,
    date: "30-07-2017",
  },
  {
    end: 671,
    date: "31-07-2017",
  },
  {
    end: 673,
    date: "01-08-2017",
  },
  {
    end: 676,
    date: "02-08-2017",
  },
  {
    end: 678,
    date: "03-08-2017",
  },
  {
    end: 679,
    date: "04-08-2017",
  },
  {
    end: 670,
    date: "05-08-2017",
  },
  {
    end: 671,
    date: "06-08-2017",
  },
  {
    end: 676,
    date: "07-08-2017",
  },
  {
    end: 677,
    date: "08-08-2017",
  },
  {
    end: 680,
    date: "09-08-2017",
  },
  {
    end: 681,
    date: "10-08-2017",
  },
  {
    end: 684,
    date: "11-08-2017",
  },
  {
    end: 686,
    date: "12-08-2017",
  },
  {
    end: 688,
    date: "13-08-2017",
  },
  {
    end: 671,
    date: "14-08-2017",
  },
  {
    end: 681,
    date: "15-08-2017",
  },
  {
    end: 691,
    date: "16-08-2017",
  },
  {
    end: 688,
    date: "17-08-2017",
  },
  {
    end: 689,
    date: "18-08-2017",
  },
  {
    end: 694,
    date: "19-08-2017",
  },
  {
    end: 695,
    date: "20-08-2017",
  },
  {
    end: 698,
    date: "21-08-2017",
  },
  {
    end: 701,
    date: "22-08-2017",
  },
  {
    end: 711,
    date: "23-08-2017",
  },
  {
    end: 691,
    date: "24-08-2017",
  },
  {
    end: 697,
    date: "25-08-2017",
  },
  {
    end: 699,
    date: "26-08-2017",
  },
  {
    end: 691,
    date: "27-08-2017",
  },
  {
    end: 700,
    date: "28-08-2017",
  },
  {
    end: 704,
    date: "29-08-2017",
  },
  {
    end: 708,
    date: "30-08-2017",
  },
  {
    end: 703,
    date: "01-09-2017",
  },
  {
    end: 709,
    date: "02-09-2017",
  },
  {
    end: 711,
    date: "03-09-2017",
  },
  {
    end: 733,
    date: "04-09-2017",
  },
  {
    end: 713,
    date: "05-09-2017",
  },
  {
    end: 717,
    date: "06-09-2017",
  },
  {
    end: 722,
    date: "07-09-2017",
  },
  {
    end: 723,
    date: "08-09-2017",
  },
  {
    end: 727,
    date: "09-09-2017",
  },
  {
    end: 729,
    date: "10-09-2017",
  },
  {
    end: 731,
    date: "11-09-2017",
  },
  {
    end: 733,
    date: "12-09-2017",
  },
  {
    end: 735,
    date: "13-09-2017",
  },
  {
    end: 738,
    date: "14-09-2017",
  },
  {
    end: 744,
    date: "15-09-2017",
  },
  {
    end: 745,
    date: "16-09-2017",
  },
  {
    end: 748,
    date: "17-09-2017",
  },
  {
    end: 755,
    date: "18-09-2017",
  },
  {
    end: 733,
    date: "19-09-2017",
  },
  {
    end: 723,
    date: "20-09-2017",
  },
  {
    end: 648,
    date: "21-09-2017",
  },
  {
    end: 731,
    date: "22-09-2017",
  },
  {
    end: 737,
    date: "23-09-2017",
  },
  {
    end: 691,
    date: "24-09-2017",
  },
  {
    end: 697,
    date: "25-09-2017",
  },
  {
    end: 699,
    date: "26-09-2017",
  },
  {
    end: 691,
    date: "27-09-2017",
  },
  {
    end: 700,
    date: "28-09-2017",
  },
  {
    end: 704,
    date: "29-09-2017",
  },
  {
    end: 709,
    date: "30-09-2017",
  },
  {
    end: 709,
    date: "31-09-2017",
  },

  {
    end: 740,
    date: "01-10-2017",
  },
  {
    end: 745,
    date: "02-10-2017",
  },
  {
    end: 755,
    date: "03-10-2017",
  },
  {
    end: 757,
    date: "04-10-2017",
  },
  {
    end: 766,
    date: "05-10-2017",
  },
  {
    end: 750,
    date: "06-10-2017",
  },
  {
    end: 751,
    date: "07-10-2017",
  },
  {
    end: 759,
    date: "08-10-2017",
  },
  {
    end: 763,
    date: "10-10-2017",
  },
  {
    end: 767,
    date: "10-10-2017",
  },
  {
    end: 768,
    date: "11-10-2017",
  },
  {
    end: 771,
    date: "12-10-2017",
  },
  {
    end: 774,
    date: "13-10-2017",
  },
  {
    end: 778,
    date: "14-10-2017",
  },
  {
    end: 766,
    date: "15-10-2017",
  },
  {
    end: 780,
    date: "16-10-2017",
  },
  {
    end: 778,
    date: "17-10-2017",
  },
  {
    end: 785,
    date: "18-10-2017",
  },
  {
    end: 783,
    date: "19-10-2017",
  },
  {
    end: 788,
    date: "20-10-2017",
  },
  {
    end: 798,
    date: "21-10-2017",
  },
  {
    end: 791,
    date: "22-10-2017",
  },
  {
    end: 797,
    date: "23-10-2017",
  },
  {
    end: 801,
    date: "24-10-2017",
  },
  {
    end: 807,
    date: "25-10-2017",
  },
  {
    end: 809,
    date: "26-10-2017",
  },
  {
    end: 811,
    date: "27-10-2017",
  },
  {
    end: 800,
    date: "28-10-2017",
  },
  {
    end: 804,
    date: "29-10-2017",
  },
  {
    end: 810,
    date: "30-10-2017",
  },
  {
    end: 817,
    date: "31-10-2017",
  },

  {
    end: 820,
    date: "01-11-2017",
  },
  {
    end: 821,
    date: "02-11-2017",
  },
  {
    end: 825,
    date: "03-11-2017",
  },
  {
    end: 827,
    date: "04-11-2017",
  },
  {
    end: 830,
    date: "05-11-2017",
  },
  {
    end: 822,
    date: "06-11-2017",
  },
  {
    end: 832,
    date: "07-11-2017",
  },
  {
    end: 800,
    date: "08-11-2017",
  },
  {
    end: 783,
    date: "09-11-2017",
  },
  {
    end: 797,
    date: "10-11-2017",
  },
  {
    end: 788,
    date: "11-11-2017",
  },
  {
    end: 771,
    date: "12-11-2017",
  },
  {
    end: 774,
    date: "13-11-2017",
  },
  {
    end: 778,
    date: "14-11-2017",
  },
  {
    end: 766,
    date: "15-11-2017",
  },
  {
    end: 780,
    date: "16-11-2017",
  },
  {
    end: 778,
    date: "17-11-2017",
  },
  {
    end: 785,
    date: "18-11-2017",
  },
  {
    end: 783,
    date: "19-11-2017",
  },
  {
    end: 788,
    date: "20-11-2017",
  },
  {
    end: 798,
    date: "21-11-2017",
  },
  {
    end: 791,
    date: "22-11-2017",
  },
  {
    end: 797,
    date: "23-11-2017",
  },
  {
    end: 801,
    date: "24-11-2017",
  },
  {
    end: 807,
    date: "25-11-2017",
  },
  {
    end: 809,
    date: "26-11-2017",
  },
  {
    end: 811,
    date: "27-11-2017",
  },
  {
    end: 800,
    date: "28-11-2017",
  },
  {
    end: 804,
    date: "29-11-2017",
  },
  {
    end: 820,
    date: "30-11-2017",
  },
  {
    end: 840,
    date: "31-11-2017",
  },

  {
    end: 845,
    date: "01-12-2017",
  },
  {
    end: 850,
    date: "02-12-2017",
  },
  {
    end: 855,
    date: "03-12-2017",
  },
  {
    end: 859,
    date: "04-12-2017",
  },
  {
    end: 851,
    date: "05-12-2017",
  },
  {
    end: 865,
    date: "06-12-2017",
  },
  {
    end: 869,
    date: "07-12-2017",
  },
  {
    end: 877,
    date: "08-12-2017",
  },
  {
    end: 871,
    date: "09-12-2017",
  },
  {
    end: 877,
    date: "10-12-2017",
  },
  {
    end: 888,
    date: "11-12-2017",
  },
  {
    end: 881,
    date: "12-12-2017",
  },
  {
    end: 874,
    date: "13-12-2017",
  },
  {
    end: 888,
    date: "14-12-2017",
  },
  {
    end: 896,
    date: "15-12-2017",
  },
  {
    end: 890,
    date: "16-12-2017",
  },
  {
    end: 908,
    date: "17-12-2017",
  },
  {
    end: 900,
    date: "18-12-2017",
  },
  {
    end: 906,
    date: "19-12-2017",
  },
  {
    end: 909,
    date: "20-12-2017",
  },
  {
    end: 900,
    date: "21-12-2017",
  },
  {
    end: 891,
    date: "22-12-2017",
  },
  {
    end: 911,
    date: "23-12-2017",
  },
  {
    end: 921,
    date: "24-12-2017",
  },
  {
    end: 927,
    date: "25-12-2017",
  },
  {
    end: 930,
    date: "26-12-2017",
  },
  {
    end: 932,
    date: "27-12-2017",
  },
  {
    end: 940,
    date: "28-12-2017",
  },
  {
    end: 933,
    date: "29-12-2017",
  },
  {
    end: 940,
    date: "30-12-2017",
  },
  {
    end: 944,
    date: "31-12-2017",
  },
  // -- 2018 start

  {
    end: 243,
    date: "01-01-2018",
  },
  {
    end: 242,
    date: "02-01-2018",
  },
  {
    end: 244,
    date: "03-01-2018",
  },
  {
    end: 247,
    date: "04-01-2018",
  },
  {
    end: 248,
    date: "05-01-2018",
  },
  {
    end: 242,
    date: "06-01-2018",
  },
  {
    end: 244,
    date: "07-01-2018",
  },
  {
    end: 240,
    date: "08-01-2018",
  },
  {
    end: 243,
    date: "09-01-2018",
  },
  {
    end: 248,
    date: "10-01-2018",
  },
  {
    end: 973,
    date: "11-01-2018",
  },
  {
    end: 977,
    date: "12-01-2018",
  },
  {
    end: 978,
    date: "13-01-2018",
  },
  {
    end: 260,
    date: "14-01-2018",
  },
  {
    end: 977,
    date: "15-01-2018",
  },
  {
    end: 260,
    date: "16-01-2018",
  },
  {
    end: 262,
    date: "17-01-2018",
  },
  {
    end: 270,
    date: "18-01-2018",
  },
  {
    end: 273,
    date: "19-01-2018",
  },
  {
    end: 270,
    date: "20-01-2018",
  },
  {
    end: 274,
    date: "21-01-2018",
  },
  {
    end: 276,
    date: "22-01-2018",
  },
  {
    end: 278,
    date: "23-01-2018",
  },
  {
    end: 278,
    date: "24-01-2018",
  },
  {
    end: 280,
    date: "25-01-2018",
  },
  {
    end: 273,
    date: "26-01-2018",
  },
  {
    end: 276,
    date: "27-01-2018",
  },
  {
    end: 283,
    date: "28-01-2018",
  },
  {
    end: 293,
    date: "29-01-2018",
  },
  {
    end: 280,
    date: "30-01-2018",
  },
  {
    end: 286,
    date: "31-01-2018",
  },
  {
    end: 290,
    date: "01-02-2018",
  },
  {
    end: 293,
    date: "02-02-2018",
  },
  {
    end: 295,
    date: "03-02-2018",
  },
  {
    end: 298,
    date: "04-02-2018",
  },
  {
    end: 293,
    date: "05-02-2018",
  },
  {
    end: 297,
    date: "06-02-2018",
  },
  {
    end: 300,
    date: "07-02-2018",
  },
  {
    end: 293,
    date: "08-02-2018",
  },
  {
    end: 300,
    date: "09-02-2018",
  },
  {
    end: 303,
    date: "10-02-2018",
  },
  {
    end: 307,
    date: "11-02-2018",
  },
  {
    end: 310,
    date: "12-02-2018",
  },
  {
    end: 293,
    date: "13-02-2018",
  },
  {
    end: 300,
    date: "14-02-2018",
  },
  {
    end: 307,
    date: "15-02-2018",
  },
  {
    end: 310,
    date: "16-02-2018",
  },
  {
    end: 320,
    date: "17-02-2018",
  },
  {
    end: 313,
    date: "18-02-2018",
  },
  {
    end: 315,
    date: "19-02-2018",
  },
  {
    end: 320,
    date: "20-02-2018",
  },
  {
    end: 322,
    date: "21-02-2018",
  },
  {
    end: 321,
    date: "22-02-2018",
  },
  {
    end: 324,
    date: "23-02-2018",
  },
  {
    end: 300,
    date: "24-02-2018",
  },
  {
    end: 313,
    date: "25-02-2018",
  },
  {
    end: 315,
    date: "26-02-2018",
  },
  {
    end: 320,
    date: "27-02-2018",
  },
  {
    end: 323,
    date: "28-02-2018",
  },
  {
    end: 322,
    date: "1-03-2018",
  },
  {
    end: 325,
    date: "02-03-2018",
  },
  {
    end: 327,
    date: "03-03-2018",
  },
  {
    end: 340,
    date: "04-03-2018",
  },
  {
    end: 345,
    date: "05-03-2018",
  },
  {
    end: 340,
    date: "06-03-2018",
  },
  {
    end: 343,
    date: "07-03-2018",
  },
  {
    end: 346,
    date: "08-03-2018",
  },
  {
    end: 356,
    date: "09-03-2018",
  },
  {
    end: 353,
    date: "10-03-2018",
  },
  {
    end: 355,
    date: "11-03-2018",
  },
  {
    end: 359,
    date: "12-03-2018",
  },
  {
    end: 353,
    date: "13-03-2018",
  },
  {
    end: 363,
    date: "14-03-2018",
  },
  {
    end: 366,
    date: "15-03-2018",
  },
  {
    end: 364,
    date: "16-03-2018",
  },
  {
    end: 369,
    date: "17-03-2018",
  },
  {
    end: 370,
    date: "18-03-2018",
  },
  {
    end: 373,
    date: "19-03-2018",
  },
  {
    end: 380,
    date: "20-03-2018",
  },
  {
    end: 366,
    date: "21-03-2018",
  },
  {
    end: 369,
    date: "22-03-2018",
  },
  {
    end: 376,
    date: "23-03-2018",
  },
  {
    end: 379,
    date: "24-03-2018",
  },
  {
    end: 381,
    date: "25-03-2018",
  },
  {
    end: 384,
    date: "26-03-2018",
  },
  {
    end: 389,
    date: "27-03-2018",
  },
  {
    end: 390,
    date: "28-03-2018",
  },
  {
    end: 393,
    date: "29-03-2018",
  },
  {
    end: 394,
    date: "30-03-2018",
  },
  {
    end: 401,
    date: "31-03-2018",
  },
  {
    end: 403,
    date: "01-04-2018",
  },
  {
    end: 405,
    date: "02-04-2018",
  },
  {
    end: 410,
    date: "03-04-2018",
  },
  {
    end: 408,
    date: "04-04-2018",
  },
  {
    end: 411,
    date: "05-04-2018",
  },
  {
    end: 413,
    date: "06-04-2018",
  },
  {
    end: 423,
    date: "07-04-2018",
  },
  {
    end: 417,
    date: "08-04-2018",
  },
  {
    end: 420,
    date: "09-04-2018",
  },
  {
    end: 423,
    date: "10-04-2018",
  },
  {
    end: 444,
    date: "11-04-2018",
  },
  {
    end: 440,
    date: "12-04-2018",
  },
  {
    end: 430,
    date: "13-04-2018",
  },
  {
    end: 422,
    date: "14-04-2018",
  },
  {
    end: 428,
    date: "15-04-2018",
  },
  {
    end: 430,
    date: "16-04-2018",
  },
  {
    end: 423,
    date: "17-04-2018",
  },
  {
    end: 438,
    date: "18-04-2018",
  },
  {
    end: 437,
    date: "19-04-2018",
  },
  {
    end: 423,
    date: "20-04-2018",
  },
  {
    end: 420,
    date: "21-04-2018",
  },
  {
    end: 423,
    date: "22-04-2018",
  },
  {
    end: 414,
    date: "23-04-2018",
  },
  {
    end: 423,
    date: "24-04-2018",
  },
  {
    end: 429,
    date: "25-04-2018",
  },
  {
    end: 420,
    date: "26-04-2018",
  },
  {
    end: 413,
    date: "27-04-2018",
  },
  {
    end: 408,
    date: "28-04-2018",
  },
  {
    end: 404,
    date: "29-04-2018",
  },
  {
    end: 389,
    date: "30-04-2018",
  },
  {
    end: 400,
    date: "01-05-2018",
  },
  {
    end: 410,
    date: "02-05-2018",
  },
  {
    end: 413,
    date: "03-05-2018",
  },
  {
    end: 420,
    date: "04-05-2018",
  },
  {
    end: 426,
    date: "05-05-2018",
  },
  {
    end: 429,
    date: "06-05-2018",
  },
  {
    end: 440,
    date: "07-05-2018",
  },
  {
    end: 450,
    date: "08-05-2018",
  },
  {
    end: 440,
    date: "09-05-2018",
  },
  {
    end: 444,
    date: "10-05-2018",
  },
  {
    end: 448,
    date: "11-05-2018",
  },
  {
    end: 454,
    date: "12-05-2018",
  },
  {
    end: 464,
    date: "13-05-2018",
  },
  {
    end: 469,
    date: "14-05-2018",
  },
  {
    end: 470,
    date: "15-05-2018",
  },
  {
    end: 474,
    date: "16-05-2018",
  },
  {
    end: 478,
    date: "17-05-2018",
  },
  {
    end: 490,
    date: "18-05-2018",
  },
  {
    end: 449,
    date: "19-05-2018",
  },
  {
    end: 464,
    date: "20-05-2018",
  },
  {
    end: 470,
    date: "21-05-2018",
  },
  {
    end: 474,
    date: "22-05-2018",
  },
  {
    end: 479,
    date: "23-05-2018",
  },
  {
    end: 484,
    date: "24-05-2018",
  },
  {
    end: 470,
    date: "25-05-2018",
  },
  {
    end: 474,
    date: "26-05-2018",
  },
  {
    end: 490,
    date: "27-05-2018",
  },
  {
    end: 494,
    date: "28-05-2018",
  },
  {
    end: 498,
    date: "29-05-2018",
  },
  {
    end: 500,
    date: "30-05-2018",
  },
  {
    end: 504,
    date: "31-05-2018",
  },
  {
    end: 508,
    date: "01-06-2018",
  },
  {
    end: 510,
    date: "02-06-2018",
  },
  {
    end: 510,
    date: "03-06-2018",
  },
  {
    end: 514,
    date: "04-06-2018",
  },
  {
    end: 524,
    date: "05-06-2018",
  },
  {
    end: 528,
    date: "06-06-2018",
  },
  {
    end: 534,
    date: "07-06-2018",
  },
  {
    end: 533,
    date: "08-06-2018",
  },
  {
    end: 538,
    date: "09-06-2018",
  },
  {
    end: 540,
    date: "10-06-2018",
  },
  {
    end: 544,
    date: "11-06-2018",
  },
  {
    end: 542,
    date: "12-06-2018",
  },
  {
    end: 546,
    date: "13-06-2018",
  },
  {
    end: 548,
    date: "14-06-2018",
  },
  {
    end: 550,
    date: "15-06-2018",
  },
  {
    end: 540,
    date: "16-06-2018",
  },
  {
    end: 545,
    date: "17-06-2018",
  },
  {
    end: 547,
    date: "18-06-2018",
  },
  {
    end: 564,
    date: "19-06-2018",
  },
  {
    end: 568,
    date: "20-06-2018",
  },
  {
    end: 574,
    date: "21-06-2018",
  },
  {
    end: 566,
    date: "22-06-2018",
  },
  {
    end: 568,
    date: "23-06-2018",
  },
  {
    end: 563,
    date: "24-06-2018",
  },
  {
    end: 580,
    date: "25-06-2018",
  },
  {
    end: 584,
    date: "26-06-2018",
  },
  {
    end: 594,
    date: "27-06-2018",
  },
  {
    end: 590,
    date: "28-06-2018",
  },
  {
    end: 592,
    date: "29-06-2018",
  },
  {
    end: 597,
    date: "30-06-2018",
  },
  {
    end: 600,
    date: "01-07-2018",
  },
  {
    end: 603,
    date: "02-07-2018",
  },
  {
    end: 607,
    date: "03-07-2018",
  },
  {
    end: 603,
    date: "04-07-2018",
  },
  {
    end: 610,
    date: "05-07-2018",
  },
  {
    end: 613,
    date: "06-07-2018",
  },
  {
    end: 617,
    date: "07-07-2018",
  },
  {
    end: 633,
    date: "08-07-2018",
  },
  {
    end: 620,
    date: "09-07-2018",
  },
  {
    end: 623,
    date: "10-07-2018",
  },
  {
    end: 627,
    date: "11-07-2018",
  },
  {
    end: 629,
    date: "12-07-2018",
  },
  {
    end: 634,
    date: "13-07-2018",
  },
  {
    end: 637,
    date: "14-07-2018",
  },
  {
    end: 639,
    date: "15-07-2018",
  },
  {
    end: 640,
    date: "16-07-2018",
  },
  {
    end: 643,
    date: "17-07-2018",
  },
  {
    end: 647,
    date: "18-07-2018",
  },
  {
    end: 644,
    date: "19-07-2018",
  },
  {
    end: 643,
    date: "20-07-2018",
  },
  {
    end: 647,
    date: "21-07-2018",
  },
  {
    end: 650,
    date: "22-07-2018",
  },
  {
    end: 655,
    date: "23-07-2018",
  },
  {
    end: 653,
    date: "24-07-2018",
  },
  {
    end: 657,
    date: "25-07-2018",
  },
  {
    end: 660,
    date: "26-07-2018",
  },
  {
    end: 666,
    date: "27-07-2018",
  },
  {
    end: 663,
    date: "28-07-2018",
  },
  {
    end: 669,
    date: "29-07-2018",
  },
  {
    end: 668,
    date: "30-07-2018",
  },
  {
    end: 671,
    date: "31-07-2018",
  },
  {
    end: 673,
    date: "01-08-2018",
  },
  {
    end: 676,
    date: "02-08-2018",
  },
  {
    end: 678,
    date: "03-08-2018",
  },
  {
    end: 679,
    date: "04-08-2018",
  },
  {
    end: 670,
    date: "05-08-2018",
  },
  {
    end: 671,
    date: "06-08-2018",
  },
  {
    end: 676,
    date: "07-08-2018",
  },
  {
    end: 677,
    date: "08-08-2018",
  },
  {
    end: 680,
    date: "09-08-2018",
  },
  {
    end: 681,
    date: "10-08-2018",
  },
  {
    end: 684,
    date: "11-08-2018",
  },
  {
    end: 686,
    date: "12-08-2018",
  },
  {
    end: 688,
    date: "13-08-2018",
  },
  {
    end: 671,
    date: "14-08-2018",
  },
  {
    end: 681,
    date: "15-08-2018",
  },
  {
    end: 691,
    date: "16-08-2018",
  },
  {
    end: 688,
    date: "17-08-2018",
  },
  {
    end: 689,
    date: "18-08-2018",
  },
  {
    end: 694,
    date: "19-08-2018",
  },
  {
    end: 695,
    date: "20-08-2018",
  },
  {
    end: 698,
    date: "21-08-2018",
  },
  {
    end: 701,
    date: "22-08-2018",
  },
  {
    end: 711,
    date: "23-08-2018",
  },
  {
    end: 691,
    date: "24-08-2018",
  },
  {
    end: 697,
    date: "25-08-2018",
  },
  {
    end: 699,
    date: "26-08-2018",
  },
  {
    end: 691,
    date: "27-08-2018",
  },
  {
    end: 700,
    date: "28-08-2018",
  },
  {
    end: 704,
    date: "29-08-2018",
  },
  {
    end: 708,
    date: "30-08-2018",
  },
  {
    end: 703,
    date: "01-09-2018",
  },
  {
    end: 709,
    date: "02-09-2018",
  },
  {
    end: 711,
    date: "03-09-2018",
  },
  {
    end: 733,
    date: "04-09-2018",
  },
  {
    end: 713,
    date: "05-09-2018",
  },
  {
    end: 717,
    date: "06-09-2018",
  },
  {
    end: 722,
    date: "07-09-2018",
  },
  {
    end: 723,
    date: "08-09-2018",
  },
  {
    end: 727,
    date: "09-09-2018",
  },
  {
    end: 729,
    date: "10-09-2018",
  },
  {
    end: 731,
    date: "11-09-2018",
  },
  {
    end: 733,
    date: "12-09-2018",
  },
  {
    end: 735,
    date: "13-09-2018",
  },
  {
    end: 738,
    date: "14-09-2018",
  },
  {
    end: 744,
    date: "15-09-2018",
  },
  {
    end: 745,
    date: "16-09-2018",
  },
  {
    end: 748,
    date: "17-09-2018",
  },
  {
    end: 755,
    date: "18-09-2018",
  },
  {
    end: 733,
    date: "19-09-2018",
  },
  {
    end: 723,
    date: "20-09-2018",
  },
  {
    end: 648,
    date: "21-09-2018",
  },
  {
    end: 731,
    date: "22-09-2018",
  },
  {
    end: 737,
    date: "23-09-2018",
  },
  {
    end: 691,
    date: "24-09-2018",
  },
  {
    end: 697,
    date: "25-09-2018",
  },
  {
    end: 699,
    date: "26-09-2018",
  },
  {
    end: 691,
    date: "27-09-2018",
  },
  {
    end: 700,
    date: "28-09-2018",
  },
  {
    end: 704,
    date: "29-09-2018",
  },
  {
    end: 709,
    date: "30-09-2018",
  },
  {
    end: 709,
    date: "31-09-2018",
  },

  {
    end: 740,
    date: "01-10-2018",
  },
  {
    end: 745,
    date: "02-10-2018",
  },
  {
    end: 755,
    date: "03-10-2018",
  },
  {
    end: 757,
    date: "04-10-2018",
  },
  {
    end: 766,
    date: "05-10-2018",
  },
  {
    end: 750,
    date: "06-10-2018",
  },
  {
    end: 751,
    date: "07-10-2018",
  },
  {
    end: 759,
    date: "08-10-2018",
  },
  {
    end: 763,
    date: "10-10-2018",
  },
  {
    end: 767,
    date: "10-10-2018",
  },
  {
    end: 768,
    date: "11-10-2018",
  },
  {
    end: 771,
    date: "12-10-2018",
  },
  {
    end: 774,
    date: "13-10-2018",
  },
  {
    end: 778,
    date: "14-10-2018",
  },
  {
    end: 766,
    date: "15-10-2018",
  },
  {
    end: 780,
    date: "16-10-2018",
  },
  {
    end: 778,
    date: "17-10-2018",
  },
  {
    end: 785,
    date: "18-10-2018",
  },
  {
    end: 783,
    date: "19-10-2018",
  },
  {
    end: 788,
    date: "20-10-2018",
  },
  {
    end: 798,
    date: "21-10-2018",
  },
  {
    end: 791,
    date: "22-10-2018",
  },
  {
    end: 797,
    date: "23-10-2018",
  },
  {
    end: 801,
    date: "24-10-2018",
  },
  {
    end: 807,
    date: "25-10-2018",
  },
  {
    end: 809,
    date: "26-10-2018",
  },
  {
    end: 811,
    date: "27-10-2018",
  },
  {
    end: 800,
    date: "28-10-2018",
  },
  {
    end: 804,
    date: "29-10-2018",
  },
  {
    end: 810,
    date: "30-10-2018",
  },
  {
    end: 817,
    date: "31-10-2018",
  },

  {
    end: 820,
    date: "01-11-2018",
  },
  {
    end: 821,
    date: "02-11-2018",
  },
  {
    end: 825,
    date: "03-11-2018",
  },
  {
    end: 827,
    date: "04-11-2018",
  },
  {
    end: 830,
    date: "05-11-2018",
  },
  {
    end: 822,
    date: "06-11-2018",
  },
  {
    end: 832,
    date: "07-11-2018",
  },
  {
    end: 800,
    date: "08-11-2018",
  },
  {
    end: 783,
    date: "09-11-2018",
  },
  {
    end: 797,
    date: "10-11-2018",
  },
  {
    end: 788,
    date: "11-11-2018",
  },
  {
    end: 771,
    date: "12-11-2018",
  },
  {
    end: 774,
    date: "13-11-2018",
  },
  {
    end: 778,
    date: "14-11-2018",
  },
  {
    end: 766,
    date: "15-11-2018",
  },
  {
    end: 780,
    date: "16-11-2018",
  },
  {
    end: 778,
    date: "17-11-2018",
  },
  {
    end: 785,
    date: "18-11-2018",
  },
  {
    end: 783,
    date: "19-11-2018",
  },
  {
    end: 788,
    date: "20-11-2018",
  },
  {
    end: 798,
    date: "21-11-2018",
  },
  {
    end: 791,
    date: "22-11-2018",
  },
  {
    end: 797,
    date: "23-11-2018",
  },
  {
    end: 801,
    date: "24-11-2018",
  },
  {
    end: 807,
    date: "25-11-2018",
  },
  {
    end: 809,
    date: "26-11-2018",
  },
  {
    end: 811,
    date: "27-11-2018",
  },
  {
    end: 800,
    date: "28-11-2018",
  },
  {
    end: 804,
    date: "29-11-2018",
  },
  {
    end: 820,
    date: "30-11-2018",
  },
  {
    end: 840,
    date: "31-11-2018",
  },

  {
    end: 845,
    date: "01-12-2018",
  },
  {
    end: 850,
    date: "02-12-2018",
  },
  {
    end: 855,
    date: "03-12-2018",
  },
  {
    end: 859,
    date: "04-12-2018",
  },
  {
    end: 851,
    date: "05-12-2018",
  },
  {
    end: 865,
    date: "06-12-2018",
  },
  {
    end: 869,
    date: "07-12-2018",
  },
  {
    end: 877,
    date: "08-12-2018",
  },
  {
    end: 871,
    date: "09-12-2018",
  },
  {
    end: 877,
    date: "10-12-2018",
  },
  {
    end: 888,
    date: "11-12-2018",
  },
  {
    end: 881,
    date: "12-12-2018",
  },
  {
    end: 874,
    date: "13-12-2018",
  },
  {
    end: 888,
    date: "14-12-2018",
  },
  {
    end: 896,
    date: "15-12-2018",
  },
  {
    end: 890,
    date: "16-12-2018",
  },
  {
    end: 908,
    date: "17-12-2018",
  },
  {
    end: 900,
    date: "18-12-2018",
  },
  {
    end: 906,
    date: "19-12-2018",
  },
  {
    end: 909,
    date: "20-12-2018",
  },
  {
    end: 900,
    date: "21-12-2018",
  },
  {
    end: 891,
    date: "22-12-2018",
  },
  {
    end: 911,
    date: "23-12-2018",
  },
  {
    end: 921,
    date: "24-12-2018",
  },
  {
    end: 927,
    date: "25-12-2018",
  },
  {
    end: 930,
    date: "26-12-2018",
  },
  {
    end: 932,
    date: "27-12-2018",
  },
  {
    end: 940,
    date: "28-12-2018",
  },
  {
    end: 933,
    date: "29-12-2018",
  },
  {
    end: 940,
    date: "30-12-2018",
  },
  {
    end: 944,
    date: "31-12-2018",
  },

  // -- 2019 start

  {
    end: 243,
    date: "01-01-2019",
  },
  {
    end: 242,
    date: "02-01-2019",
  },
  {
    end: 244,
    date: "03-01-2019",
  },
  {
    end: 247,
    date: "04-01-2019",
  },
  {
    end: 248,
    date: "05-01-2019",
  },
  {
    end: 242,
    date: "06-01-2019",
  },
  {
    end: 244,
    date: "07-01-2019",
  },
  {
    end: 240,
    date: "08-01-2019",
  },
  {
    end: 243,
    date: "09-01-2019",
  },
  {
    end: 248,
    date: "10-01-2019",
  },
  {
    end: 973,
    date: "11-01-2019",
  },
  {
    end: 977,
    date: "12-01-2019",
  },
  {
    end: 978,
    date: "13-01-2019",
  },
  {
    end: 260,
    date: "14-01-2019",
  },
  {
    end: 977,
    date: "15-01-2019",
  },
  {
    end: 260,
    date: "16-01-2019",
  },
  {
    end: 262,
    date: "17-01-2019",
  },
  {
    end: 270,
    date: "18-01-2019",
  },
  {
    end: 273,
    date: "19-01-2019",
  },
  {
    end: 270,
    date: "20-01-2019",
  },
  {
    end: 274,
    date: "21-01-2019",
  },
  {
    end: 276,
    date: "22-01-2019",
  },
  {
    end: 278,
    date: "23-01-2019",
  },
  {
    end: 278,
    date: "24-01-2019",
  },
  {
    end: 280,
    date: "25-01-2019",
  },
  {
    end: 273,
    date: "26-01-2019",
  },
  {
    end: 276,
    date: "27-01-2019",
  },
  {
    end: 283,
    date: "28-01-2019",
  },
  {
    end: 293,
    date: "29-01-2019",
  },
  {
    end: 280,
    date: "30-01-2019",
  },
  {
    end: 286,
    date: "31-01-2019",
  },
  {
    end: 290,
    date: "01-02-2019",
  },
  {
    end: 293,
    date: "02-02-2019",
  },
  {
    end: 295,
    date: "03-02-2019",
  },
  {
    end: 298,
    date: "04-02-2019",
  },
  {
    end: 293,
    date: "05-02-2019",
  },
  {
    end: 297,
    date: "06-02-2019",
  },
  {
    end: 300,
    date: "07-02-2019",
  },
  {
    end: 293,
    date: "08-02-2019",
  },
  {
    end: 300,
    date: "09-02-2019",
  },
  {
    end: 303,
    date: "10-02-2019",
  },
  {
    end: 307,
    date: "11-02-2019",
  },
  {
    end: 310,
    date: "12-02-2019",
  },
  {
    end: 293,
    date: "13-02-2019",
  },
  {
    end: 300,
    date: "14-02-2019",
  },
  {
    end: 307,
    date: "15-02-2019",
  },
  {
    end: 310,
    date: "16-02-2019",
  },
  {
    end: 320,
    date: "17-02-2019",
  },
  {
    end: 313,
    date: "18-02-2019",
  },
  {
    end: 315,
    date: "19-02-2019",
  },
  {
    end: 320,
    date: "20-02-2019",
  },
  {
    end: 322,
    date: "21-02-2019",
  },
  {
    end: 321,
    date: "22-02-2019",
  },
  {
    end: 324,
    date: "23-02-2019",
  },
  {
    end: 300,
    date: "24-02-2019",
  },
  {
    end: 313,
    date: "25-02-2019",
  },
  {
    end: 315,
    date: "26-02-2019",
  },
  {
    end: 320,
    date: "27-02-2019",
  },
  {
    end: 323,
    date: "28-02-2019",
  },
  {
    end: 322,
    date: "1-03-2019",
  },
  {
    end: 325,
    date: "02-03-2019",
  },
  {
    end: 327,
    date: "03-03-2019",
  },
  {
    end: 340,
    date: "04-03-2019",
  },
  {
    end: 345,
    date: "05-03-2019",
  },
  {
    end: 340,
    date: "06-03-2019",
  },
  {
    end: 343,
    date: "07-03-2019",
  },
  {
    end: 346,
    date: "08-03-2019",
  },
  {
    end: 356,
    date: "09-03-2019",
  },
  {
    end: 353,
    date: "10-03-2019",
  },
  {
    end: 355,
    date: "11-03-2019",
  },
  {
    end: 359,
    date: "12-03-2019",
  },
  {
    end: 353,
    date: "13-03-2019",
  },
  {
    end: 363,
    date: "14-03-2019",
  },
  {
    end: 366,
    date: "15-03-2019",
  },
  {
    end: 364,
    date: "16-03-2019",
  },
  {
    end: 369,
    date: "17-03-2019",
  },
  {
    end: 370,
    date: "18-03-2019",
  },
  {
    end: 373,
    date: "19-03-2019",
  },
  {
    end: 380,
    date: "20-03-2019",
  },
  {
    end: 366,
    date: "21-03-2019",
  },
  {
    end: 369,
    date: "22-03-2019",
  },
  {
    end: 376,
    date: "23-03-2019",
  },
  {
    end: 379,
    date: "24-03-2019",
  },
  {
    end: 381,
    date: "25-03-2019",
  },
  {
    end: 384,
    date: "26-03-2019",
  },
  {
    end: 389,
    date: "27-03-2019",
  },
  {
    end: 390,
    date: "28-03-2019",
  },
  {
    end: 393,
    date: "29-03-2019",
  },
  {
    end: 394,
    date: "30-03-2019",
  },
  {
    end: 401,
    date: "31-03-2019",
  },
  {
    end: 403,
    date: "01-04-2019",
  },
  {
    end: 405,
    date: "02-04-2019",
  },
  {
    end: 410,
    date: "03-04-2019",
  },
  {
    end: 408,
    date: "04-04-2019",
  },
  {
    end: 411,
    date: "05-04-2019",
  },
  {
    end: 413,
    date: "06-04-2019",
  },
  {
    end: 423,
    date: "07-04-2019",
  },
  {
    end: 417,
    date: "08-04-2019",
  },
  {
    end: 420,
    date: "09-04-2019",
  },
  {
    end: 423,
    date: "10-04-2019",
  },
  {
    end: 444,
    date: "11-04-2019",
  },
  {
    end: 440,
    date: "12-04-2019",
  },
  {
    end: 430,
    date: "13-04-2019",
  },
  {
    end: 422,
    date: "14-04-2019",
  },
  {
    end: 428,
    date: "15-04-2019",
  },
  {
    end: 430,
    date: "16-04-2019",
  },
  {
    end: 423,
    date: "17-04-2019",
  },
  {
    end: 438,
    date: "18-04-2019",
  },
  {
    end: 437,
    date: "19-04-2019",
  },
  {
    end: 423,
    date: "20-04-2019",
  },
  {
    end: 420,
    date: "21-04-2019",
  },
  {
    end: 423,
    date: "22-04-2019",
  },
  {
    end: 414,
    date: "23-04-2019",
  },
  {
    end: 423,
    date: "24-04-2019",
  },
  {
    end: 429,
    date: "25-04-2019",
  },
  {
    end: 420,
    date: "26-04-2019",
  },
  {
    end: 413,
    date: "27-04-2019",
  },
  {
    end: 408,
    date: "28-04-2019",
  },
  {
    end: 404,
    date: "29-04-2019",
  },
  {
    end: 389,
    date: "30-04-2019",
  },
  {
    end: 400,
    date: "01-05-2019",
  },
  {
    end: 410,
    date: "02-05-2019",
  },
  {
    end: 413,
    date: "03-05-2019",
  },
  {
    end: 420,
    date: "04-05-2019",
  },
  {
    end: 426,
    date: "05-05-2019",
  },
  {
    end: 429,
    date: "06-05-2019",
  },
  {
    end: 440,
    date: "07-05-2019",
  },
  {
    end: 450,
    date: "08-05-2019",
  },
  {
    end: 440,
    date: "09-05-2019",
  },
  {
    end: 444,
    date: "10-05-2019",
  },
  {
    end: 448,
    date: "11-05-2019",
  },
  {
    end: 454,
    date: "12-05-2019",
  },
  {
    end: 464,
    date: "13-05-2019",
  },
  {
    end: 469,
    date: "14-05-2019",
  },
  {
    end: 470,
    date: "15-05-2019",
  },
  {
    end: 474,
    date: "16-05-2019",
  },
  {
    end: 478,
    date: "17-05-2019",
  },
  {
    end: 490,
    date: "18-05-2019",
  },
  {
    end: 449,
    date: "19-05-2019",
  },
  {
    end: 464,
    date: "20-05-2019",
  },
  {
    end: 470,
    date: "21-05-2019",
  },
  {
    end: 474,
    date: "22-05-2019",
  },
  {
    end: 479,
    date: "23-05-2019",
  },
  {
    end: 484,
    date: "24-05-2019",
  },
  {
    end: 470,
    date: "25-05-2019",
  },
  {
    end: 474,
    date: "26-05-2019",
  },
  {
    end: 490,
    date: "27-05-2019",
  },
  {
    end: 494,
    date: "28-05-2019",
  },
  {
    end: 498,
    date: "29-05-2019",
  },
  {
    end: 500,
    date: "30-05-2019",
  },
  {
    end: 504,
    date: "31-05-2019",
  },
  {
    end: 508,
    date: "01-06-2019",
  },
  {
    end: 510,
    date: "02-06-2019",
  },
  {
    end: 510,
    date: "03-06-2019",
  },
  {
    end: 514,
    date: "04-06-2019",
  },
  {
    end: 524,
    date: "05-06-2019",
  },
  {
    end: 528,
    date: "06-06-2019",
  },
  {
    end: 534,
    date: "07-06-2019",
  },
  {
    end: 533,
    date: "08-06-2019",
  },
  {
    end: 538,
    date: "09-06-2019",
  },
  {
    end: 540,
    date: "10-06-2019",
  },
  {
    end: 544,
    date: "11-06-2019",
  },
  {
    end: 542,
    date: "12-06-2019",
  },
  {
    end: 546,
    date: "13-06-2019",
  },
  {
    end: 548,
    date: "14-06-2019",
  },
  {
    end: 550,
    date: "15-06-2019",
  },
  {
    end: 540,
    date: "16-06-2019",
  },
  {
    end: 545,
    date: "17-06-2019",
  },
  {
    end: 547,
    date: "18-06-2019",
  },
  {
    end: 564,
    date: "19-06-2019",
  },
  {
    end: 568,
    date: "20-06-2019",
  },
  {
    end: 574,
    date: "21-06-2019",
  },
  {
    end: 566,
    date: "22-06-2019",
  },
  {
    end: 568,
    date: "23-06-2019",
  },
  {
    end: 563,
    date: "24-06-2019",
  },
  {
    end: 580,
    date: "25-06-2019",
  },
  {
    end: 584,
    date: "26-06-2019",
  },
  {
    end: 594,
    date: "27-06-2019",
  },
  {
    end: 590,
    date: "28-06-2019",
  },
  {
    end: 592,
    date: "29-06-2019",
  },
  {
    end: 597,
    date: "30-06-2019",
  },
  {
    end: 600,
    date: "01-07-2019",
  },
  {
    end: 603,
    date: "02-07-2019",
  },
  {
    end: 607,
    date: "03-07-2019",
  },
  {
    end: 603,
    date: "04-07-2019",
  },
  {
    end: 610,
    date: "05-07-2019",
  },
  {
    end: 613,
    date: "06-07-2019",
  },
  {
    end: 617,
    date: "07-07-2019",
  },
  {
    end: 633,
    date: "08-07-2019",
  },
  {
    end: 620,
    date: "09-07-2019",
  },
  {
    end: 623,
    date: "10-07-2019",
  },
  {
    end: 627,
    date: "11-07-2019",
  },
  {
    end: 629,
    date: "12-07-2019",
  },
  {
    end: 634,
    date: "13-07-2019",
  },
  {
    end: 637,
    date: "14-07-2019",
  },
  {
    end: 639,
    date: "15-07-2019",
  },
  {
    end: 640,
    date: "16-07-2019",
  },
  {
    end: 643,
    date: "17-07-2019",
  },
  {
    end: 647,
    date: "18-07-2019",
  },
  {
    end: 644,
    date: "19-07-2019",
  },
  {
    end: 643,
    date: "20-07-2019",
  },
  {
    end: 647,
    date: "21-07-2019",
  },
  {
    end: 650,
    date: "22-07-2019",
  },
  {
    end: 655,
    date: "23-07-2019",
  },
  {
    end: 653,
    date: "24-07-2019",
  },
  {
    end: 657,
    date: "25-07-2019",
  },
  {
    end: 660,
    date: "26-07-2019",
  },
  {
    end: 666,
    date: "27-07-2019",
  },
  {
    end: 663,
    date: "28-07-2019",
  },
  {
    end: 669,
    date: "29-07-2019",
  },
  {
    end: 668,
    date: "30-07-2019",
  },
  {
    end: 671,
    date: "31-07-2019",
  },
  {
    end: 673,
    date: "01-08-2019",
  },
  {
    end: 676,
    date: "02-08-2019",
  },
  {
    end: 678,
    date: "03-08-2019",
  },
  {
    end: 679,
    date: "04-08-2019",
  },
  {
    end: 670,
    date: "05-08-2019",
  },
  {
    end: 671,
    date: "06-08-2019",
  },
  {
    end: 676,
    date: "07-08-2019",
  },
  {
    end: 677,
    date: "08-08-2019",
  },
  {
    end: 680,
    date: "09-08-2019",
  },
  {
    end: 681,
    date: "10-08-2019",
  },
  {
    end: 684,
    date: "11-08-2019",
  },
  {
    end: 686,
    date: "12-08-2019",
  },
  {
    end: 688,
    date: "13-08-2019",
  },
  {
    end: 671,
    date: "14-08-2019",
  },
  {
    end: 681,
    date: "15-08-2019",
  },
  {
    end: 691,
    date: "16-08-2019",
  },
  {
    end: 688,
    date: "17-08-2019",
  },
  {
    end: 689,
    date: "18-08-2019",
  },
  {
    end: 694,
    date: "19-08-2019",
  },
  {
    end: 695,
    date: "20-08-2019",
  },
  {
    end: 698,
    date: "21-08-2019",
  },
  {
    end: 701,
    date: "22-08-2019",
  },
  {
    end: 711,
    date: "23-08-2019",
  },
  {
    end: 691,
    date: "24-08-2019",
  },
  {
    end: 697,
    date: "25-08-2019",
  },
  {
    end: 699,
    date: "26-08-2019",
  },
  {
    end: 691,
    date: "27-08-2019",
  },
  {
    end: 700,
    date: "28-08-2019",
  },
  {
    end: 704,
    date: "29-08-2019",
  },
  {
    end: 708,
    date: "30-08-2019",
  },
  {
    end: 703,
    date: "01-09-2019",
  },
  {
    end: 709,
    date: "02-09-2019",
  },
  {
    end: 711,
    date: "03-09-2019",
  },
  {
    end: 733,
    date: "04-09-2019",
  },
  {
    end: 713,
    date: "05-09-2019",
  },
  {
    end: 717,
    date: "06-09-2019",
  },
  {
    end: 722,
    date: "07-09-2019",
  },
  {
    end: 723,
    date: "08-09-2019",
  },
  {
    end: 727,
    date: "09-09-2019",
  },
  {
    end: 729,
    date: "10-09-2019",
  },
  {
    end: 731,
    date: "11-09-2019",
  },
  {
    end: 733,
    date: "12-09-2019",
  },
  {
    end: 735,
    date: "13-09-2019",
  },
  {
    end: 738,
    date: "14-09-2019",
  },
  {
    end: 744,
    date: "15-09-2019",
  },
  {
    end: 745,
    date: "16-09-2019",
  },
  {
    end: 748,
    date: "17-09-2019",
  },
  {
    end: 755,
    date: "18-09-2019",
  },
  {
    end: 733,
    date: "19-09-2019",
  },
  {
    end: 723,
    date: "20-09-2019",
  },
  {
    end: 648,
    date: "21-09-2019",
  },
  {
    end: 731,
    date: "22-09-2019",
  },
  {
    end: 737,
    date: "23-09-2019",
  },
  {
    end: 691,
    date: "24-09-2019",
  },
  {
    end: 697,
    date: "25-09-2019",
  },
  {
    end: 699,
    date: "26-09-2019",
  },
  {
    end: 691,
    date: "27-09-2019",
  },
  {
    end: 700,
    date: "28-09-2019",
  },
  {
    end: 704,
    date: "29-09-2019",
  },
  {
    end: 709,
    date: "30-09-2019",
  },
  {
    end: 709,
    date: "31-09-2019",
  },

  {
    end: 740,
    date: "01-10-2019",
  },
  {
    end: 745,
    date: "02-10-2019",
  },
  {
    end: 755,
    date: "03-10-2019",
  },
  {
    end: 757,
    date: "04-10-2019",
  },
  {
    end: 766,
    date: "05-10-2019",
  },
  {
    end: 750,
    date: "06-10-2019",
  },
  {
    end: 751,
    date: "07-10-2019",
  },
  {
    end: 759,
    date: "08-10-2019",
  },
  {
    end: 763,
    date: "10-10-2019",
  },
  {
    end: 767,
    date: "10-10-2019",
  },
  {
    end: 768,
    date: "11-10-2019",
  },
  {
    end: 771,
    date: "12-10-2019",
  },
  {
    end: 774,
    date: "13-10-2019",
  },
  {
    end: 778,
    date: "14-10-2019",
  },
  {
    end: 766,
    date: "15-10-2019",
  },
  {
    end: 780,
    date: "16-10-2019",
  },
  {
    end: 778,
    date: "17-10-2019",
  },
  {
    end: 785,
    date: "18-10-2019",
  },
  {
    end: 783,
    date: "19-10-2019",
  },
  {
    end: 788,
    date: "20-10-2019",
  },
  {
    end: 798,
    date: "21-10-2019",
  },
  {
    end: 791,
    date: "22-10-2019",
  },
  {
    end: 797,
    date: "23-10-2019",
  },
  {
    end: 801,
    date: "24-10-2019",
  },
  {
    end: 807,
    date: "25-10-2019",
  },
  {
    end: 809,
    date: "26-10-2019",
  },
  {
    end: 811,
    date: "27-10-2019",
  },
  {
    end: 800,
    date: "28-10-2019",
  },
  {
    end: 804,
    date: "29-10-2019",
  },
  {
    end: 810,
    date: "30-10-2019",
  },
  {
    end: 817,
    date: "31-10-2019",
  },

  {
    end: 820,
    date: "01-11-2019",
  },
  {
    end: 821,
    date: "02-11-2019",
  },
  {
    end: 825,
    date: "03-11-2019",
  },
  {
    end: 827,
    date: "04-11-2019",
  },
  {
    end: 830,
    date: "05-11-2019",
  },
  {
    end: 822,
    date: "06-11-2019",
  },
  {
    end: 832,
    date: "07-11-2019",
  },
  {
    end: 800,
    date: "08-11-2019",
  },
  {
    end: 783,
    date: "09-11-2019",
  },
  {
    end: 797,
    date: "10-11-2019",
  },
  {
    end: 788,
    date: "11-11-2019",
  },
  {
    end: 771,
    date: "12-11-2019",
  },
  {
    end: 774,
    date: "13-11-2019",
  },
  {
    end: 778,
    date: "14-11-2019",
  },
  {
    end: 766,
    date: "15-11-2019",
  },
  {
    end: 780,
    date: "16-11-2019",
  },
  {
    end: 778,
    date: "17-11-2019",
  },
  {
    end: 785,
    date: "18-11-2019",
  },
  {
    end: 783,
    date: "19-11-2019",
  },
  {
    end: 788,
    date: "20-11-2019",
  },
  {
    end: 798,
    date: "21-11-2019",
  },
  {
    end: 791,
    date: "22-11-2019",
  },
  {
    end: 797,
    date: "23-11-2019",
  },
  {
    end: 801,
    date: "24-11-2019",
  },
  {
    end: 807,
    date: "25-11-2019",
  },
  {
    end: 809,
    date: "26-11-2019",
  },
  {
    end: 811,
    date: "27-11-2019",
  },
  {
    end: 800,
    date: "28-11-2019",
  },
  {
    end: 804,
    date: "29-11-2019",
  },
  {
    end: 820,
    date: "30-11-2019",
  },
  {
    end: 840,
    date: "31-11-2019",
  },

  {
    end: 845,
    date: "01-12-2019",
  },
  {
    end: 850,
    date: "02-12-2019",
  },
  {
    end: 855,
    date: "03-12-2019",
  },
  {
    end: 859,
    date: "04-12-2019",
  },
  {
    end: 851,
    date: "05-12-2019",
  },
  {
    end: 865,
    date: "06-12-2019",
  },
  {
    end: 869,
    date: "07-12-2019",
  },
  {
    end: 877,
    date: "08-12-2019",
  },
  {
    end: 871,
    date: "09-12-2019",
  },
  {
    end: 877,
    date: "10-12-2019",
  },
  {
    end: 888,
    date: "11-12-2019",
  },
  {
    end: 881,
    date: "12-12-2019",
  },
  {
    end: 874,
    date: "13-12-2019",
  },
  {
    end: 888,
    date: "14-12-2019",
  },
  {
    end: 896,
    date: "15-12-2019",
  },
  {
    end: 890,
    date: "16-12-2019",
  },
  {
    end: 908,
    date: "17-12-2019",
  },
  {
    end: 900,
    date: "18-12-2019",
  },
  {
    end: 906,
    date: "19-12-2019",
  },
  {
    end: 909,
    date: "20-12-2019",
  },
  {
    end: 900,
    date: "21-12-2019",
  },
  {
    end: 891,
    date: "22-12-2019",
  },
  {
    end: 911,
    date: "23-12-2019",
  },
  {
    end: 921,
    date: "24-12-2019",
  },
  {
    end: 927,
    date: "25-12-2019",
  },
  {
    end: 930,
    date: "26-12-2019",
  },
  {
    end: 932,
    date: "27-12-2019",
  },
  {
    end: 940,
    date: "28-12-2019",
  },
  {
    end: 933,
    date: "29-12-2019",
  },
  {
    end: 940,
    date: "30-12-2019",
  },
  {
    end: 944,
    date: "31-12-2019",
  },
  // -- 2020 start

  {
    end: 243,
    date: "01-01-2020",
  },
  {
    end: 242,
    date: "02-01-2020",
  },
  {
    end: 244,
    date: "03-01-2020",
  },
  {
    end: 247,
    date: "04-01-2020",
  },
  {
    end: 248,
    date: "05-01-2020",
  },
  {
    end: 242,
    date: "06-01-2020",
  },
  {
    end: 244,
    date: "07-01-2020",
  },
  {
    end: 240,
    date: "08-01-2020",
  },
  {
    end: 243,
    date: "09-01-2020",
  },
  {
    end: 248,
    date: "10-01-2020",
  },
  {
    end: 973,
    date: "11-01-2020",
  },
  {
    end: 977,
    date: "12-01-2020",
  },
  {
    end: 978,
    date: "13-01-2020",
  },
  {
    end: 260,
    date: "14-01-2020",
  },
  {
    end: 977,
    date: "15-01-2020",
  },
  {
    end: 260,
    date: "16-01-2020",
  },
  {
    end: 262,
    date: "17-01-2020",
  },
  {
    end: 270,
    date: "18-01-2020",
  },
  {
    end: 273,
    date: "19-01-2020",
  },
  {
    end: 270,
    date: "20-01-2020",
  },
  {
    end: 274,
    date: "21-01-2020",
  },
  {
    end: 276,
    date: "22-01-2020",
  },
  {
    end: 278,
    date: "23-01-2020",
  },
  {
    end: 278,
    date: "24-01-2020",
  },
  {
    end: 280,
    date: "25-01-2020",
  },
  {
    end: 273,
    date: "26-01-2020",
  },
  {
    end: 276,
    date: "27-01-2020",
  },
  {
    end: 283,
    date: "28-01-2020",
  },
  {
    end: 293,
    date: "29-01-2020",
  },
  {
    end: 280,
    date: "30-01-2020",
  },
  {
    end: 286,
    date: "31-01-2020",
  },
  {
    end: 290,
    date: "01-02-2020",
  },
  {
    end: 293,
    date: "02-02-2020",
  },
  {
    end: 295,
    date: "03-02-2020",
  },
  {
    end: 298,
    date: "04-02-2020",
  },
  {
    end: 293,
    date: "05-02-2020",
  },
  {
    end: 297,
    date: "06-02-2020",
  },
  {
    end: 300,
    date: "07-02-2020",
  },
  {
    end: 293,
    date: "08-02-2020",
  },
  {
    end: 300,
    date: "09-02-2020",
  },
  {
    end: 303,
    date: "10-02-2020",
  },
  {
    end: 307,
    date: "11-02-2020",
  },
  {
    end: 310,
    date: "12-02-2020",
  },
  {
    end: 293,
    date: "13-02-2020",
  },
  {
    end: 300,
    date: "14-02-2020",
  },
  {
    end: 307,
    date: "15-02-2020",
  },
  {
    end: 310,
    date: "16-02-2020",
  },
  {
    end: 320,
    date: "17-02-2020",
  },
  {
    end: 313,
    date: "18-02-2020",
  },
  {
    end: 315,
    date: "19-02-2020",
  },
  {
    end: 320,
    date: "20-02-2020",
  },
  {
    end: 322,
    date: "21-02-2020",
  },
  {
    end: 321,
    date: "22-02-2020",
  },
  {
    end: 324,
    date: "23-02-2020",
  },
  {
    end: 300,
    date: "24-02-2020",
  },
  {
    end: 313,
    date: "25-02-2020",
  },
  {
    end: 315,
    date: "26-02-2020",
  },
  {
    end: 320,
    date: "27-02-2020",
  },
  {
    end: 323,
    date: "28-02-2020",
  },
  {
    end: 322,
    date: "1-03-2020",
  },
  {
    end: 325,
    date: "02-03-2020",
  },
  {
    end: 327,
    date: "03-03-2020",
  },
  {
    end: 340,
    date: "04-03-2020",
  },
  {
    end: 345,
    date: "05-03-2020",
  },
  {
    end: 340,
    date: "06-03-2020",
  },
  {
    end: 343,
    date: "07-03-2020",
  },
  {
    end: 346,
    date: "08-03-2020",
  },
  {
    end: 356,
    date: "09-03-2020",
  },
  {
    end: 353,
    date: "10-03-2020",
  },
  {
    end: 355,
    date: "11-03-2020",
  },
  {
    end: 359,
    date: "12-03-2020",
  },
  {
    end: 353,
    date: "13-03-2020",
  },
  {
    end: 363,
    date: "14-03-2020",
  },
  {
    end: 366,
    date: "15-03-2020",
  },
  {
    end: 364,
    date: "16-03-2020",
  },
  {
    end: 369,
    date: "17-03-2020",
  },
  {
    end: 370,
    date: "18-03-2020",
  },
  {
    end: 373,
    date: "19-03-2020",
  },
  {
    end: 380,
    date: "20-03-2020",
  },
  {
    end: 366,
    date: "21-03-2020",
  },
  {
    end: 369,
    date: "22-03-2020",
  },
  {
    end: 376,
    date: "23-03-2020",
  },
  {
    end: 379,
    date: "24-03-2020",
  },
  {
    end: 381,
    date: "25-03-2020",
  },
  {
    end: 384,
    date: "26-03-2020",
  },
  {
    end: 389,
    date: "27-03-2020",
  },
  {
    end: 390,
    date: "28-03-2020",
  },
  {
    end: 393,
    date: "29-03-2020",
  },
  {
    end: 394,
    date: "30-03-2020",
  },
  {
    end: 401,
    date: "31-03-2020",
  },
  {
    end: 403,
    date: "01-04-2020",
  },
  {
    end: 405,
    date: "02-04-2020",
  },
  {
    end: 410,
    date: "03-04-2020",
  },
  {
    end: 408,
    date: "04-04-2020",
  },
  {
    end: 411,
    date: "05-04-2020",
  },
  {
    end: 413,
    date: "06-04-2020",
  },
  {
    end: 423,
    date: "07-04-2020",
  },
  {
    end: 417,
    date: "08-04-2020",
  },
  {
    end: 420,
    date: "09-04-2020",
  },
  {
    end: 423,
    date: "10-04-2020",
  },
  {
    end: 444,
    date: "11-04-2020",
  },
  {
    end: 440,
    date: "12-04-2020",
  },
  {
    end: 430,
    date: "13-04-2020",
  },
  {
    end: 422,
    date: "14-04-2020",
  },
  {
    end: 428,
    date: "15-04-2020",
  },
  {
    end: 430,
    date: "16-04-2020",
  },
  {
    end: 423,
    date: "17-04-2020",
  },
  {
    end: 438,
    date: "18-04-2020",
  },
  {
    end: 437,
    date: "19-04-2020",
  },
  {
    end: 423,
    date: "20-04-2020",
  },
  {
    end: 420,
    date: "21-04-2020",
  },
  {
    end: 423,
    date: "22-04-2020",
  },
  {
    end: 414,
    date: "23-04-2020",
  },
  {
    end: 423,
    date: "24-04-2020",
  },
  {
    end: 429,
    date: "25-04-2020",
  },
  {
    end: 420,
    date: "26-04-2020",
  },
  {
    end: 413,
    date: "27-04-2020",
  },
  {
    end: 408,
    date: "28-04-2020",
  },
  {
    end: 404,
    date: "29-04-2020",
  },
  {
    end: 389,
    date: "30-04-2020",
  },
  {
    end: 400,
    date: "01-05-2020",
  },
  {
    end: 410,
    date: "02-05-2020",
  },
  {
    end: 413,
    date: "03-05-2020",
  },
  {
    end: 420,
    date: "04-05-2020",
  },
  {
    end: 426,
    date: "05-05-2020",
  },
  {
    end: 429,
    date: "06-05-2020",
  },
  {
    end: 440,
    date: "07-05-2020",
  },
  {
    end: 450,
    date: "08-05-2020",
  },
  {
    end: 440,
    date: "09-05-2020",
  },
  {
    end: 444,
    date: "10-05-2020",
  },
  {
    end: 448,
    date: "11-05-2020",
  },
  {
    end: 454,
    date: "12-05-2020",
  },
  {
    end: 464,
    date: "13-05-2020",
  },
  {
    end: 469,
    date: "14-05-2020",
  },
  {
    end: 470,
    date: "15-05-2020",
  },
  {
    end: 474,
    date: "16-05-2020",
  },
  {
    end: 478,
    date: "17-05-2020",
  },
  {
    end: 490,
    date: "18-05-2020",
  },
  {
    end: 449,
    date: "19-05-2020",
  },
  {
    end: 464,
    date: "20-05-2020",
  },
  {
    end: 470,
    date: "21-05-2020",
  },
  {
    end: 474,
    date: "22-05-2020",
  },
  {
    end: 479,
    date: "23-05-2020",
  },
  {
    end: 484,
    date: "24-05-2020",
  },
  {
    end: 470,
    date: "25-05-2020",
  },
  {
    end: 474,
    date: "26-05-2020",
  },
  {
    end: 490,
    date: "27-05-2020",
  },
  {
    end: 494,
    date: "28-05-2020",
  },
  {
    end: 498,
    date: "29-05-2020",
  },
  {
    end: 500,
    date: "30-05-2020",
  },
  {
    end: 504,
    date: "31-05-2020",
  },
  {
    end: 508,
    date: "01-06-2020",
  },
  {
    end: 510,
    date: "02-06-2020",
  },
  {
    end: 510,
    date: "03-06-2020",
  },
  {
    end: 514,
    date: "04-06-2020",
  },
  {
    end: 524,
    date: "05-06-2020",
  },
  {
    end: 528,
    date: "06-06-2020",
  },
  {
    end: 534,
    date: "07-06-2020",
  },
  {
    end: 533,
    date: "08-06-2020",
  },
  {
    end: 538,
    date: "09-06-2020",
  },
  {
    end: 540,
    date: "10-06-2020",
  },
  {
    end: 544,
    date: "11-06-2020",
  },
  {
    end: 542,
    date: "12-06-2020",
  },
  {
    end: 546,
    date: "13-06-2020",
  },
  {
    end: 548,
    date: "14-06-2020",
  },
  {
    end: 550,
    date: "15-06-2020",
  },
  {
    end: 540,
    date: "16-06-2020",
  },
  {
    end: 545,
    date: "17-06-2020",
  },
  {
    end: 547,
    date: "18-06-2020",
  },
  {
    end: 564,
    date: "19-06-2020",
  },
  {
    end: 568,
    date: "20-06-2020",
  },
  {
    end: 574,
    date: "21-06-2020",
  },
  {
    end: 566,
    date: "22-06-2020",
  },
  {
    end: 568,
    date: "23-06-2020",
  },
  {
    end: 563,
    date: "24-06-2020",
  },
  {
    end: 580,
    date: "25-06-2020",
  },
  {
    end: 584,
    date: "26-06-2020",
  },
  {
    end: 594,
    date: "27-06-2020",
  },
  {
    end: 590,
    date: "28-06-2020",
  },
  {
    end: 592,
    date: "29-06-2020",
  },
  {
    end: 597,
    date: "30-06-2020",
  },
  {
    end: 600,
    date: "01-07-2020",
  },
  {
    end: 603,
    date: "02-07-2020",
  },
  {
    end: 607,
    date: "03-07-2020",
  },
  {
    end: 603,
    date: "04-07-2020",
  },
  {
    end: 610,
    date: "05-07-2020",
  },
  {
    end: 613,
    date: "06-07-2020",
  },
  {
    end: 617,
    date: "07-07-2020",
  },
  {
    end: 633,
    date: "08-07-2020",
  },
  {
    end: 620,
    date: "09-07-2020",
  },
  {
    end: 623,
    date: "10-07-2020",
  },
  {
    end: 627,
    date: "11-07-2020",
  },
  {
    end: 629,
    date: "12-07-2020",
  },
  {
    end: 634,
    date: "13-07-2020",
  },
  {
    end: 637,
    date: "14-07-2020",
  },
  {
    end: 639,
    date: "15-07-2020",
  },
  {
    end: 640,
    date: "16-07-2020",
  },
  {
    end: 643,
    date: "17-07-2020",
  },
  {
    end: 647,
    date: "18-07-2020",
  },
  {
    end: 644,
    date: "19-07-2020",
  },
  {
    end: 643,
    date: "20-07-2020",
  },
  {
    end: 647,
    date: "21-07-2020",
  },
  {
    end: 650,
    date: "22-07-2020",
  },
  {
    end: 655,
    date: "23-07-2020",
  },
  {
    end: 653,
    date: "24-07-2020",
  },
  {
    end: 657,
    date: "25-07-2020",
  },
  {
    end: 660,
    date: "26-07-2020",
  },
  {
    end: 666,
    date: "27-07-2020",
  },
  {
    end: 663,
    date: "28-07-2020",
  },
  {
    end: 669,
    date: "29-07-2020",
  },
  {
    end: 668,
    date: "30-07-2020",
  },
  {
    end: 671,
    date: "31-07-2020",
  },
  {
    end: 673,
    date: "01-08-2020",
  },
  {
    end: 676,
    date: "02-08-2020",
  },
  {
    end: 678,
    date: "03-08-2020",
  },
  {
    end: 679,
    date: "04-08-2020",
  },
  {
    end: 670,
    date: "05-08-2020",
  },
  {
    end: 671,
    date: "06-08-2020",
  },
  {
    end: 676,
    date: "07-08-2020",
  },
  {
    end: 677,
    date: "08-08-2020",
  },
  {
    end: 680,
    date: "09-08-2020",
  },
  {
    end: 681,
    date: "10-08-2020",
  },
  {
    end: 684,
    date: "11-08-2020",
  },
  {
    end: 686,
    date: "12-08-2020",
  },
  {
    end: 688,
    date: "13-08-2020",
  },
  {
    end: 671,
    date: "14-08-2020",
  },
  {
    end: 681,
    date: "15-08-2020",
  },
  {
    end: 691,
    date: "16-08-2020",
  },
  {
    end: 688,
    date: "17-08-2020",
  },
  {
    end: 689,
    date: "18-08-2020",
  },
  {
    end: 694,
    date: "19-08-2020",
  },
  {
    end: 695,
    date: "20-08-2020",
  },
  {
    end: 698,
    date: "21-08-2020",
  },
  {
    end: 701,
    date: "22-08-2020",
  },
  {
    end: 711,
    date: "23-08-2020",
  },
  {
    end: 691,
    date: "24-08-2020",
  },
  {
    end: 697,
    date: "25-08-2020",
  },
  {
    end: 699,
    date: "26-08-2020",
  },
  {
    end: 691,
    date: "27-08-2020",
  },
  {
    end: 700,
    date: "28-08-2020",
  },
  {
    end: 704,
    date: "29-08-2020",
  },
  {
    end: 708,
    date: "30-08-2020",
  },
  {
    end: 703,
    date: "01-09-2020",
  },
  {
    end: 709,
    date: "02-09-2020",
  },
  {
    end: 711,
    date: "03-09-2020",
  },
  {
    end: 733,
    date: "04-09-2020",
  },
  {
    end: 713,
    date: "05-09-2020",
  },
  {
    end: 717,
    date: "06-09-2020",
  },
  {
    end: 722,
    date: "07-09-2020",
  },
  {
    end: 723,
    date: "08-09-2020",
  },
  {
    end: 727,
    date: "09-09-2020",
  },
  {
    end: 729,
    date: "10-09-2020",
  },
  {
    end: 731,
    date: "11-09-2020",
  },
  {
    end: 733,
    date: "12-09-2020",
  },
  {
    end: 735,
    date: "13-09-2020",
  },
  {
    end: 738,
    date: "14-09-2020",
  },
  {
    end: 744,
    date: "15-09-2020",
  },
  {
    end: 745,
    date: "16-09-2020",
  },
  {
    end: 748,
    date: "17-09-2020",
  },
  {
    end: 755,
    date: "18-09-2020",
  },
  {
    end: 733,
    date: "19-09-2020",
  },
  {
    end: 723,
    date: "20-09-2020",
  },
  {
    end: 648,
    date: "21-09-2020",
  },
  {
    end: 731,
    date: "22-09-2020",
  },
  {
    end: 737,
    date: "23-09-2020",
  },
  {
    end: 691,
    date: "24-09-2020",
  },
  {
    end: 697,
    date: "25-09-2020",
  },
  {
    end: 699,
    date: "26-09-2020",
  },
  {
    end: 691,
    date: "27-09-2020",
  },
  {
    end: 700,
    date: "28-09-2020",
  },
  {
    end: 704,
    date: "29-09-2020",
  },
  {
    end: 709,
    date: "30-09-2020",
  },
  {
    end: 709,
    date: "31-09-2020",
  },

  {
    end: 740,
    date: "01-10-2020",
  },
  {
    end: 745,
    date: "02-10-2020",
  },
  {
    end: 755,
    date: "03-10-2020",
  },
  {
    end: 757,
    date: "04-10-2020",
  },
  {
    end: 766,
    date: "05-10-2020",
  },
  {
    end: 750,
    date: "06-10-2020",
  },
  {
    end: 751,
    date: "07-10-2020",
  },
  {
    end: 759,
    date: "08-10-2020",
  },
  {
    end: 763,
    date: "10-10-2020",
  },
  {
    end: 767,
    date: "10-10-2020",
  },
  {
    end: 768,
    date: "11-10-2020",
  },
  {
    end: 771,
    date: "12-10-2020",
  },
  {
    end: 774,
    date: "13-10-2020",
  },
  {
    end: 778,
    date: "14-10-2020",
  },
  {
    end: 766,
    date: "15-10-2020",
  },
  {
    end: 780,
    date: "16-10-2020",
  },
  {
    end: 778,
    date: "17-10-2020",
  },
  {
    end: 785,
    date: "18-10-2020",
  },
  {
    end: 783,
    date: "19-10-2020",
  },
  {
    end: 788,
    date: "20-10-2020",
  },
  {
    end: 798,
    date: "21-10-2020",
  },
  {
    end: 791,
    date: "22-10-2020",
  },
  {
    end: 797,
    date: "23-10-2020",
  },
  {
    end: 801,
    date: "24-10-2020",
  },
  {
    end: 807,
    date: "25-10-2020",
  },
  {
    end: 809,
    date: "26-10-2020",
  },
  {
    end: 811,
    date: "27-10-2020",
  },
  {
    end: 800,
    date: "28-10-2020",
  },
  {
    end: 804,
    date: "29-10-2020",
  },
  {
    end: 810,
    date: "30-10-2020",
  },
  {
    end: 817,
    date: "31-10-2020",
  },

  {
    end: 820,
    date: "01-11-2020",
  },
  {
    end: 821,
    date: "02-11-2020",
  },
  {
    end: 825,
    date: "03-11-2020",
  },
  {
    end: 827,
    date: "04-11-2020",
  },
  {
    end: 830,
    date: "05-11-2020",
  },
  {
    end: 822,
    date: "06-11-2020",
  },
  {
    end: 832,
    date: "07-11-2020",
  },
  {
    end: 800,
    date: "08-11-2020",
  },
  {
    end: 783,
    date: "09-11-2020",
  },
  {
    end: 797,
    date: "10-11-2020",
  },
  {
    end: 788,
    date: "11-11-2020",
  },
  {
    end: 771,
    date: "12-11-2020",
  },
  {
    end: 774,
    date: "13-11-2020",
  },
  {
    end: 778,
    date: "14-11-2020",
  },
  {
    end: 766,
    date: "15-11-2020",
  },
  {
    end: 780,
    date: "16-11-2020",
  },
  {
    end: 778,
    date: "17-11-2020",
  },
  {
    end: 785,
    date: "18-11-2020",
  },
  {
    end: 783,
    date: "19-11-2020",
  },
  {
    end: 788,
    date: "20-11-2020",
  },
  {
    end: 798,
    date: "21-11-2020",
  },
  {
    end: 791,
    date: "22-11-2020",
  },
  {
    end: 797,
    date: "23-11-2020",
  },
  {
    end: 801,
    date: "24-11-2020",
  },
  {
    end: 807,
    date: "25-11-2020",
  },
  {
    end: 809,
    date: "26-11-2020",
  },
  {
    end: 811,
    date: "27-11-2020",
  },
  {
    end: 800,
    date: "28-11-2020",
  },
  {
    end: 804,
    date: "29-11-2020",
  },
  {
    end: 820,
    date: "30-11-2020",
  },
  {
    end: 840,
    date: "31-11-2020",
  },

  {
    end: 845,
    date: "01-12-2020",
  },
  {
    end: 850,
    date: "02-12-2020",
  },
  {
    end: 855,
    date: "03-12-2020",
  },
  {
    end: 859,
    date: "04-12-2020",
  },
  {
    end: 851,
    date: "05-12-2020",
  },
  {
    end: 865,
    date: "06-12-2020",
  },
  {
    end: 869,
    date: "07-12-2020",
  },
  {
    end: 877,
    date: "08-12-2020",
  },
  {
    end: 871,
    date: "09-12-2020",
  },
  {
    end: 877,
    date: "10-12-2020",
  },
  {
    end: 888,
    date: "11-12-2020",
  },
  {
    end: 881,
    date: "12-12-2020",
  },
  {
    end: 874,
    date: "13-12-2020",
  },
  {
    end: 888,
    date: "14-12-2020",
  },
  {
    end: 896,
    date: "15-12-2020",
  },
  {
    end: 890,
    date: "16-12-2020",
  },
  {
    end: 908,
    date: "17-12-2020",
  },
  {
    end: 900,
    date: "18-12-2020",
  },
  {
    end: 906,
    date: "19-12-2020",
  },
  {
    end: 909,
    date: "20-12-2020",
  },
  {
    end: 900,
    date: "21-12-2020",
  },
  {
    end: 891,
    date: "22-12-2020",
  },
  {
    end: 911,
    date: "23-12-2020",
  },
  {
    end: 921,
    date: "24-12-2020",
  },
  {
    end: 927,
    date: "25-12-2020",
  },
  {
    end: 930,
    date: "26-12-2020",
  },
  {
    end: 932,
    date: "27-12-2020",
  },
  {
    end: 940,
    date: "28-12-2020",
  },
  {
    end: 933,
    date: "29-12-2020",
  },
  {
    end: 940,
    date: "30-12-2020",
  },
  {
    end: 944,
    date: "31-12-2020",
  },
];
