import React, { useEffect, useState } from "react";
import AfterloginApp from "../../AfterloginApp";
import { Link } from "react-router-dom";
import Plus from "../../../assets/img/add_new_icon.svg";
import Dropdown from "../../../components/Dropdowns/DropdownObjects";
import ClientCard from "./ClientCard";
import { Bars } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  GetClients,
  GetRms,
  PostEventLoggingData,
} from "../../../actions/actions";
import InvestedgeSearchField from "../../../components/searchfield/InvestedgeSearchField";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import InvestedgeTabs from "../../../components/tabs/InvestedgeTabs";
import InvestedgePagination from "../../../components/pagination/InvestedgePagination";
export default function ReviewPortfolio() {
  const rms = useSelector((state) => state.rms);
  const clients = useSelector((state) => state.clients);
  const [selected, setSelected] = useState({});
  // const [clientData, setClientData] = useState(
  //   rms.length && Object.keys(rms[0]).length !== 0
  //     ? selected.clients.filter((data) => data.is_prospect === false)
  //     : [
  //         {
  //           client_info: {
  //             username: "",
  //             email: "",
  //             role: "",
  //             profile_pic: null,
  //           },
  //           client_type: "",
  //           is_prospect: true,
  //         },
  //       ]
  // );
  const [searchClient, setSearchClient] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const clientsPerPage = 10;
  const dispatch = useDispatch();
  // const pagesVisited = pageNumber * clientsPerPage;
  const pageCount = Math.ceil(
    useSelector((state) => state.clients.count) / clientsPerPage
  );
  const changePage = (e, val) => {
    setPageNumber(val);
    dispatch(
      GetClients({
        params: {
          search: searchClient,
          is_prospect: clienttype === 1,
          rmid: selected?.id,
          page: val,
          size: clientsPerPage,
        },
      })
    );
  };
  const [clienttype, setClienttype] = useState(0);

  // useEffect(() => {
  //   dispatch(GetClients());
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    selected?.id &&
      dispatch(
        GetClients({
          params: {
            search: searchClient,
            is_prospect: clienttype === 1,
            rmid: selected?.id,
            page: 1,
            size: clientsPerPage,
          },
        })
      );
    setPageNumber(1); // eslint-disable-next-line
  }, [searchClient, clienttype, selected]);

  useEffect(() => {
    setSelected(rms[0]); // eslint-disable-next-line
  }, [rms]);

  useEffect(() => {
    dispatch(GetRms()); // eslint-disable-next-line
  }, []);
  // useEffect(() => {
  //   setClientData(
  //     selected.clients.filter((data) =>
  //       clienttype === 0
  //         ? data.is_prospect === false
  //         : data.is_prospect === true
  //     )
  //   ); // eslint-disable-next-line
  // }, [selected]);
  const tabs = [{ label: "Clients" }, { label: "Prospects" }];
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Portfolio Insights"}
        description={"Create and Customize Portfolio Recommendations"}
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className="main-panel bg-[white] p-4 min-h-[calc(100vh_-_116px)] rounded-[4px] w-full relative ">
            <div className="h-auto">
              <div className="flex w-full justify-between">
                <div className="w-[22%] pt-[0.3rem]">
                  <Dropdown
                    options={rms}
                    selected={selected}
                    setSelected={setSelected}
                    attribute="username"
                    labelName="Relationship Manager"
                  />
                </div>
              </div>
              <div className="flex justify-between items-end w-full mt-[1rem]">
                <InvestedgeTabs
                  tabs={tabs}
                  tabvalue={clienttype}
                  handletabchange={(e, value) => {
                    dispatch(
                      PostEventLoggingData({
                        module_name: "Client Details",
                        event_type: "Information",
                        url: window.location.href,
                        note: "Tabs clicked",
                      })
                    );
                    setClienttype(value);
                  }}
                />
                <div className="flex items-end w-full justify-end mb-2">
                  <InvestedgeSearchField
                    type="text"
                    placeholder="Search for Client name"
                    value={searchClient}
                    onChange={(e) => setSearchClient(e.target.value)}
                  />
                  <Link to="/clientinput">
                    <InvestedgeButton
                      sx={{ marginBottom: "2px" }}
                      className="ml-4"
                    >
                      <img
                        className="w-[14px] object-contain mr-[0.5rem]"
                        src={Plus}
                        alt="err"
                      />
                      Add New
                    </InvestedgeButton>
                  </Link>
                </div>
              </div>
              {clients.loading ? (
                <div className="client-dashboard-table w-full min-h-[400px] flex flex-col justify-center items-center opacity-[0.9]">
                  <Bars color="#1982F8" width={50} height={50} />
                </div>
              ) : (
                <div className="w-full grid grid-cols-5 gap-5 pt-8">
                  {Array.isArray(clients.results)
                    ? // .slice(pagesVisited, pagesVisited + clientsPerPage)
                      clients.results.map((client, index) => {
                        return <ClientCard client={client} key={index} />;
                      })
                    : null}
                </div>
              )}

              <div className="w-full absolute bottom-[16px] right-4 flex justify-end">
                <InvestedgePagination
                  count={pageCount}
                  defaultPage={1}
                  page={pageNumber}
                  onChange={changePage}
                  color="primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
