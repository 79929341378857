import { GET_SETTINGS_RMS_ARRAY } from "../actions/type";
const initialState = [];
export default function GetSettingsRms(state = initialState, action) {
  switch (action.type) {
    case GET_SETTINGS_RMS_ARRAY:
      return action.payload;
    default:
      return state;
  }
}
