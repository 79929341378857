import React, { useState } from "react";
import "../../../../static/css/existing/dashboard_styles.css";
import OverView from "./OverView/Overview";
import Portfolio from "./Portfolio/Portfolio";
import SuitableClients from "./SuitableClients/SuitableClients";
import InvestedgeTabs from "../../../../components/tabs/InvestedgeTabs";
export const AllControl = ({ fundData }) => {
  const [fundType, setFundType] = useState(0);
  const tabs = [{ label: "Overview" }, { label: "Portfolio" }];
  const handletabchange = (event, newValue) => {
    setFundType(newValue);
  };
  const HandleScreens = (fundType) => {
    switch (fundType) {
      case 0:
        return <OverView fundData={fundData} />;
      case 1:
        return <Portfolio fundData={fundData} />;
      case 2:
        return <SuitableClients fundData={fundData} />;
      default:
        break;
    }
  };

  return (
    <>
      <div className="pt-3 px-6 pb-0 mt-2 w-full flex bg-[#fff] border rounded-t-[8px] ">
        <InvestedgeTabs
          tabs={tabs}
          tabvalue={fundType}
          handletabchange={handletabchange}
        />
      </div>
      <div>{HandleScreens(fundType)}</div>
    </>
  );
};
