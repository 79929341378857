import { GET_DASHBOARD_MF_PORTFOLIO } from "../actions/type";
const initialState = { transactions: [], portfolio: [], loading: true };
export default function GetDashboardMFPortfolio(state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_MF_PORTFOLIO:
      return action.payload;
    default:
      return state;
  }
}
