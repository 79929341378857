import React, { useEffect } from "react";
import { Bars } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  ThirdPartyLogin,
  UserDetails,
  GetSettingsAbout,
} from "../../actions/actions";
import auth from "../../auth";
export default function Authenticate() {
  const { otc } = useParams();
  const dispatch = useDispatch();
  const tokendata = useSelector((state) => state.thirdPartyLogin);
  const history = useHistory();

  useEffect(() => {
    auth.logout(() => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    });
    dispatch(ThirdPartyLogin({ token: otc })); //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      dispatch(UserDetails(history));
      dispatch(GetSettingsAbout());
    } //eslint-disable-next-line
  }, [tokendata]);

  return (
    <div className="parentLoggedinScreen">
      {/* <AfterloginApp
        description={
          "This is your dashboard. Access all of your latest updates here."
        }
      /> */}
      <div className="loggedinscreen-container min-h-full h-full">
        <div className="loggedinscreen h-full ">
          <div className=" portfolio-proposal-body-card min-h-[calc(100vh-56px)] shadow-none border-none relative flex flex-col justify-center">
            <div className=" w-full flex-col justify-center items-center flex p-[32px] rounded-[8px] bg-white min-h-full">
              <Bars color="#1982F8" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
