import React, { useRef } from "react";
import Hourglass from "../../assets/img/wait.gif";
import Women from "../../assets/img/modal_chr.svg";
import { useSelector, useDispatch } from "react-redux";
import { PostEventLoggingData } from "../../actions/actions";
import InvestedgeModal from "../../components/modal/InvestedgeModal";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import { IoIosClose } from "react-icons/io";

export default function ClientSummaryModal({ clientSummaryModal, onClose }) {
  const dispatch = useDispatch();
  const pptRef = useRef(null);
  const summary = useSelector((state) => state.dashboardPortfolioSummary);
  const downloadSummary = async () => await pptRef.current.click();

  return (
    <InvestedgeModal
      aria-labelledby="Meeting Navigator Modal"
      aria-describedby="Meeting Navigator Modal"
      sx={{ height: "60vh", margin: "auto" }}
      open={clientSummaryModal}
      handleclose={onClose}
    >
      <div className="flex justify-center flex-col h-full items-center">
        <div className="modal-header border-0 p-0 flex justify-between w-full">
          <h5 className="card-heading">Meeting Navigator</h5>
          <IoIosClose onClick={onClose} size={30} />
        </div>
        <div className="flex justify-center h-full w-[94%]">
          <div className="w-[80%] h-full rounded-[10px] p-[2rem] flex flex-col justify-center items-center">
            <img
              className="h-[220px] object-contain"
              alt="err"
              src={!summary.pdf ? Hourglass : Women}
            />
            <h3 className="card-heading text-center my-4">
              {!summary.pdf
                ? "Your request is under process. Please wait for sometime.."
                : "Meeting Navigator Summary is ready."}
            </h3>

            {summary.pdf && (
              <div className="flex justify-center w-[85%]">
                <InvestedgeButton
                  className="mt-4"
                  onClick={() => {
                    dispatch(
                      PostEventLoggingData({
                        module_name: "Portfolio Insights",
                        event_type: "Download button",
                        url: window.location.href,
                        note: `Download excel`,
                      })
                    );
                    downloadSummary();
                  }}
                >
                  Download
                </InvestedgeButton>
              </div>
            )}
            <a href={summary.pdf} ref={pptRef} className="hidden">
              Summary
            </a>
          </div>
        </div>
      </div>
    </InvestedgeModal>
  );
}
