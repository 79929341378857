import React from "react";
import DefaultProfilePic from "../../../../../assets/img/defaultprofilepic.png";
// import {
//   GetReviewPortfolioAnalytics,
//   GetReviewPortfolioSummary,
// } from "../../../../../actions/actions";
export default function ClientCard({
  client,
  setPortfolio,
  selectedClient,
  setSelectedClient,
}) {
  return (
    <div
      className={
        selectedClient === client.client_id
          ? "border border-primary-200 bg-gradient-180 from-[#fafafa] to-[#f6f6f6] bg-[#fafafa] shadow-clientCard rounded-[4px] p-[1rem] h-[165px] ml-[3.7%] mt-[1.5%] z-[0] w-full  m-0 hover:translate-y-[-8px] duration-300 cursor-pointer transition-transform"
          : "bg-[#fafafa] rounded-[4px] bg-gradient-180 from-[#fafafa] to-[#f6f6f6] p-[1rem] h-[165px] ml-[3.7%] mt-[1.5%] z-0 w-full shadow-none m-0 hover:translate-y-[-8px] duration-300 cursor-pointer transition-transform"
      }
      onClick={() => {
        setSelectedClient(client.client_id);
        setPortfolio("selected");
      }}
    >
      <div className="flex justify-end items-start">
        <div className="flex flex-col justify-center items-center w-[97%]">
          <img
            src={
              client.client_info.profile_pic
                ? client.client_info.profile_pic
                : DefaultProfilePic
            }
            className="h-[70px] rounded-[100%]"
            alt="err"
          />
          <p className=" font-inter w-full text-[16px] not-italic font-medium leading-[19px] mb-0 tracking-[0em] text-center text-neutralBlack mt-[1rem]">
            {client.client_info.username}
          </p>
          <p className=" font-inter text-[12px] not-italic font-medium leading-[12px] mb-0 tracking-[0.015em] text-center text-primary-200 mt-[0.5rem] bg-primary-accent py-[0.25rem] px-[0.5rem] rounded-[2px]">
            {client.client_type === "F" ? "Family" : "Individual"}
          </p>
        </div>
        {/* <img
          src={Menu}
          alt="err"
          onClick={() => setisActive(!isActive)}
          ref={setReferenceRef}
        /> */}
      </div>
    </div>
  );
}
