import React from "react";
import PieChart from "../../../../dashboard/PieChart";
import ProgressBar from "../ProgressBar";
export default function DebtSection({ analytics }) {
  const LegendItem = ({ color, text }) => {
    return (
      <div className="flex ml-[4px]">
        <div
          className={`w-[7px] h-[7px] block mt-[7px]`}
          style={{ backgroundColor: color }}
        />
        <p className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] ml-[0.2rem] mb-0">
          {text}
        </p>
      </div>
    );
  };
  const categoryKeys = [];
  const categoryValues = [];
  const colorPalette = [
    "#ED6D85",
    "#56A1E5",
    "#F7CE6B",
    "#F9C175",
    "#7C8FF5",
    "#6BD7D7",
    "#977DC1",
    "#E9E778",
    "#7FDEA5",
    "#EB7DBC",
    "#BB82DE",
    "#E39981",
    "#8DD2F0",
    "#EC93A5",
    "#56A1E5",
  ];
  for (const property in analytics.before_debt_category_allocation) {
    categoryKeys.push(property);
    categoryValues.push(
      (analytics.before_debt_category_allocation[property] * 100).toFixed(1)
    );
  }
  const aftercategoryKeys = [];
  const aftercategoryValues = [];
  for (const property in analytics.after_debt_category_allocation) {
    aftercategoryKeys.push(property);
    aftercategoryValues.push(
      (analytics.after_debt_category_allocation[property] * 100).toFixed(1)
    );
  }
  const productKeys = [];
  const productValues = [];
  for (const property in analytics.before_debt_product_allocation) {
    productKeys.push(property);
    productValues.push(
      (analytics.before_debt_product_allocation[property] * 100).toFixed(1)
    );
  }
  const afterproductKeys = [];
  const afterproductValues = [];
  for (const property in analytics.after_debt_product_allocation) {
    afterproductKeys.push(property);
    afterproductValues.push(
      (analytics.after_debt_product_allocation[property] * 100).toFixed(1)
    );
  }

  const assetdata = {
    labels: categoryKeys,

    datasets: [
      {
        data: categoryValues,
        backgroundColor: colorPalette,
        borderColor: colorPalette,
        borderWidth: 1,
      },
    ],
  };
  const afterassetdata = {
    labels: aftercategoryKeys,
    datasets: [
      {
        data: aftercategoryValues,
        backgroundColor: colorPalette,
        borderColor: colorPalette,
        borderWidth: 1,
      },
    ],
  };
  const pieoptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      labels: {
        render: "value",
        fontColor: "#fff",
      },
      datalabels: {
        display: false,
      },
    },
  };
  return (
    <div className="w-full mt-6">
      <h4 className="font-inter not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-2">
        Product Blended performance
      </h4>
      <div className="flex w-full justify-between">
        <div className="w-[49%] bg-[#fafafa] py-[1rem] pt-2 px-0 min-h-full">
          <p className="text-sm text-neutralBlack mx-4 font-medium">Current</p>
          <div className="flex items-center mt-2">
            <div className=" w-[50%] text-center border-r border-r-dividerColor">
              <h3 className="font-inter text-[24px] text-[#6a6a76] font-semibold leading-[20px] tracking-[0em] text-center mb-0">
                {`${(analytics.before_debt_cagr * 100).toFixed(1)} %`}
              </h3>
              <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                3Y CAGR
              </label>
            </div>
            <div className="w-[50%] text-center">
              <h3 className="text-[#6a6a76] font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center mb-0">
                {`${(analytics.before_debt_bm_cagr * 100).toFixed(1)} %`}
              </h3>
              <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                3Y BM* CAGR
              </label>
            </div>
          </div>
        </div>
        <div className="w-[49%] bg-[#fafafa] py-[1rem] pt-2 px-0 min-h-full">
          <p className="text-sm text-neutralBlack mx-4 font-medium">Proposed</p>
          <div className="flex items-center mt-2">
            <div className=" w-[50%] text-center border-r border-r-dividerColor">
              <h3 className="font-inter text-[24px] text-[#6a6a76] font-semibold leading-[20px] tracking-[0em] text-center mb-0">
                {`${(analytics.after_debt_cagr * 100).toFixed(1)} %`}
              </h3>
              <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                3Y CAGR
              </label>
            </div>
            <div className=" w-[50%] text-center">
              <h3 className="text-[#6a6a76] font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center mb-0">
                {`${(analytics.after_debt_bm_cagr * 100).toFixed(1)} %`}
              </h3>
              <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                3Y BM* CAGR
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mt-4">
        <h4 className="font-inter not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-2">
          Category allocation
        </h4>
        <div className="flex w-full justify-between">
          <div className="w-[49%] min-h-[238px]">
            <div className="bg-[#FAFAFA] w-[100%] p-[1rem] min-h-full">
              <p className="text-sm text-neutralBlack font-medium">Current</p>
              <div className="w-full flex justify-center items-center">
                <PieChart
                  data={assetdata}
                  options={pieoptions}
                  width={170}
                  height={170}
                />
              </div>

              <div className=" flex justify-evenly w-full z-[100] pt-[0.4rem] flex-wrap">
                {categoryKeys.map((key, index) => (
                  <LegendItem
                    key={index}
                    color={colorPalette[index]}
                    text={key}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="w-[49%] min-h-[238px]">
            <div className="bg-[#FAFAFA] w-[100%] p-[1rem] min-h-full">
              <p className="text-sm text-neutralBlack font-medium">Proposed</p>
              <div className="w-full flex justify-center items-center">
                <PieChart
                  data={afterassetdata}
                  options={pieoptions}
                  width={170}
                  height={170}
                />
              </div>

              <div className=" flex justify-evenly w-full z-[100] pt-[0.4rem] flex-wrap">
                {aftercategoryKeys.map((key, index) => (
                  <LegendItem
                    key={index}
                    color={colorPalette[index]}
                    text={key}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mt-4">
        <h4 className="font-inter not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-2">
          Product allocation
        </h4>
        <div className="flex w-full justify-between h-[238px]">
          <div className="bg-[#FAFAFA] w-[49%] p-[1rem] h-full">
            <p className="text-sm text-neutralBlack font-medium">Current</p>

            <div
              className="min-h-full grid grid-cols-2 grid-flow-col gap-x-5"
              style={{ gridTemplateRows: "20% 20% 20% 20% 20%" }}
            >
              {productKeys.map((key, index) => (
                <div className="row-span-1" key={index}>
                  <ProgressBar
                    bg={colorPalette[index % colorPalette.length]}
                    progress={productValues[index]}
                    label={key}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="bg-[#FAFAFA] w-[49%] p-[1rem] h-full">
            <p className="text-sm text-neutralBlack font-medium">Proposed</p>

            <div
              className="min-h-full grid grid-cols-2 grid-flow-col gap-x-5"
              style={{ gridTemplateRows: "20% 20% 20% 20% 20%" }}
            >
              {afterproductKeys.map((key, index) => (
                <div className="row-span-1" key={index}>
                  <ProgressBar
                    bg={colorPalette[index % colorPalette.length]}
                    progress={afterproductValues[index]}
                    label={key}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
