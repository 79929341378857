import { FORGET_PASSWORD } from "../actions/type";
const initialState = {};
export default function GetOtpReducer(state = initialState, action) {
  switch (action.type) {
    case FORGET_PASSWORD:
      return action.payload;

    default:
      return state;
  }
}
