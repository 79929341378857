import React, { useEffect, useState } from "react";
import InvestedgeToggleButtonGroup from "../../../../../components/toggle/InvestedgeToggle";
import CustomProposal from "./CustomProposal";
import SelectProposal from "./SelectProposal";
export default function Lumpsum({
  tabType,
  proposalType,
  setProposalType,
  riskscore,
  setRiskscore,
  horizon,
  portfolioId,
  setPortfolioId,
  schemes,
  setSchemes,
}) {
  const [faqs, setFaqs] = useState({
    raq1: 1,
    raq2: 1,
    raq3: 1,
    raq4: 1,
    raq5: 1,
    sumraq: 5,
  });
  const marks = [
    {
      value: 0,
      label: "Risk averse",
    },
    {
      value: 100,
      label: "Risk seeker",
    },
  ];
  const toggletabs = [
    { label: "Create Custom Proposal", value: 0 },
    { label: "Select from Model Portfolio", value: 1 },
  ];
  const handletabchange = (event, newValue) => {
    if (newValue === 0 || newValue === 1) setProposalType(newValue);
  };
  const [riskText, setRiskText] = useState("Moderate");
  const [rsmodal, setrsmodal] = useState(false);
  useEffect(() => {
    if (riskscore >= 0 && riskscore <= 20) setRiskText("Conservative");
    if (riskscore > 20 && riskscore <= 40)
      setRiskText("Moderately Conservative");
    if (riskscore > 40 && riskscore <= 60) setRiskText("Moderate");
    if (riskscore > 60 && riskscore <= 80) setRiskText("Moderately Aggressive");
    if (riskscore > 80 && riskscore <= 100) setRiskText("Aggressive");

    // eslint-disable-next-line
  }, [riskscore]);
  return (
    <div className="rounded-[8px] w-full bg-white px-8 py-4 mt-[16px]">
      <div className="flex justify-center h-full">
        <div className="w-[94%] pb-[0.75rem] flex justify-center h-full">
          <div
            className="portfolio-stepper-first-form-container"
            style={{
              width: "70%",
              borderRadius: "10px",
            }}
          >
            <div className="flex justify-between w-full">
              <h2 className="card-heading">
                {tabType === 0 ? "Lumpsum Investment Details" : "SIP Details"}
              </h2>
            </div>
            <div className="w-[100%] flex justify-center mt-6">
              <InvestedgeToggleButtonGroup
                tabs={toggletabs}
                value={proposalType}
                onChange={handletabchange}
                exclusive
              />
            </div>
            {proposalType === 0 ? (
              <CustomProposal
                tabType={tabType}
                riskText={riskText}
                riskscore={riskscore}
                setRiskscore={setRiskscore}
                horizon={horizon}
                marks={marks}
                rsmodal={rsmodal}
                setrsmodal={setrsmodal}
                faqs={faqs}
                setFaqs={setFaqs}
                schemes={schemes}
                setSchemes={setSchemes}
              />
            ) : (
              <SelectProposal
                portfolioId={portfolioId}
                setPortfolioId={setPortfolioId}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
