import React from "react";
import { Switch, FormControlLabel } from "@mui/material";
import InvestedgeSlider from "../../components/slider/InvestedgeSlider";
export default function ProductUnderstandingRow({
  familyMembers,
  setFamilyMembers2,
  label,
  name,
  selected,
}) {
  const marks = [
    {
      value: 1,
      label: "No understanding",
    },
    {
      value: 5,
      label: "All understanding",
    },
  ];
  let handleChangeSwitch = (e) => {
    let newfamilyMembers = [...familyMembers];

    newfamilyMembers[selected.optionid].understanding[e.target.name].interest =
      !newfamilyMembers[selected.optionid].understanding[e.target.name]
        .interest;
    setFamilyMembers2(newfamilyMembers);
  };
  const handleSliderChange = (event, newValue) => {
    let newfamilyMembers = [...familyMembers];
    newfamilyMembers[selected.optionid].understanding[name].understanding =
      newValue;
    setFamilyMembers2(newfamilyMembers);
  };
  return (
    <>
      <tr className="product-understanding-modal-row">
        <td>
          {" "}
          <div className="flex w-full items-center h-[80%] text-sm">
            {label}
          </div>
        </td>
        <td className="text-sm">
          <div className="flex justify-center w-full items-center h-full">
            <FormControlLabel
              control={
                <Switch
                  checked={
                    familyMembers[selected.optionid].understanding[name].checked
                  }
                  name={name}
                  onChange={(e) => handleChangeSwitch(e)}
                  inputProps={{ role: "switch" }}
                  color="primary"
                />
              }
              label={
                <span className="text-sm font-inter">
                  {familyMembers[selected.optionid].understanding[name].checked
                    ? "Yes"
                    : "No"}
                </span>
              }
            />
          </div>
        </td>
        <td className="text-[18px] text-center w-[40%]">
          <InvestedgeSlider
            marks={marks}
            min={1}
            max={5}
            value={
              familyMembers[selected.optionid].understanding[name].understanding
            }
            onChange={(event, value) => handleSliderChange(event, value)}
            valueLabelDisplay="on"
            step={1}
          />
        </td>
      </tr>
    </>
  );
}
