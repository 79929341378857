import React, { useState, useEffect } from "react";
import Add from "../../../../assets/img/portfolioaddfunds.svg";
import Reload from "../../../../assets/img/portfolioreload.svg";
import DisableSorted from "../../../../assets/img/disabled_sort.svg";
import AscendingSorted from "../../../../assets/img/ascending_sort.svg";
import DescendingSorted from "../../../../assets/img/descending_sort.svg";
import OverviewSection from "./OverviewSection";
import DebtSection from "./DebtSection";
import EquitySection from "./EquitySection";
import { useSelector, useDispatch } from "react-redux";
import {
  GetSIPAnalyticsAPI,
  PostEventLoggingData,
} from "../../../../actions/actions";
import AddFundsModal from "./AddFundsModal";
import { Bars } from "react-loader-spinner";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
import InvestedgePagination from "../../../../components/pagination/InvestedgePagination";
import PortfolioFourthStepRow from "./PortfolioFourthStepRow";
import AlternateSection from "./AlternateSection";
import Dropdown from "../../../../components/Dropdowns/DropdownWhiteSingle";
export default function PortfolioFourthStep({
  handleBack,
  handleNext,
  formValue,
  portfolioProducts,
  setPortfolioProducts,
  proposalType,
  handleModelPortfolioBack,
}) {
  const dispatch = useDispatch();
  const preferenceData = useSelector((state) => state.sipallocationPreference);
  const modelPortfolio = useSelector(
    (state) => state.getModelPortfolioProducts
  );
  const [nameSorted, setNameSorted] = useState(0);
  const [amountSorted, setAmountSorted] = useState(0);
  const rationalizeProducts = (productsArray) => {
    let temp = productsArray.map((product) => {
      return {
        ...product,
        individual_asset_pct: (product.individual_asset_pct * 100).toFixed(1),
      };
    });

    return temp;
  };
  const derationalizeProducts = (productsArray) => {
    let temp = productsArray.map((product) => {
      return {
        ...product,
        individual_asset_pct: product.individual_asset_pct / 100,
      };
    });

    return temp;
  };
  const [parsedData, setParsedData] = useState(
    rationalizeProducts(
      portfolioProducts.length === 0
        ? preferenceData["Mutual Fund"]
        : portfolioProducts.length === 0 && proposalType === 1
        ? modelPortfolio
        : portfolioProducts
    )
  );
  useEffect(() => {
    if (
      (proposalType === 0 &&
        preferenceData["Mutual Fund"].length > 0 &&
        portfolioProducts.length === 0) ||
      (proposalType === 1 &&
        modelPortfolio.length > 0 &&
        portfolioProducts.length === 0)
    ) {
      dispatch(
        GetSIPAnalyticsAPI(
          proposalType === 0
            ? preferenceData
            : { "Mutual Fund": modelPortfolio }
        )
      );
      let tempArray = rationalizeProducts(
        proposalType === 0 ? preferenceData["Mutual Fund"] : modelPortfolio
      );
      let tempSum = 0;
      tempArray.forEach(
        (value) => (tempSum += parseFloat(value.individual_asset_pct))
      );
      tempSum = tempSum.toFixed(1);
      tempArray[0].individual_asset_pct =
        parseFloat(tempArray[0].individual_asset_pct) +
        (100.0 - parseFloat(tempSum));
      tempArray[0].individual_asset_pct =
        tempArray[0].individual_asset_pct.toFixed(1);
      setParsedData(tempArray);
    } // eslint-disable-next-line
  }, [preferenceData, modelPortfolio]);

  const analytics = useSelector((state) => state.sipanalyticsAPI);
  const [section, setSection] = useState("Overview");
  const [afmodal, setAfmodal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  const clientsPerPage = 10;
  const pagesVisited = pageNumber * clientsPerPage;
  var portfolioSum = 0;
  const addedFundsMap = {};
  // eslint-disable-next-line
  parsedData.forEach((value) => {
    addedFundsMap[value.product_id] = true;
    portfolioSum += parseFloat(value.individual_asset_pct);
  });

  const portfolioValue = formValue.sip_proposal_value.amount;
  const sortedArray = (array) => {
    if (nameSorted)
      switch (nameSorted) {
        case 0:
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_type === asset);
        case 1:
          array.sort((a, b) => {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
            // names must be equal
          });
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_type === asset);
        case 2:
          array.sort((a, b) => {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase

            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            return 0;
          });
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_type === asset);
        default:
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_type === asset);
      }
    else
      switch (amountSorted) {
        case 0:
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_type === asset);
        case 1:
          array.sort((a, b) => {
            const nameA = parseFloat(a.individual_asset_pct); // ignore upper and lowercase
            const nameB = parseFloat(b.individual_asset_pct); // ignore upper and lowercase

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
            // names must be equal
          });
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_type === asset);
        case 2:
          array.sort((a, b) => {
            const nameA = parseFloat(a.individual_asset_pct); // ignore upper and lowercase
            const nameB = parseFloat(b.individual_asset_pct); // ignore upper and lowercase

            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            return 0;
          });
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_type === asset);
        default:
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_type === asset);
      }
  };
  const [asset, setAsset] = useState("All");
  const assetSet = new Set(parsedData.map((product) => product.asset_type));
  const handleCross = (id) => {
    delete addedFundsMap[id];
    let index = parsedData.findIndex((x) => x.product_id === id);
    let newParsedData = [...parsedData];
    newParsedData.splice(index, 1);
    setParsedData(newParsedData);
  };

  const handleWeightChange = (e, id) => {
    if (
      e.target.value <= 100 &&
      (!e.target.value.split(".")[1] ||
        e.target.value.split(".")[1].length <= 1)
    ) {
      let index = parsedData.findIndex((x) => x.product_id === id);
      let newProducts = [...parsedData];
      newProducts[index].individual_asset_pct = e.target.value;
      setParsedData(newProducts);
    }
  };
  const displaydata = sortedArray(parsedData)
    .slice(pagesVisited, pagesVisited + clientsPerPage)
    .map((client) => {
      return (
        <PortfolioFourthStepRow
          key={client.product_id}
          client={client}
          handleCross={handleCross}
          handleWeightChange={handleWeightChange}
          portfolioValue={portfolioValue}
          denomination={formValue.sip_proposal_value.denomination}
        />
      );
    });
  const pageCount = Math.ceil(parsedData.length / clientsPerPage);
  const changePage = (e, val) => {
    setPageNumber(val - 1);
  };
  const handleSections = () => {
    switch (section) {
      case "Overview":
        return (
          <OverviewSection
            analytics={analytics}
            body={{ "Mutual Fund": derationalizeProducts(parsedData) }}
          />
        );

      case "Debt":
        return <DebtSection analytics={analytics} />;

      case "Equity":
        return <EquitySection analytics={analytics} />;
      case "Alternate":
        return <AlternateSection analytics={analytics} />;
      default:
        console.log(section);
    }
  };
  useEffect(() => {
    dispatch(
      PostEventLoggingData({
        module_name: "Portfolio Insights",
        event_type: "Information",
        url: window.location.href,
        note: `Checked out ${section} of analytics`,
      })
    ); // eslint-disable-next-line
  }, [section]);

  const sortingIcon = (state) =>
    state === 0
      ? DisableSorted
      : state === 1
      ? AscendingSorted
      : DescendingSorted;

  return (
    <>
      <div className="px-8 rounded-[8px] w-full bg-white pt-4 pb-4 mt-[16px] ">
        <div className=" flex justify-between w-full">
          <h2 className="card-heading">Portfolio &amp; Analysis</h2>
          <div className="flex">
            <InvestedgeButton
              onClick={() => {
                dispatch(
                  PostEventLoggingData({
                    module_name: "Portfolio Insights",
                    event_type: "Prev button",
                    url: window.location.href,
                    note: `Fourth Screen`,
                  })
                );
                proposalType ? handleModelPortfolioBack() : handleBack();
              }}
            >
              Prev
            </InvestedgeButton>
            <InvestedgeButton
              className="ml-4"
              onClick={() => {
                let temp = derationalizeProducts(parsedData);
                dispatch(GetSIPAnalyticsAPI({ "Mutual Fund": temp }));
                setPortfolioProducts(temp);
                dispatch(
                  PostEventLoggingData({
                    module_name: "Portfolio Insights",
                    event_type: "Next button",
                    url: window.location.href,
                    note: `Fourth Screen`,
                  })
                );
                handleNext();
              }}
              disabled={Math.round(portfolioSum * 10) / 10 !== 100}
            >
              Finalize Proposal
            </InvestedgeButton>
          </div>
        </div>
        <p className="w-full leading-[20px] font-inter text-xs text-neutralBlack mb-0 mt-[4px]">
          Recommendation based on algorithms best suited for the clients. You
          can change the portfolio to see the live analytics on the right side.
        </p>
      </div>
      <div className="px-8 rounded-[8px] w-full bg-white pt-4 pb-4 mt-[16px]">
        <div className="flex justify-between w-full">
          <div className="w-[52.5%]">
            <div className="flex justify-between items-center border-b border-[#e0dddd] w-full pb-2">
              <h3 className=" font-inter text-[16px] font-medium leading-[20px] tracking-[0em] text-left pb-[0.3rem]">
                Portfolio
              </h3>
              <div className="w-[25%]">
                <Dropdown
                  options={["All", ...assetSet]}
                  selected={asset}
                  setSelected={setAsset}
                  labelName="Asset Class"
                />
              </div>
            </div>
            {!analytics.loading ? (
              <>
                <div className="w-full bg-[#fafafa] mt-[1rem] p-[1rem] flex rounded-[6px]">
                  <div className="w-[20%] text-center border-r border-r-dividerColor">
                    <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0">
                      {`${(analytics.asset_allocation.Equity * 100).toFixed(
                        1
                      )}%`}
                    </h3>
                    <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                      Equity
                    </label>
                  </div>
                  <div className="w-[20%] text-center border-r border-r-dividerColor">
                    <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0">
                      {`${(analytics.asset_allocation.Debt * 100).toFixed(1)}%`}
                    </h3>
                    <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                      Debt
                    </label>
                  </div>
                  <div className="w-[20%] text-center border-r border-r-dividerColor">
                    <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0">
                      {`${(analytics.asset_allocation.Alternate * 100).toFixed(
                        1
                      )}%`}
                    </h3>
                    <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                      Alternates
                    </label>
                  </div>
                  <div className="w-[20%] text-center border-r border-r-dividerColor">
                    <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#1982f8] mb-0">
                      {`₹ ${portfolioValue} ${formValue.sip_proposal_value.denomination}`}
                    </h3>
                    <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                      Value
                    </label>
                  </div>
                  <div className="w-[20%] text-center">
                    <h3
                      className="font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0"
                      style={
                        Math.round(portfolioSum * 10) / 10 !== 100
                          ? { color: "red" }
                          : { color: "#00B031" }
                      }
                    >
                      {portfolioSum.toFixed(1)}%
                    </h3>
                    <label className="font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                      Total
                    </label>
                  </div>
                </div>

                <div className="rounded-[6px] mt-[1rem] min-h-[680px]">
                  <table className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] text-center py-[0.25rem] px-0 w-full h-full border-none shadow-none investedge-table">
                    <colgroup>
                      <col width="40%" />
                      <col width="20%" />
                      <col width="16%" />
                      <col width="16%" />
                      <col width="8%" />
                    </colgroup>
                    <tbody>
                      <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                        <th
                          style={{
                            textAlign: "left",
                            paddingLeft: "1rem",
                          }}
                          className="font-inter text-[12px] font-medium leading-[20px] tracking-[0em] text-left py-[0.25rem] pl-[1rem] pr-0 flex items-center"
                        >
                          Name{" "}
                          <img
                            src={sortingIcon(nameSorted)}
                            onClick={() => {
                              setAmountSorted(0);
                              setNameSorted((nameSorted + 1) % 3);
                            }}
                            alt="err"
                            className="w-6 ml-2 cursor-pointer"
                          />
                        </th>
                        <th className="font-inter text-[12px] not-italic font-medium leading-[20px] tracking-[0em] text-center py-[4px]">
                          Asset Class/ <br />
                          Product Category
                        </th>
                        <th className="font-inter text-[12px] font-medium leading-[20px] tracking-[0em] text-center py-[0.25rem] px-0 flex items-center justify-center">
                          Amount
                          <br /> (in ₹){" "}
                          <img
                            src={sortingIcon(amountSorted)}
                            onClick={() => {
                              setNameSorted(0);
                              setAmountSorted((amountSorted + 1) % 3);
                            }}
                            alt="err"
                            className="w-6 ml-1 cursor-pointer"
                          />
                        </th>
                        <th className="font-inter text-[12px] not-italic font-medium leading-[20px] tracking-[0em] text-center py-[4px]">
                          Weight
                          <br /> (% of Total)
                        </th>
                        <th className="font-inter text-[12px] not-italic font-medium leading-[20px] tracking-[0em] text-center py-[4px]"></th>
                      </tr>
                      {displaydata}
                    </tbody>
                  </table>
                </div>
                <div className="flex w-full justify-between pt-0">
                  <InvestedgeButton
                    className="w-[175px] mt-4"
                    onClick={() => {
                      dispatch(
                        PostEventLoggingData({
                          module_name: "Portfolio Insights",
                          event_type: "Add button",
                          url: window.location.href,
                          note: `Fourth Screen fund added`,
                        })
                      );
                      setAfmodal(true);
                    }}
                  >
                    <img
                      className="w-[14px] object-contain  mr-[0.25rem]"
                      src={Add}
                      alt="err"
                    />
                    Add Products
                  </InvestedgeButton>
                  <AddFundsModal
                    onClose={() => setAfmodal(false)}
                    afmodal={afmodal}
                    parsedData={parsedData}
                    setParsedData={setParsedData}
                    addedFundsMap={addedFundsMap}
                  />
                  <div className="w-full mt-4 hidden md:flex justify-end">
                    <InvestedgePagination
                      count={pageCount}
                      defaultPage={1}
                      page={pageNumber + 1}
                      onChange={changePage}
                      color="primary"
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="w-[45%] pt-4">
            <div className=" w-full flex justify-between border-b border-[#e0dddd]">
              <div className="flex">
                <h3 className="font-inter text-[16px] font-medium leading-[20px] tracking-[0em] text-left pb-[0.3rem]">
                  Analytics
                </h3>
                <p
                  className={
                    section === "Overview"
                      ? "border-b border-primary-200 ml-[2rem] mr-[1rem] text-primary-200 font-inter text-[12px] font-medium leading-[31px] tracking-[0em] text-left cursor-pointer"
                      : "font-inter text-[12px] font-medium leading-[31px] tracking-[0em] text-left  ml-[2rem] mr-[1rem] cursor-pointer"
                  }
                  onClick={() => setSection("Overview")}
                >
                  Overview
                </p>
                <p
                  className={
                    section === "Equity"
                      ? "border-b border-primary-200 ml-[2rem] mr-[1rem] text-primary-200 font-inter text-[12px] font-medium leading-[31px] tracking-[0em] text-left cursor-pointer "
                      : "font-inter text-[12px] font-medium leading-[31px] tracking-[0em] text-left  ml-[2rem] mr-[1rem] cursor-pointer"
                  }
                  onClick={() => setSection("Equity")}
                >
                  Equity
                </p>
                <p
                  className={
                    section === "Debt"
                      ? "border-b border-primary-200 ml-[2rem] mr-[1rem] text-primary-200 font-inter text-[12px] font-medium leading-[31px] tracking-[0em] text-left cursor-pointer "
                      : "font-inter text-[12px] font-medium leading-[31px] tracking-[0em] text-left  ml-[2rem] mr-[1rem] cursor-pointer"
                  }
                  onClick={() => setSection("Debt")}
                >
                  Debt
                </p>
                <p
                  className={
                    section === "Alternate"
                      ? "border-b border-primary-200 ml-[2rem] text-primary-200 font-inter text-[12px] font-medium leading-[31px] tracking-[0em] text-left  my-0 mr-[1rem] cursor-pointer"
                      : "ml-[2rem] font-inter text-[12px] font-medium leading-[31px] tracking-[0em] text-[#000] text-left my-0 mr-[1rem] cursor-pointer"
                  }
                  onClick={() => setSection("Alternate")}
                >
                  Alternate
                </p>
              </div>
              <div
                className="flex cupo items-baseline"
                onClick={() => {
                  let temp = derationalizeProducts(parsedData);
                  dispatch(
                    PostEventLoggingData({
                      module_name: "Portfolio Insights",
                      event_type: "Refresh",
                      url: window.location.href,
                      note: `Fourth Screen analytics refreshed`,
                    })
                  );
                  dispatch(GetSIPAnalyticsAPI({ "Mutual Fund": temp }));
                }}
              >
                <img src={Reload} alt="err" style={{ width: "10px" }} />
                <p className="portfolio-stepper-fourth-form-reload-text text-primary-200">
                  Simulate
                </p>
              </div>
            </div>
            {!analytics.loading ? handleSections(section) : ""}
          </div>
        </div>
        {analytics.loading ? (
          <div className=" w-full h-[380px] flex justify-center items-center opacity-[0.9]">
            <Bars color="#1982F8" />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
