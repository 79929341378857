import React, { useEffect } from "react";
import AfterloginApp from "../../AfterloginApp";
import LatestMarketEventQuestion from "./LatestMarketEventQuestion";
import { MarketEvents } from "../../../actions/actions";
import { useDispatch, useSelector } from "react-redux";

export default function Latestmarketevent() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.marketevents);
  // const [conversationTopics, setconversationTopics] = useState(data);
  useEffect(() => {
    dispatch(MarketEvents()); // eslint-disable-next-line
  }, []);
  // useEffect(() => {
  //   setconversationTopics(data);
  //   // eslint-disable-next-line
  // }, [data]);
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Latest Market Events"}
        description={"All market events at one place with impact analysis"}
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className="bg-[#fff] p-[1rem] rounded-[0.5rem] min-h-[calc(100vh_-_116px)] w-full">
            <div className="card-heading">Latest Market Events</div>
            {data.map((topic, index) => (
              <LatestMarketEventQuestion
                topic={topic}
                key={topic.id}
                index={index}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
