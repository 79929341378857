import React from "react";
import Cloud from "../../../../assets/img/bluecloud.svg";
import Lock from "../../../../assets/img/lock_locked_blue.svg";
import { useDispatch } from "react-redux";
import { OpenSnackbar } from "../../../../actions/actions";
export default function UploadStatementCards({
  handleCardClick,
  label,
  locked,
}) {
  const dispatch = useDispatch();
  return (
    <div
      onClick={(e) =>
        !locked
          ? handleCardClick(e, label)
          : dispatch(
              OpenSnackbar({
                severity: "info",
                message: "Feature will be coming soon",
              })
            )
      }
      className="relative flex justify-center items-center flex-col w-[18%] h-[100px] rounded-[10px]  cursor-pointer border border-globalBorderColor p-4"
    >
      {locked && (
        <img src={Lock} alt="err" className="absolute top-[8px] right-[8px]" />
      )}
      <img className="w-[25px] h-[25px] opacity-[0.9]" src={Cloud} alt="err" />
      <p className="text-center text-xs mt-2">{`Upload ${label} statement`}</p>
    </div>
  );
}
