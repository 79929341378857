import React, { useState } from "react";
import Minimalist from "../../../assets/img/minimalist_template.png";
import Classic from "../../../assets/img/classic_template.png";
import Tick from "../../../assets/img/module_access_type_tick.svg";
import { FormControlLabel, Switch } from "@mui/material";
import AfterloginApp from "../../AfterloginApp";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GetMutualPortfolioAnalyticsPpt } from "../../../actions/actions";
export default function MutualFundAnalyticsTemplate() {
  const [template, setTemplate] = useState(1);
  const location = useLocation();
  const dispatch = useDispatch();
  const [proposal, setProposal] = useState({
    summary: true,
    investment_strategy: true,
    portfolio_with_irr: true,
    equity_portfolio_analytics: true,
    debt_portfolio_analytics: true,
    alternates_portfolio_analytics: true,
    one_page_on_each_product: true,
  });
  const [excelformat, setExcelFormat] = useState(false);

  let handleChangeSwitch = (e) => {
    let newProposal = { ...proposal };
    newProposal[e.target.name] = !newProposal[e.target.name];
    setProposal(newProposal);
  };
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Portfolio Insights"}
        description={"Create and Customize Portfolio Recommendations"}
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className="main-panel bg-[white] p-4 min-h-[calc(100vh_-_116px)] rounded-[4px] w-full relative">
            <div className="flex justify-between w-full">
              <h2 className="card-heading">Select Template</h2>
              <Link to="/portfolioproposal/mfanalytics/files">
                <InvestedgeButton
                  onClick={() =>
                    dispatch(
                      GetMutualPortfolioAnalyticsPpt({
                        client_id: location?.state?.clientId || {},
                        template: 1,

                        ppt: {
                          ...proposal,
                          executive_summary: proposal.summary,
                        },
                        output: {
                          ppt: true,
                          excel: excelformat,
                          pdf: false,
                          all: false,
                        },
                      })
                    )
                  }
                >
                  Get Analytics
                </InvestedgeButton>
              </Link>
            </div>
            <div className="w-[80%] pt-[1.5rem] flex justify-between">
              <div className="w-[45%]">
                {template === 1 ? (
                  <div className="relative">
                    <div
                      className="w-[100%] bg-[#fafafa] p-[32px] min-h-[200px]"
                      onClick={() => setTemplate(1)}
                    >
                      <img
                        src={Minimalist}
                        alt="err"
                        style={{
                          width: "68%",
                          filter:
                            "drop-shadow(4px 4px 32px rgba(1, 34, 175, 0.16)) drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.05))",
                        }}
                      />
                      <p className="mt-[16px] font-inter font-medium text-lg mb-0">
                        Minimalist
                      </p>
                    </div>
                    <div className="absolute bottom-0 w-full h-full flex justify-end items-start p-[1rem] bg-primary-100/[0.3]">
                      <img src={Tick} alt="err" />
                    </div>
                  </div>
                ) : (
                  <div
                    className=" w-full bg-[#fafafa] p-[32px] min-h-[200px]"
                    onClick={() => setTemplate(1)}
                  >
                    <img
                      src={Minimalist}
                      alt="err"
                      style={{
                        width: "68%",
                        filter:
                          "drop-shadow(4px 4px 32px rgba(1, 34, 175, 0.16)) drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.05))",
                      }}
                    />
                    <p className="mt-[16px] font-inter font-medium text-lg mb-0">
                      Minimalist
                    </p>
                  </div>
                )}

                <div className="flex justify-between mt-[2rem]">
                  <div className="w-[60%]">
                    <h3 className="font-inter text-[16px] font-medium leading-[20px] tracking-[0em] text-left text-[#000] mt-[1rem]">
                      Choose Proposal Components
                    </h3>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={proposal.summary}
                          name="summary"
                          onChange={(e) => handleChangeSwitch(e)}
                          inputProps={{ role: "switch" }}
                          color="primary"
                        />
                      }
                      label={
                        <span className=" font-inter text-[16px] font-normal leading-[20px] tracking-[0em] text-left text-neutralBlack">
                          Executive Summary
                        </span>
                      }
                      className="pt-[1.75rem]"
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={proposal.investment_strategy}
                          name="investment_strategy"
                          onChange={(e) => handleChangeSwitch(e)}
                          inputProps={{ role: "switch" }}
                          color="primary"
                        />
                      }
                      label={"Investment Strategy"}
                      style={{ paddingTop: "1rem" }}
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={proposal.portfolio_with_irr}
                          name="portfolio_with_irr"
                          onChange={(e) => handleChangeSwitch(e)}
                          inputProps={{ role: "switch" }}
                          color="primary"
                        />
                      }
                      label={"Portfolio With IRR"}
                      style={{ paddingTop: "1rem" }}
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={proposal.equity_portfolio_analytics}
                          name="equity_portfolio_analytics"
                          onChange={(e) => handleChangeSwitch(e)}
                          inputProps={{ role: "switch" }}
                          color="primary"
                        />
                      }
                      label={"Equity Portfolio Analytics"}
                      style={{ paddingTop: "1rem" }}
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={proposal.debt_portfolio_analytics}
                          name="debt_portfolio_analytics"
                          onChange={(e) => handleChangeSwitch(e)}
                          inputProps={{ role: "switch" }}
                          color="primary"
                        />
                      }
                      label={"Debt Portfolio Analytics"}
                      style={{
                        paddingTop: "1rem",
                        fontFamily: "Inter,sans-serif",
                        fontWeight: 400,
                      }}
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={proposal.alternates_portfolio_analytics}
                          name="alternates_portfolio_analytics"
                          onChange={(e) => handleChangeSwitch(e)}
                          inputProps={{ role: "switch" }}
                          color="primary"
                        />
                      }
                      label={"Alternates Portfolio Analytics"}
                      style={{
                        paddingTop: "1rem",
                        fontFamily: "Inter,sans-serif",
                        fontWeight: 400,
                      }}
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={proposal.one_page_on_each_product}
                          name="one_page_on_each_product"
                          onChange={(e) => handleChangeSwitch(e)}
                          inputProps={{ role: "switch" }}
                          color="primary"
                        />
                      }
                      label={"One Page On Each Product"}
                      style={{
                        paddingTop: "1rem",
                        fontFamily: "Inter,sans-serif",
                        fontWeight: 400,
                      }}
                    />
                  </div>
                  <div className="w-[35%]">
                    <h3 className="portfolio-stepper-fifth-form-subheading">
                      Output Format
                    </h3>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={true}
                          inputProps={{ role: "switch" }}
                          color="primary"
                        />
                      }
                      label={
                        <span className=" font-inter text-[16px] font-normal leading-[20px] tracking-[0em] text-left text-neutralBlack">
                          PPT
                        </span>
                      }
                      style={{ paddingTop: "1.75rem" }}
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={excelformat}
                          onChange={() => setExcelFormat(!excelformat)}
                          inputProps={{ role: "switch" }}
                          color="primary"
                        />
                      }
                      label={
                        <span className=" font-inter text-[16px] font-normal leading-[20px] tracking-[0em] text-left text-neutralBlack">
                          Excel
                        </span>
                      }
                      style={{ paddingTop: "1rem" }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-[45%]">
                {template === 2 ? (
                  <div className="relative">
                    <div
                      className="w-[100%] bg-[#fafafa] p-[32px] min-h-[200px]"
                      onClick={() => setTemplate(2)}
                    >
                      <img
                        src={Classic}
                        alt="err"
                        style={{
                          width: "70%",
                          filter:
                            "drop-shadow(4px 4px 32px rgba(1, 34, 175, 0.16)) drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.05))",
                        }}
                      />
                      <p className="mt-[16px] font-inter font-medium text-lg mb-0">
                        Classic
                      </p>
                    </div>
                    <div className="absolute bottom-0 w-full h-full flex justify-end items-start p-[1rem] bg-primary-100/[0.3]">
                      <img src={Tick} alt="err" />
                    </div>
                  </div>
                ) : (
                  <div
                    className=" w-full bg-[#fafafa] p-[32px] min-h-[200px]"
                    onClick={() => setTemplate(2)}
                  >
                    <img
                      src={Classic}
                      alt="err"
                      style={{
                        width: "70%",
                        filter:
                          "drop-shadow(4px 4px 32px rgba(1, 34, 175, 0.16)) drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.05))",
                      }}
                    />
                    <p className="mt-[16px] font-inter font-medium text-lg mb-0">
                      Classic
                    </p>
                  </div>
                )}

                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
