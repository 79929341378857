import React, { useEffect, useRef } from "react";
import Add from "../../assets/img/circle_over_plus_blue.svg";
import Stocksrow from "./Stocksrow";
import { useState } from "react";
import urls from "../../urls";
import { useDispatch, useSelector } from "react-redux";
import {
  getComparisonData,
  GetDashboardStocksPortfolio,
  GetDashboardPortfolioList,
  OpenSnackbar,
} from "../../actions/actions";
import { Bars } from "react-loader-spinner";
import ClickAwayListener from "react-click-away-listener";
import axios from "axios";
import InvestedgeTextField from "../../components/textfield/InvestedgeTextField";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import InvestedgePopper from "../../components/popper/InvestedgePopper";
import { MenuItem } from "@mui/material";
export default function StocksScreen() {
  const data = useSelector((store) => store.dashboardStocksPortfolio);
  const [stocksarray, setStocksArray] = useState(
    JSON.parse(JSON.stringify(data)).map((y) => {
      return { ...y, purchase_date: new Date(y.purchase_date) };
    })
  );
  const [patchStocksArray, setPatchStocksArray] = useState([]);
  const [postStocksArray, setPostStocksArray] = useState([]);
  const [submitActive, setSubmitActive] = useState(false);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const productsData = useSelector((state) => state.getComparisonData);
  const portfolioList = useSelector(
    (store) => store.dashboardPortfolioPriceList
  );
  const removeStocksArray = (i) => {
    let newStocksArray = [...stocksarray];
    newStocksArray.splice(i, 1);
    setStocksArray(newStocksArray);
  };
  // const portfolioValue = useSelector(
  //   (store) => store.portfolioReviewGetSumary.portfolio_value
  // );
  // const parsePortfolio = (portfolio) => {
  //   if (portfolio / 1000 < 100)
  //     return `₹ ${parseFloat(portfolio / 1000).toFixed(1)} K`;
  //   if (portfolio / 100000 < 100)
  //     return `₹ ${parseFloat(portfolio / 100000).toFixed(1)} L`;
  //   return `₹ ${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  // };
  const [searchBar, setSearchBar] = useState(false);
  const [addedProduct, setAddedProduct] = useState("");
  const [newFund, setNewFund] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const popperRef = useRef(null);
  const [deleteArray, setDeleteArray] = useState([]);
  useEffect(() => {
    dispatch(
      GetDashboardStocksPortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setStocksArray(data);
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    newFund.length >= 3 &&
      dispatch(
        getComparisonData({ params: { name: newFund, type: "Stocks" } })
      );
    if (newFund.length >= 3) setAnchorEl(popperRef.current);
    else setAnchorEl(null);
    // eslint-disable-next-line
  }, [newFund]);
  useEffect(() => {
    if (deleteArray.length || postStocksArray.length || patchStocksArray.length)
      setSubmitActive(true);
    else setSubmitActive(false);
  }, [deleteArray, postStocksArray, patchStocksArray]);

  const handleSubmit = async () => {
    setloading(true);
    let newPatchList = patchStocksArray.map((item) => {
      var dd = String(item.purchase_date.getDate()).padStart(2, "0");
      var mm = String(item.purchase_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.purchase_date.getFullYear();
      return {
        id: item.id,
        user_id: localStorage.getItem("clientId"),
        stock_id: item.stock_id,
        purchase_date: yyyy + "-" + mm + "-" + dd,
        count_units: parseFloat(item.count_units),
        purchase_price: parseFloat(item.purchase_price),
        current_price: parseFloat(item.current_price),
      };
    });
    let newPostList = postStocksArray.map((item) => {
      var dd = String(item.purchase_date.getDate()).padStart(2, "0");
      var mm = String(item.purchase_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.purchase_date.getFullYear();
      return {
        stock_id: item.stock_id,
        user_id: localStorage.getItem("clientId"),
        count_units: parseFloat(item.count_units),
        purchase_date: yyyy + "-" + mm + "-" + dd,
        purchase_price: parseFloat(item.purchase_price),
        current_price: parseFloat(item.current_price),
      };
    });
    let newDeleteList = deleteArray.map((item) => {
      return {
        id: item.id,
        user_id: localStorage.getItem("clientId"),
        stock_id: item.stock_id,
        count_units: parseFloat(item.count_units),
        purchase_price: parseFloat(item.purchase_price),
        current_price: parseFloat(item.current_price),
      };
    });
    newDeleteList.length &&
      (await axios.post(urls.dashboardStocksPortfolioDeleteURL, newDeleteList));
    newPostList.length &&
      (await axios.post(urls.dashboardStocksPortfolioURL, newPostList));
    newPatchList.length &&
      (await axios.patch(urls.dashboardStocksPortfolioURL, newPatchList));
    setPatchStocksArray([]);
    setPostStocksArray([]);
    setDeleteArray([]);
    dispatch(
      GetDashboardPortfolioList({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      GetDashboardStocksPortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      OpenSnackbar({
        severity: "success",
        message: "Portfolio Updated Successfully",
      })
    );
    setloading(false);
  };
  return (
    <div className="w-full">
      <div className="font-medium font-inter text-[18px] text-neutralBlack">
        Stocks - {portfolioList.total_stock_value}
      </div>
      {!loading ? (
        <>
          <div className="flex justify-between w-[96%] mt-[4px] mb-0">
            <div className="flex justify-center items-center h-[56px] cursor-pointer">
              <img src={Add} alt="err" />
              <p
                className="text-primary-200 text-[14px] font-medium mb-0 ml-[0.3rem]"
                onClick={
                  () => setSearchBar(true)
                  // let newStocksArray = [...stocksarray];
                  // newStocksArray.unshift({ name: "New Mutual Fund", price: "0" });
                  // setStocksArray(newStocksArray);
                }
              >
                Add Stock
              </p>
              {searchBar && (
                <div className="w-[200px] select-none ml-[16px]">
                  <InvestedgeTextField
                    forwardedref={popperRef}
                    sx={{
                      fontFamily: "Inter,sans-sarif",
                      fontWeight: 400,
                      width: "200px",
                    }}
                    label="Search Stock"
                    type="text"
                    value={newFund}
                    onChange={(e) => setNewFund(e.target.value)}
                  />
                  <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <InvestedgePopper
                      id="mf-client-input-popper"
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      placement="bottom-start"
                      className="z-[100000] h-[300px]"
                    >
                      {productsData[0] === "loading" ? (
                        <div className="h-[300px] flex justify-center items-center w-[200px]">
                          <Bars color="#1982f8" height="60" width="60" />
                        </div>
                      ) : productsData.length ? (
                        <div className="h-[300px] productunderstading-scrollablediv overflow-auto">
                          {productsData.map((option, ind) => (
                            <MenuItem
                              key={ind}
                              onClick={() => {
                                setSearchBar(false);
                                const rnd = Math.random();
                                setAddedProduct(rnd);
                                let newStocksArray = [...stocksarray];
                                newStocksArray.unshift({
                                  id: rnd,
                                  stock_id: option.product_id,
                                  name: option.name,
                                  count_units: 1,
                                  purchase_date: new Date(),
                                  purchase_price: 1,
                                  current_price: option.nav,
                                  current_price_date: new Date(),
                                });
                                setPostStocksArray([
                                  ...postStocksArray,
                                  {
                                    id: rnd,
                                    stock_id: option.product_id,
                                    name: option.name,
                                    count_units: 1,
                                    purchase_date: new Date(),
                                    purchase_price: 1,
                                    current_price: option.nav,
                                    current_price_date: new Date(),
                                  },
                                ]);
                                setStocksArray(newStocksArray);
                                setAnchorEl(null);
                              }}
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                        </div>
                      ) : (
                        <div className="h-[300px] flex items-center justify-center w-[200px]">
                          No data found
                        </div>
                      )}
                    </InvestedgePopper>
                  </ClickAwayListener>
                </div>
              )}
            </div>
            {/* <p className="asset-handler-text" onClick={() => setStocksArray([])}>
          Clear All
        </p> */}
          </div>
          <div className="update-portfolio-asset-row-container h-[63vh]">
            <table className="w-full">
              <tbody className="w-full">
                {stocksarray.map((fund, index) => (
                  <Stocksrow
                    key={fund.id}
                    fund={fund}
                    id={index}
                    removeStocksArray={removeStocksArray}
                    stocksarray={stocksarray}
                    setStocksArray={setStocksArray}
                    patchStocksArray={patchStocksArray}
                    setPatchStocksArray={setPatchStocksArray}
                    deleteArray={deleteArray}
                    setDeleteArray={setDeleteArray}
                    data={data}
                    postStocksArray={postStocksArray}
                    setPostStocksArray={setPostStocksArray}
                    addedProduct={addedProduct}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="flex flex-col justify-center items-center w-[96%] h-[62vh]">
          <Bars color="#1982F8" />
          <p className=" font-inter text-[14px] font-medium text-neutralBlack mb-[1rem] mt-[1rem]">
            Updating Portfolio ...
          </p>
        </div>
      )}
      <div className="flex w-full mt-[24px] pr-[24px] justify-end">
        <InvestedgeButton
          disabled={!submitActive}
          onClick={() => {
            if (
              patchStocksArray.length ||
              postStocksArray.length ||
              deleteArray.length
            ) {
              setSubmitActive(false);
              handleSubmit();
            }
          }}
        >
          Submit
        </InvestedgeButton>
      </div>
    </div>
  );
}
