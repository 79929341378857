import React from "react";
import DropdownForAttributes from "../../../../components/Dropdowns/DropdownForAttributes";
import InvestedgeTextField from "../../../../components/textfield/InvestedgeTextField";
export default function SIP({
  sipData,
  setSipData,
  proposalValue,
  setProposalValue,
  errors,
  setErrors,
  formValue,
  setFormValue,
}) {
  return (
    <>
      <div className="flex justify-between mt-4">
        <div className="w-[14%]">
          <DropdownForAttributes
            options={["₹ K", "₹ L", "₹ C"]}
            attribute="proposalDenomination"
            selected={sipData}
            setSelected={setSipData}
            labelName={null}
          />
        </div>
        <InvestedgeTextField
          required
          sx={{
            fontFamily: "Inter,sans-sarif",
            fontWeight: 400,
            width: "83%",
          }}
          label="SIP Proposal Value"
          value={sipData.proposalValue}
          error={errors.sipProposalValue ? true : false}
          helperText={errors.sipProposalValue ? errors.name : ""}
          onChange={(e) => {
            const re = /^\d+$/;
            if (e.target.value === "" || re.test(e.target.value)) {
              if (!e.target.value) {
                let newerrors = { ...errors };
                newerrors.sipProposalValue = "This field is required";
                setErrors(newerrors);
              } else {
                let newerrors = { ...errors };
                newerrors.sipProposalValue = null;
                setErrors(newerrors);
              }
              setSipData({ ...sipData, proposalValue: e.target.value });
            }
          }}
          min="0"
        />
      </div>
      <div className="w-full mt-[24px]">
        <DropdownForAttributes
          selected={sipData}
          setSelected={setSipData}
          options={[
            "Every Weekday (Mon-Fri)",
            "Daily",
            "Weekly",
            "Monthly",
            "Quarterly",
            "Annually",
          ]}
          attribute="frequencySip"
          labelName="Frequency of SIP"
        />
      </div>
      <InvestedgeTextField
        required
        sx={{
          fontFamily: "Inter,sans-sarif",
          fontWeight: 400,
          width: "100%",
          marginTop: "24px",
        }}
        label="Total no. of SIP Installments"
        value={sipData.installments}
        // error={errors.proposalValue ? true : false}
        // helperText={errors.proposalValue ? errors.name : ""}
        onChange={(e) => {
          const re = /^\d+$/;
          if (e.target.value === "" || re.test(e.target.value)) {
            // if (!e.target.value) {
            //   let newerrors = { ...errors };
            //   newerrors.proposalValue = "This field is required";
            //   setErrors(newerrors);
            // } else {
            //   let newerrors = { ...errors };
            //   newerrors.proposalValue = null;
            //   setErrors(newerrors);
            // }
            setSipData({ ...sipData, installments: e.target.value });
          }
        }}
        min="0"
      />
      <div className="w-full flex justify-between mt-[24px]">
        <div className="w-[15%]">
          <DropdownForAttributes
            labelName={null}
            selected={sipData}
            setSelected={setSipData}
            options={["₹ K", "₹ L", "₹ C"]}
            attribute="topUpType"
          />
        </div>
        <InvestedgeTextField
          sx={{
            fontFamily: "Inter,sans-sarif",
            fontWeight: 400,
            width: "83%",
          }}
          label="Top - Up"
          value={sipData.topUp}
          onChange={(e) => {
            const re = /^[0-9\b]+$/;
            if (e.target.value === "" || re.test(e.target.value))
              setSipData({ ...sipData, topUp: e.target.value });
          }}
          min="0"
        />
      </div>
      <div className="w-full mt-[24px]">
        <DropdownForAttributes
          selected={sipData}
          setSelected={setSipData}
          options={["Quarterly", "Annually"]}
          attribute="frequencyTopUp"
          labelName="Frequency of SIP Top - Up"
        />
      </div>
    </>
  );
}
