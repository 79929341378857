import React, { useState } from "react";
import "../static/css/existing/dashboard_styles.css";
import { PostEventLoggingData } from "../actions/actions";
import { useDispatch } from "react-redux";
import InvestedgeTextField from "../components/textfield/InvestedgeTextField";
import InvestedgeButtonOutlined from "../components/buttons/InvestedgeButtonOutlined";
export default function EditField({
  label,
  value,
  formBody,
  setformBody,
  type,
  dispatchingSave,
  fieldWidth,
}) {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(true);
  const [disable, setdisable] = useState(true);
  const handleeditbutton = () => {
    dispatch(
      PostEventLoggingData({
        module_name: "Settings",
        event_type: "Edit button",
        url: window.location.href,
        note: `Editing Data`,
      })
    );
    setEdit(false);
    setdisable(!disable);
  };
  const handlesavebutton = () => {
    dispatchingSave();
    setEdit(true);
    setdisable(!disable);
  };
  const handlecancelbutton = () => {
    setEdit(true);
    setdisable(!disable);
  };

  return (
    <div className="grid grid-cols-12 mt-[1.5rem]">
      <div className="col-span-4">
        <InvestedgeTextField
          variant="outlined"
          label={label}
          fullWidth
          style={{
            fontFamily: "Inter,sans-sarif",
            fontWeight: 400,
            marginRight: "0.5rem",
          }}
          type={type}
          name={label}
          disabled={disable}
          value={formBody[value]}
          onChange={(e) => {
            if (type === "alpha") {
              const re = /^[A-Za-z]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                let newformBody = { ...formBody };
                newformBody[value] = e.target.value;
                setformBody(newformBody);
              }
            } else if (type === "phone number") {
              const re = /^\d+$/;
              if (
                (e.target.value === "" || re.test(e.target.value)) &&
                e.target.value.length <= 10
              ) {
                let newformBody = { ...formBody };
                newformBody[value] = e.target.value;
                setformBody(newformBody);
              }
            } else {
              let newformBody = { ...formBody };
              newformBody[value] = e.target.value;
              setformBody(newformBody);
            }
          }}
        />
      </div>
      <div className="col-span-2 flex justify-between">
        {edit ? (
          <InvestedgeButtonOutlined
            className="mx-auto"
            onClick={handleeditbutton}
          >
            Edit
          </InvestedgeButtonOutlined>
        ) : (
          <InvestedgeButtonOutlined
            className="mx-auto"
            color="success"
            onClick={handlesavebutton}
          >
            Save
          </InvestedgeButtonOutlined>
        )}
      </div>
      <div className="col-span-2 flex justify-between">
        {edit ? null : (
          <InvestedgeButtonOutlined color="error" onClick={handlecancelbutton}>
            Cancel
          </InvestedgeButtonOutlined>
        )}
      </div>
    </div>
  );
}
