import React from "react";
import Add from "../../assets/img/circle_over_plus_blue.svg";
import { productArray } from "../../arrays/productArray";
import EPFrow from "./EPFrow";
import { useState } from "react";

export default function EPFscreen() {
  const [epfarray, setepfarray] = useState(productArray.epf.items);
  const removeepfarray = (i) => {
    let newepfarray = [...epfarray];
    newepfarray.splice(i, 1);
    setepfarray(newepfarray);
  };
  return (
    <div className="w-full">
      <div className="font-medium font-inter text-[18px] text-neutralBlack">
        EPF/VPF - ₹ 62.5 L
      </div>
      <div className="flex justify-between w-[96%] mt-[4px] mb-0">
        <div className="flex justify-center items-center h-[56px]">
          <img src={Add} alt="err" />
          <p
            className="text-primary-200 text-[14px] font-medium mb-0 ml-[0.3rem]"
            style={{ marginLeft: "0.3rem" }}
            onClick={(e) => {
              let newepfarray = [...epfarray];
              newepfarray.unshift({ name: "New EPF", price: "0" });
              setepfarray(newepfarray);
            }}
          >
            Add EPF/VPF
          </p>
        </div>
        <p
          className="text-primary-200 text-[14px] font-medium mb0"
          onClick={() => setepfarray([])}
        >
          Clear All
        </p>
      </div>
      <div className="update-portfolio-asset-row-container">
        {epfarray.map((fund, index) => (
          <EPFrow
            key={index}
            fund={fund}
            id={index}
            removeepfarray={removeepfarray}
            epfarray
            setepfarray
          />
        ))}
      </div>
    </div>
  );
}
