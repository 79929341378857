import React, { useState } from "react";
import Dropdown from "../../../../components/Dropdowns/DropdownWhiteSingle";
import { useDispatch } from "react-redux";
import { PostEventLoggingData } from "../../../../actions/actions";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
import InvestedgeTextField from "../../../../components/textfield/InvestedgeTextField";
import { InputAdornment } from "@mui/material";
export default function PortfolioFirstStep({
  handleNext,
  formValue,
  setFormValue,
}) {
  const denotionParser = (denotion, value) => {
    if (denotion[2] === "K")
      return parseInt(value * 1000).toLocaleString("en-IN");
    else if (denotion[2] === "L")
      return parseInt(value * 100000).toLocaleString("en-IN");
    else if (denotion[2] === "C")
      return parseInt(value * 10000000).toLocaleString("en-IN");
    else return "";
  };
  const dispatch = useDispatch();
  let proposalDenotion;
  const [taxStatus, setTaxStatus] = useState(formValue.tax_status);
  const [taxSlab, setTaxSlab] = useState(formValue.tax_slab);
  const [name, setName] = useState(formValue.client_name);
  const [proposalValue, setProposalValue] = useState(
    denotionParser(
      formValue.proposal_value.denomination,
      formValue.proposal_value.amount
    )
  );
  const [errors, setErrors] = useState({ name: null, proposalValue: null });

  return (
    <div className="px-8 rounded-[8px] w-full bg-white pb-8 mt-[16px] min-h-[calc(100vh_-_240px)]">
      <div className="flex justify-center h-full">
        <div className="w-[94%] pt-[3rem] pb-[0.75rem] flex justify-center h-full">
          <div className="w-[55%] rounded-lg border border-globalBorderColor p-8 pb-12">
            <div className="flex justify-between w-full">
              <h2 className="card-heading">Client Details</h2>
              <InvestedgeButton
                onClick={() => {
                  const re = /^\d+$/;
                  if (
                    name.length === 0 ||
                    proposalValue.length === 0 ||
                    !re.test(proposalValue.replaceAll(",", ""))
                  ) {
                    let newerrors = { ...errors };
                    if (name.length === 0)
                      newerrors.name = "This field is required";
                    if (
                      proposalValue.length === 0 ||
                      !re.test(proposalValue.replaceAll(",", ""))
                    )
                      newerrors.proposalValue = "Please enter a valid value";
                    setErrors(newerrors);
                  } else {
                    let temp;
                    if (
                      parseFloat(proposalValue.replaceAll(",", "")) / 1000 <
                      100
                    ) {
                      proposalDenotion = "₹ K";
                      temp =
                        parseFloat(proposalValue.replaceAll(",", "")) / 1000;
                    } else if (
                      parseFloat(proposalValue.replaceAll(",", "")) / 100000 <
                      100
                    ) {
                      proposalDenotion = "₹ L";
                      temp =
                        parseFloat(proposalValue.replaceAll(",", "")) / 100000;
                    } else {
                      proposalDenotion = "₹ C";
                      temp =
                        parseFloat(proposalValue.replaceAll(",", "")) /
                        10000000;
                    }
                    setFormValue({
                      ...formValue,
                      client_name: name,
                      proposal_value: {
                        amount: parseFloat(temp),
                        denomination: proposalDenotion,
                      },
                      tax_status: taxStatus,
                      tax_slab: taxSlab,
                    });
                    dispatch(
                      PostEventLoggingData({
                        module_name: "Portfolio Insights",
                        event_type: "Next button",
                        url: window.location.href,
                        note: `First Screen`,
                      })
                    );
                    handleNext();
                  }
                }}
              >
                Next
              </InvestedgeButton>
            </div>

            <InvestedgeTextField
              required
              type="text"
              sx={{
                fontFamily: "Inter,sans-sarif",
                fontWeight: 400,
                width: "100%",
                marginTop: "2.5rem",
              }}
              label="Client Name"
              error={errors.name ? true : false}
              helperText={errors.name ? errors.name : ""}
              value={name}
              onChange={(e) => {
                if (!e.target.value) {
                  let newerrors = { ...errors };
                  newerrors.name = "This field is required";
                  setErrors(newerrors);
                } else {
                  let newerrors = { ...errors };
                  newerrors.name = null;
                  setErrors(newerrors);
                }
                setName(e.target.value);
              }}
            />

            <br />
            <div className="flex justify-between pt-[2rem]">
              {/* <div className="w-[14%]">
                <Dropdown
                  options={["₹ K", "₹ L", "₹ C"]}
                  selected={proposalDenotion}
                  setSelected={setProposalDenotion}
                  labelName={null}
                />
              </div> */}
              <InvestedgeTextField
                required
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                  width: "100%",
                }}
                label="Proposal Value"
                value={proposalValue}
                error={errors.proposalValue ? true : false}
                helperText={errors.proposalValue ? errors.name : ""}
                onChange={(e) => {
                  const re = /^\d+$/;
                  if (
                    e.target.value === "" ||
                    re.test(e.target.value.replaceAll(",", ""))
                  )
                    setProposalValue(
                      e.target.value.length
                        ? parseInt(
                            e.target.value.replaceAll(",", "")
                          ).toLocaleString("en-IN")
                        : ""
                    );
                  // const re = /^\d+$/;
                  // if (e.target.value === "" || re.test(e.target.value)) {
                  //   if (!e.target.value) {
                  //     let newerrors = { ...errors };
                  //     newerrors.proposalValue = "This field is required";
                  //     setErrors(newerrors);
                  //   } else {
                  //     let newerrors = { ...errors };
                  //     newerrors.proposalValue = null;
                  //     setErrors(newerrors);
                  //   }
                  //   setProposalValue(e.target.value);
                  // }
                }}
                min="0"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
              />
            </div>

            <div className=" mt-[2rem]">
              <Dropdown
                options={["Resident", "Non-Resident"]}
                selected={taxStatus}
                setSelected={setTaxStatus}
                labelName="Tax Status"
              />
            </div>
            <div className=" mt-[2rem]">
              <Dropdown
                options={[
                  "Less than ₹ 2.5 L",
                  "₹ 2.5L - 5L",
                  "₹ 5L - 10L",
                  "₹ 10 L AND ABOVE",
                ]}
                selected={taxSlab}
                setSelected={setTaxSlab}
                labelName="Tax Slab"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
