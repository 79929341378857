import React from "react";
import "../../../static/css/existing/dashboard_styles.css";
import { PostResearch } from "../../../actions/actions";
import { useDispatch, useSelector } from "react-redux";
import InvestedgeModal from "../../../components/modal/InvestedgeModal";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import { IoIosClose } from "react-icons/io";
export default function PublishModal({
  saveType,
  onClose,
  setChanges,
  publishModal,
  sliderData,
  setDisplayResearchData,
}) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.myresearchData);
  return (
    <InvestedgeModal
      aria-labelledby="Research screen post modal"
      aria-describedby="Research screen post modal"
      open={publishModal}
      handleclose={onClose}
      sx={{ padding: "1rem" }}
    >
      <div className="flex justify-between border-0 p-0">
        <h4 className="modal-title font-medium text-md font-inter">
          Do you want to save and publish your research view?
        </h4>
        <IoIosClose size={30} onClick={onClose} />
      </div>
      <div className="modal-body mt-3 p-0">
        <p className="text-sm leading-5">
          Once you hit the save and publish button, we will replace the existing
          research view on different assets and replace it with the current
          view. This view will be reflected across all clients’ portfolios.
        </p>
        <p className="text-sm">
          You can change the research view and publish again to reflect it
          around all the clients portfolios.
        </p>
      </div>
      <div className="flex justify-end gap-5 border-0 mt-4">
        <InvestedgeButton onClick={onClose}>Close</InvestedgeButton>
        <InvestedgeButton
          onClick={() => {
            dispatch(
              PostResearch(
                saveType === "asset"
                  ? {
                      ...data[0],
                      equity_score: sliderData.equity_score,
                      debt_score: sliderData.debt_score,
                      alternate_score: sliderData.alternate_score,
                      largecap_score: sliderData.largecap_score,
                      midcap_score: sliderData.midcap_score,
                      small_cap_score: sliderData.small_cap_score,
                      credit_score: sliderData.credit_score,
                      duration_score: sliderData.duration_score,
                      alternate_volatility_score:
                        sliderData.alternate_volatility_score,
                      equity_view: sliderData.equity_view,
                      alternate_view: sliderData.alternate_view,
                      debt_view: sliderData.debt_view,
                      comment: sliderData.comment,
                      is_active: true,
                      org_id: sliderData.org_id,
                    }
                  : {
                      ...data[0],
                      information_technology: sliderData.information_technology,
                      financials: sliderData.financials,
                      consumer_staples: sliderData.consumer_staples,
                      consumer_discretionary: sliderData.consumer_discretionary,
                      industrials: sliderData.industrials,
                      health_care: sliderData.health_care,
                      materials: sliderData.materials,
                      energy: sliderData.energy,
                      real_estate: sliderData.real_estate,
                      communication_services: sliderData.communication_services,
                      utilities: sliderData.utilities,
                      momentum: sliderData.momentum,
                      low_vol: sliderData.low_vol,
                      growth: sliderData.growth,
                      value: sliderData.value,
                      quality: sliderData.quality,
                      dividend_yield: sliderData.dividend_yield,
                      leverage: sliderData.leverage,
                      sector_view: sliderData.sector_view,
                      style_view: sliderData.style_view,
                      is_active: true,
                      org_id: sliderData.org_id,
                    }
              )
            );
            setDisplayResearchData(sliderData);
            setChanges(false);
            onClose();
          }}
        >
          Save &amp; Publish
        </InvestedgeButton>
      </div>
    </InvestedgeModal>
  );
}
