import { GET_ALLOCATION_PREFERENCE } from "../actions/type";
const initialState = {
  "Mutual Fund": [],
};
export default function GetAllocationPreference(state = initialState, action) {
  switch (action.type) {
    case GET_ALLOCATION_PREFERENCE:
      return action.payload;
    default:
      return state;
  }
}
