import React from "react";
import Add from "../../assets/img/circle_over_plus_blue.svg";
import { productArray } from "../../arrays/productArray";
import SavingsRow from "./SavingsRow";
import { useState } from "react";

export default function SavingsScreen() {
  const [savingsarray, setsavingsarray] = useState(productArray.savings.items);
  const removesavingsarray = (i) => {
    let newsavingsarray = [...savingsarray];
    newsavingsarray.splice(i, 1);
    setsavingsarray(newsavingsarray);
  };
  return (
    <div className="w-full">
      <div className="font-medium font-inter text-[18px] text-neutralBlack">
        Saving Schemes - ₹ 62.5 L
      </div>
      <div className="flex justify-between w-[96%] mt-[4px] mb-0">
        <div className="flex justify-center items-center h-[56px]">
          <img src={Add} alt="err" />
          <p
            className="text-primary-200 text-[14px] font-medium mb-0 ml-[0.3rem]"
            onClick={(e) => {
              let newsavingsarray = [...savingsarray];
              newsavingsarray.unshift({ name: "New Scheme", price: "0" });
              setsavingsarray(newsavingsarray);
            }}
          >
            Add fund
          </p>
        </div>
        <p
          className="font-medium text-[14px] text-primary-200 mb-0"
          onClick={() => setsavingsarray([])}
        >
          Clear All
        </p>
      </div>
      <div className="update-portfolio-asset-row-container">
        {savingsarray.map((fund, index) => (
          <SavingsRow
            key={index}
            fund={fund}
            id={index}
            removesavingsarray={removesavingsarray}
            savingsarray
            setsavingsarray
          />
        ))}
      </div>
    </div>
  );
}
