import React from "react";
// import TooltipIcon from "../../../assets/img/tooltip_icon.svg";
// import axios from "axios";
// import urls from "../../../urls";
// import InvestedgePopper from "../../../components/popper/InvestedgePopper";
// import { Bars } from "react-loader-spinner";
export default function MobilePortfolioProductsRow({ client }) {
  //   const [anchorElTooltip, setAnchorElTooltip] = useState(null);
  //   const [performanceData, setPerformanceData] = useState({ loading: true });
  //   const tooltipRef = useRef(null);
  const parsePortfolio = (portfolio) => {
    if (portfolio / 1000 < 100)
      return `₹ ${parseFloat(portfolio / 1000).toFixed(1)} K`;
    if (portfolio / 100000 < 100)
      return `₹ ${parseFloat(portfolio / 100000).toFixed(1)} L`;
    return `₹ ${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  };
  // const denotionParser = (denotion, value) => {
  //   if (denotion === "K") return parseInt(value * 1000);
  //   else if (denotion === "L") return parseInt(value * 100000);
  //   else return parseInt(value * 10000000);
  // };
  return (
    <div className="p-4 rounded-[8px] w-full mt-[16px] bg-white space-y-4">
      <p className="text-neutralBlack leading-[20px] font-medium">
        {client.name}
      </p>
      <p className="text-[#6A6A76] leading-[18px]">
        {`${client.asset_type} - ${client.product_type}`}
      </p>
      <div className="w-full flex justify-between">
        <div>
          <h3 className="text-[#6A6A76] leading-[20px] font-medium">
            {parsePortfolio(client.amount)}
          </h3>
          <label className="text-[#6A6A76] leading-[18px] font-normal">
            Amount
          </label>
        </div>
        <div>
          <h3 className="text-[#6A6A76] leading-[20px] font-medium">
            {`${client.individual_asset_pct} %`}
          </h3>
          <label className="text-[#6A6A76] leading-[18px] font-normal">
            Weight
          </label>
        </div>
      </div>
    </div>
  );
}
