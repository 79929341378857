import React from "react";
import PPT from "../../assets/img/ppt_file_type.svg";
import XLS from "../../assets/img/xls_file_type.svg";
export default function PreviousHistoryModalRow({ element, type }) {
  const item = new Date(element.created_at);
  var dd = String(item.getDate()).padStart(2, "0");
  var mm = String(item.getMonth() + 1).padStart(2, "0");
  var yyyy = item.getFullYear();
  return (
    <tr className="border-bottom py-0 h-[55px]">
      <td className="px-0 py-[12px]">
        <div className="flex w-full items-center">
          {/* {type === "Portfolio Review" && (
            <img
              src={element.profile_pic}
              alt="err"
              className="w-[30px] object-contain rounded-full mr-2"
            />
          )} */}
          <p className="mb-0 text-[14px]">{element.client_name}</p>
        </div>
      </td>
      <td className="px-0 py-[12px] text-center text-sm">{`${dd}-${mm}-${yyyy}`}</td>
      <td className="px-0 py-[12px] text-center text-sm">
        {item.toLocaleTimeString("en-US")}
      </td>
      <td className="px-0 py-3 ">
        <div className="flex w-full items-center justify-center">
          <img src={element.file_type === "1" ? PPT : XLS} alt="err" />{" "}
          <a
            href={element.file}
            className="mb-0 text-sm mt-0 ml-2 text-primary-200 cursor-pointer leading-0"
          >
            Link
          </a>
        </div>
      </td>
    </tr>
  );
}
