import React from "react";
import AfterloginApp from "../../AfterloginApp";
import ClientSidebar from "../ClientSidebar";
import FamilyContent from "./FamilyContent";

import "../../../static/css/existing/dashboard_styles.css";
export default function FamilyDetails(props) {
  const {
    accounttype,
    // , familyMembers
  } = (props.location && props.location.state) || {};

  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Client Input"}
        description={
          "Browse for portfolio insights, actionable, cash flow planning, and much more"
        }
      />
      <div className="loggedinscreen-container pt-0">
        <div className="loggedinscreen flex flex-row justify-between ">
          <ClientSidebar />
          <div className="p-[1rem] bg-[#fff] rounded-[0.5rem] min-h-[calc(100vh_-_116px)] client-input-parent-card w-[79%] ml-0 mt-4">
            <FamilyContent accounttype={accounttype} />
          </div>
        </div>
      </div>
    </div>
  );
}
