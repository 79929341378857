import React, { useState, useRef, useEffect } from "react";
import "../../static/css/existing/dashboard_styles.css";
import Dropdown from "../../components/Dropdowns/DropdownWhiteSingle";
// import { ClientData } from "./clientData";
import Portfolio from "./Portfolio";
import Insights from "./Insights";
import Analytics from "./Analytics";
import Performance from "./Performance";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  PostDashboardAnalytics,
  PostDashboardInsights,
  PostDashboardPerformance,
  PostDashboardPortfolio,
  PostEventLoggingData,
} from "../../actions/actions";
import AfterloginApp from "../../rmdashboard/AfterloginApp";
import Clientsidebar from "../ClientSidebar";
import InvestedgeToggleButtonGroup from "../../components/toggle/InvestedgeToggle";
export default function Clientdashboard() {
  const history = useHistory();
  const dispatch = useDispatch();
  if (!localStorage.getItem("clientId")) history.replace("/dashboard");
  const clientId = localStorage.getItem("clientId");
  const options = [localStorage.getItem("clientName")];
  // ClientData.map((member, index) => {
  //   return { optionname: member.name, optionid: index };
  // });
  const myRef = useRef(null);
  const [selected, setSelected] = useState(options[0]);
  const [route, setRoute] = useState(1);
  const handleTabClick = (e, val) => {
    if (val) {
      var elmnt = document.getElementById("client-dashboard-screen");
      setRoute(val);
      elmnt.scrollTo({
        top: val,
        behavior: "smooth",
      });
      dispatch(
        PostEventLoggingData({
          module_name: "Client Dashboard",
          event_type: "Information",
          url: window.location.href,
          note: `Toggle tab clicked`,
        })
      );
    }
  };
  useEffect(() => {
    dispatch(PostDashboardPortfolio({ client_id: clientId }));
    dispatch(PostDashboardInsights({ client_id: clientId }));
    dispatch(PostDashboardAnalytics({ client_id: clientId }));
    dispatch(PostDashboardPerformance({ client_id: clientId }));
    // eslint-disable-next-line
  }, [dispatch]);
  const toggletabs = [
    { label: "Portfolio", value: 1 },
    { label: "Insights", value: 450 },
    { label: "Analytics", value: 1100 },
    { label: "Performance", value: 2050 },
  ];
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp />
      <div
        className="loggedinscreen-container pt-0"
        id="client-dashboard-screen"
      >
        <div className="loggedinscreen flex flex-row justify-between">
          <Clientsidebar />
          <div className="p-[1rem] bg-[#fff] rounded-[0.5rem] min-h-full pt-0 ml-0 shadow-none client-input-parent-card w-[79%]">
            <div className="sticky top-0 bg-[#f3f3f3] ml-[-22px] mr-[-22px] z-[10] pt-[1rem] px-[6px]">
              <div className="w-full h-full rounded-t-[0.5rem] bg-[#fff] p-[1rem]">
                <div className="flex justify-between w-full">
                  <div className="w-[22%]">
                    <Dropdown
                      options={options}
                      selected={selected}
                      setSelected={setSelected}
                      labelName="Client"
                    />
                  </div>
                  <div className="flex w-full justify-end">
                    <InvestedgeToggleButtonGroup
                      tabs={toggletabs}
                      value={route}
                      onChange={handleTabClick}
                      sx={{ height: "40px", "& button": { width: "152px" } }}
                      exclusive
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-[#fff] m-[-1rem] p-[1rem] rounded-b-[0.5rem]">
              <Portfolio />
              <Insights />
              <Analytics myref={myRef} />
              <Performance />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
