import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import SaveDetails from "../../assets/img/save_model_description.svg";
import Dropdown from "../../components/Dropdowns/DropdownForAttributes";
import InvestedgeModal from "../../components/modal/InvestedgeModal";

export default function DescriptionModel({
  desModal,
  readOnly,
  onClose,
  portfolioDescription,
  setPortfolioDescription,
}) {
  const [temp, setTemp] = useState(portfolioDescription);
  const purposePortfolioOption = [
    "Dream Car",
    "Dream Home",
    "Retirement Planning",
    "Child Education",
    "Child Marriage",
    "Miscellaneous Goal",
    "Wealth Creation",
    "Other",
  ];
  const riskProfileOption = [
    "Conservative",
    "Moderately Conservative",
    "Moderate",
    "Moderately Aggressive",
    "Aggressive",
    "Other",
  ];
  const portfolioRangeOption = [
    "Less than 25 lakhs",
    "Between 25 lakhs to 1 Crores",
    "More than 2 Crores",
    "Other",
  ];
  const productOption = [
    "Mutual Fund Portfolio",
    "Equity Portfolio",
    "Debt Portfolio",
    "Multi Product Portfolio",
    "Other",
  ];
  const returnProfileOption = [
    "Less than 8%",
    "8%-10%",
    "10%-12%",
    "More than 12%",
    "Other",
  ];
  const investmentHorizonOption = [
    "Less than 3 Years",
    "3 Years",
    "5 years",
    "7 years",
    "10 years",
    "More than 10 years",
    "Other",
  ];
  return (
    <InvestedgeModal
      aria-labelledby="Description Modal"
      aria-describedby="Description Modal"
      sx={{ margin: "auto", minWidth: "45vw" }}
      open={desModal}
      handleclose={() => {
        setTemp(portfolioDescription);
        onClose();
      }}
    >
      <div className="flex w-full justify-between items-center">
        <h5>
          {" "}
          {readOnly
            ? "Following are the parameters for this portfolio"
            : "Do you want to modify the parameters for your portfolio ?"}
        </h5>
        <IoIosClose
          size={30}
          onClick={() => {
            setTemp(portfolioDescription);
            onClose();
          }}
        />
      </div>
      <div className="w-[60%] mt-6">
        <Dropdown
          options={purposePortfolioOption}
          selected={temp}
          setSelected={setTemp}
          disabled={readOnly}
          attribute="purposeOfPortfolio"
          labelName="Purpose of Portfolio"
        />
      </div>
      <div className="w-[60%] mt-6">
        <Dropdown
          options={riskProfileOption}
          selected={temp}
          setSelected={setTemp}
          disabled={readOnly}
          attribute="riskProfileType"
          labelName="Risk Profile Type "
        />
      </div>
      <div className="w-[60%] mt-6">
        <Dropdown
          options={portfolioRangeOption}
          selected={temp}
          setSelected={setTemp}
          disabled={readOnly}
          attribute="portfolioValueRange"
          labelName="Portfolio Value Range"
        />
      </div>
      <div className="w-[60%] mt-6">
        <Dropdown
          options={productOption}
          selected={temp}
          setSelected={setTemp}
          disabled={readOnly}
          attribute="productType"
          labelName="Product Type"
        />
      </div>
      <div className="w-[60%] mt-6">
        <Dropdown
          options={returnProfileOption}
          selected={temp}
          setSelected={setTemp}
          disabled={readOnly}
          attribute="returnProfileType"
          labelName="Return Profile Type"
        />
      </div>
      <div className="w-[60%] mt-6">
        <Dropdown
          options={investmentHorizonOption}
          selected={temp}
          setSelected={setTemp}
          disabled={readOnly}
          attribute="investmentHorizon"
          labelName="Investment Horizon"
        />
      </div>
      {!readOnly && (
        <div className="w-full flex justify-end mt-6">
          <InvestedgeButton
            onClick={() => {
              onClose();
              setPortfolioDescription(temp);
            }}
          >
            <img src={SaveDetails} alt="err" className="mr-2" />
            Save Details
          </InvestedgeButton>{" "}
        </div>
      )}
    </InvestedgeModal>
  );
}
