import React, { useState, useEffect } from "react";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Dropdown from "../../../components/Dropdowns/DropdownWhiteSingle";
import Query from "../../../assets/img/telegram_app.svg";
import {
  PostDashboardRedeemCapital,
  EmptyDashboardRedeemCapital,
} from "../../../actions/actions";
import { useDispatch, useSelector } from "react-redux";
import RedeemOption from "./RedeemOption";
import AfterloginApp from "../../../rmdashboard/AfterloginApp";
import Clientsidebar from "../../ClientSidebar";
import InvestedgeTextField from "../../../components/textfield/InvestedgeTextField";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
export default function RedeemCapital() {
  const [amountDenotion, setAmountDenotion] = useState("₹ L");
  const [amount, setAmount] = useState("");
  const backendData = useSelector((state) => state.dashboardRedeemCapital);
  const [queryResults, setQueryResults] = useState({});
  const [optionsState, setOptionsState] = useState([]);
  const handleAmountChange = (e) => {
    const re = /^\d*[0-9]+\d*$/;
    if (e.target.value === "" || re.test(e.target.value))
      setAmount(e.target.value);
  };
  const [redeemAsset, setRedeemAsset] = React.useState({
    equity: true,
    debt: false,
    alternates: false,
    multiAssets: false,
  });
  // const [redemption, setRedemption] = useState("IPS Deviation");
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setRedeemAsset({
      ...redeemAsset,
      [event.target.name]: event.target.checked,
    });
  };
  const handleQuery = () => {
    const redeem = [];
    if (redeemAsset.equity) redeem.push("Equity");
    if (redeemAsset.debt) redeem.push("Debt");
    if (redeemAsset.alternates) redeem.push("Alternates");
    if (redeemAsset.multiAssets) redeem.push("MultiAssets");
    dispatch(
      PostDashboardRedeemCapital({
        client_id: localStorage.getItem("clientId"),
        amount_to_redeem: {
          amount: parseFloat(amount),
          denomination: amountDenotion[2],
        },
        asset_class: redeem,
        // redemption_based_on: redemption,
      })
    );
    setQueryResults(backendData);
  };
  useEffect(() => {
    setOptionsState(new Array(Object.keys(backendData).length).fill(false));
    setQueryResults(backendData);
  }, [backendData]);
  useEffect(() => {
    dispatch(EmptyDashboardRedeemCapital());
    return () => {
      dispatch(EmptyDashboardRedeemCapital());
    }; // eslint-disable-next-line
  }, []);
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp />
      <div className="loggedinscreen-container pt-0">
        <div className="loggedinscreen flex flex-row justify-between">
          <Clientsidebar />
          <div className="w-[79%] mt-4">
            <div className="p-[1rem] bg-[#fff] rounded-[0.5rem] min-h-[28%] w-full ml-0">
              <h1 className="card-heading">Reedem Capital</h1>
              <div className="w-full flex justify-between pt-[1rem]">
                <div className="w-[25%] flex justify-between items-center">
                  <div className="w-[30%]">
                    <Dropdown
                      options={["₹ L", "₹ K", "₹ C"]}
                      selected={amountDenotion}
                      setSelected={setAmountDenotion}
                      labelName={null}
                    />
                  </div>
                  <div className="w-[68%]">
                    <InvestedgeTextField
                      variant="outlined"
                      fullWidth
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                      }}
                      label="Amount To Redeem"
                      value={amount}
                      onChange={(e) => handleAmountChange(e)}
                    />
                  </div>
                </div>
                <div className="w-[40%]">
                  <FormControl
                    sx={{
                      width: "100%",
                      display: "flex",
                      fontFamily: "Inter,sans-serif",
                    }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormLabel
                      component="legend"
                      className="font-inter text-sm text-neutralBlack"
                    >
                      Asset Class
                    </FormLabel>
                    <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={redeemAsset.equity}
                            onChange={handleChange}
                            name="equity"
                          />
                        }
                        label={
                          <span className="font-inter text-sm">Equity</span>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={redeemAsset.debt}
                            onChange={handleChange}
                            name="debt"
                          />
                        }
                        label={<span className="font-inter text-sm">Debt</span>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={redeemAsset.alternates}
                            onChange={handleChange}
                            name="alternates"
                          />
                        }
                        label={
                          <span className="font-inter text-sm">Alternates</span>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={redeemAsset.multiAssets}
                            onChange={handleChange}
                            name="multiAssets"
                          />
                        }
                        label={
                          <span className="font-inter text-sm">
                            Multi-Assets
                          </span>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </div>
                {/* <div className="w-[25%]">
                  <Dropdown
                    options={[
                      "IPS Deviation",
                      "Liquidity Deviation",
                      "Taxation Deviation",
                      "Underperformance Deviation",
                    ]}
                    selected={redemption}
                    setSelected={setRedemption}
                    labelName="Redemption Based On"
                  />
                </div> */}
              </div>
              <div className="w-full flex justify-end mt-4">
                <InvestedgeButton onClick={handleQuery}>
                  Show Results
                </InvestedgeButton>
              </div>
            </div>
            <div className="client-input-parent-card min-h-[69%] bg-[#fff] p-[1rem]  rounded-[0.5rem]  mt-[1%] w-full ml-0">
              <h3 className="card-heading">Suggested Redeem Options</h3>
              {Object.keys(queryResults).length ? (
                <>
                  {Object.keys(queryResults).map((option, index) => (
                    <RedeemOption
                      key={index}
                      option={option}
                      index={index}
                      queryResults={queryResults}
                      optionsState={optionsState}
                      setOptionsState={setOptionsState}
                    />
                  ))}

                  {/* <h3
                className="portfolio-stepper-sixth-form-sub-status"
                style={{
                  color: "#1982F8",
                  textAlign: "left",
                  marginTop: "1rem",
                  cursor: "pointer",
                }}
              >
                See more options
              </h3> */}
                </>
              ) : (
                <div className=" w-full min-h-[350px] flex flex-col justify-center items-center">
                  <img src={Query} alt="err" />
                  <h3 className=" font-inter text-[24px] font-medium leading-[30px] tracking-[0.1133817583322525px] mb-[1rem] text-center">
                    Run Query for results
                  </h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
