import { GET_DASHBOARD_INVESTMENT } from "../actions/type";
const initialState = {
  ip_statement: {
    id: 5,
    user_id: "307f8235-a831-42ee-9b4f-d2023e4cda9d",
    flag: true,
    equity: {
      max: 5,
      min: 30,
      target: 0.5,
    },
    debt: {
      max: 5,
      min: 25,
      target: 0.45,
    },
    alternate: {
      max: 5,
      min: 0,
      target: 0.05,
    },
    multi_assets: {
      max: 0.5,
      min: 0,
      target: 0.5,
    },
    large_cap: {
      max: 5,
      min: 30,
      target: 0.5,
    },
    mid_cap: {
      max: 5,
      min: 20,
      target: 0.4,
    },
    small_cap: {
      max: 5,
      min: 0,
      target: 0.1,
    },
    duration: {
      max: 4,
      min: 2,
      target: 0.03,
    },
    aaa_equiv: {
      max: 100,
      min: 100,
      target: 200,
    },
    alternates: {
      max: 5,
      min: 0,
      target: 0.05,
    },
  },
  ip_product_understanding: {
    id: 5,
    user_id: "307f8235-a831-42ee-9b4f-d2023e4cda9d",
    mfund: {
      interest: true,
      understanding: 3,
    },
    stock: {
      interest: true,
      understanding: 3,
    },
    bond: {
      interest: true,
      understanding: 3,
    },
    pm: {
      interest: true,
      understanding: 3,
    },
    aif: {
      interest: true,
      understanding: 3,
    },
    cash: {
      interest: true,
      understanding: 3,
    },
    deposit: {
      interest: true,
      understanding: 3,
    },
    gold_silver: {
      interest: true,
      understanding: 3,
    },
    small_saving_scheme: {
      interest: true,
      understanding: 3,
    },
    estate: {
      interest: true,
      understanding: 3,
    },
    epf_vpf: {
      interest: true,
      understanding: 3,
    },
    ppf: {
      interest: true,
      understanding: 3,
    },
    unlisted_states: {
      interest: true,
      understanding: 3,
    },
  },
  ip_profile: {
    id: "63f52e15-73f0-4eb1-9b09-2148661f6d61",
    user_id: "307f8235-a831-42ee-9b4f-d2023e4cda9d",
    investmentHorizon: 0,
    investmentCorpus: {
      amount: 0,
      denomination: "L",
    },
    riskScore: 46,
  },
};
export default function GetDashboardInvestment(state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_INVESTMENT:
      return action.payload;
    default:
      return state;
  }
}
