import React, { useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import "../../../../../static/css/existing/dashboard_styles.css";
import { PerformanceOnepager } from "./PerformanceOnepager";
import { RiskAndQuants } from "./RiskAndQuants";
import { KeyMetrics } from "./KeyMetrics";
import { Manager } from "./Manager";
import InvestedgeToggleButtonGroup from "../../../../../components/toggle/InvestedgeToggle";
import { useEffect } from "react";
export default function OverView({ fundData }) {
  // const data = {
  //   labels: fundData.bench_mark.map((el) => {
  //     // let d = el.date.split("-");
  //     return el.date;
  //   }),
  //   datasets: [
  //     {
  //       label: fundData.final_data.name.slice(0, 20),
  //       data: fundData.bench_mark.map((price) => {
  //         let priceVal = price.value * 1.5;
  //         return priceVal.toFixed(2);
  //       }),
  //       fill: false,
  //       spanGaps: true,
  //       borderColor: "#304ACA",
  //       tension: 0.1,
  //       opacity: "0.3",
  //       borderWidth: 3,
  //     },
  //     {
  //       label: fundData.final_data.benchmark_name,
  //       data: fundData.bench_mark.map((price) => {
  //         return price.value;
  //       }),
  //       fill: false,
  //       spanGaps: true,
  //       borderColor: "#56B787",
  //       tension: 0.1,
  //       opacity: "0.3",
  //       borderWidth: 3,
  //     },
  //   ],
  // };
  const options = {
    animation: {
      duration: 1000,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            maxTicksLimit: 6,
            maxRotation: 0,
            beginAtZero: true,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            callback: function (value, index, values) {
              if (value < 0) return +(value / 1e3).toFixed(1) + "K";
              if (value < 1e3) return value;
              if (value >= 1e3) return +(value / 1e3).toFixed(1) + "K";
            },
          },
          type: "linear",
          display: true,
          position: "left",
        },
      ],
    },

    elements: {
      bar: {
        borderWidth: 3,
      },
      point: {
        radius: 0,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: false,
    },
    plugins: {
      title: {
        display: true,
      },
      labels: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      points: {
        display: false,
      },

      interaction: {
        mode: "index",
        intersect: false,
      },
    },
  };
  const size = Object.keys(fundData.json_field.performance).length;
  let dataLength;
  if (size) {
    dataLength = Object.keys(fundData.json_field.performance.data).length;
  } else {
    dataLength = null;
  }
  const [cagrDataFund, setCagrData] = useState(
    (size && !dataLength) ||
      (!size && !dataLength) ||
      (dataLength && !fundData.json_field.performance.data["5y_cagr"])
      ? "0%"
      : `${fundData.json_field.performance.data["5y_cagr"].toFixed(2)}%`
  );
  const [cagrValue, setCagrValue] = useState("5Y CAGR");
  const [timeToggle, setTimeToggle] = useState("Max");
  const cagrRefVal = useRef();
  cagrRefVal.current =
    (size && !dataLength) ||
    (!size && !dataLength) ||
    (dataLength && !fundData.json_field.performance.data["5y_cagr"])
      ? "0%"
      : `${fundData.json_field.performance.data["5y_cagr"].toFixed(2)}%`;

  const mockData = {
    label: ["1 Nov", "5 Nov", "10 Nov", "15 Nov", "20 Nov", "25 Nov", "30 Nov"],
    datasets: [
      {
        label: [
          "1 Nov",
          "5 Nov",
          "10 Nov",
          "15 Nov",
          "20 Nov",
          "25 Nov",
          "30Nov",
        ],
        data: [2, 3, 6, 8, 10, 11, 14],
        fill: false,
        spanGaps: true,
        borderColor: "#304ACA",
        tension: 0.1,
        opacity: "0.3",
        borderWidth: 3,
      },
      {
        label: [
          "1 Nov",
          "5 Nov",
          "10 Nov",
          "15 Nov",
          "20 Nov",
          "25 Nov",
          "30 Nov",
        ],
        data: [1, 3, 4, 3, 2, 6, 3],
        fill: false,
        spanGaps: true,
        borderColor: "#56B787",
        tension: 0.1,
        opacity: "0.3",
        borderWidth: 3,
      },
    ],
  };
  const tabs = [
    { label: "1M", value: "1M" },
    { label: "6M", value: "6M" },
    { label: "1Y", value: "1Y" },
    { label: "3Y", value: "3Y" },
    { label: "5Y", value: "5Y" },
    { label: "Max", value: "Max" },
  ];
  const handleChangedtime = (e, newAlignment) => {
    if (newAlignment) setTimeToggle(newAlignment);
  };
  const OnepagerLegend = ({ color, text }) => {
    return (
      <div className="flex mt-[15px]">
        <div
          className="w-[10px] h-[10px] mr-[15px] mt-[5px]"
          style={{
            backgroundColor: color,
            borderRadius: "50%",
          }}
        />
        <p className="font-inter text-[14px] font-normal leading-5">{text}</p>
      </div>
    );
  };
  const handleClick = () => {
    if (timeToggle === "1M") {
      (size && !dataLength) ||
      (!size && !dataLength) ||
      (dataLength && !fundData.json_field.performance.data["1m_abs"])
        ? setCagrData("0%")
        : setCagrData(
            `${fundData.json_field.performance.data["1m_abs"].toFixed(2)}%`
          );
      setCagrValue("1M ABS");
    }
    if (timeToggle === "6M") {
      (size && !dataLength) ||
      (!size && !dataLength) ||
      (dataLength && !fundData.json_field.performance.data["6m_abs"])
        ? setCagrData("0%")
        : setCagrData(
            `${fundData.json_field.performance.data["6m_abs"].toFixed(2)}%`
          );
      setCagrValue("6M ABS");
    }
    if (timeToggle === "1Y") {
      (size && !dataLength) ||
      (!size && !dataLength) ||
      (dataLength && !fundData.json_field.performance.data["1y_cagr"])
        ? setCagrData("0%")
        : setCagrData(
            `${fundData.json_field.performance.data["1y_cagr"].toFixed(2)}%`
          );
      setCagrValue("1Y CAGR");
    }
    if (timeToggle === "3Y") {
      // MonthSetData(total value of days in 3 year == 1000)
      (size && !dataLength) ||
      (!size && !dataLength) ||
      (dataLength && !fundData.json_field.performance.data["3y_cagr"])
        ? setCagrData("0%")
        : setCagrData(
            `${fundData.json_field.performance.data["3y_cagr"].toFixed(2)}%`
          );
      setCagrValue("3Y CAGR");
    }
    if (timeToggle === "5Y") {
      // MonthSetData(data.labels.length)
      (size && !dataLength) ||
      (!size && !dataLength) ||
      (dataLength && !fundData.json_field.performance.data["5y_cagr"])
        ? setCagrData("0%")
        : setCagrData(
            `${fundData.json_field.performance.data["5y_cagr"].toFixed(2)}%`
          );
      setCagrValue("5Y CAGR");
    }
  };
  useEffect(() => {
    handleClick();
    // eslint-disable-next-line
  }, [timeToggle]);
  // const [updatedData, setUpdatedData] = useState({
  //   ...data,
  // });
  // const MonthSetData = (days) => {
  //   // switch (days) {
  //   //   case 31:
  //   //     data.labels = data.labels.map((el) => el[0] + " " + el[1]);
  //   //     break;
  //   //   default:
  //   //     data.labels = data.labels.map((el) => el[1] + " " + el[2]);
  //   //     break;
  //   // }
  //   setUpdatedData({
  //     ...data,
  //     labels: data.labels.slice(data.labels.length - days),
  //     datasets: [
  //       {
  //         ...data.datasets[0],
  //         data: data.datasets[0].data.slice(
  //           data.datasets[0].data.length - days
  //         ),
  //       },
  //       {
  //         ...data.datasets[1],
  //         data: data.datasets[1].data.slice(
  //           data.datasets[1].data.length - days
  //         ),
  //       },
  //     ],
  //   });
  // };

  return (
    <>
      <div
        className="onepager-main-card"
        style={{
          borderRadius: "0px 0px 8px 8px",
        }}
      >
        <div className="flex w-full justify-between">
          <div className="w-[72%]">
            <div className="flex justify-between w-full">
              <div>
                <p className="font-inter text-[16px] leading-5 font-semibold text-[#00B031] m-[0px]">
                  {cagrDataFund}
                </p>
                <p className="font-inter text-[14px] leading-5 font-normal mt-[5px] text-[#3D3D3D]">
                  {cagrValue}
                </p>
              </div>
              <InvestedgeToggleButtonGroup
                tabs={tabs}
                value={timeToggle}
                onChange={handleChangedtime}
                onClick={handleClick}
                exclusive
              />
            </div>

            <div
              className="chart-container-div w-[100%] h-[400px]"
              // style={{
              //   width: "100%",
              //   height: "400px",
              // }}
            >
              <Line id="canvas" data={mockData} options={options} />
            </div>
            <div className="flex justify-around pt-[0.5rem] w-[60%] m-auto">
              <OnepagerLegend
                color={"#304ACA"}
                text={fundData.json_field.fund_details.name}
              />
              <OnepagerLegend color={"#56B787"} text={"Nifty 50 TR"} />
            </div>
          </div>
          {/* second div */}

          <div className="onepager-return-right-card p-4 h-[460px]  w-[27%] rounded-[8px] bg-[#FFF] border-[1px] ">
            <p
              className="card-heading mb-2"
              style={{
                fontSize: "14px",
              }}
            >
              Fund Checklist
            </p>
            <ul className="pl-[1rem] list-disc">
              <li className="text-[#6A6A6A] text-[14px] font-normal font-inter mb-[1.5rem]">
                Fund has been able to generate better returns compared to other
                funds in the same category.
              </li>
              <li className="text-[#6A6A6A] text-[14px] font-normal font-inter mb-[1.5rem]">
                Less expense ratio implies better returns over the long term.
              </li>
            </ul>
          </div>
        </div>
        <PerformanceOnepager />
        <RiskAndQuants />
        <KeyMetrics />
        <Manager />
      </div>
    </>
  );
}
