import { POST_DASHBOARD_PORTFOLIO } from "../actions/type";
const initialState = {
  actualCorpus: {
    amount: 0,
    denomination: "",
  },
  equity: {
    "Mutual Fund": 0,
  },
  debt: {
    "Mutual Fund": 0,
  },
  alternate: {
    "Mutual Fund": 0,
  },
  download: {
    holding_report: "url",
    client_summary: "url",
  },
};
export default function PostDashboardPortfolio(state = initialState, action) {
  switch (action.type) {
    case POST_DASHBOARD_PORTFOLIO:
      return action.payload;
    default:
      return state;
  }
}
