import React from "react";
import { Checkbox } from "@mui/material";
export default function Offshorerow({
  fund,
  productarray,
  setProductarray,
  patchProductArray,
  setPatchProductArray,
  id,
  name,
  data,
}) {
  const parsePortfolio = (portfolio) => {
    if (portfolio / 1000 < 100)
      return `${parseFloat(portfolio / 1000).toFixed(1)} K`;
    if (portfolio / 100000 < 100)
      return `${parseFloat(portfolio / 100000).toFixed(1)} L`;
    return `${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  };
  return (
    <div className="flex justify-between mt-2 min-h-[42px]">
      <div className="w-[5%]">
        {!fund.goal_id || fund.goal_id === id ? (
          <Checkbox
            checked={fund.goal_id ? true : false}
            onChange={(e) => {
              //editing mf array
              let newMfArray = [...productarray];
              let index = productarray.findIndex((pid) => pid.id === fund.id);
              newMfArray[index].goal_id = e.target.checked ? id : null;
              setProductarray(newMfArray);
              //editing patch mf array
              let newPatchMfArray = [...patchProductArray];
              let patchindex = newPatchMfArray.findIndex(
                (pid) => pid.id === fund.id
              );
              if (patchindex === -1) {
                newPatchMfArray.push({
                  ...fund,
                  purchase_date: new Date(fund.purchase_date),
                  goal_id: e.target.checked ? id : null,
                  goal_title: e.target.checked ? name : null,
                });
              } else {
                let dataIndex = data.findIndex((pid) => pid.id === fund.id);
                if ((e.target.checked ? id : null) === data[dataIndex].goal_id)
                  newPatchMfArray.splice(patchindex, 1);
                else {
                  newPatchMfArray[patchindex].goal_id = e.target.checked
                    ? id
                    : null;
                  newPatchMfArray[patchindex].goal_title = e.target.checked
                    ? name
                    : null;
                }
              }
              setPatchProductArray(newPatchMfArray);
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        ) : (
          ""
        )}
      </div>

      <div
        className={
          fund.goal_id === id
            ? "w-[93%] bg-primary-accent flex items-center px-2 rounded-md"
            : "w-[93%] bg-[#FAFAFA] flex items-center px-2 rounded-md"
        }
      >
        <p className="w-[60%] text-sm">{fund.name}</p>
        <p className="w-[20%] text-sm text-center">
          {parsePortfolio(fund.count_units * fund.current_price)}
        </p>
        <p className="w-[20%] text-sm text-center">
          {fund.goal_title ? fund.goal_title : ""}
        </p>
      </div>
    </div>
  );
}
