import React, { useEffect, useState } from "react";
import "../../../static/css/existing/dashboard_styles.css";
import Add from "../../../assets/img/commisionsadd.svg";
import Subtract from "../../../assets/img/commisionssubtract.svg";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../../../components/Dropdowns/DropdownWhiteSingle";
import { Recommendation } from "../../../arrays/globalArray";
import TextField from "@mui/material/TextField";
import { getComparisonData } from "../../../actions/actions";
import ReactPaginate from "react-paginate";
import NextArrow from "../../../assets/img/pagination_next.svg";
import { Bars } from "react-loader-spinner";

export default function CollateralScreen({
  collateralModel,
  setCollateralModel,
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getComparisonData());
    // eslint-disable-next-line
  }, []);

  const data = useSelector((store) => store.getComparisonData);
  const [productsInitialsData, setProductsInitalData] = useState({});
  const [productsChangedData, setProductsChangedData] = useState({});
  const [productsData, setProductsData] = useState(data);
  const [searchProduct, setSearchProduct] = useState("");
  const [asset, setAsset] = useState("All");
  const [product, setProduct] = useState("All");
  const [category, setCategory] = useState("All");
  const [recommendation, setRecommendation] = useState("All");
  const assetSet = new Set(data.map((product) => product.asset_type));
  const productSet = new Set(data.map((product) => product.product_type));
  const categorySet = new Set(data.map((product) => product.category));
  const [pageNumber, setPageNumber] = useState(0);
  const clientsPerPage = 5;
  const pagesVisited = pageNumber * clientsPerPage;
  const displaydata =
    Array.isArray(productsData) &&
    productsData
      .slice(pagesVisited, pagesVisited + clientsPerPage)
      .map((pid) => {
        return (
          <tr key={pid.id} className="border border-[#e0dddd]">
            <td className="text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-4 tracking-[0em] p-4 text-left pl-4">
              {pid.name}
            </td>
            <td className="text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-4 tracking-[0em] p-4 text-center">
              {pid.asset_type}
            </td>
            <td className="text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-4 tracking-[0em] p-4 text-center">
              {pid.product_type}
            </td>
            <td className="text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-4 tracking-[0em] p-4 text-center">
              {pid.category}
            </td>
            <td>
              <div className="flex justify-center">
                <img
                  src={Subtract}
                  alt="err"
                  className="mr-[5%]"
                  onClick={() => handleCommissionChange(pid.product_id, -0.1)}
                />
                <input
                  className=" w-[30%] h-[25px] text-center font-inter text-[14px]"
                  value={Math.round(pid.commissions * 10) / 10}
                />
                <img
                  src={Add}
                  alt="err"
                  className="mr-[5%]"
                  onClick={() => handleCommissionChange(pid.product_id, 0.1)}
                />
              </div>
            </td>
            <td className="d-flex flex-row justify-center pt-4">
              <div
                className=" font-inter text-[14px] font-medium text-center w-[30%] py-[0.25rem] px-[0rem] cursor-pointer tracking-[-0.009]"
                // style={
                //   pid.recommendation === "Buy"
                //     ? {
                //         borderRadius: "10px 0 0 10px",
                //         backgroundColor: "#0122af",
                //         color: "white",
                //       }
                //     : {
                //         borderRadius: "10px 0 0 10px",
                //         border: "1px solid #999999",
                //       }
                // }
                // onClick={() =>
                //   handleRecommendationChange(pid.product_id, "Buy")
                // }
              >
                Buy
              </div>
              <div
                className=" font-inter text-[14px] font-medium text-center w-[30%] py-[0.25rem] px-[0rem] cursor-pointer tracking-[-0.009]"
                // style={
                //   pid.recommendation === "Hold"
                //     ? {
                //         backgroundColor: "#0122af",
                //         color: "white",
                //       }
                //     : { border: "1px solid #999999" }
                // }
                // onClick={() =>
                //   handleRecommendationChange(pid.product_id, "Hold")
                // }
              >
                Hold
              </div>
              <div
                className=" font-inter text-[14px] font-medium text-center w-[30%] py-[0.25rem] px-[0rem] cursor-pointer tracking-[-0.009]"
                // style={
                //   pid.recommendation === "Sell"
                //     ? {
                //         backgroundColor: "#0122af",
                //         color: "white",
                //         borderRadius: "0 10px 10px 0",
                //       }
                //     : {
                //         borderRadius: "0 10px 10px 0",
                //         border: "1px solid #999999",
                //       }
                // }
                // onClick={() =>
                //   handleRecommendationChange(pid.product_id, "Sell")
                // }
              >
                Sell
              </div>
            </td>
          </tr>
        );
      });

  const pageCount = Math.ceil(productsData.length / clientsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    let temp = {};
    data.map(
      (product) =>
        (temp[product.product_id] = {
          ...product,
          commissions: (product.commissions * 100).toFixed(1),
        })
    );

    setProductsInitalData(temp);
    setProductsChangedData({});
    setProductsData(
      data.map((product) => {
        return {
          ...product,
          commissions: (product.commissions * 100).toFixed(1),
        };
      })
    );
  }, [data]);

  const handlingChangedValues = (id, recommendation, commission) => {
    let temp = productsInitialsData[id];
    setProductsChangedData({
      ...productsChangedData,
      [id]: {
        ...temp,
        recommendation: recommendation,
        commissions: parseFloat(commission),
      },
    });
  };

  // const handleRecommendationChange = (id, recommendationStatus) => {
  //   let newproductsData = [...productsData];
  //   let index = newproductsData.findIndex((pid) => pid.product_id === id);
  //   newproductsData[index].recommendation = recommendationStatus;
  //   handlingChangedValues(
  //     id,
  //     recommendationStatus,
  //     newproductsData[index].commissions
  //   );
  //   setProductsData(newproductsData);
  // };

  const handleCommissionChange = (id, value) => {
    let newproductsData = [...productsData];
    let index = newproductsData.findIndex((pid) => pid.product_id === id);
    newproductsData[index].commissions =
      parseFloat(newproductsData[index].commissions) + value;
    newproductsData[index].commissions = Math.max(
      newproductsData[index].commissions,
      0
    );
    handlingChangedValues(
      id,
      newproductsData[index].recommendation,
      Math.max(newproductsData[index].commissions, 0)
    );
    setProductsData(newproductsData);
  };

  // useEffect(() => {
  //   if (
  //     asset === "All" &&
  //     product === "All" &&
  //     category === "All" &&
  //     recommendation === "All"
  //   )
  //     setProductsData(
  //       data
  //         .filter((data) =>
  //           data.name.toLowerCase().includes(searchProduct.toLowerCase())
  //         )
  //         .map((product) => {
  //           return {
  //             ...product,
  //             commissions: (product.commissions * 100).toFixed(1),
  //           };
  //         })
  //     );
  //   else if (asset === "All" && product === "All" && category === "All")
  //     setProductsData(
  //       data
  //         .filter(
  //           (data) =>
  //             data.recommendation === recommendation &&
  //             data.name &&
  //             data.name.toLowerCase().includes(searchProduct.toLowerCase())
  //         )
  //         .map((product) => {
  //           return {
  //             ...product,
  //             commissions: (product.commissions * 100).toFixed(1),
  //           };
  //         })
  //     );
  //   else if (asset === "All" && product === "All" && recommendation === "All")
  //     setProductsData(
  //       data
  //         .filter(
  //           (data) =>
  //             data.category === category &&
  //             data.name &&
  //             data.name.toLowerCase().includes(searchProduct.toLowerCase())
  //         )
  //         .map((product) => {
  //           return {
  //             ...product,
  //             commissions: (product.commissions * 100).toFixed(1),
  //           };
  //         })
  //     );
  //   else if (asset === "All" && recommendation === "All" && category === "All")
  //     setProductsData(
  //       data
  //         .filter(
  //           (data) =>
  //             data.product_type === product &&
  //             data.name &&
  //             data.name.toLowerCase().includes(searchProduct.toLowerCase())
  //         )
  //         .map((product) => {
  //           return {
  //             ...product,
  //             commissions: (product.commissions * 100).toFixed(1),
  //           };
  //         })
  //     );
  //   else if (
  //     product === "All" &&
  //     recommendation === "All" &&
  //     category === "All"
  //   )
  //     setProductsData(
  //       data
  //         .filter(
  //           (data) =>
  //             data.asset_type === asset &&
  //             data.name &&
  //             data.name.toLowerCase().includes(searchProduct.toLowerCase())
  //         )
  //         .map((product) => {
  //           return {
  //             ...product,
  //             commissions: (product.commissions * 100).toFixed(1),
  //           };
  //         })
  //     );
  //   else if (asset === "All" && product === "All")
  //     setProductsData(
  //       data
  //         .filter(
  //           (data) =>
  //             data.recommendation === recommendation &&
  //             data.category === category &&
  //             data.name &&
  //             data.name.toLowerCase().includes(searchProduct.toLowerCase())
  //         )
  //         .map((product) => {
  //           return {
  //             ...product,
  //             commissions: (product.commissions * 100).toFixed(1),
  //           };
  //         })
  //     );
  //   else if (asset === "All" && category === "All")
  //     setProductsData(
  //       data
  //         .filter(
  //           (data) =>
  //             data.recommendation === recommendation &&
  //             data.product_type === product &&
  //             data.name &&
  //             data.name.toLowerCase().includes(searchProduct.toLowerCase())
  //         )
  //         .map((product) => {
  //           return {
  //             ...product,
  //             commissions: (product.commissions * 100).toFixed(1),
  //           };
  //         })
  //     );
  //   else if (asset === "All" && recommendation === "All")
  //     setProductsData(
  //       data
  //         .filter(
  //           (data) =>
  //             data.product_type === product &&
  //             data.category === category &&
  //             data.name &&
  //             data.name.toLowerCase().includes(searchProduct.toLowerCase())
  //         )
  //         .map((product) => {
  //           return {
  //             ...product,
  //             commissions: (product.commissions * 100).toFixed(1),
  //           };
  //         })
  //     );
  //   else if (product === "All" && category === "All")
  //     setProductsData(
  //       data
  //         .filter(
  //           (data) =>
  //             data.recommendation === recommendation &&
  //             data.asset_type === asset &&
  //             data.name &&
  //             data.name.toLowerCase().includes(searchProduct.toLowerCase())
  //         )
  //         .map((product) => {
  //           return {
  //             ...product,
  //             commissions: (product.commissions * 100).toFixed(1),
  //           };
  //         })
  //     );
  //   else if (product === "All" && recommendation === "All")
  //     setProductsData(
  //       data
  //         .filter(
  //           (data) =>
  //             data.category === category &&
  //             data.asset_type === asset &&
  //             data.name &&
  //             data.name.toLowerCase().includes(searchProduct.toLowerCase())
  //         )
  //         .map((product) => {
  //           return {
  //             ...product,
  //             commissions: (product.commissions * 100).toFixed(1),
  //           };
  //         })
  //     );
  //   else if (recommendation === "All" && category === "All")
  //     setProductsData(
  //       data
  //         .filter(
  //           (data) =>
  //             data.product_type === product &&
  //             data.asset_type === asset &&
  //             data.name &&
  //             data.name.toLowerCase().includes(searchProduct.toLowerCase())
  //         )
  //         .map((product) => {
  //           return {
  //             ...product,
  //             commissions: (product.commissions * 100).toFixed(1),
  //           };
  //         })
  //     );
  //   else if (recommendation === "All")
  //     setProductsData(
  //       data
  //         .filter(
  //           (data) =>
  //             data.product_type === product &&
  //             data.asset_type === asset &&
  //             data.category === category &&
  //             data.name &&
  //             data.name.toLowerCase().includes(searchProduct.toLowerCase())
  //         )
  //         .map((product) => {
  //           return {
  //             ...product,
  //             commissions: (product.commissions * 100).toFixed(1),
  //           };
  //         })
  //     );
  //   else if (category === "All")
  //     setProductsData(
  //       data
  //         .filter(
  //           (data) =>
  //             data.product_type === product &&
  //             data.asset_type === asset &&
  //             data.recommendation === recommendation &&
  //             data.name &&
  //             data.name.toLowerCase().includes(searchProduct.toLowerCase())
  //         )
  //         .map((product) => {
  //           return {
  //             ...product,
  //             commissions: (product.commissions * 100).toFixed(1),
  //           };
  //         })
  //     );
  //   else if (product === "All")
  //     setProductsData(
  //       data
  //         .filter(
  //           (data) =>
  //             data.category === category &&
  //             data.asset_type === asset &&
  //             data.recommendation === recommendation &&
  //             data.name &&
  //             data.name.toLowerCase().includes(searchProduct.toLowerCase())
  //         )
  //         .map((product) => {
  //           return {
  //             ...product,
  //             commissions: (product.commissions * 100).toFixed(1),
  //           };
  //         })
  //     );
  //   else if (asset === "All")
  //     setProductsData(
  //       data
  //         .filter(
  //           (data) =>
  //             data.category === category &&
  //             data.product_type === product &&
  //             data.recommendation === recommendation &&
  //             data.name &&
  //             data.name.toLowerCase().includes(searchProduct.toLowerCase())
  //         )
  //         .map((product) => {
  //           return {
  //             ...product,
  //             commissions: (product.commissions * 100).toFixed(1),
  //           };
  //         })
  //     );
  //   else
  //     setProductsData(
  //       data
  //         .filter(
  //           (data) =>
  //             data.asset_type === asset &&
  //             data.product_type === product &&
  //             data.category === category &&
  //             data.recommendation === recommendation &&
  //             data.name &&
  //             data.name.toLowerCase().includes(searchProduct.toLowerCase())
  //         )
  //         .map((product) => {
  //           return {
  //             ...product,
  //             commissions: (product.commissions * 100).toFixed(1),
  //           };
  //         })
  //     );
  //   // eslint-disable-next-line
  // }, [asset, product, category, recommendation, searchProduct]);
  if (!collateralModel) return null;
  return (
    <div className="modal-component" style={{ zIndex: 100 }}>
      <div className="productunderstading-scrollablediv w-[1050px] max-w-[1250px] h-auto bg-[#fff] rounded-[10px] py-8 px-[1.75rem] overflow-y-auto border border-primaryBlackborder shadow-primaryShadow">
        <div className="flex justify-between">
          <h4 className=" font-inter text-[16px] font-medium leading-[20px] tracking-[0em] text-left mb-2">
            Search for funds here
          </h4>

          <button
            onClick={() => setCollateralModel(false)}
            className="h-[12px] text-[#000]"
            type="button"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="mt-4 flex justify-between">
          <div className="w-[12%]">
            <Dropdown
              options={["All", ...assetSet]}
              selected={asset}
              setSelected={setAsset}
              labelName="Asset Class"
            />
          </div>
          <div className="w-[12%]">
            <Dropdown
              options={["All", ...productSet]}
              selected={product}
              setSelected={setProduct}
              labelName="Product Type"
            />
          </div>
          <div className="w-[12%]">
            <Dropdown
              options={["All", ...categorySet]}
              selected={category}
              setSelected={setCategory}
              labelName="Category"
            />
          </div>
          <div className="w-[12%]">
            <Dropdown
              options={["All", ...Recommendation]}
              selected={recommendation}
              setSelected={setRecommendation}
              labelName="Recommendation"
            />
          </div>
          <div className="w-[48%]">
            <TextField
              fullWidth
              type="text"
              sx={{
                fontFamily: "Inter,sans-sarif",
                fontWeight: 400,
              }}
              label="Search Products"
              value={searchProduct}
              onChange={(e) => setSearchProduct(e.target.value)}
            />
          </div>
        </div>

        {searchProduct.length > 0 && (
          <>
            <div className="flex justify-between w-full">
              <div className=" flex w-[40%] flex-row-reverse"></div>
            </div>
            <div
              style={{ marginTop: "2rem" }}
              className="rounded-[6px] border border-primaryBlackborder shadow-primaryShadow h-[335px]"
            >
              {data.length === 0 ? (
                <div className=" w-full h-[335px] flex justify-center items-center opacity-[0.9]">
                  <Bars color="#0122af" />
                </div>
              ) : (
                <table
                  style={{ width: "100%" }}
                  className="client-dashboard-table"
                >
                  <col width="26%" />
                  <col width="14.5%" />
                  <col width="14.5%" />
                  <col width="14.5%" />
                  <col width="14.5%" />
                  <col width="22%" />
                  <tbody>
                    <tr className="h-[40px] rounded-[10px] border-b border-[#323232] font-inter font-bold">
                      <th className="pl-[1rem] text-left">Product Name</th>
                      <th className="text-center">Asset Class</th>
                      <th className="text-center">Product Type</th>
                      <th className="text-center">Product Category</th>
                      <th className="text-center">Commisions(bps)</th>
                      <th className="text-center">Select Recommendation</th>
                    </tr>
                    {displaydata}
                  </tbody>
                </table>
              )}
            </div>
            <ReactPaginate
              nextLabel={
                <img src={NextArrow} alt="err" className="w-[11px] h-[11px]" />
              }
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"dashboard-pagination-buttons"}
              previousLinkClassName={"dashboard-pagination-previous"}
              nextLinkClassName={"dashboard-pagination-next"}
              disabledClassName={"dashboard-pagination-disabled"}
              activeClassName={"dashboard-pagination-active"}
            />
          </>
        )}
      </div>
    </div>
  );
}
