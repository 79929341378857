import auth from "../auth";
import api from "../api";
import {
  LOGIN_USER,
  INVALID_CRED,
  GET_ERRORS,
  USER_DETAILS,
  FORGET_PASSWORD,
  INVALID_EMAIL,
  INVALID_OTP,
  GET_MARKET_EVENTS,
  GET_MARKET_FAQS,
  GET_MARKET_SECTOR,
  GET_SAVED_MARKET_FAQS,
  POST_SAVED_MARKET_FAQS,
  GET_MARKET_EXPERTS,
  GET_MARKET_EXPERTS_CONSOLIDATED,
  GET_MARKET_CONVERSATION,
  GET_RESEARCH,
  GET_FUNDHOUSE_RESEARCH,
  GET_IPS_DATA,
  GET_SIP_IPS_DATA,
  POST_RESEARCH,
  POST_RESEARCH_ASSETS,
  GET_PRODUCT_INPUT,
  RESET_PRODUCT_INPUT,
  POST_PRODUCT_INPUT,
  POST_SETTINGS,
  GET_ORG,
  GET_ORG_MEMBER,
  UPDATE_CLIENT_DATA,
  SUBMIT_CONTACT,
  GET_ANNUAL_TARGET,
  GET_AUM_BREAKUP,
  GET_AUM_BREAKUP_PRODUCT,
  GET_AUM_BREAKUP_ASSET,
  GET_AUM_BREAKUP_CLIENT,
  GET_POPULAR_PRODUCTS,
  GET_SETTINGS_ABOUT,
  GET_SETTINGS_GENERAL,
  POST_SETTINGS_GENERAL,
  GET_SETTINGS_MODULE,
  GET_SETTINGS_RMINFO,
  GET_SETTINGS_OPERATIONS,
  GET_SETTINGS_TEAMLEAD,
  PATCH_SETTINGS_TEAMLEAD,
  GET_ALLOCATION_PREFERENCE,
  GET_ANALYTICS_API,
  GET_PPT_API,
  // LOAD_PPT_API,
  GET_SETTINGS_RMS_ARRAY,
  GET_SETTINGS_OPERATIONS_ARRAY,
  GET_CLIENTS,
  POST_CLIENT_INPUT_NEW,
  GET_COMPARISON_DATA,
  GET_DASHBOARD_ALL_CLIENT,
  GET_DASHBOARD_YOUR_CLIENT,
  POST_DASHBOARD_PORTFOLIO,
  POST_DASHBOARD_ANALYTICS,
  POST_DASHBOARD_INSIGHTS,
  POST_DASHBOARD_PERFORMANCE,
  POST_DASHBOARD_INVESTMENT,
  POST_DASHBOARD_DETAILS,
  GET_DASHBOARD_DETAILS,
  GET_DASHBOARD_INVESTMENT,
  POST_DASHBOARD_REDEEM_CAPITAL,
  POST_DASHBOARD_DEPLOY_CAPITAL,
  POST_DASHBOARD_SUITABLE_PRODUCTS,
  GET_PRODUCTONEPAGER_DATA,
  GET_REVIEW_PORTFOLIO_SUMMARY,
  PATCH_REVIEW_PORTFOLIO_SUMMARY,
  GET_REVIEW_PORTFOLIO_ANALYTICS,
  GET_REVIEW_PORTFOLIO_IAA,
  GET_REVIEW_PORTFOLIO_IPS_TRACKER,
  GET_REVIEW_PORTFOLIO_ACTIONABLE,
  POST_REVIEW_PORTFOLIO_ACTIONABLE,
  GET_REVIEW_PORTFOLIO_PPT,
  GET_REVIEW_PORTFOLIO_ANALYTICS_FINAL,
  GET_REVIEW_PORTFOLIO_IPS_DATA,
  POST_CLIENT_INPUT_UPLOAD_FILE,
  GET_CLIENT_INPUT_TEMPLATE,
  UPDATE_SNACKBAR_PROPS,
  POST_EVENT_LOGGING_DATA,
  POST_DASHBOARD_PORTFOLIO_SUMMARY,
  POST_CLIENT_INPUT_PROFILE_PICTURE,
  UPDATE_INVESTMENT_POLICY_MODEL_DATA,
  UPDATE_PRODUCT_UNDERSTANDING_DATA,
  UPDATE_RESEARCH_INVESTMENT_POLICY_MODEL_DATA,
  GET_DASHBOARD_MF_PORTFOLIO,
  POST_DASHBOARD_HOLDING_REPORT,
  GET_TRENDING_MARKET_FAQS,
  GET_DASHBOARD_STOCKS_PORTFOLIO,
  GET_DASHBOARD_AIF_PORTFOLIO,
  GET_DASHBOARD_BONDS_PORTFOLIO,
  GET_DASHBOARD_UNLISTED_PORTFOLIO,
  GET_DASHBOARD_DEPOSITS_PORTFOLIO,
  GET_DASHBOARD_CASH_PORTFOLIO,
  GET_DASHBOARD_GOLD_PORTFOLIO,
  GET_DASHBOARD_REAL_ESTATE_PORTFOLIO,
  GET_DASHBOARD_PMS_PORTFOLIO,
  GET_BIRTHDAY_THIS_MONTH,
  GET_PROPOSAL_PREVIOUS_HISTORY,
  GET_REVIEW_PREVIOUS_HISTORY,
  GET_DASHBOARD_OFFSHORE_PORTFOLIO,
  GET_DASHBOARD_BENCHMARK_PRICE,
  GET_DASHBOARD_PORTFOLIO_LIST,
  GET_SIP_ANALYTICS_API,
  GET_SIP_ALLOCATION_PREFERENCE,
  GET_SIP_PPT_API,
  GET_MF_CENTRAL_OTP,
  GET_MF_CENTRAL_PAYLOAD,
  POST_MODEL_PORTFOLIO_BOOKMARK,
  GET_MODEL_PORTFOLIO,
  POST_MODEL_PORTFOLIO,
  PATCH_MODEL_PORTFOLIO,
  DELETE_MODEL_PORTFOLIO,
  GET_MODEL_PORTFOLIO_PRODUCTS,
  POST_MODEL_PORTFOLIO_PRODUCTS,
  PATCH_MODEL_PORTFOLIO_PRODUCTS,
  DELETE_MODEL_PORTFOLIO_PRODUCTS,
  GET_MODEL_PORTFOLIO_ANALYTICS,
  GET_SIP_PREVIOUS_HISTORY,
  GET_GOALS_LIST,
  GET_GOAL_DETAILS,
  GET_PROPOSAL_ADVANCE_ANALYTICS,
  GET_SIP_ADVANCE_ANALYTICS,
  GET_REVIEW_ADVANCE_ANALYTICS,
  GET_SIP_MODEL_PORTFOLIO_PRODUCTS,
  GET_ACQUIRE_CLIENTS_ANALYTICS,
  GET_ACQUIRE_CLIENTS_PPT,
  GET_ACQUIRE_CLIENTS_DASHBOARD,
  GET_RMS,
  THIRD_PARTY_LOGIN,
} from "./type";
export const LoginUser = (user, appState, setAppState) => async (dispatch) => {
  try {
    const { data } = await api.login(user);
    dispatch({
      type: LOGIN_USER,
      payload: data,
    });
    localStorage.setItem("accessToken", data.access);
    localStorage.setItem("refreshToken", data.refresh);
  } catch (error) {
    setAppState({ ...appState, loading: false });
    dispatch({
      type: INVALID_CRED,
      payload: "Invalid credentials",
    });
  }
};
export const ThirdPartyLogin = (user) => async (dispatch) => {
  try {
    dispatch({
      type: THIRD_PARTY_LOGIN,
      payload: { loading: true },
    });
    const { data } = await api.thirdPartyLogin(user);
    dispatch({
      type: THIRD_PARTY_LOGIN,
      payload: data,
    });
    localStorage.setItem("accessToken", data.access_token);
    localStorage.setItem("refreshToken", data.refresh_token);
  } catch (error) {
    dispatch({
      type: INVALID_CRED,
      payload: "Invalid credentials",
    });
  }
};
export const UserDetails = (history) => async (dispatch) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    const { data } = await api.userDetailsToken(config);
    dispatch({
      type: USER_DETAILS,
      payload: data,
    });

    auth.login(() => {
      history.push("/investgpt/markets");
      /*if (data.crm) history.push("/dashboard");
      else if (data.research) history.push("/marketdashboard");
      else if (data.product) history.push("/dashboard");
      else if (data.port_analytics) history.push("/portfolioproposal");
      else if (data.product_input) history.push("/productinput");
      else if (data.research_input) history.push("/researchinput");
      else if (data.client_input) history.push("/clientinput");*/
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const SettingsUserDetails = () => async (dispatch) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    const { data } = await api.userDetailsToken(config);
    dispatch({
      type: USER_DETAILS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetOtp = (email, history) => async (dispatch) => {
  try {
    const { data } = await api.forgetPassword({ email: email });
    dispatch({
      type: FORGET_PASSWORD,
      payload: data.message,
    });
    history.push({
      pathname: "/confirmotp",
      state: { email: email },
    });
  } catch (error) {
    dispatch({
      type: INVALID_EMAIL,
      payload: error.response.data,
    });
  }
};
export const SetPassword = (body, history) => async (dispatch) => {
  try {
    await api.forgetPasswordConfirm(body);
    dispatch({
      type: INVALID_CRED,
      payload: "Password updated successfully !",
    });
    history.push({
      pathname: "/login",
    });
  } catch (error) {
    if (body.password1 !== body.password2)
      dispatch({
        type: INVALID_OTP,
        payload: "Passwords didn't match",
      });
    else
      dispatch({
        type: INVALID_OTP,
        payload: "Incorrect OTP",
      });
  }
};
export const EmptySetPassword = () => async (dispatch) => {
  dispatch({
    type: INVALID_OTP,
    payload: null,
  });
};
export const EmptyInvalidEmail = () => async (dispatch) => {
  dispatch({
    type: INVALID_EMAIL,
    payload: null,
  });
};
export const EmptyInvalidCredentials = () => async (dispatch) => {
  dispatch({
    type: INVALID_CRED,
    payload: null,
  });
};
export const MarketEvents = () => async (dispatch) => {
  try {
    const { data } = await api.marketEvents();
    dispatch({
      type: GET_MARKET_EVENTS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const EmptyUserDetails = () => async (dispatch) => {
  dispatch({
    type: USER_DETAILS,
    payload: {},
  });
};
export const MarketFAQS = (body) => async (dispatch) => {
  try {
    const { data } = await api.marketFAQ(body);
    dispatch({
      type: GET_MARKET_FAQS,
      payload: ["loading"],
    });
    dispatch({
      type: GET_MARKET_FAQS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const MarketSectors = () => async (dispatch) => {
  try {
    const { data } = await api.marketSector();
    dispatch({
      type: GET_MARKET_SECTOR,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetSavedFAQS = () => async (dispatch) => {
  try {
    const { data } = await api.getsavedFAQ();
    dispatch({
      type: GET_SAVED_MARKET_FAQS,
      payload: data,
    });
  } catch (error) {
    console.log(
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message
    );
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const PostSavedFAQS = (body) => async (dispatch) => {
  try {
    const { data } = await api.postsavedFAQ(body);
    dispatch({
      type: POST_SAVED_MARKET_FAQS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetTrendingFAQS = () => async (dispatch) => {
  try {
    const { data } = await api.trendingFaq();
    dispatch({
      type: GET_TRENDING_MARKET_FAQS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const MarketExperts = () => async (dispatch) => {
  try {
    const { data } = await api.marketExperts();

    dispatch({
      type: GET_MARKET_EXPERTS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const MarketExpertsConsolidated = () => async (dispatch) => {
  try {
    const { data } = await api.marketExpertsConsolidated();

    dispatch({
      type: GET_MARKET_EXPERTS_CONSOLIDATED,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const MarketConversation = () => async (dispatch) => {
  try {
    const { data } = await api.marketConversation();

    dispatch({
      type: GET_MARKET_CONVERSATION,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetResearch = () => async (dispatch) => {
  try {
    const { data } = await api.getResearch();
    dispatch({
      type: GET_RESEARCH,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetFundhouseResearch = () => async (dispatch) => {
  try {
    const { data } = await api.getFundhouseResearch();
    dispatch({
      type: GET_FUNDHOUSE_RESEARCH,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const UpdateResearchInvestmentPolicyData =
  (body) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_RESEARCH_INVESTMENT_POLICY_MODEL_DATA,
        payload: body,
      });
    } catch (error) {
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    }
  };
export const GetIpsData = (body) => async (dispatch) => {
  try {
    const { data } = await api.getIps(body);
    dispatch({
      type: GET_IPS_DATA,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetSipIpsData = (body) => async (dispatch) => {
  try {
    const { data } = await api.getIps(body);
    dispatch({
      type: GET_SIP_IPS_DATA,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const EditIpsData = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_IPS_DATA,
      payload: body,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const EditSipIpsData = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SIP_IPS_DATA,
      payload: body,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const PostResearch = (body) => async (dispatch) => {
  try {
    const { data } = await api.postResearch(body);

    dispatch({
      type: POST_RESEARCH,
      payload: data,
    });
    dispatch({
      type: UPDATE_SNACKBAR_PROPS,
      payload: {
        open: true,
        severity: "success",
        message: "Research views updated ",
      },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const PostResearchAssets = (body) => async (dispatch) => {
  try {
    dispatch({
      type: POST_RESEARCH_ASSETS,
      payload: {
        gen_comment_on_profile: "loading",
        data_summary: [
          { field: "", opt_allocation: 0, target: 0, rationale: "" },
          { field: "", opt_allocation: 0, target: 0, rationale: "" },
          { field: "", opt_allocation: 0, target: 0, rationale: "" },
          { field: "", opt_allocation: 0, target: 0, rationale: "" },
          { field: "", opt_allocation: 0, target: 0, rationale: "" },
          { field: "", opt_allocation: 0, target: 0, rationale: "" },
          { field: "", opt_allocation: 0, target: 0, rationale: "" },
          { field: "", opt_allocation: 0, target: 0, rationale: "" },
          { field: "", opt_allocation: 0, target: 0, rationale: "" },
        ],
      },
    });
    const { data } = await api.postResearchAssets(body);
    dispatch({
      type: POST_RESEARCH_ASSETS,
      payload: data,
    });
    dispatch({
      type: UPDATE_SNACKBAR_PROPS,
      payload: {
        open: true,
        severity: "success",
        message: "Asset allocation simulated successfully",
      },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetProductInput = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PRODUCT_INPUT,
      payload: { count: 0, results: [], loading: true },
    });
    const { data } = await api.getProductInput(body);
    dispatch({
      type: GET_PRODUCT_INPUT,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const ResetProductInput = () => async (dispatch) => {
  try {
    const { data } = await api.getProductInput();
    dispatch({
      type: RESET_PRODUCT_INPUT,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const PostProductInput = (body) => async (dispatch) => {
  try {
    const { data } = await api.postProductInput(body);
    dispatch({
      type: POST_PRODUCT_INPUT,
      payload: data,
    });
    dispatch({
      type: UPDATE_SNACKBAR_PROPS,
      payload: {
        open: true,
        severity: "success",
        message: "Data updated succesfully",
      },
    });
    dispatch({
      type: GET_PRODUCT_INPUT,
      payload: { count: 0, results: [], loading: true },
    });
    const res = await api.getProductInput({
      params: {
        isin: "",
        category: "",
        product_type: "",
        asset_type: "",
        search: "",
        page: 1,
        size: 5,
      },
    });
    dispatch({
      type: GET_PRODUCT_INPUT,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const PostUserSettings = () => async (dispatch) => {
  try {
    const { data } = await api.postUserSettings();
    dispatch({
      type: POST_SETTINGS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetOrg = () => async (dispatch) => {
  try {
    const { data } = await api.organizationDetails();
    dispatch({
      type: GET_ORG,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetOrgMember = () => async (dispatch) => {
  try {
    const { data } = await api.organizationMembers();
    dispatch({
      type: GET_ORG_MEMBER,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const UpdateClientData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_CLIENT_DATA,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const SubmitContact = (body) => async (dispatch) => {
  try {
    const { data } = await api.contact(body);

    dispatch({
      type: SUBMIT_CONTACT,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
// AUM Actions
export const GetAnnualTarget = () => async (dispatch) => {
  try {
    const { data } = await api.annualTarget();

    dispatch({
      type: GET_ANNUAL_TARGET,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetAumBreakup = () => async (dispatch) => {
  try {
    const { data } = await api.aumBreakup();

    dispatch({
      type: GET_AUM_BREAKUP,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetAumBreakupProduct = () => async (dispatch) => {
  try {
    const { data } = await api.aumBreakupProduct();

    dispatch({
      type: GET_AUM_BREAKUP_PRODUCT,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetAumBreakupAsset = () => async (dispatch) => {
  try {
    const { data } = await api.aumBreakupAsset();

    dispatch({
      type: GET_AUM_BREAKUP_ASSET,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetAumBreakupClient = () => async (dispatch) => {
  try {
    const { data } = await api.aumBreakupClient();

    dispatch({
      type: GET_AUM_BREAKUP_CLIENT,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetBirthdayThisMonth = () => async (dispatch) => {
  try {
    const { data } = await api.birthdayThisMonth();

    dispatch({
      type: GET_BIRTHDAY_THIS_MONTH,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetPopularProducts = () => async (dispatch) => {
  try {
    const { data } = await api.popularProducts();
    dispatch({
      type: GET_POPULAR_PRODUCTS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetDashboardAllClients = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_DASHBOARD_ALL_CLIENT,
      payload: ["loading"],
    });
    const { data } = await api.dashboardAllClients();

    dispatch({
      type: GET_DASHBOARD_ALL_CLIENT,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetDashboardYourClients = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_DASHBOARD_YOUR_CLIENT,
      payload: ["loading"],
    });

    const { data } = await api.dashboardYourClients();

    dispatch({
      type: GET_DASHBOARD_YOUR_CLIENT,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};

// Settings
export const GetSettingsAbout = () => async (dispatch) => {
  try {
    const { data } = await api.settingsAbout();

    dispatch({
      type: GET_SETTINGS_ABOUT,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetSettingsGeneral = () => async (dispatch) => {
  try {
    const { data } = await api.settingsGeneral();

    dispatch({
      type: GET_SETTINGS_GENERAL,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const PostSettingsGeneral = (body) => async (dispatch) => {
  try {
    const { data } = await api.postSettingsGeneral(body);

    dispatch({
      type: POST_SETTINGS_GENERAL,
      payload: data,
    });
    dispatch({
      type: UPDATE_SNACKBAR_PROPS,
      payload: {
        open: true,
        severity: "success",
        message: "Settings Updated Successfully",
      },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const PostImageGeneral = (body) => async (dispatch) => {
  try {
    let fileData = new FormData();
    fileData.append("profile_pic", body.file);
    fileData.append("contact", body.contact);
    fileData.append("dob", body.dob);
    const { data } = await api.patchSettingsGeneral(fileData);

    dispatch({
      type: POST_SETTINGS_GENERAL,
      payload: data,
    });
    dispatch({
      type: UPDATE_SNACKBAR_PROPS,
      payload: {
        open: true,
        severity: "success",
        message: "Profile picture Updated Successfully",
      },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetSettingsModule = () => async (dispatch) => {
  try {
    const { data } = await api.settingsModule();

    dispatch({
      type: GET_SETTINGS_MODULE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetAllocationPreference = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ANALYTICS_API,
      payload: {
        asset_allocation: {
          Equity: 0,
          Debt: 0,
          Alternate: 0,
        },
        product_allocation: {
          "Mutual Fund": 1.0,
        },
        port_return: 0,
        bm_blended_return: 0,
        total_products: 0,
        liquidity: 0,
        market_cap: {
          "Large Cap": [0, 0],
          "Mid Cap": [0, 0],
          "Small Cap": [0, 0],
        },
        top5_sector: {
          Financials: [0, 0],
          "Consumer Discretionary": [0, 0],
          "Health Care": [0, 0],
          Materials: [0, 0],
          "Information Technology": [0, 0],
        },
        top5_stocks: {
          "ICICI Bank Ltd.": 0,
          "HDFC Bank Ltd.": 0,
          "Axis Bank Ltd.": 0,
          "ITC Ltd.": 0,
          "State Bank Of India": 0,
        },
        debt_quants: {
          duration: 0,
          gross_ytm: 0,
          maturity: 0,
        },
        rating_allocation: {
          A: 0,
          AA: 0,
          AAA: 0,
          B: 0.0,
          BB: 0.0,
          BBB: 0.0,
          C: 0.0,
          Cash: 0.0,
          D: 0.0,
          SOV: 0.0,
          Unrated: 0.0,
        },
        top5_debt_instruments: {
          "GOI FRB 22-Sep-2033": 0.0,
          "Mangalore Refinery & Petrochemicals Ltd. -SR-4 07.48% (14-Apr-32)": 0.0,
          "NTPC Ltd. SR-76 06.74% (14-Apr-32)": 0.0,
          "National Highways Authority of India - SR-III 06.87% (14-Apr-32)": 0.0,
          "Power Finance Corporation Ltd. SR-214 6.92% (14-Apr-32)": 0.0,
        },
        loading: true,
      },
    });
    const { data } = await api.allocationPreference(body);

    dispatch({
      type: GET_ALLOCATION_PREFERENCE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const ResetAllocationPreference = () => async (dispatch) => {
  dispatch({
    type: GET_ALLOCATION_PREFERENCE,
    payload: {
      "Mutual Fund": [],
    },
  });
};
export const GetAnalyticsAPI = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ANALYTICS_API,
      payload: {
        asset_allocation: {
          Equity: 0,
          Debt: 0,
          Alternate: 0,
        },
        product_allocation: {
          "Mutual Fund": 1.0,
        },
        port_return: 0,
        bm_blended_return: 0,
        total_products: 0,
        liquidity: 0,
        market_cap: {
          "Large Cap": [0, 0],
          "Mid Cap": [0, 0],
          "Small Cap": [0, 0],
        },
        top5_sector: {
          Financials: [0, 0],
          "Consumer Discretionary": [0, 0],
          "Health Care": [0, 0],
          Materials: [0, 0],
          "Information Technology": [0, 0],
        },
        top5_stocks: {
          "ICICI Bank Ltd.": 0,
          "HDFC Bank Ltd.": 0,
          "Axis Bank Ltd.": 0,
          "ITC Ltd.": 0,
          "State Bank Of India": 0,
        },
        debt_quants: {
          duration: 0,
          gross_ytm: 0,
          maturity: 0,
        },
        rating_allocation: {
          A: 0,
          AA: 0,
          AAA: 0,
          B: 0.0,
          BB: 0.0,
          BBB: 0.0,
          C: 0.0,
          Cash: 0.0,
          D: 0.0,
          SOV: 0.0,
          Unrated: 0.0,
        },
        top5_debt_instruments: {
          "GOI FRB 22-Sep-2033": 0.0,
          "Mangalore Refinery & Petrochemicals Ltd. -SR-4 07.48% (14-Apr-32)": 0.0,
          "NTPC Ltd. SR-76 06.74% (14-Apr-32)": 0.0,
          "National Highways Authority of India - SR-III 06.87% (14-Apr-32)": 0.0,
          "Power Finance Corporation Ltd. SR-214 6.92% (14-Apr-32)": 0.0,
        },
        loading: true,
      },
    });
    const { data } = await api.analyticsAPI(body);

    dispatch({
      type: GET_ANALYTICS_API,
      payload: { ...data, loading: false },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetPPTAPI = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PPT_API,
      payload: { pdf: "", excel: "", loading: true },
    });
    const { data } = await api.pptAPI(body);

    dispatch({
      type: GET_PPT_API,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetProposalHistory = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PROPOSAL_PREVIOUS_HISTORY,
      payload: { loading: true, results: [] },
    });
    const { data } = await api.proposalHistory(body);
    dispatch({
      type: GET_PROPOSAL_PREVIOUS_HISTORY,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetProposalAdvanceAnalyticsAPI = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PROPOSAL_ADVANCE_ANALYTICS,
      payload: {
        equity_cagr: 0.28102675271601807,
        equity_bm_cagr: 0.25090799331665037,
        equity_category_allocation: {
          "Flexi Cap": 0.27888323339713994,
          ETF: 0.16393442622950816,
          "Multi Cap": 0.16393442622950813,
          "Pharma & Health Care": 0.1639344262295081,
          "FoFs (Overseas)": 0.08606557377049183,
          Others: 0.14324791414384408,
        },
        equity_product_allocation: {
          "Mutual Fund": 1.0,
        },
        full_sector_allocation: {
          "Health Care": 0.2562940036573252,
          Financials: 0.25120566390713417,
          Industrials: 0.10050624843167878,
          Materials: 0.09586499609101654,
          "Consumer Discretionary": 0.08753629413579035,
          "Consumer Staples": 0.07040976166230023,
          Energy: 0.044958145994652234,
          "Information Technology": 0.03888923436996799,
          Utilities: 0.02949640509397072,
          "Communication Services": 0.020238217142890966,
          "Real Estate": 0.004601029513272696,
        },
        geographical_allocation: {
          "Domestic Equities": 0.7806262312512386,
          "Overseas Equities": 0.21937376874876138,
        },
        equity_products_count: 8,
        debt_cagr: 0.06539327561929503,
        debt_bm_cagr: 0.05938215039540151,
        debt_category_allocation: {
          "Corporate Bond": 0.5698005698005701,
          "Short Duration": 0.1452991452991449,
          ETF: 0.07186783257141309,
          "Debt Oriented": 0.21303245232887186,
        },
        debt_product_allocation: {
          "Mutual Fund": 1.0,
        },
        debt_products_count: 8,
        alternate_cagr: 0.09,
        alternate_bm_cagr: 0.05370800018310547,
        alternate_category_allocation: {
          ETF: 1.0,
        },
        alternate_product_allocation: {
          "Mutual Fund": 1.0,
        },
        alternate_products_count: 1,
        loading: true,
      },
    });
    const { data } = await api.proposalAdvanceAnalytics(body);

    dispatch({
      type: GET_PROPOSAL_ADVANCE_ANALYTICS,
      payload: { ...data, loading: false },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
// Settings
export const GetRminfo = () => async (dispatch) => {
  try {
    const { data } = await api.settingsRminfo();
    dispatch({
      type: GET_SETTINGS_RMINFO,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetOperations = () => async (dispatch) => {
  try {
    const { data } = await api.settingsOperations();
    dispatch({
      type: GET_SETTINGS_OPERATIONS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetTeamLead = () => async (dispatch) => {
  try {
    const { data } = await api.settingsgetTeamLead();
    dispatch({
      type: GET_SETTINGS_TEAMLEAD,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const PatchTeamLead = (body) => async (dispatch) => {
  try {
    const { data } = await api.settingspatchTeamLead(body);
    dispatch({
      type: PATCH_SETTINGS_TEAMLEAD,
      payload: data,
    });
    dispatch({
      type: UPDATE_SNACKBAR_PROPS,
      payload: {
        open: true,
        severity: "success",
        message: "Settings Updated Successfully",
      },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetRmArray = () => async (dispatch) => {
  try {
    const { data } = await api.settingsgetRms();
    dispatch({
      type: GET_SETTINGS_RMS_ARRAY,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetOperationsArray = () => async (dispatch) => {
  try {
    const { data } = await api.settingsgetOperationsArray();

    dispatch({
      type: GET_SETTINGS_OPERATIONS_ARRAY,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

// CLIENT INPUT ACTIONS

export const GetClients = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CLIENTS,
      payload: { loading: true, results: [] },
    });
    const { data } = await api.getClients(body);
    dispatch({
      type: GET_CLIENTS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetRms = () => async (dispatch) => {
  try {
    const { data } = await api.getClientInputRms();
    dispatch({
      type: GET_RMS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const PostClientInputNew =
  (body, history, familyMembers) => async (dispatch) => {
    try {
      dispatch({
        type: POST_CLIENT_INPUT_NEW,
        payload: {
          client_id: null,
        },
      });
      const { data } = await api.clientInputPostNew(body);

      dispatch({
        type: POST_CLIENT_INPUT_NEW,
        payload: data,
      });
      dispatch({
        type: UPDATE_SNACKBAR_PROPS,
        payload: {
          open: true,
          severity: "success",
          message: "Client created successfully",
        },
      });
      history.push({
        pathname: "/clientinput/updateportfolios",
        state: {
          familyMembers: familyMembers,
          clientId: data.client_id[0],
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_SNACKBAR_PROPS,
        payload: {
          open: true,
          severity: "error",
          message: "Client creation failed",
        },
      });
      history.push("/clientinput");
      dispatch({
        type: GET_ERRORS,
        payload:
          error.response && error.response.data.detail //err.response.data
            ? error.response.data.detail
            : error.message,
      });
    }
  };
export const PostClientInputProfilePicture = (body) => async (dispatch) => {
  try {
    let fileData = new FormData();
    fileData.append("client_id", body.client_id);
    fileData.append("profile_pic", body.file);
    const { data } = await api.clientInputUploadProfilePicture(fileData);
    dispatch({
      type: POST_CLIENT_INPUT_PROFILE_PICTURE,
      payload: data,
    });
    dispatch({
      type: UPDATE_SNACKBAR_PROPS,
      payload: {
        open: true,
        severity: "success",
        message: "Profile picture updated successfully",
      },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetClientInputTemplate = () => async (dispatch) => {
  try {
    const { data } = await api.clientInputDownloadTemplate();
    dispatch({
      type: GET_CLIENT_INPUT_TEMPLATE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const PostClientInputUploadFile = (body) => async (dispatch) => {
  try {
    dispatch({
      type: POST_CLIENT_INPUT_UPLOAD_FILE,
      payload: "loading",
    });
    let fileData = new FormData();
    fileData.append("client_id", body.client_id);
    fileData.append("file", body.file);
    fileData.append("file_type", body.file_type);
    fileData.append("password", body.password);
    const { data } = await api.clientInputUploadFile(fileData);
    dispatch({
      type: POST_CLIENT_INPUT_UPLOAD_FILE,
      payload: data,
    });
    dispatch({
      type: UPDATE_SNACKBAR_PROPS,
      payload: {
        open: true,
        severity: "success",
        message: "Portfolio updated successfully",
      },
    });
  } catch (error) {
    dispatch({
      type: POST_CLIENT_INPUT_UPLOAD_FILE,
      payload: "failed",
    });
  }
};
export const getComparisonData = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COMPARISON_DATA,
      payload: ["loading"],
    });
    const { data } = await api.comparisonData(body);
    dispatch({
      type: GET_COMPARISON_DATA,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getProductOnepagerData =
  (body, history, searchFund) => async (dispatch) => {
    try {
      const { data } = await api.productonepager(body);
      dispatch({
        type: GET_PRODUCTONEPAGER_DATA,
        payload: data,
      });
      history.push({
        pathname: "/productonepager",
      });
    } catch (error) {
      if (searchFund.length) alert("Data for this Fund is not available");
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    }
  };
// event_logging

export const PostEventLoggingData = (body) => async (dispatch) => {
  try {
    const { data } = await api.eventLogging(body);
    dispatch({
      type: POST_EVENT_LOGGING_DATA,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INVALID_CRED,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
// Client Dashboard URLs
export const PostDashboardPortfolio = (body) => async (dispatch) => {
  try {
    const { data } = await api.dashboardPortfolio(body);
    dispatch({
      type: POST_DASHBOARD_PORTFOLIO,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: POST_DASHBOARD_PORTFOLIO,
      payload: {
        account_type: "I",
        investment_horizon: 0,
        user_type: false,
        composite_risk_score: 0,
        ips_deviation: "No Data",
        actualCorpus: {
          amount: 0,
          denomination: "L",
        },
        equity: {
          "No Data": 0.33,
        },
        debt: {
          "No Data": 0.33,
        },
        alternate: {
          "No Data": 0.34,
        },
        download: {
          holding_report: "url",
          client_summary: "url",
        },
      },
    });

    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
    error.response.status !== 401 &&
      dispatch({
        type: UPDATE_SNACKBAR_PROPS,
        payload: {
          open: true,
          severity: "error",
          message: "Something went wrong. Please try later",
        },
      });
  }
};
export const GetDashboardPortfolioSummary = (body) => async (dispatch) => {
  try {
    dispatch({
      type: POST_DASHBOARD_PORTFOLIO_SUMMARY,
      payload: { pdf: null },
    });
    const { data } = await api.dashboardPortfolioSummary(body);
    dispatch({
      type: POST_DASHBOARD_PORTFOLIO_SUMMARY,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetDashboardHoldingReport = (body) => async (dispatch) => {
  try {
    dispatch({
      type: POST_DASHBOARD_HOLDING_REPORT,
      payload: { excel: null },
    });
    const { data } = await api.dashboardHoldingReport(body);
    dispatch({
      type: POST_DASHBOARD_HOLDING_REPORT,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const PostDashboardInsights = (body) => async (dispatch) => {
  try {
    const { data } = await api.dashboardInsights(body);
    dispatch({
      type: POST_DASHBOARD_INSIGHTS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: POST_DASHBOARD_INSIGHTS,
      payload: {
        investment_policy: ["Please upload portfolio of your client."],
        product: ["Please upload portfolio of your client."],
        portfolio: ["Please upload portfolio of your client."],
        taxation: ["Please upload portfolio of your client."],
        cashflow_and_liquidity: ["Please upload portfolio of your client."],
      },
    });

    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const PostDashboardAnalytics = (body) => async (dispatch) => {
  try {
    const { data } = await api.dashboardAnalytics(body);
    dispatch({
      type: POST_DASHBOARD_ANALYTICS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: POST_DASHBOARD_ANALYTICS,
      payload: {
        asset_allocation: {
          alternate: 0,
          equity: 0,
          debt: 0,
        },
        product_allocation: {},
        liquidity: {
          Liquid: 1.0,
          "Semi-Liquid": 0,
          Illiquid: 0,
        },
        top_holding: [
          {
            label: "No Data",
            type: "Alternate",
            value: 0,
          },
        ],
        mcap_allocation: {
          "Large Cap": 0,
          "Mid Cap": 0,
          "Small Cap": 0,
        },
        sector_allocation: {
          Financials: 0,
          "Consumer Discretionary": 0,
          Materials: 0,
          "Information Technology": 0,
          "Health Care": 0,
          "Consumer Staples": 0,
          Industrials: 0,
          Utilities: 0,
          Energy: 0,
          "Real Estate": 0,
          "Communication Services": 0,
        },
        maturity_profile: {
          avg_maturity_yr: 0,
          gross_ytm: 0,
          mod_duration_yr: 0,
        },
        credit_quality: {
          A: 0,
          AA: 0,
          AAA: 0,
          B: 0.0,
          BB: 0.0,
          BBB: 0.0,
          C: 0.0,
          Cash: 0,
          D: 0.0,
          SOV: 0,
          Unrated: 0.0,
        },
      },
    });

    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const PostDashboardPerformance = (body) => async (dispatch) => {
  try {
    dispatch({
      type: POST_DASHBOARD_PERFORMANCE,
      payload: [],
    });
    const { data } = await api.dashboardPerformance(body);
    dispatch({
      type: POST_DASHBOARD_PERFORMANCE,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: POST_DASHBOARD_PERFORMANCE,
      payload: [],
    });
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetDashboardDetails = (body) => async (dispatch) => {
  try {
    const { data } = await api.dashboardGetDetails(body);
    dispatch({
      type: GET_DASHBOARD_DETAILS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const PostDashboardDetails = (body) => async (dispatch) => {
  try {
    const { data } = await api.dashboardPostDetails(body);
    dispatch({
      type: POST_DASHBOARD_DETAILS,
      payload: data,
    });
    dispatch({
      type: UPDATE_SNACKBAR_PROPS,
      payload: {
        open: true,
        severity: "success",
        message: "Client Details Updated Successfully",
      },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetDashboardInvestment = (body) => async (dispatch) => {
  try {
    const { data } = await api.dashboardGetInvestment(body);
    dispatch({
      type: GET_DASHBOARD_INVESTMENT,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const UpdateInvestmentPolicyData = (body) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_INVESTMENT_POLICY_MODEL_DATA,
      payload: body,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const UpdateProductUnderstandingData = (body) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PRODUCT_UNDERSTANDING_DATA,
      payload: body,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const PostDashboardInvestment = (body) => async (dispatch) => {
  try {
    const { data } = await api.dashboardPostInvestment(body);
    dispatch({
      type: POST_DASHBOARD_INVESTMENT,
      payload: data,
    });
    dispatch({
      type: UPDATE_SNACKBAR_PROPS,
      payload: {
        open: true,
        severity: "success",
        message: "Investment Profile Updated Successfully",
      },
    });
    const response = await api.dashboardGetInvestment({
      params: {
        client_id: localStorage.getItem("clientId"),
      },
    });
    dispatch({
      type: GET_DASHBOARD_INVESTMENT,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const PostDashboardRedeemCapital = (body) => async (dispatch) => {
  try {
    const { data } = await api.dashboardRedeemCapital(body);
    dispatch({
      type: POST_DASHBOARD_REDEEM_CAPITAL,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const EmptyDashboardRedeemCapital = () => async (dispatch) => {
  dispatch({
    type: POST_DASHBOARD_REDEEM_CAPITAL,
    payload: {},
  });
};
export const PostDashboardDeployCapital = (body) => async (dispatch) => {
  try {
    const { data } = await api.dashboardDeployCapital(body);
    dispatch({
      type: POST_DASHBOARD_DEPLOY_CAPITAL,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const EmptyDashboardDeployCapital = () => async (dispatch) => {
  dispatch({
    type: POST_DASHBOARD_DEPLOY_CAPITAL,
    payload: {},
  });
};
export const PostDashboardSuitableProducts = (body) => async (dispatch) => {
  try {
    const { data } = await api.dashboardSuitableProducts(body);
    dispatch({
      type: POST_DASHBOARD_SUITABLE_PRODUCTS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetMFCentralOtp = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_MF_CENTRAL_OTP,
      payload: { loading: true },
    });
    const { data } = await api.dashboardMFCentralOtp(body);
    dispatch({
      type: GET_MF_CENTRAL_OTP,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetMFCentralPayload = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_MF_CENTRAL_PAYLOAD,
      payload: { loading: true },
    });
    const { data } = await api.dashboardMFCentralPayload(body);
    dispatch({
      type: GET_MF_CENTRAL_PAYLOAD,
      payload: data,
    });
    dispatch({
      type: UPDATE_SNACKBAR_PROPS,
      payload: {
        open: true,
        severity: "success",
        message: "Portfolio updated successfully",
      },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetDashboardMfPortfolio = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DASHBOARD_MF_PORTFOLIO,
      payload: { transactions: [], portfolio: [], loading: true },
    });
    const { data } = await api.dashboardMFPortfolio(body);
    dispatch({
      type: GET_DASHBOARD_MF_PORTFOLIO,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetDashboardStocksPortfolio = (body) => async (dispatch) => {
  try {
    const { data } = await api.dashboardStocksPortfolio(body);
    dispatch({
      type: GET_DASHBOARD_STOCKS_PORTFOLIO,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetDashboardPmsPortfolio = (body) => async (dispatch) => {
  try {
    const { data } = await api.dashboardPmsPortfolio(body);
    dispatch({
      type: GET_DASHBOARD_PMS_PORTFOLIO,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetDashboardAifPortfolio = (body) => async (dispatch) => {
  try {
    const { data } = await api.dashboardAifPortfolio(body);
    dispatch({
      type: GET_DASHBOARD_AIF_PORTFOLIO,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetDashboardBondsPortfolio = (body) => async (dispatch) => {
  try {
    const { data } = await api.dashboardBondsPortfolio(body);
    dispatch({
      type: GET_DASHBOARD_BONDS_PORTFOLIO,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetDashboardUnlistedPortfolio = (body) => async (dispatch) => {
  try {
    const { data } = await api.dashboardUnlistedPortfolio(body);
    dispatch({
      type: GET_DASHBOARD_UNLISTED_PORTFOLIO,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetDashboardCashPortfolio = (body) => async (dispatch) => {
  try {
    const { data } = await api.dashboardCashPortfolio(body);
    dispatch({
      type: GET_DASHBOARD_CASH_PORTFOLIO,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetDashboardDepositsPortfolio = (body) => async (dispatch) => {
  try {
    const { data } = await api.dashboardDepositsPortfolio(body);
    dispatch({
      type: GET_DASHBOARD_DEPOSITS_PORTFOLIO,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetDashboardGoldPortfolio = (body) => async (dispatch) => {
  try {
    const { data } = await api.dashboardGoldPortfolio(body);
    dispatch({
      type: GET_DASHBOARD_GOLD_PORTFOLIO,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetDashboardRealEstatePortfolio = (body) => async (dispatch) => {
  try {
    const { data } = await api.dashboardRealEstatePortfolio(body);
    dispatch({
      type: GET_DASHBOARD_REAL_ESTATE_PORTFOLIO,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetDashboardOffshorePortfolio = (body) => async (dispatch) => {
  try {
    const { data } = await api.dashboardOffshorePortfolio(body);
    dispatch({
      type: GET_DASHBOARD_OFFSHORE_PORTFOLIO,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetDashboardBenchmarkPrice = (body) => async (dispatch) => {
  try {
    const { data } = await api.dashboardBenchmarkPrice(body);
    dispatch({
      type: GET_DASHBOARD_BENCHMARK_PRICE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetDashboardPortfolioList = (body) => async (dispatch) => {
  try {
    const { data } = await api.dashboardPortfolioList(body);
    dispatch({
      type: GET_DASHBOARD_PORTFOLIO_LIST,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
// Review Portfolio Actions

export const GetReviewPortfolioSummary =
  (body, history) => async (dispatch) => {
    try {
      const { data } = await api.reviewPortfolioGetSummary(body);
      dispatch({
        type: GET_REVIEW_PORTFOLIO_SUMMARY,
        payload: data,
      });
      history.push({
        pathname: "/portfolioproposal/reviewstepper",
        state: body.params.client_id,
      });
    } catch (error) {
      dispatch({
        type: GET_ERRORS,
        payload:
          error.response && error.response.data.detail //err.response.data
            ? error.response.data.detail
            : error.message,
      });
    }
  };
export const PatchReviewPortfolioSummary = (body) => async (dispatch) => {
  try {
    const { data } = await api.reviewPortfolioPatchSummary(body);
    dispatch({
      type: PATCH_REVIEW_PORTFOLIO_SUMMARY,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const GetReviewPortfolioAnalytics = (body) => async (dispatch) => {
  try {
    const { data } = await api.reviewPortfolioGetAnalytics(body);
    dispatch({
      type: GET_REVIEW_PORTFOLIO_ANALYTICS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetReviewPortfolioIpsData = (body) => async (dispatch) => {
  try {
    const { data } = await api.reviewPortfolioGetIpsData(body);
    dispatch({
      type: GET_REVIEW_PORTFOLIO_IPS_DATA,
      payload: data[0],
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetReviewPortfolioIaa = (body) => async (dispatch) => {
  dispatch({
    type: GET_REVIEW_PORTFOLIO_IAA,
    payload: [
      {
        field: "duration",
        opt_allocation: 3.2,
        target: 3.0,
        rationale: "Relative bullish view on Duration",
        loading: true,
      },
      {
        field: "credit_risk",
        opt_allocation: 0.79,
        target: 0.75,
        rationale: "Relative bullish view on Credit Risk",
      },
      {
        field: "alternates_low_vol",
        opt_allocation: 0.79,
        target: 0.75,
        rationale: "Relative bullish view on Low Volatility Alternates",
      },
      {
        field: "large_cap",
        opt_allocation: 0.5,
        target: 0.5,
        rationale: "Relative bearish view on Large Cap",
      },
      {
        field: "mid_cap",
        opt_allocation: 0.4,
        target: 0.4,
        rationale: "Relative bearish view on Mid Cap",
      },
      {
        field: "small_cap",
        opt_allocation: 0.1,
        target: 0.1,
        rationale: "Relative bearish view on Small Cap",
      },
      {
        field: "equity",
        opt_allocation: 0.4886877828054299,
        target: 0.5,
        rationale: "Relative bearish view on Equity",
      },
      {
        field: "debt",
        opt_allocation: 0.4434389140271493,
        target: 0.45,
        rationale: "Relative bearish view on Debt",
      },
      {
        field: "alternates",
        opt_allocation: 0.06787330316742082,
        target: 0.05,
        rationale: "Relative bullish view on Alternates",
      },
    ],
  });
  try {
    const { data } = await api.reviewPortfolioGetIAA(body);
    dispatch({
      type: GET_REVIEW_PORTFOLIO_IAA,
      payload: data.data_summary,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const GetReviewPortfolioIpsTracker = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REVIEW_PORTFOLIO_IPS_TRACKER,
      payload: [],
    });
    const { data } = await api.reviewPortfolioGetIpstracker(body);
    dispatch({
      type: GET_REVIEW_PORTFOLIO_IPS_TRACKER,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetReviewPortfolioActionable = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REVIEW_PORTFOLIO_ACTIONABLE,
      payload: {
        initial_portfolio: {
          "Mutual Fund": [],
        },
        final_portfolio: {
          "Mutual Fund": [],
        },
        actions: [],
        frontend_table: [],
        loading: true,
        portfolio_value: "5600000.00",
      },
    });
    const { data } = await api.reviewPortfolioGetActionable(body);
    dispatch({
      type: GET_REVIEW_PORTFOLIO_ANALYTICS_FINAL,
      payload: data.analytics.compare,
    });
    dispatch({
      type: GET_REVIEW_PORTFOLIO_ACTIONABLE,
      payload: data,
    });
    dispatch({
      type: UPDATE_SNACKBAR_PROPS,
      payload: {
        open: true,
        severity: data.message.severity,
        message: data.message.message,
        infinite: true,
      },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const ResetReviewPortfolioActionable = (value) => async (dispatch) => {
  dispatch({
    type: GET_REVIEW_PORTFOLIO_ACTIONABLE,
    payload: {
      initial_portfolio: {
        "Mutual Fund": [],
      },
      final_portfolio: {
        "Mutual Fund": [],
      },
      actions: [],
      frontend_table: [],

      portfolio_value: value,
    },
  });
};
export const PostReviewPortfolioActionable = (body) => async (dispatch) => {
  try {
    const { data } = await api.reviewPortfolioPostActionable(body);
    dispatch({
      type: POST_REVIEW_PORTFOLIO_ACTIONABLE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetReviewAdvanceAnalyticsAPI = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REVIEW_ADVANCE_ANALYTICS,
      payload: {
        before_equity_cagr: 0.25649488721804514,
        before_equity_bm_cagr: 0.25090799331665037,
        before_equity_category_allocation: {
          "Large Cap": 0.3763139453398739,
          "Mid Cap": 0.2648913805185704,
          "Flexi Cap": 0.12754029432375613,
          "Small cap": 0.0967063770147162,
          ETF: 0.06797477224947443,
          Others: 0.06657323055360898,
        },
        before_equity_product_allocation: {
          "Mutual Fund": 0.9586545199719693,
          AIF: 0.04134548002803083,
        },
        before_full_sector_allocation: {
          Financials: 0.3014427872850865,
          "Consumer Discretionary": 0.1417543613685334,
          Materials: 0.11900426161191946,
          "Health Care": 0.10428084089584587,
          Industrials: 0.09773375397119483,
          "Information Technology": 0.08787795198185226,
          "Consumer Staples": 0.056873184893973416,
          Utilities: 0.03751024715223223,
          Energy: 0.03281307114837468,
          "Real Estate": 0.011257895356414225,
          "Communication Services": 0.009451644334573284,
        },
        before_geographical_allocation: {
          "Domestic Equities": 0.9360072367976349,
          "Overseas Equities": 0.06399276320236508,
        },
        before_equity_products_count: 12,
        before_debt_cagr: 0.04851066319895968,
        before_debt_bm_cagr: 0.05938215039540151,
        before_debt_category_allocation: {
          "Ultra Short Duration": 0.6474760610001182,
          ETF: 0.2767466603617449,
          "High Yield Credit": 0.05473460219884147,
          "Medium to Long Duration": 0.01075777278638137,
          "Short Duration": 0.005910864168341412,
          Others: 0.004374039484572645,
        },
        before_debt_product_allocation: {
          "Mutual Fund": 0.9408913583165859,
          PMS: 0.054734602198841464,
          Deposits: 0.004374039484572644,
        },
        before_debt_products_count: 7,
        before_alternate_cagr: 0.05425221238938052,
        before_alternate_bm_cagr: 0.05370800018310547,
        before_alternate_category_allocation: {
          ETF: 0.5929203539823008,
          "Gold or Related": 0.07964601769911503,
          "Real Estate": 0.3274336283185841,
        },
        before_alternate_product_allocation: {
          "Gold or Related": 0.07964601769911503,
          "Mutual Fund": 0.5929203539823008,
          "Real Estate": 0.3274336283185841,
        },
        before_alternate_products_count: 3,
        after_equity_cagr: 0.2430050849878102,
        after_equity_bm_cagr: 0.25090799331665037,
        after_equity_category_allocation: {
          ETF: 0.14225178716545167,
          "Large Cap": 0.5732446385036452,
          Value: 0.14225178716545167,
          "Index Fund": 0.14225178716545167,
        },
        after_equity_product_allocation: {
          "Mutual Fund": 1.0,
        },
        after_full_sector_allocation: {
          Financials: 0.3892768535615603,
          "Information Technology": 0.14617975878040596,
          "Consumer Discretionary": 0.0962908751362212,
          Energy: 0.08374155462949383,
          "Consumer Staples": 0.0731504174695477,
          Materials: 0.054083297630196675,
          Industrials: 0.053478272829591215,
          "Health Care": 0.041683397452108605,
          "Communication Services": 0.03361792181196752,
          Utilities: 0.026852885623682387,
          "Real Estate": 0.001644765075224708,
        },
        after_geographical_allocation: {
          "Domestic Equities": 0.9994877969495775,
          "Overseas Equities": 0.0005122030504225526,
        },
        after_equity_products_count: 7,
        after_debt_cagr: 0.05936805390631601,
        after_debt_bm_cagr: 0.05938215039540151,
        after_debt_category_allocation: {
          "Dynamic Bond": 0.01512309029244617,
          "Short Duration": 0.9848769097075539,
        },
        after_debt_product_allocation: {
          "Mutual Fund": 1.0,
        },
        after_debt_products_count: 2,
        after_alternate_cagr: 0.0,
        after_alternate_bm_cagr: 0.05370800018310547,
        after_alternate_category_allocation: {
          "Unlisted Shares": 0.4513152249859408,
          "Real Estate": 0.5486847750140592,
        },
        after_alternate_product_allocation: {
          "Real Estate": 0.5486847750140592,
          "Unlisted Shares": 0.4513152249859408,
        },
        after_alternate_products_count: 19,
        loading: true,
      },
    });
    const { data } = await api.reviewAdvanceAnalytics(body);

    dispatch({
      type: GET_REVIEW_ADVANCE_ANALYTICS,
      payload: { ...data, loading: false },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetReviewPortfolioAnalyticsFinal = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REVIEW_PORTFOLIO_ANALYTICS_FINAL,
      payload: {
        loading: true,
        asset_allocation_before: {
          Equity: 0.522,
          Debt: 0.373,
          Alternate: 0.104,
        },
        asset_allocation_after: {
          Equity: 0.363,
          Debt: 0.56,
          Alternate: 0.077,
        },
        overall_product_allocation_before: {
          "Mutual Fund": 1.0,
        },
        overall_product_allocation_after: {
          "Mutual Fund": 1.0,
        },
        overall_prod_count_before: 17,
        overall_prod_count_after: 15,
        overall_liquidity_before: {
          Liquid: 1.0,
          "Semi-Liquid": 0,
          Illiquid: 0,
        },
        overall_liquidity_after: {
          Liquid: 1.0,
          "Semi-Liquid": 0,
          Illiquid: 0,
        },
        ovarall_cagr_before: 0.10159743589743592,
        ovarall_cagr_after: 0.08826787345500643,
        bm_blended_returns_before: 0.104011343,
        bm_blended_returns_after: 0.092598181,
        mkt_cap_allocation_before: {
          "Large Cap": [0.5742121880193439, 0.7768197345124772],
          "Mid Cap": [0.25714993100367817, 0.14793277839108115],
          "Small Cap": [0.16863788097697793, 0.07524748709644165],
        },
        mkt_cap_allocation_after: {
          "Large Cap": [0.5663484452910013, 0.7768197345124772],
          "Mid Cap": [0.29796826486310274, 0.14793277839108115],
          "Small Cap": [0.13568328984589587, 0.07524748709644165],
        },
        rating_allocation_before: [
          ["AA", "AAA", "Cash", "SOV"],
          [
            0.03287640657528132, 0.6667937333587467, 0.07795721559144313,
            0.2223726444745289,
          ],
        ],
        rating_allocation_after: [
          ["AA", "AAA", "Cash", "SOV"],
          [
            0.02212789260766159, 0.7222462092545587, 0.0635114039860613,
            0.19211449415171827,
          ],
        ],
        top5_sector_before: {
          Financials: 0.30290279232943323,
          "Consumer Discretionary": 0.16330796761870112,
          Materials: 0.12349433240284789,
          "Information Technology": 0.11912017415725008,
          "Health Care": 0.06933532932412445,
        },
        top5_sector_after: {
          Financials: 0.29583470895883945,
          "Consumer Discretionary": 0.17302269632626519,
          "Information Technology": 0.12079313615137308,
          Materials: 0.11687027791601368,
          "Health Care": 0.07544778253162132,
        },
        top5_stocks_before: {
          "ICICI Bank Ltd.": 0.06725266454963652,
          "HDFC Bank Ltd.": 0.05259732967074195,
          "Infosys Ltd.": 0.04591500551686917,
          "Axis Bank Ltd.": 0.030878412431032155,
          "Reliance Industries Ltd.": 0.030520864697084676,
        },
        top5_stocks_after: {
          "ICICI Bank Ltd.": 0.0635426565345761,
          "HDFC Bank Ltd.": 0.04498335392105113,
          "Infosys Ltd.": 0.039017714330106715,
          "Reliance Industries Ltd.": 0.03505825590326859,
          "Axis Bank Ltd.": 0.025734849174747942,
        },
        debt_quants_before: {
          duration: 2.33656,
          gross_ytm: 0.06418,
          maturity: 3.5729400000000004,
        },
        debt_quants_after: {
          duration: 2.5959453624733473,
          gross_ytm: 0.06495367803837954,
          maturity: 3.8066158848614067,
        },
        debt_top_5_sec_before: {
          "91 Days Treasury Bill - 18-Aug-2022": 0.026658649680025494,
          "GOI - 30-Aug-2034": 0.02175475265665715,
          "04.04% GOI - 04-Oct-2028": 0.018106112713713103,
          "Axis Bank Ltd. (10-Feb-23)": 0.018081601904988732,
          "Housing Development Finance Corporation Ltd. -SR-W-005 07.21% (30-Dec-22)": 0.016356735082190384,
        },
        debt_top_5_sec_after: {
          "Hindustan Petroleum Corporation Ltd. SR-II 7.03% (12-Apr-30)": 0.02364770190230143,
          "Power Finance Corpn. Ltd. SR-196 07.41% (25-Feb-30)": 0.02263210739383852,
          "Nuclear Power Corporation of India Ltd. SR-XXXIV 7.34% (23-Jan-30)": 0.021056104505787702,
          "REC Ltd.-SR-188-B 07.89% (31-Mar-30)": 0.021044117160769777,
          "Indian Railway Finance Corpn Ltd SR-144 7.55% (12-Apr-30)": 0.020276261116010608,
        },
      },
    });
    const { data } = await api.reviewPortfolioGetAnalyticsFinal(body);
    dispatch({
      type: GET_REVIEW_PORTFOLIO_ANALYTICS_FINAL,
      payload: data.compare,
    });
    dispatch({
      type: UPDATE_SNACKBAR_PROPS,
      payload: {
        open: true,
        severity: "success",
        message: "Analytics updated successfully",
      },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetReviewPortfolioPpt = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REVIEW_PORTFOLIO_PPT,
      payload: { pdf: "", excel: "", loading: true },
    });

    const { data } = await api.reviewPortfolioGetPPT(body);
    dispatch({
      type: GET_REVIEW_PORTFOLIO_PPT,
      payload: data,
    });
  } catch (error) {
    console.log(
      error.response && error.response.data.detail //err.response.data
        ? error.response.data.detail
        : error.message
    );
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetMutualPortfolioAnalyticsPpt = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REVIEW_PORTFOLIO_PPT,
      payload: { pdf: "", excel: "", loading: true },
    });

    const { data } = await api.mutualFundAnalyticsGetPPT(body);
    dispatch({
      type: GET_REVIEW_PORTFOLIO_PPT,
      payload: data,
    });
  } catch (error) {
    console.log(
      error.response && error.response.data.detail //err.response.data
        ? error.response.data.detail
        : error.message
    );
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const GetReviewHistory = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REVIEW_PREVIOUS_HISTORY,
      payload: { loading: true, results: [] },
    });
    const { data } = await api.reviewHistory(body);
    dispatch({
      type: GET_REVIEW_PREVIOUS_HISTORY,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
// Snackbar Actions
export const CloseSnackbar = () => async (dispatch) => {
  dispatch({
    type: UPDATE_SNACKBAR_PROPS,
    payload: {
      open: false,
      severity: "info",
      message: "",
    },
  });
};
export const OpenSnackbar =
  ({ severity, message, infinite }) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_SNACKBAR_PROPS,
      payload: {
        open: true,
        severity: severity,
        message: message,
        infinite: infinite ? true : false,
      },
    });
  };
// SIP Proposal
export const GetSIPAllocationPreference = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SIP_ANALYTICS_API,
      payload: {
        asset_allocation: {
          Equity: 0,
          Debt: 0,
          Alternate: 0,
        },
        product_allocation: {
          "Mutual Fund": 1.0,
        },
        port_return: 0,
        bm_blended_return: 0,
        total_products: 0,
        liquidity: 0,
        market_cap: {
          "Large Cap": [0, 0],
          "Mid Cap": [0, 0],
          "Small Cap": [0, 0],
        },
        top5_sector: {
          Financials: [0, 0],
          "Consumer Discretionary": [0, 0],
          "Health Care": [0, 0],
          Materials: [0, 0],
          "Information Technology": [0, 0],
        },
        top5_stocks: {
          "ICICI Bank Ltd.": 0,
          "HDFC Bank Ltd.": 0,
          "Axis Bank Ltd.": 0,
          "ITC Ltd.": 0,
          "State Bank Of India": 0,
        },
        debt_quants: {
          duration: 0,
          gross_ytm: 0,
          maturity: 0,
        },
        rating_allocation: {
          A: 0,
          AA: 0,
          AAA: 0,
          B: 0.0,
          BB: 0.0,
          BBB: 0.0,
          C: 0.0,
          Cash: 0.0,
          D: 0.0,
          SOV: 0.0,
          Unrated: 0.0,
        },
        top5_debt_instruments: {
          "GOI FRB 22-Sep-2033": 0.0,
          "Mangalore Refinery & Petrochemicals Ltd. -SR-4 07.48% (14-Apr-32)": 0.0,
          "NTPC Ltd. SR-76 06.74% (14-Apr-32)": 0.0,
          "National Highways Authority of India - SR-III 06.87% (14-Apr-32)": 0.0,
          "Power Finance Corporation Ltd. SR-214 6.92% (14-Apr-32)": 0.0,
        },
        loading: true,
      },
    });
    const { data } = await api.sipallocationPreference(body);
    dispatch({
      type: GET_SIP_ALLOCATION_PREFERENCE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const ResetSIPAllocationPreference = () => async (dispatch) => {
  dispatch({
    type: GET_SIP_ALLOCATION_PREFERENCE,
    payload: {
      "Mutual Fund": [],
    },
  });
};
export const GetSIPAnalyticsAPI = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SIP_ANALYTICS_API,
      payload: {
        asset_allocation: {
          Equity: 0,
          Debt: 0,
          Alternate: 0,
        },
        product_allocation: {
          "Mutual Fund": 1.0,
        },
        port_return: 0,
        bm_blended_return: 0,
        total_products: 0,
        liquidity: 0,
        market_cap: {
          "Large Cap": [0, 0],
          "Mid Cap": [0, 0],
          "Small Cap": [0, 0],
        },
        top5_sector: {
          Financials: [0, 0],
          "Consumer Discretionary": [0, 0],
          "Health Care": [0, 0],
          Materials: [0, 0],
          "Information Technology": [0, 0],
        },
        top5_stocks: {
          "ICICI Bank Ltd.": 0,
          "HDFC Bank Ltd.": 0,
          "Axis Bank Ltd.": 0,
          "ITC Ltd.": 0,
          "State Bank Of India": 0,
        },
        debt_quants: {
          duration: 0,
          gross_ytm: 0,
          maturity: 0,
        },
        rating_allocation: {
          A: 0,
          AA: 0,
          AAA: 0,
          B: 0.0,
          BB: 0.0,
          BBB: 0.0,
          C: 0.0,
          Cash: 0.0,
          D: 0.0,
          SOV: 0.0,
          Unrated: 0.0,
        },
        top5_debt_instruments: {
          "GOI FRB 22-Sep-2033": 0.0,
          "Mangalore Refinery & Petrochemicals Ltd. -SR-4 07.48% (14-Apr-32)": 0.0,
          "NTPC Ltd. SR-76 06.74% (14-Apr-32)": 0.0,
          "National Highways Authority of India - SR-III 06.87% (14-Apr-32)": 0.0,
          "Power Finance Corporation Ltd. SR-214 6.92% (14-Apr-32)": 0.0,
        },
        loading: true,
      },
    });
    const { data } = await api.sipanalytics(body);

    dispatch({
      type: GET_SIP_ANALYTICS_API,
      payload: { ...data, loading: false },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetSIPAdvanceAnalyticsAPI = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SIP_ADVANCE_ANALYTICS,
      payload: {
        equity_cagr: 0.28102675271601807,
        equity_bm_cagr: 0.25090799331665037,
        equity_category_allocation: {
          "Flexi Cap": 0.27888323339713994,
          ETF: 0.16393442622950816,
          "Multi Cap": 0.16393442622950813,
          "Pharma & Health Care": 0.1639344262295081,
          "FoFs (Overseas)": 0.08606557377049183,
          Others: 0.14324791414384408,
        },
        equity_product_allocation: {
          "Mutual Fund": 1.0,
        },
        full_sector_allocation: {
          "Health Care": 0.2562940036573252,
          Financials: 0.25120566390713417,
          Industrials: 0.10050624843167878,
          Materials: 0.09586499609101654,
          "Consumer Discretionary": 0.08753629413579035,
          "Consumer Staples": 0.07040976166230023,
          Energy: 0.044958145994652234,
          "Information Technology": 0.03888923436996799,
          Utilities: 0.02949640509397072,
          "Communication Services": 0.020238217142890966,
          "Real Estate": 0.004601029513272696,
        },
        geographical_allocation: {
          "Domestic Equities": 0.7806262312512386,
          "Overseas Equities": 0.21937376874876138,
        },
        equity_products_count: 8,
        debt_cagr: 0.06539327561929503,
        debt_bm_cagr: 0.05938215039540151,
        debt_category_allocation: {
          "Corporate Bond": 0.5698005698005701,
          "Short Duration": 0.1452991452991449,
          ETF: 0.07186783257141309,
          "Debt Oriented": 0.21303245232887186,
        },
        debt_product_allocation: {
          "Mutual Fund": 1.0,
        },
        debt_products_count: 8,
        alternate_cagr: 0.09,
        alternate_bm_cagr: 0.05370800018310547,
        alternate_category_allocation: {
          ETF: 1.0,
        },
        alternate_product_allocation: {
          "Mutual Fund": 1.0,
        },
        alternate_products_count: 1,
        loading: true,
      },
    });
    const { data } = await api.sipAdvanceAnalytics(body);

    dispatch({
      type: GET_SIP_ADVANCE_ANALYTICS,
      payload: { ...data, loading: false },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetSIPPPTAPI = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SIP_PPT_API,
      payload: { pdf: "", excel: "", loading: true },
    });
    const { data } = await api.sipppt(body);

    dispatch({
      type: GET_SIP_PPT_API,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetSIPHistory = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SIP_PREVIOUS_HISTORY,
      payload: { loading: true, results: [] },
    });
    const { data } = await api.sipHistory(body);
    dispatch({
      type: GET_SIP_PREVIOUS_HISTORY,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
// MODEL PORTFOLIO
export const PostModelPortfolioBookmark = (body) => async (dispatch) => {
  try {
    const { data } = await api.postModelPortfolioBookmark(body);
    dispatch({
      type: POST_MODEL_PORTFOLIO_BOOKMARK,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const EmptyModelPortfolioAnalytics = (body) => async (dispatch) => {
  dispatch({
    type: GET_MODEL_PORTFOLIO_ANALYTICS,
    payload: {
      asset_allocation: {
        Equity: 0,
        Debt: 0,
        Alternate: 0,
      },
      product_allocation: {
        "Mutual Fund": 0,
      },
      port_return: 0,
      bm_blended_return: 0,
      total_products: 0,
      liquidity: 0,
      market_cap: {
        "Large Cap": [0, 0],
        "Mid Cap": [0, 0],
        "Small Cap": [0, 0],
      },
      top5_sector: {
        Materials: [0, 0],
        "Consumer Staples": [0, 0],
        Industrials: [0, 0],
        Financials: [0, 0],
        "Consumer Discretionary": [0, 0],
      },
      top5_stocks: {
        "ITC Ltd.": 0,
        "Ambuja Cements Ltd.": 0,
        "Adani Ports and Special Economic Zone Ltd.": 0,
        "State Bank Of India": 0,
        "Larsen & Toubro Ltd.": 0,
      },
      debt_quants: {
        duration: 0,
        gross_ytm: 0,
        maturity: 0,
      },
      rating_allocation: {},
      top5_debt_instruments: {},
    },
  });
};
export const GetModelPortfolioAnalytics = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_MODEL_PORTFOLIO_ANALYTICS,
      payload: {
        loading: true,
        asset_allocation: {
          Equity: 1.0,
          Debt: 0,
          Alternate: 0,
        },
        product_allocation: {
          "Mutual Fund": 1.0,
        },
        port_return: 0.3403,
        bm_blended_return: 0.0,
        total_products: 1,
        liquidity: 1.0,
        market_cap: {
          "Large Cap": [0.4746713058597233, 0.7697023922583999],
          "Mid Cap": [0.26589340011821416, 0.15305255370835041],
          "Small Cap": [0.25943529402206256, 0.07724505403324969],
        },
        top5_sector: {
          Materials: [0.2122564403102599, 0.0],
          "Consumer Staples": [0.1779059629659611, 0.0],
          Industrials: [0.16385854798844557, 0.0],
          Financials: [0.12754850678505303, 0.0],
          "Consumer Discretionary": [0.09381156797549162, 0.0],
        },
        top5_stocks: {
          "ITC Ltd.": 0.09763708824201497,
          "Ambuja Cements Ltd.": 0.08660419390915779,
          "Adani Ports and Special Economic Zone Ltd.": 0.05651137062572416,
          "State Bank Of India": 0.05156972114762233,
          "Larsen & Toubro Ltd.": 0.0398986101170433,
        },
        debt_quants: {
          duration: 0,
          gross_ytm: 0,
          maturity: 0,
        },
        rating_allocation: {},
        top5_debt_instruments: {},
      },
    });
    const { data } = await api.getModelPortfolioAnalytics(body);
    dispatch({
      type: GET_MODEL_PORTFOLIO_ANALYTICS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetModelPortfolio = (body) => async (dispatch) => {
  try {
    const { data } = await api.getModelPortfolio(body);
    dispatch({
      type: GET_MODEL_PORTFOLIO,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const PostModelPortfolio =
  (body, products, history) => async (dispatch) => {
    try {
      const { data } = await api.postModelPortfolio(body);
      dispatch({
        type: POST_MODEL_PORTFOLIO,
        payload: data,
      });
      const temp = products.map((product) => {
        return { ...product, portfolio_id: data.portfolio_id };
      });
      const res = await api.postModelPortfolioProducts(temp);
      dispatch({
        type: POST_MODEL_PORTFOLIO_PRODUCTS,
        payload: res.data,
      });
      history.push("/modelportfolio");
    } catch (error) {
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    }
  };
export const PatchModelPortfolio = (body, history) => async (dispatch) => {
  try {
    const { data } = await api.patchModelPortfolio(body);
    dispatch({
      type: PATCH_MODEL_PORTFOLIO,
      payload: data,
    });
    history.push("/modelportfolio");
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const DeleteModelPortfolio = (body) => async (dispatch) => {
  try {
    const { data } = await api.deleteModelPortfolio(body);
    dispatch({
      type: DELETE_MODEL_PORTFOLIO,
      payload: data,
    });
    const res = await api.getModelPortfolio();
    dispatch({
      type: GET_MODEL_PORTFOLIO,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetModelPortfolioProducts =
  (body, history, readOnly = false, portfolio) =>
  async (dispatch) => {
    try {
      const { data } = await api.getModelPortfolioProducts({
        params: {
          portfolio_id: body,
        },
      });
      dispatch({
        type: GET_MODEL_PORTFOLIO_PRODUCTS,
        payload: data,
      });
      dispatch({
        type: GET_MODEL_PORTFOLIO_ANALYTICS,
        payload: {
          loading: true,
          asset_allocation: {
            Equity: 1.0,
            Debt: 0,
            Alternate: 0,
          },
          product_allocation: {
            "Mutual Fund": 1.0,
          },
          port_return: 0.3403,
          bm_blended_return: 0.0,
          total_products: 0,
          liquidity: 1.0,
          market_cap: {
            "Large Cap": [0.4746713058597233, 0.7697023922583999],
            "Mid Cap": [0.26589340011821416, 0.15305255370835041],
            "Small Cap": [0.25943529402206256, 0.07724505403324969],
          },
          top5_sector: {
            Materials: [0.2122564403102599, 0.0],
            "Consumer Staples": [0.1779059629659611, 0.0],
            Industrials: [0.16385854798844557, 0.0],
            Financials: [0.12754850678505303, 0.0],
            "Consumer Discretionary": [0.09381156797549162, 0.0],
          },
          top5_stocks: {
            "ITC Ltd.": 0.09763708824201497,
            "Ambuja Cements Ltd.": 0.08660419390915779,
            "Adani Ports and Special Economic Zone Ltd.": 0.05651137062572416,
            "State Bank Of India": 0.05156972114762233,
            "Larsen & Toubro Ltd.": 0.0398986101170433,
          },
          debt_quants: {
            duration: 0,
            gross_ytm: 0,
            maturity: 0,
          },
          rating_allocation: {},
          top5_debt_instruments: {},
        },
      });
      history.push({
        pathname: "/modelportfolio/open",
        state: { products: data, readOnly: readOnly, portfolio: portfolio },
      });
      const analyticsReq = { "Mutual Fund": data };
      const res = await api.getModelPortfolioAnalytics(analyticsReq);
      dispatch({
        type: GET_MODEL_PORTFOLIO_ANALYTICS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    }
  };
export const GetSipModelPortfolioProducts = (body) => async (dispatch) => {
  try {
    const { data } = await api.getModelPortfolioProducts({
      params: {
        portfolio_id: body,
      },
    });
    dispatch({
      type: GET_SIP_MODEL_PORTFOLIO_PRODUCTS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const PostModelPortfolioProducts = (body) => async (dispatch) => {
  try {
    const { data } = await api.postModelPortfolioProducts(body);
    dispatch({
      type: POST_MODEL_PORTFOLIO_PRODUCTS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const PatchModelPortfolioProducts = (body) => async (dispatch) => {
  try {
    const { data } = await api.patchModelPortfolioProducts(body);
    dispatch({
      type: PATCH_MODEL_PORTFOLIO_PRODUCTS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const DeleteModelPortfolioProducts = (body) => async (dispatch) => {
  try {
    const { data } = await api.deleteModelPortfolioProducts(body);
    dispatch({
      type: DELETE_MODEL_PORTFOLIO_PRODUCTS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
// GOAL PLANNING
export const GetGoalsList = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_GOALS_LIST,
      payload: ["loading"],
    });
    const { data } = await api.getGoalsList(body);
    dispatch({
      type: GET_GOALS_LIST,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetGoalDetails = (id, body) => async (dispatch) => {
  try {
    const { data } = await api.getGoalDetail(id, body);
    dispatch({
      type: GET_GOAL_DETAILS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

//instant-review
export const GetAcquireClientsAnalytics = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ACQUIRE_CLIENTS_ANALYTICS,
      payload: {
        "Mutual Fund": [],
        analytics: {
          asset_allocation: {
            Equity: 0,
            Debt: 0,
            Alternate: 0,
          },
          product_allocation: {
            "Mutual Fund": 1.0,
          },
          port_return: 0,
          bm_blended_return: 0,
          total_products: 0,
          liquidity: 0,
          market_cap: {
            "Large Cap": [0, 0],
            "Mid Cap": [0, 0],
            "Small Cap": [0, 0],
          },
          top5_sector: {
            Financials: [0, 0],
            "Consumer Discretionary": [0, 0],
            "Health Care": [0, 0],
            Materials: [0, 0],
            "Information Technology": [0, 0],
          },
          top5_stocks: {
            "ICICI Bank Ltd.": 0,
            "HDFC Bank Ltd.": 0,
            "Axis Bank Ltd.": 0,
            "ITC Ltd.": 0,
            "State Bank Of India": 0,
          },
          debt_quants: {
            duration: 0,
            gross_ytm: 0,
            maturity: 0,
          },
          rating_allocation: {
            A: 0,
            AA: 0,
            AAA: 0,
            B: 0.0,
            BB: 0.0,
            BBB: 0.0,
            C: 0.0,
            Cash: 0.0,
            D: 0.0,
            SOV: 0.0,
            Unrated: 0.0,
          },
          top5_debt_instruments: {
            "GOI FRB 22-Sep-2033": 0.0,
            "Mangalore Refinery & Petrochemicals Ltd. -SR-4 07.48% (14-Apr-32)": 0.0,
            "NTPC Ltd. SR-76 06.74% (14-Apr-32)": 0.0,
            "National Highways Authority of India - SR-III 06.87% (14-Apr-32)": 0.0,
            "Power Finance Corporation Ltd. SR-214 6.92% (14-Apr-32)": 0.0,
          },
          loading: true,
        },
      },
    });
    const { data } = await api.acquireClientsAnalytics(body);

    dispatch({
      type: GET_ACQUIRE_CLIENTS_ANALYTICS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ACQUIRE_CLIENTS_ANALYTICS,
      payload: {
        "Mutual Fund": [],
        analytics: {
          asset_allocation: {
            Equity: 0,
            Debt: 0,
            Alternate: 0,
          },
          product_allocation: {
            "Mutual Fund": 1.0,
          },
          port_return: 0,
          bm_blended_return: 0,
          total_products: 0,
          liquidity: 0,
          market_cap: {
            "Large Cap": [0, 0],
            "Mid Cap": [0, 0],
            "Small Cap": [0, 0],
          },
          top5_sector: {
            Financials: [0, 0],
            "Consumer Discretionary": [0, 0],
            "Health Care": [0, 0],
            Materials: [0, 0],
            "Information Technology": [0, 0],
          },
          top5_stocks: {
            "ICICI Bank Ltd.": 0,
            "HDFC Bank Ltd.": 0,
            "Axis Bank Ltd.": 0,
            "ITC Ltd.": 0,
            "State Bank Of India": 0,
          },
          debt_quants: {
            duration: 0,
            gross_ytm: 0,
            maturity: 0,
          },
          rating_allocation: {
            A: 0,
            AA: 0,
            AAA: 0,
            B: 0.0,
            BB: 0.0,
            BBB: 0.0,
            C: 0.0,
            Cash: 0.0,
            D: 0.0,
            SOV: 0.0,
            Unrated: 0.0,
          },
          top5_debt_instruments: {
            "GOI FRB 22-Sep-2033": 0.0,
            "Mangalore Refinery & Petrochemicals Ltd. -SR-4 07.48% (14-Apr-32)": 0.0,
            "NTPC Ltd. SR-76 06.74% (14-Apr-32)": 0.0,
            "National Highways Authority of India - SR-III 06.87% (14-Apr-32)": 0.0,
            "Power Finance Corporation Ltd. SR-214 6.92% (14-Apr-32)": 0.0,
          },
          loading: true,
          reattempt: true,
        },
      },
    });
  }
};
export const UpdateAcquireClientsAnalytics = (body) => async (dispatch) => {
  dispatch({
    type: GET_ACQUIRE_CLIENTS_ANALYTICS,
    payload: body,
  });
};
export const GetAcquireClientsPPTAPI = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ACQUIRE_CLIENTS_PPT,
      payload: { pdf: "", excel: "", loading: true },
    });
    const { data } = await api.acquireClientsPPT(body);
    dispatch({
      type: GET_ACQUIRE_CLIENTS_PPT,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetAcquireClientsCamsPPTAPI = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ACQUIRE_CLIENTS_PPT,
      payload: { pdf: "", excel: "", loading: true },
    });
    const { data } = await api.acquireClientsCamsPPT(body);
    dispatch({
      type: GET_ACQUIRE_CLIENTS_PPT,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
export const GetAcquireClientsDashboardAPI = (body) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ACQUIRE_CLIENTS_DASHBOARD,
      payload: { loading: true, count: 0, results: [] },
    });
    const { data } = await api.acquireClientsDashboard(body);

    dispatch({
      type: GET_ACQUIRE_CLIENTS_DASHBOARD,
      payload: { loading: false, ...data },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
