import { POST_MODEL_PORTFOLIO } from "../actions/type";
const initialState = {};
export default function postModelPortfolio(state = initialState, action) {
  switch (action.type) {
    case POST_MODEL_PORTFOLIO:
      return action.payload;
    default:
      return state;
  }
}
