import React from "react";
import InvestedgeModal from "../../../components/modal/InvestedgeModal";
import parse from "html-react-parser";
import { IoIosClose } from "react-icons/io";
export default function MarketFaqModel({ data, open, onClose }) {
  return (
    <InvestedgeModal
      aria-labelledby={`Market faq ${data.id}`}
      aria-describedby={`Market faq ${data.id}`}
      open={open}
      handleclose={onClose}
    >
      <div className="flex justify-between border-0 pt-0">
        <h5 className="font-medium text-xl align-left p-0 font-inter text-neutralBlack">
          {data.question}
        </h5>
        <IoIosClose size={30} onClick={onClose} />
      </div>
      <h2 className="font-inter text-[14px] font-normal leading-[20px] tracking-[0em] text-left mt-4">
        {parse(data.answer)}
      </h2>
      <div className="flex flex-row gap-4 mt-3 mb-3">
        {Object.keys(data.tags).map((e) => (
          <div
            key={data.tags[e]}
            className="pl-3 pr-3 text-xs font-inter font-normal leading-[23px] bg-[#EDF5FE] text-primary-300 rounded-[4px]"
          >
            {data.tags[e]}
          </div>
        ))}
      </div>
    </InvestedgeModal>
  );
}
