import React, { useState } from "react";
// import AfterloginApp from "../../../AfterloginApp";
import CheckDisabled from "../../../../assets/img/check_circle_outlined_grey.svg";
import CheckSuccess from "../../../../assets/img/check_circle_outlined_green.svg";
import SelectProposal from "../../../../assets/img/select_icon_simulate_proposal.svg";
import PortfolioSelectionModal from "./PortfolioSelectionModal";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
// import { Link } from "react-router-dom";
export default function PortfolioFirstStep({ handleNext }) {
  const [portfolio1, setPortfolio1] = useState(null);
  const [portfolio2, setPortfolio2] = useState(null);
  const [portfolioSelectionModal, setPortfolioSelectionModal] = useState(false);
  const [portfolioType, setPortfolioType] = useState("");
  return (
    <>
      <div className="px-8 rounded-[8px] w-full bg-white py-6 mt-[16px]">
        <div className="flex justify-between w-full">
          <div className="flex items-center">
            <h2 className="card-heading">Select Portfolio</h2>
          </div>

          <InvestedgeButton
            onClick={() => {
              // if (horizon) {
              //   setFormValue({
              //     ...formValue,
              //     risk_score: riskscore,
              //     investment_horizon: parseInt(horizon),
              //     type_of_scheme: scheme,
              //   });
              //   handleNext();
              // } else setError("This field is required");
              // dispatch(
              //   GetReviewPortfolioActionable({
              //     client_id: clientId,
              //     allocation_preference: formValue.allocation_preference,
              //   })
              // );
              // dispatch(
              //   PostEventLoggingData({
              //     module_name: "Portfolio Review",
              //     event_type: "Next Button",
              //     url: window.location.href,
              //     note: `Third screen`,
              //   })
              // );
              handleNext();
            }}
          >
            Simulate
          </InvestedgeButton>
        </div>
      </div>
      <div className="px-8 rounded-[8px] w-full bg-white py-8 mt-[16px] min-h-[calc(100vh_-_350px)]">
        <p className="portfolio-proposal-page-description">
          Add/delete Invetment Products to see how Portfolio Analytics changes.
          Check and add what suits best for the client.
        </p>
        <div className=" w-[80%] flex flex-wrap justify-between pt-[2rem]">
          <div className="bg-[#FAFAFA] p-[32px] w-[45%] rounded-[4px]">
            <div className="flex justify-between">
              <h3 className="font-inter font-medium text-lg">
                Select Portfolio 1
              </h3>
              <img src={portfolio1 ? CheckSuccess : CheckDisabled} alt="err" />
            </div>
            <div
              style={{
                marginTop: 24,
              }}
              className="w-[90%]  border border-[#a9d5ff] my-0 mx-auto p-[32px] rounded-[6px] flex justify-center items-center bg-[#FCFCFC] cursor-pointer"
              onClick={() => {
                setPortfolioType("portfolio1");
                setPortfolioSelectionModal(true);
              }}
            >
              <p className="text-sm font-inter font-medium">Select</p>
              <img
                src={SelectProposal}
                alt="err"
                className="w-[48px] mt-[8px]"
              />
            </div>
          </div>
          <div className="bg-[#FAFAFA] p-[32px] w-[45%] rounded-[4px]">
            <div className="flex justify-between">
              <h3 className="font-inter font-medium text-lg">
                Select Portfolio 2
              </h3>
              <img src={portfolio2 ? CheckSuccess : CheckDisabled} alt="err" />
            </div>
            <div
              style={{
                marginTop: 24,
              }}
              className="w-[90%] p-[32px] border border-[#a9d5ff] my-0 mx-auto mt-[24] rounded-[6px] flex justify-center items-center bg-[#FCFCFC] cursor-pointer"
              onClick={() => {
                setPortfolioType("portfolio2");
                setPortfolioSelectionModal(true);
              }}
            >
              <p className="text-sm font-inter font-medium">Select</p>
              <img
                src={SelectProposal}
                alt="err"
                className="w-[48px] mt-[8px]"
              />
            </div>
          </div>
        </div>
        <PortfolioSelectionModal
          portfolioType={portfolioType}
          setPortfolio1={setPortfolio1}
          setPortfolio2={setPortfolio2}
          portfolioSelectionModal={portfolioSelectionModal}
          onClose={() => setPortfolioSelectionModal(false)}
        />
      </div>
    </>
  );
}
