import { GET_ANNUAL_TARGET } from "../actions/type";
const initialState = {
  percentage: 0,
  target_aum: {
    amount: 0,
    denomination: "L",
  },
};
export default function GetAnnualTarget(state = initialState, action) {
  switch (action.type) {
    case GET_ANNUAL_TARGET:
      return action.payload;
    default:
      return state;
  }
}
