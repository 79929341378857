import React from "react";
import Cross from "../../../assets/img/circleovercrosslight.svg";
export default function UpdatedClientCard({
  ind,
  client,
  handleCancelDetails,
}) {
  return (
    <div
      className="flex justify-between min-w-[20%] bg-primary-accent text-primary-200 py-[0.5rem] px-[0.75rem] m-[1rem] rounded-[4px]"
      style={ind === 0 ? { marginLeft: 0 } : {}}
    >
      <p className="mb-[0rem] font-inter font-normal">{client.optionname}</p>
      <img
        className="ml-[0.5rem]"
        onClick={() => handleCancelDetails(client)}
        src={Cross}
        alt="err"
      />
    </div>
  );
}
