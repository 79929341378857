import React, { useState, useEffect } from "react";
import BookmarkUnsaved from "../../assets/img/bookmarkunsaved.svg";
import BookmarkSaved from "../../assets/img/bookmarksaved.svg";
import { PostEventLoggingData, PostSavedFAQS } from "../../actions/actions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import "../../static/css/existing/dashboard_styles.css";

export default function SearchResultQuestion({ faq, savedFaqIds }) {
  const dispatch = useDispatch();
  const [bookmark, setBookmark] = useState(savedFaqIds[faq.id]);
  useEffect(() => {
    setBookmark(savedFaqIds[faq.id]);
    // eslint-disable-next-line
  }, [savedFaqIds]);
  return (
    <div className="max-w-full">
      <div className="flex w-full justify-between">
        <Link
          to={{
            pathname: "/searchviewquestion",
            state: {
              faq: faq.id,
              savedFaqIds: savedFaqIds,
            },
          }}
        >
          <h1 className="text-primary-200 font-inter text-[15px] font-medium leading-5 tracking-[0.02em] mb-[1rem]">
            {faq.question}
          </h1>
        </Link>
        <img
          src={bookmark ? BookmarkSaved : BookmarkUnsaved}
          alt="err"
          onClick={() => {
            var formdata = new FormData();
            formdata.append("faq_id", faq.id);
            dispatch(PostSavedFAQS(formdata));
            !bookmark &&
              dispatch(
                PostEventLoggingData({
                  module_name: "Search",
                  event_type: "Bookmark",
                  url: window.location.href,
                  note: faq.question,
                })
              );
            setBookmark(!bookmark);
          }}
          style={{ cursor: "pointer" }}
        />
      </div>

      <div className="font-inter text-[14px] font-medium leading-[25px] tracking-[0.02em] text-neutralBlack mb-[3rem]">
        {faq.answer && parse(faq.answer.slice(0, 200))}
        <Link
          to={{
            pathname: "/searchviewquestion",
            state: {
              faq: faq.id,
              savedFaqIds: savedFaqIds,
            },
          }}
        >
          <span className="font-inter font-medium text-[14px] leading-[25px] tracking-[0.02em] text-primary-200 mb-[2rem]">
            ...Read more
          </span>
        </Link>
      </div>
    </div>
  );
}
