import React, { useEffect } from "react";
import { Bars } from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import {
  GetReviewPortfolioActionable,
  PostEventLoggingData,
  ResetReviewPortfolioActionable,
} from "../../../../actions/actions";
import TooltipIcon from "../../../../assets/img/tooltip_icon.svg";
import { Tooltip, Zoom } from "@mui/material";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
export default function PortfolioThirdStep({
  handleBack,
  handleNext,
  formValue,
  clientId,
  setPortfolioActions,
  setPortfolioProducts,
}) {
  const ipsData = useSelector((state) => state.portfolioReviewGetIpsTracker);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ResetReviewPortfolioActionable());
    setPortfolioActions([]);
    setPortfolioProducts([]);
    // eslint-disable-next-line
  }, []);
  const disabledValue = !ipsData.length;
  return (
    <>
      <div className="px-8 rounded-[8px] w-full bg-white py-8 mt-[16px]">
        <div className="flex justify-between w-full">
          <div className="flex items-center">
            <h2 className="card-heading">IPS Check</h2>
            <Tooltip
              TransitionComponent={Zoom}
              placement="right"
              title="Tells the difference between the current asset/ sub-asset allocation and IPS limits."
              arrow
            >
              <img
                src={TooltipIcon}
                alt="tooltip"
                className="w-[16px] h-[16px] ml-2 mb-1"
              />
            </Tooltip>
          </div>

          <div className="flex">
            <InvestedgeButton
              onClick={() => {
                dispatch(
                  PostEventLoggingData({
                    module_name: "Portfolio Review",
                    event_type: "Prev Button",
                    url: window.location.href,
                    note: `Third screen`,
                  })
                );
                handleBack();
              }}
            >
              Prev
            </InvestedgeButton>
            <InvestedgeButton
              className="ml-4"
              disabled={disabledValue}
              onClick={() => {
                // if (horizon) {
                //   setFormValue({
                //     ...formValue,
                //     risk_score: riskscore,
                //     investment_horizon: parseInt(horizon),
                //     type_of_scheme: scheme,
                //   });
                //   handleNext();
                // } else setError("This field is required");
                ipsData.length &&
                  dispatch(
                    GetReviewPortfolioActionable({
                      client_id: clientId,
                      allocation_preference: formValue.allocation_preference,
                    })
                  );
                ipsData.length &&
                  dispatch(
                    PostEventLoggingData({
                      module_name: "Portfolio Review",
                      event_type: "Next Button",
                      url: window.location.href,
                      note: `Third screen`,
                    })
                  );
                ipsData.length && handleNext();
              }}
            >
              Next
            </InvestedgeButton>
          </div>
        </div>
      </div>
      <div className="w-[100%] flex">
        {ipsData.length ? (
          <div className="px-8 rounded-[8px] w-full bg-white py-8 mt-[16px] font-inter">
            <div className="w-full grid grid-cols-5 divide-solid border-b-[1px] gap-[16px] border-b-[#6A6A76] p-[8px]">
              <h2 className="font-semibold text-sm text-[#3D3D3D]">
                {ipsData[0][0]}
              </h2>
              <h2 className="font-semibold text-sm text-[#3D3D3D] text-center">
                {ipsData[0][1]}
              </h2>
              <h2 className="font-semibold text-sm text-[#3D3D3D] text-center">
                {ipsData[0][2]}
              </h2>
              <h2 className="font-semibold text-sm text-[#3D3D3D] text-center">
                {ipsData[0][3]}
              </h2>
              <h2 className="font-semibold text-sm text-[#3D3D3D] text-center">
                {ipsData[0][4]}
              </h2>
            </div>

            <div className="mt-[16px]">
              <div className=" p-[8px] w-full border-b-[1px] border-b-[E5E5E5]">
                <h2 className="font-semibold text-sm text-[#3D3D3D] font-inter">
                  {ipsData[1][0]}
                </h2>
              </div>
              <div className="bg-[#FFF] grid grid-cols-5 gap-[16px] p-[8px] w-full border-b-[1px] border-b-[E5E5E5] font-inter">
                <h2 className="text-sm mb-0 text-[#3D3D3D] font-inter">
                  {ipsData[2][0]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[2][1]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[2][2]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[2][3]}
                </h2>
                <h2
                  className={
                    ipsData[2][4][0] === "Y"
                      ? "text-sm text-center text-[#FF0000]  font-inter"
                      : "text-sm  text-center text-[#3D3D3D] font-inter"
                  }
                >
                  {ipsData[2][4]}
                </h2>
              </div>
              <div className="bg-[#FFF] grid grid-cols-5 gap-[16px] p-[8px] w-full border-b-[1px] border-b-[E5E5E5]">
                <h2 className="text-sm mb-0 text-[#3D3D3D] font-inter ">
                  {ipsData[3][0]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center  font-inter">
                  {ipsData[3][1]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center  font-inter">
                  {ipsData[3][2]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center  font-inter">
                  {ipsData[3][3]}
                </h2>
                <h2
                  className={
                    ipsData[3][4][0] === "Y"
                      ? "text-sm text-center text-[#FF0000]  font-inter"
                      : "text-sm  text-center text-[#3D3D3D] font-inter"
                  }
                >
                  {ipsData[3][4]}
                </h2>
              </div>
              <div className="bg-[#FFF] grid grid-cols-5 gap-[16px] p-[8px] w-full border-b-[1px] border-b-[E5E5E5]">
                <h2 className="text-sm mb-0 text-[#3D3D3D] font-inter">
                  {ipsData[4][0]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[4][1]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[4][2]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[4][3]}
                </h2>
                <h2
                  className={
                    ipsData[4][4][0] === "Y"
                      ? "text-sm text-center text-[#FF0000]  font-inter"
                      : "text-sm  text-center text-[#3D3D3D] font-inter"
                  }
                >
                  {ipsData[4][4]}
                </h2>
              </div>
              <div className=" p-[8px] w-full border-b-[1px] border-b-[E5E5E5]">
                <h2 className="font-semibold text-sm text-[#3D3D3D] font-inter">
                  {ipsData[5][0]}
                </h2>
              </div>
              <div className="bg-[#FFF] grid grid-cols-5 gap-[16px] p-[8px] w-full border-b-[1px] border-b-[E5E5E5]">
                <h2 className="text-sm mb-0 text-[#3D3D3D] font-inter">
                  {ipsData[6][0]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[6][1]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[6][2]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[6][3]}
                </h2>
                <h2
                  className={
                    ipsData[6][4][0] === "Y"
                      ? "text-sm text-center text-[#FF0000]  font-inter"
                      : "text-sm  text-center text-[#3D3D3D] font-inter"
                  }
                >
                  {ipsData[6][4]}
                </h2>
              </div>
              <div className="bg-[#FFF] grid grid-cols-5 gap-[16px] p-[8px] w-full border-b-[1px] border-b-[E5E5E5]">
                <h2 className="text-sm mb-0 text-[#3D3D3D] font-inter">
                  {ipsData[7][0]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[7][1]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[7][2]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[7][3]}
                </h2>
                <h2
                  className={
                    ipsData[7][4][0] === "Y"
                      ? "text-sm text-center text-[#FF0000]  font-inter"
                      : "text-sm  text-center text-[#3D3D3D] font-inter"
                  }
                >
                  {ipsData[7][4]}
                </h2>
              </div>
              <div className=" p-[8px] w-full border-b-[1px] border-b-[E5E5E5]">
                <h2 className="font-semibold text-sm text-[#3D3D3D] font-inter">
                  {ipsData[8][0]}
                </h2>
              </div>
              <div className="bg-[#FFF] grid grid-cols-5 gap-[16px] p-[8px] w-full border-b-[1px] border-b-[E5E5E5]">
                <h2 className="text-sm mb-0 text-[#3D3D3D] font-inter">
                  {ipsData[9][0]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[9][1]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[9][2]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[9][3]}
                </h2>
                <h2
                  className={
                    ipsData[9][4][0] === "Y"
                      ? "text-sm text-center text-[#FF0000]  font-inter"
                      : "text-sm  text-center text-[#3D3D3D] font-inter"
                  }
                >
                  {ipsData[9][4]}
                </h2>
              </div>
              <div className=" p-[8px] w-full border-b-[1px] border-b-[E5E5E5]">
                <h2 className="font-semibold text-sm text-[#3D3D3D] font-inter">
                  {ipsData[10][0]}
                </h2>
              </div>
              <div className="bg-[#FFF] grid grid-cols-5 gap-[16px] p-[8px] w-full border-b-[1px] border-b-[E5E5E5]">
                <h2 className="text-sm mb-0 text-[#3D3D3D] font-inter">
                  {ipsData[11][0]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[11][1]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[11][2]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[11][3]}
                </h2>
                <h2
                  className={
                    ipsData[11][4][0] === "Y"
                      ? "text-sm text-center text-[#FF0000]  font-inter"
                      : "text-sm  text-center text-[#3D3D3D] font-inter"
                  }
                >
                  {ipsData[11][4]}
                </h2>
              </div>
              <div className="bg-[#FFF] grid grid-cols-5 gap-[16px] p-[8px] w-full border-b-[1px] border-b-[E5E5E5]">
                <h2 className="text-sm mb-0 text-[#3D3D3D] font-inter">
                  {ipsData[12][0]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[12][1]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[12][2]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[12][3]}
                </h2>
                <h2
                  className={
                    ipsData[12][4][0] === "Y"
                      ? "text-sm text-center text-[#FF0000]  font-inter"
                      : "text-sm  text-center text-[#3D3D3D] font-inter"
                  }
                >
                  {ipsData[12][4]}
                </h2>
              </div>
              <div className="bg-[#FFF] grid grid-cols-5 gap-[16px] p-[8px] w-full">
                <h2 className="text-sm mb-0 text-[#3D3D3D] font-inter">
                  {ipsData[13][0]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[13][1]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[13][2]}
                </h2>
                <h2 className="text-sm mb-0 text-[#3D3D3D] text-center font-inter">
                  {ipsData[13][3]}
                </h2>
                <h2
                  className={
                    ipsData[13][4][0] === "Y"
                      ? "text-sm text-center text-[#FF0000]  font-inter"
                      : "text-sm  text-center text-[#3D3D3D] font-inter"
                  }
                >
                  {ipsData[13][4]}
                </h2>
              </div>
            </div>
          </div>
        ) : (
          <div className=" w-full h-[335px] flex justify-center items-center opacity-[0.9] client-dashboard-table">
            <Bars color="#1982F8" />
          </div>
        )}
      </div>
    </>
  );
}
