import { GET_SETTINGS_RMINFO } from "../actions/type";
const initialState = {
  revenue: {},
  aum_target: {},
};
export default function GetRminfoReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SETTINGS_RMINFO:
      return action.payload;
    default:
      return state;
  }
}
