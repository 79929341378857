import React from "react";
import { whatsGood } from "../onePagerConsts";
import Ani1 from "../../../assets/img/ani1.svg";
import "../../../static/css/existing/dashboard_styles.css";

export default function whatsGoodComponent() {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {Object.entries(whatsGood.data)
          .slice(0, -1)
          .map(([key, value]) => (
            <div
              style={{
                padding: "1rem",
                backgroundColor: "#fff",
                borderRadius: "6px",
                boxShadow:
                  " 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(0, 0, 0, 0.05)",
                minheight: "274px",
                width: "49.2%",
              }}
            >
              <h5 className="mb-4 onepager-small-heading">{value["title"]}</h5>
              <p className="onepager-description-text mt-1 ">{value["text"]}</p>
              <p
                className="mb-2 onepager-description-text"
                style={{ color: "#0000F5" }}
              >
                Learn more
              </p>
            </div>
          ))}
      </div>
      <div
        style={{
          backgroundColor: "#EF5DA8",
          color: "#fff",
          padding: "1rem",

          borderRadius: "6px",
          boxShadow:
            " 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(0, 0, 0, 0.05)",
          minheight: "274px",
          marginTop: "1.5rem",
          display: "flex",
        }}
      >
        <div>
          <img src={Ani1} alt="err" />
        </div>
        <div style={{ marginLeft: "1rem" }}>
          <h5 className="onepager-small-heading">
            {whatsGood.data[whatsGood.data.length - 1]["title"]}
          </h5>
          <p className="onepager-description-text mt-1 ">
            {whatsGood.data[whatsGood.data.length - 1]["text"]}
          </p>
        </div>
      </div>
    </>
  );
}
