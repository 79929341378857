import { UPDATE_RESEARCH_INVESTMENT_POLICY_MODEL_DATA } from "../actions/type";
const initialState = {
  equity: {
    target: 0,
    flexibility: 0,
    min: 0,
    max: 0,
  },
  debt: {
    target: 0,
    flexibility: 0,
    min: 0,
    max: 0,
  },
  alternates: {
    target: 0,
    flexibility: 0,
    min: 0,
    max: 0,
  },
  large_cap: {
    target: 0,
    flexibility: 0,
    min: 0,
    max: 0,
  },
  mid_cap: {
    target: 0,
    flexibility: 0,
    min: 0,
    max: 0,
  },
  small_cap: {
    target: 0,
    flexibility: 0,
    min: 0,
    max: 0,
  },
  duration: {
    target: 0,
    flexibility: 0,
    min: 0,
    max: 0,
  },
  credit_risk: {
    target: 0,
    flexibility: 0,
    min: 0,
    max: 0,
  },
  alternates_low_vol: {
    target: 0,
    flexibility: 0,
    min: 0,
    max: 0,
  },
};
export default function UpdateResearchIpsData(state = initialState, action) {
  switch (action.type) {
    case UPDATE_RESEARCH_INVESTMENT_POLICY_MODEL_DATA:
      return action.payload;
    default:
      return state;
  }
}
