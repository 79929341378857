import { GET_SAVED_MARKET_FAQS } from "../actions/type";
const initialState = [
  {
    id: "378a09bb-36db-499f-871f-fd519c3f1695",
    question: "",
    answer: "",
    published_date: "",
    module: "",
    section: "",
    geography: "",
    asset: "",
    product_type: "",
    product: "",
    tags: [],
    trending: true,
    saved_faq: true,
  },
];

export default function GetSavedFaqs(state = initialState, action) {
  switch (action.type) {
    case GET_SAVED_MARKET_FAQS:
      return action.payload;
    default:
      return state;
  }
}
