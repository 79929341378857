import React from "react";
import InvestedgeModal from "../../components/modal/InvestedgeModal";
import { IoIosClose } from "react-icons/io";
export default function AlgorithmModel({ state, onClose, heading, points }) {
  return (
    <InvestedgeModal
      aria-labelledby="Previous History Modal"
      aria-describedby="Previous History Modal"
      sx={{
        marginRight: "auto",
        marginLeft: "auto",
        padding: "1rem",
      }}
      open={state}
      handleclose={onClose}
    >
      <div
        className="flex justify-between border-0 pl-0"
        style={{ paddingTop: "0" }}
      >
        <h5 className="card-heading">{heading}</h5>
        <IoIosClose size={30} onClick={onClose} />
      </div>
      <ul className="text-sm mt-8" type="1">
        {points.map((point, ind) => (
          <li key={ind} className="mt-2">
            {ind + 1 + ". " + point}
          </li>
        ))}
      </ul>
      {heading === "Portfolio Construction Algorithm Logic" && (
        <p className="text-sm text-primary-300 mt-8">
          **Not applicable for Model Portfolio driven portfolio construction
        </p>
      )}
    </InvestedgeModal>
  );
}
