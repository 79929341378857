import React, { useState, useRef } from "react";
import TooltipIcon from "../../../../assets/img/tooltip_icon.svg";
import axios from "axios";
import urls from "../../../../urls";
import InvestedgePopper from "../../../../components/popper/InvestedgePopper";
import Cross from "../../../../assets/img/standardcross.svg";
import { Bars } from "react-loader-spinner";
export default function PortfolioFourthStepRow({
  client,
  handleCross,
  handleWeightChange,
  portfolioValue,
}) {
  const [anchorElTooltip, setAnchorElTooltip] = useState(null);
  const [performanceData, setPerformanceData] = useState({ loading: true });
  const tooltipRef = useRef(null);
  const parsePortfolio = (portfolio) => {
    if (portfolio / 1000 < 100)
      return `${parseFloat(portfolio / 1000).toFixed(1)} K`;
    if (portfolio / 100000 < 100)
      return `${parseFloat(portfolio / 100000).toFixed(1)} L`;
    return `${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  };
  return (
    <tr
      className="h-[40px] border-b border-primarytablebottomClr"
      key={client.product_id}
    >
      <td
        className="dashboard-body-second-card-table-data !text-xs flex justify-between"
        style={{
          paddingLeft: "1rem",
          textAlign: "left",
        }}
      >
        {client.name}
        <img
          src={TooltipIcon}
          alt="tooltip"
          className="w-[16px] h-[16px] ml-2"
          ref={tooltipRef}
          onMouseEnter={() => {
            setAnchorElTooltip(tooltipRef.current);
            axios
              .get(urls.productPerformanceURL, {
                params: { product_id: client.product_id },
              })
              .then((response) => {
                setPerformanceData(response.data.performance);
              })
              .catch(() => {
                setPerformanceData({ loading: true });
              });
          }}
          onMouseLeave={() => {
            setAnchorElTooltip(null);
          }}
        />
        <InvestedgePopper
          open={Boolean(anchorElTooltip)}
          anchorEl={anchorElTooltip}
          placement="right-start"
          className="z-[100]"
          offset={[0, 20]}
          arrow="true"
        >
          <div className="bg-[#fff] p-[16px] w-[250px]">
            {performanceData.loading ? (
              <div className="w-full min-h-[100px] flex flex-col justify-center items-center opacity-[0.9]">
                <Bars color="#1982F8" height="40" width="40" />
              </div>
            ) : (
              <>
                <p className="text-xs text-[#a3a3a3]">
                  1 month return:{" "}
                  <span className="text-neutralBlack">
                    {client.asset_class === "Debt"
                      ? performanceData.data["1m_ann"]
                        ? `${(performanceData.data["1m_ann"] * 100).toFixed(
                            1
                          )}%`
                        : "NA"
                      : performanceData.data["1m_abs"]
                      ? `${(performanceData.data["1m_abs"] * 100).toFixed(1)}%`
                      : "NA"}
                  </span>
                </p>
                <p className="text-xs text-[#a3a3a3] mt-4">
                  6 months return:{" "}
                  <span className="text-neutralBlack">
                    {client.asset_class === "Debt"
                      ? performanceData.data["6m_ann"]
                        ? `${(performanceData.data["6m_ann"] * 100).toFixed(
                            1
                          )}%`
                        : "NA"
                      : performanceData.data["6m_abs"]
                      ? `${(performanceData.data["6m_abs"] * 100).toFixed(1)}%`
                      : "NA"}
                  </span>
                </p>
                <p className="text-xs text-[#a3a3a3] mt-4">
                  1 year return:{" "}
                  <span className="text-neutralBlack">
                    {performanceData.data["1y_cagr"]
                      ? `${(performanceData.data["1y_cagr"] * 100).toFixed(1)}%`
                      : "NA"}
                  </span>
                </p>
                <p className="text-xs text-[#a3a3a3] mt-4">
                  3 year CAGR:{" "}
                  <span className="text-neutralBlack">
                    {performanceData.data["3y_cagr"]
                      ? `${(performanceData.data["3y_cagr"] * 100).toFixed(1)}%`
                      : "NA"}
                  </span>
                </p>
                <p className="text-xs text-[#a3a3a3] mt-4">
                  5 year CAGR:{" "}
                  <span className="text-neutralBlack">
                    {performanceData.data["5y_cagr"]
                      ? `${(performanceData.data["5y_cagr"] * 100).toFixed(1)}%`
                      : "NA"}
                  </span>
                </p>
                <p className="text-xs text-[#a3a3a3] mt-4">
                  Data as of:{" "}
                  <span className="text-neutralBlack">
                    {performanceData["as_on"] ? performanceData["as_on"] : "NA"}
                  </span>
                </p>
              </>
            )}
          </div>
        </InvestedgePopper>
      </td>

      <td className="dashboard-body-second-card-table-data !text-xs">
        {`${client.asset_type} - ${client.product_type}`}
      </td>
      <td className="dashboard-body-second-card-table-data !text-xs">
        {parsePortfolio((client.individual_asset_pct * portfolioValue) / 100)}
      </td>
      <td className="dashboard-body-second-card-table-data !text-xs">
        <input
          type="text"
          value={client.individual_asset_pct}
          style={{
            width: "100%",
            height: "40px",
            boxShadow: "none",
            textAlign: "center",
            fontFamily: "Inter, sans-serif",
            fontSize: "14px",
            background: "#F3F3F3",
            border: "none",
          }}
          onChange={(e) => handleWeightChange(e, client.product_id)}
        />
      </td>
      <td>
        <img
          src={Cross}
          alt="err"
          style={{ width: "14px", objectFit: "contain" }}
          onClick={() => handleCross(client.product_id)}
        />
      </td>
    </tr>
  );
}
