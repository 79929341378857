import React from "react";
export default function CalculatorBanner({ heading, description, image }) {
  return (
    <div className="portfolio-proposal-body-card pr-11 min-h-auto h-[100%] flex justify-between items-center border-none shadow-none">
      <div className="w-[60%]">
        <h3 className="headingThree mb-[6px]">{heading}</h3>
        <p className="font-inter text-sm leading-[20px] text-[#6a6a76] mb-0">
          {description}
        </p>
      </div>
      <img src={image} alt="err" className="h-[100%]" />
    </div>
  );
}
