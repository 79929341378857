import { SUBMIT_CONTACT } from "../actions/type";
const initialState = null;
export default function SubmitContactReducer(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_CONTACT:
      return action.payload;
    default:
      return state;
  }
}
