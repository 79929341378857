import { GET_CLIENT_INPUT_TEMPLATE } from "../actions/type";
const initialState = "";
export default function GetClientInputTemplate(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENT_INPUT_TEMPLATE:
      return action.payload;
    default:
      return state;
  }
}
