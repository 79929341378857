import React, { useEffect, useState } from "react";
import Actionable from "./Actionable";
import BuyList from "./BuyList";
import SellList from "./SellList";
import {
  PostEventLoggingData,
  ResetReviewPortfolioActionable,
} from "../../../../actions/actions";
import { useDispatch, useSelector } from "react-redux";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
export default function PortfolioFourthStep({
  handleBack,
  handleNext,
  formValue,
  portfolioProducts,
  setPortfolioProducts,
  portfolio,
  setPortfolio,
  portfolioActions,
  setPortfolioActions,
  clientId,
}) {
  const [listtype, setListtype] = useState("actionable");
  const dispatch = useDispatch();
  var portfolioValue = useSelector(
    (state) => state.portfolioReviewGetActionable.portfolio_value
  );
  const preferenceData = useSelector(
    (state) => state.portfolioReviewGetActionable
  );
  const analytics = useSelector(
    (state) => state.portfolioReviewGetFinalAnalytics
  );
  var portfolioSum = 0;

  const handleSections = () => {
    switch (listtype) {
      case "actionable":
        return (
          <Actionable
            formValue={formValue}
            portfolioProducts={portfolioProducts}
            setPortfolioProducts={setPortfolioProducts}
            portfolio={portfolio}
            setPortfolio={setPortfolio}
            portfolioActions={portfolioActions}
            setPortfolioActions={setPortfolioActions}
            clientId={clientId}
            errorDiff={errorDiff}
            setErrorDiff={setErrorDiff}
          />
        );

      case "buylist":
        return (
          <BuyList
            portfolioProducts={portfolioProducts}
            portfolioValue={portfolioValue}
          />
        );

      case "selllist":
        return (
          <SellList
            portfolioProducts={portfolioProducts}
            portfolioValue={portfolioValue}
          />
        );

      default:
        console.log(listtype);
    }
  };
  useEffect(() => {
    dispatch(
      PostEventLoggingData({
        module_name: "Portfolio Review",
        event_type: "Information",
        url: window.location.href,
        note: `Checked out ${listtype}`,
      })
    ); // eslint-disable-next-line
  }, [listtype]);
  portfolioProducts.forEach((value) => {
    portfolioSum += value.proposed_market_value
      ? parseFloat(value.proposed_market_value)
      : 0;
  });
  const [errorDiff, setErrorDiff] = useState(0);
  return (
    <>
      <div className="px-8 rounded-[8px] w-full bg-white py-8 mt-[16px]">
        <div className="flex justify-between w-full">
          <h2 className="card-heading">Portfolio Observations</h2>
          <div className="flex">
            <InvestedgeButton
              onClick={() => {
                dispatch(
                  PostEventLoggingData({
                    module_name: "Portfolio Review",
                    event_type: "Prev Button",
                    url: window.location.href,
                    note: `Fourth screen`,
                  })
                );
                handleBack();
              }}
            >
              Prev
            </InvestedgeButton>
            <InvestedgeButton
              className="ml-4"
              disabled={
                Math.abs(portfolioValue - portfolioSum) > 10 ||
                preferenceData.loading ||
                analytics.loading
              }
              onClick={() => {
                // if (horizon) {
                //   setFormValue({
                //     ...formValue,
                //     risk_score: riskscore,
                //     investment_horizon: parseInt(horizon),
                //     type_of_scheme: scheme,
                //   });
                //   handleNext();
                // } else setError("This field is required");

                Math.abs(portfolioValue - portfolioSum) <= 10 &&
                  !preferenceData.loading &&
                  !analytics.loading &&
                  handleNext();
                Math.abs(portfolioValue - portfolioSum) <= 10 &&
                  !preferenceData.loading &&
                  !analytics.loading &&
                  dispatch(ResetReviewPortfolioActionable(portfolioValue));
                Math.abs(portfolioValue - portfolioSum) <= 10 &&
                  !preferenceData.loading &&
                  !analytics.loading &&
                  dispatch(
                    PostEventLoggingData({
                      module_name: "Portfolio Review",
                      event_type: "Next Button",
                      url: window.location.href,
                      note: `Fourth screen`,
                    })
                  );
              }}
            >
              Next
            </InvestedgeButton>
          </div>
        </div>
        {/* <h2 className="font-medium text-sm mb-[16px]">IPS</h2>
        <h2 className="font-medium text-sm mb-[16px]">
          Overweight / underweight comments​ Underperforming schemes comments ​
        </h2>
        <h2 className="font-medium text-sm">
          Taking the above in account and basis your constraints and
          requirements, Following is the actionable plan​
        </h2> */}
      </div>
      <div className="rounded-[8px] w-full bg-white pt-[16px] mt-[16px] flex">
        <h2
          onClick={() => setListtype("actionable")}
          className={
            listtype === "actionable"
              ? "text-[#3D3D3D] font-medium text-lg ml-[24px] leading-[40px] border-b-2 border-b-[#1982f8] cursor-pointer mb-0 font-inter"
              : "text-[#6A6A76] text-lg ml-[24px] leading-[40px] cursor-pointer font-inter"
          }
        >
          Actionables
        </h2>
        <h2
          onClick={() => setListtype("buylist")}
          className={
            listtype === "buylist"
              ? "text-[#3D3D3D] font-medium text-lg ml-[48px] leading-[40px] border-b-2 border-b-[#1982f8] cursor-pointer mb-0"
              : "text-[#6A6A76] text-lg ml-[48px] leading-[40px] cursor-pointer"
          }
        >
          Buy List
        </h2>
        <h2
          onClick={() => setListtype("selllist")}
          className={
            listtype === "selllist"
              ? "text-[#3D3D3D] font-medium text-lg ml-[48px] leading-[40px] border-b-2 border-b-[#1982f8] cursor-pointer mb-0"
              : "text-[#6A6A76] text-lg ml-[48px] leading-[40px] cursor-pointer"
          }
        >
          Sell List
        </h2>
      </div>
      {handleSections(listtype)}
    </>
  );
}
