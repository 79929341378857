import React from "react";
import BarsChart from "../../../../components/charts/BarsChart";
import ProgressBar from "./ProgressBar";
export default function EquitySection({ analytics }) {
  const bgGradients = [
    "linear-gradient(223.78deg, #09C3E7 0%, #7669F0 106.87%)",
    "linear-gradient(225deg, #9C2979 0%, #F15E75 100%)",
    "linear-gradient(108.81deg, #FF9F38 -27.65%, #FE5C44 125%)",
    "linear-gradient(108.81deg, #FFD166 -27.65%, #C26F0D 125%)",
  ];
  const keys = [];
  const values = [];
  let maxStock = 0;
  for (const property in analytics.top5_stocks) {
    keys.push(property);
    values.push((analytics.top5_stocks[property] * 100).toFixed(1));
    maxStock = Math.max(maxStock, analytics.top5_stocks[property] * 100);
  }
  const sectorkeys = [];
  const sectorvalues1 = [];
  const sectorvalues2 = [];
  for (const property in analytics.top5_sector) {
    sectorkeys.push(property.slice(0, 11));
    sectorvalues1.push((analytics.top5_sector[property][0] * 100).toFixed(1));
    sectorvalues2.push((analytics.top5_sector[property][1] * 100).toFixed(1));
  }
  // const displayingHorizontalChartLabels = (value) => {
  //   return value.length > 4 ? `${value.slice(0, 4)}..` : value.slice(0, 4);
  // };
  const currentmarketData = {
    labels: ["Large Cap", "Mid Cap", "Small Cap"],

    datasets: [
      {
        label: "Equity Portfolio",
        data: [
          analytics.mkt_cap_allocation_before["Large Cap"] &&
            (analytics.mkt_cap_allocation_before["Large Cap"][0] * 100).toFixed(
              1
            ),
          analytics.mkt_cap_allocation_before["Mid Cap"] &&
            (analytics.mkt_cap_allocation_before["Mid Cap"][0] * 100).toFixed(
              1
            ),
          analytics.mkt_cap_allocation_before["Small Cap"] &&
            (analytics.mkt_cap_allocation_before["Small Cap"][0] * 100).toFixed(
              1
            ),
        ],
        backgroundColor: "#119D0E",
        borderColor: "#119D0E",
      },
      {
        label: "NSE 500",
        data: [
          analytics.mkt_cap_allocation_before["Large Cap"] &&
            (analytics.mkt_cap_allocation_before["Large Cap"][1] * 100).toFixed(
              1
            ),
          analytics.mkt_cap_allocation_before["Mid Cap"] &&
            (analytics.mkt_cap_allocation_before["Mid Cap"][1] * 100).toFixed(
              1
            ),
          analytics.mkt_cap_allocation_before["Small Cap"] &&
            (analytics.mkt_cap_allocation_before["Small Cap"][1] * 100).toFixed(
              1
            ),
        ],
        backgroundColor: "#5675E5",
        borderColor: "#5675E5",
      },
    ],
  };
  const proposedmarketData = {
    labels: ["Large Cap", "Mid Cap", "Small Cap"],

    datasets: [
      {
        label: "Equity Portfolio",
        data: [
          analytics.mkt_cap_allocation_after["Large Cap"] &&
            (analytics.mkt_cap_allocation_after["Large Cap"][0] * 100).toFixed(
              1
            ),
          analytics.mkt_cap_allocation_after["Mid Cap"] &&
            (analytics.mkt_cap_allocation_after["Mid Cap"][0] * 100).toFixed(1),
          analytics.mkt_cap_allocation_after["Small Cap"] &&
            (analytics.mkt_cap_allocation_after["Small Cap"][0] * 100).toFixed(
              1
            ),
        ],
        backgroundColor: "#119D0E",
        borderColor: "#119D0E",
      },
      {
        label: "NSE 500",
        data: [
          analytics.mkt_cap_allocation_after["Large Cap"] &&
            (analytics.mkt_cap_allocation_after["Large Cap"][1] * 100).toFixed(
              1
            ),
          analytics.mkt_cap_allocation_after["Mid Cap"] &&
            (analytics.mkt_cap_allocation_after["Mid Cap"][1] * 100).toFixed(1),
          analytics.mkt_cap_allocation_after["Small Cap"] &&
            (analytics.mkt_cap_allocation_after["Small Cap"][1] * 100).toFixed(
              1
            ),
        ],
        backgroundColor: "#5675E5",
        borderColor: "#5675E5",
      },
    ],
  };
  // const sectorsData = {
  //   labels: sectorkeys,
  //   datasets: [
  //     {
  //       label: "Equity Portfolio",
  //       data: sectorvalues1,
  //       backgroundColor: "#ED6D85",
  //       borderColor: "#ED6D85",
  //     },
  //     {
  //       label: "NSE 500",
  //       data: sectorvalues2,
  //       backgroundColor: "#56A1E5",
  //       borderColor: "#56A1E5",
  //     },
  //   ],
  // };
  const barOptions = {
    maintainAspectRatio: false,
    cornerRadius: 20,
    legend: {
      display: false,
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 30,
        bottom: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
    plugins: {
      labels: {
        // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
        render: "value",
        fontColor: "#000",
        position: "default",
        outsidePadding: 14,
        textMargin: 3,
      },
      datalabels: {
        display: false,
      },
    },
  };
  const currentSectorsData = [];
  const proposedSectorsData = [];
  const currentStocksData = [];
  const proposedStocksData = [];
  for (const property in analytics.top5_stocks_before)
    currentStocksData.push({
      label: property,
      value: (analytics.top5_stocks_before[property] * 100).toFixed(1),
    });
  for (const property in analytics.top5_stocks_after)
    proposedStocksData.push({
      label: property,
      value: (analytics.top5_stocks_after[property] * 100).toFixed(1),
    });
  for (const property in analytics.top5_sector_before)
    currentSectorsData.push({
      label: property,
      value: (analytics.top5_sector_before[property] * 100).toFixed(1),
    });
  for (const property in analytics.top5_sector_after)
    proposedSectorsData.push({
      label: property,
      value: (analytics.top5_sector_after[property] * 100).toFixed(1),
    });
  return (
    <>
      <div className="pt-[16px]">
        <div className="flex justify-between">
          <h2 className="font-medium text-lg font-inter">Market Cap</h2>
          <div className="flex">
            <div className="flex items-center ml-[16px]">
              <div className="bg-[#119D0E] rounded w-[14px] h-[14px]" />
              <h2 className="text-xs ml-[8px]">Equity Portfolio</h2>
            </div>
            <div className="flex items-center ml-[16px]">
              <div className="bg-[#5675E5] rounded w-[14px] h-[14px]" />
              <h2 className="text-xs ml-[8px]">NSE 500</h2>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between mt-[16px]">
          <div className="mt-[1rem] w-[49%] bg-[#fafafa] min-h-[250px] p-[1rem]">
            <h4 className=" font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack">
              Portfolio 1
            </h4>
            <BarsChart
              data={currentmarketData}
              options={barOptions}
              width={200}
              height={200}
            />
          </div>
          <div className="mt-[1rem] w-[49%] bg-[#fafafa] min-h-[250px] p-[1rem]">
            <h4 className=" font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack">
              Portfolio 2
            </h4>
            <BarsChart
              data={proposedmarketData}
              options={barOptions}
              width={200}
              height={200}
            />
          </div>
        </div>
      </div>
      <h2 className="font-medium text-lg mt-[16px]">Top 5 Stocks</h2>
      <div className="w-full flex justify-between mt-[16px]">
        <div className=" w-[49%] bg-[#fafafa] min-h-[250px] p-[1rem]">
          <h4 className=" font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack">
            Portfolio 1
          </h4>
          {currentStocksData.map((product, index) => (
            <ProgressBar
              key={index}
              bg={bgGradients[index % bgGradients.length]}
              progress={product.value}
              label={product.label}
            />
          ))}
        </div>
        <div className=" w-[49%] bg-[#fafafa] min-h-[250px] p-[1rem]">
          <h4 className=" font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack">
            Portfolio 2
          </h4>
          {proposedStocksData.map((product, index) => (
            <ProgressBar
              key={index}
              bg={bgGradients[index % bgGradients.length]}
              progress={product.value}
              label={product.label}
            />
          ))}
        </div>
      </div>
      <h2 className="font-medium text-lg mt-[16px]">Top 5 Sectors</h2>
      <div className="w-full flex justify-between mt-[16px]">
        <div className=" w-[49%] bg-[#fafafa] min-h-[250px] p-[1rem]">
          <h4 className="font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack">
            Portfolio 1
          </h4>
          {currentSectorsData.map((product, index) => (
            <ProgressBar
              key={index}
              bg={bgGradients[index % bgGradients.length]}
              progress={product.value}
              label={product.label}
            />
          ))}
        </div>
        <div className=" w-[49%] bg-[#fafafa] min-h-[250px] p-[1rem]">
          <h4 className="font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack">
            Portfolio 2
          </h4>
          {proposedSectorsData.map((product, index) => (
            <ProgressBar
              key={index}
              bg={bgGradients[index % bgGradients.length]}
              progress={product.value}
              label={product.label}
            />
          ))}
        </div>
      </div>
    </>
  );
}
