import React, { useRef } from "react";
import Hourglass from "../../../../assets/img/wait.gif";
import Women from "../../../../assets/img/modal_chr.svg";
import { useSelector, useDispatch } from "react-redux";
import { PostEventLoggingData } from "../../../../actions/actions";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";

export default function PortfolioSixthStep({ handleBack }) {
  const dispatch = useDispatch();
  const pptRef = useRef(null);
  const excelRef = useRef(null);
  const fileUrls = useSelector((state) => state.sippptAPI);

  const downloadexcel = async () => await excelRef.current.click();
  const downloadppt = async () => await pptRef.current.click();

  return (
    <div className="px-8 rounded-[8px] w-full bg-white pb-8 mt-[16px] h-full">
      <div className="flex justify-center h-full items-center">
        <div className=" w-[94%] pt-[3rem] flex justify-center min-h-[calc(100vh_-_300px)]">
          <div className="portfolio-stepper-first-form-container w-[80%] h-full rounded-[10px] p-[2rem] flex flex-col justify-center items-center">
            <img
              alt="err"
              src={fileUrls.loading ? Hourglass : Women}
              className="min-h-[250px] object-contain"
            />
            <h3 className="font-inter text-[24px] font-medium leading-[30px] tracking-[0.1133817583322525px] mb-[1rem] text-center">
              {fileUrls.loading
                ? "Sometimes, great things take time."
                : "Happy Investment. Thanks for downloading the Proposal"}
            </h3>
            {fileUrls.loading ? (
              <h3 className="portfolio-stepper-sixth-form-sub-status">
                Your request is under process. Please wait for sometime.
              </h3>
            ) : (
              ""
            )}
            {!fileUrls.loading && (
              <div className="flex w-[85%] justify-between items-center">
                <div className="flex">
                  <InvestedgeButton
                    onClick={() => {
                      dispatch(
                        PostEventLoggingData({
                          module_name: "Portfolio Insights",
                          event_type: "Repeat",
                          url: window.location.href,
                          note: `Making another portfolio`,
                        })
                      );
                      window.location.reload();
                    }}
                  >
                    Make Another
                  </InvestedgeButton>
                  <InvestedgeButton
                    className="ml-4"
                    onClick={() => {
                      dispatch(
                        PostEventLoggingData({
                          module_name: "Portfolio Insights sixth screen",
                          event_type: "Prev",
                          url: window.location.href,
                          note: `Previous button`,
                        })
                      );
                      handleBack();
                    }}
                  >
                    Go Back
                  </InvestedgeButton>
                </div>
                <div className="flex">
                  {fileUrls.excel && (
                    <InvestedgeButton
                      onClick={() => {
                        dispatch(
                          PostEventLoggingData({
                            module_name: "Portfolio Insights",
                            event_type: "Download button",
                            url: window.location.href,
                            note: `Download excel`,
                          })
                        );
                        downloadexcel();
                      }}
                    >
                      Download Excel
                    </InvestedgeButton>
                  )}

                  <InvestedgeButton
                    className="ml-4"
                    onClick={() => {
                      dispatch(
                        PostEventLoggingData({
                          module_name: "Portfolio Insights",
                          event_type: "Download button",
                          url: window.location.href,
                          note: `Download ppt`,
                        })
                      );
                      downloadppt();
                    }}
                  >
                    Download PPT
                  </InvestedgeButton>
                </div>
              </div>
            )}
            <a href={fileUrls.ppt} ref={pptRef} className="hidden">
              File URL
            </a>
            <a href={fileUrls.excel} ref={excelRef} className="hidden">
              File URL
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
