import React, { useEffect } from "react";
import Add from "../../../../../assets/img/circle_over_plus_blue.svg";
import GoldRow from "./GoldRow";
import { useState } from "react";
import urls from "../../../../../urls";
import { useDispatch, useSelector } from "react-redux";
import {
  GetDashboardBenchmarkPrice,
  GetDashboardGoldPortfolio,
  GetDashboardPortfolioList,
  OpenSnackbar,
} from "../../../../../actions/actions";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import InvestedgeButton from "../../../../../components/buttons/InvestedgeButton";
export default function GoldScreen() {
  const data = useSelector((store) => store.dashboardGoldPortfolio);
  const benchmarkPrice = useSelector((store) => store.dashboardBenchmark);
  const [productarray, setProductarray] = useState(
    JSON.parse(JSON.stringify(data)).map((y) => {
      return {
        ...y,
        purchase_date: new Date(y.purchase_date),
        current_price_date: new Date(y.current_price_date),
      };
    })
  );
  const [patchProductArray, setPatchProductArray] = useState([]);
  const [postProductArray, setPostProductArray] = useState([]);
  const [submitActive, setSubmitActive] = useState(false);
  const dispatch = useDispatch();
  const productsData = ["Gold", "Silver"];
  const portfolioList = useSelector(
    (store) => store.dashboardPortfolioPriceList
  );
  const removeProductarray = (i) => {
    let newProductArray = [...productarray];
    newProductArray.splice(i, 1);
    setProductarray(newProductArray);
  };
  // const portfolioValue = useSelector(
  //   (store) => store.portfolioReviewGetSumary.portfolio_value
  // );
  // const parsePortfolio = (portfolio) => {
  //   if (portfolio / 1000 < 100)
  //     return `₹ ${parseFloat(portfolio / 1000).toFixed(1)} K`;
  //   if (portfolio / 100000 < 100)
  //     return `₹ ${parseFloat(portfolio / 100000).toFixed(1)} L`;
  //   return `₹ ${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  // };
  const [searchBar, setSearchBar] = useState(false);
  const [addedProduct, setAddedProduct] = useState("");
  const [deleteArray, setDeleteArray] = useState([]);
  useEffect(() => {
    dispatch(
      GetDashboardGoldPortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      GetDashboardBenchmarkPrice({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setProductarray(data);
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    if (
      deleteArray.length ||
      postProductArray.length ||
      patchProductArray.length
    )
      setSubmitActive(true);
    else setSubmitActive(false);
  }, [deleteArray, postProductArray, patchProductArray]);

  const handleSubmit = async () => {
    let newPatchList = patchProductArray.map((item) => {
      var dd = String(item.purchase_date.getDate()).padStart(2, "0");
      var mm = String(item.purchase_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.purchase_date.getFullYear();
      var dd1 = String(item.current_price_date.getDate()).padStart(2, "0");
      var mm1 = String(item.current_price_date.getMonth() + 1).padStart(2, "0");
      var yyyy1 = item.current_price_date.getFullYear();
      return {
        id: item.id,
        commodity_id: item.commodity_id,
        name: item.name,
        weight: parseFloat(item.weight),
        purchase_price: parseFloat(item.purchase_price),
        purchase_date: yyyy + "-" + mm + "-" + dd,
        current_price: parseFloat(item.current_price),
        current_price_date: yyyy1 + "-" + mm1 + "-" + dd1,
        user_id: localStorage.getItem("clientId"),
      };
    });
    let newPostList = postProductArray.map((item) => {
      var dd = String(item.purchase_date.getDate()).padStart(2, "0");
      var mm = String(item.purchase_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.purchase_date.getFullYear();
      var dd1 = String(item.current_price_date.getDate()).padStart(2, "0");
      var mm1 = String(item.current_price_date.getMonth() + 1).padStart(2, "0");
      var yyyy1 = item.current_price_date.getFullYear();
      return {
        commodity_id: item.commodity_id,
        name: item.name,
        weight: parseFloat(item.weight),
        purchase_price: parseFloat(item.purchase_price),
        purchase_date: yyyy + "-" + mm + "-" + dd,
        current_price: parseFloat(item.current_price),
        current_price_date: yyyy1 + "-" + mm1 + "-" + dd1,
        user_id: localStorage.getItem("clientId"),
      };
    });
    let newDeleteList = deleteArray.map((item) => {
      var dd = String(item.purchase_date.getDate()).padStart(2, "0");
      var mm = String(item.purchase_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.purchase_date.getFullYear();
      var dd1 = String(item.current_price_date.getDate()).padStart(2, "0");
      var mm1 = String(item.current_price_date.getMonth() + 1).padStart(2, "0");
      var yyyy1 = item.current_price_date.getFullYear();
      return {
        id: item.id,
        commodity_id: parseInt(item.commodity_id),
        name: item.name,
        weight: parseFloat(item.weight),
        purchase_price: parseFloat(item.purchase_price),
        purchase_date: yyyy + "-" + mm + "-" + dd,
        current_price: parseFloat(item.current_price),
        current_price_date: yyyy1 + "-" + mm1 + "-" + dd1,
        user_id: localStorage.getItem("clientId"),
      };
    });
    newDeleteList.length &&
      (await axios.post(urls.dashboardGoldPortfolioDeleteURL, newDeleteList));
    newPostList.length &&
      (await axios.post(urls.dashboardGoldPortfolioURL, newPostList));
    newPatchList.length &&
      (await axios.patch(urls.dashboardGoldPortfolioURL, newPatchList));
    setPatchProductArray([]);
    setPostProductArray([]);
    setDeleteArray([]);
    dispatch(
      GetDashboardPortfolioList({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      GetDashboardGoldPortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      OpenSnackbar({
        severity: "success",
        message: "Portfolio Updated Successfully",
      })
    );
  };
  return (
    <div className="w-full">
      <div className="font-medium font-inter text-[18px] text-neutralBlack">
        Gold/Silver - {portfolioList.total_gold_value}
      </div>
      <div className="flex justify-between w-[96%] mt-[4px] mb-0">
        <div className="flex justify-center items-center h-[56px]">
          <img src={Add} alt="err" />
          <p
            className="text-primary-200 text-[14px] font-medium mb-0 ml-[0.3rem]"
            onClick={
              () => setSearchBar(true)
              // let newProductArray = [...productarray];
              // newProductArray.unshift({ name: "New Mutual Fund", price: "0" });
              // setProductarray(newProductArray);
            }
          >
            Add Gold/Silver
          </p>
          {searchBar && (
            <FormControl
              size="small"
              sx={{
                width: "200px",
                marginLeft: "1rem",
                "& .MuiInputBase-inputSizeSmall": {
                  paddingTop: "10px",
                  paddingBottom: "10px",
                },
              }}
            >
              <InputLabel>
                <span className="font-inter font-normal text-sm">
                  Choose Commodity
                </span>
              </InputLabel>
              <Select
                label="Choose Bank"
                onChange={(e) => {
                  setSearchBar(false);
                  setAddedProduct(e.target.value.product_id);
                  let newAifarray = [...productarray];
                  newAifarray.unshift({
                    commodity_id: e.target.value,
                    name: e.target.value,
                    weight: parseFloat(0),
                    purchase_price: parseFloat(0),
                    purchase_date: new Date(),
                    current_price:
                      e.target.value[0] === "G"
                        ? benchmarkPrice.BM_65.nav
                        : benchmarkPrice.BM_199.nav,
                    current_price_date: new Date(),
                    user_id: localStorage.getItem("clientId"),
                  });
                  setPostProductArray([
                    ...postProductArray,
                    {
                      commodity_id: e.target.value,
                      name: e.target.value,
                      weight: parseFloat(0),
                      purchase_price: parseFloat(0),
                      purchase_date: new Date(),
                      current_price:
                        e.target.value[0] === "G"
                          ? benchmarkPrice.BM_65.nav
                          : benchmarkPrice.BM_199.nav,
                      current_price_date: new Date(),
                      user_id: localStorage.getItem("clientId"),
                    },
                  ]);
                  setProductarray(newAifarray);
                }}
                className="font-inter text-sm"
              >
                {productsData.map((option, ind) => (
                  <MenuItem value={option} id={ind} key={ind}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        {/* <p className="asset-handler-text" onClick={() => setProductarray([])}>
          Clear All
        </p> */}
      </div>
      <div className="update-portfolio-asset-row-container  h-[63vh]">
        {productarray.map((fund, index) => (
          <GoldRow
            key={fund.id}
            fund={fund}
            id={index}
            removeProductarray={removeProductarray}
            productarray={productarray}
            setProductarray={setProductarray}
            patchProductArray={patchProductArray}
            setPatchProductArray={setPatchProductArray}
            deleteArray={deleteArray}
            setDeleteArray={setDeleteArray}
            data={data}
            postProductArray={postProductArray}
            setPostProductArray={setPostProductArray}
            addedProduct={addedProduct}
          />
        ))}
      </div>
      <div className="flex w-full mt-[24px] pr-[24px] justify-end">
        <InvestedgeButton
          disabled={!submitActive}
          onClick={() => {
            if (
              patchProductArray.length ||
              postProductArray.length ||
              deleteArray.length
            ) {
              setSubmitActive(false);
              handleSubmit();
            }
          }}
        >
          Submit
        </InvestedgeButton>
      </div>
    </div>
  );
}
