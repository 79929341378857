import React from "react";
import { Link } from "react-router-dom";
import Logo1 from "../assets/img/logo_with_b.png";
import { HashLink } from "react-router-hash-link";
import "../assets/css/style.css";
// import "../assets/vendor/icofont/icofont.min.css";

function Header() {
  return (
    <>
      <header id="header" className="fixed-top">
        <nav className="navbar navbar-expand-lg -mt-1 ml-4 mr-5">
          <div className="navbar-brand" href="#">
            <div className="logo mr-auto ml-5 -mt-1">
              <Link to="/">
                <img
                  style={{
                    height: "39px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    objectFit: "contain",
                  }}
                  src={Logo1}
                  alt=""
                  className="img-fluid"
                />
              </Link>
            </div>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span
              className="navbar-toggler-icon"
              style={{ color: "#03009E" }}
            ></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto"></ul>
            <div className="form-inline my-2 my-lg-0">
              <Link to="/">
                <h3 className="text-sm font-semibold mr-4">Home</h3>
              </Link>
              <HashLink to="/#team">
                <h3 className="text-sm font-semibold mr-4">About</h3>
              </HashLink>

              <a
                href="https://peppermint-prose-e38.notion.site/BeyondIRR-is-Hiring-63983dd1646045949aeff63bee684422"
                className="text-sm font-semibold mr-4 mb-2"
              >
                Careers
              </a>
              <Link to="/login">
                <h3 className="text-sm font-semibold mr-4">Login</h3>
              </Link>
              <button
                className="btn btn-primary btn-get-started   scrollto -mt-2"
                style={{
                  backgroundColor: "#03009E",
                  color: "#fff",
                  fontSize: "13px",
                  fontWeight: "500",
                  paddingLeft: "2vw",
                  paddingRight: "2vw",
                  paddingTop: "0.9vw",
                  paddingBottom: "0.7vw",
                  borderRadius: "5px",
                  fontFamily: "Inter",
                }}
                data-toggle="modal"
                data-target="#exampleModal"
              >
                JOIN THE WAITLIST
              </button>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
