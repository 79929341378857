import React, { useState, useEffect } from "react";
import AfterloginApp from "../AfterloginApp";
import "../../static/css/existing/dashboard_styles.css";
import { useDispatch, useSelector } from "react-redux";
import PieChart from "./PieChart";
import Plus from "../../assets/img/add_new_icon.svg";
import ProductCard from "./ProductCard";
import BarChart from "./BarChart";
import TooltipIcon from "../../assets/img/tooltip_icon.svg";
import { Tooltip, Zoom } from "@mui/material";
import {
  GetAnnualTarget,
  GetAumBreakup,
  GetAumBreakupProduct,
  GetAumBreakupAsset,
  GetAumBreakupClient,
  GetDashboardYourClients,
  GetDashboardAllClients,
  PostEventLoggingData,
} from "../../actions/actions";
import { Link, useHistory } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import BdayModal from "./BdayModal";
import { BsArrowUpRightSquare } from "react-icons/bs";
import InvestedgeToggleButtonGroup from "../../components/toggle/InvestedgeToggle";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import InvestedgeTabs from "../../components/tabs/InvestedgeTabs";
import InvestedgePagination from "../../components/pagination/InvestedgePagination";
import { GraphColorPalette } from "../../components/colors/graphColorPalette";
import InvestedgeSearchField from "../../components/searchfield/InvestedgeSearchField";
import useMediaQuery from "@mui/material/useMediaQuery";
export default function Dashboard() {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    // AUM Dashboard Dispatch
    dispatch(GetAnnualTarget());
    dispatch(GetAumBreakup());
    dispatch(GetAumBreakupProduct());
    dispatch(GetAumBreakupAsset());
    dispatch(GetAumBreakupClient());
    dispatch(GetDashboardYourClients()); // eslint-disable-next-line
  }, []);

  const annualTarget = useSelector((state) => state.annualTarget);
  const aumBreakup = useSelector((state) => state.aumBreakup);
  const aumBreakupProduct = useSelector((state) =>
    state.aumBreakupProduct.sort((a, b) => b.percentage - a.percentage)
  );
  const aumBreakupAsset = useSelector((state) =>
    Array.isArray(state.aumBreakupAsset)
      ? state.aumBreakupAsset.sort((a, b) => b.percentage - a.percentage)
      : []
  );
  const aumBreakupClient = useSelector((state) => state.aumBreakupClient);
  const dashboardClients = useSelector((state) => state.dashboardClients);
  const [clientType, setClientType] = useState(0);
  const [clientToggle, setClientToggle] = useState("your");
  const [searchClient, setSearchClient] = useState("");
  const userdata = useSelector((state) => state.userdata);
  const [bdaymodal, setBdaymodal] = useState(false);
  var initials = "";
  if (userdata) {
    var nameArray = userdata.username.split(" ");
    initials = nameArray[0];
  }

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = dd + "-" + mm + "-" + yyyy;

  const aumBreakupAssetData = [];
  for (var a = 0; a < aumBreakupAsset.length; a++) {
    aumBreakupAssetData.push((aumBreakupAsset[a].percentage * 100).toFixed(1));
  }
  const aumBreakupProductData = [];
  for (let i = 0; i < aumBreakupProduct.length; i++) {
    aumBreakupProductData.push(
      (aumBreakupProduct[i].percentage * 100).toFixed(1)
    );
  }
  const assetdata = {
    labels: aumBreakupAsset.map((item) => {
      return item.name;
    }),
    datasets: [
      {
        data: aumBreakupAssetData,
        backgroundColor: GraphColorPalette,
        borderColor: GraphColorPalette,
        borderWidth: 1,
      },
    ],
  };

  const productdata = {
    labels: aumBreakupProduct.map((item) => {
      return item.name;
    }),
    datasets: [
      {
        data: aumBreakupProductData,
        backgroundColor: GraphColorPalette,
        borderColor: GraphColorPalette,
        borderWidth: 1,
      },
    ],
  };

  const aum = [
    {
      value: aumBreakup.your_aum.amount
        ? `${aumBreakup.your_aum.amount.toFixed(1)} ${
            aumBreakup.your_aum.denomination
          }`
        : "0 L",
      label: "YOUR AUM",
    },
    {
      value: aumBreakup.teams_aum
        ? `${aumBreakup.teams_aum.amount.toFixed(1)} ${
            aumBreakup.teams_aum.denomination
          }`
        : "0 L",
      label: "TEAM’S AUM",
    },
    {
      value: aumBreakup.total_clients,
      label: "TOTAL CLIENTS",
    },
    {
      value: aumBreakup.teams_total_clients,
      label: "TEAM’S TOTAL CLIENTS",
    },
    {
      value: aumBreakup.rms_under_you,
      label: "RMs UNDER YOU",
    },
  ];

  const pieoptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      labels: [
        {
          render: function () {},
          position: "outside",
          fontFamily: "Inter,sans-serif",
          fontColor: "#323232",
          textMargin: 4,
          fontSize: 14,
          display: "auto",
        },
        {
          render: function () {},
          fontColor: "#fff",
          display: "auto",
        },
      ],
      datalabels: {
        position: "outside",
        fontFamily: "Inter,sans-serif",
        formatter: function (value, context) {
          return (
            aumBreakupAsset[context.dataIndex].name +
            " " +
            parseFloat(value).toFixed(1) +
            "%"
          );
        },
        color: "#ededed",
        textMargin: 4,
        fontSize: 14,
        display: "auto",
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var meta = dataset._meta[Object.keys(dataset._meta)[0]];
          var total = meta.total;
          var currentValue = dataset.data[tooltipItem.index];
          var percentage = parseFloat(
            ((currentValue / total) * 100).toFixed(1)
          );
          return (
            "₹" +
            aumBreakupAsset[tooltipItem.index].aum.amount.toFixed(1) +
            " " +
            aumBreakupAsset[tooltipItem.index].aum.denomination +
            " (" +
            percentage +
            "%)"
          );
        },
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
      },
    },
  };
  const pieoptions2 = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      labels: [
        {
          render: function () {},
          position: "outside",
          fontFamily: "Inter,sans-serif",
          fontColor: "#fff",
          textMargin: 4,
          fontSize: 14,
          display: false,
        },
        {
          render: function () {},
          fontColor: "#323232",
          display: false,
        },
      ],
      datalabels: {
        position: "outside",
        fontFamily: "Inter,sans-serif",
        formatter: function (value, context) {
          return (
            aumBreakupProduct[context.dataIndex].name +
            " " +
            parseFloat(value).toFixed(1) +
            "%"
          );
        },
        color: "#fff",
        textMargin: 4,
        fontSize: 14,
        display: "auto",
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          // var meta = dataset._meta[Object.keys(dataset._meta)[0]];
          // var total = meta.total;
          var currentValue = dataset.data[tooltipItem.index];
          // var percentage = parseFloat(
          //   ((currentValue / total) * 100).toFixed(1)
          // );
          return (
            "₹" +
            aumBreakupProduct[tooltipItem.index].aum.amount.toFixed(1) +
            " " +
            aumBreakupProduct[tooltipItem.index].aum.denomination +
            " (" +
            currentValue +
            "%)"
          );
        },
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
      },
    },
  };
  const [clients, setclients] = useState(
    dashboardClients.filter((client) => client.prospect === false)
  );
  const [pageNumber, setPageNumber] = useState(0);
  let pastSmallScreen = useMediaQuery("(min-width:640px)");
  const clientsPerPage = pastSmallScreen ? 5 : 3;
  const pagesVisited = pageNumber * clientsPerPage;
  const displayclients = clients
    .slice(pagesVisited, pagesVisited + clientsPerPage)
    .map((client, index) => {
      return (
        <tr
          key={index}
          style={
            index !==
            clients.slice(pagesVisited, pagesVisited + clientsPerPage).length -
              1
              ? { borderBottom: "1px solid #e0dddd" }
              : {}
          }
          className="hover:bg-[#f3f3f3] cursor-pointer"
          onClick={() => {
            localStorage.setItem("clientId", client.id);
            localStorage.setItem("clientName", client.name);
            dispatch(
              PostEventLoggingData({
                module_name: "Dashboard",
                event_type: "Information",
                url: window.location.href,
                note: `Client - ${client.name} clicked`,
              })
            );
            history.push("/clientdashboard");
          }}
        >
          <td className="font-inter text-sm not-italic tracking-[0em] p-[1rem] text-left">
            {client.name}
          </td>
          <td className="font-inter text-sm not-italic tracking-[0em] p-[1rem] text-center">
            {client.account_type === "F" ? "Family" : "Individual"}
          </td>
          <td className="font-inter text-sm not-italic tracking-[0em] p-[1rem] text-center">
            {`₹ ${client.current_value.amount.toFixed(1)} ${
              client.current_value.denomination
            }`}
          </td>
          <td className="font-inter text-sm not-italic tracking-[0em] p-[1rem] text-center">
            {client.risk_score}
          </td>
        </tr>
      );
    });
  const displayclientsSmallScreen = clients
    .slice(pagesVisited, pagesVisited + clientsPerPage)
    .map((client, index) => {
      return (
        <div
          key={index}
          className="w-full p-4 border border-[#e0dddd] rounded mb-4"
        >
          <div className="w-full flex justify-between text-neutralBlack font-semibold text-sm">
            <p>{client.name}</p>
            <p>{`₹ ${client.current_value.amount.toFixed(1)} ${
              client.current_value.denomination
            }`}</p>
          </div>
          <div className="w-full flex justify-between text-[#6A6A76] text-sm mt-1">
            <p>Individual</p>
            <p>Current Value</p>
          </div>
          <div className="w-full flex justify-between text-neutralBlack font-semibold text-sm mt-4">
            <p>{client.risk_score}</p>
            <p>{client.account_type === "F" ? "Family" : "Individual"}</p>
          </div>
          <div className="w-full flex justify-between text-[#6A6A76] text-sm mt-1">
            <p>Risk Score</p>
            <p>Account Type</p>
          </div>
        </div>
      );
    });
  const pageCount = Math.ceil(clients.length / clientsPerPage);
  const changePage = (event, value) => {
    setPageNumber(value - 1);
  };
  const percentage = Math.round(annualTarget.percentage * 100);

  const ProgressBar = (props) => {
    return (
      <div className="dashboard-progress-bar">
        <Filler percentage={props.percentage} />
      </div>
    );
  };

  const Filler = (props) => {
    return (
      <Tooltip
        TransitionComponent={Zoom}
        placement="top"
        title={`Your current AUM is ${aum[0].value}`}
        arrow
      >
        <div
          className="dashboard-filler"
          style={
            props.percentage >= 98
              ? {
                  width: `${Math.max(5, Math.min(props.percentage, 100))}%`,
                  borderTopRightRadius: "25px",
                  borderBottomRightRadius: "25px",
                }
              : { width: `${Math.max(5, Math.min(props.percentage, 100))}%` }
          }
        >
          {Math.round(props.percentage)} %
        </div>
      </Tooltip>
    );
  };

  const clientBreakup = {
    labels: [
      "Below ₹ 1 Cr",
      "₹ 1 Cr to ₹ 5 Cr",
      "₹ 5 Cr to ₹ 10 Cr",
      "₹ 10 Cr and above",
    ],

    datasets: [
      {
        axis: "y",
        data: [
          aumBreakupClient.less_than_one,
          aumBreakupClient.between_one_five,
          aumBreakupClient.between_five_ten,
          aumBreakupClient.between_ten_twenty,
        ],
        backgroundColor: GraphColorPalette,
        borderColor: GraphColorPalette,
        borderWidth: 1,
        barThickness: 55,
      },
    ],
  };
  const handleChange = (e, newAlignment) => {
    if (newAlignment) {
      setClientToggle(newAlignment);
      dispatch(
        newAlignment === "your"
          ? GetDashboardYourClients()
          : GetDashboardAllClients()
      );
      dispatch(
        newAlignment === "your"
          ? PostEventLoggingData({
              module_name: "Dashboard",
              event_type: "Information",
              url: window.location.href,
              note: "Your Clients clicked",
            })
          : PostEventLoggingData({
              module_name: "Dashboard",
              event_type: "Information",
              url: window.location.href,
              note: "All Clients clicked",
            })
      );
    }
  };
  useEffect(() => {
    setclients(
      dashboardClients.filter((client) =>
        clientType === 0 ? client.prospect === false : client.prospect === true
      )
    ); // eslint-disable-next-line
  }, [dashboardClients]);
  useEffect(() => {
    setclients(
      dashboardClients.filter(
        (client) =>
          (clientType === 0
            ? client.prospect === false
            : client.prospect === true) &&
          client.name &&
          client.name.toLowerCase().includes(searchClient.toLowerCase())
      )
    );
    setPageNumber(0); // eslint-disable-next-line
  }, [searchClient]);
  const toggletabs = [
    { label: "Your Clients", value: "your" },
    { label: "All Clients", value: "all" },
  ];
  const tabs = [{ label: "Clients" }, { label: "Prospects" }];
  const handletabchange = (event, newValue) => {
    setclients(
      dashboardClients.filter((client) =>
        newValue ? client.prospect === true : client.prospect === false
      )
    );
    setClientType(newValue);
    setPageNumber(0);
    dispatch(
      PostEventLoggingData({
        module_name: "Dashboard",
        event_type: "Information",
        url: window.location.href,
        note: "Client button clicked",
      })
    );
  };
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={`Welcome back, ${initials} 👋`}
        description={
          "This is your dashboard. Access all of your latest updates here."
        }
      />
      <BdayModal bdaymodal={bdaymodal} onClose={() => setBdaymodal(false)} />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen h-auto">
          <div className="main-body-card sm:flex">
            <div className="p-[1rem] w-full sm:w-9/12">
              <h3 className="card-heading">AUM Breakup</h3>
              <div className="font-inter text-sm not-italic font-normal mt-2 text-left text-neutralBlack">
                As on {today}
              </div>
              <div className="sm:flex items-start justify-center w-full h-full">
                <div className="flex sm:w-6/12 w-full flex-col items-center">
                  <PieChart
                    data={assetdata}
                    options={pieoptions}
                    width={370}
                    height={270}
                  />
                  <h3 className="small-heading-three">Asset Class</h3>
                </div>
                <div className="flex sm:w-6/12 w-full flex-col items-center">
                  <PieChart
                    data={productdata}
                    options={pieoptions2}
                    width={370}
                    height={270}
                  />
                  <h3 className="small-heading-three">Product Type</h3>
                </div>
              </div>
            </div>
            <div className="hidden sm:block w-[23%] border-l border-[#e0dddd]">
              {aum.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-col justify-center items-center py-[0.75rem] px-0 border-b-[1px] border-[#e0dddd]"
                  >
                    <div className="font-inter text-[20px] not-italic font-medium text-center text-[#3D3D3D] tracking-[0.1133817583322525px] ">
                      {item.value}
                    </div>
                    <div className="font-inter text-[11px] not-italic font-normal leading-4 text-center text-[#939393] tracking-[0em]">
                      {item.label}
                    </div>
                  </div>
                );
              })}
              <div className="dashboard-sidebar-data-container">
                <div className="dashboard-sidebar-data-value flex items-center">
                  {aumBreakup.birthdays_this_month}
                  {aumBreakup.birthdays_this_month ? (
                    <BsArrowUpRightSquare
                      size={14}
                      color="#00003F"
                      style={{ marginLeft: "0.5rem", cursor: "pointer" }}
                      onClick={() => setBdaymodal(true)}
                    />
                  ) : null}
                </div>
                <div className="dashboard-sidebar-data-label">
                  CLIENT BIRTHDAYS THIS MONTH
                </div>
              </div>
            </div>
          </div>
          <div className="sm:hidden main-body-card mt-4">
            {aum.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-col justify-center items-center py-[0.75rem] px-0 border-b-[1px] border-[#e0dddd]"
                >
                  <div className="font-inter not-italic font-medium text-center text-[#3D3D3D] tracking-[0.1133817583322525px]">
                    {item.value}
                  </div>
                  <div className="font-inter text-[11px] not-italic font-normal leading-4 text-center text-[#939393] tracking-[0em]">
                    {item.label}
                  </div>
                </div>
              );
            })}
            <div className="dashboard-sidebar-data-container">
              <div className="font-inter not-italic font-medium text-center text-[#3D3D3D] tracking-[0.1133817583322525px] flex items-center">
                {aumBreakup.birthdays_this_month}
                {aumBreakup.birthdays_this_month ? (
                  <BsArrowUpRightSquare
                    size={14}
                    color="#00003F"
                    style={{ marginLeft: "0.5rem", cursor: "pointer" }}
                    onClick={() => setBdaymodal(true)}
                  />
                ) : null}
              </div>
              <div className="dashboard-sidebar-data-label">
                CLIENT BIRTHDAYS THIS MONTH
              </div>
            </div>
          </div>
          <div className="pt-[1rem]">
            <div className=" p-[1rem] bg-[#fff] rounded-[00.5rem] w-full flex justify-between flex-col">
              <div className="flex justify-between w-full">
                <InvestedgeToggleButtonGroup
                  tabs={toggletabs}
                  value={clientToggle}
                  onChange={handleChange}
                  exclusive
                />
              </div>
              <div className="md:hidden flex items-center mb-2 mt-4">
                <Link to="/clientinput">
                  <InvestedgeButton
                    className="h-full"
                    onClick={() =>
                      dispatch(
                        PostEventLoggingData({
                          module_name: "Dashboard",
                          event_type: "Add button",
                          url: window.location.href,
                          note: "Add new client clicked",
                        })
                      )
                    }
                  >
                    <img
                      className=" mr-[0.5rem] object-contain w-[14px]"
                      src={Plus}
                      alt="err"
                    />
                    Add New
                  </InvestedgeButton>
                  <InvestedgeSearchField
                    type="text"
                    placeholder="Search"
                    value={searchClient}
                    onChange={(e) => setSearchClient(e.target.value)}
                    className="w-[50%] ml-4"
                  />
                </Link>
              </div>
              <div className="flex justify-between w-full items-center mt-[0.5rem]">
                <div className="w-full sm:w-[50%]">
                  <InvestedgeTabs
                    tabs={tabs}
                    tabvalue={clientType}
                    handletabchange={handletabchange}
                  />
                </div>
                <div className="md:flex hidden items-end mb-2">
                  <InvestedgeSearchField
                    type="text"
                    placeholder="Search"
                    value={searchClient}
                    onChange={(e) => setSearchClient(e.target.value)}
                  />
                  <Link to="/clientinput">
                    <InvestedgeButton
                      sx={{ marginBottom: "2px" }}
                      className="ml-4 h-full w-[110px]"
                      onClick={() =>
                        dispatch(
                          PostEventLoggingData({
                            module_name: "Dashboard",
                            event_type: "Add button",
                            url: window.location.href,
                            note: "Add new client clicked",
                          })
                        )
                      }
                    >
                      <img
                        className=" mr-[0.5rem] object-contain w-[14px]"
                        src={Plus}
                        alt="err"
                      />
                      Add New
                    </InvestedgeButton>
                  </Link>
                </div>
              </div>
              <div className="mt-[1rem]">
                <div className="rounded-md min-h-[300px]">
                  {dashboardClients[0] !== "loading" ? (
                    pastSmallScreen ? (
                      <table className="w-full font-inter investedge-table hidden sm:table">
                        <colgroup>
                          <col width="31%" />
                          <col width="24%" />
                          <col width="24%" />
                          <col width="24%" />
                        </colgroup>
                        <tbody>
                          <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                            <th className="pl-[1rem] font-medium text-left">
                              Client Name
                            </th>
                            <th className="text-center font-medium">
                              Account Type
                            </th>
                            <th className="text-center font-medium">
                              Current Value
                            </th>
                            <th className="text-center font-medium">
                              Risk Score
                            </th>
                          </tr>
                          {displayclients}
                        </tbody>
                      </table>
                    ) : (
                      displayclientsSmallScreen
                    )
                  ) : (
                    <div className="w-full h-[300px] flex justify-center items-center opacity-90">
                      <Bars color="#1982F8" />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-end">
                <InvestedgePagination
                  count={pageCount}
                  defaultPage={1}
                  page={pageNumber + 1}
                  onChange={changePage}
                  color="primary"
                />
              </div>
            </div>
          </div>
          <div className="md:flex pt-[1rem]">
            <div className="md:w-[35%] w-full">
              <div className="flex-col p-[1rem] bg-[#fff] rounded-[00.5rem] h-[559px] relative">
                <h2 className="card-heading">Popular Products</h2>
                <ProductCard />
              </div>
            </div>
            <div className="md:ml-[1rem] w-full md:w-[65%] mt-4 md:mt-0">
              <div>
                <div className="flex-col p-[1rem] bg-[#fff] rounded-[00.5rem] w-full flex justify-between">
                  <div className="flex items-center">
                    <h2 className="card-heading">Annual Target</h2>
                    <Tooltip
                      TransitionComponent={Zoom}
                      placement="right"
                      title="This is Annual AUM Target which can be set under Module Settings in Settings tab"
                      arrow
                    >
                      <img
                        src={TooltipIcon}
                        alt="tooltip"
                        className="w-[16px] ml-2 "
                      />
                    </Tooltip>
                  </div>
                  <ProgressBar percentage={percentage} />
                  <div className="flex mt-[1rem] justify-between w-[96%] m-auto">
                    <p className="mt-[0.5rem] font-inter text-[12px] not-italic font-medium text-center text-[#000] tracking-[0em]">
                      ₹ 0 {annualTarget.target_aum.denomination}
                    </p>
                    <p className="mt-[0.5rem] font-inter text-[12px] not-italic font-medium text-center text-[#000] tracking-[0em]">{`₹ ${annualTarget.target_aum.amount} ${annualTarget.target_aum.denomination}`}</p>
                  </div>
                </div>
              </div>
              <div className="mt-[1rem]">
                <div className="flex-col p-[1rem] bg-[#fff] rounded-[00.5rem] w-full flex justify-between">
                  <h2 className="card-heading">AUM wise client breakup</h2>
                  <BarChart data={clientBreakup} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
