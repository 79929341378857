import { useState } from "react";
import * as MdIcons from "react-icons/md";
import { usePopper } from "react-popper";
function DropdownAsset({ selected, setSelected, options }) {
  const [isActive, setIsActive] = useState(false);
  const [referenceRef, setReferenceRef] = useState(null);
  const [popperRef, setPopperRef] = useState(null);

  const { styles, attributes } = usePopper(referenceRef, popperRef, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "preventOverflow",
        options: {
          enabled: false,
          rootBoundary: "document",
        },
      },
    ],
  });
  return (
    <div className="w-full relative select-none">
      <div
        className="upload-excel-dropdown-btn asset-screen-dropdown-btn"
        ref={setReferenceRef}
        onClick={(e) => setIsActive(!isActive)}
      >
        {selected}
        <MdIcons.MdKeyboardArrowDown
          style={{ marginTop: "0.2rem" }}
          size={17}
        />
      </div>
      {isActive && (
        <div
          ref={setPopperRef}
          style={styles.popper}
          {...attributes.popper}
          className="upload-excel-dropdown-content asset-screen-dropdown-content"
        >
          {options.map((option, index) => (
            <div
              key={index}
              onClick={() => {
                setSelected(option);
                setIsActive(false);
              }}
              className="upload-excel-dropdown-item"
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default DropdownAsset;
