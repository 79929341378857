import React from "react";
import { IoIosClose } from "react-icons/io";
import { useSelector } from "react-redux";
import InvestedgeModal from "../../../../components/modal/InvestedgeModal";

export default function InvestmentPolicyModal({ onClose, ipsmodal }) {
  const ipsData = useSelector((state) => state.portfolioReviewGetIpsData);
  const tableFields = {
    equity: {
      name: "Equity",
      target: (ipsData.equity.target * 100).toFixed(0),
      min: (ipsData.equity.min * 100).toFixed(0),
      max: (ipsData.equity.max * 100).toFixed(0),
    },
    debt: {
      name: "Debt",
      target: (ipsData.debt.target * 100).toFixed(0),
      min: (ipsData.debt.min * 100).toFixed(0),
      max: (ipsData.debt.max * 100).toFixed(0),
    },
    alternates: {
      name: "Alternates",
      target: (ipsData.alternate.target * 100).toFixed(0),
      min: (ipsData.alternate.min * 100).toFixed(0),
      max: (ipsData.alternate.max * 100).toFixed(0),
    },
    large_cap: {
      name: "Large cap",
      target: (ipsData.large_cap.target * 100).toFixed(0),
      min: (ipsData.large_cap.min * 100).toFixed(0),
      max: (ipsData.large_cap.max * 100).toFixed(0),
    },
    mid_cap: {
      name: "Mid cap",
      target: (ipsData.mid_cap.target * 100).toFixed(0),
      min: (ipsData.mid_cap.min * 100).toFixed(0),
      max: (ipsData.mid_cap.max * 100).toFixed(0),
    },
    small_cap: {
      name: "Small cap",
      target: (ipsData.small_cap.target * 100).toFixed(0),
      min: (ipsData.small_cap.min * 100).toFixed(0),
      max: (ipsData.small_cap.max * 100).toFixed(0),
    },

    credit_risk: {
      name: "Credit risk",
      target: (ipsData.aaa_equiv.target * 100).toFixed(0),
      min: (ipsData.aaa_equiv.min * 100).toFixed(0),
      max: (ipsData.aaa_equiv.max * 100).toFixed(0),
    },
    alternates_low_vol: {
      name: "Alternates - Low vol",
      target: (ipsData.alternates.target * 100).toFixed(0),
      min: (ipsData.alternates.min * 100).toFixed(0),
      max: (ipsData.alternates.max * 100).toFixed(0),
    },
  };
  const tableHeaders = ["Asset/rule", "Target", "Min", "Max"];
  return (
    <>
      <InvestedgeModal
        aria-labelledby="Investment Policy Modal"
        aria-describedby="Investment Policy Modal"
        open={ipsmodal}
        onClose={onClose}
      >
        <div className="flex justify-between">
          <h5 className="card-heading">Investment Policy Statement</h5>
          <IoIosClose size={30} onClick={onClose} />
        </div>
        <div className="modal-body">
          <p className="text-[14px] font-inter mt-[0.5rem]">
            An investment policy statement (IPS) is a document drafted between a
            portfolio manager and a client that outlines general rules for the
            manager. Specific information on matters such as asset allocation,
            risk tolerance, and liquidity requirements are included in an
            investment policy statement.
          </p>
          <div className=" mt-3">
            <table className="w-full investedge-table">
              <col width="31%" />
              <col width="25%" />
              <col width="22%" />
              <col width="22%" />
              <tbody>
                <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                  {tableHeaders.map((tableHeader, index) => (
                    <th
                      key={index}
                      className="text-left p-[8px] border-b border-neutralBlack font-medium"
                      style={index ? { textAlign: "center" } : {}}
                    >
                      {tableHeader}
                    </th>
                  ))}
                </tr>

                {Object.keys(tableFields).map((key, index) => {
                  return (
                    <tr className="border-bottom text-sm" key={index}>
                      <td className="text-left p-[8px]">
                        {tableFields[key].name}
                      </td>
                      <td className="flex justify-center w-full p-[8px] text-left">
                        {tableFields[key].target}
                        <span>%</span>
                      </td>
                      <td className="text-center p-[8px]">
                        {tableFields[key].min}%
                      </td>
                      <td className="text-center p-[8px]">
                        {tableFields[key].max}%
                      </td>
                    </tr>
                  );
                })}
                <tr className="border-bottom text-sm">
                  <td className="text-left p-[8px]">Duration</td>
                  <td className=" flex w-full justify-center text-left p-[8px]">
                    {ipsData.duration.target}
                    <span>yrs</span>
                  </td>

                  <td className="text-left p-[8px]">
                    <div className="flex w-full justify-center">
                      {ipsData.duration.min}
                      <span>yrs</span>
                    </div>
                  </td>
                  <td className="text-center">
                    {`${ipsData.duration.max}yrs`}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </InvestedgeModal>
    </>
  );
}
