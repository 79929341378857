import React from "react";
import Storyteller from "../assets/img/StoryTeller.svg";
import VisibilitySensor from "react-visibility-sensor";
import Heart from "../assets/img/Heart.svg";
import ActiveStoryteller from "../assets/img/active_storyteller.svg";
export default function StoryTeller({ setBg, bg }) {
  return (
    <div className="grid grid-cols-1 mt-40 md:grid-cols-2 md:px-20">
      <div className="hidden md:flex flex-col justify-center">
        <VisibilitySensor partialVisibility>
          <>
            <div
              className="w-[200px] flex pb-[16px] mb-8"
              style={{
                background: bg[3],
                transitionProperty: "width,background",
                transitionDuration: "0.2s,0.2s",
                transitionTimingFunction: "linear",
              }}
            >
              <img src={bg[3].length ? ActiveStoryteller : Heart} alt="err" />
              <p
                className="font-montserrat mb-0 ml-2 text-xl"
                style={
                  bg[3].length
                    ? { color: "#FCFCFD", fontWeight: 600 }
                    : { color: "7777E91" }
                }
              >
                Storyteller
              </p>
            </div>
            <h2 className="hidden md:block font-montserrat font-medium text-4xl text-[#FCFCFD] leading-[54px]">
              Insights-driven Story of <br />
              Clients' Portfolio
            </h2>
            <p className="font-montserrat text-[#B1B5C4] font-normal text-sm md:text-xl mt-[16px] md:mt-6 md:px-0 px-[20px]">
              Always be ready with actionable insights to monitor, review client
              portfolio in the context of market conditions, portfolio
              positioning and client requirements.
              <br />
              <br />
              Don’t only talk about analytics – convey the story behind why the
              portfolio has been designed this way. Give insights on its return
              profile, risk profile and liquidity profile.
            </p>
          </>
        </VisibilitySensor>
      </div>
      <div className="md:hidden flex flex-col justify-center">
        <div
          className="w-[200px] flex pb-[16px] ml-[16px]"
          style={{
            background: bg[3],
            transitionProperty: "width,background",
            transitionDuration: "0.2s,0.2s",
            transitionTimingFunction: "linear",
          }}
        >
          <img src={bg[3].length ? ActiveStoryteller : Heart} alt="err" />
          <p
            className="font-montserrat mb-0 ml-2"
            style={
              bg[3].length
                ? { color: "#FCFCFD", fontWeight: 600 }
                : { color: "7777E91" }
            }
          >
            Storyteller
          </p>
        </div>
        <h2 className="md:hidden font-montserrat font-medium text-2xl md:text-4xl text-[#FCFCFD] mt-6 leading-9 sm:leading-[54px] md:px-0 px-[20px]">
          Insights-driven Story of <br />
          Clients' Portfolio
        </h2>
        <p className="font-montserrat text-[#B1B5C4] font-normal text-sm md:text-xl mt-[16px] md:mt-6 md:px-0 px-[20px]">
          Always be ready with actionable insights to monitor, review client
          portfolio in the context of market conditions, portfolio positioning
          and client requirements.
          <br />
          <br />
          Don’t only talk about analytics – convey the story behind why the
          portfolio has been designed this way. Give insights on its return
          profile, risk profile and liquidity profile.
        </p>
      </div>
      <div className="bg-[url('../img/red_mesh.svg')] bg-cover flex justify-center items-center bg-center">
        <img src={Storyteller} alt="err" />
      </div>
    </div>
    // <div className="hidden md:grid grid-cols-2 px-20 mt-40">
    //   <div className="flex flex-col justify-center">
    //     <VisibilitySensor
    //       partialVisibility
    //       onChange={(isVisible) =>
    //         isVisible &&
    //         setBg([
    //           "",
    //           "",
    //           "",
    //           "radial-gradient(29.3% 35.71% at 50% 100%, rgba(42, 168, 234, 0.64) 0%, rgba(6, 7, 27, 0.64)",
    //         ])
    //       }
    //     >
    //       <>
    //         <h2 className="font-montserrat font-medium text-4xl text-[#FCFCFD] mt-6 leading-[54px]">
    //           Insights-driven Story of <br />
    //           Clients' Portfolio
    //         </h2>

    //         <p className="font-montserrat text-[#B1B5C4] font-normal text-xl mt-6">
    //           Always be ready with actionable insights to monitor, review client
    //           portfolio in the context of market conditions, portfolio
    //           positioning and client requirements.
    //         </p>
    //       </>
    //     </VisibilitySensor>
    //     <p className="font-montserrat text-[#B1B5C4] font-normal text-xl mt-6">
    //       Don’t only talk about analytics – convey the story behind why the
    //       portfolio has been designed this way. Give insights on its return
    //       profile, risk profile and liquidity profile.
    //     </p>
    //   </div>
    //   <div className="bg-[url('../img/red_mesh.svg')] bg-cover flex justify-center items-center bg-center">
    //     <img src={Storyteller} alt="err" />
    //   </div>
    // </div>
  );
}
