import { GET_AUM_BREAKUP } from "../actions/type";
const initialState = {
  your_aum: {
    amount: 0,
    denomination: "",
  },
  teams_aum: {
    amount: 0,
    denomination: "",
  },
  total_clients: 0,
  teams_total_clients: 0,
  rms_under_you: 0,
  birthdays_this_month: 0,
};
export default function GetAumBreakup(state = initialState, action) {
  switch (action.type) {
    case GET_AUM_BREAKUP:
      return action.payload;
    default:
      return state;
  }
}
