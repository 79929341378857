import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../../../../components/Dropdowns/DropdownWhiteSingle";
import { GetProductInput, OpenSnackbar } from "../../../../actions/actions";
import { Bars } from "react-loader-spinner";
import InvestedgeModal from "../../../../components/modal/InvestedgeModal";
import InvestedgeAutocomplete from "../../../../components/autocomplete/InvestedgeAutocomplete";
import InvestedgeTextField from "../../../../components/textfield/InvestedgeTextField";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
import InvestedgePagination from "../../../../components/pagination/InvestedgePagination";
import { IoIosClose } from "react-icons/io";
// import Paper from "@mui/material/Paper";
export default function AddFundsModal({
  onClose,
  afmodal,
  parsedData,
  setParsedData,
  addedFundsMap,
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetProductInput());
    // eslint-disable-next-line
  }, []);
  const data = useSelector((state) => state.productInputData);
  const [productsData, setProductsData] = useState(data);
  const [searchProduct, setSearchProduct] = useState("");
  const [asset, setAsset] = useState("All");
  const [product, setProduct] = useState("All");
  const [category, setCategory] = useState("All");
  const [recommendation, setRecommendation] = useState("All");
  const assetSet = new Set(data.map((product) => product.asset_type));
  const productSet = new Set(data.map((product) => product.product_type));
  const categorySet = new Set(data.map((product) => product.category));
  const [pageNumber, setPageNumber] = useState(0);
  const clientsPerPage = 5;
  const pagesVisited = pageNumber * clientsPerPage;
  const displaydata =
    Array.isArray(productsData) &&
    productsData
      .slice(pagesVisited, pagesVisited + clientsPerPage)
      .map((pid, ind) => {
        return (
          <tr className="h-[80px] border-b border-[#e0dddd]" key={ind}>
            <td className=" text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-[20px] tracking-[0em] p-[1rem] text-left pl-[1rem]">
              {pid.name}
            </td>
            <td className=" text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-[20px] tracking-[0em] p-[1rem] text-center">
              {pid.recommendation}
            </td>
            <td className=" text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-[20px] tracking-[0em] p-[1rem] text-center">
              {pid.asset_type}
            </td>
            <td className=" text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-[20px] tracking-[0em] p-[1rem] text-center">
              {pid.product_type}
            </td>
            <td className=" text-clip text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-[20px] tracking-[0em] p-[1rem] text-center">
              {pid.category}
            </td>

            <td className=" text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-[20px] tracking-[0em] p-[1rem] text-center">
              {addedFundsMap[pid.product_id] ? (
                <InvestedgeButton disabled>Added</InvestedgeButton>
              ) : (
                <InvestedgeButton
                  onClick={() => {
                    if (
                      pid.recommendation === "Sell" ||
                      pid.recommendation === "Hold"
                    )
                      dispatch(
                        OpenSnackbar({
                          severity: "warning",
                          message:
                            "You have chosen product(s) with sell/hold recommendation",
                        })
                      );
                    let index = parsedData.findIndex(
                      (product) => product.product_id === pid.product_id
                    );
                    if (index === -1)
                      setParsedData([
                        {
                          product_id: pid.product_id,
                          individual_asset_pct: 0.0,
                          name: pid.name,
                          asset_type: pid.asset_type,
                          product_type: pid.product_type,
                        },
                        ...parsedData,
                      ]);
                    addedFundsMap[pid.product_id] = true;
                  }}
                >
                  Add
                </InvestedgeButton>
              )}
            </td>
          </tr>
        );
      });

  const pageCount = Math.ceil(productsData.length / clientsPerPage);
  const changePage = (e, val) => {
    setPageNumber(val - 1);
  };
  useEffect(() => {
    setProductsData(data);
  }, [data]);
  useEffect(() => {
    if (
      asset === "All" &&
      product === "All" &&
      category === "All" &&
      recommendation === "All"
    )
      setProductsData(
        data.filter((data) =>
          data.name.toLowerCase().includes(searchProduct.toLowerCase())
        )
      );
    else if (asset === "All" && product === "All" && category === "All")
      setProductsData(
        data.filter(
          (data) =>
            data.recommendation === recommendation &&
            data.name &&
            data.name.toLowerCase().includes(searchProduct.toLowerCase())
        )
      );
    else if (asset === "All" && product === "All" && recommendation === "All")
      setProductsData(
        data.filter(
          (data) =>
            data.category === category &&
            data.name &&
            data.name.toLowerCase().includes(searchProduct.toLowerCase())
        )
      );
    else if (asset === "All" && recommendation === "All" && category === "All")
      setProductsData(
        data.filter(
          (data) =>
            data.product_type === product &&
            data.name &&
            data.name.toLowerCase().includes(searchProduct.toLowerCase())
        )
      );
    else if (
      product === "All" &&
      recommendation === "All" &&
      category === "All"
    )
      setProductsData(
        data.filter(
          (data) =>
            data.asset_type === asset &&
            data.name &&
            data.name.toLowerCase().includes(searchProduct.toLowerCase())
        )
      );
    else if (asset === "All" && product === "All")
      setProductsData(
        data.filter(
          (data) =>
            data.recommendation === recommendation &&
            data.category === category &&
            data.name &&
            data.name.toLowerCase().includes(searchProduct.toLowerCase())
        )
      );
    else if (asset === "All" && category === "All")
      setProductsData(
        data.filter(
          (data) =>
            data.recommendation === recommendation &&
            data.product_type === product &&
            data.name &&
            data.name.toLowerCase().includes(searchProduct.toLowerCase())
        )
      );
    else if (asset === "All" && recommendation === "All")
      setProductsData(
        data.filter(
          (data) =>
            data.product_type === product &&
            data.category === category &&
            data.name &&
            data.name.toLowerCase().includes(searchProduct.toLowerCase())
        )
      );
    else if (product === "All" && category === "All")
      setProductsData(
        data.filter(
          (data) =>
            data.recommendation === recommendation &&
            data.asset_type === asset &&
            data.name &&
            data.name.toLowerCase().includes(searchProduct.toLowerCase())
        )
      );
    else if (product === "All" && recommendation === "All")
      setProductsData(
        data.filter(
          (data) =>
            data.category === category &&
            data.asset_type === asset &&
            data.name &&
            data.name.toLowerCase().includes(searchProduct.toLowerCase())
        )
      );
    else if (recommendation === "All" && category === "All")
      setProductsData(
        data.filter(
          (data) =>
            data.product_type === product &&
            data.asset_type === asset &&
            data.name &&
            data.name.toLowerCase().includes(searchProduct.toLowerCase())
        )
      );
    else if (recommendation === "All")
      setProductsData(
        data.filter(
          (data) =>
            data.product_type === product &&
            data.asset_type === asset &&
            data.category === category &&
            data.name &&
            data.name.toLowerCase().includes(searchProduct.toLowerCase())
        )
      );
    else if (category === "All")
      setProductsData(
        data.filter(
          (data) =>
            data.product_type === product &&
            data.asset_type === asset &&
            data.recommendation === recommendation &&
            data.name &&
            data.name.toLowerCase().includes(searchProduct.toLowerCase())
        )
      );
    else if (product === "All")
      setProductsData(
        data.filter(
          (data) =>
            data.category === category &&
            data.asset_type === asset &&
            data.recommendation === recommendation &&
            data.name &&
            data.name.toLowerCase().includes(searchProduct.toLowerCase())
        )
      );
    else if (asset === "All")
      setProductsData(
        data.filter(
          (data) =>
            data.category === category &&
            data.product_type === product &&
            data.recommendation === recommendation &&
            data.name &&
            data.name.toLowerCase().includes(searchProduct.toLowerCase())
        )
      );
    else
      setProductsData(
        data.filter(
          (data) =>
            data.asset_type === asset &&
            data.product_type === product &&
            data.category === category &&
            data.recommendation === recommendation &&
            data.name &&
            data.name.toLowerCase().includes(searchProduct.toLowerCase())
        )
      );
    setPageNumber(0);
    // eslint-disable-next-line
  }, [asset, product, category, recommendation, searchProduct]);
  const flatProps = {
    options: ["All", ...categorySet].map((option) => option),
  };
  // const CustomPaper = (props) => {
  //   return (
  //     <Paper
  //       elevation={8}
  //       style={{ display: "inline-block", width: "250px" }}
  //       {...props}
  //     />
  //   );
  // };
  return (
    <InvestedgeModal
      aria-labelledby="Add funds Modal"
      aria-describedby="Add funds Modal"
      sx={{
        width: "80vw",
        minHeight: "96vh",
        marginRight: "auto",
        marginLeft: "auto",
      }}
      open={afmodal}
      handleclose={onClose}
    >
      <div className="flex justify-between mb-[1.5%]">
        <h3 className="card-heading">Add Products</h3>
        <IoIosClose size={30} onClick={onClose} />
      </div>
      <div className=" mt-[1rem] flex justify-between">
        <div className="w-[12%]">
          <Dropdown
            options={["All", "Buy", "Hold", "Sell"]}
            selected={recommendation}
            setSelected={setRecommendation}
            labelName="Recommendation"
          />
        </div>
        <div className="w-[12%]">
          <Dropdown
            options={["All", ...assetSet]}
            selected={asset}
            setSelected={setAsset}
            labelName="Asset Class"
          />
        </div>
        <div className="w-[12%]">
          <Dropdown
            options={["All", ...productSet]}
            selected={product}
            setSelected={setProduct}
            labelName="Product Type"
          />
        </div>
        <div className="w-[30%]">
          <InvestedgeAutocomplete
            {...flatProps}
            value={category}
            onChange={(event, value) => {
              setCategory(value);
            }}
            renderInput={(params) => (
              <InvestedgeTextField
                {...params}
                label={"Category"}
                sx={{ boxShadow: "none" }}
              />
            )}
          />
        </div>

        <div className="w-[30%]">
          <InvestedgeTextField
            fullWidth
            type="text"
            sx={{
              fontFamily: "Inter,sans-sarif",
              fontWeight: 400,
            }}
            label="Search Products"
            value={searchProduct}
            onChange={(e) => setSearchProduct(e.target.value)}
          />
        </div>
      </div>

      <h5 className=" font-inter text-[16px] font-medium leading-[20px] tracking-[0em] text-left text-neutralBlack mb-0  ">
        Search Results
      </h5>

      <div className=" rounded-[6px] mt-[1rem] min-h-[400px]">
        {data.length === 0 ? (
          <div className=" w-full h-[340px] flex justify-center items-center opacity-[0.9]">
            <Bars color="#1982F8" />
          </div>
        ) : productsData.length ? (
          <div className="min-h-[400px]">
            <table className="w-full investedge-table ">
              <colgroup>
                <col width="25%" />
                <col width="15.5%" />
                <col width="15.5%" />
                <col width="15.5%" />
                <col width="20.5%" />
                <col width="14%" />
              </colgroup>
              <tbody>
                <tr className="rounded-[10px] max-h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76] h-10">
                  <th className="font-inter text-[15px] font-medium not-italic leading-[20px] tracking-[0em] text-left pl-[1rem]">
                    Product Name
                  </th>
                  <th className=" font-inter text-[15px] font-medium not-italic leading-[20px] tracking-[0em] text-center">
                    Recommendation
                  </th>
                  <th className=" font-inter text-[15px] font-medium not-italic leading-[20px] tracking-[0em] text-center">
                    Asset Class
                  </th>
                  <th className=" font-inter text-[15px] font-medium not-italic leading-[20px] tracking-[0em] text-center">
                    Product Type
                  </th>
                  <th className=" font-inter text-[15px] font-medium not-italic leading-[20px] tracking-[0em] text-center">
                    Product Category
                  </th>
                  <th className=" font-inter text-[15px] font-medium not-italic leading-[20px] tracking-[0em] text-center">
                    Add Products
                  </th>
                </tr>
                {displaydata}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="w-full min-h-[400px] flex justify-center items-center">
            <h2 className="card-heading">No Data Found</h2>
          </div>
        )}
      </div>
      <div className="w-[95%] flex justify-end mt-4 absolute bottom-4">
        <InvestedgePagination
          count={pageCount}
          defaultPage={1}
          page={pageNumber + 1}
          onChange={changePage}
          color="primary"
        />
      </div>
    </InvestedgeModal>
  );
}
