import React, { useState, useEffect } from "react";
import Add from "../../../../assets/img/commisionsadd.svg";
import Subtract from "../../../../assets/img/commisionssubtract.svg";
import PortfolioThirdStepSlider from "./PortfolioThirdStepSlider";
import { useSelector } from "react-redux";
import {
  GetAllocationPreference,
  ResetAllocationPreference,
  EditIpsData,
  PostEventLoggingData,
} from "../../../../actions/actions";
import { useDispatch } from "react-redux";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
export default function PortfolioThirdStep({
  handleBack,
  handleNext,
  formValue,
  setFormValue,
  setPortfolioProducts,
}) {
  const dispatch = useDispatch();
  const ipsData = useSelector((state) => state.getipsdata);
  const ThreeColors = ["#156DD0", "#BFE0FF"];
  const TwoColors = ["#156DD0", "#BFE0FF"];
  const [EquityWidths, setEquity] = useState(
    ipsData[1].large_cap === 0 &&
      ipsData[1].mid_cap === 0 &&
      ipsData[1].small_cap === 0
      ? [33.3, 100 - 33.3]
      : [
          parseInt(ipsData[1].large_cap * 100),
          parseInt((1 - ipsData[1].large_cap) * 100),
        ]
  );
  const [DebtWidths, setDebt] = useState([
    parseInt(ipsData[3].credit_risk * 100),
    parseInt((1 - ipsData[3].credit_risk) * 100),
  ]);
  const [AlternativesWidths, setAlternatives] = useState([
    parseInt(ipsData[4].alternates_low_vol * 100),
    parseInt((1 - ipsData[4].alternates_low_vol) * 100),
  ]);
  const [equityValue, setEquityValue] = useState(
    parseFloat((ipsData[0].equity * 100).toFixed(1))
  );
  const [debtValue, setDebtValue] = useState(
    parseFloat((ipsData[0].debt * 100).toFixed(1))
  );
  const [alternativeValue, setAlternativeValue] = useState(
    parseFloat((ipsData[0].alternates * 100).toFixed(1))
  );
  const assetSum =
    parseFloat(equityValue) +
    parseFloat(debtValue) +
    parseFloat(alternativeValue);
  const [duration, setDuration] = useState(ipsData[2].duration);
  const LegendItem = ({ color, text }) => {
    return (
      <div className="flex">
        <div
          className={`w-[7px] h-[7px] block mt-[7px] `}
          style={{ background: color }}
        />
        <p className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] ml-[0.2rem] mb-0">
          {text}
        </p>
      </div>
    );
  };
  useEffect(() => {
    setEquity(
      ipsData[1].large_cap === 0 &&
        ipsData[1].mid_cap === 0 &&
        ipsData[1].small_cap === 0
        ? [33.3, 100 - 33.3]
        : [
            parseInt(ipsData[1].large_cap * 100),
            parseInt((1 - ipsData[1].large_cap) * 100),
          ]
    );
    setDebt([
      parseInt(ipsData[3].credit_risk * 100),
      parseInt((1 - ipsData[3].credit_risk) * 100),
    ]);
    setAlternatives([
      parseInt(ipsData[4].alternates_low_vol * 100),
      parseInt((1 - ipsData[4].alternates_low_vol) * 100),
    ]);
    setEquityValue(parseFloat((ipsData[0].equity * 100).toFixed(1)));
    setDebtValue(parseFloat((ipsData[0].debt * 100).toFixed(1)));
    setAlternativeValue(parseFloat((ipsData[0].alternates * 100).toFixed(1)));
    setDuration(ipsData[2].duration);
    dispatch(ResetAllocationPreference());
    // eslint-disable-next-line
  }, [ipsData]);

  return (
    <>
      <div className="px-8 rounded-[8px] w-full bg-white py-8 mt-[16px]">
        <div className="flex justify-between w-full">
          <h2 className="card-heading">Asset Allocation</h2>
          <div className="flex">
            <InvestedgeButton
              className="mr-4"
              onClick={() => {
                dispatch(
                  PostEventLoggingData({
                    module_name: "Portfolio Insights",
                    event_type: "Prev button",
                    url: window.location.href,
                    note: `Third Screen`,
                  })
                );
                handleBack();
              }}
            >
              Prev
            </InvestedgeButton>
            <InvestedgeButton
              disabled={Math.round(assetSum * 10) / 10 !== 100}
              onClick={() => {
                setPortfolioProducts([]);
                dispatch(
                  GetAllocationPreference({
                    ...formValue,
                    proposal_value: {
                      amount: parseInt(formValue.proposal_value.amount),
                      denomination: formValue.proposal_value.denomination[2],
                    },
                    allocation_preference: {
                      equity: parseFloat(Math.round(equityValue * 10) / 1000),
                      debt: parseFloat(Math.round(debtValue * 10) / 1000),
                      alternate: parseFloat(
                        Math.round(alternativeValue * 10) / 1000
                      ),
                      large: parseFloat(
                        Math.round(EquityWidths[0] * 10) / 1000
                      ),
                      mid: parseFloat(Math.round(EquityWidths[1] * 10) / 2000),
                      small: parseFloat(
                        Math.round(EquityWidths[1] * 10) / 2000
                      ),
                      AAA: parseFloat(Math.round(DebtWidths[0] * 10) / 1000),
                      duration: parseFloat(duration),
                      low_volatility: parseFloat(
                        Math.round(AlternativesWidths[0] * 10) / 1000
                      ),
                    },
                  })
                );
                setFormValue({
                  ...formValue,

                  allocation_preference: {
                    equity: parseFloat(Math.round(equityValue * 10) / 1000),
                    debt: parseFloat(Math.round(debtValue * 10) / 1000),
                    alternate: parseFloat(
                      Math.round(alternativeValue * 10) / 1000
                    ),
                    large: parseFloat(Math.round(EquityWidths[0] * 10) / 1000),
                    mid: parseFloat(Math.round(EquityWidths[1] * 10) / 2000),
                    small: parseFloat(Math.round(EquityWidths[1] * 10) / 2000),
                    AAA: parseFloat(Math.round(DebtWidths[0] * 10) / 1000),
                    duration: parseFloat(duration),
                    low_volatility: parseFloat(
                      Math.round(AlternativesWidths[0] * 10) / 1000
                    ),
                  },
                });
                dispatch(
                  EditIpsData([
                    {
                      equity: parseFloat(Math.round(equityValue * 10) / 1000),
                      debt: parseFloat(Math.round(debtValue * 10) / 1000),
                      alternates: parseFloat(
                        Math.round(alternativeValue * 10) / 1000
                      ),
                    },
                    {
                      large_cap: parseFloat(
                        Math.round(EquityWidths[0] * 10) / 1000
                      ),
                      mid_cap: parseFloat(
                        Math.round(EquityWidths[1] * 10) / 2000
                      ),
                      small_cap: parseFloat(
                        Math.round(EquityWidths[1] * 10) / 2000
                      ),
                    },
                    { duration: parseFloat(duration) },
                    {
                      credit_risk: parseFloat(
                        Math.round(DebtWidths[0] * 10) / 1000
                      ),
                    },
                    {
                      alternates_low_vol: parseFloat(
                        Math.round(AlternativesWidths[0] * 10) / 1000
                      ),
                    },
                  ])
                );
                dispatch(
                  PostEventLoggingData({
                    module_name: "Portfolio Insights",
                    event_type: "Next button",
                    url: window.location.href,
                    note: `Third Screen`,
                  })
                );
                handleNext();
              }}
            >
              Next
            </InvestedgeButton>
          </div>
        </div>
        <p className="w-full leading-[20px] font-inter text-xs text-neutralBlack mb-0 mt-[4px]">
          Basis your client’s risk score we have recommended the below asset
          allocation. You can change the asset allocation as per your
          requirement. Sum of desired asset allocation should be 100.0.
          <br /> Right now :
          <span
            className={
              Math.round(assetSum * 10) / 10 !== 100
                ? "text-error-200  ml-[0.2rem]"
                : " ml-[0.2rem]"
            }
          >
            {Math.round(assetSum * 10) / 10}
          </span>
        </p>
        <div className="flex flex-col items-center w-full mt-[1rem]">
          <table className="w-full p-[1rem] investedge-table">
            <colgroup>
              <col width="25%" />
              <col width="30%" />
              <col width="45%" />
            </colgroup>
            <tbody>
              <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                <th className="pl-[1rem] text-center rounded-tl-[10px] font-medium border-b border-b-neutralBlack">
                  Asset Class
                </th>
                <th className="text-center font-medium">
                  Desired Allocation (in %)
                </th>
                <th className="rounded-tr-[10px] text-center font-medium">
                  Sub-Asset Allocation
                </th>
              </tr>

              <tr className="border-b border-[#efefef]">
                <td className=" text-center font-inter border-t-[0px] border-neutralBlack">
                  Equity
                </td>
                <td className="border-t border-neutralBlack">
                  <div className="flex justify-center">
                    <img
                      src={Subtract}
                      alt="err"
                      className="mr-[5%]"
                      onClick={() =>
                        setEquityValue(
                          Math.round((parseFloat(equityValue) - 0.1) * 1e12) /
                            1e12
                        )
                      }
                    />
                    <input
                      className="w-[18%] h-[40px] shadow-none text-center font-inter text-[14px] bg-[#f3f3f3] border-none"
                      value={equityValue}
                      onChange={(e) => {
                        if (
                          e.target.value <= 100 &&
                          (!e.target.value.split(".")[1] ||
                            e.target.value.split(".")[1].length <= 1)
                        )
                          setEquityValue(e.target.value);
                      }}
                    />
                    <img
                      src={Add}
                      alt="err"
                      className="ml-[5%]"
                      onClick={() =>
                        setEquityValue(
                          Math.round((parseFloat(equityValue) + 0.1) * 1e12) /
                            1e12
                        )
                      }
                    />
                  </div>
                </td>
                <td className="p-[2rem] border-t border-neutralBlack">
                  <PortfolioThirdStepSlider
                    colors={ThreeColors}
                    width={EquityWidths}
                    setWidth={setEquity}
                  />
                  <div className="flex justify-evenly w-full z-[100] pt-[1rem]">
                    <LegendItem color="#156DD0" text="Large Cap" />
                    {/* <LegendItem color="#52A1FA" text="Mid Cap" /> */}
                    <LegendItem
                      color="#BFE0FF"
                      text="Mid Cap &amp; Small Cap"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className=" font-inter text-center" rowSpan={2}>
                  Debt
                </td>
                <td rowSpan={2}>
                  <div className="flex justify-center items-center">
                    <img
                      src={Subtract}
                      alt="err"
                      className="mr-[5%]"
                      onClick={() =>
                        setDebtValue(
                          Math.round((parseFloat(debtValue) - 0.1) * 1e12) /
                            1e12
                        )
                      }
                    />
                    <input
                      className="w-[18%] h-[40px] shadow-none text-center font-inter text-[14px] bg-[#f3f3f3] border-none"
                      value={debtValue}
                      onChange={(e) => {
                        if (
                          e.target.value <= 100 &&
                          (!e.target.value.split(".")[1] ||
                            e.target.value.split(".")[1].length <= 1)
                        )
                          setDebtValue(e.target.value);
                      }}
                    />
                    <img
                      src={Add}
                      alt="err"
                      className="ml-[5%]"
                      onClick={() =>
                        setDebtValue(
                          Math.round((parseFloat(debtValue) + 0.1) * 1e12) /
                            1e12
                        )
                      }
                    />
                  </div>
                </td>
                <td className="p-[2rem]">
                  <PortfolioThirdStepSlider
                    colors={TwoColors}
                    width={DebtWidths}
                    setWidth={setDebt}
                  />
                  <div className="flex justify-evenly w-full z-[100] pt-[1rem]">
                    <LegendItem color="#156DD0" text={"AAA and Equivalent"} />
                    <LegendItem color="#BFE0FF" text={"Others"} />
                  </div>
                </td>
              </tr>
              <tr className="border-b border-[#efefef]">
                <td className="p-[2rem]">
                  <div className="flex justify-center items-center">
                    <p className=" font-inter text-[13px] font-medium leading-[20px] tracking-[0em] text-left mt-[2px] mb-0 mr-[5%]">
                      Maximum Duration
                    </p>
                    <img
                      src={Subtract}
                      alt="err"
                      className="mr-[3%]"
                      onClick={() =>
                        setDuration(
                          Math.round((parseFloat(duration) - 0.1) * 1e12) / 1e12
                        )
                      }
                    />
                    <input
                      className="w-[18%] h-[40px] shadow-none text-center font-inter text-[14px] bg-[#f3f3f3] border-none"
                      value={duration}
                      onChange={(e) => {
                        if (
                          e.target.value <= 100 &&
                          (!e.target.value.split(".")[1] ||
                            e.target.value.split(".")[1].length <= 1)
                        )
                          setDuration(e.target.value);
                      }}
                    />
                    <img
                      src={Add}
                      alt="err"
                      style={{ marginLeft: "3%" }}
                      onClick={() =>
                        setDuration(
                          Math.round((parseFloat(duration) + 0.1) * 1e12) / 1e12
                        )
                      }
                    />
                    <p className=" font-inter text-[13px] font-medium leading-[20px] tracking-[0em] text-left mt-[2px] mb-0 ml-[5%]">
                      Years
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td className=" font-inter text-center">Alternate</td>
                <td>
                  <div className="flex justify-center">
                    <img
                      src={Subtract}
                      alt="err"
                      className="mr-[5%]"
                      onClick={() =>
                        setAlternativeValue(
                          Math.round(
                            (parseFloat(alternativeValue) - 0.1) * 1e12
                          ) / 1e12
                        )
                      }
                    />
                    <input
                      className="w-[18%] h-[40px] shadow-none text-center font-inter text-[14px] bg-[#f3f3f3] border-none"
                      value={alternativeValue}
                      onChange={(e) => {
                        if (
                          e.target.value <= 100 &&
                          (!e.target.value.split(".")[1] ||
                            e.target.value.split(".")[1].length <= 1)
                        )
                          setAlternativeValue(e.target.value);
                      }}
                    />
                    <img
                      src={Add}
                      alt="err"
                      className="ml-[5%]"
                      onClick={() =>
                        setAlternativeValue(
                          Math.round(
                            (parseFloat(alternativeValue) + 0.1) * 1e12
                          ) / 1e12
                        )
                      }
                    />
                  </div>
                </td>
                <td className="p-[2rem]">
                  <PortfolioThirdStepSlider
                    colors={TwoColors}
                    width={AlternativesWidths}
                    setWidth={setAlternatives}
                  />
                  <div className="flex justify-evenly w-full z-[100] pt-[1rem]">
                    <LegendItem color="#156DD0" text={"Low Volatility"} />
                    <LegendItem color="#BFE0FF" text={"High Volatility"} />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* <div className="px-8 rounded-[8px] w-full bg-white pt-[32px] pb-[16px] mt-[16px]">
        
      </div> */}
    </>
  );
}
