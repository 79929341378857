import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import { FiArrowRight } from "react-icons/fi";
import Individual from "../../assets/img/individual_account_type.svg";
// import Family from "../../assets/img/family_account_type.svg";
import Tick from "../../assets/img/circle_over_tick.svg";
import FilledTick from "../../assets/img/filled_circle_over_tick.svg";
// import Lock from "../../assets/img/lock_locked_blue.svg";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  // OpenSnackbar,
  PostEventLoggingData,
  UpdateClientData,
} from "../../actions/actions";
import InvestedgeModal from "../../components/modal/InvestedgeModal";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
export default function AccountTypeModal({ onClose, open, clientType }) {
  const [accountType, setaccountType] = useState("Individual");
  let newClientData = useSelector((state) => state.clientinputdata);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(
      PostEventLoggingData({
        module_name: "Client Details",
        event_type: "Next button",
        url: window.location.href,
        note: `Individual or Family selected`,
      })
    );
    newClientData.accountType = accountType;
    newClientData.clientType = clientType;
    dispatch(UpdateClientData(newClientData));
  };
  return (
    <InvestedgeModal
      aria-labelledby="Account type Modal"
      aria-describedby="Account type Modal"
      open={open}
      handleclose={onClose}
    >
      <div className="flex justify-between w-full">
        <h3 className="card-heading leading-[28px] tracking-[0em] text-left">
          Add New Client
        </h3>
        <CgClose size={20} onClick={onClose} />
      </div>
      <div className="flex justify-center w-full mt-[1rem]">
        <div
          className={
            accountType === "Individual"
              ? "w-[45%] border border-[#e0dddd] p-[1rem] bg-primary-accent"
              : "w-[45%] border border-[#e0dddd] p-[1rem] bg-white"
          }
          onClick={() => setaccountType("Individual")}
        >
          <div className="flex justify-start items-start w-full">
            <img
              src={accountType === "Individual" ? FilledTick : Tick}
              alt="err"
            />
          </div>
          <div className="flex flex-col justify-center items-center w-full">
            <img
              className=" w-[50px] object-contain rounded-[100%]"
              src={Individual}
              alt="err"
            />
            <p className="card-heading leading-[29px] text-left mt-[1rem] ">
              Individual
            </p>
            <p className="font-inter text-[12px] not-italic font-normal leading-[16px] tracking-[0em] text-center mt-[1rem] mb-0 text-[#777777]">
              An individual account is for a single person without any family
              structure
            </p>
          </div>
        </div>
        {/* <div
          className="w-[45%] border border-[#e0dddd] p-[1rem]"
          style={
            accountType === "Family"
              ? { backgroundColor: "#e6e9f7" }
              : { backgroundColor: "#fff" }
          }
          // onClick={() => setaccountType("Family")}
          onClick={() =>
            dispatch(
              OpenSnackbar({
                severity: "info",
                message: "Feature will be coming soon",
              })
            )
          }
        >
          <div className="flex justify-start items-start w-full">
            <img src={accountType === "Family" ? FilledTick : Lock} alt="err" />
          </div>
          <div className="flex flex-col justify-center items-center w-full">
            <img
              className="w-[50px] object-contain rounded-[100%] opacity-[0.9]"
              src={Family}
              alt="err"
            />
            <p className="card-heading leading-[29px] text-left mt-[1rem] ">
              Family
            </p>
            <p className="font-inter text-[12px] not-italic font-normal leading-[16px] tracking-[0em] text-center mt-[1rem] mb-0 text-[#777777]">
              A family account has a key person and several members of the
              family
            </p>
          </div>
        </div> */}
      </div>
      <div className="flex justify-end w-full">
        <Link
          to={{
            pathname: "/clientinput/familydetails",
            state: {
              accounttype: accountType,
            },
          }}
        >
          <InvestedgeButton
            onClick={() => accountType.length && handleClick()}
            className="mt-4"
          >
            Next <FiArrowRight color="#fff" size={18} />
          </InvestedgeButton>
        </Link>
      </div>
    </InvestedgeModal>
  );
}
