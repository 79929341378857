import React, { useEffect, useState } from "react";
import Vector from "../../assets/img/modelportfolioemptylist.svg";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import Plus from "../../assets/img/add_new_icon.svg";
import InvestedgeSearchField from "../../components/searchfield/InvestedgeSearchField";
import PortfolioCard from "./PortfolioCard";
import InvestedgePagination from "../../components/pagination/InvestedgePagination";
import InvestedgeTabs from "../../components/tabs/InvestedgeTabs";
import {
  EmptyModelPortfolioAnalytics,
  GetModelPortfolio,
  PostEventLoggingData,
} from "../../actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
export default function ModelPortfolioContent() {
  const modelportfolios = useSelector((state) => state.getModelPortfolio);
  const [portfolioType, setPortfolioType] = useState(0);
  const [searchClient, setSearchClient] = useState("");
  const [bookmarkPortfolios, setBookmarkPortfolios] = useState({});
  const [paginatedData, setPaginatedData] = useState(modelportfolios);
  const tabs = [{ label: "All" }, { label: "Bookmarked" }];
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(0);
  const clientsPerPage = 10;
  const pagesVisited = pageNumber * clientsPerPage;
  const pageCount = Math.ceil(paginatedData.length / clientsPerPage);
  const changePage = (e, val) => {
    setPageNumber(val - 1);
  };

  useEffect(() => {
    setPaginatedData(
      modelportfolios.filter(
        (portfolio) =>
          (portfolioType === 0
            ? portfolio.bookmark !== !portfolio.bookmark
            : bookmarkPortfolios[portfolio.id]) &&
          portfolio.portfolio_name
            .toLowerCase()
            .includes(searchClient.toLowerCase())
      )
    );
    setPageNumber(0); // eslint-disable-next-line
  }, [searchClient, portfolioType]);

  useEffect(() => {
    setPaginatedData(modelportfolios);
    const temp = bookmarkPortfolios;
    for (let i = 0; i < modelportfolios.length; i++)
      temp[modelportfolios[i].id] = modelportfolios[i].bookmark;
    setBookmarkPortfolios(temp); // eslint-disable-next-line
  }, [modelportfolios]);

  useEffect(() => {
    dispatch(GetModelPortfolio());
  }, [dispatch]);

  return (
    <div className="main-panel bg-[white] p-4 min-h-[calc(100vh_-_116px)] rounded-[4px] w-full relative ">
      {modelportfolios.length ? (
        <>
          <div className="h-auto ">
            <div className="flex justify-between w-[100%] items-center">
              <h2 className="card-heading">Model Portfolios</h2>
            </div>

            <div className="flex justify-between w-full mt-[1rem]">
              <InvestedgeTabs
                tabs={tabs}
                tabvalue={portfolioType}
                handletabchange={(e, value) => {
                  dispatch(
                    PostEventLoggingData({
                      module_name: "Model Portfolio",
                      event_type: "Information",
                      url: window.location.href,
                      note: "Tabs clicked",
                    })
                  );
                  setPortfolioType(value);
                }}
              />
              <div className="flex items-end w-full justify-end mb-2 space-x-2">
                <InvestedgeSearchField
                  type="text"
                  className="w-1/4"
                  value={searchClient}
                  onChange={(e) => setSearchClient(e.target.value)}
                  placeholder="Search portfolios"
                />
                <Link to="/modelportfolio/open">
                  <InvestedgeButton
                    sx={{ marginBottom: "2px" }}
                    className="flex justify-between items-center ml-4"
                    onClick={() => {
                      dispatch(EmptyModelPortfolioAnalytics());
                      dispatch(
                        PostEventLoggingData({
                          module_name: "Client Details",
                          event_type: "Add Button",
                          url: window.location.href,
                          note: `Add new client clicked`,
                        })
                      );
                    }}
                  >
                    <img
                      className="w-[14px] object-contain mr-2"
                      src={Plus}
                      alt="err"
                    />
                    Create New
                  </InvestedgeButton>
                </Link>
              </div>
            </div>
          </div>
          <div className="w-full grid grid-cols-5 gap-5 pt-8">
            {paginatedData
              .slice(pagesVisited, pagesVisited + clientsPerPage)
              .map((portfolio, index) => {
                return (
                  <PortfolioCard
                    portfolio={portfolio}
                    bookmarkPortfolios={bookmarkPortfolios}
                    setBookmarkPortfolios={setBookmarkPortfolios}
                    key={index}
                    setPortfolioType={setPortfolioType}
                  />
                );
              })}
          </div>
          <div className="w-full flex justify-end absolute bottom-4 right-4">
            <InvestedgePagination
              count={pageCount}
              defaultPage={1}
              page={pageNumber + 1}
              onChange={changePage}
              color="primary"
            />
          </div>
        </>
      ) : (
        <>
          <h2 className="card-heading">Model Portfolios</h2>
          <div className="h-[600px] w-full flex flex-col justify-center items-center">
            <img src={Vector} alt="err" />
            <p className="card-heading mt-8">
              No Portfolios Found. Start creating one now!
            </p>
            <Link to="/modelportfolio/open">
              <InvestedgeButton
                className="mt-6"
                onClick={() => {
                  dispatch(EmptyModelPortfolioAnalytics());
                  dispatch(
                    PostEventLoggingData({
                      module_name: "Client Details",
                      event_type: "Add Button",
                      url: window.location.href,
                      note: `Add new client clicked`,
                    })
                  );
                }}
              >
                <img
                  className=" mr-[0.5rem] object-contain w-[14px]"
                  src={Plus}
                  alt="err"
                />
                Create New
              </InvestedgeButton>
            </Link>
          </div>
        </>
      )}
    </div>
  );
}
