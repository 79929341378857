import React, { useEffect } from "react";
import AfterloginApp from "../../AfterloginApp";
import ClientSidebar from "../ClientSidebar";
import "../../../static/css/existing/dashboard_styles.css";
import UpdatePortfoliosContent from "./UpdatePortfoliosContent";
import {
  GetDashboardPortfolioList,
  GetReviewPortfolioSummary,
} from "../../../actions/actions";
import { useDispatch, useSelector } from "react-redux";
export default function UpdatePortfolios(props) {
  const portfolioUpload = useSelector(
    (store) => store.postClientInputUploadFile
  );
  const { familyMembers, clientId } =
    (props.location && props.location.state) || {};
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      GetDashboardPortfolioList({
        params: {
          client_id: clientId,
        },
      })
    );
    dispatch(
      GetReviewPortfolioSummary({
        params: {
          client_id: clientId,
        },
      })
    ); // eslint-disable-next-line
  }, [dispatch, portfolioUpload, clientId]);
  const portfolioValue = useSelector(
    (store) => store.dashboardPortfolioPriceList.total_portfolio_value
  );
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Client Input"}
        description={
          "Browse for portfolio insights, actionable, cash flow planning, and much more"
        }
      />
      <div className="loggedinscreen-container pt-0">
        <div className="loggedinscreen flex flex-row justify-between">
          <ClientSidebar />
          <div className="p-[2rem] bg-[#fff] rounded-[0.5rem] min-h-full client-input-parent-card w-[79%] ml-0 pt-[1rem] mt-4">
            <UpdatePortfoliosContent
              familyMembers={familyMembers}
              clientId={clientId}
              portfolioValue={portfolioValue}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
