import React, { useRef, useState } from "react";
import Cloud from "../../../assets/img/greyuploadcloud.svg";
import Tick from "../../../assets/img/module_access_type_tick.svg";
import TextField from "@mui/material/TextField";
import {
  PostClientInputUploadFile,
  GetReviewPortfolioSummary,
  PostEventLoggingData,
  GetDashboardPortfolioList,
} from "../../../actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { Bars } from "react-loader-spinner";
export default function UploadStatementScreen({ statementtype }) {
  const [file, setFile] = useState(null);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const excelInputRef = useRef(null);
  const [fileUpload, setFileUpload] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const portfolioUpload = useSelector(
    (store) => store.postClientInputUploadFile
  );
  return (
    <div className="flex flex-col justify-center items-center h-[90%]">
      <p className=" font-inter font-medium text-[20px] text-neutralBlack mb-[1rem]">
        Upload {statementtype} Statement
      </p>
      {!submitted ? (
        <div
          style={{ width: "60%" }}
          className="family-details-fieldset portfolio-details-fieldset upload-statement-fieldset"
        >
          <img className="w-[25px] h-[25px]" src={Cloud} alt="err" />
          <FileUploader
            multiple={false}
            handleChange={(file) => {
              setFileUpload(true);
              setFile(file);
            }}
            name="file"
            types={["PDF"]}
          >
            <p className="font-inter font-medium text-[14px] text-neutralBlack mb-[1rem] mt-[0.5rem]">
              Drag and Drop account statement
            </p>
          </FileUploader>
          <p className="font-inter font-medium text-[14px] text-neutralBlack mb-[1rem]">
            Or
          </p>
          <input
            ref={excelInputRef}
            className="hidden"
            onInput={() => {
              setFileUpload(true);
              setFile(excelInputRef.current.files[0]);
            }}
            type="file"
            accept=".pdf"
          />
          {!fileUpload ? (
            <div
              className=" bg-[#e6e9f7] font-inter text-primary-200 py-[0.4rem] px-[1.25rem] text-center rounded-[0.25rem] text-[14px] font-medium"
              onClick={() => excelInputRef.current.click()}
            >
              Browse
            </div>
          ) : (
            <img src={Tick} alt="err" className="mx-auto" />
          )}

          <TextField
            sx={{
              fontFamily: "Inter,sans-sarif",
              fontWeight: 400,
              width: "40%",
              marginTop: "1rem",
            }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label="Password, if any"
            type="password"
          />

          <div className="flex justify-end w-full">
            <button
              className={
                fileUpload
                  ? "bg-primary-200 text-[#fff] font-semibold py-2 px-3 rounded shadow-boxShadowButton font-inter normal-case active:opacity-[0.8] focus:outline-none"
                  : "text-primaryDisabledClr bg-primaryDisabledBgClr font-semibold py-2 px-3 rounded shadow-boxShadowButton font-inter normal-case active:opacity-[0.8] focus:outline-none"
              }
              onClick={() => {
                fileUpload &&
                  dispatch(
                    PostClientInputUploadFile({
                      client_id: localStorage.getItem("clientId"),
                      file: file,
                      file_type: 1,
                      password: password,
                    })
                  );
                fileUpload && setSubmitted(true);
                fileUpload &&
                  dispatch(
                    GetDashboardPortfolioList({
                      params: {
                        client_id: localStorage.getItem("clientId"),
                      },
                    })
                  );
                fileUpload &&
                  dispatch(
                    GetReviewPortfolioSummary({
                      params: {
                        client_id: localStorage.getItem("clientId"),
                      },
                    })
                  );
                fileUpload &&
                  dispatch(
                    PostEventLoggingData({
                      module_name: "Client Profile",
                      event_type: "Submit button",
                      url: window.location.href,
                      note: `${localStorage.getItem(
                        "clientName"
                      )} Portfolio updated`,
                    })
                  );
              }}
            >
              Submit
            </button>
          </div>
        </div>
      ) : portfolioUpload === "loading" ? (
        <div className="w-full min-h-[250px] flex flex-col justify-center items-center opacity-[0.9]">
          <Bars color="#1982F8" />
          <p className=" font-inter text-[14px] font-medium text-neutralBlack mb-[1rem] mt-[1rem]">
            Uploading Portfolio ...
          </p>
        </div>
      ) : portfolioUpload === "failed" ? (
        <p className=" font-inter text-[14px] font-medium text-neutralBlack mb-[1rem] mt-[3rem]">
          Something failed, Try again!
        </p>
      ) : (
        <p className=" font-inter text-[14px] font-medium text-neutralBlack mb-[1rem] mt-[3rem]">
          Submitted Successfully!
        </p>
      )}
    </div>
  );
}
