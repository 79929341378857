import { GET_REVIEW_ADVANCE_ANALYTICS } from "../actions/type";
const initialState = {
  before_equity_cagr: 0.25649488721804514,
  before_equity_bm_cagr: 0.25090799331665037,
  before_equity_category_allocation: {
    "Large Cap": 0.3763139453398739,
    "Mid Cap": 0.2648913805185704,
    "Flexi Cap": 0.12754029432375613,
    "Small cap": 0.0967063770147162,
    ETF: 0.06797477224947443,
    Others: 0.06657323055360898,
  },
  before_equity_product_allocation: {
    "Mutual Fund": 0.9586545199719693,
    AIF: 0.04134548002803083,
  },
  before_full_sector_allocation: {
    Financials: 0.3014427872850865,
    "Consumer Discretionary": 0.1417543613685334,
    Materials: 0.11900426161191946,
    "Health Care": 0.10428084089584587,
    Industrials: 0.09773375397119483,
    "Information Technology": 0.08787795198185226,
    "Consumer Staples": 0.056873184893973416,
    Utilities: 0.03751024715223223,
    Energy: 0.03281307114837468,
    "Real Estate": 0.011257895356414225,
    "Communication Services": 0.009451644334573284,
  },
  before_geographical_allocation: {
    "Domestic Equities": 0.9360072367976349,
    "Overseas Equities": 0.06399276320236508,
  },
  before_equity_products_count: 12,
  before_debt_cagr: 0.04851066319895968,
  before_debt_bm_cagr: 0.05938215039540151,
  before_debt_category_allocation: {
    "Ultra Short Duration": 0.6474760610001182,
    ETF: 0.2767466603617449,
    "High Yield Credit": 0.05473460219884147,
    "Medium to Long Duration": 0.01075777278638137,
    "Short Duration": 0.005910864168341412,
    Others: 0.004374039484572645,
  },
  before_debt_product_allocation: {
    "Mutual Fund": 0.9408913583165859,
    PMS: 0.054734602198841464,
    Deposits: 0.004374039484572644,
  },
  before_debt_products_count: 7,
  before_alternate_cagr: 0.05425221238938052,
  before_alternate_bm_cagr: 0.05370800018310547,
  before_alternate_category_allocation: {
    ETF: 0.5929203539823008,
    "Gold or Related": 0.07964601769911503,
    "Real Estate": 0.3274336283185841,
  },
  before_alternate_product_allocation: {
    "Gold or Related": 0.07964601769911503,
    "Mutual Fund": 0.5929203539823008,
    "Real Estate": 0.3274336283185841,
  },
  before_alternate_products_count: 3,
  after_equity_cagr: 0.2430050849878102,
  after_equity_bm_cagr: 0.25090799331665037,
  after_equity_category_allocation: {
    ETF: 0.14225178716545167,
    "Large Cap": 0.5732446385036452,
    Value: 0.14225178716545167,
    "Index Fund": 0.14225178716545167,
  },
  after_equity_product_allocation: {
    "Mutual Fund": 1.0,
  },
  after_full_sector_allocation: {
    Financials: 0.3892768535615603,
    "Information Technology": 0.14617975878040596,
    "Consumer Discretionary": 0.0962908751362212,
    Energy: 0.08374155462949383,
    "Consumer Staples": 0.0731504174695477,
    Materials: 0.054083297630196675,
    Industrials: 0.053478272829591215,
    "Health Care": 0.041683397452108605,
    "Communication Services": 0.03361792181196752,
    Utilities: 0.026852885623682387,
    "Real Estate": 0.001644765075224708,
  },
  after_geographical_allocation: {
    "Domestic Equities": 0.9994877969495775,
    "Overseas Equities": 0.0005122030504225526,
  },
  after_equity_products_count: 7,
  after_debt_cagr: 0.05936805390631601,
  after_debt_bm_cagr: 0.05938215039540151,
  after_debt_category_allocation: {
    "Dynamic Bond": 0.01512309029244617,
    "Short Duration": 0.9848769097075539,
  },
  after_debt_product_allocation: {
    "Mutual Fund": 1.0,
  },
  after_debt_products_count: 2,
  after_alternate_cagr: 0.0,
  after_alternate_bm_cagr: 0.05370800018310547,
  after_alternate_category_allocation: {
    "Unlisted Shares": 0.4513152249859408,
    "Real Estate": 0.5486847750140592,
  },
  after_alternate_product_allocation: {
    "Real Estate": 0.5486847750140592,
    "Unlisted Shares": 0.4513152249859408,
  },
  after_alternate_products_count: 19,
  loading: true,
};
export default function getReviewAdvanceAnalytics(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_REVIEW_ADVANCE_ANALYTICS:
      return action.payload;
    default:
      return state;
  }
}
