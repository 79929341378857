import React from "react";
import { performance } from "../onePagerConsts";

export default function performanceComponent() {
  return (
    <>
      {/* #7 Contains performance card text */}
      <div
        style={{
          backgroundColor: "#fff",
          padding: "1rem",

          borderRadius: "6px",
          boxShadow:
            " 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(0, 0, 0, 0.05)",
          minheight: "274px",
        }}
      >
        <p className="onepager-description-text">{performance.cardText}</p>
      </div>
    </>
  );
}
