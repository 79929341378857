import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Hourglass from "../assets/img/wait.gif";
import Women from "../assets/img/modal_chr.svg";
import { GetDashboardPortfolioList } from "../actions/actions";
import { useDispatch } from "react-redux";
export default function MFCentralResultScreen() {
  const data = useSelector((state) => state.mfCentralPayload);
  const dispatch = useDispatch();
  useEffect(() => {
    if (data.status === "created") {
      dispatch(
        GetDashboardPortfolioList({
          params: {
            client_id: localStorage.getItem("clientId"),
          },
        })
      );
    }
  }, [data, dispatch]);

  return (
    <div className="min-w-[800px] flex-col justify-center items-center min-h-[500px] mx-auto pt-20">
      <img
        alt="err"
        src={data.loading ? Hourglass : Women}
        className="h-[200px] object-contain mx-auto"
      />
      <h3 className="portfolio-stepper-sixth-form-status">
        {data.loading
          ? "Sometimes, great things take time."
          : "Portfolio Update is under process. Please come back in sometime."}
      </h3>
      {/* {data.loading && (
        <h3 className=" font-inter text-[14px] font-medium leading-[16px] tracking-[0em] text-center">
          Your request is under process. We will notify you post successful
          verification.
        </h3>
      )} */}
    </div>
  );
}
