import { OutlinedInput } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PieChart from "../../../rmdashboard/dashboard/PieChart";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import { useEffect, useState } from "react";
import Portfolio from "./RecommendedProducts/Portfolio";
import { useDispatch } from "react-redux";
import { GetAllocationPreference } from "../../../actions/actions";
import urls from "../../../urls";
import axios from "axios";
export const AssetAllocation = ({ goalDetails, goalData }) => {
  const denotionParser = (denotion, value) => {
    if (denotion === "K") return parseInt(value) * 1000;
    else if (denotion === "L") return parseInt(value) * 100000;
    else return value * 10000000;
  };
  const dispatch = useDispatch();
  const sipData = [
    {
      val: `₹ ${parseFloat(
        goalDetails.current_value_of_portfolio.toFixed(1)
      ).toLocaleString("en-IN")}`,
      label: "Current Value of Portfolio",
    },
    {
      val: `₹ ${denotionParser(
        goalData.ongoing_sip.denomination,
        goalData.ongoing_sip.amount
      ).toLocaleString("en-IN")}`,
      label: "Ongoing SIP",
    },
    {
      val:
        goalDetails.required_rate_to_reach_goal <= 0
          ? "NA"
          : `${(goalDetails.required_rate_to_reach_goal * 100).toFixed(1)} %`,
      label: "Required return to Reach Goal",
    },
  ];
  const [open, setOpen] = useState(false);
  // const [returnRate, setReturnRate] = useState(0.0);
  const [comparedData, setComparedData] = useState({
    equity_percentage: 0,
    alternate_percentage: 0.0,
    debt_percentage: 0.0,
    proposed_equity: 1.0,
    proposed_alternate: 0.0,
    proposed_debt: 0.0,
  });
  const [allocation, setAllocation] = useState({ equity: "", debt: "" });
  const handleCalculate = () => {
    axios
      .post(urls.goalsAssetAllocationURL, {
        client_id: localStorage.getItem("clientId"),
        goal_id: goalData.id,
        return_required: goalDetails.required_rate_to_reach_goal,
        equity_alternates: allocation.equity / 100,
        debt: allocation.debt / 100,
      })
      .then((response) => {
        setComparedData(response.data);
        setOpen(true);
      })
      .catch(() => {});
  };
  const Legend = ({ color, text }) => {
    return (
      <div className="flex ">
        <div
          className="rounded-[4px] w-[14px] h-[14px] mt-[3px] mr-[15px]"
          style={{
            backgroundColor: color,
          }}
        />
        <p className="onepager-description-text">{text}</p>
      </div>
    );
  };
  const CurrentData = {
    labels: ["Equity", "Debt", "Alternative"],
    datasets: [
      {
        data: [
          (comparedData.equity_percentage * 100).toFixed(1),
          (comparedData.debt_percentage * 100).toFixed(1),
          (comparedData.alternate_percentage * 100).toFixed(1),
        ],
        backgroundColor: ["#ED6D84", "#F8CE6C", "#56A1E5", "#D4D4D4"],
        borderColor: ["#ED6D84", "#F8CE6C", "#56A1E5", "#D4D4D4"],
        borderWidth: 1,
      },
    ],
  };
  const ProposedData = {
    labels: ["Equity", "Debt", "Alternative"],
    datasets: [
      {
        data: [
          (comparedData.proposed_equity * 100).toFixed(1),
          (comparedData.proposed_debt * 100).toFixed(1),
          (comparedData.proposed_alternate * 100).toFixed(1),
        ],
        backgroundColor: ["#ED6D84", "#F8CE6C", "#56A1E5", "#D4D4D4"],
        borderColor: ["#ED6D84", "#F8CE6C", "#56A1E5", "#D4D4D4"],
        borderWidth: 1,
      },
    ],
  };
  const pieOptions = {
    legend: {
      display: false,
    },
    plugins: {
      labels: [
        {
          render: "label",
          position: "outside",
          // arc: true,
          fontFamily: "Inter,sans-serif",
          fontColor: "#323232",
          textMargin: 15,
          fontSize: 14,
        },
        {
          render: function (args) {
            return `${args.value}%`;
          },
          fontColor: "#fff",
        },
      ],
      datalabels: {
        display: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };
  const diff_years = (dt2, dt1) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24;
    return Math.abs(Math.round(diff / 365.25));
  };
  // useEffect(() => {
  //   const monthDiff = (d1, d2) => {
  //     var months;
  //     months = (d2.getFullYear() - d1.getFullYear()) * 12;
  //     months -= d1.getMonth();
  //     months += d2.getMonth();
  //     return months <= 0 ? 0 : months;
  //   };
  //   axios
  //     .post(urls.goalsReturnPercentURL, {
  //       client_id: localStorage.getItem("clientId"),
  //       goal_id: goalData.id,
  //       goal_ends_on: monthDiff(new Date(), new Date(goalData.end_date)),
  //       ongoing_sip: denotionParser(
  //         goalData.ongoing_sip.denomination,
  //         goalData.ongoing_sip.amount
  //       ),
  //     })
  //     .then((response) => {
  //       setReturnRate(response.data.rate_percent_required);
  //     })
  //     .catch(() => {
  //       setReturnRate(0.0);
  //     }); //eslint-disable-next-line
  // }, []);

  useEffect(() => {
    dispatch(
      GetAllocationPreference({
        client_name: "badass",
        proposal_value: {
          amount: goalData.amount_required_for_goal.amount,
          denomination: goalData.amount_required_for_goal.denomination,
        },
        tax_status: "Resident",
        tax_slab: "₹ 2.5L - 5L",
        risk_score: 50,
        portfolioData: null,
        investment_horizon: diff_years(new Date(), new Date(goalData.end_date)),
        type_of_scheme: "Regular",
        allocation_preference: {
          equity: comparedData.proposed_equity,
          debt: comparedData.proposed_debt,
          alternate: comparedData.proposed_alternate,
          large: 0.8,
          mid: 0.1,
          small: 0.1,
          AAA: 0.75,
          duration: 2,
          low_volatility: 0.8,
        },
      })
    ); // eslint-disable-next-line
  }, [comparedData]);

  return (
    <div>
      <div className="w-full bg-[#fafafa] flex items-center justify-center rounded-[4px] py-4 px-4 mt-6">
        {sipData.map((el, id) => (
          <div
            key={id}
            className="border-r-[1px] border-[#E0DDDD] mr-[1rem] last:border-none first:ml-[1rem] first:text-primary-200 text-neutralBlack w-1/3 flex flex-col items-center justify-center"
          >
            <p className="font-inter text-2xl font-medium  leading-[39px] m-0">
              {el.val}
            </p>
            <p className="font-inter text-sm font-normal leading-[20px] text-[#6A6A6A] mt-[4px] mb-[2px] ">
              {el.label}
            </p>
          </div>
        ))}
      </div>
      <div className="card-heading text-base font-normal mt-4 text-black">
        Measure
      </div>
      <p className="text-sm mt-1">{goalDetails.return_measure}</p>
      {goalDetails.return_measure ===
      "To meet the required return, we suggest increasing SIP amount. If not feasible, allocate more to equity and alternative investments, though this may increase volatility. Please input expected returns to calculate the necessary allocation." ? (
        <>
          <p className=" text-[18px] text-base font-normal mt-4 text-black">
            Asset Allocation
          </p>
          <div className="border border-[#E5E5E5] mt-4 w-full p-4 rounded-sm">
            <p className="font-medium">Expected Returns</p>{" "}
            <div className="flex justify-between w-[70%] mt-4 items-center">
              <p className="text-[14px] font-normal">
                Equity &amp; Alternates (%)
              </p>
              <OutlinedInput
                size="small"
                className="text-sm"
                value={allocation.equity}
                onChange={(e) => {
                  const re = /^\d*\.?\d*$/;
                  if (e.target.value === "" || re.test(e.target.value))
                    setAllocation({
                      ...allocation,
                      equity: e.target.value,
                    });
                }}
                required
                style={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                  width: "20%",
                  //   height: "40px",
                  //   marginTop: "1.5rem",
                }}
              />
              <p className="text-[14px] font-normal ">Debt (%)</p>
              <OutlinedInput
                size="small"
                className="text-sm"
                value={allocation.debt}
                onChange={(e) => {
                  const re = /^\d*\.?\d*$/;
                  if (e.target.value === "" || re.test(e.target.value))
                    setAllocation({
                      ...allocation,
                      debt: e.target.value,
                    });
                }}
                required
                style={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                  width: "20%",
                }}
              />
            </div>
            <InvestedgeButton
              disabled={!allocation.debt || !allocation.equity}
              onClick={handleCalculate}
              sx={{
                marginTop: "1rem",
                width: "100px",
              }}
            >
              Calculate
            </InvestedgeButton>
          </div>
          {open ? (
            <>
              {" "}
              <div className=" justify-between flex w-full mt-4">
                <div className="chart-container-div w-[49%] h-[300px]">
                  <PieChart
                    data={CurrentData}
                    options={pieOptions}
                    width={270}
                    height={120}
                  />
                  <div className="flex justify-evenly">
                    <Legend color={"#ED6D84"} text="Equity" />
                    <Legend color={"#F8CE6C"} text="Debt" />
                    <Legend color={"#56A1E5"} text="Alternate" />
                  </div>
                  <p className="text-center mt-4 text-sm font-normal font-inter">
                    Current Asset Allocation
                  </p>
                </div>
                <div className="w-[49%] h-[300px] chart-container-div">
                  <PieChart
                    data={ProposedData}
                    options={pieOptions}
                    width={270}
                    height={120}
                  />
                  <div className="flex  justify-evenly">
                    <Legend color={"#ED6D84"} text="Equity" />
                    <Legend color={"#F8CE6C"} text="Debt" />
                    <Legend color={"#56A1E5"} text="Alternate" />
                  </div>
                  <p className="text-center mt-4 text-sm font-normal font-inter">
                    Proposed Asset Allocation
                  </p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <Accordion
            sx={{
              marginTop: "1.5rem",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.08)",
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              //   sx={{ fontSize: "14px" }}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography> Recommended Products</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Portfolio goalData={goalDetails} />
            </AccordionDetails>
          </Accordion>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
