import { GET_REVIEW_PORTFOLIO_SUMMARY } from "../actions/type";
const initialState = { MF: [] };
export default function GetReviewPortfolioSummaryReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_REVIEW_PORTFOLIO_SUMMARY:
      return action.payload;
    default:
      return state;
  }
}
