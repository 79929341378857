import React, { useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../static/css/existing/dashboard_styles.css";
import DownArrow from "../assets/img/downward_arrow_solid.svg";
import ClickAwayListener from "react-click-away-listener";
import InvestedgePopper from "../components/popper/InvestedgePopper";
import { MenuItem } from "@mui/material";

export default function Submenu({ item, pathnames, userdata }) {
  let tempbool = false;
  const location = useLocation();
  if (
    item.mapkey !== undefined &&
    pathnames.get(item.mapkey).hasOwnProperty(location.pathname)
  ) {
    tempbool = true;
  }

  const [subnav, setSubnav] = useState(tempbool);
  const submenuRef = useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const showSubnav = () => {
    setSubnav(!subnav);
    setAnchorEl(submenuRef.current);
  };

  if (
    userdata &&
    item.path &&
    !userdata[item.module] &&
    item.module !== "common"
  )
    return null;
  if (item.subNav && userdata) {
    let flag = false;
    for (let i = 0; i < item.subNav.length; i++) {
      if (userdata[item.subNav[i].module]) flag = true;
    }

    if (!flag) return null;
  }
  return (
    <div className="lg:block hidden">
      {item.path ? (
        <Link to={item.path}>
          <div
            className={
              location.pathname === item.path ||
              (pathnames.has(item.title) &&
                pathnames.get(item.title).hasOwnProperty(location.pathname))
                ? "activesidebarlink mx-[20px]"
                : "sidebarlink mx-[20px]"
            }
            onClick={item.subNav && showSubnav}
          >
            <div className="sidebariconlabel">
              <div className="sidebarlabel">{item.title}</div>
              {item.subNav ? (
                <img
                  src={DownArrow}
                  style={{ marginLeft: "0.4rem" }}
                  alt="err"
                />
              ) : (
                ""
              )}
            </div>
            {/* <div>
              {item.subNav && subnav
                ? item.iconOpened
                : item.subNav
                ? item.iconClosed
                : null}
            </div> */}
          </div>
        </Link>
      ) : (
        <div>
          <div
            className={
              location.pathname === item.path ||
              (pathnames.has(item.title) &&
                pathnames.get(item.title).hasOwnProperty(location.pathname))
                ? "activesidebarlink ml-[20px]"
                : "sidebarlink ml-[20px]"
            }
            onClick={item.subNav && showSubnav}
          >
            <div className="sidebariconlabel">
              <div className="sidebarlabel">{item.title}</div>
              {item.subNav ? (
                <img
                  aria-describedby={`header-submenu-popper-${item.title}`}
                  src={DownArrow}
                  ref={submenuRef}
                  style={{ marginLeft: "0.4rem" }}
                  alt="err"
                />
              ) : (
                ""
              )}
            </div>
            <div>
              {item.subNav && subnav
                ? item.iconOpened
                : item.subNav
                ? item.iconClosed
                : null}
            </div>
          </div>
        </div>
      )}
      {item.subNav && userdata && subnav ? (
        <ClickAwayListener onClickAway={() => setSubnav(false)}>
          <InvestedgePopper
            id={`header-submenu-popper-${item.title}`}
            open={subnav}
            anchorEl={anchorEl}
            placement="bottom-end"
            className="z-[100]"
            offset={[0, 20]}
            arrow="true"
          >
            {item.subNav.map((item, index) => {
              if (userdata[item.module])
                return (
                  <Link
                    to={item.path}
                    key={index}
                    onClick={showSubnav}
                    className="hover:text-primary-200 hover:bg-secondary-100"
                  >
                    <MenuItem
                      className="font-inter py-[1rem] text-neutralBlack "
                      selected={item.path === location.pathname}
                      key={index}
                    >
                      {item.title}
                    </MenuItem>
                  </Link>
                );
              return null;
            })}
          </InvestedgePopper>
        </ClickAwayListener>
      ) : (
        ""
      )}
    </div>
  );
}
