import { GET_GOAL_DETAILS } from "../actions/type";
const initialState = {
  goal_details: {
    goal_percent_attained: 296.1385,
    corpus_required: {
      amount: 1,
      denomination: "L",
    },
    year: "1-2024",
    expected_return: 0.12,
    ongoing_sip: {
      amount: 1,
      denomination: "L",
    },
  },
  client_investments: [
    {
      name: "DSP Midcap Fund-Reg(G)",
      product_type: "Mutual Fund",
      asset_type: "Equity",
      amount: {
        amount: 2.96,
        denomination: "C",
      },
      holding: 0.011,
    },
  ],
  current_value_of_portfolio: 29613850.0,
  sip_required_for_goal: -2623269.8217342747,
  shortfall_in_going_sip: null,
  measure: null,
  required_rate_to_reach_goal: -11.999999999999996,
};
export default function getGoalsDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_GOAL_DETAILS:
      return action.payload;
    default:
      return state;
  }
}
