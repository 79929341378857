export const SettingsSidebarData = [
  {
    title: "General",
    path: "/settings/general",
  },
  {
    title: "Access",
    path: "/settings/access",
  },
  {
    title: "Module settings",
    path: "/settings/module",
  },
  {
    title: "About your firm",
    path: "/settings/about",
  },
];
