import SearchIcon from "../../../assets/img/bigsearchicon.svg";
import { useSelector, useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import ClickAwayListener from "react-click-away-listener";
import { Bars } from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import InvestedgePopper from "../../../components/popper/InvestedgePopper";
import { MenuItem } from "@mui/material";
import {
  getComparisonData,
  getProductOnepagerData,
} from "../../../actions/actions";
import "../../../static/css/existing/dashboard_styles.css";
export const SearchBarOnepager = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const fundName = useSelector((store) => store.getComparisonData);
  // const [visible, setVisible] = useState(false);
  const [searchFund, setSearchFund] = useState("");
  // const [isActive, setIsActive] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const inputDivRef = useRef(null);
  // const displayFundName =
  //   Array.isArray(data) &&
  //   data
  //     .filter((elem) =>
  //       elem.name?.toLowerCase().includes(searchFund?.toLowerCase())
  //     )
  //     .slice(0, 5)
  //     .map((elem) => {
  //       return (
  //         <>
  //           <div
  //             className="productInsight-searchbar-p"
  //             onClick={() => {
  //               dispatch(
  //                 getProductOnepagerData(
  //                   {
  //                     params: {
  //                       product_id: elem.product_id,
  //                     },
  //                   },
  //                   history
  //                 )
  //               );
  //               setSearchFund(elem.name);
  //               setVisible(true);
  //             }}
  //           >
  //             {elem.name}
  //           </div>
  //         </>
  //       );
  //     });
  useEffect(() => {
    searchFund.length >= 3 &&
      dispatch(getComparisonData({ params: { name: searchFund } }));
    if (searchFund.length >= 3) setAnchorEl(inputDivRef.current);
    else setAnchorEl(null);
    // eslint-disable-next-line
  }, [searchFund]);
  return (
    <div>
      <form className="w-full flex relative">
        <div
          ref={inputDivRef}
          className={
            searchFund.length >= 3
              ? "p-[1rem] pb-0 bg-[#fff] w-full rounded-[0.5rem]"
              : "p-[1rem] bg-[#fff] w-full rounded-[0.5rem]"
          }
        >
          <div
            className="search-bar-container"
            style={
              searchFund.length
                ? {
                    width: "100%",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "6px 6px 0px 0px",
                    border: "1px solid #E3E3E3",
                  }
                : {
                    width: "100%",
                    backgroundColor: "#FFFFFF",
                  }
            }
          >
            <img src={SearchIcon} alt="err" />
            <input
              placeholder="Search for Mutual Funds, Stocks, PMS, etc"
              className="bg-[#ffffff] border-transparent font-inter ml-[1rem] shadow-none"
              value={searchFund}
              onChange={(e) => {
                // setVisible(false);
                setSearchFund(e.target.value);
              }}
            />
            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
              <InvestedgePopper
                id="search-screen-results-popper"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                className="h-[300px] ml-4 w-[92.2%] border border-[brown]"
                placement="bottom-start"
              >
                {fundName[0] === "loading" ? (
                  <div className="h-[300px] flex justify-center items-center">
                    <Bars color="#1982F8" height="60" width="60" />
                  </div>
                ) : fundName.length ? (
                  <div className=" border border-[red]">
                    {fundName.slice(0, 7).map((option, ind) => (
                      <MenuItem
                        key={ind}
                        onClick={() => {
                          dispatch(
                            getProductOnepagerData(
                              {
                                params: {
                                  product_id: option.product_id,
                                },
                              },
                              history
                            )
                          );
                          setSearchFund(option.name);
                          setAnchorEl(null);
                        }}
                        className="font-inter py-[1rem] text-neutralBlack"
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </div>
                ) : (
                  <div className="h-[300px] font-inter flex items-center justify-center font-medium text-xl text-neutralBlack">
                    No data found
                  </div>
                )}
              </InvestedgePopper>
            </ClickAwayListener>
            {/* {isActive && (
              <ClickAwayListener onClickAway={() => setIsActive(false)}>
                <div
                  ref={setPopperRef}
                  style={
                    !visible
                      ? {
                          ...styles.popper,
                          zIndex: 1000000,
                          background: "white",
                          top: "45px",
                        }
                      : {
                          ...styles.popper,
                          zIndex: 1000000,
                          background: "white",
                          top: "45px",
                          border: "none",
                          boxShadow: "none",
                        }
                  }
                  {...attributes.popper}
                  className="family-details-dropdown-content w-full "
                >
                  {data[0] === "loading" ? (
                    <div className="h-[300px]  flex justify-center items-center">
                      <Bars color="#1982f8" height="60" width="100" />
                    </div>
                  ) : data.length ? (
                    data.map((option, ind) => (
                      <div
                        style={{
                          display: visible ? "none" : "block",
                        }}
                        key={ind}
                        onClick={() => {
                          setSearchFund(option.name);
                          setVisible(true);
                          setIsActive(false);
                          dispatch(
                            getProductOnepagerData(
                              {
                                params: {
                                  product_id: option.product_id,
                                },
                              },
                              history
                            )
                          );
                        }}
                        className="family-details-dropdown-item"
                      >
                        {option.name}
                      </div>
                    ))
                  ) : (
                    <div className="h-[300px] flex items-center justify-center">
                      No data found
                    </div>
                  )}
                </div>
              </ClickAwayListener>
            )} */}
          </div>
        </div>
      </form>
    </div>
  );
};
