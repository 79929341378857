export const markets_questions = [
  "what is the view on RBI's monetary policy?",
  "What is the state of Indian economy?",
  "What are some upcoming IPOs to subscribe and why?",
  "What is happening in Adani Ports?",
];

export const products_questions = [
  "What are the top 5 stocks in the portfolio?",
  "How the fund has been performing?",
  "How to pitch this product to a client?",
  "Who is the fund manager of this fund?",
  "What are some negative points about this fund?",
];

export const clients_questions = [
  "What is the asset allocation of the client?",
  "What are the actionable in this portfolio?",
  "I am going for a client meeting, give me some talking points?",
  "What are the top 10 underlying for this client?",
];
