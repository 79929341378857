import React from "react";
import { topHoldings } from "../onePagerConsts";
import "../../../static/css/existing/dashboard_styles.css";

export default function topHoldingsComponent() {
  return (
    <>
      <div
        style={{
          padding: "1rem",
          backgroundColor: "#fff",
          borderRadius: "6px",
          boxShadow:
            " 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(0, 0, 0, 0.05)",
          minheight: "274px",
          marginTop: "1.5rem",
        }}
      >
        <div
          className="tab-content card"
          style={{
            // border: "1px solid blue",
            border: "1px solid rgba(0,0,0,.125)",
            borderRadius: "6px",
            boxShadow:
              "2px 2px 6px rgb(0 0 0 / 5%), -2px -2px 6px rgb(0 0 0 / 5%)",
          }}
        >
          <div role="tabpanel" className="tab-pane fade show active" id="views">
            <table className="table table-hover">
              <tr
                style={{
                  height: "40px",
                  borderRadius: "10px",
                  borderBottom: "1px solid rgb(50, 50, 50)",
                  fontFamily: "Inter, sans-serif",
                  fontWeight: "700",
                }}
              >
                <th
                  className=" product-reckoner-heading"
                  style={{ textAlign: "left" }}
                >
                  Security Name
                </th>
                <th className=" product-reckoner-heading">Sector</th>
                <th className=" product-reckoner-heading">Weight</th>
              </tr>

              {/* #10 Contains top Holdings card */}
              {topHoldings.data.map((items) => (
                <tr className="dashboard-body-second-card-table-data">
                  <td>{items["securityName"]}</td>
                  <td className="dashboard-body-second-card-table-data">
                    {items["sector"]}
                  </td>
                  <td className="dashboard-body-second-card-table-data">
                    {items["weight"]}
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>

        <p
          className=" onepager-description-text"
          style={{ color: "#0000F5", marginTop: "1rem", marginBottom: 0 }}
        >
          Load all
        </p>
      </div>
    </>
  );
}
