import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  EditIpsData,
  EditSipIpsData,
  GetAllocationPreference,
  GetSIPAllocationPreference,
  PostEventLoggingData,
} from "../../../../actions/actions";
import { useDispatch } from "react-redux";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
import Lumpsum from "./module/Lumpsum";
import InvestedgeTabs from "../../../../components/tabs/InvestedgeTabs";
export default function PortfolioThirdStep({
  handleBack,
  handleNext,
  formValue,
  proposalType,
  sipProposalType,
  setFormValue,
  setPortfolioProducts,
  setSipPortfolioProducts,
}) {
  const dispatch = useDispatch();
  const ipsData = useSelector((state) => state.getipsdata);
  const sipIpsData = useSelector((state) => state.getSipIpsReducer);
  const [EquityWidths, setEquity] = useState(
    ipsData[1].large_cap === 0 &&
      ipsData[1].mid_cap === 0 &&
      ipsData[1].small_cap === 0
      ? [33.3, 100 - 33.3]
      : [
          parseInt(ipsData[1].large_cap * 100),
          parseInt((1 - ipsData[1].large_cap) * 100),
        ]
  );
  const [DebtWidths, setDebt] = useState([
    parseInt(ipsData[3].credit_risk * 100),
    parseInt((1 - ipsData[3].credit_risk) * 100),
  ]);
  const [AlternativesWidths, setAlternatives] = useState([
    parseInt(ipsData[4].alternates_low_vol * 100),
    parseInt((1 - ipsData[4].alternates_low_vol) * 100),
  ]);
  const [equityValue, setEquityValue] = useState(
    parseFloat((ipsData[0].equity * 100).toFixed(1))
  );
  const [debtValue, setDebtValue] = useState(
    parseFloat((ipsData[0].debt * 100).toFixed(1))
  );
  const [alternativeValue, setAlternativeValue] = useState(
    parseFloat((ipsData[0].alternates * 100).toFixed(1))
  );
  const [duration, setDuration] = useState(ipsData[2].duration);
  const [sipEquityWidths, setsipEquity] = useState(
    sipIpsData[1].large_cap === 0 &&
      sipIpsData[1].mid_cap === 0 &&
      sipIpsData[1].small_cap === 0
      ? [33.3, 100 - 33.3]
      : [
          parseInt(sipIpsData[1].large_cap * 100),
          parseInt((1 - sipIpsData[1].large_cap) * 100),
        ]
  );
  const [sipDebtWidths, setsipDebt] = useState([
    parseInt(sipIpsData[3].credit_risk * 100),
    parseInt((1 - sipIpsData[3].credit_risk) * 100),
  ]);
  const [sipAlternativesWidths, setsipAlternatives] = useState([
    parseInt(sipIpsData[4].alternates_low_vol * 100),
    parseInt((1 - sipIpsData[4].alternates_low_vol) * 100),
  ]);
  const [sipequityValue, setsipEquityValue] = useState(
    parseFloat((sipIpsData[0].equity * 100).toFixed(1))
  );
  const [sipdebtValue, setsipDebtValue] = useState(
    parseFloat((sipIpsData[0].debt * 100).toFixed(1))
  );
  const [sipalternativeValue, setsipAlternativeValue] = useState(
    parseFloat((sipIpsData[0].alternates * 100).toFixed(1))
  );
  const [sipduration, setsipDuration] = useState(ipsData[2].duration);
  const tabs =
    proposalType === 0 && sipProposalType === 0
      ? [{ label: "Lumpsum" }, { label: "SIP" }]
      : proposalType === 0
      ? [{ label: "Lumpsum" }]
      : [{ label: "SIP" }];
  const [tabType, setTabType] = useState(0);
  const handlePptChange = (event, newValue) => {
    setTabType(newValue);
  };
  const [expectedReturns, setExpectedReturns] = useState({
    large_cap: 12,
    mid_cap: 14,
    small_cap: 15,
    aaa: 7,
    belowaaa: 9,
    lowvol: 10,
    highvol: 20,
  });
  const [sipexpectedReturns, setsipExpectedReturns] = useState({
    large_cap: 12,
    mid_cap: 14,
    small_cap: 15,
    aaa: 7,
    belowaaa: 9,
    lowvol: 10,
    highvol: 20,
  });
  const [invAmount, setInvAmount] = useState(1000);
  const [cagr, setCagr] = useState(50);
  const [time, setTime] = useState(12);
  const [sipinvAmount, setsipInvAmount] = useState(1000);
  const [sipcagr, setsipCagr] = useState(50);
  const [siptime, setsipTime] = useState(12);
  const [expectedReturnsDropdown, setExpectedReturnsDropdown] =
    useState("Expected Returns");
  const [sipexpectedReturnsDropdown, setsipExpectedReturnsDropdown] =
    useState("Expected Returns");
  const assetSum =
    parseFloat(equityValue) +
    parseFloat(debtValue) +
    parseFloat(alternativeValue);
  const sipassetSum =
    parseFloat(sipequityValue) +
    parseFloat(sipdebtValue) +
    parseFloat(sipalternativeValue);
  return (
    <>
      <div className="px-8 rounded-[8px] w-full bg-white py-8 mt-[16px]">
        <div className="flex justify-between w-full">
          <h2 className="card-heading">
            Asset Allocation &amp; Returns Simulator
          </h2>
          <div className="flex">
            <InvestedgeButton
              onClick={() => {
                handleBack();
                dispatch(
                  PostEventLoggingData({
                    module_name: "Portfolio Review",
                    event_type: "Prev Button",
                    url: window.location.href,
                    note: `Second screen`,
                  })
                );
              }}
            >
              Prev
            </InvestedgeButton>
            <InvestedgeButton
              disabled={
                Math.round(assetSum * 10) / 10 !== 100 ||
                ipsData[0].loading ||
                Math.round(sipassetSum * 10) / 10 !== 100
              }
              className="ml-4"
              onClick={() => {
                setSipPortfolioProducts([]);
                if (sipProposalType === 0) {
                  dispatch(
                    GetSIPAllocationPreference({
                      ...formValue,
                      investment_horizon: parseFloat(
                        formValue.investment_horizon
                      ),
                      sip_proposal_value: {
                        amount: parseFloat(formValue.sip_proposal_value.amount),
                        denomination: formValue.sip_proposal_value.denomination,
                      },
                      risk_score: parseInt(
                        formValue.sip_proposal_value.risk_score
                      ),
                      duration_of_sip: formValue.duration_of_sip
                        ? parseInt(formValue.duration_of_sip)
                        : 2,
                      frequency_of_sip:
                        formValue.sip_proposal_value.frequency_of_sip,
                      top_up: parseFloat(
                        formValue.sip_proposal_value.top_up.value
                      ),
                      frequency_of_topup:
                        formValue.sip_proposal_value.frequency_of_topup,
                      number_of_schemes_for_asset_allocation: formValue
                        .sip_proposal_value.schemes
                        ? parseInt(formValue.sip_proposal_value.schemes)
                        : 2,
                      allocation_preference: {
                        equity: parseFloat(
                          Math.round(sipequityValue * 10) / 1000
                        ),
                        debt: parseFloat(Math.round(sipdebtValue * 10) / 1000),
                        alternate: parseFloat(
                          Math.round(sipalternativeValue * 10) / 1000
                        ),
                        large: parseFloat(
                          Math.round(sipEquityWidths[0] * 10) / 1000
                        ),
                        mid: parseFloat(
                          Math.round(sipEquityWidths[1] * 10) / 2000
                        ),
                        small: parseFloat(
                          Math.round(sipEquityWidths[1] * 10) / 2000
                        ),
                        AAA: parseFloat(
                          Math.round(sipDebtWidths[0] * 10) / 1000
                        ),
                        duration: parseFloat(sipduration),
                        low_volatility: parseFloat(
                          Math.round(sipAlternativesWidths[0] * 10) / 1000
                        ),
                      },
                    })
                  );
                  setFormValue({
                    ...formValue,
                    sip_allocation_preference: {
                      equity: parseFloat(
                        Math.round(sipequityValue * 10) / 1000
                      ),
                      debt: parseFloat(Math.round(sipdebtValue * 10) / 1000),
                      alternate: parseFloat(
                        Math.round(sipalternativeValue * 10) / 1000
                      ),
                      large: parseFloat(
                        Math.round(sipEquityWidths[0] * 10) / 1000
                      ),
                      mid: parseFloat(
                        Math.round(sipEquityWidths[1] * 10) / 2000
                      ),
                      small: parseFloat(
                        Math.round(sipEquityWidths[1] * 10) / 2000
                      ),
                      AAA: parseFloat(Math.round(sipDebtWidths[0] * 10) / 1000),
                      duration: parseFloat(sipduration),
                      low_volatility: parseFloat(
                        Math.round(sipAlternativesWidths[0] * 10) / 1000
                      ),
                    },
                  });
                  dispatch(
                    EditSipIpsData([
                      {
                        equity: parseFloat(
                          Math.round(sipequityValue * 10) / 1000
                        ),
                        debt: parseFloat(Math.round(sipdebtValue * 10) / 1000),
                        alternates: parseFloat(
                          Math.round(sipalternativeValue * 10) / 1000
                        ),
                      },
                      {
                        large_cap: parseFloat(
                          Math.round(sipEquityWidths[0] * 10) / 1000
                        ),
                        mid_cap: parseFloat(
                          Math.round(sipEquityWidths[1] * 10) / 2000
                        ),
                        small_cap: parseFloat(
                          Math.round(sipEquityWidths[1] * 10) / 2000
                        ),
                      },
                      { duration: parseFloat(sipduration) },
                      {
                        credit_risk: parseFloat(
                          Math.round(sipDebtWidths[0] * 10) / 1000
                        ),
                      },
                      {
                        alternates_low_vol: parseFloat(
                          Math.round(sipAlternativesWidths[0] * 10) / 1000
                        ),
                      },
                    ])
                  );
                }
                setPortfolioProducts([]);
                if (proposalType === 0) {
                  dispatch(
                    GetAllocationPreference({
                      ...formValue,
                      proposal_value: {
                        amount: parseInt(formValue.proposal_value.amount),
                        denomination: formValue.proposal_value.denomination[2],
                      },
                      allocation_preference: {
                        equity: parseFloat(Math.round(equityValue * 10) / 1000),
                        debt: parseFloat(Math.round(debtValue * 10) / 1000),
                        alternate: parseFloat(
                          Math.round(alternativeValue * 10) / 1000
                        ),
                        large: parseFloat(
                          Math.round(EquityWidths[0] * 10) / 1000
                        ),
                        mid: parseFloat(
                          Math.round(EquityWidths[1] * 10) / 2000
                        ),
                        small: parseFloat(
                          Math.round(EquityWidths[1] * 10) / 2000
                        ),
                        AAA: parseFloat(Math.round(DebtWidths[0] * 10) / 1000),
                        duration: parseFloat(duration),
                        low_volatility: parseFloat(
                          Math.round(AlternativesWidths[0] * 10) / 1000
                        ),
                      },
                    })
                  );
                  setFormValue({
                    ...formValue,

                    allocation_preference: {
                      equity: parseFloat(Math.round(equityValue * 10) / 1000),
                      debt: parseFloat(Math.round(debtValue * 10) / 1000),
                      alternate: parseFloat(
                        Math.round(alternativeValue * 10) / 1000
                      ),
                      large: parseFloat(
                        Math.round(EquityWidths[0] * 10) / 1000
                      ),
                      mid: parseFloat(Math.round(EquityWidths[1] * 10) / 2000),
                      small: parseFloat(
                        Math.round(EquityWidths[1] * 10) / 2000
                      ),
                      AAA: parseFloat(Math.round(DebtWidths[0] * 10) / 1000),
                      duration: parseFloat(duration),
                      low_volatility: parseFloat(
                        Math.round(AlternativesWidths[0] * 10) / 1000
                      ),
                    },
                  });
                  dispatch(
                    EditIpsData([
                      {
                        equity: parseFloat(Math.round(equityValue * 10) / 1000),
                        debt: parseFloat(Math.round(debtValue * 10) / 1000),
                        alternates: parseFloat(
                          Math.round(alternativeValue * 10) / 1000
                        ),
                      },
                      {
                        large_cap: parseFloat(
                          Math.round(EquityWidths[0] * 10) / 1000
                        ),
                        mid_cap: parseFloat(
                          Math.round(EquityWidths[1] * 10) / 2000
                        ),
                        small_cap: parseFloat(
                          Math.round(EquityWidths[1] * 10) / 2000
                        ),
                      },
                      { duration: parseFloat(duration) },
                      {
                        credit_risk: parseFloat(
                          Math.round(DebtWidths[0] * 10) / 1000
                        ),
                      },
                      {
                        alternates_low_vol: parseFloat(
                          Math.round(AlternativesWidths[0] * 10) / 1000
                        ),
                      },
                    ])
                  );
                }
                dispatch(
                  PostEventLoggingData({
                    module_name: "Portfolio Insights",
                    event_type: "Next button",
                    url: window.location.href,
                    note: `Third Screen`,
                  })
                );
                handleNext();
              }}
            >
              Next
            </InvestedgeButton>
          </div>
        </div>
      </div>
      <div className="rounded-[8px] w-full bg-white px-8 py-4 mt-[16px] flex justify-between items-center">
        <InvestedgeTabs
          tabs={tabs}
          tabvalue={tabType}
          handletabchange={handlePptChange}
        />
        <p className="text-sm text-primary-200">
          {proposalType === 1
            ? "Asset Allocation is not permitted for Lumpsum as portfolio summary would be created basis model portfolio"
            : sipProposalType === 1
            ? "Asset Allocation is not permitted for SIP as portfolio summary would be created basis model portfolio"
            : ""}
        </p>
      </div>
      <Lumpsum
        tab={tabs[tabType].label}
        EquityWidths={tabType === 0 ? EquityWidths : sipEquityWidths}
        setEquity={tabType === 0 ? setEquity : setsipEquity}
        DebtWidths={tabType === 0 ? DebtWidths : sipDebtWidths}
        setDebt={tabType === 0 ? setDebt : setsipDebt}
        AlternativesWidths={
          tabType === 0 ? AlternativesWidths : sipAlternativesWidths
        }
        setAlternatives={tabType === 0 ? setAlternatives : setsipAlternatives}
        equityValue={tabType === 0 ? equityValue : sipequityValue}
        setEquityValue={tabType === 0 ? setEquityValue : setsipEquityValue}
        debtValue={tabType === 0 ? debtValue : sipdebtValue}
        setDebtValue={tabType === 0 ? setDebtValue : setsipDebtValue}
        alternativeValue={
          tabType === 0 ? alternativeValue : sipalternativeValue
        }
        setAlternativeValue={
          tabType === 0 ? setAlternativeValue : setsipAlternativeValue
        }
        duration={tabType === 0 ? duration : sipduration}
        setDuration={tabType === 0 ? setDuration : setsipDuration}
        expectedReturns={tabType === 0 ? expectedReturns : sipexpectedReturns}
        setExpectedReturns={
          tabType === 0 ? setExpectedReturns : setsipExpectedReturns
        }
        invAmount={tabType === 0 ? invAmount : sipinvAmount}
        setInvAmount={tabType === 0 ? setInvAmount : setsipInvAmount}
        cagr={tabType === 0 ? cagr : sipcagr}
        setCagr={tabType === 0 ? setCagr : setsipCagr}
        time={tabType === 0 ? time : siptime}
        setTime={tabType === 0 ? setTime : setsipTime}
        expectedReturnsDropdown={
          tabType === 0 ? expectedReturnsDropdown : sipexpectedReturnsDropdown
        }
        setExpectedReturnsDropdown={
          tabType === 0
            ? setExpectedReturnsDropdown
            : setsipExpectedReturnsDropdown
        }
      />
    </>
  );
}
