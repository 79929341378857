import React from "react";
import BarsChart from "../../../../../components/charts/BarsChart";
import { GraphColorPalette } from "../../../../../components/colors/graphColorPalette";
import PieChart from "../../../../dashboard/PieChart";
import ProgressBar from "../ProgressBar";
export default function EquitySection({ analytics }) {
  const LegendItem = ({ color, text }) => {
    return (
      <div className="flex ml-[4px]">
        <div
          className={`w-[7px] h-[7px] block mt-[7px] `}
          style={{ backgroundColor: color }}
        />
        <p className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] ml-[0.2rem] mb-0">
          {text}
        </p>
      </div>
    );
  };
  const categoryKeys = [];
  const categoryValues = [];
  const colorPalette = GraphColorPalette;
  for (const property in analytics.before_equity_category_allocation) {
    categoryKeys.push(property);
    categoryValues.push(
      (analytics.before_equity_category_allocation[property] * 100).toFixed(1)
    );
  }
  const aftercategoryKeys = [];
  const aftercategoryValues = [];
  for (const property in analytics.after_equity_category_allocation) {
    aftercategoryKeys.push(property);
    aftercategoryValues.push(
      (analytics.after_equity_category_allocation[property] * 100).toFixed(1)
    );
  }
  const productKeys = [];
  const productValues = [];
  for (const property in analytics.before_equity_product_allocation) {
    productKeys.push(property);
    productValues.push(
      (analytics.before_equity_product_allocation[property] * 100).toFixed(1)
    );
  }
  const afterproductKeys = [];
  const afterproductValues = [];
  for (const property in analytics.after_equity_product_allocation) {
    afterproductKeys.push(property);
    afterproductValues.push(
      (analytics.after_equity_product_allocation[property] * 100).toFixed(1)
    );
  }
  const sectorkeys = [];
  const sectorvalues1 = [];
  const sectorvalues2 = [];
  for (const property in analytics.before_full_sector_allocation) {
    sectorkeys.push(property);
    sectorvalues1.push(
      (analytics.before_full_sector_allocation[property][0] * 100).toFixed(1)
    );
    sectorvalues2.push(
      (analytics.before_full_sector_allocation[property][1] * 100).toFixed(1)
    );
  }
  const aftersectorkeys = [];
  const aftersectorvalues1 = [];
  const aftersectorvalues2 = [];
  for (const property in analytics.after_full_sector_allocation) {
    aftersectorkeys.push(property);
    aftersectorvalues1.push(
      (analytics.after_full_sector_allocation[property][0] * 100).toFixed(1)
    );
    aftersectorvalues2.push(
      (analytics.after_full_sector_allocation[property][1] * 100).toFixed(1)
    );
  }
  const assetdata = {
    labels: categoryKeys,

    datasets: [
      {
        data: categoryValues,
        backgroundColor: colorPalette,
        borderColor: colorPalette,
        borderWidth: 1,
      },
    ],
  };
  const afterassetdata = {
    labels: aftercategoryKeys,

    datasets: [
      {
        data: aftercategoryValues,
        backgroundColor: colorPalette,
        borderColor: colorPalette,
        borderWidth: 1,
      },
    ],
  };
  const pieoptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      labels: {
        render: "value",
        fontColor: "#fff",
      },
      datalabels: {
        display: false,
      },
    },
  };
  const sectorsData = {
    labels: sectorkeys.map((sector) =>
      sector.length > 9 ? `${sector.slice(0, 9)}..` : sector.slice(0, 9)
    ),
    datasets: [
      {
        label: "Equity Portfolio",
        data: sectorvalues1,
        backgroundColor: "#119D0E",
        borderColor: "#ED6D85",
      },
      {
        label: "NSE 500",
        data: sectorvalues2,
        backgroundColor: "#5675E5",
        borderColor: "#5675E5",
      },
    ],
  };
  const aftersectorsData = {
    labels: aftersectorkeys.map((sector) =>
      sector.length > 9 ? `${sector.slice(0, 9)}..` : sector.slice(0, 9)
    ),
    datasets: [
      {
        label: "Equity Portfolio",
        data: aftersectorvalues1,
        backgroundColor: "#119D0E",
        borderColor: "#ED6D85",
      },
      {
        label: "NSE 500",
        data: aftersectorvalues2,
        backgroundColor: "#5675E5",
        borderColor: "#5675E5",
      },
    ],
  };
  const barOptions = {
    maintainAspectRatio: false,
    cornerRadius: 20,
    legend: {
      display: false,
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 30,
        bottom: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
    plugins: {
      labels: {
        // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
        render: "value",
        fontColor: "#000",
        position: "default",
        outsidePadding: 14,
        textMargin: 3,
      },
      datalabels: {
        display: false,
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var currentValue = dataset.data[tooltipItem.index];
          return `${sectorkeys[tooltipItem.index]}: ${currentValue} `;
        },
        title: function (tooltipItem) {
          return sectorkeys[tooltipItem.index];
        },
      },
    },
  };
  const afterbarOptions = {
    maintainAspectRatio: false,
    cornerRadius: 20,
    legend: {
      display: false,
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 30,
        bottom: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
    plugins: {
      labels: {
        // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
        render: "value",
        fontColor: "#000",
        position: "default",
        outsidePadding: 14,
        textMargin: 3,
      },
      datalabels: {
        display: false,
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var currentValue = dataset.data[tooltipItem.index];
          return `${aftersectorkeys[tooltipItem.index]}: ${currentValue} `;
        },
        title: function (tooltipItem) {
          return aftersectorkeys[tooltipItem.index];
        },
      },
    },
  };
  return (
    <div className="w-full mt-6">
      <h4 className="font-inter not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-2">
        Product Blended performance
      </h4>
      <div className="flex w-full justify-between">
        <div className="w-[49%] bg-[#fafafa] py-[1rem] pt-2 px-0 min-h-full">
          <p className="text-sm text-neutralBlack mx-4 font-medium">Current</p>
          <div className="flex items-center mt-2">
            <div className=" w-[50%] text-center border-r border-r-dividerColor">
              <h3 className="font-inter text-[24px] text-[#6a6a76] font-semibold leading-[20px] tracking-[0em] text-center mb-0">
                {`${(analytics.before_equity_cagr * 100).toFixed(1)} %`}
              </h3>
              <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                3Y CAGR
              </label>
            </div>
            <div className="w-[50%] text-center">
              <h3 className="text-[#6a6a76] font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center mb-0">
                {`${(analytics.before_equity_bm_cagr * 100).toFixed(1)} %`}
              </h3>
              <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                3Y BM* CAGR
              </label>
            </div>
          </div>
        </div>
        <div className="w-[49%] bg-[#fafafa] py-[1rem] pt-2 px-0 min-h-full">
          <p className="text-sm text-neutralBlack mx-4 font-medium">Proposed</p>
          <div className="flex items-center mt-2">
            <div className=" w-[50%] text-center border-r border-r-dividerColor">
              <h3 className="font-inter text-[24px] text-[#6a6a76] font-semibold leading-[20px] tracking-[0em] text-center mb-0">
                {`${(analytics.after_equity_cagr * 100).toFixed(1)} %`}
              </h3>
              <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                3Y CAGR
              </label>
            </div>
            <div className=" w-[50%] text-center">
              <h3 className="text-[#6a6a76] font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center mb-0">
                {`${(analytics.after_equity_bm_cagr * 100).toFixed(1)} %`}
              </h3>
              <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                3Y BM* CAGR
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mt-4">
        <h4 className="font-inter not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-2">
          Category allocation
        </h4>
        <div className="flex w-full justify-between">
          <div className="w-[49%] min-h-[238px]">
            <div className="bg-[#FAFAFA] w-[100%] p-[1rem] min-h-full">
              <p className="text-sm text-neutralBlack font-medium">Current</p>
              <div className="w-full flex justify-center items-center">
                <PieChart
                  data={assetdata}
                  options={pieoptions}
                  width={170}
                  height={170}
                />
              </div>

              <div className=" flex justify-evenly w-full z-[100] pt-[0.4rem] flex-wrap">
                {categoryKeys.map((key, index) => (
                  <LegendItem
                    key={index}
                    color={colorPalette[index]}
                    text={key}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="w-[49%] min-h-[238px]">
            <div className="bg-[#FAFAFA] w-[100%] p-[1rem] min-h-full">
              <p className="text-sm text-neutralBlack font-medium">Proposed</p>
              <div className="w-full flex justify-center items-center">
                <PieChart
                  data={afterassetdata}
                  options={pieoptions}
                  width={170}
                  height={170}
                />
              </div>

              <div className=" flex justify-evenly w-full z-[100] pt-[0.4rem] flex-wrap">
                {aftercategoryKeys.map((key, index) => (
                  <LegendItem
                    key={index}
                    color={colorPalette[index]}
                    text={key}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mt-4">
        <h4 className="font-inter not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-2">
          Product allocation
        </h4>
        <div className="flex w-full justify-between h-[238px]">
          <div className="bg-[#FAFAFA] w-[49%] p-[1rem] h-full">
            <p className="text-sm text-neutralBlack font-medium">Current</p>

            <div
              className="min-h-full grid grid-cols-2 grid-flow-col gap-x-5"
              style={{ gridTemplateRows: "20% 20% 20% 20% 20%" }}
            >
              {productKeys.map((key, index) => (
                <div className="row-span-1" key={index}>
                  <ProgressBar
                    bg={colorPalette[index % colorPalette.length]}
                    progress={productValues[index]}
                    label={key}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="bg-[#FAFAFA] w-[49%] p-[1rem] h-full">
            <p className="text-sm text-neutralBlack font-medium">Proposed</p>

            <div
              className="min-h-full grid grid-cols-2 grid-flow-col gap-x-5"
              style={{ gridTemplateRows: "20% 20% 20% 20% 20%" }}
            >
              {afterproductKeys.map((key, index) => (
                <div className="row-span-1" key={index}>
                  <ProgressBar
                    bg={colorPalette[index % colorPalette.length]}
                    progress={afterproductValues[index]}
                    label={key}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mt-4">
        <h4 className="font-inter not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-2">
          Full sector allocation
        </h4>
        <div className="bg-[#FAFAFA] w-[100%] p-[1rem] min-h-full mt-2">
          <p className="text-sm text-neutralBlack font-medium">Current</p>

          <BarsChart
            data={sectorsData}
            options={barOptions}
            width={220}
            height={220}
          />
          <div className="flex justify-center mt-2">
            <div className="flex items-center ml-[16px]">
              <div className="bg-[#119D0E] rounded w-[14px] h-[14px]" />
              <h2 className="text-xs ml-[8px] mb-0">Equity Portfolio</h2>
            </div>
            <div className="flex items-center ml-[16px]">
              <div className="bg-[#5675E5] rounded w-[14px] h-[14px]" />
              <h2 className="text-xs ml-[8px] mb-0">NSE 500</h2>
            </div>
          </div>
        </div>
        <div className="bg-[#FAFAFA] w-[100%] p-[1rem] min-h-full mt-2">
          <p className="text-sm text-neutralBlack font-medium">Proposed</p>

          <BarsChart
            data={aftersectorsData}
            options={afterbarOptions}
            width={220}
            height={220}
          />
          <div className="flex justify-center mt-2">
            <div className="flex items-center ml-[16px]">
              <div className="bg-[#119D0E] rounded w-[14px] h-[14px]" />
              <h2 className="text-xs ml-[8px] mb-0">Equity Portfolio</h2>
            </div>
            <div className="flex items-center ml-[16px]">
              <div className="bg-[#5675E5] rounded w-[14px] h-[14px]" />
              <h2 className="text-xs ml-[8px] mb-0">NSE 500</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mt-4">
        <h4 className="font-inter not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-2">
          Geographical Allocation
        </h4>
        <div className="bg-[#FAFAFA] w-[100%] p-[1rem] min-h-full mt-2">
          <p className="text-sm text-neutralBlack font-medium">Current</p>
          <div className="w-[90%] mx-auto flex mt-2 ">
            <div
              style={{
                width: `${(
                  analytics.before_geographical_allocation[
                    "Domestic Equities"
                  ] * 100
                ).toFixed(1)}%`,
                maxWidth: "96%",
                minWidth: "4%",
              }}
              className="bg-[#ED6D85] p-1 text-xs text-white h-6 rounded-l-sm"
            >{`${(
              analytics.before_geographical_allocation["Domestic Equities"] *
              100
            ).toFixed(1)}%`}</div>
            <div
              style={{
                width: `${(
                  analytics.before_geographical_allocation[
                    "Overseas Equities"
                  ] * 100
                ).toFixed(1)}%`,
                maxWidth: "96%",
                minWidth: "4%",
              }}
              className="bg-[#5675E5] p-1 text-xs text-white h-6 rounded-r-sm"
            >{`${(
              analytics.before_geographical_allocation["Overseas Equities"] *
              100
            ).toFixed(1)}%`}</div>
          </div>
          <div className="flex justify-center mt-4">
            <div className="flex items-center ml-[16px]">
              <div className="bg-[#ED6D85] rounded w-[14px] h-[14px]" />
              <h2 className="text-xs ml-[8px] mb-0">Domestic</h2>
            </div>
            <div className="flex items-center ml-[16px]">
              <div className="bg-[#5675E5] rounded w-[14px] h-[14px]" />
              <h2 className="text-xs ml-[8px] mb-0">Overseas</h2>
            </div>
          </div>
        </div>
        <div className="bg-[#FAFAFA] w-[100%] p-[1rem] min-h-full mt-2">
          <p className="text-sm text-neutralBlack font-medium">Proposed</p>

          <div className="w-[90%] mx-auto flex mt-2 ">
            <div
              style={{
                width: `${(
                  analytics.after_geographical_allocation["Domestic Equities"] *
                  100
                ).toFixed(1)}%`,
                maxWidth: "96%",
                minWidth: "4%",
              }}
              className="bg-[#ED6D85] p-1 text-xs text-white h-6 rounded-l-sm"
            >{`${(
              analytics.after_geographical_allocation["Domestic Equities"] * 100
            ).toFixed(1)}%`}</div>
            <div
              style={{
                width: `${(
                  analytics.after_geographical_allocation["Overseas Equities"] *
                  100
                ).toFixed(1)}%`,
                maxWidth: "96%",
                minWidth: "4%",
              }}
              className="bg-[#5675E5] p-1 text-xs text-white h-6 rounded-r-sm"
            >{`${(
              analytics.after_geographical_allocation["Overseas Equities"] * 100
            ).toFixed(1)}%`}</div>
          </div>
          <div className="flex justify-center mt-4">
            <div className="flex items-center ml-[16px]">
              <div className="bg-[#ED6D85] rounded w-[14px] h-[14px]" />
              <h2 className="text-xs ml-[8px] mb-0">Domestic</h2>
            </div>
            <div className="flex items-center ml-[16px]">
              <div className="bg-[#5675E5] rounded w-[14px] h-[14px]" />
              <h2 className="text-xs ml-[8px] mb-0">Overseas</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
