import { GET_REVIEW_PORTFOLIO_IAA } from "../actions/type";
const initialState = [
  {
    field: "duration",
    opt_allocation: 3.2,
    target: 3.0,
    rationale: "Relative bullish view on Duration",
  },
  {
    field: "credit_risk",
    opt_allocation: 0.79,
    target: 0.75,
    rationale: "Relative bullish view on Credit Risk",
  },
  {
    field: "alternates_low_vol",
    opt_allocation: 0.79,
    target: 0.75,
    rationale: "Relative bullish view on Low Volatility Alternates",
  },
  {
    field: "large_cap",
    opt_allocation: 0.5,
    target: 0.5,
    rationale: "Relative bearish view on Large Cap",
  },
  {
    field: "mid_cap",
    opt_allocation: 0.4,
    target: 0.4,
    rationale: "Relative bearish view on Mid Cap",
  },
  {
    field: "small_cap",
    opt_allocation: 0.1,
    target: 0.1,
    rationale: "Relative bearish view on Small Cap",
  },
  {
    field: "equity",
    opt_allocation: 0.4886877828054299,
    target: 0.5,
    rationale: "Relative bearish view on Equity",
  },
  {
    field: "debt",
    opt_allocation: 0.4434389140271493,
    target: 0.45,
    rationale: "Relative bearish view on Debt",
  },
  {
    field: "alternates",
    opt_allocation: 0.06787330316742082,
    target: 0.05,
    rationale: "Relative bullish view on Alternates",
  },
];
export default function GetReviewPortfolioIAA(state = initialState, action) {
  switch (action.type) {
    case GET_REVIEW_PORTFOLIO_IAA:
      return action.payload;
    default:
      return state;
  }
}
