import React, { useState } from "react";
import DoughnutChart from "../../components/charts/DoughnutChart";
// import Lock from "../../assets/img/lock_locked_white.svg";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import HoldingReportModal from "./HoldingReportModal";
import {
  GetDashboardHoldingReport,
  GetDashboardPortfolioSummary,
} from "../../actions/actions";
import { useDispatch } from "react-redux";
import ClientSummaryModal from "./ClientSummaryModal";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
// import { OpenSnackbar } from "../../actions/actions";
export default function Portfolio() {
  const [holdingReportModal, setHoldingReportModal] = useState(false);
  const [clientSummaryModal, setClientSummaryModal] = useState(false);
  const dispatch = useDispatch();
  const outerDataLabels = [];
  const outerDataKeys = [];
  const portfolioData = useSelector((state) => state.dashboardPortfolio);
  let risktext;
  if (
    portfolioData.composite_risk_score >= 0 &&
    portfolioData.composite_risk_score <= 20
  )
    risktext = "Conservative";
  if (
    portfolioData.composite_risk_score > 20 &&
    portfolioData.composite_risk_score <= 40
  )
    risktext = "Moderately Conservative";
  if (
    portfolioData.composite_risk_score > 40 &&
    portfolioData.composite_risk_score <= 60
  )
    risktext = "Moderate";
  if (
    portfolioData.composite_risk_score > 60 &&
    portfolioData.composite_risk_score <= 80
  )
    risktext = "Moderately Aggressive";
  if (
    portfolioData.composite_risk_score > 80 &&
    portfolioData.composite_risk_score <= 100
  )
    risktext = "Aggressive";
  const details = [
    {
      value: !portfolioData.user_type ? "Client" : "Prospect",
      label: "User Type",
    },
    {
      value: portfolioData.account_type === "F" ? "Family" : "Individual",
      label: "Account Type",
    },
    {
      value: `${portfolioData.investment_horizon || 0} yrs`,
      label: "Investment Horizon",
    },
    {
      value: portfolioData.ips_deviation || "High",
      label: "IPS Deviation",
    },
  ];
  var equitySum = 0;
  var debtSum = 0;
  var alternateSum = 0;
  var outerGraphBgColors = [];
  for (const property in portfolioData.equity) {
    outerDataLabels.push(property);
    outerDataKeys.push(portfolioData.equity[property] * 100);
    equitySum += portfolioData.equity[property] * 100;
    outerGraphBgColors.push("#56A1E5");
  }
  for (const property in portfolioData.debt) {
    outerDataLabels.push(property);
    outerDataKeys.push(portfolioData.debt[property] * 100);
    debtSum += portfolioData.debt[property] * 100;
    outerGraphBgColors.push("#ED6D84");
  }
  for (const property in portfolioData.alternate) {
    outerDataLabels.push(property);
    outerDataKeys.push(portfolioData.alternate[property] * 100);
    alternateSum += portfolioData.alternate[property] * 100;
    outerGraphBgColors.push("#F8CE6C");
  }
  const parsePortfolio = (portfolio) => {
    if (portfolio / 1000 < 100)
      return `₹ ${parseFloat(portfolio / 1000).toFixed(1)} K`;
    if (portfolio / 100000 < 100)
      return `₹ ${parseFloat(portfolio / 100000).toFixed(1)} L`;
    return `₹ ${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  };
  const outerdata = {
    labels: outerDataLabels,
    datasets: [
      {
        data: outerDataKeys,
        backgroundColor: outerGraphBgColors,
        borderColor: "#fff",
        borderWidth: 3,
      },
    ],
  };
  const innerdata = {
    labels: ["Equity", "Debt", "Alternate"],
    datasets: [
      {
        data: [equitySum, debtSum, alternateSum],
        backgroundColor: ["#56A1E5", "#ED6D84", "#F8CE6C"],
        borderColor: "#fff",
        borderWidth: 1,
      },
    ],
  };
  const doughnutinneroptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      labels: {
        render: function () {},

        fontColor: "#fff",
      },
      datalabels: {
        position: "outside",
        fontFamily: "Inter,sans-serif",
        formatter: function (value, context) {
          return (
            ["Equity", "Debt", "Alternate"][context.dataIndex] +
            "\n" +
            parseFloat(value).toFixed(1) +
            "%"
          );
        },
        color: "#fff",
        font: { size: 13, family: "'Inter', sans-serif" },
        display: "auto",
      },
    },
  };
  const doughnutouteroptions = {
    maintainAspectRatio: false,
    cutoutPercentage: 87,
    legend: {
      display: false,
    },
    plugins: {
      labels: [
        {
          render: () => {},
          position: "outside",
          fontColor: "#323232",
          fontSize: 13,
          fontFamily: "'Inter', sans-serif",
          textMargin: 5,
        },
      ],
      datalabels: {
        position: "outside",
        fontFamily: "Inter,sans-serif",
        formatter: function (value, context) {
          return outerDataLabels[context.dataIndex];
        },
        anchor: "start",
        align: "end",
        offset: "24",
        color: "#323232",
        font: { size: 13, family: "'Inter', sans-serif" },
        display: "auto",
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          var multiplier =
            portfolioData.actualCorpus.denomination === "K"
              ? 1000
              : portfolioData.actualCorpus.denomination === "L"
              ? 100000
              : 10000000;
          return (
            parsePortfolio(
              (outerDataKeys[tooltipItem.index] *
                portfolioData.actualCorpus.amount *
                multiplier) /
                100
            ) +
            " (" +
            outerDataKeys[tooltipItem.index].toFixed(1) +
            "%)"
          );
        },
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
      },
    },
  };
  const clientId = localStorage.getItem("clientId");
  return (
    <div id="client-dashoard-portfolio" className="mt-[2rem]">
      <div className="mt-[3rem] w-[59%] flex justify-between">
        <h3 className="card-heading">Portfolio</h3>
        <div className="flex">
          <div className="flex items-center ml-[16px]">
            <div className="bg-[#56A1E5] rounded w-[14px] h-[14px]" />
            <h2 className="text-xs ml-[8px] mb-0">Equity</h2>
          </div>
          <div className="flex items-center ml-[16px]">
            <div className="bg-[#ED6D84] rounded w-[14px] h-[14px]" />
            <h2 className="text-xs ml-[8px] mb-0">Debt</h2>
          </div>
          <div className="flex items-center ml-[16px]">
            <div className="bg-[#F8CE6C] rounded w-[14px] h-[14px]" />
            <h2 className="text-xs ml-[8px] mb-0">Alternate</h2>
          </div>
        </div>
      </div>

      <div className="mt-[1rem] flex justify-between">
        <div className="relative w-[45%] z-[8]">
          <DoughnutChart
            data={outerdata}
            options={doughnutouteroptions}
            width={350}
            height={350}
          />
          <div
            style={{
              transform: "translate(-50%, -50%)",
            }}
            className="w-[71%] h-[71%] top-[50%] left-[50%] absolute z-[-1]"
          >
            <Doughnut data={innerdata} options={doughnutinneroptions} />
          </div>
          <div
            style={{
              transform: "translate(-50%, -50%)",
            }}
            className="top-[50%] left-[50%] absolute font-inter text-[20px] not-italic leading-[34px] tracking-[0em] text-center font-medium"
          >
            {portfolioData.actualCorpus
              ? `₹ ${portfolioData.actualCorpus.amount.toFixed(1)} ${
                  portfolioData.actualCorpus.denomination
                }`
              : "0 L"}
          </div>
        </div>
        <div className="w-[40%] pt-[1rem]">
          <div className="flex justify-start flex-wrap pb-[1rem] border-b border-greyBorder">
            {details.map((detail, index) => {
              return (
                <div key={index} className="w-[50%] p-[0.5rem] pl-0">
                  <div className=" font-inter text-[12px] not-italic font-normal leading-[16px] tracking-[0em] text-[#999]">
                    {detail.label}
                  </div>
                  <div className="card-heading mt-[0.5rem]">{detail.value}</div>
                </div>
              );
            })}
            <div className="w-[50%] p-[0.5rem] pl-0">
              <div className=" font-inter text-[12px] not-italic font-normal leading-[16px] tracking-[0em] text-[#999]">
                Composite Risk Score
              </div>
              <div className="card-heading mt-[0.5rem]">
                {portfolioData.composite_risk_score}{" "}
                <span className="text-sm">{`(${risktext})`}</span>
              </div>
            </div>
          </div>
          <div className="p-[1rem] pl-0 font-inter text-[16px] not-italic font-medium leading-[16px] text-left text-[#999] tracking-[-0.009em]">
            Download
          </div>
          <div className="flex">
            <InvestedgeButton
              onClick={() => {
                setHoldingReportModal(true);
                dispatch(GetDashboardHoldingReport({ client_id: clientId }));
              }}
            >
              Holding Report
            </InvestedgeButton>
            <InvestedgeButton
              className="ml-4"
              onClick={() => {
                setClientSummaryModal(true);
                dispatch(GetDashboardPortfolioSummary({ client_id: clientId }));
              }}
            >
              Meeting Navigator
            </InvestedgeButton>
          </div>
        </div>
      </div>
      <HoldingReportModal
        holdingReportModal={holdingReportModal}
        onClose={() => setHoldingReportModal(false)}
      />
      <ClientSummaryModal
        clientSummaryModal={clientSummaryModal}
        onClose={() => setClientSummaryModal(false)}
      />
    </div>
  );
}
