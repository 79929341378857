import React, { useState } from "react";
import Logo from "../../assets/img/beyondirr_header_logo.png";
import Arrow from "../../assets/img/header_down_arrow.svg";
import WhiteLogo from "../../assets/img/mobile_header_menu_logo.svg";
import { usePopper } from "react-popper";
import ClickAwayListener from "react-click-away-listener";
import Investedge from "../../assets/img/investedge_header.svg";
import Investlane from "../../assets/img/investlane_header.svg";
import InvestAlpha from "../../assets/img/investalpha_header.svg";
import InvestedgeMenuArrow from "../../assets/img/header_menu_arrow_investedge.svg";
import InvestlaneMenuArrow from "../../assets/img/header_menu_arrow_investlane.svg";
import MenuArrow from "../../assets/img/header_menu_arrow.svg";
import { Link } from "react-router-dom";
import Menu from "../../assets/img/menu_icon.svg";
import MenuCross from "../../assets/img/header_menu_cross.svg";
import WhiteArrow from "../../assets/img/header_blue_menu_arrow.svg";
import Fade from "@mui/material/Fade";
import { REACT_APP_development } from "../../env";
export default function Header({ background }) {
  // const [bg, setBg] = useState("transparent");
  const [investedgebg, setInvestedgebg] = useState("");
  const [investlanebg, setInvestlanebg] = useState("");
  const [investalphabg, setInvestalphabg] = useState("");
  const [productsOpen, setProductsOpen] = useState(false);
  const [productsreferenceRef, setproductsReferenceRef] = useState(null);
  const [productspopperRef, setproductsPopperRef] = useState(null);
  const [sidenavbar, setSidenavbar] = useState(false);
  const [productsSubmenu, setProductsSubmenu] = useState(false);
  const productpopper = usePopper(productsreferenceRef, productspopperRef, {
    placement: "bottom-end",
    modifiers: [
      {
        name: "offset",
        enabled: true,
      },
    ],
    options: {
      offset: [0, 32],
    },
  });
  // const listenScrollEvent = () => {
  //   if (window.scrollY > 1) {
  //     setBg("rgba(12, 4, 24, 0.8)");
  //   } else setBg("transparent");
  // };
  // useEffect(() => {
  //   window.addEventListener("scroll", listenScrollEvent);
  // }, []);
  const handleArrowClick = () => {
    var elmnt = document.getElementById("mobile-header-menu-arrow");
    elmnt.style.transform = productsSubmenu
      ? "rotate(360deg)"
      : "rotate(-180deg)";
    setProductsSubmenu(!productsSubmenu);
  };
  return (
    <>
      <div
        className={`hidden md:block md:fixed md:top-0 md:z-50 md:w-full md:px-20 md:pt-7 md:pb-5`}
        style={{ background: background }}
      >
        <div className="flex justify-between items-center">
          <a href={process.env.REACT_APP_beyondirrhome}>
            <img src={Logo} alt="err" className="h-[42px]" />
          </a>
          <div className="flex items-center">
            {REACT_APP_development !== "production" ? (
              <>
                <div
                  className="flex pr-6 relative cursor-pointer"
                  onClick={() => setProductsOpen(!productsOpen)}
                >
                  <div
                    className="absolute w-[15%] h-[4px] rounded-[4px] bottom-[-8px] z-20"
                    style={{
                      left: "28%",
                      background:
                        "linear-gradient(223.78deg, #09C3E7 0%, #7669F0 106.87%)",
                    }}
                  />
                  <p
                    className="font-montserrat font-normal cursor-pointer text-[#FCFCFD] leading-[24px] pr-[8px] mb-0"
                    ref={setproductsReferenceRef}
                  >
                    Products /{" "}
                    <span className="text-[#E05476] font-light pr-[4px]">
                      InvestEdge
                    </span>
                  </p>
                  <img src={Arrow} alt="err" />
                </div>
                <a href={process.env.REACT_APP_beyondirrhome + "about"}>
                  <p className="font-montserrat leading-[24px] text-[#F4F5F6] pr-6 cursor-pointer mb-0">
                    About
                  </p>
                </a>{" "}
              </>
            ) : null}
            <div className="flex cursor-pointer">
              <Link to={{ pathname: "/login" }}>
                <p className="font-montserrat text-[#FCFCFD] leading-[24px] pr-4 mb-0">
                  Login
                </p>
              </Link>
            </div>
          </div>
          {productsOpen && (
            <ClickAwayListener onClickAway={() => setProductsOpen(false)}>
              <div
                className="bg-primarybg-300 rounded-2xl border-[#353945] shadow-[0px_12px_64px_#06071B] p-[16px] pb-0 w-[430px] mt-4"
                ref={setproductsPopperRef}
                style={{
                  ...productpopper.styles.popper,
                  border: "1px solid #353945",
                }}
                {...productpopper.attributes.popper}
              >
                <Link to="/">
                  <div
                    className="p-[16px] w-full"
                    style={{
                      boxShadow: "inset 0px -1px 0px #23262F",
                      background: investedgebg,
                    }}
                    onMouseEnter={() =>
                      setInvestedgebg(
                        "radial-gradient(59.18% 36.32% at 50% 95.26%, rgba(216, 79, 119, 0.64) 0%, rgba(6, 7, 27, 0) 100%)"
                      )
                    }
                    onMouseLeave={() => setInvestedgebg("")}
                  >
                    <div className="flex">
                      <img
                        src={Investedge}
                        alt="err"
                        style={
                          investedgebg
                            ? {
                                filter:
                                  "drop-shadow(0px 0px 15px #F02763) drop-shadow(0px 0px 15px #D74E77)",
                              }
                            : {}
                        }
                      />
                      <p className="text-white font-montserrat text-lg leading-[30px] font-medium ml-3 mb-0">
                        InvestEdge
                      </p>
                    </div>
                    <div className="flex justify-end pb-2">
                      <img
                        src={investedgebg ? InvestedgeMenuArrow : MenuArrow}
                        alt="err"
                      />
                    </div>

                    <p className="font-montserrat font-normal text-sm leading-[21px] text-[#E6E8EC] ml-9 mb-0">
                      Market Views, Product Stories, and Portfolio Insights.
                      Well prepared.
                    </p>
                  </div>
                </Link>
                <a href={process.env.REACT_APP_investlane}>
                  <div
                    className="p-[16px] w-full"
                    style={{
                      boxShadow: "inset 0px -1px 0px #23262F",
                      background: investlanebg,
                    }}
                    onMouseEnter={() =>
                      setInvestlanebg(
                        "radial-gradient(52.58% 58.42% at 50% 100%, #FF873D 0%, rgba(6, 7, 27, 0) 100%)"
                      )
                    }
                    onMouseLeave={() => setInvestlanebg("")}
                  >
                    <div className="flex">
                      <img
                        src={Investlane}
                        alt="err"
                        style={
                          investlanebg
                            ? {
                                filter:
                                  "drop-shadow(0px 0px 15px #FF3830) drop-shadow(0px 0px 15px #FF3332)",
                              }
                            : {}
                        }
                      />
                      <p className="text-white font-montserrat text-lg leading-[30px] font-medium ml-3 mb-0">
                        InvestLane
                      </p>
                    </div>
                    <div className="flex justify-end pb-2">
                      <img
                        src={investlanebg ? InvestlaneMenuArrow : MenuArrow}
                        alt="err"
                      />
                    </div>

                    <p className="font-montserrat font-normal text-sm eading-[21px] text-[#E6E8EC] ml-9 mb-0">
                      Differentiated investment opportunities. Seamless
                      execution.
                    </p>
                  </div>
                </a>
                <div
                  className="p-[16px] w-full cursor-not-allowed"
                  style={{
                    background: investalphabg,
                  }}
                  onMouseEnter={() =>
                    setInvestalphabg(
                      "radial-gradient(50% 46.32% at 50% 100%, #0ECAE9 0%, rgba(6, 7, 27, 0) 100%)"
                    )
                  }
                  onMouseLeave={() => setInvestalphabg("")}
                >
                  <div className="flex items-center">
                    <img
                      src={InvestAlpha}
                      alt="err"
                      style={
                        investalphabg
                          ? {
                              filter:
                                "drop-shadow(0px 0px 25px #4A8EED) drop-shadow(0px 0px 25px #4BEFFF)",
                            }
                          : {}
                      }
                    />
                    <p className="text-[#777E91] font-montserrat text-lg leading-[30px] font-medium ml-3 mb-0">
                      InvestAlpha
                    </p>
                    <p
                      className="font-montserrat font-medium text-sm bg-clip-text align-middle ml-4 mb-0"
                      style={{
                        background:
                          "linear-gradient(223.78deg, #09C3E7 0%, #7669F0 106.87%)",
                        WebkitTextFillColor: "transparent",
                        WebkitBackgroundClip: "text",
                      }}
                    >
                      Coming Soon
                    </p>
                  </div>

                  <p className="font-montserrat font-normal text-sm eading-[21px] text-[#777E91] ml-9 mb-0">
                    Personalised investment management. At Scale.
                  </p>
                </div>
              </div>
            </ClickAwayListener>
          )}
          {/* {productsOpen && (
            <ClickAwayListener onClickAway={() => setProductsOpen(false)}>
              <div
                className="bg-primarybg-300 rounded-2xl border-[#353945] shadow-[0px_12px_64px_#06071B] p-[16px] w-[430px] mt-4"
                ref={setproductsPopperRef}
                style={{
                  ...productpopper.styles.popper,
                  border: "1px solid #353945",
                }}
                {...productpopper.attributes.popper}
              >
                <div
                  className="p-[16px] w-full"
                  style={{ boxShadow: "inset 0px -1px 0px #23262F" }}
                >
                  <div className="flex">
                    <img src={Investedge} alt="err" />
                    <p className="text-white font-montserrat text-lg leading-[30px] font-medium ml-3 mb-0">
                      Invest Edge
                    </p>
                  </div>
                  <div className="flex justify-end pb-2">
                    <img src={MenuArrow} alt="err" />
                  </div>

                  <p className="font-montserrat font-normal text-sm eading-[21px] text-[#E6E8EC] ml-9 mb-0">
                    Section for InvestEdge with text and Visuals
                  </p>
                </div>
                <a href={process.env.REACT_APP_investlane}>
                  <div
                    className="p-[16px] w-full"
                    style={{ boxShadow: "inset 0px -1px 0px #23262F" }}
                  >
                    <div className="flex">
                      <img src={Investlane} alt="err" />
                      <p className="text-white font-montserrat text-lg leading-[30px] font-medium ml-3 mb-0">
                        Invest Lane
                      </p>
                    </div>
                    <div className="flex justify-end pb-2">
                      <img src={MenuArrow} alt="err" />
                    </div>

                    <p className="font-montserrat font-normal text-sm eading-[21px] text-[#E6E8EC] ml-9 mb-0">
                      Section for InvestLane with text and Visuals
                    </p>
                  </div>
                </a>
                <div className="p-[16px] w-full">
                  <div className="flex items-center">
                    <img src={InvestAlpha} alt="err" />
                    <p className="text-white font-montserrat text-lg leading-[30px] font-medium ml-3 mb-0">
                      Invest Alpha
                    </p>
                    <p
                      className="font-montserrat font-medium text-sm bg-clip-text align-middle ml-4 mb-0"
                      style={{
                        background:
                          "linear-gradient(223.78deg, #09C3E7 0%, #7669F0 106.87%)",
                        WebkitTextFillColor: "transparent",
                        WebkitBackgroundClip: "text",
                      }}
                    >
                      Coming Soon
                    </p>
                  </div>

                  <p className="font-montserrat font-normal text-sm eading-[21px] text-[#777E91] ml-9 mb-0">
                    Section for InvestAlpha with text and Visuals
                  </p>
                </div>
              </div>
            </ClickAwayListener>
          )} */}
          {/* {loginOpen && (
            <ClickAwayListener onClickAway={() => setLoginOpen(false)}>
              <div
                className="bg-primarybg-300 rounded-2xl border border-[#353945] shadow-[0px_12px_64px_#06071B] p-[16px] w-[400px]"
                ref={setPopperRef}
                style={styles.popper}
                {...attributes.popper}
              >
                <div className="p-[16px] flex justify-between items-center w-full">
                  <div className="flex">
                    <img src={Investedge} alt="err" />
                    <p className="text-white font-montserrat text-lg leading-[30px] font-medium ml-3 mb-0">
                      Login to Invest Edge
                    </p>
                  </div>
                  <img src={MenuArrow} alt="err" />
                </div>
                <div className="p-[16px] flex justify-between items-center w-full">
                  <div className="flex">
                    <img src={Investlane} alt="err" />
                    <p className="text-white font-montserrat text-lg leading-[30px] font-medium ml-3 mb-0">
                      Login to Invest Lane
                    </p>
                  </div>
                  <img src={MenuArrow} alt="err" />
                </div>
              </div>
            </ClickAwayListener>
          )} */}
        </div>
      </div>
      <div
        className="md:hidden px-[20px] flex justify-between fixed top-0 w-full"
        style={{ zIndex: 80, background: "rgba(12, 4, 24, 0.85)" }}
      >
        <a href={process.env.REACT_APP_beyondirrhome}>
          <img src={Logo} alt="err" className="h-[28px] my-[20px]" />
        </a>

        <div className="flex items-center">
          <Link to="/login">
            <p className="font-montserrat  text-[#FCFCFD] mb-0">Login</p>
          </Link>
          {REACT_APP_development !== "production" ? (
            <img
              src={Menu}
              alt="err"
              className="ml-[20px]"
              onClick={() => setSidenavbar(true)}
            />
          ) : null}
        </div>
      </div>
      <div
        className={
          sidenavbar
            ? "md:hidden duration-300 bg-primarybg-100 w-[100vw] h-[100%] fixed top-0 z-[100] right-0 p-8 overflow-hidden"
            : "md:hidden bg-primarybg-100 w-[100%] h-[100%] fixed top-0 right-[-100%] duration-700 z-[100] p-8"
        }
      >
        <div className="flex flex-col justify-between h-full">
          <div>
            <div
              className="py-[20px] flex justify-between items-center"
              style={{
                boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.12)",
              }}
            >
              <p className="font-poppins font-normal text-sm text-[#FCFCFD] mb-0">
                Menu
              </p>
              <img
                src={MenuCross}
                alt="err"
                onClick={() => setSidenavbar(false)}
              />
            </div>
            <div className="mt-[20px] flex justify-between items-center">
              <p className="font-poppins font-semibold text-2xl leading-[36px] text-[#FCFCFD] mb-0">
                Products
              </p>
              <img
                src={WhiteArrow}
                alt="err"
                id="mobile-header-menu-arrow"
                className="duration-200"
                onClick={handleArrowClick}
              />
            </div>
            <Fade in={productsSubmenu} unmountOnExit>
              <div
                // className={
                //   productsSubmenu
                //     ? "bg-primarybg-300 rounded-2xl w-full mt-4"
                //     : "hidden"
                // }
                className="bg-primarybg-300 rounded-2xl w-full mt-[16px]"
              >
                <div
                  className="p-[16px] w-full"
                  style={{ boxShadow: "inset 0px -1px 0px #23262F" }}
                >
                  <div className="flex">
                    <img src={Investedge} alt="err" />
                    <p className="text-white font-montserrat leading-[30px] font-medium ml-3">
                      InvestEdge
                    </p>
                  </div>
                  <div className="flex justify-between items-start pt-1">
                    <p className="font-montserrat font-normal text-sm eading-[21px] text-[#E6E8EC] ml-9">
                      Market Views, Product Stories,
                      <br /> and Portfolio Insights.
                    </p>
                    <img src={MenuArrow} alt="err" />
                  </div>
                </div>
                <a href={process.env.REACT_APP_investlane}>
                  <div
                    className="p-[16px] w-full"
                    style={{ boxShadow: "inset 0px -1px 0px #23262F" }}
                  >
                    <div className="flex">
                      <img src={Investlane} alt="err" />
                      <p className="text-white font-montserrat leading-[30px] font-medium ml-3">
                        InvestLane
                      </p>
                    </div>
                    <div className="flex justify-between items-start pt-1">
                      <p className="font-montserrat font-normal text-sm eading-[21px] text-[#E6E8EC] ml-9">
                        Differentiated investment opportunities.
                      </p>
                      <img src={MenuArrow} alt="err" />
                    </div>
                  </div>
                </a>
                <div className="p-[16px] w-full">
                  <div className="flex items-center">
                    <img src={InvestAlpha} alt="err" />
                    <p className="text-[#777E91] font-montserrat leading-[30px] font-medium ml-3">
                      InvestAlpha
                    </p>
                    <p
                      className="font-montserrat font-medium text-sm bg-clip-text align-middle ml-4"
                      style={{
                        background:
                          "linear-gradient(223.78deg, #09C3E7 0%, #7669F0 106.87%)",
                        WebkitTextFillColor: "transparent",
                        WebkitBackgroundClip: "text",
                      }}
                    >
                      Coming Soon
                    </p>
                  </div>

                  <p className="font-montserrat font-normal text-sm eading-[21px] text-[#777E91] ml-9">
                    Personalised investment management.
                  </p>
                </div>
              </div>
            </Fade>
            <Link to="/about">
              <p className="mt-[20px] font-poppins font-semibold text-2xl leading-[36px] text-[#FCFCFD] mb-0">
                About Us
              </p>
            </Link>
          </div>
          <div className="flex justify-center">
            <img src={WhiteLogo} alt="err" />
          </div>
        </div>
      </div>
    </>
  );
}
