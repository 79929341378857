import React from "react";
import "../../../static/css/existing/dashboard_styles.css";
import SearchIcon from "../../../assets/img/bigsearchicon.svg";

export default function Searchbar() {
  return (
    <div>
      <form
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          className="search-bar-container mt-4 mb-4"
          style={{
            width: "100%",
            boxShadow:
              "2px 2px 6px rgba(0, 0, 0, 0.05), -2px -2px 6px rgba(0, 0, 0, 0.05)",
          }}
        >
          <img src={SearchIcon} alt="err" />
          <input
            placeholder="Search Mutual Funds, Stocks, PMS, AIFs, MLDs, Unlisted Shares, and much more..."
            style={{
              boxShadow: "none",
              border: "none",
              fontFamily: "Inter , sans-serif",
              marginLeft: "1rem",
            }}
          />
        </div>
      </form>
    </div>
  );
}
