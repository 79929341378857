import React from "react";
import { category } from "../onePagerConsts";
import Ani2 from "../../../assets/img/ani2.svg";
import "../../../static/css/existing/dashboard_styles.css";

export default function categoryComponent() {
  return (
    <>
      <div
        style={{
          backgroundColor: "#5D5FEF",
          color: "#fff",
          padding: "1rem",

          borderRadius: "6px",
          boxShadow:
            " 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(0, 0, 0, 0.05)",
          minheight: "274px",
          marginTop: "1.5rem",
          display: "flex",
        }}
      >
        <div style={{ marginRight: "1rem" }}>
          <img src={Ani2} alt="err" />
        </div>

        {/* #6 Contains Category card items */}
        <div>
          <h5 className="text-md onepager-small-heading">{category.title}</h5>
          <p className="onepager-description-text mt-1 ">{category.text} </p>
        </div>
      </div>
    </>
  );
}
