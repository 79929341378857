import React, { useEffect, useState } from "react";
import AfterloginApp from "../../rmdashboard/AfterloginApp";
import Clientsidebar from "../ClientSidebar";
import Plus from "../../assets/img/add_new_icon.svg";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import InvestedgePagination from "../../components/pagination/InvestedgePagination";
import dayjs from "dayjs";
import AddMeeting from "./AddMeeting";
import axios from "axios";
import urls from "../../urls";
export default function MeetingNotes() {
  const [modal, setModal] = useState(false);
  const numToMonthMap = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };
  const dateParser = (date) => {
    var dd = String(date.getDate()).padStart(2, "0");
    var mm = String(date.getMonth() + 1).padStart(2, "0");
    var yyyy = date.getFullYear();
    return dd + " " + numToMonthMap[parseInt(mm)].slice(0, 3) + " " + yyyy;
  };

  const [meetings, setMeetings] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const clientsPerPage = 6;
  const pagesVisited = pageNumber * clientsPerPage;
  const [modelData, setModelData] = useState({
    topic: "",
    date: new Date(),
    meeting_notes: "",
    time: [dayjs(new Date().toISOString()), dayjs(new Date().toISOString())],
    people: "",
  });
  const displaymeetings = meetings
    .slice(pagesVisited, pagesVisited + clientsPerPage)
    .map((meeting, index) => {
      const hours = new Date(meeting.start_time).getHours();
      const minutes = new Date(meeting.start_time).getMinutes();
      const amOrPm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12; // Convert to 12-hour format
      const formattedMinutes = minutes.toString().padStart(2, "0");
      const formattedTime = `${formattedHours}:${formattedMinutes} ${amOrPm}`;
      const hours2 = new Date(meeting.end_time).getHours();
      const minutes2 = new Date(meeting.end_time).getMinutes();
      const amOrPm2 = hours2 >= 12 ? "PM" : "AM";
      const formattedHours2 = hours2 % 12 || 12; // Convert to 12-hour format
      const formattedMinutes2 = minutes2.toString().padStart(2, "0");
      const formattedTime2 = `${formattedHours2}:${formattedMinutes2} ${amOrPm2}`;
      return (
        <tr
          key={index}
          style={
            index !==
            meetings.slice(pagesVisited, pagesVisited + clientsPerPage).length -
              1
              ? { borderBottom: "1px solid #e0dddd" }
              : {}
          }
          className="hover:bg-[#f3f3f3] cursor-pointer"
          //   onClick={() => {
          //     localStorage.setItem("clientId", client.id);
          //     localStorage.setItem("clientName", client.name);
          //     dispatch(
          //       PostEventLoggingData({
          //         module_name: "Dashboard",
          //         event_type: "Information",
          //         url: window.location.href,
          //         note: `Client - ${client.name} clicked`,
          //       })
          //     );
          //     history.push("/clientdashboard");
          //   }}
        >
          <td
            className="font-inter text-sm not-italic tracking-[0em] p-[1rem] text-left text-primary-200 underline !font-medium"
            onClick={() => {
              setModelData({
                topic: meeting.topic_of_the_meeting,
                date: new Date(meeting.date),
                meeting_notes: meeting.meeting_notes,
                time: [
                  dayjs(new Date(meeting.start_time).toISOString()),
                  dayjs(new Date(meeting.end_time).toISOString()),
                ],
                people: meeting.people_in_the_meeting,
                meeting_note: meeting.meeting_notes,
                patch: true,
                id: meeting.id,
              });
              setModal(true);
            }}
          >
            {meeting.topic_of_the_meeting}
          </td>
          <td className="font-inter text-sm not-italic tracking-[0em] p-[1rem] pl-0">
            {dateParser(new Date(meeting.date))}
          </td>
          <td className="font-inter text-sm not-italic tracking-[0em] p-[1rem] pl-0">
            {formattedTime} - {formattedTime2}
          </td>
          <td className="font-inter text-sm not-italic tracking-[0em] p-[1rem] pl-0">
            {meeting.people_in_the_meeting.length > 40
              ? meeting.people_in_the_meeting.slice(0, 40) + "..."
              : meeting.people_in_the_meeting}
          </td>
        </tr>
      );
    });
  useEffect(() => {
    axios
      .get(urls.dashboardMeetingSummaryURL, {
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
      .then((response) => {
        setMeetings(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const pageCount = Math.ceil(meetings.length / clientsPerPage);
  const changePage = (event, value) => {
    setPageNumber(value - 1);
  };
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp />
      <div className="loggedinscreen-container pt-0">
        <div className="loggedinscreen flex flex-row justify-between">
          <Clientsidebar />
          <div className="p-[1rem] bg-[#fff] rounded-[0.5rem] min-h-full client-input-parent-card mt-4 w-[79%] ml-0 relative">
            <div className="w-full flex justify-between">
              <h1 className="card-heading">Meeting Notes</h1>
              <InvestedgeButton
                className="h-full"
                onClick={() => {
                  setModelData({
                    topic: "",
                    date: new Date(),
                    meeting_notes: "",
                    time: [
                      dayjs(new Date().toISOString()),
                      dayjs(new Date().toISOString()),
                    ],
                    people: "",
                  });
                  setModal(true);
                }}
                //   onClick={() =>
                //     dispatch(
                //       PostEventLoggingData({
                //         module_name: "Dashboard",
                //         event_type: "Add button",
                //         url: window.location.href,
                //         note: "Add new client clicked",
                //       })
                //     )
                //   }
              >
                <img
                  className=" mr-[0.5rem] object-contain w-[14px]"
                  src={Plus}
                  alt="err"
                />
                Add New
              </InvestedgeButton>
            </div>
            <AddMeeting
              modal={modal}
              onClose={() => setModal(false)}
              title="Add new meeting"
              modelData={modelData}
              setMeetings={setMeetings}
            />
            <div className="w-full mt-4 min-h-[400px]">
              <table className="w-full font-inter investedge-table hidden sm:table ">
                <colgroup>
                  <col width="33%" />
                  <col width="15%" />
                  <col width="15%" />
                  <col width="40%" />
                </colgroup>
                <tbody>
                  <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                    <th className="pl-[1rem] font-medium text-left">Topic</th>
                    <th className="text-start font-medium">Date</th>
                    <th className="text-start font-medium">Time</th>
                    <th className="text-start font-medium">People</th>
                  </tr>
                  {displaymeetings}
                </tbody>
              </table>
            </div>

            <div className="flex justify-end">
              <InvestedgePagination
                count={pageCount}
                defaultPage={1}
                page={pageNumber + 1}
                onChange={changePage}
                color="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
