import React, { useEffect } from "react";
import AfterloginApp from "../../rmdashboard/AfterloginApp";
import "../../static/css/existing/dashboard_styles.css";
import SettingsSidebar from "../SettingsSidebar";
import GeneralContent from "./GeneralContent";
import { useDispatch, useSelector } from "react-redux";
import { GetSettingsGeneral, GetRminfo } from "../../actions/actions";
export default function General() {
  const dispatch = useDispatch();
  const userdata = useSelector((state) => state.userdata);
  useEffect(() => {
    dispatch(GetSettingsGeneral());
    userdata.role !== "O" && userdata.role !== "SO" && dispatch(GetRminfo());
    // eslint-disable-next-line
  }, []);

  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Settings"}
        description={"Update and configure your information"}
      />
      <div className="loggedinscreen-container pt-0">
        <div className="loggedinscreen flex flex-row justify-between">
          <SettingsSidebar />
          <div className="p-[1rem] bg-[#FFF] min-h-full rounded-[0.5rem] w-[79%] mt-4">
            <GeneralContent />
          </div>
        </div>
      </div>
    </div>
  );
}
