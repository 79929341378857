import fundmanger from "../../../../../assets/img/fundmanager2.svg";
export const Manager = () => {
  return (
    <div className=" w-[72%] pt-[60px]">
      <h4 className="card-heading mb-6">Fund Manager Details</h4>
      <div className="grid grid-rows-2 grid-cols-2 gap-4 ">
        <div className="manager-card-one bg-[#FAFAFA] rounded-[6px] p-4">
          <div className="flex w-50% ">
            <div className="w-[48px] h-[48px]">
              <img src={fundmanger} alt="alt" />
            </div>
            <div className="pl-4">
              <p className="font-inter text-[18px] font-semibold text-left align-top tracking-[3%] mb-1 text-[#000000] leading-[22px]">
                Gaurav Mishra
              </p>
              <p className="font-inter text-[14px]  text-left align-top tracking-[3%]  text-[#000000] ">
                MBA, IIM Lucknow/ BA ECO(HONS)
              </p>
            </div>
          </div>
          <p className="font-inter text-[14px]  text-left align-top mb-0  text-[#6A6A76] mt-2">
            26 years of experience in investment management and equity research
            functions. Prior to this he has worked as Senior Portfolio Manager
            with ASK Investment Managers Limited.
          </p>
        </div>
        <div className="manager-card-one bg-[#FAFAFA] rounded-[6px] p-4">
          <div className="flex w-50% ">
            <div className="w-[48px] h-[48px]">
              <img src={fundmanger} alt="alt" />
            </div>
            <div className="pl-4">
              <p className="font-inter text-[18px] font-semibold text-left align-top tracking-[3%] mb-1 text-[#000000] leading-[22px]">
                Gaurav Mishra
              </p>
              <p className="font-inter text-[14px]  text-left align-top tracking-[3%]  text-[#000000] ">
                MBA, IIM Lucknow/ BA ECO(HONS)
              </p>
            </div>
          </div>
          <p className="font-inter text-[14px]  text-left align-top mb-0  text-[#6A6A76]  mt-2">
            26 years of experience in investment management and equity research
            functions. Prior to this he has worked as Senior Portfolio Manager
            with ASK Investment Managers Limited.
          </p>
        </div>
        <div className="manager-card-one bg-[#FAFAFA] rounded-[6px] p-4">
          <div className="flex w-50% ">
            <div className="w-[48px] h-[48px]">
              <img src={fundmanger} alt="alt" />
            </div>
            <div className="pl-4">
              <p className="font-inter text-[18px] font-semibold text-left align-top tracking-[3%] mb-1 text-[#000000] leading-[22px]">
                Gaurav Mishra
              </p>
              <p className="font-inter text-[14px]  text-left align-top tracking-[3%]  text-[#000000] ">
                MBA, IIM Lucknow/ BA ECO(HONS)
              </p>
            </div>
          </div>
          <p className="font-inter text-[14px]  text-left align-top mb-0  text-[#6A6A76]  mt-2">
            26 years of experience in investment management and equity research
            functions. Prior to this he has worked as Senior Portfolio Manager
            with ASK Investment Managers Limited.
          </p>
        </div>
      </div>
    </div>
  );
};
