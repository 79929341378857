import React, { useState } from "react";
import DropdownForAttributes from "../../../../components/Dropdowns/DropdownForAttributes";
import InvestedgeTextField from "../../../../components/textfield/InvestedgeTextField";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
import { InputAdornment } from "@mui/material";
export default function PortfolioFirstStep({
  handleNext,
  formValue,
  setFormValue,
}) {
  const denotionParser = (denotion, value) => {
    if (denotion === "K") return parseInt(value * 1000).toLocaleString("en-IN");
    else if (denotion === "L")
      return parseInt(value * 100000).toLocaleString("en-IN");
    else if (denotion === "C")
      return parseInt(value * 10000000).toLocaleString("en-IN");
    else return "";
  };
  let proposalDenotion, topUpDenotion;
  const [formData, setFormData] = useState({
    name: formValue.client_name,
    proposalValue: denotionParser(
      formValue.sip_proposal_value.denomination,
      formValue.sip_proposal_value.amount
    ),
    proposalDenomination: `₹ ${formValue.sip_proposal_value.denomination}`,
    duration: formValue.duration_of_sip,
    frequencySip: formValue.frequency_of_sip,
    topUp: denotionParser(formValue.top_up.type, formValue.top_up.value),
    topUpType: `₹ ${formValue.top_up.type}`,
    frequencyTopUp: formValue.frequency_of_topup,
    purpose_of_sip: formValue.purpose_of_sip,
  });
  const [errors, setErrors] = useState({
    name: null,
    proposalValue: null,
    topUp: null,
  });
  return (
    <div className="flex ">
      <div className="px-8 rounded-[8px] w-full bg-white py-8 mt-[16px] flex justify-center min-h-[calc(100vh_-_260px)]">
        <div className="w-[80%]">
          <div className="w-full flex justify-between">
            <h2 className="card-heading">Client Details</h2>
            <InvestedgeButton
              onClick={() => {
                const re = /^\d+$/;
                if (
                  formData.name.length === 0 ||
                  formData.proposalValue.length === 0 ||
                  !re.test(formData.proposalValue.replaceAll(",", ""))
                ) {
                  let newerrors = { ...errors };
                  if (formData.name.length === 0)
                    newerrors.name = "This field is required";
                  if (
                    formData.proposalValue.length === 0 ||
                    !re.test(formData.proposalValue.replaceAll(",", ""))
                  )
                    newerrors.proposalValue = "Please enter a valid value";
                  setErrors(newerrors);
                } else {
                  let temp;
                  if (
                    parseFloat(formData.proposalValue.replaceAll(",", "")) /
                      1000 <
                    100
                  ) {
                    proposalDenotion = "K";
                    temp =
                      parseFloat(formData.proposalValue.replaceAll(",", "")) /
                      1000;
                  } else if (
                    parseFloat(formData.proposalValue.replaceAll(",", "")) /
                      100000 <
                    100
                  ) {
                    proposalDenotion = "L";
                    temp =
                      parseFloat(formData.proposalValue.replaceAll(",", "")) /
                      100000;
                  } else {
                    proposalDenotion = "C";
                    temp =
                      parseFloat(formData.proposalValue.replaceAll(",", "")) /
                      10000000;
                  }
                  let temp2;
                  if (formData.topUp) {
                    if (
                      parseFloat(formData.topUp.replaceAll(",", "")) / 1000 <
                      100
                    ) {
                      topUpDenotion = "K";
                      temp2 =
                        parseFloat(formData.topUp.replaceAll(",", "")) / 1000;
                    } else if (
                      parseFloat(formData.topUp.replaceAll(",", "")) / 100000 <
                      100
                    ) {
                      topUpDenotion = "L";
                      temp2 =
                        parseFloat(formData.topUp.replaceAll(",", "")) / 100000;
                    } else {
                      topUpDenotion = "C";
                      temp2 =
                        parseFloat(formData.topUp.replaceAll(",", "")) /
                        10000000;
                    }
                  }
                  setFormValue({
                    ...formValue,
                    client_name: formData.name,
                    sip_proposal_value: {
                      amount: parseFloat(temp),
                      denomination: proposalDenotion,
                    },
                    purpose_of_sip: formData.purpose_of_sip,
                    duration_of_sip: formData.duration,
                    frequency_of_sip: formData.frequencySip,
                    top_up: {
                      type: formData.topUp ? topUpDenotion : "K",
                      value:
                        formData.topUp.length === 0 ? 0 : parseFloat(temp2),
                    },
                    frequency_of_topup: formData.frequencyTopUp,
                  });
                  handleNext();
                }
              }}
            >
              Next
            </InvestedgeButton>
          </div>
          <InvestedgeTextField
            required
            type="text"
            sx={{
              fontFamily: "Inter,sans-sarif",
              fontWeight: 400,
              width: "70%",
              marginTop: "1rem",
            }}
            label="Client Name"
            error={errors.name ? true : false}
            helperText={errors.name ? errors.name : ""}
            value={formData.name}
            onChange={(e) => {
              if (!e.target.value) {
                let newerrors = { ...errors };
                newerrors.name = "This field is required";
                setErrors(newerrors);
              } else {
                let newerrors = { ...errors };
                newerrors.name = null;
                setErrors(newerrors);
              }
              setFormData({ ...formData, name: e.target.value });
            }}
          />
          <div className="w-[70%] mt-[24px]">
            <DropdownForAttributes
              selected={formData}
              setSelected={setFormData}
              options={[
                "Child Education",
                "Child Marriage",
                "Retirement Planning",
                "Wealth Creation",
              ]}
              attribute="purpose_of_sip"
              labelName="Purpose of SIP"
            />
          </div>
          <div className="w-[70%] flex justify-between mt-[24px]">
            {/* <div className="w-[15%]">
              <DropdownForAttributes
                labelName={null}
                selected={formData}
                setSelected={setFormData}
                options={["₹ K", "₹ L", "₹ C"]}
                attribute="proposalDenomination"
              />
            </div> */}
            <InvestedgeTextField
              required
              sx={{
                fontFamily: "Inter,sans-sarif",
                fontWeight: 400,
                width: "100%",
              }}
              label="SIP Value"
              value={formData.proposalValue}
              error={errors.proposalValue ? true : false}
              helperText={errors.proposalValue ? errors.proposalValue : ""}
              onChange={(e) => {
                const re = /^\d+$/;
                if (
                  e.target.value === "" ||
                  re.test(e.target.value.replaceAll(",", ""))
                )
                  setFormData({
                    ...formData,
                    proposalValue: e.target.value.length
                      ? parseInt(
                          e.target.value.replaceAll(",", "")
                        ).toLocaleString("en-IN")
                      : "",
                  });

                // const re = /^[0-9\b]+$/;
                // if (e.target.value === "" || re.test(e.target.value)) {
                //   if (!e.target.value) {
                //     let newerrors = { ...errors };
                //     newerrors.proposalValue = "This field is required";
                //     setErrors(newerrors);
                //   } else {
                //     let newerrors = { ...errors };
                //     newerrors.proposalValue = null;
                //     setErrors(newerrors);
                //   }

                // }
              }}
              min="0"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
            />
          </div>
          {/* <div className="mt-[20px] w-[70%]">
            <div className="w-full flex justify-between items-center mt-[0.5rem]">
              <div className="flex items-center w-[70%]">
                <p className="font-inter text-sm">Duration of SIP(in Yrs)</p>
              </div>

              <input
                className="border border-[#E5E5E5] h-[40px] shadow-none bg-[#FFF] w-[20%] px-[8px] font-sm text-[#3d3d3d] text-center text-sm"
                value={formData.duration}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === "" || re.test(e.target.value))
                    setFormData({
                      ...formData,
                      duration: parseInt(e.target.value),
                    });
                }}
              />
            </div>
            <InvestedgeSlider
              min={1}
              max={50}
              value={formData.duration}
              onChange={(e, val) =>
                setFormData({ ...formData, duration: parseInt(val) })
              }
              step={1}
            />
          </div> */}
          <div className="w-[70%] mt-[24px]">
            <DropdownForAttributes
              selected={formData}
              setSelected={setFormData}
              options={[
                "Every Weekday (Mon-Fri)",
                "Daily",
                "Weekly",
                "Monthly",
                "Quarterly",
                "Annually",
              ]}
              attribute="frequencySip"
              labelName="Frequency of SIP"
            />
          </div>
          <div className="w-[70%] flex justify-between mt-[24px]">
            {/* <div className="w-[15%]">
              <DropdownForAttributes
                labelName={null}
                selected={formData}
                setSelected={setFormData}
                options={["₹ K", "₹ L", "₹ C"]}
                attribute="proposalDenomination"
              />
            </div> */}
            <InvestedgeTextField
              sx={{
                fontFamily: "Inter,sans-sarif",
                fontWeight: 400,
                width: "100%",
              }}
              label="Top - Up"
              value={formData.topUp}
              onChange={(e) => {
                const re = /^\d+$/;
                if (
                  e.target.value === "" ||
                  re.test(e.target.value.replaceAll(",", ""))
                )
                  setFormData({
                    ...formData,
                    topUp: e.target.value.length
                      ? parseInt(
                          e.target.value.replaceAll(",", "")
                        ).toLocaleString("en-IN")
                      : "",
                  });
                // const re = /^[0-9\b]+$/;
                // if (e.target.value === "" || re.test(e.target.value))
                //   setFormData({ ...formData, topUp: e.target.value });
              }}
              min="0"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
            />
          </div>
          {/* <div className="w-[70%] flex justify-between mt-[24px]">
            <div className="w-[15%]">
              <DropdownForAttributes
                labelName={null}
                selected={formData}
                setSelected={setFormData}
                options={["₹ K", "₹ L", "₹ C"]}
                attribute="topUpType"
              />
            </div>
          </div> */}
          <div className="w-[70%] mt-[24px]">
            <DropdownForAttributes
              selected={formData}
              setSelected={setFormData}
              options={["Quarterly", "Annually"]}
              attribute="frequencyTopUp"
              labelName="Frequency of SIP Top - Up"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
