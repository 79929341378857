import React, { useEffect, useState } from "react";
import Dropdown from "../../components/Dropdowns/DropdownWhiteSingle";
import { useDispatch, useSelector } from "react-redux";
import { PostDashboardSuitableProducts } from "../../actions/actions";
import AfterloginApp from "../../rmdashboard/AfterloginApp";
import Clientsidebar from "../ClientSidebar";
import InvestedgeSearchField from "../../components/searchfield/InvestedgeSearchField";
import InvestedgePagination from "../../components/pagination/InvestedgePagination";
export default function SuitableProducts() {
  const dispatch = useDispatch();
  const [asset, setAsset] = useState("All");
  const [product, setProduct] = useState("All");
  const [searchProduct, setSearchProduct] = useState("");
  const tableData = useSelector((state) => state.dashboardSuitableProducts);
  const assetSet = new Set(tableData.map((product) => product.asset_type));
  const productSet = new Set(tableData.map((product) => product.product_type));
  const [data, setData] = useState(tableData);
  const [pageNumber, setPageNumber] = useState(0);
  const clientsPerPage = 5;
  const pagesVisited = pageNumber * clientsPerPage;
  const displaydata = data
    .slice(pagesVisited, pagesVisited + clientsPerPage)
    .map((client, index) => {
      return (
        <tr className="border-b border-[#e0dddd]" key={index}>
          <td className=" text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-left pl-[1rem]">
            {client.product_name}
          </td>
          <td className=" text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
            {client.product_type}
          </td>
          <td className=" text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
            {client.asset_type}
          </td>
          <td className=" text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
            {/* {`${client.aum.amount} ${client.aum.denomination}`} */}
          </td>
          <td
            className={
              client.threeY > 0
                ? "text-[#EC3F3F] font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center"
                : "text-[#269E4F] font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center"
            }
          >
            {`${(client.three_year_cagr * 100).toFixed(2)}%`}
          </td>
          <td className=" text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
            {client.reason_for_suitability}
          </td>
        </tr>
      );
    });
  const pageCount = Math.ceil(data.length / clientsPerPage);
  const changePage = (e, value) => {
    setPageNumber(value - 1);
  };
  useEffect(() => {
    dispatch(
      PostDashboardSuitableProducts({
        client_id: localStorage.getItem("clientId"),
      })
    ); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setData(tableData); // eslint-disable-next-line
  }, [tableData]);

  // useEffect(() => {
  //   if (asset === "All" && product === "All")
  //     setData(
  //       tableData.filter((data) =>
  //         data.product_name.toLowerCase().includes(searchProduct.toLowerCase())
  //       )
  //     );
  //   else if (product === "All")
  //     setData(
  //       tableData.filter(
  //         (data) =>
  //           data.asset_type === asset &&
  //           data.product_name
  //             .toLowerCase()
  //             .includes(searchProduct.toLowerCase())
  //       )
  //     );
  //   else if (asset === "All")
  //     setData(
  //       tableData.filter(
  //         (data) =>
  //           data.product_type === product &&
  //           data.product_name
  //             .toLowerCase()
  //             .includes(searchProduct.toLowerCase())
  //       )
  //     );
  //   else
  //     setData(
  //       tableData.filter(
  //         (data) =>
  //           data.asset_type === asset &&
  //           data.product_type === product &&
  //           data.product_name
  //             .toLowerCase()
  //             .includes(searchProduct.toLowerCase())
  //       )
  //     ); // eslint-disable-next-line
  // }, [asset, product, searchProduct]);
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp />
      <div className="loggedinscreen-container pt-0">
        <div className="loggedinscreen flex flex-row justify-between">
          <Clientsidebar />
          <div className="p-[1rem] bg-[#fff] rounded-[0.5rem] min-h-full client-input-parent-card mt-4 w-[79%] ml-0 relative">
            <h1 className="card-heading">Suitable Products</h1>
            <div className="mt-[1rem]">
              <div className="flex w-[100%] justify-between">
                <div className="flex w-[30%] justify-between">
                  <div className="w-[49%]">
                    <Dropdown
                      options={["All", ...assetSet]}
                      selected={asset}
                      setSelected={setAsset}
                      labelName="Asset Type"
                    />
                  </div>
                  <div className="w-[49%]">
                    <Dropdown
                      options={["All", ...productSet]}
                      selected={product}
                      setSelected={setProduct}
                      labelName="Product Type"
                    />
                  </div>
                </div>
                <InvestedgeSearchField
                  placeholder="Search"
                  value={searchProduct}
                  onChange={(e) => setSearchProduct(e.target.value)}
                />
              </div>
              <div className=" rounded-[6px] min-h-[335px] mt-[1rem]">
                <table className="w-[100%] investedge-table">
                  <colgroup>
                    <col width="25%" />
                    <col width="12.5%" />
                    <col width="12.5%" />
                    <col width="12.5%" />
                    <col width="12.5%" />
                    <col width="25%" />
                  </colgroup>
                  <tbody>
                    <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                      <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-left pl-[1rem]">
                        Product Name
                      </th>
                      <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-center">
                        Product Type
                      </th>
                      <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-center">
                        Asset Type
                      </th>
                      <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-center">
                        AUM (₹)
                      </th>
                      <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-center">
                        3Y CAGR
                      </th>
                      <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-center">
                        Reason for Suitability
                      </th>
                    </tr>
                    {displaydata}
                  </tbody>
                </table>
              </div>
              <div className="w-full absolute bottom-4 right-4 mt-4 hidden md:flex justify-end">
                <InvestedgePagination
                  count={pageCount}
                  defaultPage={1}
                  page={pageNumber + 1}
                  onChange={changePage}
                  color="primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
