import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EmptyGoals from "../../assets/img/emptyGoalList.svg";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import { useSelector, useDispatch } from "react-redux";
import {
  GetDashboardPortfolioList,
  GetGoalsList,
  GetReviewPortfolioSummary,
} from "../../actions/actions";
import InvestedgePagination from "../../components/pagination/InvestedgePagination";
import GoalCard from "./GoalCard";
import Plus from "../../assets/img/add_new_icon.svg";
import InvestedgeSearchField from "../../components/searchfield/InvestedgeSearchField";
import { Bars } from "react-loader-spinner";
export default function GoalsList() {
  const dispatch = useDispatch();
  const goals = useSelector((state) =>
    Array.isArray(state.goalsList)
      ? state.goalsList.sort((a, b) => {
          return new Date(b.created_on) - new Date(a.created_on);
        })
      : []
  );
  useEffect(() => {
    dispatch(
      GetGoalsList({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      GetDashboardPortfolioList({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      GetReviewPortfolioSummary({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    ); // eslint-disable-next-line
  }, [dispatch]);
  const [goalsData, setGoalsData] = useState(goals);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchGoal, setSearchGoal] = useState("");
  const clientsPerPage = 8;
  const pagesVisited = pageNumber * clientsPerPage;
  const pageCount = Math.ceil(goalsData.length / clientsPerPage);
  const changePage = (e, val) => {
    setPageNumber(val - 1);
  };
  useEffect(() => {
    setGoalsData(goals);
  }, [goals]);
  useEffect(() => {
    setGoalsData(
      goals
        .filter(
          (goal) =>
            goal.goal_title &&
            goal.goal_title.toLowerCase().includes(searchGoal.toLowerCase())
        )
        .sort((a, b) => {
          return new Date(b.created_on) - new Date(a.created_on);
        })
    ); // eslint-disable-next-line
  }, [searchGoal]);
  return (
    <div className="w-full h-[90%]">
      {goals.length ? (
        goals[0] === "loading" ? (
          <div className="w-full min-h-[500px] flex flex-col justify-center items-center opacity-[0.9]">
            <Bars color="#1982F8" height="60" width="60" />
          </div>
        ) : (
          <>
            <div className="w-full flex justify-between">
              <p className="card-heading">{`${localStorage.getItem(
                "clientName"
              )}'s Goals`}</p>
              <div className="flex items-end w-1/2 justify-end mb-2">
                <InvestedgeSearchField
                  type="text"
                  className="w-3/6"
                  value={searchGoal}
                  onChange={(e) => setSearchGoal(e.target.value)}
                  placeholder="Search goals"
                />
                <Link to="/goalplanning/create">
                  <InvestedgeButton
                    sx={{ marginBottom: "2px" }}
                    className="flex justify-between items-center ml-4"
                    // onClick={() => {
                    //   dispatch(EmptyModelPortfolioAnalytics());
                    //   dispatch(
                    //     PostEventLoggingData({
                    //       module_name: "Client Details",
                    //       event_type: "Add Button",
                    //       url: window.location.href,
                    //       note: `Add new client clicked`,
                    //     })
                    //   );
                    // }}
                  >
                    <img
                      className="w-[14px] object-contain mr-2"
                      src={Plus}
                      alt="err"
                    />
                    Add Goal
                  </InvestedgeButton>
                </Link>
              </div>
            </div>
            <div className="w-full grid grid-cols-4 gap-6 pt-8">
              {goalsData
                .slice(pagesVisited, pagesVisited + clientsPerPage)
                .map((goal) => {
                  return <GoalCard goal={goal} key={goal.id || 0} />;
                })}
            </div>
            <div className="w-full flex justify-end absolute bottom-4 right-4">
              <InvestedgePagination
                count={pageCount}
                defaultPage={1}
                page={pageNumber + 1}
                onChange={changePage}
                color="primary"
              />
            </div>
          </>
        )
      ) : (
        <>
          <p className="card-heading">{`${localStorage.getItem(
            "clientName"
          )}'s Goals`}</p>
          <div className="w-full h-full flex flex-col justify-center items-center">
            <img
              src={EmptyGoals}
              alt="err"
              className="w-[32%] object-contain"
            />
            <p className="text-neutralBlack font-medium mt-4">
              You haven’t started Goal Mapping for this client yet. Get started
              now!
            </p>
            <Link to="/goalplanning/create">
              <InvestedgeButton className="mt-4">
                Create a Goal
              </InvestedgeButton>
            </Link>
          </div>
        </>
      )}
    </div>
  );
}
