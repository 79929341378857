import React from "react";
import PieChart from "../../../dashboard/PieChart";
import ProgressBar from "./ProgressBar";
export default function AlternateSection({ analytics }) {
  const currentliquidityKeys = [];
  const currentliquidityValues = [];
  const proposedliquidityKeys = [];
  const proposedliquidityValues = [];
  const bgGradients = [
    "linear-gradient(223.78deg, #09C3E7 0%, #7669F0 106.87%)",
    "linear-gradient(225deg, #9C2979 0%, #F15E75 100%)",
    "linear-gradient(108.81deg, #FF9F38 -27.65%, #FE5C44 125%)",
    "linear-gradient(108.81deg, #FFD166 -27.65%, #C26F0D 125%)",
  ];
  for (const property in analytics.alternate_liquidity_allocation_before) {
    currentliquidityKeys.push(property);
    currentliquidityValues.push(
      (analytics.alternate_liquidity_allocation_before[property] * 100).toFixed(
        1
      )
    );
  }
  for (const property in analytics.alternate_liquidity_allocation_after) {
    proposedliquidityKeys.push(property);
    proposedliquidityValues.push(
      (analytics.alternate_liquidity_allocation_after[property] * 100).toFixed(
        1
      )
    );
  }
  const currentdata = {
    labels: currentliquidityKeys,

    datasets: [
      {
        data: currentliquidityValues,
        backgroundColor: [
          "#F8CE6C",
          "#ED6D84",
          "#A155B9",
          "#56A1E5",
          "#6CBDC0",
        ],
        borderColor: ["#F8CE6C", "#ED6D84", "#A155B9", "#56A1E5", "#6CBDC0"],
        borderWidth: 1,
      },
    ],
  };
  const proposeddata = {
    labels: proposedliquidityKeys,

    datasets: [
      {
        data: proposedliquidityValues,
        backgroundColor: [
          "#F8CE6C",
          "#ED6D84",
          "#A155B9",
          "#56A1E5",
          "#6CBDC0",
        ],
        borderColor: ["#F8CE6C", "#ED6D84", "#A155B9", "#56A1E5", "#6CBDC0"],
        borderWidth: 1,
      },
    ],
  };
  const pieoptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      labels: [
        {
          render: "percentage",
          position: "outside",
          fontFamily: "Inter,sans-serif",
          fontColor: "#323232",
          textMargin: 8,
          fontSize: 14,
        },
      ],
      datalabels: {
        display: false,
      },
    },
  };
  const currentProductsData = [];
  const proposedProductsData = [];
  for (const property in analytics.alternate_product_categories_before)
    currentProductsData.push({
      label: property,
      value: (
        analytics.alternate_product_categories_before[property] * 100
      ).toFixed(1),
    });
  for (const property in analytics.alternate_product_categories_after)
    proposedProductsData.push({
      label: property,
      value: (
        analytics.alternate_product_categories_after[property] * 100
      ).toFixed(1),
    });
  return (
    <>
      <div className="mt-4 flex justify-between">
        <h2 className="font-medium text-lg font-inter">Liquidity Allocation</h2>
        <div className="flex">
          <div className="flex items-center ml-[16px]">
            <div className="bg-[#F8CE6C] rounded w-[14px] h-[14px]" />
            <h2 className="text-xs ml-[8px] mb-0">Liquid</h2>
          </div>
          <div className="flex items-center ml-[16px]">
            <div className="bg-[#ED6D84] rounded w-[14px] h-[14px]" />
            <h2 className="text-xs ml-[8px] mb-0">Semi-liquid</h2>
          </div>
          <div className="flex items-center ml-[16px]">
            <div className="bg-[#A155B9] rounded w-[14px] h-[14px]" />
            <h2 className="text-xs ml-[8px] mb-0">Illiquid</h2>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between mt-[16px]">
        <div className=" w-[49%] bg-[#fafafa] min-h-[250px] p-[1rem]">
          <h4 className=" font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack">
            Current
          </h4>
          <PieChart
            data={currentdata}
            options={pieoptions}
            width={200}
            height={200}
          />
        </div>
        <div className=" w-[49%] bg-[#fafafa] min-h-[250px] p-[1rem]">
          <h4 className=" font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack">
            Proposed
          </h4>
          <PieChart
            data={proposeddata}
            options={pieoptions}
            width={200}
            height={200}
          />
        </div>
      </div>
      <h2 className="font-medium text-lg mt-[16px]">Product Allocation</h2>
      <div className="w-full flex justify-between mt-[16px]">
        <div className=" w-[49%] bg-[#fafafa] min-h-[250px] p-[1rem]">
          <h4 className=" font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack">
            Current
          </h4>
          {currentProductsData.map((product, index) => (
            <ProgressBar
              key={index}
              bg={bgGradients[index % bgGradients.length]}
              progress={product.value}
              label={product.label}
            />
          ))}
        </div>
        <div className=" w-[49%] bg-[#fafafa] min-h-[250px] p-[1rem]">
          <h4 className=" font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack">
            Proposed
          </h4>
          {proposedProductsData.map((product, index) => (
            <ProgressBar
              key={index}
              bg={bgGradients[index % bgGradients.length]}
              progress={product.value}
              label={product.label}
            />
          ))}
        </div>
      </div>
    </>
  );
}
