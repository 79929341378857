import React, { useEffect, useState } from "react";
import DefaultPortfolioPic from "../../../../../assets/img/modelportfoliothumbnailgreybg.svg";
import BookmarkUnsaved from "../../../../../assets/img/bookmarkunsaved.svg";
import BookmarkSaved from "../../../../../assets/img/bookmarksaved.svg";
import { useDispatch } from "react-redux";
import { PostModelPortfolioBookmark } from "../../../../../actions/actions";
export default function PortfolioCard({
  portfolioId,
  setPortfolioId,
  portfolio,
  bookmarkPortfolios,
  setBookmarkPortfolios,
}) {
  const dispatch = useDispatch();
  const [bookmarked, setBookmarked] = useState(
    bookmarkPortfolios[portfolio.id]
  );
  useEffect(() => {
    setBookmarked(bookmarkPortfolios[portfolio.id]);
    // eslint-disable-next-line
  }, [bookmarkPortfolios[portfolio.id]]);
  // const parseHorizon = (horizon) => {
  //   switch (horizon) {
  //     case "Less than 3 Years":
  //       return 2;
  //     case "3 years":
  //       return 3;
  //     case "5 years":
  //       return 5;
  //     case "7 years":
  //       return 7;
  //     case "10 years":
  //       return 10;
  //     case "More than 10 years":
  //       return 15;
  //     case "Other":
  //       return 15;
  //     default:
  //       return 5;
  //   }
  // };
  return (
    <div
      className={
        portfolioId === portfolio.id
          ? "border border-primary-100 rounded-[4px] bg-gradient-180 from-[#fafafa] to-[#f6f6f6] p-[1rem] min-h-[155px] z-0 w-full shadow-none m-0 duration-300 transition-transform"
          : "rounded-[4px] bg-gradient-180 from-[#fafafa] to-[#f6f6f6] p-[1rem] min-h-[155px] z-0  w-full shadow-none m-0 duration-300 transition-transform"
      }
      onClick={() => {
        setPortfolioId(portfolio.id);
        // setHorizon(parseHorizon(portfolio.investment_horizon));
      }}
    >
      <div className="flex justify-end items-start">
        <div className="flex justify-center items-center w-[97%] flex-col">
          <img
            className="h-[60px] w-[60px] rounded-[100%]"
            src={DefaultPortfolioPic}
            alt="err"
          />
          <div className="flex justify-center items-center mt-[1rem]">
            <p className="font-inter w-full text-sm not-italic leading-[19px] mb-0 tracking-[0em] text-center text-neutralBlack">
              {portfolio.portfolio_name}
            </p>
          </div>

          <p className="font-inter text-[12px] not-italic  leading-[12px] tracking-[0.015em] text-center mb-0 text-primary-200 mt-[0.5rem] bg-[#EDF5FE] py-[0.25rem] px-[0.5rem] rounded-[2px]">
            {portfolio.purpose_of_portfolio}
          </p>
        </div>
        <img
          src={bookmarked ? BookmarkSaved : BookmarkUnsaved}
          alt="err"
          onClick={() => {
            const temp = bookmarkPortfolios;
            temp[portfolio.id] = !temp[portfolio.id];
            dispatch(
              PostModelPortfolioBookmark({
                bookmark: temp[portfolio.id],
                portfolio_id: portfolio.id,
              })
            );
            setBookmarkPortfolios(temp);
            setBookmarked((prevState) => !prevState);
          }}
          className="abssolute top-4 right-4 w-[10px]"
        />
      </div>
    </div>
  );
}
