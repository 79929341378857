import React from "react";
import { useState } from "react";
// import { ThemeProvider } from "@material-ui/styles";
// import { Switch, FormControlLabel } from "@mui/material";
// import { createTheme } from "@mui/material/styles";
import ArrowLeft from "../../../../assets/img/left_arrow_icon.svg";
import ArrowDown from "../../../../assets/img/down_arrow_icon.svg";
import TextField from "@mui/material/TextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
export default function GoldRow({
  fund,
  productarray,
  setProductarray,
  patchProductArray,
  setPatchProductArray,
  deleteArray,
  setDeleteArray,
  data,
  postProductArray,
  setPostProductArray,
  addedProduct,
}) {
  // const BirrSwitch = createTheme({
  //   overrides: {
  //     MuiSwitch: {
  //       root: {
  //         width: 70,
  //         height: 24,
  //         padding: 0,
  //         marginRight: 10,
  //       },
  //       palette: {
  //         primary: "#0000f5",
  //         secondary: "#0000f5",
  //       },
  //       switchBase: {
  //         padding: 0,
  //         color: "#0000f5",
  //         "&$checked": {
  //           transform: "translateX(45px)",
  //           color: "#0000f5",
  //           "& + $track": {
  //             backgroundColor: "#0000f5",
  //             opacity: 0.4,
  //             border: "none",
  //           },
  //         },
  //         "&$focusVisible $thumb": {
  //           color: "#0000f5",
  //           border: "6px solid #fff",
  //         },
  //       },
  //       thumb: {
  //         width: 24,
  //         height: 24,
  //       },
  //       track: {
  //         borderRadius: 30 / 2,
  //         border: `1px solid #c4c4c4`,
  //         opacity: 0.2,
  //       },
  //       checked: {},
  //     },
  //   },
  // });
  const [open, setOpen] = useState(fund.id === addedProduct ? true : false);

  // const [checked, setChecked] = useState(false);

  // let handleChange = (i, e) => {
  //   let newMfarray = [...productarray];
  //   newMfarray[i][e.target.name] = e.target.value;
  //   setProductarray(newMfarray);
  // };
  // const icon = (
  //   <Box>
  //     <>
  //       <td style={{ width: "25%" }}>
  //         <TextField
  //           fullWidth
  //           sx={{
  //             fontFamily: "Inter,sans-sarif",
  //             fontWeight: 400,
  //           }}
  //           label="SIP Amount"
  //           type="text"
  //         />
  //       </td>
  //       <td style={{ width: "25%" }}>
  //         <TextField
  //           fullWidth
  //           sx={{
  //             fontFamily: "Inter,sans-sarif",
  //             fontWeight: 400,
  //           }}
  //           label="SIP frequency"
  //           type="text"
  //         />
  //       </td>
  //     </>
  //   </Box>
  // );
  return (
    <>
      <tr className=" w-full bg-[#f5f5f5] mt-[0.5rem] flex justify-between items-center p-[0.1rem] h-[50px] pr-[0.21rem] rounded-[4px]">
        <td className=" font-medium text-[15px] text-[#000]">{fund.name}</td>
        <td>
          <tr>
            <td className=" font-medium text-[14px]">{`₹ ${parseInt(
              fund.weight
                ? parseFloat(fund.weight * fund.current_price).toFixed(0)
                : 0
            ).toLocaleString("en-IN")}`}</td>
            <td
              className=" font-medium text-[15px] text-primary-200 mr-[1rem]"
              onClick={() => {
                let newMfArray = [...productarray];
                let index = productarray.findIndex((pid) => pid.id === fund.id);
                newMfArray.splice(index, 1);
                setProductarray(newMfArray);
                //editing in patch mf array
                if (data.some((getFund) => getFund.id === fund.id)) {
                  let newPatchMfArray = [...patchProductArray];
                  let patchindex = newPatchMfArray.findIndex(
                    (pid) => pid.id === fund.id
                  );
                  if (patchindex !== -1) newPatchMfArray.splice(patchindex, 1);
                  setPatchProductArray(newPatchMfArray);
                  setDeleteArray([
                    ...deleteArray,
                    {
                      ...fund,
                      purchase_date: new Date(fund.purchase_date),
                      current_price_date: new Date(fund.current_price_date),
                    },
                  ]);
                } //editing in post mf array
                else {
                  let newPostMfArray = [...postProductArray];
                  let postindex = newPostMfArray.findIndex(
                    (pid) => pid.id === fund.id
                  );
                  newPostMfArray.splice(postindex, 1);
                  setPostProductArray(newPostMfArray);
                }
              }}
            >
              Delete
            </td>
            <td
              className="p-[0px] w-[20px] pl-[0.3rem]"
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open ? (
                <img className="block" src={ArrowDown} alt="err" />
              ) : (
                <img className="block" src={ArrowLeft} alt="err" />
              )}
            </td>
          </tr>
        </td>
      </tr>
      {open ? (
        <div className="pt-[16px]">
          <tr className="flex w-full">
            <td className="w-[31%]">
              <TextField
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label={fund.commodity_id}
                required
                type="text"
                value={fund.name}
                onChange={(e) => {
                  let newMfArray = [...productarray];
                  let index = productarray.findIndex(
                    (pid) => pid.id === fund.id
                  );
                  newMfArray[index].name = e.target.value;
                  setProductarray(newMfArray);
                  //editing in patch mf array
                  if (data.some((getFund) => getFund.id === fund.id)) {
                    let newPatchMfArray = [...patchProductArray];
                    let patchindex = newPatchMfArray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    if (patchindex === -1) {
                      newPatchMfArray.push({
                        ...fund,
                        purchase_date: new Date(fund.purchase_date),
                        current_price_date: new Date(fund.current_price_date),
                      });
                      newPatchMfArray[newPatchMfArray.length - 1].name =
                        e.target.value;
                    } else newPatchMfArray[patchindex].name = e.target.value;
                    setPatchProductArray(newPatchMfArray);
                  } //editing in post mf array
                  else {
                    let newPostMfArray = [...postProductArray];
                    let postindex = newPostMfArray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newPostMfArray[postindex].name = e.target.value;
                    setPostProductArray(newPostMfArray);
                  }
                }}
              />
            </td>
            <td className="w-[23%]">
              <TextField
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Weight(in gm)"
                type="text"
                value={fund.weight}
                onChange={(e) => {
                  const re = /^\d*\.?\d*$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    let newMfArray = [...productarray];
                    let index = productarray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newMfArray[index].weight = e.target.value;
                    setProductarray(newMfArray);
                    //editing in patch mf array
                    if (data.some((getFund) => getFund.id === fund.id)) {
                      let newPatchMfArray = [...patchProductArray];
                      let patchindex = newPatchMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      if (patchindex === -1) {
                        newPatchMfArray.push({
                          ...fund,
                          purchase_date: new Date(fund.purchase_date),
                          current_price_date: new Date(fund.current_price_date),
                        });
                        newPatchMfArray[newPatchMfArray.length - 1].weight =
                          e.target.value;
                      } else
                        newPatchMfArray[patchindex].weight = e.target.value;
                      setPatchProductArray(newPatchMfArray);
                    } //editing in post mf array
                    else {
                      let newPostMfArray = [...postProductArray];
                      let postindex = newPostMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      newPostMfArray[postindex].weight = e.target.value;
                      setPostProductArray(newPostMfArray);
                    }
                  }
                }}
              />
            </td>
            <td className="w-[23%]">
              <TextField
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Purchase Price(in ₹)"
                type="text"
                value={fund.purchase_price}
                onChange={(e) => {
                  const re = /^\d*\.?\d*$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    let newMfArray = [...productarray];
                    let index = productarray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newMfArray[index].purchase_price = e.target.value;
                    setProductarray(newMfArray);
                    //editing in patch mf array
                    if (data.some((getFund) => getFund.id === fund.id)) {
                      let newPatchMfArray = [...patchProductArray];
                      let patchindex = newPatchMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      if (patchindex === -1) {
                        newPatchMfArray.push({
                          ...fund,
                          purchase_date: new Date(fund.purchase_date),
                          current_price_date: new Date(fund.current_price_date),
                        });
                        newPatchMfArray[
                          newPatchMfArray.length - 1
                        ].purchase_price = e.target.value;
                      } else
                        newPatchMfArray[patchindex].purchase_price =
                          e.target.value;
                      setPatchProductArray(newPatchMfArray);
                    } //editing in post mf array
                    else {
                      let newPostMfArray = [...postProductArray];
                      let postindex = newPostMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      newPostMfArray[postindex].purchase_price = e.target.value;
                      setPostProductArray(newPostMfArray);
                    }
                  }
                }}
              />
            </td>
            <td className="w-[23%]">
              <TextField
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Current Price(in ₹)"
                type="text"
                value={fund.current_price}
                disabled
              />
            </td>
          </tr>
          <tr className="flex w-full">
            <td className="w-[25%]">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  autoOk={true}
                  className="client-input-date-input"
                  inputFormat="DD-MM-YYYY"
                  id="date-picker-inline"
                  label="Purchase Date"
                  maxDate={new Date()}
                  value={new Date(fund.purchase_date)}
                  onChange={(date) => {
                    let newMfArray = [...productarray];
                    let index = productarray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newMfArray[index].purchase_date = date;
                    setProductarray(newMfArray);
                    //editing in patch mf array
                    if (data.some((getFund) => getFund.id === fund.id)) {
                      let newPatchMfArray = [...patchProductArray];
                      let patchindex = newPatchMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      if (patchindex === -1) {
                        newPatchMfArray.push({
                          ...fund,
                          purchase_date: new Date(fund.purchase_date),
                          current_price_date: new Date(fund.current_price_date),
                        });
                        newPatchMfArray[
                          newPatchMfArray.length - 1
                        ].purchase_date = date;
                      } else newPatchMfArray[patchindex].purchase_date = date;
                      setPatchProductArray(newPatchMfArray);
                    } //editing in post mf array
                    else {
                      let newPostMfArray = [...postProductArray];
                      let postindex = newPostMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      newPostMfArray[postindex].purchase_date = date;
                      setPostProductArray(newPostMfArray);
                    }
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </td>
            <td className="w-[25%]">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  autoOk={true}
                  className="client-input-date-input"
                  inputFormat="DD-MM-YYYY"
                  id="date-picker-inline"
                  label="Curent Price Date"
                  value={new Date(fund.current_price_date)}
                  disabled={true}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </td>
            <td className="w-[23%]">
              <TextField
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Current Value(in ₹)"
                type="text"
                value={fund.weight * fund.current_price}
                disabled
              />
            </td>
          </tr>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
