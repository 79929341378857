import React, { useState } from "react";
import { FormControlLabel, Radio } from "@mui/material";
import { PostEventLoggingData } from "../../../../../actions/actions";
import { useDispatch } from "react-redux";
import { IoIosClose } from "react-icons/io";
import InvestedgeButton from "../../../../../components/buttons/InvestedgeButton";
import InvestedgeModal from "../../../../../components/modal/InvestedgeModal";
export default function RiskAssessmentModal({
  onClose,
  familyMembers,
  setFamilyMembers2,
  rsmodal,
  riskScore,
  setRiskscore,
  riskText,
}) {
  const [getScore, setGetScore] = useState(false);
  const dispatch = useDispatch();
  if (!rsmodal) return null;

  let handleChange = (e) => {
    let newfamilyMembers = { ...familyMembers };
    newfamilyMembers[e.target.name] = parseInt(e.target.value);
    newfamilyMembers.sumraq =
      newfamilyMembers.raq1 +
      newfamilyMembers.raq2 +
      newfamilyMembers.raq3 +
      newfamilyMembers.raq4 +
      newfamilyMembers.raq5;
    setFamilyMembers2(newfamilyMembers);
  };
  let handleclose = () => {
    onClose();
    setGetScore(false);
  };

  return (
    <>
      <InvestedgeModal
        aria-labelledby="Risk Assessment Questionnaire Client Dashboard"
        aria-describedby="Risk Assessment Questionnaire Client Dashboard"
        sx={{
          padding: "1.5rem",
          width: "75vw",
          marginRight: "auto",
          marginLeft: "auto",
        }}
        open={rsmodal}
        handleclose={onClose}
      >
        <div className="flex w-full justify-between mb-[2rem]">
          <h1 className="card-heading">Risk Assessment Questionnaire</h1>
          <IoIosClose size={30} onClick={onClose} />
        </div>

        <p className="text-[16px] font-inter text-neutralBlack mt-[1.5rem] font-medium mb-[0.3rem]">
          1. You decide to invest 10 lacs for next 10 years. Which portfolio
          will you choose?
        </p>
        <div className="product-understanding-modal-answers">
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers.raq1 === 1}
                onChange={(e) => handleChange(e)}
                value={1}
                name="raq1"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter">
                Portfolio giving 7% average return with no negative returns in
                any of last 10 years.
              </span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers.raq1 === 2}
                onChange={(e) => handleChange(e)}
                value={2}
                name="raq1"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter">
                Portfolio giving 9% average return but with negative returns in
                2 of last 10 years with max loss of 8% in 1 year.
              </span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers.raq1 === 3}
                onChange={(e) => handleChange(e)}
                value={3}
                name="raq1"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter">
                Portfolio giving 11% average return but with negative returns in
                3 of last 10 years with max loss of 15% in 1 year.
              </span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers.raq1 === 4}
                onChange={(e) => handleChange(e)}
                value={4}
                name="raq1"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter">
                Portfolio giving 13% average return but with negative returns in
                4 of last 10 years with max loss of 20% in 1 year.
              </span>
            }
          />
        </div>
        <p className="text-[16px] font-inter text-neutralBlack mt-[1.5rem] font-medium mb-[0.3rem]">
          2. What percentage of your investible surplus would you invest in a
          product/asset where the risk and return are expected to be high?
        </p>
        <div className="product-understanding-modal-answers">
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers.raq2 === 1}
                onChange={(e) => handleChange(e)}
                value={1}
                name="raq2"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={<span className="text-[14px] font-inter">Upto 10%.</span>}
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers.raq2 === 2}
                onChange={(e) => handleChange(e)}
                value={2}
                name="raq2"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={<span className="text-[14px] font-inter">10-20%.</span>}
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers.raq2 === 3}
                onChange={(e) => handleChange(e)}
                value={3}
                name="raq2"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={<span className="text-[14px] font-inter">20-40%.</span>}
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers.raq2 === 4}
                onChange={(e) => handleChange(e)}
                value={4}
                name="raq2"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter">More than 40%.</span>
            }
          />
        </div>
        <p className="text-[16px] font-inter text-neutralBlack mt-[1.5rem] font-medium mb-[0.3rem]">
          3. I will decide to sell my investments, if they fall by __% in first
          year of investment?
        </p>
        <div className="product-understanding-modal-answers">
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers.raq3 === 1}
                onChange={(e) => handleChange(e)}
                value={1}
                name="raq3"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={<span className="text-[14px] font-inter">10%.</span>}
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers.raq3 === 2}
                onChange={(e) => handleChange(e)}
                value={2}
                name="raq3"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={<span className="text-[14px] font-inter">20%.</span>}
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers.raq3 === 3}
                onChange={(e) => handleChange(e)}
                value={3}
                name="raq3"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter">More than 20%.</span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers.raq3 === 4}
                onChange={(e) => handleChange(e)}
                value={4}
                name="raq3"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={<span className="text-[14px] font-inter">Never.</span>}
          />
        </div>
        <p className="text-[16px] font-inter text-neutralBlack mt-[1.5rem] font-medium mb-[0.3rem]">
          4. You unexpectedly receive a huge amount of money in a lottery. All
          your goals and expenses are already taken care of. Where will you
          invest that money?
        </p>
        <div className="product-understanding-modal-answers">
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers.raq4 === 1}
                onChange={(e) => handleChange(e)}
                value={1}
                name="raq4"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter">Fixed Deposits.</span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers.raq4 === 2}
                onChange={(e) => handleChange(e)}
                value={2}
                name="raq4"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter">Debt Mutual Funds.</span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers.raq4 === 3}
                onChange={(e) => handleChange(e)}
                value={3}
                name="raq4"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={<span className="text-[14px] font-inter">Property.</span>}
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers.raq4 === 4}
                onChange={(e) => handleChange(e)}
                value={4}
                name="raq4"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter">
                Equity MF and Stocks.
              </span>
            }
          />
        </div>
        <p className="text-[16px] font-inter text-neutralBlack mt-[1.5rem] font-medium mb-[0.3rem]">
          5. You are on a TV game show and can choose one of the following.
          Which one would you pick?
        </p>
        <div className="product-understanding-modal-answers">
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers.raq5 === 1}
                onChange={(e) => handleChange(e)}
                value={1}
                name="raq5"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter">
                Get 5 lacs in cash and walk away.
              </span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers.raq5 === 2}
                onChange={(e) => handleChange(e)}
                value={2}
                name="raq5"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter">
                Continue with 50% chance of winning 10 lacs.
              </span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers.raq5 === 3}
                onChange={(e) => handleChange(e)}
                value={3}
                name="raq5"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter">
                Continue with 20% chance of winning 25 lacs.
              </span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers.raq5 === 4}
                onChange={(e) => handleChange(e)}
                value={4}
                name="raq5"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter">
                Continue with 10% chance of winning 1 crore.
              </span>
            }
          />
        </div>
        <div className="flex w-[97%] items-center justify-end mt-[1rem]">
          <InvestedgeButton
            onClick={() => {
              let newfamilyMembers = { ...familyMembers };
              newfamilyMembers.sumraq =
                newfamilyMembers.raq1 +
                newfamilyMembers.raq2 +
                newfamilyMembers.raq3 +
                newfamilyMembers.raq4 +
                newfamilyMembers.raq5;
              setRiskscore(
                Math.round((newfamilyMembers.sumraq - 4) * (100 / 16))
              );
              setFamilyMembers2(newfamilyMembers);
              dispatch(
                PostEventLoggingData({
                  module_name: "Portfolio Insights",
                  event_type: "Assess button",
                  url: window.location.href,
                  note: `Second Screen`,
                })
              );
              setGetScore(true);
            }}
          >
            Assess
          </InvestedgeButton>
        </div>
      </InvestedgeModal>

      <InvestedgeModal
        aria-labelledby="Risk Assessment Questionnaire Child Modal"
        aria-describedby="Risk Assessment Questionnaire Child Modal"
        sx={{
          padding: "1.5rem",
          minWidth: "40vw",
          marginRight: "auto",
          marginLeft: "auto",
        }}
        open={getScore}
        handleclose={() => setGetScore(false)}
      >
        <div className="modal-component-cross-container">
          <IoIosClose size={30} onClick={() => setGetScore(false)} />
        </div>
        <div className="h-[90%] flex justify-center items-center">
          <h1 className="text-[16px] font-medium font-inter text-neutralBlack text-center leading-[25px]">
            Your risk score is
            <span className="text-[16px] font-semibold text-primary-200 ml-[0.5rem]">
              {riskScore}
            </span>
            ,<br /> You should ideally maintain your portfolio as a
            <span className="text-[16px] font-semibold text-primary-200 pr-[0.25rem] pl-[0.26rem]">
              {riskText}
            </span>
            investor
          </h1>
        </div>
        <div className=" flex w-full justify-end">
          <button
            className="primary-button text-sm mr-[1rem] font-semibold py-2 px-3  shadow-boxShadowButton normal-case active:opacity-[0.8] focus:outline-none"
            onClick={handleclose}
          >
            Got it
          </button>
        </div>
      </InvestedgeModal>
    </>
  );
}
