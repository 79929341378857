import React, { useState } from "react";
import AfterloginApp from "../../AfterloginApp";
import { withStyles } from "@mui/styles";
import EmptyTable from "./EmptyTable";
import ScreenerTable from "./screenerTable";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Slider from "@mui/material/Slider";
import Dropdown from "../../../components/Dropdowns/DropdownWhiteSingle";
import Meter1 from "../../../assets/img/meter1.svg";
import Meter2 from "../../../assets/img/meter2.svg";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Meter3 from "../../../assets/img/meter3.svg";
import "../../../static/css/existing/dashboard_styles.css";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";

const label = { inputProps: { "aria-label": "Switch demo" } };
export default function Screeners() {
  const options1 = ["Mutual Fund", "One", "Two", "Three"];
  const options2 = ["Dynamic Bond", "One", "Two", "Three"];
  const options3 = ["10%", "One", "Two", "Three"];
  const options4 = ["10%", "One", "Two", "Three"];
  const [selected, setSelected] = useState(options1[0]);
  const [selected2, setSelected2] = useState(options2[0]);
  const [selected3, setSelected3] = useState(options3[0]);
  const [selected4, setSelected4] = useState(options4[0]);
  const [showResults, setShowResults] = useState(false);
  const onClick = () => setShowResults(true);

  const BlueRadio = withStyles({
    root: {
      color: "#0122AF",
      opacity: "0.8",
      "&$checked": {
        color: "#0122AF",
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Screener"}
        description={"Screen investment products using different filters"}
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen pt-4 px-[20px] pb-[40px]">
          <div className=" bg-[#fff] p-4 rounded-[0.5rem] min-h-[30%] border  border-primaryBlackborder shadow-primaryShadow">
            <h3 className=" font-inter text-[24px] not-italic font-medium leading-[30px]">
              Screener
            </h3>

            <div className="flex justify-between mt-4">
              <div className=" border border-[#e0dddd] h-[300px] w-[32%] bg-[#ffffff] pl-4 rounded-[4px] pt-4 shadow-screenerTableCont">
                <p className="bg-[#fff] font-inter not-italic font-normal text-[17px] mb-4">
                  Product Features
                </p>

                <FormControl>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    className="-mb-1"
                    style={{
                      color: "#999999",
                      fontFamily: "Inter , sans-serif",
                      fontSize: "13px",
                      fontWeight: "400",
                      marginBottom: "0.5rem",
                    }}
                  >
                    Asset Class
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      control={
                        <BlueRadio
                          size="small"
                          value="Equity"
                          name="gender"
                          inputProps={{ "aria-label": "Direct" }}
                        />
                      }
                      label={
                        <span className=" font-normal font-inter text-[12px]">
                          Equity
                        </span>
                      }
                    />
                    <FormControlLabel
                      control={
                        <BlueRadio
                          size="small"
                          value="Debt"
                          name="gender"
                          inputProps={{ "aria-label": "Direct" }}
                        />
                      }
                      label={
                        <span className=" font-normal font-inter text-[12px]">
                          Debt
                        </span>
                      }
                    />{" "}
                    <FormControlLabel
                      control={
                        <BlueRadio
                          size="small"
                          value="Alternate"
                          name="gender"
                          inputProps={{ "aria-label": "Direct" }}
                        />
                      }
                      label={
                        <span className=" font-normal font-inter text-[12px]">
                          Alternate
                        </span>
                      }
                    />
                    <FormControlLabel
                      control={
                        <BlueRadio
                          size="small"
                          value="Multi-Asset "
                          name="gender"
                          inputProps={{ "aria-label": "Direct" }}
                        />
                      }
                      label={
                        <span className=" font-normal font-inter text-[12px]">
                          Multi-Asset
                        </span>
                      }
                    />
                  </RadioGroup>
                </FormControl>

                <div className="row  flex flex-row gap-5 pl-4">
                  <div className="w-[44%] text-[#999999] font-inter text-[12px] font-normal">
                    <div className=" w-full h-[27px] pt-[1.3rem]">
                      <Dropdown
                        options={options1}
                        selected={selected}
                        setSelected={setSelected}
                        labelName="Product Type"
                      />
                    </div>
                  </div>

                  <div className=" w-[35%] h-[24px] pt-[1.3rem] pl-4">
                    <TextField
                      required
                      fullWidth
                      sx={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                      }}
                      label="Min. AUM (₹ Cr)"
                      type="text"
                      name=" Min. AUM (₹ Cr)"
                    />
                  </div>
                </div>

                <div className="row flex flex-row gap-5 mb-4 pl-4">
                  <div className="w-[44%] text-[#999999] font-inter text-[12px] font-normal">
                    <div className="mt-[4.4rem]">
                      <Dropdown
                        style={{
                          width: "155px",
                          height: "27px",
                        }}
                        options={options2}
                        selected={selected2}
                        setSelected={setSelected2}
                        labelName="Category"
                      />
                    </div>
                  </div>
                  <div className="w-[44%] text-[#999999] font-inter text-[12px] font-normal pt-[4.4rem] pl-4">
                    Exit Load? (Y/N)
                    <br></br>
                    <Switch
                      {...label}
                      className="mt-2"
                      style={{ color: "#0122AF", opacity: "0.8" }}
                    />
                  </div>
                </div>
              </div>
              <div className=" border border-[#e0dddd] h-[300px] w-[32%] bg-[#ffffff] pl-4 rounded-[4px] pt-4 shadow-screenerTableCont">
                <p className="bg-[#fff] font-inter not-italic font-normal text-[17px] mb-4">
                  Returns
                </p>
                <div className=" text-[#999999] font-inter text-[12px] font-normal mb-[0.1rem] ">
                  <p>Date</p>
                  <input
                    className=" font-inter w-[40%] border border-[#e0dddd] pl-[0.2rem] mb-4"
                    type="date"
                    name=""
                  />
                </div>
                <div className="row  flex flex-row gap-5">
                  <div className="w-[44%] mb-3 text-[#999999] font-inter text-[12px] font-normal pl-4">
                    Return Ranking
                    <div className="d-flex flex-row mt-2">
                      <button className="prd-in-btn prd-default  active1 font-inter font-normal not-italic">
                        1M
                      </button>
                      <button className="prd-in-btn prd-default  active1">
                        6M
                      </button>
                      <button className="prd-in-btn prd-default  active1">
                        1Y
                      </button>
                      <button className="prd-in-btn prd-default  active1">
                        3Y
                      </button>
                      <button className="prd-in-btn prd-default  active1">
                        5Y
                      </button>
                    </div>
                  </div>

                  <div className="w-[44%] text-[#999999]">
                    <br></br>
                    <div className=" w-full h-[22px] pl-4">
                      <Dropdown
                        style={{ height: "22px" }}
                        options={options3}
                        selected={selected3}
                        setSelected={setSelected3}
                      />
                    </div>
                  </div>
                </div>
                <div className="row flex flex-row gap-5 ">
                  <div className="w-[44%] pl-4 text-[#999999] font-inter text-[12px] font-normal">
                    Rolling Ranking
                    <div className="d-flex flex-row mt-2">
                      <button className="prd-in-btn prd-default  active2 font-inter font-normal not-italic">
                        1M
                      </button>
                      <button className="prd-in-btn prd-default  active2">
                        6M
                      </button>
                      <button className="prd-in-btn prd-default  active2">
                        1Y
                      </button>
                      <button className="prd-in-btn prd-default  active2">
                        3Y
                      </button>
                      <button className="prd-in-btn prd-default  active2">
                        5Y
                      </button>
                    </div>
                  </div>

                  <div className="w-[44%] text-[#999999]">
                    <br></br>
                    <div className="pl-4 w-full">
                      <Dropdown
                        options={options4}
                        selected={selected4}
                        setSelected={setSelected4}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" border border-[#e0dddd] h-[300px] w-[32%] bg-[#ffffff] pl-4 rounded-[4px] pt-4 shadow-screenerTableCont">
                <p className="bg-[#fff] font-inter not-italic font-normal text-[17px] mb-4">
                  Risk
                </p>

                <label>
                  <span className="text-[#999999] font-inter text-[13px] font-normal mb-[0.5rem]">
                    Volatility
                  </span>
                </label>
                <div className="flex flex-row gap-4 mb-4">
                  <img src={Meter1} alt="meter1" />
                  <img src={Meter2} alt="meter2" />
                  <img src={Meter3} alt="meter3" />
                </div>

                <div className="row flex flex-row gap-5 ">
                  <div className="text-[#999999] w-[70%] font-inter text-[12px] font-normal pl-4">
                    Beta
                    <br></br>
                    <Slider
                      valueLabelDisplay="on"
                      defaultValue={70}
                      step={1}
                      sx={{
                        marginTop: "1rem",
                        width: "90%",
                        height: 6,
                        color: "#0122AF",
                        opacity: "0.8",
                        "& .MuiSlider-track": {
                          border: "none",
                        },
                        "& .MuiSlider-rail": {
                          height: 5,
                          borderRadius: 10,
                          backgroundColor: "#E0DDDD",
                        },
                        "& .MuiSlider-thumb": {
                          height: 20,
                          width: 20,
                          backgroundColor: "#fff",
                          boxShadow:
                            "-2px -2px 8px rgba(0, 0, 0, 0.1), 2px 2px 6px rgba(0, 0, 0, 0.1);",
                          "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible":
                            {
                              boxShadow:
                                "-2px -2px 8px rgba(0, 0, 0, 0.1), 2px 2px 6px rgba(0, 0, 0, 0.1);",
                              border: "0",
                            },
                          "&:before": {
                            display: "none",
                          },
                        },
                        "& .MuiSlider-mark": {
                          backgroundColor: "transparent",
                        },
                        "& .MuiSlider-markLabel": {
                          //styleName: Text/Mini Description;
                          fontFamily: "Inter, sans-serif",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "11px",
                          letterSpacing: "0.05em",
                          textAlign: "center",

                          transform: "translateX(0%)",
                          color: "#939393",

                          '&[data-index="1"]': {
                            transform: "translateX(-100%)",
                          },
                        },
                        "& .MuiSlider-valueLabel": {
                          lineHeight: 1.2,
                          fontSize: 12,
                          background: "grey",
                          padding: 0,
                          width: 25,
                          height: 25,
                          borderRadius: "4px",
                          backgroundColor: "grey",
                          transformOrigin: "bottom left",
                          transform:
                            "translate(50%, -100%) rotate(0deg) scale(0)",
                          "&:before": { display: "none" },
                          "&.MuiSlider-valueLabelOpen": {
                            transform:
                              "translate(0%, -100%) rotate(0deg) scale(1)",
                          },
                          "& > *": {
                            transform: "rotate(0deg)",
                          },
                        },
                      }}
                    />
                    <div className="flex flex-row justify-between text-xs -mt-3 text-[#000]">
                      <p>Low Beta</p>
                      <p>High Beta</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="ml-[87%]">
              <button
                onClick={onClick}
                data-toggle="modal"
                data-target="#productInputModal"
                style={{
                  backgroundColor: "#0122AF",
                  fontSize: "14px",
                  fontWeight: "600",
                  display: "flex",
                  width: "120px",
                  height: "37px",
                  justifyContent: "center",
                }}
                className="text-white font-semibold py-2 px-3 rounded box-shadowed-button ml-12 mt-4"
              >
                RUN QUERY
              </button>
            </div>
          </div>
          <div className="bg-[#fff] p-4 min-h-[30%] rounded-[0.5rem] border border-primaryBlackborder shadow-primaryShadow w-full mt-4">
            <h3 className=" font-inter text-[24px] not-italic font-medium leading-[30px] mb-4">
              Results
            </h3>
            {showResults ? <ScreenerTable /> : <EmptyTable />}
          </div>
        </div>
      </div>
    </div>
  );
}
