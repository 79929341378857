import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../static/css/existing/dashboard_styles.css";
import Dropdown from "../../../components/Dropdowns/DropdownWhiteSingle";
import { Recommendation } from "../../../arrays/globalArray";
import {
  GetProductInput,
  // OpenSnackbar,
} from "../../../actions/actions";
import { Bars } from "react-loader-spinner";
import TextField from "@mui/material/TextField";
import { useMediaQuery } from "@mui/material";
// import Lock from "../../assets/img/lock_locked.svg";
import InvestedgeTextField from "../../../components/textfield/InvestedgeTextField";
import InvestedgeAutocomplete from "../../../components/autocomplete/InvestedgeAutocomplete";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import InvestedgePagination from "../../../components/pagination/InvestedgePagination";
import { FormControlLabel, Radio } from "@mui/material";
export default function ProductInput({
  setScreen,
  productId,
  setProductId,
  setProductName,
}) {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(GetProductInput());
  //   // eslint-disable-next-line
  // }, []);
  var data = useSelector((state) => state.productInputData.results);
  var dataloading = useSelector((state) => state.productInputData.loading);
  const [productsData, setProductsData] = useState(data);
  const [searchProduct, setSearchProduct] = useState("");
  const [asset, setAsset] = useState("All");
  const [product, setProduct] = useState("All");
  const [category, setCategory] = useState("All");
  const [searchBy, setSearchBy] = useState("product");
  const [recommendation, setRecommendation] = useState("All");
  const assetSet = ["Debt", "Alternate", "Equity", "Multi Asset"];
  const productSet = [
    "Mutual Fund",
    "PMS",
    "AIF",
    "Deposits",
    "Bonds or MLDS",
    "Stocks",
    "Offshore",
    "Unlisted Shares",
  ];
  const categorySet = [
    "Aggressive Hybrid Fund",
    "Alternate - ETFs - Gold",
    "Alternate - ETFs - Other",
    "Alternate - FoFs (Domestic) - Debt Oriented",
    "Alternate - FoFs (Overseas)",
    "Alternate - FoFs - Gold",
    "Alternate - Life Settlement",
    "Alternate - Long Short",
    "Alternate - Long short",
    "Alternate - Real Estate - Warehousing",
    "Alternate - Venture Capital - Early Stage Fund",
    "Alternate - Venture Debt",
    "Arbitrage Fund",
    "Balanced Advantage",
    "Balanced Hybrid Fund",
    "Bank Deposit",
    "Banking and PSU Fund",
    "Bonds",
    "Company Deposit",
    "Conservative Hybrid Fund",
    "Contra",
    "Corporate Bond",
    "Debt - Arbitrage Fund",
    "Debt - Bank Deposit",
    "Debt - Banking and PSU Fund",
    "Debt - Bonds",
    "Debt - Cash",
    "Debt - Company Deposit",
    "Debt - Corporate Bond",
    "Debt - Credit Risk Fund",
    "Debt - Debt -Interval Funds",
    "Debt - Dynamic Bond",
    "Debt - ETFs - Debt",
    "Debt - Fixed Maturity Plans",
    "Debt - Floating Rate",
    "Debt - FoFs (Domestic) - Debt Oriented",
    "Debt - Gilt - Gilt Fund with 10 year constant duration",
    "Debt - Gilt - Short & Mid Term",
    "Debt - High Yield Credit",
    "Debt - Liquid",
    "Debt - Long Duration",
    "Debt - Low Duration",
    "Debt - MLD",
    "Debt - Medium Duration",
    "Debt - Medium to Long Duration",
    "Debt - Money Market",
    "Debt - Overnight Fund",
    "Debt - Sector Funds",
    "Debt - Short Duration",
    "Debt - Solution Oriented - Retirement Fund",
    "Debt - Ultra Short Duration",
    "Dividend Yield",
    "Dynamic Asset Allocation",
    "Dynamic Bond",
    "ETFs",
    "ETFs - Other",
    "Equity - Contra",
    "Equity - Dividend Yield",
    "Equity - ETFs - Index",
    "Equity - ETFs - Other",
    "Equity - Equity Linked Savings Scheme",
    "Equity - FoFs (Domestic) - Equity Oriented",
    "Equity - FoFs (Overseas)",
    "Equity - Focused Fund",
    "Equity - Index Funds - Index - Nifty",
    "Equity - Index Funds - Index - Nifty Next 50",
    "Equity - Index Funds - Index - Sensex",
    "Equity - Index Funds - Index Funds - Other",
    "Equity - Market Cap Fund - Flexi Cap Fund",
    "Equity - Market Cap Fund - Large & Mid Cap",
    "Equity - Market Cap Fund - Large Cap Fund",
    "Equity - Market Cap Fund - Mid & Small Cap Fund",
    "Equity - Market Cap Fund - Mid Cap Fund",
    "Equity - Market Cap Fund - Multi Cap Fund",
    "Equity - Market Cap Fund - Small cap Fund",
    "Equity - Sector Funds",
    "Equity - Sector Funds - Auto",
    "Equity - Sector Funds - Banks & Financial Services",
    "Equity - Sector Funds - Consumption",
    "Equity - Sector Funds - Energy & Power",
    "Equity - Sector Funds - Infrastructure",
    "Equity - Sector Funds - Pharma & Health Care",
    "Equity - Sector Funds - Service Industry",
    "Equity - Sector Funds - Technology",
    "Equity - Solution Oriented - Children's Fund",
    "Equity - Solution Oriented - Retirement Fund",
    "Equity - Stocks - Large Cap",
    "Equity - Stocks - Mid Cap",
    "Equity - Stocks - Small Cap",
    "Equity - Thematic Fund",
    "Equity - Thematic Fund - Global",
    "Equity - Thematic Fund - MNC",
    "Equity - Unlisted Shares",
    "Equity - Value Fund",
    "Equity Linked Savings Scheme",
    "Equity Savings",
    "Fixed Maturity Plans",
    "Flexi Cap Fund",
    "Floating Rate",
    "FoFs",
    "FoFs (Domestic)",
    "FoFs (Overseas)",
    "Focused Fund",
    "Gilt",
    "High Yield Credit",
    "Index Funds",
    "Large Cap",
    "Large Cap Fund",
    "Liquid",
    "Long Duration",
    "Low Duration",
    "MLD",
    "Market Cap Fund",
    "Medium Duration",
    "Mid & Small Cap Fund",
    "Money Market",
    "Multi Asset - Aggressive Hybrid Fund",
    "Multi Asset - Arbitrage Fund",
    "Multi Asset - Balanced Advantage",
    "Multi Asset - Balanced Hybrid Fund",
    "Multi Asset - Capital Protection Funds",
    "Multi Asset - Conservative Hybrid Fund",
    "Multi Asset - Dynamic Asset Allocation",
    "Multi Asset - ETFs - Index",
    "Multi Asset - ETFs - Other",
    "Multi Asset - Equity Savings",
    "Multi Asset - FoFs (Domestic) - Debt Oriented",
    "Multi Asset - FoFs (Domestic) - Equity Oriented",
    "Multi Asset - FoFs (Overseas)",
    "Multi Asset - Index Funds - Index Funds - Other",
    "Multi Asset - Multi Asset Allocation",
    "Multi Asset - Real Estate",
    "Multi Asset - Solution Oriented - Children's Fund",
    "Multi Asset - Solution Oriented - Retirement Fund",
    "Multi Asset - Thematic Fund",
    "Multi Asset Allocation",
    "Overnight Fund",
    "Pre-IPO",
    "Real Estate",
    "Sector Funds",
    "Short Duration",
    "Solution Oriented - Children's Fund",
    "Solution Oriented - Retirement Fund",
    "Thematic Fund",
    "Ultra Short Duration",
    "Value Fund",
    "Venture Debt",
  ];
  const [pageNumber, setPageNumber] = useState(0);
  let pastSmallScreen = useMediaQuery("(min-width:768px)");
  const clientsPerPage = 5;
  // const pagesVisited = pageNumber * clientsPerPage;
  const displaydata =
    Array.isArray(productsData) &&
    productsData
      // .slice(pagesVisited, pagesVisited + clientsPerPage)
      .map((pid) => {
        return (
          <tr
            key={pid.product_id}
            className={`min-h-[72px] border-b border-[#e0dddd] ${
              productId === pid.product_id ? "bg-primary-accent" : ""
            }`}
            onClick={() => {
              setProductId(pid.product_id);
              setProductName(pid.name);
            }}
          >
            <td
              className="dashboard-body-second-card-table-data leading-5"
              style={{
                paddingLeft: "1rem",
                textAlign: "left",
              }}
            >
              {pid.name}
            </td>
            <td className="dashboard-body-second-card-table-data">
              {pid.asset_type}
            </td>
            <td className="dashboard-body-second-card-table-data">
              {pid.product_type}
            </td>
            <td className="dashboard-body-second-card-table-data leading-5">
              {pid.category}
            </td>
          </tr>
        );
      });
  const displaydataSmallScreen =
    Array.isArray(productsData) &&
    productsData
      // .slice(pagesVisited, pagesVisited + clientsPerPage)
      .map((pid, index) => {
        return (
          <div
            key={index}
            className="w-full p-4 border border-[#e0dddd] rounded mb-4"
            onClick={() => {
              setProductId(pid.product_id);
              setProductName(pid.name);
            }}
          >
            <p className="text-[#6A6A76] text-xs">Product Name</p>
            <p className="text-neutralBlack text-sm mt-1 leading-[18px]">
              {pid.name}
            </p>
            <div className="grid grid-cols-8 mt-3">
              <p className="text-[#6A6A76] text-xs col-span-2">Asset Class</p>
              <p className="text-[#6A6A76] text-xs col-span-3">Product Type</p>
              <p className="text-[#6A6A76] text-xs col-span-3">
                Product Category
              </p>
              <p className="text-neutralBlack text-sm col-span-2 mt-1 leading-[18px]">
                {pid.asset_type}
              </p>
              <p className="text-neutralBlack text-sm col-span-3 mt-1 leading-[18px]">
                {pid.product_type}
              </p>
              <p className="text-neutralBlack text-sm col-span-3 mt-1 leading-[18px]">
                {pid.category}
              </p>
            </div>
          </div>
        );
      });
  const pageCount = Math.ceil(
    useSelector((state) => state.productInputData.count) / 5
  );
  const changePage = (event, value) => {
    setPageNumber(value);
    dispatch(
      GetProductInput({
        params: {
          isin: searchBy === "isin" ? searchProduct : "",
          category: category === "All" ? "" : category,
          product_type: product === "All" ? "" : product,
          asset_type: asset === "All" ? "" : asset,
          search: searchBy === "product" ? searchProduct : "",
          recommendation: recommendation === "All" ? "" : recommendation,
          page: value,
          size: clientsPerPage,
        },
      })
    );
  };

  useEffect(() => {
    setProductsData(data);
  }, [data]);

  useEffect(() => {
    dispatch(
      GetProductInput({
        params: {
          isin: searchBy === "isin" ? searchProduct : "",
          category: category === "All" ? "" : category,
          product_type: product === "All" ? "" : product,
          asset_type: asset === "All" ? "" : asset,
          search: searchBy === "product" ? searchProduct : "",
          recommendation: recommendation === "All" ? "" : recommendation,
          page: 1,
          size: clientsPerPage,
        },
      })
    );
    setPageNumber(1);
    // eslint-disable-next-line
  }, [asset, product, category, recommendation, searchProduct, searchBy]);

  const flatProps = {
    options: ["All", ...categorySet].map((option) => option),
  };

  return (
    <>
      <div className="bg-white rounded-[0.5rem]">
        <h4 className="font-inter text-[16px] font-medium leading-[20px] tracking-[0em] text-left">
          Apply Filters or Search Products
        </h4>
        <div className="autocomplete-containing-div mt-[1rem] justify-between grid grid-cols-12 gap-4">
          <div className="md:col-span-2 col-span-6 hidden">
            <Dropdown
              options={["All", ...Recommendation]}
              selected={recommendation}
              setSelected={setRecommendation}
              labelName="Recommendation"
            />
          </div>
          <div className="md:col-span-2 col-span-6">
            <Dropdown
              options={["All", ...assetSet]}
              selected={asset}
              setSelected={setAsset}
              labelName="Asset Class"
            />
          </div>
          <div className="md:col-span-2 col-span-6">
            <Dropdown
              options={["All", ...productSet]}
              selected={product}
              setSelected={setProduct}
              labelName="Product Type"
            />
          </div>
          <div className="md:col-span-3 col-span-6">
            <InvestedgeAutocomplete
              {...flatProps}
              value={category}
              onChange={(event, value) => {
                setCategory(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Category"}
                  sx={{ boxShadow: "none" }}
                />
              )}
            />
          </div>

          <div className="md:col-span-5 col-span-18">
            <InvestedgeTextField
              fullWidth
              type="text"
              label="Search Products"
              value={searchProduct}
              onChange={(e) => setSearchProduct(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-[1rem] justify-end flex">
          <div className="w-[40%]">
            <FormControlLabel
              control={
                <Radio
                  size="small"
                  checked={searchBy === "product"}
                  onChange={(e) => setSearchBy("product")}
                  value="product"
                  name="searchBy"
                  inputProps={{ "aria-label": "default" }}
                />
              }
              label={<span className="text-[14px] font-inter">By Product</span>}
            />
            <FormControlLabel
              control={
                <Radio
                  size="small"
                  checked={searchBy === "isin"}
                  onChange={(e) => setSearchBy("isin")}
                  value="isin"
                  name="searchBy"
                  inputProps={{ "aria-label": "default" }}
                />
              }
              label={<span className="text-[14px] font-inter">By ISIN</span>}
            />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-[0.5rem] mt-[2rem]">
        <div className="flex justify-between w-full">
          <h3 className="font-inter text-lg not-italic font-medium ">
            Select Product
          </h3>{" "}
          <InvestedgeButton
            sx={{ marginBottom: "2px" }}
            className="ml-4"
            onClick={() => {
              setScreen("query");
            }}
            disabled={productId.length ? false : true}
          >
            Next
          </InvestedgeButton>
        </div>
        <div className="min-h-[400px] mt-4">
          {dataloading ? (
            <div className="w-full h-[350px] flex justify-center items-center opacity-[0.9]">
              <Bars color="#1982F8" />
            </div>
          ) : pastSmallScreen ? (
            <table className="w-full font-inter text-[15px] not-italic font-medium  tracking-[0em] text-center investedge-table hidden sm:table">
              <colgroup>
                <col width="40.5%" />
                <col width="11.5%" />
                <col width="11.5%" />
                <col width="36.5%" />
              </colgroup>
              <tbody>
                <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                  <th className="pl-[1rem] text-left font-medium">
                    Product Name
                  </th>
                  <th className="text-center font-medium">Asset Class</th>
                  <th className="text-center font-medium">Product Type</th>
                  <th className="text-center font-medium">Product Category</th>
                </tr>
                {displaydata}
              </tbody>
            </table>
          ) : (
            displaydataSmallScreen
          )}
        </div>
        <div className="w-full mt-4 flex justify-end">
          <InvestedgePagination
            count={pageCount}
            defaultPage={1}
            page={pageNumber}
            onChange={changePage}
            color="primary"
          />
        </div>
      </div>
    </>
  );
}
