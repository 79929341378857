import React, { useState } from "react";
import AfterloginApp from "../../rmdashboard/AfterloginApp";
import Clientsidebar from "../ClientSidebar";
import Dropdown from "../../components/Dropdowns/DropdownWhiteSingle";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import Copy from "../../assets/img/copytoclipboard.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import parse from "html-react-parser";
import { Bars } from "react-loader-spinner";
import axios from "axios";
import urls from "../../urls";
export default function SalesPitch() {
  const options = [localStorage.getItem("clientName")];
  const [selected, setSelected] = useState(options[0]);
  const [backendData, setBackendData] = useState({});
  const calculatePitch = () => {
    setBackendData({ loading: true });
    axios
      .get(urls.dashboardSalesPitchURL, {
        params: { client_id: localStorage.getItem("clientId") },
      })
      .then((response) => {
        setBackendData(response.data);
      })
      .catch(() => {
        setBackendData({ loading: true });
      });
  };
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp />
      <div className="loggedinscreen-container pt-0">
        <div className="loggedinscreen flex flex-row justify-between">
          <Clientsidebar />
          <div className="p-[1rem] bg-[#fff] rounded-[0.5rem] min-h-full client-input-parent-card mt-4 w-[79%] ml-0 relative">
            <h1 className="card-heading">Sales Pitch</h1>
            <div className="flex mt-4 items-center w-full">
              <div className="w-[22%]">
                <Dropdown
                  options={options}
                  selected={selected}
                  setSelected={setSelected}
                  labelName="Client"
                />
              </div>
              <InvestedgeButton className="ml-4" onClick={calculatePitch}>
                Get latest sales Pitch
              </InvestedgeButton>
            </div>
            <p className="text-sm text-neutralBlack mt-4">
              The sales pitch is generated basis the latest market views, client
              portfolio, and research views. It uses GPT models to generate
              human-like text.
            </p>
            <div className="w-3/4 mt-8">
              {backendData.loading ? (
                <div className=" w-full h-[340px] flex flex-col justify-center items-center opacity-[0.9]">
                  <Bars color="#1982F8" />
                  <p className=" font-inter text-[14px] font-medium text-neutralBlack mb-[1rem] mt-[1rem]">
                    Preparing pitch might take 4-5 minutes ...
                  </p>
                </div>
              ) : backendData.content ? (
                <>
                  <div className="w-full flex justify-between cursor-pointer">
                    <p className="font-medium text-neutralBlack text-sm">
                      Sales Pitch for
                      <span className="text-primary-200 ml-1">
                        {localStorage.getItem("clientName")}
                      </span>
                    </p>
                    <CopyToClipboard text={parse(backendData.content)}>
                      <div className="flex items-center">
                        <img src={Copy} alt="err" className="mr-2" />
                        <p className="text-sm text-primary-200">
                          Copy to Clipboard
                        </p>
                      </div>
                    </CopyToClipboard>
                  </div>
                  <p className="text-sm text-justify mt-6">
                    {parse(backendData.content)}
                  </p>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
