import React from "react";
import InvestedgeSlider from "../../../../components/slider/InvestedgeSlider";
import { useDispatch } from "react-redux";
import { GetIpsData } from "../../../../actions/actions";
import InvestedgeTextField from "../../../../components/textfield/InvestedgeTextField";
import RiskAssessmentModal from "./RiskAssessmentModal";
export default function CustomProposal({
  riskText,
  riskscore,
  setRiskscore,
  rsmodal,
  horizon,
  marks,
  setrsmodal,
  faqs,
  setFaqs,
  error,
  setError,
  handleChange,
  schemes,
  setSchemes,
}) {
  const dispatch = useDispatch();
  return (
    <>
      <table className="w-full mt-[1rem]">
        <colgroup>
          <col width="50%" />
          <col width="50%" />
        </colgroup>

        <tbody>
          <tr>
            <td className="pl-0">
              <label className="text-[14px] mr-[1rem] font-inter font-normal leading-[20px] tracking-[0em]">
                Risk Score
              </label>
              <div className="bg-primary-accent mb-[0.5rem] font-inter text-[12px] inline-block font-medium text-primary-200 py-[0.3rem] px-[0.5rem] rounded-[8px]">
                {riskText}
              </div>
            </td>
            <td style={{ paddingLeft: "3rem" }}>
              <label className="text-[14px] mr-[1rem] font-inter font-normal leading-[20px] tracking-[0em]">
                Don’t Know Your Client’s Risk Score?
              </label>
            </td>
          </tr>
          <tr>
            <td className="pl-0">
              <InvestedgeSlider
                sx={{ width: "90%" }}
                valueLabelDisplay="on"
                value={riskscore}
                onChange={(e, val) => setRiskscore(val)}
                onChangeCommitted={(e, val) =>
                  horizon.length !== 0 &&
                  dispatch(
                    GetIpsData({
                      params: {
                        risk_score: val,
                        investment_horizon: parseInt(horizon),
                      },
                    })
                  )
                }
                marks={marks}
                step={1}
              />
            </td>
            <td style={{ verticalAlign: "baseline", paddingLeft: "3rem" }}>
              <div
                className="text-sm text-primary-200"
                style={{ paddingTop: "1rem" }}
                onClick={() => setrsmodal(true)}
              >
                Click to view and change
              </div>
              <RiskAssessmentModal
                onClose={() => setrsmodal(false)}
                familyMembers={faqs}
                setFamilyMembers2={setFaqs}
                riskScore={riskscore}
                setRiskscore={setRiskscore}
                rsmodal={rsmodal}
                riskText={riskText}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <InvestedgeTextField
        required
        sx={{
          fontFamily: "Inter,sans-sarif",
          fontWeight: 400,
          width: "45%",
          marginTop: "1rem",
        }}
        label="Investment Horizon (Years)"
        error={error ? true : false}
        helperText={error ? error : false}
        type="text"
        value={horizon}
        onChange={(e) => {
          const re = /^\d*\.?\d*$/;
          if (e.target.value === "" || re.test(e.target.value)) {
            if (e.target.value.length) setError(null);
            else setError("This field is required");
            handleChange(e);
          }
        }}
      />
      <InvestedgeTextField
        sx={{
          fontFamily: "Inter,sans-sarif",
          fontWeight: 400,
          width: "45%",
          marginTop: "1.5rem",
        }}
        label="Number of Schemes for Asset Allocation (Optional)"
        min="1"
        max="100"
        value={schemes}
        onChange={(e) => {
          const re = /^[0-9\b]+$/;
          if (e.target.value === "" || re.test(e.target.value)) {
            setSchemes(e.target.value);
          }
        }}
      />
      {/* <label
className="portfolio-stepper-first-form-label"
style={{ marginTop: "2rem", fontSize: "14px" }}
>
Type of schemes to include in portfolio
</label>
<br />
<div
className="individual-details-radio-buttons-div"
style={{ width: "45%" }}
>
<FormControlLabel
  control={
    <BlueRadio
      size="small"
      checked={scheme === "Regular"}
      onChange={(e) => setScheme("Regular")}
      value="Regular"
      name="gender"
      inputProps={{ "aria-label": "Regular" }}
    />
  }
  label={
    <span
      style={{ fontWeight: "400", fontFamily: "Inter,sans-serif" }}
    >
      Regular
    </span>
  }
/>
<FormControlLabel
  control={
    <BlueRadio
      size="small"
      checked={scheme === "Direct"}
      onChange={(e) => setScheme("Direct")}
      value="Direct"
      name="gender"
      inputProps={{ "aria-label": "Direct" }}
    />
  }
  label={
    <span
      style={{ fontWeight: "400", fontFamily: "Inter,sans-serif" }}
    >
      Direct
    </span>
  }
/>

<FormControlLabel
  control={
    <BlueRadio
      size="small"
      checked={scheme === "Both"}
      onChange={(e) => setScheme("Both")}
      value="Both"
      name="gender"
      inputProps={{ "aria-label": "Both" }}
    />
  }
  label={
    <span
      style={{ fontWeight: "400", fontFamily: "Inter,sans-serif" }}
    >
      Both
    </span>
  }
/>
</div> */}
    </>
  );
}
