import React from "react";
import MFscreen from "./MF/MFscreen";
import StocksScreen from "./Stocks/StocksScreen";
import Bondsscreen from "./Bonds/Bondsscreen";
import PMSscreen from "./PMS/PMSscreen";
import AIFscreen from "./AIF/AIFscreen";
import CashScreen from "./Cash/CashScreen";
import DepositsScreen from "./Deposits/DepositsScreen";
import GoldScreen from "./Gold/GoldScreen";
import EPFscreen from "./EPF/EPFscreen";
import PPFscreen from "./PPF/PPFscreen";
import UnlistedShareScreen from "./UnlistedShares/UnlistedShareScreen";
import OthersScreen from "./Others/OthersScreen";
import SavingsScreen from "./Savings/SavingsScreen";
import EstateScreen from "./Estate/EstateScreen";
import OffshoreScreen from "./Offshore/Offshorescreen";
export default function PortfolioInputRouter({ route, setRoute, id, name }) {
  switch (route) {
    case "mutualfundsscreen":
      return <MFscreen id={id} name={name} />;
    case "stocksscreen":
      return <StocksScreen id={id} name={name} />;
    case "bondsscreen":
      return <Bondsscreen id={id} name={name} />;
    case "pmsscreen":
      return <PMSscreen id={id} name={name} />;
    case "aifsscreen":
      return <AIFscreen id={id} name={name} />;
    case "cashscreen":
      return <CashScreen id={id} name={name} />;
    case "depositsscreen":
      return <DepositsScreen id={id} name={name} />;
    case "goldscreen":
      return <GoldScreen id={id} name={name} />;
    case "epfscreen":
      return <EPFscreen id={id} name={name} />;
    case "ppfsscreen":
      return <PPFscreen id={id} name={name} />;
    case "unlistedscreen":
      return <UnlistedShareScreen id={id} name={name} />;
    case "offshorescreen":
      return <OffshoreScreen id={id} name={name} />;
    case "othersscreen":
      return <OthersScreen id={id} name={name} />;
    case "savingsscreen":
      return <SavingsScreen id={id} name={name} />;
    case "estatescreen":
      return <EstateScreen id={id} name={name} />;
    default:
      return null;
  }
}
