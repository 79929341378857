import React, { useRef, useState } from "react";
import Subsbg from "../assets/img/card_bg.svg";
import "../assets/css/style.css";
// import "../assets/vendor/icofont/icofont.min.css";
import urls from "../urls.js";

function Subscribe() {
  const form = useRef(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(form.current);
    fetch(urls.subscriptionURL, {
      method: "POST",
      body: data,
    }).then((res) => console.log(res.json()));
    e.target.reset();
  };

  const [email, setEmail] = useState("");

  function SubscribeButton() {
    if (email) {
      return (
        <button
          type="submit"
          className="btn btn-primary mb-2"
          data-toggle="modal"
          data-target="#successModal"
          style={{ backgroundColor: "#0000F5" }}
        >
          SUBSCRIBE
        </button>
      );
    } else {
      return (
        <button
          type="submit"
          className="btn btn-primary mb-2"
          data-toggle="modal"
          data-target="#successModal"
          style={{ backgroundColor: "#0000F5" }}
          disabled
        >
          SUBSCRIBE
        </button>
      );
    }
  }
  return (
    <>
      <section style={{ marginTop: "20px", backgroundImage: `url(${Subsbg})` }}>
        <div className="container">
          <div className="text-center font-semibold mt-5">
            <h3 className="text-gray-900 text-lg font-semibold">
              {" "}
              Subscribe to our Newsletter
            </h3>
          </div>
        </div>
        <br></br>
        <div className="row mb-5">
          <div className="col"></div>
          <div className="col">
            <form ref={form} onSubmit={handleSubmit}>
              <div className="form-row align-items-center">
                <div className="col-9">
                  <label className="sr-only" for="inlineFormInput">
                    Email
                  </label>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    className="form-control mb-2"
                    id="inlineFormInput"
                    placeholder="Enter Email ID"
                    name="email"
                  />
                </div>
                <div className="col-auto" style={{ marginLeft: "-3%" }}>
                  <SubscribeButton />
                </div>
              </div>
            </form>
          </div>
          <div className="col"></div>
        </div>
      </section>
      <div
        className="modal fade"
        id="successModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <br></br>
              <br></br>
              <h2
                className="p-5	"
                style={{ marginLeft: "8%", color: "#0000f5" }}
              >
                Thank You for Submitting the form!
              </h2>
              <br></br>
              <br></br>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Subscribe;
