import { GET_SIP_PPT_API } from "../actions/type";
const initialState = [];
export default function GetSIPPPTAPI(state = initialState, action) {
  switch (action.type) {
    case GET_SIP_PPT_API:
      return action.payload;
    default:
      return state;
  }
}
