import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import InvestedgeTextField from "../../../components/textfield/InvestedgeTextField";
import MFPortfolioRow from "./MFPortfolioRow";
import DisableSorted from "../../../assets/img/disabled_sort.svg";
import AscendingSorted from "../../../assets/img/ascending_sort.svg";
import DescendingSorted from "../../../assets/img/descending_sort.svg";

export default function MFPortfolio({ portfolioList, products, setProducts }) {
  const [search, setSearch] = useState("");
  const { portfolio } = useSelector((store) => store.dashboardMFPortfolio);
  const [sorted, setSorted] = useState({
    name: 0,
    unit: 0,
    amount: 0,
  });

  const sortingIcon = (state) =>
    state === 0
      ? DisableSorted
      : state === 1
      ? AscendingSorted
      : DescendingSorted;

  useEffect(() => {
    setProducts(portfolio);
    // eslint-disable-next-line
  }, [portfolio]);
  const sorting_switch = (attribute, sortingType, products) => {
    switch (sortingType) {
      case 0:
        return products;
      case 1:
        products.sort((a, b) => {
          const attributeA = isNaN(a[attribute])
            ? a[attribute].toUpperCase()
            : parseFloat(a[attribute]); // ignore upper and lowercase
          const attributeB = isNaN(b[attribute])
            ? b[attribute].toUpperCase()
            : parseFloat(b[attribute]); // ignore upper and lowercase

          if (attributeA < attributeB) {
            return -1;
          }
          if (attributeA > attributeB) {
            return 1;
          }
          return 0;
          // names must be equal
        });
        return products;
      case 2:
        products.sort((a, b) => {
          const attributeA = isNaN(a[attribute])
            ? a[attribute].toUpperCase()
            : parseFloat(a[attribute]); // ignore upper and lowercase
          const attributeB = isNaN(b[attribute])
            ? b[attribute].toUpperCase()
            : parseFloat(b[attribute]); // ignore upper and lowercase

          if (attributeA > attributeB) {
            return -1;
          }
          if (attributeA < attributeB) {
            return 1;
          }
          return 0;
        });
        return products;
      default:
        return products;
    }
  };
  const sortedArray = (products) => {
    if (sorted.name) return sorting_switch("name", sorted.name, products);
    else if (sorted.unit)
      return sorting_switch("count_units", sorted.unit, products);
    else return sorting_switch("current_amount", sorted.amount, products);
  };
  return (
    <>
      <div className="w-full flex justify-between items-center">
        <div className="font-medium font-inter text-[18px] text-neutralBlack mt-4">
          Mutual Funds - ₹ {portfolioList.total_mutual_fund_value}
        </div>
        <div className="flex">
          <InvestedgeTextField
            label="Search Fund"
            value={search}
            className="w-[200px]"
            onChange={(e) => {
              setSearch(e.target.value);
              setProducts(
                portfolio.filter((transaction) =>
                  transaction.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
                )
              );
            }}
          />
        </div>
      </div>

      <div className="max-h-[60vh] h-[60vh] scrollablediv overflow-auto mt-4 pt-[-8px]">
        <table className="investedge-table w-full">
          <colgroup>
            <col width="50%" />
            <col width="25%" />
            <col width="25%" />
            {/* <col width="15%" />
            <col width="15%" /> */}
          </colgroup>
          <tbody className="w-full">
            <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
              <th style={{ paddingLeft: "0rem" }}>
                <div className="flex items-center justify-start">
                  Name
                  <img
                    src={sortingIcon(sorted.name)}
                    onClick={() => {
                      setSorted({
                        name: (sorted.name + 1) % 3,
                        unit: 0,
                        amount: 0,
                      });
                    }}
                    alt="err"
                    className="w-6 ml-1 cursor-pointer"
                  />
                </div>
              </th>
              <th>
                <div className="flex items-center justify-center">
                  Total Unit
                  <img
                    src={sortingIcon(sorted.unit)}
                    onClick={() => {
                      setSorted({
                        name: 0,
                        unit: (sorted.unit + 1) % 3,
                        amount: 0,
                      });
                    }}
                    alt="err"
                    className="w-6 ml-1 cursor-pointer"
                  />
                </div>
              </th>
              {/* <th>
                Investment Amount <br /> (in ₹)
              </th> */}
              <th>
                <div className="flex items-center justify-center">
                  Current Amount <br /> (in ₹)
                  <img
                    src={sortingIcon(sorted.amount)}
                    onClick={() => {
                      setSorted({
                        name: 0,
                        unit: 0,
                        amount: (sorted.amount + 1) % 3,
                      });
                    }}
                    alt="err"
                    className="w-6 ml-1 cursor-pointer"
                  />
                </div>
              </th>
              {/* <th>
                Absolute Returns <br /> (in %)
              </th> */}
            </tr>
            {sortedArray(products).map((product, index) => (
              <MFPortfolioRow key={product.id} product={product} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
