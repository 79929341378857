import React, { useState } from "react";
import Minimalist from "../../../../assets/img/minimalist_template.png";
import Classic from "../../../../assets/img/classic_template.png";
import Tick from "../../../../assets/img/module_access_type_tick.svg";
import { FormControlLabel, Switch } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  GetReviewPortfolioPpt,
  PostEventLoggingData,
} from "../../../../actions/actions";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
export default function PortfolioFifthStep({
  handleBack,
  handleNext,
  formValue,
  // portfolioProducts,
  clientId,
  portfolioActions,
  portfolio,
}) {
  const dispatch = useDispatch();
  const [template, setTemplate] = useState(1);
  const [proposal, setProposal] = useState({
    summary: true,
    comparison: true,
    currentportfolioAnalytics: true,
    proposedportfolioAnalytics: true,
    portfolioActionable: true,
  });
  const [excelformat, setExcelFormat] = useState(false);

  let handleChangeSwitch = (e) => {
    let newProposal = { ...proposal };
    newProposal[e.target.name] = !newProposal[e.target.name];
    setProposal(newProposal);
  };
  return (
    <>
      <div className="px-8 rounded-[8px] w-full bg-white py-8 mt-[16px]">
        <div className="flex justify-between w-full">
          <h2 className="card-heading">Review Template</h2>
          <div className="flex items-center">
            <InvestedgeButton
              onClick={() => {
                dispatch(
                  PostEventLoggingData({
                    module_name: "Portfolio Review",
                    event_type: "Prev Button",
                    url: window.location.href,
                    note: `Fifth screen`,
                  })
                );
                handleBack();
              }}
            >
              Prev
            </InvestedgeButton>
            <InvestedgeButton
              className="ml-4"
              onClick={() => {
                dispatch(
                  GetReviewPortfolioPpt({
                    client_id: clientId,
                    allocation_preference: formValue.allocation_preference,
                    template: template,
                    ppt: {
                      executive_summary: proposal.summary,
                      portfolio_comparison: proposal.comparison,
                      current_portfolio_analytics:
                        proposal.currentportfolioAnalytics,
                      proposed_portfolio_analytics:
                        proposal.proposedportfolioAnalytics,
                      portfolio_actionable: proposal.portfolioActionable,
                    },
                    output: {
                      ppt: true,
                      excel: excelformat,
                      pdf: false,
                      all: false,
                    },
                    portfolio_final: portfolio.reduce((x, y) => {
                      x &&
                        y &&
                        (x[y.product_type] = x[y.product_type] || []).push(y);

                      return x;
                    }, {}),
                    actionable: portfolioActions,
                  })
                );
                dispatch(
                  PostEventLoggingData({
                    module_name: "Portfolio Review",
                    event_type: "Download Button",
                    url: window.location.href,
                    note: `Fifth screen`,
                  })
                );
                handleNext();
              }}
            >
              Download
            </InvestedgeButton>
          </div>
        </div>
      </div>
      <div className="w-[100%] flex mt-[16px]">
        <div className="p-[32px] rounded-[8px] w-full bg-white my-[4px]">
          <div className="flex justify-between w-full">
            <h2 className="card-heading">Select Template</h2>
          </div>
          <div className="w-[90%] pt-[2rem] flex justify-between">
            <div className="w-[45%]">
              {template === 1 ? (
                <div className="relative">
                  <div
                    className="w-[100%] bg-[#fafafa] p-[32px] min-h-[300px]"
                    onClick={() => setTemplate(1)}
                  >
                    <img
                      src={Minimalist}
                      alt="err"
                      style={{
                        width: "68%",
                        filter:
                          "drop-shadow(4px 4px 32px rgba(1, 34, 175, 0.16)) drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.05))",
                      }}
                    />
                    <p className="mt-[16px] font-inter font-medium text-lg mb-0">
                      Minimalist
                    </p>
                  </div>
                  <div className="absolute bottom-0 w-full h-full flex justify-end items-start p-[1rem] bg-primary-100/[0.3]">
                    <img src={Tick} alt="err" />
                  </div>
                </div>
              ) : (
                <div
                  className=" w-full bg-[#fafafa] p-[32px] min-h-[300px]"
                  onClick={() => setTemplate(1)}
                >
                  <img
                    src={Minimalist}
                    alt="err"
                    style={{
                      width: "68%",
                      filter:
                        "drop-shadow(4px 4px 32px rgba(1, 34, 175, 0.16)) drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.05))",
                    }}
                  />
                  <p className="mt-[16px] font-inter font-medium text-lg mb-0">
                    Minimalist
                  </p>
                </div>
              )}

              <div className="flex justify-between mt-[2rem]">
                <div className="w-[50%]">
                  <h3 className="font-inter text-[16px] font-medium leading-[20px] tracking-[0em] text-left text-[#000] mt-[1rem]">
                    Choose Proposal Components
                  </h3>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={proposal.summary}
                        name="summary"
                        onChange={(e) => handleChangeSwitch(e)}
                        inputProps={{ role: "switch" }}
                        color="primary"
                      />
                    }
                    label={
                      <span className=" font-inter text-[16px] font-normal leading-[20px] tracking-[0em] text-left text-neutralBlack">
                        Executive Summary
                      </span>
                    }
                    className="pt-[1.75rem]"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={proposal.comparison}
                        name="comparison"
                        onChange={(e) => handleChangeSwitch(e)}
                        inputProps={{ role: "switch" }}
                        color="primary"
                      />
                    }
                    label={"Portfolio Comparison"}
                    style={{ paddingTop: "1rem" }}
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={proposal.currentportfolioAnalytics}
                        name="currentportfolioAnalytics"
                        onChange={(e) => handleChangeSwitch(e)}
                        inputProps={{ role: "switch" }}
                        color="primary"
                      />
                    }
                    label={"Current Portfolio Analytics"}
                    style={{ paddingTop: "1rem" }}
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={proposal.proposedportfolioAnalytics}
                        name="proposedportfolioAnalytics"
                        onChange={(e) => handleChangeSwitch(e)}
                        inputProps={{ role: "switch" }}
                        color="primary"
                      />
                    }
                    label={"Proposed Portfolio Analytics"}
                    style={{ paddingTop: "1rem" }}
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={proposal.portfolioActionable}
                        name="portfolioActionable"
                        onChange={(e) => handleChangeSwitch(e)}
                        inputProps={{ role: "switch" }}
                        color="primary"
                      />
                    }
                    label={"Portfolio Actionable"}
                    style={{
                      paddingTop: "1rem",
                      fontFamily: "Inter,sans-serif",
                      fontWeight: 400,
                    }}
                  />
                </div>
                <div className="w-[35%]">
                  <h3 className="portfolio-stepper-fifth-form-subheading">
                    Output Format
                  </h3>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={true}
                        inputProps={{ role: "switch" }}
                        color="primary"
                      />
                    }
                    label={
                      <span className=" font-inter text-[16px] font-normal leading-[20px] tracking-[0em] text-left text-neutralBlack">
                        PPT
                      </span>
                    }
                    style={{ paddingTop: "1.75rem" }}
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={excelformat}
                        onChange={() => setExcelFormat(!excelformat)}
                        inputProps={{ role: "switch" }}
                        color="primary"
                      />
                    }
                    label={
                      <span className=" font-inter text-[16px] font-normal leading-[20px] tracking-[0em] text-left text-neutralBlack">
                        Excel
                      </span>
                    }
                    style={{ paddingTop: "1rem" }}
                  />
                </div>
              </div>
            </div>
            <div className="w-[45%]">
              {template === 2 ? (
                <div className="relative">
                  <div
                    className="w-[100%] bg-[#fafafa] p-[32px] min-h-[300px]"
                    onClick={() => setTemplate(2)}
                  >
                    <img
                      src={Classic}
                      alt="err"
                      style={{
                        width: "70%",
                        filter:
                          "drop-shadow(4px 4px 32px rgba(1, 34, 175, 0.16)) drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.05))",
                      }}
                    />
                    <p className="mt-[16px] font-inter font-medium text-lg mb-0">
                      Classic
                    </p>
                  </div>
                  <div className="absolute bottom-0 w-full h-full flex justify-end items-start p-[1rem] bg-primary-100/[0.3]">
                    <img src={Tick} alt="err" />
                  </div>
                </div>
              ) : (
                <div
                  className=" w-full bg-[#fafafa] p-[32px] min-h-[300px]"
                  onClick={() => setTemplate(2)}
                >
                  <img
                    src={Classic}
                    alt="err"
                    style={{
                      width: "70%",
                      filter:
                        "drop-shadow(4px 4px 32px rgba(1, 34, 175, 0.16)) drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.05))",
                    }}
                  />
                  <p className="mt-[16px] font-inter font-medium text-lg mb-0">
                    Classic
                  </p>
                </div>
              )}

              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
