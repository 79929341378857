import { GET_AUM_BREAKUP_CLIENT } from "../actions/type";
const initialState = [];
export default function GetAumBreakupClient(state = initialState, action) {
  switch (action.type) {
    case GET_AUM_BREAKUP_CLIENT:
      return action.payload;
    default:
      return state;
  }
}
