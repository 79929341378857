import React from "react";
import AfterloginApp from "../../rmdashboard/AfterloginApp";
import Clientsidebar from "../ClientSidebar";
import GoalsList from "./GoalsList";

export default function GoalPlanning() {
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp />
      <div className="loggedinscreen-container pt-0">
        <div className="loggedinscreen flex flex-row justify-between">
          <Clientsidebar />
          <div className="p-[1rem] bg-[#fff] rounded-[0.5rem] min-h-full client-input-parent-card mt-4 w-[79%] ml-0 relative">
            <GoalsList />
          </div>
        </div>
      </div>
    </div>
  );
}
