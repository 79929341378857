import React, { useEffect } from "react";
import EditFieldAbout from "./EditFieldAbout";
import Logo from "../../assets/img/logo_profile.svg";
import "../../static/css/existing/dashboard_styles.css";
import { useSelector, useDispatch } from "react-redux";
import { PostEventLoggingData } from "../../actions/actions";
import InvestedgeTextField from "../../components/textfield/InvestedgeTextField";
export default function AboutContent() {
  const aboutData = useSelector((state) => state.settingsAbout);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      PostEventLoggingData({
        module_name: "Settings",
        event_type: "Information",
        url: window.location.href,
        note: `About your firm`,
      })
    );
  }, [dispatch]);
  return (
    <>
      <EditFieldAbout
        label="Name of the Firm"
        value={aboutData.org_name}
        type="text"
      />
      <div className="flex  flex-col items-start mt-[1.3rem] max-w-full duration-[500ms] ">
        <label className="font-inter text-[14px] font-medium text-[#475569] tracking-[0.5px]">
          Logo
        </label>
        <div className="grid grid-cols-12 mt-4">
          <div className="col-span-4">
            <img
              src={aboutData.logo ? aboutData.logo : Logo}
              alt="logo"
              className="h-[80px]"
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 mt-[1.5rem]">
        <div className="col-span-4">
          <InvestedgeTextField
            variant="outlined"
            label="Bio"
            multiline
            minRows={4}
            maxRows={8}
            fullWidth
            type="text"
            disabled
            value={aboutData.bio}
          />
        </div>
      </div>
      <EditFieldAbout label="Vision" value={aboutData.vision} type="text" />
      <EditFieldAbout label="Mission" value={aboutData.mission} type="text" />
      <EditFieldAbout label="Address" value={aboutData.address} type="text" />
      <EditFieldAbout
        label="Company Email"
        value={aboutData.email}
        type="text"
      />
      <EditFieldAbout label="Website" value={aboutData.website} type="text" />
    </>
  );
}
