import React from "react";
import "../../../static/css/existing/dashboard_styles.css";
export default function FundDetail({ fundData }) {
  return (
    <div
      style={{
        borderRadius: "0px 0px 8px 8px",
      }}
      className="w-full justify-around bg-[#fff] p-[2.5rem] flex mt-[0.2rem]"
    >
      <div className=" flex-wrap w-[20%] border-r-[1px] border-[#E0DDDD] mr-[2rem]">
        <p className="font-inter text-[32px] font-medium text-primary-200 tracking-[-0.03em] leading-[39px] m-0">
          14Y
          {/* {fundData.final_data.highlights_section.track_record.main_val} */}
          <span className="text-[#3d3d3d] font-inter text-[16px] ml-[0.3rem] font-normal">
            of Track Record
          </span>
        </p>
        <p className="font-inter text-[14px] font-normal leading-[20px] text-[#6A6A6A] mt-[4px] mb-[2px] flex-wrap">
          {/* {fundData.final_data.highlights_section.track_record.middle_val}
           */}
          Inception Date - 12 Mar 2008
        </p>
      </div>
      {/* 2nd */}
      <div className="flex-wrap w-[20%] border-r-[1px] border-[#E0DDDD] mr-[2rem]">
        <p className="font-inter text-[32px] font-medium text-primary-200 tracking-[-0.03em] leading-[39px] m-0">
          35,000
          {/* {fundData.final_data.highlights_section.aum.main_val.toFixed(0)} */}
          <span className="text-[#3d3d3d] font-inter text-[16px] ml-[0.3rem] font-normal">
            Cr+
          </span>
        </p>
        <p className="font-inter text-[14px] font-normal leading-[20px] flex-wrap mb-[2px] mt-[4px] text-[#6A6A76]">
          {/* {fundData.final_data.highlights_section.aum.middle_val} */}AUM
        </p>
      </div>
      {/* 3 */}
      <div className="flex-wrap w-[20%] border-r-[1px] border-[#E0DDDD] mr-[2rem]">
        <p className="font-inter text-[32px] font-medium text-primary-200 tracking-[-0.03em] leading-[39px] m-0">
          {/* {fundData.final_data.highlights_section.performance.main_val} */}
          Moderate
        </p>
        <p className="font-inter text-[14px] font-normal leading-[20px] text-[#6A6A76] mt-[4px] mb-[2px] flex-wrap">
          {/* {fundData.final_data.highlights_section.performance.middle_val} */}{" "}
          Risk
        </p>
      </div>
      {/* 4 */}
      <div className="flex-wrap">
        <p className="font-inter text-[32px] font-medium text-primary-200 tracking-[-0.03em] leading-[39px] m-0">
          {/* {fundData.final_data.highlights_section.investment_strategy.main_val} */}
          Strategy
        </p>
        <p className="font-inter text-[14px] font-normal leading-[20px] text-[#6A6A76] mt-[4px] mb-[2px] flex-wrap">
          {/* {
            fundData.final_data.highlights_section.investment_strategy
              .middle_val
          } */}
          Seeking portfolio stability with Largecap & Alpha generation with
          Midcap picks
        </p>
      </div>
    </div>
  );
}
