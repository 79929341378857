import React, { useState, useEffect } from "react";
import OverviewSection from "./OverviewSection";
import DebtSection from "./DebtSection";
import EquitySection from "./EquitySection";
import { useSelector, useDispatch } from "react-redux";
import NextArrow from "../../../../assets/img/pagination_next.svg";
import Add from "../../../../assets/img/portfolioaddfunds.svg";
import Reload from "../../../../assets/img/portfolioreload.svg";
import AddFundsModal from "./AddFundsModal";
import ReactPaginate from "react-paginate";
import { PortfolioData } from "./portfolioData";
import ActionableTableRow from "./ActionableTableRow";
import {
  GetReviewPortfolioAnalyticsFinal,
  PostEventLoggingData,
} from "../../../../actions/actions";
import { Bars } from "react-loader-spinner";
export default function Actionable({
  portfolioProducts,
  setPortfolioProducts,
  portfolio,
  setPortfolio,
  portfolioActions,
  setPortfolioActions,
  clientId,
}) {
  const dispatch = useDispatch();
  const preferenceData = useSelector(
    (state) => state.portfolioReviewGetActionable
  );
  const rationalizeProducts = (productsArray) => {
    let temp = Array.isArray(productsArray)
      ? productsArray.map((product) => {
          return {
            ...product,
            proposed_weight: (product.proposed_weight * 100).toFixed(1),
          };
        })
      : [];

    return temp;
  };
  const derationalizeProducts = (productsArray) => {
    let temp = Array.isArray(productsArray)
      ? productsArray.map((product) => {
          return {
            ...product,
            proposed_weight: product.proposed_weight / 100,
          };
        })
      : [];

    return temp;
  };
  const handleCross = (id) => {
    delete addedFundsMap[id];
    let index = parsedData.findIndex((x) => x.product_id === id);
    let newParsedData = [...parsedData];
    newParsedData.splice(index, 1);
    setParsedData(newParsedData);
  };
  const [parsedData, setParsedData] = useState(
    rationalizeProducts(
      portfolioProducts.length === 0 ? PortfolioData : PortfolioData
    )
  );
  const [parsedPortfolio, setParsedPortfolio] = useState(
    portfolio.length === 0
      ? preferenceData.final_portfolio["Mutual Fund"]
      : portfolio
  );
  const [parsedActions, setParsedActions] = useState(
    portfolioActions.length === 0 ? preferenceData.actions : portfolioActions
  );
  useEffect(() => {
    if (preferenceData.actions.length !== 0 && portfolioActions.length === 0) {
      let tempArray = rationalizeProducts(
        preferenceData.actions.length !== 0
          ? preferenceData.frontend_table
          : portfolioProducts
      );
      let tempSum = 0;
      tempArray.forEach(
        (value) => (tempSum += parseFloat(value.proposed_weight))
      );
      tempSum = tempSum.toFixed(1);
      tempArray[0].proposed_weight =
        parseFloat(tempArray[0].proposed_weight) +
        (100.0 - parseFloat(tempSum));
      tempArray[0].weight_change += (100.0 - parseFloat(tempSum)) / 100;
      tempArray[0].proposed_weight = tempArray[0].proposed_weight.toFixed(1);
      if ((100.0 - parseFloat(tempSum)) / 100) {
        tempArray[0].rationale_type = "Action due to Multiple Reasons";
        tempArray[0].rationale = "";
      }
      setParsedData(tempArray);
      setParsedPortfolio(
        preferenceData.actions.length !== 0
          ? preferenceData.final_portfolio["Mutual Fund"]
          : portfolio
      );
      setParsedActions(
        preferenceData.actions.length !== 0
          ? preferenceData.actions
          : portfolioActions
      );
      setPortfolioProducts(derationalizeProducts(tempArray));
      setPortfolio(
        preferenceData.actions.length !== 0
          ? preferenceData.final_portfolio["Mutual Fund"]
          : portfolio
      );
      setPortfolioActions(
        preferenceData.actions.length !== 0
          ? preferenceData.actions
          : portfolioActions
      );
    } // eslint-disable-next-line
  }, [preferenceData]);
  const analytics = useSelector(
    (state) => state.portfolioReviewGetFinalAnalytics.compare
  );
  const [section, setSection] = useState("Overview");
  const [portfolioType, setPortfolioType] = useState("Portfolio 1");
  const [afmodal, setAfmodal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  const clientsPerPage = 10;
  const pagesVisited = pageNumber * clientsPerPage;
  var portfolioSum = 0;
  const addedFundsMap = {};
  parsedData.forEach((value) => {
    addedFundsMap[value.product_id] = true;
    portfolioSum += parseFloat(value.weight);
  });
  //   const portfolioValue = formValue.proposal_value.amount;
  const handleWeightChange = (e, id) => {
    // console.log("oldparsedData is", parsedData);
    // console.log("oldparsedActions is", parsedActions);
    // console.log("oldparsedPortfolio is", parsedPortfolio);
    if (
      e.target.value <= 100 &&
      (!e.target.value.split(".")[1] ||
        e.target.value.split(".")[1].length <= 1)
    ) {
      let index = parsedData.findIndex((x) => x.product_id === id);
      let newProducts = [...parsedData];
      newProducts[index].proposed_weight = e.target.value;
      newProducts[index].rationale_type = "Action due to Multiple Reasons";
      newProducts[index].rationale = "";
      if (e.target.value > newProducts[index].current_weight * 100)
        newProducts[index].tag = "Buy";
      else if (e.target.value < newProducts[index].current_weight * 100)
        newProducts[index].tag = "Sell";
      else newProducts[index].tag = "No Action";
      newProducts[index].weight_change =
        (parseFloat(newProducts[index].proposed_weight).toFixed(1) -
          parseFloat(newProducts[index].current_weight * 100).toFixed(1)) /
        100;
      if (
        (parseFloat(newProducts[index].proposed_weight).toFixed(1) ===
          parseFloat(newProducts[index].current_weight * 100).toFixed(1)) /
        100
      )
        newProducts[index].list_type = 0;
      else newProducts[index].list_type = 2;
      setParsedData(newProducts);
      let actionsindex = parsedActions.findIndex((x) => x.product_id === id);
      let newActions = [...parsedActions];
      if (
        actionsindex === -1 &&
        parseFloat(newProducts[index].proposed_weight).toFixed(1) -
          parseFloat(newProducts[index].current_weight * 100).toFixed(1)
      )
        newActions.push({
          product_id: id,
          pct:
            (parseFloat(newProducts[index].proposed_weight).toFixed(1) -
              parseFloat(newProducts[index].current_weight * 100).toFixed(1)) /
            100,
          rationale_type: "Action due to Multiple Reasons",
          rationale: "",
        });
      if (actionsindex !== -1) {
        if (
          parseFloat(newProducts[index].proposed_weight).toFixed(1) -
          parseFloat(newProducts[index].current_weight * 100).toFixed(1)
        ) {
          newActions[actionsindex].pct =
            (parseFloat(newProducts[index].proposed_weight).toFixed(1) -
              parseFloat(newProducts[index].current_weight * 100).toFixed(1)) /
            100;
        } else newActions.splice(actionsindex, 1);
      }
      setParsedActions(newActions);
      let portfolioIndex = parsedPortfolio.findIndex(
        (x) => x.product_id === id
      );
      let newPortfolio = [...parsedPortfolio];
      if (portfolioIndex === -1 && e.target.value)
        newPortfolio.push({
          product_id: id,
          individual_asset_pct: e.target.value / 100,
          market_value: (parseFloat(e.target.value) * portfolioValue) / 100,
        });
      if (portfolioIndex !== -1) {
        if (e.target.value) {
          newPortfolio[portfolioIndex].individual_asset_pct =
            e.target.value / 100;
          newPortfolio[portfolioIndex].market_value =
            (parseFloat(e.target.value) * portfolioValue) / 100;
        } else newPortfolio.splice(portfolioIndex, 1);
      }
      setParsedPortfolio(newPortfolio);
      setPortfolioProducts(derationalizeProducts(newProducts));
      setPortfolioActions(newActions);
      setPortfolio(newPortfolio);
      // console.log("parsedData is", newProducts);
      // console.log("parsedActions is", newActions);
      // console.log("parsedPortfolio is", newPortfolio);
    }
  };
  var portfolioValue = useSelector(
    (state) => state.portfolioReviewGetActionable.portfolio_value
  );
  const displaydata = PortfolioData.slice(
    pagesVisited,
    pagesVisited + clientsPerPage
  ).map((client, ind) => {
    return (
      <ActionableTableRow
        key={ind}
        client={client}
        handleWeightChange={handleWeightChange}
        parsedActions={parsedActions}
        setParsedActions={setParsedActions}
        parsedData={PortfolioData}
        setParsedData={setParsedData}
        setPortfolioActions={setPortfolioActions}
        setPortfolioProducts={setPortfolioProducts}
        derationalizeProducts={derationalizeProducts}
        handleCross={handleCross}
        portfolioValue={portfolioValue}
      />
    );
  });
  const pageCount = Math.ceil(parsedData.length / clientsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleSections = () => {
    switch (section) {
      case "Overview":
        return <OverviewSection analytics={analytics} />;

      case "Debt":
        return <DebtSection analytics={analytics} />;

      case "Equity":
        return <EquitySection analytics={analytics} />;

      default:
        console.log(section);
    }
  };
  useEffect(() => {
    dispatch(
      PostEventLoggingData({
        module_name: "Portfolio Review",
        event_type: "Information",
        url: window.location.href,
        note: `Checked fourth screen ${section} Analytics`,
      })
    ); // eslint-disable-next-line
  }, [section]);
  return (
    <div className="w-[100%] flex">
      <div className="px-[16px] flex justify-between w-full border   rounded-[8px] bg-white py-[16px] my-[4px] mb-8">
        {preferenceData.loading || analytics.loading ? (
          <div className="client-dashboard-table w-full h-[335px] flex justify-center items-center opacity-[0.9]">
            <Bars color="#1982F8" />
          </div>
        ) : (
          <>
            <div className="w-[52.5%]">
              <div className=" w-full flex justify-between border-b border-[#e0dddd]">
                <div className="flex">
                  <h3 className=" font-inter text-[24px] font-medium leading-[20px] tracking-[0em] text-left pb-[0.3rem]">
                    Portfolio
                  </h3>
                  <p
                    className={
                      portfolioType === "Portfolio 1"
                        ? "border-b-[2px] border-[#1982f8] ml-[6rem] text-[#3d3d3d] font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-left my-0 mx-[1rem] cursor-pointer"
                        : "ml-[6rem] text-[#6a6a76] font-normal font-inter text-[12px] leading-[25px] tracking-[0em] text-left  my-0 mx-[1rem] cursor-pointer"
                    }
                    onClick={() => setPortfolioType("Portfolio 1")}
                  >
                    Portfolio 1
                  </p>
                  <p
                    className={
                      portfolioType === "Portfolio 2"
                        ? "border-b-[2px] border-[#1982f8] ml-[2rem] text-[#3d3d3d] font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-left my-0 mx-[1rem] cursor-pointer"
                        : "ml-[2rem] text-[#6a6a76] font-normal font-inter text-[12px] leading-[25px] tracking-[0em] text-left  my-0 mx-[1rem] cursor-pointer"
                    }
                    onClick={() => setPortfolioType("Portfolio 2")}
                  >
                    Portfolio 2
                  </p>
                </div>
              </div>
              <>
                <div className=" w-full mt-[1rem] bg-[#fafafa] p-[1rem] flex rounded-[6px]">
                  <div className="w-[20%] text-center border-r border-r-dividerColor">
                    <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0">
                      {`${(
                        analytics.asset_allocation_after.Equity * 100
                      ).toFixed(1)}%`}
                    </h3>
                    <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                      Equity
                    </label>
                  </div>
                  <div className="w-[20%] text-center border-r border-r-dividerColor">
                    <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0">
                      {`${(analytics.asset_allocation_after.Debt * 100).toFixed(
                        1
                      )}%`}
                    </h3>
                    <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                      Debt
                    </label>
                  </div>
                  <div className="w-[20%] text-center border-r border-r-dividerColor">
                    <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0">
                      {`${(
                        analytics.asset_allocation_after.Alternate * 100
                      ).toFixed(1)}%`}
                    </h3>
                    <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                      Alternates
                    </label>
                  </div>
                  <div className="w-[20%] text-center border-r border-r-dividerColor">
                    <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#1982f8] mb-0">
                      {`₹ ${(portfolioValue / 100000).toFixed(1)} L`}
                    </h3>
                    <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                      Value
                    </label>
                  </div>
                  <div className="w-[20%] text-center">
                    <h3
                      className="font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0"
                      style={
                        Math.round(portfolioSum * 10) / 10 !== 100
                          ? { color: "red" }
                          : { color: "#00B031" }
                      }
                    >
                      {portfolioSum.toFixed(1)}%
                    </h3>
                    <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                      Total
                    </label>
                  </div>
                </div>
                <div className="mt-[1rem]  rounded-[6px]   min-h-[680px]">
                  <table className="w-[100%] h-full investedge-table">
                    <colgroup>
                      <col width="40%" />
                      <col width="20%" />
                      <col width="16%" />
                      <col width="16%" />
                      <col width="8%" />
                    </colgroup>
                    <tbody>
                      <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                        <th className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] text-left py-[0.25rem] pr-0 pl-[1rem]">
                          Name
                        </th>
                        <th className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] text-center py-[0.25rem] px-0">
                          Asset Class/ <br />
                          Product Category
                        </th>
                        <th className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] text-center py-[0.25rem] px-0">
                          Amount
                          <br /> (in ₹L)
                        </th>
                        <th className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] text-center py-[0.25rem] px-0">
                          Weight
                          <br /> (% of Total)
                        </th>
                        <th className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] text-center py-[0.25rem] px-0"></th>
                      </tr>
                      {displaydata}
                    </tbody>
                  </table>
                </div>
                <div className="flex w-full justify-between pt-0 pr-[1rem] pb-[1rem] pl-[1rem]">
                  <button
                    className=" primary-button items-center text-sm flex justify-center w-[180px] h-[37px] mt-[1rem] font-semibold py-2 px-3 rounded  shadow-boxShadowButton normal-case active:opacity-[0.8] focus:outline-none"
                    onClick={() => {
                      dispatch(
                        PostEventLoggingData({
                          module_name: "Portfolio Review",
                          event_type: "Add button",
                          url: window.location.href,
                          note: `Add funds clicked`,
                        })
                      );
                      setAfmodal(true);
                    }}
                  >
                    <img
                      className="w-[14px] object-contain  mr-[0.25rem]"
                      src={Add}
                      alt="err"
                    />
                    Add Products
                  </button>
                  <AddFundsModal
                    onClose={() => setAfmodal(false)}
                    afmodal={afmodal}
                    parsedData={parsedData}
                    setParsedData={setParsedData}
                    parsedPortfolio={parsedPortfolio}
                    setParsedPortfolio={setParsedPortfolio}
                    parsedActions={parsedActions}
                    setParsedActions={setParsedActions}
                    derationalizeProducts={derationalizeProducts}
                    setPortfolioProducts={setPortfolioProducts}
                    setPortfolioActions={setPortfolioActions}
                    addedFundsMap={addedFundsMap}
                  />
                  <ReactPaginate
                    /*previousLabel={<img src={PreviousArrow} alt="err" />}*/
                    nextLabel={
                      <img
                        src={NextArrow}
                        alt="err"
                        style={{ width: "11px", height: "11px" }}
                      />
                    }
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"dashboard-pagination-buttons"}
                    previousLinkClassName={"dashboard-pagination-previous"}
                    nextLinkClassName={"dashboard-pagination-next"}
                    disabledClassName={"dashboard-pagination-disabled"}
                    activeClassName={"dashboard-pagination-active"}
                  />
                </div>
              </>
            </div>
            <div className="w-[45%]">
              <div className="w-[100%] flex justify-between border-b border-[#e0dddd]">
                <div className="flex">
                  <h3 className=" font-inter text-[24px] font-medium leading-[20px] tracking-[0em] text-left pb-[0.3rem]">
                    Analytics
                  </h3>
                  <p
                    className={
                      section === "Overview"
                        ? "border-b-[2px] border-primary-200 ml-[96px] text-[#3d3d3d] font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-left  my-0 mr-[1rem] cursor-pointer"
                        : "ml-[96px] font-inter text-[12px] leading-[25px] tracking-[0em] text-[#6a6a76] text-left mr-[1rem] cursor-pointer"
                    }
                    onClick={() => setSection("Overview")}
                  >
                    Overview
                  </p>
                  <p
                    className={
                      section === "Equity"
                        ? "border-b-[2px] border-primary-200 ml-[2rem] text-[#3d3d3d] font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-left  my-0 mr-[1rem] cursor-pointer"
                        : "ml-[2rem] font-inter text-[12px] leading-[25px] tracking-[0em] text-[#6a6a76] text-left  my-0 mr-[1rem] cursor-pointer"
                    }
                    onClick={() => setSection("Equity")}
                  >
                    Equity
                  </p>
                  <p
                    className={
                      section === "Debt"
                        ? "border-b-[2px] border-primary-200 ml-[2rem] text-[#3d3d3d] font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-left  my-0 mr-[1rem] cursor-pointer"
                        : "ml-[2rem] font-inter text-[12px] leading-[25px] tracking-[0em] text-[#6a6a76] text-left  my-0 mr-[1rem] cursor-pointer"
                    }
                    onClick={() => setSection("Debt")}
                  >
                    Debt
                  </p>
                </div>
                <div
                  className="flex cursor-pointer items-baseline mt-[0.5rem]"
                  onClick={() => {
                    dispatch(
                      GetReviewPortfolioAnalyticsFinal({
                        client_id: clientId,
                        final_portfolio: {
                          "Mutual Fund": parsedPortfolio,
                        },
                      })
                    );
                    dispatch(
                      PostEventLoggingData({
                        module_name: "Portfolio Review",
                        event_type: "Refresh",
                        url: window.location.href,
                        note: `Refresh button pressed`,
                      })
                    );
                  }}
                >
                  <img src={Reload} alt="err" className="w-[10px]" />
                  <p className=" font-inter text-[12px] font-medium tracking-[0em] text-left text-primary-200 mb-0 align-bottom ml-[0.2rem]">
                    Simulate
                  </p>
                </div>
              </div>
              {handleSections(section)}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
