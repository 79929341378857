import React from "react";
import { SettingsSidebarData } from "./SettingsSidebarData";
import "../static/css/existing/dashboard_styles.css";
import { Link, useLocation } from "react-router-dom";
export default function SettingsSidebar() {
  const location = useLocation();
  return (
    <nav className="clientsidenavbar">
      <div className="bg-white h-full rounded-[0.5rem] p-4">
        {SettingsSidebarData.map((item, index) => {
          return (
            <Link to={item.path} key={index}>
              <div
                className={
                  location.pathname === item.path
                    ? "active-settings-sidebar-link"
                    : "settings-sidebar-link"
                }
              >
                {item.title}
              </div>
            </Link>
          );
        })}
      </div>
    </nav>
  );
}
