import React, { useState } from "react";
import InvestedgeModal from "../../components/modal/InvestedgeModal";
import { IoIosClose } from "react-icons/io";
import DropdownForAttributes from "../../components/Dropdowns/DropdownForAttributes";
import InvestedgeTextField from "../../components/textfield/InvestedgeTextField";
import { InputAdornment, OutlinedInput } from "@mui/material";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import InvestedgeButtonHollow from "../../components/buttons/InvestedgeButtonHollow";
import urls from "../../urls";
import axios from "axios";
export default function InflationModal({
  modal,
  setModal,
  goalDetails,
  setGoalDetails,
}) {
  const numToMonthMap = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };

  const monthDiff = (d1, d2) => {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  };
  const denotionParser = (denotion, value) => {
    if (denotion[2] === "K") return parseInt(value) * 1000;
    else if (denotion[2] === "L") return parseInt(value) * 100000;
    else return value * 10000000;
  };
  const [backendData, setBackendData] = useState({ inflation_adjusted: 0 });
  const [modalData, setModaldata] = useState({
    amount: "",
    amountDenomination: "₹ L",
    inflation: "",
  });
  const handleCalculate = () => {
    axios
      .post(urls.goalsInflationAdjustedURL, {
        current_value_of_amount_required: denotionParser(
          modalData.amountDenomination,
          modalData.amount
        ),
        inflation: parseInt(modalData.inflation) / 100,
        time_period: monthDiff(new Date(), goalDetails.goalEnd),
      })
      .then((response) => setBackendData(response.data))
      .catch(() => setBackendData({ inflation_adjusted: 0 }));
  };
  return (
    <InvestedgeModal
      aria-labelledby="Change Password Modal"
      aria-describedby="Change Password Modal"
      sx={{
        height: "55vh",
        minWidth: "60vw",
        margin: "auto",
        padding: "1.5rem",
      }}
      open={modal}
      handleclose={() => setModal(false)}
    >
      <div className="flex justify-between items-start">
        <h3 className="card-heading">
          Calculate your inflation adjusted Goal Value here
        </h3>
        <IoIosClose size={30} onClick={() => setModal(false)} />
      </div>
      <div className="grid grid-cols-12 gap-10 mt-6">
        <div className="col-span-7">
          <div className="w-full flex justify-between items-center">
            <p className="font-inter text-sm">
              Current Value of Amount Required
            </p>
            <div className="w-[40%] flex justify-between">
              <div className="w-[38%]">
                <DropdownForAttributes
                  labelName={null}
                  options={["₹ L", "₹ K", "₹ C"]}
                  selected={modalData}
                  setSelected={setModaldata}
                  attribute="amountDenomination"
                />
              </div>
              <div className="w-[60%]">
                <InvestedgeTextField
                  variant="outlined"
                  fullWidth
                  style={{
                    fontFamily: "Inter,sans-sarif",
                    fontWeight: 400,
                  }}
                  label={null}
                  value={modalData.amount}
                  onChange={(e) => {
                    const re = /^\d*[0-9]+\d*$/;
                    if (e.target.value === "" || re.test(e.target.value))
                      setModaldata({
                        ...modalData,
                        amount: e.target.value,
                      });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full flex justify-between items-center mt-8">
            <div className="flex items-center w-[70%]">
              <p className="font-inter text-sm">Inflation</p>
            </div>
            <OutlinedInput
              size="small"
              className="text-sm"
              required
              style={{
                fontFamily: "Inter,sans-sarif",
                fontWeight: 400,
                width: "30%",
              }}
              value={modalData.inflation}
              onChange={(e) => {
                const re = /^\d*\.?\d*$/;
                if (e.target.value === "" || re.test(e.target.value))
                  setModaldata({
                    ...modalData,
                    inflation: e.target.value,
                  });
              }}
              name="target"
              endAdornment={
                <InputAdornment position="end">
                  <span className="text-sm font-inter">%</span>
                </InputAdornment>
              }
            />
          </div>
          <InvestedgeButton className="mt-4" onClick={handleCalculate}>
            Calculate
          </InvestedgeButton>
        </div>
        <div className="col-span-5">
          <p className="font-inter text-sm text-[#6a6a76] mb-4">
            Inflation Adjusted Value of Amount Required
          </p>
          <p className="font-inter font-semibold text-[20px] text-primary-200 mb-4">
            {`₹ ${parseInt(backendData.inflation_adjusted).toLocaleString(
              "en-IN"
            )}`}
          </p>
          <p className="font-inter font-medium text-[#3D3D3D] mb-[4px]">
            {`in ${
              numToMonthMap[goalDetails.goalEnd.getMonth() + 1]
            } ${goalDetails.goalEnd.getFullYear()}`}
          </p>
          <InvestedgeButtonHollow
            className="mt-4"
            onClick={() => {
              let value = backendData.inflation_adjusted,
                denotion;
              if (value / 1000 < 100) {
                value = parseFloat(value / 1000).toFixed(1);
                denotion = "K";
              } else if (value / 100000 < 100) {
                value = parseFloat(value / 100000).toFixed(1);
                denotion = "L";
              } else {
                value = parseFloat(value / 10000000).toFixed(1);
                denotion = "C";
              }
              setGoalDetails({
                ...goalDetails,
                moneyRequired: value,
                moneyRequiredDenotion: `₹ ${denotion}`,
              });
              setModal(false);
            }}
          >
            Use this value
          </InvestedgeButtonHollow>
        </div>
      </div>
    </InvestedgeModal>
  );
}
