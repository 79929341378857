import { GET_REVIEW_PORTFOLIO_ANALYTICS_FINAL } from "../actions/type";
const initialState = {
  compare: {
    asset_allocation_before: {
      Equity: 0.909,
      Debt: 0.091,
      Alternate: 0,
    },
    asset_allocation_after: {
      Equity: 0.61,
      Debt: 0.351,
      Alternate: 0.039,
    },
    overall_product_allocation_before: {
      "Mutual Fund": 1.0,
    },
    overall_product_allocation_after: {
      "Mutual Fund": 1.0,
    },
    overall_prod_count_before: 25,
    overall_prod_count_after: 7,
    overall_liquidity_before: {
      Liquid: 1.0,
      "Semi-Liquid": 0,
      Illiquid: 0,
    },
    overall_liquidity_after: {
      Liquid: 1.0000000000000002,
      "Semi-Liquid": 0,
      Illiquid: 0,
    },
    ovarall_cagr_before: 0.1390016654470913,
    ovarall_cagr_after: 0.08255212314132315,
    bm_blended_returns_before: 0.116184968,
    bm_blended_returns_after: 0.10297305500000001,
    mkt_cap_allocation_before: {
      "Large Cap": [0.7305818365075817, 0.7768197345124772],
      "Mid Cap": [0.1778322451625022, 0.14793277839108115],
      "Small Cap": [0.09158591832991604, 0.07524748709644165],
    },
    mkt_cap_allocation_after: {
      "Large Cap": [0.9712333389973878, 0.7768197345124772],
      "Mid Cap": [0.025534987673557018, 0.14793277839108115],
      "Small Cap": [0.00323167332905513, 0.07524748709644165],
    },
    rating_allocation_before: [
      ["AA", "AAA", "Cash", "SOV"],
      [
        0.013934047867509902, 0.6384440267601342, 0.19264196172856718,
        0.1549799636437887,
      ],
    ],
    rating_allocation_after: [
      ["AA", "AAA", "Cash", "SOV"],
      [
        0.21347847713637116, 0.5853647802475607, 0.02480210919764926,
        0.17635463341841906,
      ],
    ],
    top5_sector_before: {
      Financials: 0.32138986068386044,
      "Information Technology": 0.1550274468370718,
      "Consumer Discretionary": 0.14025637779459202,
      Materials: 0.09678051314362708,
      "Consumer Staples": 0.06848736965507304,
    },
    top5_sector_after: {
      Financials: 0.34161952859366956,
      "Information Technology": 0.20043832667193992,
      "Consumer Discretionary": 0.13387583220992835,
      Energy: 0.100440114019218,
      Materials: 0.06872488125649819,
    },
    top5_stocks_before: {
      "ICICI Bank Ltd.": 0.05536056917662413,
      "HDFC Bank Ltd.": 0.04859720049789165,
      "Infosys Ltd.": 0.04070172851954362,
      "ITC Ltd.": 0.034309833173017286,
      "Housing Development Finance Corporation Ltd.": 0.0337739184929343,
    },
    top5_stocks_after: {
      "Reliance Industries Ltd.": 0.0905639789529026,
      "ICICI Bank Ltd.": 0.08828254733492984,
      "HDFC Bank Ltd.": 0.07865652230292684,
      "Infosys Ltd.": 0.07724151696047907,
      "Tata Consultancy Services Ltd.": 0.05301606324671261,
    },
    debt_quants_before: {
      duration: 0.1711075995890459,
      gross_ytm: 0.04818438487065824,
      maturity: 0.25480454860471874,
    },
    debt_quants_after: {
      duration: 0.68,
      gross_ytm: 0.0657,
      maturity: 1.2,
    },
    debt_top_5_sec_before: {
      "91 Days Treasury Bill - 09-Jun-2022": 0.06618537219280797,
      "91 Days Treasury Bill - 23-Jul-2022": 0.05553111030457004,
      "HDFC Bank Ltd. (29-Jun-22)": 0.0471505261862897,
      "Reliance Retail Ventures Ltd. -91D (10-Jun-22)": 0.04448189030605777,
      "Tata Steel Ltd. -91D (28-Jun-22)": 0.0443751448708485,
    },
    debt_top_5_sec_after: {
      "08.92% Madhya Pradesh SDL - 8-Aug-2022": 0.036274979016550024,
      "GOI FRB 22-Sep-2033": 0.03546433633370193,
      "06.84% GOI - 19-Dec-2022": 0.03142342091212162,
      "Axis Bank Ltd. (11-Nov-22)": 0.029179037251644602,
      "Shriram Transport Finance Company Ltd. SR-K 03 OP 2 (16-Jun-2023)": 0.02755262772234002,
    },
  },
};
export default function GetReviewPortfolioAnalyticsFinal(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_REVIEW_PORTFOLIO_ANALYTICS_FINAL:
      return action.payload;
    default:
      return state;
  }
}
