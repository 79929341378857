import React, { useState } from "react";
import { trailingReturns } from "../onePagerConsts";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "../../../static/css/existing/dashboard_styles.css";

export default function TrailingReturnsComponent() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1982F8",
      },
      typography: {
        fontSize: 14,
      },
    },
  });

  const [clientToggle, setClientToggle] = useState("Fund");
  const handleChangedToogle = (e, newAlignment) => {
    if (newAlignment) setClientToggle(newAlignment);
    // console.log("new", clientToggle);
  };

  return (
    <>
      <div
        style={{
          marginTop: "1.5rem",
          padding: "1rem",
          backgroundColor: "#fff",
          borderRadius: "6px",
          boxShadow:
            " 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(0, 0, 0, 0.05)",
          minheight: "274px",
          marginBottom: "1.5rem",
        }}
      >
        <h5 className="onepager-small-heading mb-3">Trailing Returns (%)</h5>
        <div style={{ display: "flex", marginLeft: "1rem" }}>
          <nav>
            <div className="tab-content">
              <div
                style={{
                  flexDirection: "column",
                }}
              >
                <div className="dashboard-body-second-card-navigator mb-3">
                  <ThemeProvider theme={theme}>
                    <ToggleButtonGroup
                      color="primary"
                      value={clientToggle}
                      onChange={handleChangedToogle}
                      exclusive
                    >
                      <ToggleButton
                        value="Fund"
                        style={{
                          textTransform: "none",
                          padding: "1rem",
                          height: "37px",
                        }}
                      >
                        Fund
                      </ToggleButton>

                      <ToggleButton
                        value="Peers"
                        style={{
                          textTransform: "none",
                          padding: "1rem",
                          height: "37px",
                        }}
                      >
                        Peers
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </ThemeProvider>
                </div>
              </div>
              <div
                className="tab-content card"
                style={{
                  // border: "1px solid blue",
                  border: "1px solid rgba(0,0,0,.125)",
                  borderRadius: "6px",
                  boxShadow:
                    "2px 2px 6px rgb(0 0 0 / 5%), -2px -2px 6px rgb(0 0 0 / 5%)",
                  minWidth: "1040px",
                }}
              >
                {clientToggle === "Fund" ? (
                  <>
                    <div
                      role="tabpanel"
                      className="tab-pane fade show active"
                      id="views"
                    >
                      <table className="table table-hover">
                        <tr
                          style={{
                            height: "40px",
                            borderRadius: "10px",
                            borderBottom: "1px solid rgb(50, 50, 50)",
                            fontFamily: "Inter, sans-serif",
                            fontWeight: "700",
                          }}
                        >
                          {/* #8 Contains Trailing Return table headers */}
                          <th
                            className="product-reckoner-heading"
                            style={{ textAlign: "left" }}
                          >
                            Fund Name
                          </th>
                          {trailingReturns.fund.tableHeaders.map((time) => (
                            <th className="product-reckoner-heading ">
                              {time}
                            </th>
                          ))}
                        </tr>

                        {/* Map through #8 trailingReturns -> fund -> tableData -> value list */}
                        {Object.entries(trailingReturns.fund.tableData).map(
                          ([key, value]) => (
                            <tr className="border-bottom">
                              <td
                                style={{
                                  fontFamily: "Inter, sans-serif",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "16px",
                                  letterSpacing: "0em",
                                  padding: "1rem",
                                }}
                              >
                                {value["title"]}
                              </td>
                              {value["value"].map((data) => (
                                <td className="dashboard-body-second-card-table-data">
                                  {data}
                                </td>
                              ))}
                            </tr>
                          )
                        )}
                      </table>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      role="tabpanel"
                      className="tab-pane fade show"
                      id="collaterals"
                    >
                      <table className="table table-hover">
                        <thead>
                          {/* Map through #8 trailingReturns -> peers -> tableHeaders list */}
                          <tr
                            style={{
                              height: "40px",
                              borderRadius: "10px",
                              borderBottom: "1px solid rgb(50, 50, 50)",
                              fontFamily: "Inter, sans-serif",
                              fontWeight: "700",
                            }}
                          >
                            {Object.entries(
                              trailingReturns.peers.tableHeaders
                            ).map(([key, value]) => (
                              <th
                                className="product-reckoner-heading"
                                style={{ textAlign: "left" }}
                              >
                                <b
                                  style={{
                                    fontFamily: "Inter, sans-serif",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "16px",
                                    letterSpacing: "0em",
                                    padding: "1rem",
                                  }}
                                >
                                  {value}
                                </b>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {/* Map through #8 trailingReturns -> peers -> tableData list */}
                          <tr>
                            {Object.entries(
                              trailingReturns.peers.tableData
                            ).map(([key, value]) => (
                              <td
                                style={{
                                  fontFamily: "Inter, sans-serif",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "16px",
                                  letterSpacing: "0em",
                                  padding: "1rem",
                                }}
                              >
                                {value}
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </div>
              <p
                className="onepager-description-text "
                style={{ color: "#323232", paddingTop: "1rem" }}
              >
                As on 11-02-2022. Category averages and rank are for
                Regular(Growth) schemes
              </p>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
