import React, { useRef, useState, useEffect } from "react";
import Menu from "../../assets/img/three_dots_menu.svg";
import DefaultProfilePic from "../../assets/img/defaultprofilepic.png";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  GetClients,
  PostClientInputProfilePicture,
  PostEventLoggingData,
} from "../../actions/actions";
import InvestedgePopper from "../../components/popper/InvestedgePopper";
import urls from "../../urls";
import axios from "axios";
import { MenuItem } from "@mui/material";
export default function ClientCard({
  client,
  clientData,
  setClientData,
  clienttype,
  selected,
  searchClient,
  clientsPerPage,
}) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const popperRef = useRef(null);
  const profilepicRef = useRef(null);
  const [fileupload, setFileupload] = useState(false);
  const statusUpload = useSelector((state) => state.postClientProfilePicture);
  useEffect(() => {
    fileupload &&
      dispatch(
        GetClients({
          params: {
            search: searchClient,
            is_prospect: clienttype === 1,
            rmid: selected?.id,
            page: 1,
            size: clientsPerPage,
          },
        })
      );
    // eslint-disable-next-line
  }, [statusUpload]);

  return (
    <div
      className="rounded-[4px] bg-gradient-180 from-[#fafafa] to-[#f6f6f6] p-[1rem] min-h-[155px] z-0  w-full shadow-none m-0"
      onMouseLeave={() => setAnchorEl(null)}
    >
      <div className="flex justify-end items-start">
        <div className="flex justify-center items-center w-[97%] flex-col">
          <img
            className="h-[60px] w-[60px] rounded-[100%]"
            src={
              client.client_info.profile_pic
                ? client.client_info.profile_pic
                : DefaultProfilePic
            }
            alt="err"
          />
          <p className="font-inter w-full text-[16px] not-italic leading-[19px] mb-0 tracking-[0em] text-center text-neutralBlack mt-[1rem]">
            {client.client_info.username}
          </p>
          <p className="font-inter text-[12px] not-italic  leading-[12px] tracking-[0.015em] text-center mb-0 text-primary-200 mt-[0.5rem] bg-[#EDF5FE] py-[0.25rem] px-[0.5rem] rounded-[2px]">
            {client.accounttype === "F" ? "Family" : "Individual"}
          </p>
        </div>

        <img
          src={Menu}
          alt="err"
          className="cursor-pointer"
          onClick={() =>
            setAnchorEl((prevAnchor) => (prevAnchor ? null : popperRef.current))
          }
          ref={popperRef}
          aria-describedby="existing-client-popper"
        />
        <InvestedgePopper
          id="existing-client-popper"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="bottom-end"
          className="z-[100]"
          offset={[0, 20]}
          arrow="true"
        >
          <Link
            to={{
              pathname: "/clientinput/updateportfolios",
              state: {
                familyMembers: [{ name: client.client_info.username }],
                clientId: client.client_id,
              },
            }}
            onClick={() =>
              dispatch(
                PostEventLoggingData({
                  module_name: "Client Details",
                  event_type: "Edit button",
                  url: window.location.href,
                  note: `Edit client ${client.client_info.username}`,
                })
              )
            }
          >
            <MenuItem onClick={() => setAnchorEl(null)}>Edit</MenuItem>
          </Link>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              profilepicRef.current.click();
            }}
          >
            Edit Profile Picture
          </MenuItem>
          {client.is_prospect && (
            <MenuItem
              onClick={() => {
                axios
                  .post(urls.prospectToClientURL, {
                    client_id: client.client_id,
                  })
                  .then(() => {
                    setAnchorEl(null);
                    dispatch(
                      GetClients({
                        params: {
                          search: searchClient,
                          is_prospect: clienttype === 1,
                          rmid: selected?.id,
                          page: 1,
                          size: clientsPerPage,
                        },
                      })
                    );
                  })
                  .catch((err) => console.log(err));
              }}
            >
              Convert to Client
            </MenuItem>
          )}
        </InvestedgePopper>
      </div>

      <input
        ref={profilepicRef}
        className="hidden"
        onInput={() => {
          dispatch(
            PostClientInputProfilePicture({
              client_id: client.client_id,
              file: profilepicRef.current.files[0],
            })
          );
          setFileupload(true);
        }}
        type="file"
        accept="image/png,image/jpg,image/jpeg"
      />
    </div>
  );
}
