import React from "react";
import { REACT_APP_development } from "../../env";
import LinksSection from "./LinksSection";
import Subscribe from "./Subscribe";
export default function Footer() {
  return (
    <>
      {REACT_APP_development !== "production" ? <Subscribe /> : null}
      <LinksSection />
    </>
  );
}
