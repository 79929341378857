import React from "react";
import { useState } from "react";
// import { ThemeProvider } from "@material-ui/styles";
// import { Switch, FormControlLabel } from "@mui/material";
// import { createTheme } from "@mui/material/styles";
import ArrowLeft from "../../assets/img/left_arrow_icon.svg";
import ArrowDown from "../../assets/img/down_arrow_icon.svg";
import InvestedgeTextField from "../../components/textfield/InvestedgeTextField";
import InvestedgeDatepicker from "../../components/datepicker/InvestedgeDatepicker";
import dayjs from "dayjs";
export default function Stocksrow({
  fund,
  stocksarray,
  setStocksArray,
  patchStocksArray,
  setPatchStocksArray,
  deleteArray,
  setDeleteArray,
  data,
  postStocksArray,
  setPostStocksArray,
  addedProduct,
}) {
  // const BirrSwitch = createTheme({
  //   overrides: {
  //     MuiSwitch: {
  //       root: {
  //         width: 70,
  //         height: 24,
  //         padding: 0,
  //         marginRight: 10,
  //       },
  //       palette: {
  //         primary: "#0000f5",
  //         secondary: "#0000f5",
  //       },
  //       switchBase: {
  //         padding: 0,
  //         color: "#0000f5",
  //         "&$checked": {
  //           transform: "translateX(45px)",
  //           color: "#0000f5",
  //           "& + $track": {
  //             backgroundColor: "#0000f5",
  //             opacity: 0.4,
  //             border: "none",
  //           },
  //         },
  //         "&$focusVisible $thumb": {
  //           color: "#0000f5",
  //           border: "6px solid #fff",
  //         },
  //       },
  //       thumb: {
  //         width: 24,
  //         height: 24,
  //       },
  //       track: {
  //         borderRadius: 30 / 2,
  //         border: `1px solid #c4c4c4`,
  //         opacity: 0.2,
  //       },
  //       checked: {},
  //     },
  //   },
  // });
  const [open, setOpen] = useState(fund.id === addedProduct ? true : false);

  // const [checked, setChecked] = useState(false);

  // let handleChange = (i, e) => {
  //   let newMfarray = [...stocksarray];
  //   newMfarray[i][e.target.name] = e.target.value;
  //   setStocksArray(newMfarray);
  // };

  const return_date = (val) => {
    try {
      let temp = dayjs(new Date(val).toISOString());
      return temp;
    } catch (err) {
      return val;
    }
  };
  return (
    <>
      <tr className="update-portfolio-asset-row">
        <td>{fund.name}</td>
        <td>
          <div className="update-portfolio-asset-row-second-half">
            <table>
              <tbody>
                <tr>
                  <td>{`₹ ${parseInt(
                    (fund.count_units * fund.current_price).toFixed(0)
                  ).toLocaleString("en-IN")}`}</td>
                  <td
                    className="mr-[1rem]"
                    onClick={() => {
                      let newMfArray = [...stocksarray];
                      let index = stocksarray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      newMfArray.splice(index, 1);
                      setStocksArray(newMfArray);
                      //editing in patch mf array
                      if (data.some((getFund) => getFund.id === fund.id)) {
                        let newPatchMfArray = [...patchStocksArray];
                        let patchindex = newPatchMfArray.findIndex(
                          (pid) => pid.id === fund.id
                        );
                        if (patchindex !== -1)
                          newPatchMfArray.splice(patchindex, 1);
                        setPatchStocksArray(newPatchMfArray);
                        setDeleteArray([...deleteArray, fund]);
                      } //editing in post mf array
                      else {
                        let newPostMfArray = [...postStocksArray];
                        let postindex = newPostMfArray.findIndex(
                          (pid) => pid.id === fund.id
                        );
                        newPostMfArray.splice(postindex, 1);
                        setPostStocksArray(newPostMfArray);
                      }
                    }}
                  >
                    Delete
                  </td>
                  <td
                    className="w-[20px] py-0 pr-0 pl-[0.3rem]"
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    {open || fund.name === "New Mutual Fund" ? (
                      <img className="block" src={ArrowDown} alt="err" />
                    ) : (
                      <img className="block" src={ArrowLeft} alt="err" />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </td>
      </tr>
      {open || fund.name === "New Mutual Fund" ? (
        <>
          <tr className="flex w-full mt-[16px]">
            <td className="w-[31%]">
              <InvestedgeTextField
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Name"
                type="text"
                defaultValue={fund.name}
                disabled
              />
            </td>
            <td className="w-[23%]">
              <InvestedgeTextField
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Qty"
                type="text"
                value={fund.count_units}
                onChange={(e) => {
                  const re = /^\d*\.?\d*$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    let newMfArray = [...stocksarray];
                    let index = stocksarray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newMfArray[index].count_units = e.target.value;
                    setStocksArray(newMfArray);
                    //editing in patch mf array
                    if (data.some((getFund) => getFund.id === fund.id)) {
                      let newPatchMfArray = [...patchStocksArray];
                      let patchindex = newPatchMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      if (patchindex === -1) {
                        newPatchMfArray.push({
                          ...fund,
                          purchase_date: new Date(fund.purchase_date),
                        });
                        newPatchMfArray[
                          newPatchMfArray.length - 1
                        ].count_units = e.target.value;
                      } else
                        newPatchMfArray[patchindex].count_units =
                          e.target.value;
                      setPatchStocksArray(newPatchMfArray);
                    } //editing in post mf array
                    else {
                      let newPostMfArray = [...postStocksArray];
                      let postindex = newPostMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      newPostMfArray[postindex].count_units = e.target.value;
                      setPostStocksArray(newPostMfArray);
                    }
                  }
                }}
              />
            </td>
            <td className="w-[23%]">
              <InvestedgeTextField
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Purchase Price(in ₹)"
                type="text"
                value={fund.purchase_price}
                onChange={(e) => {
                  const re = /^\d*\.?\d*$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    let newMfArray = [...stocksarray];
                    let index = stocksarray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newMfArray[index].purchase_price = e.target.value;
                    setStocksArray(newMfArray);
                    //editing in patch mf array
                    if (data.some((getFund) => getFund.id === fund.id)) {
                      let newPatchMfArray = [...patchStocksArray];
                      let patchindex = newPatchMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      if (patchindex === -1) {
                        newPatchMfArray.push({
                          ...fund,
                          purchase_date: new Date(fund.purchase_date),
                        });
                        newPatchMfArray[
                          newPatchMfArray.length - 1
                        ].purchase_price = e.target.value;
                      } else
                        newPatchMfArray[patchindex].purchase_price =
                          e.target.value;
                      setPatchStocksArray(newPatchMfArray);
                    } //editing in post mf array
                    else {
                      let newPostMfArray = [...postStocksArray];
                      let postindex = newPostMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      newPostMfArray[postindex].purchase_price = e.target.value;
                      setPostStocksArray(newPostMfArray);
                    }
                  }
                }}
              />
            </td>
            <td className="w-[23%]">
              <InvestedgeTextField
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Cost(in ₹)"
                type="text"
                value={(fund.count_units * fund.purchase_price).toLocaleString(
                  "en-IN"
                )}
                disabled
              />
            </td>
          </tr>
          <tr className="flex w-full">
            <td className="w-full p-0">
              <div className="w-full">
                <table className="w-full">
                  <tbody className="w-full">
                    <tr className="flex w-full">
                      <td className="w-[31%]">
                        <InvestedgeDatepicker
                          format="DD/MM/YYYY"
                          label="Purchase Date"
                          className="w-full"
                          maxDate={dayjs(new Date().toISOString())}
                          value={return_date(fund.purchase_date)}
                          onChange={(date) => {
                            date = new Date(date);
                            let newMfArray = [...stocksarray];
                            let index = stocksarray.findIndex(
                              (pid) => pid.id === fund.id
                            );
                            newMfArray[index].purchase_date = date;
                            setStocksArray(newMfArray);
                            //editing in patch mf array
                            if (
                              data.some((getFund) => getFund.id === fund.id)
                            ) {
                              let newPatchMfArray = [...patchStocksArray];
                              let patchindex = newPatchMfArray.findIndex(
                                (pid) => pid.id === fund.id
                              );
                              if (patchindex === -1) {
                                newPatchMfArray.push(fund);
                                newPatchMfArray[
                                  newPatchMfArray.length - 1
                                ].purchase_date = date;
                              } else
                                newPatchMfArray[patchindex].purchase_date =
                                  date;
                              setPatchStocksArray(newPatchMfArray);
                            } //editing in post mf array
                            else {
                              let newPostMfArray = [...postStocksArray];
                              let postindex = newPostMfArray.findIndex(
                                (pid) => pid.id === fund.id
                              );
                              newPostMfArray[postindex].purchase_date = date;
                              setPostStocksArray(newPostMfArray);
                            }
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          renderInput={(params) => (
                            <InvestedgeTextField {...params} />
                          )}
                        />
                      </td>
                      <td className="w-[23%]">
                        <InvestedgeTextField
                          fullWidth
                          disabled
                          sx={{
                            fontFamily: "Inter,sans-sarif",
                            fontWeight: 400,
                          }}
                          label="Current Price(in ₹)"
                          type="text"
                          value={fund.current_price}
                        />
                      </td>
                      <td className="w-[23%]">
                        <InvestedgeTextField
                          fullWidth
                          disabled
                          sx={{
                            fontFamily: "Inter,sans-sarif",
                            fontWeight: 400,
                          }}
                          label="Current Value(in ₹)"
                          type="text"
                          value={(
                            fund.count_units * fund.current_price
                          ).toLocaleString("en-IN")}
                        />
                        {/* <label>SIP ?</label>
                  <br />
                  <ThemeProvider theme={BirrSwitch}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checked}
                          onChange={(e) => setChecked(!checked)}
                          inputProps={{ role: "switch" }}
                          color="#0122AF"
                        />
                      }
                    />
                  </ThemeProvider> */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </>
      ) : null}
    </>
  );
}
