import React, { useState, useRef } from "react";
import Hourglass from "../../../../assets/img/wait.gif";
import Women from "../../../../assets/img/modal_chr.svg";
import { useSelector, useDispatch } from "react-redux";
import { PostEventLoggingData } from "../../../../actions/actions";

export default function PortfolioFourthStep() {
  const pptRef = useRef(null);
  const excelRef = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const fileUrls = useSelector((state) => state.portfolioReviewGetPPT);
  setTimeout(() => {
    setLoading(false);
  }, 6000);
  const loadingPpt = false;
  const downloadexcel = async () => await excelRef.current.click();
  const downloadppt = async () => await pptRef.current.click();
  return (
    <div className="w-[100%] flex mt-[16px] h-full">
      <div className="p-[32px] flex flex-col justify-center items-center rounded-[8px] bg-white my-[4px] mb-8 h-full w-full border  ">
        <img
          className=" min-h-[250px] object-contain"
          alt="err"
          src={loading || loadingPpt ? Hourglass : Women}
        />
        <h3 className=" font-inter text-[24px] font-medium leading-[30px] tracking-[0.1133817583322525px] mb-[1rem] text-center">
          {loading || loadingPpt
            ? "Sometimes, great things take time."
            : "Happy Investment. Thanks for downloading the Proposal"}
        </h3>
        {loading ||
          (loadingPpt && (
            <h3 className=" font-inter text-[14px] font-medium leading-[16px] tracking-[0em] text-center">
              Your request is under process. We will notify you post succesful
              verification.
            </h3>
          ))}
        {!loading && !loadingPpt && (
          <div className=" w-[65%] flex justify-between">
            <button
              className=" primary-button text-sm mt-[2rem] font-semibold py-2 px-3 rounded  shadow-boxShadowButton normal-case active:opacity-[0.8] focus:outline-none"
              onClick={() => {
                dispatch(
                  PostEventLoggingData({
                    module_name: "Portfolio Review",
                    event_type: "Repeat",
                    url: window.location.href,
                    note: `Sixth screen`,
                  })
                );
                window.location.reload();
              }}
            >
              Make Another
            </button>

            <div className="flex">
              {fileUrls.excel && (
                <button
                  className="primary-button text-sm mt-[2rem] font-semibold py-2 px-3 rounded  shadow-boxShadowButton normal-case active:opacity-[0.8] focus:outline-none"
                  onClick={() => {
                    dispatch(
                      PostEventLoggingData({
                        module_name: "Portfolio Review",
                        event_type: "Download excel",
                        url: window.location.href,
                        note: `Sixth screen`,
                      })
                    );
                    downloadexcel();
                  }}
                >
                  Download Excel
                </button>
              )}
              <button
                className="primary-button text-sm mt-[2rem] ml-[1.5rem] font-semibold py-2 px-3 rounded  shadow-boxShadowButton normal-case active:opacity-[0.8] focus:outline-none"
                onClick={() => {
                  dispatch(
                    PostEventLoggingData({
                      module_name: "Portfolio Review",
                      event_type: "Download ppt",
                      url: window.location.href,
                      note: `Sixth screen`,
                    })
                  );
                  downloadppt();
                }}
              >
                Download PPT
              </button>
            </div>
          </div>
        )}
        <a href={fileUrls.ppt} ref={pptRef} className="hidden">
          File URL
        </a>
        <a href={fileUrls.excel} ref={excelRef} className="hidden">
          File URL
        </a>
      </div>
    </div>
    // <div
    //   style={{
    //     display: "flex",
    //     justifyContent: "center",
    //     height: "100%",
    //     alignItems: "center",
    //   }}
    // >
    //   <div
    //     style={{
    //       width: "94%",
    //       borderTop: "1px solid #E5E5E5",
    //       paddingTop: "3rem",
    //       display: "flex",
    //       justifyContent: "center",
    //       height: "100%",
    //     }}
    //   >
    //     <div
    //       className="portfolio-stepper-first-form-container"
    //       style={{
    //         width: "80%",
    //         height: "100%",
    //         borderRadius: "10px",
    //         padding: "2rem",
    //         display: "flex",
    //         flexDirection: "column",
    //         justifyContent: "center",
    //         alignItems: "center",
    //       }}
    //     >

    //     </div>
    //   </div>
    // </div>
  );
}
