import React from "react";

export default function SIPSection({ goalDetails }) {
  const sipData = [
    {
      val: goalDetails.current_value_of_portfolio
        ? `₹ ${parseFloat(
            goalDetails.current_value_of_portfolio.toFixed(1)
          ).toLocaleString("en-IN")}`
        : "₹ 0",
      label: "Current Value of Portfolio",
    },
    {
      val:
        goalDetails.sip_required_for_goal &&
        goalDetails.sip_required_for_goal > 0
          ? `₹ ${parseFloat(
              goalDetails.sip_required_for_goal.toFixed(1)
            ).toLocaleString("en-IN")}`
          : "NA",
      label: "SIP Required for Goal",
    },
    {
      val: goalDetails.shortfall_in_going_sip
        ? `₹ ${parseFloat(
            goalDetails.shortfall_in_going_sip.toFixed(1)
          ).toLocaleString("en-IN")}`
        : "₹ 0",
      label: "Shortfall in ongoing SIP",
    },
  ];
  return (
    <>
      <div className="w-full bg-[#fafafa] flex items-center justify-center rounded-[4px] py-4 px-4 mt-6">
        {sipData.map((el, id) => (
          <div
            key={id}
            className="border-r-[1px] border-[#E0DDDD] mr-[1rem] last:border-none first:ml-[1rem] first:text-primary-200 text-neutralBlack w-1/3 flex flex-col items-center justify-center"
          >
            <p className="font-inter text-2xl font-medium  leading-[39px] m-0">
              {el.val}
            </p>
            <p className="font-inter text-sm font-normal leading-[20px] text-[#6A6A6A] mt-[4px] mb-[2px] ">
              {el.label}
            </p>
          </div>
        ))}
      </div>
      <p className="card-heading text-base font-normal mt-4 text-black">
        Measure
      </p>
      <p className="text-sm mt-1">{goalDetails.measure}</p>
    </>
  );
}
