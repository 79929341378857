import React from "react";
import InsightsCard from "./InsightsCard";
import { useSelector } from "react-redux";
export default function Insights() {
  const portfolioData = useSelector((state) => state.dashboardInsights);
  const investmentpolicy = portfolioData.investment_policy;
  const product = portfolioData.product;
  const portfolio = portfolioData.portfolio;
  const taxation = portfolioData.taxation;
  const cashflow_and_liquidity = portfolioData.cashflow_and_liquidity;
  return (
    <div className="mt-[2rem]">
      <h3 className="card-heading mb-0">Insights</h3>
      <div className="pt-[1rem] flex justify-between flex-wrap">
        <InsightsCard
          arrdata={investmentpolicy}
          heading="Investment Policy"
          color="#8080E5"
          style={{ width: "66.7%" }}
        />
        <InsightsCard
          arrdata={product}
          heading="Product"
          color="#8080E5"
          style={{ width: "32%" }}
        />
        <InsightsCard
          arrdata={portfolio}
          heading="Portfolio"
          color="#8080E5"
          style={{ marginTop: "1rem", width: "32.7%" }}
        />
        <InsightsCard
          arrdata={taxation}
          heading="Taxation"
          color="#8080E5"
          style={{ marginTop: "1rem", width: "32.7%" }}
        />
        <InsightsCard
          arrdata={cashflow_and_liquidity}
          heading="Cashflows and Liquidity"
          color="#8080E5"
          style={{ marginTop: "1rem", width: "32%" }}
        />
      </div>
    </div>
  );
}
