import React from "react";
import { GraphColorPalette } from "../../../../../components/colors/graphColorPalette";
import PieChart from "../../../../dashboard/PieChart";
import ProgressBar from "../ProgressBar";
export default function DebtSection({ analytics }) {
  const LegendItem = ({ color, text }) => {
    return (
      <div className="flex ml-[4px]">
        <div
          className={`w-[7px] h-[7px] block mt-[7px] `}
          style={{ backgroundColor: color }}
        />
        <p className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] ml-[0.2rem] mb-0">
          {text}
        </p>
      </div>
    );
  };
  const categoryKeys = [];
  const categoryValues = [];
  const colorPalette = GraphColorPalette;
  for (const property in analytics.debt_category_allocation) {
    categoryKeys.push(property);
    categoryValues.push(
      (analytics.debt_category_allocation[property] * 100).toFixed(1)
    );
  }
  const productKeys = [];
  const productValues = [];
  for (const property in analytics.debt_product_allocation) {
    productKeys.push(property);
    productValues.push(
      (analytics.debt_product_allocation[property] * 100).toFixed(1)
    );
  }

  const assetdata = {
    labels: categoryKeys,

    datasets: [
      {
        data: categoryValues,
        backgroundColor: colorPalette,
        borderColor: colorPalette,
        borderWidth: 1,
      },
    ],
  };
  const pieoptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      labels: {
        render: "value",
        fontColor: "#fff",
      },
      datalabels: {
        display: false,
      },
    },
  };
  return (
    <div className="w-full mt-6">
      {" "}
      <h4 className="font-inter not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-2">
        Product Blended performance
      </h4>
      <div className="w-full bg-[#fafafa] py-[1rem] px-0 flex items-center min-h-full">
        <div className=" w-[50%] text-center border-r border-r-dividerColor">
          <h3 className="font-inter text-[24px] text-[#6a6a76] font-semibold leading-[20px] tracking-[0em] text-center mb-0">
            {`${(analytics.debt_cagr * 100).toFixed(1)} %`}
          </h3>
          <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
            3Y CAGR
          </label>
        </div>
        <div className=" w-[50%] text-center">
          <h3 className="text-[#6a6a76] font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center mb-0">
            {`${(analytics.debt_bm_cagr * 100).toFixed(1)} %`}
          </h3>
          <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
            3Y BM* CAGR
          </label>
        </div>
      </div>
      <div className="w-full flex justify-between mt-4">
        <div className="w-[41%] min-h-[238px]">
          <h4 className=" font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-[0.5rem]">
            Category allocation
          </h4>
          <div className="bg-[#FAFAFA] w-[100%] p-[1rem] min-h-full">
            <div className="w-full flex justify-center items-center">
              <PieChart
                data={assetdata}
                options={pieoptions}
                width={170}
                height={170}
              />
            </div>

            <div className=" flex justify-evenly w-full z-[100] pt-[0.4rem] flex-wrap">
              {categoryKeys.map((key, index) => (
                <LegendItem
                  key={index}
                  color={colorPalette[index]}
                  text={key}
                />
              ))}
            </div>
          </div>
        </div>
        <div className=" w-[57%] min-h-[238px]">
          <h4 className=" font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-[0.5rem]">
            Product allocation
          </h4>

          <div
            className="bg-[#FAFAFA] w-[100%] p-[1rem] min-h-full grid grid-cols-2 grid-flow-col gap-x-5"
            style={{ gridTemplateRows: "20% 20% 20% 20% 20%" }}
          >
            {productKeys.map((key, index) => (
              <div className="row-span-1" key={index}>
                <ProgressBar
                  bg={colorPalette[index % colorPalette.length]}
                  progress={productValues[index]}
                  label={key}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
