import React from "react";
import DesignerBanner from "../assets/img/Designer.mp4";
import Cup from "../assets/img/Cup.svg";
import VisibilitySensor from "react-visibility-sensor";
import ActiveDesigner from "../assets/img/active_designer.svg";
export default function Leader({ setBg, bg }) {
  return (
    <div className="grid grid-cols-1 mt-52 md:grid-cols-2 md:px-20">
      <div className="hidden md:flex flex-col justify-center">
        <VisibilitySensor>
          <>
            <div
              className="w-[200px] flex pb-[16px] mb-8 "
              style={{
                background: bg[2],
                transitionProperty: "width,background",
                transitionDuration: "0.2s,0.2s",
                transitionTimingFunction: "linear",
              }}
            >
              <img src={bg[2].length ? ActiveDesigner : Cup} alt="err" />
              <p
                className="font-montserrat mb-0 ml-2 text-xl"
                style={
                  bg[2].length
                    ? { color: "#FCFCFD", fontWeight: 600 }
                    : { color: "7777E91" }
                }
              >
                Designer
              </p>
            </div>
            <h2 className="hidden md:block font-montserrat font-medium text-4xl text-[#FCFCFD] leading-[54px]">
              Design and Deliver Personalisation, at Scale
            </h2>
            <p className="font-montserrat text-[#B1B5C4] font-normal text-sm md:text-xl mt-[16px] md:mt-6 md:px-0 px-[20px]">
              Make investment suggestions basis various aspects related to
              client preferences in addition to product strategy and market
              views - because every client is different. Simulate and optimize
              client portfolios to meet their return and risk expectations.
            </p>
          </>
        </VisibilitySensor>
      </div>
      <div className="md:hidden flex flex-col justify-center">
        <VisibilitySensor>
          <>
            <div
              className="w-[200px] flex pb-[16px] ml-[20px]"
              style={{
                background: bg[2],
                transitionProperty: "width,background",
                transitionDuration: "0.2s,0.2s",
                transitionTimingFunction: "linear",
              }}
            >
              <img src={bg[2].length ? ActiveDesigner : Cup} alt="err" />
              <p
                className="font-montserrat mb-0 ml-2"
                style={
                  bg[2].length
                    ? { color: "#FCFCFD", fontWeight: 600 }
                    : { color: "7777E91" }
                }
              >
                Designer
              </p>
            </div>
            <h2 className="md:hidden font-montserrat font-medium text-2xl md:text-4xl text-[#FCFCFD] mt-6 leading-9 sm:leading-[54px] md:px-0 px-[20px]">
              Design and Deliver Personalisation, at Scale
            </h2>
            <p className="font-montserrat text-[#B1B5C4] font-normal text-sm md:text-xl mt-[16px] md:mt-6 md:px-0 px-[20px]">
              Make investment suggestions basis various aspects related to
              client preferences in addition to product strategy and market
              views - because every client is different. Simulate and optimize
              client portfolios to meet their return and risk expectations.
            </p>
          </>
        </VisibilitySensor>
      </div>
      <div className="bg-[url('../img/red_mesh.svg')] bg-cover flex justify-center items-center bg-center">
        <video width="86%" loop autoPlay muted>
          <source src={DesignerBanner} type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      </div>
    </div>
    // <div className="md:grid grid-cols-2 px-20 mt-40 hidden">
    //   <div className="flex flex-col justify-center">
    //     <VisibilitySensor
    //       minTopValue={20}
    //       partialVisibility
    //       onChange={(isVisible) =>
    //         isVisible &&
    //         setBg([
    //           "",
    //           "",
    //           "radial-gradient(29.3% 35.71% at 50% 100%, rgba(42, 168, 234, 0.64) 0%, rgba(6, 7, 27, 0.64)",

    //           "",
    //         ])
    //       }
    //     >
    //       <>
    //         <h2 className="font-montserrat font-medium text-4xl text-[#FCFCFD] leading-[54px]">
    //           Design and Deliver Personalisation, at Scale
    //         </h2>

    //         <p className="font-montserrat text-[#B1B5C4] font-normal text-xl mt-6">
    //           Make investment suggestions basis various aspects related to
    //           client preferences in addition to product strategy and market
    //           views - because every client is different. Simulate and optimize
    //           client portfolios to meet their return and risk expectations.
    //         </p>
    //       </>
    //     </VisibilitySensor>
    //   </div>
    //   <div className="bg-[url('../img/red_mesh.svg')] bg-cover flex justify-center items-center bg-center">
    //     <img src={DesignerBanner} alt="err" />
    //   </div>
    // </div>
  );
}
