import React from "react";
import "../assets/css/style.css";
import Logo1 from "../assets/img/logo_with_b.png";
import Switch from "@mui/material/Switch";
import { Link } from "react-router-dom";
import Modalchr from "../assets/img/modal_chr.svg";
import axios from "axios";
import urls from "../urls";

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.contactSubmit = this.contactSubmit.bind(this);
    this.state = {
      fields: { client_type: "I", subscription: false },
      errors: {},
      flag: false,
    };
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};

    let formIsValid = true;

    //Name

    if (typeof fields["name"] !== "undefined") {
      if (!fields["name"].match(/^.{1,35}$/) || !fields["name"]) {
        formIsValid = false;
        errors["name"] = "Invalid name!";
      }
    }

    //Email

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        ) ||
        !fields["email"]
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid!";
      }
    }

    //phone

    if (typeof fields["phone"] !== "undefined") {
      if (!fields["phone"].match(/^\d{9,15}$/) || !fields["phone"]) {
        formIsValid = false;
        errors["phone"] = "Invalid Phone Number!";
      }
    }

    this.setState({ errors: errors });
    this.setState.flag = formIsValid;
    //return formIsValid;
  }

  contactSubmit(e) {
    e.preventDefault();
    console.log({
      ...this.state.fields,
    });
    axios
      .post(urls.contactURL, {
        ...this.state.fields,
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  handleChange(field, e) {
    //this.state.flag = true;
    this.handleValidation();
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  render() {
    return (
      <>
        <div
          className="modal fade form-modal"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLongTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div
                  className="brand -mt-2 -mb-2"
                  style={{
                    display: "block",
                    width: "100%",
                    paddingBottom: "20px",
                  }}
                >
                  <div className="w-88 h-12">
                    <Link to="/" exact="true">
                      <img
                        src={Logo1}
                        alt="logo"
                        className="w-full mt-4 h-full logo-login"
                        style={{ objectFit: "contain" }}
                      ></img>
                    </Link>
                  </div>
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form onSubmit={this.contactSubmit}>
                <div className="wait-modal modal-body pl-5 pr-5">
                  <label
                    htmlFor="name"
                    style={{ fontFamily: "Inter", color: "#A2A5A4" }}
                    className="font-light text-sm"
                  >
                    NAME
                  </label>
                  <br></br>
                  <input
                    ref="name"
                    onChange={this.handleChange.bind(this, "name")}
                    value={this.state.fields["name"]}
                    type="text"
                    id="name-of-client"
                    className="form-control"
                  ></input>
                  <span className="error">{this.state.errors["name"]}</span>

                  <br></br>
                  <label
                    htmlFor="email"
                    style={{ fontFamily: "Inter", color: "#A2A5A4" }}
                    className="font-light text-sm"
                  >
                    EMAIL ID
                  </label>
                  <br></br>
                  <input
                    ref="email"
                    onChange={this.handleChange.bind(this, "email")}
                    value={this.state.fields["email"]}
                    type="email"
                    id="email"
                    className="form-control"
                  ></input>
                  <span className="error">{this.state.errors["email"]}</span>
                  <br></br>

                  <label
                    htmlFor="phone-number"
                    style={{ fontFamily: "Inter", color: "#A2A5A4" }}
                    className="font-light text-sm"
                  >
                    PHONE NUMBER
                  </label>
                  <br></br>
                  <input
                    ref="phone"
                    onChange={this.handleChange.bind(this, "phone")}
                    value={this.state.fields["phone"]}
                    type="tel"
                    id="phone"
                    placeholder=""
                    className="form-control"
                  ></input>
                  <span className="error">{this.state.errors["phone"]}</span>

                  <br></br>
                  <label
                    htmlFor="client-type"
                    style={{ fontFamily: "Inter", color: "#A2A5A4" }}
                    className="font-light text-sm"
                  >
                    CLIENT TYPE
                  </label>
                  <br></br>
                  <select
                    style={{ fontFamily: "Inter", color: "#A2A5A4" }}
                    id="client_type"
                    name="client_type"
                    className="form-control"
                    onChange={(e) => {
                      let fields = this.state.fields;
                      fields.client_type = e.target.value;
                      this.setState({ fields });
                    }}
                  >
                    <option
                      style={{ fontFamily: "Inter", color: "#A2A5A4" }}
                      value="I"
                    >
                      IFA/MFD/RIA
                    </option>
                    <option
                      style={{ fontFamily: "Inter", color: "#A2A5A4" }}
                      value="W"
                    >
                      RM in Bank/Wealth firm
                    </option>
                    <option
                      style={{ fontFamily: "Inter", color: "#A2A5A4" }}
                      value="F"
                    >
                      Family offices
                    </option>
                    <option
                      style={{ fontFamily: "Inter", color: "#A2A5A4" }}
                      value="O"
                    >
                      Others - Not Listed Above
                    </option>
                  </select>
                  <br></br>
                  <label
                    htmlFor="name"
                    style={{ fontFamily: "Inter", color: "#A2A5A4" }}
                    className="font-light text-sm"
                  >
                    SEND ME UPDATES
                  </label>
                  <br></br>
                  <div className="d-flex justify-content-between">
                    <span
                      style={{ fontFamily: "Inter", color: "#696868" }}
                      className="font-semibold text-sm"
                    >
                      Opt for newsletter with insights for free
                    </span>
                    <Switch
                      className="mb-5"
                      checked={this.state.fields.subscription}
                      onChange={() => {
                        let fields = this.state.fields;
                        fields.subscription = !fields.subscription;
                        this.setState({ fields });
                      }}
                    />
                  </div>
                  <div className=" -mt-5">
                    <button
                      id="submit"
                      type="submit"
                      value="Submit"
                      className="pl-5 pr-5 pt-2 pb-2 mb-3"
                      onClick={this.contactSubmit}
                      data-toggle="modal"
                      disabled={this.state.flag ? false : true}
                      data-dismiss={this.state.flag ? "modal" : undefined}
                      data-target={
                        this.state.flag ? "#successModal" : undefined
                      }
                      style={{
                        borderRadius: "4px",
                        backgroundColor: "#03009E",
                        color: "white",
                        fontFamily: "Inter",
                      }}
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className="modal fade form-modal"
          id="successModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLongTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div
                  className="brand -mt-2 -mb-2"
                  style={{
                    display: "block",
                    width: "100%",
                    paddingBottom: "20px",
                  }}
                >
                  <div className="w-88 h-12">
                    <Link to="/" exact="true">
                      <img
                        src={Logo1}
                        alt="logo"
                        className="w-full mt-4 h-full logo-login"
                        style={{ objectFit: "contain" }}
                      ></img>
                    </Link>
                  </div>
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div>
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={Modalchr}
                    alt="err"
                  />
                </div>
                <div>
                  <p style={{ color: "#696868" }} className="text-center mt-4">
                    Thanks for filling the form. We will reach out to you
                  </p>
                </div>

                <div className="d-flex justify-content-center mb-5">
                  <button
                    id="submit"
                    type="submit"
                    data-dismiss="modal"
                    value="Submit"
                    className="pl-5 pr-5 pt-2 pb-2 mb-3"
                    data-toggle="modal"
                    style={{
                      borderRadius: "4px",
                      backgroundColor: "#03009E",
                      color: "white",
                    }}
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Modal;
