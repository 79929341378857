import { useSelector } from "react-redux";
export const PerformanceOnepager = () => {
  const fundData = useSelector((store) => store.getProductOnepagerData);
  const size = Object.keys(fundData.json_field.performance).length;
  let dataLength;
  if (size) {
    dataLength = Object.keys(fundData.json_field.performance.data).length;
  } else {
    dataLength = null;
  }
  return (
    <div className=" w-[72%] pt-[60px]">
      <h3 className=" card-heading mb-4">Performance</h3>
      <div className="rounded-md   border-[1px]  ">
        <table className="w-full">
          <colgroup>
            <col width="21%" />
            <col width="13%" />
            <col width="13%" /> <col width="13%" /> <col width="13%" />
            <col width="13%" /> <col width="13%" /> <col width="13%" />
          </colgroup>
          <tbody>
            <tr className="rounder-[10px] h-10 font-inter font-normal border-b-[1px] border-b-[#323232]">
              <th className="pl-4 font-semibold text-left">Fund / Benchmark</th>
              <th className="text-center font-semibold">1M</th>
              <th className="text-center font-semibold">3M</th>
              <th className="text-center font-semibold">6M</th>
              <th className="text-center font-semibold">1Y</th>
              <th className="text-center font-semibold">3Y</th>
              <th className="text-center font-semibold">5Y</th>
            </tr>
            <tr className="cursor-pointer border-b border-[#e0dddd]">
              <td className="font-inter text-sm not-italic tracking-[0em] p-3 text-left">
                {fundData.json_field.fund_details.name}
              </td>
              <td className="font-inter text-sm not-italic tracking-[0em] p-3 text-center">
                {!size ||
                !dataLength ||
                (dataLength && !fundData.json_field.performance.data["1m_abs"])
                  ? "0%"
                  : `${fundData.json_field.performance.data["1m_abs"].toFixed(
                      2
                    )}%`}
              </td>
              <td className="font-inter text-sm not-italic tracking-[0em] p-3 text-center">
                {!size ||
                !dataLength ||
                (dataLength && !fundData.json_field.performance.data["3m_abs"])
                  ? "0%"
                  : `${fundData.json_field.performance.data["3m_abs"].toFixed(
                      2
                    )}%`}
              </td>
              <td className="font-inter text-sm not-italic tracking-[0em] p-3 text-center">
                {!size ||
                !dataLength ||
                (dataLength && !fundData.json_field.performance.data["6m_abs"])
                  ? "0%"
                  : `${fundData.json_field.performance.data["6m_abs"].toFixed(
                      2
                    )}%`}
              </td>
              <td className="font-inter text-sm not-italic tracking-[0em] p-3 text-center">
                {!size ||
                !dataLength ||
                (dataLength && !fundData.json_field.performance.data["1y_cagr"])
                  ? "0%"
                  : `${fundData.json_field.performance.data["1y_cagr"].toFixed(
                      2
                    )}%`}
              </td>
              <td className="font-inter text-sm not-italic tracking-[0em] p-3 text-center">
                {!size ||
                !dataLength ||
                (dataLength && !fundData.json_field.performance.data["3y_cagr"])
                  ? "0%"
                  : `${fundData.json_field.performance.data["3y_cagr"].toFixed(
                      2
                    )}%`}
              </td>
              <td className="font-inter text-sm not-italic tracking-[0em] p-3 text-center">
                {!size ||
                !dataLength ||
                (dataLength && !fundData.json_field.performance.data["5y_cagr"])
                  ? "0%"
                  : `${fundData.json_field.performance.data["5y_cagr"].toFixed(
                      2
                    )}%`}
              </td>
            </tr>
            <tr className="cursor-pointer border-b border-[#e0dddd]">
              <td className="font-inter text-sm not-italic tracking-[0em] p-3 text-left">
                Nifty 50 TR
              </td>
              <td className="font-inter text-sm not-italic tracking-[0em] p-3 text-center">
                {!size ||
                !dataLength ||
                (dataLength && !fundData.json_field.performance.data["1m_abs"])
                  ? "0%"
                  : `${fundData.json_field.performance.data["1m_abs"].toFixed(
                      2
                    )}%`}
              </td>
              <td className="font-inter text-sm not-italic tracking-[0em] p-3 text-center">
                {!size ||
                !dataLength ||
                (dataLength && !fundData.json_field.performance.data["3m_abs"])
                  ? "0%"
                  : `${fundData.json_field.performance.data["3m_abs"].toFixed(
                      2
                    )}%`}
              </td>
              <td className="font-inter text-sm not-italic tracking-[0em] p-3 text-center">
                {!size ||
                !dataLength ||
                (dataLength && !fundData.json_field.performance.data["6m_abs"])
                  ? "0%"
                  : `${fundData.json_field.performance.data["6m_abs"].toFixed(
                      2
                    )}%`}
              </td>
              <td className="font-inter text-sm not-italic tracking-[0em] p-3 text-center">
                {!size ||
                !dataLength ||
                (dataLength && !fundData.json_field.performance.data["1y_cagr"])
                  ? "0%"
                  : `${fundData.json_field.performance.data["1y_cagr"].toFixed(
                      2
                    )}%`}
              </td>
              <td className="font-inter text-sm not-italic tracking-[0em] p-3 text-center">
                {!size ||
                !dataLength ||
                (dataLength && !fundData.json_field.performance.data["3y_cagr"])
                  ? "0%"
                  : `${fundData.json_field.performance.data["3y_cagr"].toFixed(
                      2
                    )}%`}
              </td>
              <td className="font-inter text-sm not-italic tracking-[0em] p-3 text-center">
                {!size ||
                !dataLength ||
                (dataLength && !fundData.json_field.performance.data["5y_cagr"])
                  ? "0%"
                  : `${fundData.json_field.performance.data["5y_cagr"].toFixed(
                      2
                    )}%`}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
