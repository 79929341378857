import React from "react";
import PieChart from "../../../dashboard/PieChart";
import ProgressBar from "./ProgressBar";
export default function OverviewSection({ analytics }) {
  const currentProductAllocation = [];
  const proposedProductAllocation = [];
  const bgGradients = [
    "linear-gradient(223.78deg, #09C3E7 0%, #7669F0 106.87%)",
    "linear-gradient(225deg, #9C2979 0%, #F15E75 100%)",
    "linear-gradient(108.81deg, #FF9F38 -27.65%, #FE5C44 125%)",
    "linear-gradient(108.81deg, #FFD166 -27.65%, #C26F0D 125%)",
  ];
  for (const property in analytics.overall_product_allocation_before)
    currentProductAllocation.push({
      label: property,
      value: (
        analytics.overall_product_allocation_before[property] * 100
      ).toFixed(1),
    });
  for (const property in analytics.overall_product_allocation_after)
    proposedProductAllocation.push({
      label: property,
      value: (
        analytics.overall_product_allocation_after[property] * 100
      ).toFixed(1),
    });
  const currentdata = {
    labels: ["Equity", "Debt", "Alternates"],

    datasets: [
      {
        data: [
          (analytics.asset_allocation_before.Equity * 100).toFixed(1),
          (analytics.asset_allocation_before.Debt * 100).toFixed(1),
          (analytics.asset_allocation_before.Alternate * 100).toFixed(1),
        ],
        backgroundColor: [
          "#ED6D85",
          "#F7CE6B",
          "#56A1E5",
          "#F1A354",
          "#6CBDBF",
        ],
        borderColor: ["#ED6D85", "#F7CE6B", "#56A1E5", "#F1A354", "#6CBDBF"],
        borderWidth: 1,
      },
    ],
  };
  const proposeddata = {
    labels: ["Equity", "Debt", "Alternates"],
    datasets: [
      {
        data: [
          (analytics.asset_allocation_after.Equity * 100).toFixed(1),
          (analytics.asset_allocation_after.Debt * 100).toFixed(1),
          (analytics.asset_allocation_after.Alternate * 100).toFixed(1),
        ],
        backgroundColor: [
          "#ED6D85",
          "#F7CE6B",
          "#56A1E5",
          "#F1A354",
          "#6CBDBF",
        ],
        borderColor: ["#ED6D85", "#F7CE6B", "#56A1E5", "#F1A354", "#6CBDBF"],
        borderWidth: 1,
      },
    ],
  };
  const pieoptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      labels: [
        {
          render: "percentage",
          position: "outside",
          fontFamily: "Inter,sans-serif",
          fontColor: "#323232",
          textMargin: 8,
          fontSize: 14,
        },
      ],
      datalabels: {
        display: false,
      },
    },
  };

  return (
    <>
      <div className=" w-full mt-[1rem] bg-[#fafafa] p-[1rem] flex rounded-[6px]">
        <div className="w-[20%] text-center border-r border-r-dividerColor">
          <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0">{`${(
            analytics.ovarall_cagr_after * 100
          ).toFixed(1)}%`}</h3>
          <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
            3Y CAGR
          </label>
        </div>
        <div className="w-[25%] text-center border-r border-r-dividerColor">
          <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0">{`${(
            analytics.bm_blended_returns_after * 100
          ).toFixed(1)}%`}</h3>
          <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
            3Y BM* CAGR
          </label>
        </div>
        <div className="w-[27.5%] text-center border-r border-r-dividerColor">
          <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#1982f8] mb-0">
            {analytics.overall_prod_count_after}
          </h3>
          <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
            Total Products
          </label>
        </div>
        <div className="w-[27.5%] text-center">
          <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#00B031] mb-0">
            {`${(analytics.overall_liquidity_after.Liquid * 100).toFixed(1)}%`}
          </h3>
          <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
            Easily Liquidable
          </label>
        </div>
      </div>
      <div className="mt-[3rem] flex justify-between">
        <h2 className="font-medium text-lg font-inter">Asset Allocation</h2>
        <div className="flex">
          <div className="flex items-center ml-[16px]">
            <div className="bg-[#ED6D84] rounded w-[14px] h-[14px]" />
            <h2 className="text-xs ml-[8px] mb-0">Equity</h2>
          </div>
          <div className="flex items-center ml-[16px]">
            <div className="bg-[#F8CE6C] rounded w-[14px] h-[14px]" />
            <h2 className="text-xs ml-[8px] mb-0">Debt</h2>
          </div>
          <div className="flex items-center ml-[16px]">
            <div className="bg-[#56A1E5] rounded w-[14px] h-[14px]" />
            <h2 className="text-xs ml-[8px] mb-0">Alternate</h2>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between mt-[16px]">
        <div className=" w-[49%] bg-[#fafafa] min-h-[250px] p-[1rem]">
          <h4 className=" font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack">
            Portfolio 1
          </h4>
          <PieChart
            data={currentdata}
            options={pieoptions}
            width={200}
            height={200}
          />
        </div>
        <div className=" w-[49%] bg-[#fafafa] min-h-[250px] p-[1rem]">
          <h4 className=" font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack">
            Portfolio 2
          </h4>
          <PieChart
            data={proposeddata}
            options={pieoptions}
            width={200}
            height={200}
          />
        </div>
      </div>
      <h2 className="font-medium text-lg mt-[1rem] font-inter">
        Overall Product Allocation
      </h2>
      <div className="w-full flex justify-between mt-[16px]">
        <div className=" w-[49%] bg-[#fafafa] h-[300px] p-[1rem]">
          <h4 className=" font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack">
            Portfolio 1
          </h4>
          {currentProductAllocation.map((product, index) => (
            <ProgressBar
              key={index}
              bg={bgGradients[index % bgGradients.length]}
              progress={product.value}
              label={product.label}
            />
          ))}
        </div>
        <div className=" w-[49%] bg-[#fafafa] h-[300px] p-[1rem]">
          <h4 className=" font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack">
            Portfolio 2
          </h4>
          {proposedProductAllocation.map((product, index) => (
            <ProgressBar
              key={index}
              bg={bgGradients[index % bgGradients.length]}
              progress={product.value}
              label={product.label}
            />
          ))}
        </div>
      </div>
    </>
  );
}
