import React, { useState } from "react";
import InvestedgePagination from "../../components/pagination/InvestedgePagination";
export default function InsightsCard({ arrdata, heading, color, style }) {
  const data = arrdata;
  const [pageNumber, setPageNumber] = useState(0);
  const dataPerPage = 1;
  const pagesVisited = pageNumber * dataPerPage;
  const displaydata = data
    .slice(pagesVisited, pagesVisited + dataPerPage)
    .map((item, index) => {
      return (
        <p
          key={index}
          className="font-inter text-[13px] not-italic font-normal leading-[20px] tracking-[0em] mt-[0.5rem]"
        >
          {item}
        </p>
      );
    });
  const pageCount = Math.ceil(data.length / dataPerPage);
  const changePage = (e, val) => {
    setPageNumber(val - 1);
  };
  return (
    <div
      className="relative p-4 bg-[#fff] rounded-[6px] min-h-[274px] border border-globalBorderColor"
      style={style}
    >
      <h4 className="font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-primary-200">
        {heading}
      </h4>
      <div className="min-h-[190px]">{displaydata}</div>
      <div className="w-full flex justify-end absolute bottom-4 right-4">
        <InvestedgePagination
          count={pageCount}
          defaultPage={1}
          page={pageNumber + 1}
          onChange={changePage}
          color="primary"
        />
      </div>
    </div>
  );
}
