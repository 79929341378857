import { RESET_PRODUCT_INPUT } from "../actions/type";
const initialState = [];
export default function ResetProductInput(state = initialState, action) {
  switch (action.type) {
    case RESET_PRODUCT_INPUT:
      return action.payload;
    default:
      return state;
  }
}
