import axios from "axios";
import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import Attachment from "../../assets/img/red_attachment.svg";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import InvestedgeButtonHollow from "../../components/buttons/InvestedgeButtonHollow";
import InvestedgeModal from "../../components/modal/InvestedgeModal";
import urls from "../../urls";

export default function ManageLinksModalRow({ element, setList }) {
  const date = new Date(element.meta_created_at);
  var dd = String(date.getDate()).padStart(2, "0");
  var mm = String(date.getMonth() + 1).padStart(2, "0");
  var yyyy = date.getFullYear();
  const [deactivateModal, setDeactivateModal] = useState(false);
  const handleDeactivate = () => {
    // setElement({ ...element, meta_is_active: false });
    axios
      .post(`${urls.acquireClientsDeactivateLinkURL}/${element.shared_link}/`)
      .then(() => {
        axios
          .get(urls.acquireClientsManageLinksURL)
          .then((res) => {
            setDeactivateModal(false);
            setList(
              res.data.sort((a, b) => {
                return (
                  new Date(b.meta_created_at) - new Date(a.meta_created_at)
                );
              })
            );
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {});
  };
  return (
    <>
      <tr className="border-bottom py-0 h-[55px]">
        <td className="px-0 py-[12px]">{`${dd}-${mm}-${yyyy}`}</td>
        <td
          className={
            element.meta_is_active
              ? "px-0 py-[12px] text-center text-sm text-primary-200"
              : "px-0 py-[12px] text-center text-sm text-[#9B9CA4]"
          }
        >
          <a
            href={
              element.meta_is_active
                ? `${window.location.origin}/instant-review/${element.shared_link}/${element.report_type}`
                : null
            }
            className={
              element.meta_is_active
                ? "hover:text-primary-200"
                : "hover:text-[#9B9CA4]"
            }
            target="_blank"
            rel="noreferrer"
          >
            {`${window.location.origin}/instant-review/${element.shared_link}/${element.report_type}`.slice(
              0,
              40
            ) + "..."}
          </a>
        </td>
        <td className="text-center py-3 ">
          {element.report_type === "mfcentral" ? "MF Central" : "CAMS CAS"}
        </td>
        <td className="text-center py-3 ">{element.ppt_count}</td>
        <td className="px-0 py-3 ">
          {element.meta_is_active ? (
            <div
              className="flex w-full items-center justify-center"
              onClick={() => setDeactivateModal(true)}
            >
              <img src={Attachment} alt="err" />{" "}
              <p className="mb-0 text-sm mt-0 ml-2 text-[#ED6D85] cursor-pointer leading-0">
                Deactivate
              </p>
            </div>
          ) : null}
        </td>
      </tr>
      <InvestedgeModal
        aria-labelledby="Previous History Modal"
        aria-describedby="Previous History Modal"
        sx={{
          width: "50vw",
          marginRight: "auto",
          marginLeft: "auto",
          padding: "1rem",
        }}
        open={deactivateModal}
        handleclose={() => setDeactivateModal(false)}
      >
        <div
          className="flex justify-between border-0 pl-0"
          style={{ paddingTop: "0" }}
        >
          <h5 className="card-heading">Are you sure?</h5>
          <IoIosClose size={30} onClick={() => setDeactivateModal(false)} />
        </div>
        <p className="text-sm mt-4">
          Please note that disabling of link means that this link would not be
          accessible to anyone anymore.
        </p>
        <div className="w-full flex justify-between mt-4">
          {" "}
          <InvestedgeButtonHollow onClick={() => setDeactivateModal(false)}>
            Cancel
          </InvestedgeButtonHollow>{" "}
          <InvestedgeButton color="error" onClick={handleDeactivate}>
            Confirm
          </InvestedgeButton>
        </div>
      </InvestedgeModal>
    </>
  );
}
