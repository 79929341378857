import { GET_PRODUCT_INPUT } from "../actions/type";
const initialState = { count: 0, results: [], loading: true };
export default function GetProductInput(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_INPUT:
      return action.payload;
    default:
      return state;
  }
}
