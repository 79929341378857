import React, { useEffect, useRef } from "react";
import Add from "../../../../../assets/img/circle_over_plus_blue.svg";
import Offshorerow from "./Offshorerow";
import { useState } from "react";
import urls from "../../../../../urls";
import { useDispatch, useSelector } from "react-redux";
import {
  getComparisonData,
  GetDashboardOffshorePortfolio,
  GetDashboardPortfolioList,
  OpenSnackbar,
} from "../../../../../actions/actions";
import { Bars } from "react-loader-spinner";
import ClickAwayListener from "react-click-away-listener";
import axios from "axios";
import InvestedgeTextField from "../../../../../components/textfield/InvestedgeTextField";
import InvestedgeButton from "../../../../../components/buttons/InvestedgeButton";
import InvestedgePopper from "../../../../../components/popper/InvestedgePopper";
import { MenuItem } from "@mui/material";
export default function OffshoreScreen() {
  const data = useSelector((store) => store.dashboardOffshorePortfolio);
  const portfolioList = useSelector(
    (store) => store.dashboardPortfolioPriceList
  );
  const [productarray, setProductarray] = useState(
    JSON.parse(JSON.stringify(data)).map((y) => {
      return { ...y, purchase_date: new Date(y.purchase_date) };
    })
  );
  const [patchProductArray, setPatchProductArray] = useState([]);
  const [postProductArray, setPostProductArray] = useState([]);
  const [submitActive, setSubmitActive] = useState(false);
  const dispatch = useDispatch();
  const productsData = useSelector((state) => state.getComparisonData);
  const removeProductarray = (i) => {
    let newAifarray = [...productarray];
    newAifarray.splice(i, 1);
    setProductarray(newAifarray);
  };
  // const portfolioValue = useSelector(
  //   (store) => store.portfolioReviewGetSumary.portfolio_value
  // );
  // const portfolioList = useSelector(
  //   (store) => store.dashboardPortfolioPriceList
  // );
  // const parsePortfolio = (portfolio) => {
  //   if (portfolio / 1000 < 100)
  //     return `₹ ${parseFloat(portfolio / 1000).toFixed(1)} K`;
  //   if (portfolio / 100000 < 100)
  //     return `₹ ${parseFloat(portfolio / 100000).toFixed(1)} L`;
  //   return `₹ ${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  // };
  const [searchBar, setSearchBar] = useState(false);
  const [addedProduct, setAddedProduct] = useState("");
  const [newProduct, setNewProduct] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const popperRef = useRef(null);
  const [deleteArray, setDeleteArray] = useState([]);
  useEffect(() => {
    dispatch(
      GetDashboardOffshorePortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setProductarray(data);
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    newProduct.length >= 3 &&
      dispatch(
        getComparisonData({ params: { name: newProduct, type: "Offshore" } })
      );
    if (newProduct.length >= 3) setAnchorEl(popperRef.current);
    else setAnchorEl(null);
    // eslint-disable-next-line
  }, [newProduct]);
  useEffect(() => {
    if (
      deleteArray.length ||
      postProductArray.length ||
      patchProductArray.length
    )
      setSubmitActive(true);
    else setSubmitActive(false);
  }, [deleteArray, postProductArray, patchProductArray]);

  const handleSubmit = async () => {
    let newPatchList = patchProductArray.map((item) => {
      var dd = String(item.purchase_date.getDate()).padStart(2, "0");
      var mm = String(item.purchase_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.purchase_date.getFullYear();
      return {
        id: item.id,
        user_id: localStorage.getItem("clientId"),
        unlisted_id: item.unlisted_id,
        purchase_date: yyyy + "-" + mm + "-" + dd,
        count_units: parseFloat(item.count_units),
        purchase_price: parseFloat(item.purchase_price),
        current_price: parseFloat(item.current_price),
      };
    });
    let newPostList = postProductArray.map((item) => {
      var dd = String(item.purchase_date.getDate()).padStart(2, "0");
      var mm = String(item.purchase_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.purchase_date.getFullYear();
      return {
        unlisted_id: item.unlisted_id,
        user_id: localStorage.getItem("clientId"),
        count_units: parseFloat(item.count_units),
        purchase_date: yyyy + "-" + mm + "-" + dd,
        purchase_price: parseFloat(item.purchase_price),
        current_price: parseFloat(item.current_price),
      };
    });
    let newDeleteList = deleteArray.map((item) => {
      return {
        id: item.id,
        user_id: localStorage.getItem("clientId"),
        unlisted_id: item.unlisted_id,
        count_units: parseFloat(item.count_units),
        purchase_price: parseFloat(item.purchase_price),
        current_price: parseFloat(item.current_price),
      };
    });
    newDeleteList.length &&
      (await axios.post(
        urls.dashboardOffshorePortfolioDeleteURL,
        newDeleteList
      ));
    newPostList.length &&
      (await axios.post(urls.dashboardOffshorePortfolioURL, newPostList));
    newPatchList.length &&
      (await axios.patch(urls.dashboardOffshorePortfolioURL, newPatchList));
    setPatchProductArray([]);
    setPostProductArray([]);
    setDeleteArray([]);
    dispatch(
      GetDashboardPortfolioList({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      GetDashboardOffshorePortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      OpenSnackbar({
        severity: "success",
        message: "Portfolio Updated Successfully",
      })
    );
  };
  return (
    <div className="asset-input-screen">
      <div className="asset-input-heading">
        Offshore - {portfolioList.total_offshore_value}
      </div>
      <div className="asset-handler" style={{ marginBottom: "0rem" }}>
        <div className="asset-handler-add-funds items-center h-[56px]">
          <img src={Add} alt="err" />
          <p
            className="asset-handler-text"
            style={{ marginLeft: "0.3rem" }}
            onClick={
              () => setSearchBar(true)
              // let newAifarray = [...productarray];
              // newAifarray.unshift({ name: "New Mutual Fund", price: "0" });
              // setProductarray(newAifarray);
            }
          >
            Add Offshore
          </p>
          {searchBar && (
            <div className="w-[200px] select-none ml-[16px]">
              <InvestedgeTextField
                ref={popperRef}
                forwardedref={popperRef}
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                  width: "200px",
                }}
                label="Search Fund"
                type="text"
                value={newProduct}
                onChange={(e) => setNewProduct(e.target.value)}
              />
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <InvestedgePopper
                  id="mf-client-input-popper"
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  placement="bottom-start"
                  className="z-[100000] h-[300px]"
                >
                  {productsData[0] === "loading" ? (
                    <div className="h-[300px] flex justify-center items-center w-[200px]">
                      <Bars color="#1982f8" height="60" width="60" />
                    </div>
                  ) : productsData.length ? (
                    <div className="h-[300px] productunderstading-scrollablediv overflow-auto">
                      {productsData.map((option, ind) => (
                        <MenuItem
                          key={ind}
                          onClick={() => {
                            setSearchBar(false);
                            setAddedProduct(option.product_id);
                            let newAifarray = [...productarray];
                            newAifarray.unshift({
                              id: option.product_id,
                              offshore_id: option.product_id,
                              name: option.name,
                              count_units: 1,
                              purchase_date: new Date(),
                              purchase_price: 1,
                              current_price: option.nav,
                              current_price_date: new Date(),
                            });
                            setPostProductArray([
                              ...postProductArray,
                              {
                                id: option.product_id,
                                offshore_id: option.product_id,
                                name: option.name,
                                count_units: 1,
                                purchase_date: new Date(),
                                purchase_price: 1,
                                current_price: option.nav,
                                current_price_date: new Date(),
                              },
                            ]);
                            setProductarray(newAifarray);
                            setAnchorEl(null);
                          }}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </div>
                  ) : (
                    <div className="h-[300px] flex items-center justify-center">
                      No data found
                    </div>
                  )}
                </InvestedgePopper>
              </ClickAwayListener>
            </div>
          )}
        </div>
        {/* <p className="asset-handler-text" onClick={() => setProductarray([])}>
          Clear All
        </p> */}
      </div>
      <div
        className="update-portfolio-asset-row-container"
        style={{ height: "55vh" }}
      >
        {productarray.map((fund, index) => (
          <Offshorerow
            key={fund.id}
            fund={fund}
            id={index}
            removeProductarray={removeProductarray}
            productarray={productarray}
            setProductarray={setProductarray}
            patchProductArray={patchProductArray}
            setPatchProductArray={setPatchProductArray}
            deleteArray={deleteArray}
            setDeleteArray={setDeleteArray}
            data={data}
            postProductArray={postProductArray}
            setPostProductArray={setPostProductArray}
            addedProduct={addedProduct}
          />
        ))}
      </div>
      <div className="flex w-full mt-[24px] pr-[24px] justify-end">
        <InvestedgeButton
          disabled={!submitActive}
          onClick={() => {
            if (
              patchProductArray.length ||
              postProductArray.length ||
              deleteArray.length
            ) {
              setSubmitActive(false);
              handleSubmit();
            }
          }}
        >
          Submit
        </InvestedgeButton>
      </div>
    </div>
  );
}
