import { GET_PRODUCTONEPAGER_DATA } from "../actions/type";
const initialState = {};
export default function GetProductOnepagerData(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTONEPAGER_DATA:
      return action.payload;
    default:
      return state;
  }
}
