import { ClickAwayListener, MenuItem } from "@mui/material";
import React, { useRef, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { Bars } from "react-loader-spinner";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getComparisonData, OpenSnackbar } from "../../../actions/actions";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import InvestedgeButtonHollow from "../../../components/buttons/InvestedgeButtonHollow";
import InvestedgeDatepicker from "../../../components/datepicker/InvestedgeDatepicker";
import DropdownForAttributes from "../../../components/Dropdowns/DropdownForAttributes";
import InvestedgeModal from "../../../components/modal/InvestedgeModal";
import InvestedgePopper from "../../../components/popper/InvestedgePopper";
import InvestedgeTextField from "../../../components/textfield/InvestedgeTextField";
import dayjs from "dayjs";
import urls from "../../../urls";
export default function AddTransaction({
  openModal,
  modalData,
  onClose,
  products,
  setProducts,
  patchMfArray,
  setPatchMfArray,
  deleteArray,
  setDeleteArray,
  postMfArray,
  setPostMfArray,
  removeMFarray,
}) {
  const [tempData, setTempData] = useState(
    JSON.parse(JSON.stringify(modalData))
  );
  const [productId, setProductId] = useState(
    modalData.product_id ? modalData.product_id : null
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const productsData = useSelector((state) => state.getComparisonData);
  const dispatch = useDispatch();
  const popperRef = useRef(null);
  const { transactions } = useSelector((store) => store.dashboardMFPortfolio);

  const return_date = (val) => {
    try {
      let temp = dayjs(new Date(val).toISOString());
      return temp;
    } catch (err) {
      return val;
    }
  };
  return (
    <InvestedgeModal
      aria-labelledby="Add Transaction Modal"
      aria-describedby="Add Transaction Modal"
      sx={{
        height: "98vh",
        margin: "auto",
        minWidth: "40vw",
        maxWidth: "40vw",
      }}
      open={openModal}
      handleclose={onClose}
    >
      <div className="modal-header border-0 p-0 flex justify-between w-full">
        <h5 className="card-heading">Buy / Sell Mutual Funds</h5>
        <IoIosClose onClick={onClose} size={30} />
      </div>
      <span className="block font-inter-regular text-xs text-justify mt-2 mb-4 leading-[17px]">
        Note : In case of Credit Transactions like "Switch Out" or "Redemption",
        Quantity/Amount should be in negative format. For example, you redeem
        100 units of Parag Parikh Flexi Cap Fund-Reg(G) at NAV 66, so you shall
        put -100 as units.
      </span>
      <div className="w-full mt-4">
        <DropdownForAttributes
          labelName={"Transaction name"}
          options={[
            "PURCHASE",
            "PURCHASE_SIP",
            "REDEMPTION",
            "DIVIDEND_PAYOUT",
            "DIVIDEND_REINVEST",
            "SWITCH_IN",
            "SWITCH_IN_MERGER",
            "SWITCH_OUT",
            "SWITCH_OUT_MERGER",
            "STT_TAX",
            "STAMP_DUTY_TAX",
            "TDS_TAX",
            "SEGREGATION",
            "MISC",
            "UNKNOWN",
            "REVERSAL",
          ]}
          selected={tempData}
          setSelected={setTempData}
          attribute="transactionName"
        />
      </div>
      <InvestedgeDatepicker
        format="DD/MM/YYYY"
        className="w-full mt-4"
        label="Date"
        maxDate={dayjs(new Date().toISOString())}
        value={return_date(tempData.date)}
        onChange={async (date) => {
          let newTempData = { ...tempData };
          newTempData.date = new Date(date);
          newTempData.nav = "";
          var dd = String(new Date(date).getDate()).padStart(2, "0");
          var mm = String(new Date(date).getMonth() + 1).padStart(2, "0");
          var yyyy = new Date(date).getFullYear();
          productId &&
            (await axios
              .get(urls.dashboardMFNavURL, {
                params: {
                  product_id: productId,
                  date: yyyy + "-" + mm + "-" + dd,
                },
              })
              .then((res) => {
                newTempData.nav = res.data.nav;
              })
              .catch((err) =>
                dispatch(
                  OpenSnackbar({
                    severity: "error",
                    message:
                      "NAV value doesn't exist for selected date for this product",
                  })
                )
              ));
          setTempData(newTempData);
        }}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        renderInput={(params) => <InvestedgeTextField {...params} />}
      />
      <InvestedgeTextField
        fullWidth
        sx={{
          fontFamily: "Inter,sans-sarif",
          fontWeight: 400,
          width: "100%",
          marginTop: "16px",
        }}
        label="Mutual fund Name"
        forwardedref={popperRef}
        type="text"
        value={tempData.name}
        onChange={(e) => {
          setProductId(null);
          e.target.value.length >= 3 &&
            dispatch(
              getComparisonData({
                params: { name: e.target.value, type: "Mutual Fund" },
              })
            );
          setTempData({ ...tempData, name: e.target.value });
          if (e.target.value.length >= 3) setAnchorEl(popperRef.current);
          else setAnchorEl(null);
        }}
      />
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <InvestedgePopper
          id="mf-client-input-popper"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="bottom-start"
          className="z-[100000] h-[300px]"
        >
          {productsData[0] === "loading" ? (
            <div className="h-[300px] flex justify-center items-center w-[30.5vw]">
              <Bars color="#1982f8" height="60" width="60" />
            </div>
          ) : productsData.length ? (
            <div className="h-[300px] productunderstading-scrollablediv overflow-auto min-w-[30.5vw]">
              {productsData.map((option, ind) => (
                <MenuItem
                  key={ind}
                  onClick={async () => {
                    var dd = String(new Date(tempData.date).getDate()).padStart(
                      2,
                      "0"
                    );
                    var mm = String(
                      new Date(tempData.date).getMonth() + 1
                    ).padStart(2, "0");
                    var yyyy = new Date(tempData.date).getFullYear();
                    let newtempdata = tempData;
                    newtempdata.name = option.name;
                    newtempdata.nav = "";
                    setProductId(option.product_id);
                    await axios
                      .get(urls.dashboardMFNavURL, {
                        params: {
                          product_id: option.product_id,
                          date: yyyy + "-" + mm + "-" + dd,
                        },
                      })
                      .then((res) => {
                        newtempdata.nav = res.data.nav;
                      })
                      .catch((err) =>
                        dispatch(
                          OpenSnackbar({
                            severity: "error",
                            message:
                              "NAV value doesn't exist for selected date for this product",
                          })
                        )
                      );
                    setTempData(newtempdata);
                    setAnchorEl(null);
                  }}
                  //   onClick={() => {
                  //     setSearchBar(false);
                  //     const rnd = Math.random();
                  //     setAddedProduct(rnd);
                  //     let newMfarray = [...mfarray];
                  //     newMfarray.unshift({
                  //       id: rnd,
                  //       mf_id: option.product_id,
                  //       name: option.name,
                  //       count_units: 1,
                  //       purchase_date: new Date(),
                  //       purchase_price: 1,
                  //       current_price: option.nav,
                  //       current_price_date: new Date(),
                  //     });
                  //     setPostMfArray([
                  //       ...postMfArray,
                  //       {
                  //         id: rnd,
                  //         mf_id: option.product_id,
                  //         name: option.name,
                  //         count_units: 1,
                  //         purchase_date: new Date(),
                  //         purchase_price: 1,
                  //         current_price: option.nav,
                  //         current_price_date: new Date(),
                  //       },
                  //     ]);
                  //     setMfarray(newMfarray);
                  //
                  //   }}
                >
                  {option.name}
                </MenuItem>
              ))}
            </div>
          ) : (
            <div className="h-[300px] flex items-center justify-center w-[200px]">
              No data found
            </div>
          )}
        </InvestedgePopper>
      </ClickAwayListener>
      <div className="w-full flex justify-between">
        <InvestedgeTextField
          fullWidth
          sx={{
            fontFamily: "Inter,sans-sarif",
            fontWeight: 400,
            width: "49%",
            marginTop: "16px",
          }}
          label="Quantity"
          type="text"
          value={tempData.quantity}
          onChange={(e) => {
            const re = /^-?\d*\.?\d*$/;
            if (e.target.value === "" || re.test(e.target.value))
              setTempData({
                ...tempData,
                quantity: e.target.value,
                amount:
                  productId && e.target.value
                    ? (parseFloat(e.target.value) * tempData.nav).toFixed(2)
                    : tempData.amount,
              });
          }}
        />
        <InvestedgeTextField
          fullWidth
          sx={{
            fontFamily: "Inter,sans-sarif",
            fontWeight: 400,
            width: "49%",
            marginTop: "16px",
          }}
          label="Amount"
          type="text"
          value={tempData.amount}
          onChange={(e) => {
            const re = /^-?\d*\.?\d*$/;
            if (e.target.value === "" || re.test(e.target.value))
              setTempData({
                ...tempData,
                amount: e.target.value,
                quantity:
                  productId && e.target.value
                    ? (parseFloat(e.target.value) / tempData.nav).toFixed(2)
                    : tempData.quantity,
              });
          }}
        />
      </div>
      <InvestedgeTextField
        fullWidth
        sx={{
          fontFamily: "Inter,sans-sarif",
          fontWeight: 400,
          width: "100%",
          marginTop: "16px",
        }}
        label="NAV"
        type="text"
        value={tempData.nav}
        onChange={() => {}}
        disabled={true}
        // onChange={(e) => {
        //   const re = /^\d*\.?\d*$/;
        //   if (e.target.value === "" || re.test(e.target.value))
        //     setTempData({
        //       ...tempData,
        //       nav: e.target.value,
        //     });
        // }}
      />
      <InvestedgeTextField
        fullWidth
        sx={{
          fontFamily: "Inter,sans-sarif",
          fontWeight: 400,
          width: "100%",
          marginTop: "16px",
        }}
        label="Folio"
        type="text"
        value={tempData.folio}
        onChange={(e) => {
          setTempData({
            ...tempData,
            folio: e.target.value,
          });
        }}
      />
      <InvestedgeTextField
        fullWidth
        sx={{
          fontFamily: "Inter,sans-sarif",
          fontWeight: 400,
          width: "100%",
          marginTop: "16px",
        }}
        label="Advisor Name"
        type="text"
        value={tempData.advisor_name}
        onChange={(e) => {
          setTempData({
            ...tempData,
            advisor_name: e.target.value,
          });
        }}
      />
      <InvestedgeTextField
        fullWidth
        sx={{
          fontFamily: "Inter,sans-sarif",
          fontWeight: 400,
          width: "100%",
          marginTop: "16px",
        }}
        label="Description"
        type="text"
        value={tempData.description}
        onChange={(e) => {
          setTempData({
            ...tempData,
            description: e.target.value,
          });
        }}
        multiline
        rows={4}
      />
      <div className="w-full mt-6 flex justify-between">
        <InvestedgeButtonHollow onClick={onClose}>
          Cancel
        </InvestedgeButtonHollow>{" "}
        <InvestedgeButton
          disabled={
            !tempData.transactionName ||
            !productId ||
            tempData.quantity === "" ||
            tempData.amount === "" ||
            tempData.nav === ""
          }
          onClick={() => {
            let newMfArray = [...products];
            let index = products.findIndex((pid) => pid.id === tempData.id);
            if (index !== -1) {
              newMfArray[index].transaction_type = tempData.transactionName;
              newMfArray[index].date = tempData.date;
              newMfArray[index].name = tempData.name;
              newMfArray[index].units = parseFloat(tempData.quantity);
              newMfArray[index].amount = parseFloat(tempData.amount);
              newMfArray[index].nav = parseFloat(tempData.nav);
              newMfArray[index].folio = tempData.folio;
              newMfArray[index].advisor_name = tempData.advisor_name;
              newMfArray[index].description = tempData.description;
              newMfArray[index].product_id = productId;
              newMfArray[index].pan = "";
            } else {
              newMfArray.unshift({
                transaction_type: tempData.transactionName,
                date: tempData.date,
                name: tempData.name,
                units: parseFloat(tempData.quantity),
                amount: parseFloat(tempData.amount),
                nav: parseFloat(tempData.nav),
                folio: tempData.folio,
                advisor_name: tempData.advisor_name,
                description: tempData.description,
                product_id: productId,
                balance_units: 0,
                pan: "",
                id: Math.random(),
              });
            }
            setProducts(newMfArray);
            //editing in patch mf array
            if (transactions.some((getFund) => getFund.id === tempData.id)) {
              let newPatchMfArray = [...patchMfArray];
              let patchindex = newPatchMfArray.findIndex(
                (pid) => pid.id === tempData.id
              );
              if (patchindex === -1) {
                newPatchMfArray.push({
                  transaction_type: tempData.transactionName,
                  date: tempData.date,
                  name: tempData.name,
                  units: parseFloat(tempData.quantity),
                  amount: parseFloat(tempData.amount),
                  nav: parseFloat(tempData.nav),
                  folio: tempData.folio,
                  advisor_name: tempData.advisor_name,
                  description: tempData.description,
                  product_id: productId,
                  id: tempData.id,
                  pan: "",
                });
              } else {
                newPatchMfArray[patchindex].transaction_type =
                  tempData.transactionName;
                newPatchMfArray[patchindex].date = tempData.date;
                newPatchMfArray[patchindex].name = tempData.name;
                newPatchMfArray[patchindex].units = parseFloat(
                  tempData.quantity
                );
                newPatchMfArray[patchindex].amount = parseFloat(
                  tempData.amount
                );
                newPatchMfArray[patchindex].nav = parseFloat(tempData.nav);
                newPatchMfArray[patchindex].folio = tempData.folio;
                newPatchMfArray[patchindex].advisor_name =
                  tempData.advisor_name;
                newPatchMfArray[patchindex].description = tempData.description;
                newPatchMfArray[patchindex].product_id = productId;
                newPatchMfArray[patchindex].pan = "";
              }
              setPatchMfArray(newPatchMfArray);
            } //editing in post mf array
            else {
              let newPostMfArray = [...postMfArray];
              let postindex = newPostMfArray.findIndex(
                (pid) => pid.id === tempData.id
              );
              if (postindex === -1) {
                newPostMfArray.push({
                  transaction_type: tempData.transactionName,
                  date: new Date(tempData.date),
                  name: tempData.name,
                  units: parseFloat(tempData.quantity),
                  amount: parseFloat(tempData.amount),
                  nav: parseFloat(tempData.nav),
                  folio: tempData.folio,
                  advisor_name: tempData.advisor_name,
                  description: tempData.description,
                  product_id: productId,
                  pan: "",
                  dividend_rate: 0.0,
                  coupon_rate: 0.0,
                  balance_units: 0.0,
                });
              } else {
                newPostMfArray[postindex].transaction_type =
                  tempData.transactionName;
                newPostMfArray[postindex].date = new Date(tempData.date);
                newPostMfArray[postindex].name = tempData.name;
                newPostMfArray[postindex].units = parseFloat(tempData.quantity);
                newPostMfArray[postindex].amount = parseFloat(tempData.amount);
                newPostMfArray[postindex].nav = parseFloat(tempData.nav);
                newPostMfArray[postindex].folio = tempData.folio;
                newPostMfArray[postindex].advisor_name = tempData.advisor_name;
                newPostMfArray[postindex].description = tempData.description;
                newPostMfArray[postindex].product_id = productId;
                newPostMfArray[postindex].pan = "";
              }
              setPostMfArray(newPostMfArray);
            }
            onClose();
          }}
        >
          {modalData.product_id ? "Save" : "Add"}
        </InvestedgeButton>
      </div>
    </InvestedgeModal>
  );
}
