import React from "react";
import { HashLink } from "react-router-hash-link";
import { Navbar } from "../onePagerConsts";
import "../../../static/css/existing/dashboard_styles.css";
import { useLocation } from "react-router-dom";
import { navHighlightedBar } from "../onePagerConsts";

export default function NavBarComponent() {
  const location = useLocation();
  return (
    <>
      <nav className="clientsidenavbar">
        {Object.entries(Navbar).map(([key, value]) => (
          <div>
            <HashLink to={"#" + key} key={key}>
              <div
                style={{ height: "44px" }}
                className={
                  location.key === value
                    ? "active-settings-sidebar-link"
                    : "settings-sidebar-link"
                }
              >
                {value}
              </div>
            </HashLink>
          </div>
        ))}

        {Object.entries(navHighlightedBar).map(([key, value]) => (
          <HashLink to={"#" + key}>
            <div
              className="text-white font-semibold py-2 px-3 rounded box-shadowed-button mt-3 "
              style={{
                backgroundColor: "#0122AF",
                fontSize: "14px",
                fontWeight: "600",
                width: "173px",
                height: "34px",
                textAlign: "center",
                fontFamily: "Inter , sans-serif",
                letterSpacing: "0.02em",
                textTransform: "uppercase",
                marginLeft: "1.5rem",
              }}
            >
              <p>{value}</p>
            </div>
          </HashLink>
        ))}
      </nav>
    </>
  );
}
