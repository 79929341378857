import React, { useState } from "react";
import PortfolioInputSidebar from "./PortfolioInputSidebar";
import PortfolioInputRouter from "./PortfolioInputRouter";
import { useSelector } from "react-redux";
// import {
//   GetDashboardPortfolioList,
//   GetReviewPortfolioSummary,
// } from "../actions/actions";
import { IoIosClose } from "react-icons/io";
import InvestedgeModal from "../components/modal/InvestedgeModal";
export default function UpdateInputScreen(props) {
  const portfolioValue = useSelector(
    (store) => store.dashboardPortfolioPriceList.total_portfolio_value
  );
  // const portfolioUpload = useSelector(
  //   (store) => store.postClientInputUploadFile
  // );
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(
  //     GetDashboardPortfolioList({
  //       params: {
  //         client_id: localStorage.getItem("clientId"),
  //       },
  //     })
  //   );
  //   dispatch(
  //     GetReviewPortfolioSummary({
  //       params: {
  //         client_id: localStorage.getItem("clientId"),
  //       },
  //     })
  //   ); // eslint-disable-next-line
  // }, [portfolioUpload]);

  const [route, setRoute] = useState("welcomescreen");
  const parsePortfolio = (portfolio) => {
    if (portfolio / 1000 < 100)
      return `₹ ${parseFloat(portfolio / 1000).toFixed(1)} K`;
    if (portfolio / 100000 < 100)
      return `₹ ${parseFloat(portfolio / 100000).toFixed(1)} L`;
    return `₹ ${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  };
  return (
    <InvestedgeModal
      aria-labelledby="Portfolio Input Modal"
      aria-describedby="Portfolio Input Modal"
      sx={{
        width: "98vw",
        marginRight: "auto",
        marginLeft: "auto",
        minHeight: "97vh",
      }}
      open={props.open}
      handleclose={props.onClose}
    >
      <div className="flex justify-between ">
        <h2 className="text-[18px] font-inherit font-medium">
          Total portfolio value: {parsePortfolio(portfolioValue)}
        </h2>
        <IoIosClose size={30} className="mr-4" onClick={props.onClose} />
      </div>
      <div className="flex justify-between">
        <PortfolioInputSidebar route={route} setRoute={setRoute} />
        <div
          style={{
            padding: "0.9rem",
            width: "81%",
          }}
          className="w-[79%] min-h-full"
        >
          <PortfolioInputRouter route={route} setRoute={setRoute} />
        </div>
      </div>
      {/* {route !== "welcomescreen" &&
                route !== "uploadstatementscreen" &&
                route !== "uploadexcelscreen" && (
                  <div className="flex w-full mt-[32px] pr-[32px] justify-end">
                    <button
                      style={{ backgroundColor: "#1982F8", color: "#fff" }}
                      className="font-semibold py-2 px-3 rounded box-shadowed-button"
                    >
                      Submit
                    </button>
                  </div>
                )} */}
    </InvestedgeModal>
  );
}
