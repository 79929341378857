import React, { useState, useEffect } from "react";
import {
  GetIpsData,
  GetModelPortfolioProducts,
  PostEventLoggingData,
} from "../../../../actions/actions";
import { useDispatch } from "react-redux";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
import InvestedgeToggleButtonGroup from "../../../../components/toggle/InvestedgeToggle";
import CustomProposal from "./CustomProposal";
import SelectProposal from "./SelectProposal";
export default function PortfolioSecondStep({
  handleBack,
  handleNext,
  formValue,
  setFormValue,
  proposalType,
  setProposalType,
  portfolioId,
  setPortfolioId,
  handleModelPortfolioNext,
  setPortfolioProducts,
}) {
  const dispatch = useDispatch();
  const [faqs, setFaqs] = useState({
    raq1: 1,
    raq2: 1,
    raq3: 1,
    raq4: 1,
    raq5: 1,
    sumraq: 5,
  });
  const marks = [
    {
      value: 0,
      label: "Risk averse",
    },
    {
      value: 100,
      label: "Risk seeker",
    },
  ];
  const [riskText, setRiskText] = useState("Moderate");
  const [rsmodal, setrsmodal] = useState(false);
  const scheme = formValue.type_of_scheme;
  const [riskscore, setRiskscore] = useState(formValue.risk_score);
  const [horizon, setHorizon] = useState(
    proposalType === 0 ? formValue.investment_horizon : ""
  );
  const [modelHorizon, setModelHorizon] = useState(
    proposalType === 1 ? formValue.investment_horizon : ""
  );
  const [error, setError] = useState(null);
  const [errorModel, setErrorModel] = useState(null);
  useEffect(() => {
    if (riskscore >= 0 && riskscore <= 20) setRiskText("Conservative");
    if (riskscore > 20 && riskscore <= 40)
      setRiskText("Moderately Conservative");
    if (riskscore > 40 && riskscore <= 60) setRiskText("Moderate");
    if (riskscore > 60 && riskscore <= 80) setRiskText("Moderately Aggressive");
    if (riskscore > 80 && riskscore <= 100) setRiskText("Aggressive");
    horizon.length !== 0 &&
      dispatch(
        GetIpsData({
          params: {
            risk_score: riskscore,
            investment_horizon: parseInt(horizon),
          },
        })
      );
    // eslint-disable-next-line
  }, [riskscore]);
  const toggletabs = [
    { label: "Create Custom Proposal", value: 0 },
    { label: "Select from Model Portfolio", value: 1 },
  ];
  const handleChange = (e) => {
    const re = /^\d*\.?\d*$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setHorizon(e.target.value);

      e.target.value.length !== 0 &&
        dispatch(
          GetIpsData({
            params: {
              risk_score: riskscore,
              investment_horizon: parseInt(e.target.value),
            },
          })
        );
    }
  };
  const handletabchange = (event, newValue) => {
    if (newValue === 0 || newValue === 1) setProposalType(newValue);
  };
  return (
    <div className="px-8 rounded-[8px] w-full bg-white pb-8 mt-[16px] min-h-[calc(100vh_-_240px)]">
      <div className="flex justify-center h-full">
        <div className="w-[94%] pb-[0.75rem] flex justify-center pt-[3rem] h-full">
          <div
            className="portfolio-stepper-first-form-container"
            style={{
              width: "70%",
              borderRadius: "10px",
            }}
          >
            <div className="flex justify-between w-full">
              <h2 className="card-heading">Proposal Requirements</h2>
              <div className="flex">
                <InvestedgeButton
                  className="mr-4"
                  onClick={() => {
                    dispatch(
                      PostEventLoggingData({
                        module_name: "Portfolio Insights",
                        event_type: "Prev button",
                        url: window.location.href,
                        note: `Second Screen`,
                      })
                    );
                    handleBack();
                  }}
                >
                  Prev
                </InvestedgeButton>
                <InvestedgeButton
                  disabled={
                    proposalType === 0
                      ? !horizon
                      : portfolioId.length === 0 || !modelHorizon
                  }
                  onClick={() => {
                    setPortfolioProducts([]);
                    if (proposalType === 0 && horizon) {
                      setFormValue({
                        ...formValue,
                        risk_score: riskscore,
                        investment_horizon: parseFloat(horizon),
                        type_of_scheme: scheme,
                      });
                      dispatch(
                        PostEventLoggingData({
                          module_name: "Portfolio Insights",
                          event_type: "Next button",
                          url: window.location.href,
                          note: `Second Screen`,
                        })
                      );

                      handleNext();
                    } else if (
                      proposalType === 1 &&
                      portfolioId.length &&
                      modelHorizon
                    ) {
                      setFormValue({
                        ...formValue,
                        risk_score: riskscore,
                        investment_horizon: modelHorizon
                          ? parseFloat(modelHorizon)
                          : 0,
                        type_of_scheme: scheme,
                      });
                      dispatch(
                        GetModelPortfolioProducts(portfolioId, null, false, {})
                      );
                      handleModelPortfolioNext();
                    } else if (proposalType === 0)
                      setError("This field is required");
                    else if (proposalType === 1 && !modelHorizon)
                      setErrorModel("This field is required");
                  }}
                >
                  Next
                </InvestedgeButton>
              </div>
            </div>
            <div className="w-[100%] flex justify-center mt-6">
              <InvestedgeToggleButtonGroup
                tabs={toggletabs}
                value={proposalType}
                onChange={handletabchange}
                exclusive
              />
            </div>
            {proposalType === 0 ? (
              <CustomProposal
                riskText={riskText}
                riskscore={riskscore}
                setRiskscore={setRiskscore}
                horizon={horizon}
                marks={marks}
                rsmodal={rsmodal}
                setrsmodal={setrsmodal}
                faqs={faqs}
                setFaqs={setFaqs}
                error={error}
                setError={setError}
                handleChange={handleChange}
              />
            ) : (
              <SelectProposal
                portfolioId={portfolioId}
                setPortfolioId={setPortfolioId}
                modelHorizon={modelHorizon}
                setModelHorizon={setModelHorizon}
                errorModel={errorModel}
                setErrorModel={setErrorModel}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
