import { GET_MARKET_EXPERTS } from "../actions/type";
const initialState = {
  id: null,
  expert_name: "",
  type: "",
  published_date: null,
  flag: "",
  economy_flag: "",
  equities_flag: "",
  interest_rate_flag: "",
  inr_flag: "",
  gold_flag: "",
  crypto_flag: "",
};
export default function MarketExperts(state = initialState, action) {
  switch (action.type) {
    case GET_MARKET_EXPERTS:
      return action.payload;
    default:
      return state;
  }
}
