import React, { useRef } from "react";
import Thumb from "../../../../assets/img/purpleknob.svg";
import BlueThumb from "../../../../assets/img/blueknob.svg";
export default function PortfolioThirdStepSlider({ colors, width, setWidth }) {
  const getPercentage = (containerWidth, distanceMoved) => {
    return (distanceMoved / containerWidth) * 100;
  };
  const limitNumberWithinRange = (value, min, max) => {
    return Math.min(Math.max(value, min), max);
  };
  const nearestN = (N, number) => Math.ceil(number / N) * N;
  const onSliderSelect = (e, index) => {
    e.preventDefault();
    document.body.style.cursor = "pointer";
    const startDragX = e.pageX;
    const sliderWidth = TagSliderRef.current.offsetWidth;
    const resize = (e) => {
      e.preventDefault();
      const endDragX = e.touches ? e.touches[0].pageX : e.pageX;
      const distanceMoved = endDragX - startDragX;
      const maxPercent = width[index] + width[index + 1];

      const percentageMoved = nearestN(
        1,
        getPercentage(sliderWidth, distanceMoved)
      );

      const _widths = width.slice();

      const prevPercentage = _widths[index];

      const newPercentage = prevPercentage + percentageMoved;
      const currentSectionWidth = limitNumberWithinRange(
        newPercentage,
        0,
        maxPercent
      );
      _widths[index] = currentSectionWidth;

      const nextSectionIndex = index + 1;

      const nextSectionNewPercentage =
        _widths[nextSectionIndex] - percentageMoved;
      const nextSectionWidth = limitNumberWithinRange(
        nextSectionNewPercentage,
        0,
        maxPercent
      );
      _widths[nextSectionIndex] = nextSectionWidth;
      setWidth(_widths);
    };
    window.addEventListener("pointermove", resize);
    window.addEventListener("touchmove", resize);

    const removeEventListener = () => {
      window.removeEventListener("pointermove", resize);
      window.removeEventListener("touchmove", resize);
    };

    const handleEventUp = (e) => {
      e.preventDefault();
      document.body.style.cursor = "initial";
      removeEventListener();
    };

    window.addEventListener("touchend", handleEventUp);
    window.addEventListener("pointerup", handleEventUp);
  };
  const TagSliderRef = useRef(null);

  return width.length === 3 ? (
    <div
      className="w-[95%] flex justify-center items-center pt-[1rem]"
      ref={TagSliderRef}
    >
      <div
        className="portfolio-stepper-third-form-slider-section"
        style={{ background: colors[0], width: width[0] + "%", height: "4px" }}
      >
        <p className="absolute mb-[8px] flex justify-center items-center bottom-0 top-[20] left-[45%] z-[10] font-inter text-[12px] font-medium leading-[20px] tracking-[0em]">
          {`${width[0].toFixed(1)}%`}
        </p>
        <div
          onPointerDown={(e) => onSliderSelect(e, 0)}
          className="portfolio-stepper-third-form-slider-thumb"
        >
          <img src={BlueThumb} alt="err" className="w-[42px]" />
        </div>
      </div>
      <div
        className="portfolio-stepper-third-form-slider-section"
        style={{
          background: colors[1],
          width: width[1] + "%",
          height: "4px",
        }}
      >
        <p className="absolute mb-[8px] flex justify-center items-center bottom-0 top-[20] left-[45%] z-[10] font-inter text-[12px] font-medium leading-[20px] tracking-[0em]">
          {`${width[1].toFixed(1)}%`}
        </p>
        <div
          onPointerDown={(e) => onSliderSelect(e, 1)}
          className="portfolio-stepper-third-form-slider-thumb"
        >
          <img src={Thumb} alt="err" className="w-[42px]" />
        </div>
      </div>
      <div
        className="portfolio-stepper-third-form-slider-section"
        style={{ background: colors[2], width: width[2] + "%", height: "4px" }}
      >
        <p className="absolute mb-[8px] flex justify-center items-center bottom-0 top-[20] left-[45%] z-[10] font-inter text-[12px] font-medium leading-[20px] tracking-[0em]">
          {`${width[2].toFixed(1)}%`}
        </p>
        <div className="portfolio-stepper-third-form-slider-thumb">
          <img src={Thumb} alt="err" className="w-[42px]" />
        </div>
      </div>
    </div>
  ) : (
    <div
      className="w-[95%] flex justify-center items-center pt-[1rem]"
      ref={TagSliderRef}
    >
      <div
        className="portfolio-stepper-third-form-slider-section"
        style={{ background: colors[0], width: width[0] + "%", height: "4px" }}
      >
        <p className="absolute mb-[8px] flex justify-center items-center bottom-0 top-[20] left-[45%] z-[10] font-inter text-[12px] font-medium leading-[20px] tracking-[0em]">
          {`${width[0].toFixed(1)}%`}
        </p>
        <div
          onPointerDown={(e) => onSliderSelect(e, 0)}
          className="portfolio-stepper-third-form-slider-thumb"
        >
          <img src={BlueThumb} alt="err" className="w-[42px]" />
        </div>
      </div>
      <div
        className="portfolio-stepper-third-form-slider-section"
        style={{
          background: colors[1],
          width: width[1] + "%",
          height: "4px",
        }}
      >
        <p className="absolute mb-[8px] flex justify-center items-center bottom-0 top-[20] left-[45%] z-[10] font-inter text-[12px] font-medium leading-[20px] tracking-[0em]">
          {`${width[1].toFixed(1)}%`}
        </p>
        <div
          onPointerDown={(e) => onSliderSelect(e, 1)}
          className="portfolio-stepper-third-form-slider-thumb"
        >
          <img src={Thumb} alt="err" className="w-[42px]" />
        </div>
      </div>
    </div>
  );
}
