export const PortfolioInputSidebarData = [
  {
    title: "Mutual Funds",
    path: "mutualfundsscreen",
    value: "total_mutual_fund_value",
  },
  {
    title: "Stocks",
    path: "stocksscreen",
    locked: false,
    value: "total_stock_value",
  },
  {
    title: "Bonds/MLDs",
    path: "bondsscreen",
    locked: false,
    value: "total_bond_value",
  },
  {
    title: "PMS",
    path: "pmsscreen",
    locked: false,
    value: "total_pms_value",
  },
  {
    title: "AIFs",
    path: "aifsscreen",
    locked: false,
    value: "total_aif_value",
  },
  {
    title: "Cash",
    path: "cashscreen",
    locked: false,
    value: "total_cash_or_saving_ac_value",
  },
  {
    title: "Deposits",
    path: "depositsscreen",
    locked: false,
    value: "total_fixed_deposit_value",
  },
  {
    title: "Gold/Silver",
    path: "goldscreen",
    locked: false,
    value: "total_gold_value",
  },
  {
    title: "Offshore",
    path: "offshorescreen",
    locked: false,
    value: "total_offshore_value",
  },
  {
    title: "Real Estate",
    path: "estatescreen",
    locked: false,
    value: "total_real_estate_value",
  },
  {
    title: "Unlisted Shares",
    path: "unlistedscreen",
    locked: false,
    value: "total_other_value",
  },
  {
    title: "Others",
    path: "othersscreen",
    locked: true,
  },
];
