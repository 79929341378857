import React, { useState } from "react";
import WelcomeUpdateScreen from "./WelcomeUpdateScreen";
import UploadStatementScreen from "./UploadStatementScreen";
import UploadExcelScreen from "./UploadExcelScreen";
import MFscreen from "./MF/MFscreen";
import StocksScreen from "./Stocks/StocksScreen";
import Bondsscreen from "./Bonds/Bondsscreen";
import PMSscreen from "./PMS/PMSscreen";
import AIFscreen from "./AIF/AIFscreen";
import CashScreen from "./Cash/CashScreen";
import DepositsScreen from "./Deposits/DepositsScreen";
import GoldScreen from "./Gold/GoldScreen";
import EPFscreen from "./EPF/EPFscreen";
import PPFscreen from "./PPF/PPFscreen";
import UnlistedShareScreen from "./UnlistedShares/UnlistedShareScreen";
import OthersScreen from "./Others/OthersScreen";
import SavingsScreen from "./Savings/SavingsScreen";
import EstateScreen from "./Estate/EstateScreen";
import OffshoreScreen from "./Offshore/Offshorescreen";
import MFCentralStatement from "./MFCentralStatement";
import MFCentralStatementOtpScreen from "./MFCentralStatementOtpScreen";
import MFCentralResultScreen from "./MFCentralResultScreen";
export default function PortfolioInputRouter({ route, setRoute }) {
  const [statementtype, setStatementType] = useState("");
  switch (route) {
    case "welcomescreen":
      return (
        <WelcomeUpdateScreen
          setRoute={setRoute}
          setStatementType={setStatementType}
        />
      );

    case "uploadstatementscreen":
      return <UploadStatementScreen statementtype={statementtype} />;
    case "mfcentralscreen":
      return <MFCentralStatement setRoute={setRoute} />;
    case "mfcentralotpscreen":
      return <MFCentralStatementOtpScreen setRoute={setRoute} />;
    case "mfcentralresultscreen":
      return <MFCentralResultScreen setRoute={setRoute} />;
    case "uploadexcelscreen":
      return <UploadExcelScreen />;
    case "mutualfundsscreen":
      return <MFscreen />;
    case "stocksscreen":
      return <StocksScreen />;
    case "bondsscreen":
      return <Bondsscreen />;
    case "pmsscreen":
      return <PMSscreen />;
    case "aifsscreen":
      return <AIFscreen />;
    case "cashscreen":
      return <CashScreen />;
    case "depositsscreen":
      return <DepositsScreen />;
    case "goldscreen":
      return <GoldScreen />;
    case "epfscreen":
      return <EPFscreen />;
    case "ppfsscreen":
      return <PPFscreen />;
    case "unlistedscreen":
      return <UnlistedShareScreen />;
    case "offshorescreen":
      return <OffshoreScreen />;
    case "othersscreen":
      return <OthersScreen />;
    case "savingsscreen":
      return <SavingsScreen />;
    case "estatescreen":
      return <EstateScreen />;
    default:
      return null;
  }
}
