import React from "react";
import AfterloginApp from "../../AfterloginApp";
import { useState } from "react";
import CalculatorBanner from "../CalculatorBanner";
import BannerImage from "../../../assets/img/automated_switch_planner_banner.svg";
import BarsChart from "../../../components/charts/BarsChart";
import Dropdown from "../../../components/Dropdowns/DropdownWhiteSingle";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import { InputAdornment, OutlinedInput, Tooltip, Zoom } from "@mui/material";
import InvestedgeTextField from "../../../components/textfield/InvestedgeTextField";
import DropdownForAttributes from "../../../components/Dropdowns/DropdownForAttributes";
import TooltipIcon from "../../../assets/img/tooltip_icon.svg";
import urls from "../../../urls";
import axios from "axios";
export default function AutomatedSwitchPlanner() {
  const numToMonthMap = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };
  const dateParser = (date) => {
    var dd = String(date.getDate()).padStart(2, "0");
    var mm = String(date.getMonth() + 1).padStart(2, "0");
    var yyyy = date.getFullYear();
    return dd + " " + numToMonthMap[parseInt(mm)].slice(0, 3) + " " + yyyy;
  };
  const denotionParser = (denotion, value) => {
    if (denotion[2] === "K") return parseInt(value) * 1000;
    else if (denotion[2] === "L") return parseInt(value) * 100000;
    else return value * 10000000;
  };
  const [backendData, setBackendData] = useState({
    stp_required: 0,
    cashflows: {
      date: [],
      outflow: [],
      inflow: [],
    },
    value_post_transfer: 0,
    absolute_gain: 0,
    abs_return: 0,
    ann_return: 0,
    outflow_fund_value: 0,
    inflow_fund_value: 0,
  });
  const [planningData, setPlanningData] = useState({
    totalAmount: "10",
    totalAmountDenotion: "₹ L",
    frequency: "Monthly",
    installments: "12",
    transferorScheme: "8",
    transfereeScheme: "8",
  });
  const [frequency, setFrequency] = useState("Monthly");
  const handleCalculate = () => {
    axios
      .post(urls.automatedSwitchPlannerURL, {
        amount_to_be_transferred: denotionParser(
          planningData.totalAmountDenotion,
          parseInt(planningData.totalAmount)
        ),
        frequency_of_transfer: planningData.frequency,
        number_of_installments: parseInt(planningData.installments),
        outflow_scheme_ret: parseInt(planningData.transferorScheme / 100),
        inflow_scheme_ret: parseInt(planningData.transfereeScheme / 100),
      })
      .then((response) => setBackendData(response.data))
      .catch(() =>
        setBackendData({
          future_value_of_current_investments: 0.0,
          future_value_of_amount_required_date: "12-2023",
          future_value_of_amount_required: 0.0,
          future_value_of_current_investments_date: "12-2023",
          monthly_investment_required: 0.0,
          monthly_investment_required_date: "12-2023",
          graph: {
            dates: [],
            value1: {
              label: "Total Amount Invested",
              values: [],
            },
            value2: {
              label: "Expected Investment Value",
              values: [],
            },
          },
        })
      );
  };
  const data = {
    backgroundColor: "#fafafa",
    labels: backendData.cashflows.date,
    datasets: [
      {
        axis: "y",
        data: backendData.cashflows.outflow,
        backgroundColor: "#1982F8",
        borderColor: "#1982F8",
        borderWidth: 1,
        barThickness: 40,
        borderRadius: 300,
        fill: false,
        spanGaps: true,
        tension: 0.1,
      },
      {
        axis: "y",
        data: backendData.cashflows.inflow,
        backgroundColor: "#A9D5FF",
        borderColor: "#A9D5FF",
        borderWidth: 1,
        barThickness: 40,
        borderRadius: 300,
        fill: false,
        spanGaps: true,
        tension: 0.1,
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    cornerRadius: 20,
    legend: {
      display: false,
    },
    elements: {
      bar: {
        borderWidth: 3,
      },
      point: {
        radius: 0,
      },
    },
    scales: {
      yAxes: [
        {
          stepSize: 100,
          type: "linear",
          display: true,
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            autoSkip: true,
            maxTicksLimit: 4,
            maxRotation: 0,
            fontSize: 10,
            callback: (val) => dateParser(new Date(val)),
          },
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
    },
    plugins: {
      title: {
        display: true,
      },
      labels: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      points: {
        display: false,
      },

      interaction: {
        mode: "index",
        intersect: false,
      },
    },
  };
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Product Input"}
        description={
          "Change the view on different products across asset class, product type, and categories"
        }
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className="main-panel h-full">
            <div>
              <div className="h-[160px]">
                <CalculatorBanner
                  heading={"Automated Switch Planner (STP)"}
                  description={
                    "Use this calculator to plan how much one should transfer from one scheme to another to achieve return objectives."
                  }
                  image={BannerImage}
                />
              </div>
              <div className="portfolio-proposal-body-card min-h-[74%] flex justify-between mt-[1.5rem] border-none shadow-none">
                <div className="w-[49%] ">
                  <div className="w-full flex justify-between items-center mt-[0.5rem]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">
                        Total Amount to be Transferred (in ₹)
                      </p>
                    </div>
                    <div className="w-[30%] flex justify-between">
                      <div className="w-[40%]">
                        <DropdownForAttributes
                          labelName={null}
                          options={["₹ L", "₹ K", "₹ C"]}
                          selected={planningData}
                          setSelected={setPlanningData}
                          attribute="totalAmountDenotion"
                        />
                      </div>
                      <div className="w-[58%]">
                        <InvestedgeTextField
                          variant="outlined"
                          fullWidth
                          style={{
                            fontFamily: "Inter,sans-sarif",
                            fontWeight: 400,
                          }}
                          label={null}
                          value={planningData.totalAmount}
                          onChange={(e) => {
                            const re = /^\d*[0-9]+\d*$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            )
                              setPlanningData({
                                ...planningData,
                                totalAmount: e.target.value,
                              });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex justify-between items-center mt-[1.5rem]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">
                        Frequency of Transfer
                      </p>
                    </div>

                    <div className="w-[17.4%]">
                      <Dropdown
                        selected={frequency}
                        setSelected={setFrequency}
                        options={["Monthly", "Quarterly"]}
                        labelName={null}
                      />
                    </div>
                  </div>
                  <div className="w-full flex justify-between items-center mt-[1.5rem]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">
                        Number of Installments
                      </p>
                    </div>

                    <OutlinedInput
                      size="small"
                      className="text-sm w-[17.4%]"
                      value={planningData.installments}
                      onChange={(e) => {
                        const re = /^\d+$/;
                        if (
                          (e.target.value === "" || re.test(e.target.value)) &&
                          (e.target.value !== ""
                            ? parseInt(e.target.value) <= 36
                            : true)
                        )
                          setPlanningData({
                            ...planningData,
                            installments: e.target.value,
                          });
                      }}
                    />
                  </div>

                  <div className="w-full flex justify-between items-center mt-[1.5rem]">
                    <div className="flex items-center w-[70%]">
                      <div className="flex items-center">
                        <p className="font-inter text-sm">
                          Expected Return on Transferor Scheme
                        </p>
                        <Tooltip
                          TransitionComponent={Zoom}
                          placement="right"
                          title="Scheme from which the transfer (outflow) is happening."
                          arrow
                        >
                          <img
                            src={TooltipIcon}
                            alt="tooltip"
                            className="w-[16px] h-[16px] ml-2"
                          />
                        </Tooltip>
                      </div>
                    </div>
                    <OutlinedInput
                      size="small"
                      className="text-sm"
                      required
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "17.4%",
                      }}
                      value={planningData.transferorScheme}
                      onChange={(e) => {
                        const re = /^\d*\.?\d*$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setPlanningData({
                            ...planningData,
                            transferorScheme: e.target.value,
                          });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <span className="text-sm font-inter">%</span>
                        </InputAdornment>
                      }
                    />
                  </div>
                  <div className="w-full flex justify-between items-center mt-[1.5rem]">
                    <div className="flex items-center w-[70%]">
                      <div className="flex items-center">
                        <p className="font-inter text-sm">
                          Expected Return on Transferee Scheme
                        </p>
                        <Tooltip
                          TransitionComponent={Zoom}
                          placement="right"
                          title="Scheme to which the transfer (inflow) is happening"
                          arrow
                        >
                          <img
                            src={TooltipIcon}
                            alt="tooltip"
                            className="w-[16px] h-[16px] ml-2"
                          />
                        </Tooltip>
                      </div>
                    </div>
                    <OutlinedInput
                      size="small"
                      className="text-sm"
                      required
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "17.4%",
                      }}
                      value={planningData.transfereeScheme}
                      onChange={(e) => {
                        const re = /^\d*\.?\d*$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setPlanningData({
                            ...planningData,
                            transfereeScheme: e.target.value,
                          });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <span className="text-sm font-inter">%</span>
                        </InputAdornment>
                      }
                    />
                  </div>
                  <InvestedgeButton
                    className="mt-4"
                    onClick={handleCalculate}
                    disabled={
                      !planningData.totalAmount.length ||
                      !planningData.totalAmountDenotion.length ||
                      !planningData.frequency.length ||
                      !planningData.installments.length ||
                      !planningData.transferorScheme.length ||
                      !planningData.transfereeScheme.length
                    }
                  >
                    Calculate
                  </InvestedgeButton>
                </div>
                <div className="w-[45%] pl-[1rem]">
                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      <p className="font-inter text-[#6a6a76] mb-[4px]">
                        Value of investment Post Transfer
                      </p>
                      <p className="font-inter font-semibold text-[24px] text-[#3D3D3D] mb-[4px]">
                        {`₹ ${backendData.value_post_transfer.toLocaleString(
                          "en-IN"
                        )}`}
                      </p>
                      <p className="font-inter font-medium text-[#3D3D3D] mb-[4px]">
                        in Oct 2037
                      </p>
                    </div>
                    <div>
                      <p className="font-inter text-[#6a6a76] mb-[4px]">
                        Monthly Transfer Amount
                      </p>
                      <p className="font-inter font-semibold text-[24px] text-success-200 mb-[4px]">
                        {`₹ ${backendData.stp_required.toLocaleString(
                          "en-IN"
                        )}`}
                      </p>
                      <p className="font-inter font-medium text-[#3D3D3D] mb-[4px]">
                        in Oct 2037
                      </p>
                    </div>
                  </div>
                  <div className="w-[95%] bg-[#fafafa] p-[16px] mt-[16px]">
                    <BarsChart data={data} options={options} height={400} />
                    <div className="flex justify-center w-full mt-[12px]">
                      <div className="flex items-center ml-[16px]">
                        <div className="bg-primary-200 rounded w-[14px] h-[14px]" />
                        <h2 className="text-xs ml-[8px] mb-0">
                          Liquid Fund Investment
                        </h2>
                      </div>
                      <div className="flex items-center ml-[16px]">
                        <div className="bg-secondary-200 rounded w-[14px] h-[14px]" />
                        <h2 className="text-xs ml-[8px] mb-0">Equity Fund</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="portfolio-proposal-body-card min-h-[20%] mt-[1.5rem] border-none shadow-none">
                <h3 className="headingThree">Returns</h3>
                <div className="rounded-md mt-[1.5rem]">
                  <table className="w-full investedge-table">
                    <colgroup>
                      <col width="25%" />
                      <col width="25%" />
                      <col width="25%" />
                      <col width="25%" />
                    </colgroup>
                    <tbody>
                      <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                        <th className="pl-[1rem] font-medium text-left">
                          Amount after Transfer
                        </th>
                        <th className="text-center font-medium">
                          Liquid Fund Returns
                        </th>
                        <th className="text-center font-medium">
                          Equity Fund Returns
                        </th>
                        <th className="text-center font-medium">
                          Annualized Returns
                        </th>
                      </tr>
                      <td className="font-inter text-sm not-italic tracking-[0em] p-[1rem] text-left">
                        {`₹ ${backendData.value_post_transfer.toLocaleString(
                          "en-IN"
                        )}`}
                      </td>
                      <td className="font-inter text-sm not-italic tracking-[0em] p-[1rem] text-center">
                        {`₹ ${backendData.outflow_fund_value.toLocaleString(
                          "en-IN"
                        )}`}
                      </td>
                      <td className="font-inter text-sm not-italic tracking-[0em] p-[1rem] text-center">
                        {`₹ ${backendData.inflow_fund_value.toLocaleString(
                          "en-IN"
                        )}`}
                      </td>
                      <td className="font-inter text-sm not-italic tracking-[0em] p-[1rem] text-center">
                        {`₹ ${(backendData.ann_return * 100).toFixed(1)}`}
                      </td>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
