import React, { useState } from "react";
import Features from "./Features";
import HeroSection from "./HeroSection";
import Footer from "./footer";
import Header from "./header/Header";
export default function Home() {
  const [background, setBackground] = useState("transparent");
  return (
    <>
      <Header background={background} />
      <HeroSection setBackground={setBackground} />
      <Features setBackground={setBackground} />
      <Footer />
    </>
  );
}
