import React from "react";
import { IoIosClose } from "react-icons/io";
import { useSelector } from "react-redux";
import InvestedgeModal from "../../components/modal/InvestedgeModal";

export default function ReadOnlyIPS({ onClose, ipsmodal }) {
  const ipsData = useSelector(
    (state) => state.dashboardGetInvestment.ip_statement
  );
  const tableFields = {
    equity: {
      name: "Equity",
      target: (ipsData.equity.target * 100).toFixed(0),
      min: (ipsData.equity.min * 100).toFixed(0),
      max: (ipsData.equity.max * 100).toFixed(0),
    },
    debt: {
      name: "Debt",
      target: (ipsData.debt.target * 100).toFixed(0),
      min: (ipsData.debt.min * 100).toFixed(0),
      max: (ipsData.debt.max * 100).toFixed(0),
    },
    alternates: {
      name: "Alternates",
      target: (ipsData.alternate.target * 100).toFixed(0),
      min: (ipsData.alternate.min * 100).toFixed(0),
      max: (ipsData.alternate.max * 100).toFixed(0),
    },
    large_cap: {
      name: "Large cap",
      target: (ipsData.large_cap.target * 100).toFixed(0),
      min: (ipsData.large_cap.min * 100).toFixed(0),
      max: (ipsData.large_cap.max * 100).toFixed(0),
    },
    mid_cap: {
      name: "Mid cap",
      target: (ipsData.mid_cap.target * 100).toFixed(0),
      min: (ipsData.mid_cap.min * 100).toFixed(0),
      max: (ipsData.mid_cap.max * 100).toFixed(0),
    },
    small_cap: {
      name: "Small cap",
      target: (ipsData.small_cap.target * 100).toFixed(0),
      min: (ipsData.small_cap.min * 100).toFixed(0),
      max: (ipsData.small_cap.max * 100).toFixed(0),
    },

    credit_risk: {
      name: "Credit risk",
      target: (ipsData.aaa_equiv.target * 100).toFixed(0),
      min: (ipsData.aaa_equiv.min * 100).toFixed(0),
      max: (ipsData.aaa_equiv.max * 100).toFixed(0),
    },
    alternates_low_vol: {
      name: "Alternates - Low vol",
      target: (ipsData.alternates.target * 100).toFixed(0),
      min: (ipsData.alternates.min * 100).toFixed(0),
      max: (ipsData.alternates.max * 100).toFixed(0),
    },
  };
  const tableHeaders = ["Asset/rule", "Target", "Min", "Max"];
  return (
    <InvestedgeModal
      aria-labelledby="Read only Investment Policy Statement"
      aria-describedby="Read only Investment Policy Statement"
      sx={{ padding: "1.5rem" }}
      open={ipsmodal}
      handleclose={onClose}
    >
      <div className="flex justify-between" style={{ paddingTop: "0" }}>
        <h5 className="card-heading">Investment Policy Statement</h5>
        <IoIosClose size={30} onClick={onClose} />
      </div>
      <div className="modal-body ">
        <p className="mt-[8px] mb-[0px] font-inter text-neutralBlack text-sm leading-5">
          An investment policy statement (IPS) is a document drafted between a
          portfolio manager and a client that outlines general rules for the
          manager. Specific information on matters such as asset allocation,
          risk tolerance, and liquidity requirements are included in an
          investment policy statement.
        </p>
        <div className=" mt-3">
          <table className="w-full investedge-table">
            <colgroup>
              <col width="31%" />
              <col width="25%" />
              <col width="22%" />
              <col width="22%" />
            </colgroup>
            <tbody className="text-sm">
              <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                {tableHeaders.map((tableHeader, index) => (
                  // <th
                  //   className={
                  //     index ? " text-center" : " "
                  //   }
                  // >
                  <th
                    key={index}
                    className=""
                    style={index ? { textAlign: "center" } : {}}
                  >
                    {tableHeader}
                  </th>
                ))}
              </tr>

              {Object.keys(tableFields).map((key, index) => {
                return (
                  <tr className="border-bottom" key={index}>
                    <td>{tableFields[key].name}</td>
                    <td className="w-full flex justify-center">
                      {tableFields[key].target}
                      <span>%</span>
                    </td>
                    <td className="text-center">{tableFields[key].min}%</td>
                    <td className="text-center">{tableFields[key].max}%</td>
                  </tr>
                );
              })}
              <tr className="border-bottom">
                <td>Duration</td>
                <td className=" w-full flex justify-center">
                  {ipsData.duration.target}
                  <span>yrs</span>
                </td>

                <td>
                  <div className="w-full flex justify-center">
                    {ipsData.duration.min}
                    <span>yrs</span>
                  </div>
                </td>
                <td className="text-center">{`${ipsData.duration.max}yrs`}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </InvestedgeModal>
  );
}
