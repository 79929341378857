import React from "react";
import { ClientsidebarData } from "./ClientSidebarData";
import Clientsubmenu from "./ClientSubmenu";
import "../static/css/existing/dashboard_styles.css";
export default function Clientsidebar() {
  const pathnames = new Map();
  pathnames.set("Cashflows", {
    "/cashflow/redeem": true,
    "/cashflow/deploy": true,
  });
  // var element = document.getElementById("headerdashboard"),
  //   style = window.getComputedStyle(element),
  //   left = style.getPropertyValue("margin-left");
  return (
    <nav className="clientsidenavbar">
      <div className="bg-white h-full rounded-[0.5rem] p-4">
        {ClientsidebarData.map((item, index) => {
          return (
            <Clientsubmenu item={item} pathnames={pathnames} key={index} />
          );
        })}
      </div>
    </nav>
  );
}
