import React, { useEffect, useState } from "react";
import {
  GetIpsData,
  GetModelPortfolioProducts,
  GetSipIpsData,
  GetSipModelPortfolioProducts,
  PostEventLoggingData,
} from "../../../../actions/actions";
import { useDispatch } from "react-redux";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
import InvestedgeTabs from "../../../../components/tabs/InvestedgeTabs";
import Lumpsum from "./module/Lumpsum";
export default function PortfolioSecondStep({
  handleBack,
  handleNext,
  formValue,
  setFormValue,
  proposalType,
  setProposalType,
  portfolioId,
  setPortfolioId,
  handleModelPortfolioNext,
  setPortfolioProducts,
  sipProposalType,
  setSipProposalType,
  sipPortfolioId,
  setSipPortfolioId,
  setSipPortfolioProducts,
}) {
  const dispatch = useDispatch();
  const [riskscore, setRiskscore] = useState(formValue.risk_score);
  const [sipRiskscore, setSipRiskscore] = useState(
    formValue.sip_proposal_value.risk_score
  );
  const [schemes, setSchemes] = useState(formValue.schemes);
  const [sipSchemes, setSipSchemes] = useState(
    formValue.sip_proposal_value.schemes
  );
  const horizon = formValue.investment_horizon;

  // const handleChange = (e) => {
  //   const re = /^\d+$/;
  //   if (e.target.value === "" || re.test(e.target.value)) {
  //     setHorizon(e.target.value);

  //     e.target.value.length !== 0 &&
  //       dispatch(
  //         GetIpsData({
  //           params: {
  //             risk_score: riskscore,
  //             investment_horizon: parseInt(e.target.value),
  //           },
  //         })
  //       );
  //   }
  // };

  const tabs = [{ label: "Lumpsum" }, { label: "SIP" }];
  const [tabType, setTabType] = useState(0);
  const handlePptChange = (event, newValue) => {
    setTabType(newValue);
  };
  useEffect(() => {
    dispatch(
      GetIpsData({
        params: {
          risk_score: riskscore,
          investment_horizon: parseInt(horizon),
        },
      })
    );
    dispatch(
      GetSipIpsData({
        params: {
          risk_score: sipRiskscore,
          investment_horizon: parseInt(horizon),
        },
      })
    );
    //eslint-disable-next-line
  }, [dispatch]);

  return (
    <>
      <div className="rounded-[8px] w-full flex justify-between bg-white px-8 py-4 mt-[16px]">
        <h2 className="card-heading">Proposal Requirements</h2>
        <div className="flex">
          <InvestedgeButton
            className="mr-4"
            onClick={() => {
              dispatch(
                PostEventLoggingData({
                  module_name: "Portfolio Insights",
                  event_type: "Prev button",
                  url: window.location.href,
                  note: `Second Screen`,
                })
              );
              handleBack();
            }}
          >
            Prev
          </InvestedgeButton>
          <InvestedgeButton
            disabled={
              (proposalType === 1 && portfolioId.length === 0) ||
              (sipProposalType === 1 && sipPortfolioId.length === 0)
            }
            onClick={() => {
              setPortfolioProducts([]);
              setSipPortfolioProducts([]);
              setFormValue({
                ...formValue,
                risk_score: riskscore,
                investment_horizon: parseInt(horizon),
                schemes: schemes ? parseInt(schemes) : 0,
                sip_proposal_value: {
                  ...formValue.sip_proposal_value,
                  risk_score: sipRiskscore,
                  schemes: sipSchemes ? parseInt(sipSchemes) : 0,
                },
              });
              if (proposalType === 1)
                dispatch(
                  GetModelPortfolioProducts(portfolioId, null, false, {})
                );
              if (sipProposalType === 1)
                dispatch(GetSipModelPortfolioProducts(sipPortfolioId));

              if (proposalType === 1 && sipProposalType === 1) {
                handleModelPortfolioNext();
              } else {
                handleNext();
              }
              // if (proposalType === 0 && horizon) {
              //   setFormValue({
              //     ...formValue,
              //     risk_score: riskscore,
              //     investment_horizon: parseInt(horizon),
              //   });
              //   dispatch(
              //     PostEventLoggingData({
              //       module_name: "Portfolio Insights",
              //       event_type: "Next button",
              //       url: window.location.href,
              //       note: `Second Screen`,
              //     })
              //   );
              //   handleNext();
              // } else if (proposalType === 1 && portfolioId.length) {
              //   setFormValue({
              //     ...formValue,
              //     risk_score: riskscore,
              //   });
              //   dispatch(
              //     GetModelPortfolioProducts(portfolioId, null, false, {})
              //   );
              //   handleModelPortfolioNext();
              // }
            }}
          >
            Next
          </InvestedgeButton>
        </div>
      </div>
      <div className="rounded-[8px] w-full bg-white px-8 py-4 mt-[16px]">
        <InvestedgeTabs
          tabs={tabs}
          tabvalue={tabType}
          handletabchange={handlePptChange}
        />
      </div>
      <Lumpsum
        tabType={tabType}
        proposalType={tabType === 0 ? proposalType : sipProposalType}
        setProposalType={tabType === 0 ? setProposalType : setSipProposalType}
        riskscore={tabType === 0 ? riskscore : sipRiskscore}
        setRiskscore={tabType === 0 ? setRiskscore : setSipRiskscore}
        horizon={horizon}
        portfolioId={tabType === 0 ? portfolioId : sipPortfolioId}
        setPortfolioId={tabType === 0 ? setPortfolioId : setSipPortfolioId}
        schemes={tabType === 0 ? schemes : sipSchemes}
        setSchemes={tabType === 0 ? setSchemes : setSipSchemes}
      />
    </>
  );
}
