import React, { useEffect, useRef } from "react";
import Add from "../../assets/img/circle_over_plus_blue.svg";
import PMSrow from "./PMSrow";
import { useState } from "react";
import urls from "../../urls";
import { useDispatch, useSelector } from "react-redux";
import {
  getComparisonData,
  GetDashboardPmsPortfolio,
  GetDashboardPortfolioList,
  OpenSnackbar,
} from "../../actions/actions";
import { Bars } from "react-loader-spinner";
import ClickAwayListener from "react-click-away-listener";
import axios from "axios";
import InvestedgeTextField from "../../components/textfield/InvestedgeTextField";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import InvestedgePopper from "../../components/popper/InvestedgePopper";
import { MenuItem } from "@mui/material";
export default function PMSscreen() {
  const data = useSelector((store) => store.dashboardPmsPortfolio);
  const [aifarray, setAifarray] = useState(
    JSON.parse(JSON.stringify(data)).map((y) => {
      return { ...y, purchase_date: new Date(y.purchase_date) };
    })
  );
  const [patchAifArray, setPatchAifArray] = useState([]);
  const [postAifArray, setPostAifArray] = useState([]);
  const [submitActive, setSubmitActive] = useState(false);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const productsData = useSelector((state) => state.getComparisonData);
  const portfolioList = useSelector(
    (store) => store.dashboardPortfolioPriceList
  );
  const removeAifarray = (i) => {
    let newAifarray = [...aifarray];
    newAifarray.splice(i, 1);
    setAifarray(newAifarray);
  };
  // const portfolioValue = useSelector(
  //   (store) => store.portfolioReviewGetSumary.portfolio_value
  // );
  // const parsePortfolio = (portfolio) => {
  //   if (portfolio / 1000 < 100)
  //     return `₹ ${parseFloat(portfolio / 1000).toFixed(1)} K`;
  //   if (portfolio / 100000 < 100)
  //     return `₹ ${parseFloat(portfolio / 100000).toFixed(1)} L`;
  //   return `₹ ${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  // };
  const [searchBar, setSearchBar] = useState(false);
  const [addedProduct, setAddedProduct] = useState("");
  const [newAif, setNewAif] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const popperRef = useRef(null);
  const [deleteArray, setDeleteArray] = useState([]);
  useEffect(() => {
    dispatch(
      GetDashboardPmsPortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setAifarray(data);
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    newAif.length >= 3 &&
      dispatch(getComparisonData({ params: { name: newAif, type: "PMS" } }));
    if (newAif.length >= 3) setAnchorEl(popperRef.current);
    else setAnchorEl(null);
    // eslint-disable-next-line
  }, [newAif]);
  useEffect(() => {
    if (deleteArray.length || postAifArray.length || patchAifArray.length)
      setSubmitActive(true);
    else setSubmitActive(false);
  }, [deleteArray, postAifArray, patchAifArray]);

  const handleSubmit = async () => {
    setloading(true);
    let newPatchList = patchAifArray.map((item) => {
      var dd = String(item.purchase_date.getDate()).padStart(2, "0");
      var mm = String(item.purchase_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.purchase_date.getFullYear();
      return {
        id: item.id,
        user_id: localStorage.getItem("clientId"),
        pms_id: item.pms_id,
        purchase_date: yyyy + "-" + mm + "-" + dd,
        count_units: parseFloat(item.count_units),
        purchase_price: parseFloat(item.purchase_price),
        current_price: parseFloat(item.current_price),
      };
    });
    let newPostList = postAifArray.map((item) => {
      var dd = String(item.purchase_date.getDate()).padStart(2, "0");
      var mm = String(item.purchase_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.purchase_date.getFullYear();
      return {
        pms_id: item.pms_id,
        user_id: localStorage.getItem("clientId"),
        count_units: parseFloat(item.count_units),
        purchase_date: yyyy + "-" + mm + "-" + dd,
        purchase_price: parseFloat(item.purchase_price),
        current_price: parseFloat(item.current_price),
      };
    });
    let newDeleteList = deleteArray.map((item) => {
      return {
        id: item.id,
        user_id: localStorage.getItem("clientId"),
        pms_id: item.pms_id,
        count_units: parseFloat(item.count_units),
        purchase_price: parseFloat(item.purchase_price),
        current_price: parseFloat(item.current_price),
      };
    });
    newDeleteList.length &&
      (await axios.post(urls.dashboardPmsPortfolioDeleteURL, newDeleteList));
    newPostList.length &&
      (await axios.post(urls.dashboardPmsPortfolioURL, newPostList));
    newPatchList.length &&
      (await axios.patch(urls.dashboardPmsPortfolioURL, newPatchList));
    setPatchAifArray([]);
    setPostAifArray([]);
    setDeleteArray([]);
    dispatch(
      GetDashboardPortfolioList({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      GetDashboardPmsPortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      OpenSnackbar({
        severity: "success",
        message: "Portfolio Updated Successfully",
      })
    );
    setloading(false);
  };
  return (
    <div className="w-full">
      <div className="font-medium font-inter text-[18px] text-neutralBlack">
        Portfolio Management Service - {portfolioList.total_pms_value}
      </div>
      {!loading ? (
        <>
          <div className="flex justify-between w-[96%] mt-[4px] mb-0">
            <div className="flex justify-center items-center h-[56px] cursor-pointer">
              <img src={Add} alt="err" />
              <p
                className="text-primary-200 text-[14px] font-medium mb-0 ml-[0.3rem]"
                onClick={
                  () => setSearchBar(true)
                  // let newAifarray = [...aifarray];
                  // newAifarray.unshift({ name: "New Mutual Fund", price: "0" });
                  // setAifarray(newAifarray);
                }
              >
                Add PMS
              </p>
              {searchBar && (
                <div className="w-[200px] select-none ml-[16px]">
                  <InvestedgeTextField
                    forwardedref={popperRef}
                    sx={{
                      fontFamily: "Inter,sans-sarif",
                      fontWeight: 400,
                      width: "200px",
                    }}
                    label="Search PMS"
                    type="text"
                    value={newAif}
                    onChange={(e) => setNewAif(e.target.value)}
                  />
                  <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <InvestedgePopper
                      id="mf-client-input-popper"
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      placement="bottom-start"
                      className="z-[100000] h-[300px]"
                    >
                      {productsData[0] === "loading" ? (
                        <div className="h-[300px] flex justify-center items-center w-[200px]">
                          <Bars color="#1982f8" height="60" width="60" />
                        </div>
                      ) : productsData.length ? (
                        <div className="h-[300px] productunderstading-scrollablediv overflow-auto">
                          {productsData.map((option, ind) => (
                            <MenuItem
                              key={ind}
                              onClick={() => {
                                setSearchBar(false);
                                const rnd = Math.random();
                                setAddedProduct(rnd);
                                let newAifarray = [...aifarray];
                                newAifarray.unshift({
                                  id: rnd,
                                  pms_id: option.product_id,
                                  name: option.name,
                                  count_units: 1,
                                  purchase_date: new Date(),
                                  purchase_price: 1,
                                  current_price: option.nav,
                                  current_price_date: new Date(),
                                });
                                setPostAifArray([
                                  ...postAifArray,
                                  {
                                    id: rnd,
                                    pms_id: option.product_id,
                                    name: option.name,
                                    count_units: 1,
                                    purchase_date: new Date(),
                                    purchase_price: 1,
                                    current_price: option.nav,
                                    current_price_date: new Date(),
                                  },
                                ]);
                                setAifarray(newAifarray);
                                setAnchorEl(null);
                              }}
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                        </div>
                      ) : (
                        <div className="h-[300px] flex items-center justify-center w-[200px]">
                          No data found
                        </div>
                      )}
                    </InvestedgePopper>
                  </ClickAwayListener>
                </div>
              )}
            </div>
            {/* <p className="asset-handler-text" onClick={() => setAifarray([])}>
          Clear All
        </p> */}
          </div>
          <div className="update-portfolio-asset-row-container  h-[63vh]">
            <table className="w-full">
              <tbody className="w-full">
                {aifarray.map((fund, index) => (
                  <PMSrow
                    key={fund.id}
                    fund={fund}
                    id={index}
                    removeAifarray={removeAifarray}
                    aifarray={aifarray}
                    setAifarray={setAifarray}
                    patchAifArray={patchAifArray}
                    setPatchAifArray={setPatchAifArray}
                    deleteArray={deleteArray}
                    setDeleteArray={setDeleteArray}
                    data={data}
                    postAifArray={postAifArray}
                    setPostAifArray={setPostAifArray}
                    addedProduct={addedProduct}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="flex flex-col justify-center items-center w-[96%] h-[62vh]">
          <Bars color="#1982F8" />
          <p className=" font-inter text-[14px] font-medium text-neutralBlack mb-[1rem] mt-[1rem]">
            Updating Portfolio ...
          </p>
        </div>
      )}
      <div className="flex w-full mt-[24px] pr-[24px] justify-end">
        <InvestedgeButton
          disabled={!submitActive}
          onClick={() => {
            if (
              patchAifArray.length ||
              postAifArray.length ||
              deleteArray.length
            ) {
              setSubmitActive(false);
              handleSubmit();
            }
          }}
        >
          Submit
        </InvestedgeButton>
      </div>
    </div>
  );
}
