import { GET_SETTINGS_OPERATIONS_ARRAY } from "../actions/type";
const initialState = [];
export default function GetSettingsOperationsArray(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_SETTINGS_OPERATIONS_ARRAY:
      return action.payload;
    default:
      return state;
  }
}
