import { Radio } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import urls from "../../urls";
import Back from "../../assets/img/back_protected_arrow.svg";
import Copy from "../../assets/img/copytoclipboard.svg";
import AfterloginApp from "../AfterloginApp";
import { Link } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { OpenSnackbar } from "../../actions/actions";
import { useDispatch } from "react-redux";

export default function AcquireClients() {
  const [type, setType] = useState("mfcentral");
  const [result, setResult] = useState({});
  const dispatch = useDispatch();

  const handleClick = () => {
    setResult({ loading: true });
    axios
      .post(`${urls.acquireClientsGenerateLinkURL}${type}/generate/`)
      .then((response) => setResult(response.data))
      .catch(() => setResult({}));
  };
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        description={
          "This is your dashboard. Access all of your latest updates here."
        }
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className="main-panel">
            <div className="portfolio-proposal-body-card min-h-[calc(100vh_-_116px)] shadow-none border-none relative">
              <Link to="/instant-review/dashboard">
                <div className="flex space-x-3 text-sm text-primary-200 font-medium">
                  <img src={Back} alt="err" />
                  <p> Back to Dashboard</p>
                </div>
              </Link>
              <h2 className="card-heading mt-6">Instant Review</h2>
              <p className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0rem] text-left">
                Please Select Investment Products to see how Portfolio Analytics
                changes. Check and add what suits best for the client.
              </p>
              <div className="space-y-3 mt-6">
                <div className="font-inter text-sm text-[#3D3D3D] flex flex-col font-medium">
                  <span>Select Return Type</span>
                </div>
                <div className="flex space-x-6">
                  <div className="space-x-2 flex items-center">
                    <Radio
                      checked={type === "mfcentral"}
                      value={"mfcentral"}
                      name="type"
                      onChange={(event) => {
                        event.stopPropagation();
                        setType("mfcentral");
                      }}
                      // disabled={index === 1}
                      sx={{
                        padding: 0,
                        "& .MuiSvgIcon-root": {
                          fontSize: 20,
                        },
                      }}
                    />
                    <span className="font-inter-regular text-sm">
                      MF Central
                    </span>
                  </div>
                  <div className="space-x-2 flex items-center">
                    <Radio
                      checked={type === "cams"}
                      value={"cams"}
                      name="type"
                      onChange={(event) => {
                        event.stopPropagation();
                        setType("cams");
                      }}
                      // disabled={index === 1}
                      sx={{
                        padding: 0,
                        "& .MuiSvgIcon-root": {
                          fontSize: 20,
                        },
                      }}
                    />
                    <span className="font-inter-regular text-sm">CAMS CAS</span>
                  </div>
                  {/* <div className="space-x-2 flex items-center">
                    <Radio
                      checked={type === "cdsl"}
                      value={"cdsl"}
                      name="type"
                      onChange={(event) => {
                        event.stopPropagation();
                        setType("cdsl");
                      }}
                      // disabled={index === 1}
                      sx={{
                        padding: 0,
                        "& .MuiSvgIcon-root": {
                          fontSize: 20,
                        },
                      }}
                    />
                    <span className="font-inter-regular text-sm">CDSL CAS</span>
                  </div>
                  <div className="space-x-2 flex items-center">
                    <Radio
                      checked={type === "nsdl"}
                      value={"nsdl"}
                      name="type"
                      onChange={(event) => {
                        event.stopPropagation();
                        setType("nsdl");
                      }}
                      // disabled={index === 1}
                      sx={{
                        padding: 0,
                        "& .MuiSvgIcon-root": {
                          fontSize: 20,
                        },
                      }}
                    />
                    <span className="font-inter-regular text-sm">NSDL CAS</span>
                  </div> */}
                </div>
              </div>
              <InvestedgeButton
                sx={{ marginTop: "1.5rem" }}
                onClick={handleClick}
                disabled={result.loading}
              >
                {result.loading ? "Generating link" : "Generate Link"}
              </InvestedgeButton>
              {result.shared_link ? (
                <div className="mt-20 space-y-6">
                  <div className="font-inter text-sm text-[#3D3D3D] flex font-medium space-x-3">
                    <span>Link Generated</span>
                    <CopyToClipboard
                      text={`${window.location.origin}/instant-review/${result.shared_link}/${type}`}
                      onCopy={() =>
                        dispatch(
                          OpenSnackbar({
                            severity: "success",
                            message: "Copied Successfully to Clipboard",
                          })
                        )
                      }
                    >
                      <img src={Copy} alt="err" className="cursor-pointer" />
                    </CopyToClipboard>
                  </div>
                  <div className="max-w-full">
                    <a
                      href={`${window.location.origin}/instant-review/${result.shared_link}/${type}`}
                      target="_blank"
                      rel="noreferrer"
                      className="hover:text-primary-200 text-primary-200 decoration-solid text-sm max-w-full break-all"
                    >
                      {`${window.location.origin}/instant-review/${result.shared_link}/${type}`}
                    </a>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
