import React, { useEffect } from "react";
import Actionable from "./Actionable";
// import BuyList from "./BuyList";
// import SellList from "./SellList";
import {
  PostEventLoggingData,
  ResetReviewPortfolioActionable,
} from "../../../../actions/actions";
import { useDispatch, useSelector } from "react-redux";
export default function PortfolioSecondStep({
  handleBack,
  handleNext,
  formValue,
  portfolioProducts,
  setPortfolioProducts,
  portfolio,
  setPortfolio,
  portfolioActions,
  setPortfolioActions,
  clientId,
}) {
  // const [listtype, setListtype] = useState("actionable");
  const listtype = "actionable";
  const dispatch = useDispatch();
  const preferenceData = useSelector(
    (state) => state.portfolioReviewGetActionable
  );
  const analytics = useSelector(
    (state) => state.portfolioReviewGetFinalAnalytics
  );
  var portfolioSum = 0;
  portfolioProducts.forEach((value) => {
    portfolioSum += parseFloat(value.proposed_weight);
  });
  const handleSections = () => {
    switch (listtype) {
      case "actionable":
        return (
          <Actionable
            formValue={formValue}
            portfolioProducts={portfolioProducts}
            setPortfolioProducts={setPortfolioProducts}
            portfolio={portfolio}
            setPortfolio={setPortfolio}
            portfolioActions={portfolioActions}
            setPortfolioActions={setPortfolioActions}
            clientId={clientId}
          />
        );

      // case "buylist":
      //   return <BuyList portfolioProducts={portfolioProducts} />;

      // case "selllist":
      //   return <SellList portfolioProducts={portfolioProducts} />;

      default:
        console.log(listtype);
    }
  };
  useEffect(() => {
    dispatch(
      PostEventLoggingData({
        module_name: "Portfolio Review",
        event_type: "Information",
        url: window.location.href,
        note: `Checked out ${listtype}`,
      })
    ); // eslint-disable-next-line
  }, [listtype]);
  return (
    <>
      <div className="px-8 rounded-[8px] w-full bg-white py-8 mt-[16px]  border ">
        <div className="flex justify-between w-full">
          <h2 className=" font-inter text-[24px] font-medium leading-[20px] tracking-[0em] text-center align-middle leading-0">
            Portfolio Observations
          </h2>
          <div className="flex">
            <button
              className="primary-button text-sm mr-[1rem] font-semibold py-2 px-3 rounded  shadow-boxShadowButton normal-case active:opacity-[0.8] focus:outline-none"
              onClick={() => {
                dispatch(
                  PostEventLoggingData({
                    module_name: "Portfolio Review",
                    event_type: "Prev Button",
                    url: window.location.href,
                    note: `Fourth screen`,
                  })
                );
                handleBack();
              }}
            >
              Prev
            </button>
            <button
              className={
                false
                  ? " bg-primaryDisabledBgClr text-primaryDisabledClr font-semibold py-2 px-3 text-sm shadow-boxShadowButton normal-case active:opacity-[0.8] focus:outline-none"
                  : "primary-button font-semibold py-2 px-3 text-sm shadow-boxShadowButton normal-case active:opacity-[0.8] focus:outline-none"
              }
              onClick={() => {
                // if (horizon) {
                //   setFormValue({
                //     ...formValue,
                //     risk_score: riskscore,
                //     investment_horizon: parseInt(horizon),
                //     type_of_scheme: scheme,
                //   });
                //   handleNext();
                // } else setError("This field is required");

                true && handleNext();
                Math.round(portfolioSum * 100 * 10) / 10 === 100 &&
                  !preferenceData.loading &&
                  !analytics.loading &&
                  dispatch(ResetReviewPortfolioActionable());
                Math.round(portfolioSum * 100 * 10) / 10 === 100 &&
                  !preferenceData.loading &&
                  !analytics.loading &&
                  dispatch(
                    PostEventLoggingData({
                      module_name: "Portfolio Review",
                      event_type: "Next Button",
                      url: window.location.href,
                      note: `Fourth screen`,
                    })
                  );
              }}
            >
              Next
            </button>
          </div>
        </div>
        {/* <h2 className="font-medium text-sm mb-[16px]">IPS</h2>
        <h2 className="font-medium text-sm mb-[16px]">
          Overweight / underweight comments​ Underperforming schemes comments ​
        </h2>
        <h2 className="font-medium text-sm">
          Taking the above in account and basis your constraints and
          requirements, Following is the actionable plan​
        </h2> */}
      </div>

      {handleSections(listtype)}
    </>
  );
}
