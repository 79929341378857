import React from "react";
import BarsChart from "../../../../components/charts/BarsChart";
import ProgressBar from "./Progressbar";
export default function EquitySection({ analytics }) {
  const bgGradients = [
    "linear-gradient(223.78deg, #09C3E7 0%, #7669F0 106.87%)",
    "linear-gradient(225deg, #9C2979 0%, #F15E75 100%)",
    "linear-gradient(108.81deg, #FF9F38 -27.65%, #FE5C44 125%)",
    "linear-gradient(108.81deg, #FFD166 -27.65%, #C26F0D 125%)",
  ];
  const keys = [];
  const values = [];
  let maxStock = 0;
  for (const property in analytics.top5_stocks) {
    keys.push(property);
    values.push((analytics.top5_stocks[property] * 100).toFixed(1));
    maxStock = Math.max(maxStock, analytics.top5_stocks[property] * 100);
  }
  const sectorkeys = [];
  const sectorvalues1 = [];
  const sectorvalues2 = [];
  for (const property in analytics.top5_sector) {
    sectorkeys.push(property);
    sectorvalues1.push((analytics.top5_sector[property][0] * 100).toFixed(1));
    sectorvalues2.push((analytics.top5_sector[property][1] * 100).toFixed(1));
  }
  // const displayingHorizontalChartLabels = (value) => {
  //   return value.length > 4 ? `${value.slice(0, 4)}..` : value.slice(0, 4);
  // };
  const marketData = {
    labels: ["Large Cap", "Mid Cap", "Small Cap"],

    datasets: [
      {
        label: "Equity Portfolio",
        data: [
          analytics.market_cap["Large Cap"] &&
            (analytics.market_cap["Large Cap"][0] * 100).toFixed(1),
          analytics.market_cap["Mid Cap"] &&
            (analytics.market_cap["Mid Cap"][0] * 100).toFixed(1),
          analytics.market_cap["Small Cap"] &&
            (analytics.market_cap["Small Cap"][0] * 100).toFixed(1),
        ],
        backgroundColor: "#119D0E",
        borderColor: "#119D0E",
      },
      {
        label: "NSE 500",
        data: [
          analytics.market_cap["Large Cap"] &&
            (analytics.market_cap["Large Cap"][1] * 100).toFixed(1),
          analytics.market_cap["Mid Cap"] &&
            (analytics.market_cap["Mid Cap"][1] * 100).toFixed(1),
          analytics.market_cap["Small Cap"] &&
            (analytics.market_cap["Small Cap"][1] * 100).toFixed(1),
        ],
        backgroundColor: "#5675E5",
        borderColor: "#5675E5",
      },
    ],
  };
  const sectorsData = {
    labels: sectorkeys.map((sector) =>
      sector.length > 9 ? `${sector.slice(0, 9)}..` : sector.slice(0, 9)
    ),
    datasets: [
      {
        label: "Equity Portfolio",
        data: sectorvalues1,
        backgroundColor: "#ED6D85",
        borderColor: "#ED6D85",
      },
      {
        label: "NSE 500",
        data: sectorvalues2,
        backgroundColor: "#56A1E5",
        borderColor: "#56A1E5",
      },
    ],
  };
  const marketOptions = {
    maintainAspectRatio: false,
    cornerRadius: 20,
    legend: {
      display: false,
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 30,
        bottom: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
    plugins: {
      labels: {
        // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
        render: "value",
        fontColor: "#000",
        position: "default",
        outsidePadding: 14,
        textMargin: 3,
      },
      datalabels: {
        display: false,
      },
    },
  };
  const barOptions = {
    maintainAspectRatio: false,
    cornerRadius: 20,
    legend: {
      display: false,
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 30,
        bottom: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
    plugins: {
      labels: {
        // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
        render: "value",
        fontColor: "#000",
        position: "default",
        outsidePadding: 14,
        textMargin: 3,
      },
      datalabels: {
        display: false,
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var currentValue = dataset.data[tooltipItem.index];
          return `${sectorkeys[tooltipItem.index]}: ${currentValue} `;
        },
        title: function (tooltipItem) {
          return sectorkeys[tooltipItem.index];
        },
      },
    },
  };
  // const productAllocationData = {
  //   labels: keys,
  //   datasets: [
  //     {
  //       backgroundColor: [
  //         "#ED6D85",
  //         "#56A1E5",
  //         "#F7CE6B",
  //         "#F1A354",
  //         "#6CBDBF",
  //         "#FFA3B9",
  //       ],
  //       borderColor: [
  //         "#ED6D85",
  //         "#56A1E5",
  //         "#F7CE6B",
  //         "#F1A354",
  //         "#6CBDBF",
  //         "#FFA3B9",
  //       ],
  //       borderWidth: 1,
  //       barThickness: 20,
  //       data: values,
  //       borderRadius: 10,
  //       minBarLength: 40,
  //     },
  //   ],
  // };
  // const horizontalBarOptions = {
  //   maintainAspectRatio: false,
  //   cornerRadius: 20,
  //   legend: {
  //     display: false,
  //   },
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           callback: (value) => {
  //             return displayingHorizontalChartLabels(value);
  //           },
  //           fontColor: "#fff",
  //           beginAtZero: true,
  //           mirror: true,
  //           padding: -8,
  //           z: 8,
  //         },
  //         gridLines: {
  //           display: false,
  //         },
  //         offset: true,
  //         alignToPixels: true,
  //       },
  //     ],
  //     xAxes: [
  //       {
  //         ticks: {
  //           beginAtZero: true,
  //           display: false,
  //           max: maxStock + 0.5,
  //         },
  //         gridLines: {
  //           display: false,
  //         },
  //       },
  //     ],
  //   },
  //   plugins: {
  //     datalabels: {
  //       display: true,
  //       color: "#323232",
  //       align: "end",
  //       anchor: "end",
  //       font: { size: "12" },
  //       clamp: true,
  //     },
  //   },
  // };
  const LegendItem = ({ color, text }) => {
    return (
      <div className="flex">
        <div className={`bg-[${color}] w-[7px] h-[7px] block mt-[7px] `} />
        <p className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] ml-[0.2rem] mb-0">
          {text}
        </p>
      </div>
    );
  };
  return (
    <>
      <div className=" flex pt-[1rem] justify-between">
        <div className=" w-[49.5%] h-[265px]">
          <h4 className=" font-inter text-[14px] font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack">
            Market Cap (in %)
          </h4>
          <div className="bg-[#FAFAFA] w-[100%] p-[1rem] min-h-full">
            <BarsChart
              data={marketData}
              options={marketOptions}
              width={200}
              height={200}
            />
            <div className=" flex justify-evenly w-full z-[100] pt-[0.4rem]">
              <LegendItem color={"#119D0E"} text={"Equity Portfolio"} />
              <LegendItem color={"#5675E5"} text={"NSE 500"} />
            </div>
          </div>
        </div>
        <div className="w-[49.59%] h-[265px]">
          <h4 className="font-inter text-[14px] font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack">
            Top 5 stocks (in %)
          </h4>
          <div className="bg-[#FAFAFA] w-[100%] p-[1rem] min-h-full">
            {keys.map((sector, index) => (
              <ProgressBar
                key={index}
                bg={bgGradients[index % bgGradients.length]}
                progress={values[index]}
                label={sector}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="w-full h-[275px] mt-[3rem]">
        <div className="flex w-full justify-between">
          <h4 className=" font-inter text-[14px] font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack">
            Top 5 sectors (in %)
          </h4>
          <div className="flex">
            <div className="flex items-center ml-[16px]">
              <div className="bg-[#ED6D84] rounded w-[14px] h-[14px]" />
              <h2 className="text-xs ml-[8px] mb-0">Equity Portfolio</h2>
            </div>
            <div className="flex items-center ml-[16px]">
              <div className="bg-[#56A1E5] rounded w-[14px] h-[14px]" />
              <h2 className="text-xs ml-[8px] mb-0">NSE 500</h2>
            </div>
          </div>
        </div>
        <div className="bg-[#FAFAFA] w-[100%] p-[1rem] min-h-full mt-[0.5rem]">
          <BarsChart
            data={sectorsData}
            options={barOptions}
            width={220}
            height={220}
          />
        </div>
      </div>
    </>
  );
}
