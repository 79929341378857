import { POST_RESEARCH_ASSETS } from "../actions/type";
const initialState = {
  gen_comment_on_profile: null,
  data_summary: [
    { field: "", opt_allocation: 0, target: 0, rationale: "" },
    { field: "", opt_allocation: 0, target: 0, rationale: "" },
    { field: "", opt_allocation: 0, target: 0, rationale: "" },
    { field: "", opt_allocation: 0, target: 0, rationale: "" },
    { field: "", opt_allocation: 0, target: 0, rationale: "" },
    { field: "", opt_allocation: 0, target: 0, rationale: "" },
    { field: "", opt_allocation: 0, target: 0, rationale: "" },
    { field: "", opt_allocation: 0, target: 0, rationale: "" },
    { field: "", opt_allocation: 0, target: 0, rationale: "" },
  ],
};
export default function PostResearchAssets(state = initialState, action) {
  switch (action.type) {
    case POST_RESEARCH_ASSETS:
      return action.payload;
    default:
      return state;
  }
}
