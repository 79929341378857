import { POST_DASHBOARD_PORTFOLIO_SUMMARY } from "../actions/type";
const initialState = { pdf: null };
export default function GetDashboardPortfolioSummary(
  state = initialState,
  action
) {
  switch (action.type) {
    case POST_DASHBOARD_PORTFOLIO_SUMMARY:
      return action.payload;
    default:
      return state;
  }
}
