import React from "react";
import { useState } from "react";
import ArrowLeft from "../../assets/img/left_arrow_icon.svg";
import ArrowDown from "../../assets/img/down_arrow_icon.svg";
// import EditAsset from "../EditAsset";
import DropdownAsset from "../DropdownAsset";
import TextField from "@mui/material/TextField";

export default function SavingsRow({
  fund,
  id,
  removesavingsarray,
  savingsarray,
  setsavingsarray,
}) {
  const [open, setOpen] = useState(fund.name === "New Scheme" ? true : false);
  const [asset, setAsset] = useState("Monthly");
  // let handleChange = (i, e) => {
  //   let newsavingsarray = [...savingsarray];
  //   newsavingsarray[i][e.target.name] = e.target.value;
  //   setsavingsarray(newsavingsarray);
  // };
  return (
    <>
      <tr className="update-portfolio-asset-row">
        <td>{fund.name}</td>
        <td>
          <tr>
            <td>₹ {fund.price}</td>
            <td className="mr-[1rem]" onClick={() => removesavingsarray(id)}>
              Delete
            </td>
            <td
              className="w-[20px] py-0 pr-0 pl-[0.3rem]"
              onClick={() => setOpen(!open)}
            >
              {open || fund.name === "New Scheme" ? (
                <img className="block" src={ArrowDown} alt="err" />
              ) : (
                <img className="block" src={ArrowLeft} alt="err" />
              )}
            </td>
          </tr>
        </td>
      </tr>
      {open || fund.name === "New Scheme" ? (
        <>
          <tr className="flex w-full">
            <td className="w-[50%]">
              <TextField
                required
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Name"
                type="text"
              />
            </td>
            <td className="w-[25%]">
              <TextField
                required
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Type"
                type="text"
              />{" "}
            </td>
            <td className="w-[25%]">
              <TextField
                required
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Bank"
                type="text"
              />{" "}
            </td>
          </tr>
          <tr className="flex w-full">
            <td className="w-[20%]">
              <TextField
                required
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Purchase date"
                type="text"
              />{" "}
            </td>
            <td className="w-[20%]">
              <TextField
                required
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Maturity date"
                type="text"
              />{" "}
              \
            </td>
            <td className="w-[20%]">
              <label>Payout Frequency</label>
              <br />
              <DropdownAsset
                selected={asset}
                setSelected={setAsset}
                options={["Quarterly", "Monthly", "Anually", "At Maturity"]}
              />
            </td>
            <td className="w-[20%]">
              <TextField
                required
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Start value"
                type="text"
              />{" "}
            </td>
            <td className="w-[20%]">
              <TextField
                required
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Current value"
                type="text"
              />{" "}
            </td>
          </tr>
        </>
      ) : (
        ""
      )}
    </>
  );
}
