import React, { useEffect, useRef, useState } from "react";
import BlueRationale from "../../../../assets/img/rationale_type_blue.svg";
import GreenRationale from "../../../../assets/img/rationale_type_green.svg";
import GreyRationale from "../../../../assets/img/rationale_type_grey.svg";
import Dropdown from "../../../../components/Dropdowns/DropdownWhiteSingle";
import Tick from "../../../../assets/img/confirm_tick.svg";
// import ClickAwayListener from "react-click-away-listener";
import { useDispatch } from "react-redux";
import { PostEventLoggingData } from "../../../../actions/actions";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
import InvestedgePopper from "../../../../components/popper/InvestedgePopper";
import TooltipIcon from "../../../../assets/img/tooltip_icon.svg";
import axios from "axios";
import urls from "../../../../urls";
import { Bars } from "react-loader-spinner";
export default function ActionableTableRow({
  client,
  handleWeightChange,
  parsedActions,
  setParsedActions,
  parsedData,
  setParsedData,
  setPortfolioActions,
  setPortfolioProducts,
  derationalizeProducts,
  portfolioValue,
}) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const popperRef = useRef(null);
  const [anchorElTooltip, setAnchorElTooltip] = useState(null);
  const tooltipRef = useRef(null);
  const [rationaleType, setRationalType] = useState(client.rationale_type);
  const [rationale, setRationale] = useState(client.rationale);
  const [performanceData, setPerformanceData] = useState({ loading: true });
  useEffect(() => {
    setRationalType(client.rationale_type);
    setRationale(client.rationale);
  }, [client, parsedData]);
  const handleConfirm = () => {
    let index = parsedData.findIndex((x) => x.product_id === client.product_id);
    let newProducts = [...parsedData];
    newProducts[index].rationale = rationale;
    newProducts[index].rationale_type = rationaleType;
    newProducts[index].list_type = 1;
    setParsedData(newProducts);
    let actionsindex = parsedActions.findIndex(
      (x) => x.product_id === client.product_id
    );
    let newActions = [...parsedActions];
    newActions[actionsindex].rationale = rationale;
    newActions[actionsindex].rationale_type = rationaleType;
    dispatch(
      PostEventLoggingData({
        module_name: "Portfolio Review",
        event_type: "Save button",
        url: window.location.href,
        note: `Rationale for ${client.product_id} saved`,
      })
    );
    setParsedActions(newActions);
    setPortfolioActions(newActions);
    setPortfolioProducts(derationalizeProducts(newProducts));
    setAnchorEl(null);
  };
  return (
    <React.Fragment key={client.product_id}>
      <tr className="border-none border-primarytablebottomClr">
        <td
          style={{
            textAlign: "left",
            paddingLeft: "1rem",
          }}
          className="text-[#3d3d3d] font-inter  not-italic font-normal tracking-[0em] p-[1rem] text-left pl-[1rem] text-sm pb-0"
        >
          {client.product_name}
        </td>

        <td className=" font-inter  not-italic font-normal tracking-[0em] p-[1rem] text-center text-[#6a6a76] text-sm pb-0">
          {`${parseInt(client.current_market_value.toFixed(0)).toLocaleString(
            "en-IN"
          )} (${((client.current_market_value / portfolioValue) * 100).toFixed(
            1
          )}%)`}
          {/* <div
            className={
              client.weight_change === 0
                ? "text-[#a3a3a3] font-inter  not-italic font-normal tracking-[0em] p-[1rem] text-center flex justify-center items-center h-[100%]"
                : client.weight_change > 0
                ? "text-[#00B031] font-inter  not-italic font-normal tracking-[0em] p-[1rem] text-center flex justify-center items-center h-[100%]"
                : "text-[#FF0000] font-inter  not-italic font-normal tracking-[0em] p-[1rem] text-center flex justify-center items-center h-[100%]"
            }
          >
            {(client.weight_change * 100).toFixed(1)}
          </div> */}
        </td>
        <td className="text-[#3d3d3d] font-inter  not-italic font-normal tracking-[0em] p-[1rem] text-center pb-0">
          <input
            className=" w-full h-[40px] shadow-none text-center font-inter text-[14px] bg-inputFieldBg"
            type="text"
            value={client.proposed_market_value}
            onChange={(e) =>
              handleWeightChange(e, client.product_id, client.product_type)
            }
          />
        </td>

        {/* <td className="text-[#3d3d3d] font-inter  not-italic font-normal tracking-[0em] p-[1rem] text-center flex justify-center items-center h-[100%]">
        <img
          src={
            client.list_type === 0
              ? GreyRationale
              : client.list_type === 1
              ? GreenRationale
              : BlueRationale
          }
          alt="err"
          ref={popperRef}
          onClick={(e) =>
            client.list_type !== 0 &&
            setAnchorEl((prevAnchor) => (prevAnchor ? null : popperRef.current))
          }
        />
      </td> */}
        <InvestedgePopper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="right-start"
          className="z-[100]"
          offset={[0, 20]}
          arrow="true"
        >
          <div className="bg-[#fff] p-[16px] w-[350px]">
            <div className="w-full flex justify-between">
              <h2 className="text-xl font-medium font-inter">Rationale</h2>
              <button
                type="button"
                className="close"
                onClick={() => setAnchorEl(null)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="w-[60%] pt-[8px]">
              <Dropdown
                options={[
                  "Remove Sell-Rated Holdings",
                  "Reduce Concentration Risk",
                  "Align Asset Allocation with IPS",
                  "Align Sub-Asset Allocation with IPS",
                  "Align with your Requirements",
                  "Action due to Multiple Reasons",
                  "Miscellaneous",
                ]}
                selected={rationaleType}
                setSelected={setRationalType}
                labelName="Rationale Type"
              />
            </div>
            <textarea
              className="w-full mt-[16px] text-sm p-[16px] border border-[#e5e5e5] bg-[#fafafa] h-[130px] rounded"
              value={rationale}
              onChange={(e) => setRationale(e.target.value)}
            />
            <div className="w-full flex justify-end mt-4">
              <InvestedgeButton onClick={handleConfirm}>
                <img
                  className="w-[14px] object-contain mr-[0.25rem]"
                  src={Tick}
                  alt="err"
                />
                Confirm
              </InvestedgeButton>
            </div>
          </div>
        </InvestedgePopper>
        <InvestedgePopper
          open={Boolean(anchorElTooltip)}
          anchorEl={anchorElTooltip}
          placement="right-start"
          className="z-[100]"
          offset={[0, 20]}
          arrow="true"
        >
          <div className="bg-[#fff] p-[16px] w-[250px]">
            {performanceData.loading ? (
              <div className="w-full min-h-[100px] flex flex-col justify-center items-center opacity-[0.9]">
                <Bars color="#1982F8" height="40" width="40" />
              </div>
            ) : (
              <>
                <p className="text-xs text-[#a3a3a3]">
                  1 month return:{" "}
                  <span className="text-neutralBlack">
                    {client.asset_class === "Debt"
                      ? performanceData.data["1m_ann"]
                        ? `${(performanceData.data["1m_ann"] * 100).toFixed(
                            1
                          )}%`
                        : "NA"
                      : performanceData.data["1m_abs"]
                      ? `${(performanceData.data["1m_abs"] * 100).toFixed(1)}%`
                      : "NA"}
                  </span>
                </p>
                <p className="text-xs text-[#a3a3a3] mt-4">
                  6 months return:{" "}
                  <span className="text-neutralBlack">
                    {client.asset_class === "Debt"
                      ? performanceData.data["6m_ann"]
                        ? `${(performanceData.data["6m_ann"] * 100).toFixed(
                            1
                          )}%`
                        : "NA"
                      : performanceData.data["6m_abs"]
                      ? `${(performanceData.data["6m_abs"] * 100).toFixed(1)}%`
                      : "NA"}
                  </span>
                </p>
                <p className="text-xs text-[#a3a3a3] mt-4">
                  1 year return:{" "}
                  <span className="text-neutralBlack">
                    {performanceData.data["1y_cagr"]
                      ? `${(performanceData.data["1y_cagr"] * 100).toFixed(1)}%`
                      : "NA"}
                  </span>
                </p>
                <p className="text-xs text-[#a3a3a3] mt-4">
                  3 year CAGR:{" "}
                  <span className="text-neutralBlack">
                    {performanceData.data["3y_cagr"]
                      ? `${(performanceData.data["3y_cagr"] * 100).toFixed(1)}%`
                      : "NA"}
                  </span>
                </p>
                <p className="text-xs text-[#a3a3a3] mt-4">
                  5 year CAGR:{" "}
                  <span className="text-neutralBlack">
                    {performanceData.data["5y_cagr"]
                      ? `${(performanceData.data["5y_cagr"] * 100).toFixed(1)}%`
                      : "NA"}
                  </span>
                </p>
                <p className="text-xs text-[#a3a3a3] mt-4">
                  Data as of:{" "}
                  <span className="text-neutralBlack">
                    {performanceData["as_on"] ? performanceData["as_on"] : "NA"}
                  </span>
                </p>
              </>
            )}
          </div>
        </InvestedgePopper>
      </tr>
      <tr>
        <td
          style={{
            textAlign: "left",
            paddingLeft: "1rem",
          }}
          className="text-[#a3a3a3]  font-inter  not-italic font-normal tracking-[0em] p-[1rem] text-left pl-[1rem] text-sm pt-0"
        >
          {`${client.asset_class} - ${client.product_type}`}
        </td>
        <td
          style={{
            textAlign: "left",
            paddingLeft: "1rem",
          }}
          className="text-[#a3a3a3]  font-inter  not-italic font-normal tracking-[0em] p-[1rem] text-left pl-[1rem] text-sm pt-0"
        ></td>
        <td
          style={{
            textAlign: "left",
            paddingLeft: "1rem",
          }}
          className="text-[#a3a3a3]  font-inter  not-italic font-normal tracking-[0em] p-[1rem] text-left pl-[1rem] text-sm pt-0"
        ></td>
      </tr>
      <tr className="border-b border-primarytablebottomClr">
        <td className="text-[#3d3d3d] font-inter  not-italic font-normal tracking-[0em] p-[1rem] text-center flex justify-start items-center pt-0">
          <div className="flex items-center">
            <img
              src={
                client.list_type === 0
                  ? GreyRationale
                  : client.list_type === 1
                  ? GreenRationale
                  : BlueRationale
              }
              alt="err"
              ref={popperRef}
              onClick={(e) =>
                client.list_type !== 0 &&
                setAnchorEl((prevAnchor) =>
                  prevAnchor ? null : popperRef.current
                )
              }
            />
            {/* <Tooltip
              TransitionComponent={Zoom}
              placement="right"
              title={<div>"IPS would get saved on clicking save button."</div>}
              arrow
            > */}
            <img
              src={TooltipIcon}
              alt="tooltip"
              className="w-[16px] h-[16px] ml-2"
              ref={tooltipRef}
              onMouseEnter={() => {
                setAnchorElTooltip(tooltipRef.current);
                axios
                  .get(urls.productPerformanceURL, {
                    params: { product_id: client.product_id },
                  })
                  .then((response) => {
                    setPerformanceData(response.data.performance);
                  })
                  .catch(() => {
                    setPerformanceData({ loading: true });
                  });
              }}
              onMouseLeave={() => {
                setAnchorElTooltip(null);
              }}
            />
            {/* </Tooltip> */}
          </div>
        </td>
        <td
          className={
            parseInt(client.proposed_market_value) -
              client.current_market_value ===
            0
              ? "text-[#a3a3a3] font-inter text-sm not-italic font-normal tracking-[0em] p-[1rem] text-center h-[100%] pt-0"
              : parseInt(client.proposed_market_value) -
                  client.current_market_value >
                0
              ? "text-[#00B031] font-inter text-sm not-italic font-normal tracking-[0em] p-[1rem] text-center h-[100%] pt-0"
              : "text-[#FF0000] font-inter text-sm not-italic font-normal tracking-[0em] p-[1rem] text-center h-[100%] pt-0"
          }
        >
          {parseInt(
            (
              parseInt(client.proposed_market_value) -
              client.current_market_value
            ).toFixed(0)
          ).toLocaleString("en-IN")}
        </td>
        <td className="text-[#3d3d3d] font-inter text-sm not-italic font-normal tracking-[0em] p-[1rem] text-center pt-0">
          {`(${(
            (parseInt(client.proposed_market_value) / portfolioValue) *
            100
          ).toFixed(1)}%)`}
        </td>
      </tr>
    </React.Fragment>
  );
}
