import React, { useEffect } from "react";
import IndividualContent from "./IndividualContent";
import "../../static/css/existing/dashboard_styles.css";
import { GetDashboardDetails } from "../../actions/actions";
import { useDispatch } from "react-redux";
import Clientsidebar from "../ClientSidebar";
import AfterloginApp from "../../rmdashboard/AfterloginApp";
export default function ClientDetails(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      GetDashboardDetails({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    ); // eslint-disable-next-line
  }, []);

  const { familyMembers } = (props.location && props.location.state) || {};
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp />
      <div className="loggedinscreen-container pt-0">
        <div className="loggedinscreen flex flex-row justify-between">
          <Clientsidebar />
          <div className=" p-[1rem] pt-0 bg-[#fff] rounded-[0.5rem] shadow-none min-h-full client-input-parent-card w-[79%] ml-0">
            <IndividualContent familyMembers={familyMembers} />
          </div>
        </div>
      </div>
    </div>
  );
}
