import React from "react";
import { useState } from "react";
import ArrowLeft from "../../../../../assets/img/left_arrow_icon.svg";
import ArrowDown from "../../../../../assets/img/down_arrow_icon.svg";
import TextField from "@mui/material/TextField";
export default function EPFrow({
  fund,
  id,
  removeepfarray,
  epfarray,
  setepfarray,
}) {
  const [open, setOpen] = useState(fund.name === "New EPF" ? true : false);

  // let handleChange = (i, e) => {
  //   let newepfarray = [...epfarray];
  //   newepfarray[i][e.target.name] = e.target.value;
  //   setepfarray(newepfarray);
  // };
  return (
    <>
      {" "}
      <table>
        <tbody>
          <tr className="update-portfolio-asset-row">
            <td>{fund.name}</td>
            <td>
              <tr>
                <td>₹ {fund.price}</td>
                <td className="mr-[1rem]" onClick={() => removeepfarray(id)}>
                  Delete
                </td>
                <td
                  className="w-[20px] py-0 pr-0 pl-[0.3rem]"
                  onClick={() => setOpen(!open)}
                >
                  {open || fund.name === "New EPF" ? (
                    <img className="block" src={ArrowDown} alt="err" />
                  ) : (
                    <img className="block" src={ArrowLeft} alt="err" />
                  )}
                </td>
              </tr>
            </td>
          </tr>
          {open || fund.name === "New EPF" ? (
            <>
              <tr className="flex w-full">
                <td className="w-[50%]">
                  <TextField
                    required
                    fullWidth
                    sx={{
                      fontFamily: "Inter,sans-sarif",
                      fontWeight: 400,
                    }}
                    label="Name"
                    type="text"
                  />
                </td>
                <td className="w-[25%]">
                  <TextField
                    required
                    fullWidth
                    sx={{
                      fontFamily: "Inter,sans-sarif",
                      fontWeight: 400,
                    }}
                    label="Purchase date"
                    type="text"
                  />
                </td>
                <td className="w-[25%]">
                  <TextField
                    required
                    fullWidth
                    sx={{
                      fontFamily: "Inter,sans-sarif",
                      fontWeight: 400,
                    }}
                    label="Cost"
                    type="text"
                  />{" "}
                </td>
              </tr>
              <tr className="flex w-full">
                <td className="w-[23%]">
                  <TextField
                    required
                    fullWidth
                    sx={{
                      fontFamily: "Inter,sans-sarif",
                      fontWeight: 400,
                    }}
                    label="Current Value"
                    type="text"
                  />
                </td>
              </tr>
            </>
          ) : (
            ""
          )}
        </tbody>
      </table>
    </>
  );
}
