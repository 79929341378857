import React, { useState } from "react";
import AfterloginApp from "../../AfterloginApp";
import InvestedgeGPTSidebar from "../InvestedgeGPTSidebar";
import BeyondAI from "./BeyondAI";
import Select from "./select";

export default function Clients() {
  const [screen, setScreen] = useState("clients");
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp />
      <div className="loggedinscreen-container pt-0">
        <div className="loggedinscreen flex flex-row justify-between">
          <InvestedgeGPTSidebar />
          <div className="p-[1rem] bg-[#fff] rounded-[0.5rem] min-h-full client-input-parent-card mt-4 w-[79%] ml-0 relative">
            {screen === "clients" ? (
              <Select setScreen={setScreen} />
            ) : (
              <BeyondAI />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
