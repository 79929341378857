import React, { useState, useEffect } from "react";
import Add from "../../../../assets/img/commisionsadd.svg";
import Subtract from "../../../../assets/img/commisionssubtract.svg";
import PortfolioThirdStepSlider from "./PortfolioSecondStepSlider";
import { useSelector } from "react-redux";
import {
  GetReviewPortfolioIpsTracker,
  PostEventLoggingData,
} from "../../../../actions/actions";
import { useDispatch } from "react-redux";
import DoughnutChart from "../../../../components/charts/DoughnutChart";
import InvestmentPolicyModal from "./InvestmentPolicyModal";
import Dropdown from "../../../../components/Dropdowns/DropdownWhiteSingle";
import { Bars } from "react-loader-spinner";
import TooltipIcon from "../../../../assets/img/tooltip_icon.svg";
import { Tooltip, Zoom } from "@mui/material";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
import InvestedgeSlider from "../../../../components/slider/InvestedgeSlider";
export default function PortfolioThirdStep({
  handleBack,
  handleNext,
  formValue,
  setFormValue,
  setPortfolioProducts,
  clientId,
}) {
  const dispatch = useDispatch();
  const ipsData = useSelector((state) => state.portfolioReviewGetIAA);
  const reviewIps = useSelector((state) => state.portfolioReviewGetIpsData);
  const [ipsmodal, setIpsModal] = useState(false);
  const ThreeColors = ["#156DD0", "#52A1FA", "#BFE0FF"];
  const TwoColors = ["#156DD0", "#BFE0FF"];
  const [EquityWidths, setEquity] = useState(
    !formValue.allocation_preference.equity
      ? [
          parseInt(ipsData[3].opt_allocation * 100),
          parseInt(ipsData[4].opt_allocation * 100),
          parseInt(ipsData[5].opt_allocation * 100),
        ]
      : [
          parseInt(formValue.allocation_preference.large * 100),
          parseInt(formValue.allocation_preference.mid * 100),
          parseInt(formValue.allocation_preference.small * 100),
        ]
  );
  const [DebtWidths, setDebt] = useState(
    !formValue.allocation_preference.debt
      ? [
          parseInt(ipsData[1].opt_allocation * 100),
          parseInt((1 - ipsData[1].opt_allocation) * 100),
        ]
      : [
          parseInt(formValue.allocation_preference.AAA * 100),
          parseInt((1 - formValue.allocation_preference.AAA) * 100),
        ]
  );
  const [AlternativesWidths, setAlternatives] = useState(
    !formValue.allocation_preference.alternate
      ? [
          parseInt(ipsData[2].opt_allocation * 100),
          parseInt((1 - ipsData[2].opt_allocation) * 100),
        ]
      : [
          parseInt(formValue.allocation_preference.low_volatility * 100),
          parseInt((1 - formValue.allocation_preference.low_volatility) * 100),
        ]
  );
  const [equityValue, setEquityValue] = useState(
    !formValue.allocation_preference.equity &&
      !formValue.allocation_preference.debt &&
      !formValue.allocation_preference.alternate
      ? parseFloat((ipsData[6].opt_allocation * 100).toFixed(1))
      : parseFloat((formValue.allocation_preference.equity * 100).toFixed(1))
  );
  const [debtValue, setDebtValue] = useState(
    !formValue.allocation_preference.equity &&
      !formValue.allocation_preference.debt &&
      !formValue.allocation_preference.alternate
      ? parseFloat((ipsData[7].opt_allocation * 100).toFixed(1))
      : parseFloat((formValue.allocation_preference.debt * 100).toFixed(1))
  );
  const [alternativeValue, setAlternativeValue] = useState(
    !formValue.allocation_preference.equity &&
      !formValue.allocation_preference.debt &&
      !formValue.allocation_preference.alternate
      ? parseFloat((ipsData[8].opt_allocation * 100).toFixed(1))
      : parseFloat((formValue.allocation_preference.alternate * 100).toFixed(1))
  );
  const [offLimits, setOffLimits] = useState(null);
  const assetSum =
    parseFloat(equityValue) +
    parseFloat(debtValue) +
    parseFloat(alternativeValue);
  const [duration, setDuration] = useState(ipsData[0].opt_allocation);
  const [expectedReturns, setExpectedReturns] = useState({
    large_cap: 12,
    mid_cap: 14,
    small_cap: 15,
    aaa: 7,
    belowaaa: 9,
    lowvol: 10,
    highvol: 20,
  });
  const LegendItem = ({ color, text }) => {
    return (
      <div className="flex mt-[1rem]">
        <div
          className={`w-[7px] h-[7px] block mt-[7px]`}
          style={{ backgroundColor: color }}
        />
        <p className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] ml-[0.2rem] mb-0">
          {text}
        </p>
      </div>
    );
  };
  const [invAmount, setInvAmount] = useState(1000);
  const [cagr, setCagr] = useState(50);
  const [time, setTime] = useState(12);
  const productdata = {
    labels: ["Invested Amount", "Estimated Returns"],
    datasets: [
      {
        data: [invAmount, (Math.pow(1 + cagr / 100, time) - 1) * invAmount],
        backgroundColor: ["#156DD0", "#BFE0FF"],
        borderColor: ["#156DD0", "#BFE0FF"],
        borderWidth: 1,
      },
    ],
  };
  const doughnutoptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      labels: { render: () => {} },
      datalabels: {
        display: false,
      },
    },
  };
  useEffect(() => {
    setEquity(
      !formValue.allocation_preference.equity
        ? [
            parseInt(ipsData[3].opt_allocation * 100),
            parseInt(ipsData[4].opt_allocation * 100),
            parseInt(ipsData[5].opt_allocation * 100),
          ]
        : [
            parseInt(formValue.allocation_preference.large * 100),
            parseInt(formValue.allocation_preference.mid * 100),
            parseInt(formValue.allocation_preference.small * 100),
          ]
    );
    setDebt(
      !formValue.allocation_preference.debt
        ? [
            parseInt(ipsData[1].opt_allocation * 100),
            parseInt((1 - ipsData[1].opt_allocation) * 100),
          ]
        : [
            parseInt(formValue.allocation_preference.AAA * 100),
            parseInt((1 - formValue.allocation_preference.AAA) * 100),
          ]
    );
    setAlternatives(
      !formValue.allocation_preference.alternate
        ? [
            parseInt(ipsData[2].opt_allocation * 100),
            parseInt((1 - ipsData[2].opt_allocation) * 100),
          ]
        : [
            parseInt(formValue.allocation_preference.low_volatility * 100),
            parseInt(
              (1 - formValue.allocation_preference.low_volatility) * 100
            ),
          ]
    );
    setEquityValue(
      !formValue.allocation_preference.equity &&
        !formValue.allocation_preference.debt &&
        !formValue.allocation_preference.alternate
        ? parseFloat((ipsData[6].opt_allocation * 100).toFixed(1))
        : parseFloat((formValue.allocation_preference.equity * 100).toFixed(1))
    );
    setDebtValue(
      !formValue.allocation_preference.equity &&
        !formValue.allocation_preference.debt &&
        !formValue.allocation_preference.alternate
        ? parseFloat((ipsData[7].opt_allocation * 100).toFixed(1))
        : parseFloat((formValue.allocation_preference.debt * 100).toFixed(1))
    );
    setAlternativeValue(
      !formValue.allocation_preference.equity &&
        !formValue.allocation_preference.debt &&
        !formValue.allocation_preference.alternate
        ? parseFloat((ipsData[8].opt_allocation * 100).toFixed(1))
        : parseFloat(
            (formValue.allocation_preference.alternate * 100).toFixed(1)
          )
    );
    setDuration(parseFloat((ipsData[0].opt_allocation * 1).toFixed(1)));
    // eslint-disable-next-line
  }, [ipsData]);
  useEffect(() => {
    if (
      EquityWidths[0] / 100 > reviewIps.large_cap.max ||
      EquityWidths[0] / 100 < reviewIps.large_cap.min
    )
      setOffLimits(
        "Desired Asset Allocation is not in accordance with your IPS."
      );
    else if (
      EquityWidths[1] / 100 > reviewIps.mid_cap.max ||
      EquityWidths[1] / 100 < reviewIps.mid_cap.min
    )
      setOffLimits(
        "Desired Asset Allocation is not in accordance with your IPS."
      );
    else if (
      EquityWidths[2] / 100 > reviewIps.small_cap.max ||
      EquityWidths[2] / 100 < reviewIps.small_cap.min
    )
      setOffLimits(
        "Desired Asset Allocation is not in accordance with your IPS."
      );
    else if (
      DebtWidths[0] / 100 > reviewIps.aaa_equiv.max ||
      DebtWidths[0] / 100 < reviewIps.aaa_equiv.min
    )
      setOffLimits(
        "Desired Asset Allocation is not in accordance with your IPS."
      );
    else if (
      AlternativesWidths[0] / 100 > reviewIps.alternates.max ||
      AlternativesWidths[0] / 100 < reviewIps.alternates.min
    )
      setOffLimits(
        "Desired Asset Allocation is not in accordance with your IPS."
      );
    else if (
      equityValue / 100 > reviewIps.equity.max ||
      equityValue / 100 < reviewIps.equity.min
    )
      setOffLimits(
        "Desired Asset Allocation is not in accordance with your IPS."
      );
    else if (
      debtValue / 100 > reviewIps.debt.max ||
      debtValue / 100 < reviewIps.debt.min
    )
      setOffLimits(
        "Desired Asset Allocation is not in accordance with your IPS."
      );
    else if (
      alternativeValue / 100 > reviewIps.alternate.max ||
      alternativeValue / 100 < reviewIps.alternate.min
    )
      setOffLimits(
        "Desired Asset Allocation is not in accordance with your IPS."
      );
    else if (
      duration > reviewIps.duration.max ||
      duration < reviewIps.duration.min
    )
      setOffLimits(
        "Desired Asset Allocation is not in accordance with your IPS."
      );
    else setOffLimits(null);
    // eslint-disable-next-line
  }, [
    EquityWidths,
    DebtWidths,
    AlternativesWidths,
    alternativeValue,
    debtValue,
    equityValue,
    duration,
  ]);
  const handleReturnsChange = (e) => {
    if (
      e.target.value <= 100 &&
      (!e.target.value.split(".")[1] ||
        e.target.value.split(".")[1].length <= 1)
    ) {
      let newReturns = { ...expectedReturns };
      newReturns[e.target.name] = e.target.value;
      setExpectedReturns(newReturns);
    }
  };
  const [expectedReturnsDropdown, setExpectedReturnsDropdown] =
    useState("Expected Returns");
  useEffect(() => {
    let fracequity = parseFloat(Math.round(equityValue * 10) / 1000);
    let fracdebt = parseFloat(Math.round(debtValue * 10) / 1000);
    let fracalternate = parseFloat(Math.round(alternativeValue * 10) / 1000);
    let fraclarge = parseFloat(Math.round(EquityWidths[0] * 10) / 1000);
    let fracmid = parseFloat(Math.round(EquityWidths[1] * 10) / 1000);
    let fracsmall = parseFloat(Math.round(EquityWidths[2] * 10) / 1000);
    let fracAAA = parseFloat(Math.round(DebtWidths[0] * 10) / 1000);
    // let fracduration = parseFloat(duration);
    let fraclow_volatility = parseFloat(
      Math.round(AlternativesWidths[0] * 10) / 1000
    );

    setCagr(
      (
        ((parseFloat(expectedReturns.large_cap || 0) / 100) *
          fracequity *
          fraclarge +
          (parseFloat(expectedReturns.mid_cap || 0) / 100) *
            fracequity *
            fracmid +
          (parseFloat(expectedReturns.small_cap || 0) / 100) *
            fracequity *
            fracsmall +
          (parseFloat(expectedReturns.aaa || 0) / 100) * fracAAA * fracdebt +
          (parseFloat(expectedReturns.belowaaa) / 100) *
            (1 - fracAAA) *
            fracdebt +
          (parseFloat(expectedReturns.lowvol || 0) / 100) *
            fraclow_volatility *
            fracalternate +
          (parseFloat(expectedReturns.highvol || 0) / 100) *
            (1 - fraclow_volatility) *
            fracalternate) *
        100
      ).toFixed(0)
    ); // eslint-disable-next-line
  }, [
    expectedReturnsDropdown,
    expectedReturns,
    EquityWidths,
    DebtWidths,
    AlternativesWidths,
    equityValue,
    debtValue,
    alternativeValue,
  ]);

  return (
    <>
      <InvestmentPolicyModal
        onClose={() => setIpsModal(false)}
        ipsmodal={ipsmodal}
      />
      <div className="px-8 rounded-[8px] w-full bg-white py-8 mt-[16px]">
        <div className="flex justify-between w-full">
          <h2 className="card-heading">
            Asset Allocation &amp; Returns Simulator
          </h2>
          <div className="flex">
            <InvestedgeButton
              onClick={() => {
                setFormValue({
                  ...formValue,
                  allocation_preference: {
                    equity: 0.0,
                    debt: 0.0,
                    alternate: 0.0,
                    large: 0.0,
                    mid: 0.0,
                    small: 0.0,
                    AAA: 0.0,
                    duration: 0.0,
                    low_volatility: 0.0,
                  },
                });
                handleBack();
                dispatch(
                  PostEventLoggingData({
                    module_name: "Portfolio Review",
                    event_type: "Prev Button",
                    url: window.location.href,
                    note: `Second screen`,
                  })
                );
              }}
            >
              Prev
            </InvestedgeButton>
            <InvestedgeButton
              className="ml-4"
              disabled={
                offLimits ||
                Math.round(assetSum * 10) / 10 !== 100 ||
                ipsData[0].loading
              }
              onClick={() => {
                // if (horizon) {
                //   setFormValue({
                //     ...formValue,
                //     risk_score: riskscore,
                //     investment_horizon: parseInt(horizon),
                //     type_of_scheme: scheme,
                //   });
                //   handleNext();
                // } else setError("This field is required");

                !offLimits &&
                  Math.round(assetSum * 10) / 10 === 100 &&
                  !ipsData[0].loading &&
                  setFormValue({
                    ...formValue,
                    allocation_preference: {
                      equity: parseFloat(Math.round(equityValue * 10) / 1000),
                      debt: parseFloat(Math.round(debtValue * 10) / 1000),
                      alternate: parseFloat(
                        Math.round(alternativeValue * 10) / 1000
                      ),
                      large: parseFloat(
                        Math.round(EquityWidths[0] * 10) / 1000
                      ),
                      mid: parseFloat(Math.round(EquityWidths[1] * 10) / 1000),
                      small: parseFloat(
                        Math.round(EquityWidths[2] * 10) / 1000
                      ),
                      AAA: parseFloat(Math.round(DebtWidths[0] * 10) / 1000),
                      duration: parseFloat(duration),
                      low_volatility: parseFloat(
                        Math.round(AlternativesWidths[0] * 10) / 1000
                      ),
                    },
                  });
                !offLimits &&
                  Math.round(assetSum * 10) / 10 === 100 &&
                  !ipsData[0].loading &&
                  dispatch(
                    GetReviewPortfolioIpsTracker({
                      client_id: clientId,
                      allocation_preference: {
                        AAA: parseFloat(Math.round(DebtWidths[0] * 10) / 1000),
                        alternate: parseFloat(
                          Math.round(alternativeValue * 10) / 1000
                        ),
                        debt: parseFloat(Math.round(debtValue * 10) / 1000),
                        duration: parseFloat(duration),
                        equity: parseFloat(Math.round(equityValue * 10) / 1000),
                        large: parseFloat(
                          Math.round(EquityWidths[0] * 10) / 1000
                        ),
                        low_volatility: parseFloat(
                          Math.round(AlternativesWidths[0] * 10) / 1000
                        ),
                        mid: parseFloat(
                          Math.round(EquityWidths[1] * 10) / 1000
                        ),
                        small: parseFloat(
                          Math.round(EquityWidths[2] * 10) / 1000
                        ),
                      },
                    })
                  );
                !offLimits &&
                  !ipsData[0].loading &&
                  Math.round(assetSum * 10) / 10 === 100 &&
                  handleNext();
                !offLimits &&
                  !ipsData[0].loading &&
                  Math.round(assetSum * 10) / 10 === 100 &&
                  dispatch(
                    PostEventLoggingData({
                      module_name: "Portfolio Review",
                      event_type: "Next Button",
                      url: window.location.href,
                      note: `Second screen`,
                    })
                  );
              }}
            >
              Next
            </InvestedgeButton>
          </div>
        </div>
      </div>
      {ipsData[0].loading ? (
        <div className="w-full h-[335px] flex justify-center items-center opacity-[0.9]">
          <Bars color="#1982F8" />
        </div>
      ) : (
        <>
          <div className="px-8 rounded-[8px] w-full bg-white pt-[32px] pb-[16px] mt-[16px]">
            <h2 className="card-heading">Asset Allocation</h2>
            <p className=" w-full font-inter text-[14px] font-normal leading-[24px] tracking-[0em] text-left mt-[1rem] text-neutralBlack  mb-0">
              Basis your client’s risk score we have recommended the below asset
              allocation. You can change the asset allocation as per your
              requirement. Sum of desired asset allocation should be 100.0.{" "}
            </p>
            <div className="w-full flex justify-between">
              <p className=" font-inter text-[14px] font-normal leading-[24px] tracking-[0em] w-[60%] text-left mt-0 text-neutralBlack ">
                Right now :
                <span
                  className={
                    Math.round(assetSum * 10) / 10 !== 100
                      ? "text-error-200  ml-[0.2rem] "
                      : "ml-[0.2rem]"
                  }
                >
                  {Math.round(assetSum * 10) / 10}
                </span>
                {offLimits && (
                  <span className="text-error-200  ml-[0.2rem]">
                    {offLimits}
                  </span>
                )}
              </p>
              <p
                className="text-[14px] leading-[24px] cursor-pointer tracking-[0em] text-left mt-0 w-auto text-primary-200 font-inter font-medium"
                onClick={() => {
                  dispatch(
                    PostEventLoggingData({
                      module_name: "Portfolio Review",
                      event_type: "Information",
                      url: window.location.href,
                      note: `Checked second screen IPS statement`,
                    })
                  );
                  setIpsModal(true);
                }}
              >
                Click here to view your IPS
              </p>
            </div>

            <div className="flex flex-col items-center w-full mt-[1rem]">
              <table className="w-full p-[1rem] mt-[1rem] investedge-table">
                <colgroup>
                  <col width="25%" />
                  <col width="30%" />
                  <col width="45%" />
                </colgroup>
                <tbody>
                  <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                    <th className="pl-[1rem] align-middle not-italic leading-[16px] tracking-[0em] text-center font-inter text-[18px] font-medium">
                      Asset Class
                    </th>
                    <th className="pl-[1rem] align-middle font-inter text-[18px] not-italic leading-[16px] tracking-[0em] text-center font-medium">
                      Desired Allocation (in %)
                    </th>
                    <th className="pl-[1rem] align-middle not-italic leading-[16px] tracking-[0em] text-center font-inter text-[18px] font-medium">
                      Sub-Asset Allocation
                    </th>
                  </tr>

                  <tr
                    style={{
                      borderTop: "1px solid #323232",
                      borderBottom: "1px solid #e5e5e5",
                    }}
                  >
                    <td className="font-inter font-medium font-sm text-[#3D3D3D] text-center">
                      Equity
                    </td>
                    <td className="font-inter">
                      <div className="flex justify-center">
                        <img
                          src={Subtract}
                          alt="err"
                          className="mr-[5%]"
                          onClick={() =>
                            setEquityValue(
                              Math.round(
                                (parseFloat(equityValue) - 0.1) * 1e12
                              ) / 1e12
                            )
                          }
                        />
                        <input
                          className="w-[30%] h-[40px] shadow-none text-center font-inter text-[14px] bg-[#f3f3f3] border-none"
                          value={equityValue}
                          onChange={(e) => {
                            if (
                              e.target.value <= 100 &&
                              (!e.target.value.split(".")[1] ||
                                e.target.value.split(".")[1].length <= 1)
                            )
                              setEquityValue(e.target.value);
                          }}
                        />
                        <img
                          src={Add}
                          alt="err"
                          className="ml-[5%]"
                          onClick={() =>
                            setEquityValue(
                              Math.round(
                                (parseFloat(equityValue) + 0.1) * 1e12
                              ) / 1e12
                            )
                          }
                        />
                      </div>
                    </td>
                    <td className="font-inter p-[1rem] text-center">
                      <PortfolioThirdStepSlider
                        colors={ThreeColors}
                        width={EquityWidths}
                        setWidth={setEquity}
                      />
                      <div className="flex justify-evenly w-full z-[100] pt-0">
                        <LegendItem color={"#156DD0"} text={"Large Cap"} />
                        <LegendItem color={"#52A1FA"} text={"Mid Cap"} />
                        <LegendItem color={"#BFE0FF"} text={"Small Cap"} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      rowSpan={2}
                      className="font-inter font-medium font-sm text-[#3D3D3D] text-center"
                    >
                      Debt
                    </td>
                    <td className="text-center" rowSpan={2}>
                      <div className="flex justify-center">
                        <img
                          src={Subtract}
                          alt="err"
                          className="mr-[5%]"
                          onClick={() =>
                            setDebtValue(
                              Math.round((parseFloat(debtValue) - 0.1) * 1e12) /
                                1e12
                            )
                          }
                        />
                        <input
                          className="w-[30%] h-[40px] shadow-none text-center font-inter text-[14px] bg-[#f3f3f3] border-none"
                          value={debtValue}
                          onChange={(e) => {
                            if (
                              e.target.value <= 100 &&
                              (!e.target.value.split(".")[1] ||
                                e.target.value.split(".")[1].length <= 1)
                            )
                              setDebtValue(e.target.value);
                          }}
                        />
                        <img
                          src={Add}
                          alt="err"
                          className="ml-[5%]"
                          onClick={() =>
                            setDebtValue(
                              Math.round((parseFloat(debtValue) + 0.1) * 1e12) /
                                1e12
                            )
                          }
                        />
                      </div>
                    </td>
                    <td className="p-[1rem] text-center">
                      <PortfolioThirdStepSlider
                        colors={TwoColors}
                        width={DebtWidths}
                        setWidth={setDebt}
                      />
                      <div className="flex justify-evenly w-full z-[100] pt-[0.5rem]">
                        <LegendItem
                          color={"#156DD0"}
                          text={"AAA and Equivalent"}
                        />
                        <LegendItem color={"#BFE0FF"} text={"Others"} />
                      </div>
                    </td>
                  </tr>
                  <tr className="border-b border-[#e5e5e5]">
                    <td className="p-[1rem] text-center">
                      <div className="flex justify-center items-center">
                        <p className=" font-inter text-[13px] font-medium leading-[20px] tracking-[0em] text-left mt-[2px] mb-0 mr-[5%]">
                          Maximum Duration
                        </p>
                        <img
                          src={Subtract}
                          alt="err"
                          className="mr-[3%]"
                          onClick={() =>
                            setDuration(
                              Math.round((parseFloat(duration) - 0.1) * 1e12) /
                                1e12
                            )
                          }
                        />
                        <input
                          className="w-[18%] h-[40px] shadow-none text-center font-inter text-[14px] bg-[#f3f3f3] border-none "
                          value={duration}
                          onChange={(e) => {
                            if (
                              e.target.value <= 100 &&
                              (!e.target.value.split(".")[1] ||
                                e.target.value.split(".")[1].length <= 1)
                            )
                              setDuration(e.target.value);
                          }}
                        />
                        <img
                          src={Add}
                          alt="err"
                          className="ml-[3%]"
                          onClick={() =>
                            setDuration(
                              Math.round((parseFloat(duration) + 0.1) * 1e12) /
                                1e12
                            )
                          }
                        />
                        <p className="font-inter text-[13px] font-medium leading-[20px] tracking-[0em] text-left mt-[2px] mb-0 ml-[5%]">
                          Years
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="font-inter font-medium font-sm text-[#3D3D3D] text-center">
                      Alternate
                    </td>
                    <td className="text-center">
                      <div className="flex justify-center">
                        <img
                          src={Subtract}
                          alt="err"
                          className="mr-[5%]"
                          onClick={() =>
                            setAlternativeValue(
                              Math.round(
                                (parseFloat(alternativeValue) - 0.1) * 1e12
                              ) / 1e12
                            )
                          }
                        />
                        <input
                          className="w-[30%] h-[40px] shadow-none text-center font-inter text-[14px] bg-[#f3f3f3] border-none"
                          value={alternativeValue}
                          onChange={(e) => {
                            if (
                              e.target.value <= 100 &&
                              (!e.target.value.split(".")[1] ||
                                e.target.value.split(".")[1].length <= 1)
                            )
                              setAlternativeValue(e.target.value);
                          }}
                        />
                        <img
                          src={Add}
                          alt="err"
                          className="ml-[5%]"
                          onClick={() =>
                            setAlternativeValue(
                              Math.round(
                                (parseFloat(alternativeValue) + 0.1) * 1e12
                              ) / 1e12
                            )
                          }
                        />
                      </div>
                    </td>
                    <td className="p-[1rem] text-center">
                      <PortfolioThirdStepSlider
                        colors={TwoColors}
                        width={AlternativesWidths}
                        setWidth={setAlternatives}
                      />
                      <div className="flex justify-evenly w-full pt-[1rem] z-[100]">
                        <LegendItem color={"#156DD0"} text={"Low Volatility"} />
                        <LegendItem
                          color={"#BFE0FF"}
                          text={"High Volatility"}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex justify-evenly w-full">
            <div className="px-8 rounded-[8px] w-full bg-white py-8 mt-[16px]">
              <h2 className="card-heading">Returns Simulator</h2>
              <div className="mt-[1rem] bg-[#FAFAFA] p-[1rem]">
                <h2 className="font-inter text-lg font-normal text-[#6A6A76]">
                  Expected Returns
                </h2>
                <div className="mt-[1rem] w-full grid grid-cols-3 gap-[2rem] gap-y-[8px]">
                  <div className="grid grid-cols-2">
                    <h2 className="font-inter text-sm font-medium text-[#3D3D3D]">
                      Equity
                    </h2>
                    <h2 className="font-inter text-sm font-medium text-[#3D3D3D]">
                      Expected Returns (%)
                    </h2>
                  </div>
                  <div className="grid grid-cols-2">
                    <h2 className="font-inter text-sm font-medium text-[#3D3D3D]">
                      Debt
                    </h2>
                    <h2 className="font-inter text-sm font-medium text-[#3D3D3D]">
                      Expected Returns (%)
                    </h2>
                  </div>
                  <div className="grid grid-cols-2">
                    <h2 className="font-inter text-sm font-medium text-[#3D3D3D]">
                      Alternate
                    </h2>
                    <h2 className="font-inter text-sm font-medium text-[#3D3D3D]">
                      Expected Returns (%)
                    </h2>
                  </div>
                  <div className="grid grid-cols-2">
                    <div
                      className="flex items-center mt-[1rem]"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <p className="font-inter text-sm">Large Cap</p>
                    </div>

                    <input
                      className="border border-[#E5E5E5] h-[40px] shadow-none bg-[#FFF] w-[50%] mt-[1rem] text-center px-[16px]"
                      value={expectedReturns.large_cap}
                      name="large_cap"
                      onChange={handleReturnsChange}
                    />
                  </div>
                  <div className="grid grid-cols-2">
                    <div
                      className="flex items-center mt-[1rem]"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <p className="font-inter text-sm">AAA &amp; Equivalent</p>
                    </div>

                    <input
                      className="border border-[#E5E5E5] h-[40px] shadow-none bg-[#FFF] w-[50%] mt-[1rem] text-center px-[16px]"
                      value={expectedReturns.aaa}
                      name="aaa"
                      onChange={handleReturnsChange}
                    />
                  </div>
                  <div className="grid grid-cols-2">
                    <div
                      className="flex items-center mt-[1rem]"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <p className="font-inter text-sm">Low Volatility</p>
                    </div>

                    <input
                      className="border border-[#E5E5E5] h-[40px] shadow-none bg-[#FFF] w-[50%] mt-[1rem] text-center px-[16px]"
                      value={expectedReturns.lowvol}
                      name="lowvol"
                      onChange={handleReturnsChange}
                    />
                  </div>
                  <div className="grid grid-cols-2">
                    <div
                      className="flex items-center mt-[1rem]"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <p className="font-inter text-sm">Mid Cap</p>
                    </div>

                    <input
                      className="border border-[#E5E5E5] h-[40px] shadow-none bg-[#FFF] w-[50%] mt-[1rem] text-center px-[16px]"
                      value={expectedReturns.mid_cap}
                      name="mid_cap"
                      onChange={handleReturnsChange}
                    />
                  </div>
                  <div className="grid grid-cols-2">
                    <div
                      className="flex items-center mt-[1rem]"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <p className="font-inter text-sm">Below AAA</p>
                    </div>

                    <input
                      className="border border-[#E5E5E5] h-[40px] shadow-none bg-[#FFF] w-[50%] mt-[1rem] text-center px-[16px]"
                      value={expectedReturns.belowaaa}
                      name="belowaaa"
                      onChange={handleReturnsChange}
                      type="number"
                    />
                  </div>
                  <div className="grid grid-cols-2">
                    <div
                      className="flex items-center mt-[1rem]"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <p className="font-inter text-sm">High Volatility</p>
                    </div>

                    <input
                      className="border border-[#E5E5E5] h-[40px] shadow-none bg-[#FFF] w-[50%] mt-[1rem] text-center px-[16px]"
                      value={expectedReturns.highvol}
                      name="highvol"
                      onChange={handleReturnsChange}
                    />
                  </div>
                  <div className="grid grid-cols-2">
                    <div
                      className="flex items-center mt-[1rem]"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <p className="font-inter text-sm">Small Cap</p>
                    </div>

                    <input
                      className="border border-[#E5E5E5] h-[40px] shadow-none bg-[#FFF] w-[50%] mt-[1rem] text-center px-[16px]"
                      value={expectedReturns.small_cap}
                      name="small_cap"
                      onChange={handleReturnsChange}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-between">
                <div className="mt-[1rem] bg-[#FAFAFA] p-[1rem] w-[60%] font-inter">
                  <div
                    className="w-full flex justify-between items-center"
                    style={{ alignItems: "center" }}
                  >
                    <div className="flex items-center ">
                      <p className="font-inter text-sm">Investment Amount</p>
                    </div>

                    <input
                      className="border border-[#E5E5E5] h-[40px] shadow-none bg-[#FFF] w-[20%] mt-[1rem] px-[8px] font-sm text-[#3d3d3d] text-center"
                      value={invAmount}
                      onChange={(e) => setInvAmount(parseInt(e.target.value))}
                      type="number"
                    />
                  </div>
                  <InvestedgeSlider
                    min={1000}
                    max={100000}
                    value={typeof invAmount === "number" ? invAmount : 1000}
                    onChange={(e, val) => setInvAmount(val)}
                    step={1}
                  />
                  <div
                    className="w-full flex justify-between items-center"
                    style={{ alignItems: "center" }}
                  >
                    <div className="flex items-center h-full w-[20%]">
                      <p className="font-inter text-sm mb-0">CAGR (%)</p>
                      <Tooltip
                        TransitionComponent={Zoom}
                        placement="right"
                        title="This CAGR is calculated based on the actual past return given by the fund during the selected investment period. This is the annualized growth rate assuming monthly compounding."
                        arrow
                      >
                        <img
                          src={TooltipIcon}
                          alt="tooltip"
                          className="w-[16px] ml-2 "
                        />
                      </Tooltip>
                    </div>
                    <div className="flex justify-end w-[80%]">
                      <div className="w-[30%]">
                        <Dropdown
                          options={["Expected Returns", "Enter Manually"]}
                          selected={expectedReturnsDropdown}
                          setSelected={setExpectedReturnsDropdown}
                          labelName={null}
                        />
                      </div>
                      <input
                        className="border border-[#E5E5E5] h-[40px] shadow-none bg-[#FFF] w-[20%] px-[8px] font-sm text-[#3d3d3d] ml-[16px] text-center"
                        value={cagr}
                        onChange={(e) =>
                          setCagr(
                            e.target.value ? parseInt(e.target.value) : ""
                          )
                        }
                        disabled={
                          expectedReturnsDropdown === "Expected Returns"
                        }
                      />
                    </div>
                  </div>
                  <InvestedgeSlider
                    max={100}
                    min={0}
                    value={cagr ? parseInt(cagr) : 0}
                    onChange={(e, val) => setCagr(val)}
                    disabled={expectedReturnsDropdown === "Expected Returns"}
                    step={1}
                  />
                  <div
                    className="w-full flex justify-between items-center"
                    style={{ alignItems: "center" }}
                  >
                    <div className="flex items-center ">
                      <p className="font-inter text-sm">Time Period (years)</p>
                    </div>

                    <input
                      className="border border-[#E5E5E5] h-[40px] shadow-none bg-[#FFF] w-[20%] mt-[1rem] px-[8px] font-sm text-[#3d3d3d] text-center"
                      value={time}
                      onChange={(e) => setTime(parseInt(e.target.value))}
                      type="number"
                    />
                  </div>
                  <InvestedgeSlider
                    max={100}
                    min={1}
                    value={time ? parseInt(time) : 0}
                    onChange={(e, val) => setTime(val)}
                    step={1}
                  />
                </div>
                <div className="mt-[1rem] bg-[#FAFAFA] p-[2rem] w-[39%] flex flex-col justify-center">
                  <DoughnutChart
                    data={productdata}
                    options={doughnutoptions}
                    width={200}
                    height={200}
                  />
                  <div className="w-full flex flex-col justify-center items-center mt-[48px]">
                    <div className="w-[80%] ">
                      <div className="grid grid-cols-9 gap-[16px] gap-y-[16px]">
                        <div className="rounded-[4px] w-[14px] h-[14px] bg-primary-300" />
                        <h2 className="text-[#3D3D3D] font-inter text-sm leading-[18px] col-span-5">
                          Investment Amount
                        </h2>
                        <h2 className="text-primary-200 font-inter text-sm leading-[18px] col-span-3">
                          {`₹ ${invAmount}`}
                        </h2>
                        <div className="rounded-[4px] w-[14px] h-[14px] bg-[#BFE0FF]" />
                        <h2 className="text-[#3D3D3D] font-inter text-sm leading-[18px] col-span-5">
                          Estimated Returns
                        </h2>
                        <h2 className="text-primary-200 font-inter text-sm leading-[18px] col-span-3">
                          {`₹ ${(
                            (Math.pow(1 + cagr / 100, time) - 1) *
                            invAmount
                          ).toFixed(0)}`}
                        </h2>
                        <div />
                        <h2 className="text-[#3D3D3D] font-inter text-sm leading-[18px] col-span-5 ">
                          You Make
                        </h2>
                        <div className="col-span-3" />
                        <div />
                        <h2 className="text-[#00B031] font-inter font-medium text-[32px] leading-[18px] col-span-7 mt-[8px]">
                          {`₹ ${(
                            Math.pow(1 + cagr / 100, time) * invAmount
                          ).toFixed(0)}`}
                        </h2>
                        <div />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
