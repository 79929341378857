import React, { useState } from "react";
import axios from "axios";
import SuccessModal from "../SuccessModal";
import { styled } from "@mui/material";
const SubscribeInput = styled("input")(({ theme, error }) => ({
  borderRadius: 8,
  outline: "none",
  lineHeight: "15px",
  backgroundColor: "#06071B",
  border: `1px solid ${error ? "#f87171" : "#D9D9D9"}`,
  color: "#B1B5C4",
  fontFamily: "Montserrat",
  fontSize: 14,
  height: "100%",
  padding: "8px 12px",
  "& label": {
    fontFamily: "Inter",
    fontSize: 14,
    color: "#D9D9D9",
    marginLeft: -8,
    marginTop: -4,
  },
  "& label.MuiInputLabel-shrink": {
    marginLeft: 0,
    marginTop: 1,
  },
  "& label.Mui-focused": {
    marginLeft: 1,
    marginTop: 1,
  },
  "& .MuiOutlinedInput-root": {
    lineHeight: 1.286,
    "& input": {
      fontFamily: "Inter",
      fontSize: 14,
      height: "inherit",
      padding: "13px 8px",
      // zIndex: 1,
    },
    "& fieldset": {
      // backgroundColor: e,
      borderColor: "#D9D9D9",
    },
    "&.Mui-focused:not(.Mui-error) fieldset": {
      borderColor: "#D9D9D9",
    },
  },
  "& .Mui-disabled": {
    backgroundColor: "#D9D9D9",
    color: "#D9D9D9",
  },
  "& label.Mui-disabled": {
    backgroundColor: "#D9D9D9",
  },
  "&:focus": {
    border: `2px solid ${error ? "#f87171" : "#3B71FE"}`,
  },
  // "&:hover": {
  //   border: `2px solid ${error ? "#f87171" : "#3B71FE"}`,
  // },
  width: "100%",
  "::placeholder": {
    color: "#B1B5C4",
    fontFamily: "Montserrat",
    fontSize: 16,
  },
  "::-webkit-input-placeholder": {
    color: "#B1B5C4",
    fontFamily: "Montserrat",
    fontSize: 16,
  },
  ":-ms-input-placeholder": {
    color: "#B1B5C4",
    fontFamily: "Montserrat",
    fontSize: 16,
  },
}));
export default function Subscribe() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [text, setText] = useState(
    "Thanks for filling the form. We will reach out to you"
  );
  const handleSubmit = (e) => {
    e.preventDefault();

    let config = {
      headers: {
        "Content-Type": "application/form-data",
      },
    };
    let formdata = new FormData();
    formdata.append("email", email);
    formdata.append("is_subscribed", true);
    if (
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    )
      setError("Enter a valid email!");
    else
      axios
        .post(process.env.REACT_APP_subscriptionURL, formdata, config)
        .then(function () {
          setText(
            "Thank you for subscribing. We look forward to delivering interesting investment insights to your inbox!"
          );
          setOpen(true);
        })
        .catch(function () {
          setText("Subscription with this email address already exists.");
          setOpen(true);
        });
  };
  return (
    <div className="w-full py-10 md:py-12 px-[16px] md:px-[80px] bg-primarybg-200">
      <div className="md:flex md:justify-between">
        <div>
          <p className="text-white font-inter text-lg mb-0">
            Join our newsletter
          </p>
          <p className="text-[#EAECF0] font-inter mt-[8px] md:mt-1 mb-0">
            Get the latest Insights, delivered to your inbox
          </p>
        </div>
        <div className="md:flex mt-8 md:mt-0 md:items-center">
          <div className="w-full md:w-64 h-[44px] md:h-full relative">
            <SubscribeInput
              type="text"
              name="email"
              error={error}
              placeholder="Enter your email"
              value={email}
              onChange={(e) => {
                if (
                  !String(e.target.value)
                    .toLowerCase()
                    .match(
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    )
                )
                  setError(error);
                else setError("");
                setEmail(e.target.value);
              }}
            />
            <div className="absolute">
              {error && (
                <div className="px-[6px] pt-[4px] font-inter text-sm text-red-400">
                  {error}
                </div>
              )}
            </div>
          </div>
          {/* <input className="w-full md:w-64 h-[44px] md:h-3/4 px-2 rounded-lg border border-[#D0D5DD] shadow-[0px_1px_2px_rgba(16,24,40,0.05)] placeholder:font-inter placeholder:text-[#667085]" /> */}
          <button
            className="md:ml-4 h-[44px] md:h-full text-white text-center w-full md:w-28 rounded-lg bg-primaryblue-200 font-inter mt-8 md:mt-0"
            onClick={handleSubmit}
          >
            Subscribe
          </button>
        </div>
      </div>
      <SuccessModal
        open={open}
        setOpen={setOpen}
        text={text}
        onClose={() => {
          setEmail("");
          setOpen(false);
        }}
      />
    </div>
  );
}
