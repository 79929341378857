import { INVALID_OTP } from "../actions/type";
const initialState = null;
export default function InvalidOtpReducer(state = initialState, action) {
  switch (action.type) {
    case INVALID_OTP:
      return action.payload;

    default:
      return state;
  }
}
