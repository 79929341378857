import React from "react";
import "../../../static/css/existing/dashboard_styles.css";
import PieChart from "../../dashboard/PieChart";
import { useSelector } from "react-redux";
import { Bars } from "react-loader-spinner";
import { GraphColorPalette } from "../../../components/colors/graphColorPalette";
const mcapoptions = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  plugins: {
    labels: [
      {
        render: "label",
        position: "outside",
        arc: true,
        fontColor: "#323232",
      },
      {
        render: "percentage",
        fontColor: "#fff",
      },
    ],
    datalabels: {
      display: false,
    },
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        var dataset = data.datasets[tooltipItem.datasetIndex];
        var meta = dataset._meta[Object.keys(dataset._meta)[0]];
        var total = meta.total;
        var currentValue = dataset.data[tooltipItem.index];
        var percentage = parseFloat(((currentValue / total) * 100).toFixed(1));
        return Math.round(percentage) + "%";
      },
      title: function (tooltipItem, data) {
        return data.labels[tooltipItem[0].index];
      },
    },
  },
};

export default function AssetAllocationCard() {
  const generalcomment = useSelector(
    (state) => state.researchAssets.gen_comment_on_profile
  );
  const allocationData = useSelector((state) =>
    state.researchAssets.data_summary
      ? state.researchAssets.data_summary
      : {
          gen_comment_on_profile: "Something Failed",
          data_summary: [
            { field: "", opt_allocation: 0, target: 0, rationale: "" },
            { field: "", opt_allocation: 0, target: 0, rationale: "" },
            { field: "", opt_allocation: 0, target: 0, rationale: "" },
            { field: "", opt_allocation: 0, target: 0, rationale: "" },
            { field: "", opt_allocation: 0, target: 0, rationale: "" },
            { field: "", opt_allocation: 0, target: 0, rationale: "" },
            { field: "", opt_allocation: 0, target: 0, rationale: "" },
            { field: "", opt_allocation: 0, target: 0, rationale: "" },
            { field: "", opt_allocation: 0, target: 0, rationale: "" },
          ],
        }
  );
  const pieGraph = (graphData, labelData) => {
    return {
      labels: labelData,
      datasets: [
        {
          data: graphData,
          backgroundColor: GraphColorPalette,
          borderColor: GraphColorPalette,
          borderWidth: 1,
        },
      ],
    };
  };
  return (
    <>
      {generalcomment !== "loading" ? (
        <div className="bg-white px-[2rem] py-[1rem] rounded-[0.5rem] mt-4">
          <h3 className="font-inter text-[20px] mb-[0.5rem] not-italic font-medium leading-[30px]">
            Asset allocation
          </h3>
          <p className="font-inter text-sm text-neutralBlack leading-6">
            {generalcomment}
          </p>
          <div className="w-full flex justify-evenly mt-[1rem] mb-[3rem]">
            <div>
              <PieChart
                data={pieGraph(
                  [
                    allocationData[6].opt_allocation,
                    allocationData[8].opt_allocation,
                    allocationData[7].opt_allocation,
                  ],
                  ["Equity", "Alternates", "Debt"]
                )}
                options={mcapoptions}
                width={240}
                height={240}
              />
              <p className="w-full text-center"> Asset Class</p>
            </div>
            <div>
              <PieChart
                data={pieGraph(
                  [
                    allocationData[3].opt_allocation,
                    allocationData[4].opt_allocation,
                    allocationData[5].opt_allocation,
                  ],
                  ["Large Cap", "Mid Cap", "Small Cap"]
                )}
                options={mcapoptions}
                width={240}
                height={240}
              />
              <p className="w-full text-center"> Equity</p>
            </div>
            <div>
              <PieChart
                data={pieGraph(
                  [
                    allocationData[1].opt_allocation,
                    1 - allocationData[1].opt_allocation,
                  ],
                  ["AAA & above", "AAA & below"]
                )}
                options={mcapoptions}
                width={240}
                height={240}
              />
              <p className="w-full text-center"> Debt</p>
            </div>
          </div>
          <div className="rounded-[6px] mt-[2rem] min-h-[335px]">
            <table className="investedge-table" style={{ width: "100%" }}>
              <colgroup>
                <col width="15%" />
                <col width="22%" />
                <col width="22%" />
                <col width="41%" />
              </colgroup>
              <tbody>
                <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                  <th
                    className="font-normal"
                    style={{
                      paddingLeft: "1rem",
                    }}
                  >
                    Asset Class
                  </th>

                  <th className="font-normal" style={{ textAlign: "center" }}>
                    Target Allocation
                  </th>
                  <th className="font-normal" style={{ textAlign: "center" }}>
                    Recommended Allocation
                  </th>
                  <th className="font-normal" style={{ textAlign: "center" }}>
                    Rationale
                  </th>
                </tr>

                <tr style={{ borderBottom: "1px solid #e0dddd" }}>
                  <td
                    style={{
                      fontWeight: "500",
                      color: "#3D3D3D",
                      paddingLeft: "1rem",
                    }}
                  >
                    Equity
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      color: "#3D3D3D",
                    }}
                  >
                    {`${Math.round(allocationData[6].target * 100)}%`}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      color: "#3D3D3D",
                    }}
                  >
                    {`${Math.round(allocationData[6].opt_allocation * 100)}%`}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      color: "#3D3D3D",
                    }}
                  >
                    {allocationData[6].rationale}
                  </td>
                </tr>
                <tr style={{ borderBottom: "1px solid #e0dddd" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                    }}
                  >
                    Large Cap
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {`${Math.round(allocationData[3].target * 100)}%`}
                  </td>
                  <td style={{ textAlign: "center" }}>{`${Math.round(
                    allocationData[3].opt_allocation * 100
                  )}%`}</td>

                  <td style={{ textAlign: "center" }}>
                    {allocationData[3].rationale}
                  </td>
                </tr>
                <tr style={{ borderBottom: "1px solid #e0dddd" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                    }}
                  >
                    Mid Cap
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {`${Math.round(allocationData[4].target * 100)}%`}
                  </td>
                  <td style={{ textAlign: "center" }}>{`${Math.round(
                    allocationData[4].opt_allocation * 100
                  )}%`}</td>

                  <td style={{ textAlign: "center" }}>
                    {allocationData[4].rationale}
                  </td>
                </tr>
                <tr style={{ borderBottom: "1px solid #e0dddd" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                    }}
                  >
                    Small Cap
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {`${Math.round(allocationData[5].target * 100)}%`}
                  </td>
                  <td style={{ textAlign: "center" }}>{`${Math.round(
                    allocationData[5].opt_allocation * 100
                  )}%`}</td>

                  <td style={{ textAlign: "center" }}>
                    {allocationData[5].rationale}
                  </td>
                </tr>
                <tr style={{ borderBottom: "1px solid #e0dddd" }}>
                  <td
                    style={{
                      fontWeight: "500",
                      color: "#3D3D3D",
                      paddingLeft: "1rem",
                    }}
                  >
                    Debt
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      color: "#3D3D3D",
                    }}
                  >
                    {`${Math.round(allocationData[7].target * 100)}%`}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      color: "#3D3D3D",
                    }}
                  >
                    {`${Math.round(allocationData[7].opt_allocation * 100)}%`}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      color: "#3D3D3D",
                    }}
                  >
                    {allocationData[7].rationale}
                  </td>
                </tr>
                <tr style={{ borderBottom: "1px solid #e0dddd" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                    }}
                  >
                    Duration
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {`${allocationData[0].target.toFixed(1)} yrs`}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {`${allocationData[0].opt_allocation.toFixed(1)} yrs`}{" "}
                  </td>

                  <td style={{ textAlign: "center" }}>
                    {allocationData[0].rationale}
                  </td>
                </tr>
                <tr style={{ borderBottom: "1px solid #e0dddd" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                    }}
                  >
                    Credit
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {`${Math.round(allocationData[1].target * 100)}%`}
                  </td>
                  <td style={{ textAlign: "center" }}>{`${Math.round(
                    allocationData[1].opt_allocation * 100
                  )}%`}</td>

                  <td style={{ textAlign: "center" }}>
                    {allocationData[1].rationale}
                  </td>
                </tr>
                <tr style={{ borderBottom: "1px solid #e0dddd" }}>
                  <td
                    style={{
                      fontWeight: "500",
                      color: "#3D3D3D",
                      paddingLeft: "1rem",
                    }}
                  >
                    Alternate
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      color: "#3D3D3D",
                    }}
                  >
                    {`${Math.round(allocationData[8].target * 100)}%`}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      color: "#3D3D3D",
                    }}
                  >
                    {`${Math.round(allocationData[8].opt_allocation * 100)}%`}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      color: "#3D3D3D",
                    }}
                  >
                    {allocationData[8].rationale}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      paddingLeft: "1rem",
                    }}
                  >
                    Volatility
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {`${Math.round(allocationData[2].target * 100)}%`}
                  </td>
                  <td style={{ textAlign: "center" }}>{`${Math.round(
                    allocationData[2].opt_allocation * 100
                  )}%`}</td>

                  <td style={{ textAlign: "center" }}>
                    {allocationData[2].rationale}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="bg-white px-[2rem] py-[1rem] rounded-[0.5rem] mt-4">
          <div className="w-full h-[335px] flex justify-center items-center opacity-[0.9] ">
            <Bars color="#1982F8" />
          </div>{" "}
        </div>
      )}
    </>
  );
}
