import React from "react";
import "../../../../../static/css/existing/dashboard_styles.css";
export default function SuitableClients({ fundData }) {
  let suitableClientData = [
    {
      client_name: "Chaitanya",
      recomment: 2.32,
      pitch:
        "Fund has been able to generate better returns compared to other funds in the same category.",
    },
    {
      client_name: "Hemant Jain",
      recomment: 8,
      pitch:
        "Fund has been able to generate better returns compared to other funds in the same category.",
    },
  ];
  const displaySuitableClientTanle = suitableClientData.map(
    (suitableClientData, index) => {
      return (
        <tr key={index} className="cursor-pointer border border-neutralBlack">
          <td className="font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-left pl-[1rem]">
            {suitableClientData.client_name}
          </td>
          <td className="font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
            {`${suitableClientData.recomment} %`}
          </td>
          <td className="font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
            {suitableClientData.pitch}
          </td>
        </tr>
      );
    }
  );
  const FundChecklist = fundData.final_data.risk_insights.map((el, index) => {
    return (
      <ul className="pl-[1rem] list-disc">
        <li className="text-[#6A6A6A] text-[14px] font-normal font-inter mb-[1.5rem]">
          {el}
        </li>
      </ul>
    );
  });

  return (
    <>
      <div
        className="onepager-main-card justify-between flex "
        style={{
          borderRadius: "0px 0px 8px 8px",
        }}
      >
        <div className=" w-[72%]">
          <div
            role="tabpanel"
            className="tab-pane fade show active "
            id="views"
          >
            <div className="rounded-md   border-[1px]  ">
              <table className="w-full">
                <tbody>
                  <tr className="rounded-[10px] h-10 font-inter font-normal border-[1px] border-b-neutralBlack">
                    <th className="pl-2 font-semibold"> Client Name</th>
                    <th className="text-center font-semibold">
                      {" "}
                      % Recommended
                    </th>
                    <th className="text-center font-semibold">Pitch</th>
                  </tr>
                  {displaySuitableClientTanle}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="border-[1px]  w-[27%]  rounded-[8px] bg-[#FFF] onepager-return-right-card p-4 h-[460px] ml-4">
          <p
            className="onepager-heading"
            style={{
              fontSize: "14px",
            }}
          >
            Fund Checklist
          </p>
          {FundChecklist}
        </div>
      </div>
    </>
  );
}
