import React from "react";
import Query from "../../../assets/img/telegram_app.svg";

export default function EmptyTable() {
  return (
    <>
      <div className=" w-full min-h-[350px] flex justify-center items-center flex-col">
        <img src={Query} alt="err" />
        <br />
        <h3 className=" font-inter text-[24x] font-medium leading-[30px] tracking-[0.1133817583322525px] mb-4 text-center">
          Run Query for results
        </h3>
      </div>
    </>
  );
}
