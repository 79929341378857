import React, { useState, useEffect } from "react";
import BdayPopper from "../../assets/img/bday_popper.svg";
import { useDispatch, useSelector } from "react-redux";
import { GetBirthdayThisMonth } from "../../actions/actions";
import ClientCard from "./ClientCard";
import InvestedgeModal from "../../components/modal/InvestedgeModal";
import { IoIosClose } from "react-icons/io";
import InvestedgePagination from "../../components/pagination/InvestedgePagination";
export default function BdayModal({ bdaymodal, onClose }) {
  const clientData = useSelector((state) =>
    state.birthdayThisMonth.sort(
      (a, b) =>
        String(new Date(a.client_dob).getDate()).padStart(2, "0") -
        String(new Date(b.client_dob).getDate()).padStart(2, "0")
    )
  );
  const [pageNumber, setPageNumber] = useState(0);
  const clientsPerPage = 8;
  const dispatch = useDispatch();
  const pagesVisited = pageNumber * clientsPerPage;
  const pageCount = Math.ceil(clientData.length / clientsPerPage);
  const changePage = (e, val) => {
    setPageNumber(val - 1);
  };
  useEffect(() => {
    dispatch(GetBirthdayThisMonth());
  }, [dispatch]);
  return (
    <InvestedgeModal
      aria-labelledby="Client Birthdays this Month"
      aria-describedby=" Client Birthdays this Month"
      sx={{
        minHeight: "85vh",
        width: "80vw",
        marginRight: "auto",
        marginLeft: "auto",
        padding: "1rem",
      }}
      open={bdaymodal}
      handleclose={onClose}
    >
      <div className="flex justify-between items-center pt-0">
        <div className="flex items-center">
          <img src={BdayPopper} alt="err" className="h-12" />
          <h5 className="card-heading ml-4">Client Birthdays this Month</h5>
        </div>
        <IoIosClose size={30} onClick={onClose} />
      </div>
      <div className="w-full grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-5 pt-8">
        {clientData
          .slice(pagesVisited, pagesVisited + clientsPerPage)
          .map((client, index) => {
            return <ClientCard client={client} key={index} />;
          })}
      </div>
      <div className="w-full flex justify-end absolute bottom-4 right-4">
        <InvestedgePagination
          count={pageCount}
          defaultPage={1}
          page={pageNumber + 1}
          onChange={changePage}
          color="primary"
        />
      </div>
    </InvestedgeModal>
  );
}
