import React from "react";
import { IoIosClose } from "react-icons/io";
import InvestedgeModal from "../../components/modal/InvestedgeModal";
import PortfolioPic from "../../assets/img/modelportfoliothumbnailwhitebg.svg";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import InvestedgeButtonHollow from "../../components/buttons/InvestedgeButtonHollow";
import { useDispatch } from "react-redux";
import { DeleteModelPortfolio } from "../../actions/actions";
export default function DeletePortfolioModal({
  open,
  onClose,
  portfolio,
  setPortfolioType,
}) {
  const dispatch = useDispatch();
  return (
    <InvestedgeModal
      aria-labelledby="Delete Portfolio Modal"
      aria-describedby="Delete Portfolio Modal"
      //   sx={{ height: "60vh", margin: "auto" }}
      open={open}
      handleclose={onClose}
    >
      <div className="modal-header border-0 p-0 flex justify-end w-full">
        <IoIosClose onClick={onClose} size={30} />
      </div>
      <img src={PortfolioPic} alt="err" className="mx-auto" />
      <p className="font-inter font-medium text-neutralBlack mb-2 text-center mt-8">
        You are about to Delete
      </p>
      <p className="font-inter font-medium text-primary-200 text-center">
        {portfolio.portfolio_name}
      </p>
      <div className="w-full flex justify-between mt-6">
        {" "}
        <InvestedgeButtonHollow onClick={onClose}>
          Cancel
        </InvestedgeButtonHollow>{" "}
        <InvestedgeButton
          color="error"
          onClick={() => {
            dispatch(DeleteModelPortfolio({ id: portfolio.id }));
            setPortfolioType(0);
            onClose();
          }}
        >
          Delete
        </InvestedgeButton>
      </div>
    </InvestedgeModal>
  );
}
