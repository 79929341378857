import { LOGIN_USER } from "../actions/type";
const initialState = {};
export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER:
      return action.payload;

    default:
      return state;
  }
}
