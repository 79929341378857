import { GET_DASHBOARD_GOLD_PORTFOLIO } from "../actions/type";
const initialState = [];
export default function getDashboardGoldPortfolio(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_DASHBOARD_GOLD_PORTFOLIO:
      return action.payload;
    default:
      return state;
  }
}
