import React from "react";
import { IoIosClose } from "react-icons/io";
import InvestedgeModal from "../../../components/modal/InvestedgeModal";
import parse from "html-react-parser";
export default function CoverMarketTopicModal({ topic, open, onClose }) {
  return (
    <InvestedgeModal
      aria-labelledby={`CoverMarketTopicModal ${topic.id}`}
      aria-describedby={`CoverMarketTopicModal ${topic.id}`}
      open={open}
      handleclose={onClose}
    >
      <div className="flex justify-between border-0 pt-0">
        <h5 className="font-medium text-xl align-left p-0 font-inter text-neutralBlack">
          {topic.question}
        </h5>
        <IoIosClose size={30} onClick={onClose} />
      </div>
      <div className="modal-body text-sm mt-4">
        <p className="font-inter text-[14px] font-normal leading-[20px] tracking-[0em] text-left">
          {parse(topic.answer)}
        </p>
      </div>
    </InvestedgeModal>
  );
}
