import React from "react";
import { ClientSidebarData } from "./clientSidebarData";
import "../../static/css/existing/dashboard_styles.css";
import { useLocation } from "react-router-dom";
export default function ClientSidebar() {
  const location = useLocation();
  return (
    <nav className="clientsidenavbar">
      <div className="bg-white h-full rounded-[0.5rem] p-4">
        {ClientSidebarData.map((item, index) => {
          return (
            <div
              key={index}
              className={
                location.pathname === item.path
                  ? "bg-[#f3f3f3] text-[14px] h-[40px] border-l-[6px] border-primary-200 text-primary-200 pt-[0.573rem] mb-[10px] pl-[calc(1.3rem-6px)] rounded-md"
                  : "cursor-pointer text-[14px] h-[40px]  list-none pl-[1.3rem] pr-[1.3rem] pt-[0.573rem] no-underline duration-[0.25s] w-[100%] font-inter text-[#777] hover:bg-[#f3f3f3] hover:text-primary-200 hover:mb-[10px]"
              }
            >
              {item.title}
            </div>
          );
        })}
      </div>
    </nav>
  );
}
