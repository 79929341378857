import React, { useState } from "react";
import InvestedgePagination from "../../../components/pagination/InvestedgePagination";
import EmptyTag from "../../../assets/img/emptyTagInvestments.svg";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import TagInvestmentIndex from "../TagInvestments/TagInvestmentIndex";
import { GetGoalDetails } from "../../../actions/actions";
import { useDispatch } from "react-redux";
export default function Investments({ goalDetails, id, title }) {
  const data = goalDetails.client_investments;
  const [pageNumber, setPageNumber] = useState(0);
  const [tagsOpen, setTagsOpen] = useState(false);
  const clientsPerPage = 5;
  const pagesVisited = pageNumber * clientsPerPage;
  const pageCount = Math.ceil(data.length / clientsPerPage);
  const changePage = (e, val) => {
    setPageNumber(val - 1);
  };
  const dispatch = useDispatch();
  const displaydata = data
    .slice(pagesVisited, pagesVisited + clientsPerPage)
    .map((client, index) => {
      return (
        <tr key={index} className="border-b border-[#e0dddd] h-[68px]">
          <td
            style={{
              textAlign: "left",
              paddingLeft: "1rem",
            }}
            className=" font-inter text-[14px] not-italic font-normal leading-[20px] tracking-[0em] p-[1rem] text-left"
          >
            {client.name}
          </td>
          <td className=" font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
            {client.product_type}
          </td>
          <td className=" font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
            {client.asset_type}
          </td>
          <td className=" font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
            {client.amount.amount + client.amount.denomination}
          </td>
          <td className=" font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
            {(client.holding * 100).toFixed(1)}
          </td>
        </tr>
      );
    });
  return (
    <div className="mt-[3rem]">
      <p className="card-heading">Client Investments</p>
      <div className="rounded-[6px] mt-[1rem] min-h-[400px]">
        {data.length ? (
          <table className="investedge-table w-full ">
            <colgroup>
              <col width="32%" />
              <col width="17%" />
              <col width="17%" />
              <col width="17%" />
              <col width="17%" />
            </colgroup>
            <tbody>
              <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                <th
                  style={{
                    textAlign: "left",
                  }}
                  className="pl-[1rem] text-left font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em]"
                >
                  Product Name
                </th>
                <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-center">
                  Product Type
                </th>
                <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-center">
                  Asset Type
                </th>
                <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-center">
                  Amount(₹)
                </th>
                <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-center">
                  Holding(%)
                </th>
              </tr>
              {displaydata}
            </tbody>
          </table>
        ) : (
          <div className="client-dashboard-table w-full flex flex-col justify-center items-center opacity-[0.9]">
            <img src={EmptyTag} alt="err" className="h-52" />
            <p className="font-medium text-neutralBlack mt-4">
              You haven’t tagged any investments to this goal yet.
            </p>
            <InvestedgeButton
              className="mt-4"
              onClick={() => setTagsOpen(true)}
            >
              Tag Investments
            </InvestedgeButton>
            <TagInvestmentIndex
              open={tagsOpen}
              id={id}
              name={title}
              onClose={() => {
                dispatch(
                  GetGoalDetails(id, {
                    params: {
                      client_id: localStorage.getItem("clientId"),
                    },
                  })
                );
                setTagsOpen(false);
              }}
            />
          </div>
        )}
      </div>
      <div className="flex justify-end mt-4">
        {data.length ? (
          <InvestedgePagination
            count={pageCount}
            defaultPage={1}
            page={pageNumber + 1}
            onChange={changePage}
            color="primary"
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
