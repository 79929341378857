export const ClientSidebarData = [
  {
    title: "Account Details",
    path: "/clientinput/familydetails",
  },
  {
    title: "Individual Details",
    path: "/clientinput/individualdetails",
  },
  {
    title: "Investment Profile",
    path: "/clientinput/investmentprofile",
  },
  {
    title: "Update Portfolios",
    path: "/clientinput/updateportfolios",
  },
];
