import React, { useEffect } from "react";
import Add from "../../../../assets/img/circle_over_plus_blue.svg";
import MFrow from "./MFrow";
import { useState } from "react";
import { usePopper } from "react-popper";
import urls from "../../../../urls";
import { useDispatch, useSelector } from "react-redux";
import {
  getComparisonData,
  GetDashboardMfPortfolio,
  GetDashboardPortfolioList,
  OpenSnackbar,
} from "../../../../actions/actions";
import { TextField } from "@mui/material";
import { Bars } from "react-loader-spinner";
import ClickAwayListener from "react-click-away-listener";
import axios from "axios";
export default function MFscreen() {
  const data = useSelector((store) => store.dashboardMFPortfolio);
  const portfolioList = useSelector(
    (store) => store.dashboardPortfolioPriceList
  );
  const [mfarray, setMfarray] = useState(
    JSON.parse(JSON.stringify(data)).map((y) => {
      return { ...y, purchase_date: new Date(y.purchase_date) };
    })
  );
  const [patchMfArray, setPatchMfArray] = useState([]);
  const [postMfArray, setPostMfArray] = useState([]);
  const [submitActive, setSubmitActive] = useState(false);
  const dispatch = useDispatch();
  const productsData = useSelector((state) => state.getComparisonData);
  const removeMFarray = (i) => {
    let newMfarray = [...mfarray];
    newMfarray.splice(i, 1);
    setMfarray(newMfarray);
  };
  // const portfolioValue = useSelector(
  //   (store) => store.portfolioReviewGetSumary.portfolio_value
  // );
  // const parsePortfolio = (portfolio) => {
  //   if (portfolio / 1000 < 100)
  //     return `₹ ${parseFloat(portfolio / 1000).toFixed(1)} K`;
  //   if (portfolio / 100000 < 100)
  //     return `₹ ${parseFloat(portfolio / 100000).toFixed(1)} L`;
  //   return `₹ ${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  // };
  const [searchBar, setSearchBar] = useState(false);
  const [addedProduct, setAddedProduct] = useState("");
  const [newFund, setNewFund] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [referenceRef, setReferenceRef] = useState(null);
  const [popperRef, setPopperRef] = useState(null);
  const [deleteArray, setDeleteArray] = useState([]);
  const { styles, attributes } = usePopper(referenceRef, popperRef, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        enabled: true,
      },
      {
        name: "flip",
        options: {
          fallbackPlacements: ["bottom", "bottom"],
        },
      },
    ],
  });
  useEffect(() => {
    dispatch(
      GetDashboardMfPortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setMfarray(data);
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    newFund.length >= 3 &&
      dispatch(
        getComparisonData({ params: { name: newFund, type: "Mutual Fund" } })
      );
    if (newFund.length >= 3) setIsActive(true);
    else setIsActive(false);
    // eslint-disable-next-line
  }, [newFund]);
  useEffect(() => {
    if (deleteArray.length || postMfArray.length || patchMfArray.length)
      setSubmitActive(true);
    else setSubmitActive(false);
  }, [deleteArray, postMfArray, patchMfArray]);

  const handleSubmit = async () => {
    let newPatchList = patchMfArray.map((item) => {
      var dd = String(item.purchase_date.getDate()).padStart(2, "0");
      var mm = String(item.purchase_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.purchase_date.getFullYear();
      return {
        id: item.id,
        user_id: localStorage.getItem("clientId"),
        mf_id: item.mf_id,
        purchase_date: yyyy + "-" + mm + "-" + dd,
        count_units: parseFloat(item.count_units),
        purchase_price: parseFloat(item.purchase_price),
        current_price: parseFloat(item.current_price),
      };
    });
    let newPostList = postMfArray.map((item) => {
      var dd = String(item.purchase_date.getDate()).padStart(2, "0");
      var mm = String(item.purchase_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.purchase_date.getFullYear();
      return {
        mf_id: item.mf_id,
        user_id: localStorage.getItem("clientId"),
        count_units: parseFloat(item.count_units),
        purchase_date: yyyy + "-" + mm + "-" + dd,
        purchase_price: parseFloat(item.purchase_price),
        current_price: parseFloat(item.current_price),
      };
    });
    let newDeleteList = deleteArray.map((item) => {
      return {
        id: item.id,
        user_id: localStorage.getItem("clientId"),
        mf_id: item.mf_id,
        count_units: parseFloat(item.count_units),
        purchase_price: parseFloat(item.purchase_price),
        current_price: parseFloat(item.current_price),
      };
    });
    newDeleteList.length &&
      (await axios.post(urls.dashboardMFPortfolioDeleteURL, newDeleteList));
    newPostList.length &&
      (await axios.post(urls.dashboardMFTransactionURL, newPostList));
    newPatchList.length &&
      (await axios.patch(urls.dashboardMFTransactionURL, newPatchList));

    setPatchMfArray([]);
    setPostMfArray([]);
    setDeleteArray([]);
    dispatch(
      GetDashboardPortfolioList({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      GetDashboardMfPortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      OpenSnackbar({
        severity: "success",
        message: "Portfolio Updated Successfully",
      })
    );
  };
  return (
    <div className="w-full">
      <div className="font-medium font-inherit text-[18px] text-neutralBlack">
        Mutual Funds - {portfolioList.total_mutual_fund_value}
      </div>
      <div className="text-primary-200 flex justify-between w-[96%] mt-[4px] text-[14px] font-medium mb-0">
        <div className="flex justify-center items-center h-[56px]">
          <img src={Add} alt="err" />
          <p
            className="text-primary-200 text-[14px] font-medium mb-0 ml-[0.3rem]"
            onClick={
              () => setSearchBar(true)
              // let newMfarray = [...mfarray];
              // newMfarray.unshift({ name: "New Mutual Fund", price: "0" });
              // setMfarray(newMfarray);
            }
          >
            Add Mutual Fund
          </p>
          {searchBar && (
            <div className="w-[200px] select-none ml-[16px]">
              <TextField
                ref={setReferenceRef}
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                  width: "200px",
                }}
                label="Search Fund"
                type="text"
                value={newFund}
                onChange={(e) => setNewFund(e.target.value)}
              />
              {isActive && (
                <ClickAwayListener onClickAway={() => setIsActive(false)}>
                  <div
                    ref={setPopperRef}
                    style={{
                      ...styles.popper,
                      zIndex: 1000000,
                      background: "white",
                    }}
                    {...attributes.popper}
                    className="family-details-dropdown-content"
                  >
                    {productsData[0] === "loading" ? (
                      <div className="h-[300px] flex justify-center items-center">
                        <Bars color="#1982f8" height="60" width="60" />
                      </div>
                    ) : productsData.length ? (
                      productsData.map((option, ind) => (
                        <div
                          key={ind}
                          onClick={() => {
                            setSearchBar(false);
                            setAddedProduct(option.product_id);
                            let newMfarray = [...mfarray];
                            newMfarray.unshift({
                              id: option.product_id,
                              mf_id: option.product_id,
                              name: option.name,
                              count_units: 1,
                              purchase_date: new Date(),
                              purchase_price: 1,
                              current_price: option.nav,
                              current_price_date: new Date(),
                            });
                            setPostMfArray([
                              ...postMfArray,
                              {
                                id: option.product_id,
                                mf_id: option.product_id,
                                name: option.name,
                                count_units: 1,
                                purchase_date: new Date(),
                                purchase_price: 1,
                                current_price: option.nav,
                                current_price_date: new Date(),
                              },
                            ]);
                            setMfarray(newMfarray);
                            setIsActive(false);
                          }}
                          className="family-details-dropdown-item"
                        >
                          {option.name}
                        </div>
                      ))
                    ) : (
                      <div className="h-[300px] flex items-center justify-center w-[200px]">
                        No data found
                      </div>
                    )}
                  </div>
                </ClickAwayListener>
              )}
            </div>
          )}
        </div>
        {/* <p className="asset-handler-text" onClick={() => setMfarray([])}>
          Clear All
        </p> */}
      </div>
      <div
        className="update-portfolio-asset-row-container"
        style={{ height: "55vh" }}
      >
        {mfarray.map((fund, index) => (
          <MFrow
            key={fund.id}
            fund={fund}
            id={index}
            removeMFarray={removeMFarray}
            mfarray={mfarray}
            setMfarray={setMfarray}
            patchMfArray={patchMfArray}
            setPatchMfArray={setPatchMfArray}
            deleteArray={deleteArray}
            setDeleteArray={setDeleteArray}
            data={data}
            postMfArray={postMfArray}
            setPostMfArray={setPostMfArray}
            addedProduct={addedProduct}
          />
        ))}
      </div>
      <div className="flex w-full mt-[24px] pr-[24px] justify-end">
        <button
          className={
            submitActive
              ? "primary-button font-semibold py-2 px-3  shadow-boxShadowButton normal-case active:opacity-[0.8] focus:outline-none"
              : " text-primaryDisabledClr bg-primaryDisabledBgClr font-semibold py-2 px-3 shadow-boxShadowButton normal-case active:opacity-[0.8] focus:outline-none"
          }
          onClick={() => {
            if (
              patchMfArray.length ||
              postMfArray.length ||
              deleteArray.length
            ) {
              setSubmitActive(false);
              handleSubmit();
            }
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
