import React, { useState, useEffect } from "react";
import "../../../static/css/existing/dashboard_styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  OpenSnackbar,
  PostEventLoggingData,
  UpdateProductUnderstandingData,
} from "../../../actions/actions";
import InvestedgeModal from "../../../components/modal/InvestedgeModal";
import { IoIosClose } from "react-icons/io";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import { InputAdornment, OutlinedInput } from "@mui/material";
export default function InvestmentPolicyModal({
  onClose,
  familyMembers,
  setFamilyMembers2,
  selected,
  ipsmodal,
}) {
  const dispatch = useDispatch();
  const reduxData = useSelector((state) => state.clientInputData);
  const [tempfamilyMem, settempfamilyMem] = useState(
    JSON.parse(JSON.stringify(reduxData)).map((y) => y)
  );
  useEffect(() => {
    settempfamilyMem(JSON.parse(JSON.stringify(reduxData)).map((y) => y));
  }, [reduxData]);
  const [errors, setErrors] = useState(null);
  useEffect(() => {
    if (
      parseInt(tempfamilyMem[selected.optionid].ips.equity.target) +
        parseInt(tempfamilyMem[selected.optionid].ips.debt.target) +
        parseInt(tempfamilyMem[selected.optionid].ips.alternates.target) !==
      100
    )
      setErrors("Sum of Equity, Debt and Alternates should be 100");
    else if (
      parseInt(tempfamilyMem[selected.optionid].ips.large_cap.target) +
        parseInt(tempfamilyMem[selected.optionid].ips.mid_cap.target) +
        parseInt(tempfamilyMem[selected.optionid].ips.small_cap.target) !==
      100
    )
      setErrors("Sum of Large Cap, Mid Cap and Small Cap should be 100");
    else if (
      !tempfamilyMem[selected.optionid].ips.large_cap.target ||
      !tempfamilyMem[selected.optionid].ips.mid_cap.target ||
      !tempfamilyMem[selected.optionid].ips.small_cap.target ||
      !tempfamilyMem[selected.optionid].ips.equity.target ||
      !tempfamilyMem[selected.optionid].ips.debt.target ||
      !tempfamilyMem[selected.optionid].ips.alternates.target ||
      !tempfamilyMem[selected.optionid].ips.credit_risk.target ||
      !tempfamilyMem[selected.optionid].ips.alternates_low_vol.target ||
      !tempfamilyMem[selected.optionid].ips.duration.target
    )
      setErrors("Target of assets cannot be empty");
    else setErrors(null);
    // eslint-disable-next-line
  }, [tempfamilyMem]);
  if (!ipsmodal) return null;
  const tableFields = {
    equity: {
      name: "Equity",
      min: Math.max(
        0,
        tempfamilyMem[selected.optionid].ips.equity.target -
          tempfamilyMem[selected.optionid].ips.equity.flexibility
      ).toFixed(0),
      max: Math.min(
        parseInt(tempfamilyMem[selected.optionid].ips.equity.target) +
          parseInt(tempfamilyMem[selected.optionid].ips.equity.flexibility),
        100
      ).toFixed(0),
    },
    debt: {
      name: "Debt",
      min: Math.max(
        0,
        tempfamilyMem[selected.optionid].ips.debt.target -
          tempfamilyMem[selected.optionid].ips.debt.flexibility
      ).toFixed(0),
      max: Math.min(
        parseInt(tempfamilyMem[selected.optionid].ips.debt.target) +
          parseInt(tempfamilyMem[selected.optionid].ips.debt.flexibility),
        100
      ).toFixed(0),
    },
    alternates: {
      name: "Alternates",
      min: Math.max(
        0,
        parseInt(tempfamilyMem[selected.optionid].ips.alternates.target) -
          parseInt(tempfamilyMem[selected.optionid].ips.alternates.flexibility)
      ).toFixed(0),
      max: Math.min(
        parseInt(tempfamilyMem[selected.optionid].ips.alternates.target) +
          parseInt(tempfamilyMem[selected.optionid].ips.alternates.flexibility),
        100
      ).toFixed(0),
    },
    large_cap: {
      name: "Large cap",
      min: Math.max(
        0,
        tempfamilyMem[selected.optionid].ips.large_cap.target -
          tempfamilyMem[selected.optionid].ips.large_cap.flexibility
      ).toFixed(0),
      max: Math.min(
        parseInt(tempfamilyMem[selected.optionid].ips.large_cap.target) +
          parseInt(tempfamilyMem[selected.optionid].ips.large_cap.flexibility),
        100
      ).toFixed(0),
    },
    mid_cap: {
      name: "Mid cap",
      min: Math.max(
        0,
        tempfamilyMem[selected.optionid].ips.mid_cap.target -
          tempfamilyMem[selected.optionid].ips.mid_cap.flexibility
      ).toFixed(0),
      max: Math.min(
        parseInt(tempfamilyMem[selected.optionid].ips.mid_cap.target) +
          parseInt(tempfamilyMem[selected.optionid].ips.mid_cap.flexibility),
        100
      ).toFixed(0),
    },
    small_cap: {
      name: "Small cap",
      min: Math.max(
        0,
        tempfamilyMem[selected.optionid].ips.small_cap.target -
          tempfamilyMem[selected.optionid].ips.small_cap.flexibility
      ).toFixed(0),
      max: Math.min(
        parseInt(tempfamilyMem[selected.optionid].ips.small_cap.target) +
          parseInt(tempfamilyMem[selected.optionid].ips.small_cap.flexibility),
        100
      ).toFixed(0),
    },

    credit_risk: {
      name: "Credit risk",
      min: Math.max(
        0,
        tempfamilyMem[selected.optionid].ips.credit_risk.target -
          tempfamilyMem[selected.optionid].ips.credit_risk.flexibility
      ).toFixed(0),
      max: Math.min(
        parseInt(tempfamilyMem[selected.optionid].ips.credit_risk.target) +
          parseInt(
            tempfamilyMem[selected.optionid].ips.credit_risk.flexibility
          ),
        100
      ).toFixed(0),
    },
    alternates_low_vol: {
      name: "Alternates - Low vol",
      min: Math.max(
        0,
        tempfamilyMem[selected.optionid].ips.alternates_low_vol.target -
          tempfamilyMem[selected.optionid].ips.alternates_low_vol.flexibility
      ).toFixed(0),
      max: Math.min(
        parseInt(
          tempfamilyMem[selected.optionid].ips.alternates_low_vol.target
        ) +
          parseInt(
            tempfamilyMem[selected.optionid].ips.alternates_low_vol.flexibility
          ),
        100
      ).toFixed(0),
    },
  };

  const tableHeaders = ["Asset/rule", "Target", "Flexibility", "Min", "Max"];

  return (
    <>
      <InvestedgeModal
        aria-labelledby="Investment Policy Statement"
        aria-describedby="Investment Policy Statement"
        sx={{ padding: "1rem" }}
        open={ipsmodal}
        handleclose={() => {
          settempfamilyMem(JSON.parse(JSON.stringify(reduxData)).map((y) => y));
          onClose();
        }}
      >
        <div className="flex justify-between border-0 p-0">
          <h5 className="card-heading">Investment Policy Statement</h5>
          <IoIosClose
            size={30}
            onClick={() => {
              settempfamilyMem(
                JSON.parse(JSON.stringify(reduxData)).map((y) => y)
              );
              onClose();
            }}
          />
        </div>
        <div>
          <p
            className="mt-[8px] mb-[0px] font-inter text-neutralBlack"
            style={{
              fontSize: "14px",
            }}
          >
            An investment policy statement (IPS) is a document drafted between a
            portfolio manager and a client that outlines general rules for the
            manager. Specific information on matters such as asset allocation,
            risk tolerance, and liquidity requirements are included in an
            investment policy statement.
          </p>

          <p className="text-error-200 font-medium text-sm h-[16px] mb-[0px] mt-2">
            {errors && errors}
          </p>

          <div className="mt-[4px] shadow-none">
            <table className="w-full rounded-[6px] investedge-table">
              <colgroup>
                <col width="26%" />
                <col width="20%" />
                <col width="20%" />
                <col width="17%" />
                <col width="17%" />
              </colgroup>
              <tbody>
                <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                  {tableHeaders.map((tableHeader, index) => (
                    <th
                      key={index}
                      className="font-medium"
                      style={index ? { textAlign: "center" } : {}}
                    >
                      {tableHeader}
                    </th>
                  ))}
                </tr>

                {Object.keys(tableFields).map((key, index) => {
                  return (
                    <tr className="border-bottom text-sm" key={index}>
                      <td>{tableFields[key].name}</td>
                      <td className="w-full flex justify-center">
                        <OutlinedInput
                          size="small"
                          className="text-sm"
                          required
                          style={{
                            fontFamily: "Inter,sans-sarif",
                            fontWeight: 400,
                            width: "65%",
                          }}
                          value={
                            tempfamilyMem[selected.optionid].ips[key].target
                          }
                          onChange={(e) => {
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              let newtempfamilyMembers = [...tempfamilyMem];
                              newtempfamilyMembers[selected.optionid].ips[
                                key
                              ].target = e.target.value;
                              settempfamilyMem(newtempfamilyMembers);
                            }
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <span className="text-sm font-inter">%</span>
                            </InputAdornment>
                          }
                        />
                      </td>

                      <td>
                        <div className="flex justify-center w-full">
                          <OutlinedInput
                            size="small"
                            className="text-sm"
                            required
                            style={{
                              fontFamily: "Inter,sans-sarif",
                              fontWeight: 400,
                              width: "65%",
                            }}
                            value={
                              tempfamilyMem[selected.optionid].ips[key]
                                .flexibility
                            }
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                let newtempfamilyMembers = [...tempfamilyMem];
                                newtempfamilyMembers[selected.optionid].ips[
                                  key
                                ].flexibility = e.target.value;
                                settempfamilyMem(newtempfamilyMembers);
                              }
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <span className="text-sm font-inter">%</span>
                              </InputAdornment>
                            }
                          />
                        </div>
                      </td>
                      <td className="text-center">{tableFields[key].min}%</td>
                      <td className="text-center">{tableFields[key].max}%</td>
                    </tr>
                  );
                })}
                <tr className="border-bottom text-sm">
                  <td className="text-sm">Duration</td>
                  <td className="w-full flex justify-center">
                    <OutlinedInput
                      size="small"
                      className="text-sm"
                      required
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "65%",
                      }}
                      value={
                        tempfamilyMem[selected.optionid].ips.duration.target
                      }
                      onChange={(e) => {
                        const re = /^\d*\.?\d*$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          let newtempfamilyMembers = [...tempfamilyMem];
                          newtempfamilyMembers[
                            selected.optionid
                          ].ips.duration.target = e.target.value;
                          settempfamilyMem(newtempfamilyMembers);
                        }
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <span className="text-sm font-inter">yrs</span>
                        </InputAdornment>
                      }
                    />
                  </td>

                  <td>
                    <div className="flex justify-center w-full">
                      <OutlinedInput
                        size="small"
                        className="text-sm"
                        required
                        style={{
                          fontFamily: "Inter,sans-sarif",
                          fontWeight: 400,
                          width: "65%",
                        }}
                        value={
                          tempfamilyMem[selected.optionid].ips.duration
                            .flexibility
                        }
                        onChange={(e) => {
                          const re = /^\d*\.?\d*$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            let newtempfamilyMembers = [...tempfamilyMem];
                            newtempfamilyMembers[
                              selected.optionid
                            ].ips.duration.flexibility = e.target.value;
                            settempfamilyMem(newtempfamilyMembers);
                          }
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <span className="text-sm font-inter">yrs</span>
                          </InputAdornment>
                        }
                      />
                    </div>
                  </td>
                  <td className="text-center">
                    {`${Math.max(
                      0,
                      tempfamilyMem[selected.optionid].ips.duration.target -
                        tempfamilyMem[selected.optionid].ips.duration
                          .flexibility
                    )}yrs`}
                  </td>
                  <td className="text-center">{`${Math.min(
                    5,
                    parseFloat(
                      tempfamilyMem[selected.optionid].ips.duration.target +
                        tempfamilyMem[selected.optionid].ips.duration
                          .flexibility
                    ).toFixed(2)
                  )}yrs`}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex justify-end w-[97%] border-0 align-right pr-0">
          <InvestedgeButton
            disabled={errors}
            onClick={() => {
              let newFm = [...familyMembers];
              newFm[selected.optionid].ips =
                tempfamilyMem[selected.optionid].ips;
              !errors && setFamilyMembers2(newFm);
              !errors && dispatch(UpdateProductUnderstandingData(newFm));
              !errors &&
                dispatch(
                  PostEventLoggingData({
                    module_name: "Client Details",
                    event_type: "Save button",
                    url: window.location.href,
                    note: `IPS updated for ${
                      tempfamilyMem[selected.optionid].name
                    }`,
                  })
                );
              !errors &&
                dispatch(
                  OpenSnackbar({
                    severity: "success",
                    message: `IPS for ${
                      tempfamilyMem[selected.optionid].name
                    } updated`,
                  })
                );
              !errors && onClose();
            }}
          >
            Save
          </InvestedgeButton>
        </div>
      </InvestedgeModal>
    </>
  );
}
