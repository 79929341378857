import React from "react";
import AfterloginApp from "../../AfterloginApp";
import { useState } from "react";
import CalculatorBanner from "../CalculatorBanner";
import BannerImage from "../../../assets/img/automated_investment_planner_banner.svg";
import DoughnutChart from "../../../components/charts/DoughnutChart";
import Dropdown from "../../../components/Dropdowns/DropdownWhiteSingle";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import { InputAdornment, OutlinedInput } from "@mui/material";
import DropdownForAttributes from "../../../components/Dropdowns/DropdownForAttributes";
import InvestedgeTextField from "../../../components/textfield/InvestedgeTextField";
export default function AutomatedInvestmentPlanner() {
  const data = {
    labels: ["Total Amount Invested", "Absolute Profit"],
    datasets: [
      {
        data: [3533709, 125909],
        backgroundColor: ["#1982f8", "#A9D5FF"],
        borderColor: ["#1982f8", "#A9D5FF"],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      labels: { render: () => {} },
      datalabels: {
        display: false,
      },
    },
  };
  const [planningData, setPlanningData] = useState({
    sipAmountDenotion: "₹ L",
    sipAmount: 100,
    time: 18,
    topUpValue: 100,
    returns: 8,
  });

  const [investmentFrequency, setInvestmentFrequency] = useState("Monthly");
  const [topUpType, setTopUpType] = useState("Amount");
  const [topUpFrequency, setTopUpFrequency] = useState("Yearly");

  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Product Input"}
        description={
          "Change the view on different products across asset class, product type, and categories"
        }
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className="main-panel h-full">
            <div>
              <div className="h-[160px]">
                <CalculatorBanner
                  heading={"Auotmated Investment Planner (SIP)"}
                  description={
                    "SIPs are the best way to make regular investments without the need to time the market. Use SIP Calculator to plan the client’s investment in a systematic way."
                  }
                  image={BannerImage}
                />
              </div>
              <div className="portfolio-proposal-body-card min-h-[74%] flex justify-between mt-[1rem] border-none shadow-none">
                <div className="w-[49%] ">
                  <div className="w-full flex justify-between items-center mt-[0.5rem]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">SIP Amount (in ₹)</p>
                    </div>
                    <div className="w-[30%] flex justify-between">
                      <div className="w-[40%]">
                        <DropdownForAttributes
                          labelName={null}
                          options={["₹ L", "₹ K", "₹ C"]}
                          selected={planningData}
                          setSelected={setPlanningData}
                          attribute="sipAmountDenotion"
                        />
                      </div>
                      <div className="w-[58%]">
                        <InvestedgeTextField
                          variant="outlined"
                          fullWidth
                          style={{
                            fontFamily: "Inter,sans-sarif",
                            fontWeight: 400,
                          }}
                          label={null}
                          value={planningData.sipAmount}
                          onChange={(e) => {
                            const re = /^\d*[0-9]+\d*$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            )
                              setPlanningData({
                                ...planningData,
                                sipAmount: e.target.value,
                              });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex justify-between items-center mt-[1.5rem]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">
                        Frequency of Investment
                      </p>
                    </div>
                    <div className="w-[17.4%]">
                      <Dropdown
                        selected={investmentFrequency}
                        setSelected={setInvestmentFrequency}
                        options={[
                          "Weekly",
                          "Fortnightly",
                          "Monthly",
                          "Quarterly",
                          "Yearly",
                        ]}
                        labelName={null}
                      />
                    </div>
                  </div>
                  <div className="w-full flex justify-between items-center mt-[1.5rem]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">Time Period</p>
                    </div>
                    <OutlinedInput
                      size="small"
                      className="text-sm"
                      required
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "17.4%",
                      }}
                      value={planningData.time}
                      onChange={(e) => {
                        const re = /^\d*[0-9]+\d*$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setPlanningData({
                            ...planningData,
                            time: e.target.value,
                          });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <span className="text-sm font-inter">yrs</span>
                        </InputAdornment>
                      }
                    />
                  </div>
                  <div className="w-full flex justify-between items-center mt-[1.5rem]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">Top up by</p>
                    </div>

                    <div className="w-[17.4%]">
                      <Dropdown
                        selected={topUpType}
                        setSelected={setTopUpType}
                        options={["Amount", "%"]}
                        labelName={null}
                      />
                    </div>
                  </div>
                  <div className="w-[full] flex justify-end mt-[1.5rem]">
                    <OutlinedInput
                      size="small"
                      className="text-sm w-[17.4%]"
                      value={planningData.topUpValue}
                      onChange={(e) => {
                        const re = /^\d*\.?\d*$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setPlanningData({
                            ...planningData,
                            topUpValue: e.target.value,
                          });
                      }}
                      type="number"
                    />
                  </div>
                  <div className="w-full flex justify-between items-center mt-[1.5rem]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">Frequency of Top Up</p>
                    </div>

                    <div className="w-[17.4%]">
                      <Dropdown
                        selected={topUpFrequency}
                        setSelected={setTopUpFrequency}
                        options={["Yearly", "Quarterly"]}
                        labelName={null}
                      />
                    </div>
                  </div>
                  <div className="w-full flex justify-between items-center mt-[28px]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">
                        Expected Return(in %)
                      </p>
                    </div>
                    <OutlinedInput
                      size="small"
                      className="text-sm"
                      required
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "17.4%",
                      }}
                      value={planningData.returns}
                      onChange={(e) => {
                        const re = /^\d*\.?\d*$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setPlanningData({
                            ...planningData,
                            returns: e.target.value,
                          });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <span className="text-sm font-inter">%</span>
                        </InputAdornment>
                      }
                    />
                  </div>
                  <InvestedgeButton className="mt-4">
                    Calculate
                  </InvestedgeButton>
                </div>
                <div className="w-[45%] pl-[1rem]">
                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      <p className="font-inter text-[#6a6a76] mb-[4px]">
                        Total Amount Invested
                      </p>
                      <p className="font-inter font-semibold text-[24px] text-primary-200 mb-[4px]">
                        ₹ 35,63,000
                      </p>
                      <p className="font-inter font-medium text-[#3D3D3D] mb-[4px]">
                        in Oct 2037
                      </p>
                    </div>
                    <div>
                      <p className="font-inter text-[#6a6a76] mb-[4px]">
                        Future Value of SIPs
                      </p>
                      <p className="font-inter font-semibold text-[24px] text-[#3D3D3D] mb-[4px]">
                        ₹ 1,25,909
                      </p>
                      <p className="font-inter font-medium text-[#3D3D3D] mb-[4px]">
                        in Oct 2037
                      </p>
                    </div>
                  </div>
                  <div className="w-[95%] bg-[#fafafa] p-[24px] mt-[16px]">
                    <DoughnutChart
                      data={data}
                      options={options}
                      width={200}
                      height={200}
                    />
                    <div className="flex justify-center w-full mt-[12px]">
                      <div className="flex items-center ml-[16px]">
                        <div className="bg-primary-200 rounded w-[14px] h-[14px]" />
                        <h2 className="text-xs ml-[8px] mb-0">
                          Total Amount Invested
                        </h2>
                      </div>
                      <div className="flex items-center ml-[16px]">
                        <div className="bg-secondary-200 rounded w-[14px] h-[14px]" />
                        <h2 className="text-xs ml-[8px] mb-0">
                          Absolute Profit
                        </h2>
                      </div>
                    </div>
                  </div>
                  <p className="font-inter text-[#6a6a76] mb-[4px] mt-[24px]">
                    Absolute Profit
                  </p>
                  <p className="font-inter font-semibold text-[24px] text-success-200 mb-[4px]">
                    ₹ 1,25,909
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
