import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../static/css/existing/dashboard_styles.css";
import Lock from "../../assets/img/lock_locked_blue.svg";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  GetDashboardPortfolioList,
  GetReviewPortfolioAnalytics,
  GetReviewPortfolioSummary,
  OpenSnackbar,
} from "../../actions/actions";
export default function InvestedgeGPTSubmenu({ item, pathnames }) {
  let tempbool = false;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  if (
    item.mapkey !== undefined &&
    pathnames.get(item.mapkey).hasOwnProperty(location.pathname)
  ) {
    tempbool = true;
  }
  const [subnav, setSubnav] = useState(tempbool);
  const showSubnav = () => setSubnav(!subnav);
  return item.externalLink ? (
    <div
      className={
        location.pathname === item.path
          ? "active-settings-sidebar-link"
          : "settings-sidebar-link"
        // ? "bg-[#e6e9f7] border-l-[6px] border-primarytext-primary-200 text-primary-200 mb-[10px] pl-[calc(1.3rem_-_6px)]"
        // : " cursor-pointer text-[14px] h-[48px] list-none pl-[1.3rem] pr-[1.3rem] pt-[0.875rem] duration-[0.25s] w-full font-inter text-[#777] no-underline hover:bg-[#e6e9f7] hover:text-primary-200 hover:mb-[10px]"
      }
      onClick={() => {
        dispatch(
          GetReviewPortfolioAnalytics({
            client_id: localStorage.getItem("clientId"),
          })
        );
        dispatch(
          GetDashboardPortfolioList({
            params: {
              client_id: localStorage.getItem("clientId"),
            },
          })
        );
        dispatch(
          GetReviewPortfolioSummary(
            {
              params: {
                client_id: localStorage.getItem("clientId"),
              },
            },
            history
          )
        );
        item.locked &&
          dispatch(
            OpenSnackbar({
              severity: "info",
              message: "Feature will be coming soon",
            })
          );
      }}
    >
      <div className="flex justify-between">
        <div>{item.title}</div>
        <div className="mt-1">
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
        {item.locked && <img src={Lock} alt="err" />}
      </div>
    </div>
  ) : (
    <>
      <Link to={item.path}>
        <div
          className={
            item.links && item.links[location.pathname]
              ? "active-settings-sidebar-link"
              : "settings-sidebar-link"
            // ? "bg-[#e6e9f7] border-l-[6px] border-primarytext-primary-200 text-primary-200 mb-[10px] pl-[calc(1.3rem_-_6px)]"
            // : " cursor-pointer text-[14px] h-[48px] list-none pl-[1.3rem] pr-[1.3rem] pt-[0.875rem] duration-[0.25s] w-full font-inter text-[#777] no-underline hover:bg-[#e6e9f7] hover:text-primary-200 hover:mb-[10px]"
          }
          onClick={() => {
            item.subNav && showSubnav();
            item.locked &&
              dispatch(
                OpenSnackbar({
                  severity: "info",
                  message: "Feature will be coming soon",
                })
              );
          }}
        >
          <div className="flex justify-between">
            <div>{item.title}</div>
            <div className="mt-1">
              {item.subNav && subnav
                ? item.iconOpened
                : item.subNav
                ? item.iconClosed
                : null}
            </div>
            {item.locked ? <img src={Lock} alt="err" /> : ""}
          </div>
        </div>
      </Link>
      {item.subNav && subnav
        ? item.subNav.map((item, index) => {
            return (
              <Link to={item.path} key={index}>
                <div
                  className={
                    location.pathname === item.path
                      ? "mt-[0.3rem] mb-[0.3rem] w-[90%] border-l-[4px] border-primary-200 bg-inputFieldBg h-[35px] ml-auto pl-[calc(1.5rem_-_5px)] flex items-center font-inter no-underline text-primary-200 cursor-pointer text-[14px] duration-[100ms] tracking-[0.5px] rounded-md"
                      : "mt-[0.3rem] mb-[0.3rem] h-[35px] w-[90%] ml-auto pl-[1.5rem] flex items-center no-underline text-primary-200 cursor-pointer text-[14px] duration-[100ms] tracking-[0.5px] font-inter hover:bg-[#f3f3f3]"
                  }
                  key={index}
                >
                  <div className="subnavlabel">{item.title}</div>
                </div>
              </Link>
            );
          })
        : ""}
    </>
  );
}
