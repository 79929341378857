import React, { useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import AfterloginApp from "../../AfterloginApp";
import PortfolioFirstStep from "./firstStep/PortfolioFirstStep";
// import StepConnector, {
//   stepConnectorClasses,
// } from "@mui/material/StepConnector";
// import { styled } from "@mui/material/styles";
import PortfolioSecondStep from "./secondStep/PortfolioSecondStep";
import PortfolioThirdStep from "./thirdStep/PortfolioThirdStep";
import PortfolioFourthStep from "./fourthStep/PortfolioFourthStep";
import PortfolioFifthStep from "./fifthStep/PortfolioFifthStep";
import PortfolioSixthStep from "./sixthStep/PortfolioSixthStep";
import { useLocation } from "react-router-dom";
export default function PortfolioProposalStepperSection() {
  const labels = [
    "Current Portfolio Summary",
    "Asset Allocation & Returns Simulator",
    "IPS Check",
    "Review Summary & Actionable",
    "Review Template",
    "Finish",
  ];
  const location = useLocation();
  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <PortfolioFirstStep
            handleNext={handleNext}
            formValue={formValue}
            portfolioProducts={portfolioProducts}
            setPortfolioProducts={setPortfolioProducts}
            clientId={clientId}
          />
        );
      case 1:
        return (
          <PortfolioSecondStep
            handleBack={handleBack}
            handleNext={handleNext}
            formValue={formValue}
            setFormValue={setFormValue}
            clientId={clientId}
          />
        );
      case 2:
        return (
          <PortfolioThirdStep
            handleBack={handleBack}
            handleNext={handleNext}
            formValue={formValue}
            setFormValue={setFormValue}
            setPortfolioProducts={setPortfolioProducts}
            clientId={clientId}
            setPortfolioActions={setPortfolioActions}
          />
        );
      case 3:
        return (
          <PortfolioFourthStep
            handleBack={handleBack}
            handleNext={handleNext}
            formValue={formValue}
            portfolioProducts={portfolioProductsFourthStep}
            setPortfolioProducts={setPortfolioProductsFourthStep}
            portfolio={portfolio}
            setPortfolio={setPortfolio}
            portfolioActions={portfolioActions}
            setPortfolioActions={setPortfolioActions}
            clientId={clientId}
          />
        );
      case 4:
        return (
          <PortfolioFifthStep
            handleBack={handleBack}
            handleNext={handleNext}
            formValue={formValue}
            portfolioProducts={portfolioProductsFourthStep}
            clientId={clientId}
            portfolioActions={portfolioActions}
            portfolio={portfolio}
          />
        );
      case 5:
        return (
          <PortfolioSixthStep
            handleBack={handleBack}
            handleNext={handleNext}
            formValue={formValue}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };
  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const QontoConnector = styled(StepConnector)(({ theme }) => ({
  //   [`&.${stepConnectorClasses.active}`]: {
  //     [`& .${stepConnectorClasses.line}`]: {
  //       borderColor: "#784af4",
  //       outerWidth: 3,
  //     },
  //   },
  //   [`&.${stepConnectorClasses.completed}`]: {
  //     [`& .${stepConnectorClasses.line}`]: {
  //       borderColor: "#784af4",
  //     },
  //   },
  // }));
  const [formValue, setFormValue] = useState({
    client_name: "",
    proposal_value: { amount: "", denomination: "₹ C" },
    tax_status: "Resident",
    tax_slab: "₹ 2.5L - 5L",
    risk_score: 50,
    portfolioData: null,
    investment_horizon: "",
    type_of_scheme: "Regular",
    allocation_preference: {
      equity: 0.0,
      debt: 0.0,
      alternate: 0.0,
      large: 0.0,
      mid: 0.0,
      small: 0.0,
      AAA: 0.0,
      duration: 0.0,
      low_volatility: 0.0,
    },
  });
  const [portfolioProducts, setPortfolioProducts] = useState([]);
  const [portfolioProductsFourthStep, setPortfolioProductsFourthStep] =
    useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const [portfolioActions, setPortfolioActions] = useState([]);
  const clientId = location.state;
  return (
    <>
      <div className="parentLoggedinScreen">
        <AfterloginApp />
        <div className="loggedinscreen-container">
          <div className="loggedinscreen">
            <div className="px-8 rounded-[8px] w-full bg-white">
              <div className="h-[20%]">
                <Stepper
                  activeStep={activeStep}
                  sx={{ py: 3 }}
                  alternativeLabel
                >
                  {labels.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </div>
            <div className="h-[80%]">{handleSteps(activeStep)}</div>
          </div>
        </div>
      </div>
    </>
  );
}
