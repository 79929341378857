import React, { useEffect, useState } from "react";
import "../../../static/css/existing/dashboard_styles.css";
import { useSelector, useDispatch } from "react-redux";
import {
  PostEventLoggingData,
  UpdateResearchInvestmentPolicyData,
} from "../../../actions/actions";
import InvestedgeModal from "../../../components/modal/InvestedgeModal";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import { IoIosClose } from "react-icons/io";
import { InputAdornment, OutlinedInput } from "@mui/material";
export default function InvestmentPolicyModal({
  onClose,
  ipsmodal,
  ips,
  setIpsData,
}) {
  const dispatch = useDispatch();
  const reduxData = useSelector((state) => state.researchIps);
  const [newIpsData, setnewIpsData] = useState(
    JSON.parse(JSON.stringify(reduxData))
  );
  useEffect(() => {
    setnewIpsData(JSON.parse(JSON.stringify(reduxData))); // eslint-disable-next-line
  }, [reduxData]);
  const [errors, setErrors] = useState(null);
  useEffect(() => {
    if (
      parseInt(newIpsData.equity.target) +
        parseInt(newIpsData.debt.target) +
        parseInt(newIpsData.alternates.target) !==
      100
    )
      setErrors("Sum of Equity, Debt and Alternates should be 100");
    else if (
      parseInt(newIpsData.large_cap.target) +
        parseInt(newIpsData.mid_cap.target) +
        parseInt(newIpsData.small_cap.target) !==
      100
    )
      setErrors("Sum of Large Cap, Mid Cap and Small Cap should be 100");
    else setErrors(null);
    // eslint-disable-next-line
  }, [newIpsData]);
  const tableFields = {
    equity: {
      name: "Equity",
      min: Math.max(
        0,
        newIpsData.equity.target - newIpsData.equity.flexibility
      ),
      max: Math.min(
        parseInt(newIpsData.equity.target) +
          parseInt(newIpsData.equity.flexibility),
        100
      ),
    },
    debt: {
      name: "Debt",
      min: Math.max(0, newIpsData.debt.target - newIpsData.debt.flexibility),
      max: Math.min(
        parseInt(newIpsData.debt.target) +
          parseInt(newIpsData.debt.flexibility),
        100
      ),
    },
    alternates: {
      name: "Alternates",
      min: Math.max(
        0,
        parseInt(newIpsData.alternates.target) -
          parseInt(newIpsData.alternates.flexibility)
      ),
      max: Math.min(
        parseInt(newIpsData.alternates.target) +
          parseInt(newIpsData.alternates.flexibility),
        100
      ),
    },
    large_cap: {
      name: "Large cap",
      min: Math.max(
        0,
        newIpsData.large_cap.target - newIpsData.large_cap.flexibility
      ),
      max: Math.min(
        parseInt(newIpsData.large_cap.target) +
          parseInt(newIpsData.large_cap.flexibility),
        100
      ),
    },
    mid_cap: {
      name: "Mid cap",
      min: Math.max(
        0,
        newIpsData.mid_cap.target - newIpsData.mid_cap.flexibility
      ),
      max: Math.min(
        parseInt(newIpsData.mid_cap.target) +
          parseInt(newIpsData.mid_cap.flexibility),
        100
      ),
    },
    small_cap: {
      name: "Small cap",
      min: Math.max(
        0,
        newIpsData.small_cap.target - newIpsData.small_cap.flexibility
      ),
      max: Math.min(
        parseInt(newIpsData.small_cap.target) +
          parseInt(newIpsData.small_cap.flexibility),
        100
      ),
    },

    credit_risk: {
      name: "Credit risk",
      min: Math.max(
        0,
        newIpsData.credit_risk.target - newIpsData.credit_risk.flexibility
      ),
      max: Math.min(
        parseInt(newIpsData.credit_risk.target) +
          parseInt(newIpsData.credit_risk.flexibility),
        100
      ),
    },
    alternates_low_vol: {
      name: "Alternates - Low vol",
      min: Math.max(
        0,
        newIpsData.alternates_low_vol.target -
          newIpsData.alternates_low_vol.flexibility
      ),
      max: Math.min(
        parseInt(newIpsData.alternates_low_vol.target) +
          parseInt(newIpsData.alternates_low_vol.flexibility),
        100
      ),
    },
  };

  const tableHeaders = ["Asset/rule", "Target", "Flexibility", "Min", "Max"];
  const handleChange = (e, key) => {
    let temp1 = { ...newIpsData };
    temp1[key][e.target.name] = parseInt(e.target.value);
    setnewIpsData(temp1);
  };
  return (
    <InvestedgeModal
      aria-labelledby="Research screen IPS Modal"
      aria-describedby="To check IPS before evaluating research views"
      sx={{ padding: "1rem" }}
      open={ipsmodal}
      handleclose={onClose}
    >
      <div className="flex justify-between border-0 pt-0">
        <h5 className="card-heading">Investment Policy Statement</h5>
        <IoIosClose
          size={30}
          onClick={() => {
            setnewIpsData(JSON.parse(JSON.stringify(reduxData)));
            onClose();
          }}
        />
      </div>
      <div className="modal-body mt-4">
        <p
          style={{
            fontSize: "14px",
          }}
          className="mb-[0rem]"
        >
          An investment policy statement (IPS) is a document drafted between a
          portfolio manager and a client that outlines general rules for the
          manager. Specific information on matters such as asset allocation,
          risk tolerance, and liquidity requirements are included in an
          investment policy statement.
        </p>

        <p className="text-error-200 font-medium text-sm h-[16px] mb-[0px] mt-[4px]">
          {errors && errors}
        </p>

        <div className=" mt-[4px] !shadow-none border-none">
          <table className="w-full investedge-table">
            <colgroup>
              <col width="26%" />
              <col width="20%" />
              <col width="20%" />
              <col width="17%" />
              <col width="17%" />
            </colgroup>
            <tbody>
              <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                {tableHeaders.map((tableHeader, index) => (
                  <th
                    key={index}
                    className="font-medium"
                    style={index ? { textAlign: "center" } : {}}
                  >
                    {tableHeader}
                  </th>
                ))}
              </tr>

              {Object.keys(tableFields).map((key, index) => {
                return (
                  <tr className="border-bottom text-sm" key={index}>
                    <td>{tableFields[key].name}</td>
                    <td className="w-full flex justify-center items-center">
                      <OutlinedInput
                        size="small"
                        className="text-sm"
                        required
                        style={{
                          fontFamily: "Inter,sans-sarif",
                          fontWeight: 400,
                          width: "65%",
                        }}
                        value={newIpsData[key].target || 0}
                        onChange={(e) => handleChange(e, key)}
                        name="target"
                        endAdornment={
                          <InputAdornment position="end">
                            <span className="text-sm font-inter">%</span>
                          </InputAdornment>
                        }
                      />
                    </td>

                    <td>
                      <div className="w-[100%] flex justify-center items-center">
                        <OutlinedInput
                          size="small"
                          className="text-sm"
                          required
                          style={{
                            fontFamily: "Inter,sans-sarif",
                            fontWeight: 400,
                            width: "65%",
                          }}
                          value={newIpsData[key].flexibility || 0}
                          onChange={(e) => handleChange(e, key)}
                          name="flexibility"
                          endAdornment={
                            <InputAdornment position="end">
                              <span className="text-sm font-inter">%</span>
                            </InputAdornment>
                          }
                        />
                      </div>
                    </td>
                    <td className="text-center">
                      {tableFields[key].min || "0"}%
                    </td>
                    <td className="text-center">
                      {tableFields[key].max || "0"}%
                    </td>
                  </tr>
                );
              })}
              <tr className="border-bottom text-sm">
                <td>Duration</td>
                <td className="w-full flex justify-center items-center">
                  <OutlinedInput
                    size="small"
                    className="text-sm"
                    required
                    style={{
                      fontFamily: "Inter,sans-sarif",
                      fontWeight: 400,
                      width: "65%",
                    }}
                    value={newIpsData.duration.target || "0"}
                    onChange={(e) => {
                      let temp1 = { ...newIpsData };
                      temp1.duration.target = parseInt(e.target.value);
                      setnewIpsData(temp1);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <span className="text-sm font-inter">yrs</span>
                      </InputAdornment>
                    }
                  />
                </td>

                <td>
                  <div className="w-full flex justify-center items-center">
                    <OutlinedInput
                      size="small"
                      className="text-sm"
                      required
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "65%",
                      }}
                      value={newIpsData.duration.flexibility || "0"}
                      onChange={(e) => {
                        let temp1 = { ...newIpsData };
                        temp1.duration.flexibility = parseInt(e.target.value);
                        setnewIpsData(temp1);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <span className="text-sm font-inter">yrs</span>
                        </InputAdornment>
                      }
                    />
                  </div>
                </td>
                <td className="text-center">
                  {`${Math.max(
                    0,
                    newIpsData.duration.target -
                      newIpsData.duration.flexibility || 0
                  )}yrs`}
                </td>
                <td className="text-center">
                  {`${Math.min(
                    5,
                    newIpsData.duration.target +
                      newIpsData.duration.flexibility || 0
                  )}yrs`}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal-footer border-0 flex justify-end mt-4">
        <InvestedgeButton
          disabled={errors}
          onClick={() => {
            !errors && setIpsData(newIpsData);
            !errors &&
              dispatch(
                PostEventLoggingData({
                  module_name: "Research Views",
                  event_type: "Save button",
                  url: window.location.href,
                  note: `IPS saved`,
                })
              );
            !errors && dispatch(UpdateResearchInvestmentPolicyData(newIpsData));
            !errors && onClose();
          }}
        >
          Save
        </InvestedgeButton>
      </div>
    </InvestedgeModal>
  );
}
