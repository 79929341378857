import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import invalidCred from "./invalidCred";
import userDetailsReducer from "./userDetailsReducer";
import getOtpReducer from "./getOtpReducer";
import errorReducer from "./errorReducer";
import invalidEmailReducer from "./invalidEmailReducer";
import invalidOtpReducer from "./invalidOtpReducer";
import marketEventsReducer from "./marketEventsReducer";
import marketFAQ from "./marketFaq";
import postSavedFaq from "./postSavedFaq";
import marketExperts from "./marketExperts";
import marketExpertsConsolidated from "./marketExpertsConsolidated";
import marketConversation from "./marketConversation";
import getResearch from "./getResearch";
import getFundHouseResearchReducer from "./getFundHouseResearchReducer";
import postResearch from "./postResearch";
import postResearchAssets from "./postResearchAssets";
import getProductInput from "./getProductInput";
import postProductInput from "./postProductInput";
import postSetting from "./postSetting";
import getOrg from "./getOrg";
import getOrgMember from "./getOrgMember";
import clientDataReducer from "./clientDataReducer";
import getIpsReducer from "./getIpsReducer";
import submitContactReducer from "./submitContactReducer";
import getAnnualTarget from "./getAnnualTarget";
import getAumBreakup from "./getAumBreakup";
import getAumBreakupProduct from "./getAumBreakupProduct";
import getAumBreakupAsset from "./getAumBreakupAsset";
import getAumBreakupClient from "./getAumBreakupClient";
import getPopularProducts from "./getPopularProducts";
import getSavedFaqs from "./getSavedFaqs";
import getSettingsAbout from "./getSettingsAbout";
import getSettingsGeneral from "./getSettingsGeneral";
import postSettingsGeneralReducer from "./postSettingsGeneralReducer";
import resetProductInput from "./resetProductInput";
import getSettingsModule from "./getSettingsModule";
import getAllocationPreference from "./getAllocationPreference";
import getAnalyticsAPI from "./getAnalyticsAPI";
import getPptAPI from "./getPptAPI";
import loadingpptreducer from "./loadingpptreducer";
import getMarketSectorsReducer from "./getMarketSectorsReducer";
import getRminfoReducer from "./getRminfoReducer";
import getSettingsOperations from "./getSettingsOperations";
import getTeamlead from "./getTeamlead";
import patchTeamLead from "./patchTeamLead";
import getSettingsOperationsArray from "./getSettingsOperationsArray";
import getSettingsRms from "./getSettingsRms";
import postClientInputNew from "./postClientInputNew";
import getComparisonData from "./getComparisonData";
import getProductOnepagerData from "./getProductOnepagerData";
import getProductReckonerData from "./getProductReckonerData";
import getDashboardClients from "./getDashboardClients";
import postDashboardPortfolio from "./postDashboardPortfolio";
import postDashboardInsights from "./postDashboardInsights";
import postDashboardAnalytics from "./postDashboardAnalytics";
import postDashboardPerformance from "./postDashboardPerformance";
import getDashboardDetails from "./getDashboardDetails";
import getDashboardInvestment from "./getDashboardInvestment";
import postDashboardDetails from "./postDashboardDetails";
import postDashboardInvestment from "./postDashboardInvestment";
import postRedeemCapital from "./postRedeemCapital";
import postDeployCapital from "./postDeployCapital";
import postSuitableProducts from "./postSuitableProducts";
import getReviewPortfolioSummaryReducer from "./getReviewPortfolioSummaryReducer";
import patchReviewPortfolioSummary from "./patchReviewPortfolioSummary";
import getReviewPortfolioAnalytics from "./getReviewPortfolioAnalytics";
import getReviewPortfolioIAA from "./getReviewPortfolioIAA";
import getReviewPortfolioIPSTracker from "./getReviewPortfolioIPSTracker";
import getReviewPortfolioActionable from "./getReviewPortfolioActionable";
import postReviewPortfolioActionable from "./postReviewPortfolioActionable";
import getReviewPortfolioPpt from "./getReviewPortfolioPpt";
import getReviewPortfolioAnalyticsFinal from "./getReviewPortfolioAnalyticsFinal";
import getReviewPortfolioIpsData from "./getReviewPortfolioIpsData";
import getClientInputTemplate from "./getClientInputTemplate";
import postClientInputUploadFile from "./postClientInputUploadFile";
import updateSnackbarProps from "./updateSnackbarProps";
import getDashboardPortfolioSummary from "./getDashboardPortfolioSummary";
import postEventLoggingReducer from "./postEventLoggingReducer";
import postClientProfilePicture from "./postClientProfilePicture";
import updateInvestmentPolicyData from "./updateInvestmentPolicyData";
import updateProductUnderstandingData from "./updateProductUnderstandingData";
import updateResearchIpsData from "./updateResearchIpsData";
import getDashboardMFPortfolio from "./getDashboardMFPortfolio";
import getDashboardHoldingReport from "./getDashboardHoldingReport";
import getTrendingFaqs from "./getTrendingFaqs";
import getDashboardStocksPortfolio from "./getDashboardStocksPortfolio";
import getDashboardAifPortfolio from "./getDashboardAifPortfolio";
import getDashboardUnlistedPortfolio from "./getDashboardUnlistedPortfolio";
import getDashboardBondsPortfolio from "./getDashboardBondsPortfolio";
import getDashboardCashPortfolio from "./getDashboardCashPortfolio";
import getDashboardDepositsPortfolio from "./getDashboardDepositsPortfolio";
import getDashboardGoldPortfolio from "./getDashboardGoldPortfolio";
import getDashboardRealEstatePortfolio from "./getDashboardRealEstatePortfolio";
import getDashboardPmsPortfolio from "./getDashboardPmsPortfolio";
import getProposalHistory from "./getProposalHistory";
import getReviewHistory from "./getReviewHistory";
import getBirthdayThisMonth from "./getBirthdayThisMonth";
import getDashboardOffshorePortfolio from "./getDashboardOffshorePortfolio";
import getDashboardPortfolioList from "./getDashboardPortfolioList";
import getDashboardBenchmark from "./getDashboardBenchmark";
import GetSIPAllocationPreference from "./getSIPAllocationPreference";
import GetSIPAnalytics from "./getSIPAnalytics";
import GetSIPPPTAPI from "./getSIPPPT";
import getMFCentralOtp from "./mfCentralOtpReducer";
import getMFCentralPayload from "./mfCentralPayloadReducer";
import postModelPortfolioBookmark from "./postModelPortfolioBookmark";
import getModelPortfolio from "./getModelPortfolio";
import postModelPortfolio from "./postModelPortfolio";
import updatedModelPortfolio from "./updatedModelPortfolio";
import getModelPortfolioProducts from "./getModelPortfolioProducts";
import updatedModelPortfolioProducts from "./updatedModelPortfolioProducts";
import getModelPortfolioAnalytics from "./getModelPortfolioAnalytics";
import getSIPHistory from "./getSIPHistory";
import getGoalsList from "./getGoalsList";
import getGoalsDetailsReducer from "./getGoalDetailsReducer";
import getSIPAdvanceAnalytics from "./getSIPAdvanceAnalytics";
import getReviewAdvanceAnalytics from "./getReviewAdvanceAnalytics";
import getProposalAdvanceAnalytics from "./getProposalAdvanceAnalytics";
import getSipIpsReducer from "./getSipIpsReducer";
import getSipModelPortfolioProducts from "./getSipModelPortfolioProducts";
import GetAcquireClientsAnalytics from "./getAcquireClientsAnalytics";
import getAcquireClientsPptAPI from "./getAcquireClientsPPT";
import getAcquireClientsDashboard from "./getAcquireClientsDashboard";
import getRms from "./getRms";
import getClients from "./getClients";
import thirdPartyLogin from "./thirdPartyLogin";
export default combineReducers({
  tokendata: UserReducer,
  thirdPartyLogin: thirdPartyLogin,
  inavlidCredentials: invalidCred,
  userdata: userDetailsReducer,
  getOtp: getOtpReducer,
  invalidEmail: invalidEmailReducer,
  invalidOtp: invalidOtpReducer,
  errors: errorReducer,
  marketevents: marketEventsReducer,
  faqdata: marketFAQ,
  marketsectordata: getMarketSectorsReducer,
  marketexperts: marketExperts,
  marketexpertsconsolidated: marketExpertsConsolidated,
  conversationdata: marketConversation,
  myresearchData: getResearch,
  researchIps: updateResearchIpsData,
  fundhouseData: getFundHouseResearchReducer,
  postResearch: postResearch,
  researchAssets: postResearchAssets,
  productInputData: getProductInput,
  resetProductInput: resetProductInput,
  getipsdata: getIpsReducer,
  getSipIpsReducer: getSipIpsReducer,
  postProductInputData: postProductInput,
  postContact: postSetting,
  getOrg: getOrg,
  getOrgMember: getOrgMember,
  clientinputdata: clientDataReducer,
  submitcontact: submitContactReducer,
  savedFaqs: getSavedFaqs,
  trendingFaqs: getTrendingFaqs,
  postSaveFaq: postSavedFaq,
  // AUM APIs
  annualTarget: getAnnualTarget,
  aumBreakup: getAumBreakup,
  aumBreakupProduct: getAumBreakupProduct,
  aumBreakupAsset: getAumBreakupAsset,
  aumBreakupClient: getAumBreakupClient,
  dashboardClients: getDashboardClients,
  birthdayThisMonth: getBirthdayThisMonth,
  // PopularProducts APIs
  popularProducts: getPopularProducts,
  //Settings APIs
  settingsAbout: getSettingsAbout,
  settingsGeneral: getSettingsGeneral,
  postSettingsGeneral: postSettingsGeneralReducer,
  settingsModule: getSettingsModule,
  settingsRminfo: getRminfoReducer,
  settingsOperations: getSettingsOperations,
  settingsGetTeamLead: getTeamlead,
  settingsPatchTeamLead: patchTeamLead,
  settingsOperationsArray: getSettingsOperationsArray,
  settingsRms: getSettingsRms,
  //Proposal
  allocationPreference: getAllocationPreference,
  analyticsAPI: getAnalyticsAPI,
  pptAPI: getPptAPI,
  loadingppt: loadingpptreducer,
  proposalHistory: getProposalHistory,
  proposalAdvanceAnalytics: getProposalAdvanceAnalytics,
  //Client Input
  clientinputpostnew: postClientInputNew,
  clients: getClients,
  rms: getRms,
  postClientProfilePicture: postClientProfilePicture,
  getClientInputExcelTemplate: getClientInputTemplate,
  postClientInputUploadFile: postClientInputUploadFile,
  // productInsightData
  getComparisonData: getComparisonData,
  // getProductOnepagerData
  getProductOnepagerData: getProductOnepagerData,
  // productreckonerData
  getProductReckonerData: getProductReckonerData,
  // eventloggingData
  eventLogging: postEventLoggingReducer,
  // Client Dashboard
  dashboardPortfolio: postDashboardPortfolio,
  dashboardInsights: postDashboardInsights,
  dashboardAnalytics: postDashboardAnalytics,
  dashboardPerformance: postDashboardPerformance,
  dashboardPortfolioSummary: getDashboardPortfolioSummary,
  dashboardHoldingReport: getDashboardHoldingReport,
  dashboardGetDetails: getDashboardDetails,
  dashboardPostDetails: postDashboardDetails,
  dashboardGetInvestment: getDashboardInvestment,
  dashboardPostInvestment: postDashboardInvestment,
  clientDashboardData: updateInvestmentPolicyData,
  clientInputData: updateProductUnderstandingData,
  dashboardRedeemCapital: postRedeemCapital,
  dashboardDeployCapital: postDeployCapital,
  dashboardSuitableProducts: postSuitableProducts,
  mfCentralOtp: getMFCentralOtp,
  mfCentralPayload: getMFCentralPayload,
  dashboardMFPortfolio: getDashboardMFPortfolio,
  dashboardStocksPortfolio: getDashboardStocksPortfolio,
  dashboardAifPortfolio: getDashboardAifPortfolio,
  dashboardPmsPortfolio: getDashboardPmsPortfolio,
  dashboardUnlistedPortfolio: getDashboardUnlistedPortfolio,
  dashboardBondsPortfolio: getDashboardBondsPortfolio,
  dashboardCashPortfolio: getDashboardCashPortfolio,
  dashboardDepositsPortfolio: getDashboardDepositsPortfolio,
  dashboardGoldPortfolio: getDashboardGoldPortfolio,
  dashboardRealEstatePortfolio: getDashboardRealEstatePortfolio,
  dashboardOffshorePortfolio: getDashboardOffshorePortfolio,
  dashboardPortfolioPriceList: getDashboardPortfolioList,
  dashboardBenchmark: getDashboardBenchmark,
  //Review Portfolio
  portfolioReviewGetSumary: getReviewPortfolioSummaryReducer,
  portfolioReviewPatchSummary: patchReviewPortfolioSummary,
  portfolioReviewGetAnalytics: getReviewPortfolioAnalytics,
  portfolioReviewGetIpsData: getReviewPortfolioIpsData,
  portfolioReviewGetIAA: getReviewPortfolioIAA,
  portfolioReviewGetIpsTracker: getReviewPortfolioIPSTracker,
  portfolioReviewGetActionable: getReviewPortfolioActionable,
  portfolioReviewPostActionable: postReviewPortfolioActionable,
  portfolioReviewGetFinalAnalytics: getReviewPortfolioAnalyticsFinal,
  portfolioReviewGetPPT: getReviewPortfolioPpt,
  reviewHistory: getReviewHistory,
  snackbarProps: updateSnackbarProps,
  reviewAdvanceAnalytics: getReviewAdvanceAnalytics,
  //SIP
  sipallocationPreference: GetSIPAllocationPreference,
  sipModelPortfolio: getSipModelPortfolioProducts,
  sipanalyticsAPI: GetSIPAnalytics,
  sippptAPI: GetSIPPPTAPI,
  sipHistory: getSIPHistory,
  sipAdvanceAnalytics: getSIPAdvanceAnalytics,
  //Model Portfolio
  postModelPortfolioBookmark: postModelPortfolioBookmark,
  getModelPortfolio: getModelPortfolio,
  getModelPortfolioAnalytics: getModelPortfolioAnalytics,
  postModelPortfolio: postModelPortfolio,
  updatedModelPortfolio: updatedModelPortfolio,
  getModelPortfolioProducts: getModelPortfolioProducts,
  updatedModelPortfolioPorducts: updatedModelPortfolioProducts,
  //Goal Mapping
  goalsList: getGoalsList,
  goalData: getGoalsDetailsReducer,
  //Instant Review
  acquireClientsAnalytics: GetAcquireClientsAnalytics,
  getAcquireClientsPptAPI: getAcquireClientsPptAPI,
  getAcquireClientsDashboard: getAcquireClientsDashboard,
});
