import React, { useState, useEffect, useRef } from "react";
import Cloud from "../../../assets/img/greyuploadcloud.svg";
// import Dropdown from "../../../components/Dropdowns/DropdownWhiteSingle";
import Tick from "../../../assets/img/module_access_type_tick.svg";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from "react-redux";
import {
  GetClientInputTemplate,
  GetDashboardPortfolioList,
  GetReviewPortfolioSummary,
  PostClientInputUploadFile,
  PostEventLoggingData,
} from "../../../actions/actions";
import { Bars } from "react-loader-spinner";
export default function UploadExcelScreen() {
  const dispatch = useDispatch();
  // const [asset, setAsset] = useState("All");
  // const [product, setProduct] = useState("All");
  // const [category, setCategory] = useState("All");
  const uploadFileFlag = useSelector(
    (state) => state.postClientInputUploadFile
  );
  useEffect(() => {
    dispatch(GetClientInputTemplate());
  }, [dispatch]);
  const portfolioUpload = useSelector(
    (store) => store.postClientInputUploadFile
  );
  const templateLink = useSelector(
    (state) => state.getClientInputExcelTemplate
  );
  const linkRef = useRef(null);
  const excelInputRef = useRef(null);
  const downloadexcel = async () => await linkRef.current.click();
  const [file, setFile] = useState(null);
  const [fileUpload, setFileUpload] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    dispatch(
      GetDashboardPortfolioList({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      GetReviewPortfolioSummary({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    ); // eslint-disable-next-line
  }, [uploadFileFlag]);

  return (
    <div className="flex flex-col justify-center items-center h-[90%]">
      <p className=" font-inter font-medium text-[18px] text-neutralBlack mb-[1rem]">
        Upload excel template
      </p>
      <p className="font-inter font-normal text-[14px] text-neutralBlack mb-[1rem]">
        You can upload the portfolio using the standard statements, manually
        edit them in each tab, or use a custom excel template
      </p>
      <div className=" w-[60%] h-full overflow-y-visible min-h-[200px] p-[1rem] mt-[2rem] pb-[2rem] rounded-[8px] border border-[#e0dddd] shadow-uploadexcelScreen flex-col justify-center items-center flex">
        <p className="text-[17px] not-italic font-medium tracking-[0em] text-left text-[#000] mt-0 mb-[0.5rem]">
          Download Template
        </p>

        {/* <tr className="download-template-row">
          <td>
            <Dropdown
              options={["All", "Equity", "Debt", "Multi-Asset", "Alternate"]}
              selected={asset}
              setSelected={setAsset}
              labelName="Asset Class"
            />
          </td>
          <td>
            <Dropdown
              options={["All", "P1", "P2", "P3"]}
              selected={product}
              setSelected={setProduct}
              labelName="Product Type"
            />
          </td>
          <td>
            <Dropdown
              options={[
                "All",
                "Mutual Funds",
                "Stocks",
                "Bonds/MLDs",
                "PMS",
                "AIFs",
                "Cash",
                "Deposits",
                "Gold/Silver",
                "Savings Scheme",
                "Real Estate",
                "EPF/VPF",
                "PPFs",
                "Unlisted Shares",
                "Others",
              ]}
              selected={category}
              setSelected={setCategory}
              labelName="Category"
            />
          </td>
        </tr> */}
        <a href={templateLink} className="hidden" ref={linkRef}>
          Template
        </a>
        <div className="flex w-full justify-center">
          <button
            className="primary-button text-sm mt-[1rem] font-semibold py-1.5 px-3  shadow-boxShadowButton normal-case active:opacity-[0.8] focus:outline-none"
            onClick={() => {
              downloadexcel();
              dispatch(
                PostEventLoggingData({
                  module_name: "Client Profile",
                  event_type: "Download button",
                  url: window.location.href,
                  note: `Template downloaded for ${localStorage.getItem(
                    "clientName"
                  )}`,
                })
              );
            }}
          >
            Download
          </button>
        </div>
      </div>

      {!submitted ? (
        <div
          style={{
            width: "60%",
            height: "auto",
            minHeight: "190px",
            marginTop: "5%",
          }}
          className="family-details-fieldset portfolio-details-fieldset upload-statement-fieldset upload-excel-fieldset"
        >
          <p className="text-[17px] not-italic font-medium tracking-[0em] text-left text-[#000] mt-0 mb-[0.5rem]">
            Upload Files
          </p>
          <img className="w-[25px] h-[25px]" src={Cloud} alt="err" />
          <FileUploader
            multiple={false}
            handleChange={(file) => {
              setFileUpload(true);
              setFile(file);
            }}
            name="file"
            types={["XLS", "XLSX"]}
          >
            <p className="font-inter font-medium text-[14px] mt-[0.25rem] mb-[1rem] text-neutralBlack">
              Drag and Drop account statement
            </p>
          </FileUploader>
          <p className="font-inter font-medium text-[14px] mb-0 text-neutralBlack">
            Or
          </p>
          <tr className="flex justify-between w-full">
            <td className="w-[18%]"></td>
            <td className="w-[23%] flex items-center">
              <input
                ref={excelInputRef}
                className="hidden"
                onInput={() => {
                  setFileUpload(true);
                  setFile(excelInputRef.current.files[0]);
                }}
                type="file"
                accept=".xls,.xlsx"
              />
              {!fileUpload ? (
                <div
                  className="bg-[#e6e9f7] font-inter text-primary-200 py-[0.3rem] px-[1rem] text-center rounded-[0.25rem] text-[14px] font-medium cursor-pointer"
                  onClick={() => excelInputRef.current.click()}
                >
                  Browse
                </div>
              ) : (
                <img src={Tick} alt="err" className="mx-auto" />
              )}
            </td>
            <td className="w-[18%]">
              <button
                className={
                  fileUpload
                    ? "primary-button font-semibold py-1.5 px-3  shadow-boxShadowButton normal-case active:opacity-[0.8] focus:outline-none"
                    : "text-primaryDisabledClr bg-primaryDisabledBgClr font-semibold py-1.5 px-3 shadow-boxShadowButton normal-case active:opacity-[0.8] focus:outline-none"
                }
                onClick={() => {
                  fileUpload &&
                    dispatch(
                      PostClientInputUploadFile({
                        client_id: localStorage.getItem("clientId"),
                        file: file,
                        file_type: 5,
                        password: "",
                      })
                    );
                  fileUpload &&
                    dispatch(
                      PostEventLoggingData({
                        module_name: "Client Profile",
                        event_type: "Submit button",
                        url: window.location.href,
                        note: `${localStorage.getItem(
                          "clientName"
                        )} Portfolio updated`,
                      })
                    );
                  fileUpload && setSubmitted(true);
                }}
              >
                Submit
              </button>
            </td>
          </tr>
        </div>
      ) : portfolioUpload === "loading" ? (
        <div className="client-dashboard-table w-full h-[335px] flex flex-col justify-center items-center opacity-[0.9]">
          <Bars color="#1982F8" />
          <p className=" font-inter text-[14px] font-medium text-neutralBlack mb-[1rem] mt-[1rem]">
            Uploading Portfolio ...
          </p>
        </div>
      ) : portfolioUpload === "failed" ? (
        <p className=" font-inter text-[14px] font-medium text-neutralBlack mb-[1rem] mt-[3rem]">
          Something failed, Try again!
        </p>
      ) : !portfolioUpload.poorly_formatted.length &&
        !portfolioUpload.does_not_exist.length ? (
        <p className=" font-inter text-[14px] font-medium text-neutralBlack mb-[1rem] mt-[3rem]">
          Submitted Successfully!
        </p>
      ) : (
        <>
          <p className="font-inter font-medium text-[14px] text-neutralBlack mb-[1rem] mt-[3rem] w-full text-center">
            Following funds either don't exist or are poorly formatted:
            <ul className="w-full flex list-disc px-8 mt-2">
              {portfolioUpload.poorly_formatted
                .concat(portfolioUpload.does_not_exist)
                .map((item, index) => (
                  <li className="mt-0 ml-[2rem]" key={index}>
                    {item}
                  </li>
                ))}
            </ul>
          </p>
        </>
      )}
    </div>
  );
}
