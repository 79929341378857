import React, { useState, useEffect } from "react";
import Dropdown from "../individualdetails/Dropdown";
import UpdatedClientCard from "../individualdetails/UpdatedClientCard";
import { Link } from "react-router-dom";
import ProductUnderstandingModal from "./ProductUnderstandingModal";
import RiskAssessmentModal from "./RiskAssessmentModal";
import InvestmentPolicyModal from "./InvestmentPolicyModal";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  GetIpsData,
  OpenSnackbar,
  PostClientInputNew,
  PostEventLoggingData,
  UpdateProductUnderstandingData,
} from "../../../actions/actions";
import Dropdown2 from "../individualdetails/DropdownObjectFIelds";
import InvestedgeTextField from "../../../components/textfield/InvestedgeTextField";
import InvestedgeSlider from "../../../components/slider/InvestedgeSlider";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import { InputAdornment, OutlinedInput } from "@mui/material";

export default function InvestmentContent() {
  const dispatch = useDispatch();
  const history = useHistory();
  const clientData = useSelector((state) => state.clientinputdata);
  const [pumodal, setpumodal] = useState(false);
  const [rsmodal, setrsmodal] = useState(false);
  const [ipsmodal, setipsmodal] = useState(false);

  const [options, setOptions] = useState(
    clientData.Members.map((member, index) => {
      return { optionname: member.name, optionid: index };
    })
  );
  const [validationFlag, setValidationFlag] = useState(false);
  const [updatedOptions, setUpdatedOptions] = useState([]);
  const [selected, setSelected] = useState(options[0]);
  const [familyMembers2, setFamilyMembers2] = useState(clientData.Members);
  const [riskText, setRiskText] = useState("Moderate");
  const ipsData = useSelector((state) => state.getipsdata);
  const useEffectDependancy =
    familyMembers2.length !== updatedOptions.length
      ? familyMembers2[selected.optionid].riskscore
      : "";
  useEffect(() => {
    let newfamilyMembers = [...familyMembers2];

    newfamilyMembers[selected.optionid].ips.equity.target = Math.round(
      ipsData[0].equity * 100
    );
    newfamilyMembers[selected.optionid].ips.debt.target = Math.round(
      ipsData[0].debt * 100
    );
    newfamilyMembers[selected.optionid].ips.alternates.target = Math.round(
      ipsData[0].alternates * 100
    );
    newfamilyMembers[selected.optionid].ips.large_cap.target = Math.round(
      ipsData[1].large_cap * 100
    );
    newfamilyMembers[selected.optionid].ips.mid_cap.target = Math.round(
      ipsData[1].mid_cap * 100
    );
    newfamilyMembers[selected.optionid].ips.small_cap.target = Math.round(
      ipsData[1].small_cap * 100
    );
    newfamilyMembers[selected.optionid].ips.duration.target = Math.round(
      ipsData[2].duration
    );
    newfamilyMembers[selected.optionid].ips.credit_risk.target = Math.round(
      ipsData[3].credit_risk * 100
    );
    newfamilyMembers[selected.optionid].ips.alternates_low_vol.target =
      Math.round(ipsData[4].alternates_low_vol * 100);
    setFamilyMembers2(newfamilyMembers);
    dispatch(UpdateProductUnderstandingData(newfamilyMembers));
    // eslint-disable-next-line
  }, [ipsData]);

  useEffect(() => {
    if (familyMembers2.length !== updatedOptions.length) {
      if (
        familyMembers2[selected.optionid].riskscore >= 0 &&
        familyMembers2[selected.optionid].riskscore <= 20
      )
        setRiskText("Conservative");
      if (
        familyMembers2[selected.optionid].riskscore > 20 &&
        familyMembers2[selected.optionid].riskscore <= 40
      )
        setRiskText("Moderately Conservative");
      if (
        familyMembers2[selected.optionid].riskscore > 40 &&
        familyMembers2[selected.optionid].riskscore <= 60
      )
        setRiskText("Moderate");
      if (
        familyMembers2[selected.optionid].riskscore > 60 &&
        familyMembers2[selected.optionid].riskscore <= 80
      )
        setRiskText("Moderately Aggressive");
      if (
        familyMembers2[selected.optionid].riskscore > 80 &&
        familyMembers2[selected.optionid].riskscore <= 100
      )
        setRiskText("Aggressive");
    }
    // eslint-disable-next-line
  }, [useEffectDependancy]);
  useEffect(() => {
    dispatch(UpdateProductUnderstandingData(clientData.Members));
    // eslint-disable-next-line
  }, []);

  let handleValidation = () => {
    let temp = true;
    if (updatedOptions.length !== clientData.Members.length) temp = false;
    for (let i = 0; i < familyMembers2.length; i++) {
      if (
        familyMembers2[i].investmentcorpus.length === 0 ||
        familyMembers2[i].investmenthorizon.length === 0 ||
        familyMembers2[i].flexibility.length === 0
      )
        temp = false;
    }
    setValidationFlag(temp);
  };
  let handleChange = (e) => {
    const re = /^\d*[0-9]+\d*$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      let newfamilyMembers = [...familyMembers2];
      newfamilyMembers[selected.optionid][e.target.name] = e.target.value;
      if (e.target.name === "flexibility")
        Object.keys(newfamilyMembers[selected.optionid].ips).forEach((key) => {
          newfamilyMembers[selected.optionid].ips[key].flexibility = parseInt(
            e.target.value
          );
          if (key === "duration")
            newfamilyMembers[selected.optionid].ips[key].flexibility =
              parseInt(e.target.value) * 0.05;
        });
      if (e.target.name === "investmenthorizon" && e.target.value.length)
        dispatch(
          GetIpsData({
            params: {
              risk_score: familyMembers2[selected.optionid].riskscore,
              investment_horizon: e.target.value,
            },
          })
        );
      setFamilyMembers2(newfamilyMembers);
      dispatch(UpdateProductUnderstandingData(newfamilyMembers));
      handleValidation();
    }
  };
  let handleUpdateClick = () => {
    if (
      !familyMembers2[selected.optionid].investmentcorpus ||
      !familyMembers2[selected.optionid].investmenthorizon ||
      !familyMembers2[selected.optionid].flexibility
    ) {
      let newfamilyMembers = [...familyMembers2];
      if (!familyMembers2[selected.optionid].investmentcorpus)
        newfamilyMembers[selected.optionid].errors.corpus =
          "This field is required";

      if (!familyMembers2[selected.optionid].investmenthorizon)
        newfamilyMembers[selected.optionid].errors.horizon =
          "This field is required";
      if (!familyMembers2[selected.optionid].flexibility)
        newfamilyMembers[selected.optionid].errors.flexibility =
          "This field is required";
      setFamilyMembers2(newfamilyMembers);
      dispatch(UpdateProductUnderstandingData(newfamilyMembers));
      var elmnt = document.getElementById(
        "client-details-investment-details-screen"
      );
      if (
        !familyMembers2[selected.optionid].investmentcorpus ||
        !familyMembers2[selected.optionid].investmenthorizon
      )
        elmnt.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      else
        elmnt.scrollTo({
          top: 400,
          behavior: "smooth",
        });
    } else {
      let newOptions = [...options];
      for (var i = 0; i < newOptions.length; i++) {
        if (newOptions[i] === selected) {
          newOptions.splice(i, 1);
        }
      }
      dispatch(
        PostEventLoggingData({
          module_name: "Client Details",
          event_type: "Next button",
          url: window.location.href,
          note: `Individual Details updated for ${
            familyMembers2[selected.optionid].name
          }`,
        })
      );
      dispatch(
        OpenSnackbar({
          severity: "success",
          message: `Investment profile for ${
            familyMembers2[selected.optionid].name
          } updated`,
        })
      );
      let newUpdatedOptions = updatedOptions;
      newUpdatedOptions.push(selected);
      setOptions(newOptions);
      setUpdatedOptions(newUpdatedOptions);
      setSelected(newOptions[0]);
      handleValidation();
    }
  };
  let handleCancelDetails = (element) => {
    let newOptions = [...options];
    newOptions.push(element);
    let newUpdatedOptions = updatedOptions;
    for (var i = 0; i < newUpdatedOptions.length; i++) {
      if (newUpdatedOptions[i] === element) {
        newUpdatedOptions.splice(i, 1);
      }
    }
    setOptions(newOptions);
    if (newOptions.length === 1) setSelected(newOptions[0]);
    setUpdatedOptions(newUpdatedOptions);
    setValidationFlag(false);
  };
  const handleSliderChange = (event, newValue) => {
    let newfamilyMembers = [...familyMembers2];
    newfamilyMembers[selected.optionid].riskscore = newValue;
    setFamilyMembers2(newfamilyMembers);
    dispatch(UpdateProductUnderstandingData(newfamilyMembers));
  };
  const handleSave = () => {
    dispatch(
      PostEventLoggingData({
        module_name: "Client Details",
        event_type: "Save button",
        url: window.location.href,
        note: `Investment Profile`,
      })
    );
    const newMembers = familyMembers2.map((member) => {
      var dd = String(member.dob.getDate()).padStart(2, "0");
      var mm = String(member.dob.getMonth() + 1).padStart(2, "0");
      var yyyy = member.dob.getFullYear();
      return {
        username: member.name,
        contact_email: "",
        contact: "1234567234",
        dob: yyyy + "-" + mm + "-" + dd,
        info: {
          gender: member.gender[0],
          marital_status: member.status[0],
          dob: yyyy + "-" + mm + "-" + dd,
          pan_number: member.panNumber,
          occupation: member.occupation,
          sector: member.sector,
          address: member.address,
          pincode: member.pincode === "" ? 0 : member.pincode,
          is_primary: member.primary,
          relation_with_primary: member.relationship,
        },
        tax_info: {
          taxResidency: member.taxResidency[0],
          birthCountry: "I",
          residencyStatus: member.residencyStatus[0],
          nationality: member.nationality[0],
          investmentEntity: "50",
          incomeTaxSlab: "50",
          marginalTaxRate: member.taxrate,
          isProfitMaking: true,
          carryFwdShortTermCapitalLoss: {
            amount: parseInt(member.carrystcl),
            denomination: member.carrystcldenotion[2],
          },
          carryFwdLongTermCapitalLoss: {
            amount: parseInt(member.carryftcl),
            denomination: member.carryftcldenotion[2],
          },
          curentShortTermCapitalLoss: {
            amount: parseInt(member.currentstcl),
            denomination: member.currentstcldenotion[2],
          },
          currentLongTermCapitalLoss: {
            amount: parseInt(member.currentftcl),
            denomination: member.currentftcldenotion[2],
          },
        },
        ip_profile: {
          investmentHorizon: member.investmenthorizon,
          investmentCorpus: {
            amount: parseInt(member.investmentcorpus),
            denomination: member.investmentcorpusdenotion[2],
          },
          riskScore: member.riskscore,
        },
        understanding: {
          mfund: {
            interest: member.understanding.mf.checked,
            understanding: member.understanding.mf.value,
          },
          stock: {
            interest: member.understanding.stocks.checked,
            understanding: member.understanding.stocks.value,
          },
          bond: {
            interest: member.understanding.bonds.checked,
            understanding: member.understanding.bonds.value,
          },
          pm: {
            interest: member.understanding.pms.checked,
            understanding: member.understanding.pms.value,
          },
          aif: {
            interest: member.understanding.aif.checked,
            understanding: member.understanding.aif.value,
          },
          cash: {
            interest: member.understanding.cash.checked,
            understanding: member.understanding.cash.value,
          },
          deposit: {
            interest: member.understanding.deposit.checked,
            understanding: member.understanding.deposit.value,
          },
          gold_silver: {
            interest: member.understanding.gold.checked,
            understanding: member.understanding.gold.value,
          },
          small_saving_scheme: {
            interest: member.understanding.savingsschemes.checked,
            understanding: member.understanding.savingsschemes.value,
          },
          estate: {
            interest: member.understanding.realestate.checked,
            understanding: member.understanding.realestate.value,
          },
          epf_vpf: {
            interest: member.understanding.epf.checked,
            understanding: member.understanding.epf.value,
          },
          ppf: {
            interest: member.understanding.ppf.checked,
            understanding: member.understanding.ppf.value,
          },
          unlisted_states: {
            interest: member.understanding.unlisted.checked,
            understanding: member.understanding.unlisted.value,
          },
        },
        ips: {
          equity: {
            target: parseInt(member.ips.equity.target) / 100,
            min: Math.max(
              0,
              (member.ips.equity.target - member.ips.equity.flexibility) / 100
            ),
            max: Math.min(
              1,
              (member.ips.equity.target + member.ips.equity.flexibility) / 100
            ),
          },
          debt: {
            target: parseInt(member.ips.debt.target) / 100,
            min: Math.max(
              0,
              (member.ips.debt.target - member.ips.debt.flexibility) / 100
            ),
            max: Math.min(
              1,
              (member.ips.debt.target + member.ips.debt.flexibility) / 100
            ),
          },
          alternate: {
            target: parseInt(member.ips.alternates.target) / 100,
            min: Math.max(
              0,
              (member.ips.alternates.target -
                member.ips.alternates.flexibility) /
                100
            ),
            max: Math.min(
              1,
              (member.ips.alternates.target +
                member.ips.alternates.flexibility) /
                100
            ),
          },
          multi_assets: {
            target: 200,
            min: 100,
            max: 500,
          },
          large_cap: {
            target: parseInt(member.ips.large_cap.target) / 100,
            min: Math.max(
              0,
              (member.ips.large_cap.target - member.ips.large_cap.flexibility) /
                100
            ),
            max: Math.min(
              1,
              (member.ips.large_cap.target + member.ips.large_cap.flexibility) /
                100
            ),
          },
          mid_cap: {
            target: parseInt(member.ips.mid_cap.target) / 100,
            min: Math.max(
              0,
              (member.ips.mid_cap.target - member.ips.mid_cap.flexibility) / 100
            ),
            max: Math.min(
              1,
              (member.ips.mid_cap.target + member.ips.mid_cap.flexibility) / 100
            ),
          },
          small_cap: {
            target: parseInt(member.ips.small_cap.target) / 100,
            min: Math.max(
              0,
              (member.ips.small_cap.target - member.ips.small_cap.flexibility) /
                100
            ),
            max: Math.min(
              1,
              (member.ips.small_cap.target + member.ips.small_cap.flexibility) /
                100
            ),
          },
          duration: {
            target: parseInt(member.ips.duration.target),
            min: Math.max(
              0,
              member.ips.duration.target - member.ips.duration.flexibility
            ),
            max: Math.min(
              5,
              member.ips.duration.target + member.ips.duration.flexibility
            ),
          },
          aaa_equiv: {
            target: parseInt(member.ips.credit_risk.target) / 100,
            min: Math.max(
              0,
              (member.ips.credit_risk.target -
                member.ips.credit_risk.flexibility) /
                100
            ),
            max: Math.min(
              1,
              (member.ips.credit_risk.target +
                member.ips.credit_risk.flexibility) /
                100
            ),
          },
          alternates: {
            target: parseInt(member.ips.alternates.target) / 100,
            min: Math.max(
              0,
              (member.ips.alternates.target -
                member.ips.alternates.flexibility) /
                100
            ),
            max: Math.min(
              1,
              (member.ips.alternates.target +
                member.ips.alternates.flexibility) /
                100
            ),
          },
        },
      };
    });
    dispatch(
      PostClientInputNew(
        {
          rmid: clientData.rmID,
          account_type: clientData.accountType[0],
          is_prospect: clientData.clientType === "Prospect",
          family_name:
            clientData.accountType === "Family"
              ? clientData.familyName
              : `${familyMembers2[0].name} Family`,
          members: newMembers,
        },
        history,
        familyMembers2
      )
    );
  };
  const marks = [
    {
      value: 0,
      label: "Risk averse",
    },
    {
      value: 100,
      label: "Risk seeker",
    },
  ];

  return (
    <>
      <div className="sticky top-0 bg-[#f3f3f3] ml-[-22px] mr-[-22px] z-[10] pt-[1rem] pl-[6px] pr-[6px]">
        <div className="w-full rounded-t-[0.5rem] h-full bg-[#fff] p-[1rem]">
          <h1 className="card-heading">
            Edit Investment profile for each individual
          </h1>

          <div className="w-[28%] mt-[1.5rem]">
            <Dropdown
              options={options}
              selected={selected}
              setSelected={setSelected}
              labelName="Select Individual"
            />
          </div>

          <h2 className="w-[100%] text-left border-b-[2px] border-[#ece7e7] leading-[0.1em] mt-[2.7rem]">
            <span className="bg-[#fff] text-sm font-inter pr-[10px] pb-[10px]  text-neutralBlack ">
              Edit Details
            </span>
          </h2>
        </div>
      </div>

      <div className="rounded-b-[0.5rem] p-[1rem] m-[-1rem] bg-[#fff]">
        {familyMembers2.length !== updatedOptions.length ? (
          <>
            <table>
              <tbody>
                <tr className="mt-[1rem] w-[98%] flex justify-between">
                  <td className="w-[35%]">
                    <label
                      style={{
                        verticalAlign: "-webkit-baseline-middle",
                      }}
                      className="font-inter text-neutralBlack   mb-0"
                    >
                      Investment corpus
                    </label>
                  </td>

                  <td className="w-[65%] font-inter text-neutralBlack text-sm font-normal mb-0 block">
                    <div className="flex w-[50%] justify-between">
                      <div className="w-[25%]">
                        <Dropdown2
                          options={["₹ K", "₹ L", "₹ C"]}
                          selected={familyMembers2}
                          setSelected={setFamilyMembers2}
                          attribute="investmentcorpusdenotion"
                          labelName={null}
                          index={selected.optionid}
                        />
                      </div>
                      <InvestedgeTextField
                        variant="outlined"
                        required
                        style={{
                          fontFamily: "Inter,sans-sarif",
                          fontWeight: 400,
                          width: "73%",
                        }}
                        label={null}
                        name="investmentcorpus"
                        value={
                          familyMembers2[selected.optionid].investmentcorpus
                        }
                        error={
                          familyMembers2[selected.optionid].errors.corpus
                            ? true
                            : false
                        }
                        helperText={
                          familyMembers2[selected.optionid].errors.corpus
                            ? familyMembers2[selected.optionid].errors.corpus
                            : ""
                        }
                        onChange={(e) => {
                          if (!e.target.value) {
                            let newfamilyMembers = [...familyMembers2];
                            newfamilyMembers[selected.optionid].errors.corpus =
                              "This  field can not be empty";
                            setFamilyMembers2(newfamilyMembers);
                            dispatch(
                              UpdateProductUnderstandingData(newfamilyMembers)
                            );
                          } else {
                            let newfamilyMembers = [...familyMembers2];
                            newfamilyMembers[selected.optionid].errors.corpus =
                              null;
                            setFamilyMembers2(newfamilyMembers);
                            dispatch(
                              UpdateProductUnderstandingData(newfamilyMembers)
                            );
                          }
                          handleChange(e);
                        }}
                      />
                    </div>
                  </td>
                </tr>
                <tr className="w-[98%] flex justify-between mt-0">
                  <td className="w-[35%]"></td>
                  <td className="w-[65%] font-inter text-neutralBlack text-sm font-normal mb-0 block">
                    Investment Corpus means the net worth of financial assets of
                    client
                  </td>
                </tr>
                <tr className="w-[98%] flex justify-between mt-[0.5rem]">
                  <td className="w-[35%]">
                    <label
                      style={{
                        verticalAlign: "-webkit-baseline-middle",
                      }}
                      className=" font-inter text-neutralBlack   mb-0"
                    >
                      Investment horizon
                    </label>
                  </td>
                  <td className="w-[65%] font-inter text-neutralBlack text-sm font-normal mb-0 flex items-center">
                    <OutlinedInput
                      size="small"
                      className="text-sm"
                      required
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "25%",
                        marginRight: "0.5rem",
                      }}
                      min={0}
                      max={100}
                      name="investmenthorizon"
                      value={
                        familyMembers2[selected.optionid].investmenthorizon
                      }
                      error={
                        familyMembers2[selected.optionid].errors.horizon
                          ? true
                          : false
                      }
                      helperText={
                        familyMembers2[selected.optionid].errors.horizon
                          ? familyMembers2[selected.optionid].errors.horizon
                          : ""
                      }
                      onChange={(e) => {
                        if (!e.target.value) {
                          let newfamilyMembers = [...familyMembers2];
                          newfamilyMembers[selected.optionid].errors.horizon =
                            "This  field can not be empty";
                          setFamilyMembers2(newfamilyMembers);
                          dispatch(
                            UpdateProductUnderstandingData(newfamilyMembers)
                          );
                        } else {
                          let newfamilyMembers = [...familyMembers2];
                          newfamilyMembers[selected.optionid].errors.horizon =
                            null;
                          setFamilyMembers2(newfamilyMembers);
                          dispatch(
                            UpdateProductUnderstandingData(newfamilyMembers)
                          );
                        }
                        handleChange(e);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <span className="text-sm font-inter">years</span>
                        </InputAdornment>
                      }
                    />
                  </td>
                </tr>
                <tr className="w-[98%] flex justify-between mt-[0.5rem]">
                  <td className="w-[35%]">
                    <label
                      style={{
                        verticalAlign: "-webkit-baseline-middle",
                      }}
                      className=" font-inter text-neutralBlack   mb-0"
                    >
                      Age
                    </label>
                  </td>
                  <td className="w-[65%] font-inter text-neutralBlack text-sm font-normal mb-0 block">
                    {`${familyMembers2[selected.optionid].age} years`}
                  </td>
                </tr>
                <tr className="w-[98%] flex justify-between mt-[0.5rem]">
                  <td className="w-[35%]">
                    <label
                      style={{
                        verticalAlign: "-webkit-baseline-middle",
                      }}
                      className=" font-inter text-neutralBlack   mb-0"
                    >
                      Risk Assessment questionnaire
                    </label>
                  </td>
                  <td className="w-[65%] font-inter text-neutralBlack text-sm font-normal mb-0 block">
                    <div
                      className=" font-inter text-sm text-primary-200  block mt-0 pt-[0.3rem] cursor-pointer"
                      onClick={() => setrsmodal(true)}
                    >
                      Click to know your composite risk score
                    </div>
                    <RiskAssessmentModal
                      onClose={() => setrsmodal(false)}
                      familyMembers={familyMembers2}
                      setFamilyMembers2={setFamilyMembers2}
                      selected={selected}
                      rsmodal={rsmodal}
                      riskText={riskText}
                    />
                  </td>
                </tr>
                <tr className="mt-[1.5rem] w-[98%] flex justify-between">
                  <td className="w-[35%] pb-0">
                    <label
                      style={{
                        verticalAlign: "-webkit-baseline-middle",
                      }}
                      className=" font-inter text-neutralBlack   mb-0"
                    >
                      Composite risk score
                    </label>
                  </td>
                  <td className="w-[65%] font-inter text-neutralBlack text-sm font-normal mb-0 block pb-0">
                    <InvestedgeSlider
                      className="w-3/4"
                      marks={marks}
                      value={
                        typeof familyMembers2[selected.optionid].riskscore ===
                        "number"
                          ? familyMembers2[selected.optionid].riskscore
                          : 0
                      }
                      onChange={(event, value) =>
                        handleSliderChange(event, value)
                      }
                      onChangeCommitted={(event, value) =>
                        familyMembers2[selected.optionid].investmenthorizon
                          .length &&
                        dispatch(
                          GetIpsData({
                            params: {
                              risk_score: value,
                              investment_horizon:
                                familyMembers2[selected.optionid]
                                  .investmenthorizon,
                            },
                          })
                        )
                      }
                      valueLabelDisplay="on"
                      step={1}
                    />
                  </td>
                </tr>
                <tr className="mt-[0rem] w-[98%] flex justify-between">
                  <td className="pt-0 w-[35%]">
                    <div className="rounded-[8px] text-sm font-inter py-[0.3rem] px-[0.5rem] inline-block bg-primary-accent font-medium text-primary-200">
                      {riskText}
                    </div>
                  </td>
                  <td className="w-[65%] font-inter text-neutralBlack text-sm font-normal mb-0 block pt-0 leading-5 text-justify">
                    <div className="w-[70%]">
                      Composite risk score level decides the overall risk taking
                      capacity of the investor. The risk tolerance helps in
                      deciding the strategic asset allocation.
                    </div>
                  </td>
                </tr>
                <tr className="mt-[1rem] w-[98%] flex justify-between">
                  <td className="w-[35%]">
                    <label
                      style={{
                        verticalAlign: "-webkit-baseline-middle",
                      }}
                      className=" font-inter text-neutralBlack   mb-0"
                    >
                      Flexibility
                    </label>
                  </td>
                  <td className="w-[65%] font-inter text-neutralBlack text-sm font-normal mb-0 flex items-center">
                    <OutlinedInput
                      size="small"
                      className="text-sm"
                      required
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "30%",
                        marginRight: "0.5rem",
                      }}
                      min={0}
                      max={100}
                      name="flexibility"
                      value={familyMembers2[selected.optionid].flexibility}
                      error={
                        familyMembers2[selected.optionid].errors.flexibility
                          ? true
                          : false
                      }
                      helperText={
                        familyMembers2[selected.optionid].errors.flexibility
                          ? familyMembers2[selected.optionid].errors.flexibility
                          : ""
                      }
                      onChange={(e) => {
                        if (!e.target.value) {
                          let newfamilyMembers = [...familyMembers2];
                          newfamilyMembers[
                            selected.optionid
                          ].errors.flexibility = "This  field can not be empty";
                          setFamilyMembers2(newfamilyMembers);
                        } else {
                          let newfamilyMembers = [...familyMembers2];
                          newfamilyMembers[
                            selected.optionid
                          ].errors.flexibility = null;
                          setFamilyMembers2(newfamilyMembers);
                        }
                        handleChange(e);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <span className="text-sm font-inter">%</span>
                        </InputAdornment>
                      }
                    />
                  </td>
                </tr>
                <tr className="mt-[0.5rem] w-[98%] flex justify-between">
                  <td className="w-[35%]"></td>
                  <td className="w-[65%] font-inter text-neutralBlack text-sm font-normal mb-0 block">
                    <div className="w-[70%] leading-5 text-justify">
                      Flexibility decides how much variance is allowed in the
                      strategic asset allocation based on the view provided for
                      different assets.
                    </div>
                  </td>
                </tr>
                <tr className="mt-[0.5rem] w-[98%] flex justify-between">
                  <td className="w-[35%]"></td>
                  <td className="w-[65%] font-inter text-neutralBlack text-sm font-normal mb-0 block">
                    <div className="w-[70%] leading-5 text-justify">
                      For example, if the strategic equity allocation for a
                      moderate risk profile (risk tolerance = 50) is 75% and
                      flexibility is 20%, then the equity allocation can vary
                      from 55% to 95% depending on the view provided on equity
                      asset class. If the view is bearish then equity allocation
                      is close to 55% and if the view is bullish the equity
                      allocation is close to 95%.
                    </div>
                  </td>
                </tr>
                <tr className="mt-[1rem] w-[98%] flex justify-between">
                  <td className="w-[35%]">
                    <label
                      style={{
                        verticalAlign: "-webkit-baseline-middle",
                      }}
                      className=" font-inter text-neutralBlack   mb-0"
                    >
                      Investment policy statement
                    </label>
                  </td>
                  <td className="w-[65%] font-inter text-neutralBlack text-sm font-normal mb-0 block">
                    <div
                      className="font-inter text-sm text-primary-200  block mb-0 pt-[0.3rem] cursor-pointer"
                      onClick={() => setipsmodal(true)}
                    >
                      Click to view and change
                    </div>
                    <InvestmentPolicyModal
                      onClose={() => setipsmodal(false)}
                      familyMembers={familyMembers2}
                      setFamilyMembers2={setFamilyMembers2}
                      selected={selected}
                      ipsmodal={ipsmodal}
                    />
                  </td>
                </tr>
                <tr className="mt-[1rem] w-[98%] flex justify-between">
                  <td className="w-[35%]">
                    <label
                      style={{
                        verticalAlign: "-webkit-baseline-middle",
                      }}
                      className=" font-inter text-neutralBlack   mb-0"
                    >
                      Product Understanding
                    </label>
                  </td>
                  <td className="w-[65%] font-inter text-neutralBlack text-sm font-normal mb-0 block">
                    <div
                      className="font-inter text-sm text-primary-200  block mb-0 pt-[0.3rem] cursor-pointer"
                      onClick={() => setpumodal(true)}
                    >
                      Click to view and change
                    </div>
                    <ProductUnderstandingModal
                      familyMembers={familyMembers2}
                      setFamilyMembers2={setFamilyMembers2}
                      selected={selected}
                      onClose={() => setpumodal(false)}
                      pumodal={pumodal}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="flex justify-end">
              <div
                className="font-inter text-primary-200 bg-primary-accent py-[0.45rem] px-0 text-center  w-[16%] rounded-[0.25rem] mt-[2.5rem] mb-0 mx-0 cursor-pointer"
                onClick={handleUpdateClick}
              >
                Update details
              </div>
            </div>
            {updatedOptions.length !== 0 && (
              <>
                <h2 className=" text-neutralBlack text-sm mt-[0.3rem]">
                  Updated data for
                </h2>
                <div className="flex flex-wrap">
                  {updatedOptions.map((client, index) => {
                    return (
                      <UpdatedClientCard
                        key={index}
                        ind={index}
                        client={client}
                        handleCancelDetails={handleCancelDetails}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="flex justify-center items-center h-[300px]">
              <h2 className="text-[20px] text-primary-200  font-inter">
                Investment Profile Updated!
              </h2>
            </div>

            <h2 className="font-inter text-neutralBlack text-sm mt-[0.3rem]">
              Updated data for
            </h2>
            <div className="flex flex-wrap">
              {updatedOptions.map((client, index) => {
                return (
                  <UpdatedClientCard
                    key={index}
                    ind={index}
                    client={client}
                    handleCancelDetails={handleCancelDetails}
                  />
                );
              })}
            </div>
          </>
        )}
        <div className="flex justify-end items-center w-full mt-[2rem]">
          <Link
            className="mr-4"
            to={{
              pathname: "/clientinput/individualdetails",
              state: {
                familyMembers: familyMembers2,
              },
            }}
          >
            <InvestedgeButton
              onClick={() =>
                dispatch(
                  PostEventLoggingData({
                    module_name: "Client Details",
                    event_type: "Prev button",
                    url: window.location.href,
                    note: `Investment Profile`,
                  })
                )
              }
            >
              Back
            </InvestedgeButton>
          </Link>
          {validationFlag ? (
            <InvestedgeButton onClick={handleSave}>Save</InvestedgeButton>
          ) : (
            <InvestedgeButton disabled>Save</InvestedgeButton>
          )}
        </div>
      </div>
    </>
  );
}
