import React, { useEffect } from "react";
import AIFRow from "./AIFRow";
import { useState } from "react";
import urls from "../../../../urls";
import { useDispatch, useSelector } from "react-redux";
import {
  GetDashboardAifPortfolio,
  GetDashboardPortfolioList,
  OpenSnackbar,
} from "../../../../actions/actions";
import { Bars } from "react-loader-spinner";
import axios from "axios";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
export default function AIFscreen({ id, name }) {
  const data = useSelector((store) => store.dashboardAifPortfolio);
  const [aifarray, setAifarray] = useState(
    JSON.parse(JSON.stringify(data)).map((y) => {
      return { ...y, purchase_date: new Date(y.purchase_date) };
    })
  );
  const [patchAifArray, setPatchAifArray] = useState([]);
  const [submitActive, setSubmitActive] = useState(false);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const portfolioList = useSelector(
    (store) => store.dashboardPortfolioPriceList
  );
  const removeAifarray = (i) => {
    let newAifarray = [...aifarray];
    newAifarray.splice(i, 1);
    setAifarray(newAifarray);
  };
  // const portfolioValue = useSelector(
  //   (store) => store.portfolioReviewGetSumary.portfolio_value
  // );
  // const parsePortfolio = (portfolio) => {
  //   if (portfolio / 1000 < 100)
  //     return `₹ ${parseFloat(portfolio / 1000).toFixed(1)} K`;
  //   if (portfolio / 100000 < 100)
  //     return `₹ ${parseFloat(portfolio / 100000).toFixed(1)} L`;
  //   return `₹ ${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  // };
  useEffect(() => {
    dispatch(
      GetDashboardAifPortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setAifarray(data);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (patchAifArray.length) setSubmitActive(true);
    else setSubmitActive(false);
  }, [patchAifArray]);

  const handleSubmit = async () => {
    setloading(true);
    let newPatchList = patchAifArray.map((item) => {
      var dd = String(item.purchase_date.getDate()).padStart(2, "0");
      var mm = String(item.purchase_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.purchase_date.getFullYear();
      return {
        id: item.id,
        user_id: localStorage.getItem("clientId"),
        aif_id: item.aif_id,
        purchase_date: yyyy + "-" + mm + "-" + dd,
        count_units: parseFloat(item.count_units),
        purchase_price: parseFloat(item.purchase_price),
        current_price: parseFloat(item.current_price),
        goal_title: item.goal_title,
        goal_id: item.goal_id,
      };
    });
    newPatchList.length &&
      (await axios.patch(urls.dashboardAifPortfolioURL, newPatchList));
    setPatchAifArray([]);
    dispatch(
      GetDashboardPortfolioList({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      GetDashboardAifPortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      OpenSnackbar({
        severity: "success",
        message: "AIF Tagged Successfully",
      })
    );
    setloading(false);
  };
  return (
    <div className="w-full">
      <div className="font-medium font-inter text-[18px] text-neutralBlack">
        Alternate Investment Funds - {portfolioList.total_aif_value}
      </div>
      {!loading ? (
        <div className="update-portfolio-asset-row-container h-[62vh] mt-4">
          {aifarray.map((fund, index) => (
            <AIFRow
              key={fund.id}
              fund={fund}
              id={id}
              name={name}
              removeAifarray={removeAifarray}
              aifarray={aifarray}
              setAifarray={setAifarray}
              patchAifArray={patchAifArray}
              setPatchAifArray={setPatchAifArray}
              data={data}
            />
          ))}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center w-[96%] h-[62vh]">
          <Bars color="#1982F8" />
          <p className=" font-inter text-[14px] font-medium text-neutralBlack mb-[1rem] mt-[1rem]">
            Updating Portfolio ...
          </p>
        </div>
      )}
      <div className="flex w-full mt-[24px] pr-[24px] justify-end">
        <InvestedgeButton
          disabled={!submitActive}
          onClick={() => {
            if (patchAifArray.length) {
              setSubmitActive(false);
              handleSubmit();
            }
          }}
        >
          Submit
        </InvestedgeButton>
      </div>
    </div>
  );
}
