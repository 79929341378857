import React, { useState } from "react";
import AccountTypeModal from "./AccountTypeModal";
import NewPortfolio from "./NewPortfolio/NewPortfolio";
import SelectClientPortfolio from "./ClientPortfolio/SelectClientPortfolio";
import UploadStatementScreen from "./NewPortfolio/UploadStatementScreen";
import UploadExcelScreen from "./NewPortfolio/UploadExcelScreen";
import InvestedgeModal from "../../../../components/modal/InvestedgeModal";
import { IoIosClose } from "react-icons/io";
export default function PortfolioSelectionModal({
  portfolioType,
  setPortfolio1,
  setPortfolio2,
  portfolioSelectionModal,
  onClose,
}) {
  const [route, setRoute] = useState("portfolioSelectionScreen");
  const [statementType, setStatementType] = useState("");
  if (!portfolioSelectionModal) return null;
  const handleSwitch = () => {
    switch (route) {
      case "portfolioSelectionScreen":
        return <AccountTypeModal setRoute={setRoute} />;
      case "clientPortfolioScreen":
        return (
          <SelectClientPortfolio
            setPortfolio={
              portfolioType === "portfolio1" ? setPortfolio1 : setPortfolio2
            }
            setRoute={setRoute}
            onClose={onClose}
          />
        );
      case "newPortfolio":
        return (
          <NewPortfolio
            setRoute={setRoute}
            setStatementType={setStatementType}
          />
        );
      case "uploadstatementscreen":
        return (
          <UploadStatementScreen
            setPortfolio={
              portfolioType === "portfolio1" ? setPortfolio1 : setPortfolio2
            }
            statementType={statementType}
            setRoute={setRoute}
          />
        );
      case "uploadexcelscreen":
        return (
          <UploadExcelScreen
            setPortfolio={
              portfolioType === "portfolio1" ? setPortfolio1 : setPortfolio2
            }
            statementType={statementType}
            setRoute={setRoute}
          />
        );

      //   case "Debt":
      //     return <DebtSection analytics={analytics} />;

      //   case "Equity":
      //     return <EquitySection analytics={analytics} />;

      default:
        console.log(route);
    }
  };
  return (
    <InvestedgeModal
      aria-labelledby="Portfolio Selection Modal"
      aria-describedby="Portfolio Selection Modal"
      open={portfolioSelectionModal}
      handleclose={() => {
        setRoute("portfolioSelectionScreen");
        onClose();
      }}
    >
      <div className="flex justify-end">
        <IoIosClose
          size={30}
          onClick={() => {
            setRoute("portfolioSelectionScreen");
            onClose();
          }}
        />
      </div>

      {handleSwitch(route)}
    </InvestedgeModal>
  );
}
