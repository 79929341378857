import { GET_BIRTHDAY_THIS_MONTH } from "../actions/type";
const initialState = [];
export default function getBirthdayThisMonth(state = initialState, action) {
  switch (action.type) {
    case GET_BIRTHDAY_THIS_MONTH:
      return action.payload;
    default:
      return state;
  }
}
