import { INVALID_CRED } from "../actions/type";

const initialState = null;
export default function InvalidCred(state = initialState, action) {
  switch (action.type) {
    case INVALID_CRED:
      return action.payload;

    default:
      return state;
  }
}
