import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { PostEventLoggingData } from "../../actions/actions";
import AfterloginApp from "../../rmdashboard/AfterloginApp";
import "../../static/css/existing/dashboard_styles.css";
import SettingsSidebar from "../SettingsSidebar";
import AccessContent from "./AccessContent";
export default function Access() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      PostEventLoggingData({
        module_name: "Settings",
        event_type: "Information",
        url: window.location.href,
        note: `Accessing Modules`,
      })
    );
  }, [dispatch]);

  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Settings"}
        description={"Update and configure your information"}
      />
      <div className="loggedinscreen-container pt-0">
        <div className="loggedinscreen flex flex-row justify-between">
          <SettingsSidebar />
          <div className="p-[1rem] pt-0 bg-[#FFF] min-h-full rounded-[0.5rem] w-[79%] mt-4">
            <AccessContent />
          </div>
        </div>
      </div>
    </div>
  );
}
