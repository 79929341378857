import React from "react";

export default function MFPortfolioRow({ product }) {
  return (
    <tr className="w-full h-12">
      <td style={{ paddingLeft: "0rem" }}>{product.name}</td>
      <td className="text-center">
        {parseFloat(product.count_units.toFixed(4)).toLocaleString("en-IN")}
      </td>
      {/* <td className="text-center">
        {parseInt(product.purchase_price).toFixed(1)}
      </td> */}
      <td className="text-center">
        {parseInt(
          (product.count_units * product.current_price).toFixed(1)
        ).toLocaleString("en-IN")}
      </td>
      {/* <td
        className={`text-center text-${
          product.abs_return >= 0 ? "success-300" : "error-300"
        }`}
      >
        {(product.abs_return * 100).toFixed(1)}
      </td> */}
    </tr>
  );
}
