import React, { useEffect, useState } from "react";
import AfterloginApp from "../AfterloginApp";
import "../../static/css/existing/dashboard_styles.css";
import { GiSpeaker } from "react-icons/gi";
import { HiMail } from "react-icons/hi";
import { IoLogoWhatsapp } from "react-icons/io";
import { BsFillShareFill } from "react-icons/bs";
import urls from "../../urls";
import axios from "axios";
export default function Coverfaqpage() {
  const [marketFaqs, setmarketFaqs] = useState([]);
  useEffect(() => {
    (async () => {
      axios.get(urls.marketFaqURL).then((res) => {
        const marketFaqs = res.data;

        setmarketFaqs(marketFaqs);
        // console.log("Market faqs are ", marketFaqs);
      });
    })();
  }, []);
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"FAQ"}
        description={
          "Browse for portfolio insights, actionable, cash flow planning, and much more"
        }
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className="main-panel">
            <div className="w-full">
              <div className="row">
                <div className="col-lg-12 grid-margin">
                  <div className="card border-0">
                    <div className="card-body">
                      <h1 className="text-xl mb-3 ">
                        Frequently Asked Questions-test{" "}
                      </h1>
                      <div className="template-demo d-flex flex-row gap-5">
                        <div className="dropdown">
                          <button
                            className="btn dropdown-toggle mr-3"
                            style={{
                              backgroundColor: "#5E72E4",
                              color: "#fff",
                            }}
                            type="button"
                            id="dropdownMenuButton3"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {" "}
                            Section{" "}
                          </button>

                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton3"
                          >
                            <h6 className="dropdown-header">Settings</h6>
                            <a className="dropdown-item" href="!#">
                              Action
                            </a>
                            <a className="dropdown-item" href="!#">
                              Another action
                            </a>
                            <a className="dropdown-item" href="!#">
                              Something else here
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="!#">
                              Separated link
                            </a>
                          </div>
                        </div>
                        <div className="dropdown">
                          <button
                            className="btn dropdown-toggle mr-3"
                            style={{
                              backgroundColor: "#5E72E4",
                              color: "#fff",
                            }}
                            type="button"
                            id="dropdownMenuButton4"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {" "}
                            Asset: All{" "}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton4"
                          >
                            <h6 className="dropdown-header">Settings</h6>
                            <a className="dropdown-item" href="!#">
                              Action
                            </a>
                            <a className="dropdown-item" href="!#">
                              Another action
                            </a>
                            <a className="dropdown-item" href="!#">
                              Something else here
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="!#">
                              Separated link
                            </a>
                          </div>
                        </div>
                        <div className="dropdown">
                          <button
                            className="btn dropdown-toggle mr-3"
                            style={{
                              backgroundColor: "#5E72E4",
                              color: "#fff",
                            }}
                            type="button"
                            id="dropdownMenuButton5"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {" "}
                            Product{" "}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton5"
                          >
                            <h6 className="dropdown-header">Settings</h6>
                            <a className="dropdown-item" href="!#">
                              Action
                            </a>
                            <a className="dropdown-item" href="!#">
                              Another action
                            </a>
                            <a className="dropdown-item" href="!#">
                              Something else here
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="!#">
                              Separated link
                            </a>
                          </div>
                        </div>
                        <div className="dropdown">
                          <button
                            className="btn dropdown-toggle mr-3"
                            style={{
                              backgroundColor: "#5E72E4",
                              color: "#fff",
                            }}
                            type="button"
                            id="dropdownMenuButton6"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {" "}
                            Geography{" "}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton6"
                          >
                            <h6 className="dropdown-header">Settings</h6>
                            <a className="dropdown-item" href="!#">
                              Action
                            </a>
                            <a className="dropdown-item" href="!#">
                              Another action
                            </a>
                            <a className="dropdown-item" href="!#">
                              Something else here
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="!#">
                              Separated link
                            </a>
                          </div>
                        </div>
                      </div>
                      <form
                        className="ml-auto search-form d-none d-md-block mt-4"
                        action="#"
                      >
                        <div className="form-group">
                          <input
                            type="search"
                            className="form-control"
                            placeholder='Type "View on Economy" '
                          />
                        </div>
                      </form>
                      <h2
                        className="text-md  mt-5"
                        style={{ color: "#323232" }}
                      >
                        Results
                      </h2>
                      <hr style={{ color: "#C3C4C6" }}></hr>
                      {marketFaqs.map((individualFaq) => (
                        <div className="card border-0 individual_faq">
                          <div
                            className="modal fade"
                            id={`faqModal` + individualFaq.id}
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-lg p-5"
                              role="document"
                            >
                              <div className="modal-content pr-5 pl-5 pb-5 pt-2">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title  text-2xl"
                                    id="exampleModalLabel"
                                  >
                                    {individualFaq.question}
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>

                                <div className="modal-body text-sm font-semibold">
                                  <div className="d-flex flex-row gap-4 mt-3 mb-3">
                                    <GiSpeaker
                                      style={{
                                        color: "#0000f5",
                                        fontSize: "22px",
                                        marginTop: "-4px",
                                      }}
                                    />
                                    <HiMail />
                                    <IoLogoWhatsapp />
                                    <BsFillShareFill />
                                  </div>

                                  {individualFaq.answer}
                                  <div className="d-flex flex-row gap-4 mt-3 mb-3"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="card-body"
                            style={{
                              borderBottom: "1px solid #C3C4C6",
                            }}
                          >
                            <h1 className="text-xl ">
                              {individualFaq.question}
                            </h1>
                            <div className="d-flex flex-row gap-4 mt-3 mb-3">
                              <GiSpeaker
                                style={{
                                  color: "#0000f5",
                                  fontSize: "22px",
                                  marginTop: "-4px",
                                }}
                              />
                              <HiMail />
                              <IoLogoWhatsapp />
                              <BsFillShareFill />
                            </div>
                            <h2
                              className="text-sm font-semibold mr-3"
                              style={{ lineHeight: "28px", display: "inline" }}
                            >
                              {individualFaq.answer.slice(0, 250)}
                            </h2>
                            <a
                              href="!#"
                              className="text-sm font-semibold"
                              style={{
                                color: "#0000F5",
                                cursor: "pointer",
                                display: "inline",
                              }}
                              data-toggle="modal"
                              data-target={`#faqModal` + individualFaq.id}
                            >
                              Read More
                            </a>
                            <div className="d-flex flex-row gap-4 mt-3 mb-3"></div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
