import React, { useEffect, useRef, useState } from "react";
import "./clientinput.css";
import "../../static/css/existing/dashboard_styles.css";
import Dropdown from "../../components/Dropdowns/DropdownObjects";
import Plus from "../../assets/img/add_new_icon.svg";
import ClientCard from "./ClientCard";
import AccountTypeModal from "./AccountTypeModal";
import {
  UpdateClientData,
  GetClients,
  GetRms,
  PostEventLoggingData,
} from "../../actions/actions";
import { useDispatch, useSelector } from "react-redux";
import ClickAwayListener from "react-click-away-listener";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import InvestedgePopper from "../../components/popper/InvestedgePopper";
import { MenuItem } from "@mui/material";
import InvestedgeSearchField from "../../components/searchfield/InvestedgeSearchField";
import InvestedgeTabs from "../../components/tabs/InvestedgeTabs";
import InvestedgePagination from "../../components/pagination/InvestedgePagination";
import { Bars } from "react-loader-spinner";
export default function ClientContent() {
  const rms = useSelector((state) => state.rms);
  const clients = useSelector((state) => state.clients);
  const [selected, setSelected] = useState({});
  const popperRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [newClientType, setNewClientType] = useState("");
  const [searchClient, setSearchClient] = useState("");
  const [clienttype, setClienttype] = useState(0);
  // const [clientData, setClientData] = useState(
  //   rms.length && Object.keys(rms[0]).length !== 0
  //     ? selected.clients.filter((data) => data.is_prospect === false)
  //     : [
  //         {
  //           client_info: {
  //             username: "",
  //             email: "",
  //             role: "",
  //             profile_pic: null,
  //           },
  //           client_type: "",
  //           is_prospect: true,
  //         },
  //       ]
  // );
  const [pageNumber, setPageNumber] = useState(1);
  const clientsPerPage = 10;
  // const pagesVisited = pageNumber * clientsPerPage;
  const pageCount = Math.ceil(
    useSelector((state) => state.clients.count) / clientsPerPage
  );
  const changePage = (e, val) => {
    setPageNumber(val);
    dispatch(
      GetClients({
        params: {
          search: searchClient,
          is_prospect: clienttype === 1,
          rmid: selected?.id,
          page: val,
          size: clientsPerPage,
        },
      })
    );
  };
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    selected?.id &&
      dispatch(
        GetClients({
          params: {
            search: searchClient,
            is_prospect: clienttype === 1,
            rmid: selected?.id,
            page: 1,
            size: clientsPerPage,
          },
        })
      );
    setPageNumber(1); // eslint-disable-next-line
  }, [searchClient, clienttype, selected]);

  // useEffect(() => {
  //   dispatch(
  //     GetClients({
  //       params: {
  //         search: searchClient,
  //         is_prospect: clienttype === 1,
  //         rmid: "",
  //       },
  //     })
  //   ); // eslint-disable-next-line
  // }, [selected]);

  useEffect(() => {
    setSelected(rms[0]); // eslint-disable-next-line
  }, [rms]);

  useEffect(() => {
    dispatch(GetRms());
    let today = new Date();
    let bday = new Date(2000, 11, 10);
    let aget = today.getFullYear() - bday.getFullYear();

    var m = today.getMonth() - bday.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < bday.getDate())) {
      aget--;
    }
    dispatch(
      UpdateClientData({
        accountType: "Family",
        clientType: "Client",
        familyName: "",
        rmID: "",
        Members: [
          {
            name: "",
            primary: true,
            relationship: "Self",
            fullName: "",
            dob: new Date(2000, 10, 10),
            gender: "Male",
            status: "Married",
            panNumber: "",
            occupation: "",
            sector: "",
            address: "",
            pincode: "",
            taxResidency: "Indian",
            residencyStatus: "Indian",
            nationality: "Indian",
            taxslab: "",
            surcharge: "0%",
            taxrate: "30%",
            currentftcl: 0,
            currentstcl: 0,
            carrystcl: 0,
            carryftcl: 0,
            currentftcldenotion: "₹ L",
            currentstcldenotion: "₹ L",
            carrystcldenotion: "₹ L",
            carryftcldenotion: "₹ L",
            investmentcorpus: "",
            investmentcorpusdenotion: "₹ L",
            investmenthorizon: "",
            riskscore: 50,
            age: aget,
            flexibility: "",
            raq1: 1,
            raq2: 1,
            raq3: 1,
            raq4: 1,
            raq5: 1,
            sumraq: 5,
            understanding: {
              mf: {
                checked: true,
                value: 3,
              },
              stocks: {
                checked: true,
                value: 3,
              },
              bonds: {
                checked: true,
                value: 3,
              },
              pms: {
                checked: true,
                value: 3,
              },
              aif: {
                checked: true,
                value: 3,
              },
              cash: {
                checked: true,
                value: 3,
              },
              deposit: {
                checked: true,
                value: 3,
              },
              gold: {
                checked: true,
                value: 3,
              },
              savingsschemes: {
                checked: true,
                value: 3,
              },
              realestate: {
                checked: true,
                value: 3,
              },
              epf: {
                checked: true,
                value: 3,
              },
              ppf: {
                checked: true,
                value: 3,
              },
              unlisted: {
                checked: true,
                value: 3,
              },
            },
            ips: {
              equity: {
                target: 0.4,
                flexibility: 0.1,
              },
              debt: {
                target: 0.5,
                flexibility: 0.04,
              },
              alternates: {
                target: 0.1,
                flexibility: 0.04,
              },
              large_cap: {
                target: 0.6,
                flexibility: 0.2,
              },
              mid_cap: {
                target: 0.3,
                flexibility: 0.2,
              },
              small_cap: {
                target: 0.1,
                flexibility: 0.1,
              },
              duration: {
                target: 3,
                flexibility: 1,
              },
              credit_risk: {
                target: 0.85,
                flexibility: 0.2,
              },
              alternates_low_vol: {
                target: 0.65,
                flexibility: 0.2,
              },
            },
            errors: {
              pannumber: null,
              familyname: null,
              name: null,
              relationship: null,
              corpus: null,
              horizon: null,
              flexibility: null,
            },
          },
        ],
      })
    ); // eslint-disable-next-line
  }, []);
  const tabs = [{ label: "Clients" }, { label: "Prospects" }];
  return (
    <>
      <div className="main-panel bg-[white] p-4 min-h-[calc(100vh_-_116px)] rounded-[4px] w-full relative ">
        <div className="h-auto ">
          <div className="flex justify-between w-[100%] items-center">
            <div className="w-[22%] pt-[0.3rem]">
              <Dropdown
                options={rms}
                selected={selected}
                setSelected={setSelected}
                attribute="username"
                labelName="Relationship Manager"
              />
            </div>
          </div>

          <div className="flex justify-between w-full mt-[1rem]">
            <InvestedgeTabs
              tabs={tabs}
              tabvalue={clienttype}
              handletabchange={(e, value) => {
                dispatch(
                  PostEventLoggingData({
                    module_name: "Client Details",
                    event_type: "Information",
                    url: window.location.href,
                    note: "Tabs clicked",
                  })
                );
                setClienttype(value);
              }}
            />
            <div className="flex items-end w-full justify-end mb-2 space-x-2">
              <InvestedgeSearchField
                type="text"
                className="w-1/4"
                value={searchClient}
                onChange={(e) => setSearchClient(e.target.value)}
                placeholder="Search clients and prospects"
              />
              <InvestedgeButton
                aria-describedby="add-new-client-popper"
                sx={{ marginBottom: "2px" }}
                className="flex justify-between items-center ml-4"
                onClick={() => {
                  setAnchorEl((prevAnchor) =>
                    prevAnchor ? null : popperRef.current
                  );
                  dispatch(
                    PostEventLoggingData({
                      module_name: "Client Details",
                      event_type: "Add Button",
                      url: window.location.href,
                      note: `Add new client clicked`,
                    })
                  );
                }}
              >
                <img
                  ref={popperRef}
                  className="w-[14px] object-contain mr-2"
                  src={Plus}
                  alt="err"
                />
                Add New
              </InvestedgeButton>
              <InvestedgePopper
                id="add-new-client-popper"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                placement="bottom-start"
                className="z-[100]"
                offset={[0, 20]}
                arrow="true"
              >
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                  <div>
                    <MenuItem
                      onClick={() => {
                        dispatch(
                          PostEventLoggingData({
                            module_name: "Client Details",
                            event_type: "Next button",
                            url: window.location.href,
                            note: `Client type selected as client`,
                          })
                        );
                        setOpen(true);
                        setNewClientType("Client");
                      }}
                    >
                      Client
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        dispatch(
                          PostEventLoggingData({
                            module_name: "Client Details",
                            event_type: "Next button",
                            url: window.location.href,
                            note: `Client type selected as prospect`,
                          })
                        );
                        setOpen(true);
                        setNewClientType("Prospect");
                      }}
                    >
                      Prospect
                    </MenuItem>
                  </div>
                </ClickAwayListener>
              </InvestedgePopper>
            </div>
          </div>
        </div>
        {clients.loading ? (
          <div className="client-dashboard-table w-full min-h-[400px] flex flex-col justify-center items-center opacity-[0.9]">
            <Bars color="#1982F8" width={50} height={50} />
          </div>
        ) : (
          <div className="w-full grid grid-cols-5 gap-5 pt-8">
            {Array.isArray(clients.results)
              ? // .slice(pagesVisited, pagesVisited + clientsPerPage)
                clients.results.map((client, index) => {
                  return (
                    <ClientCard
                      client={client}
                      key={index}
                      clienttype={clienttype}
                      searchClient={searchClient}
                      selected={selected}
                      clientsPerPage={clientsPerPage}
                    />
                  );
                })
              : null}
          </div>
        )}
        <div className="w-full flex justify-end absolute bottom-4 right-4">
          <InvestedgePagination
            count={pageCount}
            defaultPage={1}
            page={pageNumber}
            onChange={changePage}
            color="primary"
          />
        </div>
      </div>
      <AccountTypeModal
        onClose={() => setOpen(false)}
        open={open}
        clientType={newClientType}
      />
    </>
  );
}
