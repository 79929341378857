import { GET_MARKET_FAQS } from "../actions/type";
const initialState = [
  {
    id: "",
    question: "",
    answer: "",
    published_date: "",
    flag: true,
    module: "",
    section: "",
    geography: "",
    asset: "",
    product_type: "",
    product: "",
    trending: false,
  },
];

export default function MarketFAQ(state = initialState, action) {
  switch (action.type) {
    case GET_MARKET_FAQS:
      return action.payload;
    default:
      return state;
  }
}
