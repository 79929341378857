import React, { useState, useEffect } from "react";
import ProductUnderstandingRow from "./ProductUnderstandingRow";
import { useDispatch, useSelector } from "react-redux";
import {
  OpenSnackbar,
  PostEventLoggingData,
  UpdateInvestmentPolicyData,
} from "../../actions/actions";
import InvestedgeModal from "../../components/modal/InvestedgeModal";
import { IoIosClose } from "react-icons/io";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
export default function ProductUnderstandingModal({
  familyMembers,
  setFamilyMembers2,
  selected,
  onClose,
  pumodal,
}) {
  const dispatch = useDispatch();
  const reduxData = useSelector((state) => state.clientDashboardData);
  const [tempfamilyMem, settempfamilyMem] = useState(
    JSON.parse(JSON.stringify(reduxData)).map((y) => y)
  );
  useEffect(() => {
    settempfamilyMem(JSON.parse(JSON.stringify(reduxData)).map((y) => y));
  }, [reduxData]);
  return (
    <InvestedgeModal
      aria-labelledby="Product Understanding for Client Dashboard"
      aria-describedby="Product Understanding for Client Dashboard"
      sx={{ padding: "1rem" }}
      open={pumodal}
      onClose={() => {
        settempfamilyMem(JSON.parse(JSON.stringify(reduxData)).map((y) => y));
        onClose();
      }}
    >
      <div className="w-[100%] flex justify-between">
        <h1 className="card-heading">Product Understanding</h1>
        <IoIosClose
          size={30}
          onClick={() => {
            settempfamilyMem(
              JSON.parse(JSON.stringify(reduxData)).map((y) => y)
            );
            onClose();
          }}
        />
      </div>
      <p className="font-inter text-sm mt-[0.5rem] leading-5">
        Rate your clients understanding on different products. Any number above
        2.5 will prioritize that product over other products in the client’s
        portfolio
      </p>
      <table className="w-full rounded-[6px] investedge-table">
        <colgroup>
          <col width="27.5%" />
          <col width="27.5%" />
          <col width="45%" />
        </colgroup>
        <tbody>
          <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
            <td />
            <td className="text-base text-center font-medium">
              Product Interest
            </td>
            <td className="text-center text-base font-medium">
              Product Understanding
            </td>
          </tr>
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="mfund"
            label="Mutual Funds"
            selected={selected}
            slidervalue="mfvalue"
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="stock"
            label="Stocks"
            selected={selected}
            slidervalue="mfvalue"
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="bond"
            label="Bonds/MLDs"
            selected={selected}
            slidervalue="mfvalue"
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="pm"
            label="PMS"
            selected={selected}
            slidervalue="mfvalue"
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="aif"
            label="AIFs"
            selected={selected}
            slidervalue="mfvalue"
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="cash"
            label="Cash"
            selected={selected}
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="deposit"
            label="Deposits"
            selected={selected}
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="gold_silver"
            label="Gold/Silver"
            selected={selected}
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="small_saving_scheme"
            label="Small saving schemes"
            selected={selected}
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="estate"
            label="Real Estate"
            selected={selected}
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="epf_vpf"
            label="EPF/VPF"
            selected={selected}
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="ppf"
            label="PPFs"
            selected={selected}
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="unlisted_states"
            label="Unlisted Shares"
            selected={selected}
          />
        </tbody>
      </table>
      <div className="flex w-full items-center justify-end mt-[1rem]">
        <InvestedgeButton
          onClick={() => {
            setFamilyMembers2(tempfamilyMem);
            dispatch(UpdateInvestmentPolicyData(tempfamilyMem));
            dispatch(
              OpenSnackbar({
                severity: "success",
                message: `Product Understanding for ${
                  familyMembers[selected.optionid].name
                } updated`,
              })
            );
            dispatch(
              PostEventLoggingData({
                module_name: "Investment Profile",
                event_type: "Save button",
                url: window.location.href,
                note: `Product Understanding for ${localStorage.getItem(
                  "clientName"
                )} updated`,
              })
            );
            onClose();
          }}
        >
          Save
        </InvestedgeButton>
      </div>
    </InvestedgeModal>
  );
}
