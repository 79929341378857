import React, { useState } from "react";
import { FormControlLabel, Radio } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  GetIpsData,
  OpenSnackbar,
  PostEventLoggingData,
} from "../../../actions/actions";
import InvestedgeModal from "../../../components/modal/InvestedgeModal";
import { IoIosClose } from "react-icons/io";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
export default function RiskAssessmentModal({
  onClose,
  familyMembers,
  setFamilyMembers2,
  selected,
  rsmodal,
  riskText,
}) {
  const [getScore, setGetScore] = useState(false);
  const dispatch = useDispatch();

  let handleChange = (e) => {
    let newfamilyMembers = [...familyMembers];
    newfamilyMembers[selected.optionid][e.target.name] = parseInt(
      e.target.value
    );
    newfamilyMembers[selected.optionid].sumraq =
      newfamilyMembers[selected.optionid].raq1 +
      newfamilyMembers[selected.optionid].raq2 +
      newfamilyMembers[selected.optionid].raq3 +
      newfamilyMembers[selected.optionid].raq4 +
      newfamilyMembers[selected.optionid].raq5;
    setFamilyMembers2(newfamilyMembers);
  };
  let handleclose = () => {
    onClose();
    setGetScore(false);
  };

  return (
    <>
      <InvestedgeModal
        aria-labelledby="Risk Assessment Questionnaire"
        aria-describedby="Risk Assessment Questionnaire"
        sx={{
          padding: "1.5rem",
          width: "75vw",
          marginRight: "auto",
          marginLeft: "auto",
        }}
        open={rsmodal}
        handleclose={onClose}
      >
        <div className="w-[100%] flex mb-[1rem] justify-between items-center">
          <h1 className="font-inter text-[21px] not-italic font-medium tracking-[0em] text-left text-neutralBlack">
            Risk Assessment Questionnaire
          </h1>
          <IoIosClose onClick={onClose} size={30} />
        </div>
        <p className="product-understanding-modal-question">
          1. You decide to invest 10 lacs for next 10 years. Which portfolio
          will you choose?
        </p>
        <div className="product-understanding-modal-answers">
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers[selected.optionid].raq1 === 1}
                onChange={(e) => handleChange(e)}
                value={1}
                name="raq1"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter leading-">
                Portfolio giving 7% average return with no negative returns in
                any of last 10 years.
              </span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers[selected.optionid].raq1 === 2}
                onChange={(e) => handleChange(e)}
                value={2}
                name="raq1"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter leading-">
                Portfolio giving 9% average return but with negative returns in
                2 of last 10 years with max loss of 8% in 1 year.
              </span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers[selected.optionid].raq1 === 3}
                onChange={(e) => handleChange(e)}
                value={3}
                name="raq1"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter leading-">
                Portfolio giving 11% average return but with negative returns in
                3 of last 10 years with max loss of 15% in 1 year.
              </span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers[selected.optionid].raq1 === 4}
                onChange={(e) => handleChange(e)}
                value={4}
                name="raq1"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter leading-">
                Portfolio giving 13% average return but with negative returns in
                4 of last 10 years with max loss of 20% in 1 year.
              </span>
            }
          />
        </div>
        <p className="product-understanding-modal-question">
          2. What percentage of your investible surplus would you invest in a
          product/asset where the risk and return are expected to be high?
        </p>
        <div className="product-understanding-modal-answers">
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers[selected.optionid].raq2 === 1}
                onChange={(e) => handleChange(e)}
                value={1}
                name="raq2"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter leading-">Upto 10%.</span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers[selected.optionid].raq2 === 2}
                onChange={(e) => handleChange(e)}
                value={2}
                name="raq2"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter leading-">10-20%.</span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers[selected.optionid].raq2 === 3}
                onChange={(e) => handleChange(e)}
                value={3}
                name="raq2"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter leading-">20-40%.</span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers[selected.optionid].raq2 === 4}
                onChange={(e) => handleChange(e)}
                value={4}
                name="raq2"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter leading-">
                More than 40%.
              </span>
            }
          />
        </div>
        <p className="product-understanding-modal-question">
          3. I will decide to sell my investments, if they fall by __% in first
          year of investment?
        </p>
        <div className="product-understanding-modal-answers">
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers[selected.optionid].raq3 === 1}
                onChange={(e) => handleChange(e)}
                value={1}
                name="raq3"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter leading-">10%.</span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers[selected.optionid].raq3 === 2}
                onChange={(e) => handleChange(e)}
                value={2}
                name="raq3"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter leading-">20%.</span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers[selected.optionid].raq3 === 3}
                onChange={(e) => handleChange(e)}
                value={3}
                name="raq3"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter leading-">
                More than 20%.
              </span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers[selected.optionid].raq3 === 4}
                onChange={(e) => handleChange(e)}
                value={4}
                name="raq3"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter leading-">Never.</span>
            }
          />
        </div>
        <p className="product-understanding-modal-question">
          4. You unexpectedly receive a huge amount of money in a lottery. All
          your goals and expenses are already taken care of. Where will you
          invest that money?
        </p>
        <div className="product-understanding-modal-answers">
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers[selected.optionid].raq4 === 1}
                onChange={(e) => handleChange(e)}
                value={1}
                name="raq4"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter leading-">
                Fixed Deposits.
              </span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers[selected.optionid].raq4 === 2}
                onChange={(e) => handleChange(e)}
                value={2}
                name="raq4"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter leading-">
                Debt Mutual Funds.
              </span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers[selected.optionid].raq4 === 3}
                onChange={(e) => handleChange(e)}
                value={3}
                name="raq4"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter leading-">Property.</span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers[selected.optionid].raq4 === 4}
                onChange={(e) => handleChange(e)}
                value={4}
                name="raq4"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter leading-">
                Equity MF and Stocks.
              </span>
            }
          />
        </div>
        <p className="product-understanding-modal-question">
          5. You are on a TV game show and can choose one of the following.
          Which one would you pick?
        </p>
        <div className="product-understanding-modal-answers">
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers[selected.optionid].raq5 === 1}
                onChange={(e) => handleChange(e)}
                value={1}
                name="raq5"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter leading-">
                Get 5 lacs in cash and walk away.
              </span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers[selected.optionid].raq5 === 2}
                onChange={(e) => handleChange(e)}
                value={2}
                name="raq5"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter leading-">
                Continue with 50% chance of winning 10 lacs.
              </span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers[selected.optionid].raq5 === 3}
                onChange={(e) => handleChange(e)}
                value={3}
                name="raq5"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter leading-">
                Continue with 20% chance of winning 25 lacs.
              </span>
            }
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={familyMembers[selected.optionid].raq5 === 4}
                onChange={(e) => handleChange(e)}
                value={4}
                name="raq5"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={
              <span className="text-[14px] font-inter leading-">
                Continue with 10% chance of winning 1 crore.
              </span>
            }
          />
        </div>
        <div
          className="flex items-center justify-end  mt-[1rem]"
          onClick={() => setGetScore(true)}
        >
          <InvestedgeButton
            onClick={() => {
              let newfamilyMembers = [...familyMembers];
              newfamilyMembers[selected.optionid].sumraq =
                newfamilyMembers[selected.optionid].raq1 +
                newfamilyMembers[selected.optionid].raq2 +
                newfamilyMembers[selected.optionid].raq3 +
                newfamilyMembers[selected.optionid].raq4 +
                newfamilyMembers[selected.optionid].raq5;
              newfamilyMembers[selected.optionid].riskscore = Math.round(
                (newfamilyMembers[selected.optionid].sumraq - 4) * (100 / 16)
              );
              setFamilyMembers2(newfamilyMembers);
              familyMembers[selected.optionid].investmenthorizon.length &&
                dispatch(
                  GetIpsData({
                    params: {
                      risk_score: familyMembers[selected.optionid].riskscore,
                      investment_horizon:
                        familyMembers[selected.optionid].investmenthorizon,
                    },
                  })
                );
              dispatch(
                PostEventLoggingData({
                  module_name: "Client Details",
                  event_type: "Assess button",
                  url: window.location.href,
                  note: `Risk assessed for ${
                    familyMembers[selected.optionid].name
                  }`,
                })
              );
              dispatch(
                OpenSnackbar({
                  severity: "success",
                  message: `Risk profile for ${
                    familyMembers[selected.optionid].name
                  } updated`,
                })
              );
            }}
          >
            Assess
          </InvestedgeButton>
        </div>
      </InvestedgeModal>

      <InvestedgeModal
        aria-labelledby="Risk Assessment Questionnaire Child Modal"
        aria-describedby="Risk Assessment Questionnaire Child Modal"
        sx={{
          padding: "1.5rem",
          minWidth: "40vw",
          marginRight: "auto",
          marginLeft: "auto",
        }}
        open={getScore}
        handleclose={() => setGetScore(false)}
      >
        <div className="modal-component-cross-container">
          <IoIosClose size={30} onClick={() => setGetScore(false)} />
        </div>
        <div className="h-[90%] flex justify-center items-center">
          <h1 className="text-[16px] font-medium font-inter text-neutralBlack text-center leading-[25px]">
            Your risk score is
            <span className="text-[16px] font-semibold text-primary-200 ml-[0.5rem]">
              {familyMembers[selected.optionid].riskscore}
            </span>
            ,<br /> You should ideally maintain your portfolio as a
            <span className="text-[16px] text-primary-200 font-semibold mx-[0.26rem]">
              {riskText}
            </span>
            investor
          </h1>
        </div>
        <div className="flex w-full justify-end mt-4">
          <InvestedgeButton onClick={handleclose}>Got it</InvestedgeButton>
        </div>
      </InvestedgeModal>
    </>
  );
}
