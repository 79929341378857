import React from "react";
// import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import DoughnutChart from "../../../components/charts/DoughnutChart";

export default function GoalDetails({ goalDetails }) {
  const numToMonthMap = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sept",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };
  const goalDetailData = [
    {
      val:
        goalDetails.goal_details.corpus_required.amount.toFixed(0) +
        " " +
        goalDetails.goal_details.corpus_required.denomination,
      label: "Corpus Required",
      estimation: "(inflation Adjusted)",
    },
    {
      val: `${
        numToMonthMap[parseInt(goalDetails.goal_details.year.split("-")[0])]
      } ${goalDetails.goal_details.year.split("-")[1]}`,
      label: "Year",
      estimation: "(when goal ends)",
    },
    {
      val: (goalDetails.goal_details.expected_return * 100).toFixed(2) + "%",
      label: "Expected Return",
      estimation: "",
    },
    {
      val:
        goalDetails.goal_details.ongoing_sip.amount.toFixed(0) +
        " " +
        goalDetails.goal_details.ongoing_sip.denomination,
      label: "Ongoing SIP",
      estimation: "",
    },
  ];
  const goalAttainedData = {
    labels: ["Goal Attained", "Goal Remaining"],
    datasets: [
      {
        data: [
          (goalDetails.goal_details.goal_percent_attained * 100).toFixed(1),
          100 - goalDetails.goal_details.goal_percent_attained * 100 > 0
            ? (
                100 -
                goalDetails.goal_details.goal_percent_attained * 100
              ).toFixed(1)
            : 0,
        ],
        backgroundColor: ["#56B787"],
        borderColor: "#fff",
        borderWidth: 1,
      },
    ],
  };
  const goalAttainedOption = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var currentValue = dataset.data[tooltipItem.index];
          return `${
            ["Goal Attained", "Goal Remaining"][tooltipItem.index]
          }: ${currentValue}%`;
        },
        // title: function (tooltipItem) {
        //   return sectorkeys[tooltipItem.index];
        // },
      },
    },
    plugins: {
      labels: [
        {
          render: function (args) {},
          fontColor: "#fff",
        },
      ],
      datalabels: {
        display: false,
      },
    },
  };
  const parsePortfolio = (portfolio) => {
    if (portfolio / 1000 < 100)
      return `₹ ${parseFloat(portfolio / 1000).toFixed(1)} K`;
    if (portfolio / 100000 < 100)
      return `₹ ${parseFloat(portfolio / 100000).toFixed(1)} L`;
    return `₹ ${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  };
  return (
    <>
      <div className="w-full flex justify-between items-center mt-4">
        <h3 className="card-heading">Portfolio</h3>
        {/* <InvestedgeButton
          disabled={goalDetails.client_investments.length === 0}
        >
          Download Goal Summary
        </InvestedgeButton> */}
      </div>
      <div className="flex justify-between w-full mt-4">
        <div className="w-[30%] flex justify-between items-center bg-[#fafafa] rounded-[4px] h-[200px] py-8 pr-4 pl-6">
          <DoughnutChart
            data={goalAttainedData}
            options={goalAttainedOption}
            width={150}
            height={160}
          />
          <div className="h-[100px] w-[40%]">
            <p className="font-inter text-2xl font-medium text-success-300 leading-[39px] m-0">
              {parsePortfolio(goalDetails.current_value_of_portfolio)}
            </p>
            <p className="font-inter text-sm font-normal leading-[20px] text-[#6A6A6A] mt-[4px] mb-[2px]">
              Current Value of Goal
            </p>
          </div>

          {/* <p className="mt-[1rem] text-center">60% Goal Achieved</p> */}
        </div>
        <div className="w-[68%] bg-[#fafafa] flex items-center justify-center rounded-[4px] h-[200px] px-4">
          {goalDetailData.map((el, id) => (
            <div
              key={id}
              className="border-r-[1px] border-[#E0DDDD] mr-[1rem] last:border-none first:ml-[1rem] first:text-primary-200 text-neutralBlack w-1/4 flex flex-col items-start h-[100px]"
            >
              <p className="font-inter text-2xl font-medium  leading-[39px] m-0">
                {el.val}
              </p>
              <p className="font-inter text-sm font-normal leading-[20px] text-[#6A6A6A] mt-[4px] mb-[2px] ">
                {el.label}
              </p>
              <p className="font-inter text-sm font-normal leading-[20px] text-[#6A6A6A] mt-[4px] mb-[2px]">
                {el.estimation}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
