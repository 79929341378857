import { GET_MODEL_PORTFOLIO } from "../actions/type";
const initialState = [];
export default function getModelPortfolio(state = initialState, action) {
  switch (action.type) {
    case GET_MODEL_PORTFOLIO:
      return action.payload;
    default:
      return state;
  }
}
