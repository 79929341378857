import React, { useState, useEffect } from "react";
import Add from "../../../../assets/img/portfolioaddfunds.svg";
import Reload from "../../../../assets/img/portfolioreload.svg";
import OverviewSection from "./OverviewSection";
import DebtSection from "./DebtSection";
import EquitySection from "./EquitySection";
import { useSelector, useDispatch } from "react-redux";
import {
  GetAnalyticsAPI,
  PostEventLoggingData,
} from "../../../../actions/actions";
import AddFundsModal from "./AddFundsModal";
import { Bars } from "react-loader-spinner";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
import InvestedgePagination from "../../../../components/pagination/InvestedgePagination";
import PortfolioRow from "./PortfolioRow";
export default function Portfolio({ goalData }) {
  let val = goalData.current_value_of_portfolio;
  let den;
  if (val / 1000 < 100) {
    val = parseFloat(val / 1000).toFixed(1);
    den = "K";
  } else if (val / 100000 < 100) {
    val = parseFloat(val / 100000).toFixed(1);
    den = "L";
  } else {
    val = parseFloat(val / 10000000).toFixed(1);
    den = "C";
  }
  const formValue = {
    proposal_value: {
      amount: parseFloat(val),
      denomination: `₹ ${den}`,
    },
  };
  const dispatch = useDispatch();
  const preferenceData = useSelector((state) => state.allocationPreference);

  const rationalizeProducts = (productsArray) => {
    let temp = productsArray.map((product) => {
      return {
        ...product,
        individual_asset_pct: (product.individual_asset_pct * 100).toFixed(1),
      };
    });

    return temp;
  };
  const derationalizeProducts = (productsArray) => {
    let temp = productsArray.map((product) => {
      return {
        ...product,
        individual_asset_pct: product.individual_asset_pct / 100,
      };
    });

    return temp;
  };
  const [parsedData, setParsedData] = useState(
    rationalizeProducts(preferenceData["Mutual Fund"])
  );
  useEffect(() => {
    if (preferenceData["Mutual Fund"].length > 0) {
      dispatch(GetAnalyticsAPI(preferenceData));
      let tempArray = rationalizeProducts(preferenceData["Mutual Fund"]);
      let tempSum = 0;
      tempArray.forEach(
        (value) => (tempSum += parseFloat(value.individual_asset_pct))
      );
      tempSum = tempSum.toFixed(1);
      tempArray[0].individual_asset_pct =
        parseFloat(tempArray[0].individual_asset_pct) +
        (100.0 - parseFloat(tempSum));
      tempArray[0].individual_asset_pct =
        tempArray[0].individual_asset_pct.toFixed(1);
      setParsedData(tempArray);
    }
    // eslint-disable-next-line
  }, [preferenceData]);

  const analytics = useSelector((state) => state.analyticsAPI);
  const [section, setSection] = useState("Overview");
  const [afmodal, setAfmodal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  const clientsPerPage = 7;
  const pagesVisited = pageNumber * clientsPerPage;
  var portfolioSum = 0;
  const addedFundsMap = {};
  // eslint-disable-next-line
  parsedData.forEach((value) => {
    addedFundsMap[value.product_id] = true;
    portfolioSum += parseFloat(value.individual_asset_pct);
  });
  const handleCross = (id) => {
    delete addedFundsMap[id];
    let index = parsedData.findIndex((x) => x.product_id === id);
    let newParsedData = [...parsedData];
    newParsedData.splice(index, 1);
    setParsedData(newParsedData);
  };
  const handleWeightChange = (e, id) => {
    if (
      e.target.value <= 100 &&
      (!e.target.value.split(".")[1] ||
        e.target.value.split(".")[1].length <= 1)
    ) {
      let index = parsedData.findIndex((x) => x.product_id === id);
      let newProducts = [...parsedData];
      newProducts[index].individual_asset_pct = e.target.value;
      setParsedData(newProducts);
    }
  };
  const portfolioValue = formValue.proposal_value.amount;
  const displaydata = parsedData
    .slice(pagesVisited, pagesVisited + clientsPerPage)
    .map((client) => {
      return (
        <PortfolioRow
          client={client}
          handleCross={handleCross}
          handleWeightChange={handleWeightChange}
          portfolioValue={goalData.current_value_of_portfolio}
        />
      );
    });
  const pageCount = Math.ceil(parsedData.length / clientsPerPage);
  const changePage = (e, val) => {
    setPageNumber(val - 1);
  };

  const handleSections = () => {
    switch (section) {
      case "Overview":
        return <OverviewSection analytics={analytics} />;

      case "Debt":
        return <DebtSection analytics={analytics} />;

      case "Equity":
        return <EquitySection analytics={analytics} />;

      default:
        console.log(section);
    }
  };

  useEffect(() => {
    dispatch(
      PostEventLoggingData({
        module_name: "Portfolio Insights",
        event_type: "Information",
        url: window.location.href,
        note: `Checkoed out ${section} of analytics`,
      })
    ); // eslint-disable-next-line
  }, [section]);

  return (
    <>
      <div className="rounded-[8px] w-full bg-white">
        <div className="flex justify-between w-full">
          <div className="w-[52.5%]">
            <div className="w-full flex border-b border-[#e0dddd]">
              <h3 className=" font-inter text-[16px] font-medium leading-[20px] tracking-[0em] text-left pb-[0.3rem]">
                Portfolio
              </h3>
            </div>
            {!analytics.loading ? (
              <>
                <div className="w-full bg-[#fafafa] mt-[1rem] p-[1rem] flex flex-wrap rounded-[6px]">
                  <div className="w-1/3 text-center border-r border-r-dividerColor">
                    <h3 className="text-[#6a6a76] font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center mb-0">
                      {`${(analytics.asset_allocation.Equity * 100).toFixed(
                        1
                      )}%`}
                    </h3>
                    <label className="font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                      Equity
                    </label>
                  </div>
                  <div className="w-1/3 text-center border-r border-r-dividerColor">
                    <h3 className="text-[#6a6a76] font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center mb-0">
                      {`${(analytics.asset_allocation.Debt * 100).toFixed(1)}%`}
                    </h3>
                    <label className="font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                      Debt
                    </label>
                  </div>
                  <div className="w-1/3 text-center">
                    <h3 className="text-[#6a6a76] font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center mb-0">
                      {`${(analytics.asset_allocation.Alternate * 100).toFixed(
                        1
                      )}%`}
                    </h3>
                    <label className="font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                      Alternates
                    </label>
                  </div>
                  <div className="w-1/3 text-center border-r border-r-dividerColor mt-4">
                    <h3 className="text-[#1982f8] font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center mb-0">
                      {`₹ ${portfolioValue.toFixed(1)} ${
                        formValue.proposal_value.denomination[2]
                      }`}
                    </h3>
                    <label className="font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                      Value
                    </label>
                  </div>
                  <div className="w-1/3 text-center mt-4">
                    <h3
                      className="font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center mb-0"
                      style={
                        Math.round(portfolioSum * 10) / 10 !== 100
                          ? { color: "red" }
                          : { color: "#00B031" }
                      }
                    >
                      {portfolioSum.toFixed(1)}%
                    </h3>
                    <label className="font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                      Total
                    </label>
                  </div>
                </div>
                <div className="mt-[1rem] min-h-[600px]">
                  <table
                    style={{ width: "100%", height: "100%" }}
                    className="investedge-table"
                  >
                    <colgroup>
                      <col width="40%" />
                      <col width="20%" />
                      <col width="16%" />
                      <col width="16%" />
                      <col width="8%" />
                    </colgroup>
                    <tbody>
                      <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                        <th
                          style={{
                            textAlign: "left",
                          }}
                          className="font-inter text-[12px] font-medium leading-[20px] tracking-[0em] text-left py-[0.25rem] pl-[1rem] pr-0 !text-xs"
                        >
                          Name
                        </th>
                        <th className="font-inter text-[12px] font-medium leading-[20px] tracking-[0em] text-center py-[0.25rem] px-0 !text-xs">
                          Asset Class/ <br />
                          Product Category
                        </th>
                        <th className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] text-center py-[0.25rem] px-0 !text-xs">
                          Amount
                          {/* <br /> (in ₹{" "}
                          {formValue.proposal_value.denomination[2]}) */}
                        </th>
                        <th className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] text-center py-[0.25rem] px-0 !text-xs">
                          Weight
                          <br /> (% of Total)
                        </th>
                        <th className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] text-center py-[0.25rem] px-0"></th>
                      </tr>
                      {displaydata}
                    </tbody>
                  </table>
                </div>
                <div className="flex w-full justify-between pt-0 items-center">
                  <InvestedgeButton
                    className="mt-4 w-[175px]"
                    onClick={() => {
                      dispatch(
                        PostEventLoggingData({
                          module_name: "Portfolio Insights",
                          event_type: "Add button",
                          url: window.location.href,
                          note: `Fourth Screen fund added`,
                        })
                      );
                      setAfmodal(true);
                    }}
                  >
                    <img
                      className="w-[14px] object-contain mr-[0.25rem]"
                      src={Add}
                      alt="err"
                    />
                    Add Products
                  </InvestedgeButton>
                  <AddFundsModal
                    onClose={() => setAfmodal(false)}
                    afmodal={afmodal}
                    parsedData={parsedData}
                    setParsedData={setParsedData}
                    addedFundsMap={addedFundsMap}
                  />
                  <div className="flex justify-end w-full mt-4 items-center">
                    <InvestedgePagination
                      count={pageCount}
                      defaultPage={1}
                      page={pageNumber + 1}
                      onChange={changePage}
                      color="primary"
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="w-[45%]">
            <div className="w-[100%] flex justify-between border-b  border-[#e0dddd]">
              <div className="flex">
                <h3 className=" font-inter text-[16px] font-medium leading-[20px] tracking-[0em] text-left pb-[0.3rem]">
                  Analytics
                </h3>
                <p
                  className={
                    section === "Overview"
                      ? "border-b border-primary-200 ml-[2rem] text-primary-200 font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-left  my-0 mr-[1rem] cursor-pointer"
                      : "ml-[2rem] font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-[#000] text-left  my-0 mr-[1rem] cursor-pointer"
                  }
                  onClick={() => setSection("Overview")}
                >
                  Overview
                </p>
                <p
                  className={
                    section === "Equity"
                      ? "border-b border-primary-200 ml-[2rem] text-primary-200 font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-left  my-0 mr-[1rem] cursor-pointer"
                      : "ml-[2rem] font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-[#000] text-left  my-0 mr-[1rem] cursor-pointer"
                  }
                  onClick={() => setSection("Equity")}
                >
                  Equity
                </p>
                <p
                  className={
                    section === "Debt"
                      ? "border-b border-primary-200 ml-[2rem] text-primary-200 font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-left  my-0 mr-[1rem] cursor-pointer"
                      : "ml-[2rem] font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-[#000] text-left  my-0 mr-[1rem] cursor-pointer"
                  }
                  onClick={() => setSection("Debt")}
                >
                  Debt
                </p>
              </div>
              <div
                className="flex cursor-pointer items-baseline"
                onClick={() => {
                  let temp = derationalizeProducts(parsedData);
                  dispatch(
                    PostEventLoggingData({
                      module_name: "Portfolio Insights",
                      event_type: "Refresh",
                      url: window.location.href,
                      note: `Fourth Screen analytics refreshed`,
                    })
                  );
                  dispatch(GetAnalyticsAPI({ "Mutual Fund": temp }));
                }}
              >
                <img src={Reload} alt="err" className="w-[10px]" />
                <p className=" font-inter text-[12px] font-medium tracking-[0em] text-left text-primary-200 mb-0 align-bottom ml-[0.2rem]">
                  Simulate
                </p>
              </div>
            </div>
            {!analytics.loading ? handleSections(section) : ""}
          </div>
        </div>
        {analytics.loading ? (
          <div className="w-full h-[370px] flex justify-center items-center">
            <Bars color="#1982F8" />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
