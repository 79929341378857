import React, { useRef, useState } from "react";
import Cloud from "../../../../../assets/img/greyuploadcloud.svg";
import {
  PostClientInputUploadFile,
  PostEventLoggingData,
} from "../../../../../actions/actions";
import Tick from "../../../../../assets/img/module_access_type_tick.svg";
import { useDispatch, useSelector } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { Bars } from "react-loader-spinner";
import InvestedgeTextField from "../../../../../components/textfield/InvestedgeTextField";
import InvestedgeButton from "../../../../../components/buttons/InvestedgeButton";
export default function UploadStatementScreen({
  statementtype,
  setRoute,
  setPortfolio,
}) {
  const [file, setFile] = useState(null);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const excelInputRef = useRef(null);
  const [fileUpload, setFileUpload] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const portfolioUpload = useSelector(
    (store) => store.postClientInputUploadFile
  );
  return (
    <>
      <div className="w-full h-[500px] flex items-center">
        <div className="flex flex-col justify-center items-center h-[90%] w-full">
          <p className=" font-inter  text-[20px] text-neutralBlack mb-[1rem]">
            Upload {statementtype} Statement
          </p>
          {!submitted ? (
            <div className="w-[60%] family-details-fieldset portfolio-details-fieldset upload-statement-fieldset">
              <img className="w-[25px] h-[25px]" src={Cloud} alt="err" />
              <FileUploader
                multiple={false}
                handleChange={(file) => {
                  setFileUpload(true);
                  setFile(file);
                }}
                name="file"
                types={["PDF"]}
              >
                <p className="font-inter  text-[14px] text-neutralBlack mb-[1rem] mt-[0.5rem]">
                  Drag and Drop account statement
                </p>
              </FileUploader>

              <p className="font-inter  text-[14px] text-neutralBlack mb-[1rem]">
                Or
              </p>
              <input
                ref={excelInputRef}
                style={{ display: "none" }}
                onInput={() => {
                  setFileUpload(true);
                  setFile(excelInputRef.current.files[0]);
                }}
                type="file"
                accept=".pdf"
              />
              {!fileUpload ? (
                <div
                  className=" bg-primary-accent font-inter text-primary-200 py-[0.4rem] px-[0.5rem] text-center rounded-[0.25rem] text-[14px] "
                  onClick={() => {
                    dispatch(
                      PostEventLoggingData({
                        module_name: "Client Details",
                        event_type: "Browse",
                        url: window.location.href,
                        note: `${localStorage.getItem(
                          "clientName"
                        )} browsed protfolio`,
                      })
                    );
                    excelInputRef.current.click();
                  }}
                >
                  Browse
                </div>
              ) : (
                <img src={Tick} alt="err" className="mx-auto" />
              )}

              <InvestedgeTextField
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                  width: "40%",
                  marginTop: "1rem",
                }}
                label="Password, if any"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <div className="upload-statement-submit-container">
                <InvestedgeButton
                  disabled={!fileUpload}
                  onClick={() => {
                    fileUpload &&
                      dispatch(
                        PostClientInputUploadFile({
                          client_id: localStorage.getItem("clientId"),
                          file: file,
                          file_type: 1,
                          password: password,
                        })
                      );
                    fileUpload &&
                      dispatch(
                        PostEventLoggingData({
                          module_name: "Client Details",
                          event_type: "Submit button",
                          url: window.location.href,
                          note: `${localStorage.getItem(
                            "clientName"
                          )} Portfolio updated`,
                        })
                      );
                    fileUpload && setSubmitted(true);
                    fileUpload && setPortfolio("selected");
                  }}
                >
                  Submit
                </InvestedgeButton>
              </div>
            </div>
          ) : portfolioUpload === "loading" ? (
            <div className="client-dashboard-table w-full min-h-[250px] flex flex-col justify-center items-center opacity-[0.9]">
              <Bars color="#1982F8" />
              <p className=" font-inter  text-[14px] text-neutralBlack mb-[1rem] mt-[1rem]">
                Uploading Portfolio ...
              </p>
            </div>
          ) : !portfolioUpload.poorly_formatted.length &&
            !portfolioUpload.does_not_exist.length ? (
            <p className=" font-inter  text-[14px] text-neutralBlack mb-[1rem] mt-[3rem]">
              Submitted Successfully!
            </p>
          ) : (
            <>
              <p className=" font-inter  text-[14px] mt-[3rem] mb-[1rem] text-neutralBlack w-full text-center">
                Following funds either don't exist or are poorly formatted:
                <ul className="w-full flex list-disc px-8 mt-2">
                  {portfolioUpload.poorly_formatted
                    .concat(portfolioUpload.does_not_exist)
                    .map((item, index) => (
                      <li className="mt-0 ml-[2rem]" key={index}>
                        {item}
                      </li>
                    ))}
                </ul>
              </p>
            </>
          )}
        </div>
      </div>
      <div className="flex justify-end">
        <InvestedgeButton
          // onClick={() => {
          //   dispatch(
          //     PostEventLoggingData({
          //       module_name: "Portfolio Insights",
          //       event_type: "Start button",
          //       url: window.location.href,
          //       note: `Create Pitchbook started`,
          //     })
          //   );
          // }}
          onClick={() => {
            setRoute("portfolioSelectionScreen");
          }}
        >
          Back
        </InvestedgeButton>
      </div>
    </>
  );
}
