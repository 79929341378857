// import React, { useEffect, useState } from "react";
// import BlueRationale from "../../../../assets/img/rationale_type_blue.svg";
// import GreenRationale from "../../../../assets/img/rationale_type_green.svg";
// import GreyRationale from "../../../../assets/img/rationale_type_grey.svg";
// import { usePopper } from "react-popper";
import Cross from "../../../../assets/img/standardcross.svg";
// import { useDispatch } from "react-redux";
// import { PostEventLoggingData } from "../../../../actions/actions";
export default function ActionableTableRow({
  client,
  handleWeightChange,
  parsedActions,
  setParsedActions,
  parsedData,
  setParsedData,
  setPortfolioActions,
  setPortfolioProducts,
  derationalizeProducts,
  handleCross,
  portfolioValue,
}) {
  // const dispatch = useDispatch();
  // const [isActive, setIsActive] = useState(false);
  // const [referenceRef, setReferenceRef] = useState(null);
  // const [popperRef, setPopperRef] = useState(null);
  // const { styles, attributes } = usePopper(referenceRef, popperRef, {
  //   placement: "right-start",
  //   modifiers: [
  //     {
  //       name: "offset",
  //       enabled: true,
  //     },
  //     {
  //       name: "arrow",
  //       enabled: true,
  //     },
  //     {
  //       name: "flip",
  //       options: {
  //         fallbackPlacements: ["bottom", "bottom"],
  //       },
  //     },
  //   ],
  // });
  // const [rationaleType, setRationalType] = useState(client.rationale_type);
  // const [rationale, setRationale] = useState(client.rationale);
  // useEffect(() => {
  //   setRationalType(client.rationale_type);
  //   setRationale(client.rationale);
  // }, [client, parsedData]);
  // const handleConfirm = () => {
  //   let index = parsedData.findIndex((x) => x.product_id === client.product_id);
  //   let newProducts = [...parsedData];
  //   newProducts[index].rationale = rationale;
  //   newProducts[index].rationale_type = rationaleType;
  //   newProducts[index].list_type = 1;
  //   setParsedData(newProducts);
  //   let actionsindex = parsedActions.findIndex(
  //     (x) => x.product_id === client.product_id
  //   );
  //   let newActions = [...parsedActions];
  //   newActions[actionsindex].rationale = rationale;
  //   newActions[actionsindex].rationale_type = rationaleType;
  //   dispatch(
  //     PostEventLoggingData({
  //       module_name: "Portfolio Review",
  //       event_type: "Save button",
  //       url: window.location.href,
  //       note: `Rationale for ${client.product_id} saved`,
  //     })
  //   );
  //   setParsedActions(newActions);
  //   setPortfolioActions(newActions);
  //   setPortfolioProducts(derationalizeProducts(newProducts));
  //   setIsActive(false);
  // };
  return (
    <tr
      className="border-primarytablebottomClr border-b h-[40px]"
      key={client.product_id}
    >
      <td
        style={{
          paddingLeft: "1rem",
          textAlign: "left",
        }}
        className=" text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-left pl-[1rem]"
      >
        {client.product_name}
      </td>

      <td className=" text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
        {`${client.asset_type} - ${client.product_type}`}
      </td>
      <td className=" text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
        {((client.amount * portfolioValue) / 10000000).toFixed(1)}
      </td>
      <td className=" text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
        <input
          className="border border-[#e5e5e5] w-[100%] h-[50px] shadow-none text-center font-inter text-[14px] bg-[#fafafa]"
          type="text"
          value={client.weight}
          onChange={(e) => handleWeightChange(e, client.product_id)}
        />
      </td>
      <td>
        <img
          className="w-[14px] object-contain"
          src={Cross}
          alt="err"
          onClick={() => handleCross(client.product_id)}
        />
      </td>
    </tr>
  );
}
