import React from "react";
import { GraphColorPalette } from "../../../../components/colors/graphColorPalette";
import PieChart from "../../../dashboard/PieChart";
import ProgressBar from "./ProgressBar";
export default function OverviewSection({ analytics }) {
  const keys = [];
  const values = [];
  let maxStock = 0;
  const bgGradients = [
    "linear-gradient(223.78deg, #09C3E7 0%, #7669F0 106.87%)",
    "linear-gradient(225deg, #9C2979 0%, #F15E75 100%)",
    "linear-gradient(108.81deg, #FF9F38 -27.65%, #FE5C44 125%)",
    "linear-gradient(108.81deg, #FFD166 -27.65%, #C26F0D 125%)",
  ];
  // const displayingHorizontalChartLabels = (value) => {
  //   return value.length > 4 ? `${value.slice(0, 4)}..` : value.slice(0, 4);
  // };
  for (const property in analytics.product_allocation) {
    keys.push(property);
    values.push((analytics.product_allocation[property] * 100).toFixed(1));
    maxStock = Math.max(maxStock, analytics.product_allocation[property] * 100);
  }

  const assetdata = {
    labels: ["Equity", "Debt", "Alternates"],

    datasets: [
      {
        data: [
          (analytics.asset_allocation.Equity * 100).toFixed(1),
          (analytics.asset_allocation.Debt * 100).toFixed(1),
          (analytics.asset_allocation.Alternate * 100).toFixed(1),
        ],
        backgroundColor: GraphColorPalette,
        borderColor: GraphColorPalette,
        borderWidth: 1,
      },
    ],
  };
  const pieoptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      labels: {
        render: "value",
        outsidePadding: 14,
        textMargin: 3,
        fontColor: "#fff",
      },
      datalabels: {
        display: false,
      },
    },
  };

  const LegendItem = ({ color, text }) => {
    return (
      <div className="flex">
        <div
          className="w-[7px] h-[7px] block mt-[7px]"
          style={{ backgroundColor: color }}
        />
        <p className="portfolio-stepper-third-form-legend-item-name">{text}</p>
      </div>
    );
  };
  return (
    <>
      <div className=" w-full mt-[1rem] bg-[#fafafa] p-[1rem] flex rounded-[6px]">
        <div className="w-[20%] text-center border-r border-r-dividerColor">
          <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0">{`${(
            analytics.port_return * 100
          ).toFixed(1)}%`}</h3>
          <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
            3Y CAGR
          </label>
        </div>
        <div className="w-[25%] text-center border-r border-r-dividerColor">
          <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0">{`${(
            analytics.bm_blended_return * 100
          ).toFixed(1)}%`}</h3>
          <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
            3Y BM* CAGR
          </label>
        </div>
        <div className="w-[27.5%] text-center border-r border-r-dividerColor">
          <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#1982f8] mb-0">
            {analytics.total_products}
          </h3>
          <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
            Total Products
          </label>
        </div>
        <div className="w-[27.5%] text-center">
          <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#00B031] mb-0">
            {`${(analytics.liquidity * 100).toFixed(1)}%`}
          </h3>
          <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
            Easily Liquidable
          </label>
        </div>
      </div>
      <div className="flex pt-[1rem] justify-between">
        <div className="bg-[#fafafa] w-[40%] h-[275px] p-[1rem]">
          <h4 className=" font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-4">
            Asset Allocation (in %)
          </h4>
          <PieChart
            data={assetdata}
            options={pieoptions}
            width={100}
            height={190}
          />
          <div className="flex justify-between w-full z-[100] mt-2">
            <LegendItem color={"#ED6D85"} text={"Equity"} />
            <LegendItem color={"#56A1E5"} text={"Debt"} />
            <LegendItem color={"#F7CE6B"} text={"Alternate"} />
          </div>
        </div>
        <div className="w-[59%] bg-[#fafafa] h-[275px] p-[1rem]">
          <h4 className=" font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-4">
            Product Allocation (Overall in %)
          </h4>
          {keys.map((sector, index) => (
            <ProgressBar
              key={index}
              bg={bgGradients[index % bgGradients.length]}
              progress={values[index]}
              label={sector}
            />
          ))}
        </div>
      </div>
    </>
  );
}
