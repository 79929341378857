import React, { useEffect } from "react";
import Add from "../../../../../assets/img/circle_over_plus_blue.svg";
import Cashrow from "./Cashrow";
import { useState } from "react";
import urls from "../../../../../urls";
import { useDispatch, useSelector } from "react-redux";
import {
  GetDashboardCashPortfolio,
  GetDashboardPortfolioList,
  OpenSnackbar,
} from "../../../../../actions/actions";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import InvestedgeButton from "../../../../../components/buttons/InvestedgeButton";
export default function CashScreen() {
  const data = useSelector((store) => store.dashboardCashPortfolio);
  const [productarray, setProductarray] = useState(
    JSON.parse(JSON.stringify(data)).map((y) => {
      return {
        ...y,
        last_amount_date: new Date(y.last_amount_date),
        current_amount_date: new Date(y.current_amount_date),
      };
    })
  );
  const [patchProductArray, setPatchProductArray] = useState([]);
  const [postProductArray, setPostProductArray] = useState([]);
  const portfolioList = useSelector(
    (store) => store.dashboardPortfolioPriceList
  );
  const [submitActive, setSubmitActive] = useState(false);
  const dispatch = useDispatch();
  const productsData = [
    "Other",
    "Bank of Baroda",
    "Bank of India",
    "Bank of Maharashtra",
    "Canara Bank",
    "Central Bank of India",
    "Indian Bank",
    "Indian Overseas Bank",
    "Punjab & Sind Bank",
    "Punjab National Bank",
    "State Bank of India",
    "UCO Bank",
    "Union Bank of India",
    "Axis Bank Ltd.",
    "Bandhan Bank Ltd.",
    "CSB Bank Ltd.",
    "City Union Bank Ltd.",
    "DCB Bank Ltd.",
    "Dhanlaxmi Bank Ltd.",
    "Federal Bank Ltd.",
    "HDFC Bank Ltd",
    "ICICI Bank Ltd.",
    "Induslnd Bank Ltd",
    "IDFC First Bank Ltd.",
    "Jammu & Kashmir Bank Ltd.",
    "Karnataka Bank Ltd.",
    "Karur Vysya Bank Ltd.",
    "Kotak Mahindra Bank Ltd",
    "Nainital Bank Ltd.",
    "RBL Bank Ltd.",
    "South Indian Bank Ltd.",
    "Tamilnad Mercantile Bank Ltd.",
    "YES Bank Ltd.",
    "IDBI Bank Ltd.",
    "Au Small Finance Bank Limited",
    "Capital Small Finance Bank Limited",
    "Equitas Small Finance Bank Limited",
    "Suryoday Small Finance Bank Limited",
    "Ujjivan Small Finance Bank Limited",
    "Utkarsh Small Finance Bank Limited",
    "ESAF Small Finance Bank Limited",
    "Fincare Small Finance Bank Limited",
    "Jana Small Finance Bank Limited",
    "North East Small Finance Bank Limited",
    "Shivalik Small Finance Bank Limited",
    "Unity Small Finance Bank Limited",
    "India Post Payments Bank Limited",
    "Fino Payments Bank Limited",
    "Paytm Payments Bank Limited",
    "Airtel Payments Bank Limited",
    "Andhra Pragathi Grameena Bank",
    "Andhra Pradesh Grameena Vikas Bank",
    "Arunachal Pradesh Rural Bank",
    "Aryavart Bank",
    "Assam Gramin Vikash Bank",
    "Bangiya Gramin Vikas Bank",
    "Baroda Gujarat Gramin Bank",
    "Baroda Rajasthan Kshetriya Gramin Bank",
    "Baroda UP Bank",
    "Chaitanya Godavari Grameena Bank",
    "Chhattisgarh Rajya Gramin Bank",
    "Dakshin Bihar Gramin Bank",
    "Ellaquai Dehati Bank",
    "Himachal Pradesh Gramin Bank",
    "J&K Grameen Bank",
    "Jharkhand Rajya Gramin Bank",
    "Karnataka Gramin Bank",
    "Karnataka Vikas Grameena Bank",
    "Kerala Gramin Bank",
    "Madhya Pradesh Gramin Bank",
    "Madhyanchal Gramin Bank",
    "Maharashtra Gramin Bank",
    "Manipur Rural Bank",
    "Meghalaya Rural Bank",
    "Mizoram Rural Bank",
    "Nagaland Rural Bank",
    "Odisha Gramya Bank",
    "Paschim Banga Gramin Bank",
    "Prathama UP Gramin Bank",
    "Puduvai Bharathiar Grama Bank",
    "Punjab Gramin Bank",
    "Rajasthan Marudhara Gramin Bank",
    "Saptagiri Grameena Bank",
    "Sarva Haryana Gramin Bank",
    "Saurashtra Gramin Bank",
    "Tamil Nadu Grama Bank",
    "Telangana Grameena Bank",
    "Tripura Gramin Bank",
    "Utkal Grameen bank",
    "Uttar Bihar Gramin Bank",
    "Uttarakhand Gramin Bank",
    "Uttarbanga Kshetriya Gramin Bank",
    "Vidharbha Konkan Gramin Bank",
    "AB Bank Ltd.",
    "Abu Dhabi Commercial Bank Ltd.",
    "American Express Banking Corporation",
    "Australia and New Zealand Banking Group Ltd.",
    "Barclays Bank Plc.",
    "Bank of America",
    "Bank of Bahrain & Kuwait BSC",
    "Bank of Ceylon",
    "Bank of China",
    "Bank of Nova Scotia",
    "BNP Paribas",
    "Citibank N.A.",
    "Cooperatieve Rabobank U.A.",
    "Credit Agricole Corporate & Investment Bank",
    "Credit Suisse A.G",
    "CTBC Bank Co., Ltd.",
    "DBS Bank India Limited*",
    "Deutsche Bank",
    "Doha Bank Q.P.S.C",
    "Emirates Bank NBD",
    "First Abu Dhabi Bank PJSC",
    "FirstRand Bank Ltd",
    "HSBC Ltd",
    "Industrial & Commercial Bank of China Ltd.",
    "Industrial Bank of Korea",
    "J.P. Morgan Chase Bank N.A.",
    "JSC VTB Bank",
    "KEB Hana Bank",
    "Kookmin Bank",
    "Krung Thai Bank Public Co. Ltd.",
    "Mashreq Bank PSC",
    "Mizuho  Bank Ltd.",
    "MUFG Bank, Ltd.",
    "NatWest Markets Plc",
    "PT Bank Maybank Indonesia TBK",
    "Qatar National Bank (Q.P.S.C.)",
    "Sberbank",
    "SBM Bank (India) Limited*",
    "Shinhan Bank",
    "Societe Generale",
    "Sonali Bank Ltd.",
    "Standard Chartered Bank",
    "Sumitomo Mitsui Banking Corporation",
    "United Overseas Bank Ltd",
    "Woori Bank",
  ];
  const removeProductarray = (i) => {
    let newAifarray = [...productarray];
    newAifarray.splice(i, 1);
    setProductarray(newAifarray);
  };
  // const portfolioValue = useSelector(
  //   (store) => store.portfolioReviewGetSumary.portfolio_value
  // );
  // const parsePortfolio = (portfolio) => {
  //   if (portfolio / 1000 < 100)
  //     return `₹ ${parseFloat(portfolio / 1000).toFixed(1)} K`;
  //   if (portfolio / 100000 < 100)
  //     return `₹ ${parseFloat(portfolio / 100000).toFixed(1)} L`;
  //   return `₹ ${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  // };
  const [searchBar, setSearchBar] = useState(false);
  const [addedProduct, setAddedProduct] = useState("");
  const [deleteArray, setDeleteArray] = useState([]);
  useEffect(() => {
    dispatch(
      GetDashboardCashPortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setProductarray(data);
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    if (
      deleteArray.length ||
      postProductArray.length ||
      patchProductArray.length
    )
      setSubmitActive(true);
    else setSubmitActive(false);
  }, [deleteArray, postProductArray, patchProductArray]);
  const numberOfDays = (date1, date2) => {
    // To calculate the time difference of two dates
    var difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    var difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
    return difference_In_Days;
  };
  const handleSubmit = async () => {
    let newPatchList = patchProductArray.map((item) => {
      var dd = String(item.last_amount_date.getDate()).padStart(2, "0");
      var mm = String(item.last_amount_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.last_amount_date.getFullYear();
      var dd1 = String(item.current_amount_date.getDate()).padStart(2, "0");
      var mm1 = String(item.current_amount_date.getMonth() + 1).padStart(
        2,
        "0"
      );
      var yyyy1 = item.current_amount_date.getFullYear();
      return {
        id: item.id,
        name: item.name,
        last_amount: parseFloat(item.last_amount),
        last_amount_date: yyyy + "-" + mm + "-" + dd,
        bank: item.bank,
        interest_rate: parseFloat(item.interest_rate),
        current_amount: parseFloat(
          parseInt(item.last_amount) *
            (1 + parseFloat(item.interest_rate / 100)) **
              (numberOfDays(
                new Date(item.current_amount_date),
                new Date(item.last_amount_date)
              ) /
                365)
        ),
        current_amount_date: yyyy1 + "-" + mm1 + "-" + dd1,
        user_id: localStorage.getItem("clientId"),
      };
    });
    let newPostList = postProductArray.map((item) => {
      var dd = String(item.last_amount_date.getDate()).padStart(2, "0");
      var mm = String(item.last_amount_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.last_amount_date.getFullYear();
      var dd1 = String(item.current_amount_date.getDate()).padStart(2, "0");
      var mm1 = String(item.current_amount_date.getMonth() + 1).padStart(
        2,
        "0"
      );
      var yyyy1 = item.current_amount_date.getFullYear();
      return {
        name: item.name,
        last_amount: parseFloat(item.last_amount),
        last_amount_date: yyyy + "-" + mm + "-" + dd,
        bank: item.bank,
        interest_rate: parseFloat(item.interest_rate),
        current_amount: parseFloat(
          parseInt(item.last_amount) *
            (1 + parseFloat(item.interest_rate / 100)) **
              (numberOfDays(
                new Date(item.current_amount_date),
                new Date(item.last_amount_date)
              ) /
                365)
        ),
        current_amount_date: yyyy1 + "-" + mm1 + "-" + dd1,
        user_id: localStorage.getItem("clientId"),
      };
    });
    let newDeleteList = deleteArray.map((item) => {
      var dd = String(item.last_amount_date.getDate()).padStart(2, "0");
      var mm = String(item.last_amount_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.last_amount_date.getFullYear();
      var dd1 = String(item.current_amount_date.getDate()).padStart(2, "0");
      var mm1 = String(item.current_amount_date.getMonth() + 1).padStart(
        2,
        "0"
      );
      var yyyy1 = item.current_amount_date.getFullYear();
      return {
        id: item.id,
        name: item.name,
        last_amount: parseFloat(item.last_amount),
        last_amount_date: yyyy + "-" + mm + "-" + dd,
        bank: item.bank,
        interest_rate: parseFloat(item.interest_rate),
        current_amount: parseFloat(
          parseInt(item.last_amount) *
            (1 + parseFloat(item.interest_rate / 100)) **
              (numberOfDays(
                new Date(item.current_amount_date),
                new Date(item.last_amount_date)
              ) /
                365)
        ),
        current_amount_date: yyyy1 + "-" + mm1 + "-" + dd1,
        user_id: localStorage.getItem("clientId"),
      };
    });
    newDeleteList.length &&
      (await axios.post(urls.dashboardCashPortfolioDeleteURL, newDeleteList));
    newPostList.length &&
      (await axios.post(urls.dashboardCashPortfolioURL, newPostList));
    newPatchList.length &&
      (await axios.patch(urls.dashboardCashPortfolioURL, newPatchList));
    setPatchProductArray([]);
    setPostProductArray([]);
    setDeleteArray([]);
    dispatch(
      GetDashboardPortfolioList({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      GetDashboardCashPortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      OpenSnackbar({
        severity: "success",
        message: "Portfolio Updated Successfully",
      })
    );
  };
  return (
    <div className="w-full">
      <div className="font-medium font-inter text-[18px] text-neutralBlack">
        Cash - {portfolioList.total_cash_or_saving_ac_value}
      </div>
      <div className="flex justify-between w-[96%] mt-[4px] mb-0">
        <div className="flex justify-center items-center h-[56px]">
          <img src={Add} alt="err" />
          <p
            className="text-primary-200 text-[14px] font-medium mb-0 ml-[0.3rem]"
            onClick={
              () => setSearchBar(true)
              // let newAifarray = [...productarray];
              // newAifarray.unshift({ name: "New Mutual Fund", price: "0" });
              // setProductarray(newAifarray);
            }
          >
            Add Cash
          </p>
          {searchBar && (
            <FormControl
              size="small"
              sx={{
                width: "200px",
                marginLeft: "1rem",
                "& .MuiInputBase-inputSizeSmall": {
                  paddingTop: "10px",
                  paddingBottom: "10px",
                },
              }}
            >
              <InputLabel>
                <span className="font-inter font-normal text-sm">
                  Choose Bank
                </span>
              </InputLabel>
              <Select
                label="Choose Bank"
                onChange={(e) => {
                  setSearchBar(false);
                  setAddedProduct(e.target.value.product_id);
                  let newAifarray = [...productarray];
                  newAifarray.unshift({
                    name: "",
                    last_amount: parseFloat(0),
                    last_amount_date: new Date(),
                    bank: e.target.value,
                    interest_rate: parseFloat(0),
                    current_amount: parseFloat(0),
                    current_amount_date: new Date(),
                    user_id: localStorage.getItem("clientId"),
                  });
                  setPostProductArray([
                    ...postProductArray,
                    {
                      name: "",
                      last_amount: parseFloat(0),
                      last_amount_date: new Date(),
                      bank: e.target.value,
                      interest_rate: parseFloat(0),
                      current_amount: parseFloat(0),
                      current_amount_date: new Date(),
                      user_id: localStorage.getItem("clientId"),
                    },
                  ]);
                  setProductarray(newAifarray);
                }}
                className="font-inter text-sm"
              >
                {productsData.map((option, ind) => (
                  <MenuItem value={option} id={ind} key={ind}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        {/* <p className="asset-handler-text" onClick={() => setProductarray([])}>
          Clear All
        </p> */}
      </div>
      <div className="update-portfolio-asset-row-container  h-[63vh]">
        {productarray.map((fund, index) => (
          <Cashrow
            key={fund.id}
            fund={fund}
            id={index}
            removeProductarray={removeProductarray}
            productarray={productarray}
            setProductarray={setProductarray}
            patchProductArray={patchProductArray}
            setPatchProductArray={setPatchProductArray}
            deleteArray={deleteArray}
            setDeleteArray={setDeleteArray}
            data={data}
            postProductArray={postProductArray}
            setPostProductArray={setPostProductArray}
            addedProduct={addedProduct}
          />
        ))}
      </div>
      <div className="flex w-full mt-[24px] pr-[24px] justify-end">
        <InvestedgeButton
          disabled={!submitActive}
          onClick={() => {
            if (
              patchProductArray.length ||
              postProductArray.length ||
              deleteArray.length
            ) {
              setSubmitActive(false);
              handleSubmit();
            }
          }}
        >
          Submit
        </InvestedgeButton>
      </div>
    </div>
  );
}
