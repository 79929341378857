import React from "react";
import { IoIosClose } from "react-icons/io";
import InvestedgeModal from "../../../components/modal/InvestedgeModal";
import Down from "../../../assets/img/down.svg";
import Up from "../../../assets/img/up.svg";
import Const from "../../../assets/img/const.svg";
import parse from "html-react-parser";
export default function LatestMarketEventModal({ topic, open, onClose }) {
  return (
    <InvestedgeModal
      aria-labelledby={`Market Event ${topic.id}`}
      aria-describedby={`Market Event ${topic.id}`}
      open={open}
      handleclose={onClose}
    >
      <div className="flex justify-between border-0 pt-0">
        <h5 className="font-medium text-xl align-left p-0 font-inter text-neutralBlack">
          {topic.title}
        </h5>
        <IoIosClose size={30} onClick={onClose} />
      </div>
      <div className="modal-body text-sm mt-4">
        <h2 className="font-inter text-[14px] font-normal leading-[20px] tracking-[0em] text-left">
          {parse(topic.content)}
        </h2>
        <div className="flex items-baseline flex-row mb-0 mt-3">
          {topic.feedback_economy > 0 ? (
            <div className=" bg-success-300 flex gap-2 flex-row items-baseline mr-3 font-inter rounded-md font-medium text-[13px] py-[4px] px-[10px]">
              <span className="text-xs">Economy</span>
              <span>
                <img src={Up} alt="err" />
              </span>
            </div>
          ) : topic.feedback_economy === 0 ? (
            <div className="bg-[#777] flex gap-2 flex-row items-baseline mr-3 font-inter rounded-md font-medium text-[13px] py-[4px] px-[10px]">
              <span className="text-xs">Economy</span>
              <span>
                <img src={Const} alt="err" className="mb-[2px]" />
              </span>
            </div>
          ) : topic.feedback_economy < 0 ? (
            <div className="bg-error-200 flex gap-2 flex-row items-baseline mr-3 font-inter rounded-md font-medium text-[13px] py-[4px] px-[10px]">
              <span className="text-xs">Economy</span>
              <span>
                <img src={Down} alt="err" />
              </span>
            </div>
          ) : (
            <div></div>
          )}

          {topic.feedback_equity > 0 ? (
            <div className="bg-success-300 flex gap-2 flex-row items-baseline mr-3 font-inter rounded-md font-medium text-[13px] py-[4px] px-[10px]">
              <span className="text-xs">Equity</span>
              <span>
                <img src={Up} alt="err" />
              </span>
            </div>
          ) : topic.feedback_equity === 0 ? (
            <div className="bg-[#777] flex gap-2 flex-row items-baseline mr-3 font-inter rounded-md font-medium text-[13px] py-[4px] px-[10px]">
              <span className="text-xs">Equity</span>
              <span>
                <img src={Const} alt="err" className="mb-[2px]" />
              </span>
            </div>
          ) : topic.feedback_equity < 0 ? (
            <div className="bg-error-200 flex gap-2 flex-row items-baseline mr-3 font-inter rounded-md font-medium text-[13px] py-[4px] px-[10px]">
              <span className="text-xs">Equity</span>
              <span>
                <img src={Down} alt="err" />
              </span>
            </div>
          ) : (
            <div></div>
          )}

          {topic.feedback_debt > 0 ? (
            <div className="bg-[#59ab32] flex gap-2 flex-row items-baseline mr-3 font-inter rounded-md font-medium text-[13px] py-[4px] px-[10px]">
              <span className="text-xs">Debt</span>
              <span>
                <img src={Up} alt="err" />
              </span>
            </div>
          ) : topic.feedback_debt === 0 ? (
            <div className="bg-[#777] flex gap-2 flex-row items-baseline mr-3 font-inter rounded-md font-medium text-[13px] py-[4px] px-[10px]">
              <span className="text-xs">Debt</span>
              <span>
                <img src={Const} alt="err" className="mb-[2px]" />
              </span>
            </div>
          ) : topic.feedback_debt < 0 ? (
            <div className="bg-error-200 flex gap-2 flex-row items-baseline font-inter rounded-md font-medium text-[13px] py-[4px] px-[10px]">
              <span className="text-xs">Debt</span>
              <span>
                <img src={Down} alt="err" />
              </span>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </InvestedgeModal>
  );
}
