import { THIRD_PARTY_LOGIN } from "../actions/type";
const initialState = { loading: true };
export default function thirdPartyLogin(state = initialState, action) {
  switch (action.type) {
    case THIRD_PARTY_LOGIN:
      return action.payload;
    default:
      return state;
  }
}
