import React, { useState, useEffect, useRef } from "react";
import AfterloginApp from "../AfterloginApp";
import SearchIcon from "../../assets/img/global_search_icon.svg";
import MicrophoneIcon from "../../assets/img/bluemicrophone.svg";
import GreyMicrophoneIcon from "../../assets/img/greymicrophone.svg";
import { Link } from "react-router-dom";
import {
  MarketFAQS,
  PostEventLoggingData,
  GetTrendingFAQS,
  GetSavedFAQS,
} from "../../actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "../../static/css/existing/dashboard_styles.css";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Bars } from "react-loader-spinner";
import ClickAwayListener from "react-click-away-listener";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import InvestedgeTabs from "../../components/tabs/InvestedgeTabs";
import InvestedgePopper from "../../components/popper/InvestedgePopper";
import { MenuItem } from "@mui/material";
export default function Search() {
  const inputDivRef = useRef(null);
  const { listening, transcript } = useSpeechRecognition();
  const [faqType, setFaqType] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(GetTrendingFAQS());
    dispatch(GetSavedFAQS());
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setquestion(transcript);
  }, [transcript]);

  const tabs = [{ label: "Trending" }, { label: "Bookmarked" }];
  const [question, setquestion] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const aboutData = useSelector((state) => state.settingsAbout);
  const savedFaqData = useSelector((state) => state.savedFaqs);
  const faqData = useSelector((state) => state.faqdata);
  const trendingFaqData = useSelector((state) => state.trendingFaqs);
  const handleSearch = (e) => {
    e.preventDefault();
    history.push({
      pathname: `/searchresults`,
      search: `?searchedquestion=${question}`,
    });
  };
  useEffect(() => {
    question.length >= 3 &&
      dispatch(MarketFAQS({ params: { query: question } }));
    if (question.length >= 3) setAnchorEl(inputDivRef.current);
    else setAnchorEl(null);
    // eslint-disable-next-line
  }, [question]);

  let savedFaqIds = {};
  for (let i = 0; i < savedFaqData.length; i++)
    savedFaqIds[savedFaqData[i].id] = true;
  const handletabchange = (event, newValue) => setFaqType(newValue);
  const faqs = (faqType) => {
    switch (faqType) {
      case 0:
        return (
          <div className="w-full h-[225px]">
            {trendingFaqData.slice(0, 5).map((item, index) => {
              return (
                <Link
                  to={{
                    pathname: "/searchviewquestion",
                    state: {
                      faq: item.id,
                      savedFaqIds: savedFaqIds,
                    },
                  }}
                  onClick={() =>
                    dispatch(
                      PostEventLoggingData({
                        module_name: "Search",
                        event_type: "Information",
                        url: window.location.href,
                        note: item.question,
                      })
                    )
                  }
                  key={index}
                >
                  <p className="font-inter text-[15px] leading-5 tracking-[0.02rem] text-primary-200 mb-[5%] md:mb-[3%]">
                    {item.question}
                  </p>
                </Link>
              );
            })}
          </div>
        );
      case 1:
        return (
          <div className="w-full h-[225px]">
            {savedFaqData.slice(0, 5).map((item, index) => {
              return (
                <Link
                  to={{
                    pathname: "/searchviewquestion",
                    state: {
                      faq: item.id,
                      savedFaqIds: savedFaqIds,
                    },
                  }}
                  onClick={() =>
                    dispatch(
                      PostEventLoggingData({
                        module_name: "Search",
                        event_type: "Information",
                        url: window.location.href,
                        note: item.question,
                      })
                    )
                  }
                  key={index}
                >
                  <p className="font-inter text-[15px] leading-5 tracking-[0.02rem] text-primary-200 mb-[3%]">
                    {item.question}
                  </p>
                </Link>
              );
            })}
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className="bg-[#fff] p-[1rem] rounded-[00.5rem] h-full w-full min-h-[calc(100vh_-_116px)]">
            <div className="min-h-[calc(100vh_-_116px_-_32px)] w-full flex flex-col justify-evenly items-center max-h-[600px] md:max-h-[710px]">
              <Link to="/search" className="flex items-center">
                <img
                  src={aboutData.logo}
                  alt="err"
                  className="h-[62.5px] object-contain"
                />
              </Link>

              <div className="w-[85%] lg:w-3/6 ">
                <form
                  onSubmit={handleSearch}
                  className="flex w-full justify-center"
                >
                  <div
                    ref={inputDivRef}
                    aria-describedby="search-screen-results-popper"
                    className="w-[88%] border !border-globalBorderColor py-[0.25rem] px-[1rem] pr-[1rem] flex rounded-[6px] justify-between items-center relative mb-[16%] md:mb-[6%] rounded-r-none hover:border-neutralBlack"
                  >
                    <img src={SearchIcon} alt="err" />
                    <input
                      placeholder="Search.."
                      value={question}
                      className="md:hidden border-none shadow-none w-[calc(100%_-_24px_-_40px)] ml-[0.5rem] text-[14px] placeholder:text-placeholder placeholder:font-normal placeholder:text-base"
                      onChange={(e) => setquestion(e.target.value)}
                    />
                    <input
                      placeholder="Search for view on Markets, Sectors, and Economy"
                      value={question}
                      className="hidden md:block border-none shadow-none w-[calc(100%_-_24px_-_40px)] ml-[0.5rem] text-[14px] placeholder:text-placeholder placeholder:font-normal placeholder:text-base"
                      onChange={(e) => setquestion(e.target.value)}
                    />
                    <img
                      src={listening ? GreyMicrophoneIcon : MicrophoneIcon}
                      alt="err"
                      className="w-[20px] object-contain"
                      onClick={
                        listening
                          ? SpeechRecognition.stopListening
                          : SpeechRecognition.startListening
                      }
                    />
                  </div>
                  <InvestedgeButton
                    className="h-[45px] rounded-l-none w-[12%] "
                    type="submit"
                    onClick={() =>
                      dispatch(
                        PostEventLoggingData({
                          module_name: "Search",
                          event_type: "Search button",
                          url: window.location.href,
                          note: question,
                        })
                      )
                    }
                  >
                    Search
                  </InvestedgeButton>
                  <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <InvestedgePopper
                      id="search-screen-results-popper"
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      className="h-[300px] md:w-[41.7%] w-[70%]"
                      placement="bottom-start"
                    >
                      {faqData[0] === "loading" ? (
                        <div className="h-[300px] flex justify-center items-center">
                          <Bars color="#1982F8" height="60" width="60" />
                        </div>
                      ) : faqData.length ? (
                        faqData.slice(0, 7).map((option, ind) => (
                          <Link
                            key={ind}
                            to={{
                              pathname: "/searchviewquestion",
                              state: {
                                faq: option.id,
                                savedFaqIds: savedFaqIds,
                              },
                            }}
                            className="hover:text-primary-200 hover:bg-secondary-100 "
                          >
                            <MenuItem
                              key={ind}
                              onClick={() => setAnchorEl(null)}
                              className="font-inter py-[1rem] text-neutralBlack max-w-full whitespace-normal"
                            >
                              {option.question}
                            </MenuItem>
                          </Link>
                        ))
                      ) : (
                        <div className="h-[300px] font-inter flex items-center justify-center font-medium text-xl text-neutralBlack">
                          No data found
                        </div>
                      )}
                    </InvestedgePopper>
                  </ClickAwayListener>
                </form>

                <InvestedgeTabs
                  tabs={tabs}
                  tabvalue={faqType}
                  handletabchange={handletabchange}
                  centered
                />
                <div className="mt-[32px]">{faqs(faqType)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
