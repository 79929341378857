import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { GetMFCentralOtp } from "../actions/actions";
import InvestedgeButton from "../components/buttons/InvestedgeButton";
import InvestedgeTextField from "../components/textfield/InvestedgeTextField";

export default function MFCentralStatement({ setRoute }) {
  const [data, setData] = useState({ pan: "", number: "" });
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col items-center justify-between h-[90%]">
      <div className="w-[80%] pt-12">
        <div className="font-inter text-[18px] text-neutralBlack mb-8 font-medium w-full flex justify-center">
          <p className="mx-auto">MF Central Statement</p>
        </div>

        <p className="font-inter text-[18px] text-neutralBlack mb-[1rem] text-sm mx-auto text-center">
          Please provide below details to help us fetch your clients’ updated
          portfolio from MF Central.
        </p>
        <div className="flex flex-col">
          <InvestedgeTextField
            sx={{
              fontFamily: "Inter,sans-sarif",
              fontWeight: 400,
              marginTop: "1rem",
              width: "50%",
            }}
            className="mx-auto"
            label="PAN Number"
            type="text"
            value={data.pan}
            onChange={(e) => setData({ ...data, pan: e.target.value })}
          />
          <div className="w-1/2 flex justify-between mx-auto">
            <InvestedgeTextField
              sx={{
                fontFamily: "Inter,sans-sarif",
                fontWeight: 400,
                marginTop: "2rem",
                width: "15%",
              }}
              label={null}
              type="text"
              value={"+91"}
            />
            <InvestedgeTextField
              sx={{
                fontFamily: "Inter,sans-sarif",
                fontWeight: 400,
                marginTop: "2rem",
                width: "84%",
              }}
              label="Mobile Number"
              value={data.number}
              onChange={(e) => {
                const re = /^\d+$/;
                if (
                  (e.target.value === "" || re.test(e.target.value)) &&
                  e.target.value.length <= 10
                )
                  setData({ ...data, number: e.target.value });
              }}
            />
          </div>
        </div>
        <div className="flex justify-center mt-8">
          <InvestedgeButton
            onClick={() => {
              dispatch(
                GetMFCentralOtp({
                  pan: data.pan,
                  mobile: "+91" + data.number,
                  client_id: localStorage.getItem("clientId"),
                })
              );
              setRoute("mfcentralotpscreen");
            }}
          >
            Proceed
          </InvestedgeButton>
        </div>
      </div>
    </div>
  );
}
