import React, { useEffect } from "react";
// import Add from "../../assets/img/circle_over_plus_blue.svg";
// import MFrow from "./MFrow";
import { useState } from "react";
import urls from "../../urls";
import { useDispatch, useSelector } from "react-redux";
import {
  GetDashboardMfPortfolio,
  GetDashboardPortfolioList,
  OpenSnackbar,
} from "../../actions/actions";
import { Bars } from "react-loader-spinner";
// import ClickAwayListener from "react-click-away-listener";
import axios from "axios";
// import InvestedgeTextField from "../../components/textfield/InvestedgeTextField";
// import InvestedgeButton from "../../components/buttons/InvestedgeButton";
// import InvestedgePopper from "../../components/popper/InvestedgePopper";
// import { MenuItem } from "@mui/material";
import InvestedgeTabs from "../../components/tabs/InvestedgeTabs";
import MFTransaction from "./Transaction/MFTransaction";
import MFPortfolio from "./Portfolio/MFPortfolio";
export default function MFscreen() {
  const data = useSelector((store) => store.dashboardMFPortfolio);
  const portfolioList = useSelector(
    (store) => store.dashboardPortfolioPriceList
  );
  const [mfarray, setMfarray] = useState(
    JSON.parse(JSON.stringify(data.transactions)).map((y) => {
      return { ...y, date: new Date(y.date) };
    })
  );
  const [mf_portfolio_array, setmfportfolioarray] = useState(
    JSON.parse(JSON.stringify(data.portfolio)).map((y) => {
      return { ...y, current_amount: y.count_units * y.current_price };
    })
  );
  const [search, setSearch] = useState("");
  const [type, setType] = useState("All");
  const [patchMfArray, setPatchMfArray] = useState([]);
  const [postMfArray, setPostMfArray] = useState([]);
  const [submitActive, setSubmitActive] = useState(false);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  // const productsData = useSelector((state) => state.getComparisonData);
  const removeMFarray = (i) => {
    let newMfarray = [...mfarray];
    newMfarray.splice(i, 1);
    setMfarray(newMfarray);
  };
  // const portfolioValue = useSelector(
  //   (store) => store.portfolioReviewGetSumary.portfolio_value
  // );
  // const parsePortfolio = (portfolio) => {
  //   if (portfolio / 1000 < 100)
  //     return `₹ ${parseFloat(portfolio / 1000).toFixed(1)} K`;
  //   if (portfolio / 100000 < 100)
  //     return `₹ ${parseFloat(portfolio / 100000).toFixed(1)} L`;
  //   return `₹ ${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  // };
  // const [searchBar, setSearchBar] = useState(false);
  // const [addedProduct, setAddedProduct] = useState("");
  // const [newFund, setNewFund] = useState("");
  // const [anchorEl, setAnchorEl] = useState(null);
  // const popperRef = useRef(null);
  const [deleteArray, setDeleteArray] = useState([]);
  useEffect(() => {
    dispatch(
      GetDashboardMfPortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    // console.log("exectued");
    setMfarray(data.transactions);
    // eslint-disable-next-line
  }, [data]);
  // useEffect(() => {
  //   newFund.length >= 3 &&
  //     dispatch(
  //       getComparisonData({ params: { name: newFund, type: "Mutual Fund" } })
  //     );
  //   if (newFund.length >= 3) setAnchorEl(popperRef.current);
  //   else setAnchorEl(null);
  //   // eslint-disable-next-line
  // }, [newFund]);
  useEffect(() => {
    if (deleteArray.length || postMfArray.length || patchMfArray.length)
      setSubmitActive(true);
    else setSubmitActive(false);
  }, [deleteArray, postMfArray, patchMfArray]);

  const handleSubmit = async () => {
    setloading(true);
    let newPatchList = patchMfArray.map((item) => {
      var dd = String(new Date(item.date).getDate()).padStart(2, "0");
      var mm = String(new Date(item.date).getMonth() + 1).padStart(2, "0");
      var yyyy = new Date(item.date).getFullYear();
      return {
        ...item,
        date: yyyy + "-" + mm + "-" + dd,
        user_id: localStorage.getItem("clientId"),
      };
    });
    let newPostList = postMfArray.map((item) => {
      var dd = String(new Date(item.date).getDate()).padStart(2, "0");
      var mm = String(new Date(item.date).getMonth() + 1).padStart(2, "0");
      var yyyy = new Date(item.date).getFullYear();
      return {
        ...item,
        date: yyyy + "-" + mm + "-" + dd,
        user_id: localStorage.getItem("clientId"),
      };
    });
    let newDeleteList = deleteArray.map((item) => {
      return item.id;
    });
    newDeleteList.length &&
      (await axios.post(urls.dashboardMFPortfolioDeleteURL, {
        user_id: localStorage.getItem("clientId"),
        id: newDeleteList,
      }));
    newPostList.length &&
      (await axios.post(urls.dashboardMFTransactionURL, newPostList));
    newPatchList.length &&
      (await axios.patch(urls.dashboardMFTransactionURL, newPatchList));

    setPatchMfArray([]);
    setPostMfArray([]);
    setDeleteArray([]);
    dispatch(
      GetDashboardPortfolioList({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      GetDashboardMfPortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      OpenSnackbar({
        severity: "success",
        message: "Portfolio Updated Successfully",
      })
    );
    setloading(false);
  };
  const tabs = [{ label: "Portfolio" }, { label: "Transactions" }];
  const [tabType, setTabType] = useState(0);
  const handletabchange = (event, newValue) => {
    setTabType(newValue);
  };
  // console.log(mfarray.length);
  return (
    <div className="w-full">
      {data.loading ? (
        <div className="flex flex-col justify-center items-center w-[96%] h-[62vh]">
          <Bars color="#1982F8" />
        </div>
      ) : !loading ? (
        <>
          <InvestedgeTabs
            tabs={tabs}
            tabvalue={tabType}
            handletabchange={handletabchange}
          />
          {tabType === 0 ? (
            <MFPortfolio
              portfolioList={portfolioList}
              products={mf_portfolio_array}
              setProducts={setmfportfolioarray}
            />
          ) : (
            <MFTransaction
              submitActive={submitActive}
              setSubmitActive={setSubmitActive}
              handleSubmit={handleSubmit}
              products={mfarray}
              setProducts={setMfarray}
              patchMfArray={patchMfArray}
              setPatchMfArray={setPatchMfArray}
              deleteArray={deleteArray}
              setDeleteArray={setDeleteArray}
              postMfArray={postMfArray}
              setPostMfArray={setPostMfArray}
              removeMFarray={removeMFarray}
              search={search}
              setSearch={setSearch}
              type={type}
              setType={setType}
            />
          )}
        </>
      ) : (
        <div className="flex flex-col justify-center items-center w-[96%] h-[62vh]">
          <Bars color="#1982F8" />
          <p className=" font-inter text-[14px] font-medium text-neutralBlack mb-[1rem] mt-[1rem]">
            Updating Portfolio (may take 3-5 mins)...
          </p>
        </div>
      )}
      {/* {!loading ? (
        <>
          <div className="flex justify-between w-[96%] mt-[4px] mb-0">
            <div className="flex justify-center items-center h-[56px] cursor-pointer">
              <img src={Add} alt="err" />
              <p
                className="text-primary-200 text-[14px] font-medium mb-0 ml-[0.3rem]"
                onClick={
                  () => setSearchBar(true)
                  // let newMfarray = [...mfarray];
                  // newMfarray.unshift({ name: "New Mutual Fund", price: "0" });
                  // setMfarray(newMfarray);
                }
              >
                Add Mutual Fund
              </p>
              {searchBar && (
                <div className="w-[200px] select-none ml-[16px]">
                  <InvestedgeTextField
                    forwardedref={popperRef}
                    sx={{
                      fontFamily: "Inter,sans-sarif",
                      fontWeight: 400,
                      width: "200px",
                    }}
                    label="Search Fund"
                    type="text"
                    value={newFund}
                    onChange={(e) => setNewFund(e.target.value)}
                  />
                  <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <InvestedgePopper
                      id="mf-client-input-popper"
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      placement="bottom-start"
                      className="z-[100000] h-[300px]"
                    >
                      {productsData[0] === "loading" ? (
                        <div className="h-[300px] flex justify-center items-center w-[200px]">
                          <Bars color="#1982f8" height="60" width="60" />
                        </div>
                      ) : productsData.length ? (
                        <div className="h-[300px] productunderstading-scrollablediv overflow-auto">
                          {productsData.map((option, ind) => (
                            <MenuItem
                              key={ind}
                              onClick={() => {
                                setSearchBar(false);
                                const rnd = Math.random();
                                setAddedProduct(rnd);
                                let newMfarray = [...mfarray];
                                newMfarray.unshift({
                                  id: rnd,
                                  mf_id: option.product_id,
                                  name: option.name,
                                  count_units: 1,
                                  purchase_date: new Date(),
                                  purchase_price: 1,
                                  current_price: option.nav,
                                  current_price_date: new Date(),
                                });
                                setPostMfArray([
                                  ...postMfArray,
                                  {
                                    id: rnd,
                                    mf_id: option.product_id,
                                    name: option.name,
                                    count_units: 1,
                                    purchase_date: new Date(),
                                    purchase_price: 1,
                                    current_price: option.nav,
                                    current_price_date: new Date(),
                                  },
                                ]);
                                setMfarray(newMfarray);
                                setAnchorEl(null);
                              }}
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                        </div>
                      ) : (
                        <div className="h-[300px] flex items-center justify-center w-[200px]">
                          No data found
                        </div>
                      )}
                    </InvestedgePopper>
                  </ClickAwayListener>
                </div>
              )}
            </div>
          </div>
          <div className="update-portfolio-asset-row-container h-[63vh]">
            <table className="w-full">
              <tbody className="w-full">
                {mfarray.map((fund, index) => (
                  <MFrow
                    key={fund.id}
                    fund={fund}
                    id={index}
                    removeMFarray={removeMFarray}
                    mfarray={mfarray}
                    setMfarray={setMfarray}
                    patchMfArray={patchMfArray}
                    setPatchMfArray={setPatchMfArray}
                    deleteArray={deleteArray}
                    setDeleteArray={setDeleteArray}
                    data={data}
                    postMfArray={postMfArray}
                    setPostMfArray={setPostMfArray}
                    addedProduct={addedProduct}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="flex flex-col justify-center items-center w-[96%] h-[62vh]">
          <Bars color="#1982F8" />
          <p className=" font-inter text-[14px] font-medium text-neutralBlack mb-[1rem] mt-[1rem]">
            Updating Portfolio ...
          </p>
        </div>
      )} */}
    </div>
  );
}
