import React from "react";
import Dashboard from "../assets/img/dashboard_icon.svg";
import MarketUpdates from "../assets/img/marketuploads.svg";
import ProductInsights from "../assets/img/productinsights.svg";
import PortfolioProposal from "../assets/img/portfolio_proposal.svg";
import ClientInput from "../assets/img/client_input.svg";
// import * as MdIcons from "react-icons/md";

const DashboardIcon = () => {
  const styles = {
    img: {
      width: "15px",
      height: "15px",
    },
  };
  return <img style={styles.img} src={Dashboard} alt="err" />;
};

const MarketUpdatesIcon = () => {
  const styles = {
    img: {
      width: "15px",
      height: "15px",
      display: "block",
    },
  };
  return <img style={styles.img} src={MarketUpdates} alt="err" />;
};

const ProductInsightsIcon = () => {
  const styles = {
    img: {
      width: "15px",
      height: "15px",
      display: "block",
    },
  };
  return <img style={styles.img} src={ProductInsights} alt="err" />;
};

const PortfolioProposalIcon = () => {
  const styles = {
    img: {
      width: "15px",
      height: "15px",
      display: "block",
    },
  };
  return <img style={styles.img} src={PortfolioProposal} alt="err" />;
};

// const ProductInputIcon = () => {
//   const styles = {
//     img: {
//       width: "15px",
//       height: "15px",
//       display: "block",
//     },
//   };
//   return <img style={styles.img} src={ProductInput} alt="err" />;
// };

// const ResearchInputIcon = () => {
//   const styles = {
//     img: {
//       width: "15px",
//       height: "15px",
//       display: "block",
//     },
//   };
//   return <img style={styles.img} src={ResearchInput} alt="err" />;
// };

const ClientInputIcon = () => {
  const styles = {
    img: {
      width: "15px",
      height: "15px",
      display: "block",
    },
  };
  return <img style={styles.img} src={ClientInput} alt="err" />;
};
export const SidebarData = [
  {
    title: "InvestGPT",
    path: "/investgpt/markets",
    icon: <DashboardIcon />,
    module: "common",
  },
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <DashboardIcon />,
    module: "crm",
  },
  {
    title: "Market Insights",
    path: "/marketdashboard",
    icon: <MarketUpdatesIcon />,
    module: "research",
  },
  {
    title: "Product Insights",
    path: "/productinsights",
    icon: <ProductInsightsIcon />,
    module: "product",
  },
  {
    title: "Portfolio Insights",
    path: "/portfolioproposal",
    icon: <PortfolioProposalIcon />,
    module: "port_analytics",
  },
  {
    title: "Instant Review",
    path: "/instant-review/dashboard",
    icon: <PortfolioProposalIcon />,
    module: "common",
  },
  {
    title: "Masters",
    icon: <ClientInputIcon />,
    subNav: [
      {
        title: "Product Views",
        path: "/productinput",
        module: "product_input",
      },
      { title: "Research Views", path: "/research", module: "research_input" },
      { title: "Client Details", path: "/clientinput", module: "client_input" },
      {
        title: "Financial Tools",
        path: "/calculators",
        module: "calculators",
      },
      {
        title: "Model Portfolio",
        path: "/modelportfolio",
        module: "port_analytics",
      },
    ],
  },
];
