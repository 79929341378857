import InvestedgeModal from "../../../components/modal/InvestedgeModal";
import parse from "html-react-parser";
import { IoIosClose } from "react-icons/io";
export default function SectorModal({ sector, open, onClose, handleFeedback }) {
  return (
    <InvestedgeModal
      aria-labelledby={`Sector ${sector.sector_id}`}
      aria-describedby={`Sector ${sector.sector_id}`}
      open={open}
      handleclose={onClose}
    >
      <div className="flex justify-between border-0 pt-0">
        <h5 className="font-medium text-xl align-left p-0 font-inter text-neutralBlack">
          {sector.sector_name}
        </h5>
        <IoIosClose size={30} onClick={onClose} />
      </div>
      <h2 className="text-sm font-inter leading-5 my-4">
        {parse(sector.detailed_text)}
      </h2>
      {handleFeedback(sector.feedback)}
    </InvestedgeModal>
  );
}
