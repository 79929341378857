import React, { useEffect, useState } from "react";
import AfterloginApp from "../AfterloginApp";
import MarriageExpensePlanner from "../../assets/img/marriage_expense_planner.svg";
import ChildEducationPlanner from "../../assets/img/child_education_planner.svg";
import RetiremennPlanner from "../../assets/img/retirement_planner.svg";
import WealthCreation from "../../assets/img/wealth_creation.svg";
import BuildYourOwnGoal from "../../assets/img/build_your_own_goal.svg";
// import AutomatedInvestmentPlanner from "../../assets/img/automated_investment_planner.svg";
// import AutomatedSwitchPlanner from "../../assets/img/automated_switch_planner.svg";
import AutomatedCashflowPlanner from "../../assets/img/automated_cashflow_planner.svg";
import Lock from "../../assets/img/lock_locked_blue.svg";
import { Link } from "react-router-dom";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import InvestedgePagination from "../../components/pagination/InvestedgePagination";
import InvestedgeSearchField from "../../components/searchfield/InvestedgeSearchField";
import { OpenSnackbar } from "../../actions/actions";
import { useDispatch } from "react-redux";
export default function Calculators() {
  const dispatch = useDispatch();
  const planningToolsData = [
    {
      img: MarriageExpensePlanner,
      title: "Marriage Expense Planner",
      description:
        "Use this planner to estimate how much your client would need & should invest for marriage expense in future.",
      link: "/calculator/marriage_expense_planner",
      locked: false,
    },
    {
      img: ChildEducationPlanner,
      title: "Child Education Planner",
      description:
        "Create Child Education Planner for clients who are wanting to plan well in advance for higher education of their children.",
      link: "/calculator/child_education_planner",
      locked: false,
    },
    {
      img: RetiremennPlanner,
      title: "Retirement Planner",
      description:
        "Use this tool to do Retirement planning as your client would need money to manage their lifestyle post-retirement. ",
      link: "/calculator/retirement_planner",
      locked: false,
    },
    {
      img: WealthCreation,
      title: "Wealth Creation",
      description:
        "Help your client in identifying the investment requirements for creating wealth using this calculator.",
      link: "/calculator/wealth_creation",
      locked: false,
    },
    {
      img: BuildYourOwnGoal,
      title: "Build Your Own Goal",
      description:
        "Help your client in identifying the investment requirements for achieving goals using this calculator.",
      link: "/calculator/build_your_own_goal",
      locked: false,
    },
    {
      img: AutomatedCashflowPlanner,
      title: "Automated Cashflow Planner (SWP)",
      description:
        "Use SWP Calculator to check if the current investments are sufficient to meet clients cashflow requirements.",
      link: "/calculator/automated_cashflow_planner",
      locked: false,
    },
    // {
    //   img: AutomatedInvestmentPlanner,
    //   title: "Automated Investment Planner (SIP)",
    //   description:
    //     "Use SIP Calculator to plan the client’s investment in a systematic way with no need of timing the market.",
    //   link: "/calculator/automated_investment_planner",
    //   locked: true,
    // },
    // {
    //   img: AutomatedSwitchPlanner,
    //   title: "Automated Switch Planner (STP)",
    //   description:
    //     "Use this calculator to plan how much one should transfer from one scheme to another to achieve return objectives.",
    //   link: "/calculator/automated_switch_planner",
    //   locked: true,
    // },
  ];
  const [paginatedData, setPaginatedData] = useState(planningToolsData);
  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const tilesPerPage = 6;
  const pagesVisited = pageNumber * tilesPerPage;
  const pageCount = Math.ceil(paginatedData.length / tilesPerPage);
  const changePage = (event, value) => {
    setPageNumber(value - 1);
  };
  useEffect(() => {
    setPaginatedData(
      planningToolsData.filter((tool) =>
        tool.title.toLowerCase().includes(search.toLowerCase())
      )
    );
    setPageNumber(0); // eslint-disable-next-line
  }, [search]);
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Product Input"}
        description={
          "Change the view on different products across asset class, product type, and categories"
        }
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className="flex min-h-[calc(100vh_-_116px)]">
            <div className="portfolio-proposal-body-card border-none shadow-none relative p-4 ">
              <div className="w-full flex justify-between items-center">
                <div>
                  <h3 className="headingThree mb-[6px]">
                    Financial Planning Tools
                  </h3>
                  <p className="font-inter text-sm text-neutralBlack mb-0">
                    Use our Calculators to check the amounts that your clients
                    need to save/invest for the life goals or for any other
                    specific requirements.
                  </p>
                </div>
                <InvestedgeSearchField
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div className="min-h-[532px] flex flex-col justify-between mt-4">
                <div className="w-full grid grid-cols-2 gap-10 gap-y-5 mt-[24px]">
                  {paginatedData
                    .slice(pagesVisited, pagesVisited + tilesPerPage)
                    .map((item, index) => (
                      <div
                        key={index}
                        className="p-[12px] flex justify-between rounded-lg h-[140px] relative pl-0 border border-globalBorderColor"
                        onClick={() =>
                          item.locked &&
                          dispatch(
                            OpenSnackbar({
                              severity: "info",
                              message: "Feature will be coming soon",
                            })
                          )
                        }
                      >
                        {item.locked ? (
                          <img
                            src={Lock}
                            alt="err"
                            className="absolute top-4 right-4"
                          />
                        ) : null}
                        <div className="w-[15%] pt-[16px]">
                          <img
                            src={item.img}
                            alt="err"
                            className="w-[40px] h-[40px] mx-auto"
                          />
                        </div>

                        <div className="w-[85%]">
                          <h2 className="font-inter text-lg font-medium mb-[0.3rem] text-neutralBlack">
                            {item.title}
                          </h2>
                          <p className="font-inter text-sm font-normal leading-[20px] mb-0">
                            {item.description}
                          </p>
                          {item.locked ? (
                            <InvestedgeButton
                              className="absolute bottom-[12px]"
                              disabled
                            >
                              Calculate
                            </InvestedgeButton>
                          ) : (
                            <Link
                              to={item.link}
                              className="absolute bottom-[12px]"
                            >
                              <InvestedgeButton>Calculate</InvestedgeButton>
                            </Link>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
                <div className="w-full flex justify-end bottom-4 right-4 absolute">
                  <InvestedgePagination
                    count={pageCount}
                    defaultPage={1}
                    page={pageNumber + 1}
                    onChange={changePage}
                    color="primary"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
