import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AfterloginApp from "../AfterloginApp";
import "../../static/css/existing/dashboard_styles.css";
import Add from "../../assets/img/commisionsadd.svg";
import Subtract from "../../assets/img/commisionssubtract.svg";
import Dropdown from "../../components/Dropdowns/DropdownWhiteSingle";
import { Recommendation } from "../../arrays/globalArray";
import {
  GetProductInput,
  PostProductInput,
  // OpenSnackbar,
} from "../../actions/actions";
import { Bars } from "react-loader-spinner";
import TextField from "@mui/material/TextField";
import TooltipIcon from "../../assets/img/tooltip_icon.svg";
import {
  FormControlLabel,
  Radio,
  Tooltip,
  useMediaQuery,
  Zoom,
} from "@mui/material";
// import Lock from "../../assets/img/lock_locked.svg";
import InvestedgeTextField from "../../components/textfield/InvestedgeTextField";
import InvestedgeAutocomplete from "../../components/autocomplete/InvestedgeAutocomplete";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import InvestedgePagination from "../../components/pagination/InvestedgePagination";
import InvestedgeToggleButtonGroup from "../../components/toggle/InvestedgeToggle";
import ProductInputRow from "./ProductInputRow";

export default function ProductInput() {
  const dispatch = useDispatch();
  const [productsInitialsData, setProductsInitalData] = useState({});
  const [productsChangedData, setProductsChangedData] = useState({});

  var data = useSelector((state) => state.productInputData.results);
  var dataloading = useSelector((state) => state.productInputData.loading);
  const [productsData, setProductsData] = useState(data);
  const [searchProduct, setSearchProduct] = useState("");
  const [searchBy, setSearchBy] = useState("product");
  const [asset, setAsset] = useState("All");
  const [product, setProduct] = useState("All");
  const [category, setCategory] = useState("All");
  const [recommendation, setRecommendation] = useState("All");
  // const assetSet = new Set(data.map((product) => product.asset_type));
  // const productSet = new Set(data.map((product) => product.product_type));
  // const categorySet = new Set(data.map((product) => product.category));
  const assetSet = ["Debt", "Alternate", "Equity", "Multi Asset"];
  const productSet = [
    "Mutual Fund",
    "PMS",
    "AIF",
    "Deposits",
    "Bonds or MLDS",
    "Stocks",
    "Offshore",
    "Unlisted Shares",
  ];
  const categorySet = [
    "Aggressive Hybrid Fund",
    "Alternate - ETFs - Gold",
    "Alternate - ETFs - Other",
    "Alternate - FoFs (Domestic) - Debt Oriented",
    "Alternate - FoFs (Overseas)",
    "Alternate - FoFs - Gold",
    "Alternate - Life Settlement",
    "Alternate - Long Short",
    "Alternate - Long short",
    "Alternate - Real Estate - Warehousing",
    "Alternate - Venture Capital - Early Stage Fund",
    "Alternate - Venture Debt",
    "Arbitrage Fund",
    "Balanced Advantage",
    "Balanced Hybrid Fund",
    "Bank Deposit",
    "Banking and PSU Fund",
    "Bonds",
    "Company Deposit",
    "Conservative Hybrid Fund",
    "Contra",
    "Corporate Bond",
    "Debt - Arbitrage Fund",
    "Debt - Bank Deposit",
    "Debt - Banking and PSU Fund",
    "Debt - Bonds",
    "Debt - Cash",
    "Debt - Company Deposit",
    "Debt - Corporate Bond",
    "Debt - Credit Risk Fund",
    "Debt - Debt -Interval Funds",
    "Debt - Dynamic Bond",
    "Debt - ETFs - Debt",
    "Debt - Fixed Maturity Plans",
    "Debt - Floating Rate",
    "Debt - FoFs (Domestic) - Debt Oriented",
    "Debt - Gilt - Gilt Fund with 10 year constant duration",
    "Debt - Gilt - Short & Mid Term",
    "Debt - High Yield Credit",
    "Debt - Liquid",
    "Debt - Long Duration",
    "Debt - Low Duration",
    "Debt - MLD",
    "Debt - Medium Duration",
    "Debt - Medium to Long Duration",
    "Debt - Money Market",
    "Debt - Overnight Fund",
    "Debt - Sector Funds",
    "Debt - Short Duration",
    "Debt - Solution Oriented - Retirement Fund",
    "Debt - Ultra Short Duration",
    "Dividend Yield",
    "Dynamic Asset Allocation",
    "Dynamic Bond",
    "ETFs",
    "ETFs - Other",
    "Equity - Contra",
    "Equity - Dividend Yield",
    "Equity - ETFs - Index",
    "Equity - ETFs - Other",
    "Equity - Equity Linked Savings Scheme",
    "Equity - FoFs (Domestic) - Equity Oriented",
    "Equity - FoFs (Overseas)",
    "Equity - Focused Fund",
    "Equity - Index Funds - Index - Nifty",
    "Equity - Index Funds - Index - Nifty Next 50",
    "Equity - Index Funds - Index - Sensex",
    "Equity - Index Funds - Index Funds - Other",
    "Equity - Market Cap Fund - Flexi Cap Fund",
    "Equity - Market Cap Fund - Large & Mid Cap",
    "Equity - Market Cap Fund - Large Cap Fund",
    "Equity - Market Cap Fund - Mid & Small Cap Fund",
    "Equity - Market Cap Fund - Mid Cap Fund",
    "Equity - Market Cap Fund - Multi Cap Fund",
    "Equity - Market Cap Fund - Small cap Fund",
    "Equity - Sector Funds",
    "Equity - Sector Funds - Auto",
    "Equity - Sector Funds - Banks & Financial Services",
    "Equity - Sector Funds - Consumption",
    "Equity - Sector Funds - Energy & Power",
    "Equity - Sector Funds - Infrastructure",
    "Equity - Sector Funds - Pharma & Health Care",
    "Equity - Sector Funds - Service Industry",
    "Equity - Sector Funds - Technology",
    "Equity - Solution Oriented - Children's Fund",
    "Equity - Solution Oriented - Retirement Fund",
    "Equity - Stocks - Large Cap",
    "Equity - Stocks - Mid Cap",
    "Equity - Stocks - Small Cap",
    "Equity - Thematic Fund",
    "Equity - Thematic Fund - Global",
    "Equity - Thematic Fund - MNC",
    "Equity - Unlisted Shares",
    "Equity - Value Fund",
    "Equity Linked Savings Scheme",
    "Equity Savings",
    "Fixed Maturity Plans",
    "Flexi Cap Fund",
    "Floating Rate",
    "FoFs",
    "FoFs (Domestic)",
    "FoFs (Overseas)",
    "Focused Fund",
    "Gilt",
    "High Yield Credit",
    "Index Funds",
    "Large Cap",
    "Large Cap Fund",
    "Liquid",
    "Long Duration",
    "Low Duration",
    "MLD",
    "Market Cap Fund",
    "Medium Duration",
    "Mid & Small Cap Fund",
    "Money Market",
    "Multi Asset - Aggressive Hybrid Fund",
    "Multi Asset - Arbitrage Fund",
    "Multi Asset - Balanced Advantage",
    "Multi Asset - Balanced Hybrid Fund",
    "Multi Asset - Capital Protection Funds",
    "Multi Asset - Conservative Hybrid Fund",
    "Multi Asset - Dynamic Asset Allocation",
    "Multi Asset - ETFs - Index",
    "Multi Asset - ETFs - Other",
    "Multi Asset - Equity Savings",
    "Multi Asset - FoFs (Domestic) - Debt Oriented",
    "Multi Asset - FoFs (Domestic) - Equity Oriented",
    "Multi Asset - FoFs (Overseas)",
    "Multi Asset - Index Funds - Index Funds - Other",
    "Multi Asset - Multi Asset Allocation",
    "Multi Asset - Real Estate",
    "Multi Asset - Solution Oriented - Children's Fund",
    "Multi Asset - Solution Oriented - Retirement Fund",
    "Multi Asset - Thematic Fund",
    "Multi Asset Allocation",
    "Overnight Fund",
    "Pre-IPO",
    "Real Estate",
    "Sector Funds",
    "Short Duration",
    "Solution Oriented - Children's Fund",
    "Solution Oriented - Retirement Fund",
    "Thematic Fund",
    "Ultra Short Duration",
    "Value Fund",
    "Venture Debt",
  ];
  const [pageNumber, setPageNumber] = useState(1);
  let pastSmallScreen = useMediaQuery("(min-width:768px)");
  const clientsPerPage = 5;
  // const pagesVisited = pageNumber * clientsPerPage;
  const tabs = [
    { label: "Buy", value: "Buy" },
    { label: "Hold", value: "Hold" },
    { label: "Sell", value: "Sell" },
  ];

  const handlingChangedValues = (
    id,
    recommendation,
    commission,
    popular,
    rating,
    view
  ) => {
    let temp = productsInitialsData[id];
    setProductsChangedData({
      ...productsChangedData,
      [id]: {
        ...temp,
        recommendation: recommendation,
        commissions: commission ? parseFloat(commission) : "",
        is_popular: popular,
        product_rating: rating,
        product_view: view,
      },
    });
  };

  const handleRecommendationChange = (id, recommendationStatus) => {
    let newproductsData = [...productsData];
    let index = newproductsData.findIndex((pid) => pid.product_id === id);
    newproductsData[index].recommendation = recommendationStatus;
    handlingChangedValues(
      id,
      recommendationStatus,
      newproductsData[index].commissions,
      newproductsData[index].is_popular,
      newproductsData[index].product_rating,
      newproductsData[index].product_view
    );
    setProductsData(newproductsData);
  };

  const handleCommissionChange = (id, value) => {
    value = Math.round(value * 10) / 10;
    let newproductsData = [...productsData];
    let index = newproductsData.findIndex((pid) => pid.product_id === id);
    newproductsData[index].commissions =
      parseFloat(newproductsData[index].commissions) + value;
    newproductsData[index].commissions = Math.max(
      newproductsData[index].commissions,
      0
    );
    handlingChangedValues(
      id,
      newproductsData[index].recommendation,
      Math.max(newproductsData[index].commissions, 0),
      newproductsData[index].is_popular,
      newproductsData[index].product_rating,
      newproductsData[index].product_view
    );
    setProductsData(newproductsData);
  };

  const handleCustomCommissionChange = (id, value) => {
    let newproductsData = [...productsData];
    let index = newproductsData.findIndex((pid) => pid.product_id === id);
    newproductsData[index].commissions = value;
    newproductsData[index].commissions = value
      ? Math.max(newproductsData[index].commissions, 0)
      : "";
    handlingChangedValues(
      id,
      newproductsData[index].recommendation,
      value ? Math.max(newproductsData[index].commissions, 0) : "",
      newproductsData[index].is_popular,
      newproductsData[index].product_rating,
      newproductsData[index].product_view
    );
    setProductsData(newproductsData);
  };

  const handleChangeRow = (id, value, key) => {
    let newproductsData = [...productsData];
    let index = newproductsData.findIndex((pid) => pid.product_id === id);
    newproductsData[index][key] = value;
    handlingChangedValues(
      id,
      newproductsData[index].recommendation,
      newproductsData[index].commissions,
      newproductsData[index].is_popular,
      newproductsData[index].product_rating,
      newproductsData[index].product_view
    );
    setProductsData(newproductsData);
  };

  const displaydata =
    Array.isArray(productsData) &&
    productsData
      // .slice(pagesVisited, pagesVisited + clientsPerPage)
      .map((pid) => {
        return (
          <ProductInputRow
            key={pid.id}
            pid={pid}
            handleCustomCommissionChange={handleCustomCommissionChange}
            handleCommissionChange={handleCommissionChange}
            tabs={tabs}
            handleRecommendationChange={handleRecommendationChange}
            handleChangeRow={handleChangeRow}
          />
        );
      });
  const displaydataSmallScreen =
    Array.isArray(productsData) &&
    productsData
      // .slice(pagesVisited, pagesVisited + clientsPerPage)
      .map((pid, index) => {
        return (
          <div
            key={index}
            className="w-full p-4 border border-[#e0dddd] rounded mb-4"
          >
            <p className="text-[#6A6A76] text-xs">Product Name</p>
            <p className="text-neutralBlack text-sm mt-1 leading-[18px]">
              {pid.name}
            </p>
            <div className="grid grid-cols-8 mt-3">
              <p className="text-[#6A6A76] text-xs col-span-2">Asset Class</p>
              <p className="text-[#6A6A76] text-xs col-span-3">Product Type</p>
              <p className="text-[#6A6A76] text-xs col-span-3">
                Product Category
              </p>
              <p className="text-neutralBlack text-sm col-span-2 mt-1 leading-[18px]">
                {pid.asset_type}
              </p>
              <p className="text-neutralBlack text-sm col-span-3 mt-1 leading-[18px]">
                {pid.product_type}
              </p>
              <p className="text-neutralBlack text-sm col-span-3 mt-1 leading-[18px]">
                {pid.category}
              </p>
            </div>
            <p className="text-[#6A6A76] text-xs mt-3">Commissions(bps)</p>
            <div className="flex mt-2">
              <img
                src={Subtract}
                alt="err"
                style={{ marginRight: "5%" }}
                onClick={() => handleCommissionChange(pid.product_id, -5.0)}
              />
              <input
                className="w-[30%] h-[30px] text-center font-inter shadow-none bg-inputFieldBg text-sm border-none text-neutralBlack"
                value={pid.commissions}
                onChange={(e) => {
                  const re = /^\d*[0-9]+\d*$/;
                  if (e.target.value === "" || re.test(e.target.value))
                    handleCustomCommissionChange(
                      pid.product_id,
                      parseInt(e.target.value)
                    );
                }}
              />
              <img
                src={Add}
                alt="err"
                className="ml-[5%]"
                onClick={() => handleCommissionChange(pid.product_id, 5.0)}
              />
            </div>
            <p className="text-[#6A6A76] text-xs mb-2 mt-3">
              Change Recommendation
            </p>
            <InvestedgeToggleButtonGroup
              tabs={tabs}
              value={pid.recommendation}
              onChange={(e, val) =>
                handleRecommendationChange(pid.product_id, val)
              }
              exclusive
            />
          </div>
        );
      });
  const pageCount = Math.ceil(
    useSelector((state) => state.productInputData.count) / 5
  );
  const changePage = (event, value) => {
    setPageNumber(value);
    dispatch(
      GetProductInput({
        params: {
          isin: searchBy === "isin" ? searchProduct : "",
          category: category === "All" ? "" : category,
          product_type: product === "All" ? "" : product,
          asset_type: asset === "All" ? "" : asset,
          search: searchBy === "product" ? searchProduct : "",
          recommendation: recommendation === "All" ? "" : recommendation,
          page: value,
          size: clientsPerPage,
        },
      })
    );
  };

  useEffect(() => {
    let temp = {};
    data.map(
      (product) =>
        (temp[product.product_id] = {
          ...product,
          commissions: (product.commissions * 100).toFixed(0),
        })
    );

    setProductsInitalData(temp);
    // setProductsChangedData({});
    setProductsData(
      data.map((product) => {
        return {
          ...product,
          commissions: (product.commissions * 100).toFixed(0),
        };
      })
    );
  }, [data]);

  const changedValues = Object.values(productsChangedData);

  const handleSave = () => {
    const postData = changedValues.map((product) => {
      return {
        id: product.id,
        product_id: product.product_id,
        recommendation: product.recommendation
          ? product.recommendation
          : "None",
        commissions: product.commissions / 100,
        is_popular: product.is_popular,
        product_rating: product.product_rating,
        product_view: product.product_view,
      };
    });
    dispatch(PostProductInput(postData));
    setRecommendation("All");
    setProduct("All");
    setCategory("All");
    setAsset("All");
    setSearchProduct("");
  };

  // useEffect(() => {
  //   dispatch(
  //     GetProductInput({
  //       params: {
  //         isin: searchBy === "isin" ? searchProduct : "",
  //         category: category === "All" ? "" : category,
  //         product_type: product === "All" ? "" : product,
  //         asset_type: asset === "All" ? "" : asset,
  //         search: searchBy === "product" ? searchProduct : "",
  //         page: 1,
  //         size: clientsPerPage,
  //       },
  //     })
  //   );
  //   // dispatch(ResetProductInput());
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    dispatch(
      GetProductInput({
        params: {
          isin: searchBy === "isin" ? searchProduct : "",
          category: category === "All" ? "" : category,
          product_type: product === "All" ? "" : product,
          asset_type: asset === "All" ? "" : asset,
          search: searchBy === "product" ? searchProduct : "",
          recommendation: recommendation === "All" ? "" : recommendation,
          page: 1,
          size: clientsPerPage,
        },
      })
    );
    setPageNumber(1);
    // eslint-disable-next-line
  }, [asset, product, category, recommendation, searchProduct, searchBy]);

  const flatProps = {
    options: ["All", ...categorySet].map((option) => option),
  };

  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className="bg-white rounded-[0.5rem] p-[1rem]">
            <h4 className="font-inter text-[16px] font-medium leading-[20px] tracking-[0em] text-left">
              Apply Filters or Search Products
            </h4>
            <div className="autocomplete-containing-div mt-[1rem] justify-between grid grid-cols-12 gap-4">
              <div className="md:col-span-2 col-span-6">
                <Dropdown
                  options={["All", ...Recommendation]}
                  selected={recommendation}
                  setSelected={setRecommendation}
                  labelName="Recommendation"
                />
              </div>
              <div className="md:col-span-2 col-span-6">
                <Dropdown
                  options={["All", ...assetSet]}
                  selected={asset}
                  setSelected={setAsset}
                  labelName="Asset Class"
                />
              </div>
              <div className="md:col-span-2 col-span-6">
                <Dropdown
                  options={["All", ...productSet]}
                  selected={product}
                  setSelected={setProduct}
                  labelName="Product Type"
                />
              </div>
              <div className="md:col-span-3 col-span-6">
                <InvestedgeAutocomplete
                  {...flatProps}
                  value={category}
                  onChange={(event, value) => {
                    setCategory(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Category"}
                      sx={{ boxShadow: "none" }}
                    />
                  )}
                />
              </div>

              <div className="md:col-span-3 col-span-12">
                <InvestedgeTextField
                  fullWidth
                  type="text"
                  label="Search Products"
                  value={searchProduct}
                  onChange={(e) => setSearchProduct(e.target.value)}
                />
              </div>
            </div>
            <div className="mt-[1rem] justify-end flex">
              <div className="w-[24%]">
                <FormControlLabel
                  control={
                    <Radio
                      size="small"
                      checked={searchBy === "product"}
                      onChange={(e) => setSearchBy("product")}
                      value="product"
                      name="searchBy"
                      inputProps={{ "aria-label": "default" }}
                    />
                  }
                  label={
                    <span className="text-[14px] font-inter">By Product</span>
                  }
                />
                <FormControlLabel
                  control={
                    <Radio
                      size="small"
                      checked={searchBy === "isin"}
                      onChange={(e) => setSearchBy("isin")}
                      value="isin"
                      name="searchBy"
                      inputProps={{ "aria-label": "default" }}
                    />
                  }
                  label={
                    <span className="text-[14px] font-inter">By ISIN</span>
                  }
                />
              </div>
            </div>
          </div>

          <div className="bg-white rounded-[0.5rem] p-[1rem] mt-[1rem]">
            <div className="flex justify-between w-full">
              <div className="flex items-center">
                <h3 className="font-inter text-[20px] not-italic font-medium leading-[30px]">
                  Product Views
                </h3>
                <Tooltip
                  TransitionComponent={Zoom}
                  placement="right"
                  title="Product Views section is designed to know your views on different products. Your buy, sell and hold recommendations for the products will be used to construct portfolio proposal &amp; review portfolio"
                  arrow
                >
                  <img
                    src={TooltipIcon}
                    alt="tooltip"
                    className="w-[16px] h-[16px] ml-2 "
                  />
                </Tooltip>
              </div>
              <div className="w-[40%] flex flex-row-reverse">
                {/* <InvestedgeButton
                  onClick={() =>
                    dispatch(
                      OpenSnackbar({
                        severity: "info",
                        message: "Feature will be coming soon",
                      })
                    )
                  }
                  style={{
                    verticalAlign: "center",
                  }}
                  className="flex items-center"
                >
                  Upload Excel
                  <img
                    className="w-[14px] ml-[0.5rem] object-contain"
                    src={Lock}
                    alt="err"
                  />
                </InvestedgeButton> */}
              </div>
            </div>
            <div className="min-h-[400px] mt-4">
              {dataloading ? (
                <div className="w-full h-[400px] flex justify-center items-center opacity-[0.9]">
                  <Bars color="#1982F8" />
                </div>
              ) : pastSmallScreen ? (
                <table className="w-full font-inter text-[15px] not-italic font-medium  tracking-[0em] text-center investedge-table hidden sm:table">
                  <colgroup>
                    <col width="25%" />
                    <col width="15%" />
                    <col width="19.5%" />
                    <col width="14.5%" />
                    <col width="20%" />
                    <col width="10%" />
                  </colgroup>
                  <tbody>
                    <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                      <th className="pl-[1rem] text-left font-medium">
                        Product Name
                      </th>
                      <th className="text-center font-medium">
                        Asset Class/Product Type
                      </th>
                      <th className="text-center font-medium">
                        Product Category
                      </th>
                      <th className="text-center font-medium">
                        Commissions(bps)
                      </th>
                      <th className="text-center font-medium">
                        Select Recommendation
                      </th>
                      <th className="text-center font-medium">Ratings</th>
                      <th className="text-center font-medium">Comments</th>
                    </tr>
                    {displaydata}
                  </tbody>
                </table>
              ) : (
                displaydataSmallScreen
              )}
            </div>
            <div className="w-full mt-4 flex justify-end">
              <InvestedgePagination
                count={pageCount}
                defaultPage={1}
                page={pageNumber}
                onChange={changePage}
                color="primary"
              />
            </div>

            <div className="flex flex-row gap-5 mt-4 w-full justify-end">
              <InvestedgeButton
                disabled={!changedValues.length}
                onClick={() => {
                  dispatch(
                    GetProductInput({
                      params: {
                        isin: searchBy === "isin" ? searchProduct : "",
                        category: category === "All" ? "" : category,
                        product_type: product === "All" ? "" : product,
                        asset_type: asset === "All" ? "" : asset,
                        search: searchBy === "product" ? searchProduct : "",
                        recommendation:
                          recommendation === "All" ? "" : recommendation,
                        page: 1,
                        size: clientsPerPage,
                      },
                    })
                  );
                  setPageNumber(1);
                  setProductsChangedData({});
                }}
              >
                Reset
              </InvestedgeButton>
              <InvestedgeButton
                disabled={!changedValues.length}
                onClick={handleSave}
              >
                Save
              </InvestedgeButton>
            </div>
          </div>

          {/* <div
            className="modal fade"
            id="productInputModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLongTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content p-[1rem]">
                <div className="modal-header border-b-0">
                  <div className="card  border-0 w-full">
                    <h1 className="font-inter text-[16px] font-medium leading-[20px] tracking-[0em] text-left text-[#000]">
                      Download template and upload recommendation
                    </h1>
                    <fieldset className="border border-[#e0dddd] w-full mt-[1rem] overflow-y-auto rounded-[10px] p-[1rem] h-[200px]">
                      <legend className="text-neutralBlack p-[5px] w-auto mb-0 font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left">
                        Download template
                      </legend>
                      <div className="mt-[1rem] flex justify-between">
                        <div className="w-[22%]">
                          <Dropdown
                            options={["All", ...Asset]}
                            selected={asset}
                            setSelected={setAsset}
                            labelName="Asset Class"
                          />
                        </div>
                        <div className="w-[22%]">
                          <Dropdown
                            options={["All", ...Product]}
                            selected={product}
                            setSelected={setProduct}
                            labelName="Product Type"
                          />
                        </div>
                        <div className="w-[22%]">
                          <Dropdown
                            options={["All", ...categorySet]}
                            selected={category}
                            setSelected={setCategory}
                            labelName="Category"
                          />
                        </div>
                        <div className="w-[22%]">
                          <Dropdown
                            options={["All", ...Recommendation]}
                            selected={recommendation}
                            setSelected={setRecommendation}
                            labelName="Recommendation"
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-row gap-5 mt-4 w-full justify-end">
                        <button className="text-white font-semibold py-2 rounded box-shadowed-button w-[150px] bg-primary-200">
                          Download
                        </button>
                      </div>
                    </fieldset>

                    <fieldset className="border border-[#e0dddd] w-full mt-[1rem] overflow-y-auto rounded-[10px] p-[1rem] h-[230px]">
                      <legend className="text-[#000] p-[5px] w-auto mb-0 font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left">
                        Upload Recommendation
                      </legend>
                      <div className="align-center">
                        <img
                          className="img-center"
                          src={Browseimage}
                          alt="err"
                        ></img>
                        <p className="drag-drop-download-text">
                          Drag and drop downloaded template
                        </p>
                        <p className="-mb-2">Or</p>
                        <button className="text-white mt-[1rem] w-[110px] bg-primary-200 font-semibold py-2 rounded box-shadowed-button">
                          Browse
                        </button>
                        <br></br>
                        <div className="d-flex flex-row gap-5 mt-2 w-full justify-end">
                          <button
                            className="text-white font-semibold py-2  rounded box-shadowed-button bg-primary-200 w-[150px]"
                            data-toggle="modal"
                            data-target="#successUploadModal"
                            data-dismiss="modal"
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="successUploadModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header border-0">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body -mt-5 flex items-center flex-col justify-center min-h-[40vh]">
                  <img className="block" src={Tick} alt="err" />
                  <h4 className="font-inter text-[16px] font-medium leading-[20px] tracking-[0em] text-center mt-3">
                    All Recommendation Uploaded Successfully
                  </h4>
                  <p className="font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center">
                    Our system will now use your recommendation to review
                    portfolios, generate proposals, and recommend products
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
