import React, { useEffect } from "react";
import Slider from "@mui/material/Slider";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles(() => ({
  input: {
    width: 56,
  },
  sliderNegative: {
    "& .MuiSlider-thumb": {
      "&+.MuiSlider-thumb": {
        display: "none",
      },
    },
    "& .MuiSlider-track": {
      color: "#F178B6",
    },
  },
  sliderPositive: {
    "& .MuiSlider-thumb": {
      display: "none",
      "&+.MuiSlider-thumb": {
        display: "flex",
      },
    },
    "& .MuiSlider-track": {
      color: "#1982F8",
    },
  },
}));
export default function ResearchSliderInput({
  name,
  label,
  markHigh = "BULLISH",
  markLow = "BEARISH",
  sliderData,
  setSliderData,
  changes,
  setChanges,
}) {
  const marks = [
    {
      value: -5,
      label: markLow,
    },
    {
      value: 5,
      label: markHigh,
    },
  ];

  const [value, setValue] = React.useState(sliderData[name] || 0);
  const baseValue = 0;
  const classes = useStyles();
  useEffect(() => {
    setValue(sliderData[name]);
    // eslint-disable-next-line
  }, [sliderData[name]]);

  return (
    <div className="flex justify-between w-full pl-[5px] mt-[1rem]">
      <div className="w-[12%] mt-[0.5rem]">
        <h5 className="font-inter text-[13px] not-italic font-normal leading-[20px] tracking-[0em] text-left text-neutralBlack">
          {label}
        </h5>
      </div>
      <Slider
        value={[
          value < baseValue ? value : baseValue,
          value > baseValue ? value : baseValue,
        ]}
        onChange={(e, val) => {
          let temp = val[1];
          if (val[0] !== baseValue && (val[1] === baseValue || val[0] < value))
            temp = val[0];

          setValue(temp);
          let newsliderData = sliderData;
          newsliderData[name] = temp;
          setSliderData(newsliderData);
        }}
        onChangeCommitted={() => setChanges(true)}
        className={
          value < baseValue ? classes.sliderNegative : classes.sliderPositive
        }
        min={-5}
        max={5}
        valueLabelDisplay="on"
        marks={marks}
        step={1}
        sx={{
          width: "85%",
          height: 6,
          color: "#7D7AF9",
          "& .MuiSlider-track": {
            border: "none",
          },
          "& .MuiSlider-rail": {
            height: 6,
            borderRadius: 10,
            backgroundColor: "#E0DDDD",
          },
          "& .MuiSlider-thumb": {
            height: 20,
            width: 20,
            backgroundColor: "#fff",
            boxShadow:
              "-2px -2px 8px rgba(0, 0, 0, 0.1), 2px 2px 6px rgba(0, 0, 0, 0.1);",
            "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
              boxShadow:
                "-2px -2px 8px rgba(0, 0, 0, 0.1), 2px 2px 6px rgba(0, 0, 0, 0.1);",
              border: "0",
            },
            "&:before": {
              display: "none",
            },
          },
          "& .MuiSlider-mark": {
            backgroundColor: "transparent",
          },
          "& .MuiSlider-markLabel": {
            transform: "translateX(0%)",
            color: "#939393",
            fontSize: 9,
            fontStyle: "Arial",
            '&[data-index="1"]': {
              transform: "translateX(-100%)",
            },
          },
          "& .MuiSlider-valueLabel": {
            lineHeight: 1.2,
            fontSize: 12,
            background: "grey",
            padding: 0,
            width: 25,
            height: 25,
            borderRadius: "4px",
            backgroundColor: "grey",
            transformOrigin: "bottom left",
            transform: "translate(50%, -100%) rotate(0deg) scale(0)",
            "&:before": { display: "none" },
            "&.MuiSlider-valueLabelOpen": {
              transform: "translate(0%, -100%) rotate(0deg) scale(1)",
            },
            "& > *": {
              transform: "rotate(0deg)",
            },
          },
        }}
      />
    </div>
  );
}
