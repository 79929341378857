import {
  GET_DASHBOARD_ALL_CLIENT,
  GET_DASHBOARD_YOUR_CLIENT,
} from "../actions/type";
const initialState = [];
export default function GetDashboardClients(state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_ALL_CLIENT:
      return action.payload;
    case GET_DASHBOARD_YOUR_CLIENT:
      return action.payload;
    default:
      return state;
  }
}
