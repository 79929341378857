import React, { useEffect, useState } from "react";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import InvestedgeTextField from "../../../components/textfield/InvestedgeTextField";
import axios from "axios";
import urls from "../../../urls";
import CopyToClipboard from "react-copy-to-clipboard";
import { Bars } from "react-loader-spinner";
import parse from "html-react-parser";
import Copy from "../../../assets/img/copytoclipboard.svg";
import { products_questions } from "../data";
import { OpenSnackbar } from "../../../actions/actions";
import { useDispatch } from "react-redux";
// import Typewriter from "typewriter-effect";
// import Empty from "../../../assets/img/search_query.svg";

export default function Query({ productId, productName }) {
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();
  const [results, setResults] = useState({
    gpt_answer: "",
    gpt_links: [],
    google_results: [],
  });

  const handleSuggestion = async (e) => {
    setQuery(e.target.innerText);
    try {
      setResults((prev) => {
        return { ...prev, gpt_answer: "loading" };
      });

      const response = await fetch(urls.investedgegptProductSpecificURL, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: e.target.innerText,
          product_id: productId,
        }),
      });
      if (!response.ok || !response.body) {
        throw response.statusText;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { value, done } = await reader.read();

        if (done) {
          // setLoading(false);
          break;
        }
        const decodedChunk = decoder.decode(value, { stream: true });
        // console.log(decodedChunk);

        setResults((prevValue) => {
          return {
            gpt_answer:
              prevValue.gpt_answer === "loading"
                ? decodedChunk
                : `${prevValue.gpt_answer}${decodedChunk}`.replaceAll(
                    "\n",
                    "<br/>"
                  ),
            gpt_links: [],
            google_results: [],
          };
        });
      }
    } catch (error) {
      // console.log(error);
      let refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken && error === "Unauthorized") {
        await axios
          .post(urls.refreshTokenURL, {
            refresh: refreshToken,
          })
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem("accessToken", res.data.access);
              handleSuggestion(e);
            }
          })
          .catch((err) => {
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("accessToken");
            window.location.pathname = "/login";
          });
      }
    }
  };
  const handleSubmit = async (e) => {
    e && e?.preventDefault();

    try {
      setResults((prev) => {
        return { ...prev, gpt_answer: "loading" };
      });

      const response = await fetch(urls.investedgegptProductSpecificURL, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query: query, product_id: productId }),
      });
      if (!response.ok || !response.body) {
        throw response.statusText;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { value, done } = await reader.read();

        if (done) {
          // setLoading(false);
          break;
        }
        const decodedChunk = decoder.decode(value, { stream: true });
        // console.log(decodedChunk);

        setResults((prevValue) => {
          return {
            gpt_answer:
              prevValue.gpt_answer === "loading"
                ? decodedChunk
                : `${prevValue.gpt_answer}${decodedChunk}`.replaceAll(
                    "\n",
                    "<br/>"
                  ),
            gpt_links: [],
            google_results: [],
          };
        });
      }
    } catch (error) {
      // console.log(error);
      let refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken && error === "Unauthorized") {
        await axios
          .post(urls.refreshTokenURL, {
            refresh: refreshToken,
          })
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem("accessToken", res.data.access);
              handleSubmit();
            }
          })
          .catch((err) => {
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("accessToken");
            window.location.pathname = "/login";
          });
      }
    }
    // axios
    //   .post(urls.investedgegptMarketsURL, { query })
    //   .then((res) => setResults(res.data))
    //   .catch((err) => {}
    //   );
  };

  const renderOutput = results.gpt_answer ? (
    <div className="p-[1rem] px-0 bg-[#fff] rounded-[0.5rem] w-full ml-0 mt-4">
      {results.gpt_answer === "loading" ? (
        <div className="h-[300px] flex justify-center items-center">
          <Bars color="#1982f8" height="60" width="60" />
        </div>
      ) : (
        <>
          <div className="w-full flex justify-between">
            <p className="font-medium text-neutralBlack">InvestGPT’s Answer</p>
            <CopyToClipboard text={results.gpt_answer.replaceAll("<br/>", "")}>
              <div className="flex items-center">
                <img src={Copy} alt="err" className="mr-2" />
                <p className="text-sm text-primary-200">Copy to Clipboard</p>
              </div>
            </CopyToClipboard>
          </div>
          <p className="text-sm text-justify mt-4 leading-[20px] bg-[#FAFAFA] border border-[#e2e2e2] rounded-[4px] p-4">
            {parse(results.gpt_answer)}
          </p>
        </>
      )}
    </div>
  ) : (
    <div className="space-y-6 mt-6">
      <p className="font-medium text-neutralBlack">Suggested Queries </p>
      <div className="space-y-4 text-primary-200 text-sm font-medium">
        <div className="space-y-4 text-primary-200 text-sm font-medium ">
          {products_questions.map((item, index) => (
            <>
              <p
                className="cursor-pointer inline-block"
                key={index}
                onClick={(e) => handleSuggestion(e)}
              >
                {item}
              </p>{" "}
              <br />{" "}
            </>
          ))}
        </div>
      </div>
    </div>
  );
  useEffect(() => {
    dispatch(
      OpenSnackbar({
        severity: "info",
        message:
          "We employ our proprietary algorithms to produce textual data for all responses. No sensitive data is transmitted to the GPT models. As the answers may not consistently reflect accurate information, users are advised to verify them before sharing or for their personal use.",
        infinite: true,
      })
    ); //eslint-disable-next-line
  }, []);
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="w-full flex justify-between mt-2">
          <InvestedgeTextField
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            label={`Enter Query Here ( for ${productName})`}
            className="w-3/4"
          />
          <InvestedgeButton
            type="submit"
            className="w-[10%]"
            disabled={results.gpt_answer === "loading"}
          >
            Submit
          </InvestedgeButton>
        </div>
      </form>
      {renderOutput}
    </>
  );
}
