import React from "react";
import Ani1 from "../../../assets/img/ani1.svg";
import { taxation } from "../onePagerConsts";

export default function taxationComponent() {
  return (
    <>
      <div
        style={{
          backgroundColor: "#1EB8CD",
          color: "#fff",
          padding: "1rem",
          borderRadius: "6px",
          boxShadow:
            " 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(0, 0, 0, 0.05)",
          minheight: "274px",
        }}
      >
        <div className="card-body">
          <div className="row">
            <div className="col-2">
              <img src={Ani1} alt="err" />
            </div>
            <div className="col-10">
              <h5 className="text-md ">Equity Mutual Fund Taxation</h5>

              {/* #13 Contains taxation card text */}
              <p className="font-medium mt-1 text-sm">{taxation["text"]}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
