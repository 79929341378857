import React from "react";
import { GraphColorPalette } from "../../../../components/colors/graphColorPalette";
import PieChart from "../../../dashboard/PieChart";
import ProgressBar from "./ProgressBar";
export default function DebtSection({ analytics }) {
  const keys = [];
  const values = [];
  const colorPalette = GraphColorPalette;

  const bgGradients = [
    "linear-gradient(223.78deg, #09C3E7 0%, #7669F0 106.87%)",
    "linear-gradient(225deg, #9C2979 0%, #F15E75 100%)",
    "linear-gradient(108.81deg, #FF9F38 -27.65%, #FE5C44 125%)",
    "linear-gradient(108.81deg, #FFD166 -27.65%, #C26F0D 125%)",
  ];
  for (const property in analytics.top5_debt_instruments) {
    keys.push(property);
    values.push((analytics.top5_debt_instruments[property] * 100).toFixed(1));
  }
  const ratingKeys = [];
  const ratingValues = [];
  for (const property in analytics.rating_allocation) {
    ratingKeys.push(property);
    ratingValues.push((analytics.rating_allocation[property] * 100).toFixed(1));
  }
  // const displayingHorizontalChartLabels = (value) => {
  //   return value.length > 4 ? `${value.slice(0, 4)}..` : value.slice(0, 4);
  // };
  const assetdata = {
    labels: ratingKeys,

    datasets: [
      {
        data: ratingValues,
        backgroundColor: colorPalette,
        borderColor: colorPalette,
        borderWidth: 1,
      },
    ],
  };
  const pieoptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      labels: {
        render: "value",
        fontColor: "#fff",
      },
      datalabels: {
        display: false,
      },
    },
  };

  // const productAllocationData = {
  //   labels: [...keys, "Test"],
  //   datasets: [
  //     {
  //       label: "Top 5 securities",
  //       backgroundColor: [
  //         "#ED6D85",
  //         "#56A1E5",
  //         "#F7CE6B",
  //         "#F1A354",
  //         "#6CBDBF",
  //         "#FFA3B9",
  //       ],
  //       borderColor: [
  //         "#ED6D85",
  //         "#56A1E5",
  //         "#F7CE6B",
  //         "#F1A354",
  //         "#6CBDBF",
  //         "#FFA3B9",
  //       ],
  //       borderWidth: 1,
  //       barThickness: 20,
  //       data: [...values, 0],
  //       borderRadius: 10,
  //       minBarLength: 40,
  //     },
  //   ],
  // };
  // const horizontalBarOptions = {
  //   maintainAspectRatio: false,
  //   cornerRadius: 20,
  //   legend: {
  //     display: false,
  //   },
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           callback: (value) => {
  //             return displayingHorizontalChartLabels(value);
  //           },
  //           fontColor: "#fff",
  //           beginAtZero: false,
  //           mirror: true,
  //           padding: -8,
  //           z: 8,
  //         },
  //         offset: true,
  //         alignToPixels: true,
  //         gridLines: {
  //           display: false,
  //         },
  //       },
  //     ],
  //     xAxes: [
  //       {
  //         ticks: {
  //           beginAtZero: true,
  //           display: false,
  //         },
  //         gridLines: {
  //           display: false,
  //         },
  //       },
  //     ],
  //   },
  //   plugins: {
  //     datalabels: {
  //       display: true,
  //       color: "#323232",
  //       align: "end",
  //       anchor: "end",
  //       font: { size: "12" },
  //     },
  //   },
  // };
  const LegendItem = ({ color, text }) => {
    return (
      <div className="flex ml-[4px]">
        <div
          className="w-[7px] h-[7px] block mt-[7px]"
          style={{ backgroundColor: color }}
        />
        <p className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] ml-[0.2rem] mb-0">
          {text}
        </p>
      </div>
    );
  };
  return (
    <div className="w-full flex justify-between pt-[1rem]">
      <div className="w-[49.5%]">
        <div className="w-full bg-[#fafafa] h-[140px] p-[1rem]">
          <h4 className=" font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack">
            Debt Quants
          </h4>
          <div className="w-full py-[1rem] px-0 flex">
            <div className="w-[50%] text-center border-r border-r-dividerColor">
              <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0">
                {`${(analytics.debt_quants.gross_ytm * 100).toFixed(1)} %`}
              </h3>
              <label className="font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                Gross YTM
              </label>
            </div>
            <div className="w-[50%] text-center">
              <h3 className="text-[#1982f8] font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center mb-0">
                {`${analytics.debt_quants.duration.toFixed(1)} Yrs`}
              </h3>
              <label className="font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                Duration
              </label>
            </div>
          </div>
        </div>
        <div className="bg-[#fafafa] w-full h-[278px] mt-[0.5rem] p-[1rem]">
          <h4 className="font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-[0.5rem]">
            Credit Rating (in %)
          </h4>
          <PieChart
            data={assetdata}
            options={pieoptions}
            width={190}
            height={190}
          />
          <div className="flex justify-evenly w-full z-[100] pt-[0.4rem] flex-wrap">
            {ratingKeys.map((key, index) => (
              <LegendItem key={index} color={colorPalette[index]} text={key} />
            ))}
          </div>
        </div>
      </div>
      <div className="w-[49.5%] bg-[#fafafa] h-[426px] p-[1rem]">
        <h4 className=" font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack">
          Top 5 securities (in %)
        </h4>
        {keys.map((sector, index) => (
          <ProgressBar
            key={index}
            bg={bgGradients[index % bgGradients.length]}
            progress={values[index]}
            label={sector}
          />
        ))}
      </div>
    </div>
  );
}
