import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BsDot } from "react-icons/bs";
import Subscribe from "./SubscribeSection";
import { useParams } from "react-router";
import Header from "./Header";
import Filtered from "./Filtered";
import Modal from "./Modal";
import Footer from "./Footer";
import parse from "html-react-parser";
import urls from "../urls.js";

function Individualblog() {
  const { id } = useParams();
  const [blogs, setBlogs] = useState({
    title: "",
    content: "",
  });

  useEffect(() => {
    const getData = async () => {
      await fetch(urls.blogsURL + id + "/")
        .then((resp) => resp.json())
        .then((data) => {
          setBlogs(data);
          console.log(data);
        });
    };
    getData(); // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="container mb-0">
        <div className="w-full h-full mt-5">
          <img src={blogs.image} alt="err" />
        </div>
        <div className="mt-5">
          <Link to="/individualblog">
            <h3 className="font-semibold">{blogs.title}</h3>
          </Link>
          <h5
            style={{ display: "inline" }}
            className="text-sm font-weight-light"
          >
            {blogs.pub_date}
          </h5>{" "}
          <BsDot className="text-gray-500" style={{ display: "inline" }} />
          <h5
            style={{ display: "inline" }}
            className="text-sm font-weight-light"
          >
            {blogs.author}
          </h5>{" "}
          <BsDot className="text-gray-500" style={{ display: "inline" }} />
          <h5
            style={{ display: "inline" }}
            className="text-sm font-weight-light"
          >
            {blogs.duration} min
          </h5>
        </div>
        <div className="mt-3 text-sm font-weight-semibold">
          <p>{parse(blogs.content)}</p>
        </div>
      </div>
      <section>
        <div className="container">
          <h3 className="text-xxl text-gray-900 ">Other Blogs</h3>
          <Filtered />
        </div>
      </section>
      <Subscribe />
      <Modal />
      <Footer />
    </>
  );
}

export default Individualblog;
