import { GET_AUM_BREAKUP_PRODUCT } from "../actions/type";
const initialState = [];
export default function GetAumBreakupProduct(state = initialState, action) {
  switch (action.type) {
    case GET_AUM_BREAKUP_PRODUCT:
      return action.payload;
    default:
      return state;
  }
}
