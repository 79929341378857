import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../../../../components/Dropdowns/DropdownWhiteSingle";
import { GetProductInput, OpenSnackbar } from "../../../../actions/actions";
import { Bars } from "react-loader-spinner";
import InvestedgeAutocomplete from "../../../../components/autocomplete/InvestedgeAutocomplete";
import InvestedgeTextField from "../../../../components/textfield/InvestedgeTextField";
import InvestedgePagination from "../../../../components/pagination/InvestedgePagination";
import InvestedgeModal from "../../../../components/modal/InvestedgeModal";
import { IoIosClose } from "react-icons/io";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
import { FormControlLabel, Radio } from "@mui/material";
// import Paper from "@mui/material/Paper";
export default function AddFundsModal({
  onClose,
  afmodal,
  parsedData,
  setParsedData,
  addedFundsMap,
}) {
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(GetProductInput());
  //   // eslint-disable-next-line
  // }, []);
  var data = useSelector((state) => state.productInputData.results);
  var dataloading = useSelector((state) => state.productInputData.loading);
  const [productsData, setProductsData] = useState(data);
  const [searchProduct, setSearchProduct] = useState("");
  const [searchBy, setSearchBy] = useState("product");
  const [asset, setAsset] = useState("All");
  const [product, setProduct] = useState("All");
  const [category, setCategory] = useState("All");
  const [recommendation, setRecommendation] = useState("All");
  const assetSet = ["Debt", "Alternate", "Equity", "Multi Asset"];
  const productSet = [
    "Mutual Fund",
    "PMS",
    "AIF",
    "Deposits",
    "Bonds or MLDS",
    "Stocks",
    "Offshore",
    "Unlisted Shares",
  ];
  const categorySet = [
    "Aggressive Hybrid Fund",
    "Alternate - ETFs - Gold",
    "Alternate - ETFs - Other",
    "Alternate - FoFs (Domestic) - Debt Oriented",
    "Alternate - FoFs (Overseas)",
    "Alternate - FoFs - Gold",
    "Alternate - Life Settlement",
    "Alternate - Long Short",
    "Alternate - Long short",
    "Alternate - Real Estate - Warehousing",
    "Alternate - Venture Capital - Early Stage Fund",
    "Alternate - Venture Debt",
    "Arbitrage Fund",
    "Balanced Advantage",
    "Balanced Hybrid Fund",
    "Bank Deposit",
    "Banking and PSU Fund",
    "Bonds",
    "Company Deposit",
    "Conservative Hybrid Fund",
    "Contra",
    "Corporate Bond",
    "Debt - Arbitrage Fund",
    "Debt - Bank Deposit",
    "Debt - Banking and PSU Fund",
    "Debt - Bonds",
    "Debt - Cash",
    "Debt - Company Deposit",
    "Debt - Corporate Bond",
    "Debt - Credit Risk Fund",
    "Debt - Debt -Interval Funds",
    "Debt - Dynamic Bond",
    "Debt - ETFs - Debt",
    "Debt - Fixed Maturity Plans",
    "Debt - Floating Rate",
    "Debt - FoFs (Domestic) - Debt Oriented",
    "Debt - Gilt - Gilt Fund with 10 year constant duration",
    "Debt - Gilt - Short & Mid Term",
    "Debt - High Yield Credit",
    "Debt - Liquid",
    "Debt - Long Duration",
    "Debt - Low Duration",
    "Debt - MLD",
    "Debt - Medium Duration",
    "Debt - Medium to Long Duration",
    "Debt - Money Market",
    "Debt - Overnight Fund",
    "Debt - Sector Funds",
    "Debt - Short Duration",
    "Debt - Solution Oriented - Retirement Fund",
    "Debt - Ultra Short Duration",
    "Dividend Yield",
    "Dynamic Asset Allocation",
    "Dynamic Bond",
    "ETFs",
    "ETFs - Other",
    "Equity - Contra",
    "Equity - Dividend Yield",
    "Equity - ETFs - Index",
    "Equity - ETFs - Other",
    "Equity - Equity Linked Savings Scheme",
    "Equity - FoFs (Domestic) - Equity Oriented",
    "Equity - FoFs (Overseas)",
    "Equity - Focused Fund",
    "Equity - Index Funds - Index - Nifty",
    "Equity - Index Funds - Index - Nifty Next 50",
    "Equity - Index Funds - Index - Sensex",
    "Equity - Index Funds - Index Funds - Other",
    "Equity - Market Cap Fund - Flexi Cap Fund",
    "Equity - Market Cap Fund - Large & Mid Cap",
    "Equity - Market Cap Fund - Large Cap Fund",
    "Equity - Market Cap Fund - Mid & Small Cap Fund",
    "Equity - Market Cap Fund - Mid Cap Fund",
    "Equity - Market Cap Fund - Multi Cap Fund",
    "Equity - Market Cap Fund - Small cap Fund",
    "Equity - Sector Funds",
    "Equity - Sector Funds - Auto",
    "Equity - Sector Funds - Banks & Financial Services",
    "Equity - Sector Funds - Consumption",
    "Equity - Sector Funds - Energy & Power",
    "Equity - Sector Funds - Infrastructure",
    "Equity - Sector Funds - Pharma & Health Care",
    "Equity - Sector Funds - Service Industry",
    "Equity - Sector Funds - Technology",
    "Equity - Solution Oriented - Children's Fund",
    "Equity - Solution Oriented - Retirement Fund",
    "Equity - Stocks - Large Cap",
    "Equity - Stocks - Mid Cap",
    "Equity - Stocks - Small Cap",
    "Equity - Thematic Fund",
    "Equity - Thematic Fund - Global",
    "Equity - Thematic Fund - MNC",
    "Equity - Unlisted Shares",
    "Equity - Value Fund",
    "Equity Linked Savings Scheme",
    "Equity Savings",
    "Fixed Maturity Plans",
    "Flexi Cap Fund",
    "Floating Rate",
    "FoFs",
    "FoFs (Domestic)",
    "FoFs (Overseas)",
    "Focused Fund",
    "Gilt",
    "High Yield Credit",
    "Index Funds",
    "Large Cap",
    "Large Cap Fund",
    "Liquid",
    "Long Duration",
    "Low Duration",
    "MLD",
    "Market Cap Fund",
    "Medium Duration",
    "Mid & Small Cap Fund",
    "Money Market",
    "Multi Asset - Aggressive Hybrid Fund",
    "Multi Asset - Arbitrage Fund",
    "Multi Asset - Balanced Advantage",
    "Multi Asset - Balanced Hybrid Fund",
    "Multi Asset - Capital Protection Funds",
    "Multi Asset - Conservative Hybrid Fund",
    "Multi Asset - Dynamic Asset Allocation",
    "Multi Asset - ETFs - Index",
    "Multi Asset - ETFs - Other",
    "Multi Asset - Equity Savings",
    "Multi Asset - FoFs (Domestic) - Debt Oriented",
    "Multi Asset - FoFs (Domestic) - Equity Oriented",
    "Multi Asset - FoFs (Overseas)",
    "Multi Asset - Index Funds - Index Funds - Other",
    "Multi Asset - Multi Asset Allocation",
    "Multi Asset - Real Estate",
    "Multi Asset - Solution Oriented - Children's Fund",
    "Multi Asset - Solution Oriented - Retirement Fund",
    "Multi Asset - Thematic Fund",
    "Multi Asset Allocation",
    "Overnight Fund",
    "Pre-IPO",
    "Real Estate",
    "Sector Funds",
    "Short Duration",
    "Solution Oriented - Children's Fund",
    "Solution Oriented - Retirement Fund",
    "Thematic Fund",
    "Ultra Short Duration",
    "Value Fund",
    "Venture Debt",
  ];
  const [pageNumber, setPageNumber] = useState(0);
  const clientsPerPage = 5;
  // const pagesVisited = pageNumber * clientsPerPage;
  const displaydata =
    Array.isArray(productsData) &&
    productsData
      // .slice(pagesVisited, pagesVisited + clientsPerPage)
      .map((pid) => {
        return (
          <tr className="h-[80px]" key={pid.product_id}>
            <td
              className="dashboard-body-second-card-table-data"
              style={{
                paddingLeft: "1rem",
                textAlign: "left",
              }}
            >
              {pid.name}
            </td>
            <td className="dashboard-body-second-card-table-data">
              {pid.recommendation}
            </td>
            <td className="dashboard-body-second-card-table-data">
              {pid.asset_type}
            </td>
            <td className="dashboard-body-second-card-table-data">
              {pid.product_type}
            </td>
            <td className="dashboard-body-second-card-table-data">
              {pid.category}
            </td>

            <td className="dashboard-body-second-card-table-data">
              {addedFundsMap[pid.product_id] ? (
                <InvestedgeButton disabled>Added</InvestedgeButton>
              ) : (
                <InvestedgeButton
                  onClick={() => {
                    if (
                      pid.recommendation === "Sell" ||
                      pid.recommendation === "Hold"
                    )
                      dispatch(
                        OpenSnackbar({
                          severity: "warning",
                          message:
                            "You have chosen product(s) with sell/hold recommendation",
                        })
                      );
                    let index = parsedData.findIndex(
                      (product) => product.product_id === pid.product_id
                    );
                    if (index === -1)
                      setParsedData([
                        {
                          product_id: pid.product_id,
                          individual_asset_pct: 0.0,
                          name: pid.name,
                          asset_type: pid.asset_type,
                          product_type: pid.product_type,
                        },
                        ...parsedData,
                      ]);
                    addedFundsMap[pid.product_id] = true;
                  }}
                >
                  Add
                </InvestedgeButton>
              )}
            </td>
          </tr>
        );
      });

  const pageCount = Math.ceil(
    useSelector((state) => state.productInputData.count) / 5
  );
  const changePage = (event, value) => {
    setPageNumber(value);
    dispatch(
      GetProductInput({
        params: {
          isin: searchBy === "isin" ? searchProduct : "",
          category: category === "All" ? "" : category,
          product_type: product === "All" ? "" : product,
          asset_type: asset === "All" ? "" : asset,
          search: searchBy === "product" ? searchProduct : "",
          recommendation: recommendation === "All" ? "" : recommendation,
          page: value,
          size: clientsPerPage,
        },
      })
    );
  };

  useEffect(() => {
    setProductsData(data);
  }, [data]);

  useEffect(() => {
    dispatch(
      GetProductInput({
        params: {
          isin: searchBy === "isin" ? searchProduct : "",
          category: category === "All" ? "" : category,
          product_type: product === "All" ? "" : product,
          asset_type: asset === "All" ? "" : asset,
          search: searchBy === "product" ? searchProduct : "",
          recommendation: recommendation === "All" ? "" : recommendation,
          page: 1,
          size: clientsPerPage,
        },
      })
    );
    setPageNumber(1);
    // eslint-disable-next-line
  }, [asset, product, category, recommendation, searchProduct, searchBy]);

  const flatProps = {
    options: ["All", ...categorySet].map((option) => option),
  };
  // const CustomPaper = (props) => {
  //   return (
  //     <Paper
  //       elevation={8}
  //       style={{ display: "inline-block", width: "250px" }}
  //       {...props}
  //     />
  //   );
  // };
  return (
    <InvestedgeModal
      aria-labelledby="Add funds Modal"
      aria-describedby="Add funds Modal"
      sx={{
        width: "80vw",
        minHeight: "96vh",
        marginRight: "auto",
        marginLeft: "auto",
      }}
      open={afmodal}
      handleclose={onClose}
    >
      <div className="flex justify-between mb-[1.5%]">
        <h3 className=" font-inter text-[24px] not-italic font-medium leading-[30px]">
          Add Products
        </h3>
        <IoIosClose size={30} onClick={onClose} />
      </div>
      <div className="autocomplete-containing-div mt-[1rem] flex justify-between">
        <div className="w-[12%]">
          <Dropdown
            options={["All", "Buy", "Hold", "Sell"]}
            selected={recommendation}
            setSelected={setRecommendation}
            labelName="Recommendation"
          />
        </div>
        <div className="w-[12%]">
          <Dropdown
            options={["All", ...assetSet]}
            selected={asset}
            setSelected={setAsset}
            labelName="Asset Class"
          />
        </div>
        <div className="w-[12%]">
          <Dropdown
            options={["All", ...productSet]}
            selected={product}
            setSelected={setProduct}
            labelName="Product Type"
          />
        </div>
        <div className="w-[30%]">
          <InvestedgeAutocomplete
            {...flatProps}
            value={category}
            onChange={(event, value) => {
              setCategory(value);
            }}
            renderInput={(params) => (
              <InvestedgeTextField
                {...params}
                label={"Category"}
                sx={{ boxShadow: "none" }}
              />
            )}
          />
        </div>

        <div className="w-[30%]">
          <InvestedgeTextField
            fullWidth
            type="text"
            sx={{
              fontFamily: "Inter,sans-sarif",
              fontWeight: 400,
              boxShadow: "none",
            }}
            label="Search Products"
            value={searchProduct}
            onChange={(e) => setSearchProduct(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-[1rem] justify-end flex">
        <div className="w-[30%]">
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={searchBy === "product"}
                onChange={(e) => setSearchBy("product")}
                value="product"
                name="searchBy"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={<span className="text-[14px] font-inter">By Product</span>}
          />
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={searchBy === "isin"}
                onChange={(e) => setSearchBy("isin")}
                value="isin"
                name="searchBy"
                inputProps={{ "aria-label": "default" }}
              />
            }
            label={<span className="text-[14px] font-inter">By ISIN</span>}
          />
        </div>
      </div>
      <h5 className="mt-[2rem] font-inter text-[16px] font-medium leading-[20px] tracking-[0em] text-left text-neutralBlack mb-0 pt-[0.5rem]">
        Search Results
      </h5>
      <div className="rounded-[6px] mt-[1rem] min-h-[400px]">
        {dataloading ? (
          <div className=" w-full h-[340px] flex justify-center items-center opacity-[0.9]">
            <Bars color="#1982F8" />
          </div>
        ) : (
          <div className="min-h-[400px]">
            <table className="w-full investedge-table ">
              <colgroup>
                <col width="25%" />
                <col width="15.5%" />
                <col width="15.5%" />
                <col width="15.5%" />
                <col width="20.5%" />
                <col width="14%" />
              </colgroup>
              <tbody>
                <tr className="rounded-[10px] max-h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76] h-10">
                  <th className="font-inter text-[15px] font-medium not-italic leading-[20px] tracking-[0em] text-left pl-[1rem]">
                    Product Name
                  </th>
                  <th className=" font-inter text-[15px] font-medium not-italic leading-[20px] tracking-[0em] text-center">
                    Recommendation
                  </th>
                  <th className=" font-inter text-[15px] font-medium not-italic leading-[20px] tracking-[0em] text-center">
                    Asset Class
                  </th>
                  <th className=" font-inter text-[15px] font-medium not-italic leading-[20px] tracking-[0em] text-center">
                    Product Type
                  </th>
                  <th className=" font-inter text-[15px] font-medium not-italic leading-[20px] tracking-[0em] text-center">
                    Product Category
                  </th>
                  <th className=" font-inter text-[15px] font-medium not-italic leading-[20px] tracking-[0em] text-center">
                    Add Products
                  </th>
                </tr>
                {displaydata}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="w-[95%] flex justify-end mt-4 absolute bottom-4">
        <InvestedgePagination
          count={pageCount}
          defaultPage={1}
          page={pageNumber}
          onChange={changePage}
          color="primary"
        />
      </div>
    </InvestedgeModal>
  );
}
