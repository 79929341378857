import React, { useState, useEffect } from "react";
import AfterloginApp from "../../AfterloginApp";
import { useDispatch, useSelector } from "react-redux";
import { MarketConversation } from "../../../actions/actions";
import CoverMarketTopic from "./CoverMarketTopic";
import "swiper/css";
import "swiper/css/navigation";
import InvestedgeTabs from "../../../components/tabs/InvestedgeTabs";
export default function Covermarketmodule() {
  const dispatch = useDispatch();
  const conversationData = useSelector((state) => state.conversationdata);

  const [latestMarketEvents, setlatestMarketEvents] =
    useState(conversationData);

  useEffect(() => {
    dispatch(MarketConversation());
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setlatestMarketEvents(conversationData);
    // eslint-disable-next-line
  }, [conversationData]);

  const topics = [...new Set(conversationData.map((topic) => topic.tag))];
  topics.unshift("All");
  const [currentTopic, setCurrentTopic] = useState(0);
  useEffect(() => {
    const temp = topics.map((topic) => {
      return { label: topic };
    });
    if (currentTopic === 0) setlatestMarketEvents(conversationData);
    else
      setlatestMarketEvents(
        conversationData.filter(
          (topic) => topic.tag === temp[currentTopic].label
        )
      ); // eslint-disable-next-line
  }, [currentTopic]);
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Conversation Topics"}
        description={
          "Browse all the topics that interests your client the most."
        }
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className="bg-[#fff] p-[1rem] rounded-[0.5rem] min-h-[calc(100vh_-_116px)] w-full">
            <div className="card-heading">Conversation Topics</div>
            <InvestedgeTabs
              tabs={topics.map((topic) => {
                return { label: topic };
              })}
              tabvalue={currentTopic}
              handletabchange={(e, value) => setCurrentTopic(value)}
              variant="scrollable"
              TabIndicatorProps={{
                children: <span className="MuiTabs-indicatorSpan" />,
              }}
              scrollButtons
              allowScrollButtonsMobile
              sx={{
                marginTop: "4px",
                "& .MuiTabs-root": {
                  minHeight: "40px",
                },
                "& .MuiTab-root": {
                  padding: "8px",
                  minHeight: "40px",
                  fontSize: 13,
                },
                "& .MuiTabs-indicator": {
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "transparent",
                  bottom: "auto",
                },
                "& .MuiTabs-indicatorSpan": {
                  maxWidth: 60,
                  width: "100%",
                  backgroundColor: "#1982F8",
                },
              }}
              aria-label="research entities tab"
            />

            {latestMarketEvents.map((topic, index) => (
              <CoverMarketTopic topic={topic} index={index} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
