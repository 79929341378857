import { GET_SIP_PREVIOUS_HISTORY } from "../actions/type";
const initialState = { loading: true, results: [] };
export default function getSIPHistory(state = initialState, action) {
  switch (action.type) {
    case GET_SIP_PREVIOUS_HISTORY:
      return action.payload;
    default:
      return state;
  }
}
