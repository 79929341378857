import React, { useEffect, useState } from "react";
// import ResearchStepperSection from "./ResearchStepper/ResearchStepperSection";
import ResearchHeroSection from "./ResearchSection/AssetAllocationSection";
import AfterloginApp from "../AfterloginApp";
import InvestedgeTabs from "../../components/tabs/InvestedgeTabs";
import SectorStylesView from "./ResearchSection/SectorStylesView";
import SimulateSection from "./ResearchSection/SimulateSection";
import AssetAllocationCard from "./ResearchCard/AssetAllocationCard";
import { useSelector, useDispatch } from "react-redux";
import { GetResearch } from "../../actions/actions";
export default function ResearchInput() {
  const data = useSelector((state) => state.myresearchData);
  if (Array.isArray(data))
    data.sort((a, b) => {
      return b.id - a.id;
    });
  const dispatch = useDispatch();
  const [changes, setChanges] = useState(false);
  const [sectorChanges, setSectorChanges] = useState(false);
  const [displayResearchData, setDisplayResearchData] = useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [sectionType, setSectionType] = useState(0);
  const [sliderData, setSliderData] = useState(data);
  const tabs = [
    { label: "Asset Allocation Views" },
    { label: "Sector & Style Views" },
    { label: "Simulate for Client Profiles" },
  ];
  const handleSection = (sectionType) => {
    switch (sectionType) {
      case 0:
        return (
          <ResearchHeroSection
            changes={changes}
            setChanges={setChanges}
            displayResearchData={displayResearchData}
            setDisplayResearchData={setDisplayResearchData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            sliderData={sliderData}
            setSliderData={setSliderData}
          />
        );
      case 1:
        return (
          <SectorStylesView
            changes={sectorChanges}
            setChanges={setSectorChanges}
            sliderData={sliderData}
            setSliderData={setSliderData}
            setDisplayResearchData={setDisplayResearchData}
          />
        );
      case 2:
        return <SimulateSection sliderData={sliderData} />;
      default:
        return null;
    }
  };
  useEffect(() => {
    setSliderData(data[0]);
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    dispatch(GetResearch());
    // dispatch(GetFundhouseResearch());
    // eslint-disable-next-line
  }, []);
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Research Input"}
        description={
          "Change and simulate the view on different asset and sub-asset classes"
        }
      />
      <div
        className="loggedinscreen-container"
        id="research-input-parent-screen"
      >
        <div className="loggedinscreen h-auto">
          <div className="bg-white px-[2rem] py-[1rem] rounded-[0.5rem]">
            <InvestedgeTabs
              tabs={tabs}
              tabvalue={sectionType}
              handletabchange={(e, value) => {
                setSectionType(value);
              }}
            />
            {handleSection(sectionType)}
          </div>
          {sectionType === 2 ? <AssetAllocationCard /> : null}
        </div>
      </div>
    </div>
  );
}
