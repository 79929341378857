import React from "react";
import AfterloginApp from "../../AfterloginApp";
import { useState } from "react";
import CalculatorBanner from "../CalculatorBanner";
import BannerImage from "../../../assets/img/automated_cashflow_planner_banner.svg";
import urls from "../../../urls";
import axios from "axios";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import { InputAdornment, OutlinedInput } from "@mui/material";
import InvestedgeTextField from "../../../components/textfield/InvestedgeTextField";
import DropdownForAttributes from "../../../components/Dropdowns/DropdownForAttributes";
export default function AutomatedCashflowPlanner() {
  const [planningData, setPlanningData] = useState({
    investmentValue: "10",
    investmentValueDenotion: "₹ L",
    withdrawalPerMonth: "5",
    withdrawalPerMonthDenotion: "₹ L",
    time: "5",
    expectedReturn: "8",
  });
  const denotionParser = (denotion, value) => {
    if (denotion[2] === "K") return parseInt(value) * 1000;
    else if (denotion[2] === "L") return parseInt(value) * 100000;
    else return value * 10000000;
  };
  const [backendData, setBackendData] = useState({
    amount_invested: 0,
    total_withdrawal: 0,
    final_value: 0,
  });
  const handleCalculate = () => {
    axios
      .post(urls.automatedCashflowPlannerURL, {
        value_of_investment: denotionParser(
          planningData.investmentValueDenotion,
          parseInt(planningData.investmentValue)
        ),
        withdrawal_per_month: denotionParser(
          planningData.withdrawalPerMonthDenotion,
          parseInt(planningData.withdrawalPerMonth)
        ),
        time_period: parseInt(planningData.time),
        expected_return: parseInt(planningData.expectedReturn) / 100,
      })
      .then((response) => setBackendData(response.data))
      .catch(() =>
        setBackendData({
          amount_invested: 100000,
          total_withdrawal: 5000,
          final_value: 45141,
        })
      );
  };
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Product Input"}
        description={
          "Change the view on different products across asset class, product type, and categories"
        }
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className="main-panel h-full">
            <div className="h-[100vh_-_116px]">
              <div className="h-[160px]">
                <CalculatorBanner
                  heading={"Automated Cashflow Planner (SWP)"}
                  description={
                    "Use SWP Calculator to check if the current investments are sufficient to meet clients cashflow requirements."
                  }
                  image={BannerImage}
                />
              </div>
              <div className="portfolio-proposal-body-card min-h-[520px] flex justify-between mt-[1rem] border-none shadow-none">
                <div className="w-[49%] ">
                  <div className="w-full flex justify-between items-center mt-[0.5rem]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">
                        Value of Investment (in ₹)
                      </p>
                    </div>
                    <div className="w-[30%] flex justify-between">
                      <div className="w-[40%]">
                        <DropdownForAttributes
                          labelName={null}
                          options={["₹ L", "₹ K", "₹ C"]}
                          selected={planningData}
                          setSelected={setPlanningData}
                          attribute="investmentValueDenotion"
                        />
                      </div>
                      <div className="w-[58%]">
                        <InvestedgeTextField
                          variant="outlined"
                          fullWidth
                          style={{
                            fontFamily: "Inter,sans-sarif",
                            fontWeight: 400,
                          }}
                          label={null}
                          value={planningData.investmentValue}
                          onChange={(e) => {
                            const re = /^\d*\.?\d*$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            )
                              setPlanningData({
                                ...planningData,
                                investmentValue: e.target.value,
                              });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex justify-between items-center mt-[1.5rem]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">
                        Withdrawal per Month (in ₹)
                      </p>
                    </div>
                    <div className="w-[30%] flex justify-between">
                      <div className="w-[40%]">
                        <DropdownForAttributes
                          labelName={null}
                          options={["₹ L", "₹ K", "₹ C"]}
                          selected={planningData}
                          setSelected={setPlanningData}
                          attribute="withdrawalPerMonthDenotion"
                        />
                      </div>
                      <div className="w-[58%]">
                        <InvestedgeTextField
                          variant="outlined"
                          fullWidth
                          style={{
                            fontFamily: "Inter,sans-sarif",
                            fontWeight: 400,
                          }}
                          label={null}
                          value={planningData.withdrawalPerMonth}
                          onChange={(e) => {
                            const re = /^\d*[0-9]+\d*$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            )
                              setPlanningData({
                                ...planningData,
                                withdrawalPerMonth: e.target.value,
                              });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex justify-between items-center mt-[1.5rem]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">Time Period</p>
                    </div>
                    <OutlinedInput
                      size="small"
                      className="text-sm"
                      required
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "17.4%",
                      }}
                      value={planningData.time}
                      onChange={(e) => {
                        const re = /^\d*\.?\d*$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setPlanningData({
                            ...planningData,
                            time: e.target.value,
                          });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <span className="text-sm font-inter">yrs</span>
                        </InputAdornment>
                      }
                    />
                  </div>

                  <div className="w-full flex justify-between items-center mt-[1.5rem]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">Expected Return</p>
                    </div>
                    <OutlinedInput
                      size="small"
                      className="text-sm"
                      required
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "17.4%",
                      }}
                      value={planningData.expectedReturn}
                      onChange={(e) => {
                        const re = /^\d*\.?\d*$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setPlanningData({
                            ...planningData,
                            expectedReturn: e.target.value,
                          });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <span className="text-sm font-inter">%</span>
                        </InputAdornment>
                      }
                    />
                  </div>
                  <InvestedgeButton
                    onClick={handleCalculate}
                    className="mt-8"
                    disabled={
                      !planningData.investmentValue.length ||
                      !planningData.investmentValueDenotion.length ||
                      !planningData.withdrawalPerMonth.length ||
                      !planningData.withdrawalPerMonthDenotion.length ||
                      !planningData.time.length ||
                      !planningData.expectedReturn.length
                    }
                  >
                    Calculate
                  </InvestedgeButton>
                </div>
                <div className="w-[45%] pl-[1rem] flex flex-col justify-center h-[80%]">
                  <div>
                    <p className="font-inter text-[#6a6a76] mb-[4px]">
                      Total Investment
                    </p>
                    <p className="font-inter font-semibold text-[24px] text-[#3D3D3D] mb-[4px]">
                      {`₹ ${parseInt(
                        backendData.amount_invested
                      ).toLocaleString("en-IN")}`}
                    </p>
                  </div>
                  <div className="mt-[2rem]">
                    <p className="font-inter text-[#6a6a76] mb-[4px] ">
                      Total Withdrawal
                    </p>
                    <p className="font-inter font-semibold text-[24px] text-primary-200 mb-[4px]">
                      {`₹ ${parseInt(
                        backendData.total_withdrawal
                      ).toLocaleString("en-IN")}`}
                    </p>
                  </div>
                  <div className="mt-[2rem]">
                    <p className="font-inter text-[#6a6a76] mb-[4px]">
                      Final Value
                    </p>
                    <p
                      className={
                        backendData.final_value > 0
                          ? "font-inter font-semibold text-[24px] text-success-200 mb-[4px]"
                          : "font-inter font-semibold text-[24px] text-error-200 mb-[4px]"
                      }
                    >
                      {`₹ ${parseInt(backendData.final_value).toLocaleString(
                        "en-IN"
                      )}`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
