import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../static/css/existing/dashboard_styles.css";
import DownArrow from "../assets/img/downward_arrow_solid.svg";
import BlackArrow from "../assets/img/header_blue_menu_arrow_black.svg";
export default function MobileSubmenu({ item, pathnames, userdata }) {
  let tempbool = false;
  const location = useLocation();

  if (
    item.mapkey !== undefined &&
    pathnames.get(item.mapkey).hasOwnProperty(location.pathname)
  ) {
    tempbool = true;
  }

  const [subnav, setSubnav] = useState(tempbool);
  const showSubnav = () => setSubnav(!subnav);
  if (
    userdata &&
    item.path &&
    !userdata[item.module] &&
    item.module !== "common"
  )
    return null;
  if (item.subNav && userdata) {
    let flag = false;
    for (let i = 0; i < item.subNav.length; i++) {
      if (userdata[item.subNav[i].module]) flag = true;
    }

    if (!flag) return null;
  }
  return (
    <div>
      {item.path ? (
        <Link to={item.path}>
          <div
            className={
              location.pathname === item.path ||
              (pathnames.has(item.title) &&
                pathnames.get(item.title).hasOwnProperty(location.pathname))
                ? " text-primary-200 inline-block p-0 list-none  w-auto cursor-pointer decoration-none transition-all duration-300 border-b-[2px] border-b-primary-200 ease-in-out my-[12px]  leading-[20px] text-lg"
                : " text-[#3D3D3D] inline-block p-0 list-none  w-auto cursor-pointer decoration-none transition-all duration-300 ease-in-out my-[12px] text-lg"
            }
            onClick={item.subNav && showSubnav}
          >
            <div className="sidebariconlabel">
              <div className="sidebarlabel leading-[30px]">{item.title}</div>
              {item.subNav ? (
                <img
                  src={DownArrow}
                  style={{ marginLeft: "0.4rem" }}
                  alt="err"
                />
              ) : (
                ""
              )}
            </div>
            {/* <div>
              {item.subNav && subnav
                ? item.iconOpened
                : item.subNav
                ? item.iconClosed
                : null}
            </div> */}
          </div>
        </Link>
      ) : (
        <div>
          <div
            className={
              location.pathname === item.path ||
              (pathnames.has(item.title) &&
                pathnames.get(item.title).hasOwnProperty(location.pathname))
                ? "text-[#0122AF] inline-block p-0 list-none  w-full cursor-pointer decoration-none transition-all duration-300 ease-in-out my-[12px] text-lg"
                : "text-[#3D3D3D] inline-block p-0 list-none  w-full cursor-pointer decoration-none transition-all duration-300 ease-in-out my-[12px] text-lg"
            }
            onClick={item.subNav && showSubnav}
          >
            <div className="flex w-full justify-between items-center">
              <div className="sidebarlabel leading-[30px]">{item.title}</div>
              {item.subNav ? (
                <img
                  src={BlackArrow}
                  style={{ marginLeft: "0.4rem" }}
                  alt="err"
                />
              ) : (
                ""
              )}
            </div>
            <div>
              {item.subNav && subnav
                ? item.iconOpened
                : item.subNav
                ? item.iconClosed
                : null}
            </div>
          </div>
        </div>
      )}
      {item.subNav && userdata && subnav && (
        <div className="transition-all duration-300">
          {item.subNav.map((item, index) => {
            if (userdata[item.module])
              return (
                <Link to={item.path} key={index} onClick={showSubnav}>
                  <div
                    className={
                      location.pathname === item.path
                        ? " text-primary-200 list-none w-full cursor-pointer decoration-none transition-all duration-300  ease-in-out mt-2 mb-[16px] leading-[20px]"
                        : " text-[#3D3D3D] list-none w-full cursor-pointer decoration-none transition-all duration-300 ease-in-out mt-2 mb-[16px]"
                    }
                    key={index}
                  >
                    {item.title}
                  </div>
                </Link>
              );
            return null;
          })}
        </div>
      )}
    </div>
  );
}
