import React, { useEffect, useState } from "react";
import Down from "../../../assets/img/down.svg";
import Up from "../../../assets/img/up.svg";
import Const from "../../../assets/img/const.svg";
import parse from "html-react-parser";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { PostEventLoggingData } from "../../../actions/actions";
import { useDispatch } from "react-redux";
import LatestMarketEventModal from "./LatestMarketEventModal";
export default function LatestMarketEventQuestion({ topic, index }) {
  const dispatch = useDispatch();
  const control = useAnimation();
  const [ref, inView] = useInView();
  const [open, setOpen] = useState(false);
  const FaqVariant = {
    visible: { opacity: 1, scale: 1, x: 0, transition: { duration: 1 } },
    hidden: { opacity: 0, scale: 1, x: 0 },
  };
  useEffect(() => {
    if (inView) control.start("visible");
    else control.start("hidden"); // eslint-disable-next-line
  }, [control, inView]);
  return (
    <motion.div
      ref={ref}
      variants={FaqVariant}
      className="w-full"
      initial="hidden"
      animate={control}
    >
      {/* <div
        className="modal fade"
        id={`faqModal` + topic.id}
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg mt-[7%]" role="document">
          <div className="modal-content">
            <div className="p-[1rem] pb-0">
              <h5
                className="modal-title  text-2xl font-inter leading-[20px] tracking-[0em] text-[#000] mb-[1rem]"
                id="exampleModalLabel"
              >
                {topic.title}
              </h5>
              <button
                type="button"
                className="close"
                style={{ marginTop: "-6%" }}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body text-sm font-semibold">
              <h2 className="font-inter text-[14px] font-normal leading-[20px] tracking-[0em] text-left">
                {parse(topic.content)}
              </h2>
              <div className="flex items-baseline flex-row mb-0 mt-3">
                {topic.feedback_economy > 0 ? (
                  <div className=" bg-success-300 flex gap-2 flex-row items-baseline mr-3 font-inter rounded-md font-medium text-[13px] py-[4px] px-[10px]">
                    <span className="text-xs">Economy</span>
                    <span>
                      <img src={Up} alt="err" />
                    </span>
                  </div>
                ) : topic.feedback_economy === 0 ? (
                  <div className="bg-[#777] flex gap-2 flex-row items-baseline mr-3 font-inter rounded-md font-medium text-[13px] py-[4px] px-[10px]">
                    <span className="text-xs">Economy</span>
                    <span>
                      <img src={Const} alt="err" className="mb-[2px]" />
                    </span>
                  </div>
                ) : topic.feedback_economy < 0 ? (
                  <div className="bg-error-200 flex gap-2 flex-row items-baseline mr-3 font-inter rounded-md font-medium text-[13px] py-[4px] px-[10px]">
                    <span className="text-xs">Economy</span>
                    <span>
                      <img src={Down} alt="err" />
                    </span>
                  </div>
                ) : (
                  <div></div>
                )}

                {topic.feedback_equity > 0 ? (
                  <div className="bg-success-300 flex gap-2 flex-row items-baseline mr-3 font-inter rounded-md font-medium text-[13px] py-[4px] px-[10px]">
                    <span className="text-xs">Equity</span>
                    <span>
                      <img src={Up} alt="err" />
                    </span>
                  </div>
                ) : topic.feedback_equity === 0 ? (
                  <div className="bg-[#777] flex gap-2 flex-row items-baseline mr-3 font-inter rounded-md font-medium text-[13px] py-[4px] px-[10px]">
                    <span className="text-xs">Equity</span>
                    <span>
                      <img src={Const} alt="err" className="mb-[2px]" />
                    </span>
                  </div>
                ) : topic.feedback_equity < 0 ? (
                  <div className="bg-error-200 flex gap-2 flex-row items-baseline mr-3 font-inter rounded-md font-medium text-[13px] py-[4px] px-[10px]">
                    <span className="text-xs">Equity</span>
                    <span>
                      <img src={Down} alt="err" />
                    </span>
                  </div>
                ) : (
                  <div></div>
                )}

                {topic.feedback_debt > 0 ? (
                  <div className="bg-[#59ab32] flex gap-2 flex-row items-baseline mr-3 font-inter rounded-md font-medium text-[13px] py-[4px] px-[10px]">
                    <span className="text-xs">Debt</span>
                    <span>
                      <img src={Up} alt="err" />
                    </span>
                  </div>
                ) : topic.feedback_debt === 0 ? (
                  <div className="bg-[#777] flex gap-2 flex-row items-baseline mr-3 font-inter rounded-md font-medium text-[13px] py-[4px] px-[10px]">
                    <span className="text-xs">Debt</span>
                    <span>
                      <img src={Const} alt="err" className="mb-[2px]" />
                    </span>
                  </div>
                ) : topic.feedback_debt < 0 ? (
                  <div className="bg-error-200 flex gap-2 flex-row items-baseline font-inter rounded-md font-medium text-[13px] py-[4px] px-[10px]">
                    <span className="text-xs">Debt</span>
                    <span>
                      <img src={Down} alt="err" />
                    </span>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <LatestMarketEventModal
        topic={topic}
        open={open}
        onClose={() => setOpen(false)}
      />
      <div
        className="shadow-none border border-globalBorderColor rounded-lg"
        style={
          index === 0
            ? {
                width: "100%",
                padding: "1rem",
                height: "210px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }
            : {
                width: "100%",
                padding: "1rem",
                marginTop: "1.5rem",
                height: "210px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }
        }
      >
        <div>
          <h5 className="text-[16px] font-inter font-medium leading-[20px] tracking-[0em] text-neutralBlack mb-[1rem]">
            {topic.title}
          </h5>

          <h2 className="font-inter text-[14px] font-normal leading-[18px] tracking-[0em] text-left inline text-neutralBlack">
            {parse(topic.content.slice(0, 700))}
          </h2>
          <p
            className="text-[13px] text-primary-200 cursor-pointer inline-block font-inter font-normal leading-[20px] tracking-[0em] text-left"
            data-toggle="modal"
            data-target={`#faqModal` + topic.id}
            onClick={() => {
              setOpen(true);
              dispatch(
                PostEventLoggingData({
                  module_name: "Market Insights",
                  event_type: "Information",
                  url: window.location.href,
                  note: `${topic.title} viewed`,
                })
              );
            }}
          >
            ...Read More
          </p>
        </div>
        <div className="flex items-baseline flex-row mb-0">
          {topic.feedback_economy > 0 ? (
            <div className=" bg-success-300 flex gap-2 flex-row items-baseline mr-3 text-[#fff] py-[4px] px-[10px] text-[13px] font-medium font-inter rounded-md">
              <span className="text-xs">Economy</span>
              <span>
                <img src={Up} alt="err" />
              </span>
            </div>
          ) : topic.feedback_economy === 0 ? (
            <div className="bg-[#777] flex gap-2 flex-row items-baseline mr-3 text-[#fff] py-[4px] px-[10px] text-[13px] font-medium font-inter rounded-md">
              <span className="text-xs">Economy</span>
              <span>
                <img src={Const} alt="err" style={{ marginBottom: "2px" }} />
              </span>
            </div>
          ) : topic.feedback_economy < 0 ? (
            <div className=" bg-error-200 flex gap-2 flex-row items-baseline mr-3 text-[#fff] py-[4px] px-[10px] text-[13px] font-medium font-inter rounded-md">
              <span className="text-xs">Economy</span>
              <span>
                <img src={Down} alt="err" />
              </span>
            </div>
          ) : (
            <div></div>
          )}

          {topic.feedback_equity > 0 ? (
            <div className="bg-success-300 flex gap-2 flex-row items-baseline mr-3 text-[#fff] py-[4px] px-[10px] text-[13px] font-medium font-inter rounded-md">
              <span className="text-xs">Equity</span>
              <span>
                <img src={Up} alt="err" />
              </span>
            </div>
          ) : topic.feedback_equity === 0 ? (
            <div className=" bg-[#777] flex gap-2 flex-row items-baseline mr-3 text-[#fff] py-[4px] px-[10px] text-[13px] font-medium font-inter rounded-md">
              <span className="text-xs">Equity</span>
              <span>
                <img src={Const} alt="err" style={{ marginBottom: "2px" }} />
              </span>
            </div>
          ) : topic.feedback_equity < 0 ? (
            <div className=" bg-error-200 flex gap-2 flex-row items-baseline mr-3 text-[#fff] py-[4px] px-[10px] text-[13px] font-medium font-inter rounded-md">
              <span className="text-xs">Equity</span>
              <span>
                <img src={Down} alt="err" />
              </span>
            </div>
          ) : (
            <div></div>
          )}

          {topic.feedback_debt > 0 ? (
            <div className=" bg-success-300 flex gap-2 flex-row items-baseline mr-3 text-[#fff] py-[4px] px-[10px] text-[13px] font-medium font-inter rounded-md">
              <span className="text-xs">Debt</span>
              <span>
                <img src={Up} alt="err" />
              </span>
            </div>
          ) : topic.feedback_debt === 0 ? (
            <div className=" bg-[#777] flex gap-2 flex-row items-baseline mr-3 text-[#fff] py-[4px] px-[10px] text-[13px] font-medium font-inter rounded-md">
              <span className="text-xs">Debt</span>
              <span>
                <img src={Const} alt="err" style={{ marginBottom: "2px" }} />
              </span>
            </div>
          ) : topic.feedback_debt < 0 ? (
            <div className="bg-error-200 flex gap-2 flex-row items-baseline mr-3 text-[#fff] py-[4px] px-[10px] text-[13px] font-medium font-inter rounded-md">
              <span className="text-xs">Debt</span>
              <span>
                <img src={Down} alt="err" />
              </span>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </motion.div>
  );
}
