import React, { useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import Individual from "../../../../assets/img/individual_account_type.svg";
import Family from "../../../../assets/img/family_account_type.svg";
import Tick from "../../../../assets/img/circle_over_tick.svg";
import FilledTick from "../../../../assets/img/filled_circle_over_tick.svg";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
// import {
//   OpenSnackbar,
//   PostEventLoggingData,
//   UpdateClientData,
// } from "../../actions/actions";
export default function AccountTypeModal({ setRoute }) {
  const [accountType, setaccountType] = useState("");
  const handleClick = () => {
    setRoute(accountType === "Old" ? "clientPortfolioScreen" : "newPortfolio");
    // dispatch(
    //   PostEventLoggingData({
    //     module_name: "Client Details",
    //     event_type: "Next button",
    //     url: window.location.href,
    //     note: `Individual or Family selected`,
    //   })
    // );
  };
  return (
    <div className="w-full">
      <div className="flex justify-start w-full">
        <h3 className="card-heading">Add New Client</h3>
      </div>
      <div className="flex justify-between w-full mt-[2rem]">
        <div
          className={
            accountType === "Old"
              ? "bg-primary-accent w-[45%] border border-globalBorderColor p-[1rem] h-[200px]"
              : " bg-[#fff] w-[45%] border border-globalBorderColor p-[1rem] h-[200px]"
          }
          onClick={() => setaccountType("Old")}
        >
          <div className="flex justify-start items-start w-full">
            <img
              className=" opacity-[0.9]"
              src={accountType === "Old" ? FilledTick : Tick}
              alt="err"
            />
          </div>
          <div className="flex flex-col justify-center items-center w-full">
            <img
              className=" w-[50px] object-contain rounded-[100%] opacity-[0.9]"
              src={Individual}
              alt="err"
            />
            <p className="font-inter text-lg not-italic leading-[29px] tracking-[0em] text-left text-neutralBlack mb-0 mt-4">
              Portfolio of existing client
            </p>
          </div>
        </div>
        <div
          className={
            accountType === "New"
              ? "bg-primary-accent w-[45%] border border-globalBorderColor p-[1rem] h-[200px]"
              : " bg-[#fff] w-[45%] border border-globalBorderColor p-[1rem] h-[200px]"
          }
          onClick={() => setaccountType("New")}
        >
          <div className="flex justify-start items-start w-full">
            <img
              src={accountType === "New" ? FilledTick : Tick}
              alt="err"
              className="opacity-[0.9]"
            />
          </div>
          <div className="flex justify-center flex-col items-center w-full">
            <img
              className=" object-contain w-[50px] rounded-[100%] opacity-[0.9]"
              src={Family}
              alt="err"
            />
            <p className="font-inter text-lg not-italic leading-[29px] tracking-[0em] text-left text-neutralBlack mb-0 mt-4">
              Create New Portfolio
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-end w-full">
        <InvestedgeButton className="mt-4" onClick={handleClick}>
          Next <FiArrowRight color="#fff" size={18} />
        </InvestedgeButton>
      </div>
    </div>
  );
}
