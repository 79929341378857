import React, { useState, useEffect, useRef } from "react";
import Cloud from "../assets/img/greyuploadcloud.svg";
// import Dropdown from "../../../../components/Dropdowns/DropdownWhiteSingle";
import Tick from "../assets/img/module_access_type_tick.svg";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from "react-redux";
import {
  GetClientInputTemplate,
  PostClientInputUploadFile,
  PostEventLoggingData,
} from "../actions/actions";
import { Bars } from "react-loader-spinner";
import InvestedgeButton from "../components/buttons/InvestedgeButton";
export default function UploadExcelScreen() {
  const dispatch = useDispatch();
  // const [asset, setAsset] = useState("All");
  // const [product, setProduct] = useState("All");
  // const [category, setCategory] = useState("All");
  useEffect(() => {
    dispatch(GetClientInputTemplate()); // eslint-disable-next-line
  }, [dispatch]);
  const portfolioUpload = useSelector(
    (store) => store.postClientInputUploadFile
  );
  const templateLink = useSelector(
    (state) => state.getClientInputExcelTemplate
  );
  const linkRef = useRef(null);
  const excelInputRef = useRef(null);
  const downloadexcel = async () => await linkRef.current.click();
  const [file, setFile] = useState(null);
  const [fileUpload, setFileUpload] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  return (
    <div className="flex flex-col justify-center items-center h-[90%]">
      <p className="font-inter text-[18px] text-neutralBlack mb-[1rem]">
        Upload excel template
      </p>

      <p className="font-inter font-normal text-[14px] text-neutralBlack mb-[1rem]">
        You can upload the portfolio using the standard statements, manually
        edit them in each tab, or use a custom excel template
      </p>
      <div className="w-[60%] h-auto overflow-y-visible min-h-[200px] mt-[2rem] p-[1rem] flex flex-col justify-center items-center border border-globalBorderColor rounded-md">
        <p className="not-italic text-[17px] tracking-[0em] text-left text-[#000] mt-0 mb-[0.5rem]">
          Download Template
        </p>
        <a href={templateLink} className="hidden" ref={linkRef}>
          Template
        </a>
        <div className="flex w-full justify-center">
          <InvestedgeButton
            className="mt-4"
            onClick={() => {
              dispatch(
                PostEventLoggingData({
                  module_name: "Client Details",
                  event_type: "Download button",
                  url: window.location.href,
                  note: `Template downloaded for ${localStorage.getItem(
                    "clientName"
                  )}`,
                })
              );
              downloadexcel();
            }}
          >
            Download
          </InvestedgeButton>
        </div>
      </div>
      {!submitted ? (
        <div className="w-[60%] h-auto min-h-[190px] mt-[5%] flex flex-col justify-center items-center border border-globalBorderColor rounded-md">
          <p className="text-[17px] not-italic tracking-[0em] text-left text-[#000] mb-[0.5rem] mt-0">
            Upload Files
          </p>
          <img className="w-[25px] h-[25px]" src={Cloud} alt="err" />
          <FileUploader
            multiple={false}
            handleChange={(file) => {
              setFileUpload(true);
              setFile(file);
            }}
            name="file"
            types={["XLS", "XLSX"]}
          >
            <p className="font-inter  text-[14px] text-neutralBlack mt-[0.25rem] mb-[1rem]">
              Drag and Drop account statement
            </p>
          </FileUploader>
          <p className="font-inter text-[14px] text-neutralBlack  mb-0">Or</p>
          <table className="w-full">
            <tbody>
              <tr className="flex justify-between w-full">
                <td className="w-[18%]"></td>
                <td className="w-[23%] flex items-center justify-center">
                  <input
                    className="hidden"
                    ref={excelInputRef}
                    onInput={() => {
                      setFileUpload(true);
                      setFile(excelInputRef.current.files[0]);
                    }}
                    type="file"
                    accept=".xls,.xlsx"
                  />
                  {!fileUpload ? (
                    <div
                      className="bg-primary-accent font-inter text-primary-200 py-[0.3rem] px-[1rem] text-center rounded-[0.25rem] text-[14px] cursor-pointer"
                      onClick={() => {
                        dispatch(
                          PostEventLoggingData({
                            module_name: "Client Details",
                            event_type: "Browse",
                            url: window.location.href,
                            note: `${localStorage.getItem(
                              "clientName"
                            )} browsed protfolio`,
                          })
                        );
                        excelInputRef.current.click();
                      }}
                    >
                      Browse
                    </div>
                  ) : (
                    <img src={Tick} alt="err" className="mx-auto" />
                  )}
                </td>
                <td className="w-[18%]">
                  <InvestedgeButton
                    disabled={!fileUpload}
                    onClick={() => {
                      fileUpload &&
                        dispatch(
                          PostClientInputUploadFile({
                            client_id: localStorage.getItem("clientId"),
                            file: file,
                            file_type: 5,
                            password: "",
                          })
                        );
                      fileUpload &&
                        dispatch(
                          PostEventLoggingData({
                            module_name: "Client Details",
                            event_type: "Submit button",
                            url: window.location.href,
                            note: `${localStorage.getItem(
                              "clientName"
                            )} Portfolio updated`,
                          })
                        );
                      fileUpload && setSubmitted(true);
                    }}
                  >
                    Submit
                  </InvestedgeButton>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : portfolioUpload === "loading" ? (
        <div className="w-full h-[335px] flex flex-col justify-center items-center opacity-[0.9] client-dashboard-table">
          <Bars color="#1982f8" />
          <p className="font-inter text-[14px] text-neutralBlack mb-[1rem] mt-[1rem]">
            Uploading Portfolio ...
          </p>
        </div>
      ) : portfolioUpload === "failed" ? (
        <p className="font-inter text-[14px] text-neutralBlack mb-[1rem] mt-[3rem]">
          Something failed, Try again!
        </p>
      ) : portfolioUpload.message === "Portfolio Updated" ? (
        <p className="font-inter text-[14px] text-neutralBlack mb-[1rem] mt-[3rem]">
          Submitted Successfully!
        </p>
      ) : (
        <>
          <p className="font-inter text-[14px] text-neutralBlack mb-[1rem] mt-[3rem]">
            Following funds either don't exist or are poorly formatted:
            <ul className="w-full flex list-disc px-8 mt-2">
              {portfolioUpload.could_not_be_created_for.map((item, index) => (
                <li className="mt-0 ml-[2rem]" key={index}>
                  {item}
                </li>
              ))}
            </ul>
          </p>
        </>
      )}
    </div>
  );
}
