import React, { useState } from "react";
import InvestedgeTextField from "../../components/textfield/InvestedgeTextField";
import AfterloginApp from "../../rmdashboard/AfterloginApp";
import Clientsidebar from "../ClientSidebar";
import InvestedgeDatepicker from "../../components/datepicker/InvestedgeDatepicker";
import DropdownForAttributes from "../../components/Dropdowns/DropdownForAttributes";
import { InputAdornment, OutlinedInput } from "@mui/material";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import InflationModal from "./InflationModal";
import SuccessModal from "./SuccessModal";
import urls from "../../urls";
import axios from "axios";
import { OpenSnackbar } from "../../actions/actions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
export default function GoalDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const stateGoal = location.state ? location.state.goal : null;
  const [goalDetails, setGoalDetails] = useState({
    title: stateGoal ? stateGoal.goal_title : "",
    goalEnd: stateGoal ? new Date(stateGoal.end_date) : new Date(),
    moneyRequiredDenotion: stateGoal
      ? `₹ ${stateGoal.amount_required_for_goal.denomination}`
      : "₹ L",
    moneyRequired: stateGoal ? stateGoal.amount_required_for_goal.amount : "",
    return: stateGoal ? (stateGoal.expected_return * 100).toFixed(2) : "",
    sipDenotion: stateGoal ? `₹ ${stateGoal.ongoing_sip.denomination}` : "₹ L",
    sip: stateGoal ? stateGoal.ongoing_sip.amount : "",
  });
  const [inflationModal, setInflationModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [backendData, setBackendData] = useState({
    id: "",
    goal_percent_attained: "",
    created_on: "",
    goal_title: "",
    end_date: "",
    amount_required_for_goal: {
      amount: "",
      denomination: "",
    },
    expected_return: "",
    ongoing_sip: {
      amount: "",
      denomination: "",
    },
    is_active: false,
    user: "",
    client_id: "",
  });
  const handleCreate = () => {
    var dd = String(goalDetails.goalEnd.getDate()).padStart(2, "0");
    var mm = String(goalDetails.goalEnd.getMonth() + 1).padStart(2, "0");
    var yyyy = goalDetails.goalEnd.getFullYear();
    stateGoal
      ? axios
          .patch(urls.goalsListURL, {
            ...stateGoal,
            client_id: localStorage.getItem("clientId"),
            goal_title: goalDetails.title,
            end_date: yyyy + "-" + mm + "-" + dd,
            amount_required_for_goal: {
              amount: parseFloat(goalDetails.moneyRequired),
              denomination: goalDetails.moneyRequiredDenotion[2],
            },
            expected_return: parseFloat((goalDetails.return / 100).toFixed(2)),
            ongoing_sip: {
              amount: goalDetails.sip ? parseInt(goalDetails.sip) : 0,
              denomination: goalDetails.sipDenotion[2],
            },
          })
          .then((response) => {
            setBackendData(response.data);
            setSuccessModal(true);
          })
          .catch(() => {
            dispatch(
              OpenSnackbar({
                severity: "error",
                message: "Goal Creation Failed",
              })
            );
          })
      : axios
          .post(urls.goalsListURL, {
            client_id: localStorage.getItem("clientId"),
            goal_title: goalDetails.title,
            end_date: yyyy + "-" + mm + "-" + dd,
            amount_required_for_goal: {
              amount: parseFloat(goalDetails.moneyRequired),
              denomination: goalDetails.moneyRequiredDenotion[2],
            },
            expected_return: parseFloat((goalDetails.return / 100).toFixed(2)),
            ongoing_sip: {
              amount: goalDetails.sip ? parseInt(goalDetails.sip) : 0,
              denomination: goalDetails.sipDenotion[2],
            },
          })
          .then((response) => {
            setBackendData(response.data);
            setSuccessModal(true);
          })
          .catch(() => {
            dispatch(
              OpenSnackbar({
                severity: "error",
                message: "Goal Creation Failed",
              })
            );
          });
  };

  const return_date = (val) => {
    try {
      let temp = dayjs(new Date(val).toISOString());
      return temp;
    } catch (err) {
      return val;
    }
  };
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp />
      <div className="loggedinscreen-container pt-0">
        <div className="loggedinscreen flex flex-row justify-between">
          <Clientsidebar />
          <div className="p-[1rem] bg-[#fff] rounded-[0.5rem] min-h-full client-input-parent-card mt-4 w-[79%] ml-0 relative">
            <p className="card-heading">
              {stateGoal ? `Editing ${stateGoal.goal_title}` : "Create a Goal"}
            </p>
            <div className="w-3/4 mt-6">
              <div className="w-full flex justify-between">
                <p className="font-inter text-sm">What’s the Goal</p>
                <InvestedgeTextField
                  className="w-[30%]"
                  value={goalDetails.title}
                  onChange={(e) => {
                    setGoalDetails({
                      ...goalDetails,
                      title: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="w-full flex justify-between mt-4">
                <p className="font-inter text-sm">When does the goal end? </p>
                <InvestedgeDatepicker
                  format="DD/MM/YYYY"
                  className="w-[30%]"
                  label={null}
                  minDate={dayjs(new Date().toISOString())}
                  value={return_date(goalDetails.goalEnd)}
                  onChange={(date) => {
                    setGoalDetails({ ...goalDetails, goalEnd: new Date(date) });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  renderInput={(params) => <InvestedgeTextField {...params} />}
                />
              </div>
              <div className="w-full flex justify-between mt-4 items-center">
                <p className="font-inter text-sm">
                  How much amount is required to reach the goal (inflation
                  adjusted){" "}
                </p>
                <div className="w-[30%] flex justify-between">
                  <div className="w-[40%]">
                    <DropdownForAttributes
                      labelName={null}
                      options={["₹ L", "₹ K", "₹ C"]}
                      selected={goalDetails}
                      setSelected={setGoalDetails}
                      attribute="moneyRequiredDenotion"
                    />
                  </div>
                  <div className="w-[58%]">
                    <InvestedgeTextField
                      variant="outlined"
                      fullWidth
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                      }}
                      label={null}
                      value={goalDetails.moneyRequired}
                      onChange={(e) => {
                        const re = /^\d*\.?\d*$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setGoalDetails({
                            ...goalDetails,
                            moneyRequired: e.target.value,
                          });
                      }}
                    />
                  </div>
                </div>
              </div>
              <p
                className="mt-2 mb-4 text-primary-200 text-sm underline leading-6 cursor-pointer"
                onClick={() => setInflationModal(true)}
              >
                Click here to Calculate Inflation Adjusted Goal Value
              </p>
              <InflationModal
                modal={inflationModal}
                setModal={setInflationModal}
                goalDetails={goalDetails}
                setGoalDetails={setGoalDetails}
              />
              <div className="w-full flex justify-between items-center mt-4">
                <div className="flex items-center w-[70%]">
                  <p className="font-inter text-sm">Expected Return</p>
                </div>
                <OutlinedInput
                  size="small"
                  className="text-sm"
                  required
                  style={{
                    fontFamily: "Inter,sans-sarif",
                    fontWeight: 400,
                    width: "30%",
                  }}
                  value={goalDetails.return}
                  onChange={(e) => {
                    const re = /^\d*\.?\d*$/;
                    if (e.target.value === "" || re.test(e.target.value))
                      setGoalDetails({
                        ...goalDetails,
                        return: e.target.value,
                      });
                  }}
                  name="target"
                  endAdornment={
                    <InputAdornment position="end">
                      <span className="text-sm font-inter">%</span>
                    </InputAdornment>
                  }
                />
              </div>
              <div className="w-full flex justify-between mt-4">
                <p className="font-inter text-sm">
                  Ongoing SIP for the Goal (if any)
                </p>
                <div className="w-[30%] flex justify-between">
                  <div className="w-[40%]">
                    <DropdownForAttributes
                      labelName={null}
                      options={["₹ L", "₹ K", "₹ C"]}
                      selected={goalDetails}
                      setSelected={setGoalDetails}
                      attribute="sipDenotion"
                    />
                  </div>
                  <div className="w-[58%]">
                    <InvestedgeTextField
                      variant="outlined"
                      fullWidth
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                      }}
                      label={null}
                      value={goalDetails.sip}
                      onChange={(e) => {
                        const re = /^\d*[0-9]+\d*$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setGoalDetails({
                            ...goalDetails,
                            sip: e.target.value,
                          });
                      }}
                    />
                  </div>
                </div>
              </div>
              <InvestedgeButton
                className="mt-8"
                disabled={
                  !goalDetails.title ||
                  !goalDetails.moneyRequired ||
                  !goalDetails.return
                }
                onClick={() => {
                  handleCreate();
                }}
              >
                {stateGoal ? "Save Changes" : "Create Goal"}
              </InvestedgeButton>
              <SuccessModal
                stateGoal={stateGoal}
                modal={successModal}
                setModal={setSuccessModal}
                name={goalDetails.title}
                id={stateGoal ? stateGoal.id : backendData.id}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
