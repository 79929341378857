import React, { useState, useEffect } from "react";
import AddMembers from "../../../assets/img/add_members.svg";
import { Switch, FormControlLabel } from "@mui/material";
import { Link } from "react-router-dom";
import Cross from "../../../assets/img/circleovercrosslight.svg";
import Dropdown from "./Dropdown";
import Dropdown2 from "../../../components/Dropdowns/DropdownObjects";
import TooltipIcon from "../../../assets/img/tooltip_icon.svg";
import { Tooltip, Zoom } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  UpdateClientData,
  GetRms,
  PostEventLoggingData,
} from "../../../actions/actions";
import InvestedgeTextField from "../../../components/textfield/InvestedgeTextField";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import { Bars } from "react-loader-spinner";
export default function FamilyContent() {
  let today = new Date();
  let bday = new Date(2000, 11, 10);
  let aget = today.getFullYear() - bday.getFullYear();

  var m = today.getMonth() - bday.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < bday.getDate())) {
    aget--;
  }
  const dispatch = useDispatch();
  const rms = useSelector((state) => state.rms);

  useEffect(() => {
    dispatch(GetRms()); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setrmID(rms[0]);
  }, [rms]);

  /*const BirrSwitch = withStyles((theme) => ({
    root: {
      width: 70,
      height: 24,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 0,
      color: "#0000f5",
      "&$checked": {
        transform: "translateX(45px)",
        color: "#0000f5",
        "& + $track": {
          backgroundColor: "#0000f5",
          opacity: 0.3,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#0000f5",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 30 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      opacity: 0.2,
      transition: theme.transitions.create(["#000", "border"]),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });*/
  const clientData = useSelector((state) => state.clientinputdata);
  const [familyMembers, setFamilyMembers] = useState(
    useSelector((state) => state.clientinputdata.Members)
  );
  const [family, setFamily] = useState(clientData.familyName);
  const [rmID, setrmID] = useState(rms[0]);

  let handleValidation = () => {
    let temp = true;
    if (clientData.accountType === "Family" && family.length === 0)
      temp = false;
    for (let i = 0; i < familyMembers.length; i++) {
      if (
        familyMembers[i].name.length === 0 ||
        familyMembers[i].relationship.length === 0 ||
        (familyMembers[i].primary &&
          familyMembers[i].relationship !== "Self") ||
        (!familyMembers[i].primary && familyMembers[i].relationship === "Self")
      ) {
        temp = false;
      }
    }
    // familyMembers.map((element) => {
    //   if (
    //     element.name.length === 0 ||
    //     element.relationship.length === 0 ||
    //     (element.primary && element.relationship !== "Self") ||
    //     (!element.primary && element.relationship === "Self")
    //   )
    //     temp = false;
    // });
    return temp;
  };

  let addFamilyMembers = () => {
    let newfamilyMembers = [...familyMembers];
    newfamilyMembers.push({
      name: "",
      primary: false,
      relationship: "Other",
      fullName: "",
      dob: new Date(2000, 10, 10),
      gender: "Male",
      status: "Married",
      panNumber: "",
      occupation: "",
      sector: "",
      address: "",
      pincode: "",
      taxResidency: "Indian",
      residencyStatus: "Indian",
      nationality: "Indian",
      taxslab: "",
      surcharge: "0%",
      taxrate: "30%",
      currentftcl: 0,
      currentstcl: 0,
      carrystcl: 0,
      carryftcl: 0,
      currentftcldenotion: "₹ L",
      currentstcldenotion: "₹ L",
      carrystcldenotion: "₹ L",
      carryftcldenotion: "₹ L",
      investmentcorpus: 0,
      investmentcorpusdenotion: "₹ L",
      investmenthorizon: 0,
      riskscore: 50,
      age: aget,
      flexibility: "",
      raq1: 1,
      raq2: 1,
      raq3: 1,
      raq4: 1,
      raq5: 1,
      sumraq: 5,
      understanding: {
        mf: {
          checked: true,
          value: 3,
        },
        stocks: {
          checked: true,
          value: 3,
        },
        bonds: {
          checked: true,
          value: 3,
        },
        pms: {
          checked: true,
          value: 3,
        },
        aif: {
          checked: true,
          value: 3,
        },
        cash: {
          checked: true,
          value: 3,
        },
        deposit: {
          checked: true,
          value: 3,
        },
        gold: {
          checked: true,
          value: 3,
        },
        savingsschemes: {
          checked: true,
          value: 3,
        },
        realestate: {
          checked: true,
          value: 3,
        },
        epf: {
          checked: true,
          value: 3,
        },
        ppf: {
          checked: true,
          value: 3,
        },
        unlisted: {
          checked: true,
          value: 3,
        },
      },
      ips: {
        equity: {
          target: 0.4,
          flexibility: 0.1,
        },
        debt: {
          target: 0.5,
          flexibility: 0.04,
        },
        alternates: {
          target: 0.1,
          flexibility: 0.04,
        },
        large_cap: {
          target: 0.6,
          flexibility: 0.2,
        },
        mid_cap: {
          target: 0.3,
          flexibility: 0.2,
        },
        small_cap: {
          target: 0.1,
          flexibility: 0.1,
        },
        duration: {
          target: 3,
          flexibility: 1,
        },
        credit_risk: {
          target: 0.85,
          flexibility: 0.2,
        },
        alternates_low_vol: {
          target: 0.65,
          flexibility: 0.2,
        },
      },
      errors: {
        pannumber: null,
        familyname: null,
        name: null,
        relationship: null,
        corpus: null,
        horizon: null,
        flexibility: null,
      },
    });
    setFamilyMembers(newfamilyMembers);
    setValidationFlag(false);
  };

  let removeFamilyMembers = (i) => {
    let newfamilyMembers = [...familyMembers];
    newfamilyMembers.splice(i, 1);
    setFamilyMembers(newfamilyMembers);
    let temp = true;
    for (let i = 0; i < newfamilyMembers.length; i++) {
      if (
        newfamilyMembers[i].name.length === 0 ||
        newfamilyMembers[i].relationship.length === 0
      ) {
        temp = false;
      }
    }
    // newfamilyMembers.map((element) => {
    //   if (element.name.length === 0 || element.relationship.length === 0)
    //     temp = false;
    // });
    setValidationFlag(temp);
  };

  let handleChange = (i, e) => {
    let newfamilyMembers = [...familyMembers];
    newfamilyMembers[i][e.target.name] = e.target.value;
    setFamilyMembers(newfamilyMembers);
    setValidationFlag(handleValidation());
  };

  let handleChangeSwitch = (i, e) => {
    let newfamilyMembers = [...familyMembers];
    for (let i = 0; i < newfamilyMembers.length; i++) {
      if (newfamilyMembers[i].primary === true) {
        newfamilyMembers[i].relationship = "Other";
      }
      newfamilyMembers[i].primary = false;
    }
    // newfamilyMembers.map((element) => {
    //   if (element.primary === true) element.relationship = "Other";
    //   element.primary = false;
    // });
    newfamilyMembers[i][e.target.name] = !newfamilyMembers[i][e.target.name];
    newfamilyMembers[i].relationship = "Self";
    setFamilyMembers(newfamilyMembers);
  };
  let handleFamilyChange = (e) => {
    setFamily(e.target.value);
    let temp = true;
    if (e.target.value.length === 0) temp = false;
    for (let i = 0; i < familyMembers.length; i++) {
      if (
        familyMembers[i].name.length === 0 ||
        familyMembers[i].relationship.length === 0
      )
        temp = false;
    }
    // familyMembers.map((element) => {
    //   if (element.name.length === 0 || element.relationship.length === 0)
    //     temp = false;
    // });
    setValidationFlag(temp);
  };
  let handleNextClick = () => {
    dispatch(
      PostEventLoggingData({
        module_name: "Client Details",
        event_type: "Next button",
        url: window.location.href,
        note: `Account Details`,
      })
    );
    clientData.Members = familyMembers;
    clientData.familyName = family;
    clientData.rmID = rmID.id;
    dispatch(UpdateClientData(clientData));
  };
  const [validationFlag, setValidationFlag] = useState(handleValidation());
  return !Array.isArray(rms) ? (
    <div className="h-[300px] flex justify-center items-center w-full">
      <Bars color="#1982f8" height="60" width="60" />
    </div>
  ) : (
    <>
      <div className="w-full flex justify-between">
        <div className="flex items-center">
          <h1 className="card-heading">Create Account</h1>

          <Tooltip
            TransitionComponent={Zoom}
            placement="right"
            title={`A client's account will be created once you fill up all the mandatory details till "Investment Profile" section. Please fill in the "Account details" carefully as you will not be able to edit it later on.`}
            arrow
          >
            <img
              src={TooltipIcon}
              alt="tooltip"
              className="w-[16px] h-[16px] ml-2 "
            />
          </Tooltip>
        </div>

        <div className="bg-primary-accent inline-block font-medium text-primary-200 tracking-[0.3px] py-[0.3rem] px-[0.5rem] rounded-[8px] font-inter text-[14px]">
          {clientData.accountType}
        </div>
      </div>
      {clientData.accountType === "Family" && (
        <div className="flex flex-col">
          <InvestedgeTextField
            required
            sx={{
              fontFamily: "Inter,sans-sarif",
              fontWeight: 400,
              width: "300px",
              marginTop: "1rem",
            }}
            label="Family Name"
            error={familyMembers[0].errors.familyname ? true : false}
            helperText={
              familyMembers[0].errors.familyname
                ? familyMembers[0].errors.familyname
                : ""
            }
            value={family}
            onChange={(e) => {
              let reg = e.target.value.length === 0;
              if (reg) {
                let newfamilyMembers = [...familyMembers];
                newfamilyMembers[0].errors.familyname =
                  "This field is required";
                setFamilyMembers(newfamilyMembers);
              } else {
                let newfamilyMembers = [...familyMembers];
                newfamilyMembers[0].errors.familyname = null;
                setFamilyMembers(newfamilyMembers);
              }
              handleFamilyChange(e);
            }}
          />
        </div>
      )}

      <div className="w-[300px] pt-[1.5rem]">
        <Dropdown2
          options={rms}
          selected={rmID || rms[0]}
          setSelected={setrmID}
          attribute="username"
          labelName="RM Name"
        />
      </div>

      <p className="text-[17px] not-italic font-medium tracking-[0em] text-left text-[#000] mt-[1.5rem] mb-[0.5rem]">
        Basic Details
      </p>
      <div className="family-details-fieldset border border-globalBorderColor">
        {familyMembers.map((element, index) => (
          <div className="pt-[1rem] overflow-y-visible" key={index}>
            {index ? (
              <div className="flex justify-end pt-[0.5rem] w-full m-auto border-t border-[#e0dddd]">
                <img
                  src={Cross}
                  alt="err"
                  onClick={() => removeFamilyMembers(index)}
                />
              </div>
            ) : (
              ""
            )}
            <div
              className={
                index
                  ? "flex justify-between mt-[0.7rem]"
                  : "flex justify-between"
              }
            >
              <div className="w-[30%]">
                <InvestedgeTextField
                  required
                  fullWidth
                  sx={{
                    fontFamily: "Inter,sans-sarif",
                    fontWeight: 400,
                  }}
                  label="Name"
                  type="text"
                  name="name"
                  value={element.name || ""}
                  error={element.errors.name ? true : false}
                  helperText={element.errors.name ? element.errors.name : ""}
                  onChange={(e) => {
                    let reg = e.target.value.length === 0;
                    if (reg) {
                      let newfamilyMembers = [...familyMembers];
                      newfamilyMembers[index].errors.name =
                        "This field is required";
                      setFamilyMembers(newfamilyMembers);
                    } else {
                      let newfamilyMembers = [...familyMembers];
                      newfamilyMembers[index].errors.name = null;
                      setFamilyMembers(newfamilyMembers);
                    }
                    handleChange(index, e);
                  }}
                />
              </div>
              <div>
                <p className="mb-[0rem] font-inter text-sm">Primary Member?</p>
                <FormControlLabel
                  control={
                    <Switch
                      checked={element.primary}
                      name="primary"
                      onChange={(e) => handleChangeSwitch(index, e)}
                      inputProps={{ role: "switch" }}
                      color="primary"
                    />
                  }
                  label={element.primary ? "Yes" : "No"}
                  sx={{ marginBottom: "0" }}
                />
              </div>
              <div className="w-[30%]">
                <Dropdown
                  familyMembers={familyMembers}
                  setFamilyMembers={setFamilyMembers}
                  index={index}
                  handleValidation={handleValidation}
                  setValidationFlag={setValidationFlag}
                  labelName="Relationship with primary member"
                />
              </div>
            </div>
            {/*<tr className="family-details-fieldset-field">
              <td>
                Name
                <sup
                  style={{
                    fontSize: "20px",
                    verticalAlign: "bottom",
                    paddingLeft: "0.2rem",
                  }}
                >
                  *
                </sup>
              </td>
              <td>Primary Member?</td>
              <td>
                Relationship with primary member
                <sup
                  style={{
                    fontSize: "20px",
                    verticalAlign: "bottom",
                    paddingLeft: "0.2rem",
                  }}
                >
                  *
                </sup>
              </td>
                </tr>*/}
          </div>
        ))}
      </div>
      {clientData.accountType === "Family" ? (
        <div className="flex mt-[0.3rem] text-primary-200 cursor-pointer">
          <img src={AddMembers} alt="err" className="w-[21px] h-[21px]" />
          <p className="ml-[8px]" onClick={() => addFamilyMembers()}>
            Add members
          </p>
        </div>
      ) : (
        ""
      )}
      <div className="flex items-center justify-end mr-[2rem] w-full mt-[2rem]">
        <Link to="/clientinput">
          <InvestedgeButton
            className="mr-6"
            onClick={() =>
              dispatch(
                PostEventLoggingData({
                  module_name: "Client Details",
                  event_type: "Prev button",
                  url: window.location.href,
                  note: `Account Details`,
                })
              )
            }
          >
            Back
          </InvestedgeButton>
        </Link>
        {validationFlag ? (
          <Link
            to={{
              pathname: "/clientinput/individualdetails",
              state: {
                familyMembers: familyMembers,
              },
            }}
          >
            <InvestedgeButton onClick={handleNextClick}>Next</InvestedgeButton>
          </Link>
        ) : (
          <InvestedgeButton disabled>Next</InvestedgeButton>
        )}
      </div>
    </>
  );
}
