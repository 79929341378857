import React, { useRef, useState } from "react";
import Cloud from "../assets/img/greyuploadcloud.svg";
import {
  PostClientInputUploadFile,
  PostEventLoggingData,
} from "../actions/actions";
import Tick from "../assets/img/module_access_type_tick.svg";
import { useDispatch, useSelector } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { Bars } from "react-loader-spinner";
import InvestedgeTextField from "../components/textfield/InvestedgeTextField";
import InvestedgeButton from "../components/buttons/InvestedgeButton";
export default function UploadStatementScreen({ statementtype }) {
  const [file, setFile] = useState(null);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const excelInputRef = useRef(null);
  const [fileUpload, setFileUpload] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const portfolioUpload = useSelector(
    (store) => store.postClientInputUploadFile
  );
  return (
    <div className="welcome-portfolio-input-screen-container">
      <p className="font-inter text-lg text-neutralBlack mb-[1rem]">
        Upload {statementtype} Statement
      </p>
      {!submitted ? (
        <div
          style={{ width: "60%" }}
          className="family-details-fieldset portfolio-details-fieldset upload-statement-fieldset"
        >
          <img className="w-[25px] h-[25px]" src={Cloud} alt="err" />
          <FileUploader
            multiple={false}
            handleChange={(file) => {
              setFileUpload(true);
              setFile(file);
            }}
            name="file"
            types={["PDF"]}
          >
            <p className="font-inter mt-[0.5rem] mb-[1rem] text-[14px] text-neutralBlack">
              Drag and Drop account statement
            </p>
          </FileUploader>

          <p className="font-inter text-[14px] text-neutralBlack mb-[1rem]">
            Or
          </p>
          <input
            className="hidden"
            ref={excelInputRef}
            onInput={() => {
              setFileUpload(true);
              setFile(excelInputRef.current.files[0]);
            }}
            type="file"
            accept=".pdf"
          />
          {!fileUpload ? (
            <div
              className="bg-primary-accent font-inter text-primary-200 text-[14px] py-[0.4rem] px-[1.25rem] text-center rounded-[0.25rem] cursor-pointer"
              onClick={() => {
                dispatch(
                  PostEventLoggingData({
                    module_name: "Client Details",
                    event_type: "Browse",
                    url: window.location.href,
                    note: `${localStorage.getItem(
                      "clientName"
                    )} browsed protfolio`,
                  })
                );
                excelInputRef.current.click();
              }}
            >
              Browse
            </div>
          ) : (
            <img src={Tick} alt="err" className="mx-auto" />
          )}

          <InvestedgeTextField
            className="w-[40%] mt-4"
            label="Password, if any"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <div className="flex justify-end w-full">
            <InvestedgeButton
              disabled={!fileUpload}
              onClick={() => {
                fileUpload &&
                  dispatch(
                    PostClientInputUploadFile({
                      client_id: localStorage.getItem("clientId"),
                      file: file,
                      file_type: 1,
                      password: password,
                    })
                  );
                fileUpload &&
                  dispatch(
                    PostEventLoggingData({
                      module_name: "Client Details",
                      event_type: "Submit button",
                      url: window.location.href,
                      note: `${localStorage.getItem(
                        "clientName"
                      )} Portfolio updated`,
                    })
                  );
                fileUpload && setSubmitted(true);
              }}
            >
              Submit
            </InvestedgeButton>
          </div>
        </div>
      ) : portfolioUpload === "loading" ? (
        <div className="client-dashboard-table w-full min-h-[250px] flex flex-col justify-center items-center opacity-[0.9]">
          <Bars color="#1982f8" />
          <p className="font-inter text-[14px] text-neutralBlack mb-[1rem] mt-[1rem]">
            Uploading Portfolio ...
          </p>
        </div>
      ) : portfolioUpload === "failed" ? (
        <p className="font-inter text-[14px] text-neutralBlack mb-[1rem] mt-[3rem]">
          Something failed, Try again!
        </p>
      ) : (
        <p className="font-inter text-[14px] text-neutralBlack mb-[1rem] mt-[3rem]">
          Submitted Successfully!
        </p>
      )}
    </div>
  );
}
