import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import parse from "html-react-parser";
import { PostEventLoggingData } from "../../../actions/actions";
import { useDispatch } from "react-redux";
import CoverMarketTopicModal from "./CoverMarketTopicModal";
export default function CoverMarketTopic({ topic, index }) {
  const dispatch = useDispatch();
  const control = useAnimation();
  const [open, setOpen] = useState(false);
  const [ref, inView] = useInView();
  const FaqVariant = {
    visible: { opacity: 1, scale: 1, x: 0, transition: { duration: 1 } },
    hidden: { opacity: 0, scale: 1, x: 0 },
  };
  useEffect(() => {
    if (inView) control.start("visible");
    else control.start("hidden");
  }, [control, inView]);
  return (
    <motion.div
      className="w-full"
      ref={ref}
      variants={FaqVariant}
      initial="hidden"
      animate={control}
    >
      {/* <div
        className="modal fade"
        id={`faqModal` + topic.id}
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg mt-[7%]" role="document">
          <div className="modal-content">
            <div className="p-[1rem] pb-0">
              <h5
                className="modal-title  text-2xl font-inter leading-[20px] tracking-[0em] text-[#000] mb-[1rem]"
                id="exampleModalLabel"
              >
                {topic.question}
              </h5>
              <button
                type="button"
                className="close"
                style={{ marginTop: "-6%" }}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body text-sm font-semibold">
              <h2 className="font-inter text-[14px] font-normal leading-[20px] tracking-[0em] text-left">
                {parse(topic.answer)}
              </h2>
            </div>
          </div>
        </div>
      </div> */}
      <CoverMarketTopicModal
        topic={topic}
        open={open}
        onClose={() => setOpen(false)}
      />
      <div
        className="card rounded-lg border-globalBorderColor border"
        style={
          index === 0
            ? {
                width: "100%",
                padding: "1rem",
                height: "200px",
              }
            : {
                width: "100%",
                padding: "1rem",
                marginTop: "1.5rem",
                height: "200px",
              }
        }
      >
        <h5 className="text-[16px] font-inter font-medium leading-[20px] tracking-[0em] text-neutralBlack mb-[1rem] ">
          {topic.question}
        </h5>

        <h2 className="font-inter text-[14px] font-normal inline leading-[18px] tracking-[0em] text-left mb-0">
          {parse(topic.answer.slice(0, 700))}
        </h2>
        <p
          className="text-primary-200 cursor-pointer inline-block font-inter text-[13px] font-normal leading-[16px] tracking-[0em] text-left"
          onClick={() => {
            setOpen(true);
            dispatch(
              PostEventLoggingData({
                module_name: "Market Insights",
                event_type: "Information",
                url: window.location.href,
                note: `${topic.question} viewed`,
              })
            );
          }}
        >
          ...Read More
        </p>
      </div>
    </motion.div>
  );
}
