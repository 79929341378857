import Profilefund from "../../assets/img/profile_fund.svg";
import Ani1 from "../../assets/img/ani1.svg";

export const Navbar = {
  overview: "Overview",
  strategy: "Strategy",
  good: "What's good?",
  bad: "What’s bad?",
  category: "Category",
  performance: "Performance",
  portfolio: "Portfolio",
  fund: "Fund Details",
  taxation: "Taxation",
  faqs: "FAQ's",
};

export const IntroductionData = [
  {
    amount: "  ₹ 12,253 cr",
    category: "AUM",
  },
  {
    amount: "  ₹ 69.8234",
    category: "EXPENSE RATIO",
  },
  {
    amount: "  23.14%",
    category: " 3Y CAGR",
  },
  {
    amount: "  1/5",
    category: " Rating",
  },
  {
    amount: "4",
    category: "Strategy",
  },
  {
    amount: "   All",
    category: "  Category",
  },
];

export const navHighlightedBar = {
  testSuitability: "Test Suitability",
  compare: "Compare",
  collateral: "Collateral",
};

export const strategy = {
  card: {
    text: `Diversified portfolio of (~40-70) companies across large, medium
            and small market capitalization. Primarily (75-80% core ) a
            buy-and-hold strategy; with growth & quality oriented approach
            with appetite for taking risk of higher valuations.`,
  },
  fundManagement: [
    {
      img: Profilefund,
      name: "Atul Bhole",
      managingSince: "2016",
    },
    {
      img: Ani1,
      name: "Abhishek",
      managingSince: "2011",
    },
  ],
  fundHouse: {
    title: "Fund House",
    text: `DSP Mutual Fund (formerly known as DSP BlackRock Mutual
            Fund) was established in 1996 as a joint venture between the
            DSP Merrill Lynch Ltd., and the Merrill Lynch Investment
            Managers. Since its inception, the fund house has grown to
            become one of the premier AMCs in India. It offers a broad
            range of mutual fund schemes across equity, debt and hybrid
            categories along`,
  },
  managementStyle: {
    title: "Management Style",
    text: `Diversified portfolio of (~40-70) companies across large, medium
        and small market capitalization. Primarily (75-80% core ) a
        buy-and-hold strategy; with growth & quality oriented approach
        with appetite for taking risk of higher valuations.`,
  },
};

export const whatsGood = {
  data: [
    {
      title: "Quality Oriented Portfolio",
      text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960`,
      learn: "Learn more",
    },
    {
      title: "Quality Oriented Portfolio",
      text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960`,
      learn: "Learn more",
    },
    {
      title: "Full of Quality Stocks",
      text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960.`,
    },
  ],
};
export const whatsBad = {
  data: [
    {
      title: "First",
      text: `One`,
    },
    {
      title: "Second",
      text: `Two`,
    },
    {
      title: "Third",
      text: `Three`,
    },
  ],
};

export const category = {
  title: "Quality Oriented Portfolio",
  text: `Diversified portfolio of (~40-70) companies across large, medium
  and small market capitalization. Primarily (75-80% core ) a
  buy-and-hold strategy.`,
};
export const performance = {
  cardText: `Diversified portfolio of (~40-70) companies across large, medium
    and small market capitalization. Primarily (75-80% core ) a
    buy-and-hold strategy; with growth & quality oriented approach
    with appetite for taking risk of higher valuations.`,
};

export const trailingReturns = {
  fund: {
    tableHeaders: ["YTD", "1M", "3M", "6M", "1Y", "3Y", "5Y", "7Y", "10Y"],
    tableData: [
      {
        title: "DSP Flexi Cap Fund - Regular (Growth)",
        value: [
          "12.12",
          "2.32",
          "3.29",
          "12.13",
          "32.13",
          "23.31",
          "23.31",
          "19.31",
          "-",
        ],
      },
      {
        title: "bbbb",
        value: [
          "12.12",
          "2.32",
          "3.29",
          "12.13",
          "32.13",
          "23.31",
          "23.31",
          "19.11",
          "-",
        ],
      },
    ],
  },
  peers: {
    tableHeaders: ["INR", "Gold", "Crypto"],
    tableData: ["Rakesh Jhunjhunwala", "Someone else", "Someone other"],
  },
};

export const portfolio = {
  data: [
    {
      title: "Holdings",
      text: `One`,
    },
    {
      title: "Sector & market Cap",
      text: `Two`,
    },
  ],
};

export const topHoldings = {
  data: [
    {
      securityName: "Asian Paints Limited",
      sector: "Materials",
      weight: "12.35%",
    },
  ],
};

export const fundDetails = {};

export const quants = {
  data: [
    {
      ratio: "Sharpe Ratio",
      value: "0.54",
    },
    {
      ratio: "Sharpe Ratio",
      value: "0.57",
    },
    {
      ratio: "Sharpe Ratio",
      value: "0.57",
    },
    {
      ratio: "Sharpe Ratio",
      value: "0.57",
    },
  ],
};

export const taxation = {
  text: `Lorem Ipsum is simply dummy text of the printing and
    typesetting industry. Lorem Ipsum has been the industry's
    standard dummy text ever since the 1500s`,
};
export const faq = {
  data: [
    {
      question: `Which type of investors should invest in this product?`,
      answer: `Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the industry's
            standard dummy text ever since the 1500s`,
    },
    {
      question: `Question Two`,
      answer: `Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the industry's
            standard dummy text ever since the 1500s`,
    },
  ],
};
