export const productArray = {
  mutualfunds: {
    items: [
      { name: "IIF Mutual Fund", price: "55,673" },
      { name: "IIF Mutual Fund", price: "55,673" },
      { name: "IIF Mutual Fund", price: "55,673" },
      { name: "IIF Mutual Fund", price: "55,673" },
      { name: "IIF Mutual Fund", price: "55,673" },
      { name: "IIF Mutual Fund", price: "55,673" },
      { name: "IIF Mutual Fund", price: "55,673" },
      { name: "IIF Mutual Fund", price: "55,673" },
    ],
  },
  stocks: {
    items: [
      { name: "Axis Bank Ltd.", price: "55,673" },
      { name: "KBC Global", price: "55,673" },
      { name: "RTN India", price: "55,673" },
      { name: "RTN Power", price: "55,673" },
      { name: "GTL Infra Bee", price: "55,673" },
      { name: "Subex Ltd.", price: "55,673" },
      { name: "DPSC Ltd.", price: "55,673" },
      { name: "Reliance Ltd.", price: "55,673" },
    ],
  },
  bonds: {
    items: [
      { name: "IIFL Securities 92 days 5.4%", price: "58,783" },
      { name: "Religare 182 days 6.3%", price: "58,783" },
      { name: "Sundaram Transport 182 days 7.1% MLD", price: "58,783" },
    ],
  },
  pms: {
    items: [
      { name: "White Oak PMS", price: "58,783" },
      { name: "Phoenix Portfolio", price: "58,783" },
      { name: "IIFL Multicap Advantage PMS", price: "58,783" },
    ],
  },
  aif: {
    items: [
      { name: "IIFL Special Opportunities Fund", price: "58,783" },
      { name: "Avendus Venture Debt Fund", price: "58,783" },
      { name: "Sundaram Transport 182 days 7.1% MLD", price: "58,783" },
    ],
  },
  cash: {
    items: [
      { name: "HDFC Bank Savings Account", price: "58,783" },
      { name: "IDFC First Bank Savings Account", price: "58,783" },
      { name: "ICICI Bank Savings Account", price: "58,783" },
    ],
  },
  deposits: {
    items: [
      { name: "SBI Fixed Deposit", price: "58,783" },
      { name: "ICICI Bank Fixed Depsoit", price: "58,783" },
      { name: "HDFC Fixed Deposit", price: "58,783" },
    ],
  },
  gold: {
    items: [
      { name: "Gold - 40gm", price: "58,783" },
      { name: "Silver - 1 kg", price: "58,783" },
      { name: "Gold Jewelry", price: "58,783" },
    ],
  },
  epf: {
    items: [
      { name: "EPF Name", price: "58,783" },
      { name: "Religare 182 days 6.3%", price: "58,783" },
      { name: "Sundaram Transport 182 days 7.1% MLD", price: "58,783" },
    ],
  },
  ppf: {
    items: [
      { name: "PPF Name", price: "58,783" },
      { name: "Religare 182 days 6.3%", price: "58,783" },
      { name: "Sundaram Transport 182 days 7.1% MLD", price: "58,783" },
    ],
  },
  unlisted: {
    items: [
      { name: "Chennai Super Kings Private Limited", price: "58,783" },
      { name: "Religare 182 days 6.3%", price: "58,783" },
      { name: "Sundaram Transport 182 days 7.1% MLD", price: "58,783" },
    ],
  },
  others: {
    items: [
      { name: "IIFL Securities 92 days 5.4%", price: "58,783" },
      { name: "Religare 182 days 6.3%", price: "58,783" },
      { name: "Sundaram Transport 182 days 7.1% MLD", price: "58,783" },
    ],
  },
  savings: {
    items: [
      { name: "Sukanya Samriddhi ", price: "58,783" },
      { name: "Religare 182 days 6.3%", price: "58,783" },
      { name: "Sundaram Transport 182 days 7.1% MLD", price: "58,783" },
    ],
  },
  estate: {
    items: [
      { name: "House - Indira Nagar", price: "58,783" },
      { name: "Smart Spaces - BKC", price: "58,783" },
      { name: "Farm land - Bhopal", price: "58,783" },
    ],
  },
};
