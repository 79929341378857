import React, { useState } from "react";
import Dropdown from "../../../../components/Dropdowns/DropdownWhiteSingle";
import { useDispatch } from "react-redux";
import Up from "../../../../assets/img/arrow_up.svg";
import Down from "../../../../assets/img/arrow_down.svg";
import { PostEventLoggingData } from "../../../../actions/actions";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
import InvestedgeTextField from "../../../../components/textfield/InvestedgeTextField";
import Proposal from "./Proposal";
import SIP from "./SIP";
export default function PortfolioFirstStep({
  handleNext,
  formValue,
  setFormValue,
}) {
  const dispatch = useDispatch();
  const [proposalDenotion, setProposalDenotion] = useState(
    formValue.proposal_value.denomination
  );
  const [taxStatus, setTaxStatus] = useState(formValue.tax_status);
  const [taxSlab, setTaxSlab] = useState(formValue.tax_slab);
  const [name, setName] = useState(formValue.client_name);
  const [proposalValue, setProposalValue] = useState(
    formValue.proposal_value.amount
  );
  const [horizon, setHorizon] = useState(formValue.investment_horizon);
  const [errors, setErrors] = useState({
    name: null,
    proposalValue: null,
    sipProposalValue: "",
    horizon: "",
  });
  const [purposePortfolio, setPurposePortfolio] = useState(
    formValue.purposePortfolio
  );
  const [openSIP, setOpenSIP] = useState(false);
  const [openLumpsum, setOpenLumpsum] = useState(false);
  const purposePortfolioOption = [
    "Dream Car",
    "Dream Home",
    "Retirement Planning",
    "Child Education",
    "Child Marriage",
    "Miscellaneous Goal",
    "Wealth Creation",
    "Other",
  ];
  const [sipData, setSipData] = useState({
    proposalValue: formValue.sip_proposal_value.amount,
    proposalDenomination: `₹ ${formValue.sip_proposal_value.denomination}`,
    frequencySip: formValue.sip_proposal_value.frequency_of_sip,
    installments: formValue.sip_proposal_value.installments,
    topUp: formValue.sip_proposal_value.top_up.value,
    topUpType: `₹ ${formValue.sip_proposal_value.top_up.type}`,
    frequencyTopUp: formValue.sip_proposal_value.frequency_of_topup,
  });
  return (
    <>
      <div className="px-8 rounded-[8px] w-full bg-white mt-[16px] ">
        <div className="flex justify-center h-full">
          <div className="w-full flex justify-center h-full">
            <div className="w-[60%] p-4 pb-8">
              <div className="flex justify-between w-full">
                <h2 className="card-heading">Client Details</h2>
                <InvestedgeButton
                  onClick={() => {
                    if (
                      name.length === 0 ||
                      proposalValue.length === 0 ||
                      sipData.proposalValue.length === 0 ||
                      horizon.length === 0
                    ) {
                      let newerrors = { ...errors };
                      if (name.length === 0)
                        newerrors.name = "This field is required";
                      if (proposalValue.length === 0) {
                        newerrors.proposalValue = "This field is required";
                        setOpenLumpsum(true);
                      }
                      if (sipData.proposalValue.length === 0) {
                        newerrors.sipProposalValue = "This field is required";
                        setOpenSIP(true);
                      }
                      if (horizon.length === 0)
                        newerrors.horizon = "This field is required";
                      setErrors(newerrors);
                    } else {
                      setFormValue({
                        ...formValue,
                        client_name: name,
                        purposePortfolio: purposePortfolio,
                        proposal_value: {
                          amount: parseInt(proposalValue),
                          denomination: proposalDenotion,
                        },
                        tax_status: taxStatus,
                        investment_horizon: parseInt(horizon),
                        tax_slab: taxSlab,
                        sip_proposal_value: {
                          ...formValue.sip_proposal_value,
                          amount: parseInt(sipData.proposalValue),
                          denomination: sipData.proposalDenomination[2],
                          frequency_of_sip: sipData.frequencySip,
                          installments: sipData.installments
                            ? parseInt(sipData.installments)
                            : 0,
                          top_up: {
                            type: sipData.topUpType[2],
                            value: sipData.topUp ? parseInt(sipData.topUp) : 0,
                          },
                          frequency_of_topup: sipData.frequencyTopUp,
                        },
                      });
                      dispatch(
                        PostEventLoggingData({
                          module_name: "Portfolio Insights",
                          event_type: "Next button",
                          url: window.location.href,
                          note: `First Screen`,
                        })
                      );
                      handleNext();
                    }
                  }}
                >
                  Next
                </InvestedgeButton>
              </div>

              <InvestedgeTextField
                required
                type="text"
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                  width: "100%",
                  marginTop: "1.5rem",
                }}
                label="Client Name"
                error={errors.name ? true : false}
                helperText={errors.name ? errors.name : ""}
                value={name}
                onChange={(e) => {
                  if (!e.target.value) {
                    let newerrors = { ...errors };
                    newerrors.name = "This field is required";
                    setErrors(newerrors);
                  } else {
                    let newerrors = { ...errors };
                    newerrors.name = null;
                    setErrors(newerrors);
                  }
                  setName(e.target.value);
                }}
              />
              <div className="w-full mt-6">
                <Dropdown
                  options={purposePortfolioOption}
                  selected={purposePortfolio}
                  setSelected={setPurposePortfolio}
                  labelName="Purpose of Portfolio"
                />
              </div>
              <br />
              <InvestedgeTextField
                required
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                  width: "100%",
                }}
                label="Investment Horizon (yrs)"
                value={horizon}
                error={errors.horizon ? true : false}
                helperText={errors.horizon ? errors.horizon : ""}
                onChange={(e) => {
                  const re = /^\d+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    if (!e.target.value) {
                      let newerrors = { ...errors };
                      newerrors.horizon = "This field is required";
                      setErrors(newerrors);
                    } else {
                      let newerrors = { ...errors };
                      newerrors.horizon = null;
                      setErrors(newerrors);
                    }
                    setHorizon(e.target.value);
                  }
                }}
                min="0"
              />

              <div className="mt-6">
                <Dropdown
                  options={["Resident", "Non-Resident"]}
                  selected={taxStatus}
                  setSelected={setTaxStatus}
                  labelName="Tax Status"
                />
              </div>
              <div className=" mt-6">
                <Dropdown
                  options={[
                    "Less than ₹ 2.5 L",
                    "₹ 2.5L - 5L",
                    "₹ 5L - 10L",
                    "₹ 10 L AND ABOVE",
                  ]}
                  selected={taxSlab}
                  setSelected={setTaxSlab}
                  labelName="Tax Slab"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-8 rounded-[8px] w-full bg-white mt-[16px] ">
        <div className="flex justify-center h-full">
          <div className="w-[60%] p-4">
            <div className="flex justify-between w-full">
              <h2 className="card-heading">SIP Details</h2>
              <img
                src={openSIP ? Up : Down}
                alt="err"
                className="w-3"
                onClick={() => setOpenSIP((prevOpen) => !prevOpen)}
              />
            </div>
            {openSIP ? (
              <SIP
                sipData={sipData}
                setSipData={setSipData}
                errors={errors}
                setErrors={setErrors}
                formValue={formValue}
                setFormValue={setFormValue}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="px-8 rounded-[8px] w-full bg-white mt-[16px] ">
        <div className="flex justify-center h-full">
          <div className="w-[60%] p-4">
            <div className="flex justify-between w-full">
              <h2 className="card-heading">Lumpsum Investment Details</h2>
              <img
                src={openLumpsum ? Up : Down}
                alt="err"
                className="w-3"
                onClick={() => setOpenLumpsum((prevOpen) => !prevOpen)}
              />
            </div>
            {openLumpsum ? (
              <Proposal
                proposalDenotion={proposalDenotion}
                setProposalDenotion={setProposalDenotion}
                proposalValue={proposalValue}
                setProposalValue={setProposalValue}
                errors={errors}
                setErrors={setErrors}
                formValue={formValue}
                setFormValue={setFormValue}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
