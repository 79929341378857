import React from "react";
import { IoIosClose } from "react-icons/io";
import InvestedgeModal from "../../components/modal/InvestedgeModal";
export default function TNCmodal({ modal, onClose }) {
  return (
    <InvestedgeModal
      aria-labelledby="Previous History Modal"
      aria-describedby="Previous History Modal"
      sx={{
        width: "80vw",
        marginRight: "auto",
        marginLeft: "auto",
        minHeight: "80vh",
        padding: "1rem",
      }}
      open={modal}
      handleclose={onClose}
    >
      <div
        className="flex justify-between border-0 pl-0"
        style={{ paddingTop: "0" }}
      >
        <h5 className="card-heading">
          Terms and Conditions for usage and sharing of information received
          from MF Central
        </h5>
        <IoIosClose size={30} onClick={onClose} />
      </div>
      <p className="font-medium text-neutralBlack my-6">
        For the purposes of these Terms and Conditions
      </p>
      <p className="text-sm leading-[20px]">
        <strong>“Investor”</strong> means an individual who has provided his PAN
        details, Mobile number and OTP on BeyondIRR’s InvestEdge platform to get
        his/her detailed and comprehensive statement of account of investments
        in all mutual fund units held by the investor with other relevant
        information to those mutual fund units from MF Central.
      </p>
      <br />
      <p className="text-sm leading-[20px]">
        {" "}
        <strong>“MFD”</strong> means Valiant Fintech Private Limited
        (“BeyondIRR”)
      </p>
      <br />
      <p className="text-sm leading-[20px]">
        <strong>“Affiliate of MFD”</strong> means affiliate of BeyondIRR who is
        using BeyondIRR’s InvestEdge Platform to provide services to his / her
        clients and shared the BeyondIRR’s MF central information retrieval link
        available under his BeyondIRR’s InvestEdge login to his/her clients.
      </p>
      <br />
      <p className="text-sm leading-[20px] mb-3">Investor hereby permits - </p>
      <ul className="text-sm leading-[20px] space-y-3">
        <li className="list-item list-inside">
          1. BeyondIRR to use the data received from MF Central for providing
          the analytics, performance comparisons as well as insights on the
          Investor’s MF portfolios.
        </li>
        <li>
          2. BeyondIRR to share the information received with that affiliate of
          BeyondIRR who used BeyondIRR’s InvestEdge Platform to get analytics,
          performance comparisons as well as insights on the Investor’s MF
          portfolios.
        </li>
        <li>
          3. Investors permits MFD to represent the information received from MF
          Central in whatever format as long as the underlying data reflects the
          holding and transaction positions truly as supplied by the RTAs.
        </li>
      </ul>
      <br />
      <br />
      <p className="text-sm leading-[20px] mb-3">
        Affiliate of MFD hereby agrees -
      </p>
      <ul className="text-sm leading-[20px] space-y-3">
        <li className="list-item list-inside">
          1. To use the information received from MF Central only for the
          purposes stated in these Terms and Conditions.
        </li>
        <li>
          2. That it shall not store any data received from MF-RTAs in their
          system (including in the local cache) except for the data pertaining
          to the purposes mentioned above.
        </li>
      </ul>
    </InvestedgeModal>
  );
}
