import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../../../../components/Dropdowns/DropdownWhiteSingle";
import Cross from "../../../../assets/img/FilledCircleOverCross.svg";
import Autocomplete from "@mui/material/Autocomplete";
import { GetProductInput, OpenSnackbar } from "../../../../actions/actions";
import { Bars } from "react-loader-spinner";
import { TextField } from "@mui/material";
import { FormControlLabel, Radio } from "@mui/material";
import InvestedgePagination from "../../../../components/pagination/InvestedgePagination";
// import Paper from "@mui/material/Paper";
export default function AddFundsModal({
  onClose,
  afmodal,
  parsedData,
  setParsedData,
  addedFundsMap,
  parsedPortfolio,
  setParsedPortfolio,
  parsedActions,
  setParsedActions,
  setPortfolioProducts,
  derationalizeProducts,
  setPortfolioActions,
}) {
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(GetProductInput());
  //   // eslint-disable-next-line
  // }, []);
  var data = useSelector((state) => state.productInputData.results);
  var dataloading = useSelector((state) => state.productInputData.loading);
  const [productsData, setProductsData] = useState(data);
  const [searchProduct, setSearchProduct] = useState("");
  const [searchBy, setSearchBy] = useState("product");
  const [asset, setAsset] = useState("All");
  const [product, setProduct] = useState("All");
  const [category, setCategory] = useState("All");
  const [recommendation, setRecommendation] = useState("All");
  const assetSet = ["Debt", "Alternate", "Equity", "Multi Asset"];
  const productSet = [
    "Mutual Fund",
    "PMS",
    "AIF",
    "Deposits",
    "Bonds or MLDS",
    "Stocks",
    "Offshore",
    "Unlisted Shares",
  ];
  const categorySet = [
    "Aggressive Hybrid Fund",
    "Alternate - ETFs - Gold",
    "Alternate - ETFs - Other",
    "Alternate - FoFs (Domestic) - Debt Oriented",
    "Alternate - FoFs (Overseas)",
    "Alternate - FoFs - Gold",
    "Alternate - Life Settlement",
    "Alternate - Long Short",
    "Alternate - Long short",
    "Alternate - Real Estate - Warehousing",
    "Alternate - Venture Capital - Early Stage Fund",
    "Alternate - Venture Debt",
    "Arbitrage Fund",
    "Balanced Advantage",
    "Balanced Hybrid Fund",
    "Bank Deposit",
    "Banking and PSU Fund",
    "Bonds",
    "Company Deposit",
    "Conservative Hybrid Fund",
    "Contra",
    "Corporate Bond",
    "Debt - Arbitrage Fund",
    "Debt - Bank Deposit",
    "Debt - Banking and PSU Fund",
    "Debt - Bonds",
    "Debt - Cash",
    "Debt - Company Deposit",
    "Debt - Corporate Bond",
    "Debt - Credit Risk Fund",
    "Debt - Debt -Interval Funds",
    "Debt - Dynamic Bond",
    "Debt - ETFs - Debt",
    "Debt - Fixed Maturity Plans",
    "Debt - Floating Rate",
    "Debt - FoFs (Domestic) - Debt Oriented",
    "Debt - Gilt - Gilt Fund with 10 year constant duration",
    "Debt - Gilt - Short & Mid Term",
    "Debt - High Yield Credit",
    "Debt - Liquid",
    "Debt - Long Duration",
    "Debt - Low Duration",
    "Debt - MLD",
    "Debt - Medium Duration",
    "Debt - Medium to Long Duration",
    "Debt - Money Market",
    "Debt - Overnight Fund",
    "Debt - Sector Funds",
    "Debt - Short Duration",
    "Debt - Solution Oriented - Retirement Fund",
    "Debt - Ultra Short Duration",
    "Dividend Yield",
    "Dynamic Asset Allocation",
    "Dynamic Bond",
    "ETFs",
    "ETFs - Other",
    "Equity - Contra",
    "Equity - Dividend Yield",
    "Equity - ETFs - Index",
    "Equity - ETFs - Other",
    "Equity - Equity Linked Savings Scheme",
    "Equity - FoFs (Domestic) - Equity Oriented",
    "Equity - FoFs (Overseas)",
    "Equity - Focused Fund",
    "Equity - Index Funds - Index - Nifty",
    "Equity - Index Funds - Index - Nifty Next 50",
    "Equity - Index Funds - Index - Sensex",
    "Equity - Index Funds - Index Funds - Other",
    "Equity - Market Cap Fund - Flexi Cap Fund",
    "Equity - Market Cap Fund - Large & Mid Cap",
    "Equity - Market Cap Fund - Large Cap Fund",
    "Equity - Market Cap Fund - Mid & Small Cap Fund",
    "Equity - Market Cap Fund - Mid Cap Fund",
    "Equity - Market Cap Fund - Multi Cap Fund",
    "Equity - Market Cap Fund - Small cap Fund",
    "Equity - Sector Funds",
    "Equity - Sector Funds - Auto",
    "Equity - Sector Funds - Banks & Financial Services",
    "Equity - Sector Funds - Consumption",
    "Equity - Sector Funds - Energy & Power",
    "Equity - Sector Funds - Infrastructure",
    "Equity - Sector Funds - Pharma & Health Care",
    "Equity - Sector Funds - Service Industry",
    "Equity - Sector Funds - Technology",
    "Equity - Solution Oriented - Children's Fund",
    "Equity - Solution Oriented - Retirement Fund",
    "Equity - Stocks - Large Cap",
    "Equity - Stocks - Mid Cap",
    "Equity - Stocks - Small Cap",
    "Equity - Thematic Fund",
    "Equity - Thematic Fund - Global",
    "Equity - Thematic Fund - MNC",
    "Equity - Unlisted Shares",
    "Equity - Value Fund",
    "Equity Linked Savings Scheme",
    "Equity Savings",
    "Fixed Maturity Plans",
    "Flexi Cap Fund",
    "Floating Rate",
    "FoFs",
    "FoFs (Domestic)",
    "FoFs (Overseas)",
    "Focused Fund",
    "Gilt",
    "High Yield Credit",
    "Index Funds",
    "Large Cap",
    "Large Cap Fund",
    "Liquid",
    "Long Duration",
    "Low Duration",
    "MLD",
    "Market Cap Fund",
    "Medium Duration",
    "Mid & Small Cap Fund",
    "Money Market",
    "Multi Asset - Aggressive Hybrid Fund",
    "Multi Asset - Arbitrage Fund",
    "Multi Asset - Balanced Advantage",
    "Multi Asset - Balanced Hybrid Fund",
    "Multi Asset - Capital Protection Funds",
    "Multi Asset - Conservative Hybrid Fund",
    "Multi Asset - Dynamic Asset Allocation",
    "Multi Asset - ETFs - Index",
    "Multi Asset - ETFs - Other",
    "Multi Asset - Equity Savings",
    "Multi Asset - FoFs (Domestic) - Debt Oriented",
    "Multi Asset - FoFs (Domestic) - Equity Oriented",
    "Multi Asset - FoFs (Overseas)",
    "Multi Asset - Index Funds - Index Funds - Other",
    "Multi Asset - Multi Asset Allocation",
    "Multi Asset - Real Estate",
    "Multi Asset - Solution Oriented - Children's Fund",
    "Multi Asset - Solution Oriented - Retirement Fund",
    "Multi Asset - Thematic Fund",
    "Multi Asset Allocation",
    "Overnight Fund",
    "Pre-IPO",
    "Real Estate",
    "Sector Funds",
    "Short Duration",
    "Solution Oriented - Children's Fund",
    "Solution Oriented - Retirement Fund",
    "Thematic Fund",
    "Ultra Short Duration",
    "Value Fund",
    "Venture Debt",
  ];
  const [pageNumber, setPageNumber] = useState(0);
  const clientsPerPage = 5;
  // const pagesVisited = pageNumber * clientsPerPage;
  const displaydata =
    Array.isArray(productsData) &&
    productsData
      // .slice(pagesVisited, pagesVisited + clientsPerPage)
      .map((pid, ind) => {
        return (
          <tr className="border-b border-[#e0dddd]" key={ind}>
            <td className=" text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-left pl-[1rem]">
              {pid.name}
            </td>
            <td className=" text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
              {pid.recommendation}
            </td>
            <td className=" text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
              {pid.asset_type}
            </td>
            <td className=" text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
              {pid.product_type}
            </td>
            <td className=" whitespace-nowrap overflow-hidden text-clip text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
              {pid.category}
            </td>
            <td className=" text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
              {addedFundsMap[pid.product_id] ? (
                <button className=" bg-primaryDisabledBgClr text-primaryDisabledClr text-sm font-semibold py-1 px-3 rounded  font-inter shadow-boxShadowButton normal-case active:opacity-[0.8] focus:outline-none">
                  Added
                </button>
              ) : (
                <button
                  className="primary-button text-sm font-semibold py-1 px-4 rounded  font-inter shadow-boxShadowButton normal-case active:opacity-[0.8] focus:outline-none"
                  onClick={() => {
                    if (
                      pid.recommendation === "Sell" ||
                      pid.recommendation === "Hold"
                    )
                      dispatch(
                        OpenSnackbar({
                          severity: "warning",
                          message:
                            "You have chosen product(s) with sell/hold recommendation",
                        })
                      );
                    setParsedData([
                      {
                        product_id: pid.product_id,
                        individual_asset_pct: 0.0,
                        product_name: pid.name,
                        asset_class: pid.asset_type,
                        product_type: pid.product_type,
                        current_weight: 0,
                        proposed_weight: 0,
                        weight_change: 0,
                        list_type: 2,
                        tag: "Buy",
                        rationale_type: "Action due to Multiple Reasons",
                        rationale: "",
                      },
                      ...parsedData,
                    ]);
                    setParsedActions([
                      {
                        product_id: pid.product_id,
                        pct: 0.0,
                        rationale_type: "Action due to Multiple Reasons",
                        rationale: "",
                      },
                      ...parsedActions,
                    ]);
                    setPortfolioActions([
                      {
                        product_id: pid.product_id,
                        pct: 0.0,
                        rationale_type: "Action due to Multiple Reasons",
                        rationale: "",
                      },
                      ...parsedActions,
                    ]);
                    setPortfolioProducts(
                      derationalizeProducts([
                        {
                          product_id: pid.product_id,
                          individual_asset_pct: 0.0,
                          product_name: pid.name,
                          asset_class: pid.asset_type,
                          product_type: pid.product_type,
                          current_weight: 0,
                          proposed_weight: 0,
                          weight_change: 0,
                          list_type: 2,
                          tag: "Buy",
                          rationale_type: "Action due to Multiple Reasons",
                          rationale: "",
                        },
                        ...parsedData,
                      ])
                    );
                    addedFundsMap[pid.product_id] = true;
                    dispatch(
                      OpenSnackbar({
                        severity: "success",
                        message: "Fund added successfully",
                      })
                    );
                  }}
                >
                  Add
                </button>
              )}
            </td>
          </tr>
        );
      });

  const pageCount = Math.ceil(
    useSelector((state) => state.productInputData.count) / 5
  );
  const changePage = (event, value) => {
    setPageNumber(value);
    dispatch(
      GetProductInput({
        params: {
          isin: searchBy === "isin" ? searchProduct : "",
          category: category === "All" ? "" : category,
          product_type: product === "All" ? "" : product,
          asset_type: asset === "All" ? "" : asset,
          search: searchBy === "product" ? searchProduct : "",
          recommendation: recommendation === "All" ? "" : recommendation,
          page: value,
          size: clientsPerPage,
        },
      })
    );
  };

  useEffect(() => {
    setProductsData(data);
  }, [data]);

  useEffect(() => {
    dispatch(
      GetProductInput({
        params: {
          isin: searchBy === "isin" ? searchProduct : "",
          category: category === "All" ? "" : category,
          product_type: product === "All" ? "" : product,
          asset_type: asset === "All" ? "" : asset,
          search: searchBy === "product" ? searchProduct : "",
          recommendation: recommendation === "All" ? "" : recommendation,
          page: 1,
          size: clientsPerPage,
        },
      })
    );
    setPageNumber(1);
    // eslint-disable-next-line
  }, [asset, product, category, recommendation, searchProduct, searchBy]);

  const flatProps = {
    options: ["All", ...categorySet].map((option) => option),
  };
  // const CustomPaper = (props) => {
  //   return (
  //     <Paper
  //       elevation={8}
  //       style={{ display: "inline-block", width: "250px" }}
  //       {...props}
  //     />
  //   );
  // };
  if (!afmodal) return null;
  return (
    <>
      <div className="modal-component">
        <div
          className="product-understanding-modal-content market_dsh_card"
          style={{ width: "80%" }}
        >
          <div className="flex justify-between mb-[1.5%]">
            <h3 className=" font-inter text-[24px] not-italic font-medium leading-[30px]">
              Add Products
            </h3>
            <img alt="err" src={Cross} onClick={onClose} className="mt-[-1%]" />
          </div>
          <div className=" rounded-[10px] p-[1rem] border border-[#e0dddd] w-full shadow-marketUpdates">
            <div className="autocomplete-containing-div mt-[1rem] flex justify-between">
              <div className="w-[12%]">
                <Dropdown
                  options={["All", "Buy", "Hold", "Sell"]}
                  selected={recommendation}
                  setSelected={setRecommendation}
                  labelName="Recommendation"
                />
              </div>
              <div className="w-[12%]">
                <Dropdown
                  options={["All", ...assetSet]}
                  selected={asset}
                  setSelected={setAsset}
                  labelName="Asset Class"
                />
              </div>
              <div className="w-[12%]">
                <Dropdown
                  options={["All", ...productSet]}
                  selected={product}
                  setSelected={setProduct}
                  labelName="Product Type"
                />
              </div>
              <div className="w-[30%]">
                <Autocomplete
                  {...flatProps}
                  value={category}
                  onChange={(event, value) => {
                    setCategory(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Category"}
                      sx={{ boxShadow: "none" }}
                    />
                  )}
                />
              </div>

              <div className="w-[30%]">
                <TextField
                  fullWidth
                  type="text"
                  sx={{
                    fontFamily: "Inter,sans-sarif",
                    fontWeight: 400,
                  }}
                  label="Search Products"
                  value={searchProduct}
                  onChange={(e) => setSearchProduct(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="mt-[1rem] justify-end flex">
            <div className="w-[30%]">
              <FormControlLabel
                control={
                  <Radio
                    size="small"
                    checked={searchBy === "isin"}
                    onChange={(e) => setSearchBy("isin")}
                    value="isin"
                    name="searchBy"
                    inputProps={{ "aria-label": "default" }}
                  />
                }
                label={<span className="text-[14px] font-inter">By ISIN</span>}
              />
              <FormControlLabel
                control={
                  <Radio
                    size="small"
                    checked={searchBy === "product"}
                    onChange={(e) => setSearchBy("product")}
                    value="product"
                    name="searchBy"
                    inputProps={{ "aria-label": "default" }}
                  />
                }
                label={
                  <span className="text-[14px] font-inter">By Product</span>
                }
              />
            </div>
          </div>
          <h5 className="mt-[2rem] font-inter text-[16px] font-medium leading-[20px] tracking-[0em] text-left text-neutralBlack mb-0 pt-[0.5rem]">
            Search Results
          </h5>

          <div className="rounded-[6px] border mt-[1rem]    min-h-[335px]">
            {dataloading ? (
              <div className="w-[100%] h-[335px] flex justify-center items-center opacity-[0.9]">
                <Bars color="#1982F8" />
              </div>
            ) : (
              <div className="min-h-[400px]">
                <table className="w-full investedge-table ">
                  <colgroup>
                    <col width="25%" />
                    <col width="15.5%" />
                    <col width="15.5%" />
                    <col width="15.5%" />
                    <col width="20.5%" />
                    <col width="14%" />
                  </colgroup>
                  <tbody>
                    <tr className="rounded-[10px] max-h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76] h-10">
                      <th className="font-inter text-[15px] font-medium not-italic leading-[20px] tracking-[0em] text-left pl-[1rem]">
                        Product Name
                      </th>
                      <th className=" font-inter text-[15px] font-medium not-italic leading-[20px] tracking-[0em] text-center">
                        Recommendation
                      </th>
                      <th className=" font-inter text-[15px] font-medium not-italic leading-[20px] tracking-[0em] text-center">
                        Asset Class
                      </th>
                      <th className=" font-inter text-[15px] font-medium not-italic leading-[20px] tracking-[0em] text-center">
                        Product Type
                      </th>
                      <th className=" font-inter text-[15px] font-medium not-italic leading-[20px] tracking-[0em] text-center">
                        Product Category
                      </th>
                      <th className=" font-inter text-[15px] font-medium not-italic leading-[20px] tracking-[0em] text-center">
                        Add Products
                      </th>
                    </tr>
                    {displaydata}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="w-[95%] flex justify-end mt-4 absolute bottom-4">
            <InvestedgePagination
              count={pageCount}
              defaultPage={1}
              page={pageNumber}
              onChange={changePage}
              color="primary"
            />
          </div>
        </div>
      </div>
    </>
  );
}
