import React, { useState } from "react";
import Minimalist from "../../../../assets/img/minimalist_template.png";
import Classic from "../../../../assets/img/classic_template.png";
import Tick from "../../../../assets/img/module_access_type_tick.svg";
import { FormControlLabel, Switch } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  GetSIPPPTAPI,
  PostEventLoggingData,
} from "../../../../actions/actions";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
export default function PortfolioFifthStep({
  handleBack,
  handleNext,
  formValue,
  portfolioProducts,
}) {
  const dispatch = useDispatch();
  const [template, setTemplate] = useState(1);
  const [proposal, setProposal] = useState({
    summary: true,
    investmentStrategy: true,
    proposedportfolio: true,
    equityportfolioAnalytics: true,
    debtportfolioAnalytics: true,
    alternatesportfolioAnalytics: true,
    onePage: true,
    whySip: true,
  });
  const [excelformat, setExcelFormat] = useState(false);

  let handleChangeSwitch = (e) => {
    let newProposal = { ...proposal };
    newProposal[e.target.name] = !newProposal[e.target.name];
    setProposal(newProposal);
  };
  return (
    <>
      <div className="w-[100%] flex mt-[16px]">
        <div className="p-[32px] rounded-[8px] bg-white my-[4px] w-full">
          <div className="flex justify-between w-full items-center">
            <div>
              <h2 className="card-heading">Select Template</h2>
            </div>

            <div className="flex">
              <InvestedgeButton
                onClick={() => {
                  dispatch(
                    PostEventLoggingData({
                      module_name: "Portfolio Review",
                      event_type: "Prev Button",
                      url: window.location.href,
                      note: `Fifth screen`,
                    })
                  );
                  handleBack();
                }}
              >
                Prev
              </InvestedgeButton>
              <InvestedgeButton
                className="ml-4"
                onClick={() => {
                  dispatch(
                    GetSIPPPTAPI({
                      sip_for: formValue.purpose_of_sip,
                      client: {
                        ...formValue,
                        investment_horizon: parseFloat(
                          formValue.investment_horizon
                        ).toFixed(2),
                        proposal_value: {
                          amount: parseFloat(
                            formValue.sip_proposal_value.amount
                          ),
                          denomination:
                            formValue.sip_proposal_value.denomination,
                        },
                        risk_score: parseInt(formValue.risk_score),
                        duration_of_sip: parseInt(formValue.duration_of_sip),
                        sip_frequency: formValue.frequency_of_sip,
                        top_up_amount: {
                          amount: formValue.top_up.value
                            ? parseFloat(formValue.top_up.value)
                            : 0,
                          denomination: formValue.top_up.type,
                        },
                        top_up_frequency: formValue.frequency_of_topup,
                        number_of_schemes_for_asset_allocation: formValue
                          .number_of_schemes_for_asset_allocation.length
                          ? parseInt(
                              formValue.number_of_schemes_for_asset_allocation
                            )
                          : 2,
                        tax_status: "Resident",
                        tax_slab: "₹ 2.5L - 5L",
                        desired_returns: 0.123,
                      },
                      allocation_preference: formValue.allocation_preference,
                      template: template,
                      ppt: {
                        executive_summary: proposal.summary,
                        investment_strategy: proposal.investmentStrategy,
                        proposed_portfolio: proposal.proposedportfolio,
                        equity_portfolio_analytics:
                          proposal.equityportfolioAnalytics,
                        debt_portfolio_analytics:
                          proposal.debtportfolioAnalytics,
                        alternates_portfolio_analytics:
                          proposal.alternatesportfolioAnalytics,
                        one_page_on_each_product: proposal.onePage,
                        why_sip: proposal.whySip,
                      },
                      output: {
                        ppt: true,
                        excel: excelformat,
                        pdf: false,
                        all: false,
                      },
                      portfolio: { "Mutual Funds": portfolioProducts },
                    })
                  );
                  dispatch(
                    PostEventLoggingData({
                      module_name: "Portfolio Review",
                      event_type: "Download Button",
                      url: window.location.href,
                      note: `Fifth screen`,
                    })
                  );
                  handleNext();
                }}
              >
                Download
              </InvestedgeButton>
            </div>
          </div>

          <div className=" w-[90%] pt-[2rem] flex justify-between">
            <div className="w-[45%]">
              {template === 1 ? (
                <div className=" relative">
                  <div
                    className="w-[100%] bg-[#fafafa] p-[32px] min-h-[300px]"
                    onClick={() => setTemplate(1)}
                  >
                    <img
                      src={Minimalist}
                      alt="err"
                      style={{
                        width: "68%",
                        filter:
                          "drop-shadow(4px 4px 32px rgba(1, 34, 175, 0.16)) drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.05))",
                      }}
                    />
                    <p className="mt-[16px] font-inter font-medium text-lg mb-0">
                      Minimalist
                    </p>
                  </div>
                  <div className="absolute bottom-0 w-full h-full flex justify-end items-start p-[1rem] bg-primary-100/[0.3]">
                    <img src={Tick} alt="err" />
                  </div>
                </div>
              ) : (
                <div
                  className=" w-full bg-[#fafafa] p-[32px] min-h-[300px]"
                  onClick={() => setTemplate(1)}
                >
                  <img
                    src={Minimalist}
                    alt="err"
                    style={{
                      width: "68%",
                      filter:
                        "drop-shadow(4px 4px 32px rgba(1, 34, 175, 0.16)) drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.05))",
                    }}
                  />
                  <p className="mt-[16px] font-inter font-medium text-lg mb-0">
                    Minimalist
                  </p>
                </div>
              )}

              <div className="flex justify-between mt-[2rem]">
                <div className="w-[50%]">
                  <h3 className="font-inter text-[16px] font-medium leading-[20px] tracking-[0em] text-left text-[#000] mt-[1rem]">
                    Choose Proposal Components
                  </h3>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={proposal.summary}
                        name="summary"
                        onChange={(e) => handleChangeSwitch(e)}
                        inputProps={{ role: "switch" }}
                        color="primary"
                      />
                    }
                    label={
                      <span className=" font-inter text-[16px] font-normal leading-[20px] tracking-[0em] text-left text-neutralBlack">
                        Executive Summary
                      </span>
                    }
                    className="pt-[1.75rem]"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={proposal.investmentStrategy}
                        name="investmentStrategy"
                        onChange={(e) => handleChangeSwitch(e)}
                        inputProps={{ role: "switch" }}
                        color="primary"
                      />
                    }
                    label={"Investment Strategy"}
                    style={{ paddingTop: "1rem" }}
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={proposal.proposedportfolio}
                        name="proposedportfolio"
                        onChange={(e) => handleChangeSwitch(e)}
                        inputProps={{ role: "switch" }}
                        color="primary"
                      />
                    }
                    label={"Proposed Portfolio"}
                    style={{ paddingTop: "1rem" }}
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={proposal.equityportfolioAnalytics}
                        name="equityportfolioAnalytics"
                        onChange={(e) => handleChangeSwitch(e)}
                        inputProps={{ role: "switch" }}
                        color="primary"
                      />
                    }
                    label={"Equity Portfolio Analytics"}
                    style={{ paddingTop: "1rem" }}
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={proposal.debtportfolioAnalytics}
                        name="debtportfolioAnalytics"
                        onChange={(e) => handleChangeSwitch(e)}
                        inputProps={{ role: "switch" }}
                        color="primary"
                      />
                    }
                    label={"Debt Portfolio Analytics"}
                    style={{
                      paddingTop: "1rem",
                      fontFamily: "Inter,sans-serif",
                      fontWeight: 400,
                    }}
                  />
                  <br />
                  {/* <FormControlLabel
                    control={
                      <Switch
                        checked={proposal.alternatesportfolioAnalytics}
                        name="alternatesportfolioAnalytics"
                        onChange={(e) => handleChangeSwitch(e)}
                        inputProps={{ role: "switch" }}
                        color="primary"
                      />
                    }
                    label={"Alternates Portfolio Analytics"}
                    style={{
                      paddingTop: "1rem",
                      fontFamily: "Inter,sans-serif",
                      fontWeight: 400,
                    }}
                  />
                  <br /> */}
                  {/* <FormControlLabel
                    control={
                      <Switch
                        checked={proposal.onePage}
                        name="onePage"
                        onChange={(e) => handleChangeSwitch(e)}
                        inputProps={{ role: "switch" }}
                        color="primary"
                      />
                    }
                    label={"One Page on Each Product"}
                    style={{
                      paddingTop: "1rem",
                      fontFamily: "Inter,sans-serif",
                      fontWeight: 400,
                    }}
                  />
                  <br /> */}
                  <FormControlLabel
                    control={
                      <Switch
                        checked={proposal.whySip}
                        name="whySip"
                        onChange={(e) => handleChangeSwitch(e)}
                        inputProps={{ role: "switch" }}
                        color="primary"
                      />
                    }
                    label={"Why SIP"}
                    style={{
                      paddingTop: "1rem",
                      fontFamily: "Inter,sans-serif",
                      fontWeight: 400,
                    }}
                  />
                </div>
                <div className="w-[35%]">
                  <h3 className="portfolio-stepper-fifth-form-subheading">
                    Output Format
                  </h3>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={true}
                        inputProps={{ role: "switch" }}
                        color="primary"
                      />
                    }
                    label={
                      <span className=" font-inter text-[16px] font-normal leading-[20px] tracking-[0em] text-left text-neutralBlack">
                        PPT
                      </span>
                    }
                    className="pt-[1.75rem]"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={excelformat}
                        onChange={() => setExcelFormat(!excelformat)}
                        inputProps={{ role: "switch" }}
                        color="primary"
                      />
                    }
                    label={
                      <span className=" font-inter text-[16px] font-normal leading-[20px] tracking-[0em] text-left text-neutralBlack">
                        Excel
                      </span>
                    }
                    className="pt-[1rem]"
                  />
                </div>
              </div>
            </div>
            <div className="w-[45%]">
              {template === 2 ? (
                <div className="relative">
                  <div
                    className="w-full bg-[#fafafa] p-[32px] min-h-[300px]"
                    onClick={() => setTemplate(2)}
                  >
                    <img
                      src={Classic}
                      alt="err"
                      style={{
                        width: "70%",
                        filter:
                          "drop-shadow(4px 4px 32px rgba(1, 34, 175, 0.16)) drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.05))",
                      }}
                    />
                    <p className="mt-[16px] font-inter font-medium text-lg mb-0">
                      Classic
                    </p>
                  </div>
                  <div className="absolute bottom-0 w-full h-full flex justify-end items-start p-[1rem] bg-primary-100/[0.3]">
                    <img src={Tick} alt="err" />
                  </div>
                </div>
              ) : (
                <div
                  className="w-full bg-[#fafafa] p-[32px] min-h-[300px]"
                  onClick={() => setTemplate(2)}
                >
                  <img
                    src={Classic}
                    alt="err"
                    style={{
                      width: "70%",
                      filter:
                        "drop-shadow(4px 4px 32px rgba(1, 34, 175, 0.16)) drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.05))",
                    }}
                  />
                  <p className="mt-[16px] font-inter font-medium text-lg mb-0">
                    Classic
                  </p>
                </div>
              )}

              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
