import React, { useState } from "react";
import urls from "../../urls";
import auth from "../../auth";
import axios from "axios";
import { useHistory } from "react-router-dom";
import InvestedgeModal from "../../components/modal/InvestedgeModal";
import { IoIosClose } from "react-icons/io";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import InvestedgeTextField from "../../components/textfield/InvestedgeTextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { InputAdornment } from "@mui/material";
export default function ChangePasswordModal({ modal, setModal }) {
  const history = useHistory();
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    confirmPassword: false,
  });
  const [error, setError] = useState({
    oldPassword: null,
    confirmPassword: null,
  });
  return (
    <InvestedgeModal
      aria-labelledby="Change Password Modal"
      aria-describedby="Change Password Modal"
      sx={{
        height: "55vh",
        minWidth: "450px",
        width: "450px",
        margin: "auto",
        padding: "1.5rem",
      }}
      open={modal}
      handleclose={() => setModal(false)}
    >
      <div className="flex justify-between items-start">
        <h3 className="card-heading">Change Password</h3>
        <IoIosClose size={30} onClick={() => setModal(false)} />
      </div>
      <InvestedgeTextField
        variant="outlined"
        required
        label="Old Password"
        className="font-inter font-normal w-full mr-[0.5rem] mt-[1.5rem]"
        type="password"
        value={oldPassword}
        error={error.oldPassword}
        helperText={error.oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
        style={{
          marginTop: "1.5rem",
        }}
      />
      <InvestedgeTextField
        variant="outlined"
        required
        label="New Password"
        className="font-inter font-normal w-full mr-[0.5rem] mt-[1.5rem]"
        sx={{
          ".MuiFormHelperText-root": {
            position: "absolute",
            bottom: "-1.7em",
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="start"
              className="cursor-pointer"
              onClick={() =>
                setShowPassword({
                  ...showPassword,
                  newPassword: !showPassword.newPassword,
                })
              }
            >
              {showPassword.newPassword ? <VisibilityOff /> : <Visibility />}
            </InputAdornment>
          ),
        }}
        type={showPassword.newPassword ? "text" : "password"}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={{
          marginTop: "1.5rem",
        }}
      />
      <InvestedgeTextField
        variant="outlined"
        required
        label="Confirm Password"
        className="font-inter font-normal w-full mr-[0.5rem] mt-[1.5rem]"
        sx={{
          ".MuiFormHelperText-root": {
            bottom:
              error.confirmPassword === "Passwords don't match"
                ? "-1.7em"
                : "-4em",
            lineHeight: "16px",
          },
        }}
        error={error.confirmPassword}
        helperText={error.confirmPassword}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="start"
              className="cursor-pointer"
              onClick={() =>
                setShowPassword({
                  ...showPassword,
                  confirmPassword: !showPassword.confirmPassword,
                })
              }
            >
              {showPassword.confirmPassword ? (
                <VisibilityOff />
              ) : (
                <Visibility />
              )}
            </InputAdornment>
          ),
        }}
        type={showPassword.confirmPassword ? "text" : "password"}
        value={confirmPassword}
        onChange={(e) => {
          setConfirmPassword(e.target.value);
          if (password === e.target.value)
            setError({ oldPassword: null, confirmPassword: null });
        }}
        style={{
          marginTop: "1.5rem",
        }}
      />
      <div className="flex w-full justify-end absolute bottom-[32px] right-8">
        <InvestedgeButton
          onClick={() => {
            const re =
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            if (password !== confirmPassword)
              setError({
                oldPassword: null,
                confirmPassword: "Passwords don't match",
              });
            else if (!re.test(password)) {
              setError({
                oldPassword: null,
                confirmPassword:
                  "Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
              });
            } else {
              axios
                .post(urls.settingsChangePasswordURL, {
                  old_password: oldPassword,
                  password1: password,
                  password2: confirmPassword,
                })
                .then((response) => {
                  auth.logout(() => {
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("refreshToken");
                    history.push("/login");
                  });
                })
                .catch(() =>
                  setError({
                    ...error,
                    oldPassword: "Enter correct password",
                  })
                );
            }
          }}
        >
          Change Password
        </InvestedgeButton>
      </div>
    </InvestedgeModal>
  );
}
