import React, { useState, useEffect } from "react";
import { BsArrowRight } from "react-icons/bs";
import {
  Link,
  //  useParams
} from "react-router-dom";
import Logoone from "../assets/img/l1.svg";
import Insightbg from "../assets/img/insight_bg.svg";
import Footer from "./Footer";
import Filtered from "./Filtered";
import Modal from "./Modal";
import Header from "./Header";
import Subscribe from "./SubscribeSection";
import "../assets/css/style.css";
// import "../assets/vendor/icofont/icofont.min.css";
import urls from "../urls.js";

function Insights() {
  // const { id } = useParams();
  // const [blogs, setBlogs] = useState(null);
  // const [visible, setVisible] = useState(4);
  const [header, setHeader] = useState();

  useEffect(() => {
    const getData = async () => {
      await fetch(urls.blogsURL)
        .then((resp) => resp.json())
        .then((data) => {
          // setBlogs(data);
          setFirstBlog(data);
        });
    };
    getData();
  }, []);

  // const tagDictionary = {
  //   M: "Markets",
  //   P: "Perspectives",
  //   R: "Research",
  //   I: "Impact Stories",
  //   Re: "Reports",
  // };

  function setFirstBlog(temp) {
    return setHeader(temp[0]);
  }

  return (
    <>
      <Header />

      <main id="main">
        <section
          style={{ marginTop: "70px", backgroundImage: `url(${Insightbg})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 pt-5 pt-lg-0 order-2 order-lg-1">
                <h3
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    marginTop: "10%",
                    marginBottom: "5%",
                  }}
                >
                  {!!header ? header.title : null}
                </h3>

                <Link
                  to={!!header ? `/individualblog/` + header.id : null}
                  exact
                >
                  <button
                    className="btn btn-primary btn-get-started scrollto make-it-slow"
                    style={{ backgroundColor: "#0000F5" }}
                  >
                    VIEW BLOG <BsArrowRight style={{ display: "inline" }} />
                  </button>
                </Link>
              </div>
              <div className="col-lg-5 order-1 order-lg-2">
                <div style={{ width: "70%", height: "70%" }}>
                  <img
                    src={Logoone}
                    alt="growth-img"
                    style={{ borderRadius: "5px", marginLeft: "75%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <Filtered />
          </div>
        </section>
        <Subscribe />
        <Modal />
        <Footer />
      </main>
    </>
  );
}

export default Insights;
