import React, { useState } from "react";
import InvestedgeToggleButtonGroup from "../../../components/toggle/InvestedgeToggle";
import AfterloginApp from "../../../rmdashboard/AfterloginApp";
import Clientsidebar from "../../ClientSidebar";
import GoalDetails from "./GoalDetails";
import GoalTracking from "./GoalTracking";
import Investments from "./Investments";
import ScenerioAnalysis from "./ScenerioAnalysis";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
export default function ViewGoal() {
  const [route, setRoute] = useState(1);
  const goalDetails = useSelector((state) => state.goalData);
  const location = useLocation();
  const id = location.state.goal.id;
  const title = location.state.goal.goal_title;
  const handleTabClick = (e, val) => {
    if (val) {
      var elmnt = document.getElementById("view-goal-screen");
      setRoute(val);
      elmnt.scrollTo({
        top: val,
        behavior: "smooth",
      });
    }
  };
  const toggletabs = [
    { label: "Goal Details", value: 1 },
    { label: "Investments", value: 270 },
    { label: "Goal Tracking & Actionables", value: 830 },
    { label: "Scenario Analysis", value: 3050 },
  ];
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp />
      <div className="loggedinscreen-container pt-0" id="view-goal-screen">
        <div className="loggedinscreen flex flex-row justify-between">
          <Clientsidebar />
          <div className="p-[1rem] bg-[#fff] rounded-[0.5rem] min-h-full pt-0 ml-0 shadow-none client-input-parent-card w-[79%]">
            <div className="sticky top-0 bg-[#f3f3f3] ml-[-22px] mr-[-22px] z-[10] pt-[1rem] px-[6px]">
              <div className="w-full h-full rounded-t-[0.5rem] bg-[#fff] p-[1rem]">
                <div className="flex justify-between w-full">
                  <div className="card-heading">{title}</div>
                  <div className="flex justify-end">
                    <InvestedgeToggleButtonGroup
                      tabs={toggletabs}
                      value={route}
                      onChange={handleTabClick}
                      sx={{ height: "40px", "& button": { minWidth: "152px" } }}
                      exclusive
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-[#fff] m-[-1rem] p-[1rem] rounded-b-[0.5rem]">
              <GoalDetails goalDetails={goalDetails} />
              <Investments goalDetails={goalDetails} id={id} title={title} />
              <GoalTracking
                goalDetails={goalDetails}
                goalData={location.state.goal}
              />
              <ScenerioAnalysis
                goalData={goalDetails}
                clientInvestments={goalDetails.client_investments}
                id={id}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
