import { POST_DASHBOARD_SUITABLE_PRODUCTS } from "../actions/type";
const initialState = [];
export default function PostSuitableProducts(state = initialState, action) {
  switch (action.type) {
    case POST_DASHBOARD_SUITABLE_PRODUCTS:
      return action.payload;
    default:
      return state;
  }
}
