import { useMediaQuery } from "@mui/material";
import React from "react";
import MobilePortfolioProducts from "./PortfolioProducts/MobilePortfolioProducts";
import PortfolioProducts from "./PortfolioProducts/PortfolioProducts";

export default function Analytics() {
  let pastSmallScreen = useMediaQuery("(min-width:768px)");
  return (
    <div className="parentLoggedinScreen">
      <div className="loggedinscreen-container min-h-full h-full">
        <div className="loggedinscreen h-full ">
          <div className="h-full">
            {pastSmallScreen ? (
              <PortfolioProducts />
            ) : (
              <MobilePortfolioProducts />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
