import React from "react";
import { strategy } from "../onePagerConsts";
import Fundmanage from "../../../assets/img/fund_manage.svg";
import Fundhouse from "../../../assets/img/fund_house.svg";
import "../../../static/css/existing/dashboard_styles.css";

export default function strategyComponent() {
  return (
    <>
      <div
        style={{
          padding: "1rem",
          backgroundColor: "#fff",
          borderRadius: "6px",
          boxShadow:
            " 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(0, 0, 0, 0.05)",
          minheight: "274px",
        }}
      >
        <p className="onepager-description-text">{strategy.card["text"]}</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "1.5rem",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            padding: "1rem",
            backgroundColor: "#fff",
            borderRadius: "6px",
            boxShadow:
              " 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(0, 0, 0, 0.05)",
            minheight: "274px",
            width: "39.5%",
          }}
        >
          <div className="d-flex flex-row">
            <img src={Fundmanage} className="-mt-2 mr-2" alt="err" />
            <h5 className="onepager-small-heading">Fund Management</h5>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "1rem",
              justifyContent: "space-around",
            }}
          >
            {/* #3 Contains Strategy Items */}

            {Object.entries(strategy.fundManagement).map(([key, value]) => (
              <div>
                <div
                  style={{
                    boxShadow: "0px 2px 16px rgba(0, 0, 0, 0.1)",
                    padding: "1rem",
                  }}
                >
                  <img
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={value["img"]}
                    alt="err"
                  ></img>
                </div>
                <h5
                  className="onepager-small-heading"
                  style={{
                    paddingTop: "1rem",
                    textAlign: "center",
                  }}
                >
                  {value["name"]}
                </h5>
                <p
                  className="onepager-description-text"
                  style={{
                    color: "#939393",
                  }}
                >
                  Managing since {value["managingSince"]}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div
          style={{
            padding: "1rem",
            backgroundColor: "#fff",
            borderRadius: "6px",
            boxShadow:
              " 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(0, 0, 0, 0.05)",
            height: "279px",
            width: "59%",
          }}
        >
          <div className="d-flex flex-row">
            <img src={Fundhouse} className="-mt-2 mr-2" alt="err" />
            <h5 className="onepager-small-heading">
              {strategy.fundHouse["title"]}
            </h5>
          </div>
          <p className="onepager-description-text mt-1">
            {strategy.fundHouse["text"]}
          </p>
          <p
            className=" onepager-description-text"
            style={{ color: "#0000F5", marginTop: "2rem" }}
          >
            Learn more
          </p>
        </div>
      </div>
      <div
        style={{
          padding: "1rem",
          backgroundColor: "#fff",
          borderRadius: "6px",
          boxShadow:
            " 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(0, 0, 0, 0.05)",
          minheight: "274px",
          marginTop: "1.5rem",
        }}
      >
        <h5 className="onepager-small-heading">
          {strategy.managementStyle["title"]}
        </h5>
        <p className="onepager-description-text mt-2">
          {strategy.managementStyle["text"]}
        </p>
      </div>
    </>
  );
}
