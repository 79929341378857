// import { InputAdornment, OutlinedInput } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetIpsData,
  PostResearchAssets,
  UpdateResearchInvestmentPolicyData,
} from "../../../actions/actions";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import InvestedgeToggleButtonGroup from "../../../components/toggle/InvestedgeToggle";
import InvestmentPolicyModal from "../ResearchModal/InvestmentPolicyModal";

export default function SimulateSection({ sliderData }) {
  const dispatch = useDispatch();
  const ipsData = useSelector((state) => state.getipsdata);
  const toggletabs = [
    { label: "Conservative", value: 15 },
    { label: "Mod Conservative", value: 35 },
    { label: "Moderate", value: 55 },
    { label: "Mod Aggressive", value: 75 },
    { label: "Aggressive", value: 95 },
  ];
  const [riskscore, setRiskscore] = useState(55);
  const [riskText, setRiskText] = useState("Moderate");
  const handleTabClick = (e, val) => {
    if (val) {
      setRiskscore(val);
      dispatch(
        GetIpsData({
          params: {
            risk_score: val,
            investment_horizon: 5,
          },
        })
      );
    }
  };
  // const [flexibility, setFlexibility] = useState(10);
  const [ipsmodal, setIpsModal] = useState(false);
  const [ips, setIpsData] = useState({
    equity: {
      target: null,
      flexibility: 10,
    },
    debt: {
      target: null,
      flexibility: 10,
    },
    alternates: {
      target: null,
      flexibility: 10,
    },
    large_cap: {
      target: null,
      flexibility: 10,
    },
    mid_cap: {
      target: null,
      flexibility: 10,
    },
    small_cap: {
      target: null,
      flexibility: 10,
    },
    duration: {
      target: null,
      flexibility: (parseInt(10) * 0.05).toFixed(0),
    },
    credit_risk: {
      target: null,
      flexibility: 10,
    },
    alternates_low_vol: {
      target: null,
      flexibility: 10,
    },
  });
  useEffect(() => {
    let temp = { ...ips };
    temp.equity.target =
      Math.round(ipsData[0].equity * 100) +
      (100 -
        (Math.round(ipsData[0].equity * 100) +
          Math.round(ipsData[0].debt * 100) +
          Math.round(ipsData[0].alternates * 100)));
    temp.debt.target = Math.round(ipsData[0].debt * 100);
    temp.alternates.target = Math.round(ipsData[0].alternates * 100);
    temp.large_cap.target = Math.round(ipsData[1].large_cap * 100);
    temp.mid_cap.target = Math.round(ipsData[1].mid_cap * 100);
    temp.small_cap.target = Math.round(ipsData[1].small_cap * 100);
    temp.duration.target = Math.round(ipsData[2].duration);
    temp.credit_risk.target = Math.round(ipsData[3].credit_risk * 100);
    temp.alternates_low_vol.target = Math.round(
      ipsData[4].alternates_low_vol * 100
    );
    setIpsData(temp);
    dispatch(UpdateResearchInvestmentPolicyData(temp));
    // eslint-disable-next-line
  }, [ipsData]);
  useEffect(() => {
    if (riskscore >= 0 && riskscore <= 20) setRiskText("Conservative");
    if (riskscore > 20 && riskscore <= 40)
      setRiskText("Moderately Conservative");
    if (riskscore > 40 && riskscore <= 60) setRiskText("Moderate");
    if (riskscore > 60 && riskscore <= 80) setRiskText("Moderately Aggressive");
    if (riskscore > 80 && riskscore <= 100) setRiskText("Aggressive");
    // handleSimulation();
    //eslint-disable-next-line
  }, [riskscore]);

  // const handleFlexibilityChange = (e) => {
  //   setFlexibility(e.target.value);
  //   let newips = ips;
  //   Object.keys(newips).forEach((key) => {
  //     newips[key].flexibility = parseInt(e.target.value);
  //     if (key === "duration")
  //       newips[key].flexibility = parseInt(e.target.value) * 0.05;
  //   });
  //   setIpsData(newips);
  // };

  const handleSimulation = () => {
    dispatch(
      PostResearchAssets({
        flag: false,
        asset_class_view: {
          equity: sliderData.equity_score,
          debt: sliderData.debt_score,
          alternates: sliderData.alternate_score,
        },
        equity_view: {
          large_cap: sliderData.largecap_score,
          mid_cap: sliderData.midcap_score,
          small_cap: sliderData.small_cap_score,
        },
        duration_view: sliderData.duration_score,
        credit_risk_view: sliderData.credit_score,
        alternates_low_vol_view: sliderData.alternate_volatility_score,
        risk_score: riskscore,
        investment_horizon: 5,
        ips: {
          equity: {
            target: ips.equity.target / 100,
            flexibility: ips.equity.flexibility / 100,
          },
          debt: {
            target: ips.debt.target / 100,
            flexibility: ips.debt.flexibility / 100,
          },
          alternates: {
            target: ips.alternates.target / 100,
            flexibility: ips.alternates.flexibility / 100,
          },
          large_cap: {
            target: ips.large_cap.target / 100,
            flexibility: ips.large_cap.flexibility / 100,
          },
          mid_cap: {
            target: ips.mid_cap.target / 100,
            flexibility: ips.mid_cap.flexibility / 100,
          },
          small_cap: {
            target: ips.small_cap.target / 100,
            flexibility: ips.small_cap.flexibility / 100,
          },
          duration: {
            target: ips.duration.target,
            flexibility: ips.duration.flexibility,
          },
          credit_risk: {
            target: ips.credit_risk.target / 100,
            flexibility: ips.credit_risk.flexibility / 100,
          },
          alternates_low_vol: {
            target: ips.alternates_low_vol.target / 100,
            flexibility: ips.alternates_low_vol.flexibility / 100,
          },
        },
      })
    );
    var elmnt = document.getElementById("research-input-parent-screen");
    elmnt.scrollTo({
      top: 550,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <h3 className="text-[20px] font-inter not-italic font-medium leading-[30px] mt-3">
        Select Client Profile
      </h3>
      <div className="z-[10000] w-full h-[30px] pt-[0.5rem]">
        <p className="font-inter text-[13px] not-italic font-normal leading-[20px] tracking-[0em] text-left text-neutralBlack">
          You can see the tactical and strategic asset allocation basis the
          asset allocation views that your firm has.
        </p>
      </div>
      <table className="w-full">
        <colgroup>
          <col width="60%" /> <col width="40%" />
        </colgroup>
        <tbody className="w-full">
          <tr className="w-full">
            <td style={{ paddingLeft: "0px" }}>
              <p className="font-semibold text-sm text-neutralBlack">
                Risk Profile
              </p>
            </td>
            <td>
              {/* <p className="font-semibold text-sm text-neutralBlack">
                Flexibility
              </p> */}
            </td>
          </tr>
          <tr>
            <td style={{ paddingLeft: "0px" }}>
              <InvestedgeToggleButtonGroup
                tabs={toggletabs}
                value={riskscore}
                onChange={handleTabClick}
                sx={{ height: "40px", "& button": { width: "155px" } }}
                exclusive
              />
            </td>
            <td>
              {/* <div className="flex items-center">
                <div className="w-[30%]">
                  <OutlinedInput
                    size="small"
                    className="text-sm"
                    value={flexibility}
                    onChange={(e) => {
                      const re = /^\d+$/;
                      if (
                        (e.target.value === "" || re.test(e.target.value)) &&
                        e.target.value.length <= 10
                      )
                        handleFlexibilityChange(e);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <span className="text-sm font-inter">%</span>
                      </InputAdornment>
                    }
                  />
                </div>
              </div> */}
            </td>
          </tr>
          <tr>
            <td
              style={{ paddingLeft: "0px" }}
              className="flex flex-col items-start"
            >
              <div className="bg-[#EDF5FE] text-primary-200 inline-block font-medium text-sm py-[0.3rem] px-[0.5rem] rounded-[8px] mb-[0.5rem]">
                {riskText}
              </div>
              <p className="mb-0 text-justify text-sm leading-[20px] w-[60%]">
                Risk tolerance level decides the overall risk taking capacity of
                the investor. The risk tolerance helps in deciding the strategic
                asset allocation.
              </p>
            </td>
            <td>
              {/* <p className="mb-0 text-justify text-sm leading-[20px]">
                Flexibility decides how much variance is allowed in the
                strategic asset allocation based on the view provided for
                different assets.
                <br /> <br />
                For example, if the strategic equity allocation for a moderate
                risk profile (risk tolerance = 50) is 75% and flexibility is
                20%, then the equity allocation can vary from 55% to 95%
                depending on the view provided on equity asset class. If the
                view is bearish then equity allocation is close to 55% and if
                the view is bullish the equity allocation is close to 95%.
              </p> */}
            </td>
          </tr>
          <tr>
            <td style={{ paddingLeft: "0px" }}>
              {" "}
              <p className="font-semibold text-sm text-neutralBlack mt-2">
                Investment Policy Statement
              </p>
              <div
                className="investment-profile-modal-anchor text-primary-200 pt-4"
                onClick={() => {
                  setIpsModal(true);
                }}
              >
                Click to view and change
              </div>
              <InvestmentPolicyModal
                onClose={() => setIpsModal(false)}
                ipsmodal={ipsmodal}
                ips={ips}
                setIpsData={setIpsData}
              />
            </td>
            <td>
              {/* <p className="mb-0 text-justify text-sm leading-[20px] mt-2">
                Risk tolerance level decides the overall risk taking capacity of
                the investor. The risk tolerance helps in deciding the strategic
                asset allocation.
              </p> */}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="w-full flex pt-4">
        <InvestedgeButton
          // disabled={flexibility.length === 0}
          variant="contained"
          onClick={() => {
            handleSimulation();
          }}
        >
          Simulate
        </InvestedgeButton>
      </div>
    </div>
  );
}
