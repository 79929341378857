import React, { useState } from "react";
import UpdateInputScreen from "../../portfolioinput/UpdateInputScreen";
import { useDispatch } from "react-redux";
import { PostEventLoggingData } from "../../actions/actions";
import InvestedgeButtonOutlined from "../../components/buttons/InvestedgeButtonOutlined";
export default function PortfolioDetailsRow({ member, familyMembers }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const parsePortfolio = (portfolio) => {
    if (portfolio / 1000 < 100)
      return `₹ ${parseFloat(portfolio / 1000).toFixed(1)} K`;
    if (portfolio / 100000 < 100)
      return `₹ ${parseFloat(portfolio / 100000).toFixed(1)} L`;
    return `₹ ${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  };
  return (
    <>
      <tr key={member.name} className="border-b border-[#e0dddd]">
        <td className=" font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] pl-[1rem] text-left">
          {member.name}
        </td>
        <td className="font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
          {parsePortfolio(member.portfolio_value)}
        </td>
        <td className="font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
          <InvestedgeButtonOutlined
            className="mx-auto"
            onClick={() => {
              dispatch(
                PostEventLoggingData({
                  module_name: "Client Profile",
                  event_type: "Information",
                  url: window.location.href,
                  note: `${member.name} Portfolio viewed`,
                })
              );
              setOpen(true);
            }}
          >
            View
          </InvestedgeButtonOutlined>
        </td>
      </tr>
      <UpdateInputScreen
        familyMembers={familyMembers}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
}
