import { GET_SETTINGS_OPERATIONS } from "../actions/type";
const initialState = [];
export default function GetSettingsOperations(state = initialState, action) {
  switch (action.type) {
    case GET_SETTINGS_OPERATIONS:
      return action.payload;
    default:
      return state;
  }
}
