import React, { useState, useEffect } from "react";
import OverviewSection from "./OverviewSection";
import DebtSection from "./DebtSection";
import EquitySection from "./EquitySection";
import DisableSorted from "../../../../assets/img/disabled_sort.svg";
import AscendingSorted from "../../../../assets/img/ascending_sort.svg";
import DescendingSorted from "../../../../assets/img/descending_sort.svg";
import { useSelector, useDispatch } from "react-redux";
import Add from "../../../../assets/img/portfolioaddfunds.svg";
import Reload from "../../../../assets/img/portfolioreload.svg";
import AddFundsModal from "./AddFundsModal";
import ActionableTableRow from "./ActionableTableRow";
// import TooltipIcon from "../../../../assets/img/tooltip_icon.svg";
// import { Tooltip, Zoom } from "@mui/material";
import {
  GetReviewPortfolioAnalyticsFinal,
  PostEventLoggingData,
} from "../../../../actions/actions";
import { Bars } from "react-loader-spinner";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
import InvestedgePagination from "../../../../components/pagination/InvestedgePagination";
import AlternateSection from "./AlternateSection";
import Dropdown from "../../../../components/Dropdowns/DropdownWhiteSingle";
export default function Actionable({
  portfolioProducts,
  setPortfolioProducts,
  portfolio,
  setPortfolio,
  portfolioActions,
  setPortfolioActions,
  clientId,
  errorDiff,
  setErrorDiff,
}) {
  const dispatch = useDispatch();
  const preferenceData = useSelector(
    (state) => state.portfolioReviewGetActionable
  );
  const [nameSorted, setNameSorted] = useState(0);
  const [amountSorted, setAmountSorted] = useState(0);
  const rationalizeProducts = (productsArray) => {
    let temp = Array.isArray(productsArray)
      ? productsArray.map((product) => {
          return {
            ...product,
            proposed_market_value: product.proposed_market_value
              ? parseInt(product.proposed_market_value)
              : 0,
          };
        })
      : [];

    return temp;
  };
  const derationalizeProducts = (productsArray) => {
    let temp = Array.isArray(productsArray)
      ? productsArray.map((product) => {
          return {
            ...product,
            proposed_market_value: product.proposed_market_value,
          };
        })
      : [];

    return temp;
  };
  const [parsedData, setParsedData] = useState(
    rationalizeProducts(
      portfolioProducts.length === 0
        ? preferenceData.frontend_table
        : portfolioProducts
    )
  );
  const [parsedPortfolio, setParsedPortfolio] = useState(
    portfolio.length === 0
      ? [].concat(
          preferenceData.final_portfolio["Mutual Fund"],
          preferenceData.final_portfolio["Stocks"],
          preferenceData.final_portfolio["AIF"],
          preferenceData.final_portfolio["Bonds or MLDs"],
          preferenceData.final_portfolio["Gold or Related"],
          preferenceData.final_portfolio["Deposits"],
          preferenceData.final_portfolio["Real Estate"],
          preferenceData.final_portfolio["Unlisted Shares"],
          preferenceData.final_portfolio["Offshore"],
          preferenceData.final_portfolio["PMS"]
        )
      : portfolio
  );
  const [parsedActions, setParsedActions] = useState(
    portfolioActions.length === 0 ? preferenceData.actions : portfolioActions
  );
  useEffect(() => {
    if (preferenceData.actions.length !== 0 && portfolioActions.length === 0) {
      let tempArray = rationalizeProducts(
        preferenceData.actions.length !== 0
          ? preferenceData.frontend_table
          : portfolioProducts
      );
      let tempSum = 0;
      // let tempPortfolio = preferenceData.final_portfolio["Mutual Fund"];
      // let tempActions = preferenceData.actions;
      tempArray.forEach(
        (value) => (tempSum += parseInt(value.proposed_market_value))
      );
      tempSum = tempSum.toFixed(0);
      tempArray[0].proposed_market_value =
        parseInt(tempArray[0].proposed_market_value) +
        (portfolioValue - parseInt(tempSum) > 10
          ? portfolioValue - parseInt(tempSum)
          : 0);
      // if (portfolioValue - parseInt(tempSum)) {
      //   tempArray[0].rationale_type = "Action due to Multiple Reasons";
      //   tempArray[0].rationale = "";
      // }
      tempArray[0].proposed_market_value =
        tempArray[0].proposed_market_value.toFixed(0);
      setParsedData(tempArray);
      setParsedPortfolio(
        preferenceData.actions.length !== 0
          ? [].concat(
              preferenceData.final_portfolio["Mutual Fund"],
              preferenceData.final_portfolio["Stocks"],
              preferenceData.final_portfolio["AIF"],
              preferenceData.final_portfolio["Bonds or MLDs"],
              preferenceData.final_portfolio["Gold or Related"],
              preferenceData.final_portfolio["Deposits"],
              preferenceData.final_portfolio["Real Estate"],
              preferenceData.final_portfolio["Unlisted Shares"],
              preferenceData.final_portfolio["Offshore"],
              preferenceData.final_portfolio["PMS"]
            )
          : portfolio
      );
      setParsedActions(
        preferenceData.actions.length !== 0
          ? preferenceData.actions
          : portfolioActions
      );
      setPortfolioProducts(derationalizeProducts(tempArray));
      setPortfolio(
        preferenceData.actions.length !== 0
          ? [].concat(
              preferenceData.final_portfolio["Mutual Fund"],
              preferenceData.final_portfolio["Stocks"],
              preferenceData.final_portfolio["AIF"],
              preferenceData.final_portfolio["Bonds or MLDs"],
              preferenceData.final_portfolio["Gold or Related"],
              preferenceData.final_portfolio["Deposits"],
              preferenceData.final_portfolio["Real Estate"],
              preferenceData.final_portfolio["Unlisted Shares"],
              preferenceData.final_portfolio["Offshore"],
              preferenceData.final_portfolio["PMS"]
            )
          : portfolio
      );
      setPortfolioActions(
        preferenceData.actions.length !== 0
          ? preferenceData.actions
          : portfolioActions
      );
    } // eslint-disable-next-line
  }, [preferenceData]);
  const analytics = useSelector(
    (state) => state.portfolioReviewGetFinalAnalytics
  );
  const [section, setSection] = useState("Overview");
  const [afmodal, setAfmodal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  const clientsPerPage = 7;
  const pagesVisited = pageNumber * clientsPerPage;
  var portfolioSum = 0;
  const addedFundsMap = {};
  parsedData.forEach((value) => {
    addedFundsMap[value.product_id] = true;
    portfolioSum += value.proposed_market_value
      ? parseFloat(value.proposed_market_value)
      : 0;
  });
  //   const portfolioValue = formValue.proposal_value.amount;
  const sortedArray = (array) => {
    if (nameSorted)
      switch (nameSorted) {
        case 0:
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_class === asset);
        case 1:
          array.sort((a, b) => {
            const nameA = a.product_name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.product_name.toUpperCase(); // ignore upper and lowercase

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
            // names must be equal
          });
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_class === asset);
        case 2:
          array.sort((a, b) => {
            const nameA = a.product_name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.product_name.toUpperCase(); // ignore upper and lowercase

            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            return 0;
          });
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_class === asset);
        default:
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_class === asset);
      }
    else
      switch (amountSorted) {
        case 0:
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_class === asset);
        case 1:
          array.sort((a, b) => {
            const nameA = parseFloat(a.proposed_market_value); // ignore upper and lowercase
            const nameB = parseFloat(b.proposed_market_value); // ignore upper and lowercase

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
            // names must be equal
          });
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_class === asset);
        case 2:
          array.sort((a, b) => {
            const nameA = parseFloat(a.proposed_market_value); // ignore upper and lowercase
            const nameB = parseFloat(b.proposed_market_value); // ignore upper and lowercase

            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            return 0;
          });
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_class === asset);
        default:
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_class === asset);
      }
  };
  const [asset, setAsset] = useState("All");
  const assetSet = new Set(parsedData.map((product) => product.asset_class));
  const handleWeightChange = (e, id, type) => {
    // console.log("oldparsedData is", parsedData);
    // console.log("oldparsedActions is", parsedActions);
    // console.log("oldparsedPortfolio is", parsedPortfolio);
    const re = /^\d+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      let index = parsedData.findIndex((x) => x.product_id === id);
      let newProducts = [...parsedData];
      newProducts[index].proposed_market_value = e.target.value;
      newProducts[index].rationale_type = "Action due to Multiple Reasons";
      newProducts[index].rationale = "";
      let val = e.target.value.length ? parseInt(e.target.value) : 0;
      if (Math.abs(val - newProducts[index].current_market_value) < 10)
        newProducts[index].tag = "No Action";
      else if (val - newProducts[index].current_market_value > 10)
        newProducts[index].tag = "Buy";
      else if (val - newProducts[index].current_market_value < -10)
        newProducts[index].tag = "Sell";
      else newProducts[index].tag = "No Action";
      // newProducts[index].weight_change =
      //   (parseFloat(newProducts[index].proposed_weight).toFixed(1) -
      //     parseFloat(newProducts[index].current_weight * 100).toFixed(1)) /
      //   100;
      if (val === parseInt(newProducts[index].current_market_value))
        newProducts[index].list_type = 0;
      else newProducts[index].list_type = 2;
      setParsedData(newProducts);
      setPortfolioProducts(newProducts);
      let actionsindex = parsedActions.findIndex((x) => x.product_id === id);
      let newActions = [...parsedActions];

      if (
        actionsindex === -1 &&
        val - parseInt(newProducts[index].current_market_value)
      )
        newActions.push({
          product_id: id,
          pct:
            (val - parseFloat(newProducts[index].current_market_value)) /
            parseFloat(portfolioValue),
          rationale_type: "Action due to Multiple Reasons",
          rationale: "",
          product_type: type,
        });
      if (actionsindex !== -1) {
        if (val - parseInt(newProducts[index].current_market_value)) {
          newActions[actionsindex].pct =
            (val - parseFloat(newProducts[index].current_market_value)) /
            parseFloat(portfolioValue);
        } else newActions.splice(actionsindex, 1);
      }
      let newPortfolio = [...parsedPortfolio];
      let portfolioIndex = newPortfolio.findIndex(
        (x) => x && x.product_id === id
      );
      if (portfolioIndex === -1 && e.target.value)
        newPortfolio.push({
          product_id: id,
          individual_asset_pct: parseFloat(e.target.value) / portfolioValue,
          market_value: parseFloat(e.target.value),
          product_type: type,
        });
      if (portfolioIndex !== -1) {
        if (e.target.value) {
          newPortfolio[portfolioIndex].individual_asset_pct =
            parseFloat(e.target.value) / portfolioValue;
          newPortfolio[portfolioIndex].market_value = parseFloat(
            e.target.value
          );
        } else newPortfolio.splice(portfolioIndex, 1);
      }
      setParsedPortfolio(newPortfolio);
      setPortfolio(newPortfolio);
      setParsedActions(newActions);
      setPortfolioActions(newActions);

      // console.log("parsedData is", newProducts);
      // console.log("parsedActions is", newActions);
      // console.log("parsedPortfolio is", newPortfolio);
    }
  };
  var portfolioValue = useSelector(
    (state) => state.portfolioReviewGetActionable.portfolio_value
  );
  const displaydata = sortedArray(parsedData)
    .slice(pagesVisited, pagesVisited + clientsPerPage)
    .map((client, ind) => {
      return (
        <ActionableTableRow
          key={ind}
          client={client}
          handleWeightChange={handleWeightChange}
          parsedActions={parsedActions}
          setParsedActions={setParsedActions}
          parsedData={parsedData}
          setParsedData={setParsedData}
          setPortfolioActions={setPortfolioActions}
          setPortfolioProducts={setPortfolioProducts}
          derationalizeProducts={derationalizeProducts}
          portfolioValue={portfolioValue}
        />
      );
    });
  const pageCount = Math.ceil(parsedData.length / clientsPerPage);
  const changePage = (e, val) => {
    setPageNumber(val - 1);
  };
  // const handleCross = (id) => {
  //   delete addedFundsMap[id];
  //   let index = parsedData.findIndex((x) => x.product_id === id);
  //   let newParsedData = [...parsedData];
  //   newParsedData.splice(index, 1);
  //   setParsedData(newParsedData);
  // };
  const handleSections = () => {
    switch (section) {
      case "Overview":
        return (
          <OverviewSection
            analytics={analytics}
            body={{
              client_id: clientId,
              final_portfolio: parsedPortfolio.reduce((x, y) => {
                x && y && (x[y.product_type] = x[y.product_type] || []).push(y);
                return x;
              }, {}),
            }}
          />
        );

      case "Debt":
        return <DebtSection analytics={analytics} />;

      case "Equity":
        return <EquitySection analytics={analytics} />;
      case "Alternate":
        return <AlternateSection analytics={analytics} />;
      default:
        console.log(section);
    }
  };
  useEffect(() => {
    dispatch(
      PostEventLoggingData({
        module_name: "Portfolio Review",
        event_type: "Information",
        url: window.location.href,
        note: `Checked fourth screen ${section} Analytics`,
      })
    ); // eslint-disable-next-line
  }, [section]);

  const sortingIcon = (state) =>
    state === 0
      ? DisableSorted
      : state === 1
      ? AscendingSorted
      : DescendingSorted;

  const parsePortfolio = (portfolio) => {
    if (portfolio / 1000 < 100)
      return `₹ ${parseFloat(portfolio / 1000).toFixed(1)} K`;
    if (portfolio / 100000 < 100)
      return `₹ ${parseFloat(portfolio / 100000).toFixed(1)} L`;
    return `₹ ${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  };

  return (
    <div className="w-[100%] flex">
      <div className="px-[16px] flex justify-between w-full rounded-[8px] bg-white py-[16px] my-[4px] ">
        {preferenceData.loading || analytics.loading ? (
          <div className="client-dashboard-table w-full h-[335px] flex justify-center items-center opacity-[0.9]">
            <Bars color="#1982F8" />
          </div>
        ) : (
          <>
            <div className="w-[52.5%]">
              <div className="flex justify-between items-center border-b border-[#e0dddd] w-full pb-2">
                <h3 className=" font-inter text-[16px] font-medium leading-[20px] tracking-[0em] text-left pb-[0.3rem]">
                  Portfolio
                </h3>
                <div className="w-[25%]">
                  <Dropdown
                    options={["All", ...assetSet]}
                    selected={asset}
                    setSelected={setAsset}
                    labelName="Asset Class"
                  />
                </div>
              </div>
              <>
                <div
                  className={
                    Math.abs(portfolioValue - portfolioSum) > 10
                      ? "w-full mt-[1rem] bg-[#FEF1F1] p-[1rem] flex rounded-[6px]"
                      : "w-full mt-[1rem] bg-[#fafafa] p-[1rem] flex rounded-[6px]"
                  }
                >
                  <div className="w-[20%] text-center border-r border-r-dividerColor">
                    <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0">
                      {`${(
                        analytics.asset_allocation_after.Equity * 100
                      ).toFixed(1)}%`}
                    </h3>
                    <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                      Equity
                    </label>
                  </div>
                  <div className="w-[20%] text-center border-r border-r-dividerColor">
                    <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0">
                      {`${(analytics.asset_allocation_after.Debt * 100).toFixed(
                        1
                      )}%`}
                    </h3>
                    <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                      Debt
                    </label>
                  </div>
                  <div className="w-[20%] text-center border-r border-r-dividerColor">
                    <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0">
                      {`${(
                        analytics.asset_allocation_after.Alternate * 100
                      ).toFixed(1)}%`}
                    </h3>
                    <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                      Alternates
                    </label>
                  </div>
                  <div className="w-[20%] text-center border-r border-r-dividerColor">
                    <h3 className="font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#1982f8] mb-0">
                      {parsePortfolio(portfolioValue)}
                    </h3>
                    <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                      Value
                    </label>
                  </div>
                  <div className="w-[20%] text-center">
                    <h3
                      className="font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0"
                      style={
                        ((portfolioSum / portfolioValue) * 100).toFixed(1) !==
                        "100.0"
                          ? { color: "red" }
                          : { color: "#00B031" }
                      }
                    >
                      {((portfolioSum / portfolioValue) * 100).toFixed(1)}%
                    </h3>
                    <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                      Total
                    </label>
                  </div>
                </div>
                <div className="w-full flex items-center justify-between">
                  <p
                    className={
                      errorDiff > 0
                        ? "text-sm h-5 text-success-300 font-medium my-2 leading-[18px]"
                        : "text-sm h-5 text-error-200 font-medium my-2 leading-[18px]"
                    }
                  >
                    {errorDiff > 0
                      ? `Buy Rs. ${Math.abs(errorDiff).toLocaleString("en-IN")}`
                      : `Sell Rs. ${Math.abs(errorDiff).toLocaleString(
                          "en-IN"
                        )}`}
                  </p>
                  <div
                    className="flex cursor-pointer items-baseline"
                    onClick={() => {
                      setErrorDiff(
                        Math.abs(portfolioValue - portfolioSum) > 10
                          ? parseInt((portfolioValue - portfolioSum).toFixed(0))
                          : 0
                      );
                    }}
                  >
                    <img src={Reload} alt="err" className="w-[10px]" />
                    <p className=" font-inter text-[12px] font-medium tracking-[0em] text-left text-primary-200 mb-0 align-bottom ml-[0.2rem]">
                      Calculate difference
                    </p>
                  </div>
                </div>

                <div className="rounded-[6px] min-h-[680px]">
                  <table className=" w-[100%] h-full shadow-none border-none portfolio-stepper-fourth-form-table ">
                    <colgroup>
                      <col width="42%" />
                      <col width="29%" />
                      <col width="29%" />
                    </colgroup>
                    <tbody>
                      <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                        <th
                          style={{
                            textAlign: "left",
                            paddingLeft: "1rem",
                          }}
                          className=" font-inter text-[14px] font-medium leading-[20px] tracking-[0em] text-left py-[0.25rem] pr-0 pl-[1rem] flex items-end"
                        >
                          Scheme Name
                          <img
                            src={sortingIcon(nameSorted)}
                            onClick={() => {
                              setAmountSorted(0);
                              setNameSorted((nameSorted + 1) % 3);
                            }}
                            alt="err"
                            className="w-6 ml-2 cursor-pointer"
                          />
                        </th>
                        <th className=" font-inter text-[14px] font-medium leading-[20px] tracking-[0em] text-center py-[0.25rem] px-0">
                          Current Amount
                          <br /> (weight)
                        </th>
                        <th className=" font-inter text-[14px] font-medium leading-[20px] tracking-[0em] text-center py-[0.25rem] px-0">
                          <div className="flex justify-center">
                            Proposed Amount
                            <br /> (weight)
                            <img
                              src={sortingIcon(amountSorted)}
                              onClick={() => {
                                setNameSorted(0);
                                setAmountSorted((amountSorted + 1) % 3);
                              }}
                              alt="err"
                              className="w-6 ml-1 cursor-pointer"
                            />
                          </div>
                        </th>
                        {/* <th className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] text-center py-[0.25rem] px-0">
                          <div className="flex items-center justify-center">
                            Rationale
                            <Tooltip
                              TransitionComponent={Zoom}
                              placement="right"
                              title="Rationale tells you reason behind a suggested action for a particular product. You can also provide your input and edit the rationales. Please note that there will be no rationale for the products with no deviation as there is no action required."
                              arrow
                            >
                              <img
                                src={TooltipIcon}
                                alt="tooltip"
                                className="w-[12px] h-[12px] ml-1"
                              />
                            </Tooltip>
                          </div>
                        </th> */}
                      </tr>
                      {displaydata}
                    </tbody>
                  </table>
                </div>
                <div className="flex w-full justify-between mt-4">
                  <InvestedgeButton
                    onClick={() => {
                      dispatch(
                        PostEventLoggingData({
                          module_name: "Portfolio Review",
                          event_type: "Add button",
                          url: window.location.href,
                          note: `Add funds clicked`,
                        })
                      );
                      setAfmodal(true);
                    }}
                  >
                    <img
                      className="w-[14px] object-contain mr-[0.25rem]"
                      src={Add}
                      alt="err"
                    />
                    Add Products
                  </InvestedgeButton>
                  <AddFundsModal
                    onClose={() => setAfmodal(false)}
                    afmodal={afmodal}
                    parsedData={parsedData}
                    setParsedData={setParsedData}
                    parsedPortfolio={parsedPortfolio}
                    setParsedPortfolio={setParsedPortfolio}
                    parsedActions={parsedActions}
                    setParsedActions={setParsedActions}
                    derationalizeProducts={derationalizeProducts}
                    setPortfolioProducts={setPortfolioProducts}
                    setPortfolioActions={setPortfolioActions}
                    addedFundsMap={addedFundsMap}
                  />
                  <InvestedgePagination
                    count={pageCount}
                    defaultPage={1}
                    page={pageNumber + 1}
                    onChange={changePage}
                    color="primary"
                  />
                </div>
              </>
            </div>
            <div className="w-[45%] pt-4">
              <div className="w-[100%] flex justify-between border-b border-[#e0dddd]">
                <div className="flex">
                  <h3 className="font-inter text-[16px] font-medium leading-[20px] tracking-[0em] text-left pb-[0.3rem]">
                    Analytics
                  </h3>
                  <p
                    className={
                      section === "Overview"
                        ? "border-b border-primary-200 ml-[2rem] mr-[1rem] text-primary-200 font-inter text-[12px] font-medium leading-[31px] tracking-[0em] text-left cursor-pointer"
                        : "font-inter text-[12px] font-medium leading-[31px] tracking-[0em] text-left  ml-[2rem] mr-[1rem] cursor-pointer"
                    }
                    onClick={() => setSection("Overview")}
                  >
                    Overview
                  </p>
                  <p
                    className={
                      section === "Equity"
                        ? "border-b border-primary-200 ml-[2rem] mr-[1rem] text-primary-200 font-inter text-[12px] font-medium leading-[31px] tracking-[0em] text-left cursor-pointer "
                        : "font-inter text-[12px] font-medium leading-[31px] tracking-[0em] text-left  ml-[2rem] mr-[1rem] cursor-pointer"
                    }
                    onClick={() => setSection("Equity")}
                  >
                    Equity
                  </p>
                  <p
                    className={
                      section === "Debt"
                        ? "border-b border-primary-200 ml-[2rem] mr-[1rem] text-primary-200 font-inter text-[12px] font-medium leading-[31px] tracking-[0em] text-left cursor-pointer "
                        : "font-inter text-[12px] font-medium leading-[31px] tracking-[0em] text-left  ml-[2rem] mr-[1rem] cursor-pointer"
                    }
                    onClick={() => setSection("Debt")}
                  >
                    Debt
                  </p>
                  <p
                    className={
                      section === "Alternate"
                        ? "border-b border-primary-200 ml-[2rem] text-primary-200 font-inter text-[12px] font-medium leading-[31px] tracking-[0em] text-left  my-0 mr-[1rem] cursor-pointer"
                        : "ml-[2rem] font-inter text-[12px] font-medium leading-[31px] tracking-[0em] text-[#000] text-left my-0 mr-[1rem] cursor-pointer"
                    }
                    onClick={() => setSection("Alternate")}
                  >
                    Alternate
                  </p>
                </div>
                <div
                  className="flex cursor-pointer items-baseline mt-[0.5rem]"
                  onClick={() => {
                    dispatch(
                      GetReviewPortfolioAnalyticsFinal({
                        client_id: clientId,
                        final_portfolio: parsedPortfolio.reduce((x, y) => {
                          x &&
                            y &&
                            (x[y.product_type] = x[y.product_type] || []).push(
                              y
                            );
                          return x;
                        }, {}),
                      })
                    );
                    dispatch(
                      PostEventLoggingData({
                        module_name: "Portfolio Review",
                        event_type: "Refresh",
                        url: window.location.href,
                        note: `Refresh button pressed`,
                      })
                    );
                  }}
                >
                  <img src={Reload} alt="err" className="w-[10px]" />
                  <p className=" font-inter text-[12px] font-medium tracking-[0em] text-left text-primary-200 mb-0 align-bottom ml-[0.2rem]">
                    Simulate
                  </p>
                </div>
              </div>
              {handleSections(section)}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
