import React, { useState, useEffect } from "react";
import ProductUnderstandingRow from "./ProductUnderstandingRow";
import { useDispatch, useSelector } from "react-redux";
import {
  OpenSnackbar,
  PostEventLoggingData,
  UpdateProductUnderstandingData,
} from "../../../actions/actions";
import InvestedgeModal from "../../../components/modal/InvestedgeModal";
import { IoIosClose } from "react-icons/io";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
export default function ProductUnderstandingModal({
  familyMembers,
  setFamilyMembers2,
  selected,
  onClose,
  pumodal,
}) {
  const dispatch = useDispatch();
  const reduxData = useSelector((state) => state.clientInputData);
  const [tempfamilyMem, settempfamilyMem] = useState(
    JSON.parse(JSON.stringify(reduxData)).map((y) => y)
  );
  useEffect(() => {
    settempfamilyMem(JSON.parse(JSON.stringify(reduxData)).map((y) => y));
  }, [reduxData]);
  return (
    <InvestedgeModal
      aria-labelledby="Product Understanding modal"
      aria-describedby="Product Understanding modal"
      open={pumodal}
      handleclose={() => {
        settempfamilyMem(JSON.parse(JSON.stringify(reduxData)).map((y) => y));
        onClose();
      }}
    >
      <div className="flex justify-between items-center w-full">
        <h1 className="font-inter text-[21px] not-italic font-medium tracking-[0em] text-left text-[#000]">
          Product Understanding
        </h1>
        <IoIosClose
          size={30}
          onClick={() => {
            settempfamilyMem(
              JSON.parse(JSON.stringify(reduxData)).map((y) => y)
            );
            onClose();
          }}
        />
      </div>
      <p className="font-inter text-[12px] pt-[8px]">
        Rate your clients understanding on different products. Any number above
        2.5 will prioritize that product over other products in the client’s
        portfolio
      </p>
      <table className="w-full rounded-[6px] investedge-table">
        <colgroup>
          <col width="27.5%" />
          <col width="27.5%" />
          <col width="45%" />
        </colgroup>
        <tbody>
          <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
            <td />
            <td className="text-base text-center font-medium">
              Product Interest
            </td>
            <td className="text-center text-base font-medium">
              Product Understanding
            </td>
          </tr>
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="mf"
            label="Mutual Funds"
            selected={selected}
            slidervalue="mfvalue"
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="stocks"
            label="Stocks"
            selected={selected}
            slidervalue="mfvalue"
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="bonds"
            label="Bonds/MLDs"
            selected={selected}
            slidervalue="mfvalue"
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="pms"
            label="PMS"
            selected={selected}
            slidervalue="mfvalue"
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="aif"
            label="AIFs"
            selected={selected}
            slidervalue="mfvalue"
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="cash"
            label="Cash"
            selected={selected}
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="deposit"
            label="Deposits"
            selected={selected}
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="gold"
            label="Gold/Silver"
            selected={selected}
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="savingsschemes"
            label="Smallsaving schemes"
            selected={selected}
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="realestate"
            label="Real Estate"
            selected={selected}
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="epf"
            label="EPF/VPF"
            selected={selected}
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="ppf"
            label="PPFs"
            selected={selected}
          />
          <ProductUnderstandingRow
            familyMembers={tempfamilyMem}
            setFamilyMembers2={settempfamilyMem}
            name="unlisted"
            label="Unlisted Shares"
            selected={selected}
          />
        </tbody>{" "}
      </table>
      <div className="flex items-center justify-end w-full mt-[1rem]">
        <InvestedgeButton
          onClick={() => {
            let newFm = [...familyMembers];
            newFm[selected.optionid].understanding =
              tempfamilyMem[selected.optionid].understanding;
            setFamilyMembers2(newFm);
            dispatch(UpdateProductUnderstandingData(newFm));
            dispatch(
              PostEventLoggingData({
                module_name: "Client Details",
                event_type: "Save button",
                url: window.location.href,
                note: `Product understanding updated for ${
                  familyMembers[selected.optionid].name
                }`,
              })
            );
            dispatch(
              OpenSnackbar({
                severity: "success",
                message: `Product Understanding for ${
                  familyMembers[selected.optionid].name
                } updated`,
              })
            );
            onClose();
          }}
        >
          Save
        </InvestedgeButton>
      </div>
    </InvestedgeModal>
  );
}
