import React, { useEffect, useState } from "react";
import "../../../static/css/existing/dashboard_styles.css";
import Cross from "../../../assets/img/circleovercross.svg";
import PortfolioInputSidebar from "./PortfolioInputSidebar";
import PortfolioInputRouter from "./PortfolioInputRouter";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  GetDashboardPortfolioList,
  GetReviewPortfolioSummary,
} from "../../../actions/actions";
import AfterloginApp from "../../../rmdashboard/AfterloginApp";
import Clientsidebar from "../../ClientSidebar";
export default function UpdateInputScreen(props) {
  const { familyMembers } = (props.location && props.location.state) || {};
  const [route, setRoute] = useState("welcomescreen");
  const portfolioValue = useSelector(
    (store) => store.dashboardPortfolioPriceList.total_portfolio_value
  );
  const portfolioUpload = useSelector(
    (store) => store.postClientInputUploadFile
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      GetDashboardPortfolioList({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      GetReviewPortfolioSummary({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    ); // eslint-disable-next-line
  }, [portfolioUpload]);
  const parsePortfolio = (portfolio) => {
    if (portfolio / 1000 < 100)
      return `₹ ${parseFloat(portfolio / 1000).toFixed(1)} K`;
    if (portfolio / 100000 < 100)
      return `₹ ${parseFloat(portfolio / 100000).toFixed(1)} L`;
    return `₹ ${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  };
  return (
    <div
      className="parentLoggedinScreen"
      id="client-details-investment-details-screen"
    >
      <AfterloginApp
        title={"Client Input"}
        description={
          "Browse for portfolio insights, actionable, cash flow planning, and much more"
        }
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen flex flex-row justify-between">
          <Clientsidebar />
          <div className="client-input-parent-card p-[1rem] pt-[1rem] w-[78%] ml-[19%] h-full bg-[#fff] border  rounded-[0.5rem] ">
            <div className="modal-component">
              <div className="portfolio-input-screen-modal-content">
                <div className="portfolio-input-screen-modal-header">
                  <h2 className="text-[18px] font-inherit font-medium mb-0">
                    Total portfolio value: {parsePortfolio(portfolioValue)}
                  </h2>
                  <Link
                    to={{
                      pathname: "/clientportfolio",
                      state: {
                        familyMembers: familyMembers,
                      },
                    }}
                  >
                    <img
                      className="w-[20px] h-[20px] mr-[1rem]"
                      src={Cross}
                      alt="err"
                    />
                  </Link>
                </div>
                <div className="flex">
                  <PortfolioInputSidebar route={route} setRoute={setRoute} />
                  <div className="w-[81%] min-h-full p-[0.9rem]">
                    <PortfolioInputRouter route={route} setRoute={setRoute} />
                  </div>
                </div>
                {/* {route !== "welcomescreen" &&
                route !== "uploadstatementscreen" &&
                route !== "uploadexcelscreen" && (
                  <div className="flex w-full mt-[32px] pr-[32px] justify-end">
                    <button
                      style={{ backgroundColor: "#1982F8", color: "#fff" }}
                      className="font-semibold py-2 px-3 rounded box-shadowed-button"
                    >
                      Submit
                    </button>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
