import React from "react";
import "../../static/css/existing/dashboard_styles.css";
import InvestedgeTextField from "../../components/textfield/InvestedgeTextField";
export default function EditFieldAbout({ label, value, type }) {
  return (
    <div className="grid grid-cols-12 mt-[1.5rem]">
      <div className="col-span-4">
        <InvestedgeTextField
          variant="outlined"
          label={label}
          fullWidth
          className="font-inter font-normal mr-[0.5rem]"
          type={type}
          name={label}
          disabled
          value={value}
        />
      </div>
    </div>
  );
}
