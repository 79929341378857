import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useState } from "react";
import { Bars } from "react-loader-spinner";
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { GetMFCentralPayload } from "../actions/actions";
import InvestedgeButton from "../components/buttons/InvestedgeButton";
import TNCmodal from "../rmdashboard/acquire-clients/TNCmodal";

export default function MFCentralStatementOtpScreen({ setRoute }) {
  const [OTP, setOTP] = useState("");
  const handleChange = (otp) => {
    setOTP(otp);
  };
  const [modal, setModal] = useState(false);
  const [permissions, setPermissions] = useState(false);
  const dispatch = useDispatch();
  const otpapidata = useSelector((state) => state.mfCentralOtp);
  return !otpapidata.loading ? (
    <>
      <div className="flex flex-col items-center justify-center h-[90%]">
        <div className="font-inter text-[18px] text-neutralBlack mb-8 font-medium w-full flex justify-center">
          <p className="mx-auto">MF Central Statement</p>
        </div>

        <p className="font-inter text-[18px] text-neutralBlack mb-6 text-sm mx-auto">
          Please enter the OTP received on provided Mobile Number.
        </p>
        <div className="w-[80%] bg-primary-accent p-8 flex flex-col items-center justify-center mb-4">
          <p className="text-sm text-primary-200 font-medium mb-4">
            Enter OTP Below
          </p>
          <OTPInput
            onChange={handleChange}
            value={OTP}
            inputStyle="min-w-[40px] h-[40px] mt-4 border border-globalBorderColor"
            numInputs={6}
            separator={<span className="mx-1"></span>}
          />
        </div>
        <FormControlLabel
          control={
            <Checkbox
              checked={permissions}
              onChange={(e) => setPermissions(e.target.checked)}
            />
          }
          label={
            <span className="font-inter text-xs">
              I agree to{" "}
              <span
                className="text-primary-200 cursor-pointer hover:underline-offset-2"
                onClick={() => setModal(true)}
              >
                {" "}
                Term and Conditions{" "}
              </span>{" "}
              by submitting OTP
            </span>
          }
        />
      </div>
      <div className="flex w-[90%] justify-end">
        <InvestedgeButton
          onClick={() => {
            dispatch(
              GetMFCentralPayload({
                client_id: localStorage.getItem("clientId"),
                otp: OTP,
              })
            );
            setRoute("mfcentralresultscreen");
          }}
          disabled={!permissions || OTP.length !== 6}
        >
          Submit
        </InvestedgeButton>
      </div>
      <TNCmodal modal={modal} onClose={() => setModal(false)} />
    </>
  ) : (
    <div className="w-full h-[30px] flex flex-col justify-center items-center">
      <Bars color="#1982F8" />
    </div>
  );
}
