import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "swiper/css";
import "swiper/css/navigation";
import { GetResearch, PostEventLoggingData } from "../../../actions/actions";
// import InvestedgeTabs from "../../../components/tabs/InvestedgeTabs";
import InvestedgeTextField from "../../../components/textfield/InvestedgeTextField";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import "../../../static/css/existing/dashboard_styles.css";
import PublishModal from "../ResearchModal/PublishModal";
import ResearchSliderInput from "./ResearchSliderInput";
export default function AssetAllocationSection({
  changes,
  setChanges,
  setDisplayResearchData,
  setActiveStep,
  sliderData,
  setSliderData,
}) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.myresearchData);
  // const companies = useSelector((state) => state.fundhouseData)
  const [publishModal, setPublishModal] = useState(false);
  // const tabs = companies.map((company) => {
  //   return { label: company.research_entity };
  // });

  // const [companyValue, setCompany] = useState(null);
  let handleReset = () => {
    if (!changes) return;
    setChanges(false);
    dispatch(GetResearch());
    setSliderData({
      ...sliderData,
      equity_score: data[0].equity_score,
      debt_score: data[0].debt_score,
      alternate_score: data[0].alternate_score,
      largecap_score: data[0].largecap_score,
      midcap_score: data[0].midcap_score,
      small_cap_score: data[0].small_cap_score,
      credit_score: data[0].credit_score,
      duration_score: data[0].duration_score,
      alternate_volatility_score: data[0].alternate_volatility_score,
      equity_view: data[0].equity_view,
      comment: data[0].comment,
      alternate_view: data[0].alternate_view,
      debt_view: data[0].debt_view,
    });
  };
  // let handleCarousalClick = (event, newValue) => {
  //   setCompany(newValue);
  //   console.log(newValue);
  //   setChanges(true);
  //   let newsliderData = sliderData;
  //   newsliderData.equity_score = companies[newValue].equity_score;
  //   newsliderData.debt_score = companies[newValue].debt_score;
  //   newsliderData.alternate_score = companies[newValue].alternate_score;
  //   newsliderData.largecap_score = companies[newValue].largecap_score;
  //   newsliderData.midcap_score = companies[newValue].midcap_score;
  //   newsliderData.small_cap_score = companies[newValue].small_cap_score;
  //   newsliderData.credit_score = companies[newValue].credit_score;
  //   newsliderData.duration_score = companies[newValue].duration_score;
  //   newsliderData.alternate_volatility_score =
  //     companies[newValue].alternate_volatility_score;
  //   setSliderData(newsliderData);
  // };
  return (
    <>
      <div className="w-full">
        <h3 className="font-inter font-medium leading-[20px] text-neutralBlack mt-6">
          Your views on different asset classes
        </h3>
        <div className="z-[10000] w-full h-[30px] pt-[0.5rem]">
          <p className="font-inter text-[13px] not-italic font-normal leading-[20px] tracking-[0em] text-left text-neutralBlack">
            Input your asset allocation view here by rating each asset or
            sub-asset on a scale of -5 to 5.
          </p>
        </div>
        <div className="w-full flex justify-between">
          <fieldset className="border border-[#e0dddd] w-[49%] mt-[22px] overflow-y-auto rounded-[10px] p-[1rem] h-[270px]">
            <legend className="text-neutralBlack p-[5px] w-auto mb-0 font-inter text-sm not-italic font-medium leading-[16px] tracking-0 text-left">
              Asset Class View
            </legend>
            <ResearchSliderInput
              label="Equity"
              name="equity_score"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
            <ResearchSliderInput
              label="Debt"
              name="debt_score"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
            <ResearchSliderInput
              label="Alternates"
              name="alternate_score"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
          </fieldset>
          <InvestedgeTextField
            label="Asset Class View Input"
            className="w-[49%] p-[1rem] mt-8half"
            multiline
            rows={12}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
              },
            }}
            value={sliderData.comment}
            onChange={(e) => {
              setSliderData({
                ...sliderData,
                comment: e.target.value,
              });
              setChanges(true);
            }}
          />
        </div>

        <div className="w-full flex justify-between mt-4">
          <fieldset className="border border-[#e0dddd] w-[49%] overflow-y-auto rounded-[10px] p-[1rem] h-[270px]">
            <legend className="text-neutralBlack p-[5px] w-auto mb-[0] font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left">
              Equity View
            </legend>
            <ResearchSliderInput
              label="Large Cap"
              name="largecap_score"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
            <ResearchSliderInput
              label="Mid Cap"
              name="midcap_score"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
            <ResearchSliderInput
              label="Small Cap"
              name="small_cap_score"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
          </fieldset>
          <InvestedgeTextField
            label="Equity View Input"
            className="w-[49%] p-[1rem]  mt-3"
            multiline
            rows={12}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
              },
            }}
            value={sliderData.equity_view}
            onChange={(e) => {
              setSliderData({ ...sliderData, equity_view: e.target.value });
              setChanges(true);
            }}
          />
        </div>
        <div className="w-full flex justify-between mt-4">
          <fieldset className="border border-[#e0dddd] w-[49%] overflow-y-auto rounded-[10px] p-[1rem] h-[270px]">
            <legend className="text-neutralBlack p-[5px] w-auto mb-[0] font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left">
              Alternates View
            </legend>
            <ResearchSliderInput
              label="Volatility Preference"
              name="alternate_volatility_score"
              markHigh="HIGH VOL ALTERNATE"
              markLow="LOW VOL ALTERNATE"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
          </fieldset>
          <InvestedgeTextField
            label="Alternates View Input"
            className="w-[49%] p-[1rem]  mt-3"
            multiline
            rows={12}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
              },
            }}
            value={sliderData.alternate_view}
            onChange={(e) => {
              setSliderData({ ...sliderData, alternate_view: e.target.value });
              setChanges(true);
            }}
          />
        </div>
        <div className="w-full flex justify-between mt-4">
          <fieldset className="border border-[#e0dddd] w-[49%] overflow-y-auto rounded-[10px] p-[1rem] h-[270px]">
            <legend className="text-neutralBlack p-[5px] w-auto mb-[0] font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left">
              Debt View
            </legend>
            <ResearchSliderInput
              label="Duration Preference"
              name="duration_score"
              markHigh="HIGH DURATION"
              markLow="LOW DURATION"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
            <ResearchSliderInput
              label="Credit Risk Preference"
              name="credit_score"
              markHigh="HIGH RISK"
              markLow="LOW RISK"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
          </fieldset>
          <InvestedgeTextField
            label="Debt View Input"
            className="w-[49%] p-[1rem]  mt-3"
            multiline
            rows={12}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
              },
            }}
            value={sliderData.debt_view}
            onChange={(e) => {
              setSliderData({ ...sliderData, debt_view: e.target.value });
              setChanges(true);
            }}
          />
        </div>
      </div>
      {/* <div className="w-[48%]">
          <h3 className="font-inter text-[16px] not-italic font-medium leading-[20px] tracking-[0em] text-left text-neutralBlack">
            Select pre-defined views of famous fund houses
          </h3>
          <InvestedgeTabs
            tabs={tabs}
            tabvalue={companyValue}
            handletabchange={handleCarousalClick}
            variant="scrollable"
            TabIndicatorProps={{
              children: <span className="MuiTabs-indicatorSpan" />,
            }}
            scrollButtons
            allowScrollButtonsMobile
            sx={{
              marginTop: "4px",
              "& .MuiTabs-root": {
                minHeight: "40px",
              },
              "& .MuiTab-root": {
                padding: "8px",
                minHeight: "40px",
                fontSize: 13,
              },
              "& .MuiTabs-indicator": {
                display: "flex",
                justifyContent: "center",
                backgroundColor: "transparent",
                bottom: "auto",
              },
              "& .MuiTabs-indicatorSpan": {
                maxWidth: 60,
                width: "100%",
                backgroundColor: "#1982F8",
              },
            }}
            aria-label="research entities tab"
          />
        </div> */}

      <div className="flex flex-row gap-5 mt-5 w-full justify-end">
        <InvestedgeButton
          disabled={!changes}
          onClick={() => {
            dispatch(
              PostEventLoggingData({
                module_name: "Research Views",
                event_type: "Refresh",
                url: window.location.href,
                note: `Reseting Research views`,
              })
            );
            handleReset();
          }}
        >
          Reset
        </InvestedgeButton>

        <InvestedgeButton
          disabled={!changes}
          onClick={() => {
            dispatch(
              PostEventLoggingData({
                module_name: "Research Views",
                event_type: "Save",
                url: window.location.href,
                note: `Saving research views`,
              })
            );
            setPublishModal(true);
          }}
        >
          Save
        </InvestedgeButton>
      </div>
      <PublishModal
        saveType="asset"
        setChanges={setChanges}
        onClose={() => setPublishModal(false)}
        publishModal={publishModal}
        sliderData={sliderData}
        setDisplayResearchData={setDisplayResearchData}
      />
    </>
  );
}
