import React from "react";

const ProgressBar = ({ bg, progress, label }) => {
  const Parentdiv = {
    height: 6,
    width: "100%",
    backgroundColor: "#D4D4D4",
    borderRadius: 40,
    marginTop: "3px",
  };
  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    background: bg,
    borderRadius: 40,
  };

  return (
    <>
      <div className="w-full flex justify-between mt-[12px]">
        <p className="mb-0 font-inter text-xs">{label}</p>
        <p className="mb-0 font-inter text-sm">{`${progress}%`}</p>
      </div>
      <div style={Parentdiv}>
        <div style={Childdiv}></div>
      </div>
    </>
  );
};

export default ProgressBar;
