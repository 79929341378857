import { GET_ORG } from "../actions/type";
const initialState = {};
export default function GetOrg(state = initialState, action) {
  switch (action.type) {
    case GET_ORG:
      return action.payload;

    default:
      return state;
  }
}
