import React, { useEffect } from "react";
import Add from "../../assets/img/circle_over_plus_blue.svg";
import Cashrow from "./Cashrow";
import { useState } from "react";
import urls from "../../urls";
import { useDispatch, useSelector } from "react-redux";
import {
  GetDashboardCashPortfolio,
  GetDashboardPortfolioList,
  OpenSnackbar,
} from "../../actions/actions";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import { Bars } from "react-loader-spinner";
export default function CashScreen() {
  const data = useSelector((store) => store.dashboardCashPortfolio);
  const [productarray, setProductarray] = useState(
    JSON.parse(JSON.stringify(data)).map((y) => {
      return {
        ...y,
        last_amount_date: new Date(y.last_amount_date),
        current_amount_date: new Date(y.current_amount_date),
      };
    })
  );
  const [patchProductArray, setPatchProductArray] = useState([]);
  const [postProductArray, setPostProductArray] = useState([]);
  const portfolioList = useSelector(
    (store) => store.dashboardPortfolioPriceList
  );
  const [submitActive, setSubmitActive] = useState(false);
  const dispatch = useDispatch();
  const productsData = [
    "AB Bank Ltd.",
    "Abu Dhabi Commercial Bank Ltd.",
    "Airtel Payments Bank Limited",
    "American Express Banking Corporation",
    "Andhra Pradesh Grameena Vikas Bank",
    "Andhra Pragathi Grameena Bank",
    "Arunachal Pradesh Rural Bank",
    "Aryavart Bank",
    "Assam Gramin Vikash Bank",
    "Au Small Finance Bank Limited",
    "Australia and New Zealand Banking Group Ltd.",
    "Axis Bank Ltd.",
    "BNP Paribas",
    "Bandhan Bank Ltd.",
    "Bangiya Gramin Vikas Bank",
    "Bank of America",
    "Bank of Bahrain & Kuwait BSC",
    "Bank of Baroda",
    "Bank of Ceylon",
    "Bank of China",
    "Bank of India",
    "Bank of Maharashtra",
    "Bank of Nova Scotia",
    "Barclays Bank Plc.",
    "Baroda Gujarat Gramin Bank",
    "Baroda Rajasthan Kshetriya Gramin Bank",
    "Baroda UP Bank",
    "CSB Bank Ltd.",
    "CTBC Bank Co., Ltd.",
    "Canara Bank",
    "Capital Small Finance Bank Limited",
    "Central Bank of India",
    "Chaitanya Godavari Grameena Bank",
    "Chhattisgarh Rajya Gramin Bank",
    "Citibank N.A.",
    "City Union Bank Ltd.",
    "Cooperatieve Rabobank U.A.",
    "Credit Agricole Corporate & Investment Bank",
    "Credit Suisse A.G",
    "DBS Bank India Limited*",
    "DCB Bank Ltd.",
    "Dakshin Bihar Gramin Bank",
    "Deutsche Bank",
    "Dhanlaxmi Bank Ltd.",
    "Doha Bank Q.P.S.C",
    "ESAF Small Finance Bank Limited",
    "Ellaquai Dehati Bank",
    "Emirates Bank NBD",
    "Equitas Small Finance Bank Limited",
    "Federal Bank Ltd.",
    "Fincare Small Finance Bank Limited",
    "Fino Payments Bank Limited",
    "First Abu Dhabi Bank PJSC",
    "FirstRand Bank Ltd",
    "HDFC Bank Ltd",
    "HSBC Ltd",
    "Himachal Pradesh Gramin Bank",
    "ICICI Bank Ltd.",
    "IDBI Bank Ltd.",
    "IDFC First Bank Ltd.",
    "India Post Payments Bank Limited",
    "Indian Bank",
    "Indian Overseas Bank",
    "Induslnd Bank Ltd",
    "Industrial & Commercial Bank of China Ltd.",
    "Industrial Bank of Korea",
    "J&K Grameen Bank",
    "J.P. Morgan Chase Bank N.A.",
    "JSC VTB Bank",
    "Jammu & Kashmir Bank Ltd.",
    "Jana Small Finance Bank Limited",
    "Jharkhand Rajya Gramin Bank",
    "KEB Hana Bank",
    "Karnataka Bank Ltd.",
    "Karnataka Gramin Bank",
    "Karnataka Vikas Grameena Bank",
    "Karur Vysya Bank Ltd.",
    "Kerala Gramin Bank",
    "Kookmin Bank",
    "Kotak Mahindra Bank Ltd",
    "Krung Thai Bank Public Co. Ltd.",
    "MUFG Bank, Ltd.",
    "Madhya Pradesh Gramin Bank",
    "Madhyanchal Gramin Bank",
    "Maharashtra Gramin Bank",
    "Manipur Rural Bank",
    "Mashreq Bank PSC",
    "Meghalaya Rural Bank",
    "Mizoram Rural Bank",
    "Mizuho  Bank Ltd.",
    "Nagaland Rural Bank",
    "Nainital Bank Ltd.",
    "NatWest Markets Plc",
    "North East Small Finance Bank Limited",
    "Odisha Gramya Bank",
    "Other",
    "PT Bank Maybank Indonesia TBK",
    "Paschim Banga Gramin Bank",
    "Paytm Payments Bank Limited",
    "Prathama UP Gramin Bank",
    "Puduvai Bharathiar Grama Bank",
    "Punjab & Sind Bank",
    "Punjab Gramin Bank",
    "Punjab National Bank",
    "Qatar National Bank (Q.P.S.C.)",
    "RBL Bank Ltd.",
    "Rajasthan Marudhara Gramin Bank",
    "SBM Bank (India) Limited*",
    "Saptagiri Grameena Bank",
    "Sarva Haryana Gramin Bank",
    "Saurashtra Gramin Bank",
    "Sberbank",
    "Shinhan Bank",
    "Shivalik Small Finance Bank Limited",
    "Societe Generale",
    "Sonali Bank Ltd.",
    "South Indian Bank Ltd.",
    "Standard Chartered Bank",
    "State Bank of India",
    "Sumitomo Mitsui Banking Corporation",
    "Suryoday Small Finance Bank Limited",
    "Tamil Nadu Grama Bank",
    "Tamilnad Mercantile Bank Ltd.",
    "Telangana Grameena Bank",
    "Tripura Gramin Bank",
    "UCO Bank",
    "Ujjivan Small Finance Bank Limited",
    "Union Bank of India",
    "United Overseas Bank Ltd",
    "Unity Small Finance Bank Limited",
    "Utkal Grameen bank",
    "Utkarsh Small Finance Bank Limited",
    "Uttar Bihar Gramin Bank",
    "Uttarakhand Gramin Bank",
    "Uttarbanga Kshetriya Gramin Bank",
    "Vidharbha Konkan Gramin Bank",
    "Woori Bank",
    "YES Bank Ltd.",
  ];
  const removeProductarray = (i) => {
    let newAifarray = [...productarray];
    newAifarray.splice(i, 1);
    setProductarray(newAifarray);
  };
  // const portfolioValue = useSelector(
  //   (store) => store.portfolioReviewGetSumary.portfolio_value
  // );
  // const parsePortfolio = (portfolio) => {
  //   if (portfolio / 1000 < 100)
  //     return `₹ ${parseFloat(portfolio / 1000).toFixed(1)} K`;
  //   if (portfolio / 100000 < 100)
  //     return `₹ ${parseFloat(portfolio / 100000).toFixed(1)} L`;
  //   return `₹ ${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  // };
  const [searchBar, setSearchBar] = useState(false);
  const [addedProduct, setAddedProduct] = useState("");
  const [deleteArray, setDeleteArray] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    dispatch(
      GetDashboardCashPortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setProductarray(data);
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    if (
      deleteArray.length ||
      postProductArray.length ||
      patchProductArray.length
    )
      setSubmitActive(true);
    else setSubmitActive(false);
  }, [deleteArray, postProductArray, patchProductArray]);
  const numberOfDays = (date1, date2) => {
    // To calculate the time difference of two dates
    var difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    var difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
    return difference_In_Days;
  };
  const handleSubmit = async () => {
    setloading(true);
    let newPatchList = patchProductArray.map((item) => {
      var dd = String(item.last_amount_date.getDate()).padStart(2, "0");
      var mm = String(item.last_amount_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.last_amount_date.getFullYear();
      var dd1 = String(item.current_amount_date.getDate()).padStart(2, "0");
      var mm1 = String(item.current_amount_date.getMonth() + 1).padStart(
        2,
        "0"
      );
      var yyyy1 = item.current_amount_date.getFullYear();
      return {
        id: item.id,
        name: item.name,
        last_amount: parseFloat(item.last_amount),
        last_amount_date: yyyy + "-" + mm + "-" + dd,
        bank: item.bank,
        interest_rate: parseFloat(item.interest_rate),
        current_amount: parseFloat(
          parseInt(item.last_amount) *
            (1 + parseFloat(item.interest_rate / 100)) **
              (numberOfDays(
                new Date(item.current_amount_date),
                new Date(item.last_amount_date)
              ) /
                365)
        ),
        current_amount_date: yyyy1 + "-" + mm1 + "-" + dd1,
        user_id: localStorage.getItem("clientId"),
      };
    });
    let newPostList = postProductArray.map((item) => {
      var dd = String(item.last_amount_date.getDate()).padStart(2, "0");
      var mm = String(item.last_amount_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.last_amount_date.getFullYear();
      var dd1 = String(item.current_amount_date.getDate()).padStart(2, "0");
      var mm1 = String(item.current_amount_date.getMonth() + 1).padStart(
        2,
        "0"
      );
      var yyyy1 = item.current_amount_date.getFullYear();
      return {
        name: item.name,
        last_amount: parseFloat(item.last_amount),
        last_amount_date: yyyy + "-" + mm + "-" + dd,
        bank: item.bank,
        interest_rate: parseFloat(item.interest_rate),
        current_amount: parseFloat(
          parseInt(item.last_amount) *
            (1 + parseFloat(item.interest_rate / 100)) **
              (numberOfDays(
                new Date(item.current_amount_date),
                new Date(item.last_amount_date)
              ) /
                365)
        ),
        current_amount_date: yyyy1 + "-" + mm1 + "-" + dd1,
        user_id: localStorage.getItem("clientId"),
      };
    });
    let newDeleteList = deleteArray.map((item) => {
      var dd = String(item.last_amount_date.getDate()).padStart(2, "0");
      var mm = String(item.last_amount_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.last_amount_date.getFullYear();
      var dd1 = String(item.current_amount_date.getDate()).padStart(2, "0");
      var mm1 = String(item.current_amount_date.getMonth() + 1).padStart(
        2,
        "0"
      );
      var yyyy1 = item.current_amount_date.getFullYear();
      return {
        id: item.id,
        name: item.name,
        last_amount: parseFloat(item.last_amount),
        last_amount_date: yyyy + "-" + mm + "-" + dd,
        bank: item.bank,
        interest_rate: parseFloat(item.interest_rate),
        current_amount: parseFloat(
          parseInt(item.last_amount) *
            (1 + parseFloat(item.interest_rate / 100)) **
              (numberOfDays(
                new Date(item.current_amount_date),
                new Date(item.last_amount_date)
              ) /
                365)
        ),
        current_amount_date: yyyy1 + "-" + mm1 + "-" + dd1,
        user_id: localStorage.getItem("clientId"),
      };
    });
    newDeleteList.length &&
      (await axios.post(urls.dashboardCashPortfolioDeleteURL, newDeleteList));
    newPostList.length &&
      (await axios.post(urls.dashboardCashPortfolioURL, newPostList));
    newPatchList.length &&
      (await axios.patch(urls.dashboardCashPortfolioURL, newPatchList));
    setPatchProductArray([]);
    setPostProductArray([]);
    setDeleteArray([]);
    dispatch(
      GetDashboardPortfolioList({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      GetDashboardCashPortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      OpenSnackbar({
        severity: "success",
        message: "Portfolio Updated Successfully",
      })
    );
    setloading(false);
  };
  let emptyFields = false;
  for (let i = 0; i < productarray.length; i++)
    if (!productarray[i].name) emptyFields = true;

  return (
    <div className="w-full">
      <div className="font-medium font-inter text-[18px] text-neutralBlack">
        Cash - {portfolioList.total_cash_or_saving_ac_value}
      </div>
      {!loading ? (
        <>
          <div className="flex justify-between w-[96%] mt-[4px] mb-0">
            <div className="flex justify-center items-center h-[56px] cursor-pointer">
              <img src={Add} alt="err" />
              <p
                className="text-primary-200 text-[14px] font-medium mb-0 ml-[0.3rem]"
                onClick={
                  () => setSearchBar(true)
                  // let newAifarray = [...productarray];
                  // newAifarray.unshift({ name: "New Mutual Fund", price: "0" });
                  // setProductarray(newAifarray);
                }
              >
                Add Cash
              </p>
              {searchBar && (
                <FormControl
                  size="small"
                  sx={{
                    width: "200px",
                    marginLeft: "1rem",
                    "& .MuiInputBase-inputSizeSmall": {
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    },
                  }}
                >
                  <InputLabel>
                    <span className="font-inter font-normal text-sm">
                      Choose Bank
                    </span>
                  </InputLabel>
                  <Select
                    label="Choose Bank"
                    defaultValue=""
                    onChange={(e) => {
                      setSearchBar(false);
                      const rnd = Math.random();
                      setAddedProduct(rnd);
                      let newAifarray = [...productarray];
                      newAifarray.unshift({
                        name: "",
                        id: rnd,
                        last_amount: parseFloat(0),
                        last_amount_date: new Date(),
                        bank: e.target.value,
                        interest_rate: parseFloat(0),
                        current_amount: parseFloat(0),
                        current_amount_date: new Date(),
                        user_id: localStorage.getItem("clientId"),
                      });
                      setPostProductArray([
                        ...postProductArray,
                        {
                          name: "",
                          id: rnd,
                          last_amount: parseFloat(0),
                          last_amount_date: new Date(),
                          bank: e.target.value,
                          interest_rate: parseFloat(0),
                          current_amount: parseFloat(0),
                          current_amount_date: new Date(),
                          user_id: localStorage.getItem("clientId"),
                        },
                      ]);
                      setProductarray(newAifarray);
                    }}
                    className="font-inter text-sm"
                  >
                    {productsData.map((option, ind) => (
                      <MenuItem value={option} id={ind} key={ind}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
            {/* <p className="asset-handler-text" onClick={() => setProductarray([])}>
          Clear All
        </p> */}
          </div>
          <div className="update-portfolio-asset-row-container  h-[63vh]">
            <table className="w-full">
              <tbody className="w-full">
                {productarray.map((fund, index) => (
                  <Cashrow
                    key={fund.id}
                    fund={fund}
                    id={index}
                    removeProductarray={removeProductarray}
                    productarray={productarray}
                    setProductarray={setProductarray}
                    patchProductArray={patchProductArray}
                    setPatchProductArray={setPatchProductArray}
                    deleteArray={deleteArray}
                    setDeleteArray={setDeleteArray}
                    data={data}
                    postProductArray={postProductArray}
                    setPostProductArray={setPostProductArray}
                    addedProduct={addedProduct}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="flex flex-col justify-center items-center w-[96%] h-[62vh]">
          <Bars color="#1982F8" />
          <p className=" font-inter text-[14px] font-medium text-neutralBlack mb-[1rem] mt-[1rem]">
            Updating Portfolio ...
          </p>
        </div>
      )}
      <div className="flex w-full mt-[24px] pr-[24px] justify-end">
        <InvestedgeButton
          disabled={!submitActive || emptyFields}
          onClick={() => {
            if (
              patchProductArray.length ||
              postProductArray.length ||
              deleteArray.length
            ) {
              setSubmitActive(false);
              handleSubmit();
            }
          }}
        >
          Submit
        </InvestedgeButton>
      </div>
    </div>
  );
}
