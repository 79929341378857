import {
  PATCH_MODEL_PORTFOLIO_PRODUCTS,
  DELETE_MODEL_PORTFOLIO_PRODUCTS,
  POST_MODEL_PORTFOLIO_PRODUCTS,
} from "../actions/type";
const initialState = {};
export default function updatedModelPortfolioProducts(
  state = initialState,
  action
) {
  switch (action.type) {
    case PATCH_MODEL_PORTFOLIO_PRODUCTS:
      return action.payload;
    case DELETE_MODEL_PORTFOLIO_PRODUCTS:
      return action.payload;
    case POST_MODEL_PORTFOLIO_PRODUCTS:
      return action.payload;
    default:
      return state;
  }
}
