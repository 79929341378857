import React from "react";
// import { usePopper } from "react-popper";
import DefaultProfilePic from "../../../assets/img/defaultprofilepic.png";
export default function ClientCard({
  client,
  setClientID,
  clientID,
  setClientName,
}) {
  // const [isActive, setisActive] = useState(false);
  // const [referenceRef, setReferenceRef] = useState(null);
  // const [popperRef, setPopperRef] = useState(null);
  // const { styles, attributes } = usePopper(referenceRef, popperRef, {
  //   placement: "bottom-end",
  //   modifiers: [
  //     {
  //       name: "offset",
  //       enabled: true,
  //     },
  //     {
  //       name: "flip",
  //       options: {
  //         fallbackPlacements: ["bottom", "bottom"],
  //       },
  //     },
  //   ],
  // });
  return (
    <div
      className={`transition-transform w-full bg-gradient-180 from-[#fafafa] to-[#f6f6f6] rounded-[4px] p-[1rem] min-h-[155px] ml-[3.7%] mt-[1.5%] z-0 shadow-none m-0 hover:translate-y-[-8px] duration-300 cursor-pointer bg-[#fafafa] ${
        clientID === client.client_id ? "border-primary-200 border" : ""
      }`}
      onClick={() => {
        setClientID(client.client_id);
        setClientName(client.client_info.username);
      }}
    >
      <div className="flex justify-end items-start">
        <div className="flex flex-col justify-center items-center w-[97%]">
          <img
            className="h-[60px] w-[60px] rounded-[100%]"
            src={
              client.client_info.profile_pic
                ? client.client_info.profile_pic
                : DefaultProfilePic
            }
            alt="err"
          />
          <p className=" font-inter w-full text-[16px] not-italic leading-[19px] mb-0 tracking-[0em] text-center text-neutralBlack mt-[1rem]">
            {client.client_info.username}
          </p>
          <p className=" font-inter text-[12px] not-italic leading-[12px] mb-0 tracking-[0.015em] text-center text-primary-200 mt-[0.5rem] bg-primary-accent py-[0.25rem] px-[0.5rem] rounded-[2px]">
            {client.client_type === "F" ? "Family" : "Individual"}
          </p>
        </div>
      </div>
    </div>
  );
}
