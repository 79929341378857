export const KeyMetrics = () => {
  return (
    <div className=" w-[72%] pt-[60px]">
      <h3 className=" card-heading mb-6">Key Metrics </h3>
      <div className="flex justify-between">
        <div>
          <p className="font-inter font-medium text-[24px] leading-[29px] text-left tracking-[-3%] align-top mb-[0.4rem]">
            1.50
          </p>
          <span className="font-inter flex-wrap  text-[14px] leading-[17px] text-center tracking-[-3%] align-top text-[#6A6A76]">
            Regular Expense <br /> Ratio
          </span>
        </div>
        <div>
          <p className="font-inter font-medium text-[24px] leading-[29px] text-left tracking-[-3%] align-top mb-[0.4rem]">
            0.50
          </p>
          <span className="font-inter  text-[14px] leading-[17px] text-center tracking-[-3%] align-top text-[#6A6A76]">
            Direct Expense <br /> Ratio
          </span>
        </div>{" "}
        <div>
          <p className="font-inter font-medium text-[24px] leading-[29px] text-left tracking-[-3%] align-top mb-[0.4rem]">
            1.2%
          </p>
          <span className="font-inter  text-[14px] leading-[17px] text-center tracking-[-3%] align-top text-[#6A6A76]">
            Exit Load
          </span>
        </div>
        <div>
          <p className="font-inter font-medium text-[24px] leading-[29px] text-left tracking-[-3%] align-top mb-[0.4rem]">
            12%
          </p>
          <span className="font-inter  text-[14px] leading-[17px] text-center tracking-[-3%] align-top text-[#6A6A76]">
            Commision
          </span>
        </div>
        <div>
          <p className="font-inter font-medium text-[24px] leading-[29px] text-left tracking-[-3%] align-top mb-[0.4rem]">
            ₹ 500
          </p>
          <span className="font-inter  text-[14px] leading-[17px] text-center tracking-[-3%] align-top text-[#6A6A76]">
            Min. Lumsum <br /> Amount
          </span>
        </div>
        <div>
          <p className="font-inter font-medium text-[24px] leading-[29px] text-left tracking-[-3%] align-top mb-[0.4rem]">
            S&P BSE 100 - TRI
          </p>
          <span className="font-inter  text-[14px] leading-[17px] text-center tracking-[-3%] align-top text-[#6A6A76]">
            Benchmark
          </span>
        </div>
      </div>
    </div>
  );
};
