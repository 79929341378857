import React from "react";
import PieChart from "../../../components/charts/PieChart";
import HorizontalBarChart from "../../../components/charts/HorizontalBarChart";
import "../../../static/css/existing/dashboard_styles.css";

import { portfolio } from "../onePagerConsts";

export default function portfolioComponent() {
  const options = {
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
    elements: {
      bar: {
        borderWidth: 3,
      },
    },
    responsive: true,
    Plugin: {
      legend: {
        display: false,
      },
    },
  };
  const data = {
    labels: [
      "Financials",
      "Consumer Discretionary",
      "Materials",
      "Health Care",
      "IT",
      "Industrials",
      "Telecommunication",
      "Others",
      "Cash",
    ],
    datasets: [
      {
        label: "Sector Allocation",
        data: [32.13, 30.13, 29.13, 27.13, 18.13, 15.13, 10.13, 8.13, 8.0],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          marginTop: "1rem",
          justifyContent: "space-between",
        }}
      >
        {portfolio.data.map((items) => (
          <div
            style={{
              padding: "1rem",
              backgroundColor: "#fff",
              borderRadius: "6px",
              boxShadow:
                " 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(0, 0, 0, 0.05)",
              minheight: "274px",
              width: "49.2%",
            }}
          >
            <h5 className="onepager-small-heading mb-4">{items["title"]}</h5>
            <p className="onepager-description-text mt-1">{items["text"]}</p>
            <p
              className="mb-2 onepager-description-text"
              style={{ color: "#0000F5" }}
            >
              Learn more
            </p>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "1rem",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            padding: "1rem",
            backgroundColor: "#fff",
            borderRadius: "6px",
            boxShadow:
              " 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(0, 0, 0, 0.05)",
            minheight: "274px",
            width: "49.2%",
          }}
        >
          <h5 className="onepager-small-heading mb-4">Market Capitalization</h5>
          <PieChart />
        </div>

        <div
          style={{
            padding: "1rem",
            backgroundColor: "#fff",
            borderRadius: "6px",
            boxShadow:
              " 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(0, 0, 0, 0.05)",
            minheight: "274px",
            width: "49.2%",
          }}
        >
          <h5 className="onepager-small-heading mb-4">Sector Allocation</h5>
          <HorizontalBarChart data={data} options={options} />
        </div>
      </div>
    </>
  );
}
