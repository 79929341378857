import React from "react";
import { PortfolioInputSidebarData } from "./portfolioInputSidebarData";
import Lock from "../../../assets/img/lock_locked_blue.svg";
// import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OpenSnackbar } from "../../../actions/actions";
export default function PortfolioInputSidebar({ route, setRoute }) {
  // const location = useLocation();
  const portfolioList = useSelector(
    (store) => store.dashboardPortfolioPriceList
  );
  const dispatch = useDispatch();
  return (
    <>
      <nav id="portfolio-input-sidenavbar">
        <div
          onClick={() => setRoute("welcomescreen")}
          className=" primary-button w-[95%] shadow-none py-[0.5rem] px-0 font-medium box-shadowed-button"
        >
          Upload
        </div>
        {PortfolioInputSidebarData.map((item, index) => {
          return (
            <div
              onClick={() => {
                !item.locked
                  ? setRoute(item.path)
                  : dispatch(
                      OpenSnackbar({
                        severity: "info",
                        message: "Feature will be coming soon",
                      })
                    );
              }}
              className=" flex text-[#000] justify-between items-center font-inter p-[0.5rem] list-none pb-[0rem] w-full cursor-pointer no-underline text-[16px] font-medium mt-[0.2rem] duration-[250ms]"
              key={index}
            >
              <div
                className={
                  route === item.path ? "border-b-[2px] border-primary-200" : ""
                }
              >
                {item.title}
              </div>
              {item.locked ? (
                <img src={Lock} alt="err" />
              ) : (
                portfolioList[item.value]
              )}
            </div>
          );
        })}
      </nav>
    </>
  );
}
