import React from "react";
import AfterloginApp from "../../AfterloginApp";
import { useState } from "react";
import CalculatorBanner from "../CalculatorBanner";
import BannerImage from "../../../assets/img/retirement_planner_banner.svg";
import BarsChart from "../../../components/charts/BarsChart";
import urls from "../../../urls";
import axios from "axios";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import { InputAdornment, OutlinedInput } from "@mui/material";
import InvestedgeTextField from "../../../components/textfield/InvestedgeTextField";
import DropdownForAttributes from "../../../components/Dropdowns/DropdownForAttributes";
export default function RetirementPlanner() {
  const [planningData, setPlanningData] = useState({
    currentAge: "30",
    retirementAge: "60",
    lifeExpectancy: "70",
    currentMonthlyExpenses: "5",
    currentMonthlyExpensesDenotion: "₹ L",
    currentInvestments: "5",
    currentInvestmentsDenotion: "₹ L",
    inflation: "3",
    returnsBeforeRetirement: "8",
    returnsAfterRetirement: "8",
  });
  const [backendData, setBackendData] = useState({
    future_value_of_current_investments: 36445241.84,
    amount_required_at_retirement: 215640187.04,
    monthly_investment_required: 16953.4,
    graph: {
      date: "2068-02-21",
      values: {
        plus_ten_years: 6217.82,
        plus_twenty_years: 46808.25,
      },
    },
  });
  const data = {
    backgroundColor: "#fafafa",
    labels: [
      `Life Expectancy : ${planningData.lifeExpectancy} yrs`,
      `Life Expectancy : ${parseInt(planningData.lifeExpectancy) + 10} yrs`,
      `Life Expectancy : ${parseInt(planningData.lifeExpectancy) + 20} yrs`,
    ],
    datasets: [
      {
        axis: "y",
        data: [
          backendData.monthly_investment_required,
          backendData.graph.values.plus_ten_years,
          backendData.graph.values.plus_twenty_years,
        ],
        backgroundColor: ["#1982F8", "#156DD0", "#1982F8"],
        borderColor: ["#1982F8", "#156DD0", "#1982F8"],
        borderWidth: 1,
        barThickness: 40,
        borderRadius: 300,
      },
    ],
  };
  const options = {
    cornerRadius: 20,
    legend: {
      display: false,
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 30,
        bottom: 0,
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            beginAtZero: true,
            callback: (val) => val.toLocaleString("en-IN"),
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
    },
    plugins: {
      labels: {
        // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
        render: (args) =>
          parseInt(args.value.toFixed(0)).toLocaleString("en-IN"),
        fontColor: "#000",
        position: "default",
        outsidePadding: 14,
        textMargin: 3,
      },
      datalabels: {
        display: false,
      },
    },
  };
  const denotionParser = (denotion, value) => {
    if (denotion[2] === "K") return parseInt(value) * 1000;
    else if (denotion[2] === "L") return parseInt(value) * 100000;
    else return value * 10000000;
  };
  const numToMonthMap = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };

  const handleCalculate = () => {
    axios
      .post(urls.retirementPlannerURL, {
        current_age: parseInt(planningData.currentAge),
        retirement_age: parseInt(planningData.retirementAge),
        life_expectancy: parseInt(planningData.lifeExpectancy),
        current_monthly_expenses: denotionParser(
          planningData.currentMonthlyExpensesDenotion,
          planningData.currentMonthlyExpenses
        ),
        current_investments_setaside: denotionParser(
          planningData.currentInvestmentsDenotion,
          planningData.currentInvestments
        ),
        inflation: parseFloat(planningData.inflation / 100),
        return_before_retirement: parseFloat(
          planningData.returnsBeforeRetirement / 100
        ),
        return_after_retirement: parseFloat(
          planningData.returnsAfterRetirement / 100
        ),
      })
      .then((response) => setBackendData(response.data))
      .catch(() =>
        setBackendData({
          amount_needed_for_requirement: 0,
          amount_needed_for_requirement_date: "12 2092",
          value_of_current_investments: 0,
          value_of_current_investments_date: "12 2067",
          monthly_investment_required: 0,
          monthly_investment_required_date: "12 2092",
          graph: {
            dates: [],
            value1: {
              label: "Total Amount Invested",
              values: [],
            },
            value2: {
              label: "Expected Investment Value",
              values: [],
            },
          },
        })
      );
  };
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Product Input"}
        description={
          "Change the view on different products across asset class, product type, and categories"
        }
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className="main-panel h-full">
            <div>
              <div className="h-[140px]">
                <CalculatorBanner
                  heading={"Retirement Planner"}
                  description={
                    "Retirement planning is important as we would need money to manage our lifestyle post-retirement. Use this planner to estimate various scenarios that your clients want to evaluate for thier retirement life."
                  }
                  image={BannerImage}
                />
              </div>
              <div className="portfolio-proposal-body-card min-h-[74%] flex justify-between mt-[1rem] border-none shadow-none">
                <div className="w-[49%] ">
                  <div className="w-full flex justify-between items-center mt-[0.5rem]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">Current Age</p>
                    </div>
                    <OutlinedInput
                      size="small"
                      className="text-sm"
                      required
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "17.4%",
                      }}
                      value={planningData.currentAge}
                      onChange={(e) => {
                        const re = /^\d*[0-9]+\d*$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setPlanningData({
                            ...planningData,
                            currentAge: e.target.value,
                          });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <span className="text-sm font-inter">yrs</span>
                        </InputAdornment>
                      }
                    />
                  </div>
                  <div className="w-full flex justify-between items-center mt-[20px]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">Retirement Age</p>
                    </div>
                    <OutlinedInput
                      size="small"
                      className="text-sm"
                      required
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "17.4%",
                      }}
                      value={planningData.retirementAge}
                      onChange={(e) => {
                        const re = /^\d*[0-9]+\d*$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setPlanningData({
                            ...planningData,
                            retirementAge: e.target.value,
                          });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <span className="text-sm font-inter">yrs</span>
                        </InputAdornment>
                      }
                    />
                  </div>
                  <div className="w-full flex justify-between items-center mt-[20px]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">Life Expectancy</p>
                    </div>
                    <OutlinedInput
                      size="small"
                      className="text-sm"
                      required
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "17.4%",
                      }}
                      value={planningData.lifeExpectancy}
                      onChange={(e) => {
                        const re = /^\d*[0-9]+\d*$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setPlanningData({
                            ...planningData,
                            lifeExpectancy: e.target.value,
                          });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <span className="text-sm font-inter">yrs</span>
                        </InputAdornment>
                      }
                    />
                  </div>
                  <div className="w-full flex justify-between items-center mt-[20px]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">
                        Current Monthly Expenses (relevant post retirement as
                        well)
                      </p>
                    </div>
                    <div className="w-[30%] flex justify-between">
                      <div className="w-[40%]">
                        <DropdownForAttributes
                          labelName={null}
                          options={["₹ L", "₹ K", "₹ C"]}
                          selected={planningData}
                          setSelected={setPlanningData}
                          attribute="currentMonthlyExpensesDenotion"
                        />
                      </div>
                      <div className="w-[58%]">
                        <InvestedgeTextField
                          variant="outlined"
                          fullWidth
                          style={{
                            fontFamily: "Inter,sans-sarif",
                            fontWeight: 400,
                          }}
                          label={null}
                          value={planningData.currentMonthlyExpenses}
                          onChange={(e) => {
                            const re = /^\d*[0-9]+\d*$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            )
                              setPlanningData({
                                ...planningData,
                                currentMonthlyExpenses: e.target.value,
                              });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex justify-between items-center mt-[20px]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">
                        Current Investments set aside for the Goal
                      </p>
                    </div>
                    <div className="w-[30%] flex justify-between">
                      <div className="w-[40%]">
                        <DropdownForAttributes
                          labelName={null}
                          options={["₹ L", "₹ K", "₹ C"]}
                          selected={planningData}
                          setSelected={setPlanningData}
                          attribute="currentInvestmentsDenotion"
                        />
                      </div>
                      <div className="w-[58%]">
                        <InvestedgeTextField
                          variant="outlined"
                          fullWidth
                          style={{
                            fontFamily: "Inter,sans-sarif",
                            fontWeight: 400,
                          }}
                          label={null}
                          value={planningData.currentInvestments}
                          onChange={(e) => {
                            const re = /^\d*[0-9]+\d*$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            )
                              setPlanningData({
                                ...planningData,
                                currentInvestments: e.target.value,
                              });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex justify-between items-center mt-[20px]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">Inflation</p>
                    </div>
                    <OutlinedInput
                      size="small"
                      className="text-sm"
                      required
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "17.4%",
                      }}
                      value={planningData.inflation}
                      onChange={(e) => {
                        const re = /^\d*\.?\d*$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setPlanningData({
                            ...planningData,
                            inflation: e.target.value,
                          });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <span className="text-sm font-inter">%</span>
                        </InputAdornment>
                      }
                    />
                  </div>
                  <div className="w-full flex justify-between items-center mt-[20px]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">
                        Return Before Retirement
                      </p>
                    </div>
                    <OutlinedInput
                      size="small"
                      className="text-sm"
                      required
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "17.4%",
                      }}
                      value={planningData.returnsBeforeRetirement}
                      onChange={(e) => {
                        const re = /^\d*\.?\d*$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setPlanningData({
                            ...planningData,
                            returnsBeforeRetirement: e.target.value,
                          });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <span className="text-sm font-inter">%</span>
                        </InputAdornment>
                      }
                    />
                  </div>
                  <div className="w-full flex justify-between items-center mt-[20px]">
                    <div className="flex items-center w-[70%]">
                      <p className="font-inter text-sm">
                        Return After Retirement
                      </p>
                    </div>
                    <OutlinedInput
                      size="small"
                      className="text-sm"
                      required
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "17.4%",
                      }}
                      value={planningData.returnsAfterRetirement}
                      onChange={(e) => {
                        const re = /^\d*\.?\d*$/;
                        if (e.target.value === "" || re.test(e.target.value))
                          setPlanningData({
                            ...planningData,
                            returnsAfterRetirement: e.target.value,
                          });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <span className="text-sm font-inter">%</span>
                        </InputAdornment>
                      }
                    />
                  </div>
                  <InvestedgeButton
                    className="mt-8"
                    onClick={handleCalculate}
                    disabled={
                      !planningData.currentAge.length ||
                      !planningData.retirementAge.length ||
                      !planningData.lifeExpectancy.length ||
                      !planningData.currentMonthlyExpenses.length ||
                      !planningData.currentMonthlyExpensesDenotion.length ||
                      !planningData.currentInvestments.length ||
                      !planningData.currentInvestmentsDenotion.length ||
                      !planningData.inflation.length ||
                      !planningData.returnsBeforeRetirement.length ||
                      !planningData.returnsAfterRetirement.length
                    }
                  >
                    Calculate
                  </InvestedgeButton>
                </div>
                <div className="w-[45%] pl-[1rem]">
                  <div className="grid grid-cols-3 gap-6">
                    <div>
                      <p className="font-inter text-[#6a6a76] mb-[4px]">
                        Amount for Retirement
                      </p>
                      <p className="font-inter font-semibold text-[20px] text-primary-200 mb-[4px]">
                        {`₹ ${parseInt(
                          backendData.amount_required_at_retirement
                        ).toLocaleString("en-IN")}`}
                      </p>
                      <p className="font-inter font-medium text-[#3D3D3D] mb-[4px]">
                        {`in ${
                          numToMonthMap[
                            parseInt(backendData.graph.date.split("-")[1])
                          ]
                        } ${backendData.graph.date.split("-")[0]}`}
                      </p>
                    </div>
                    <div>
                      <p className="font-inter text-[#6a6a76] mb-[4px]">
                        Current Investments
                      </p>
                      <p className="font-inter font-semibold text-[20px] text-[#3D3D3D] mb-[4px]">
                        {`₹ ${parseInt(
                          backendData.future_value_of_current_investments
                        ).toLocaleString("en-IN")}`}
                      </p>
                      <p className="font-inter font-medium text-neutralBlack mb-[4px]">
                        {`in ${
                          numToMonthMap[
                            parseInt(backendData.graph.date.split("-")[1])
                          ]
                        } ${backendData.graph.date.split("-")[0]}`}
                      </p>
                    </div>
                    <div>
                      <p className="font-inter text-[#6a6a76] mb-[4px]">
                        Before Retirement
                      </p>
                      <p className="font-inter font-semibold text-[20px] text-success-200 mb-[4px]">
                        {`₹ ${parseInt(
                          backendData.monthly_investment_required
                        ).toLocaleString("en-IN")}`}
                      </p>
                      <p className="font-inter font-medium text-[#3D3D3D] mb-[4px]">
                        Monthly
                      </p>
                    </div>
                  </div>
                  <div className="w-[95%] bg-[#fafafa] p-[16px] mt-[16px]">
                    <BarsChart data={data} options={options} height={212} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
