import React from "react";
import Logo from "../../assets/img/BeyondIRR_ WhiteIcon.png";
import Twitter from "../../assets/img/twitter_footer.svg";
import Linkedin from "../../assets/img/linkedin_footer.svg";
import Facebook from "../../assets/img/facebook_footer.svg";
import Instagram from "../../assets/img/instagram.svg";
import Medium from "../../assets/img/medium.svg";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { REACT_APP_development } from "../../env";
export default function LinksSection() {
  const footer = [
    {
      title: "Company",
      colspan: 2,
      properties: [
        { name: "About us", link: "/about" },
        { name: "Careers", link: "/careers" },
        { name: "Media Kit", link: "/mediakit" },
        { name: "News", link: "/news" },
        { name: "Contact Us", link: "/contactus" },
      ],
    },
    {
      title: "Resources",
      colspan: 1,
      properties: [{ name: "Insights", link: "/insights" }],
    },
    {
      title: "Products",
      colspan: 1,
      properties: [
        {
          name: "InvestEdge",
          link: "/#investedge-homescreen-herosection",
          hashlink: true,
        },
        { name: "InvestLane", link: "/investlane" },
        { name: "InvestAlpha", link: "/investalpha" },
      ],
    },
    {
      title: "Legal",
      colspan: 2,
      properties: [
        { name: "Terms", link: "/terms" },
        { name: "Privacy", link: "/privacy" },
      ],
    },
  ];
  const impLinks = [
    {
      title: "twitter",
      source: Twitter,
      link: "https://twitter.com/BeyondIRR_HQ",
    },
    {
      title: "linkedin",
      source: Linkedin,
      link: "https://www.linkedin.com/company/beyondirr/mycompany/",
    },
    {
      title: "facebook",
      source: Facebook,
      link: "https://www.facebook.com/beyondirr",
    },
    {
      title: "instagram",
      source: Instagram,
      link: "https://www.instagram.com/beyond_irr/",
    },
    {
      title: "medium",
      source: Medium,
      link: "https://medium.com/@birr_hq",
    },
  ];
  // const scrollWithOffset = (el, offset) => {
  //   const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  //   const yOffset = offset;
  //   window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  // };
  return (
    <div className="w-full bg-primarybg-100 text-[#EAECF0] pb-4 px-[16px] md:px-20">
      {REACT_APP_development !== "production" ? (
        <div className="py-12 md:py-16 grid grid-cols-2 md:grid-cols-6 gap-10 border-b border-[#475468]">
          <div className="col-span-2">
            <a href={process.env.REACT_APP_beyondirrhome}>
              <img
                src={Logo}
                alt="logo"
                className="w-[37%] object-contain mb-[16px]"
              />
            </a>
            <p className="font-inter font-normal">
              Unlock the potential of your wealth business
            </p>
          </div>
          {footer.map(({ title, properties, colspan }, index) => {
            return (
              <div key={index} className={`col-span-${colspan} md:col-span-1`}>
                <p className="mb-[16px] font-poppins font-medium font text-sm text-[#FCFCFD]">
                  {title}
                </p>
                {properties.map(({ name, link, hashlink }) =>
                  hashlink ? (
                    <HashLink
                      key={name}
                      to={link}
                      smooth
                      className="font-poppins font-medium text-base cursor-pointer text-[#98A2B3] hover:text-[#FCFCF4] active:text-[#FCFCF4] decoration-[#FCFCF4]"
                    >
                      <p
                        className="font-poppins font-medium mt-3 text-base cursor-pointer"
                        key={name}
                      >
                        {name}
                      </p>
                    </HashLink>
                  ) : (
                    <Link
                      key={name}
                      to={link}
                      className="font-poppins font-medium text-base cursor-pointer text-[#98A2B3] hover:text-[#FCFCF4] active:text-[#FCFCF4] decoration-[#FCFCF4]"
                    >
                      <p
                        className="font-poppins font-medium mt-3 text-base cursor-pointer mb-0"
                        key={name}
                      >
                        {name}
                      </p>
                    </Link>
                  )
                )}
              </div>
            );
          })}
        </div>
      ) : null}
      <div className="flex flex-col-reverse md:flex-row md:justify-between py-8">
        <p
          className={`font-poppins text-[#98A2B3] mt-[${
            REACT_APP_development !== "production" ? "26px" : "0px"
          }] md:mt-0 mb-0`}
        >
          &copy; 2022 Valiant Finsolutions Private Limited. All rights reserved.
          CIN - U67100MH2021PTC372297
        </p>
        {REACT_APP_development !== "production" ? (
          <div className="flex md:justify-between">
            {impLinks.map((item, ind) => (
              <a key={ind} href={item.link} target="_blank" rel="noreferrer">
                <img
                  key={item.title}
                  src={item.source}
                  alt={item.title}
                  className="ml-[20px] cursor-pointer"
                />
              </a>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}
