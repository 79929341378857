import React, { useState } from "react";
import AfterloginApp from "../../AfterloginApp";
import Pitchbook1 from "../../../assets/img/pitchbook1.svg";
import Pitchbook2 from "../../../assets/img/pitchbook2.svg";
import Pitchbook3 from "../../../assets/img/pitchbook3.svg";
import Pitchbook4 from "../../../assets/img/pitchbook4.svg";
import Check from "../../../assets/img/check_circle_outlined_hollow.svg";
import { FormControlLabel, Switch } from "@mui/material";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
export default function CreatePitchbook() {
  const [proposal, setProposal] = useState({
    companyProfile: true,
    investmentPhilosophy: true,
    market: true,
    researchViews: true,
    productReckoner: true,
  });
  const [template, setTemplate] = useState(null);
  let handleChangeSwitch = (e) => {
    let newProposal = { ...proposal };
    newProposal[e.target.name] = !newProposal[e.target.name];
    setProposal(newProposal);
  };
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Portfolio Insights"}
        description={"Create and Customize Portfolio Recommendations"}
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className="main-panel h-full">
            <div className="flex ">
              <div className="portfolio-proposal-body-card shadow-none border-none proposal-none min-h-[calc(100vh_-_116px)]">
                <h2 className="card-heading">Select Templates</h2>
                <p className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-left mb-0">
                  Select any of the below templates to Create PitchBook /
                  Brochure
                </p>
                <div className="w-full grid grid-cols-4 gap-[16px] mt-[16px]">
                  {template === 1 ? (
                    <div className="w-[100%] relative rounded-[8px] border border-primary-200">
                      <img
                        src={Pitchbook1}
                        alt="err"
                        className="w-[100%] rounded-[8px]"
                      />
                      <img
                        src={Check}
                        alt="err"
                        className="absolute top-[8px] right-[8px]"
                      />
                    </div>
                  ) : (
                    <img
                      src={Pitchbook1}
                      alt="err"
                      className="w-[100%] rounded-[8px]"
                      onClick={() => setTemplate(1)}
                    />
                  )}
                  {template === 2 ? (
                    <div className="w-[100%] relative rounded-[8px]  border border-primary-200 ">
                      <img
                        src={Pitchbook2}
                        alt="err"
                        className="w-[100%] rounded-[8px]"
                      />
                      <img
                        src={Check}
                        alt="err"
                        className="absolute top-[8px] right-[8px]"
                      />
                    </div>
                  ) : (
                    <img
                      src={Pitchbook2}
                      alt="err"
                      className="w-[100%] rounded-[8px]"
                      onClick={() => setTemplate(2)}
                    />
                  )}
                  {template === 3 ? (
                    <div className="w-[100%] relative rounded-[8px] border border-primary-200 ">
                      <img
                        src={Pitchbook3}
                        alt="err"
                        className="w-[100%] rounded-[8px]"
                      />
                      <img
                        src={Check}
                        alt="err"
                        className="absolute top-[8px] right-[8px]"
                      />
                    </div>
                  ) : (
                    <img
                      src={Pitchbook3}
                      alt="err"
                      className="w-[100%] rounded-[8px]"
                      onClick={() => setTemplate(3)}
                    />
                  )}
                  {template === 4 ? (
                    <div className="w-[100%] relative rounded-[8px] border border-primary-200 ">
                      <img
                        src={Pitchbook4}
                        alt="err"
                        className="w-[100%] rounded-[8px]"
                      />
                      <img
                        src={Check}
                        alt="err"
                        className="absolute top-[8px] right-[8px]"
                      />
                    </div>
                  ) : (
                    <img
                      src={Pitchbook4}
                      alt="err"
                      className="w-[100%] rounded-[8px]"
                      onClick={() => setTemplate(4)}
                    />
                  )}
                  {template === 5 ? (
                    <div className="w-[100%] relative rounded-[8px] border border-primary-200 ">
                      <img
                        src={Pitchbook1}
                        alt="err"
                        className="w-[100%] rounded-[8px]"
                      />
                      <img
                        src={Check}
                        alt="err"
                        className="absolute top-[8px] right-[8px]"
                      />
                    </div>
                  ) : (
                    <img
                      src={Pitchbook1}
                      alt="err"
                      className="w-[100%] rounded-[8px]"
                      onClick={() => setTemplate(5)}
                    />
                  )}
                  {template === 6 ? (
                    <div className="w-[100%] relative rounded-[8px] border border-primary-200 ">
                      <img
                        src={Pitchbook2}
                        alt="err"
                        className="w-[100%] rounded-[8px]"
                      />
                      <img
                        src={Check}
                        alt="err"
                        className="absolute top-[8px] right-[8px]"
                      />
                    </div>
                  ) : (
                    <img
                      src={Pitchbook2}
                      alt="err"
                      className="w-[100%] rounded-[8px]"
                      onClick={() => setTemplate(6)}
                    />
                  )}
                  {template === 7 ? (
                    <div className="w-[100%] relative rounded-[8px] border border-primary-200 ">
                      <img
                        src={Pitchbook3}
                        alt="err"
                        className="w-[100%] rounded-[8px]"
                      />
                      <img
                        src={Check}
                        alt="err"
                        className="absolute top-[8px] right-[8px]"
                      />
                    </div>
                  ) : (
                    <img
                      src={Pitchbook3}
                      alt="err"
                      className="w-[100%] rounded-[8px]"
                      onClick={() => setTemplate(7)}
                    />
                  )}
                  {template === 8 ? (
                    <div className="w-[100%] relative rounded-[8px] border border-primary-200 ">
                      <img
                        src={Pitchbook4}
                        alt="err"
                        className="w-[100%] rounded-[8px]"
                      />
                      <img
                        src={Check}
                        alt="err"
                        className="absolute top-[8px] right-[8px]"
                      />
                    </div>
                  ) : (
                    <img
                      src={Pitchbook4}
                      alt="err"
                      className="w-[100%] rounded-[8px]"
                      onClick={() => setTemplate(8)}
                    />
                  )}
                </div>
                <h3 className="font-inter text-[16px] font-medium leading-[20px] tracking-[0em] text-left text-[#000] mb-0 mt-[1.25rem]">
                  Things to Include
                </h3>
                <div className="mr-[24px]">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={proposal.companyProfile}
                        name="companyProfile"
                        onChange={(e) => handleChangeSwitch(e)}
                        inputProps={{ role: "switch" }}
                        color="primary"
                      />
                    }
                    label={
                      <span className="font-inter text-[16px] font-normal leading-[20px] tracking-[0em] text-left text-neutralBlack">
                        Company Profile
                      </span>
                    }
                    className="pt-[0.5rem]"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={proposal.investmentPhilosophy}
                        name="investmentPhilosophy"
                        onChange={(e) => handleChangeSwitch(e)}
                        inputProps={{ role: "switch" }}
                        color="primary"
                      />
                    }
                    label={
                      <span className="font-inter text-[16px] font-normal leading-[20px] tracking-[0em] text-left text-neutralBlack">
                        Investment Philosopy
                      </span>
                    }
                    className="pt-[0.5rem]"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={proposal.market}
                        name="market"
                        onChange={(e) => handleChangeSwitch(e)}
                        inputProps={{ role: "switch" }}
                        color="primary"
                      />
                    }
                    label={
                      <span className="font-inter text-[16px] font-normal leading-[20px] tracking-[0em] text-left text-neutralBlack">
                        Market
                      </span>
                    }
                    className="pt-[0.5rem]"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={proposal.researchViews}
                        name="researchViews"
                        onChange={(e) => handleChangeSwitch(e)}
                        inputProps={{ role: "switch" }}
                        color="primary"
                      />
                    }
                    label={
                      <span className="font-inter text-[16px] font-normal leading-[20px] tracking-[0em] text-left text-neutralBlack">
                        Research Views
                      </span>
                    }
                    className="pt-[0.5rem]"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={proposal.productReckoner}
                        name="productReckoner"
                        onChange={(e) => handleChangeSwitch(e)}
                        inputProps={{ role: "switch" }}
                        color="primary"
                      />
                    }
                    label={
                      <span className="font-inter text-[16px] font-normal leading-[20px] tracking-[0em] text-left text-neutralBlack">
                        Product Reckoner
                      </span>
                    }
                    className=" font-inter font-normal pt-[0.5rem]"
                  />
                </div>
                <div className="w-full flex justify-end">
                  <InvestedgeButton
                  // onClick={() => {
                  //   dispatch(
                  //     PostEventLoggingData({
                  //       module_name: "Portfolio Insights",
                  //       event_type: "Start button",
                  //       url: window.location.href,
                  //       note: `Create Pitchbook started`,
                  //     })
                  //   );
                  // }}
                  >
                    Create
                  </InvestedgeButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
