import React from "react";
import PieChart from "../dashboard/PieChart";
// import HorizontalBarChart from "../../../../components/charts/HorizontalBarChart";
// import Lock from "../../assets/img/lock_locked.svg";
import ProgressBar from "./ProgressBar";
// import { useDispatch } from "react-redux";
// import { OpenSnackbar } from "../../actions/actions";
// import InvestedgeButton from "../../components/buttons/InvestedgeButton";
export default function OverviewSection({ analytics }) {
  const keys = [];
  const values = [];
  let maxStock = 0;
  // const dispatch = useDispatch();
  const bgGradients = [
    "linear-gradient(223.78deg, #09C3E7 0%, #7669F0 106.87%)",
    "linear-gradient(225deg, #9C2979 0%, #F15E75 100%)",
    "linear-gradient(108.81deg, #FF9F38 -27.65%, #FE5C44 125%)",
    "linear-gradient(108.81deg, #FFD166 -27.65%, #C26F0D 125%)",
  ];
  // const displayingHorizontalChartLabels = (value) => {
  //   return value.length > 4 ? `${value.slice(0, 4)}..` : value.slice(0, 4);
  // };
  for (const property in analytics.product_allocation) {
    keys.push(property);
    values.push((analytics.product_allocation[property] * 100).toFixed(1));
    maxStock = Math.max(maxStock, analytics.product_allocation[property] * 100);
  }
  const assetdata = {
    labels: ["Equity", "Debt", "Alternates"],

    datasets: [
      {
        data: [
          (analytics.asset_allocation.Equity * 100).toFixed(1),
          (analytics.asset_allocation.Debt * 100).toFixed(1),
          (analytics.asset_allocation.Alternate * 100).toFixed(1),
        ],
        backgroundColor: [
          "#ED6D85",
          "#56A1E5",
          "#F7CE6B",
          "#F1A354",
          "#6CBDBF",
        ],
        borderColor: ["#ED6D85", "#56A1E5", "#F7CE6B", "#F1A354", "#6CBDBF"],
        borderWidth: 1,
      },
    ],
  };
  const pieoptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      labels: {
        render: "value",
        outsidePadding: 14,
        textMargin: 3,
        fontColor: "#fff",
      },
      datalabels: {
        display: false,
      },
    },
  };
  // const productAllocationData = {
  //   labels: keys,
  //   datasets: [
  //     {
  //       label: "Product Allocation",
  //       backgroundColor: [
  //         "#ED6D85",
  //         "#56A1E5",
  //         "#F7CE6B",
  //         "#F1A354",
  //         "#6CBDBF",
  //         "#FFA3B9",
  //       ],
  //       borderColor: [
  //         "#ED6D85",
  //         "#56A1E5",
  //         "#F7CE6B",
  //         "#F1A354",
  //         "#6CBDBF",
  //         "#FFA3B9",
  //       ],
  //       borderWidth: 1,
  //       barThickness: 20,
  //       data: values,
  //       borderRadius: 10,
  //     },
  //   ],
  // };
  // const horizontalBarOptions = {
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   cornerRadius: 20,
  //   legend: {
  //     display: false,
  //   },
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           callback: (value) => {
  //             return displayingHorizontalChartLabels(value);
  //           },
  //           fontColor: "#fff",
  //           beginAtZero: true,
  //           mirror: true,
  //           padding: -8,
  //           z: 8,
  //         },
  //         gridLines: {
  //           display: false,
  //         },
  //       },
  //     ],
  //     xAxes: [
  //       {
  //         ticks: {
  //           beginAtZero: true,
  //           display: false,
  //           max: maxStock + 20,
  //         },
  //         gridLines: {
  //           display: false,
  //         },
  //       },
  //     ],
  //   },
  //   plugins: {
  //     datalabels: {
  //       display: true,
  //       color: "#323232",
  //       align: "end",
  //       anchor: "end",
  //       font: { size: "12" },
  //       clamp: true,
  //       clip: true,
  //     },
  //   },
  // };
  return (
    <>
      <div className="w-full mt-[1rem] bg-[#fafafa] p-[1rem] flex rounded-[6px]">
        <div className="w-[20%] text-center border-r border-r-dividerColor">
          <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0">
            {`${(analytics.port_return * 100).toFixed(1)}%`}
          </h3>
          <label className="font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
            3Y CAGR
          </label>
        </div>
        <div className="w-[25%] text-center border-r border-r-dividerColor">
          <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0">
            {`${(analytics.bm_blended_return * 100).toFixed(1)}%`}
          </h3>
          <label className="font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
            3Y BM* CAGR
          </label>
        </div>
        <div className="w-[27.5%] text-center border-r border-r-dividerColor">
          <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#1982f8] mb-0">
            {analytics.total_products}
          </h3>
          <label className="font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
            Total Products
          </label>
        </div>
        <div className="w-[27.5%] text-center">
          <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#00B031] mb-0">
            {`${(analytics.liquidity * 100).toFixed(1)}%`}
          </h3>
          <label className="font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
            Easily Liquidable
          </label>
        </div>
      </div>
      <div className="pt-[1rem]">
        <div className="w-[100%] h-[275px]">
          <div className="flex justify-between mb-2">
            <h4 className="font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack">
              Asset Allocation (in %)
            </h4>
            <div className="flex">
              <div className="flex items-center ml-[16px]">
                <div className="bg-[#ED6D84] rounded w-[14px] h-[14px]" />
                <h2 className="text-xs ml-[8px] mb-0">Equity</h2>
              </div>
              <div className="flex items-center ml-[16px]">
                <div className="bg-[#56A1E5] rounded w-[14px] h-[14px]" />
                <h2 className="text-xs ml-[8px] mb-0">Debt</h2>
              </div>
              <div className="flex items-center ml-[16px]">
                <div className="bg-[#F8CE6C] rounded w-[14px] h-[14px]" />
                <h2 className="text-xs ml-[8px] mb-0">Alternate</h2>
              </div>
            </div>
          </div>
          <div className="bg-[#FAFAFA] w-[100%] p-[1rem] min-h-full flex justify-center items-center">
            <PieChart
              data={assetdata}
              options={pieoptions}
              width={240}
              height={250}
            />
          </div>
        </div>
        <div style={{ width: "100%", height: "275px", paddingTop: "3rem" }}>
          <h4 className="font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-neutralBlack mb-2">
            Product Allocation (Overall in %)
          </h4>
          <div className="bg-[#FAFAFA] w-[100%] p-[1rem] min-h-full">
            {keys.map((sector, index) => (
              <ProgressBar
                key={index}
                bg={bgGradients[index % bgGradients.length]}
                progress={values[index]}
                label={sector}
              />
            ))}
          </div>
        </div>
      </div>
      {/* <h3 className="font-inter text-[14px] font-medium leading-[20px] tracking-[0em]  text-primary-200 pt-[2rem]">
        ADVANCE ANALYTICS
      </h3>
      <InvestedgeButton
        className="mt-4"
        onClick={() =>
          dispatch(
            OpenSnackbar({
              severity: "info",
              message: "Feature will be coming soon",
            })
          )
        }
      >
        <img
          className="w-[14px] object-contain mr-[0.5rem]"
          src={Lock}
          alt="err"
        />
        Show
      </InvestedgeButton> */}
      <p className=" font-inter not-italic text-[10px] font-normal leading-[15px] mt-[3rem]">
        The equity benchmark is NSE 500 Index and the debt benchmark is Crisil
        Short Term Bond Index. *The portfolio benchmark is in the asset
        allocation ratio of these two indices and Gold ETF. All values are
        rebased so that the sum is 100% for each graph unless specified
        otherwise. Returns less than 1 year are absolute and more than 1 year
        are CAGR" For debt, returns less than 1 year are annualised and more
        than 1 year are CAGR. Benchmark values are calculated in proportion of
        the asset allocation of the portfolio
      </p>
    </>
  );
}
