import React from "react";
// import RelationshipMangerCard from "./RelationshipMangerCard";
// import Popover from "@mui/material/Popover";
import Profile from "../../assets/img/defaultprofilepic.png";
export default function Manager({ item }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleclose = () => {
  //   setAnchorEl(null);
  // };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <div
        className="my-[0.25rem] mx-[0.75rem]"
        aria-describedby={id}
        onClick={handleClick}
      >
        <table>
          <tbody>
            <tr>
              <td className="flex justify-center">
                <img
                  src={item.profile_pic ? item.profile_pic : Profile}
                  alt="err"
                  className="rounded-[100%] w-[70px] h-[70px]"
                />
              </td>
            </tr>
            <tr>
              <td className="text-[14px] font-inter leading-[20px] tracking-[0.2px] text-center ">
                {item.name}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleclose}
        className="mt-[1rem] w-[700px]"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="p-[0.5rem] w-[300px]">
          <RelationshipMangerCard item={item} />
        </div>
      </Popover> */}
    </>
  );
}
