import React, { useState } from "react";
import Dropdown from "./Dropdown";
import { FormControlLabel, Radio } from "@mui/material";
import UpdatedClientCard from "./UpdatedClientCard";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  OpenSnackbar,
  PostEventLoggingData,
  UpdateClientData,
} from "../../../actions/actions";
import Dropdown2 from "./DropdownObjectFIelds";
import InvestedgeTextField from "../../../components/textfield/InvestedgeTextField";
import InvestedgeDatepicker from "../../../components/datepicker/InvestedgeDatepicker";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import dayjs from "dayjs";
export default function IndividualContent() {
  const dispatch = useDispatch();
  const clientData = useSelector((state) => state.clientinputdata);
  const [options, setOptions] = useState(
    clientData.Members.map((member, index) => {
      return { optionname: member.name, optionid: index };
    })
  );
  const [validationFlag, setValidationFlag] = useState(false);
  const [updatedOptions, setUpdatedOptions] = useState([]);
  const [selected, setSelected] = useState(options[0]);
  const [familyMembers, setFamilyMembers] = useState(clientData.Members);
  let handleValidation = () => {
    let temp = true;
    if (updatedOptions.length !== clientData.Members.length) temp = false;
    // for (let i = 0; i < familyMembers.length; i++) {
    //   if (familyMembers[i].panNumber.length !== 10) {
    //     temp = false;
    //   }
    // }
    // familyMembers.map((element) => {
    //   if (element.panNumber.length !== 10) temp = false;
    // });
    setValidationFlag(temp);
  };
  let handleChange = (e) => {
    if (
      e.target.name === "pincode" ||
      e.target.name === "carryftcl" ||
      e.target.name === "currentftcl" ||
      e.target.name === "currentstcl" ||
      e.target.name === "carrystcl"
    ) {
      const re = /^\d+$/;
      if (
        (e.target.value === "" || re.test(e.target.value)) &&
        e.target.value[e.target.value.length - 1] !== "."
      ) {
        let newfamilyMembers = [...familyMembers];
        newfamilyMembers[selected.optionid][e.target.name] = e.target.value;
        setFamilyMembers(newfamilyMembers);
        handleValidation();
      }
    } else if (e.target.name === "sector") {
      const re = /^[A-Za-z]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        let newfamilyMembers = [...familyMembers];
        newfamilyMembers[selected.optionid][e.target.name] = e.target.value;
        setFamilyMembers(newfamilyMembers);
        handleValidation();
      }
    } else {
      let newfamilyMembers = [...familyMembers];
      newfamilyMembers[selected.optionid][e.target.name] = e.target.value;
      setFamilyMembers(newfamilyMembers);
      handleValidation();
    }
  };
  let handleUpdateClick = () => {
    // if (familyMembers[selected.optionid].panNumber.length !== 10) {
    //   let newfamilyMembers = [...familyMembers];
    //   newfamilyMembers[selected.optionid].errors.pannumber =
    //     "Please enter a valid 10 digit number";
    //   setFamilyMembers(newfamilyMembers);
    //   var elmnt = document.getElementById(
    //     "client-details-individual-details-screen"
    //   );
    //   elmnt.scrollTo({
    //     top: 0,
    //     behavior: "smooth",
    //   });
    // } else {
    //   let newOptions = [...options];
    //   for (var i = 0; i < newOptions.length; i++) {
    //     if (newOptions[i] === selected) {
    //       newOptions.splice(i, 1);
    //     }
    //   }
    //   dispatch(
    //     PostEventLoggingData({
    //       module_name: "Client Details",
    //       event_type: "Next button",
    //       url: window.location.href,
    //       note: `Individual Details updated for ${
    //         familyMembers[selected.optionid].name
    //       }`,
    //     })
    //   );
    //   dispatch(
    //     OpenSnackbar({
    //       severity: "success",
    //       message: `Details for ${
    //         familyMembers[selected.optionid].name
    //       } updated`,
    //     })
    //   );
    //   let newUpdatedOptions = updatedOptions;
    //   newUpdatedOptions.push(selected);
    //   setOptions(newOptions);
    //   setUpdatedOptions(newUpdatedOptions);
    //   setSelected(newOptions[0]);
    //   handleValidation();
    // }
    let newOptions = [...options];
    for (var i = 0; i < newOptions.length; i++) {
      if (newOptions[i] === selected) {
        newOptions.splice(i, 1);
      }
    }
    dispatch(
      PostEventLoggingData({
        module_name: "Client Details",
        event_type: "Next button",
        url: window.location.href,
        note: `Individual Details updated for ${
          familyMembers[selected.optionid].name
        }`,
      })
    );
    dispatch(
      OpenSnackbar({
        severity: "success",
        message: `Details for ${familyMembers[selected.optionid].name} updated`,
      })
    );
    let newUpdatedOptions = updatedOptions;
    newUpdatedOptions.push(selected);
    setOptions(newOptions);
    setUpdatedOptions(newUpdatedOptions);
    setSelected(newOptions[0]);
    handleValidation();
  };
  let handleCancelDetails = (element) => {
    let newOptions = [...options];
    newOptions.push(element);
    let newUpdatedOptions = updatedOptions;
    for (var i = 0; i < newUpdatedOptions.length; i++) {
      if (newUpdatedOptions[i] === element) {
        newUpdatedOptions.splice(i, 1);
      }
    }
    setOptions(newOptions);
    if (newOptions.length === 1) setSelected(newOptions[0]);
    setUpdatedOptions(newUpdatedOptions);
    setValidationFlag(false);
  };
  const handleDateChange = (date) => {
    date = new Date(date);
    let newfamilyMembers = [...familyMembers];
    newfamilyMembers[selected.optionid].dob = date;
    if (date) {
      let today = new Date();
      let aget = today.getFullYear() - date.getFullYear();
      var m = today.getMonth() - date.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
        aget--;
      }
      newfamilyMembers[selected.optionid].age = aget;
    }

    setFamilyMembers(newfamilyMembers);
  };

  let handleNextClick = () => {
    dispatch(
      PostEventLoggingData({
        module_name: "Client Details",
        event_type: "Next button",
        url: window.location.href,
        note: `Individual Details`,
      })
    );
    clientData.Members = familyMembers;
    dispatch(UpdateClientData(clientData));
  };

  const return_date = (val) => {
    try {
      let temp = dayjs(new Date(val).toISOString());
      return temp;
    } catch (err) {
      return val;
    }
  };
  return (
    <>
      <div className="sticky top-0 bg-[#f3f3f3] ml-[-22px] mr-[-22px] z-[10] pt-[1rem] pl-[6px] pr-[6px]">
        <div className="w-full h-full rounded-t-[0.5rem] bg-[#fff] p-[1rem] min-h-full">
          <h1 className="font-inter text-[21px] not-italic font-medium tracking-[0em] text-left text-neutralBlack">
            Edit Individual Details
          </h1>

          <div className="w-[28%] mt-[1.5rem]">
            <Dropdown
              options={options}
              selected={selected}
              setSelected={setSelected}
              labelName="Select Individual"
            />
          </div>

          <h2 className="w-[100%] text-left border-b-[2px] border-[#ece7e7] leading-[0.1em] mt-[2.5rem]">
            <span className="bg-[#fff] text-[16px] font-inter pr-[10px] pb-[10px] font-medium text-neutralBlack ">
              Edit Details
            </span>
          </h2>
        </div>
      </div>
      <div className="rounded-[0.5rem] p-[1rem] m-[-1rem] bg-[#fff]">
        {familyMembers.length !== updatedOptions.length ? (
          <div className="text-neutralBlack">
            <div className="text-[15px] text-[#939393] font-medium mt-[1rem]">
              General details
            </div>
            <table className="w-full mt-4">
              <tbody>
                {/* <tr className="w-full flex justify-between mt-0">
                  <td className="w-[30%] p-0">
                    <label className="font-inter text-[14px] font-normal leading-[22px] tracking-[0em] text-left mt-[1.3rem]">
                      Date of Birth
                    </label>
                  </td>
                  <td className="p-0 w-[30%]">
                    <label className="font-inter text-[14px] font-normal leading-[22px] tracking-[0em] text-left mt-[1.3rem]">
                      Gender
                    </label>
                  </td>
                  <td className="p-0 w-[30%]">
                    <label className="font-inter text-[14px] font-normal leading-[22px] tracking-[0em] text-left mt-[1.3rem]">
                      Marital Status
                    </label>
                  </td>
                </tr> */}
                <tr className="w-full flex justify-between mt-[0.5rem]">
                  <td className="w-[30%] p-0 flex items-center">
                    <InvestedgeDatepicker
                      format="DD/MM/YYYY"
                      label="Date of birth"
                      value={return_date(familyMembers[selected.optionid].dob)}
                      className="font-inter font-normal w-full"
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <InvestedgeTextField {...params} />
                      )}
                    />
                  </td>
                  <td className="w-[30%] p-0 h-full flex flex-col justify-between">
                    <label className="font-inter text-[14px] font-normal tracking-[0em] leading-5">
                      Gender
                    </label>
                    <div className="flex justify-between items-center w-[95%]">
                      <FormControlLabel
                        control={
                          <Radio
                            size="small"
                            checked={
                              familyMembers[selected.optionid].gender === "Male"
                            }
                            onChange={(e) => handleChange(e)}
                            value="Male"
                            name="gender"
                            inputProps={{ "aria-label": "Male" }}
                          />
                        }
                        label={
                          <span className="text-[14px] font-inter text-neutralBlack">
                            Male
                          </span>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            size="small"
                            checked={
                              familyMembers[selected.optionid].gender ===
                              "Female"
                            }
                            onChange={(e) => handleChange(e)}
                            value="Female"
                            name="gender"
                            inputProps={{ "aria-label": "Female" }}
                          />
                        }
                        label={
                          <span className="text-[14px] font-inter text-neutralBlack">
                            Female
                          </span>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            size="small"
                            checked={
                              familyMembers[selected.optionid].gender ===
                              "Other"
                            }
                            onChange={(e) => handleChange(e)}
                            value="Other"
                            name="gender"
                            inputProps={{ "aria-label": "Other" }}
                          />
                        }
                        label={
                          <span className="text-[14px] font-inter text-neutralBlack">
                            Other
                          </span>
                        }
                      />
                    </div>
                  </td>
                  <td className="w-[30%] p-0">
                    <label className="font-inter text-[14px] font-normal tracking-[0em] leading-5">
                      Marital Status
                    </label>
                    <div className="flex justify-between items-center w-[70%]">
                      <FormControlLabel
                        control={
                          <Radio
                            size="small"
                            checked={
                              familyMembers[selected.optionid].status ===
                              "Married"
                            }
                            onChange={(e) => handleChange(e)}
                            value="Married"
                            name="status"
                            inputProps={{ "aria-label": "Married" }}
                          />
                        }
                        label={
                          <span className="text-[14px] font-inter text-neutralBlack">
                            Married
                          </span>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            className="text-sm"
                            size="small"
                            checked={
                              familyMembers[selected.optionid].status ===
                              "Single"
                            }
                            onChange={(e) => handleChange(e)}
                            value="Single"
                            name="status"
                            inputProps={{ "aria-label": "Single" }}
                          />
                        }
                        label={
                          <span className="text-[14px] font-inter text-neutralBlack">
                            Single
                          </span>
                        }
                      />
                    </div>
                  </td>
                </tr>

                <tr className="w-[100%] flex justify-between mt-6">
                  <td className="w-[30%] p-0">
                    <InvestedgeTextField
                      variant="outlined"
                      fullWidth
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                      }}
                      label="PAN No."
                      error={
                        familyMembers[selected.optionid].errors.pannumber
                          ? true
                          : false
                      }
                      helperText={
                        familyMembers[selected.optionid].errors.pannumber
                          ? "Please enter a valid 10 digit PAN number"
                          : ""
                      }
                      name="panNumber"
                      value={familyMembers[selected.optionid].panNumber}
                      onChange={(e) => handleChange(e)}
                      // onChange={(e) => {
                      //   let re = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
                      //   let reg = e.target.value.length === 10;
                      //   if (!reg && !re.test(e.target.value)) {
                      //     let newfamilyMembers = [...familyMembers];
                      //     newfamilyMembers[selected.optionid].errors.pannumber =
                      //       "Please enter a valid 10 digit number";
                      //     setFamilyMembers(newfamilyMembers);
                      //   } else {
                      //     let newfamilyMembers = [...familyMembers];
                      //     newfamilyMembers[selected.optionid].errors.pannumber =
                      //       null;
                      //     setFamilyMembers(newfamilyMembers);
                      //   }
                      //   handleChange(e);
                      // }}
                    />
                  </td>
                  <td className="w-[30%] p-0">
                    <InvestedgeTextField
                      variant="outlined"
                      fullWidth
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                      }}
                      label="Occupation"
                      name="occupation"
                      value={familyMembers[selected.optionid].occupation}
                      onChange={(e) => handleChange(e)}
                    />
                  </td>
                  <td className="w-[30%] p-0">
                    <InvestedgeTextField
                      variant="outlined"
                      fullWidth
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                      }}
                      label="Sector"
                      name="sector"
                      type="alpha"
                      value={familyMembers[selected.optionid].sector}
                      onChange={(e) => handleChange(e)}
                    />
                  </td>
                </tr>

                <tr className="w-[100%] flex justify-between mt-6">
                  <td
                    className="w-[65%] p-0"
                    // style={{
                    //   width: "65%",
                    // }}
                  >
                    <InvestedgeTextField
                      variant="outlined"
                      fullWidth
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                      }}
                      label="Address"
                      name="address"
                      value={familyMembers[selected.optionid].address}
                      onChange={(e) => handleChange(e)}
                    />
                  </td>

                  <td className="w-[30%] p-0">
                    <InvestedgeTextField
                      variant="outlined"
                      fullWidth
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                      }}
                      label="PIN Code"
                      name="pincode"
                      value={familyMembers[selected.optionid].pincode}
                      onChange={(e) => handleChange(e)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="text-[15px] text-[#939393] font-medium mt-[2.5rem]">
              Tax-related details
            </div>
            <table className="w-full">
              <tbody>
                <tr className="w-[100%] flex justify-between mt-6">
                  <td className="w-[30%] p-0">
                    <Dropdown2
                      options={["Indian", "Others"]}
                      selected={familyMembers}
                      setSelected={setFamilyMembers}
                      attribute="taxResidency"
                      index={selected.optionid}
                      labelName="Tax Residency"
                    />
                  </td>
                  <td className="w-[30%] p-0">
                    <Dropdown2
                      options={["Indian", "Others"]}
                      selected={familyMembers}
                      setSelected={setFamilyMembers}
                      attribute="nationality"
                      index={selected.optionid}
                      labelName="Nationality"
                    />
                  </td>
                  <td className="w-[30%] p-0">
                    <Dropdown2
                      options={["Indian", "Others"]}
                      selected={familyMembers}
                      setSelected={setFamilyMembers}
                      attribute="residencyStatus"
                      index={selected.optionid}
                      labelName="Residency Status"
                    />
                  </td>
                </tr>

                <tr className="w-full flex justify-between mt-6">
                  <td className="w-[47%] p-0">
                    <Dropdown2
                      options={["5%", "10%", "20%", "30%"]}
                      selected={familyMembers}
                      setSelected={setFamilyMembers}
                      attribute="taxrate"
                      labelName="Marginal Tax Rate"
                      index={selected.optionid}
                    />
                  </td>
                  <td className="w-[47%] p-0">
                    <Dropdown2
                      options={["0%", "10%", "25%", "37%"]}
                      selected={familyMembers}
                      setSelected={setFamilyMembers}
                      attribute="surcharge"
                      labelName="Surcharge"
                      index={selected.optionid}
                    />
                  </td>
                </tr>

                <tr className="flex justify-between w-full mt-6">
                  <td className="flex justify-between w-[47%] p-0">
                    <div className="w-[25%]">
                      <Dropdown2
                        options={["₹ K", "₹ L", "₹ C"]}
                        selected={familyMembers}
                        setSelected={setFamilyMembers}
                        attribute="carrystcldenotion"
                        index={selected.optionid}
                      />
                    </div>
                    <InvestedgeTextField
                      variant="outlined"
                      type="number"
                      fullWidth
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "73%",
                      }}
                      label="Carry forward short-term capital loss"
                      name="carrystcl"
                      value={familyMembers[selected.optionid].carrystcl}
                      onChange={(e) => handleChange(e)}
                    />
                  </td>
                  <td className="p-0 flex justify-between w-[47%]">
                    <div className="w-[25%]">
                      <Dropdown2
                        options={["₹ K", "₹ L", "₹ C"]}
                        selected={familyMembers}
                        setSelected={setFamilyMembers}
                        attribute="currentstcldenotion"
                        index={selected.optionid}
                      />
                    </div>
                    <InvestedgeTextField
                      variant="outlined"
                      type="number"
                      fullWidth
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "73%",
                      }}
                      label="Current forward short-term capital loss"
                      name="currentstcl"
                      value={familyMembers[selected.optionid].currentstcl}
                      onChange={(e) => handleChange(e)}
                    />
                  </td>
                </tr>

                <tr className="flex justify-between w-full mt-6">
                  <td className=" flex justify-between w-[47%] p-0">
                    <div className="w-[25%]">
                      <Dropdown2
                        options={["₹ K", "₹ L", "₹ C"]}
                        selected={familyMembers}
                        setSelected={setFamilyMembers}
                        attribute="carryftcldenotion"
                        index={selected.optionid}
                      />
                    </div>
                    <InvestedgeTextField
                      variant="outlined"
                      fullWidth
                      type="number"
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "73%",
                      }}
                      label="Carry forward long-term capital loss"
                      name="carryftcl"
                      value={familyMembers[selected.optionid].carryftcl}
                      onChange={(e) => handleChange(e)}
                    />
                  </td>
                  <td className="flex justify-between w-[47%] p-0">
                    <div className="w-[25%]">
                      <Dropdown2
                        options={["₹ K", "₹ L", "₹ C"]}
                        selected={familyMembers}
                        setSelected={setFamilyMembers}
                        attribute="currentftcldenotion"
                        index={selected.optionid}
                      />
                    </div>
                    <InvestedgeTextField
                      variant="outlined"
                      type="number"
                      fullWidth
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "73%",
                      }}
                      label="Current long-term capital loss"
                      name="currentftcl"
                      value={familyMembers[selected.optionid].currentftcl}
                      onChange={(e) => handleChange(e)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="flex justify-end">
              <div
                className="font-inter text-primary-200 bg-primary-accent py-[0.45rem] px-0 text-center w-[16%] rounded-[0.25rem] mt-[2.5rem] mb-0 mx-0 cursor-pointer"
                onClick={handleUpdateClick}
              >
                Update details
              </div>
            </div>
            {updatedOptions.length !== 0 && (
              <>
                <h2 className=" font-medium text-neutralBlack text-[16px] mt-[0.3rem]">
                  Updated data for
                </h2>
                <div className="flex flex-wrap">
                  {updatedOptions.map((client, index) => {
                    return (
                      <UpdatedClientCard
                        key={index}
                        ind={index}
                        client={client}
                        handleCancelDetails={handleCancelDetails}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </div>
        ) : (
          <>
            <div className="flex justify-center items-center min-h-[calc(100vh_-_470px)]">
              <h2 className="text-[20px] text-primary-200 font-medium font-inter">
                Individual Details Updated!
              </h2>
            </div>

            <h2 className="text-neutralBlack font-medium text-[16px] mt-[0.3rem]">
              Updated data for
            </h2>
            <div className="flex flex-wrap">
              {updatedOptions.map((client, index) => {
                return (
                  <UpdatedClientCard
                    key={index}
                    ind={index}
                    client={client}
                    handleCancelDetails={handleCancelDetails}
                  />
                );
              })}
            </div>
          </>
        )}
        <div className="flex items-center justify-end mt-6 w-full">
          <Link className="mr-4" to="/clientinput/familydetails">
            <InvestedgeButton
              onClick={() =>
                dispatch(
                  PostEventLoggingData({
                    module_name: "Client Details",
                    event_type: "Prev button",
                    url: window.location.href,
                    note: `Individual Details`,
                  })
                )
              }
            >
              Back
            </InvestedgeButton>
          </Link>
          {validationFlag ? (
            <Link
              to={{
                pathname: "/clientinput/investmentprofile",
                state: {
                  familyMembers: familyMembers,
                },
              }}
            >
              <InvestedgeButton onClick={handleNextClick}>
                Next
              </InvestedgeButton>
            </Link>
          ) : (
            <InvestedgeButton disabled>Next</InvestedgeButton>
          )}
        </div>
      </div>
    </>
  );
}
