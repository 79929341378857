import React, { useEffect, useState } from "react";
import "../../static/css/existing/dashboard_styles.css";
import { useSelector, useDispatch } from "react-redux";
import {
  OpenSnackbar,
  PostEventLoggingData,
  UpdateInvestmentPolicyData,
} from "../../actions/actions";
import InvestedgeModal from "../../components/modal/InvestedgeModal";
import { IoIosClose } from "react-icons/io";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import { InputAdornment, OutlinedInput } from "@mui/material";
export default function InvestmentPolicyModal({
  onClose,
  setFamilyMembers2,
  selected,
  ipsmodal,
}) {
  const dispatch = useDispatch();
  const reduxData = useSelector((state) => state.clientDashboardData);
  const [tempfamilyMem, settempfamilyMem] = useState(
    JSON.parse(JSON.stringify(reduxData)).map((y) => y)
  );
  useEffect(() => {
    settempfamilyMem(JSON.parse(JSON.stringify(reduxData)).map((y) => y));
  }, [reduxData]);
  const [errors, setErrors] = useState(null);
  useEffect(() => {
    if (
      parseInt(tempfamilyMem[selected.optionid].ips.equity.target) +
        parseInt(tempfamilyMem[selected.optionid].ips.debt.target) +
        parseInt(tempfamilyMem[selected.optionid].ips.alternate.target) !==
      100
    )
      setErrors("Sum of Equity, Debt and Alternates should be 100");
    else if (
      parseInt(tempfamilyMem[selected.optionid].ips.large_cap.target) +
        parseInt(tempfamilyMem[selected.optionid].ips.mid_cap.target) +
        parseInt(tempfamilyMem[selected.optionid].ips.small_cap.target) !==
      100
    )
      setErrors("Sum of Large Cap, Mid Cap and Small Cap should be 100");
    else if (
      !tempfamilyMem[selected.optionid].ips.large_cap.target ||
      !tempfamilyMem[selected.optionid].ips.mid_cap.target ||
      !tempfamilyMem[selected.optionid].ips.small_cap.target ||
      !tempfamilyMem[selected.optionid].ips.equity.target ||
      !tempfamilyMem[selected.optionid].ips.debt.target ||
      !tempfamilyMem[selected.optionid].ips.alternate.target ||
      !tempfamilyMem[selected.optionid].ips.aaa_equiv.target ||
      !tempfamilyMem[selected.optionid].ips.alternates.target ||
      !tempfamilyMem[selected.optionid].ips.duration.target
    )
      setErrors("Target of assets cannot be empty");
    else setErrors(null);
    // eslint-disable-next-line
  }, [tempfamilyMem]);

  if (!ipsmodal) return null;
  const tableFields = {
    equity: {
      name: "Equity",
      min: Math.max(
        0,
        tempfamilyMem[selected.optionid].ips.equity.flexibility !== 0
          ? tempfamilyMem[selected.optionid].ips.equity.target -
              tempfamilyMem[selected.optionid].ips.equity.flexibility
          : tempfamilyMem[selected.optionid].ips.equity.target
      ).toFixed(0),
      max: Math.min(
        tempfamilyMem[selected.optionid].ips.equity.flexibility !== 0
          ? parseInt(tempfamilyMem[selected.optionid].ips.equity.target) +
              parseInt(tempfamilyMem[selected.optionid].ips.equity.flexibility)
          : tempfamilyMem[selected.optionid].ips.equity.target,
        100
      ).toFixed(0),
    },
    debt: {
      name: "Debt",
      min: Math.max(
        0,
        tempfamilyMem[selected.optionid].ips.debt.flexibility !== 0
          ? tempfamilyMem[selected.optionid].ips.debt.target -
              tempfamilyMem[selected.optionid].ips.debt.flexibility
          : tempfamilyMem[selected.optionid].ips.debt.target
      ).toFixed(0),
      max: Math.min(
        tempfamilyMem[selected.optionid].ips.debt.flexibility !== 0
          ? parseInt(tempfamilyMem[selected.optionid].ips.debt.target) +
              parseInt(tempfamilyMem[selected.optionid].ips.debt.flexibility)
          : tempfamilyMem[selected.optionid].ips.debt.target,
        100
      ).toFixed(0),
    },
    alternate: {
      name: "Alternates",
      min: Math.max(
        0,
        tempfamilyMem[selected.optionid].ips.alternate.flexibility !== 0
          ? tempfamilyMem[selected.optionid].ips.alternate.target -
              tempfamilyMem[selected.optionid].ips.alternate.flexibility
          : tempfamilyMem[selected.optionid].ips.alternate.target
      ).toFixed(0),
      max: Math.min(
        tempfamilyMem[selected.optionid].ips.alternate.flexibility !== 0
          ? parseInt(tempfamilyMem[selected.optionid].ips.alternate.target) +
              parseInt(
                tempfamilyMem[selected.optionid].ips.alternate.flexibility
              )
          : tempfamilyMem[selected.optionid].ips.alternate.target,
        100
      ).toFixed(0),
    },
    large_cap: {
      name: "Large cap",
      min: Math.max(
        0,
        tempfamilyMem[selected.optionid].ips.large_cap.flexibility !== 0
          ? tempfamilyMem[selected.optionid].ips.large_cap.target -
              tempfamilyMem[selected.optionid].ips.large_cap.flexibility
          : tempfamilyMem[selected.optionid].ips.large_cap.target
      ).toFixed(0),
      max: Math.min(
        tempfamilyMem[selected.optionid].ips.large_cap.flexibility !== 0
          ? parseInt(tempfamilyMem[selected.optionid].ips.large_cap.target) +
              parseInt(
                tempfamilyMem[selected.optionid].ips.large_cap.flexibility
              )
          : tempfamilyMem[selected.optionid].ips.large_cap.target,
        100
      ).toFixed(0),
    },
    mid_cap: {
      name: "Mid cap",
      min: Math.max(
        0,
        tempfamilyMem[selected.optionid].ips.mid_cap.flexibility !== 0
          ? tempfamilyMem[selected.optionid].ips.mid_cap.target -
              tempfamilyMem[selected.optionid].ips.mid_cap.flexibility
          : tempfamilyMem[selected.optionid].ips.mid_cap.target
      ).toFixed(0),
      max: Math.min(
        tempfamilyMem[selected.optionid].ips.mid_cap.flexibility !== 0
          ? parseInt(tempfamilyMem[selected.optionid].ips.mid_cap.target) +
              parseInt(tempfamilyMem[selected.optionid].ips.mid_cap.flexibility)
          : tempfamilyMem[selected.optionid].ips.mid_cap.target,
        100
      ).toFixed(0),
    },
    small_cap: {
      name: "Small cap",
      min: Math.max(
        0,
        tempfamilyMem[selected.optionid].ips.small_cap.flexibility !== 0
          ? tempfamilyMem[selected.optionid].ips.small_cap.target -
              tempfamilyMem[selected.optionid].ips.small_cap.flexibility
          : tempfamilyMem[selected.optionid].ips.small_cap.target
      ).toFixed(0),
      max: Math.min(
        tempfamilyMem[selected.optionid].ips.small_cap.flexibility !== 0
          ? parseInt(tempfamilyMem[selected.optionid].ips.small_cap.target) +
              parseInt(
                tempfamilyMem[selected.optionid].ips.small_cap.flexibility
              )
          : tempfamilyMem[selected.optionid].ips.small_cap.target,
        100
      ).toFixed(0),
    },

    aaa_equiv: {
      name: "Credit risk",
      min: Math.max(
        0,
        tempfamilyMem[selected.optionid].ips.aaa_equiv.flexibility !== 0
          ? tempfamilyMem[selected.optionid].ips.aaa_equiv.target -
              tempfamilyMem[selected.optionid].ips.aaa_equiv.flexibility
          : tempfamilyMem[selected.optionid].ips.aaa_equiv.target
      ).toFixed(0),
      max: Math.min(
        tempfamilyMem[selected.optionid].ips.aaa_equiv.flexibility !== 0
          ? parseInt(tempfamilyMem[selected.optionid].ips.aaa_equiv.target) +
              parseInt(
                tempfamilyMem[selected.optionid].ips.aaa_equiv.flexibility
              )
          : tempfamilyMem[selected.optionid].ips.aaa_equiv.target,
        100
      ).toFixed(0),
    },
    alternates: {
      name: "Alternates - Low vol",
      min: Math.max(
        0,
        tempfamilyMem[selected.optionid].ips.alternates.flexibility !== 0
          ? tempfamilyMem[selected.optionid].ips.alternates.target -
              tempfamilyMem[selected.optionid].ips.alternates.flexibility
          : tempfamilyMem[selected.optionid].ips.alternates.target
      ).toFixed(0),
      max: Math.min(
        tempfamilyMem[selected.optionid].ips.alternates.flexibility !== 0
          ? parseInt(tempfamilyMem[selected.optionid].ips.alternates.target) +
              parseInt(
                tempfamilyMem[selected.optionid].ips.alternates.flexibility
              )
          : tempfamilyMem[selected.optionid].ips.alternates.target,
        100
      ).toFixed(0),
    },
  };

  const tableHeaders = ["Asset/rule", "Target", "Flexibility", "Min", "Max"];
  const handleClick = () => {
    settempfamilyMem(JSON.parse(JSON.stringify(reduxData)).map((y) => y));
    onClose();
  };
  return (
    <InvestedgeModal
      aria-labelledby="Investment Policy Statement for Client Dashboard"
      aria-describedby="Investment Policy Statement for Client Dashboard"
      sx={{ padding: "1rem" }}
      open={ipsmodal}
      onClose={handleClick}
    >
      <div className="w-full flex justify-between">
        <h5 className="card-heading">Investment Policy Statement</h5>
        <IoIosClose size={30} onClick={handleClick} />
      </div>

      <div>
        <p className="text-[14px] mb-0 mt-[8px] font-inter">
          An investment policy statement (IPS) is a document drafted between a
          portfolio manager and a client that outlines general rules for the
          manager. Specific information on matters such as asset allocation,
          risk tolerance, and liquidity requirements are included in an
          investment policy statement.
        </p>

        <p className="text-error-200 text-[12px] font-semibold h-[16px] mb-0 mt-[4px]">
          {errors && errors}
        </p>

        <div className=" mt-[4px]">
          <table className="w-full rounded-[6px] investedge-table">
            <colgroup>
              <col width="26%" />
              <col width="20%" />
              <col width="20%" />
              <col width="17%" />
              <col width="17%" />
            </colgroup>
            <tbody>
              <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                {tableHeaders.map((tableHeader, index) => (
                  <th
                    key={index}
                    className="font-medium p-[8px] border-b border-greyBorder"
                    style={
                      index ? { textAlign: "center" } : { textAlign: "left" }
                    }
                  >
                    {tableHeader}
                  </th>
                ))}
              </tr>

              {Object.keys(tableFields).map((key, index) => {
                return (
                  <tr className="border-bottom text-sm" key={index}>
                    <td className="text-left p-[8px]">
                      {tableFields[key].name}
                    </td>
                    <td className="w-[100%] flex justify-center text-left p-[8px]">
                      <OutlinedInput
                        size="small"
                        className="text-sm"
                        required
                        style={{
                          fontFamily: "Inter,sans-sarif",
                          fontWeight: 400,
                          width: "65%",
                        }}
                        value={tempfamilyMem[selected.optionid].ips[key].target}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            let newfamilyMembers = [...tempfamilyMem];
                            newfamilyMembers[selected.optionid].ips[
                              key
                            ].target = e.target.value;
                            settempfamilyMem(newfamilyMembers);
                          }
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <span className="text-sm font-inter">%</span>
                          </InputAdornment>
                        }
                      />
                    </td>
                    <td className="text-left p-[8px]">
                      <div className="w-[100%] flex justify-center">
                        <OutlinedInput
                          size="small"
                          className="text-sm"
                          required
                          style={{
                            fontFamily: "Inter,sans-sarif",
                            fontWeight: 400,
                            width: "65%",
                          }}
                          value={
                            tempfamilyMem[selected.optionid].ips[key]
                              .flexibility
                          }
                          onChange={(e) => {
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              let newfamilyMembers = [...tempfamilyMem];
                              newfamilyMembers[selected.optionid].ips[
                                key
                              ].flexibility = e.target.value;
                              settempfamilyMem(newfamilyMembers);
                            }
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <span className="text-sm font-inter">%</span>
                            </InputAdornment>
                          }
                        />
                      </div>
                    </td>
                    <td className="text-center p-[8px]">
                      {tableFields[key].min}%
                    </td>
                    <td className=" text-center p-[8px]">
                      {tableFields[key].max}%
                    </td>
                  </tr>
                );
              })}
              <tr className="border-bottom text-sm">
                <td className="text-left p-[8px]">Duration</td>
                <td className="w-[100%] flex justify-center p-[8px] text-left">
                  <OutlinedInput
                    size="small"
                    className="text-sm"
                    required
                    style={{
                      fontFamily: "Inter,sans-sarif",
                      fontWeight: 400,
                      width: "65%",
                    }}
                    value={tempfamilyMem[selected.optionid].ips.duration.target}
                    onChange={(e) => {
                      const re = /^\d*\.?\d*$/;
                      if (e.target.value === "" || re.test(e.target.value)) {
                        let newfamilyMembers = [...tempfamilyMem];
                        newfamilyMembers[
                          selected.optionid
                        ].ips.duration.target = e.target.value;
                        settempfamilyMem(newfamilyMembers);
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <span className="text-sm font-inter">yrs</span>
                      </InputAdornment>
                    }
                  />
                </td>

                <td className="text-left p-[8px]">
                  <div className="w-[100%] flex justify-center">
                    <OutlinedInput
                      size="small"
                      className="text-sm"
                      required
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "65%",
                      }}
                      value={
                        tempfamilyMem[selected.optionid].ips.duration
                          .flexibility
                      }
                      onChange={(e) => {
                        const re = /^\d*\.?\d*$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          let newfamilyMembers = [...tempfamilyMem];
                          newfamilyMembers[
                            selected.optionid
                          ].ips.duration.flexibility = e.target.value;
                          settempfamilyMem(newfamilyMembers);
                        }
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <span className="text-sm font-inter">yrs</span>
                        </InputAdornment>
                      }
                    />
                  </div>
                </td>
                <td className="text-center p-[8px]">
                  {`${Math.max(
                    0,
                    tempfamilyMem[selected.optionid].ips.duration.target -
                      tempfamilyMem[selected.optionid].ips.duration.flexibility
                  )}yrs`}
                </td>
                <td className="text-center p-[8px]">{`${Math.min(
                  5,
                  tempfamilyMem[selected.optionid].ips.duration.target +
                    tempfamilyMem[selected.optionid].ips.duration.flexibility
                )}yrs`}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        className="flex justify-end mt-2 w-[98%]"
        style={{ paddingRight: 0 }}
      >
        <InvestedgeButton
          disabled={errors}
          onClick={() => {
            !errors && setFamilyMembers2(tempfamilyMem);
            !errors && dispatch(UpdateInvestmentPolicyData(tempfamilyMem));
            !errors &&
              dispatch(
                OpenSnackbar({
                  severity: "success",
                  message: `IPS for ${localStorage.getItem(
                    "clientName"
                  )} updated`,
                })
              );
            !errors &&
              dispatch(
                PostEventLoggingData({
                  module_name: "Investment Profile",
                  event_type: "Save button",
                  url: window.location.href,
                  note: `IPS for ${localStorage.getItem("clientName")} updated`,
                })
              );
            !errors && onClose();
          }}
        >
          Submit
        </InvestedgeButton>
      </div>
    </InvestedgeModal>
  );
}
