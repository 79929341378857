import React, { useState, useEffect } from "react";
import {
  Link,
  //  useParams
} from "react-router-dom";
import { BsDot } from "react-icons/bs";
import "../assets/css/style.css";
import $ from "jquery";
// import "../assets/vendor/icofont/icofont.min.css";
import urls from "../urls.js";

function Filtered(temp) {
  // const { id } = useParams();
  const [blogs, setBlogs] = useState();
  const [visible, setVisible] = useState(4);
  const [currentBlogs, setCurrentBlogs] = useState();

  const loadMoreItems = () => {
    setVisible((preValue) => preValue + 2);
  };

  useEffect(() => {
    $("a.active").click(function () {
      $("a.active").css({
        color: "black",
        "border-bottom": "3px solid #fff",
      });
      $(this).css({ color: "#0000F5", "border-bottom": "3px solid #0000F5" });
    });
    const getData = async () => {
      await fetch(urls.blogsURL)
        .then((resp) => resp.json())
        .then((data) => {
          setBlogs(data);
          setCurrentBlogs(data);
        });
    };
    getData();
  }, []);

  const tagDictionary = {
    M: "Markets",
    P: "Perspectives",
    R: "Research",
    I: "Impact Stories",
    Re: "Reports",
  };

  const markets = [];
  const perspectives = [];
  const research = [];
  const impactStories = [];
  const reports = [];
  const all = [];

  blogs &&
    blogs.map((blog) => {
      if (blog.tag === "M") {
        return markets.push(blog);
      }
      if (blog.tag === "P") {
        return perspectives.push(blog);
      }
      if (blog.tag === "R") {
        return research.push(blog);
      }
      if (blog.tag === "I") {
        return impactStories.push(blog);
      }
      if (blog.tag === "Re") {
        return reports.push(blog);
      }
      return all.push(blog);
    });

  function displayFilter(temp) {
    return setCurrentBlogs(temp);
  }

  return (
    <>
      <div className="topnav" id="topnavchange">
        <a
          href="!#"
          className="active mr-5 text-sm font-weight-bold"
          style={{ borderBottom: "3px solid #0000F5", color: "#0000F5" }}
          onClick={() => {
            displayFilter(all);
          }}
        >
          All
        </a>
        <a
          href="!#"
          className="active mr-5 text-sm font-weight-bold"
          onClick={() => {
            displayFilter(markets);
          }}
        >
          Markets
        </a>
        <a
          href="!#"
          className="active mr-5 text-sm font-weight-bold"
          onClick={() => {
            displayFilter(perspectives);
          }}
        >
          Perspectives
        </a>
        <a
          href="!#"
          className="active mr-5 text-sm font-weight-bold"
          onClick={() => {
            displayFilter(impactStories);
          }}
        >
          Impact Stories
        </a>
        <a
          href="!#"
          className="active mr-5 text-sm font-weight-bold"
          onClick={() => {
            displayFilter(reports);
          }}
        >
          Reports
        </a>
        <a
          href="!#"
          className="active mr-5 text-sm font-weight-bold"
          onClick={() => {
            displayFilter(research);
          }}
        >
          Research
        </a>
      </div>
      <div className="row">
        {currentBlogs &&
          currentBlogs.slice(0, visible).map((blog) => (
            <div className="col-6 mt-4">
              <Link to={`/individualblog/${blog.id}`}>
                <h6 className="font-semibold">{blog.title}</h6>
              </Link>
              <h6
                style={{ display: "inline" }}
                className="text-xs font-weight-light"
              >
                {blog.pub_date}
              </h6>
              <BsDot className="text-gray-500" style={{ display: "inline" }} />
              <h6
                style={{ display: "inline" }}
                className="text-xs font-weight-light"
              >
                {blog.author}
              </h6>{" "}
              <BsDot className="text-gray-500" style={{ display: "inline" }} />
              <h6
                style={{ display: "inline" }}
                className="text-xs font-weight-light"
              >
                {blog.duration} min
              </h6>
              <BsDot className="text-gray-500" style={{ display: "inline" }} />
              <h6
                style={{ display: "inline" }}
                className="text-xs font-weight-light"
              >
                {tagDictionary[blog.tag]}
              </h6>
            </div>
          ))}
      </div>
      <button
        className="btn btn-primary btn-get-started scrollto make-it-slow mt-5"
        style={{ backgroundColor: "#F0F0FF", color: "#0000F5" }}
        onClick={loadMoreItems}
      >
        <p className="font-semibold" style={{ display: "inline" }}>
          LOAD MORE
        </p>
      </button>
    </>
  );
}

export default Filtered;
