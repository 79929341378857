import React, { useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import AfterloginApp from "../../AfterloginApp";
import PortfolioFirstStep from "./firstStep/PortfolioFirstStep";
import PortfolioSecondStep from "./secondStep/PortfolioSecondStep";
import PortfolioThirdStep from "./thirdStep/PortfolioThirdStep";
import PortfolioFourthStep from "./fourthStep/PortfolioFourthStep";
import PortfolioFifthStep from "./fifthStep/PortfolioFifthStep";
import PortfolioSixthStep from "./sixthStep/PortfolioSixthStep";
export default function LumpsumPlusSip() {
  const labels = [
    "Client Details",
    "Proposal Requirements",
    "Asset Allocation & Return Simulator",
    "Portfolio & Analysis",
    "Template Selection",
    "Download",
  ];
  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <PortfolioFirstStep
            handleNext={handleNext}
            formValue={formValue}
            setFormValue={setFormValue}
          />
        );
      case 1:
        return (
          <PortfolioSecondStep
            handleBack={handleBack}
            handleNext={handleNext}
            formValue={formValue}
            setFormValue={setFormValue}
            proposalType={proposalType}
            setProposalType={setProposalType}
            sipProposalType={sipProposalType}
            setSipProposalType={setSipProposalType}
            portfolioId={portfolioId}
            setPortfolioId={setPortfolioId}
            sipPortfolioId={sipPortfolioId}
            setSipPortfolioId={setSipPortfolioId}
            handleModelPortfolioNext={handleModelPortfolioNext}
            setPortfolioProducts={setPortfolioProducts}
            setSipPortfolioProducts={setSipPortfolioProducts}
          />
        );
      case 2:
        return (
          <PortfolioThirdStep
            handleBack={handleBack}
            handleNext={handleNext}
            formValue={formValue}
            setFormValue={setFormValue}
            proposalType={proposalType}
            sipProposalType={sipProposalType}
            setPortfolioProducts={setPortfolioProducts}
            setSipPortfolioProducts={setSipPortfolioProducts}
          />
        );
      case 3:
        return (
          <PortfolioFourthStep
            handleBack={handleBack}
            handleNext={handleNext}
            formValue={formValue}
            portfolioProducts={portfolioProducts}
            setPortfolioProducts={setPortfolioProducts}
            sipPortfolioProducts={sipPortfolioProducts}
            setSipPortfolioProducts={setSipPortfolioProducts}
            portfolioId={portfolioId}
            proposalType={proposalType}
            sipProposalType={sipProposalType}
            handleModelPortfolioBack={handleModelPortfolioBack}
            purposePortfolio={formValue.purposePortfolio}
          />
        );
      case 4:
        return (
          <PortfolioFifthStep
            handleBack={handleBack}
            handleNext={handleNext}
            formValue={formValue}
            portfolioProducts={portfolioProducts}
          />
        );
      case 5:
        return (
          <PortfolioSixthStep
            handleBack={handleBack}
            handleNext={handleNext}
            formValue={formValue}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };
  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleModelPortfolioNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 2);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleModelPortfolioBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 2);
  };
  // const QontoConnector = styled(StepConnector)(({ theme }) => ({
  //   [`&.${stepConnectorClasses.active}`]: {
  //     [`& .${stepConnectorClasses.line}`]: {
  //       borderColor: "#784af4",
  //       outerWidth: 3,
  //     },
  //   },
  //   [`&.${stepConnectorClasses.completed}`]: {
  //     [`& .${stepConnectorClasses.line}`]: {
  //       borderColor: "#784af4",
  //     },
  //   },
  // }));
  const [formValue, setFormValue] = useState({
    client_name: "",
    proposal_value: { amount: "", denomination: "₹ C" },
    sip_proposal_value: {
      amount: "",
      denomination: "L",
      installments: "",
      duration_of_sip: 1,
      frequency_of_sip: "Monthly",
      top_up: { type: "L", value: "" },
      frequency_of_topup: "Quarterly",
      risk_score: 50,
      schemes: "",
    },
    tax_status: "Resident",
    schemes: "",
    tax_slab: "₹ 2.5L - 5L",
    risk_score: 50,
    portfolioData: null,
    investment_horizon: "",
    type_of_scheme: "Regular",
    purposePortfolio: "Wealth Creation",
    allocation_preference: {
      equity: 0.0,
      debt: 0.0,
      alternate: 0.0,
      large: 0.0,
      mid: 0.0,
      small: 0.0,
      AAA: 0.0,
      duration: 0.0,
      low_volatility: 0.0,
    },
    sip_allocation_preference: {
      equity: 0.0,
      debt: 0.0,
      alternate: 0.0,
      large: 0.0,
      mid: 0.0,
      small: 0.0,
      AAA: 0.0,
      duration: 0.0,
      low_volatility: 0.0,
    },
  });
  const [portfolioProducts, setPortfolioProducts] = useState([]);
  const [proposalType, setProposalType] = useState(0);
  const [portfolioId, setPortfolioId] = useState("");
  //
  const [sipPortfolioProducts, setSipPortfolioProducts] = useState([]);
  const [sipProposalType, setSipProposalType] = useState(0);
  const [sipPortfolioId, setSipPortfolioId] = useState("");
  return (
    <>
      <div className="parentLoggedinScreen">
        <AfterloginApp />
        <div className="loggedinscreen-container">
          <div className="loggedinscreen">
            <div className="px-8 rounded-[8px] w-full bg-white">
              <div className="h-[20%]">
                <Stepper
                  activeStep={activeStep}
                  sx={{ py: 3 }}
                  alternativeLabel
                >
                  {labels.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </div>
            <div className="h-[80%]">{handleSteps(activeStep)}</div>
          </div>
        </div>
      </div>
    </>
  );
}
