import { GET_SIP_ANALYTICS_API } from "../actions/type";
const initialState = {
  asset_allocation: {
    Equity: 0,
    Debt: 0,
    Alternate: 0,
  },
  product_allocation: {
    "Mutual Fund": 1.0,
  },
  port_return: 0,
  bm_blended_return: 0,
  total_products: 0,
  liquidity: 0,
  market_cap: {
    "Large Cap": [0, 0],
    "Mid Cap": [0, 0],
    "Small Cap": [0, 0],
  },
  top5_sector: {
    Financials: [0, 0],
    "Consumer Discretionary": [0, 0],
    "Health Care": [0, 0],
    Materials: [0, 0],
    "Information Technology": [0, 0],
  },
  top5_stocks: {
    "ICICI Bank Ltd.": 0,
    "HDFC Bank Ltd.": 0,
    "Axis Bank Ltd.": 0,
    "ITC Ltd.": 0,
    "State Bank Of India": 0,
  },
  debt_quants: {
    duration: 0,
    gross_ytm: 0,
    maturity: 0,
  },
  rating_allocation: {
    A: 0,
    AA: 0,
    AAA: 0,
    B: 0.0,
    BB: 0.0,
    BBB: 0.0,
    C: 0.0,
    Cash: 0.0,
    D: 0.0,
    SOV: 0.0,
    Unrated: 0.0,
  },
  top5_debt_instruments: {
    "GOI FRB 22-Sep-2033": 0.0,
    "Mangalore Refinery & Petrochemicals Ltd. -SR-4 07.48% (14-Apr-32)": 0.0,
    "NTPC Ltd. SR-76 06.74% (14-Apr-32)": 0.0,
    "National Highways Authority of India - SR-III 06.87% (14-Apr-32)": 0.0,
    "Power Finance Corporation Ltd. SR-214 6.92% (14-Apr-32)": 0.0,
  },
};
export default function GetSIPAnalytics(state = initialState, action) {
  switch (action.type) {
    case GET_SIP_ANALYTICS_API:
      return action.payload;
    default:
      return state;
  }
}
