import React from "react";
import UploadStatementCards from "./UploadStatementCards";
import { useSelector } from "react-redux";
export default function WelcomeUpdateScreen({ setRoute, setStatementType }) {
  const handleCardClick = (e, name, route) => {
    setRoute(route);
    setStatementType(name);
  };
  const portfolioValue = useSelector(
    (store) => store.dashboardPortfolioPriceList.total_portfolio_value
  );
  const parsePortfolio = (portfolio) => {
    if (portfolio / 1000 < 100)
      return `₹ ${parseFloat(portfolio / 1000).toFixed(1)} K`;
    if (portfolio / 100000 < 100)
      return `₹ ${parseFloat(portfolio / 100000).toFixed(1)} L`;
    return `₹ ${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  };
  return (
    <div className="flex flex-col justify-center items-center h-[90%]">
      <p className="font-inter text-[18px] text-neutralBlack mb-[1rem]">
        Welcome
      </p>
      <p className="font-inter  text-[16px] text-neutralBlack mb-[1rem]">
        Total portfolio value: {parsePortfolio(portfolioValue)}
      </p>
      <p className="font-inter  text-[14px] text-neutralBlack mb-[1rem]">
        You can upload the portfolio using the standard statements, manually
        edit them in each tab, or use a custom excel template
      </p>
      <div className="flex w-[100%] justify-center items-center pt-[1rem] space-x-6">
        <UploadStatementCards
          handleCardClick={handleCardClick}
          label={"CAMS CAS"}
          locked={false}
          route="uploadstatementscreen"
        />
        <UploadStatementCards
          handleCardClick={handleCardClick}
          label={"MF Central"}
          locked={false}
          route="mfcentralscreen"
        />
        {/* <UploadStatementCards
          handleCardClick={handleCardClick}
          label={"MF Central"}
          locked={true}
          route="mfcentralscreen"
        />
        <UploadStatementCards
          handleCardClick={handleCardClick}
          label={"NSDL/CSDL CAS"}
          locked={true}
          route="uploadstatementscreen"
        />
        <UploadStatementCards
          handleCardClick={handleCardClick}
          label={"demat"}
          locked={true}
          route="uploadstatementscreen"
        />
        <UploadStatementCards
          handleCardClick={handleCardClick}
          label={"wealth account"}
          locked={true}
          route="uploadstatementscreen"
        /> */}
      </div>
      <p className="font-inter  text-[14px] text-neutralBlack mt-[2rem] mb-[1rem]">
        Or
      </p>
      <p
        onClick={() => setRoute("uploadexcelscreen")}
        className="font-inter text-primary-200 cursor-pointer  text-[14px] mb-[1rem]"
      >
        Download and upload excel template
      </p>
    </div>
  );
}
