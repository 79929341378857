import { GET_REVIEW_PORTFOLIO_IPS_TRACKER } from "../actions/type";
const initialState = [
  [
    "Criteria",
    "Current Allocation (%)",
    "Desired Allocation (%)",
    "Deviation (%)",
    "Action Required?",
  ],
  ["Broad Asset Allocation", " ", " ", " ", " "],
  ["Equity", 90.9, 61.0, 29.9, "Yes. Outside desired range"],
  ["Debt", 9.1, 35.1, -26.0, "Yes. Outside desired range"],
  ["Alternate", 0, 3.9, -3.9, "Yes. Outside desired range"],
  ["Debt Asset Class", " ", " ", " ", " "],
  ["Duration", "0.2 years", "3 years", "-2.8 years", "No. It's within limits"],
  ["Min. AAA allocation", 98.6, 75.0, 23.6, "No. It's better than desired"],
  ["Equity Asset Class", " ", " ", " ", " "],
  ["Min. Large Cap", 73.1, 50.0, 23.1, "No. It's better than desired"],
  ["Overall Constraints", " ", " ", " ", " "],
  ["Max. Scheme Exposure", 17.0, 10.0, 7.0, "Yes. It's above desired"],
  ["Max. AMC Exposure", 24.9, 25.0, -0.1, "No. It's within limits"],
  ["Min. Liquidity", 100.0, 70.0, 30.0, "Yes. It's above desired"],
];
export default function GetReviewPortfolioIPSTracker(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_REVIEW_PORTFOLIO_IPS_TRACKER:
      return action.payload;
    default:
      return state;
  }
}
