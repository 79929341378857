import React, { useEffect } from "react";
import AfterloginApp from "../../rmdashboard/AfterloginApp";
import "../../static/css/existing/dashboard_styles.css";
import SettingsSidebar from "../SettingsSidebar";
import ModuleContent from "./ModuleContent";
import { useDispatch, useSelector } from "react-redux";
import {
  GetOperations,
  GetOperationsArray,
  GetRmArray,
  GetRminfo,
  GetTeamLead,
} from "../../actions/actions";
export default function Module() {
  const dispatch = useDispatch();
  const userdata = useSelector((state) => state.userdata);
  useEffect(() => {
    userdata.role !== "O" && userdata.role !== "SO" && dispatch(GetRminfo());
    dispatch(GetOperations());
    dispatch(GetTeamLead());
    dispatch(GetRmArray());
    dispatch(GetOperationsArray());
    // eslint-disable-next-line
  }, []);
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Settings"}
        description={"Update and configure your information"}
      />
      <div className="loggedinscreen-container pt-0">
        <div className="loggedinscreen flex flex-row justify-between">
          <SettingsSidebar />
          <div className="p-[1rem] bg-[#FFF] min-h-[calc(100vh_-_116px)] rounded-[0.5rem] w-[79%] mt-4">
            <ModuleContent />
          </div>
        </div>
      </div>
    </div>
  );
}
