import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import CamsCas from "./CamsCas";
import MFCentral from "./MFCentral";

export default function Type() {
  const { type } = useParams();
  const handleType = (type) => {
    switch (type) {
      case "mfcentral":
        return <MFCentral />;

      case "cams":
        return <CamsCas />;

      default:
        return <div>No such type</div>;
    }
  };
  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      return (event.returnValue = "Are you sure you want to exit?");
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  return (
    <div className="parentLoggedinScreen">
      {/* <AfterloginApp
        description={
          "This is your dashboard. Access all of your latest updates here."
        }
      /> */}
      <div className="loggedinscreen-container min-h-full h-full">
        <div className="loggedinscreen h-full ">
          <div className=" portfolio-proposal-body-card min-h-[calc(100vh-56px)] shadow-none border-none relative flex flex-col justify-center">
            {handleType(type)}
          </div>
        </div>
      </div>
    </div>
  );
}
