import React from "react";
import { quants } from "../onePagerConsts";
import "../../../static/css/existing/dashboard_styles.css";

export default function quantsComponent() {
  return (
    <>
      <div
        style={{
          display: "flex",
          marginTop: "1rem",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            padding: "1rem",
            backgroundColor: "#fff",
            borderRadius: "6px",
            boxShadow:
              " 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(0, 0, 0, 0.05)",
            minheight: "274px",
            width: "49.2%",
          }}
        >
          <h5 className="onepager-small-heading  mb-4">Quants</h5>
          {/* #11 Contains quants card items */}
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {Object.entries(quants.data).map(([key, value]) => (
              <div style={{ marginRight: "10%" }}>
                <p className="onepager-description-text">{value["value"]}</p>
                <p
                  style={{ color: "#939393" }}
                  className="onepager-description-text mb-4 -mt-4"
                >
                  {value["ratio"]}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div
            style={{
              padding: "1rem",
              backgroundColor: "#7D7AF9",
              color: "#fff",
              borderRadius: "6px",
              boxShadow:
                " 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(0, 0, 0, 0.05)",
              height: "205px",
              width: "544px",
            }}
          >
            <h3
              style={{
                fontFamily: "Inter , sans-serif",
                fontWeight: "800",
                color: "#ffffff",
                marginBottom: "20px",
              }}
            >
              Investment in last 1 year
            </h3>
            <h4
              style={{
                fontFamily: "Inter , sans-serif",
                color: "EEEEEE",
                fontWeight: "700",
                fontSize: "24px",
                marginBottom: "19px",
              }}
            >
              ₹ 1,00,000 grown to ₹ 1,34,467
            </h4>
            <h4
              style={{
                fontFamily: "Inter , sans-serif",
                color: "EEEEEE",
                fontWeight: "700",
                fontSize: "24px",
              }}
            >
              ₹ 10,000 monthly SIP grown to ₹ 1,64,467
            </h4>
          </div>
        </div>
      </div>
    </>
  );
}
