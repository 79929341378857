import React, { useEffect, useState } from "react";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import InvestedgeOTP from "../../components/otp/InvestedgeOTP";
import InvestedgeTextField from "../../components/textfield/InvestedgeTextField";
// import Logo from "../../assets/img/logo_profile.svg";
import Hourglass from "../../assets/img/wait.gif";
import { Checkbox, FormControlLabel, InputAdornment } from "@mui/material";
import urls from "../../urls";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { Bars } from "react-loader-spinner";
import TNCmodal from "./TNCmodal";

export default function MFCentral() {
  const { rmID } = useParams();
  const history = useHistory();
  const [logo, setLogo] = useState("");
  const [pan, setPan] = useState("");
  const [mobile, setMobile] = useState("");
  const [modal, setModal] = useState(false);
  const [error, setError] = useState({});
  const [otp, setOtp] = useState("");
  const [permissions, setPermissions] = useState(false);
  const [screen, setScreen] = useState("status");
  const [result, setResult] = useState({});
  const handleClick = () => {
    const re_pan = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
    if (pan === "" || !re_pan.test(pan)) {
      setError({ ...error, pan: "Enter a valid PAN number" });
      return;
    } else {
      setError({ ...error, pan: "" });
    }
    if (mobile === "" || mobile.length !== 10) {
      setError({ ...error, mobile: "Enter a valid mobile number" });
      return;
    }
    setError({});
    axios
      .post(`${urls.acquireClientsMFCentralRequestURL}/${rmID}/`, {
        pan: pan,
        mobile: "+91" + mobile,
      })
      .then((response) => setResult(response.data))
      .catch(() => {});
  };
  const handleOTP = async () => {
    setScreen("loading");
    await axios
      .post(`${urls.acquireClientsMFCentralPayloadURL}/${rmID}/`, {
        reqId: result.reqId,
        otpRef: result.otpRef,
        userSubjectReference: result.userSubjectReference,
        clientRefNo: result.clientRefNo,
        otp: otp,
      })
      .then((response) => {
        history.push({
          pathname: "/instant-review/analytics",
          state: { data: response.data },
        });
      })
      .catch(() => {});
  };

  useEffect(() => {
    axios
      .get(`${urls.acquireClientsGetLogoURL}/${rmID}/logo/`)
      .then((response) => {
        setLogo(response.data.org_logo_url);
      })
      .catch(() => {});
    axios
      .get(`${urls.acquireClientsLinkStatusURL}/${rmID}/`)
      .then((response) => {
        setScreen("data");
      })
      .catch(() => {
        setScreen("expired");
      }); //eslint-disable-next-line
  }, []);

  return screen === "expired" ? (
    <div className=" w-full flex-col justify-center items-center flex p-[32px] rounded-[8px] bg-white min-h-full">
      <h2 className="text-3xl">The link has expired !</h2>
    </div>
  ) : screen === "status" ? (
    <div className=" w-full flex-col justify-center items-center flex p-[32px] rounded-[8px] bg-white min-h-full">
      <Bars color="#1982F8" />
    </div>
  ) : screen === "data" ? (
    <div className="space-y-8 m-auto w-[90%] sm:w-3/4 md:w-1/3">
      <img
        style={{
          height: "39px",
          marginTop: "auto",
          marginBottom: "auto",
          objectFit: "contain",
        }}
        src={logo}
        alt=""
        className="img-fluid mx-auto"
      />
      <div className="space-y-2">
        <h2 className="card-heading text-center">
          Instant Investment Health Check
        </h2>
        <p className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0rem] text-center">
          Provide below details to view the comprehensive report.
        </p>
      </div>
      <div className="w-full space-y-6">
        <InvestedgeTextField
          value={pan}
          onChange={(e) => {
            // const re = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
            // if (e.target.value === "" || re.test(e.target.value)) {
            //   if (e.target.value.length) setError(null);
            //   else setError("Enter Valid PAN");
            //   setPan(e.target.value);
            // }
            setPan(e.target.value);
          }}
          error={error.pan}
          helperText={error.pan}
          label="Enter PAN"
          fullWidth
        />
        <InvestedgeTextField
          value={mobile}
          onChange={(e) => {
            // const re = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
            // if (e.target.value === "" || re.test(e.target.value)) {
            //   if (e.target.value.length) setError(null);
            //   else setError("Enter Valid PAN");
            //   setPan(e.target.value);
            // }
            setMobile(e.target.value);
          }}
          error={error.mobile}
          helperText={error.mobile}
          label="Mobile no."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">+91</InputAdornment>
            ),
          }}
          fullWidth
        />
        <InvestedgeButton onClick={handleClick} className="mt-6 text-sm">
          Send OTP
        </InvestedgeButton>
      </div>
      <div className="content space-y-[20px] pt-6">
        <span className="font-inter-regular text-xs text-[#6E6E6E]">
          Enter OTP
        </span>
        <div className="flex items-center space-x-[20px]">
          <InvestedgeOTP
            id="custom-otp-input-client-onboarding-approval-approval_otp"
            inputStyle={{
              backgroundColor: "#ffffff",
              border: "2px solid #E2E2E2",
              color: "#3d3d3d",
            }}
            disabled={!result.otpRef}
            value={otp}
            onChange={(value) => setOtp(value)}
            // error={Boolean(error)}
            // helperText={error}
          />
        </div>
        <FormControlLabel
          control={
            <Checkbox
              checked={permissions}
              onChange={(e) => setPermissions(e.target.checked)}
            />
          }
          label={
            <span className="font-inter text-xs">
              I agree to{" "}
              <span
                className="text-primary-200 cursor-pointer hover:underline-offset-2"
                onClick={() => setModal(true)}
              >
                {" "}
                Term and Conditions{" "}
              </span>{" "}
              by submitting OTP
            </span>
          }
        />
        <InvestedgeButton
          className="mt-6 text-sm"
          onClick={handleOTP}
          disabled={!permissions || !result.otpRef || otp.length !== 6}
        >
          Generate Report
        </InvestedgeButton>
      </div>
      <TNCmodal modal={modal} onClose={() => setModal(false)} />
    </div>
  ) : (
    <div className=" w-full flex-col justify-center items-center flex p-[32px] rounded-[8px] bg-white min-h-full">
      <img
        alt="err"
        src={Hourglass}
        className=" min-h-[250px] object-contain"
      />
      <h3 className="portfolio-stepper-sixth-form-status">
        Sometimes, great things take time.
      </h3>
      <h3 className=" font-inter text-[14px] font-medium leading-[16px] tracking-[0em] text-center">
        Your request is under process. This may take around 10 mins.
      </h3>
    </div>
  );
}
