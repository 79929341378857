import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
function Dropdown({
  familyMembers,
  setFamilyMembers,
  index,
  handleValidation,
  setValidationFlag,
  labelName = "Select",
}) {
  /*const [isActive, setIsActive] = useState(false);
  const [referenceRef, setReferenceRef] = useState(null);
  const [popperRef, setPopperRef] = useState(null);
  const { styles, attributes } = usePopper(referenceRef, popperRef, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        enabled: true,
      },
    ],
  });
  const options = [
    "Father",
    "Mother",
    "Brother",
    "Sister",
    "Spouse",
    "Son",
    "Daughter",
    "Other",
  ];*/
  const options = [
    "Father",
    "Mother",
    "Brother",
    "Sister",
    "Spouse",
    "Son",
    "Daughter",
    "Other",
    "Self",
  ];
  return (
    <FormControl
      fullWidth
      size="small"
      sx={{
        "& .MuiInputBase-inputSizeSmall": {
          paddingTop: "10px",
          paddingBottom: "10px",
        },
      }}
      error={familyMembers[index].errors.relationship}
    >
      <InputLabel>
        <span className="font-inter font-normal text-sm">{labelName}</span>
      </InputLabel>
      <Select
        value={familyMembers[index].relationship}
        label={labelName}
        className="font-inter text-sm"
        defaultValue=""
        onChange={(e) => {
          let reg =
            (familyMembers[index].primary && e.target.value !== "Self") ||
            (!familyMembers[index].primary && e.target.value === "Self");
          if (reg) {
            let newfamilyMembers = [...familyMembers];
            newfamilyMembers[index].errors.relationship =
              "Please select a correct relationship";
            setFamilyMembers(newfamilyMembers);
            setValidationFlag(false);
          } else {
            let newfamilyMembers = [...familyMembers];
            newfamilyMembers[index].errors.relationship = null;
            setFamilyMembers(newfamilyMembers);
            setValidationFlag(true);
          }
          let newfamilyMembers = [...familyMembers];
          newfamilyMembers[index].relationship = e.target.value;
          setFamilyMembers(newfamilyMembers);
        }}
      >
        {options.map((option, ind) => (
          <MenuItem
            key={ind}
            value={option}
            id={ind}
            style={{
              display: "block",
              padding: "0.5rem 1.5rem",
              justifyContent: "flex-start",
              fontFamily: "Inter,sans-serif",
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
      {familyMembers[index].errors.relationship && (
        <FormHelperText>
          {familyMembers[index].errors.relationship}
        </FormHelperText>
      )}
    </FormControl>
    /*<div className="family-details-dropdown">
      <div
        className="family-details-dropdown-btn"
        ref={setReferenceRef}
        onClick={(e) => setIsActive(!isActive)}
      >
        {familyMembers[index]["relationship"].length == 0
          ? "Choose one"
          : familyMembers[index]["relationship"]}
        <MdIcons.MdKeyboardArrowDown
          style={{ marginTop: "0.2rem" }}
          size={17}
        />
      </div>
      {isActive && (
        <div
          ref={setPopperRef}
          style={styles.popper}
          {...attributes.popper}
          className="family-details-dropdown-content"
        >
          {options.map((option, ind) => (
            <div
              key={ind}
              onClick={() => {
                setIsActive(false);
                let newfamilyMembers = [...familyMembers];
                newfamilyMembers[index]["relationship"] = option;
                setFamilyMembers(newfamilyMembers);
                setValidationFlag(handleValidation());
              }}
              className="family-details-dropdown-item"
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>*/
  );
}

export default Dropdown;
