import { GET_MARKET_SECTOR } from "../actions/type";
const initialState = [
  {
    sector_id: "",
    sector_name: "",
    feedback: "",
    published_date: "",
    brief_text: "",
    detailed_text: "",
  },
];

export default function GetMarketSectorsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MARKET_SECTOR:
      return action.payload;
    default:
      return state;
  }
}
