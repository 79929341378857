import React, { useEffect } from "react";
import { GetSettingsAbout } from "../../actions/actions";
import AfterloginApp from "../../rmdashboard/AfterloginApp";
import "../../static/css/existing/dashboard_styles.css";
import { useDispatch } from "react-redux";
import SettingsSidebar from "../SettingsSidebar";
import AboutContent from "./AboutContent";
export default function About() {
  const dispatch = useDispatch();
  useEffect(() => {
    // AUM Dashboard Dispatch
    dispatch(GetSettingsAbout());
    // eslint-disable-next-line
  }, []);

  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Settings"}
        description={"Update and configure your information"}
      />
      <div className="loggedinscreen-container pt-0">
        <div className="loggedinscreen flex flex-row justify-between">
          <SettingsSidebar />
          <div className="p-[1rem] pt-0 bg-[#FFF] min-h-[calc(100vh_-_116px)] rounded-[0.5rem] w-[79%] mt-4">
            <AboutContent />
          </div>
        </div>
      </div>
    </div>
  );
}
