import React, { useState, useEffect } from "react";
import Dropdown from "../../rmdashboard/clientinput/individualdetails/Dropdown";
import UpdatedClientCard from "../../rmdashboard/clientinput/individualdetails/UpdatedClientCard";
import ProductUnderstandingModal from "./ProductUnderstandingModal";
import RiskAssessmentModal from "./RiskAssessmentModal";
import InvestmentPolicyModal from "./InvestmentPolicyModal";
import { useSelector, useDispatch } from "react-redux";
import {
  // GetDashboardInvestment,
  GetIpsData,
  PostDashboardInvestment,
  PostEventLoggingData,
  UpdateInvestmentPolicyData,
} from "../../actions/actions";
import Dropdown2 from "../../rmdashboard/clientinput/individualdetails/DropdownObjectFIelds";
import ReadOnlyIPS from "./ReadOnlyIPS";
import TooltipIcon from "../../assets/img/tooltip_icon.svg";
import { InputAdornment, OutlinedInput, Tooltip, Zoom } from "@mui/material";
import InvestedgeTextField from "../../components/textfield/InvestedgeTextField";
import InvestedgeSlider from "../../components/slider/InvestedgeSlider";
export default function InvestmentContent() {
  const dispatch = useDispatch();
  const clientData = useSelector((state) => state.dashboardGetInvestment);
  const [pumodal, setpumodal] = useState(false);
  const [rsmodal, setrsmodal] = useState(false);
  const [ipsmodal, setipsmodal] = useState(false);
  const [roipsmodal, setroipsmodal] = useState(false);
  const [snackbar, setSnackbar] = useState(true);
  const [options, setOptions] = useState([
    {
      optionname: localStorage.getItem("clientName"),
      optionid: 0,
    },
  ]);
  // const [validationFlag, setValidationFlag] = useState(false);
  const [updatedOptions, setUpdatedOptions] = useState([]);
  const [selected, setSelected] = useState(options[0]);
  const [familyMembers2, setFamilyMembers2] = useState([
    {
      ...clientData.ip_profile,
      investmentcorpusdenotion: `₹ ${clientData.ip_profile.investmentCorpus.denomination}`,
      investmentCorpus: clientData.ip_profile.investmentCorpus.amount,
      flexibility: "",
      ips: {
        ...clientData.ip_statement,
        equity: {
          target: clientData.ip_statement.equity.target * 100,
          flexibility: parseInt(
            (
              Math.max(
                clientData.ip_statement.equity.target -
                  clientData.ip_statement.equity.min,

                clientData.ip_statement.equity.max -
                  clientData.ip_statement.equity.target
              ) * 100
            ).toFixed(0)
          ),
          min: clientData.ip_statement.equity.min,
          max: clientData.ip_statement.equity.max,
        },
        debt: {
          target: clientData.ip_statement.debt.target * 100,
          flexibility: parseInt(
            (
              Math.max(
                clientData.ip_statement.debt.target -
                  clientData.ip_statement.debt.min,

                clientData.ip_statement.debt.max -
                  clientData.ip_statement.debt.target
              ) * 100
            ).toFixed(0)
          ),
          min: clientData.ip_statement.debt.min,
          max: clientData.ip_statement.debt.max,
        },
        alternate: {
          target: clientData.ip_statement.alternate.target * 100,
          flexibility: parseInt(
            (
              Math.max(
                clientData.ip_statement.alternate.target -
                  clientData.ip_statement.alternate.min,

                clientData.ip_statement.alternate.max -
                  clientData.ip_statement.alternate.target
              ) * 100
            ).toFixed(0)
          ),
          min: clientData.ip_statement.alternate.min,
          max: clientData.ip_statement.alternate.max,
        },
        large_cap: {
          target: clientData.ip_statement.large_cap.target * 100,
          flexibility: parseInt(
            (
              Math.max(
                clientData.ip_statement.large_cap.target -
                  clientData.ip_statement.large_cap.min,

                clientData.ip_statement.large_cap.max -
                  clientData.ip_statement.large_cap.target
              ) * 100
            ).toFixed(0)
          ),
          min: clientData.ip_statement.large_cap.min,
          max: clientData.ip_statement.large_cap.max,
        },
        mid_cap: {
          target: clientData.ip_statement.mid_cap.target * 100,
          flexibility: parseInt(
            (
              Math.max(
                clientData.ip_statement.mid_cap.target -
                  clientData.ip_statement.mid_cap.min,

                clientData.ip_statement.mid_cap.max -
                  clientData.ip_statement.mid_cap.target
              ) * 100
            ).toFixed(0)
          ),
          min: clientData.ip_statement.mid_cap.min,
          max: clientData.ip_statement.mid_cap.max,
        },
        small_cap: {
          target: clientData.ip_statement.small_cap.target * 100,
          flexibility: parseInt(
            (
              Math.max(
                clientData.ip_statement.small_cap.target -
                  clientData.ip_statement.small_cap.min,

                clientData.ip_statement.small_cap.max -
                  clientData.ip_statement.small_cap.target
              ) * 100
            ).toFixed(0)
          ),
          min: clientData.ip_statement.small_cap.min,
          max: clientData.ip_statement.small_cap.max,
        },
        duration: {
          target: clientData.ip_statement.duration.target,
          flexibility: Math.max(
            clientData.ip_statement.duration.target -
              clientData.ip_statement.duration.min,

            clientData.ip_statement.duration.max -
              clientData.ip_statement.duration.target
          ),
          min: clientData.ip_statement.duration.min,
          max: clientData.ip_statement.duration.max,
        },
        aaa_equiv: {
          target: clientData.ip_statement.aaa_equiv.target * 100,
          flexibility: parseInt(
            (
              Math.max(
                clientData.ip_statement.aaa_equiv.target -
                  clientData.ip_statement.aaa_equiv.min,

                clientData.ip_statement.aaa_equiv.max -
                  clientData.ip_statement.aaa_equiv.target
              ) * 100
            ).toFixed(0)
          ),
          min: clientData.ip_statement.aaa_equiv.min,
          max: clientData.ip_statement.aaa_equiv.max,
        },
        alternates: {
          target: clientData.ip_statement.alternates.target * 100,
          flexibility: parseInt(
            (
              Math.max(
                clientData.ip_statement.alternates.target -
                  clientData.ip_statement.alternates.min,

                clientData.ip_statement.alternates.max -
                  clientData.ip_statement.alternates.target
              ) * 100
            ).toFixed(0)
          ),
          min: clientData.ip_statement.alternates.min,
          max: clientData.ip_statement.alternates.max,
        },
      },
      understanding: clientData.ip_product_understanding,

      raq1: 1,
      raq2: 1,
      raq3: 1,
      raq4: 1,
      raq5: 1,
      sumraq: 5,
    },
  ]);

  const [riskText, setRiskText] = useState("Moderate");
  const ipsData = useSelector((state) => state.getipsdata);
  const useEffectDependancy =
    familyMembers2.length !== updatedOptions.length
      ? familyMembers2[selected.optionid].riskScore
      : "";
  useEffect(() => {
    if (familyMembers2[selected.optionid].investmentHorizon) {
      let newfamilyMembers = [...familyMembers2];

      newfamilyMembers[selected.optionid].ips.equity.target = Math.round(
        ipsData[0].equity * 100
      );
      newfamilyMembers[selected.optionid].ips.debt.target = Math.round(
        ipsData[0].debt * 100
      );
      newfamilyMembers[selected.optionid].ips.alternate.target = Math.round(
        ipsData[0].alternates * 100
      );
      newfamilyMembers[selected.optionid].ips.large_cap.target = Math.round(
        ipsData[1].large_cap * 100
      );
      newfamilyMembers[selected.optionid].ips.mid_cap.target = Math.round(
        ipsData[1].mid_cap * 100
      );
      newfamilyMembers[selected.optionid].ips.small_cap.target = Math.round(
        ipsData[1].small_cap * 100
      );
      newfamilyMembers[selected.optionid].ips.duration.target = Math.round(
        ipsData[2].duration
      );
      newfamilyMembers[selected.optionid].ips.aaa_equiv.target = Math.round(
        ipsData[3].credit_risk * 100
      );
      newfamilyMembers[selected.optionid].ips.alternates.target = Math.round(
        ipsData[4].alternates_low_vol * 100
      );
      newfamilyMembers[selected.optionid].ips.equity.flexibility =
        familyMembers2.flexibility ? parseInt(familyMembers2.flexibility) : 0;
      newfamilyMembers[selected.optionid].ips.debt.flexibility =
        familyMembers2.flexibility ? parseInt(familyMembers2.flexibility) : 0;
      newfamilyMembers[selected.optionid].ips.alternate.flexibility =
        familyMembers2.flexibility ? parseInt(familyMembers2.flexibility) : 0;
      newfamilyMembers[selected.optionid].ips.large_cap.flexibility =
        familyMembers2.flexibility ? parseInt(familyMembers2.flexibility) : 0;
      newfamilyMembers[selected.optionid].ips.mid_cap.flexibility =
        familyMembers2.flexibility ? parseInt(familyMembers2.flexibility) : 0;
      newfamilyMembers[selected.optionid].ips.small_cap.flexibility =
        familyMembers2.flexibility ? parseInt(familyMembers2.flexibility) : 0;
      newfamilyMembers[selected.optionid].ips.duration.flexibility =
        familyMembers2.flexibility ? parseInt(familyMembers2.flexibility) : 0;
      newfamilyMembers[selected.optionid].ips.aaa_equiv.flexibility =
        familyMembers2.flexibility ? parseInt(familyMembers2.flexibility) : 0;
      newfamilyMembers[selected.optionid].ips.alternates.flexibility =
        familyMembers2.flexibility ? parseInt(familyMembers2.flexibility) : 0;
      setFamilyMembers2(newfamilyMembers);
      dispatch(UpdateInvestmentPolicyData(newfamilyMembers));
    }
    // eslint-disable-next-line
  }, [ipsData]);

  useEffect(
    () => {
      if (familyMembers2.length !== updatedOptions.length) {
        if (
          familyMembers2[selected.optionid].riskScore >= 0 &&
          familyMembers2[selected.optionid].riskScore <= 20
        )
          setRiskText("Conservative");
        if (
          familyMembers2[selected.optionid].riskScore > 20 &&
          familyMembers2[selected.optionid].riskScore <= 40
        )
          setRiskText("Moderately Conservative");
        if (
          familyMembers2[selected.optionid].riskScore > 40 &&
          familyMembers2[selected.optionid].riskScore <= 60
        )
          setRiskText("Moderate");
        if (
          familyMembers2[selected.optionid].riskScore > 60 &&
          familyMembers2[selected.optionid].riskScore <= 80
        )
          setRiskText("Moderately Aggressive");
        if (
          familyMembers2[selected.optionid].riskScore > 80 &&
          familyMembers2[selected.optionid].riskScore <= 100
        )
          setRiskText("Aggressive");
        dispatch(
          GetIpsData({
            params: {
              risk_score: familyMembers2[selected.optionid].riskScore,
              investment_horizon:
                familyMembers2[selected.optionid].investmentHorizon,
            },
          })
        );
      }
    },
    // eslint-disable-next-line
    [useEffectDependancy]
  );
  let handleValidation = () => {
    // let temp = true;
    // if (updatedOptions.length != clientData.Members.length) temp = false;
    // familyMembers2.map((element) => {
    //   if (
    //     element.investmentcorpus.length === 0 ||
    //     element.investmenthorizon.length === 0 ||
    //     element.flexibility.length === 0
    //   )
    //     temp = false;
    // });
    // setValidationFlag(temp);
  };
  let handleChange = (e) => {
    const re = /^\d+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      let newfamilyMembers = [...familyMembers2];
      newfamilyMembers[selected.optionid][e.target.name] = e.target.value;
      if (e.target.name === "flexibility")
        Object.keys(newfamilyMembers[selected.optionid].ips).forEach((key) => {
          if (
            typeof newfamilyMembers[selected.optionid].ips[key] === "object"
          ) {
            newfamilyMembers[selected.optionid].ips[key].flexibility = parseInt(
              e.target.value || 0
            );
            if (key === "duration")
              newfamilyMembers[selected.optionid].ips[key].flexibility =
                parseInt(e.target.value || 0) * 0.05;
          }
        });
      if (e.target.name === "investmentHorizon" && e.target.value.length)
        dispatch(
          GetIpsData({
            params: {
              risk_score: familyMembers2[selected.optionid].riskScore,
              investment_horizon: e.target.value,
            },
          })
        );
      setFamilyMembers2(newfamilyMembers);
      dispatch(UpdateInvestmentPolicyData(newfamilyMembers));
      handleValidation();
    }
  };
  // let handleUpdateClick = () => {
  //   let newOptions = [...options];
  //   for (var i = 0; i < newOptions.length; i++) {
  //     if (newOptions[i] === selected) {
  //       newOptions.splice(i, 1);
  //     }
  //   }
  //   let newUpdatedOptions = updatedOptions;
  //   newUpdatedOptions.push(selected);
  //   setOptions(newOptions);
  //   setUpdatedOptions(newUpdatedOptions);
  //   setSelected(newOptions[0]);
  //   handleValidation();
  // };
  let handleCancelDetails = (element) => {
    let newOptions = [...options];
    newOptions.push(element);
    let newUpdatedOptions = updatedOptions;
    for (var i = 0; i < newUpdatedOptions.length; i++) {
      if (newUpdatedOptions[i] === element) {
        newUpdatedOptions.splice(i, 1);
      }
    }
    setOptions(newOptions);
    if (newOptions.length === 1) setSelected(newOptions[0]);
    setUpdatedOptions(newUpdatedOptions);
    // setValidationFlag(false);
  };
  const handleSliderChange = (event, newValue) => {
    let newfamilyMembers = [...familyMembers2];
    newfamilyMembers[selected.optionid].riskScore = newValue;
    setFamilyMembers2(newfamilyMembers);
    dispatch(UpdateInvestmentPolicyData(newfamilyMembers));
  };
  const handleSave = () => {
    dispatch(
      PostDashboardInvestment(
        {
          client_id: localStorage.getItem("clientId"),
          ip_statement: {
            user: localStorage.getItem("clientId"),
            flag: true,
            equity: {
              target: parseInt(familyMembers2[0].ips.equity.target) / 100,
              min:
                Math.max(
                  0,
                  familyMembers2[selected.optionid].ips.equity.flexibility !== 0
                    ? familyMembers2[selected.optionid].ips.equity.target -
                        familyMembers2[selected.optionid].ips.equity.flexibility
                    : familyMembers2[selected.optionid].ips.equity.target
                ) / 100,
              max:
                Math.min(
                  familyMembers2[selected.optionid].ips.equity.flexibility !== 0
                    ? parseInt(
                        familyMembers2[selected.optionid].ips.equity.target
                      ) +
                        parseInt(
                          familyMembers2[selected.optionid].ips.equity
                            .flexibility
                        )
                    : familyMembers2[selected.optionid].ips.equity.target,
                  100
                ) / 100,
            },
            debt: {
              target: parseInt(familyMembers2[0].ips.debt.target) / 100,
              min:
                Math.max(
                  0,
                  familyMembers2[selected.optionid].ips.debt.flexibility !== 0
                    ? familyMembers2[selected.optionid].ips.debt.target -
                        familyMembers2[selected.optionid].ips.debt.flexibility
                    : familyMembers2[selected.optionid].ips.debt.target
                ) / 100,
              max:
                Math.min(
                  familyMembers2[selected.optionid].ips.debt.flexibility !== 0
                    ? parseInt(
                        familyMembers2[selected.optionid].ips.debt.target
                      ) +
                        parseInt(
                          familyMembers2[selected.optionid].ips.debt.flexibility
                        )
                    : familyMembers2[selected.optionid].ips.debt.target,
                  100
                ) / 100,
            },
            alternate: {
              target: parseInt(familyMembers2[0].ips.alternate.target) / 100,
              min:
                Math.max(
                  0,
                  familyMembers2[selected.optionid].ips.alternate
                    .flexibility !== 0
                    ? familyMembers2[selected.optionid].ips.alternate.target -
                        familyMembers2[selected.optionid].ips.alternate
                          .flexibility
                    : familyMembers2[selected.optionid].ips.alternate.target
                ) / 100,
              max:
                Math.min(
                  familyMembers2[selected.optionid].ips.alternate
                    .flexibility !== 0
                    ? parseInt(
                        familyMembers2[selected.optionid].ips.alternate.target
                      ) +
                        parseInt(
                          familyMembers2[selected.optionid].ips.alternate
                            .flexibility
                        )
                    : familyMembers2[selected.optionid].ips.alternate.target,
                  100
                ) / 100,
            },
            multi_assets: {
              target: 0.5,
              min: 0,
              max: 0.5,
            },
            large_cap: {
              target: parseInt(familyMembers2[0].ips.large_cap.target) / 100,
              min:
                Math.max(
                  0,
                  familyMembers2[selected.optionid].ips.large_cap
                    .flexibility !== 0
                    ? familyMembers2[selected.optionid].ips.large_cap.target -
                        familyMembers2[selected.optionid].ips.large_cap
                          .flexibility
                    : familyMembers2[selected.optionid].ips.large_cap.target
                ) / 100,
              max:
                Math.min(
                  familyMembers2[selected.optionid].ips.large_cap
                    .flexibility !== 0
                    ? parseInt(
                        familyMembers2[selected.optionid].ips.large_cap.target
                      ) +
                        parseInt(
                          familyMembers2[selected.optionid].ips.large_cap
                            .flexibility
                        )
                    : familyMembers2[selected.optionid].ips.large_cap.target,
                  100
                ) / 100,
            },
            mid_cap: {
              target: parseInt(familyMembers2[0].ips.mid_cap.target) / 100,
              min:
                Math.max(
                  0,
                  familyMembers2[selected.optionid].ips.mid_cap.flexibility !==
                    0
                    ? familyMembers2[selected.optionid].ips.mid_cap.target -
                        familyMembers2[selected.optionid].ips.mid_cap
                          .flexibility
                    : familyMembers2[selected.optionid].ips.mid_cap.target
                ) / 100,
              max:
                Math.min(
                  familyMembers2[selected.optionid].ips.mid_cap.flexibility !==
                    0
                    ? parseInt(
                        familyMembers2[selected.optionid].ips.mid_cap.target
                      ) +
                        parseInt(
                          familyMembers2[selected.optionid].ips.mid_cap
                            .flexibility
                        )
                    : familyMembers2[selected.optionid].ips.mid_cap.target,
                  100
                ) / 100,
            },
            small_cap: {
              target: parseInt(familyMembers2[0].ips.small_cap.target) / 100,
              min:
                Math.max(
                  0,
                  familyMembers2[selected.optionid].ips.small_cap
                    .flexibility !== 0
                    ? familyMembers2[selected.optionid].ips.small_cap.target -
                        familyMembers2[selected.optionid].ips.small_cap
                          .flexibility
                    : familyMembers2[selected.optionid].ips.small_cap.target
                ) / 100,
              max:
                Math.min(
                  familyMembers2[selected.optionid].ips.small_cap
                    .flexibility !== 0
                    ? parseInt(
                        familyMembers2[selected.optionid].ips.small_cap.target
                      ) +
                        parseInt(
                          familyMembers2[selected.optionid].ips.small_cap
                            .flexibility
                        )
                    : familyMembers2[selected.optionid].ips.small_cap.target,
                  100
                ) / 100,
            },
            duration: {
              target: parseInt(familyMembers2[0].ips.duration.target),
              min: Math.max(
                0,
                familyMembers2[selected.optionid].ips.duration.flexibility !== 0
                  ? familyMembers2[selected.optionid].ips.duration.target -
                      familyMembers2[selected.optionid].ips.duration.flexibility
                  : familyMembers2[selected.optionid].ips.duration.target
              ),
              max: Math.min(
                familyMembers2[selected.optionid].ips.duration.flexibility !== 0
                  ? familyMembers2[selected.optionid].ips.duration.target +
                      familyMembers2[selected.optionid].ips.duration.flexibility
                  : familyMembers2[selected.optionid].ips.duration.target,
                5
              ),
            },
            aaa_equiv: {
              target: parseInt(familyMembers2[0].ips.aaa_equiv.target) / 100,
              min:
                Math.max(
                  0,
                  familyMembers2[selected.optionid].ips.aaa_equiv
                    .flexibility !== 0
                    ? familyMembers2[selected.optionid].ips.aaa_equiv.target -
                        familyMembers2[selected.optionid].ips.aaa_equiv
                          .flexibility
                    : familyMembers2[selected.optionid].ips.aaa_equiv.target
                ) / 100,
              max:
                Math.min(
                  familyMembers2[selected.optionid].ips.aaa_equiv
                    .flexibility !== 0
                    ? parseInt(
                        familyMembers2[selected.optionid].ips.aaa_equiv.target
                      ) +
                        parseInt(
                          familyMembers2[selected.optionid].ips.aaa_equiv
                            .flexibility
                        )
                    : familyMembers2[selected.optionid].ips.aaa_equiv.target,
                  100
                ) / 100,
            },
            alternates: {
              target: parseInt(familyMembers2[0].ips.alternates.target) / 100,
              min:
                Math.max(
                  0,
                  familyMembers2[selected.optionid].ips.alternates
                    .flexibility !== 0
                    ? familyMembers2[selected.optionid].ips.alternates.target -
                        familyMembers2[selected.optionid].ips.alternates
                          .flexibility
                    : familyMembers2[selected.optionid].ips.alternates.target
                ) / 100,
              max:
                Math.min(
                  familyMembers2[selected.optionid].ips.alternates
                    .flexibility !== 0
                    ? parseInt(
                        familyMembers2[selected.optionid].ips.alternates.target
                      ) +
                        parseInt(
                          familyMembers2[selected.optionid].ips.alternates
                            .flexibility
                        )
                    : familyMembers2[selected.optionid].ips.alternates.target,
                  100
                ) / 100,
            },
          },
          ip_product_understanding: {
            user: localStorage.getItem("clientId"),
            ...familyMembers2[0].understanding,
          },
          ip_profile: {
            user: localStorage.getItem("clientId"),
            investmentHorizon: parseInt(familyMembers2[0].investmentHorizon),
            investmentCorpus: {
              amount: parseInt(familyMembers2[0].investmentCorpus),
              denomination: familyMembers2[0].investmentcorpusdenotion[2],
            },
            riskScore: parseInt(familyMembers2[0].riskScore),
            flexibility: 5.0,
          },
        },
        snackbar,
        setSnackbar
      )
    );
    dispatch(
      PostEventLoggingData({
        module_name: "Investment Profile",
        event_type: "Save button",
        url: window.location.href,
        note: `Investment Profile for ${localStorage.getItem(
          "clientName"
        )} updated`,
      })
    );
  };
  useEffect(() => {
    setFamilyMembers2([
      {
        ...clientData.ip_profile,
        investmentcorpusdenotion: `₹ ${clientData.ip_profile.investmentCorpus.denomination}`,
        investmentCorpus: clientData.ip_profile.investmentCorpus.amount,
        flexibility: "",
        ips: {
          ...clientData.ip_statement,
          equity: {
            target: parseInt(
              (clientData.ip_statement.equity.target * 100).toFixed(0)
            ),
            flexibility: parseInt(
              (
                Math.max(
                  clientData.ip_statement.equity.target -
                    clientData.ip_statement.equity.min,

                  clientData.ip_statement.equity.max -
                    clientData.ip_statement.equity.target
                ) * 100
              ).toFixed(0)
            ),
            min: clientData.ip_statement.equity.min,
            max: clientData.ip_statement.equity.max,
          },
          debt: {
            target: parseInt(
              (clientData.ip_statement.debt.target * 100).toFixed(0)
            ),
            flexibility: parseInt(
              (
                Math.max(
                  clientData.ip_statement.debt.target -
                    clientData.ip_statement.debt.min,

                  clientData.ip_statement.debt.max -
                    clientData.ip_statement.debt.target
                ) * 100
              ).toFixed(0)
            ),
            min: clientData.ip_statement.debt.min,
            max: clientData.ip_statement.debt.max,
          },
          alternate: {
            target: parseInt(
              (clientData.ip_statement.alternate.target * 100).toFixed(0)
            ),
            flexibility: parseInt(
              (
                Math.max(
                  clientData.ip_statement.alternate.target -
                    clientData.ip_statement.alternate.min,

                  clientData.ip_statement.alternate.max -
                    clientData.ip_statement.alternate.target
                ) * 100
              ).toFixed(0)
            ),
            min: clientData.ip_statement.alternate.min,
            max: clientData.ip_statement.alternate.max,
          },
          large_cap: {
            target: parseInt(
              (clientData.ip_statement.large_cap.target * 100).toFixed(0)
            ),
            flexibility: parseInt(
              (
                Math.max(
                  clientData.ip_statement.large_cap.target -
                    clientData.ip_statement.large_cap.min,

                  clientData.ip_statement.large_cap.max -
                    clientData.ip_statement.large_cap.target
                ) * 100
              ).toFixed(0)
            ),
            min: clientData.ip_statement.large_cap.min,
            max: clientData.ip_statement.large_cap.max,
          },
          mid_cap: {
            target: parseInt(
              (clientData.ip_statement.mid_cap.target * 100).toFixed(0)
            ),
            flexibility: parseInt(
              (
                Math.max(
                  clientData.ip_statement.mid_cap.target -
                    clientData.ip_statement.mid_cap.min,

                  clientData.ip_statement.mid_cap.max -
                    clientData.ip_statement.mid_cap.target
                ) * 100
              ).toFixed(0)
            ),
            min: clientData.ip_statement.mid_cap.min,
            max: clientData.ip_statement.mid_cap.max,
          },
          small_cap: {
            target: parseInt(
              (clientData.ip_statement.small_cap.target * 100).toFixed(0)
            ),
            flexibility: parseInt(
              (
                Math.max(
                  clientData.ip_statement.small_cap.target -
                    clientData.ip_statement.small_cap.min,

                  clientData.ip_statement.small_cap.max -
                    clientData.ip_statement.small_cap.target
                ) * 100
              ).toFixed(0)
            ),
            min: clientData.ip_statement.small_cap.min,
            max: clientData.ip_statement.small_cap.max,
          },
          duration: {
            target: parseInt(
              clientData.ip_statement.duration.target.toFixed(0)
            ),
            flexibility: Math.max(
              clientData.ip_statement.duration.target -
                clientData.ip_statement.duration.min,

              clientData.ip_statement.duration.max -
                clientData.ip_statement.duration.target
            ),
            min: clientData.ip_statement.duration.min,
            max: clientData.ip_statement.duration.max,
          },
          aaa_equiv: {
            target: parseInt(
              (clientData.ip_statement.aaa_equiv.target * 100).toFixed(0)
            ),
            flexibility: parseInt(
              (
                Math.max(
                  clientData.ip_statement.aaa_equiv.target -
                    clientData.ip_statement.aaa_equiv.min,

                  clientData.ip_statement.aaa_equiv.max -
                    clientData.ip_statement.aaa_equiv.target
                ) * 100
              ).toFixed(0)
            ),
            min: clientData.ip_statement.aaa_equiv.min,
            max: clientData.ip_statement.aaa_equiv.max,
          },
          alternates: {
            target: parseInt(
              (clientData.ip_statement.alternates.target * 100).toFixed(0)
            ),
            flexibility: parseInt(
              (
                Math.max(
                  clientData.ip_statement.alternates.target -
                    clientData.ip_statement.alternates.min,

                  clientData.ip_statement.alternates.max -
                    clientData.ip_statement.alternates.target
                ) * 100
              ).toFixed(0)
            ),
            min: clientData.ip_statement.alternates.min,
            max: clientData.ip_statement.alternates.max,
          },
        },
        understanding: clientData.ip_product_understanding,

        raq1: 1,
        raq2: 1,
        raq3: 1,
        raq4: 1,
        raq5: 1,
        sumraq: 5,
      },
    ]);
    dispatch(
      UpdateInvestmentPolicyData([
        {
          ...clientData.ip_profile,
          investmentcorpusdenotion: `₹ ${clientData.ip_profile.investmentCorpus.denomination}`,
          investmentCorpus: clientData.ip_profile.investmentCorpus.amount,
          ips: {
            ...clientData.ip_statement,
            equity: {
              target: parseInt(
                (clientData.ip_statement.equity.target * 100).toFixed(0)
              ),
              flexibility: parseInt(
                (
                  Math.max(
                    clientData.ip_statement.equity.target -
                      clientData.ip_statement.equity.min,

                    clientData.ip_statement.equity.max -
                      clientData.ip_statement.equity.target
                  ) * 100
                ).toFixed(0)
              ),
              min: clientData.ip_statement.equity.min,
              max: clientData.ip_statement.equity.max,
            },
            debt: {
              target: parseInt(
                (clientData.ip_statement.debt.target * 100).toFixed(0)
              ),
              flexibility: parseInt(
                (
                  Math.max(
                    clientData.ip_statement.debt.target -
                      clientData.ip_statement.debt.min,

                    clientData.ip_statement.debt.max -
                      clientData.ip_statement.debt.target
                  ) * 100
                ).toFixed(0)
              ),
              min: clientData.ip_statement.debt.min,
              max: clientData.ip_statement.debt.max,
            },
            alternate: {
              target: parseInt(
                (clientData.ip_statement.alternate.target * 100).toFixed(0)
              ),
              flexibility: parseInt(
                (
                  Math.max(
                    clientData.ip_statement.alternate.target -
                      clientData.ip_statement.alternate.min,

                    clientData.ip_statement.alternate.max -
                      clientData.ip_statement.alternate.target
                  ) * 100
                ).toFixed(0)
              ),
              min: clientData.ip_statement.alternate.min,
              max: clientData.ip_statement.alternate.max,
            },
            large_cap: {
              target: parseInt(
                (clientData.ip_statement.large_cap.target * 100).toFixed(0)
              ),
              flexibility: parseInt(
                (
                  Math.max(
                    clientData.ip_statement.large_cap.target -
                      clientData.ip_statement.large_cap.min,

                    clientData.ip_statement.large_cap.max -
                      clientData.ip_statement.large_cap.target
                  ) * 100
                ).toFixed(0)
              ),
              min: clientData.ip_statement.large_cap.min,
              max: clientData.ip_statement.large_cap.max,
            },
            mid_cap: {
              target: parseInt(
                (clientData.ip_statement.mid_cap.target * 100).toFixed(0)
              ),
              flexibility: parseInt(
                (
                  Math.max(
                    clientData.ip_statement.mid_cap.target -
                      clientData.ip_statement.mid_cap.min,

                    clientData.ip_statement.mid_cap.max -
                      clientData.ip_statement.mid_cap.target
                  ) * 100
                ).toFixed(0)
              ),
              min: clientData.ip_statement.mid_cap.min,
              max: clientData.ip_statement.mid_cap.max,
            },
            small_cap: {
              target: parseInt(
                (clientData.ip_statement.small_cap.target * 100).toFixed(0)
              ),
              flexibility: parseInt(
                (
                  Math.max(
                    clientData.ip_statement.small_cap.target -
                      clientData.ip_statement.small_cap.min,

                    clientData.ip_statement.small_cap.max -
                      clientData.ip_statement.small_cap.target
                  ) * 100
                ).toFixed(0)
              ),
              min: clientData.ip_statement.small_cap.min,
              max: clientData.ip_statement.small_cap.max,
            },
            duration: {
              target: parseInt(
                clientData.ip_statement.duration.target.toFixed(0)
              ),
              flexibility: Math.max(
                clientData.ip_statement.duration.target -
                  clientData.ip_statement.duration.min,

                clientData.ip_statement.duration.max -
                  clientData.ip_statement.duration.target
              ),
              min: clientData.ip_statement.duration.min,
              max: clientData.ip_statement.duration.max,
            },
            aaa_equiv: {
              target: parseInt(
                (clientData.ip_statement.aaa_equiv.target * 100).toFixed(0)
              ),
              flexibility: parseInt(
                (
                  Math.max(
                    clientData.ip_statement.aaa_equiv.target -
                      clientData.ip_statement.aaa_equiv.min,

                    clientData.ip_statement.aaa_equiv.max -
                      clientData.ip_statement.aaa_equiv.target
                  ) * 100
                ).toFixed(0)
              ),
              min: clientData.ip_statement.aaa_equiv.min,
              max: clientData.ip_statement.aaa_equiv.max,
            },
            alternates: {
              target: parseInt(
                (clientData.ip_statement.alternates.target * 100).toFixed(0)
              ),
              flexibility: parseInt(
                (
                  Math.max(
                    clientData.ip_statement.alternates.target -
                      clientData.ip_statement.alternates.min,

                    clientData.ip_statement.alternates.max -
                      clientData.ip_statement.alternates.target
                  ) * 100
                ).toFixed(0)
              ),
              min: clientData.ip_statement.alternates.min,
              max: clientData.ip_statement.alternates.max,
            },
          },
          understanding: clientData.ip_product_understanding,

          raq1: 1,
          raq2: 1,
          raq3: 1,
          raq4: 1,
          raq5: 1,
          sumraq: 5,
        },
      ])
    ); // eslint-disable-next-line
  }, [clientData]);
  const marks = [
    {
      value: 0,
      label: "Risk averse",
    },
    {
      value: 100,
      label: "Risk seeker",
    },
  ];

  return (
    <>
      <div className="sticky top-0 bg-[#f3f3f3] mx-[-22px] z-[10] pt-[1rem] px-[6px]">
        <div className="w-full h-full bg-[#fff] p-[1rem] rounded-t-[0.5rem] min-h-full">
          <h1 className="card-heading">
            Edit Investment profile for each individual
          </h1>

          <div className="w-[28%] mt-[1.5rem]">
            <Dropdown
              options={options}
              selected={selected}
              setSelected={setSelected}
              labelName="Client"
            />
          </div>
          <h2 className="w-full text-left border-b-[2px] border-[#ece7e7] leading-[0.1em] mt-[2.7rem]">
            <span className="bg-[#fff] font-medium  text-[16px] font-inter pr-[10px] pb-[10px] text-neutralBlack">
              Edit Details
            </span>
          </h2>
        </div>
      </div>
      <div className="bg-[#fff] m-[-1rem] p-[1rem] rounded-b-[0.5rem]">
        {familyMembers2.length !== updatedOptions.length ? (
          <table>
            <tbody>
              <tr className="mt-[1rem] w-[98%] flex justify-between">
                <td className="w-[35%]">
                  <label
                    style={{
                      verticalAlign: "-webkit-baseline-middle",
                    }}
                    className="font-inter text-neutralBlack text-[16px]  mb-0"
                  >
                    Investment corpus
                  </label>
                </td>

                <td className="w-[65%] font-inter text-neutralBlack text-[16px] font-normal mb-0 block">
                  <div className="flex w-[50%] justify-between">
                    <div className="w-[25%]">
                      <Dropdown2
                        options={["₹ K", "₹ L", "₹ C"]}
                        selected={familyMembers2}
                        setSelected={setFamilyMembers2}
                        labelName={null}
                        attribute="investmentcorpusdenotion"
                        index={selected.optionid}
                      />
                    </div>
                    <InvestedgeTextField
                      variant="outlined"
                      required
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "73%",
                      }}
                      name="investmentCorpus"
                      value={familyMembers2[selected.optionid].investmentCorpus}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </td>
              </tr>
              <tr className="mt-0 w-[98%] flex justify-between">
                <td className="w-[35%]"></td>
                <td className="w-[65%] font-inter text-neutralBlack text-sm font-normal mb-0 block">
                  Investment corpus means the amount of cash ready to deploy
                </td>
              </tr>
              <tr className="mt-[0.5rem] w-[98%] flex justify-between">
                <td className="w-[35%]">
                  <label
                    style={{
                      verticalAlign: "-webkit-baseline-middle",
                    }}
                    className="font-inter text-neutralBlack text-[16px]  mb-0"
                  >
                    Investment horizon
                  </label>
                </td>
                <td className="w-[65%] font-inter text-neutralBlack text-sm font-normal mb-0 flex items-center">
                  <OutlinedInput
                    size="small"
                    className="text-sm"
                    required
                    style={{
                      fontFamily: "Inter,sans-sarif",
                      fontWeight: 400,
                      width: "25%",
                      marginRight: "0.5rem",
                    }}
                    min={0}
                    max={100}
                    name="investmentHorizon"
                    value={familyMembers2[selected.optionid].investmentHorizon}
                    onChange={(e) => handleChange(e)}
                    endAdornment={
                      <InputAdornment position="end">
                        <span className="text-sm font-inter">years</span>
                      </InputAdornment>
                    }
                  />
                </td>
              </tr>
              {/* <tr
              className="investment-profile-row"
              style={{ marginTop: "1rem" }}
            >
              <td>
                <label className="investment-profile-label">Age</label>
              </td>
              <td>{`${familyMembers2[selected.optionid].age} years`}</td>
            </tr> */}
              <tr className="mt-2 w-[98%] flex justify-between">
                <td className="w-[35%]">
                  <label
                    style={{
                      verticalAlign: "-webkit-baseline-middle",
                    }}
                    className="font-inter text-neutralBlack text-[16px]  mb-0"
                  >
                    Risk Assessment questionnaire
                  </label>
                </td>
                <td className="w-[65%] font-inter text-neutralBlack text-sm font-normal mb-0 block">
                  <div
                    className=" font-inter text-sm text-primary-200 block mb-0 pt-[0.5rem] cursor-pointer align-middle"
                    onClick={() => setrsmodal(true)}
                  >
                    Click to know your composite risk score
                  </div>
                  <RiskAssessmentModal
                    onClose={() => setrsmodal(false)}
                    familyMembers={familyMembers2}
                    setFamilyMembers2={setFamilyMembers2}
                    selected={selected}
                    rsmodal={rsmodal}
                    riskText={riskText}
                  />
                </td>
              </tr>
              <tr className="mt-6 w-[98%] flex justify-between">
                <td className="w-[35%] pb-0">
                  <label
                    style={{
                      verticalAlign: "-webkit-baseline-middle",
                    }}
                    className="font-inter text-neutralBlack text-[16px]  mb-0"
                  >
                    Composite risk score
                  </label>
                </td>
                <td className="w-[65%] font-inter text-neutralBlack text-[16px] font-normal mb-0 block pb-0">
                  <InvestedgeSlider
                    className="w-3/4"
                    marks={marks}
                    value={
                      typeof familyMembers2[selected.optionid].riskScore ===
                      "number"
                        ? familyMembers2[selected.optionid].riskScore
                        : 0
                    }
                    onChange={(event, value) =>
                      handleSliderChange(event, value)
                    }
                    onChangeCommitted={(event, value) =>
                      familyMembers2[selected.optionid].investmentHorizon &&
                      dispatch(
                        GetIpsData({
                          params: {
                            risk_score: value,
                            investment_horizon:
                              familyMembers2[selected.optionid]
                                .investmentHorizon,
                          },
                        })
                      )
                    }
                    valueLabelDisplay="on"
                    step={1}
                  />
                </td>
              </tr>
              <tr className="mt-0 w-[98%] flex justify-between">
                <td className="w-[35%] pt-0">
                  <div className="rounded-[8px] font-inter py-[0.3rem] px-[0.5rem] inline-block text-sm font-medium bg-primary-accent text-primary-200">
                    {riskText}
                  </div>
                </td>
                <td className="w-[65%] font-inter text-neutralBlack text-sm font-normal mb-0 block pt-0 leading-5 text-justify">
                  <div className="w-[70%]">
                    Composite risk score level decides the overall risk taking
                    capacity of the investor. The risk tolerance helps in
                    deciding the strategic asset allocation.
                  </div>
                </td>
              </tr>
              <tr className="mt-4 w-[98%] flex justify-between">
                <td className="w-[35%]">
                  <label
                    style={{
                      verticalAlign: "-webkit-baseline-middle",
                    }}
                    className="font-inter text-neutralBlack text-[16px]  mb-0"
                  >
                    Flexibility
                  </label>
                </td>
                <td className="w-[65%] font-inter text-neutralBlack text-sm mb-0 flex items-center font-normal">
                  <OutlinedInput
                    size="small"
                    className="text-sm"
                    required
                    style={{
                      fontFamily: "Inter,sans-sarif",
                      fontWeight: 400,
                      width: "25%",
                      marginRight: "0.5rem",
                    }}
                    min={0}
                    max={100}
                    name="flexibility"
                    value={familyMembers2[selected.optionid].flexibility}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <span className="text-sm font-inter">%</span>
                      </InputAdornment>
                    }
                  />
                </td>
              </tr>
              <tr className="mt-[0.5rem] w-[98%] flex justify-between">
                <td className="w-[35%]"></td>
                <td className="w-[65%] font-inter text-neutralBlack text-sm font-normal mb-0 block">
                  <div className="w-[70%] leading-5 text-justify">
                    Flexibility decides how much variance is allowed in the
                    strategic asset allocation based on the view provided for
                    different assets.
                  </div>
                </td>
              </tr>
              <tr className="mt-[0.5rem] w-[98%] flex justify-between">
                <td className="w-[35%]"></td>
                <td className="w-[65%] font-inter text-neutralBlack text-sm font-normal mb-0 block">
                  <div className="w-[70%] leading-5 text-justify">
                    For example, if the strategic equity allocation for a
                    moderate risk profile (risk tolerance = 50) is 75% and
                    flexibility is 20%, then the equity allocation can vary from
                    55% to 95% depending on the view provided on equity asset
                    className. If the view is bearish then equity allocation is
                    close to 55% and if the view is bullish the equity
                    allocation is close to 95%.
                  </div>
                </td>
              </tr>
              <tr className="mt-6 w-[98%] flex justify-between">
                <td className="w-[35%]">
                  <label
                    style={{
                      verticalAlign: "-webkit-baseline-middle",
                    }}
                    className="font-inter text-neutralBlack text-[16px]  mb-0"
                  >
                    Investment policy statement
                  </label>
                </td>
                <td className="w-[65%] font-inter text-neutralBlack text-[16px] font-normal mb-0 block">
                  <div className="flex items-center">
                    <div
                      className=" font-inter text-sm text-primary-200  block mt-0 pt-[0.3rem] cursor-pointer"
                      onClick={() => setroipsmodal(true)}
                    >
                      Click to view current IPS
                    </div>
                    <div
                      className="font-inter text-sm text-primary-200  block mt-0 ml-6 pt-[0.3rem] cursor-pointer"
                      onClick={() => setipsmodal(true)}
                    >
                      Click to edit current IPS
                    </div>
                    <Tooltip
                      TransitionComponent={Zoom}
                      placement="right"
                      title="IPS would get saved on clicking save button."
                      arrow
                    >
                      <img
                        src={TooltipIcon}
                        alt="tooltip"
                        className="w-[16px] h-[16px] ml-2 mt-1"
                      />
                    </Tooltip>
                  </div>
                  <ReadOnlyIPS
                    onClose={() => setroipsmodal(false)}
                    ipsmodal={roipsmodal}
                  />
                  <InvestmentPolicyModal
                    onClose={() => setipsmodal(false)}
                    familyMembers={familyMembers2}
                    setFamilyMembers2={setFamilyMembers2}
                    selected={selected}
                    ipsmodal={ipsmodal}
                  />
                </td>
              </tr>

              <tr className="mt-6 w-[98%] flex justify-between">
                <td className="w-[35%]">
                  <label
                    style={{
                      verticalAlign: "-webkit-baseline-middle",
                    }}
                    className="font-inter text-neutralBlack text-[16px]  mb-0"
                  >
                    Product Understanding
                  </label>
                </td>
                <td className="w-[65%] font-inter text-neutralBlack text-[16px] font-normal mb-0 block">
                  <div
                    className="text-sm font-inter text-primary-200 block mb-0 pt-[0.3rem] cursor-pointer"
                    onClick={() => setpumodal(true)}
                  >
                    Click to view and change
                  </div>
                  <ProductUnderstandingModal
                    familyMembers={familyMembers2}
                    setFamilyMembers2={setFamilyMembers2}
                    selected={selected}
                    onClose={() => setpumodal(false)}
                    pumodal={pumodal}
                  />
                </td>
              </tr>
              {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div
                className="input-client-update-details-button"
                onClick={handleUpdateClick}
              >
                Update details
              </div>
            </div> */}
              {updatedOptions.length !== 0 && (
                <>
                  <h2 className=" text-neutralBlack text-[16px] mt-[0.3rem]">
                    Updated data for
                  </h2>
                  <div className="flex flex-wrap">
                    {updatedOptions.map((client, index) => {
                      return (
                        <UpdatedClientCard
                          key={index}
                          client={client}
                          handleCancelDetails={handleCancelDetails}
                        />
                      );
                    })}
                  </div>
                </>
              )}
            </tbody>
          </table>
        ) : (
          <>
            <div className="flex justify-center items-center h-[300px]">
              <h2 className="text-[20px] text-primary-200  font-inter">
                Successfully updated details for all family members !
              </h2>
            </div>

            <h2 className=" text-neutralBlack text-[16px] mt-[0.3rem]">
              Updated data for
            </h2>
            <div className="flex flex-wrap">
              {updatedOptions.map((client, index) => {
                return (
                  <UpdatedClientCard
                    key={index}
                    client={client}
                    handleCancelDetails={handleCancelDetails}
                  />
                );
              })}
            </div>
          </>
        )}
        <div className="mt-6 w-full flex items-center justify-end">
          {/* <Link
            className="client-account-type-button"
            to={{
              pathname: "/clientinput/individualdetails",
              state: {
                familyMembers: familyMembers2,
              },
            }}
          >
            <div className="create-client-button-create">Back</div>
          </Link>
          {validationFlag ? (
            <Link
              className="client-account-type-button create-client-button-prospect"
              to={{
                pathname: "/clientinput/updateportfolios",
                state: {
                  familyMembers: familyMembers2,
                },
              }}
              onClick={handleSave}
            >
              <div>Save</div>
            </Link>
          ) : (
            <div
              className="client-account-type-button create-client-button-prospect"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.12)",
                color: "rgba(0, 0, 0, 0.5)",
              }}
            >
              Save
            </div>
          )} */}
          <div
            className="primary-button w-[11.5%] ml-6  py-[0.45rem] px-0"
            onClick={handleSave}
          >
            Save
          </div>
        </div>
      </div>
    </>
  );
}
