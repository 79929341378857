import React from "react";
import PortfolioDetailsRow from "./PortfolioDetailsRow";
export default function UpdatePortfoliosContent({ familyMembers }) {
  return (
    <div>
      <h1 className="card-heading">Portfolio Details</h1>
      <div className="rounded-lg min-h-[335px] w-[70%] mt-[1rem] border border-globalBorderColor">
        <table className="w-full investedge-table">
          <colgroup>
            <col width="60%" />
            <col width="20%" />
            <col width="20%" />
          </colgroup>
          <tbody>
            <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
              <th className="pl-[1rem] text-left font-medium">Client Name</th>
              <th className="text-center font-medium">Portfolio Value</th>
              <th className="text-center font-medium">Portfolio</th>
            </tr>
            {familyMembers.map((member, index) => {
              return (
                <PortfolioDetailsRow
                  key={index}
                  member={member}
                  familyMembers={familyMembers}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
