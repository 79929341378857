import React, { useState } from "react";
import InvestedgeToggleButtonGroup from "../../../components/toggle/InvestedgeToggle";
import { AssetAllocation } from "./AssetAllocation";
import SIPSection from "./SIPSection";

export default function GoalTracking({ goalDetails, goalData }) {
  const [type, setType] = useState("SIP Amt Required");
  const handleTabClick = (e, val) => {
    if (val) {
      setType(val);
    }
  };
  const toggletabs = [
    { label: "SIP Amt Required", value: "SIP Amt Required" },
    { label: "Return % Required", value: "Return % Required" },
  ];

  const handleTypeChange = (type) => {
    switch (type) {
      case "SIP Amt Required":
        return <SIPSection goalDetails={goalDetails} />;

      case "Return % Required":
        return (
          <AssetAllocation goalDetails={goalDetails} goalData={goalData} />
        );

      default:
        break;
    }
  };
  return goalDetails.client_investments.length ? (
    <div className="mt-12">
      <p className="card-heading">Goal Tracking &amp; Actionables</p>
      <InvestedgeToggleButtonGroup
        tabs={toggletabs}
        value={type}
        onChange={handleTabClick}
        sx={{
          height: "40px",
          marginTop: "10px",
          "& button": { minWidth: "152px" },
        }}
        exclusive
      />
      {handleTypeChange(type)}
    </div>
  ) : (
    ""
  );
}
