import React from "react";
import { useState } from "react";
// import { ThemeProvider } from "@material-ui/styles";
// import { Switch, FormControlLabel } from "@mui/material";
// import { createTheme } from "@mui/material/styles";
import ArrowLeft from "../../assets/img/left_arrow_icon.svg";
import ArrowDown from "../../assets/img/down_arrow_icon.svg";
import InvestedgeTextField from "../../components/textfield/InvestedgeTextField";
import InvestedgeDatepicker from "../../components/datepicker/InvestedgeDatepicker";
import dayjs from "dayjs";
export default function GoldRow({
  fund,
  productarray,
  setProductarray,
  patchProductArray,
  setPatchProductArray,
  deleteArray,
  setDeleteArray,
  data,
  postProductArray,
  setPostProductArray,
  addedProduct,
}) {
  // const BirrSwitch = createTheme({
  //   overrides: {
  //     MuiSwitch: {
  //       root: {
  //         width: 70,
  //         height: 24,
  //         padding: 0,
  //         marginRight: 10,
  //       },
  //       palette: {
  //         primary: "#0000f5",
  //         secondary: "#0000f5",
  //       },
  //       switchBase: {
  //         padding: 0,
  //         color: "#0000f5",
  //         "&$checked": {
  //           transform: "translateX(45px)",
  //           color: "#0000f5",
  //           "& + $track": {
  //             backgroundColor: "#0000f5",
  //             opacity: 0.4,
  //             border: "none",
  //           },
  //         },
  //         "&$focusVisible $thumb": {
  //           color: "#0000f5",
  //           border: "6px solid #fff",
  //         },
  //       },
  //       thumb: {
  //         width: 24,
  //         height: 24,
  //       },
  //       track: {
  //         borderRadius: 30 / 2,
  //         border: `1px solid #c4c4c4`,
  //         opacity: 0.2,
  //       },
  //       checked: {},
  //     },
  //   },
  // });
  const [open, setOpen] = useState(fund.id === addedProduct ? true : false);

  // const [checked, setChecked] = useState(false);

  // let handleChange = (i, e) => {
  //   let newMfarray = [...productarray];
  //   newMfarray[i][e.target.name] = e.target.value;
  //   setProductarray(newMfarray);
  // };
  // const icon = (
  //   <Box>
  //     <>
  //                <td className="w-[25%]">

  //         <InvestedgeTextField
  //           fullWidth
  //           sx={{
  //             fontFamily: "Inter,sans-sarif",
  //             fontWeight: 400,
  //           }}
  //           label="SIP Amount"
  //           type="text"
  //         />
  //       </td>
  //                <td className="w-[25%]">

  //         <InvestedgeTextField
  //           fullWidth
  //           sx={{
  //             fontFamily: "Inter,sans-sarif",
  //             fontWeight: 400,
  //           }}
  //           label="SIP frequency"
  //           type="text"
  //         />
  //       </td>
  //     </>
  //   </Box>
  // );

  const return_date = (val) => {
    try {
      let temp = dayjs(new Date(val).toISOString());
      return temp;
    } catch (err) {
      return val;
    }
  };
  return (
    <>
      <tr className="update-portfolio-asset-row">
        <td>{fund.name}</td>
        <td>
          <div className="update-portfolio-asset-row-second-half">
            <table>
              <tbody>
                <tr>
                  <td>{`₹ ${parseInt(
                    fund.weight
                      ? parseFloat(fund.weight * fund.current_price).toFixed(0)
                      : 0
                  ).toLocaleString("en-IN")}`}</td>
                  <td
                    className="mr-[1rem]"
                    onClick={() => {
                      let newMfArray = [...productarray];
                      let index = productarray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      newMfArray.splice(index, 1);
                      setProductarray(newMfArray);
                      //editing in patch mf array
                      if (data.some((getFund) => getFund.id === fund.id)) {
                        let newPatchMfArray = [...patchProductArray];
                        let patchindex = newPatchMfArray.findIndex(
                          (pid) => pid.id === fund.id
                        );
                        if (patchindex !== -1)
                          newPatchMfArray.splice(patchindex, 1);
                        setPatchProductArray(newPatchMfArray);
                        setDeleteArray([
                          ...deleteArray,
                          {
                            ...fund,
                            purchase_date: new Date(fund.purchase_date),
                            current_price_date: new Date(
                              fund.current_price_date
                            ),
                          },
                        ]);
                      } //editing in post mf array
                      else {
                        let newPostMfArray = [...postProductArray];
                        let postindex = newPostMfArray.findIndex(
                          (pid) => pid.id === fund.id
                        );
                        newPostMfArray.splice(postindex, 1);
                        setPostProductArray(newPostMfArray);
                      }
                    }}
                  >
                    Delete
                  </td>
                  <td
                    className="w-[20px] py-0 pr-0 pl-[0.3rem]"
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    {open ? (
                      <img className="block" src={ArrowDown} alt="err" />
                    ) : (
                      <img className="block" src={ArrowLeft} alt="err" />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </td>
      </tr>
      {open ? (
        <>
          <tr className="flex w-full mt-4">
            <td className="w-[31%]">
              <InvestedgeTextField
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label={fund.commodity_id}
                type="text"
                value={fund.name}
                required
                onChange={(e) => {
                  let newMfArray = [...productarray];
                  let index = productarray.findIndex(
                    (pid) => pid.id === fund.id
                  );
                  newMfArray[index].name = e.target.value;
                  setProductarray(newMfArray);
                  //editing in patch mf array
                  if (data.some((getFund) => getFund.id === fund.id)) {
                    let newPatchMfArray = [...patchProductArray];
                    let patchindex = newPatchMfArray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    if (patchindex === -1) {
                      newPatchMfArray.push({
                        ...fund,
                        purchase_date: new Date(fund.purchase_date),
                        current_price_date: new Date(fund.current_price_date),
                      });
                      newPatchMfArray[newPatchMfArray.length - 1].name =
                        e.target.value;
                    } else newPatchMfArray[patchindex].name = e.target.value;
                    setPatchProductArray(newPatchMfArray);
                  } //editing in post mf array
                  else {
                    let newPostMfArray = [...postProductArray];
                    let postindex = newPostMfArray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newPostMfArray[postindex].name = e.target.value;
                    setPostProductArray(newPostMfArray);
                  }
                }}
              />
            </td>
            <td className="w-[23%]">
              <InvestedgeTextField
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Weight(in gm)"
                type="text"
                value={fund.weight}
                onChange={(e) => {
                  const re = /^\d*\.?\d*$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    let newMfArray = [...productarray];
                    let index = productarray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newMfArray[index].weight = e.target.value;
                    setProductarray(newMfArray);
                    //editing in patch mf array
                    if (data.some((getFund) => getFund.id === fund.id)) {
                      let newPatchMfArray = [...patchProductArray];
                      let patchindex = newPatchMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      if (patchindex === -1) {
                        newPatchMfArray.push({
                          ...fund,
                          purchase_date: new Date(fund.purchase_date),
                          current_price_date: new Date(fund.current_price_date),
                        });
                        newPatchMfArray[newPatchMfArray.length - 1].weight =
                          e.target.value;
                      } else
                        newPatchMfArray[patchindex].weight = e.target.value;
                      setPatchProductArray(newPatchMfArray);
                    } //editing in post mf array
                    else {
                      let newPostMfArray = [...postProductArray];
                      let postindex = newPostMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      newPostMfArray[postindex].weight = e.target.value;
                      setPostProductArray(newPostMfArray);
                    }
                  }
                }}
              />
            </td>
            <td className="w-[23%]">
              <InvestedgeTextField
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Purchase Price(in ₹)"
                type="text"
                value={fund.purchase_price}
                onChange={(e) => {
                  const re = /^\d*\.?\d*$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    let newMfArray = [...productarray];
                    let index = productarray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newMfArray[index].purchase_price = e.target.value;
                    setProductarray(newMfArray);
                    //editing in patch mf array
                    if (data.some((getFund) => getFund.id === fund.id)) {
                      let newPatchMfArray = [...patchProductArray];
                      let patchindex = newPatchMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      if (patchindex === -1) {
                        newPatchMfArray.push({
                          ...fund,
                          purchase_date: new Date(fund.purchase_date),
                          current_price_date: new Date(fund.current_price_date),
                        });
                        newPatchMfArray[
                          newPatchMfArray.length - 1
                        ].purchase_price = e.target.value;
                      } else
                        newPatchMfArray[patchindex].purchase_price =
                          e.target.value;
                      setPatchProductArray(newPatchMfArray);
                    } //editing in post mf array
                    else {
                      let newPostMfArray = [...postProductArray];
                      let postindex = newPostMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      newPostMfArray[postindex].purchase_price = e.target.value;
                      setPostProductArray(newPostMfArray);
                    }
                  }
                }}
              />
            </td>
            <td className="w-[23%]">
              <InvestedgeTextField
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Current Price(in ₹)"
                type="text"
                value={fund.current_price}
                disabled
              />
            </td>
          </tr>
          <tr className="flex w-full">
            <td className="w-[31%]">
              <InvestedgeDatepicker
                format="DD/MM/YYYY"
                label="Purchase Date"
                maxDate={dayjs(new Date().toISOString())}
                value={return_date(fund.purchase_date)}
                onChange={(date) => {
                  date = new Date(date);
                  let newMfArray = [...productarray];
                  let index = productarray.findIndex(
                    (pid) => pid.id === fund.id
                  );
                  newMfArray[index].purchase_date = date;
                  setProductarray(newMfArray);
                  //editing in patch mf array
                  if (data.some((getFund) => getFund.id === fund.id)) {
                    let newPatchMfArray = [...patchProductArray];
                    let patchindex = newPatchMfArray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    if (patchindex === -1) {
                      newPatchMfArray.push({
                        ...fund,
                        purchase_date: new Date(fund.purchase_date),
                        current_price_date: new Date(fund.current_price_date),
                      });
                      newPatchMfArray[
                        newPatchMfArray.length - 1
                      ].purchase_date = date;
                    } else newPatchMfArray[patchindex].purchase_date = date;
                    setPatchProductArray(newPatchMfArray);
                  } //editing in post mf array
                  else {
                    let newPostMfArray = [...postProductArray];
                    let postindex = newPostMfArray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newPostMfArray[postindex].purchase_date = date;
                    setPostProductArray(newPostMfArray);
                  }
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                renderInput={(params) => <InvestedgeTextField {...params} />}
              />
            </td>
            <td className="w-[23%]">
              <InvestedgeDatepicker
                format="DD/MM/YYYY"
                label="Curent Price Date"
                value={dayjs(new Date(fund.current_price_date).toISOString())}
                disabled={true}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                renderInput={(params) => <InvestedgeTextField {...params} />}
                onChange={() => {}}
              />
            </td>
            <td className="w-[23%]">
              <InvestedgeTextField
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Current Value(in ₹)"
                type="text"
                value={fund.weight * fund.current_price}
                disabled
              />
            </td>
          </tr>
        </>
      ) : null}
    </>
  );
}
