import React from "react";
import { useState } from "react";
// import { ThemeProvider } from "@material-ui/styles";
// import { Switch, FormControlLabel } from "@mui/material";
import Fade from "@mui/material/Fade";
// import { createTheme } from "@mui/material/styles";
import ArrowLeft from "../../../../assets/img/left_arrow_icon.svg";
import ArrowDown from "../../../../assets/img/down_arrow_icon.svg";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
export default function Cashrow({
  fund,
  productarray,
  setProductarray,
  patchProductArray,
  setPatchProductArray,
  deleteArray,
  setDeleteArray,
  data,
  postProductArray,
  setPostProductArray,
  addedProduct,
}) {
  // const BirrSwitch = createTheme({
  //   overrides: {
  //     MuiSwitch: {
  //       root: {
  //         width: 70,
  //         height: 24,
  //         padding: 0,
  //         marginRight: 10,
  //       },
  //       palette: {
  //         primary: "#0000f5",
  //         secondary: "#0000f5",
  //       },
  //       switchBase: {
  //         padding: 0,
  //         color: "#0000f5",
  //         "&$checked": {
  //           transform: "translateX(45px)",
  //           color: "#0000f5",
  //           "& + $track": {
  //             backgroundColor: "#0000f5",
  //             opacity: 0.4,
  //             border: "none",
  //           },
  //         },
  //         "&$focusVisible $thumb": {
  //           color: "#0000f5",
  //           border: "6px solid #fff",
  //         },
  //       },
  //       thumb: {
  //         width: 24,
  //         height: 24,
  //       },
  //       track: {
  //         borderRadius: 30 / 2,
  //         border: `1px solid #c4c4c4`,
  //         opacity: 0.2,
  //       },
  //       checked: {},
  //     },
  //   },
  // });
  const [open, setOpen] = useState(fund.id === addedProduct ? true : false);
  const checked = false;
  // const [checked, setChecked] = useState(false);

  // let handleChange = (i, e) => {
  //   let newMfarray = [...productarray];
  //   newMfarray[i][e.target.name] = e.target.value;
  //   setProductarray(newMfarray);
  // };
  const icon = (
    <Box>
      <>
        <td className="w-[25%]">
          <TextField
            fullWidth
            sx={{
              fontFamily: "Inter,sans-sarif",
              fontWeight: 400,
            }}
            label="SIP Amount"
            type="text"
          />
        </td>
        <td className="w-[25%]">
          <TextField
            fullWidth
            sx={{
              fontFamily: "Inter,sans-sarif",
              fontWeight: 400,
            }}
            label="SIP frequency"
            type="text"
          />
        </td>
      </>
    </Box>
  );
  const numberOfDays = (date1, date2) => {
    // To calculate the time difference of two dates
    var difference_In_Time = date1.getTime() - date2.getTime();

    // To calculate the no. of days between two dates
    var difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
    return difference_In_Days;
  };
  return (
    <>
      <tr className=" w-full bg-[#f5f5f5] mt-[0.5rem] flex justify-between items-center p-[0.1rem] h-[50px] pr-[0.21rem] rounded-[4px]">
        <td className=" font-medium text-[15px] text-[#000]">{fund.name}</td>
        <td>
          <tr>
            <td className=" font-medium text-[14px]">{`₹ ${parseInt(
              fund.last_amount ? parseFloat(fund.last_amount).toFixed(0) : 0
            ).toLocaleString("en-IN")}`}</td>
            <td
              className=" font-medium text-[15px] text-primary-200 mr-[1rem]"
              onClick={() => {
                let newMfArray = [...productarray];
                let index = productarray.findIndex((pid) => pid.id === fund.id);
                newMfArray.splice(index, 1);
                setProductarray(newMfArray);
                //editing in patch mf array
                if (data.some((getFund) => getFund.id === fund.id)) {
                  let newPatchMfArray = [...patchProductArray];
                  let patchindex = newPatchMfArray.findIndex(
                    (pid) => pid.id === fund.id
                  );
                  if (patchindex !== -1) newPatchMfArray.splice(patchindex, 1);
                  setPatchProductArray(newPatchMfArray);
                  setDeleteArray([
                    ...deleteArray,
                    {
                      ...fund,
                      last_amount_date: new Date(fund.last_amount_date),
                      current_amount_date: new Date(fund.current_amount_date),
                    },
                  ]);
                } //editing in post mf array
                else {
                  let newPostMfArray = [...postProductArray];
                  let postindex = newPostMfArray.findIndex(
                    (pid) => pid.id === fund.id
                  );
                  newPostMfArray.splice(postindex, 1);
                  setPostProductArray(newPostMfArray);
                }
              }}
            >
              Delete
            </td>
            <td
              className="p-[0px] w-[20px] pl-[0.3rem]"
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open ? (
                <img className="block" src={ArrowDown} alt="err" />
              ) : (
                <img className="block" src={ArrowLeft} alt="err" />
              )}
            </td>
          </tr>
        </td>
      </tr>
      {open ? (
        <div className="pt-[16px]">
          <tr className="flex w-full">
            <td className="w-[31%]">
              <TextField
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Name"
                required
                type="text"
                defaultValue={fund.name}
                onChange={(e) => {
                  let newMfArray = [...productarray];
                  let index = productarray.findIndex(
                    (pid) => pid.id === fund.id
                  );
                  newMfArray[index].name = e.target.value;
                  setProductarray(newMfArray);
                  //editing in patch mf array
                  if (data.some((getFund) => getFund.id === fund.id)) {
                    let newPatchMfArray = [...patchProductArray];
                    let patchindex = newPatchMfArray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    if (patchindex === -1) {
                      newPatchMfArray.push({
                        ...fund,
                        last_amount_date: new Date(fund.last_amount_date),
                        current_amount_date: new Date(fund.current_amount_date),
                      });
                      newPatchMfArray[newPatchMfArray.length - 1].name =
                        e.target.value;
                    } else newPatchMfArray[patchindex].name = e.target.value;
                    setPatchProductArray(newPatchMfArray);
                  } //editing in post mf array
                  else {
                    let newPostMfArray = [...postProductArray];
                    let postindex = newPostMfArray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newPostMfArray[postindex].name = e.target.value;
                    setPostProductArray(newPostMfArray);
                  }
                }}
              />
            </td>
            <td className="w-[23%]">
              <TextField
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Last Amount(in ₹)"
                type="text"
                value={fund.last_amount}
                onChange={(e) => {
                  const re = /^\d*\.?\d*$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    let newMfArray = [...productarray];
                    let index = productarray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newMfArray[index].last_amount = e.target.value;
                    setProductarray(newMfArray);
                    //editing in patch mf array
                    if (data.some((getFund) => getFund.id === fund.id)) {
                      let newPatchMfArray = [...patchProductArray];
                      let patchindex = newPatchMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      if (patchindex === -1) {
                        newPatchMfArray.push({
                          ...fund,
                          last_amount_date: new Date(fund.last_amount_date),
                          current_amount_date: new Date(
                            fund.current_amount_date
                          ),
                        });
                        newPatchMfArray[
                          newPatchMfArray.length - 1
                        ].last_amount = e.target.value;
                      } else
                        newPatchMfArray[patchindex].last_amount =
                          e.target.value;
                      setPatchProductArray(newPatchMfArray);
                    } //editing in post mf array
                    else {
                      let newPostMfArray = [...postProductArray];
                      let postindex = newPostMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      newPostMfArray[postindex].last_amount = e.target.value;
                      setPostProductArray(newPostMfArray);
                    }
                  }
                }}
              />
            </td>
            <td className="w-[23%]">
              <TextField
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Interest Rate(in %)"
                type="text"
                value={fund.interest_rate}
                onChange={(e) => {
                  const re = /^\d*\.?\d*$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    let newMfArray = [...productarray];
                    let index = productarray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newMfArray[index].interest_rate = e.target.value;
                    setProductarray(newMfArray);
                    //editing in patch mf array
                    if (data.some((getFund) => getFund.id === fund.id)) {
                      let newPatchMfArray = [...patchProductArray];
                      let patchindex = newPatchMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      if (patchindex === -1) {
                        newPatchMfArray.push({
                          ...fund,
                          last_amount_date: new Date(fund.last_amount_date),
                          current_amount_date: new Date(
                            fund.current_amount_date
                          ),
                        });
                        newPatchMfArray[
                          newPatchMfArray.length - 1
                        ].interest_rate = e.target.value;
                      } else
                        newPatchMfArray[patchindex].interest_rate =
                          e.target.value;
                      setPatchProductArray(newPatchMfArray);
                    } //editing in post mf array
                    else {
                      let newPostMfArray = [...postProductArray];
                      let postindex = newPostMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      newPostMfArray[postindex].interest_rate = e.target.value;
                      setPostProductArray(newPostMfArray);
                    }
                  }
                }}
              />
            </td>
            <td className="w-[23%]">
              <TextField
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Current Amount(in ₹)"
                type="text"
                value={(
                  parseInt(fund.last_amount) *
                  (1 + parseFloat(fund.interest_rate / 100)) **
                    (numberOfDays(
                      new Date(fund.current_amount_date),
                      new Date(fund.last_amount_date)
                    ) /
                      365)
                ).toLocaleString("en-IN")}
                disabled
              />
            </td>
          </tr>
          <tr className="flex w-full">
            <td className="w-[35%]">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  autoOk={true}
                  className="client-input-date-input"
                  inputFormat="DD-MM-YYYY"
                  id="date-picker-inline"
                  label="Last Amount Date"
                  value={new Date(fund.last_amount_date)}
                  onChange={(date) => {
                    let newMfArray = [...productarray];
                    let index = productarray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newMfArray[index].last_amount_date = date;
                    setProductarray(newMfArray);
                    //editing in patch mf array
                    if (data.some((getFund) => getFund.id === fund.id)) {
                      let newPatchMfArray = [...patchProductArray];
                      let patchindex = newPatchMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      if (patchindex === -1) {
                        newPatchMfArray.push({
                          ...fund,
                          last_amount_date: new Date(fund.last_amount_date),
                          current_amount_date: new Date(
                            fund.current_amount_date
                          ),
                        });
                        newPatchMfArray[
                          newPatchMfArray.length - 1
                        ].last_amount_date = date;
                      } else
                        newPatchMfArray[patchindex].last_amount_date = date;
                      setPatchProductArray(newPatchMfArray);
                    } //editing in post mf array
                    else {
                      let newPostMfArray = [...postProductArray];
                      let postindex = newPostMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      newPostMfArray[postindex].last_amount_date = date;
                      setPostProductArray(newPostMfArray);
                    }
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </td>
            <td className="w-[35%]">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  autoOk={true}
                  className="client-input-date-input"
                  inputFormat="DD-MM-YYYY"
                  id="date-picker-inline"
                  label="Curent Amount Date"
                  value={new Date(fund.current_amount_date)}
                  disabled={true}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </td>
            <td className="w-[29%]">
              <TextField
                fullWidth
                disabled
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Bank"
                type="text"
                value={fund.bank}
                onChange={(e) => {
                  let newMfArray = [...productarray];
                  let index = productarray.findIndex(
                    (pid) => pid.id === fund.id
                  );
                  newMfArray[index].bank = e.target.value;
                  setProductarray(newMfArray);
                  //editing in patch mf array
                  if (data.some((getFund) => getFund.id === fund.id)) {
                    let newPatchMfArray = [...patchProductArray];
                    let patchindex = newPatchMfArray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    if (patchindex === -1) {
                      newPatchMfArray.push({
                        ...fund,
                        last_amount_date: new Date(fund.last_amount_date),
                        current_amount_date: new Date(fund.current_amount_date),
                      });
                      newPatchMfArray[newPatchMfArray.length - 1].bank =
                        e.target.value;
                    } else newPatchMfArray[patchindex].bank = e.target.value;
                    setPatchProductArray(newPatchMfArray);
                  } //editing in post mf array
                  else {
                    let newPostMfArray = [...postProductArray];
                    let postindex = newPostMfArray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newPostMfArray[postindex].bank = e.target.value;
                    setPostProductArray(newPostMfArray);
                  }
                }}
              />
            </td>

            {/* <td style={{ width: "29%" }}>
                  <TextField
                    fullWidth
                    disabled
                    sx={{
                      fontFamily: "Inter,sans-sarif",
                      fontWeight: 400,
                    }}
                    label="Current Value"
                    type="text"
                    value={(
                      fund.last_amount * fund.current_price
                    ).toLocaleString('en-IN')}
                  />
                  <label>SIP ?</label>
                  <br />
                  <ThemeProvider theme={BirrSwitch}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checked}
                          onChange={(e) => setChecked(!checked)}
                          inputProps={{ role: "switch" }}
                          color="#0122AF"
                        />
                      }
                    />
                  </ThemeProvider>
                </td> */}

            <Fade in={checked}>{icon}</Fade>
          </tr>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
