import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import "../../../../../static/css/existing/dashboard_styles.css";
import PieChart from "../../../../dashboard/PieChart";
import InvestedgePagination from "../../../../../components/pagination/InvestedgePagination";
export default function Portfolio({ fundData }) {
  const [pageNumber, setPageNumber] = useState(0);
  let debt = !fundData.json_field.asset_allocation.data.Debt
    ? 0
    : fundData.json_field.asset_allocation.data.Debt.toFixed(2);
  let equity = !fundData.json_field.asset_allocation.data.Equity
    ? 0
    : fundData.json_field.asset_allocation.data.Equity.toFixed(2);
  let others = !fundData.json_field.asset_allocation.data.Others
    ? 0
    : fundData.json_field.asset_allocation.data.Others.toFixed(2);
  const largeCap = !fundData.json_field.market_cap_allocation.data["Large Cap"]
    ? 0
    : fundData.json_field.market_cap_allocation.data["Large Cap"].toFixed(2);
  const midCap = !fundData.json_field.market_cap_allocation.data["Mid Cap"]
    ? 0
    : fundData.json_field.market_cap_allocation.data["Mid Cap"].toFixed(2);
  const marketcapOthers = !fundData.json_field.market_cap_allocation.data[
    "Others"
  ]
    ? 0
    : fundData.json_field.market_cap_allocation.data["Others"].toFixed(2);
  const smallCap = !fundData.json_field.market_cap_allocation.data["Small Cap"]
    ? 0
    : fundData.json_field.market_cap_allocation.data["Small Cap"].toFixed(2);
  const sectorLebels = fundData.json_field.sector_allocation.data;
  const ratinglabelsData = fundData.json_field.rating_allocation.data;
  const portfolioData = fundData.json_field.holdings.holdings;
  const clientsPerPage = 5;
  const pagesVisited = pageNumber * clientsPerPage;
  const displayPortfolio = fundData.json_field.holdings.holdings
    .slice(pagesVisited, pagesVisited + clientsPerPage)
    .map((PortfolioData, index) => {
      return (
        <tr key={index} className="cursor-pointer border-b border-[#e0dddd]">
          <td
            className="dashboard-body-second-card-table-data"
            style={{
              paddingLeft: "1rem",
              textAlign: "left",
            }}
          >
            {PortfolioData.name}
          </td>
          <td className="dashboard-body-second-card-table-data">
            {PortfolioData.instrument_asset}
          </td>
          <td className="dashboard-body-second-card-table-data">
            {`${PortfolioData.pct.toFixed(2)}%`}
          </td>
          <td className="dashboard-body-second-card-table-data">
            {PortfolioData.qty.toFixed(2)}
          </td>
          <td className="dashboard-body-second-card-table-data">
            {PortfolioData.mv.toFixed(2)}
          </td>
          <td className="dashboard-body-second-card-table-data">
            {PortfolioData.instrument_asset_type}
          </td>
        </tr>
      );
    });
  const pageCount = Math.ceil(portfolioData.length / clientsPerPage);
  const changePage = (event, value) => {
    setPageNumber(value - 1);
  };
  const OnepagerLegend = ({ color, text }) => {
    return (
      <div className="flex mt-[15px]">
        <div
          className="rounded-[4px] w-[14px] h-[14px] mr-[15px] mt-[3px]"
          style={{
            backgroundColor: color,
          }}
        />
        <p className="onepager-description-text">{text}</p>
      </div>
    );
  };
  const SectorData = {
    labels: Object.entries(sectorLebels).map(([key, value]) => key),
    datasets: [
      {
        data: Object.entries(sectorLebels).map(([key, value]) =>
          value ? value.toFixed(2) : 0
        ),
        borderWidth: 1,
        backgroundColor: "#D16969",
      },
    ],
  };
  const RatingData = {
    labels: Object.entries(ratinglabelsData).map(([key, value]) => key),
    datasets: [
      {
        data: Object.entries(sectorLebels).map(([key, value]) =>
          value ? value.toFixed(2) : 0
        ),
        borderWidth: 1,
        backgroundColor: "#E5B50B",
      },
    ],
  };

  const RatingOptions = {
    scales: {
      xAxes: [
        {
          barThickness: 42,
          barPercentage: 1,
          ticks: {
            maxTicksLimit: 6,
            maxRotation: 0,
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            callback: function (value, index, values) {
              return value + "%";
            },
          },
          type: "linear",
          display: true,
          position: "left",
          gridLines: {
            display: false,
          },
        },
      ],
    },

    elements: {
      bar: {
        // borderWidth: 7,
      },
      point: {
        radius: 0,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: false,
    },
    plugins: {
      labels: {
        render: () => {},
      },
      datalabels: {
        display: false,
      },
    },
  };
  const SectorOptions = {
    legend: {
      display: false,
    },

    scales: {
      xAxes: [
        {
          barPercentage: 0.7,
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
    },
    elements: {
      bar: {
        borderWidth: 3,
      },
    },
    responsive: true,
    plugins: {
      // labels: {
      //   render: "value",
      //   fontColor: "#000",
      //   position: "default",
      //   outsidePadding: 14,
      //   textMargin: 3,
      // },
      labels: {
        render: () => {},
      },
      datalabels: {
        display: false,
      },
    },
  };
  const AssetAllocationData = {
    labels: ["Equity", "Debt", "Others"],
    datasets: [
      {
        data: [equity, debt, others],
        backgroundColor: ["#ED6D84", "#F8CE6C", "#56A1E5"],
        borderColor: ["#ED6D84", "#F8CE6C", "#56A1E5"],
        borderWidth: 1,
      },
    ],
  };
  const MarketCapData = {
    labels: ["Large Cap", "Mid Cap", "Small Cap", "Others"],
    datasets: [
      {
        data: [largeCap, midCap, smallCap, marketcapOthers],
        backgroundColor: ["#ED6D84", "#F8CE6C", "#56A1E5", "#6CBDC0"],
        borderColor: ["#ED6D84", "#F8CE6C", "#56A1E5", "#6CBDC0"],
        borderWidth: 1,
      },
    ],
  };

  const pieOptions = {
    legend: {
      display: false,
    },
    plugins: {
      labels: [
        {
          render: "label",
          position: "outside",
          fontFamily: "Inter,sans-serif",
          fontColor: "#323232",
          textMargin: 4,
          fontSize: 14,
        },
        {
          render: function (args) {
            return `${args.value}%`;
          },
          fontColor: "#fff",
        },
      ],
      datalabels: {
        display: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };
  return (
    <>
      <div
        className="onepager-main-card"
        style={{
          borderRadius: "0px 0px 8px 8px",
        }}
      >
        <div className="flex w-full">
          <div className="flex items-start justify-center pt-2 w-[72%] h-full">
            <div className="flex w-6/12 flex-col ">
              <h4 className="card-heading mb-[25px] pl-[0.5rem">
                Asset Allocation
              </h4>
              <div className="chart-container-div mr-2">
                <PieChart
                  data={AssetAllocationData}
                  options={pieOptions}
                  width={270}
                  height={160}
                />
                <div className="flex  justify-evenly">
                  <OnepagerLegend color={"#ED6D84"} text="Equity" />
                  <OnepagerLegend color={"#F8CE6C"} text="Debt" />
                  <OnepagerLegend color={"#56A1E5"} text="Others" />
                </div>
              </div>
            </div>
            <div className="flex w-6/12 flex-col ">
              <h4 className="card-heading mb-[25px] pl-[0.5rem]">
                Market Cap Allocation
              </h4>

              <div className="chart-container-div justify-center ml-2 ">
                <PieChart
                  data={MarketCapData}
                  options={pieOptions}
                  width={270}
                  height={160}
                />
                <div className="flex  justify-evenly">
                  <OnepagerLegend color={"#ED6D84"} text="Large Cap" />
                  <OnepagerLegend color={"#F8CE6C"} text="Mid Cap" />
                  <OnepagerLegend color={"#56A1E5"} text="Small Cap" />
                  <OnepagerLegend color={"#D4D4D4"} text="Others" />
                </div>
              </div>
            </div>
          </div>
          <div className="border-[1px]  onepager-return-right-card p-4 h-[460px] ml-4 w-[27%]  rounded-[8px] bg-[#FFF]">
            <p
              className="card-heading mb-2"
              style={{
                fontSize: "14px",
              }}
            >
              Fund Checklist
            </p>
            <ul className="pl-[1rem] list-disc">
              <li className="text-[#6A6A6A] text-[14px] font-normal font-inter mb-[1.5rem]">
                Fund has been able to generate better returns compared to other
                funds in the same category.
              </li>
              <li className="text-[#6A6A6A] text-[14px] font-normal font-inter mb-[1.5rem]">
                Less expense ratio implies better returns over the long term.
              </li>
            </ul>
          </div>
        </div>
        {/* second card */}
        <div className="w-[72%] ">
          <h4 className="card-heading mb-6 ">Sector Allocation</h4>
          <div className="chart-container-div  h-[330px]">
            <Bar data={SectorData} options={SectorOptions} height={100} />
          </div>
        </div>
        {/* third rating card */}
        <div className="w-[72%] pt-8 ">
          <h4 className="card-heading mb-6 ">Rating Allocation</h4>
          <div className="chart-container-div h-[330px]">
            <Bar data={RatingData} options={RatingOptions} />
          </div>
        </div>
        {/* fourth card onepager */}
        <div className=" w-[72%] pt-[60px]">
          <h4 className="card-heading mb-[20px]">Full Portfolio</h4>
          <div className="rounded-md   border-[1px]  ">
            <table className="w-full">
              <colgroup>
                <col width="25%" />
                <col width="15%" />
                <col width="15%" />
                <col width="15%" />
                <col width="15%" />
                <col width="15%" />
              </colgroup>
              <tbody>
                <tr className="rounded-[10px] h-10 font-inter font-normal border-b-[1px] border-b-neutralBlack">
                  <th className="pl-4 font-semibold text-left"> Name</th>
                  <th className="text-center font-semibold">ISIN</th>
                  <th className="text-center font-semibold">Weight %</th>
                  <th className="text-center font-semibold">Quantity</th>
                  <th className="text-center font-semibold">
                    Market Value (in Cr)
                  </th>
                  <th className="text-center font-semibold">
                    Instrument asset type
                  </th>
                </tr>
                {displayPortfolio}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end mt-4">
            <InvestedgePagination
              count={pageCount}
              defaultPage={1}
              page={pageNumber + 1}
              onChange={changePage}
              color="primary"
            />
          </div>
        </div>
      </div>
    </>
  );
}
