import React, { useEffect, useState } from "react";
import Vector from "../../../../assets/img/modelportfolioemptylist.svg";
import InvestedgeSearchField from "../../../../components/searchfield/InvestedgeSearchField";
import PortfolioCard from "./PortfolioCard";
import InvestedgePagination from "../../../../components/pagination/InvestedgePagination";
import InvestedgeTabs from "../../../../components/tabs/InvestedgeTabs";
import InvestedgeTextField from "../../../../components/textfield/InvestedgeTextField";
import {
  GetModelPortfolio,
  PostEventLoggingData,
} from "../../../../actions/actions";
import { useDispatch, useSelector } from "react-redux";
export default function SelectProposal({
  portfolioId,
  setPortfolioId,
  modelHorizon,
  setModelHorizon,
  errorModel,
  setErrorModel,
}) {
  const modelportfolios = useSelector((state) => state.getModelPortfolio);
  const [portfolioType, setPortfolioType] = useState(0);
  const [searchClient, setSearchClient] = useState("");
  const [bookmarkPortfolios, setBookmarkPortfolios] = useState({});
  const [paginatedData, setPaginatedData] = useState(modelportfolios);
  const tabs = [{ label: "All" }, { label: "Bookmarked" }];
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(0);
  const clientsPerPage = 4;
  const pagesVisited = pageNumber * clientsPerPage;
  const pageCount = Math.ceil(paginatedData.length / clientsPerPage);
  const changePage = (e, val) => {
    setPageNumber(val - 1);
  };

  useEffect(() => {
    setPaginatedData(
      modelportfolios.filter(
        (portfolio) =>
          (portfolioType === 0
            ? portfolio.bookmark !== !portfolio.bookmark
            : bookmarkPortfolios[portfolio.id]) &&
          portfolio.portfolio_name
            .toLowerCase()
            .includes(searchClient.toLowerCase())
      )
    );
    setPageNumber(0); // eslint-disable-next-line
  }, [searchClient, portfolioType]);

  useEffect(() => {
    setPaginatedData(modelportfolios);
    const temp = bookmarkPortfolios;
    for (let i = 0; i < modelportfolios.length; i++)
      temp[modelportfolios[i].id] = modelportfolios[i].bookmark;
    setBookmarkPortfolios(temp); // eslint-disable-next-line
  }, [modelportfolios]);

  useEffect(() => {
    dispatch(GetModelPortfolio());
  }, [dispatch]);
  return (
    <>
      {modelportfolios.length ? (
        <>
          <div className="h-auto mt-4">
            <InvestedgeTextField
              required
              sx={{
                fontFamily: "Inter,sans-sarif",
                fontWeight: 400,
                width: "30%",
                marginTop: "1rem",
              }}
              label="Investment Horizon (Years)"
              error={errorModel ? true : false}
              helperText={errorModel ? errorModel : false}
              min="1"
              max="100"
              value={modelHorizon}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  if (e.target.value.length) setErrorModel(null);
                  else setErrorModel("This field is required");
                  setModelHorizon(e.target.value);
                }
              }}
            />
            <div className="flex justify-between w-full mt-2">
              <InvestedgeTabs
                tabs={tabs}
                tabvalue={portfolioType}
                handletabchange={(e, value) => {
                  dispatch(
                    PostEventLoggingData({
                      module_name: "Model Portfolio",
                      event_type: "Information",
                      url: window.location.href,
                      note: "Tabs clicked",
                    })
                  );
                  setPortfolioType(value);
                }}
              />
              <InvestedgeSearchField
                type="text"
                className="w-1/3"
                value={searchClient}
                onChange={(e) => setSearchClient(e.target.value)}
                placeholder="Search model portfolio"
              />
            </div>
          </div>
          <div className="w-full grid grid-cols-4 gap-5 pt-8">
            {paginatedData
              .slice(pagesVisited, pagesVisited + clientsPerPage)
              .map((portfolio, index) => {
                return (
                  <PortfolioCard
                    portfolioId={portfolioId}
                    setPortfolioId={setPortfolioId}
                    portfolio={portfolio}
                    bookmarkPortfolios={bookmarkPortfolios}
                    setBookmarkPortfolios={setBookmarkPortfolios}
                    key={index}
                    // setHorizon={setHorizon}
                  />
                );
              })}
          </div>
          <div className="w-full flex justify-center mt-12">
            <InvestedgePagination
              count={pageCount}
              defaultPage={1}
              page={pageNumber + 1}
              onChange={changePage}
              color="primary"
            />
          </div>
        </>
      ) : (
        <>
          <div className="mt-8 w-full flex flex-col justify-center items-center">
            <img src={Vector} alt="err" className="h-[200px]" />
            <p className="card-heading mt-8">
              No Portfolios Found. Start creating one now!
            </p>
          </div>
        </>
      )}
    </>
  );
}
