import React, { useState } from "react";
import InvestedgeModal from "../../components/modal/InvestedgeModal";
// import { IoIosClose } from "react-icons/io";
import GoalSuccess from "../../assets/img/goalSuccess.svg";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import InvestedgeButtonHollow from "../../components/buttons/InvestedgeButtonHollow";
import { Link } from "react-router-dom";
import TagInvestmentIndex from "./TagInvestments/TagInvestmentIndex";
export default function SuccessModal({ stateGoal, modal, setModal, name, id }) {
  const [tagsOpen, setTagsOpen] = useState(false);
  return (
    <InvestedgeModal
      aria-labelledby="Change Password Modal"
      aria-describedby="Change Password Modal"
      sx={{
        minHeight: "55vh",
        minWidth: "60vw",
        margin: "auto",
        padding: "1.5rem",
      }}
      open={modal}
      handleclose={() => setModal(false)}
    >
      {/* <div className="flex justify-between items-start">
        <h3 className="card-heading">
          Calculate your inflation adjusted Goal Value here
        </h3>
        <IoIosClose size={30} onClick={() => setModal(false)} />
      </div> */}
      <div className="flex flex-col justify-center items-center mt-8">
        <img src={GoalSuccess} alt="err" />
        <p className="text-neutralBlack font-bold mt-6">
          {stateGoal ? "Changes Saved for" : "New Goal Added"}
        </p>
        <p className="text-primary-200 font-semibold mt-6 text-2xl">{name}</p>
        <div className="w-[90%] flex justify-between mt-4">
          <Link to="/goalplanning">
            <InvestedgeButtonHollow>
              Go to Goal Dashboard
            </InvestedgeButtonHollow>
          </Link>

          <InvestedgeButton onClick={() => setTagsOpen(true)}>
            Tag Investments
          </InvestedgeButton>
          <TagInvestmentIndex
            open={tagsOpen}
            id={id}
            name={name}
            onClose={() => setTagsOpen(false)}
          />
        </div>
      </div>
    </InvestedgeModal>
  );
}
