import React, { useState } from "react";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import InvestedgeTextField from "../../../components/textfield/InvestedgeTextField";
import AfterloginApp from "../../AfterloginApp";
import InvestedgeGPTSidebar from "../InvestedgeGPTSidebar";
import axios from "axios";
import urls from "../../../urls";
import CopyToClipboard from "react-copy-to-clipboard";
import { Bars } from "react-loader-spinner";
import parse from "html-react-parser";
import Copy from "../../../assets/img/copytoclipboard.svg";
// import Typewriter from "typewriter-effect";
// import Empty from "../../../assets/img/search_query.svg";

export default function Generic() {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState({
    gpt_answer: "",
    gpt_links: [],
    google_results: [],
  });

  const handleSuggestion = async (e) => {
    setQuery(e.target.innerText);
    try {
      setResults((prev) => {
        return { ...prev, gpt_answer: "loading" };
      });

      const response = await fetch(urls.investedgegptProductGenericURL, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query: e.target.innerText }),
      });
      if (!response.ok || !response.body) {
        throw response.statusText;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let break_flag = false;

      while (true) {
        const { value, done } = await reader.read();

        if (done) {
          // setLoading(false);
          break;
        }
        const decodedChunk = decoder.decode(value, { stream: true });
        // console.log(decodedChunk);
        if (break_flag) {
          let parsed_object = JSON.parse(decodedChunk);
          setResults((prevValue) => {
            return {
              ...prevValue,
              ...parsed_object,
            };
          });
          break;
        }

        if (decodedChunk === "SKMKB") break_flag = true;
        else {
          setResults((prevValue) => {
            return {
              gpt_answer:
                prevValue.gpt_answer === "loading"
                  ? decodedChunk
                  : `${prevValue.gpt_answer}${decodedChunk}`.replaceAll(
                      "\n",
                      "<br/>"
                    ),
              gpt_links: [],
              google_results: [],
            };
          });
        }
      }
    } catch (error) {
      // console.log(error);
      let refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken && error === "Unauthorized") {
        await axios
          .post(urls.refreshTokenURL, {
            refresh: refreshToken,
          })
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem("accessToken", res.data.access);
              handleSuggestion(e);
            }
          })
          .catch((err) => {
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("accessToken");
            window.location.pathname = "/login";
          });
      }
    }
  };
  const handleSubmit = async (e) => {
    e && e?.preventDefault();

    try {
      setResults((prev) => {
        return { ...prev, gpt_answer: "loading" };
      });

      const response = await fetch(urls.investedgegptProductGenericURL, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query: query }),
      });
      if (!response.ok || !response.body) {
        throw response.statusText;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let break_flag = false;

      while (true) {
        const { value, done } = await reader.read();

        if (done) {
          // setLoading(false);
          break;
        }
        const decodedChunk = decoder.decode(value, { stream: true });
        // console.log(decodedChunk);
        if (break_flag) {
          let parsed_object = JSON.parse(decodedChunk);
          setResults((prevValue) => {
            return {
              ...prevValue,
              ...parsed_object,
            };
          });
          break;
        }

        if (decodedChunk === "SKMKB") break_flag = true;
        else {
          setResults((prevValue) => {
            return {
              gpt_answer:
                prevValue.gpt_answer === "loading"
                  ? decodedChunk
                  : `${prevValue.gpt_answer}${decodedChunk}`.replaceAll(
                      "\n",
                      "<br/>"
                    ),
              gpt_links: [],
              google_results: [],
            };
          });
        }
      }
    } catch (error) {
      // console.log(error);
      let refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken && error === "Unauthorized") {
        await axios
          .post(urls.refreshTokenURL, {
            refresh: refreshToken,
          })
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem("accessToken", res.data.access);
              handleSubmit();
            }
          })
          .catch((err) => {
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("accessToken");
            window.location.pathname = "/login";
          });
      }
    }
    // axios
    //   .post(urls.investedgegptMarketsURL, { query })
    //   .then((res) => setResults(res.data))
    //   .catch((err) => {}
    //   );
  };

  const renderOutput = results.gpt_answer ? (
    <div className="p-[1rem] px-0 bg-[#fff] rounded-[0.5rem] w-full ml-0 mt-4">
      {results.gpt_answer === "loading" ? (
        <div className="h-[300px] flex justify-center items-center">
          <Bars color="#1982f8" height="60" width="60" />
        </div>
      ) : (
        <>
          <div className="w-full flex justify-between">
            <p className="font-medium text-neutralBlack">InvestGPT’s Answer</p>
            <CopyToClipboard text={results.gpt_answer.replaceAll("<br/>", "")}>
              <div className="flex items-center">
                <img src={Copy} alt="err" className="mr-2" />
                <p className="text-sm text-primary-200">Copy to Clipboard</p>
              </div>
            </CopyToClipboard>
          </div>
          <p className="text-sm text-justify mt-4 leading-[20px] bg-[#FAFAFA] border border-[#e2e2e2] rounded-[4px] p-4">
            {parse(results.gpt_answer)}
          </p>
          {results.google_results.length ? (
            <div className="w-full grid sm:grid-cols-1 grid-cols-1 mt-8">
              <div className="col-span-1">
                <p className="font-medium text-neutralBlack">
                  Google results of the same query
                </p>

                <div className="bg-[#FAFAFA] p-4 pt-2 mt-2">
                  {results.google_results.slice(0, 3).map((result, index) => (
                    <div
                      key={index}
                      className="font-inter-regular text-base mt-2 leading-5"
                    >
                      <a
                        href={result.url}
                        target="_blank"
                        rel="noreferrer"
                        className="hover:text-primary-200 text-primary-200 decoration-solid"
                      >
                        {result.title}
                      </a>
                      <p className="font-inter-regular text-sm mt-2 leading-5">
                        {result.description}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-span-1"></div>
            </div>
          ) : null}
        </>
      )}
    </div>
  ) : (
    <div className="space-y-6 mt-6">
      <p className="font-medium text-neutralBlack">Suggested Queries </p>
      <div className="space-y-4 text-primary-200 text-sm font-medium ">
        <p
          className="cursor-pointer inline-block"
          onClick={(e) => handleSuggestion(e)}
        >
          What’s happening in China?
        </p>
        <br />
        <p
          className="cursor-pointer inline-block"
          onClick={(e) => handleSuggestion(e)}
        >
          Any favorable investment theme as of now?
        </p>
        <br />
        <p
          className="cursor-pointer inline-block"
          onClick={(e) => handleSuggestion(e)}
        >
          How to initiate client conversation as a financial advisor?
        </p>
        <br />
        <p
          className="cursor-pointer inline-block"
          onClick={(e) => handleSuggestion(e)}
        >
          Guide for effective financial planning
        </p>
      </div>
    </div>
  );
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp />
      <div className="loggedinscreen-container pt-0">
        <div className="loggedinscreen flex flex-row justify-between">
          <InvestedgeGPTSidebar />
          <div className="p-[1rem] bg-[#fff] rounded-[0.5rem] min-h-full client-input-parent-card mt-4 w-[79%] ml-0 relative overflow-auto scrollable-div">
            <form onSubmit={handleSubmit}>
              <div className="w-full flex justify-between mt-2">
                <InvestedgeTextField
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  label="Enter Query Here"
                  className="w-3/4"
                />
                <InvestedgeButton
                  type="submit"
                  className="w-[10%]"
                  disabled={results.gpt_answer === "loading"}
                >
                  Submit
                </InvestedgeButton>
              </div>
            </form>
            {renderOutput}
          </div>
        </div>
      </div>
    </div>
  );
}
