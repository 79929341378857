import React, { useEffect, useState } from "react";
import Dropdown from "../../../../../components/Dropdowns/DropdownObjects";
import ClientCard from "./ClientCard";
import { useDispatch, useSelector } from "react-redux";
import { GetClients } from "../../../../../actions/actions";
import InvestedgeSearchField from "../../../../../components/searchfield/InvestedgeSearchField";
import InvestedgePagination from "../../../../../components/pagination/InvestedgePagination";
import InvestedgeButton from "../../../../../components/buttons/InvestedgeButton";
export default function SelectClientPortfolio({
  setPortfolio,
  setRoute,
  onClose,
}) {
  const [selectedClient, setSelectedClient] = useState(null);
  const rms = useSelector((state) => state.clients);
  const [selected, setSelected] = useState(
    rms.length && Object.keys(rms[0]).length !== 0
      ? rms[0]
      : {
          rmid: "",
          rm_name: "",
          clients: [
            {
              client_info: {
                username: "",
                email: "",
                role: "",
                profile_pic: null,
              },
              client_type: "",
              is_prospect: true,
            },
          ],
        }
  );
  const [clientData, setClientData] = useState(
    rms.length && Object.keys(rms[0]).length !== 0
      ? selected.clients.filter((data) => data.is_prospect === false)
      : [
          {
            client_info: {
              username: "",
              email: "",
              role: "",
              profile_pic: null,
            },
            client_type: "",
            is_prospect: true,
          },
        ]
  );
  const [searchClient, setSearchClient] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const clientsPerPage = 8;
  const dispatch = useDispatch();
  const pagesVisited = pageNumber * clientsPerPage;
  const pageCount = Math.ceil(clientData.length / clientsPerPage);
  const changePage = (e, val) => {
    setPageNumber(val - 1);
  };
  const [clienttype, setClienttype] = useState("Client");

  useEffect(() => {
    dispatch(GetClients());
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setClientData(
      selected.clients.filter(
        (client) =>
          (clienttype === "Client"
            ? client.is_prospect === false
            : client.is_prospect === true) &&
          client.client_info.username
            .toLowerCase()
            .includes(searchClient.toLowerCase())
      )
    );
    setPageNumber(0); // eslint-disable-next-line
  }, [searchClient]);
  useEffect(() => {
    setSelected(
      rms.length && Object.keys(rms[0]).length !== 0
        ? rms[0]
        : {
            rmid: "",
            rm_name: "",
            clients: [
              {
                client_info: {
                  username: "",
                  email: "",
                  role: "",
                  profile_pic: null,
                },
                client_type: "",
                is_prospect: true,
              },
            ],
          }
    );
    setClientData(
      selected.clients.filter((data) => data.is_prospect === false)
    ); // eslint-disable-next-line
  }, [rms]);

  useEffect(() => {
    setClientData(
      selected.clients.filter((data) =>
        clienttype === "Client"
          ? data.is_prospect === false
          : data.is_prospect === true
      )
    ); // eslint-disable-next-line
  }, [selected]);
  return (
    <div className="w-[65vw] relative">
      <div className="flex w-full justify-between items-center">
        <div className="w-[22%] pt-[0.3rem]">
          <Dropdown
            options={rms}
            selected={selected}
            setSelected={setSelected}
            attribute="rm_name"
            labelName="Relationship Manager"
          />
        </div>
        <InvestedgeSearchField
          type="text"
          placeholder="Search for Client name"
          value={searchClient}
          onChange={(e) => setSearchClient(e.target.value)}
        />
      </div>
      <div className="flex justify-between w-full mt-[1rem] border-b border-[#e0dddd]">
        <div className="flex">
          <div
            onClick={() => {
              setClienttype("Client");
              setClientData(
                selected.clients.filter((data) => data.is_prospect === false)
              );
            }}
            className={
              clienttype === "Client"
                ? " font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-primary-200 cursor-pointer pt-[1rem] pb-[0.5rem] border-b-[2px] border-primary-200"
                : " font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-[#999999] cursor-pointer pt-[1rem]"
            }
          >
            Clients
          </div>
          <div
            style={{ marginLeft: "2rem" }}
            className={
              clienttype === "Prospect"
                ? " font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-primary-200 cursor-pointer pt-[1rem] pb-[0.5rem] border-b-[2px] border-primary-200 ml-[2rem]"
                : " font-inter text-[14px] not-italic font-medium leading-[16px] tracking-[0em] text-left text-[#999999] cursor-pointer pt-[1rem] ml-[2rem]"
            }
            onClick={() => {
              setClienttype("Prospect");
              setClientData(
                selected.clients.filter((data) => data.is_prospect === true)
              );
            }}
          >
            Prospects
          </div>
        </div>
      </div>
      <div className="w-full grid grid-cols-4 gap-5 pt-4 min-h-[366px]">
        {clientData
          .slice(pagesVisited, pagesVisited + clientsPerPage)
          .map((client, index) => {
            return (
              <ClientCard
                selectedClient={selectedClient}
                setSelectedClient={setSelectedClient}
                client={client}
                key={index}
                setPortfolio={setPortfolio}
              />
            );
          })}
      </div>
      <div className="w-full flex justify-end mt-4">
        <InvestedgePagination
          count={pageCount}
          defaultPage={1}
          page={pageNumber + 1}
          onChange={changePage}
          color="primary"
        />
      </div>
      <div className="flex justify-end mt-4">
        <div className="flex">
          <InvestedgeButton
            onClick={() => {
              setRoute("portfolioSelectionScreen");
            }}
          >
            Back
          </InvestedgeButton>
          <InvestedgeButton
            className="ml-4"
            onClick={() => {
              setRoute("portfolioSelectionScreen");
              onClose();
            }}
          >
            Create
          </InvestedgeButton>
        </div>
      </div>
    </div>
  );
}
