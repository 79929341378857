import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
// import TagManager from "react-gtm-module";
import "tw-elements";
import "./assets/css/style.css";
import { Provider } from "react-redux";
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "./components/themes/muiTheme";
import { StyledEngineProvider } from "@mui/material/styles";
// const tagManagerArgs = {
//   gtmId: "GTM-WPBRP2K",
// };

// TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={Theme}>
          <StyledEngineProvider>
            <App />
          </StyledEngineProvider>
        </ThemeProvider>
      </PersistGate>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
