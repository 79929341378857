import React from "react";
import Investedge from "../assets/img/investedge.svg";
import Intelligence from "../assets/img/intelligence_driven.svg";
import Product from "../assets/img/product_stories.svg";
import Portfolio from "../assets/img/portfolio_stories.svg";
import VisibilitySensor from "react-visibility-sensor";
export default function HeroSection({ setBackground }) {
  return (
    <div
      id="investedge-homescreen-herosection"
      className="bg-primarybg-300 relative pt-18 md:pt-0 flex justify-center min-h-[730px]"
    >
      <div
        className="absolute top-0 h-[450px] sm:h-[660px] w-[100vw] md:w-[660px] flex flex-col justify-center items-center rounded-full"
        style={{
          background:
            "conic-gradient(from -13.63deg at 62.8% 52.8%, #2C69FE 0deg, #00CBF9 105deg, #A821E8 241.88deg, #A821E8 356.25deg, #2C69FE 360deg)",
          opacity: 0.3,
          filter: "blur(100px)",
        }}
      />
      <div className="h-[450px] sm:h-[660px] sm:flex sm:flex-col sm:items-center px-[20px] sm:px-0 z-30 pt-[44px] sm:pt-44">
        <VisibilitySensor
          onChange={(isVisible) =>
            isVisible
              ? setBackground("transparent")
              : setBackground("rgba(12, 4, 24, 0.85)")
          }
        >
          <img
            src={Investedge}
            alt="err"
            className="mb-[32px] sm:mb-16 h-[30px] sm:h-[auto]"
          />
        </VisibilitySensor>

        <p className="font-montserrat  text-2xl sm:text-4xl leading-[36px] sm:leading-[54px] sm:text-center text-white">
          Be Ready for Client Meetings.
          <br /> Along with Deliverables. In 2 minutes.
        </p>
        <p className="font-montserrat font-medium text-sm sm:text-xl leading-[21px] sm:leading-[30px] sm:text-center text-[#B1B5C4] mt-6">
          Exceed Your Client Expectations and Leave Them Impressed
        </p>
        <div className="hidden md:grid grid-cols-3 gap-8 mt-16">
          <div className="flex flex-col items-center">
            <img src={Intelligence} alt="err" />
            <p className="font-semibold font-montserrat text-xl leading-[30px] mb-0 mt-4 text-[#FCFCFD]">
              Intelligence-driven Conversations
            </p>
            <p className="text-[#787E92] font-medium font-montserrat text-xl leading-[30px]">
              Made Easy
            </p>
          </div>
          <div className="flex flex-col items-center">
            <img src={Product} alt="err" />
            <p className="font-semibold font-montserrat text-xl leading-[30px] mb-0 mt-4 text-[#FCFCFD]">
              Product and Portfolio Stories
            </p>
            <p className="text-[#787E92] font-medium font-montserrat text-xl leading-[30px]">
              Constructed with Insights
            </p>
          </div>
          <div className="flex flex-col items-center">
            <img src={Portfolio} alt="err" />
            <p className="font-semibold font-montserrat text-xl leading-[30px] mb-0 mt-4 text-[#FCFCFD]">
              Hyper-personalised Presentations
            </p>
            <p className="text-[#787E92] font-medium font-montserrat text-xl leading-[30px]">
              Prepared in Seconds
            </p>
          </div>
        </div>
        <div className="md:hidden grid grid-cols-1 gap-8 mt-12">
          <div className="flex items-center">
            <img src={Intelligence} alt="err" className="w-[30px]" />
            <div className="ml-[20px]">
              <p className="font-semibold font-montserrat text-sm leading-[21px] mb-0 text-[#777E91]">
                Intelligence-driven Conversations
              </p>
              <p className="text-[#FCFCFD] font-medium font-montserrat text-sm leading-[30px] mb-0">
                Constructed with insights
              </p>
            </div>
          </div>
          <div className="flex items-center">
            <img src={Product} alt="err" className="w-[30px]" />
            <div className="ml-[20px]">
              <p className="font-semibold font-montserrat text-sm leading-[21px] mb-0 text-[#777E91]">
                Product and Portfolio Stories
              </p>
              <p className="text-[#FCFCFD] font-medium font-montserrat text-sm leading-[30px] mb-0">
                Constructed with Insights
              </p>
            </div>
          </div>
          <div className="flex items-center">
            <img src={Portfolio} alt="err" className="w-[30px]" />
            <div className="ml-[20px]">
              <p className="font-semibold font-montserrat text-sm leading-[21px] mb-0 text-[#777E91]">
                Hyper-personalised Presentations
              </p>
              <p className="text-[#FCFCFD] font-medium font-montserrat text-sm leading-[30px] mb-0">
                Prepared in Seconds
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
