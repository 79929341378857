import React from "react";
import LeaderBanner from "../assets/img/Leader.svg";
import VisibilitySensor from "react-visibility-sensor";
import ActiveLeader from "../assets/img/active_leader.svg";
import Medal from "../assets/img/Medal.svg";
export default function Leader({ setBg, bg }) {
  return (
    <div className="grid grid-cols-1 mt-36 md:grid-cols-2 md:px-20">
      <div className="hidden md:flex flex-col justify-center">
        <VisibilitySensor>
          <>
            <div
              className="w-[200px] flex pb-[16px] mb-8"
              style={{
                background: bg[1],
                transitionProperty: "width,background",
                transitionDuration: "0.2s,0.2s",
                transitionTimingFunction: "linear",
              }}
            >
              <img src={bg[1].length ? ActiveLeader : Medal} alt="err" />
              <p
                className="font-montserrat mb-0 ml-2 text-xl"
                style={
                  bg[1].length
                    ? { color: "#FCFCFD", fontWeight: 600 }
                    : { color: "7777E91" }
                }
              >
                Leader
              </p>
            </div>
            <h2 className="hidden md:block font-montserrat font-medium text-4xl text-[#FCFCFD] leading-[54px]">
              Demonstrate Your Intelligence and Lead with Confidence
            </h2>
            <p className="font-montserrat text-[#B1B5C4] font-normal text-sm md:text-xl mt-[16px] md:mt-6 md:px-0 px-[20px]">
              Use our technology platform to deliver portfolio proposals,
              portfolio insights to clients. Also, gain access to views directly
              from market experts on our platform - helping you deliver that
              winning pitch. Discuss how client portfolio is positioned
              vis-a-vis market experts.
            </p>
          </>
        </VisibilitySensor>
      </div>
      <div className="md:hidden flex flex-col justify-center">
        <VisibilitySensor>
          <>
            <div
              className="w-[200px] flex pb-[16px] ml-[16px]"
              style={{
                background: bg[1],
                transitionProperty: "width,background",
                transitionDuration: "0.2s,0.2s",
                transitionTimingFunction: "linear",
              }}
            >
              <img src={bg[1].length ? ActiveLeader : Medal} alt="err" />
              <p
                className="font-montserrat mb-0 ml-2"
                style={
                  bg[1].length
                    ? { color: "#FCFCFD", fontWeight: 600 }
                    : { color: "7777E91" }
                }
              >
                Leader
              </p>
            </div>
            <h2 className="md:hidden font-montserrat font-medium text-2xl md:text-4xl text-[#FCFCFD] mt-6 leading-9 sm:leading-[54px] md:px-0 px-[20px]">
              Demonstrate Your Intelligence and Lead with Confidence
            </h2>
            <p className="font-montserrat text-[#B1B5C4] font-normal text-sm md:text-xl mt-[16px] md:mt-6 md:px-0 px-[20px]">
              Use our technology platform to deliver portfolio proposals,
              portfolio insights to clients. Also, gain access to views directly
              from market experts on our platform - helping you deliver that
              winning pitch. Discuss how client portfolio is positioned
              vis-a-vis market experts.
            </p>
          </>
        </VisibilitySensor>
      </div>
      <div className="bg-[url('../img/red_mesh.svg')] bg-cover flex justify-center items-center bg-center">
        <img src={LeaderBanner} alt="err" />
      </div>
    </div>
  );
}
