import React from "react";
import { useState } from "react";
import { Line } from "react-chartjs-2";
import urls from "../../../../../urls";
import axios from "axios";
import InvestedgeButton from "../../../../../components/buttons/InvestedgeButton";
import { InputAdornment, OutlinedInput } from "@mui/material";
import DropdownForAttributes from "../../../../../components/Dropdowns/DropdownForAttributes";
import InvestedgeTextField from "../../../../../components/textfield/InvestedgeTextField";
export default function Projections({ formValue }) {
  const [planningData, setPlanningData] = useState({
    moneyRequiredDenotion: "₹ L",
    moneyRequired: "10",
    time: "5",
    currentInvestmentsDenotion: "₹ L",
    currentInvestments: "5",
    returns: "3",
    returnssip: "8",
    frequencyTopup: "Monthly",
    sipFrequencyTopup: "Monthly",
  });
  const numToMonthMap = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };
  const dateParser = (date) => {
    var dd = String(date.getDate()).padStart(2, "0");
    var mm = String(date.getMonth() + 1).padStart(2, "0");
    var yyyy = date.getFullYear();
    return dd + " " + numToMonthMap[parseInt(mm)].slice(0, 3) + " " + yyyy;
  };
  const denotionParser = (denotion, value) => {
    if (denotion[2] === "K") return parseInt(value) * 1000;
    else if (denotion[2] === "L") return parseInt(value) * 100000;
    else return value * 10000000;
  };
  const [backendData, setBackendData] = useState({
    date: [],
    invested_amount_sip: [],
    invested_amount_lumpsum: [],
    expected_investment_val: [],
    expected_investment_val_lumpsum: [],
    total_invested_amount: [],
    total_expected_investment_value: [],
  });
  const handleCalculate = () => {
    axios
      .post(urls.sipProjectionsURL, {
        lumpsum_amount: denotionParser(
          formValue.proposal_value.denomination[2],
          formValue.proposal_value.amount
        ),
        sip_amount: denotionParser(
          formValue.sip_proposal_value.denomination,
          formValue.sip_proposal_value.amount
        ),
        time_pd: parseInt(formValue.investment_horizon),
        frequency: planningData.frequencyTopup,
        topup_amt: denotionParser(
          planningData.moneyRequiredDenotion,
          planningData.moneyRequired
        ),
        topup_frequency: planningData.sipFrequencyTopup,
        sip_return: planningData.returnssip / 100,
        lumpsum_ret: planningData.returns / 100,
      })
      .then((response) => setBackendData(response.data))
      .catch(() =>
        setBackendData({
          date: [],
          invested_amount_sip: [],
          invested_amount_lumpsum: [],
          expected_investment_val: [],
          expected_investment_val_lumpsum: [],
          total_invested_amount: [],
          total_expected_investment_value: [],
        })
      );
  };
  const data = {
    backgroundColor: "#fafafa",
    labels: backendData.date,
    datasets: [
      {
        label: "Total Amount Invested",
        data: backendData.total_invested_amount,
        fill: false,
        spanGaps: true,
        borderColor: "#1982F8",
        tension: 0.1,
        opacity: "0.3",
        borderWidth: 3,
      },
      {
        label: "Expected Investment Value",
        data: backendData.total_expected_investment_value,
        // data: backendData.graph.value2.values,
        borderColor: "#34D399",
        borderWidth: 3,
        tension: 0.1,
        opacity: "0.3",
        fill: true,
        spanGaps: true,
      },
    ],
  };
  const options = {
    animation: {
      duration: 1000,
    },

    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            autoSkip: true,
            maxTicksLimit: 7,
            maxRotation: 0,
            fontSize: 10,
            callback: (val) => dateParser(new Date(val)),
          },
          gridLines: {
            color: "white",
          },
        },
      ],
      yAxes: [
        {
          stepSize: 100,
          type: "linear",
          display: true,
          gridLines: {
            color: "white",
          },
          ticks: {
            callback: (val) => val.toLocaleString("en-IN"),
          },
        },
      ],
    },
    elements: {
      bar: {
        borderWidth: 3,
      },
      point: {
        radius: 0,
      },
    },

    legend: {
      display: false,
    },
    plugins: {
      title: {
        display: true,
      },
      labels: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      points: {
        display: false,
      },

      interaction: {
        mode: "index",
        intersect: false,
      },
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem, data) =>
          dateParser(new Date(data.labels[tooltipItem[0].index])),
      },
    },
  };
  return (
    <div className="flex justify-between mt-[1rem] border-none shadow-none">
      <div className="w-[49%] ">
        <div className="w-full flex justify-between items-center mt-[28px]">
          <div className="flex items-center w-[70%]">
            <p className="font-inter text-sm">Expected Return for Lumpsum</p>
          </div>
          <OutlinedInput
            size="small"
            className="text-sm"
            required
            style={{
              fontFamily: "Inter,sans-sarif",
              fontWeight: 400,
              width: "17.4%",
            }}
            value={planningData.returns}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === "" || re.test(e.target.value))
                setPlanningData({
                  ...planningData,
                  returns: e.target.value,
                });
            }}
            name="target"
            endAdornment={
              <InputAdornment position="end">
                <span className="text-sm font-inter">%</span>
              </InputAdornment>
            }
          />
        </div>
        <div className="w-full flex justify-between items-center mt-[28px]">
          <div className="flex items-center w-[70%]">
            <p className="font-inter text-sm">Expected Return for SIP</p>
          </div>
          <OutlinedInput
            size="small"
            className="text-sm"
            required
            style={{
              fontFamily: "Inter,sans-sarif",
              fontWeight: 400,
              width: "17.4%",
            }}
            value={planningData.returnssip}
            onChange={(e) => {
              const re = /^\d*\.?\d*$/;
              if (e.target.value === "" || re.test(e.target.value))
                setPlanningData({
                  ...planningData,
                  returnssip: e.target.value,
                });
            }}
            name="target"
            endAdornment={
              <InputAdornment position="end">
                <span className="text-sm font-inter">%</span>
              </InputAdornment>
            }
          />
        </div>
        <div className="w-full flex justify-between items-center mt-[1.5rem]">
          <div className="flex items-center w-[70%]">
            <p className="font-inter text-sm">Frequency of SIP</p>
          </div>
          <div className="w-[17.4%]">
            <DropdownForAttributes
              selected={planningData}
              setSelected={setPlanningData}
              attribute="frequencyTopup"
              options={[
                "Every Weekday (Mon-Fri)",
                "Daily",
                "Weekly",
                "Monthly",
                "Quarterly",
                "Annually",
              ]}
              labelName={null}
            />
          </div>
        </div>
        <div className="w-full flex justify-between items-center mt-[28px]">
          <div className="flex items-center w-[70%]">
            <p className="font-inter text-sm">Top-Up</p>
          </div>

          <div className="w-[30%] flex justify-between">
            <div className="w-[40%]">
              <DropdownForAttributes
                labelName={null}
                options={["₹ L", "₹ K", "₹ C"]}
                selected={planningData}
                setSelected={setPlanningData}
                attribute="moneyRequiredDenotion"
              />
            </div>
            <div className="w-[58%]">
              <InvestedgeTextField
                variant="outlined"
                fullWidth
                style={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label={null}
                value={planningData.moneyRequired}
                onChange={(e) => {
                  const re = /^\d*[0-9]+\d*$/;
                  if (e.target.value === "" || re.test(e.target.value))
                    setPlanningData({
                      ...planningData,
                      moneyRequired: e.target.value,
                    });
                }}
              />
            </div>
          </div>
        </div>

        <div className="w-full flex justify-between items-center mt-[1.5rem]">
          <div className="flex items-center w-[70%]">
            <p className="font-inter text-sm">Frequency of SIP Top-Up</p>
          </div>
          <div className="w-[17.4%]">
            <DropdownForAttributes
              selected={planningData}
              setSelected={setPlanningData}
              attribute="sipFrequencyTopup"
              options={[
                "Every Weekday (Mon-Fri)",
                "Daily",
                "Weekly",
                "Monthly",
                "Quarterly",
                "Annually",
              ]}
              labelName={null}
            />
          </div>
        </div>

        <InvestedgeButton
          className="mt-6"
          onClick={handleCalculate}
          disabled={
            !planningData.moneyRequiredDenotion.length ||
            !planningData.moneyRequired.length ||
            !planningData.time.length ||
            !planningData.currentInvestmentsDenotion.length ||
            !planningData.currentInvestments.length ||
            !planningData.returns.length ||
            !planningData.returnssip.length
          }
        >
          Calculate
        </InvestedgeButton>
      </div>
      <div className="w-[45%] pl-[1rem]">
        <div className="grid grid-cols-2 gap-6">
          <div>
            <p className="font-inter text-[#6a6a76] mb-[4px]">
              Total Investment (SIP+Lumpsum)
            </p>
            <p className="font-inter font-semibold text-[20px] text-primary-200 mb-[4px]">
              {`₹ ${
                backendData.total_invested_amount.length
                  ? parseInt(
                      backendData.total_invested_amount[
                        backendData.total_invested_amount.length - 1
                      ]
                    ).toLocaleString("en-IN")
                  : 0
              }`}
            </p>
          </div>
          <div>
            <p className="font-inter text-[#6a6a76] mb-[4px]">
              Simulated Value after 10 years
            </p>
            <p className="font-inter font-semibold text-[20px] text-success-300 mb-[4px]">
              {`₹ ${
                backendData.total_expected_investment_value.length
                  ? parseInt(
                      backendData.total_expected_investment_value[
                        backendData.total_expected_investment_value.length - 1
                      ]
                    ).toLocaleString("en-IN")
                  : 0
              }`}
            </p>
          </div>
        </div>
        <div className="w-[95%] bg-[#fafafa] p-[16px] mt-[16px]">
          <Line data={data} options={options} height={192} />
          <div className="flex justify-center w-full mt-[12px]">
            <div className="flex items-center ml-[16px]">
              <div className="bg-primary-200 rounded w-[14px] h-[14px]" />
              <h2 className="text-xs ml-[8px] mb-0">Total Amount Invested</h2>
            </div>
            <div className="flex items-center ml-[16px]">
              <div className="bg-success-200 rounded w-[14px] h-[14px]" />
              <h2 className="text-xs ml-[8px] mb-0">
                Expected Investment Value
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
