import React, { useState } from "react";
import parse from "html-react-parser";
import { useDispatch } from "react-redux";
import { PostEventLoggingData } from "../../../actions/actions";
import SectorModal from "./SectorModal";
export default function Sector({ sector, index }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleFeedback = (feedback) => {
    switch (feedback) {
      case "-1":
        return (
          <div className="bg-error-200 inline-block font-medium text-[#fff] p-[2px] rounded-md w-[65px] text-center text-xs font-inter">
            Negative
          </div>
        );

      case "0":
        return (
          <div className="bg-[#777] inline-block font-medium text-[#fff] p-[2px] rounded-md w-[65px] text-center text-xs font-inter">
            Neutral
          </div>
        );
      case "1":
        return (
          <div className="bg-success-300 inline-block font-medium text-[#fff] p-[2px] rounded-md w-[65px] text-center text-xs font-inter">
            Positive
          </div>
        );
      default:
        return (
          <div className=" inline-block font-medium text-[#fff] p-[2px] rounded-[100px] w-[65px] text-center text-[13px] font-inter">
            Positive
          </div>
        );
    }
  };
  return (
    <>
      <SectorModal
        sector={sector}
        open={open}
        onClose={() => setOpen(false)}
        handleFeedback={handleFeedback}
      />
      <div key={sector.sector_id}>
        <div
          style={
            index === 0
              ? {
                  padding: "1rem",
                  borderRadius: "6px",
                }
              : {
                  padding: "1rem",
                  marginTop: "1rem",
                  borderRadius: "6px",
                }
          }
          className="border border-globalBorderColor"
        >
          <h5 className="font-medium font-inter text-[16px] leading-[20px] tracking-[0em] text-neutralBlack mb-[0.5rem]">
            {sector.sector_name}
          </h5>
          {handleFeedback(sector.feedback)}
          <p className="market-insights-sector-brief-text">
            {parse(sector.brief_text)}
          </p>
          <p
            className="text-[13px] inline-block cursor-pointer text-primary-200 market-insights-card-answer-link-more"
            onClick={() => {
              setOpen(true);
              dispatch(
                PostEventLoggingData({
                  module_name: "Market Insights",
                  event_type: "Information",
                  url: window.location.href,
                  note: `${sector.sector_name} viewed`,
                })
              );
            }}
          >
            Read more
          </p>
        </div>
      </div>
    </>
  );
}
