export const PortfolioData = [
  {
    product_name: "Portfolio Fund 1",
    product_type: "MF",
    asset_type: "Equity",
    holding: "12%",
    oneM: -0.91,
    oneY: 38.6,
    threeY: 58,
    fiveY: 37,
    amount: 12.2,
    weight: 2.2,
  },
  {
    product_name: "Portfolio Fund 2",
    product_type: "MF",
    asset_type: "Debt",
    holding: "12%",
    oneM: -0.91,
    oneY: 38.6,
    threeY: 58,
    fiveY: 37,
    amount: 12.2,
    weight: 2.2,
  },
  {
    product_name: "Portfolio Fund 3",
    product_type: "MF",
    asset_type: "Equity",
    holding: "12%",
    oneM: -0.91,
    oneY: 38.6,
    threeY: 58,
    fiveY: 37,
    amount: 12.2,
    weight: 2.2,
  },
  {
    product_name: "Portfolio Fund 4",
    product_type: "MF",
    asset_type: "Debt",
    holding: "12%",
    oneM: -0.91,
    oneY: 38.6,
    threeY: -58,
    fiveY: -37,
    amount: 12.2,
    weight: 8.2,
  },
  {
    product_name: "Portfolio Fund 5",
    product_type: "MF",
    asset_type: "Equity",
    holding: "12%",
    oneM: -0.91,
    oneY: 38.6,
    threeY: 58,
    fiveY: 37,
    amount: 12.2,
    weight: 12,
  },
  {
    product_name: "Portfolio Fund 6",
    product_type: "MF",
    asset_type: "Equity",
    holding: "12%",
    oneM: -0.91,
    oneY: 38.6,
    threeY: 58,
    fiveY: 37,
    amount: 12.2,
    weight: 12.2,
  },
  {
    product_name: "Portfolio Fund 7",
    product_type: "MF",
    asset_type: "Equity",
    holding: "12%",
    oneM: -0.91,
    oneY: 38.6,
    threeY: 58,
    fiveY: 37,
    amount: 12.2,
    weight: 12.2,
  },
  {
    product_name: "Portfolio Fund 8",
    product_type: "MF",
    asset_type: "Equity",
    holding: "12%",
    oneM: -0.91,
    oneY: 38.6,
    threeY: 58,
    fiveY: 37,
    amount: 12.2,
    weight: 12.2,
  },
  {
    product_name: "Portfolio Fund 9",
    product_type: "MF",
    asset_type: "Equity",
    holding: "12%",
    oneM: -0.91,
    oneY: 38.6,
    threeY: 58,
    fiveY: 37,
    amount: 12.2,
    weight: 12.2,
  },
  {
    product_name: "Portfolio Fund 10",
    product_type: "MF",
    asset_type: "Equity",
    holding: "12%",
    oneM: -0.91,
    oneY: 38.6,
    threeY: 58,
    fiveY: 37,
    amount: 12.2,
    weight: 12.2,
  },
  {
    product_name: "Portfolio Fund 11",
    product_type: "MF",
    asset_type: "Equity",
    holding: "12%",
    oneM: -0.91,
    oneY: 38.6,
    threeY: 58,
    fiveY: 37,
    amount: 12.2,
    weight: 12.2,
  },
];
