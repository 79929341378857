import { POST_DASHBOARD_HOLDING_REPORT } from "../actions/type";
const initialState = { excel: null };
export default function GetDashboardHoldingReport(
  state = initialState,
  action
) {
  switch (action.type) {
    case POST_DASHBOARD_HOLDING_REPORT:
      return action.payload;
    default:
      return state;
  }
}
