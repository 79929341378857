import React, { useState } from "react";

import Navigator from "./Navigator";
import Leader from "./Leader";
import Designer from "./Designer";
import StoryTeller from "./StoryTeller";
// import VisibilitySensor from "react-visibility-sensor";

export default function Features({ setBackground }) {
  const [bg, setBg] = useState([
    "radial-gradient(29.3% 35.71% at 30% 100%, rgba(42, 168, 234, 0.64) 0%, rgba(6, 7, 27, 0.64)",
    "radial-gradient(29.3% 35.71% at 30% 100%, rgba(42, 168, 234, 0.64) 0%, rgba(6, 7, 27, 0.64)",
    "radial-gradient(29.3% 35.71% at 30% 100%, rgba(42, 168, 234, 0.64) 0%, rgba(6, 7, 27, 0.64)",
    "radial-gradient(29.3% 35.71% at 30% 100%, rgba(42, 168, 234, 0.64) 0%, rgba(6, 7, 27, 0.64)",
  ]);
  // const handleClick = (value) => {
  //   var elmnt = document.getElementById("investedge-features-description");
  //   elmnt.scrollTo({
  //     top: value,
  //     behavior: "smooth",
  //   });
  // };
  return (
    <div className="bg-primarybg-300 pt-4 md:pt-28">
      <h2 className="text-center font-montserrat font-semibold text-[20px] leading-[30px] md:text-[32px] md:leading-[48px] text-[#FCFCFD]">
        Features
      </h2>
      {/* <VisibilitySensor
        onChange={(isVisible) =>
          isVisible && setBackground("rgba(12, 4, 24, 0.85)")
        }
      >
        <div className="mt-10 px-[20px] md:px-20 grid grid-cols-2 md:grid-cols-4">
          <div
            className={
              bg[0] || bg[1]
                ? "px-4 flex justify-center pb-4 cursor-pointer"
                : "px-4 hidden md:flex justify-center pb-4 cursor-pointer"
            }
            style={{
              boxShadow: "inset 0px -1px 0px #23262F",
              background: bg[0],
              transitionProperty: "width,background",
              transitionDuration: "0.2s,0.2s",
              transitionTimingFunction: "linear",
            }}
            onClick={() => {
              // setBg([
              //   "radial-gradient(29.3% 35.71% at 50% 100%, rgba(42, 168, 234, 0.64) 0%, rgba(6, 7, 27, 0.64) 100%)",
              //   "",
              //   "",
              //   "",
              // ]);
              handleClick(0);
            }}
          >
            <img src={bg[0].length ? ActiveNavigation : Navigation} alt="err" />
            <p
              className="font-montserrat mb-0 ml-2"
              style={
                bg[0].length
                  ? { color: "#FCFCFD", fontWeight: 600 }
                  : { color: "7777E91" }
              }
            >
              Navigator
            </p>
          </div>
          <div
            className={
              bg[0] || bg[1]
                ? "px-4 flex justify-center pb-4 cursor-pointer"
                : "px-4 hidden md:flex justify-center pb-4 cursor-pointer"
            }
            style={{
              boxShadow: "inset 0px -1px 0px #23262F",
              background: bg[1],
              transitionProperty: "width,background",
              transitionDuration: "0.2s,0.2s",
              transitionTimingFunction: "linear",
            }}
            onClick={() => {
              // setBg([
              //   "",
              //   "radial-gradient(29.3% 35.71% at 50% 100%, rgba(42, 168, 234, 0.64) 0%, rgba(6, 7, 27, 0.64) 100%)",
              //   "",
              //   "",
              // ]);
              handleClick(750);
            }}
          >
            <img src={bg[1].length ? ActiveLeader : Medal} alt="err" />
            <p
              className="font-montserrat mb-0 ml-2"
              style={
                bg[1].length
                  ? { color: "#FCFCFD", fontWeight: 600 }
                  : { color: "7777E91" }
              }
            >
              Leader
            </p>
          </div>
          <div
            className={
              bg[2] || bg[3]
                ? "px-4 flex justify-center pb-4 cursor-pointer"
                : "px-4 hidden md:flex justify-center pb-4 cursor-pointer"
            }
            style={{
              boxShadow: "inset 0px -1px 0px #23262F",
              background: bg[2],
              transitionProperty: "width,background",
              transitionDuration: "0.2s,0.2s",
              transitionTimingFunction: "linear",
            }}
            onClick={() => {
              // setBg([
              //   "",
              //   "",
              //   "radial-gradient(29.3% 35.71% at 50% 100%, rgba(42, 168, 234, 0.64) 0%, rgba(6, 7, 27, 0.64) 100%)",
              //   "",
              // ]);
              handleClick(1400);
            }}
          >
            <img src={bg[2].length ? ActiveDesigner : Cup} alt="err" />
            <p
              className="font-montserrat mb-0 ml-2"
              style={
                bg[2].length
                  ? { color: "#FCFCFD", fontWeight: 600 }
                  : { color: "7777E91" }
              }
            >
              Designer
            </p>
          </div>
          <div
            className={
              bg[2] || bg[3]
                ? "px-4 flex justify-center pb-4 cursor-pointer"
                : "px-4 hidden md:flex justify-center pb-4 cursor-pointer"
            }
            style={{
              boxShadow: "inset 0px -1px 0px #23262F",
              background: bg[3],
              transitionProperty: "width,background",
              transitionDuration: "0.2s,1s",
              transitionTimingFunction: "linear",
            }}
            onClick={() => {
              // setBg([
              //   "",
              //   "",
              //   "",
              //   "radial-gradient(29.3% 35.71% at 50% 100%, rgba(42, 168, 234, 0.64) 0%, rgba(6, 7, 27, 0.64) 100%)",
              // ]);
              handleClick(2050);
            }}
          >
            <img src={bg[3].length ? ActiveStoryteller : Heart} alt="err" />
            <p
              className="font-montserrat mb-0 ml-2"
              style={
                bg[3].length
                  ? { color: "#FCFCFD", fontWeight: 600 }
                  : { color: "7777E91" }
              }
            >
              Storyteller
            </p>
          </div>
        </div>
      </VisibilitySensor> */}
      <div
        className="pb-16 overflow-scroll"
        id="investedge-features-description"
      >
        <Navigator setBg={setBg} setBackground={setBackground} bg={bg} />
        <Leader setBg={setBg} bg={bg} />
        <Designer setBg={setBg} bg={bg} />
        <StoryTeller setBg={setBg} bg={bg} />
      </div>
    </div>
  );
}
