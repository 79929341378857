import React, { useEffect, useState } from "react";
import AfterloginApp from "../AfterloginApp";
import Cloud from "../../assets/img/previous_history_cloud.svg";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import RightArrow from "../../assets/img/right_arrow.svg";
import { OpenSnackbar, PostEventLoggingData } from "../../actions/actions";
import PreviousHistoryModal from "./PreviousHistoryModal";
// import InvestedgeSearchField from "../../components/searchfield/InvestedgeSearchField";
import InvestedgePagination from "../../components/pagination/InvestedgePagination";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import Lock from "../../assets/img/lock_locked_blue.svg";
import axios from "axios";
import urls from "../../urls";
import AlgorithmModel from "./AlgorithmModel";
import { BsArrowUpRightSquare } from "react-icons/bs";
export default function PortfolioProposal() {
  const dispatch = useDispatch();
  const [proposalModal, setProposalModal] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const proposalData = [
    {
      title: "Create Investment Proposal",
      description:
        "Create Investment Proposal as per client’s risk profile, product and research view. Generate beautiful presentations with Insights and Analytics.",
      link: "/portfolioproposal/create",
      button: "Create",
      instructionModal: true,
      setFunction: setProposalModal,
    },
    // {
    //   title: "Simulate Portfolio",
    //   description:
    //     "Add/delete Invetment Products to see how Portfolio Analytics changes. Check and add what suits best for the client.",
    //   link: "/portfolioproposal/simulate",
    //   button: "Simulate",
    //   locked: true,
    // },
    {
      title: "Review Client’s Portfolio",
      description:
        "Review client’s portfolio, generate insights and execute them throught InvestLane (Investment Product Marketplace)",
      link: "/portfolioproposal/review",
      button: "Review",
      counterData: true,
      instructionModal: true,
      setFunction: setReviewModal,
    },
    // {
    //   title: "Create PitchBook/Brochure",
    //   description:
    //     "Showcase your firm’s best capabilities throught wonderful and seamless presentation. Record view on Markets, Products, and Economy",
    //   link: "/portfolioproposal/createpitchbook",
    //   button: "Create",
    //   locked: true,
    // },
    {
      title: "Create SIP Proposal",
      description:
        "Create SIP Proposal as per client’s risk profile, product and research view. Generate beautiful presentations with Insights and Analytics.",
      link: "/portfolioproposal/sipproposal",
      button: "Create",
    },
    // {
    //   title: "Create Lumpsum & SIP Proposal",
    //   description:
    //     "Design a Lumpsum & SIP investments proposal as per client's risk profile and product view. Generate captivating presentations with insights and analytics.",
    //   link: "/portfolioproposal/lumpsumandsip",
    //   button: "Create",
    // },
    {
      title: "MF portfolio analytics",
      description:
        "Generate a report particularly on client's Mutual Fund portfolio with IRR returns. This report is suitable for incidental advice.",
      link: "/portfolioproposal/mfanalytics",
      button: "Create",
    },
  ];
  const [paginatedData, setPaginatedData] = useState(proposalData);
  const [counterData, setCounterData] = useState({
    counter_value: 10,
    flag: true,
    reset_value: 10,
    is_paid: true,
  });
  // const [search, setSearch] = useState("");
  const search = "";
  const [pageNumber, setPageNumber] = useState(0);
  const tilesPerPage = 5;
  const pagesVisited = pageNumber * tilesPerPage;
  const pageCount = Math.ceil(paginatedData.length / tilesPerPage);
  const changePage = (event, value) => {
    setPageNumber(value - 1);
  };
  useEffect(() => {
    setPaginatedData(
      proposalData.filter((tool) =>
        tool.title.toLowerCase().includes(search.toLowerCase())
      )
    );
    setPageNumber(0); // eslint-disable-next-line
  }, [search]);
  useEffect(() => {
    axios
      .get(urls.reviewCounterURL)
      .then((res) => {
        setCounterData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const [historyModal, setHistoryModal] = useState(false);
  const portfolioPoints = [
    "Products are selected from the list of BUY products in the Product Views Master.",
    "It is mandatory to add list of BUY products before using the Proposal functionality.",
    "From the BUY list, products are selected such that on an overall basis they meet client requirements provided in Step 2 and Step 3.",
    "The portfolio is further optimised to maximise the Sharpe Ratio for Equity funds and YTM for Debt funds.",
    "The portfolio constructed by the Algorithm needs to be reviewed by the User and necessary changes should be made as required.",
    "You can delete a product (click on x), change its allocation (by changing % in input box provided) or add a new product as well (by clicking Add funds and choosing required product).",
    "Portfolio analytics can be seen on the right side of the Page.",
    "Whenever you make any change in allocation, delete or add a product – you should click on “Simulate” on right side to see the updated portfolio analytics post making the changes.",
    "Click on Finalise Proposal when you want to proceed with preparation of client presentation.",
  ];
  const reviewPoints = [
    "Products which are part of SELL list Product Views Master are marked for Sell at the time of portfolio review. It is mandatory to add list of BUY/SELL products before using the Review functionality.",
    "Products which have more than 10% concentration are marked for sell to bring their allocation to 10%.",
    "Realignment of portfolio is done to meet the client requirements provided in Step 2 and Step 3.",
    "The portfolio is further optimised to maximise the Sharpe Ratio for Equity funds and YTM for Debt funds.",
    "The portfolio constructed by the Algorithm needs to be reviewed by the User and necessary changes should be made as required.",
    "You can look at Buy and Sell list to quickly check the changes arrived by the Algorithm to realign the client portfolio as per the requirements given.",
    "Whenever you manual edit the Proposed amount, you need to click on “Calculate Difference” and the system will tell you how much is pending to be Bought/Sold to make the portfolio value same as initial value.",
    "You can add comments/remarks wherever there is an action being taken. Please do review the remarks as the same gets reflected in client presentation as well.",
    "You can delete a product (making the allocation 0), change its allocation (by changing amounts in input box provided) or add a new product as well (by clicking Add funds and choosing required product).",
    "Portfolio analytics can be seen on the right side of the Page.",
    "Whenever you make any change in allocation, delete or add a product – you should click on “Simulate” on right side to see the updated portfolio analytics post making the changes.",
    "Click on Next when you want to proceed with preparation of client presentation.",
  ];
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Portfolio Insights"}
        description={"Create and Customize Portfolio Recommendations"}
      />
      <PreviousHistoryModal
        historyModal={historyModal}
        onClose={() => setHistoryModal(false)}
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className="main-panel">
            <div className="portfolio-proposal-body-card min-h-[calc(100vh_-_116px)] shadow-none border-none relative">
              <div className="flex justify-between items-end">
                <div>
                  <h2 className="card-heading">Select Option</h2>
                  <p className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0rem] text-left">
                    Select an option to create proposal, review, pitchbook, or
                    simulate portfolio.
                  </p>
                </div>
                <div className="flex items-end space-x-2">
                  <div
                    className="flex items-end cursor-pointer mb-2"
                    onClick={() => setHistoryModal(true)}
                  >
                    <img src={Cloud} alt="err" className="w-[22px]" />

                    <p className="font-inter font-semibold text-sm text-primary-200 mb-0 ml-2">
                      Previous Downloads
                    </p>
                  </div>
                  {/* <InvestedgeSearchField
                    type="text"
                    placeholder="Search"
                    value={search}
                    className="ml-4"
                    onChange={(e) => setSearch(e.target.value)}
                  /> */}
                </div>
              </div>

              <div className="min-h-[400px] w-full flex flex-wrap justify-between mt-4">
                {paginatedData
                  .slice(pagesVisited, pagesVisited + tilesPerPage)
                  .map((item, index) =>
                    item.counterData ? (
                      <div
                        key={index}
                        className="relative w-[48%] rounded-[8px] h-[150px] p-[1rem] duration-[500ms] border border-globalBorderColor mt-4"
                        onClick={() =>
                          item.locked &&
                          dispatch(
                            OpenSnackbar({
                              severity: "info",
                              message: "Feature will be coming soon",
                            })
                          )
                        }
                      >
                        {item.locked && (
                          <img
                            src={Lock}
                            alt="err"
                            className="absolute top-4 right-4"
                          />
                        )}
                        {item.instructionModal ? (
                          <div className="flex items-center">
                            <h3 className="card-heading">{item.title}</h3>
                            <BsArrowUpRightSquare
                              size={14}
                              color="#1982F8"
                              style={{
                                marginLeft: "0.5rem",
                                cursor: "pointer",
                              }}
                              onClick={() => item.setFunction(true)}
                            />
                          </div>
                        ) : (
                          <h3 className="card-heading">{item.title}</h3>
                        )}
                        <p className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0.01em] text-left">
                          {item.description}
                        </p>
                        {counterData.is_paid ? (
                          <Link to={item.link}>
                            <div className="w-[92%] flex justify-between items-center absolute left-4 bottom-3 right-8">
                              <InvestedgeButton
                                // className="absolute left-4 bottom-3"
                                onClick={() => {
                                  dispatch(
                                    PostEventLoggingData({
                                      module_name: "Portfolio Insights",
                                      event_type: "Start button",
                                      url: window.location.href,
                                      note: `${item.title} Started`,
                                    })
                                  );
                                }}
                                disabled={!counterData.flag}
                              >
                                {item.button}
                              </InvestedgeButton>
                            </div>
                          </Link>
                        ) : (
                          <div className="w-[92%] flex justify-between items-center absolute left-4 bottom-3 right-8">
                            {counterData.flag ? (
                              <Link to={item.link}>
                                <InvestedgeButton
                                  // className="absolute left-4 bottom-3"
                                  onClick={() => {
                                    dispatch(
                                      PostEventLoggingData({
                                        module_name: "Portfolio Insights",
                                        event_type: "Start button",
                                        url: window.location.href,
                                        note: `${item.title} Started`,
                                      })
                                    );
                                  }}
                                  disabled={!counterData.flag}
                                >
                                  {item.button}
                                </InvestedgeButton>
                              </Link>
                            ) : (
                              <InvestedgeButton
                                // className="absolute left-4 bottom-3"
                                onClick={() => {
                                  dispatch(
                                    PostEventLoggingData({
                                      module_name: "Portfolio Insights",
                                      event_type: "Start button",
                                      url: window.location.href,
                                      note: `${item.title} Started`,
                                    })
                                  );
                                }}
                                disabled={!counterData.flag}
                              >
                                {item.button}
                              </InvestedgeButton>
                            )}

                            {counterData.flag ? (
                              <p
                                className={
                                  counterData.reset_value -
                                    counterData.counter_value !==
                                  1
                                    ? "text-success-300 text-xs"
                                    : "text-error-200 text-xs"
                                }
                              >{`${
                                counterData.reset_value -
                                counterData.counter_value
                              } out of ${
                                counterData.reset_value
                              } Reviews left`}</p>
                            ) : (
                              <div
                                className="text-primary-200 bg-primary-accent text-xs flex justify-between items-center p-[0.4rem] rounded-sm cursor-pointer"
                                onClick={() =>
                                  window.open("mailto:contact@beyondirr.tech")
                                }
                              >
                                <p>
                                  Please Upgrade to use Review in full potential
                                </p>
                                <img
                                  src={RightArrow}
                                  alt="err"
                                  className="ml-[0.5rem]"
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        key={index}
                        className="relative w-[48%] rounded-[8px] h-[150px] p-[1rem] duration-[500ms] border border-globalBorderColor mt-4"
                        onClick={() =>
                          item.locked &&
                          dispatch(
                            OpenSnackbar({
                              severity: "info",
                              message: "Feature will be coming soon",
                            })
                          )
                        }
                      >
                        {item.locked && (
                          <img
                            src={Lock}
                            alt="err"
                            className="absolute top-4 right-4"
                          />
                        )}
                        {item.instructionModal ? (
                          <div className="flex items-center">
                            <h3 className="card-heading">{item.title}</h3>
                            <BsArrowUpRightSquare
                              size={14}
                              color="#1982F8"
                              style={{
                                marginLeft: "0.5rem",
                                cursor: "pointer",
                              }}
                              onClick={() => item.setFunction(true)}
                            />
                          </div>
                        ) : (
                          <h3 className="card-heading">{item.title}</h3>
                        )}
                        <p className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0.01em] text-left">
                          {item.description}
                        </p>
                        <Link to={item.link}>
                          <InvestedgeButton
                            className="absolute left-4 bottom-3"
                            onClick={() => {
                              dispatch(
                                PostEventLoggingData({
                                  module_name: "Portfolio Insights",
                                  event_type: "Start button",
                                  url: window.location.href,
                                  note: `${item.title} Started`,
                                })
                              );
                            }}
                            disabled={item.locked}
                          >
                            {item.button}
                          </InvestedgeButton>
                        </Link>
                      </div>
                    )
                  )}
              </div>
              <div className="flex justify-end absolute bottom-4 right-4">
                <InvestedgePagination
                  count={pageCount}
                  defaultPage={1}
                  page={pageNumber + 1}
                  onChange={changePage}
                  color="primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlgorithmModel
        state={proposalModal}
        onClose={() => setProposalModal(false)}
        heading="Portfolio Construction Algorithm Logic"
        points={portfolioPoints}
      />
      <AlgorithmModel
        state={reviewModal}
        onClose={() => setReviewModal(false)}
        heading="Portfolio Review Algorithm Logic"
        points={reviewPoints}
      />
    </div>
  );
}
