export const Asset = ["Equity", "Debt", "Multi Asset", "Alternate"];
export const Product = [
  "Mutual Fund",
  "PMS",
  "Stock",
  "AlF",
  "Bond",
  "Unlisted",
  "Insurance",
];
export const Recommendation = ["Buy", "Hold", "Sell"];
