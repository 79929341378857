import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAnalyticsAPI,
  GetSIPAnalyticsAPI,
  PostEventLoggingData,
} from "../../../../actions/actions";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
import Lumpsum from "./lumpsum/Lumpsum";
import SIP from "./sip/SIP";
import InvestedgeTabs from "../../../../components/tabs/InvestedgeTabs";
import Summary from "./summary/Summary";
export default function PortfolioFourthStep({
  handleBack,
  handleNext,
  portfolioProducts,
  setPortfolioProducts,
  formValue,
  proposalType,
  sipPortfolioProducts,
  setSipPortfolioProducts,
  sipProposalType,
  purposePortfolio,
  handleModelPortfolioBack,
}) {
  const dispatch = useDispatch();
  const preferenceData = useSelector((state) => state.allocationPreference);
  const modelPortfolio = useSelector(
    (state) => state.getModelPortfolioProducts
  );
  const sippreferenceData = useSelector(
    (state) => state.sipallocationPreference
  );
  const sipmodelPortfolio = useSelector((state) => state.sipModelPortfolio);
  const rationalizeProducts = (productsArray) => {
    let temp = productsArray.map((product) => {
      return {
        ...product,
        individual_asset_pct: (product.individual_asset_pct * 100).toFixed(1),
      };
    });

    return temp;
  };
  const derationalizeProducts = (productsArray) => {
    let temp = productsArray.map((product) => {
      return {
        ...product,
        individual_asset_pct: product.individual_asset_pct / 100,
      };
    });

    return temp;
  };
  const [parsedData, setParsedData] = useState(
    rationalizeProducts(
      portfolioProducts.length === 0 && proposalType === 0
        ? preferenceData["Mutual Fund"]
        : portfolioProducts.length === 0 && proposalType === 1
        ? modelPortfolio
        : portfolioProducts
    )
  );
  const [sipparsedData, setsipParsedData] = useState(
    rationalizeProducts(
      sipPortfolioProducts.length === 0 && sipProposalType === 0
        ? sippreferenceData["Mutual Fund"]
        : sipPortfolioProducts.length === 0 && sipProposalType === 1
        ? sipmodelPortfolio
        : sipPortfolioProducts
    )
  );
  const tabs = [
    { label: "Lumpsum" },
    { label: "SIP" },
    { label: "Summary & Projections" },
  ];
  const [tabType, setTabType] = useState(0);
  const handlePptChange = (event, newValue) => {
    setTabType(newValue);
  };

  useEffect(() => {
    if (
      (sipProposalType === 0 &&
        sippreferenceData["Mutual Fund"].length > 0 &&
        sipPortfolioProducts.length === 0) ||
      (sipProposalType === 1 &&
        sipmodelPortfolio.length > 0 &&
        sipPortfolioProducts.length === 0)
    ) {
      dispatch(
        GetSIPAnalyticsAPI(
          sipProposalType === 0
            ? sippreferenceData
            : { "Mutual Fund": sipmodelPortfolio }
        )
      );
      let tempArray = rationalizeProducts(
        sipProposalType === 0
          ? sippreferenceData["Mutual Fund"]
          : sipmodelPortfolio
      );
      let tempSum = 0;
      tempArray.forEach(
        (value) => (tempSum += parseFloat(value.individual_asset_pct))
      );
      tempSum = tempSum.toFixed(1);
      tempArray[0].individual_asset_pct =
        parseFloat(tempArray[0].individual_asset_pct) +
        (100.0 - parseFloat(tempSum));
      tempArray[0].individual_asset_pct =
        tempArray[0].individual_asset_pct.toFixed(1);
      setsipParsedData(tempArray);
    } // eslint-disable-next-line
  }, [sippreferenceData, sipmodelPortfolio]);

  useEffect(() => {
    if (
      (proposalType === 0 &&
        preferenceData["Mutual Fund"].length > 0 &&
        portfolioProducts.length === 0) ||
      (proposalType === 1 &&
        modelPortfolio.length > 0 &&
        portfolioProducts.length === 0)
    ) {
      dispatch(
        GetAnalyticsAPI(
          proposalType === 0
            ? preferenceData
            : { "Mutual Fund": modelPortfolio }
        )
      );
      let tempArray = rationalizeProducts(
        proposalType === 0 ? preferenceData["Mutual Fund"] : modelPortfolio
      );
      let tempSum = 0;
      tempArray.forEach(
        (value) => (tempSum += parseFloat(value.individual_asset_pct))
      );
      tempSum = tempSum.toFixed(1);
      tempArray[0].individual_asset_pct =
        parseFloat(tempArray[0].individual_asset_pct) +
        (100.0 - parseFloat(tempSum));
      tempArray[0].individual_asset_pct =
        tempArray[0].individual_asset_pct.toFixed(1);
      setParsedData(tempArray);
    } // eslint-disable-next-line
  }, [preferenceData, modelPortfolio]);
  return (
    <>
      <div className="px-8 rounded-[8px] w-full bg-white pt-8 pb-4 mt-[16px]">
        <div className="flex justify-between w-full">
          <h2 className="font-inter text-[24px] font-medium leading-[20px] text-center tracking-[0em]">
            Portfolio &amp; Analysis
          </h2>
          <div className="flex">
            <InvestedgeButton
              onClick={() => {
                dispatch(
                  PostEventLoggingData({
                    module_name: "Portfolio Insights",
                    event_type: "Prev button",
                    url: window.location.href,
                    note: `Fourth Screen`,
                  })
                );
                proposalType && sipProposalType
                  ? handleModelPortfolioBack()
                  : handleBack();
              }}
            >
              Prev
            </InvestedgeButton>
            <InvestedgeButton
              className="ml-4"
              onClick={() => {
                let temp = derationalizeProducts(parsedData);
                dispatch(GetAnalyticsAPI({ "Mutual Fund": temp }));
                setPortfolioProducts(temp);
                let siptemp = derationalizeProducts(sipparsedData);
                dispatch(GetSIPAnalyticsAPI({ "Mutual Fund": siptemp }));
                setSipPortfolioProducts(siptemp);
                handleNext();
              }}
              // disabled={Math.round(portfolioSum * 10) / 10 !== 100}
            >
              Finalize Proposal
            </InvestedgeButton>
          </div>
        </div>
        <p className="w-full leading-[20px] font-inter text-xs text-neutralBlack mb-0 mt-[4px]">
          Recommendation based on algorithms best suited for the clients. You
          can change the portfolio to see the live analytics on the right side.
        </p>
      </div>
      <div className="rounded-[8px] w-full bg-white px-8 py-4 mt-[16px]">
        <InvestedgeTabs
          tabs={tabs}
          tabvalue={tabType}
          handletabchange={handlePptChange}
        />
      </div>
      {tabType === 0 ? (
        <Lumpsum
          formValue={formValue}
          parsedData={parsedData}
          setParsedData={setParsedData}
          portfolioProducts={portfolioProducts}
          proposalType={proposalType}
        />
      ) : tabType === 1 ? (
        <SIP
          formValue={formValue}
          parsedData={sipparsedData}
          setParsedData={setsipParsedData}
          portfolioProducts={sipPortfolioProducts}
          proposalType={sipProposalType}
        />
      ) : (
        <Summary
          parsedData={parsedData}
          sipparsedData={sipparsedData}
          purposePortfolio={purposePortfolio}
          formValue={formValue}
        />
      )}
    </>
  );
}
