import React from "react";
import InvestedgeSlider from "../../../../components/slider/InvestedgeSlider";
import { useDispatch } from "react-redux";
import { GetIpsData } from "../../../../actions/actions";
import InvestedgeTextField from "../../../../components/textfield/InvestedgeTextField";
import RiskAssessmentModal from "./RiskAssessmentModal";
export default function CustomProposal({
  riskText,
  riskscore,
  setRiskscore,
  rsmodal,
  horizon,
  marks,
  setrsmodal,
  faqs,
  setFaqs,
  error,
  setError,
  handleChange,
}) {
  const dispatch = useDispatch();
  return (
    <>
      <table className="w-full mt-12">
        <colgroup>
          <col width="50%" />
          <col width="50%" />
        </colgroup>

        <tbody>
          <tr>
            <td className="pl-[0rem]">
              <label className=" font-inter text-[14px] font-normal leading-[20px] tracking-[0em] mr-[1rem]">
                Risk Score
              </label>
              <div className="bg-primary-accent inline-block font-medium text-primary-200 py-[0.3rem] px-[0.5rem] rounded-[8px] mb-[0.5rem] font-inter text-[12px]">
                {riskText}
              </div>
            </td>
            <td className="pl-[3rem]">
              <label className=" font-inter text-[14px] font-normal leading-[20px] tracking-[0em] mr-[1rem]">
                Don’t Know Your Client’s Risk Score?
              </label>
            </td>
          </tr>
          <tr className="mt-[2rem]">
            <td className="pl-[0rem]">
              <InvestedgeSlider
                className="w-[90%]"
                valueLabelDisplay="on"
                value={riskscore}
                onChange={(e, val) => setRiskscore(val)}
                onChangeCommitted={(e, val) =>
                  horizon &&
                  dispatch(
                    GetIpsData({
                      params: {
                        risk_score: val,
                        investment_horizon: horizon,
                      },
                    })
                  )
                }
                marks={marks}
                step={1}
              />
            </td>
            <td className=" align-baseline pl-[3rem]">
              <div
                className=" font-inter text-[14px] text-primary-200 font-medium block pt-[1.5rem] mb-0 cursor-pointer"
                onClick={() => setrsmodal(true)}
              >
                Click to view and change
              </div>
              <RiskAssessmentModal
                onClose={() => setrsmodal(false)}
                familyMembers={faqs}
                setFamilyMembers2={setFaqs}
                riskScore={riskscore}
                setRiskscore={setRiskscore}
                rsmodal={rsmodal}
                riskText={riskText}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <InvestedgeTextField
        required
        sx={{
          fontFamily: "Inter,sans-sarif",
          fontWeight: 400,
          width: "45%",
          marginTop: "1rem",
        }}
        label="Investment Horizon (Years)"
        error={error ? true : false}
        helperText={error ? error : false}
        type="text"
        value={horizon}
        onChange={(e) => {
          if (e.target.value.length) setError(null);
          else setError("This field is required");
          handleChange(e);
        }}
      />
      <br />
      {/* <label
className="portfolio-stepper-first-form-label"
style={{ marginTop: "2rem", fontSize: "14px" }}
>
Type of schemes to include in portfolio
</label>
<br />
<div
className="individual-details-radio-buttons-div"
style={{ width: "45%" }}
>
<FormControlLabel
  control={
    <BlueRadio
      size="small"
      checked={scheme === "Regular"}
      onChange={(e) => setScheme("Regular")}
      value="Regular"
      name="gender"
      inputProps={{ "aria-label": "Regular" }}
    />
  }
  label={
    <span
      style={{ fontWeight: "400", fontFamily: "Inter,sans-serif" }}
    >
      Regular
    </span>
  }
/>
<FormControlLabel
  control={
    <BlueRadio
      size="small"
      checked={scheme === "Direct"}
      onChange={(e) => setScheme("Direct")}
      value="Direct"
      name="gender"
      inputProps={{ "aria-label": "Direct" }}
    />
  }
  label={
    <span
      style={{ fontWeight: "400", fontFamily: "Inter,sans-serif" }}
    >
      Direct
    </span>
  }
/>

<FormControlLabel
  control={
    <BlueRadio
      size="small"
      checked={scheme === "Both"}
      onChange={(e) => setScheme("Both")}
      value="Both"
      name="gender"
      inputProps={{ "aria-label": "Both" }}
    />
  }
  label={
    <span
      style={{ fontWeight: "400", fontFamily: "Inter,sans-serif" }}
    >
      Both
    </span>
  }
/>
</div> */}
    </>
  );
}
