import React from "react";
import Add from "../../../../assets/img/circle_over_plus_blue.svg";
import { productArray } from "../../../../arrays/productArray";
import OthersRow from "./OthersRow";
import { useState } from "react";

export default function GoldScreen() {
  const [othersarray, setothersarray] = useState(productArray.others.items);
  const removeothersarray = (i) => {
    let newothersarray = [...othersarray];
    newothersarray.splice(i, 1);
    setothersarray(newothersarray);
  };
  return (
    <div className="w-full">
      <div className="font-medium font-inter text-[18px] text-neutralBlack">
        Others - ₹ 62.5 L
      </div>
      <div className="flex justify-between w-[96%] mt-[4px] mb-0">
        <div className="flex justify-center items-center h-[56px]">
          <img src={Add} alt="err" />
          <p
            className="text-primary-200 text-[14px] font-medium mb-0 ml-[0.3rem]"
            onClick={(e) => {
              let newothersarray = [...othersarray];
              newothersarray.unshift({ name: "New Fund", price: "0" });
              setothersarray(newothersarray);
            }}
          >
            Add fund
          </p>
        </div>
        <p
          className="text-primary-200 text-[14px] font-medium mb-0"
          onClick={() => setothersarray([])}
        >
          Clear All
        </p>
      </div>
      <div className="update-portfolio-asset-row-container">
        {othersarray.map((fund, index) => (
          <OthersRow
            key={index}
            fund={fund}
            id={index}
            removeothersarray={removeothersarray}
            othersarray
            setothersarray
          />
        ))}
      </div>
    </div>
  );
}
