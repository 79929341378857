import { GET_DASHBOARD_AIF_PORTFOLIO } from "../actions/type";
const initialState = [];
export default function getDashboardAifPortfolio(state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_AIF_PORTFOLIO:
      return action.payload;
    default:
      return state;
  }
}
