import React, { useEffect, useState } from "react";
import Dropdown from "../../components/Dropdowns/DropdownWhiteSingle";
import { useSelector, useDispatch } from "react-redux";
import { PostEventLoggingData } from "../../actions/actions";
import InvestedgeSearchField from "../../components/searchfield/InvestedgeSearchField";
import InvestedgePagination from "../../components/pagination/InvestedgePagination";
import DisableSorted from "../../assets/img/disabled_sort.svg";
import AscendingSorted from "../../assets/img/ascending_sort.svg";
import DescendingSorted from "../../assets/img/descending_sort.svg";
export default function Performance() {
  const dispatch = useDispatch();
  const [asset, setAsset] = useState("All");
  const [product, setProduct] = useState("All");
  const [searchProduct, setSearchProduct] = useState("");
  const [sorted, setSorted] = useState({
    name: 0,
    date: 0,
    amount: 0,
    irr: 0,
  });

  const sortingIcon = (state) =>
    state === 0
      ? DisableSorted
      : state === 1
      ? AscendingSorted
      : DescendingSorted;

  const productData = useSelector((state) =>
    state.dashboardPerformance ? state.dashboardPerformance : []
  );
  const [data, setData] = useState(productData);
  const assetSet = new Set(productData.map((product) => product.asset_type));
  const productSet = new Set(
    productData.map((product) => product.product_type)
  );
  const [pageNumber, setPageNumber] = useState(0);
  const clientsPerPage = 5;
  const pagesVisited = pageNumber * clientsPerPage;
  const pageCount = Math.ceil(data.length / clientsPerPage);
  const changePage = (e, val) => {
    setPageNumber(val - 1);
  };

  const sorting_switch = (attribute, sortingType, products) => {
    switch (sortingType) {
      case 0:
        return products;
      case 1:
        products.sort((a, b) => {
          const attributeA = isNaN(a[attribute])
            ? a[attribute].toUpperCase()
            : parseFloat(a[attribute]); // ignore upper and lowercase
          const attributeB = isNaN(b[attribute])
            ? b[attribute].toUpperCase()
            : parseFloat(b[attribute]); // ignore upper and lowercase

          if (attributeA < attributeB) {
            return -1;
          }
          if (attributeA > attributeB) {
            return 1;
          }
          return 0;
          // names must be equal
        });
        return products;
      case 2:
        products.sort((a, b) => {
          const attributeA = isNaN(a[attribute])
            ? a[attribute].toUpperCase()
            : parseFloat(a[attribute]); // ignore upper and lowercase
          const attributeB = isNaN(b[attribute])
            ? b[attribute].toUpperCase()
            : parseFloat(b[attribute]); // ignore upper and lowercase

          if (attributeA > attributeB) {
            return -1;
          }
          if (attributeA < attributeB) {
            return 1;
          }
          return 0;
        });
        return products;
      default:
        return products;
    }
  };
  const sortedArray = (products) => {
    if (sorted.name)
      return sorting_switch("product_name", sorted.name, products);
    else if (sorted.irr)
      return sorting_switch("schemewise_irr", sorted.irr, products);
    else if (sorted.amount)
      return sorting_switch("amount", sorted.amount, products);
    else return products;
  };

  const displaydata = sortedArray(data)
    .slice(pagesVisited, pagesVisited + clientsPerPage)
    .map((client, index) => {
      return (
        <tr key={index} className="border-b border-[#e0dddd] h-[68px]">
          <td
            style={{
              textAlign: "left",
              paddingLeft: "1rem",
            }}
            className=" font-inter text-[14px] not-italic font-normal leading-[20px] tracking-[0em] p-[1rem] text-left"
          >
            {client.product_name}
          </td>
          <td className=" font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
            {client.product_type}
          </td>
          <td className=" font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
            {client.asset_type}
          </td>
          <td className=" font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
            {parseInt(client.amount).toLocaleString("en-IN")}
          </td>
          <td className=" font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
            {(client.holding * 100).toFixed(1)}
          </td>
          <td
            className={
              client.schemewise_irr > 0
                ? "text-success-300 font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center"
                : "text-error-200 font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center"
            }
          >
            {client.schemewise_irr
              ? `${(client.schemewise_irr * 100).toFixed(1)}%`
              : "-"}
          </td>
          <td className=" font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
            {client.nav === "NA" ? "NA" : client.nav.toFixed(1)}
          </td>
          <td className=" font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
            {client.date}
          </td>
        </tr>
      );
    });
  useEffect(() => {
    setData(productData);
  }, [productData]);

  useEffect(() => {
    if (asset === "All" && product === "All")
      setData(
        productData.filter((data) =>
          data.product_name.toLowerCase().includes(searchProduct.toLowerCase())
        )
      );
    else if (product === "All")
      setData(
        productData.filter(
          (data) =>
            data.asset_type === asset &&
            data.product_name
              .toLowerCase()
              .includes(searchProduct.toLowerCase())
        )
      );
    else if (asset === "All")
      setData(
        productData.filter(
          (data) =>
            data.product_type === product &&
            data.product_name
              .toLowerCase()
              .includes(searchProduct.toLowerCase())
        )
      );
    else
      setData(
        productData.filter(
          (data) =>
            data.asset_type === asset &&
            data.product_type === product &&
            data.product_name
              .toLowerCase()
              .includes(searchProduct.toLowerCase())
        )
      );
    dispatch(
      PostEventLoggingData({
        module_name: "Client Dashboard",
        event_type: "Performance Filtered",
        url: window.location.href,
        note: `Asset - ${asset} , Product - ${product} , Searched - ${searchProduct}`,
      })
    );
    setPageNumber(0);
    // eslint-disable-next-line
  }, [asset, product, searchProduct]);

  return (
    <div className="mt-[2rem]" id="client-dashoard-performance">
      <h3 className="card-heading">Performance</h3>
      <div>
        <div className="flex justify-between w-full mt-[1rem]">
          <div className="flex w-[30%] justify-between">
            <div className="w-[49%]">
              <Dropdown
                options={["All", ...assetSet]}
                selected={asset}
                setSelected={setAsset}
                labelName="Asset Type"
              />
            </div>
            <div className="w-[49%]">
              <Dropdown
                options={["All", ...productSet]}
                selected={product}
                setSelected={setProduct}
                labelName="Product Type"
              />
            </div>
          </div>
          <InvestedgeSearchField
            type="text"
            placeholder="Search"
            value={searchProduct}
            onChange={(e) => setSearchProduct(e.target.value)}
          />
        </div>
        <div className="rounded-[6px] mt-[1rem] min-h-[400px]">
          {productData.length ? (
            <table className="investedge-table w-full">
              <colgroup>
                <col width="28%" />
                <col width="12%" />
                <col width="12%" />
                <col width="12%" />
                <col width="8%" />
                <col width="8%" />
                <col width="8%" />
                <col width="12%" />
              </colgroup>
              <tbody>
                <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                  <th
                    style={{
                      textAlign: "left",
                    }}
                    className="pl-[1rem] text-left font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em]"
                  >
                    <div className="flex items-center justify-start">
                      Product Name
                      <img
                        src={sortingIcon(sorted.name)}
                        onClick={() => {
                          setSorted({
                            name: (sorted.name + 1) % 3,
                            irr: 0,
                            amount: 0,
                            date: 0,
                          });
                        }}
                        alt="err"
                        className="w-6 ml-1 cursor-pointer"
                      />
                    </div>
                  </th>
                  <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-center">
                    Product Type
                  </th>
                  <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-center">
                    Asset Type
                  </th>
                  <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-center">
                    <div className="flex items-center justify-start">
                      Amount(₹)
                      <img
                        src={sortingIcon(sorted.amount)}
                        onClick={() => {
                          setSorted({
                            name: 0,
                            amount: (sorted.amount + 1) % 3,
                            irr: 0,
                            date: 0,
                          });
                        }}
                        alt="err"
                        className="w-6 ml-1 cursor-pointer"
                      />
                    </div>
                  </th>
                  <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-center">
                    Holding (%)
                  </th>
                  <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-center">
                    <div className="flex items-center justify-center">
                      IRR
                      <img
                        src={sortingIcon(sorted.irr)}
                        onClick={() => {
                          setSorted({
                            name: 0,
                            amount: 0,
                            irr: (sorted.irr + 1) % 3,
                            date: 0,
                          });
                        }}
                        alt="err"
                        className="w-6 ml-1 cursor-pointer"
                      />
                    </div>
                  </th>
                  <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-center">
                    NAV(₹)
                  </th>
                  <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-center">
                    Date
                  </th>
                </tr>
                {displaydata}
              </tbody>
            </table>
          ) : (
            <div className="client-dashboard-table w-full flex justify-center items-center min-h-[335px] opacity-[0.9]">
              No data found. Please try again later
            </div>
          )}
        </div>
        <div className="flex justify-end mt-4">
          <InvestedgePagination
            count={pageCount}
            defaultPage={1}
            page={pageNumber + 1}
            onChange={changePage}
            color="primary"
          />
        </div>
      </div>
    </div>
  );
}
