import { INVALID_EMAIL } from "../actions/type";
const initialState = null;
export default function InvalidEmailReducer(state = initialState, action) {
  switch (action.type) {
    case INVALID_EMAIL:
      return action.payload;

    default:
      return state;
  }
}
