import React, { useState } from "react";
import Minimalist from "../../../../assets/img/minimalist_template.png";
import Classic from "../../../../assets/img/classic_template.png";
import Tick from "../../../../assets/img/module_access_type_tick.svg";
import { FormControlLabel, Switch } from "@mui/material";
import { useDispatch } from "react-redux";
import { GetPPTAPI, PostEventLoggingData } from "../../../../actions/actions";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
export default function PortfolioFifthStep({
  handleBack,
  handleNext,
  formValue,
  portfolioProducts,
}) {
  const dispatch = useDispatch();
  const [template, setTemplate] = useState(1);
  const [proposal, setProposal] = useState({
    summary: true,
    strategy: true,
    proposedPortfolio: true,
    equityPortfolio: true,
    debtPortfolio: true,
  });
  const [excelformat, setExcelFormat] = useState(false);

  let handleChangeSwitch = (e) => {
    let newProposal = { ...proposal };
    newProposal[e.target.name] = !newProposal[e.target.name];
    setProposal(newProposal);
  };
  return (
    <>
      <div className="px-8 rounded-[8px] w-full bg-white py-8 mt-[16px]">
        <div className="flex justify-between w-full">
          <h2 className="card-heading">Select Template</h2>
          <div className="flex">
            <InvestedgeButton
              className="mr-4"
              onClick={() => {
                dispatch(
                  PostEventLoggingData({
                    module_name: "Portfolio Insights",
                    event_type: "Prev button",
                    url: window.location.href,
                    note: `Fifth Screen`,
                  })
                );
                handleBack();
              }}
            >
              Prev
            </InvestedgeButton>
            <InvestedgeButton
              onClick={() => {
                dispatch(
                  GetPPTAPI({
                    client: {
                      client_name: formValue.client_name,
                      proposal_value: {
                        amount: formValue.proposal_value.amount,
                        denomination: formValue.proposal_value.denomination[2],
                      },
                      tax_status: formValue.tax_status,
                      tax_slab: formValue.tax_slab,
                      risk_score: formValue.risk_score,
                      investment_horizon: formValue.investment_horizon,
                    },
                    allocation_preference: formValue.allocation_preference,
                    template: template,
                    ppt: {
                      executive_summary: proposal.summary,
                      investment_strategy: proposal.strategy,
                      proposed_portfolio: proposal.proposedPortfolio,
                      equity_portfolio_analytics: proposal.equityPortfolio,
                      debt_portfolio_analytics: proposal.debtPortfolio,
                      alternates_portfolio_analytics: false,
                      one_page_on_each_product: false,
                    },
                    output: {
                      ppt: true,
                      excel: excelformat,
                      pdf: false,
                      all: false,
                    },
                    portfolio: { "Mutual Funds": portfolioProducts },
                  })
                );
                dispatch(
                  PostEventLoggingData({
                    module_name: "Portfolio Insights",
                    event_type: "Download button",
                    url: window.location.href,
                    note: `Fifth Screen`,
                  })
                );
                handleNext();
              }}
            >
              Download
            </InvestedgeButton>
          </div>
        </div>
      </div>
      <div className="px-8 rounded-[8px] w-full bg-white py-8 mt-[16px]">
        <div className="flex w-full">
          <div
            className="portfolio-stepper-first-form-container"
            style={{
              width: "80%",
              borderRadius: "10px",
            }}
          >
            <div className="w-[90%] pt-[1rem] flex justify-between">
              <div className="w-[45%]">
                {template === 1 ? (
                  <div className="relative">
                    <img
                      src={Minimalist}
                      alt="profilepic"
                      className="w-[100%]"
                    />
                    <div className=" absolute bottom-0 w-full h-full flex justify-end items-start p-[1rem] bg-primary-100/[0.3]">
                      <img src={Tick} alt="err" />
                    </div>
                  </div>
                ) : (
                  <img
                    src={Minimalist}
                    alt="err"
                    className="w-[100%]"
                    onClick={() => setTemplate(1)}
                  />
                )}

                <div className="w-[100%] justify-center flex">
                  <p className=" font-inter text-[16px] font-normal leading-[20px] tracking-[0em]">
                    Minimalist
                  </p>
                </div>
                <h3 className="font-inter text-[16px] font-medium leading-[20px] tracking-[0em] text-left text-[#000] mt-[2rem]">
                  Choose Proposal Components
                </h3>

                <FormControlLabel
                  control={
                    <Switch
                      checked={proposal.summary}
                      name="summary"
                      onChange={(e) => handleChangeSwitch(e)}
                      inputProps={{ role: "switch" }}
                      color="primary"
                    />
                  }
                  label={
                    <span className=" font-inter text-[16px] font-normal leading-[20px] tracking-[0em] text-left text-neutralBlack">
                      Executive Summary
                    </span>
                  }
                  className="pt-[1.75rem]"
                />
                <br />
                <FormControlLabel
                  control={
                    <Switch
                      checked={proposal.strategy}
                      name="strategy"
                      onChange={(e) => handleChangeSwitch(e)}
                      inputProps={{ role: "switch" }}
                      color="primary"
                    />
                  }
                  label={"Investment Strategy"}
                  style={{ paddingTop: "1rem" }}
                />
                <br />
                <FormControlLabel
                  control={
                    <Switch
                      checked={proposal.proposedPortfolio}
                      name="proposedPortfolio"
                      onChange={(e) => handleChangeSwitch(e)}
                      inputProps={{ role: "switch" }}
                      color="primary"
                    />
                  }
                  label={"Proposed Portfolio"}
                  style={{ paddingTop: "1rem" }}
                />
                <br />
                <FormControlLabel
                  control={
                    <Switch
                      checked={proposal.equityPortfolio}
                      name="equityPortfolio"
                      onChange={(e) => handleChangeSwitch(e)}
                      inputProps={{ role: "switch" }}
                      color="primary"
                    />
                  }
                  label={"Equity Portfolio Analytics"}
                  style={{ paddingTop: "1rem" }}
                />
                <br />
                <FormControlLabel
                  control={
                    <Switch
                      checked={proposal.debtPortfolio}
                      name="debtPortfolio"
                      onChange={(e) => handleChangeSwitch(e)}
                      inputProps={{ role: "switch" }}
                      color="primary"
                    />
                  }
                  label={"Debt Portfolio Analytics"}
                  style={{
                    paddingTop: "1rem",
                    fontFamily: "Inter,sans-serif",
                    fontWeight: 400,
                  }}
                />
              </div>
              <div className="w-[45%]">
                {template === 2 ? (
                  <div className=" relative">
                    <img src={Classic} alt="profilepic" className="w-full" />
                    <div className=" absolute bottom-0 w-full h-full flex justify-end items-start p-[1rem] bg-primary-100/[0.3]">
                      <img src={Tick} alt="err" />
                    </div>
                  </div>
                ) : (
                  <img
                    src={Classic}
                    alt="err"
                    className="w-full"
                    onClick={() => setTemplate(2)}
                  />
                )}
                <div className="w-full flex justify-center">
                  <p className=" font-inter text-[16px] font-normal leading-[20px] tracking-[0em]">
                    Classic
                  </p>
                </div>
                <h3 className="font-inter text-[16px] font-medium leading-[20px] tracking-[0em] text-left text-[#000] mt-[2rem]">
                  Output Format
                </h3>
                <FormControlLabel
                  control={
                    <Switch
                      checked={true}
                      inputProps={{ role: "switch" }}
                      color="primary"
                    />
                  }
                  label={
                    <span className="font-inter text-[16px] font-normal leading-[20px] tracking-[0em] text-left text-neutralBlack">
                      PPT
                    </span>
                  }
                  className="pt-[1.75rem]"
                />
                <br />
                <FormControlLabel
                  control={
                    <Switch
                      checked={excelformat}
                      onChange={() => setExcelFormat(!excelformat)}
                      inputProps={{ role: "switch" }}
                      color="primary"
                    />
                  }
                  label={
                    <span className=" font-inter text-[16px] font-normal leading-[20px] tracking-[0em] text-left text-neutralBlack">
                      Excel
                    </span>
                  }
                  className="pt-6"
                />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
