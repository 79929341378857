import React from "react";
import AfterloginApp from "../AfterloginApp";
import ModelPortfolioContent from "./ModelPortfolioContent";

export default function ModelPortfolio() {
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <ModelPortfolioContent />
        </div>
      </div>
    </div>
  );
}
