import React, { useState, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import AfterloginApp from "../../AfterloginApp";
import "../../../static/css/existing/dashboard_styles.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  MarketFAQS,
  MarketSectors,
  PostEventLoggingData,
} from "../../../actions/actions";
import InvestedgeTextField from "../../../components/textfield/InvestedgeTextField";
import Dropdown from "../../../components/Dropdowns/DropdownWhiteSingle";
import InvestedgePagination from "../../../components/pagination/InvestedgePagination";
import MarketFaq from "./MarketFaq";
import Sector from "./Sector";
export default function Marketdashboard() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(MarketFAQS());
    dispatch(MarketSectors());
    // eslint-disable-next-line
  }, []);
  const [searchFaq, setSearchFaq] = useState("");
  const faqs = useSelector((state) => state.faqdata);

  const sectorsList = useSelector((state) => state.marketsectordata);
  const data = faqs.filter((faq) => faq.module === "Markets");
  const [marketFaqs, setMarketFaqs] = useState(
    faqs.filter((faq) => faq.module === "Markets")
  );
  const assetSet = new Set(data.map((faq) => faq.asset));
  const sectionSet = new Set(data.map((faq) => faq.section));
  const geographySet = new Set(data.map((faq) => faq.geography));
  const [section, setSection] = useState("All");
  const [asset, setAsset] = useState("All");
  const [geography, setGeography] = useState("All");
  useEffect(() => {
    setMarketFaqs(faqs.filter((faq) => faq.module === "Markets"));
  }, [faqs]);

  useEffect(() => {
    if (asset === "All" && section === "All" && geography === "All") {
      setMarketFaqs(
        data.filter(
          (data) =>
            data.question.toLowerCase().includes(searchFaq.toLowerCase()) ||
            data.answer.toLowerCase().includes(searchFaq.toLowerCase())
        )
      );

      setPageNumber(0);
    } else if (asset === "All" && section === "All") {
      setMarketFaqs(
        data.filter(
          (data) =>
            data.geography === geography &&
            (data.question.toLowerCase().includes(searchFaq.toLowerCase()) ||
              data.answer.toLowerCase().includes(searchFaq.toLowerCase()))
        )
      );
      setPageNumber(0);
    } else if (geography === "All" && section === "All") {
      setMarketFaqs(
        data.filter(
          (data) =>
            data.asset === asset &&
            (data.question.toLowerCase().includes(searchFaq.toLowerCase()) ||
              data.answer.toLowerCase().includes(searchFaq.toLowerCase()))
        )
      );
      setPageNumber(0);
    } else if (geography === "All" && asset === "All") {
      setMarketFaqs(
        data.filter(
          (data) =>
            data.section === section &&
            (data.question.toLowerCase().includes(searchFaq.toLowerCase()) ||
              data.answer.toLowerCase().includes(searchFaq.toLowerCase()))
        )
      );
      setPageNumber(0);
    } else if (geography === "All") {
      setMarketFaqs(
        data.filter(
          (data) =>
            data.section === section &&
            data.asset === asset &&
            (data.question.toLowerCase().includes(searchFaq.toLowerCase()) ||
              data.answer.toLowerCase().includes(searchFaq.toLowerCase()))
        )
      );
      setPageNumber(0);
    } else if (section === "All") {
      setMarketFaqs(
        data.filter(
          (data) =>
            data.geography === geography &&
            data.asset === asset &&
            (data.question.toLowerCase().includes(searchFaq.toLowerCase()) ||
              data.answer.toLowerCase().includes(searchFaq.toLowerCase()))
        )
      );
      setPageNumber(0);
    } else if (asset === "All") {
      setMarketFaqs(
        data.filter(
          (data) =>
            data.section === section &&
            data.geography === geography &&
            (data.question.toLowerCase().includes(searchFaq.toLowerCase()) ||
              data.answer.toLowerCase().includes(searchFaq.toLowerCase()))
        )
      );
      setPageNumber(0);
    } else {
      setMarketFaqs(
        data.filter(
          (data) =>
            data.section === section &&
            data.geography === geography &&
            data.asset === asset &&
            (data.question.toLowerCase().includes(searchFaq.toLowerCase()) ||
              data.answer.toLowerCase().includes(searchFaq.toLowerCase()))
        )
      );
      setPageNumber(0);
    }
    dispatch(
      PostEventLoggingData({
        module_name: "Market Insights",
        event_type: "Faqs Filtered",
        url: window.location.href,
        note: `Asset - ${asset}, Geography - ${geography} , section - ${section} , searchFaq - ${searchFaq}`,
      })
    ); // eslint-disable-next-line
  }, [asset, geography, section, searchFaq]);

  const [pageNumber, setPageNumber] = useState(0);
  const faqsPerPage = 2;
  var pagesVisited = pageNumber * faqsPerPage;
  const displaydata = marketFaqs
    .slice(pagesVisited, pagesVisited + faqsPerPage)
    .map((data, index) => (
      <MarketFaq data={data} index={index} key={data.id} />
    ));
  const pageCount = Math.ceil(marketFaqs.length / faqsPerPage);
  const changePage = (event, val) => {
    setPageNumber(val - 1);
  };

  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Market Insights"}
        description={
          "All economic analysis, expert views, market events, and conversation topics at one place"
        }
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className="h-[calc(100vh_-_116px)] flex justify-between">
            <div
              style={{
                borderRadius: "0.5rem",
              }}
              className="p-[1rem] h-full w-[65%] bg-white "
            >
              <h3 id="prdfaq" className="card-heading h-[6%]">
                Markets &amp; Economy
              </h3>
              <div className="flex justify-between h-[10%] pt-[0.5rem]">
                <div className="w-[15%]">
                  <Dropdown
                    options={["All", ...sectionSet]}
                    selected={section}
                    setSelected={setSection}
                    labelName="Section"
                  />
                </div>
                <div className="w-[15%]">
                  <Dropdown
                    options={["All", ...geographySet]}
                    selected={geography}
                    setSelected={setGeography}
                    labelName="Geography"
                  />
                </div>
                <div className="w-[15%]">
                  <Dropdown
                    options={["All", ...assetSet]}
                    selected={asset}
                    setSelected={setAsset}
                    labelName="Asset"
                  />
                </div>

                <div className="w-[51%]">
                  <InvestedgeTextField
                    fullWidth
                    type="text"
                    sx={{
                      fontFamily: "Inter,sans-sarif",
                      fontWeight: 400,
                    }}
                    label="Search Products"
                    value={searchFaq}
                    onChange={(e) => setSearchFaq(e.target.value)}
                  />
                </div>
              </div>

              <div className="h-[85%] flex flex-col justify-between">
                <div>{displaydata}</div>

                <div className="flex justify-end w-full mb-2">
                  <InvestedgePagination
                    count={pageCount}
                    defaultPage={1}
                    page={pageNumber + 1}
                    onChange={changePage}
                    color="primary"
                  />
                </div>
              </div>
            </div>

            <div className="h-[100%] w-[34%]">
              <div
                style={{
                  borderRadius: "0.5rem",
                }}
                className="bg-white rounded-[0.5rem] p-[0.2rem] h-[63%] pb-[1rem]"
              >
                <h3 className="card-heading pb-0 p-[0.8rem] h-[8%]">Sectors</h3>
                <div className="market-insights-sector-container h-[90%] mt-[2%] overflow-y-auto py-[0.2rem] px-[0.8rem]">
                  {sectorsList.map((sector, index) => (
                    <Sector
                      sector={sector}
                      index={index}
                      key={sector.sector_id}
                    />
                  ))}
                </div>
              </div>

              <div className="bg-[#fff] border-0 p-4 py-2 mt-[3%] h-[35%] rounded-[0.5rem]   ">
                <div className=" p-3 h-[48%] bg-[#f3f3f3]">
                  <Link to="/latestmarketevent">
                    <h3 className="font-medium text-[16px] text-neutralBlack leading-[1rem]">
                      Market Events
                    </h3>
                  </Link>
                  <p className="text-[14px] mb-0 leading-[1rem] text-neutralBlack mt-2">
                    All market events at one place with impact analysis
                  </p>
                </div>
                <div className=" mt-2 p-3 h-[48%] bg-[#f3f3f3]">
                  <Link to="/marketmodule">
                    <h3 className="font-medium text-[16px] text-neutralBlack leading-[1rem]">
                      Conversation Topics
                    </h3>
                  </Link>
                  <p className="text-[14px] mb-0 leading-[1rem] text-neutralBlack mt-2">
                    Browse for all the topics that interests your clients the
                    most.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
