import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import SearchIcon from "../../assets/img/global_search_icon.svg";
import MicrophoneIcon from "../../assets/img/bluemicrophone.svg";
import GreyMicrophoneIcon from "../../assets/img/greymicrophone.svg";
import {
  PostSavedFAQS,
  PostEventLoggingData,
  GetTrendingFAQS,
  GetSavedFAQS,
} from "../../actions/actions";
import urls from "../../urls";
import BookmarkUnsaved from "../../assets/img/bookmarkunsaved.svg";
import BookmarkSaved from "../../assets/img/bookmarksaved.svg";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../static/css/existing/dashboard_styles.css";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import AfterloginApp from "../AfterloginApp";
import axios from "axios";
import { Bars } from "react-loader-spinner";
import ClickAwayListener from "react-click-away-listener";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import InvestedgePopper from "../../components/popper/InvestedgePopper";
import { MenuItem } from "@mui/material";
export default function SearchViewQuestion() {
  const inputDivRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { listening, transcript } = useSpeechRecognition();
  useEffect(() => {
    setquestion(transcript);
  }, [transcript]);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [question, setquestion] = useState("");
  const [faq, setFaq] = useState({ tags: [] });
  const id = location.state.faq || "";
  const trendingFaqData = useSelector((state) => state.trendingFaqs);
  const savedFaqData = useSelector((state) => state.savedFaqs);

  const handleSearch = () => {
    history.push({
      pathname: "/searchresults",
      search: `?searchedquestion=${question}`,
    });
  };
  useEffect(() => {
    dispatch(GetTrendingFAQS());
    dispatch(GetSavedFAQS());
    axios
      .get(`${urls.marketFaqURL}${id}`)
      .then((response) => {
        setFaq(response.data);
      })
      .catch(() => {
        setFaq({ tags: [] });
      });
    // eslint-disable-next-line
  }, [id]);
  let savedFaqIds = {};
  for (let i = 0; i < savedFaqData.length; i++)
    savedFaqIds[savedFaqData[i].id] = true;
  const [faqData, setfaqData] = useState([]);
  useEffect(() => {
    question.length >= 3 && setfaqData(["loading"]);
    question.length >= 3 &&
      axios
        .get(urls.marketFaqURL, { params: { query: question } })
        .then((response) => {
          setfaqData(response.data);
        })
        .catch(() => {
          setfaqData([]);
        });
    if (question.length >= 3) setAnchorEl(inputDivRef.current);
    else setAnchorEl(null);
  }, [question]);
  const [bookmark, setBookmark] = useState(savedFaqIds[id]);

  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className="bg-[#fff] p-[2rem] rounded-[0.5rem] min-h-[calc(100vh_-_116px)] w-full">
            <form
              onSubmit={handleSearch}
              className="flex md:!w-[68.5%] w-full justify-center"
            >
              <div
                ref={inputDivRef}
                aria-describedby="search-screen-results-popper"
                className="w-full border border-globalBorderColor py-[0.25rem] px-[1rem] pr-[1rem] flex rounded-[6px] justify-between items-center relative rounded-r-none hover:border-neutralBlack"
              >
                <img src={SearchIcon} alt="err" />
                <input
                  placeholder="Search for view on Markets, Sectors, and Economy"
                  value={question}
                  className="border-none shadow-none w-[calc(100%_-_24px_-_40px)] ml-[0.5rem] text-[14px] placeholder:text-placeholder placeholder:font-normal md:placeholder:text-base placeholder:text-sm"
                  onChange={(e) => setquestion(e.target.value)}
                />
                <img
                  src={listening ? GreyMicrophoneIcon : MicrophoneIcon}
                  alt="err"
                  className="w-[20px] object-contain"
                  onClick={
                    listening
                      ? SpeechRecognition.stopListening
                      : SpeechRecognition.startListening
                  }
                />
              </div>
              <InvestedgeButton
                className="h-[45px] rounded-l-none w-[12%]"
                type="submit"
                onClick={() =>
                  dispatch(
                    PostEventLoggingData({
                      module_name: "Search",
                      event_type: "Search button",
                      url: window.location.href,
                      note: question,
                    })
                  )
                }
              >
                Search
              </InvestedgeButton>
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <InvestedgePopper
                  id="search-screen-results-popper"
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  className="h-[300px] md:w-[41.7%] w-[70%]"
                  placement="bottom-start"
                >
                  {faqData[0] === "loading" ? (
                    <div className="h-[300px] flex justify-center items-center">
                      <Bars color="#1982F8" height="60" width="60" />
                    </div>
                  ) : faqData.length ? (
                    faqData.slice(0, 7).map((option, ind) => (
                      <Link
                        key={ind}
                        to={{
                          pathname: "/searchviewquestion",
                          state: {
                            faq: option.id,
                            savedFaqIds: savedFaqIds,
                          },
                        }}
                        className="hover:text-primary-200 hover:bg-secondary-100"
                      >
                        <MenuItem
                          key={ind}
                          onClick={() => setAnchorEl(null)}
                          className="font-inter py-[1rem] text-neutralBlack "
                        >
                          {option.question}
                        </MenuItem>
                      </Link>
                    ))
                  ) : (
                    <div className="h-[300px] font-inter flex items-center justify-center font-medium text-xl text-neutralBlack">
                      No data found
                    </div>
                  )}
                </InvestedgePopper>
              </ClickAwayListener>
            </form>
            <div className="grid grid-cols-10 md:gap-16 gap-0">
              <div className="col-span-10 md:col-span-7 h-auto">
                <div className="w-full flex justify-between mt-8">
                  <h4 className="font-inter font-medium leading-[20px] tracking-[0.02em] text-[16px] md:text-[20px] text-neutralBlack">
                    {faq.question}
                  </h4>
                  <img
                    src={bookmark ? BookmarkSaved : BookmarkUnsaved}
                    alt="err"
                    onClick={() => {
                      var formdata = new FormData();
                      formdata.append("faq_id", faq.id);
                      dispatch(PostSavedFAQS(formdata));
                      !bookmark &&
                        dispatch(
                          PostEventLoggingData({
                            module_name: "Search",
                            event_type: "Bookmark",
                            url: window.location.href,
                            note: faq.question,
                          })
                        );
                      setBookmark(!bookmark);
                      savedFaqIds[faq.id] = !savedFaqIds[faq.id];
                    }}
                    className="cursor-pointer"
                  />
                </div>
                <p
                  className="w-full h-2/4 font-inter text-[14px] font-medium leading-[25px] tracking-[0.02em] text-neutralBlack mt-4 text-justify"
                  dangerouslySetInnerHTML={{ __html: faq.answer }}
                />
              </div>
              <div className="md:pt-8 col-span-10 md:col-span-3">
                <div className="w-full md:hidden block">
                  <h3 className="font-inter text-[15px] font-medium leading-[25px] tracking-[0.02em] ">
                    Tags
                  </h3>
                  <div className="flex pt-4 flex-wrap">
                    {faq.tags.map((tag, index) => {
                      return (
                        <p
                          key={index}
                          className="font-inter text-[12px] font-normal leading-4 tracking-[0em] text-center text-primary-200 bg-[#EDF5FE] rounded-[20px] py-[0.25rem] px-[1rem]"
                          style={index % 3 === 0 ? {} : { marginLeft: "1rem" }}
                        >
                          {tag}
                        </p>
                      );
                    })}
                  </div>
                </div>
                <div className="w-full mt-4 md:mt-0">
                  <h3 className="font-inter text-[15px] font-medium leading-[25px] tracking-[0.02em]] text-neutralBlack">
                    People also ask
                  </h3>
                  <div className="pt-4">
                    {trendingFaqData.slice(0, 5).map((item, ind) => {
                      return (
                        <Link
                          to={{
                            pathname: "/searchviewquestion",
                            state: {
                              faq: item.id,
                              savedFaqIds: savedFaqIds,
                            },
                          }}
                          key={ind}
                          onClick={() =>
                            dispatch(
                              PostEventLoggingData({
                                module_name: "Search",
                                event_type: "Information",
                                url: window.location.href,
                                note: item.question,
                              })
                            )
                          }
                        >
                          <p className="font-inter text-sm leading-5 tracking-[0.02em] text-primary-200 mb-[0.5rem]">
                            {item.question}
                          </p>
                        </Link>
                      );
                    })}
                  </div>
                </div>
                <div className="mt-4 w-full hidden md:block">
                  <h3 className="font-inter text-[15px] font-medium leading-[25px] tracking-[0.02em] ">
                    Tags
                  </h3>
                  <div className="flex pt-4 flex-wrap">
                    {faq.tags.map((tag, index) => {
                      return (
                        <p
                          key={index}
                          className="font-inter text-[12px] font-normal leading-4 tracking-[0em] text-center text-primary-200 bg-[#EDF5FE] rounded-[20px] py-[0.25rem] px-[1rem]"
                          style={index % 3 === 0 ? {} : { marginLeft: "1rem" }}
                        >
                          {tag}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
