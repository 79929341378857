import React from "react";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import InvestedgeTextField from "../../../components/textfield/InvestedgeTextField";

export default function AskMeAnything({
  askMeAnythingQuery,
  setAskMeAnythingQuery,
  handleAskAnything,
}) {
  return (
    <div>
      <h1 className="card-heading text-base mt-8">
        Ask Me Anything about Clients Portfolio
      </h1>
      <div className="w-[52%] mt-4">
        <InvestedgeTextField
          value={askMeAnythingQuery}
          onChange={(e) => setAskMeAnythingQuery(e.target.value)}
          label={`Enter Query Here ( for ${localStorage.getItem(
            "clientName"
          )})`}
          fullWidth
        />
      </div>
      <div className="w-[52%] mt-8 flex justify-end">
        <InvestedgeButton onClick={handleAskAnything}>Submit</InvestedgeButton>
      </div>
    </div>
  );
}
