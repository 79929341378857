import React, { useEffect, useRef, useState } from "react";
import Menu from "../../assets/img/three_dots_menu.svg";
import View from "../../assets/img/view_portfolio.svg";
import Edit from "../../assets/img/edit_portfolio.svg";
import Duplicate from "../../assets/img/duplicate_portfolio.svg";
import Delete from "../../assets/img/delete_portfolio.svg";
import DefaultPortfolioPic from "../../assets/img/modelportfoliothumbnailgreybg.svg";
import InvestedgePopper from "../../components/popper/InvestedgePopper";
import { MenuItem } from "@mui/material";
import DeletePortfolioModal from "./DeletePortfolioModal";
import BookmarkUnsaved from "../../assets/img/bookmarkunsaved.svg";
import BookmarkSaved from "../../assets/img/bookmarksaved.svg";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import urls from "../../urls";
import {
  GetModelPortfolio,
  GetModelPortfolioProducts,
  PostModelPortfolioBookmark,
} from "../../actions/actions";
export default function PortfolioCard({
  portfolio,
  bookmarkPortfolios,
  setBookmarkPortfolios,
  setPortfolioType,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [deletePortfolio, setDeletePortfolio] = useState(false);
  const [bookmarked, setBookmarked] = useState(
    bookmarkPortfolios[portfolio.id]
  );
  const popperRef = useRef(null);
  useEffect(() => {
    setBookmarked(bookmarkPortfolios[portfolio.id]);
    // eslint-disable-next-line
  }, [bookmarkPortfolios[portfolio.id]]);

  return (
    <div
      className="rounded-[4px] bg-gradient-180 from-[#fafafa] to-[#f6f6f6] p-[1rem] min-h-[155px] z-0  w-full shadow-none m-0 duration-300 relative"
      onMouseLeave={() => setAnchorEl(null)}
    >
      <div className="flex justify-end items-start">
        <div className="flex justify-center items-center w-[97%] flex-col">
          <img
            className="h-[60px] w-[60px] rounded-[100%]"
            src={DefaultPortfolioPic}
            alt="err"
          />
          <div className="flex justify-center items-center mt-[1rem]">
            <p className="font-inter w-full text-sm not-italic leading-[19px] mb-0 tracking-[0em] text-center text-neutralBlack">
              {portfolio.portfolio_name}
            </p>
            <img
              src={bookmarked ? BookmarkSaved : BookmarkUnsaved}
              alt="err"
              onClick={() => {
                const temp = bookmarkPortfolios;
                temp[portfolio.id] = !temp[portfolio.id];
                dispatch(
                  PostModelPortfolioBookmark({
                    bookmark: temp[portfolio.id],
                    portfolio_id: portfolio.id,
                  })
                );
                setBookmarkPortfolios(temp);
                setBookmarked((prevState) => !prevState);
              }}
              className="w-[10px] object-contain ml-3"
            />
          </div>

          <p className="font-inter text-[12px] not-italic  leading-[12px] tracking-[0.015em] text-center mb-0 text-primary-200 mt-[0.5rem] bg-[#EDF5FE] py-[0.25rem] px-[0.5rem] rounded-[2px]">
            {portfolio.purpose_of_portfolio}
          </p>
        </div>
        <img
          src={Menu}
          alt="err"
          onClick={() =>
            setAnchorEl((prevAnchor) => (prevAnchor ? null : popperRef.current))
          }
          className="absolute top-4 right-4"
          ref={popperRef}
          aria-describedby="existing-portfolio-popper"
        />

        <InvestedgePopper
          id="existing-portfolio-popper"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="bottom-end"
          className="z-[100]"
          offset={[0, 20]}
          arrow="true"
        >
          <MenuItem
            className="flex"
            onClick={() => {
              setAnchorEl(null);
              dispatch(
                GetModelPortfolioProducts(
                  portfolio.id,
                  history,
                  true,
                  portfolio
                )
              );
            }}
          >
            <img src={View} alt="err" className="mr-4" />
            View Portfolio
          </MenuItem>
          <MenuItem
            className="flex"
            onClick={() => {
              setAnchorEl(null);
              dispatch(
                GetModelPortfolioProducts(
                  portfolio.id,
                  history,
                  false,
                  portfolio
                )
              );
            }}
          >
            <img src={Edit} alt="err" className="mr-4" />
            Edit Portfolio
          </MenuItem>
          <MenuItem
            className="flex"
            onClick={() => {
              axios
                .post(urls.modelPortfolioDescriptionDuplicateURL, {
                  id: portfolio.id,
                })
                .then(() => {
                  dispatch(GetModelPortfolio());
                  setPortfolioType(0);
                })
                .catch((err) => console.log(err));
              setAnchorEl(null);
            }}
          >
            <img src={Duplicate} alt="err" className="mr-4" /> Duplicate
            Portfolio
          </MenuItem>
          <MenuItem
            className="flex"
            onClick={() => {
              setAnchorEl(null);
              setDeletePortfolio(true);
            }}
          >
            <img src={Delete} alt="err" className="mr-4" /> Delete Portfolio
          </MenuItem>
        </InvestedgePopper>
        <DeletePortfolioModal
          open={deletePortfolio}
          onClose={() => setDeletePortfolio(false)}
          setPortfolioType={setPortfolioType}
          portfolio={portfolio}
        />
      </div>
    </div>
  );
}
