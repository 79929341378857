import React, { useState } from "react";
import PortfolioInputSidebar from "./PortfolioInputSidebar";
import PortfolioInputRouter from "./PortfolioInputRouter";
import { IoIosClose } from "react-icons/io";
import InvestedgeModal from "../../../components/modal/InvestedgeModal";
export default function TagInvestmentIndex(props) {
  const [route, setRoute] = useState("mutualfundsscreen");
  return (
    <InvestedgeModal
      aria-labelledby="Portfolio Input Modal"
      aria-describedby="Portfolio Input Modal"
      sx={{
        width: "78vw",
        marginRight: "auto",
        marginLeft: "auto",
        minHeight: "87.5vh",
      }}
      open={props.open}
      handleclose={props.onClose}
    >
      <div className="flex justify-between ">
        <h2 className="text-[18px] font-inherit font-medium">
          Tag Investments to {props.name}
        </h2>
        <IoIosClose size={30} className="mr-4" onClick={props.onClose} />
      </div>
      <div className="flex justify-between">
        <PortfolioInputSidebar route={route} setRoute={setRoute} />
        <div
          style={{
            padding: "0.9rem",
            width: "78.55%",
          }}
          className="w-[79%] min-h-full"
        >
          <PortfolioInputRouter
            route={route}
            setRoute={setRoute}
            id={props.id}
            name={props.name}
          />
        </div>
      </div>
    </InvestedgeModal>
  );
}
