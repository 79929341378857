import React from "react";
import Header from "./Header";
import Modal from "./Modal";
import Footer from "./Footer";
import "../assets/css/style.css";
// import "../assets/vendor/icofont/icofont.min.css";

function Termsofuse() {
  return (
    <>
      <Header />
      <br></br>
      <br></br>
      <section>
        <div className="container">
          <h2>
            <strong>Terms of Use</strong>
          </h2>
          <p>
            By accessing Services of the Company (which shall include web-based
            usage named as Partner Web and Client Web, mobile application named
            as Partner App and Client App or any other services provided by
            Valiant Finsolutions Private Limited) (collectively referred as the
            "Software"), you ("the User") agree to the following terms:
          </p>
          <div id="termsofuse">
            <ol
              type="1"
              style={{ listStyle: "decimal", listStylePosition: "inside" }}
            >
              <li>
                <strong>
                  Valiant Finsolutions Private Limited (the “Company” or
                  “BeyondIRR”)
                </strong>{" "}
                allows access to and usage of the Software only to authorised
                Users
              </li>
              <li>
                In the event that the User loses or misplaces the Authorised
                Login or suspects or has knowledge of any unauthorised access or
                usage of the User’s account, the User shall immediately notify
                BeyondIRR
              </li>
              <li>
                BeyondIRR shall not be liable for any losses incurred by the
                User due to non-compliance with the provisions of these terms
              </li>
              <li>
                The Software may only be used:
                <ol
                  type="a"
                  style={{
                    listStyle: "lower-alpha",
                    listStylePosition: "inside",
                    paddingLeft: "20px",
                  }}
                >
                  <li>
                    Within India, usage of the Software outside India shall be
                    subject to BeyondIRR's prior permission;
                  </li>
                  <li>
                    By User and its Authorised Personnel to whom an Authorised
                    Login has been provided
                  </li>
                </ol>
              </li>
              <li>
                User agrees that it shall not:
                <ol
                  type="a"
                  style={{
                    listStyle: "lower-alpha",
                    listStylePosition: "inside",
                    paddingLeft: "20px",
                  }}
                >
                  <li>
                    Modify or attempt to modify, reverse assemble or reverse
                    compile or directly or indirectly allow or cause a third
                    party to reverse assemble or reverse compile the whole or
                    any part of the Software, display or merge all or any part
                    of the Software with other computer programs, products or
                    services;
                  </li>
                  <li>
                    Allow any third party to use or access Software directly or
                    indirectly, whether on a time sharing, remote job entry,
                    facility management or service bureau arrangement basis,
                    without the prior written consent of BeyondIRR; or
                  </li>
                  <li>
                    Use the Software for training any third parties, commercial
                    sharing, or leasing
                  </li>
                </ol>
              </li>
              <li>
                The User acknowledges that there is no transfer of title or
                ownership to the User of the Software or the System
                Documentation
              </li>
              <li>
                The User understands that BeyondIRR may at its option upgrade,
                modify and make additions to the Software from time to time
                during the subsistence of the Agreement, if any
              </li>
              <li>
                Confidentiality: BeyondIRR agrees to treat all User Data as
                confidential and agrees that it shall not and shall ensure that
                its employees do not use confidential information, nor disclose
                such confidential information to any person, except to the
                extent necessary for:
                <ol
                  type="a"
                  style={{
                    listStyle: "lower-alpha",
                    listStylePosition: "inside",
                    paddingLeft: "20px",
                  }}
                >
                  <li>Compliance with Applicable Law</li>
                  <li>
                    Any other purpose for which the User may hereafter authorize
                    BeyondIRR in writing
                  </li>
                </ol>
              </li>
              <li>
                All Intellectual Property Rights in or related to BeyondIRR, the
                Software and the System Documentation are and shall remain the
                exclusive property of BeyondIRR and/or its licensors, whether or
                not specifically recognised or perfected under local applicable
                law. Subject to the limited use rights specified in the
                Agreement entered or to be entered between BeyondIRR and User,
                no license, right or interest in relation to any Intellectual
                Property Rights is granted to the User any time
              </li>
              <li>
                Ownership:
                <ol
                  type="a"
                  style={{
                    listStyle: "lower-alpha",
                    listStylePosition: "inside",
                    paddingLeft: "20px",
                  }}
                >
                  <li>
                    The User acknowledges and agrees that as between BeyondIRR
                    on the one hand, and the User on the other, BeyondIRR owns
                    all right, title and interest in the Software and System
                    Documentation;
                    <ol
                      type="i"
                      style={{
                        listStyle: "lower-roman",
                        listStylePosition: "inside",
                        paddingLeft: "20px",
                      }}
                    >
                      <li>
                        Nothing in this terms of Use shall confer in the User
                        ownership in the Software; and
                      </li>
                      <li>
                        The User shall not now or in the future contest the
                        validity of the License Agreement; and
                      </li>
                      <li>
                        No rights to use the Software are granted by BeyondIRR
                        except for the limited purposes expressly set forth in
                        this Terms of Use.
                      </li>
                    </ol>
                  </li>
                  <li>
                    It is clarified that User Data, even when fed into the
                    systems through the Software, shall be property of the User
                    and the User shall have all right title and interest in
                    respect of the same
                  </li>
                </ol>
              </li>
              <li>
                Restrictions during trial period:
                <ol
                  type="a"
                  style={{
                    listStyle: "lower-alpha",
                    listStylePosition: "inside",
                    paddingLeft: "20px",
                  }}
                >
                  <li>Backup of data is not guaranteed</li>
                  <li>
                    Notice of upgrades shall not be given, although BeyondIRR
                    will endeavour not to interrupt usage during normal business
                    hours
                  </li>
                  <li>
                    Saving of data between upgrades may not always be possible
                    thus it is recommended not to use actual client information
                  </li>
                </ol>
              </li>
              <li>
                Data history: BeyondIRR may maintain portfolio and transaction
                details for five years for an active registered Users.
                Transaction History prior to this will be archived and will be
                available only on request.
              </li>
            </ol>
          </div>
        </div>
      </section>
      <Footer />
      <Modal />
    </>
  );
}

export default Termsofuse;
