import React from "react";
import Dropdown from "../../../../components/Dropdowns/DropdownWhiteSingle";
import InvestedgeTextField from "../../../../components/textfield/InvestedgeTextField";
export default function Proposal({
  proposalDenotion,
  setProposalDenotion,
  proposalValue,
  setProposalValue,
  errors,
  setErrors,
}) {
  return (
    <div className="flex justify-between mt-4">
      <div className="w-[14%]">
        <Dropdown
          options={["₹ K", "₹ L", "₹ C"]}
          selected={proposalDenotion}
          setSelected={setProposalDenotion}
          labelName={null}
        />
      </div>
      <InvestedgeTextField
        required
        sx={{
          fontFamily: "Inter,sans-sarif",
          fontWeight: 400,
          width: "83%",
        }}
        label="Lumpsum Proposal Value"
        value={proposalValue}
        error={errors.proposalValue ? true : false}
        helperText={errors.proposalValue ? errors.name : ""}
        onChange={(e) => {
          const re = /^\d+$/;
          if (e.target.value === "" || re.test(e.target.value)) {
            if (!e.target.value) {
              let newerrors = { ...errors };
              newerrors.proposalValue = "This field is required";
              setErrors(newerrors);
            } else {
              let newerrors = { ...errors };
              newerrors.proposalValue = null;
              setErrors(newerrors);
            }
            setProposalValue(e.target.value);
          }
        }}
        min="0"
      />
    </div>
  );
}
