import React from "react";
import { useState } from "react";
// import { ThemeProvider } from "@material-ui/styles";
// import { Switch, FormControlLabel } from "@mui/material";
// import { createTheme } from "@mui/material/styles";
import ArrowLeft from "../../../../assets/img/left_arrow_icon.svg";
import ArrowDown from "../../../../assets/img/down_arrow_icon.svg";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
export default function EstateRow({
  fund,
  productarray,
  setProductarray,
  patchProductArray,
  setPatchProductArray,
  deleteArray,
  setDeleteArray,
  data,
  postProductArray,
  setPostProductArray,
  addedProduct,
}) {
  // const BirrSwitch = createTheme({
  //   overrides: {
  //     MuiSwitch: {
  //       root: {
  //         width: 70,
  //         height: 24,
  //         padding: 0,
  //         marginRight: 10,
  //       },
  //       palette: {
  //         primary: "#0000f5",
  //         secondary: "#0000f5",
  //       },
  //       switchBase: {
  //         padding: 0,
  //         color: "#0000f5",
  //         "&$checked": {
  //           transform: "translateX(45px)",
  //           color: "#0000f5",
  //           "& + $track": {
  //             backgroundColor: "#0000f5",
  //             opacity: 0.4,
  //             border: "none",
  //           },
  //         },
  //         "&$focusVisible $thumb": {
  //           color: "#0000f5",
  //           border: "6px solid #fff",
  //         },
  //       },
  //       thumb: {
  //         width: 24,
  //         height: 24,
  //       },
  //       track: {
  //         borderRadius: 30 / 2,
  //         border: `1px solid #c4c4c4`,
  //         opacity: 0.2,
  //       },
  //       checked: {},
  //     },
  //   },
  // });
  const [open, setOpen] = useState(fund.id === addedProduct ? true : false);

  // const [checked, setChecked] = useState(false);

  // let handleChange = (i, e) => {
  //   let newMfarray = [...productarray];
  //   newMfarray[i][e.target.name] = e.target.value;
  //   setProductarray(newMfarray);
  // };
  // const icon = (
  //   <Box>
  //     <>
  //       <td style={{ width: "25%" }}>
  //         <TextField
  //           fullWidth
  //           sx={{
  //             fontFamily: "Inter,sans-sarif",
  //             fontWeight: 400,
  //           }}
  //           label="SIP Amount"
  //           type="text"
  //         />
  //       </td>
  //       <td style={{ width: "25%" }}>
  //         <TextField
  //           fullWidth
  //           sx={{
  //             fontFamily: "Inter,sans-sarif",
  //             fontWeight: 400,
  //           }}
  //           label="SIP frequency"
  //           type="text"
  //         />
  //       </td>
  //     </>
  //   </Box>
  // );
  const industryOptions = [
    "Energy Equipment & Services",
    "Oil, Gas & Consumable Fuels",
    "Chemicals",
    "Construction Materials",
    "Containers & Packaging",
    "Metals & Mining",
    "Paper & Forest Products",
    "Aerospace & Defense",
    "Building Products",
    "Construction & Engineering",
    "Electrical Equipment",
    "Industrial Conglomerates",
    "Machinery",
    "Trading Companies & Distributors",
    "Commercial Services & Supplies",
    "Professional Services",
    "Air Freight & Logistics",
    "Airlines",
    "Marine",
    "Road & Rail",
    "Transportation Infrastructure",
    "Auto Components",
    "Automobiles",
    "Food & Staples Retailing",
    "Beverages",
    "Food Products",
    "Tobacco",
    "Health Care Equipment & Supplies",
    "Health Care Providers & Services",
    "Health Care Technology",
    "Banks",
    "Thrifts & Mortgage Finance",
    "Diversified Financial Services",
    "Consumer Finance",
    "Capital Markets",
    "Mortgage Real Estate Investment Trusts (REITs)",
    "Insurance",
    "IT Services",
    "Software",
    "Communications Equipment",
    "Technology Hardware, Storage & Peripherals",
    "Electronic Equipment, Instruments & Components",
    "Semiconductors & Semiconductor Equipment",
    "Diversified Telecommunication Services",
    "Wireless Telecommunication Services",
    "Media",
    "Entertainment",
    "Interactive Media & Services",
    "Electric Utilities",
    "Gas Utilities",
    "Multi-Utilities",
    "Water Utilities",
    "Independent Power and Renewable Electricity Producers",
    "Equity Real Estate Investment Trusts (REITs)",
    "Real Estate Management & Development",
  ];
  const sectorOptions = [
    "Energy",
    "Materials",
    "Industrials",
    "Consumer Discretionary",
    "Consumer Staples",
    "Health Care",
    "Financials",
    "Information Technology",
    "Communication Services",
    "Utilities",
    "Real Estate",
  ];
  const subTypeOptions = [
    "Apartment",
    "Land",
    "Bungalow",
    "Building",
    "Office",
    "Educational",
    "Institutional",
    "Assembly",
    "Business",
    "Mercantile",
    "Industrial",
    "Storage",
    "Wholesale Establishments",
    "Mixed Land Use",
    "Hazardous",
    "Detached",
  ];
  const typeOptions = ["Residential", "Commercial"];
  return (
    <>
      <tr className=" w-full bg-[#f5f5f5] mt-[0.5rem] flex justify-between items-center p-[0.1rem] h-[50px] pr-[0.21rem] rounded-[4px]">
        <td className=" font-medium text-[15px] text-[#000]">{fund.name}</td>
        <td>
          <tr>
            <td className="font-medium text-[14px]">{`₹ ${parseInt(
              fund.current_price ? parseFloat(fund.current_price).toFixed(0) : 0
            ).toLocaleString("en-IN")}`}</td>
            <td
              className=" font-medium text-[15px] text-primary-200 mr-[1rem]"
              onClick={() => {
                let newMfArray = [...productarray];
                let index = productarray.findIndex((pid) => pid.id === fund.id);
                newMfArray.splice(index, 1);
                setProductarray(newMfArray);
                //editing in patch mf array
                if (data.some((getFund) => getFund.id === fund.id)) {
                  let newPatchMfArray = [...patchProductArray];
                  let patchindex = newPatchMfArray.findIndex(
                    (pid) => pid.id === fund.id
                  );
                  if (patchindex !== -1) newPatchMfArray.splice(patchindex, 1);
                  setPatchProductArray(newPatchMfArray);
                  setDeleteArray([
                    ...deleteArray,
                    {
                      ...fund,
                      investment_date: new Date(fund.investment_date),
                      current_price_date: new Date(fund.current_price_date),
                    },
                  ]);
                } //editing in post mf array
                else {
                  let newPostMfArray = [...postProductArray];
                  let postindex = newPostMfArray.findIndex(
                    (pid) => pid.id === fund.id
                  );
                  newPostMfArray.splice(postindex, 1);
                  setPostProductArray(newPostMfArray);
                }
              }}
            >
              Delete
            </td>
            <td
              className="p-[0px] w-[20px] pl-[0.3rem]"
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open ? (
                <img className="block" src={ArrowDown} alt="err" />
              ) : (
                <img className="block" src={ArrowLeft} alt="err" />
              )}
            </td>
          </tr>
        </td>
      </tr>
      {open ? (
        <div className="pt-[16px]">
          <tr className="flex w-full">
            <td className="w-[31%]">
              <TextField
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Name"
                required
                type="text"
                value={fund.name}
                onChange={(e) => {
                  let newMfArray = [...productarray];
                  let index = productarray.findIndex(
                    (pid) => pid.id === fund.id
                  );
                  newMfArray[index].name = e.target.value;
                  setProductarray(newMfArray);
                  //editing in patch mf array
                  if (data.some((getFund) => getFund.id === fund.id)) {
                    let newPatchMfArray = [...patchProductArray];
                    let patchindex = newPatchMfArray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    if (patchindex === -1) {
                      newPatchMfArray.push({
                        ...fund,
                        investment_date: new Date(fund.investment_date),
                        current_price_date: new Date(fund.current_price_date),
                      });
                      newPatchMfArray[newPatchMfArray.length - 1].name =
                        e.target.value;
                    } else newPatchMfArray[patchindex].name = e.target.value;
                    setPatchProductArray(newPatchMfArray);
                  } //editing in post mf array
                  else {
                    let newPostMfArray = [...postProductArray];
                    let postindex = newPostMfArray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newPostMfArray[postindex].name = e.target.value;
                    setPostProductArray(newPostMfArray);
                  }
                }}
              />
            </td>
            <td className="w-[23%]">
              <TextField
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Investment Price(in ₹)"
                type="text"
                value={fund.investment_price}
                onChange={(e) => {
                  const re = /^\d*\.?\d*$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    let newMfArray = [...productarray];
                    let index = productarray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newMfArray[index].investment_price = e.target.value;
                    setProductarray(newMfArray);
                    //editing in patch mf array
                    if (data.some((getFund) => getFund.id === fund.id)) {
                      let newPatchMfArray = [...patchProductArray];
                      let patchindex = newPatchMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      if (patchindex === -1) {
                        newPatchMfArray.push({
                          ...fund,
                          investment_date: new Date(fund.investment_date),
                          current_price_date: new Date(fund.current_price_date),
                        });
                        newPatchMfArray[
                          newPatchMfArray.length - 1
                        ].investment_price = e.target.value;
                      } else
                        newPatchMfArray[patchindex].investment_price =
                          e.target.value;
                      setPatchProductArray(newPatchMfArray);
                    } //editing in post mf array
                    else {
                      let newPostMfArray = [...postProductArray];
                      let postindex = newPostMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      newPostMfArray[postindex].investment_price =
                        e.target.value;
                      setPostProductArray(newPostMfArray);
                    }
                  }
                }}
              />
            </td>
            <td className="w-[23%]">
              <FormControl fullWidth>
                <InputLabel>Industry</InputLabel>
                <Select
                  value={fund.industry}
                  defaultValue=""
                  label={"Industry"}
                  onChange={(e) => {
                    let newMfArray = [...productarray];
                    let index = productarray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newMfArray[index].industry = e.target.value;
                    setProductarray(newMfArray);
                    //editing in patch mf array
                    if (data.some((getFund) => getFund.id === fund.id)) {
                      let newPatchMfArray = [...patchProductArray];
                      let patchindex = newPatchMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      if (patchindex === -1) {
                        newPatchMfArray.push({
                          ...fund,
                          investment_date: new Date(fund.investment_date),
                          current_price_date: new Date(fund.current_price_date),
                        });
                        newPatchMfArray[newPatchMfArray.length - 1].industry =
                          e.target.value;
                      } else
                        newPatchMfArray[patchindex].industry = e.target.value;
                      setPatchProductArray(newPatchMfArray);
                    } //editing in post mf array
                    else {
                      let newPostMfArray = [...postProductArray];
                      let postindex = newPostMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      newPostMfArray[postindex].industry = e.target.value;
                      setPostProductArray(newPostMfArray);
                    }
                  }}
                >
                  {industryOptions.map((option, ind) => (
                    <MenuItem
                      key={ind}
                      value={option}
                      id={ind}
                      style={{
                        display: "block",
                        padding: "0.5rem 1.5rem",
                        justifyContent: "flex-start",
                        fontFamily: "Inter,sans-serif",
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </td>
            <td className="w-[23%]">
              <TextField
                fullWidth
                sx={{
                  fontFamily: "Inter,sans-sarif",
                  fontWeight: 400,
                }}
                label="Current Price(in ₹)"
                type="text"
                value={fund.current_price.toLocaleString("en-IN")}
                onChange={(e) => {
                  const re = /^\d*\.?\d*$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    let newMfArray = [...productarray];
                    let index = productarray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newMfArray[index].current_price = e.target.value;
                    setProductarray(newMfArray);
                    //editing in patch mf array
                    if (data.some((getFund) => getFund.id === fund.id)) {
                      let newPatchMfArray = [...patchProductArray];
                      let patchindex = newPatchMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      if (patchindex === -1) {
                        newPatchMfArray.push({
                          ...fund,
                          investment_date: new Date(fund.investment_date),
                          current_price_date: new Date(fund.current_price_date),
                        });
                        newPatchMfArray[
                          newPatchMfArray.length - 1
                        ].current_price = e.target.value;
                      } else
                        newPatchMfArray[patchindex].current_price =
                          e.target.value;
                      setPatchProductArray(newPatchMfArray);
                    } //editing in post mf array
                    else {
                      let newPostMfArray = [...postProductArray];
                      let postindex = newPostMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      newPostMfArray[postindex].current_price = e.target.value;
                      setPostProductArray(newPostMfArray);
                    }
                  }
                }}
              />
            </td>
          </tr>
          <tr className="flex justify-between w-full">
            <td className="w-[23%]">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  autoOk={true}
                  className="client-input-date-input"
                  inputFormat="DD-MM-YYYY"
                  id="date-picker-inline"
                  label="Investment Date"
                  value={new Date(fund.investment_date)}
                  onChange={(date) => {
                    let newMfArray = [...productarray];
                    let index = productarray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newMfArray[index].investment_date = date;
                    setProductarray(newMfArray);
                    //editing in patch mf array
                    if (data.some((getFund) => getFund.id === fund.id)) {
                      let newPatchMfArray = [...patchProductArray];
                      let patchindex = newPatchMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      if (patchindex === -1) {
                        newPatchMfArray.push({
                          ...fund,
                          investment_date: new Date(fund.investment_date),
                          current_price_date: new Date(fund.current_price_date),
                        });
                        newPatchMfArray[
                          newPatchMfArray.length - 1
                        ].investment_date = date;
                      } else newPatchMfArray[patchindex].investment_date = date;
                      setPatchProductArray(newPatchMfArray);
                    } //editing in post mf array
                    else {
                      let newPostMfArray = [...postProductArray];
                      let postindex = newPostMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      newPostMfArray[postindex].investment_date = date;
                      setPostProductArray(newPostMfArray);
                    }
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </td>
            <td className="w-[23%]">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  autoOk={true}
                  className="client-input-date-input"
                  inputFormat="DD-MM-YYYY"
                  id="date-picker-inline"
                  label="Curent Price Date"
                  value={new Date(fund.current_price_date)}
                  disabled={true}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </td>
            <td className="w-[19%]">
              <FormControl fullWidth>
                <InputLabel>Sector</InputLabel>
                <Select
                  value={fund.sector}
                  defaultValue=""
                  label={"Sector"}
                  onChange={(e) => {
                    let newMfArray = [...productarray];
                    let index = productarray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newMfArray[index].sector = e.target.value;
                    setProductarray(newMfArray);
                    //editing in patch mf array
                    if (data.some((getFund) => getFund.id === fund.id)) {
                      let newPatchMfArray = [...patchProductArray];
                      let patchindex = newPatchMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      if (patchindex === -1) {
                        newPatchMfArray.push({
                          ...fund,
                          investment_date: new Date(fund.investment_date),
                          current_price_date: new Date(fund.current_price_date),
                        });
                        newPatchMfArray[newPatchMfArray.length - 1].sector =
                          e.target.value;
                      } else
                        newPatchMfArray[patchindex].sector = e.target.value;
                      setPatchProductArray(newPatchMfArray);
                    } //editing in post mf array
                    else {
                      let newPostMfArray = [...postProductArray];
                      let postindex = newPostMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      newPostMfArray[postindex].sector = e.target.value;
                      setPostProductArray(newPostMfArray);
                    }
                  }}
                >
                  {sectorOptions.map((option, ind) => (
                    <MenuItem
                      key={ind}
                      value={option}
                      id={ind}
                      style={{
                        display: "block",
                        padding: "0.5rem 1.5rem",
                        justifyContent: "flex-start",
                        fontFamily: "Inter,sans-serif",
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </td>
            <td className="w-[19%]">
              <FormControl fullWidth>
                <InputLabel>Type</InputLabel>
                <Select
                  value={fund.type}
                  defaultValue=""
                  label={"Type"}
                  onChange={(e) => {
                    let newMfArray = [...productarray];
                    let index = productarray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newMfArray[index].type = e.target.value;
                    setProductarray(newMfArray);
                    //editing in patch mf array
                    if (data.some((getFund) => getFund.id === fund.id)) {
                      let newPatchMfArray = [...patchProductArray];
                      let patchindex = newPatchMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      if (patchindex === -1) {
                        newPatchMfArray.push({
                          ...fund,
                          last_amount_date: new Date(fund.last_amount_date),
                          current_amount_date: new Date(
                            fund.current_amount_date
                          ),
                          maturity_date: new Date(fund.maturity_date),
                        });
                        newPatchMfArray[newPatchMfArray.length - 1].type =
                          e.target.value;
                      } else newPatchMfArray[patchindex].type = e.target.value;
                      setPatchProductArray(newPatchMfArray);
                    } //editing in post mf array
                    else {
                      let newPostMfArray = [...postProductArray];
                      let postindex = newPostMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      newPostMfArray[postindex].type = e.target.value;
                      setPostProductArray(newPostMfArray);
                    }
                  }}
                >
                  {typeOptions.map((option, ind) => (
                    <MenuItem
                      key={ind}
                      value={option}
                      id={ind}
                      style={{
                        display: "block",
                        padding: "0.5rem 1.5rem",
                        justifyContent: "flex-start",
                        fontFamily: "Inter,sans-serif",
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </td>
            <td className="w-[19%]">
              <FormControl fullWidth>
                <InputLabel>Sub Type</InputLabel>
                <Select
                  value={fund.sub_type}
                  defaultValue=""
                  label={"Sub Type"}
                  onChange={(e) => {
                    let newMfArray = [...productarray];
                    let index = productarray.findIndex(
                      (pid) => pid.id === fund.id
                    );
                    newMfArray[index].sub_type = e.target.value;
                    setProductarray(newMfArray);
                    //editing in patch mf array
                    if (data.some((getFund) => getFund.id === fund.id)) {
                      let newPatchMfArray = [...patchProductArray];
                      let patchindex = newPatchMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      if (patchindex === -1) {
                        newPatchMfArray.push({
                          ...fund,
                          investment_date: new Date(fund.investment_date),
                          current_price_date: new Date(fund.current_price_date),
                        });
                        newPatchMfArray[newPatchMfArray.length - 1].sub_type =
                          e.target.value;
                      } else
                        newPatchMfArray[patchindex].sub_type = e.target.value;
                      setPatchProductArray(newPatchMfArray);
                    } //editing in post mf array
                    else {
                      let newPostMfArray = [...postProductArray];
                      let postindex = newPostMfArray.findIndex(
                        (pid) => pid.id === fund.id
                      );
                      newPostMfArray[postindex].sub_type = e.target.value;
                      setPostProductArray(newPostMfArray);
                    }
                  }}
                >
                  {subTypeOptions.map((option, ind) => (
                    <MenuItem
                      key={ind}
                      value={option}
                      id={ind}
                      style={{
                        display: "block",
                        padding: "0.5rem 1.5rem",
                        justifyContent: "flex-start",
                        fontFamily: "Inter,sans-serif",
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </td>
            {/* <td style={{ width: "29%" }}>
                  <TextField
                    fullWidth
                    disabled
                    sx={{
                      fontFamily: "Inter,sans-sarif",
                      fontWeight: 400,
                    }}
                    label="Current Value"
                    type="text"
                    value={(
                      fund.last_amount * fund.current_price
                    ).toLocaleString('en-IN')}
                  />
                  <label>SIP ?</label>
                  <br />
                  <ThemeProvider theme={BirrSwitch}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checked}
                          onChange={(e) => setChecked(!checked)}
                          inputProps={{ role: "switch" }}
                          color="#0122AF"
                        />
                      }
                    />
                  </ThemeProvider>
                </td> */}

            {/* <Fade in={checked}>{icon}</Fade> */}
          </tr>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
