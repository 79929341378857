import React, { useState, useEffect } from "react";
import EditField from "../EditField";
import "../../static/css/existing/dashboard_styles.css";
import Manager from "./Manager";
import { useDispatch, useSelector } from "react-redux";
import DefaultProfilePic from "../../assets/img/defaultprofilepic.png";
import Dropdown2 from "../../components/Dropdowns/DropdownWhiteSingle";
import { PatchTeamLead, PostEventLoggingData } from "../../actions/actions";
import InvestedgeTextField from "../../components/textfield/InvestedgeTextField";
import InvestedgeButtonOutlined from "../../components/buttons/InvestedgeButtonOutlined";
import InvestedgeAutocomplete from "../../components/autocomplete/InvestedgeAutocomplete";
import { MenuItem } from "@mui/material";
export default function ModuleContent() {
  const dispatch = useDispatch();
  const moduleBackendData = useSelector((state) => state.settingsRminfo);
  const opsAnalystData = useSelector((state) => state.settingsOperationsArray);
  const rmData = useSelector((state) => state.settingsRms);
  const operationsData = useSelector((state) => state.settingsOperations);
  const teamLeadData = useSelector((state) => state.settingsGetTeamLead);
  const [teamLead, setTeamLead] = useState({
    username: "",
    email: "",
    organization: "",
    role: "",
    product_input: true,
    research_input: true,
    client_input: true,
    product: false,
    research: false,
    client_dashboard: false,
    port_analytics: false,
    crm: false,
    client_module: false,
    calculators: false,
    model_portfolio: false,
    contact: "",
    dob: "2022-10-17",
    profile_pic: "",
  });
  const [oppsup, setOppSup] = useState({
    username: "",
    email: "",
    organization: "",
    role: "",
    product_input: true,
    research_input: true,
    client_input: true,
    product: false,
    research: false,
    client_dashboard: false,
    port_analytics: false,
    crm: false,
    client_module: false,
    calculators: false,
    model_portfolio: false,
    contact: "",
    dob: "2022-10-17",
    profile_pic: "",
  });
  const [zone, setZone] = useState("North");
  const [zoneDisable, setZoneDisable] = useState(true);
  const [opsDisable, setOpsDisable] = useState(true);
  const [teamLeadDisable, setTeamLeadDisable] = useState(true);
  const [artDenotion, setArtDenotion] = useState("₹ L");
  const [artDisable, setArtDisable] = useState(true);
  const [autDenotion, setAutDenotion] = useState("₹ L");
  const [autDisable, setAutDisable] = useState(true);
  const zonalDictionary = {
    S: "South",
    N: "North",
    E: "East",
    W: "West",
  };
  const userdata = useSelector((state) => state.userdata);

  useEffect(() => {
    let tlindex = teamLeadData.findIndex(
      (member) => member.email === moduleBackendData.team_lead
    );
    let opsindex = operationsData.findIndex(
      (member) => member.email === moduleBackendData.op_support
    );
    setTeamLead(
      tlindex === -1
        ? {
            username: "",
            email: "",
            organization: "",
            role: "",
            product_input: true,
            research_input: true,
            client_input: true,
            product: false,
            research: false,
            client_dashboard: false,
            port_analytics: false,
            crm: false,
            client_module: false,
            calculators: false,
            model_portfolio: false,
            contact: "",
            dob: "2022-10-17",
            profile_pic: "",
          }
        : teamLeadData[tlindex]
    );
    setOppSup(
      opsindex === -1
        ? {
            username: "",
            email: "",
            organization: "",
            role: "",
            product_input: true,
            research_input: true,
            client_input: true,
            product: false,
            research: false,
            client_dashboard: false,
            port_analytics: false,
            crm: false,
            client_module: false,
            calculators: false,
            model_portfolio: false,
            contact: "",
            dob: "2022-10-17",
            profile_pic: "",
          }
        : operationsData[opsindex]
    );
    setModuleBody({
      city: moduleBackendData.city,
      zone: zonalDictionary[moduleBackendData.zone],
      art: moduleBackendData.revenue.amount,
      aut: moduleBackendData.aum_target.amount,
    });
    setArtDenotion(`₹ ${moduleBackendData.revenue.denomination}`);
    setAutDenotion(`₹ ${moduleBackendData.aum_target.denomination}`);
    setZone(zonalDictionary[moduleBackendData.zone]);
    // eslint-disable-next-line
  }, [moduleBackendData, teamLeadData, operationsData]);

  const [moduleBody, setModuleBody] = useState({
    city: moduleBackendData.city,
    zone: moduleBackendData.zone,
    art: moduleBackendData.revenue.amount,
    aut: moduleBackendData.aum_target.amount,
  });

  const handleSave = () => {
    dispatch(
      PostEventLoggingData({
        module_name: "Settings",
        event_type: "Save button",
        url: window.location.href,
        note: `Saving module data`,
      })
    );
    dispatch(
      PatchTeamLead({
        city: moduleBody.city,
        zone: zone[0].toUpperCase(),
        revenue: {
          amount: parseInt(moduleBody.art),
          denomination: artDenotion[2],
        },
        aum_target: {
          amount: parseInt(moduleBody.aut),
          denomination: autDenotion[2],
        },
        op_support: oppsup.email ? oppsup.email : operationsData[0].email,
        team_lead: teamLead.email ? teamLead.email : teamLeadData[0].email,
      })
    );
  };
  return (
    <>
      {userdata.role !== "O" && userdata.role !== "SO" ? (
        <>
          <div className="grid grid-cols-12">
            <div className="col-span-4">
              <InvestedgeAutocomplete
                options={operationsData}
                autoHighlight
                getOptionLabel={(option) => option.username || ""}
                disabled={opsDisable}
                renderOption={(props, option) => (
                  <MenuItem
                    sx={{
                      "& > img": {
                        mr: 2,
                        flexShrink: 0,
                        borderRadius: "100%",
                        width: "20px",
                      },
                    }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      src={
                        option.profile_pic
                          ? option.profile_pic
                          : DefaultProfilePic
                      }
                      alt=""
                    />
                    {option.username}
                  </MenuItem>
                )}
                value={
                  oppsup.username.length === 0 ? operationsData[0] : oppsup
                }
                onChange={(event, val) => setOppSup(val)}
                renderInput={(params) => (
                  <InvestedgeTextField
                    {...params}
                    label="Your Operations Support"
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.username === value.username
                }
              />
            </div>
            <div className="col-span-2 flex justify-between">
              {opsDisable ? (
                <InvestedgeButtonOutlined
                  className="mx-auto"
                  onClick={() => {
                    dispatch(
                      PostEventLoggingData({
                        module_name: "Settings",
                        event_type: "Edit button",
                        url: window.location.href,
                        note: `Editing module data`,
                      })
                    );
                    setOpsDisable(false);
                  }}
                >
                  Edit
                </InvestedgeButtonOutlined>
              ) : (
                <InvestedgeButtonOutlined
                  className="mx-auto"
                  color="success"
                  onClick={() => {
                    handleSave();
                    setOpsDisable(true);
                  }}
                >
                  Save
                </InvestedgeButtonOutlined>
              )}
            </div>
            <div className="col-span-2 flex justify-between">
              {opsDisable ? null : (
                <InvestedgeButtonOutlined
                  color="error"
                  onClick={() => setOpsDisable(true)}
                >
                  Cancel
                </InvestedgeButtonOutlined>
              )}
            </div>
          </div>
          <div className="grid grid-cols-12 mt-[1.5rem]">
            <div className="col-span-4">
              <InvestedgeAutocomplete
                options={teamLeadData}
                disabled={teamLeadDisable}
                autoHighlight
                getOptionLabel={(option) => option.username || ""}
                renderOption={(props, option) => (
                  <MenuItem
                    sx={{
                      "& > img": {
                        mr: 2,
                        flexShrink: 0,
                        borderRadius: "100%",
                        width: "20px",
                      },
                    }}
                    {...props}
                  >
                    <img loading="lazy" src={option.profile_pic} alt="" />
                    {option.username}
                  </MenuItem>
                )}
                value={
                  teamLead.username.length === 0 ? teamLeadData[0] : teamLead
                }
                onChange={(event, val) => setTeamLead(val)}
                renderInput={(params) => (
                  <InvestedgeTextField {...params} label="Your Team Lead" />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.username === value.username
                }
              />
            </div>
            <div className="col-span-2 flex justify-between">
              {teamLeadDisable ? (
                <InvestedgeButtonOutlined
                  className="mx-auto"
                  onClick={() => {
                    dispatch(
                      PostEventLoggingData({
                        module_name: "Settings",
                        event_type: "Edit button",
                        url: window.location.href,
                        note: `Editing module data`,
                      })
                    );
                    setTeamLeadDisable(false);
                  }}
                >
                  Edit
                </InvestedgeButtonOutlined>
              ) : (
                <InvestedgeButtonOutlined
                  className="mx-auto"
                  color="success"
                  onClick={() => {
                    handleSave();
                    setTeamLeadDisable(true);
                  }}
                >
                  Save
                </InvestedgeButtonOutlined>
              )}
            </div>
            <div className="col-span-2 flex justify-between">
              {teamLeadDisable ? null : (
                <InvestedgeButtonOutlined
                  color="error"
                  onClick={() => setTeamLeadDisable(true)}
                >
                  Cancel
                </InvestedgeButtonOutlined>
              )}
            </div>
          </div>
          <EditField
            label="City"
            value="city"
            formBody={moduleBody}
            setformBody={setModuleBody}
            type="alpha"
            fieldWidth="300px"
            dispatchingSave={handleSave}
          />
          <div className="grid grid-cols-12 mt-[1.5rem]">
            <div className="col-span-4">
              <Dropdown2
                options={["North", "South", "East", "West"]}
                selected={zone}
                setSelected={setZone}
                disabled={zoneDisable}
                labelName={"Zone"}
              />
            </div>
            <div className="col-span-2 flex justify-between">
              {zoneDisable ? (
                <InvestedgeButtonOutlined
                  className="mx-auto"
                  onClick={() => {
                    dispatch(
                      PostEventLoggingData({
                        module_name: "Settings",
                        event_type: "Edit button",
                        url: window.location.href,
                        note: `Editing module data`,
                      })
                    );
                    setZoneDisable(false);
                  }}
                >
                  Edit
                </InvestedgeButtonOutlined>
              ) : (
                <InvestedgeButtonOutlined
                  className="mx-auto"
                  color="success"
                  onClick={() => {
                    handleSave();
                    setZoneDisable(true);
                  }}
                >
                  Save
                </InvestedgeButtonOutlined>
              )}
            </div>
            <div className="col-span-2 flex justify-between">
              {zoneDisable ? null : (
                <InvestedgeButtonOutlined
                  color="error"
                  onClick={() => setZoneDisable(true)}
                >
                  Cancel
                </InvestedgeButtonOutlined>
              )}
            </div>
          </div>
          <div className="grid grid-cols-12 mt-[1.5rem]">
            <div className="col-span-4">
              <div className="flex justify-between w-full">
                <div className="w-[25%]">
                  <Dropdown2
                    options={["₹ K", "₹ L", "₹ C"]}
                    labelName={null}
                    selected={artDenotion}
                    setSelected={setArtDenotion}
                    disabled={artDisable}
                  />
                </div>

                <InvestedgeTextField
                  variant="outlined"
                  label="Annual Revenue Target"
                  className="font-inter font-normal w-[72%] py-[8px] pr-[8px] pl-[0px]"
                  disabled={artDisable}
                  value={moduleBody.art}
                  onChange={(e) => {
                    const re = /^\d+$/;
                    if (
                      (e.target.value === "" || re.test(e.target.value)) &&
                      e.target.value.length <= 10
                    ) {
                      let newformBody = { ...moduleBody };
                      newformBody.art = e.target.value;
                      setModuleBody(newformBody);
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-span-2 flex justify-between">
              {artDisable ? (
                <InvestedgeButtonOutlined
                  className="mx-auto"
                  onClick={() => {
                    dispatch(
                      PostEventLoggingData({
                        module_name: "Settings",
                        event_type: "Edit button",
                        url: window.location.href,
                        note: `Editing module data`,
                      })
                    );
                    setArtDisable(false);
                  }}
                >
                  Edit
                </InvestedgeButtonOutlined>
              ) : (
                <InvestedgeButtonOutlined
                  className="mx-auto"
                  color="success"
                  onClick={() => {
                    handleSave();
                    setArtDisable(true);
                  }}
                >
                  Save
                </InvestedgeButtonOutlined>
              )}
            </div>
            <div className="col-span-2 flex justify-between">
              {artDisable ? null : (
                <InvestedgeButtonOutlined
                  color="error"
                  onClick={() => setArtDisable(true)}
                >
                  Cancel
                </InvestedgeButtonOutlined>
              )}
            </div>
          </div>
          <div className="grid grid-cols-12 mt-[1.5rem]">
            <div className="col-span-4">
              <div className="flex justify-between w-full">
                <div className="w-[25%]">
                  <Dropdown2
                    options={["₹ K", "₹ L", "₹ C"]}
                    labelName={null}
                    selected={autDenotion}
                    setSelected={setAutDenotion}
                    buttonStyles={{
                      boxShadow: "0px 0px 3px rgb(0 0 0 / 15%)",
                      border: "1px solid #e0dddd",
                    }}
                    disabled={autDisable}
                  />
                </div>

                <InvestedgeTextField
                  variant="outlined"
                  label="Annual AUM Target"
                  className="font-inter font-normal w-[72%] py-[8px] pr-[8px] pl-[0px]"
                  type="number"
                  disabled={autDisable}
                  value={moduleBody.aut}
                  onChange={(e) => {
                    const re = /^\d+$/;
                    if (
                      (e.target.value === "" || re.test(e.target.value)) &&
                      e.target.value.length <= 10
                    ) {
                      let newformBody = { ...moduleBody };
                      newformBody.aut = e.target.value;
                      setModuleBody(newformBody);
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-span-2 flex justify-between">
              {autDisable ? (
                <InvestedgeButtonOutlined
                  className="mx-auto"
                  onClick={() => {
                    dispatch(
                      PostEventLoggingData({
                        module_name: "Settings",
                        event_type: "Edit button",
                        url: window.location.href,
                        note: `Editing module data`,
                      })
                    );
                    setAutDisable(false);
                  }}
                >
                  Edit
                </InvestedgeButtonOutlined>
              ) : (
                <InvestedgeButtonOutlined
                  className="mx-auto"
                  color="success"
                  onClick={() => {
                    handleSave();
                    setAutDisable(true);
                  }}
                >
                  Save
                </InvestedgeButtonOutlined>
              )}
            </div>
            <div className="col-span-2 flex justify-between">
              {autDisable ? null : (
                <InvestedgeButtonOutlined
                  color="error"
                  onClick={() => setAutDisable(true)}
                >
                  Cancel
                </InvestedgeButtonOutlined>
              )}
            </div>
          </div>
          {rmData.length ? (
            <>
              <div className="card-heading mt-[1.5rem] tracking-[0.5px]">
                Relationship Managers Under You
              </div>
              <div className="flex w-full  overflow-x-visible overflow-y-visible min-h-[120px] pt-[0.75rem]">
                {rmData.map((item, index) => {
                  return <Manager item={item} key={index} />;
                })}
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <div className="card-heading mt-[0rem]">
            Operations Analyst Module Settings
          </div>
          <div className="card-heading mt-[0.75rem]">
            Relationship Managers Working With You
          </div>
          <div className="flex w-full min-h-[120px] overflow-x-visible overflow-y-visible pt-[0.75rem] ">
            {opsAnalystData.map((item, index) => {
              return <Manager item={item} key={index} />;
            })}
          </div>
        </>
      )}
    </>
  );
}
