import React, { useEffect } from "react";
import Cashrow from "./Cashrow";
import { useState } from "react";
import urls from "../../../../urls";
import { useDispatch, useSelector } from "react-redux";
import {
  GetDashboardCashPortfolio,
  GetDashboardPortfolioList,
  OpenSnackbar,
} from "../../../../actions/actions";
import axios from "axios";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
import { Bars } from "react-loader-spinner";
export default function CashScreen({ id, name }) {
  const data = useSelector((store) => store.dashboardCashPortfolio);
  const [productarray, setProductarray] = useState(
    JSON.parse(JSON.stringify(data)).map((y) => {
      return {
        ...y,
        last_amount_date: new Date(y.last_amount_date),
        current_amount_date: new Date(y.current_amount_date),
      };
    })
  );
  const [patchProductArray, setPatchProductArray] = useState([]);
  const portfolioList = useSelector(
    (store) => store.dashboardPortfolioPriceList
  );
  const [submitActive, setSubmitActive] = useState(false);
  const dispatch = useDispatch();
  // const portfolioValue = useSelector(
  //   (store) => store.portfolioReviewGetSumary.portfolio_value
  // );
  // const parsePortfolio = (portfolio) => {
  //   if (portfolio / 1000 < 100)
  //     return `₹ ${parseFloat(portfolio / 1000).toFixed(1)} K`;
  //   if (portfolio / 100000 < 100)
  //     return `₹ ${parseFloat(portfolio / 100000).toFixed(1)} L`;
  //   return `₹ ${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  // };
  const [loading, setloading] = useState(false);
  useEffect(() => {
    dispatch(
      GetDashboardCashPortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setProductarray(data);
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    if (patchProductArray.length) setSubmitActive(true);
    else setSubmitActive(false);
  }, [patchProductArray]);
  const numberOfDays = (date1, date2) => {
    // To calculate the time difference of two dates
    var difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    var difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
    return difference_In_Days;
  };
  const handleSubmit = async () => {
    setloading(true);
    let newPatchList = patchProductArray.map((item) => {
      var dd = String(item.last_amount_date.getDate()).padStart(2, "0");
      var mm = String(item.last_amount_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.last_amount_date.getFullYear();
      var dd1 = String(item.current_amount_date.getDate()).padStart(2, "0");
      var mm1 = String(item.current_amount_date.getMonth() + 1).padStart(
        2,
        "0"
      );
      var yyyy1 = item.current_amount_date.getFullYear();
      return {
        id: item.id,
        name: item.name,
        last_amount: parseFloat(item.last_amount),
        last_amount_date: yyyy + "-" + mm + "-" + dd,
        bank: item.bank,
        interest_rate: parseFloat(item.interest_rate),
        current_amount: parseFloat(
          parseInt(item.last_amount) *
            (1 + parseFloat(item.interest_rate / 100)) **
              (numberOfDays(
                new Date(item.current_amount_date),
                new Date(item.last_amount_date)
              ) /
                365)
        ),
        current_amount_date: yyyy1 + "-" + mm1 + "-" + dd1,
        user_id: localStorage.getItem("clientId"),
        goal_title: item.goal_title,
        goal_id: item.goal_id,
      };
    });
    newPatchList.length &&
      (await axios.patch(urls.dashboardCashPortfolioURL, newPatchList));
    setPatchProductArray([]);
    dispatch(
      GetDashboardPortfolioList({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      GetDashboardCashPortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      OpenSnackbar({
        severity: "success",
        message: "Cash Tagged Successfully",
      })
    );
    setloading(false);
  };
  let emptyFields = false;
  for (let i = 0; i < productarray.length; i++)
    if (!productarray[i].name) emptyFields = true;

  return (
    <div className="w-full">
      <div className="font-medium font-inter text-[18px] text-neutralBlack">
        Cash - {portfolioList.total_cash_or_saving_ac_value}
      </div>
      {!loading ? (
        <div className="update-portfolio-asset-row-container h-[62vh] mt-4">
          {productarray.map((fund, index) => (
            <Cashrow
              key={fund.id}
              fund={fund}
              id={id}
              name={name}
              productarray={productarray}
              setProductarray={setProductarray}
              patchProductArray={patchProductArray}
              setPatchProductArray={setPatchProductArray}
              data={data}
            />
          ))}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center w-[96%] h-[62vh]">
          <Bars color="#1982F8" />
          <p className=" font-inter text-[14px] font-medium text-neutralBlack mb-[1rem] mt-[1rem]">
            Updating Portfolio ...
          </p>
        </div>
      )}
      <div className="flex w-full mt-[24px] pr-[24px] justify-end">
        <InvestedgeButton
          disabled={!submitActive || emptyFields}
          onClick={() => {
            if (patchProductArray.length) {
              setSubmitActive(false);
              handleSubmit();
            }
          }}
        >
          Submit
        </InvestedgeButton>
      </div>
    </div>
  );
}
