import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import AfterloginApp from "../AfterloginApp";
import Screener from "../../assets/img/screener.svg";
import Collateral from "../../assets/img/collateral.svg";
import CollateralScreen from "./CollateralFiles/Collateral";
import Comparision from "../../assets/img/comparision.svg";
import "../../static/css/existing/dashboard_styles.css";
import { useHistory } from "react-router-dom";
import SearchIcon from "../../assets/img/bigsearchicon.svg";
import MicrophoneIcon from "../../assets/img/bluemicrophone.svg";
import Carousel from "react-grid-carousel";
import Lock from "../../assets/img/lock_locked_blue.svg";
import ClickAwayListener from "react-click-away-listener";
import { Bars } from "react-loader-spinner";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import InvestedgePopper from "../../components/popper/InvestedgePopper";
import { MenuItem } from "@mui/material";
// import ReactPaginate from "react-paginate";
// import NextArrow from "../../assets/img/pagination_next.svg";
// import ToggleButton from "@mui/material/ToggleButton";
// import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
// import { ThemeProvider, createTheme } from "@mui/material/styles";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
import {
  getProductOnepagerData,
  PostEventLoggingData,
  OpenSnackbar,
  getComparisonData,
} from "../../actions/actions";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import GreyMicrophoneIcon from "../../assets/img/greymicrophone.svg";
// const ProductInsightsData = [
//   {
//     fund_name: "DSP Flexi Cap Fund - Regular (Growth)",
//     product_type: "Mutual Fund",
//     assets_type: "Equity",
//     return: 2.32,
//     aum: 3.29,
//     expense: 12.13,
//   },
//   {
//     fund_name: "DSP Flexi Cap Fund - Regular (Growth)",
//     product_type: "PMS",
//     assets_type: "Debt",
//     return: 2.32,
//     aum: 3.29,
//     expense: 12.13,
//   },
//   {
//     fund_name: "DSP Flexi Cap Fund - Regular (Growth)",
//     product_type: "Stocks",
//     assets_type: "Alternate",
//     return: 2.32,
//     aum: 3.29,
//     expense: 12.13,
//   },
// ];
// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#1982F8",
//     },
//     typography: {
//       fontSize: 14,
//     },
//   },
// });
const MyDot = ({ isActive }) => (
  <span
    className="inline-block h-[10px] w-[10px] rounded-[100%] mt-[4rem]"
    style={{
      background: isActive ? "#777" : "#e0dddd",
    }}
  />
);

export default function ProductInsights() {
  const history = useHistory();
  const dispatch = useDispatch();
  const inputDivRef = useRef(null);
  const PopularProductsData = useSelector((store) => store.popularProducts);
  const fundName = useSelector((store) => store.getComparisonData);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchFund, setSearchFund] = useState("");
  const [collateralModel, setCollateralModel] = useState(false);
  const { listening, transcript } = useSpeechRecognition();
  let clickedFundID = fundName[0].product_id;
  // const [searchBar, setSearchBar] = useState(false);
  // const [productFilter, setProductFilter] = useState(ProductInsightsData);
  // const [clientToggle, setClientToggle] = useState("asset");
  // const [pageNumber, setPageNumber] = useState(0);
  // const [productType, setProductType] = useState("All");
  // const clientsPerPage = 5;
  // const pagesVisited = pageNumber * clientsPerPage;
  // const displayproductinsight = productFilter
  //   .slice(pagesVisited, pagesVisited + clientsPerPage)
  //   .map((client, index) => {
  //     return (
  //       <tbody>
  //         <tr key={index}>
  //           <td
  //             className="dashboard-body-second-card-table-data"
  //             style={{
  //               paddingLeft: "1rem",
  //               textAlign: "left",
  //             }}
  //           >
  //             {client.fund_name}
  //           </td>
  //           <td className="dashboard-body-second-card-table-data">
  //             {client.product_type}
  //           </td>
  //           <td className="dashboard-body-second-card-table-data">
  //             {client.assets_type}
  //           </td>
  //           <td className="dashboard-body-second-card-table-data">
  //             {client.return}
  //           </td>
  //           <td className="dashboard-body-second-card-table-data">
  //             {client.aum}
  //           </td>
  //           <td className="dashboard-body-second-card-table-data">
  //             {client.expense}
  //           </td>
  //         </tr>
  //       </tbody>
  //     );
  //   });
  // const pageCount = Math.ceil(productFilter.length / clientsPerPage);
  // const changePage = ({ selected }) => {
  //   setPageNumber(selected);
  // };
  // const handleChangeMui = (event) => {
  //   setProductType(event.target.value);
  // };
  // const handleChangedToogle = (e, newAlignment) => {
  //   setProductType("All");
  //   if (newAlignment) setClientToggle(newAlignment);
  // };

  // useEffect(() => {
  //   if (clientToggle === "asset") {
  //     setProductFilter(
  //       productType === "All"
  //         ? ProductInsightsData
  //         : ProductInsightsData.filter(
  //             (data) => data.assets_type === productType
  //           )
  //     );
  //   } else {
  //     setProductFilter(
  //       productType === "All"
  //         ? ProductInsightsData
  //         : ProductInsightsData.filter(
  //             (data) => data.product_type == productType
  //           )
  //     );
  //   }
  // }, [productType]);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      getProductOnepagerData(
        {
          params: {
            product_id: searchFund.length ? clickedFundID : null,
          },
        },
        history,
        !searchFund.length
          ? (alert("Please enter any Fund name"), setSearchFund(""))
          : setSearchFund(fundName[0].name)
      )
    );
    dispatch(
      PostEventLoggingData({
        module_name: "Product Insights",
        event_type: "Search Button",
        url: window.location.href,
        note: `${searchFund} has been clicked prod_id= ${clickedFundID}`,
      })
    );
  };

  useEffect(() => {
    searchFund.length >= 3 &&
      dispatch(getComparisonData({ params: { name: searchFund } }));
    if (searchFund.length >= 3) setAnchorEl(inputDivRef.current);
    else setAnchorEl(null);
    // eslint-disable-next-line
  }, [searchFund]);
  useEffect(() => {
    setSearchFund(transcript);
    // eslint-disable-next-line
  }, [transcript]);
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Product Insights"}
        description={
          "Insights on Mutual Funds, Stocks, PMS, AIFs, MLDs and much more"
        }
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className="bg-[#fff] p-[1rem] rounded-[00.5rem] h-full w-full min-h-[calc(100vh_-_116px)]">
            <div className="border-0 pt-4 bg-[#fff] justify-start w-[66%] pb-[1.5rem] px-[0rem] relative">
              <form
                onSubmit={handleSubmit}
                className="flex w-full justify-center"
              >
                <div
                  ref={inputDivRef}
                  aria-describedby="search-screen-results-popper"
                  className="w-[88%] border !border-globalBorderColor py-[0.25rem] px-[1rem] pr-[1rem] flex rounded-[6px] justify-between items-center relative rounded-r-none hover:border-neutralBlack"
                >
                  <img src={SearchIcon} alt="err" />
                  <input
                    placeholder="Search.."
                    className="md:hidden border-none shadow-none w-[calc(100%_-_24px_-_40px)] ml-[0.5rem] text-[14px] placeholder:text-placeholder placeholder:font-normal placeholder:text-base"
                    value={searchFund}
                    onChange={(e) => {
                      setSearchFund(e.target.value);
                    }}
                  />
                  <input
                    placeholder="Search for view on Markets, Sectors, and Economy"
                    className="hidden md:block border-none shadow-none w-[calc(100%_-_24px_-_40px)] ml-[0.5rem] text-[14px] placeholder:text-placeholder placeholder:font-normal placeholder:text-base"
                    value={searchFund}
                    onChange={(e) => {
                      setSearchFund(e.target.value);
                    }}
                  />
                  <img
                    src={listening ? GreyMicrophoneIcon : MicrophoneIcon}
                    alt="err"
                    className="w-[20px] object-contain"
                    onClick={
                      listening
                        ? SpeechRecognition.stopListening
                        : SpeechRecognition.startListening
                    }
                  />
                </div>
                <InvestedgeButton
                  className="h-[45px] rounded-l-none w-[12%]"
                  type="submit"
                >
                  Search
                </InvestedgeButton>
              </form>
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <InvestedgePopper
                  id="search-screen-results-popper"
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  className="h-[300px] w-[55.2%]"
                  placement="bottom-start"
                >
                  {fundName[0] === "loading" ? (
                    <div className="h-[300px] flex justify-center items-center">
                      <Bars color="#1982F8" height="60" width="60" />
                    </div>
                  ) : fundName.length ? (
                    fundName.slice(0, 7).map((option, ind) => (
                      <MenuItem
                        key={ind}
                        onClick={() => {
                          dispatch(
                            getProductOnepagerData(
                              {
                                params: {
                                  product_id: option.product_id,
                                },
                              },
                              history
                            )
                          );
                          setSearchFund(option.name);
                          setAnchorEl(null);
                        }}
                        className="font-inter py-[1rem] text-neutralBlack "
                      >
                        {option.name}
                      </MenuItem>
                    ))
                  ) : (
                    <div className="h-[300px] font-inter flex items-center justify-center font-medium text-xl text-neutralBlack">
                      No data found
                    </div>
                  )}
                </InvestedgePopper>
              </ClickAwayListener>
              {/* <div
                    ref={setPopperRef}
                    style={{
                      ...styles.popper,
                      zIndex: 1000000,
                      background: "white",
                      top: "75px",
                    }}
                    {...attributes.popper}
                    className="family-details-dropdown-content w-full absolute"
                  >
                    {data[0] === "loading" ? (
                      <div className="h-[300px]  flex justify-center items-center">
                        <Bars color="#1982f8" height="60" width="100" />
                      </div>
                    ) : data.length ? (
                      data.map((option, ind) => (
                        <div
                          key={ind}
                          onClick={() => {
                            dispatch(
                              getProductOnepagerData(
                                {
                                  params: {
                                    product_id: option.product_id,
                                  },
                                },
                                history
                              )
                            );
                            setSearchFund(option.name);
                            setIsActive(false);
                          }}
                          className="family-details-dropdown-item"
                        >
                          {option.name}
                        </div>
                      ))
                    ) : (
                      <div className="h-[300px] flex items-center justify-center">
                        No data found
                      </div>
                    )}
                  </div> */}
            </div>

            {/* product-insight-secondrow-maindiv */}
            <div className="flex-row pt-[1rem] justify-between flex w-full bg-[#fff] min-w-0">
              <div
                className="bg-[#ffffff] w-[31.5%] h-[94px] border-0 p-[1rem] shadow-productinsightsScreeners rounded-[8px] cursor-pointer"
                // onClick={() => {
                //   islocked && history.push("/productinsights/screener");
                // }}
              >
                <div className="flex w-full">
                  <div className="pl-[1rem]">
                    <img
                      src={Screener}
                      alt="err"
                      className="h-[32px] w-[32px] mt-[0.7rem]"
                    />
                  </div>
                  <div className=" w-full">
                    <div className="flex ml-[1.3rem] pt-[0.2rem] justify-between ">
                      <div>
                        <h3 className="font-inter font-medium leading-[20px] text-neutralBlack text-[16px] text-left align-top">
                          Screener
                        </h3>
                        <p className=" font-inter text-[14px] leading-[18px] text-neutralBlack font-normal pt-[0.5rem]">
                          Apply filters and find products
                        </p>
                      </div>
                      <div>
                        <img
                          src={Lock}
                          alt="err"
                          className="w-[15px]"
                          onClick={() =>
                            dispatch(
                              OpenSnackbar({
                                severity: "info",
                                message: "Feature will be coming soon",
                              })
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-[#ffffff] w-[31.5%] h-[94px] border-0 p-[1rem] shadow-productinsightsScreeners rounded-[8px] cursor-pointer">
                <div className="flex w-full">
                  <div className="pl-[1rem]">
                    <img
                      src={Comparision}
                      alt="err"
                      className="h-[32px] w-[32px] mt-[0.7rem]"
                    />
                  </div>
                  <div className=" w-full ">
                    <div className="flex ml-[1.3rem] pt-[0.2rem] justify-between ">
                      <div>
                        <h3 className="font-inter font-medium leading-[20px] text-neutralBlack text-[16px] text-left align-top">
                          Comparison
                        </h3>
                        <p className=" font-inter text-[14px] leading-[18px] text-neutralBlack font-normal pt-[0.5rem]">
                          Compare two or more products
                        </p>
                      </div>
                      <div>
                        <img
                          src={Lock}
                          alt="err"
                          className="w-[15px]"
                          onClick={() =>
                            dispatch(
                              OpenSnackbar({
                                severity: "info",
                                message: "Feature will be coming soon",
                              })
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-[#ffffff] flex-wrap w-[31.5%]  border-0 p-[1rem] shadow-productinsightsScreeners rounded-[8px] cursor-pointer">
                <div
                  // onClick={() => {
                  //   islocked
                  //     ? setCollateralModel(true)
                  //     : setCollateralModel(false);
                  // }}
                  className="flex w-full"
                >
                  <div className="pl-[1rem]">
                    <img
                      src={Collateral}
                      alt="err"
                      className="h-[32px] w-[32px] mt-[0.7rem]"
                    />
                  </div>
                  <div className=" w-full">
                    <div className="flex ml-[1.3rem] pt-[0.2rem] justify-between ">
                      <div>
                        <h3 className="font-inter font-medium leading-[20px] text-neutralBlack text-[16px] text-left align-top">
                          Collateral
                        </h3>
                        <p className=" font-inter text-[14px] leading-[18px] text-neutralBlack font-normal pt-[0.5rem]">
                          Find all collaterals at one place
                        </p>
                      </div>
                      <div>
                        <img
                          src={Lock}
                          alt="err"
                          className="w-[15px]"
                          onClick={() =>
                            dispatch(
                              OpenSnackbar({
                                severity: "info",
                                message: "Feature will be coming soon",
                              })
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CollateralScreen
                collateralModel={collateralModel}
                setCollateralModel={setCollateralModel}
              />
            </div>
            <div>
              <div className="w-full pt-[2rem] flex-row bg-[#fff]">
                <div>
                  <h3 className="card-heading ">Popular Products</h3>
                </div>
                <div className="mt-[1rem] mx-[-20px] mb-0">
                  <Carousel
                    cols={3}
                    rows={2}
                    gap={20}
                    loop
                    showDots={true}
                    autoplay={3000}
                    dot={MyDot}
                    hideArrow
                  >
                    {PopularProductsData.map((data, index) => (
                      <Carousel.Item>
                        <div
                          key={index}
                          className="flex mt-[0px] w-full min-h-[150px] border border-[#e0dddd] rounded-[4px] p-[1rem]"
                        >
                          <div className="dashboard-body-product-details">
                            <h3 className="font-inter text-[18px] not-italic font-medium leading-[22px] text-[#000] tracking-[0em] text-left">
                              {data.name}
                            </h3>
                            <p className="mt-[1rem] font-inter text-[12px] not-italic font-normal leading-[16px] tracking-[0em] text-left text-[#000]">
                              {data.description}
                            </p>
                            <div className="text-primary-300 bg-[#EDF5FE] rounded-[4px] py-[0.1rem] px-[1rem] font-inter text-[12px] not-italic font-normal leading-[16px] tracking-[0em] inline-block text-center mt-[19px]">
                              {data.product_type}
                            </div>
                          </div>
                        </div>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>

                {/* <h3
                className="dashboard-body-products-card-heading"
                style={{
                  marginTop: "71px",
                  marginBottom: "25px",
                }}
              >
                Popular Reckoner
              </h3>
              <div className="row" style={{ marginLeft: "1%" }}>
                <div style={{ width: "120%" }}>
                  <nav>
                    <div className="tab-content ">
                      <div
                        style={{
                          flexDirection: "column",
                        }}
                      >
                        <div className="dashboard-body-second-card-navigator">
                          <ThemeProvider theme={theme}>
                            <ToggleButtonGroup
                              color="primary"
                              value={clientToggle}
                              onChange={handleChangedToogle}
                              exclusive
                            >
                              <ToggleButton
                                value="asset"
                                style={{
                                  textTransform: "none",
                                  padding: "1rem",
                                  height: "37px",
                                }}
                              >
                                Asset Class
                              </ToggleButton>

                              <ToggleButton
                                value="product"
                                style={{
                                  textTransform: "none",
                                  padding: "1rem",
                                  height: "37px",
                                }}
                              >
                                Product Type
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </ThemeProvider>
                        </div>
                      </div>

                      <div style={{ display: "flex", marginTop: "2rem" }}>
                        <p
                          style={{
                            display: "inline-block",
                            fontFamily: "Inter , sans-serif",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "13px",
                          }}
                        >
                          List of recommended products under -{" "}
                        </p>

                        <div
                          style={{
                            fontFamily: "Inter , sans-serif",
                            fontSize: "14px",
                            fontStyle: "normal",
                            marginTop: "-0.4rem",
                            marginLeft: "1rem",
                          }}
                        >
                          {clientToggle == "product" ? (
                            <>
                              <FormControl
                                variant="standard"
                                sx={{ minWidth: 120 }}
                              >
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  value={productType}
                                  onChange={handleChangeMui}
                                >
                                  <MenuItem value={"All"}>All</MenuItem>
                                  <MenuItem value={"Mutual Fund"}>
                                    Mutual Funds
                                  </MenuItem>
                                  <MenuItem value={"US NRI"}>US NRI</MenuItem>
                                  <MenuItem value={"PMS"}>PMS</MenuItem>
                                  <MenuItem value={"Stocks"}>Stocks</MenuItem>
                                  <MenuItem value={"Bonds"}>Bonds</MenuItem>
                                  <MenuItem value={"AlF"}>AlF</MenuItem>
                                </Select>
                              </FormControl>
                            </>
                          ) : (
                            <>
                              <FormControl
                                variant="standard"
                                sx={{ minWidth: 120 }}
                              >
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  value={productType}
                                  onChange={handleChangeMui}
                                >
                                  <MenuItem value={"All"}>All</MenuItem>
                                  <MenuItem value={"Equity"}>Equity</MenuItem>
                                  <MenuItem value={"Debt"}>Debt</MenuItem>
                                  <MenuItem value={"Alternate"}>
                                    Alternate
                                  </MenuItem>
                                  <MenuItem value={"Multi Asset"}>
                                    Multi Asset
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </>
                          )}
                        </div>
                      </div>

                      <div
                        className="tab-content card"
                        style={{
                          marginTop: "0.5rem",
                          border: "1px solid rgba(0,0,0,.125)",
                          borderRadius: "6px",
                          boxShadow:
                            "2px 2px 6px rgb(0 0 0 / 5%), -2px -2px 6px rgb(0 0 0 / 5%)",
                          minHeight: "300px",
                        }}
                      >
                        <div
                          role="tabpanel"
                          className="tab-pane fade show active"
                          id="views"
                        >
                          <table className="table table-hover">
                            <tr
                              style={{
                                height: "40px",
                                borderRadius: "10px",
                                borderBottom: "1px solid rgb(50, 50, 50)",
                                fontFamily: "Inter, sans-serif",
                                fontWeight: "700",
                              }}
                            >
                              <th
                                className=" product-reckoner-heading"
                                style={{ textAlign: "left" }}
                              >
                                Fund's Name
                              </th>

                              <th className=" product-reckoner-heading">
                                Product Type
                              </th>
                              <th className=" product-reckoner-heading">
                                Assets Type
                              </th>
                              <th className=" product-reckoner-heading">
                                3Y Returns
                              </th>
                              <th className=" product-reckoner-heading">
                                AUM (in ₹ Cr)
                              </th>
                              <th className=" product-reckoner-heading">
                                Expense
                              </th>
                            </tr>

                            {displayproductinsight}
                          </table>
                        </div>
                      </div>
                      <ReactPaginate
                        nextLabel={
                          <img
                            src={NextArrow}
                            alt="err"
                            style={{ width: "11px", height: "11px" }}
                          />
                        }
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"dashboard-pagination-buttons"}
                        previousLinkClassName={"dashboard-pagination-previous"}
                        nextLinkClassName={"dashboard-pagination-next"}
                        disabledClassName={"dashboard-pagination-disabled"}
                        activeClassName={"dashboard-pagination-active"}
                      />
                    </div>
                  </nav>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
