import React, { useState, useEffect } from "react";
import AfterloginApp from "../../../AfterloginApp";
// import InputClientBanner from "../../../../assets/img/input_client_banner.svg";
import ClientSidebar from "../../ClientSidebar";
import "../../../../static/css/existing/dashboard_styles.css";
import PortfolioInputSidebar from "./PortfolioInputSidebar";
import PortfolioInputRouter from "./PortfolioInputRouter";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  GetDashboardPortfolioList,
  GetReviewPortfolioSummary,
} from "../../../../actions/actions";
import { IoIosClose } from "react-icons/io";
export default function UpdateInputScreen(props) {
  const { familyMembers } = (props.location && props.location.state) || {};
  const portfolioValue = useSelector(
    (store) => store.dashboardPortfolioPriceList.total_portfolio_value
  );
  const portfolioUpload = useSelector(
    (store) => store.postClientInputUploadFile
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      GetDashboardPortfolioList({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      GetReviewPortfolioSummary({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    ); // eslint-disable-next-line
  }, [portfolioUpload]);

  const [route, setRoute] = useState("welcomescreen");
  const parsePortfolio = (portfolio) => {
    if (portfolio / 1000 < 100)
      return `₹ ${parseFloat(portfolio / 1000).toFixed(1)} K`;
    if (portfolio / 100000 < 100)
      return `₹ ${parseFloat(portfolio / 100000).toFixed(1)} L`;
    return `₹ ${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  };
  return (
    <div
      className="parentLoggedinScreen"
      id="client-details-investment-details-screen"
    >
      <AfterloginApp
        title={"Client Input"}
        description={
          "Browse for portfolio insights, actionable, cash flow planning, and much more"
        }
      />
      <div className="loggedinscreen-container" style={{ paddingBottom: 32 }}>
        <div className="loggedinscreen flex flex-row justify-between">
          <ClientSidebar />
          <div className=" p-[2rem] min-h-full bg-[#fff] rounded-[0.5rem]  border  client-input-parent-card w-[78%] ml-0 ">
            <div className="modal-component">
              <div className="portfolio-input-screen-modal-content">
                <div className="flex justify-between ">
                  <h2 className="text-[18px] font-inherit font-medium">
                    Total portfolio value: {parsePortfolio(portfolioValue)}
                  </h2>
                  <Link
                    to={{
                      pathname: "/clientinput/updateportfolios",
                      state: {
                        familyMembers: familyMembers,
                        clientId: localStorage.getItem("clientId"),
                      },
                    }}
                  >
                    <IoIosClose size={30} className="mr-4" />
                  </Link>
                </div>
                <div className="flex justify-between">
                  <PortfolioInputSidebar route={route} setRoute={setRoute} />
                  <div
                    style={{
                      padding: "0.9rem",
                      width: "78.55%",
                    }}
                    className="w-[79%] min-h-full"
                  >
                    <PortfolioInputRouter route={route} setRoute={setRoute} />
                  </div>
                </div>
                {/* {route !== "welcomescreen" &&
                route !== "uploadstatementscreen" &&
                route !== "uploadexcelscreen" && (
                  <div className="flex w-full mt-[32px] pr-[32px] justify-end">
                    <button
                      style={{ backgroundColor: "#1982F8", color: "#fff" }}
                      className="font-semibold py-2 px-3 rounded box-shadowed-button"
                    >
                      Submit
                    </button>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
