import React from "react";
import "../../../static/css/existing/dashboard_styles.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import productonepager_copy from "../../../assets/img/productonepager_copy.svg";
export const FundHeader = ({ fundData }) => {
  const fund_isni = "I090KU90";
  const size = Object.keys(fundData.json_field.performance).length;
  let dataLength;
  if (size) {
    dataLength = Object.keys(fundData.json_field.performance.data).length;
  } else {
    dataLength = null;
  }
  return (
    <div
      className="pt-[2rem] pr-[2rem] pb-[1.5rem] pl-[2rem] justify-between w-full bg-[#fff] flex "
      style={{
        borderRadius: " 8px 8px 0px 0px",
      }}
    >
      <div>
        <h3 className=" font-inter text-[42px] leading-[51px] tracking-[-3%] text-[#3D3D3D] font-medium not-italic">
          {fundData.json_field.fund_details.name}
        </h3>
        <div className="flex mt-[1rem] justify-between">
          <p className="font-inter text-[16px] font-light leading-[20px] pr-[2rem] border-r-[1px] border-[#c4c4c4] mb-[0] text-[#3d3d3d]">
            {fundData.json_field.fund_details.asset_type}
          </p>
          <p className="border-r-[1px] border-[#c4c4c4] font-inter text-[16px] font-light leading-[20px] pr-[2rem] text-[#3D3D3D] mb-0 pl-[2rem]">
            {fundData.json_field.fund_details.product_type}
          </p>
          <p className="border-r-[1px] border-[#c4c4c4] font-inter text-[16px] font-light leading-[20px] pr-[2rem] text-[#3D3D3D] mb-0 pl-[2rem]">
            {fundData.json_field.fund_details.category}
          </p>
          <p className="border-r-[1px] border-[#c4c4c4] font-inter text-[16px] font-light leading-[20px] pr-[2rem] text-[#3D3D3D] mb-0 pl-[2rem]">
            {fundData.json_field.fund_details.scheme_plan}
          </p>
          <p className="font-inter text-[#3d3d3d] font-light mb-0 text-[16px] pl-[2rem] leading-[20px]">
            {/* {fundData.final_data.isin}
             */}
            {fund_isni}
          </p>
          <span
            onClick={() => alert("Text Copied")}
            className="ml-[5px] mt-[2px] "
          >
            <CopyToClipboard text={fund_isni}>
              <img
                className="text-primary-200"
                src={productonepager_copy}
                alt="copy"
              />
            </CopyToClipboard>
          </span>
        </div>
      </div>
      <div>
        <div className="flex justify-around">
          <h3
            className="font-inter not-italic font-medium leading-[51px] ml-[3rem] mr-[1rem] text-[42px]"
            style={
              dataLength && fundData.json_field.performance.data["5y_cagr"] < 0
                ? {
                    color: "#FF0000",
                  }
                : {
                    color: "#00B031",
                  }
            }
          >
            {(!size && !dataLength) ||
            (size && !dataLength) ||
            (size &&
              dataLength &&
              !fundData.json_field.performance.data["5y_cagr"])
              ? "0%"
              : `${fundData.json_field.performance.data["5y_cagr"].toFixed(
                  2
                )}%`}
          </h3>
          <h3
            className="font-inter  not-italic font-medium leading-[51px] ml-[3rem] mr-[1rem] text-[42px]"
            style={
              dataLength && fundData.json_field.performance.data["5y_cagr"] < 0
                ? {
                    color: "#FF0000",
                  }
                : {
                    color: "#00B031",
                  }
            }
          >
            {(!size && !dataLength) ||
            (size && !dataLength) ||
            (size &&
              dataLength &&
              !fundData.json_field.performance.data["5y_cagr"])
              ? "0%"
              : `${fundData.json_field.performance.data["5y_cagr"].toFixed(
                  2
                )}%`}
          </h3>
        </div>
        <div className="flex justify-around">
          <p className="font-inter text-[14px] font-normal leading-[20px] ml-[2rem] text-[#6a6a76] mt-[0.5rem]">
            5Y Scheme CAGR
          </p>
          <p className="font-inter text-[14px] font-normal leading-[20px] ml-[2rem] text-[#6a6a76] mt-[0.5rem]">
            5Y Nifty 50 CAGR
          </p>
        </div>
      </div>
    </div>
  );
};
