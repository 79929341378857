import { GET_SIP_IPS_DATA } from "../actions/type";
const initialState = [
  { equity: 0, debt: 0, alternates: 0 },
  { large_cap: 0, mid_cap: 0, small_cap: 0 },
  { duration: 0 },
  { credit_risk: 0 },
  { alternates_low_vol: 0 },
];
export default function getSipIpsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SIP_IPS_DATA:
      return action.payload;
    default:
      return state;
  }
}
