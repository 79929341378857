import React, { useState } from "react";
import AfterloginApp from "../../AfterloginApp";
import InvestedgeGPTSidebar from "../InvestedgeGPTSidebar";
import Query from "./query";
import Select from "./select";

export default function Products() {
  const [screen, setScreen] = useState("products");
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp />
      <div className="loggedinscreen-container pt-0">
        <div className="loggedinscreen flex flex-row justify-between">
          <InvestedgeGPTSidebar />
          <div className="p-[1rem] bg-[#fff] rounded-[0.5rem] min-h-full client-input-parent-card mt-4 w-[79%] ml-0 relative">
            {screen === "products" ? (
              <Select
                setScreen={setScreen}
                productId={productId}
                setProductId={setProductId}
                setProductName={setProductName}
              />
            ) : (
              <Query productId={productId} productName={productName} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
