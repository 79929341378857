import React, { useRef, useState, useEffect } from "react";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import InvestedgeTextField from "../../components/textfield/InvestedgeTextField";
import Cloud from "../../assets/img/bluecloud.svg";
import Hourglass from "../../assets/img/wait.gif";
import Tick from "../../assets/img/module_access_type_tick.svg";
import urls from "../../urls";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { Bars } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { UpdateAcquireClientsAnalytics } from "../../actions/actions";

export default function CamsCas() {
  const history = useHistory();
  const { rmID } = useParams();
  const dispatch = useDispatch();
  const [logo, setLogo] = useState("");
  const [password, setPassword] = useState("");
  const [file, setFile] = useState(null);
  const [screen, setScreen] = useState("status");
  const camcasRef = useRef(null);
  const handleClick = async () => {
    setScreen("loading");
    let formdata = new FormData();
    formdata.append("file", file);
    formdata.append("password", password);
    await axios
      .post(`${urls.acquireClientsCamCasURL}/${rmID}/`, formdata)
      .then((response) => {
        dispatch(
          UpdateAcquireClientsAnalytics({
            ...response.data,
            ...response.data.portfolio,
          })
        );
        history.push({
          pathname: "/instant-review/analytics",
          state: { data: { ...response.data, rmID: rmID } },
        });
      })
      .catch(() => {});
  };

  useEffect(() => {
    axios
      .get(`${urls.acquireClientsGetLogoURL}/${rmID}/logo/`)
      .then((response) => {
        setLogo(response.data.org_logo_url);
      })
      .catch(() => {});
    axios
      .get(`${urls.acquireClientsLinkStatusURL}/${rmID}/`)
      .then((response) => {
        setScreen("data");
      })
      .catch(() => {
        setScreen("expired");
      }); //eslint-disable-next-line
  }, []);

  return screen === "expired" ? (
    <div className=" w-full flex-col justify-center items-center flex p-[32px] rounded-[8px] bg-white min-h-full">
      <h2>The link has expired !</h2>
    </div>
  ) : screen === "status" ? (
    <div className=" w-full flex-col justify-center items-center flex p-[32px] rounded-[8px] bg-white min-h-full">
      <Bars color="#1982F8" />
    </div>
  ) : screen === "data" ? (
    <div className="space-y-8 m-auto w-[90%] sm:w-3/4 md:w-1/3">
      <img
        style={{
          height: "39px",
          marginTop: "auto",
          marginBottom: "auto",
          objectFit: "contain",
        }}
        src={logo}
        alt=""
        className="img-fluid mx-auto"
      />
      <div className="space-y-2">
        <h2 className="card-heading text-center">Upload CAS Document</h2>
        <p className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0rem] text-center">
          Please Upload CAMS Account Statement to view Analytics on your
          Portfolio.
        </p>
      </div>
      <div
        onClick={() => camcasRef.current.click()}
        className="relative flex justify-center items-center flex-col w-1/3 sm:w-1/4 h-[100px] rounded-[10px]  cursor-pointer border border-globalBorderColor p-4 mx-auto"
      >
        {file ? (
          <>
            <img src={Tick} alt="err" className="mx-auto" />
            <p className="text-center text-xs mt-2">Uploaded</p>
          </>
        ) : (
          <>
            <img
              className="w-[25px] h-[25px] opacity-[0.9]"
              src={Cloud}
              alt="err"
            />
            <p className="text-center text-xs mt-2">Upload CAMS CAS</p>
          </>
        )}
      </div>
      <div className="w-full space-y-4">
        <InvestedgeTextField
          value={password}
          type="password"
          onChange={(e) => {
            // const re = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
            // if (e.target.value === "" || re.test(e.target.value)) {
            //   if (e.target.value.length) setError(null);
            //   else setError("Enter Valid PAN");
            //   setPassword(e.target.value);
            // }
            setPassword(e.target.value);
          }}
          label="Enter Password"
          fullWidth
        />
        <InvestedgeButton className="mt-6 text-sm" onClick={handleClick}>
          Submit
        </InvestedgeButton>
      </div>
      <input
        ref={camcasRef}
        className="hidden"
        onInput={() => {
          setFile(camcasRef.current.files[0]);
        }}
        type="file"
        accept=".pdf"
      />
    </div>
  ) : (
    <div className=" w-full flex-col justify-center items-center flex p-[32px] rounded-[8px] bg-white min-h-full">
      <img
        alt="err"
        src={Hourglass}
        className=" min-h-[250px] object-contain"
      />
      <h3 className="portfolio-stepper-sixth-form-status">
        Sometimes, great things take time.
      </h3>
      <h3 className=" font-inter text-[14px] font-medium leading-[16px] tracking-[0em] text-center">
        Your request is under process. This may take around 10 mins.
      </h3>
    </div>
  );
}
