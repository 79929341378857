// import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "../assets/css/style.css";
import Modal from "./Modal";
// import "../assets/vendor/icofont/icofont.min.css";
// import urls from "../urls.js";

function Footer() {
  // const form = useRef(null);
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const data = new FormData(form.current);
  //   console.log(data);
  //   fetch(urls.contactURL, {
  //     method: "POST",
  //     body: data,
  //   }).then((res) => console.log(res.json()));
  //   e.target.reset();
  // };

  return (
    <>
      <footer id="footer">
        <div className="footer-top">
          <div className="ml-5">
            <div className="row ml-lg-3">
              <div className="col-lg-3 col-md-6">
                <div className="footer-info">
                  <img
                    src="../assets/img/birr_whitelogo.png"
                    alt=""
                    className="footer-img"
                  ></img>
                  <br />
                  <p style={{ fontFamily: "Inter" }}>
                    A product by - <br />
                    Valiant Finsolutions Private Limited <br />
                    <br />
                    <br />
                  </p>
                  <div className="social-links mt-3">
                    <a
                      href="https://twitter.com/BeyondIRR_HQ"
                      className="twitter"
                    >
                      <i className="bx bxl-twitter"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/beyondirr"
                      className="linkedin"
                    >
                      <i className="bx bxl-linkedin"></i>
                    </a>
                    <a href="https://medium.com/@birr_hq" className="medium">
                      <i className="bx bxl-medium"></i>
                    </a>
                    <a
                      href="https://www.facebook.com/beyondirr"
                      className="facebook"
                    >
                      <i className="bx bxl-facebook"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/beyond_irr/"
                      className="youtube"
                    >
                      <i className="bx bxl-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4 style={{ fontFamily: "Inter" }}>Product</h4>
                <ul>
                  <li>
                    <Link to="/login">Login</Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4 style={{ fontFamily: "Inter" }}>Company</h4>
                <ul>
                  <li>
                    <HashLink to="/#team">Team</HashLink>
                  </li>
                  <li>
                    <a
                      style={{ fontFamily: "Inter" }}
                      href="https://peppermint-prose-e38.notion.site/BeyondIRR-is-Hiring-63983dd1646045949aeff63bee684422"
                    >
                      Careers
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ fontFamily: "Inter" }}
                      href={require("../pages/mediakit.pdf")}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Media kit
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4 style={{ fontFamily: "Inter" }}>Policies</h4>
                <ul>
                  <li style={{ fontFamily: "Inter" }}>
                    <Link to="/privacy">Privacy</Link>
                  </li>
                  <li style={{ fontFamily: "Inter" }}>
                    <Link to="/termsofuse">Terms Of Use</Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-newsletter">
                <h4 style={{ fontFamily: "Inter" }}>Join the waitlist</h4>
                <p style={{ fontFamily: "Inter" }}>
                  Email us at <strong>info@beyondirr.tech</strong> <br />
                  <br />
                  Or fill this form <br /> <br />
                  <button
                    className="btn btn-primary btn-get-started scrollto   mt-5 mb-5"
                    style={{
                      backgroundColor: "#95EAF7",
                      color: "#000",
                      fontSize: "13px",
                      fontWeight: "500",
                      paddingLeft: "2vw",
                      paddingRight: "2vw",
                      paddingTop: "0.9vw",
                      paddingBottom: "0.7vw",
                      borderRadius: "5px",
                      fontFamily: "Inter",
                    }}
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    JOIN THE WAITLIST
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p style={{ fontFamily: "Inter" }}>
            All rights reserved. <strong>&copy; 2022 BeyondIRR</strong>
          </p>
        </div>
      </footer>
      <Modal />
    </>
  );
}

export default Footer;
