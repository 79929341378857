import React, { useState } from "react";
import InvestedgeButtonHollow from "../../../components/buttons/InvestedgeButtonHollow";
import Add from "../../../assets/img/add_transaction.svg";
import MFTransactionRow from "./MFTransactionRow";
import AddTransaction from "./AddTransaction";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import InvestedgeTextField from "../../../components/textfield/InvestedgeTextField";
import { useSelector } from "react-redux";
import DisableSorted from "../../../assets/img/disabled_sort.svg";
import AscendingSorted from "../../../assets/img/ascending_sort.svg";
import DescendingSorted from "../../../assets/img/descending_sort.svg";
import Dropdown from "../../../components/Dropdowns/DropdownWhiteSingle";
export default function MFTransaction({
  products,
  setProducts,
  patchMfArray,
  setPatchMfArray,
  deleteArray,
  setDeleteArray,
  postMfArray,
  setPostMfArray,
  removeMFarray,
  submitActive,
  setSubmitActive,
  handleSubmit,
  search,
  setSearch,
  type,
  setType,
}) {
  const [modalOpen, setModelOpen] = useState(false);
  const today = new Date();
  const newTransaction = {
    transactionName: "PURCHASE",
    date: today.setDate(today.getDate() - 5),
    name: "",
    quantity: "",
    amount: "",
    nav: "",
    folio: "",
    advisor_name: "",
    description: "",
  };
  const { transactions } = useSelector((store) => store.dashboardMFPortfolio);
  const [sorted, setSorted] = useState({
    type: 0,
    name: 0,
    date: 0,
    quantity: 0,
    nav: 0,
    amount: 0,
    bal_quantity: 0,
  });

  const sortingIcon = (state) =>
    state === 0
      ? DisableSorted
      : state === 1
      ? AscendingSorted
      : DescendingSorted;

  const sorting_switch = (attribute, sortingType, products) => {
    switch (sortingType) {
      case 0:
        return type === "All"
          ? products
          : products.filter((item) => item.transaction_type === type);
      case 1:
        products.sort((a, b) => {
          const attributeA = isNaN(a[attribute])
            ? a[attribute].toUpperCase()
            : parseFloat(a[attribute]); // ignore upper and lowercase
          const attributeB = isNaN(b[attribute])
            ? b[attribute].toUpperCase()
            : parseFloat(b[attribute]); // ignore upper and lowercase

          if (attributeA < attributeB) {
            return -1;
          }
          if (attributeA > attributeB) {
            return 1;
          }
          return 0;
          // names must be equal
        });
        return type === "All"
          ? products
          : products.filter((item) => item.transaction_type === type);
      case 2:
        products.sort((a, b) => {
          const attributeA = isNaN(a[attribute])
            ? a[attribute].toUpperCase()
            : parseFloat(a[attribute]); // ignore upper and lowercase
          const attributeB = isNaN(b[attribute])
            ? b[attribute].toUpperCase()
            : parseFloat(b[attribute]); // ignore upper and lowercase

          if (attributeA > attributeB) {
            return -1;
          }
          if (attributeA < attributeB) {
            return 1;
          }
          return 0;
        });
        return type === "All"
          ? products
          : products.filter((item) => item.transaction_type === type);
      default:
        return type === "All"
          ? products
          : products.filter((item) => item.transaction_type === type);
    }
  };
  const sortedArray = (products) => {
    if (sorted.type)
      return sorting_switch("transaction_type", sorted.type, products);
    else if (sorted.name) return sorting_switch("name", sorted.name, products);
    else if (sorted.date) return sorting_switch("date", sorted.date, products);
    else if (sorted.quantity)
      return sorting_switch("units", sorted.quantity, products);
    else if (sorted.nav) return sorting_switch("nav", sorted.nav, products);
    else if (sorted.amount)
      return sorting_switch("amount", sorted.amount, products);
    else return sorting_switch("balance_units", sorted.bal_quantity, products);
  };
  return (
    <div className="mt-4">
      <div className="w-full flex justify-between items-center">
        <InvestedgeButtonHollow
          className="flex justify-center p-2"
          onClick={() => setModelOpen(true)}
        >
          <img src={Add} alt="err" />{" "}
          <p className="text-sm text-primary-200 ml-3">Add transaction</p>
        </InvestedgeButtonHollow>
        <div className="flex">
          <div className="w-[200px] mr-4">
            <Dropdown
              options={[
                "All",
                "PURCHASE",
                "PURCHASE_SIP",
                "REDEMPTION",
                "DIVIDEND_PAYOUT",
                "DIVIDEND_REINVEST",
                "SWITCH_IN",
                "SWITCH_IN_MERGER",
                "SWITCH_OUT",
                "SWITCH_OUT_MERGER",
                "STT_TAX",
                "STAMP_DUTY_TAX",
                "TDS_TAX",
                "SEGREGATION",
                "MISC",
                "UNKNOWN",
                "REVERSAL",
              ]}
              selected={type}
              setSelected={setType}
              labelName="Transaction Type"
            />
          </div>
          <InvestedgeTextField
            label="Search Fund"
            value={search}
            className="w-[200px]"
            onChange={(e) => {
              setSearch(e.target.value);
              setProducts(
                transactions.filter((transaction) =>
                  type === "All"
                    ? transaction.name
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    : transaction.name
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase()) &&
                      transaction.transaction_type === type
                )
              );
            }}
          />
        </div>
      </div>

      <div className="max-h-[60vh] h-[60vh] scrollablediv overflow-auto mt-4 pt-[-8px]">
        <table className="investedge-table w-full">
          <colgroup>
            <col width="10%" />
            <col width="27%" />
            <col width="10%" />
            <col width="13%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
          </colgroup>
          <tbody className="w-full">
            <tr
              className="rounded-[10px] h-12 font-inter font-medium border-b-[1px] border-b-[#6A6A76] sticky top-0 z-20 bg-white"
              style={{ paddingTop: "20px" }}
            >
              <th
                className="flex items-center justify-center"
                style={{ paddingLeft: "0rem" }}
              >
                Transaction Type
                <img
                  src={sortingIcon(sorted.type)}
                  onClick={() => {
                    setSorted({
                      type: (sorted.type + 1) % 3,
                      name: 0,
                      date: 0,
                      quantity: 0,
                      nav: 0,
                      amount: 0,
                      bal_quantity: 0,
                    });
                  }}
                  alt="err"
                  className="w-6 ml-1 cursor-pointer"
                />
              </th>
              <th className="text-start pl-2">
                <div className="flex items-center justify-between">
                  Name
                  <img
                    src={sortingIcon(sorted.name)}
                    onClick={() => {
                      setSorted({
                        type: 0,
                        name: (sorted.name + 1) % 3,
                        date: 0,
                        quantity: 0,
                        nav: 0,
                        amount: 0,
                        bal_quantity: 0,
                      });
                    }}
                    alt="err"
                    className="w-6 ml-1 cursor-pointer"
                  />
                </div>
              </th>
              <th>
                <div className="flex items-center justify-center">
                  Date
                  <img
                    src={sortingIcon(sorted.date)}
                    onClick={() => {
                      setSorted({
                        type: 0,
                        name: 0,
                        date: (sorted.date + 1) % 3,
                        quantity: 0,
                        nav: 0,
                        amount: 0,
                        bal_quantity: 0,
                      });
                    }}
                    alt="err"
                    className="w-6 ml-1 cursor-pointer"
                  />
                </div>
              </th>
              <th>
                <div className="flex items-center justify-center">
                  Quantity
                  <img
                    src={sortingIcon(sorted.quantity)}
                    onClick={() => {
                      setSorted({
                        type: 0,
                        name: 0,
                        date: 0,
                        quantity: (sorted.quantity + 1) % 3,
                        nav: 0,
                        amount: 0,
                        bal_quantity: 0,
                      });
                    }}
                    alt="err"
                    className="w-6 ml-1 cursor-pointer"
                  />
                </div>
              </th>
              <th>
                <div className="flex items-center justify-center">
                  NAV
                  <br /> (in ₹)
                  <img
                    src={sortingIcon(sorted.nav)}
                    onClick={() => {
                      setSorted({
                        type: 0,
                        name: 0,
                        date: 0,
                        quantity: 0,
                        nav: (sorted.nav + 1) % 3,
                        amount: 0,
                        bal_quantity: 0,
                      });
                    }}
                    alt="err"
                    className="w-6 ml-1 cursor-pointer"
                  />
                </div>
              </th>
              <th>
                <div className="flex items-center justify-center">
                  Amount <br /> (in ₹)
                  <img
                    src={sortingIcon(sorted.amount)}
                    onClick={() => {
                      setSorted({
                        type: 0,
                        name: 0,
                        date: 0,
                        quantity: 0,
                        nav: 0,
                        amount: (sorted.amount + 1) % 3,
                        bal_quantity: 0,
                      });
                    }}
                    alt="err"
                    className="w-6 ml-1 cursor-pointer"
                  />
                </div>
              </th>
              <th>
                <div className="flex items-center justify-center">
                  Bal <br /> Quantity
                  <img
                    src={sortingIcon(sorted.bal_quantity)}
                    onClick={() => {
                      setSorted({
                        type: 0,
                        name: 0,
                        date: 0,
                        quantity: 0,
                        nav: 0,
                        amount: 0,
                        bal_quantity: (sorted.bal_quantity + 1) % 3,
                      });
                    }}
                    alt="err"
                    className="w-6 ml-1 cursor-pointer"
                  />
                </div>
              </th>
              <th>Actions</th>
            </tr>
            {sortedArray(products).map((product, index) => (
              <MFTransactionRow
                key={product.id}
                product={product}
                products={products}
                setProducts={setProducts}
                patchMfArray={patchMfArray}
                setPatchMfArray={setPatchMfArray}
                deleteArray={deleteArray}
                setDeleteArray={setDeleteArray}
                postMfArray={postMfArray}
                setPostMfArray={setPostMfArray}
                removeMFarray={removeMFarray}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex w-full mt-[24px] pr-[24px] justify-end">
        <InvestedgeButton
          disabled={!submitActive}
          onClick={() => {
            if (
              patchMfArray.length ||
              postMfArray.length ||
              deleteArray.length
            ) {
              setSubmitActive(false);
              handleSubmit();
            }
          }}
        >
          Submit
        </InvestedgeButton>
      </div>
      <AddTransaction
        openModal={modalOpen}
        modalData={newTransaction}
        onClose={() => setModelOpen(false)}
        products={products}
        setProducts={setProducts}
        patchMfArray={patchMfArray}
        setPatchMfArray={setPatchMfArray}
        deleteArray={deleteArray}
        setDeleteArray={setDeleteArray}
        postMfArray={postMfArray}
        setPostMfArray={setPostMfArray}
        removeMFarray={removeMFarray}
      />
    </div>
  );
}
