import { GET_MARKET_CONVERSATION } from "../actions/type";
const initialState = [
  {
    id: "",
    question: "",
    answer: "",
    published_date: "",
    flag: true,
    tag: "",
  },
];
export default function MarketConversation(state = initialState, action) {
  switch (action.type) {
    case GET_MARKET_CONVERSATION:
      return action.payload;
    default:
      return state;
  }
}
