import React from "react";
import Header from "../header/Header";

function AfterloginApp({ title, description }) {
  return (
    <div>
      <Header title={title} description={description} />
    </div>
  );
}

export default AfterloginApp;
