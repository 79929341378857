import { UPDATE_SNACKBAR_PROPS } from "../actions/type";
const initialState = {
  open: false,
  severity: "",
  message: "",
};
export default function UpdateSnackbarProps(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SNACKBAR_PROPS:
      return action.payload;
    default:
      return state;
  }
}
