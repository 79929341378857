import { UPDATE_CLIENT_DATA } from "../actions/type";
const initialState = {
  accountType: "Family",
  clientType: "Client",
  familyName: "",
  rmID: "",
  Members: [
    {
      name: "",
      primary: true,
      relationship: "Self",
      fullName: "",
      dob: new Date(2000, 10, 10),
      gender: "Male",
      status: "Married",
      panNumber: "",
      occupation: "",
      sector: "",
      address: "",
      pincode: "",
      taxResidency: "Indian",
      residencyStatus: "Indian",
      nationality: "Indian",
      taxslab: "",
      surcharge: "0%",
      taxrate: "30%",
      currentftcl: "",
      currentstcl: "",
      carrystcl: "",
      carryftcl: "",
      currentftcldenotion: "₹ L",
      currentstcldenotion: "₹ L",
      carrystcldenotion: "₹ L",
      carryftcldenotion: "₹ L",
      investmentcorpus: "",
      investmentcorpusdenotion: "₹ L",
      investmenthorizon: "",
      riskscore: 50,
      age: 0,
      flexibility: "",
      raq1: 1,
      raq2: 1,
      raq3: 1,
      raq4: 1,
      raq5: 1,
      sumraq: 5,
      understanding: {
        mf: {
          checked: false,
          value: 3,
        },
        stocks: {
          checked: false,
          value: 3,
        },
        bonds: {
          checked: false,
          value: 3,
        },
        pms: {
          checked: false,
          value: 3,
        },
        aif: {
          checked: false,
          value: 3,
        },
        cash: {
          checked: false,
          value: 3,
        },
        deposit: {
          checked: false,
          value: 3,
        },
        gold: {
          checked: false,
          value: 3,
        },
        savingsschemes: {
          checked: false,
          value: 3,
        },
        realestate: {
          checked: false,
          value: 3,
        },
        epf: {
          checked: false,
          value: 3,
        },
        ppf: {
          checked: false,
          value: 3,
        },
        unlisted: {
          checked: false,
          value: 3,
        },
      },
      ips: {
        equity: {
          target: 0.4,
          flexibility: 0.1,
        },
        debt: {
          target: 0.5,
          flexibility: 0.04,
        },
        alternates: {
          target: 0.1,
          flexibility: 0.04,
        },
        large_cap: {
          target: 0.6,
          flexibility: 0.2,
        },
        mid_cap: {
          target: 0.3,
          flexibility: 0.2,
        },
        small_cap: {
          target: 0.1,
          flexibility: 0.1,
        },
        duration: {
          target: 3,
          flexibility: 1,
        },
        credit_risk: {
          target: 0.85,
          flexibility: 0.2,
        },
        alternates_low_vol: {
          target: 0.65,
          flexibility: 0.2,
        },
      },
      errors: {
        pannumber: null,
      },
    },
  ],
};

export default function ClientDataReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CLIENT_DATA:
      return action.payload;

    default:
      return state;
  }
}
