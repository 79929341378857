import React, { useState } from "react";
// import Edit from "../../../assets/img/edit_transaction.svg";
import Delete from "../../../assets/img/delete_transaction.svg";
import AddTransaction from "./AddTransaction";
import { useSelector } from "react-redux";

export default function MFTransactionRow({
  product,
  products,
  setProducts,
  patchMfArray,
  setPatchMfArray,
  deleteArray,
  setDeleteArray,
  postMfArray,
  setPostMfArray,
  removeMFarray,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const modalData = {
    ...product,
    transactionName: product.transaction_type,
    date: new Date(product.date),
    name: product.name,
    quantity: parseFloat(product.units),
    amount: parseInt(product.amount.toFixed(0)),
    nav: parseFloat(product.nav.toFixed(2)),
    folio: product.folio,
    advisor_name: product.advisor_name,
    description: product.description,
  };
  const { transactions } = useSelector((store) => store.dashboardMFPortfolio);
  var dd = String(new Date(product.date).getDate()).padStart(2, "0");
  var mm = String(new Date(product.date).getMonth() + 1).padStart(2, "0");
  var yyyy = new Date(product.date).getFullYear();
  return (
    <tr className="w-full h-12">
      <td style={{ paddingLeft: 0 }}>{product.transaction_type}</td>
      <td>{product.name}</td>
      <td className="text-center">{yyyy + "-" + mm + "-" + dd}</td>
      <td className="text-center">{parseFloat(product.units)}</td>
      <td className="text-center">
        {parseFloat(product.nav.toFixed(2)).toLocaleString("en-IN")}
      </td>
      <td className="text-center">
        {parseInt(product.amount.toFixed(0)).toLocaleString("en-IN")}
      </td>
      <td className="text-center">
        {parseFloat(product.balance_units.toFixed(2)).toLocaleString("en-IN")}
      </td>
      <td className="flex justify-center items-center h-11">
        {/* <img
          src={Edit}
          alt="err"
          className="cursor-pointer"
          onClick={() => setModalOpen(true)}
        /> */}
        <img
          src={Delete}
          alt="err"
          className="ml-3 cursor-pointer"
          onClick={() => {
            let newMfArray = [...products];
            let index = products.findIndex((pid) => pid.id === product.id);
            newMfArray.splice(index, 1);
            setProducts(newMfArray);
            //editing in patch mf array
            if (transactions.some((getFund) => getFund.id === product.id)) {
              let newPatchMfArray = [...patchMfArray];
              let patchindex = newPatchMfArray.findIndex(
                (pid) => pid.id === product.id
              );
              if (patchindex !== -1) newPatchMfArray.splice(patchindex, 1);
              setPatchMfArray(newPatchMfArray);
              setDeleteArray([...deleteArray, product]);
            } //editing in post mf array
            else {
              let newPostMfArray = [...postMfArray];
              let postindex = newPostMfArray.findIndex(
                (pid) => pid.id === product.id
              );
              newPostMfArray.splice(postindex, 1);
              setPostMfArray(newPostMfArray);
            }
          }}
        />
        <AddTransaction
          openModal={modalOpen}
          modalData={modalData}
          onClose={() => setModalOpen(false)}
          products={products}
          setProducts={setProducts}
          patchMfArray={patchMfArray}
          setPatchMfArray={setPatchMfArray}
          deleteArray={deleteArray}
          setDeleteArray={setDeleteArray}
          postMfArray={postMfArray}
          setPostMfArray={setPostMfArray}
          removeMFarray={removeMFarray}
        />
      </td>
    </tr>
  );
}
