import { PATCH_SETTINGS_TEAMLEAD } from "../actions/type";
const initialState = {};
export default function PatchTeamLead(state = initialState, action) {
  switch (action.type) {
    case PATCH_SETTINGS_TEAMLEAD:
      return action.payload;
    default:
      return state;
  }
}
