import React from "react";
import PortfolioDetailsRow from "./PortfolioDetailsRow";
// import AddMembers from "../../../assets/img/add_members.svg";
// import { Link } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { Bars } from "react-loader-spinner";
export default function UpdatePortfoliosContent({
  familyMembers,
  clientId,
  portfolioValue,
}) {
  // const postData = useSelector((state) => state.clientinputpostnew);
  return (
    <div>
      <h1 className="card-heading">Portfolio Details</h1>
      <div className="rounded-lg min-h-[335px] mt-[1rem] w-[70%] border border-globalBorderColor">
        <table className="w-full investedge-table">
          <colgroup>
            <col width="60%" />
            <col width="20%" />
            <col width="20%" />
          </colgroup>
          <tbody>
            <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
              <th className="pl-[1rem] text-left font-medium">Client Name</th>
              <th className="text-center font-medium">Portfolio Value</th>
              <th className="text-center font-medium">Portfolio</th>
            </tr>
            {familyMembers.map((member, index) => {
              return (
                <PortfolioDetailsRow
                  key={index}
                  member={member}
                  familyMembers={familyMembers}
                  clientId={clientId}
                  portfolioValue={portfolioValue}
                />
              );
            })}
          </tbody>
        </table>
      </div>

      {/* <div className="family-details-add-members">
        <img
          src={AddMembers}
          alt="err"
          style={{ width: "21px", height: "21px" }}
        />
        <Link
          to={{
            pathname: "/clientinput/familydetails",
            state: {
              familyMembers: familyMembers,
            },
          }}
        >
          <p style={{ marginLeft: "8px" }}>Add members</p>
        </Link>
      </div> */}
    </div>
  );
}
