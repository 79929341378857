import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetPopularProducts } from "../../actions/actions";
import "../../static/css/existing/dashboard_styles.css";
import InvestedgePagination from "../../components/pagination/InvestedgePagination";

export default function ProductCard() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetPopularProducts());
    // eslint-disable-next-line
  }, []);
  const popularProductsData = useSelector((state) => state.popularProducts);
  const [pageNumber, setPageNumber] = useState(0);
  const clientsPerPage = 3;
  const pagesVisited = pageNumber * clientsPerPage;
  const displayproducts = popularProductsData
    .slice(pagesVisited, pagesVisited + clientsPerPage)
    .map((product) => {
      return (
        <div
          className="w-full border border-[#e0dddd] rounded-[4px] flex p-[1rem] mt-[1.5rem] "
          key={product.name}
        >
          <div
            style={{
              maxWidth: "100%",
              wordBreak: "break-word",
            }}
          >
            <h3 className="font-inter text-[18px] not-italic font-medium leading-[22px] text-[#000] tracking-[0em] text-left">
              {product.name}
            </h3>
            <p className="mt-[0.5rem] font-inter text-[12px] not-italic font-normal leading-[16px] tracking-[0em] text-left text-[#000]">
              {product.description}
            </p>
            <div className="text-primary-200 bg-[#EDF5FE] rounded-[4px] py-[0.1rem] px-[1rem] font-inter text-[12px] not-italic font-normal leading-[16px] tracking-[0em] inline-block text-center mt-[19px]">
              {product.product_type}
            </div>
          </div>
        </div>
      );
    });
  const pageCount = Math.ceil(popularProductsData.length / clientsPerPage);
  const changePage = (event, value) => {
    setPageNumber(value - 1);
  };
  return (
    <>
      <div className="h-[430px]">{displayproducts}</div>
      <div className="flex justify-end absolute bottom-4 right-4">
        <InvestedgePagination
          count={pageCount}
          defaultPage={1}
          page={pageNumber + 1}
          onChange={changePage}
          color="primary"
        />
      </div>
    </>
  );
}
