import React, { useEffect } from "react";
import Add from "../../../../../assets/img/circle_over_plus_blue.svg";
import DepositsRow from "./DepositsRow";
import { useState } from "react";
import urls from "../../../../../urls";
import { useDispatch, useSelector } from "react-redux";
import {
  GetDashboardDepositsPortfolio,
  GetDashboardPortfolioList,
  OpenSnackbar,
} from "../../../../../actions/actions";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import InvestedgeButton from "../../../../../components/buttons/InvestedgeButton";
export default function DepositsScreen() {
  const data = useSelector((store) => store.dashboardDepositsPortfolio);
  const [productarray, setProductarray] = useState(
    JSON.parse(JSON.stringify(data)).map((y) => {
      return {
        ...y,
        last_amount_date: new Date(y.last_amount_date),
        current_amount_date: new Date(y.current_amount_date),
        maturity_date: new Date(y.maturity_date),
      };
    })
  );
  const [patchProductArray, setPatchProductArray] = useState([]);
  const [postProductArray, setPostProductArray] = useState([]);
  const [submitActive, setSubmitActive] = useState(false);
  const portfolioList = useSelector(
    (store) => store.dashboardPortfolioPriceList
  );
  const dispatch = useDispatch();
  const productsData = [
    "Normal FD",
    "Cumulative FD",
    "Non-Cumulative FD",
    "Bank Deposits",
    "Company Deposits",
    "Senior Citizen FD",
    "NRIs FD",
    "Tax Saving FD",
    "Flexi FD",
    "Standard FD",
    "EPF",
    "VPF",
    "PPF",
    "Small Savings",
  ];
  const removeProductarray = (i) => {
    let newAifarray = [...productarray];
    newAifarray.splice(i, 1);
    setProductarray(newAifarray);
  };
  // const portfolioValue = useSelector(
  //   (store) => store.portfolioReviewGetSumary.portfolio_value
  // );
  // const parsePortfolio = (portfolio) => {
  //   if (portfolio / 1000 < 100)
  //     return `₹ ${parseFloat(portfolio / 1000).toFixed(1)} K`;
  //   if (portfolio / 100000 < 100)
  //     return `₹ ${parseFloat(portfolio / 100000).toFixed(1)} L`;
  //   return `₹ ${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  // };
  const [searchBar, setSearchBar] = useState(false);
  const [addedProduct, setAddedProduct] = useState("");
  const [deleteArray, setDeleteArray] = useState([]);
  useEffect(() => {
    dispatch(
      GetDashboardDepositsPortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setProductarray(data);
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    if (
      deleteArray.length ||
      postProductArray.length ||
      patchProductArray.length
    )
      setSubmitActive(true);
    else setSubmitActive(false);
  }, [deleteArray, postProductArray, patchProductArray]);
  const numberOfDays = (date1, date2) => {
    // To calculate the time difference of two dates
    var difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    var difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
    return difference_In_Days;
  };
  const handleSubmit = async () => {
    let newPatchList = patchProductArray.map((item) => {
      var dd = String(item.last_amount_date.getDate()).padStart(2, "0");
      var mm = String(item.last_amount_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.last_amount_date.getFullYear();
      var dd1 = String(item.current_amount_date.getDate()).padStart(2, "0");
      var mm1 = String(item.current_amount_date.getMonth() + 1).padStart(
        2,
        "0"
      );
      var yyyy1 = item.current_amount_date.getFullYear();
      var dd2 = String(item.maturity_date.getDate()).padStart(2, "0");
      var mm2 = String(item.maturity_date.getMonth() + 1).padStart(2, "0");
      var yyyy2 = item.maturity_date.getFullYear();
      return {
        id: item.id,
        name: item.name,
        type: item.type,
        last_amount: parseFloat(item.last_amount),
        last_amount_date: yyyy + "-" + mm + "-" + dd,
        interest_rate: parseFloat(item.interest_rate),
        cred_rating: item.cred_rating,
        current_amount: parseFloat(
          parseInt(item.last_amount) *
            (1 + parseFloat(item.interest_rate / 100)) **
              (numberOfDays(
                new Date(item.current_amount_date),
                new Date(item.last_amount_date)
              ) /
                365)
        ),
        current_amount_date: yyyy1 + "-" + mm1 + "-" + dd1,
        maturity_date: yyyy2 + "-" + mm2 + "-" + dd2,
        user_id: localStorage.getItem("clientId"),
      };
    });
    let newPostList = postProductArray.map((item) => {
      var dd = String(item.last_amount_date.getDate()).padStart(2, "0");
      var mm = String(item.last_amount_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.last_amount_date.getFullYear();
      var dd1 = String(item.current_amount_date.getDate()).padStart(2, "0");
      var mm1 = String(item.current_amount_date.getMonth() + 1).padStart(
        2,
        "0"
      );
      var yyyy1 = item.current_amount_date.getFullYear();
      var dd2 = String(item.maturity_date.getDate()).padStart(2, "0");
      var mm2 = String(item.maturity_date.getMonth() + 1).padStart(2, "0");
      var yyyy2 = item.maturity_date.getFullYear();
      return {
        name: item.name,
        type: item.type,
        last_amount: parseFloat(item.last_amount),
        last_amount_date: yyyy + "-" + mm + "-" + dd,
        interest_rate: parseFloat(item.interest_rate),
        cred_rating: item.cred_rating,
        current_amount: parseFloat(
          parseInt(item.last_amount) *
            (1 + parseFloat(item.interest_rate / 100)) **
              (numberOfDays(
                new Date(item.current_amount_date),
                new Date(item.last_amount_date)
              ) /
                365)
        ),
        current_amount_date: yyyy1 + "-" + mm1 + "-" + dd1,
        maturity_date: yyyy2 + "-" + mm2 + "-" + dd2,
        user_id: localStorage.getItem("clientId"),
      };
    });
    let newDeleteList = deleteArray.map((item) => {
      var dd = String(item.last_amount_date.getDate()).padStart(2, "0");
      var mm = String(item.last_amount_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.last_amount_date.getFullYear();
      var dd1 = String(item.current_amount_date.getDate()).padStart(2, "0");
      var mm1 = String(item.current_amount_date.getMonth() + 1).padStart(
        2,
        "0"
      );
      var yyyy1 = item.current_amount_date.getFullYear();
      return {
        id: item.id,
        name: item.name,
        type: item.type,
        last_amount: parseFloat(item.last_amount),
        last_amount_date: yyyy + "-" + mm + "-" + dd,

        interest_rate: parseFloat(item.interest_rate),
        current_amount: parseFloat(
          parseInt(item.last_amount) *
            (1 + parseFloat(item.interest_rate / 100)) **
              (numberOfDays(
                new Date(item.current_amount_date),
                new Date(item.last_amount_date)
              ) /
                365)
        ),
        current_amount_date: yyyy1 + "-" + mm1 + "-" + dd1,
        user_id: localStorage.getItem("clientId"),
      };
    });
    newDeleteList.length &&
      (await axios.post(
        urls.dashboardDepositsPortfolioDeleteURL,
        newDeleteList
      ));
    newPostList.length &&
      (await axios.post(urls.dashboardDepositsPortfolioURL, newPostList));
    newPatchList.length &&
      (await axios.patch(urls.dashboardDepositsPortfolioURL, newPatchList));
    setPatchProductArray([]);
    setPostProductArray([]);
    setDeleteArray([]);
    dispatch(
      GetDashboardPortfolioList({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      GetDashboardDepositsPortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      OpenSnackbar({
        severity: "success",
        message: "Portfolio Updated Successfully",
      })
    );
  };
  return (
    <div className="w-full">
      <div className="font-medium font-inter text-[18px] text-neutralBlack">
        Deposits - {portfolioList.total_fixed_deposit_value}
      </div>
      <div className="flex justify-between w-[96%] mt-[4px] mb-0">
        <div className="flex justify-center items-center h-[56px]">
          <img src={Add} alt="err" />
          <p
            className="text-primary-200 text-[14px] font-medium mb-0 ml-[0.3rem]"
            onClick={
              () => setSearchBar(true)
              // let newAifarray = [...productarray];
              // newAifarray.unshift({ name: "New Mutual Fund", price: "0" });
              // setProductarray(newAifarray);
            }
          >
            Add Deposit
          </p>
          {searchBar && (
            <FormControl
              size="small"
              sx={{
                width: "200px",
                marginLeft: "1rem",
                "& .MuiInputBase-inputSizeSmall": {
                  paddingTop: "10px",
                  paddingBottom: "10px",
                },
              }}
            >
              <InputLabel>
                <span className="font-inter font-normal text-sm">
                  Choose Bank
                </span>
              </InputLabel>
              <Select
                label="Choose Bank"
                onChange={(e) => {
                  setSearchBar(false);
                  setAddedProduct(e.target.value.product_id);
                  let newAifarray = [...productarray];
                  newAifarray.unshift({
                    name: "",
                    type: e.target.value,
                    last_amount: parseFloat(0),
                    last_amount_date: new Date(),
                    interest_rate: parseFloat(0),
                    cred_rating: "C",
                    current_amount: parseFloat(0),
                    current_amount_date: new Date(),
                    maturity_date: new Date(),
                    user_id: localStorage.getItem("clientId"),
                  });
                  setPostProductArray([
                    ...postProductArray,
                    {
                      name: "",
                      type: e.target.value,
                      last_amount: parseFloat(0),
                      last_amount_date: new Date(),
                      interest_rate: parseFloat(0),
                      cred_rating: "C",
                      current_amount: parseFloat(0),
                      current_amount_date: new Date(),
                      maturity_date: new Date(),
                      user_id: localStorage.getItem("clientId"),
                    },
                  ]);
                  setProductarray(newAifarray);
                }}
                className="font-inter text-sm"
              >
                {productsData.map((option, ind) => (
                  <MenuItem value={option} id={ind} key={ind}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        {/* <p className="asset-handler-text" onClick={() => setProductarray([])}>
          Clear All
        </p> */}
      </div>
      <div className="update-portfolio-asset-row-container  h-[63vh]">
        {productarray.map((fund, index) => (
          <DepositsRow
            key={fund.id}
            fund={fund}
            id={index}
            removeProductarray={removeProductarray}
            productarray={productarray}
            setProductarray={setProductarray}
            patchProductArray={patchProductArray}
            setPatchProductArray={setPatchProductArray}
            deleteArray={deleteArray}
            setDeleteArray={setDeleteArray}
            data={data}
            postProductArray={postProductArray}
            setPostProductArray={setPostProductArray}
            addedProduct={addedProduct}
          />
        ))}
      </div>
      <div className="flex w-full mt-[24px] pr-[24px] justify-end">
        <InvestedgeButton
          disabled={!submitActive}
          onClick={() => {
            if (
              patchProductArray.length ||
              postProductArray.length ||
              deleteArray.length
            ) {
              setSubmitActive(false);
              handleSubmit();
            }
          }}
        >
          Submit
        </InvestedgeButton>
      </div>
    </div>
  );
}
