import { GET_ACQUIRE_CLIENTS_DASHBOARD } from "../actions/type";
const initialState = { loading: true, count: 0, results: [] };
export default function getAcquireClientsDashboard(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ACQUIRE_CLIENTS_DASHBOARD:
      return action.payload;
    default:
      return state;
  }
}
