import React, { useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import InvestedgeDatepicker from "../../components/datepicker/InvestedgeDatepicker";
import InvestedgeModal from "../../components/modal/InvestedgeModal";
import InvestedgeTextField from "../../components/textfield/InvestedgeTextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { MultiInputTimeRangeField } from "@mui/x-date-pickers-pro/MultiInputTimeRangeField";
import urls from "../../urls";
import axios from "axios";
export default function AddMeeting({
  modal,
  onClose,
  title,
  modelData,
  setMeetings,
}) {
  const [tempData, setTempData] = useState(modelData);
  const [errors, setErrors] = useState({ notes: "", topic: "" });
  const handleSave = () => {
    if (!tempData.topic || !tempData.meeting_note) {
      let tempErrors = { ...errors };
      if (!tempData.topic) tempErrors.topic = "This field is required";
      if (!tempData.meeting_note) tempErrors.notes = "This field is required";
      setErrors(tempErrors);
      return;
    }
    var dd = String(new Date(tempData.date).getDate()).padStart(2, "0");
    var mm = String(new Date(tempData.date).getMonth() + 1).padStart(2, "0");
    var yyyy = new Date(tempData.date).getFullYear();
    tempData.patch
      ? axios
          .patch(urls.dashboardPostMeetingsURL, {
            date: yyyy + "-" + mm + "-" + dd,
            start_time: tempData.time[0],
            end_time: tempData.time[1],
            location: "Mumbai",
            meeting_notes: tempData.meeting_note,
            actionables: [""],
            people_in_the_meeting: tempData.people,
            topic_of_the_meeting: tempData.topic,
            client_id: localStorage.getItem("clientId"),
            id: tempData.id,
          })
          .then((response) => {
            axios
              .get(urls.dashboardMeetingSummaryURL, {
                params: {
                  client_id: localStorage.getItem("clientId"),
                },
              })
              .then((response) => {
                setMeetings(response.data);
              })
              .catch((err) => console.log(err));
            onClose();
          })
          .catch((err) => console.log(err))
      : axios
          .post(urls.dashboardPostMeetingsURL, {
            date: yyyy + "-" + mm + "-" + dd,
            start_time: tempData.time[0],
            end_time: tempData.time[1],
            location: "Mumbai",
            meeting_notes: tempData.meeting_note,
            actionables: [""],
            people_in_the_meeting: tempData.people,
            topic_of_the_meeting: tempData.topic,
            client_id: localStorage.getItem("clientId"),
          })
          .then((response) => {
            axios
              .get(urls.dashboardMeetingSummaryURL, {
                params: {
                  client_id: localStorage.getItem("clientId"),
                },
              })
              .then((response) => {
                setMeetings(response.data);
              })
              .catch((err) => console.log(err));
            onClose();
          })
          .catch((err) => console.log(err));
  };

  useEffect(() => {
    setTempData(modelData);
  }, [modelData]);

  const return_date = (val) => {
    try {
      let temp = dayjs(new Date(val).toISOString());
      return temp;
    } catch (err) {
      return val;
    }
  };
  return (
    <InvestedgeModal
      aria-labelledby="Add Transaction Modal"
      aria-describedby="Add Transaction Modal"
      sx={{
        height: "82vh",
        margin: "auto",
        minWidth: "35vw",
        maxWidth: "35vw",
      }}
      open={modal}
      handleclose={onClose}
    >
      <div className="modal-header border-0 p-0 flex justify-between w-full">
        <h5 className="card-heading">
          {tempData.patch ? "Edit Meeting" : "Add Meeting"}
        </h5>
        <IoIosClose onClick={onClose} size={30} />
      </div>
      <InvestedgeTextField
        fullWidth
        sx={{
          fontFamily: "Inter,sans-sarif",
          fontWeight: 400,
          width: "100%",
          marginTop: "16px",
        }}
        label="Topic"
        type="text"
        value={tempData.topic}
        onChange={(e) => {
          e.target.value.length && setErrors({ ...errors, topic: "" });
          setTempData({ ...tempData, topic: e.target.value });
        }}
        error={errors.topic ? true : false}
        helperText={errors.topic ? errors.topic : ""}
      />
      <InvestedgeDatepicker
        format="DD/MM/YYYY"
        className="w-full mt-5"
        label="Date"
        value={return_date(tempData.date)}
        maxDate={dayjs(new Date().toISOString())}
        onChange={(date) => {
          setTempData({ ...tempData, date: new Date(date) });
        }}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        renderInput={(params) => <InvestedgeTextField {...params} />}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MultiInputTimeRangeField
          slotProps={{
            textField: ({ position }) => ({
              label: position === "start" ? "From" : "To",
            }),
          }}
          className="mt-5"
          label="Controlled field"
          value={tempData.time}
          onChange={(newValue) => setTempData({ ...tempData, time: newValue })}
          sx={{
            "& .MuiInputLabel-shrink": {
              top: 2,
            },
            "& .MuiFormHelperText-root": {
              position: "absolute",
              bottom: "-1.7em",
            },
            "& label": {
              fontFamily: "Inter",
              fontSize: 14,
            },
            "& .MuiOutlinedInput-root": {
              fontFamily: "Inter",
              fontSize: 14,
              lineHeight: "1.429em",
              "& input": {
                padding: "10px 14px",
                height: "inherit",
              },
            },
          }}
        />
      </LocalizationProvider>
      <InvestedgeTextField
        fullWidth
        sx={{
          fontFamily: "Inter,sans-sarif",
          fontWeight: 400,
          width: "100%",
          marginTop: "20px",
        }}
        label="Meeting Notes"
        type="text"
        value={tempData.meeting_note}
        onChange={(e) => {
          e.target.value.length && setErrors({ ...errors, notes: "" });
          setTempData({ ...tempData, meeting_note: e.target.value });
        }}
        error={errors.notes ? true : false}
        helperText={errors.notes ? errors.notes : ""}
        multiline
        rows={9}
      />
      <InvestedgeTextField
        fullWidth
        sx={{
          fontFamily: "Inter,sans-sarif",
          fontWeight: 400,
          width: "100%",
          marginTop: "20px",
        }}
        label="People in meeting"
        type="text"
        value={tempData.people}
        onChange={(e) => {
          setTempData({ ...tempData, people: e.target.value });
        }}
      />
      <div className="flex w-full justify-end">
        <InvestedgeButton className="mt-6" onClick={handleSave}>
          Save
        </InvestedgeButton>
      </div>
    </InvestedgeModal>
  );
}
