import { GET_REVIEW_PREVIOUS_HISTORY } from "../actions/type";
const initialState = { loading: true, results: [] };
export default function getReviewHistory(state = initialState, action) {
  switch (action.type) {
    case GET_REVIEW_PREVIOUS_HISTORY:
      return action.payload;
    default:
      return state;
  }
}
