import { POST_CLIENT_INPUT_PROFILE_PICTURE } from "../actions/type";
const initialState = {};
export default function PostClientProfilePicture(state = initialState, action) {
  switch (action.type) {
    case POST_CLIENT_INPUT_PROFILE_PICTURE:
      return action.payload;
    default:
      return state;
  }
}
