import React from "react";
let ScreenerData = [
  {
    fund_name: "DSP Flexi Cap Fund - Regular (Growth)",
    assets_type: "Equity",
    return: 2.32,
    aum: 3.29,
    expense: 12.13,
  },
  {
    fund_name: "DSP Flexi Cap Fund - Regular (Growth)",
    assets_type: "Equity",
    return: 2.32,
    aum: 3.29,
    expense: 12.13,
  },
  {
    fund_name: "DSP Flexi Cap Fund - Regular (Growth)",
    assets_type: "Equity",
    return: 2.32,
    aum: 3.29,
    expense: 12.13,
  },
  {
    fund_name: "DSP Flexi Cap Fund - Regular (Growth)",
    assets_type: "Equity",
    return: 2.32,
    aum: 3.29,
    expense: 12.13,
  },
  {
    fund_name: "DSP Flexi Cap Fund - Regular (Growth)",
    assets_type: "Equity",
    return: 2.32,
    aum: 3.29,
    expense: 12.13,
  },
];
export default function ScreenerTable() {
  const displayScreener = ScreenerData.map((ScreenerData, index) => {
    return (
      <tr key={index}>
        <td className="text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-4 tracking-[0em] p-4 text-left pl-4">
          {ScreenerData.fund_name}
        </td>
        <td className="text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-4 tracking-[0em] p-4 text-center">
          {ScreenerData.assets_type}
        </td>
        <td className="text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-4 tracking-[0em] p-4 text-center">
          {ScreenerData.return}
        </td>
        <td className="text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-4 tracking-[0em] p-4 text-center">
          {ScreenerData.aum}
        </td>
        <td className="text-[#3d3d3d] font-inter text-[14px] not-italic font-normal leading-4 tracking-[0em] p-4 text-center">
          {ScreenerData.expense}
        </td>
      </tr>
    );
  });
  return (
    <>
      <div className="tab-content card rounded-[6px] border border-primaryBlackborder shadow-primaryShadow">
        <div role="tabpanel" className="tab-pane fade show active" id="views">
          <table className="table table-hover">
            <tr className="h-[40px] rounded-[10px] border-b border-screenerTablebottom font-inter font-semibold">
              <th className="font-bold  font-inter not-italic text-[16px] text-[#323232] text-left">
                Fund's Name
              </th>
              <th className="font-bold  font-inter not-italic text-[16px] text-[#323232] text-center">
                Assets Type
              </th>
              <th className="font-bold  font-inter not-italic text-[16px] text-[#323232] text-center">
                3Y Returns
              </th>
              <th className="font-bold  font-inter not-italic text-[16px] text-[#323232] text-center">
                AUM (in ₹ Cr)
              </th>
              <th className="font-bold  font-inter not-italic text-[16px] text-[#323232] text-center">
                Expense
              </th>
            </tr>
            {displayScreener}
          </table>
        </div>
      </div>
    </>
  );
}
