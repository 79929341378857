import React from "react";
import UploadStatementCards from "./UploadStatementCards";
// import { useSelector } from "react-redux";
export default function WelcomeUpdateScreen({ setRoute, setStatementType }) {
  const handleCardClick = (e, name) => {
    setRoute("uploadstatementscreen");
    setStatementType(name);
  };
  // const portfolioValue = useSelector(
  //   (store) => store.portfolioReviewGetSumary.portfolio_value
  // );
  return (
    <>
      <div className="w-full h-[500px] flex items-center">
        <div className="flex flex-col justify-center items-center h-[90%] w-full">
          <p className=" font-inter text-[18px] text-neutralBlack mb-[1rem]">
            Create New Portfolio
          </p>
          <p className=" font-inter text-[14px] text-neutralBlack mb-[1rem]">
            You can upload the portfolio using the standard statements, manually
            edit them in each tab, or use a custom excel template
          </p>
          <div className="flex w-full justify-around items-center pt-[1rem]">
            <UploadStatementCards
              handleCardClick={handleCardClick}
              label={"CAMS CAS"}
              locked={false}
            />
            <UploadStatementCards
              handleCardClick={handleCardClick}
              label={"NSDL/CSDL CAS"}
              locked={true}
            />
            <UploadStatementCards
              handleCardClick={handleCardClick}
              label={"demat"}
              locked={true}
            />
            <UploadStatementCards
              handleCardClick={handleCardClick}
              label={"wealth account"}
              locked={true}
            />
          </div>
          <p className=" font-inter text-[14px] text-neutralBlack mb-[1rem] mt-[2rem]">
            Or
          </p>
          <p
            onClick={() => setRoute("uploadexcelscreen")}
            className=" font-inter text-[14px] text-primary-200 mb-[1rem] cursor-pointer"
          >
            Download and upload excel template
          </p>
        </div>
      </div>
      <div className="flex justify-end">
        <button
          className="primary-button text-sm my-0 mx-[32px] font-semibold py-[10px] px-2  shadow-boxShadowButton normal-case active:opacity-[0.8] focus:outline-none"
          // onClick={() => {
          //   dispatch(
          //     PostEventLoggingData({
          //       module_name: "Portfolio Insights",
          //       event_type: "Start button",
          //       url: window.location.href,
          //       note: `Create Pitchbook started`,
          //     })
          //   );
          // }}
          onClick={() => {
            setRoute("portfolioSelectionScreen");
          }}
        >
          Back
        </button>
      </div>
    </>
  );
}
