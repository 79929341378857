import React, { useEffect } from "react";
import Add from "../../assets/img/circle_over_plus_blue.svg";
import EstateRow from "./EstateRow";
import { useState } from "react";
import urls from "../../urls";
import { useDispatch, useSelector } from "react-redux";
import {
  GetDashboardRealEstatePortfolio,
  GetDashboardPortfolioList,
  OpenSnackbar,
} from "../../actions/actions";
import axios from "axios";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import { Bars } from "react-loader-spinner";
export default function EstateScreen() {
  const data = useSelector((store) => store.dashboardRealEstatePortfolio);
  const [productarray, setProductarray] = useState(
    JSON.parse(JSON.stringify(data)).map((y) => {
      return {
        ...y,
        investment_date: new Date(y.investment_date),
        current_price_date: new Date(y.current_price_date),
      };
    })
  );
  const [patchProductArray, setPatchProductArray] = useState([]);
  const [postProductArray, setPostProductArray] = useState([]);
  const [submitActive, setSubmitActive] = useState(false);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const removeProductarray = (i) => {
    let newProductArray = [...productarray];
    newProductArray.splice(i, 1);
    setProductarray(newProductArray);
  };
  const portfolioList = useSelector(
    (store) => store.dashboardPortfolioPriceList
  );
  // const portfolioValue = useSelector(
  //   (store) => store.portfolioReviewGetSumary.portfolio_value
  // );
  // const parsePortfolio = (portfolio) => {
  //   if (portfolio / 1000 < 100)
  //     return `₹ ${parseFloat(portfolio / 1000).toFixed(1)} K`;
  //   if (portfolio / 100000 < 100)
  //     return `₹ ${parseFloat(portfolio / 100000).toFixed(1)} L`;
  //   return `₹ ${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  // };
  const [addedProduct, setAddedProduct] = useState("");
  const [deleteArray, setDeleteArray] = useState([]);
  useEffect(() => {
    dispatch(
      GetDashboardRealEstatePortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setProductarray(data);
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    if (
      deleteArray.length ||
      postProductArray.length ||
      patchProductArray.length
    )
      setSubmitActive(true);
    else setSubmitActive(false);
  }, [deleteArray, postProductArray, patchProductArray]);

  const handleSubmit = async () => {
    setloading(true);
    let newPatchList = patchProductArray.map((item) => {
      var dd = String(item.investment_date.getDate()).padStart(2, "0");
      var mm = String(item.investment_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.investment_date.getFullYear();
      var dd1 = String(item.current_price_date.getDate()).padStart(2, "0");
      var mm1 = String(item.current_price_date.getMonth() + 1).padStart(2, "0");
      var yyyy1 = item.current_price_date.getFullYear();
      return {
        id: item.id,
        name: item.name,
        type: item.type,
        sub_type: item.sub_type,
        investment_price: parseFloat(item.investment_price),
        current_price: parseFloat(item.current_price),
        sector: item.sector,
        industry: item.industry,
        investment_date: yyyy + "-" + mm + "-" + dd,
        current_price_date: yyyy1 + "-" + mm1 + "-" + dd1,
        user_id: localStorage.getItem("clientId"),
      };
    });
    let newPostList = postProductArray.map((item) => {
      var dd = String(item.investment_date.getDate()).padStart(2, "0");
      var mm = String(item.investment_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.investment_date.getFullYear();
      var dd1 = String(item.current_price_date.getDate()).padStart(2, "0");
      var mm1 = String(item.current_price_date.getMonth() + 1).padStart(2, "0");
      var yyyy1 = item.current_price_date.getFullYear();
      return {
        name: item.name,
        type: item.type,
        sub_type: item.sub_type,
        investment_price: parseFloat(item.investment_price),
        current_price: parseFloat(item.current_price),
        sector: item.sector,
        industry: item.industry,
        investment_date: yyyy + "-" + mm + "-" + dd,
        current_price_date: yyyy1 + "-" + mm1 + "-" + dd1,
        user_id: localStorage.getItem("clientId"),
      };
    });
    let newDeleteList = deleteArray.map((item) => {
      var dd = String(item.investment_date.getDate()).padStart(2, "0");
      var mm = String(item.investment_date.getMonth() + 1).padStart(2, "0");
      var yyyy = item.investment_date.getFullYear();
      var dd1 = String(item.current_price_date.getDate()).padStart(2, "0");
      var mm1 = String(item.current_price_date.getMonth() + 1).padStart(2, "0");
      var yyyy1 = item.current_price_date.getFullYear();
      return {
        id: item.id,
        name: item.name,
        type: item.type,
        sub_type: item.sub_type,
        investment_price: parseFloat(item.investment_price),
        current_price: parseFloat(item.current_price),
        sector: item.sector,
        industry: item.industry,
        investment_date: yyyy + "-" + mm + "-" + dd,
        current_price_date: yyyy1 + "-" + mm1 + "-" + dd1,
        user_id: localStorage.getItem("clientId"),
      };
    });
    newDeleteList.length &&
      (await axios.post(
        urls.dashboardRealEstatePortfolioDeleteURL,
        newDeleteList
      ));
    newPostList.length &&
      (await axios.post(urls.dashboardRealEstatePortfolioURL, newPostList));
    newPatchList.length &&
      (await axios.patch(urls.dashboardRealEstatePortfolioURL, newPatchList));
    setPatchProductArray([]);
    setPostProductArray([]);
    setDeleteArray([]);
    dispatch(
      GetDashboardPortfolioList({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      GetDashboardRealEstatePortfolio({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      OpenSnackbar({
        severity: "success",
        message: "Portfolio Updated Successfully",
      })
    );
    setloading(false);
  };
  let emptyFields = false;
  for (let i = 0; i < productarray.length; i++)
    if (!productarray[i].name) emptyFields = true;
  return (
    <div className="w-full">
      <div className="font-medium font-inter text-[18px] text-neutralBlack">
        Real Estate - {portfolioList.total_real_estate_value}
      </div>
      {!loading ? (
        <>
          <div className="flex justify-between w-[96%] mt-[4px] mb-0">
            <div className="flex justify-center items-center h-[56px] cursor-pointer">
              <img src={Add} alt="err" />
              <p
                className="text-primary-200 text-[14px] font-medium mb-0 ml-[0.3rem]"
                onClick={() => {
                  let temp = Math.random();
                  setAddedProduct(temp);
                  let newProductArray = [...productarray];
                  newProductArray.unshift({
                    id: temp,
                    name: "",
                    type: "Residential",
                    sub_type: "Apartment",
                    investment_price: parseFloat(0),
                    current_price: parseFloat(0),
                    sector: "Energy",
                    industry: "Energy Equipment & Services",
                    investment_date: new Date(),
                    current_price_date: new Date(),
                    user_id: localStorage.getItem("clientId"),
                  });
                  setPostProductArray([
                    ...postProductArray,
                    {
                      id: temp,
                      name: "",
                      type: "Residential",
                      sub_type: "Apartment",
                      investment_price: parseFloat(0),
                      current_price: parseFloat(0),
                      sector: "Energy",
                      industry: "Energy Equipment & Services",
                      investment_date: new Date(),
                      current_price_date: new Date(),
                      user_id: localStorage.getItem("clientId"),
                    },
                  ]);
                  setProductarray(newProductArray);
                }}
              >
                Add Real Estate
              </p>
            </div>
            {/* <p className="asset-handler-text" onClick={() => setProductarray([])}>
          Clear All
        </p> */}
          </div>
          <div className="update-portfolio-asset-row-container  h-[63vh]">
            <table className="w-full">
              <tbody className="w-full">
                {productarray.map((fund, index) => (
                  <EstateRow
                    key={fund.id}
                    fund={fund}
                    id={index}
                    removeProductarray={removeProductarray}
                    productarray={productarray}
                    setProductarray={setProductarray}
                    patchProductArray={patchProductArray}
                    setPatchProductArray={setPatchProductArray}
                    deleteArray={deleteArray}
                    setDeleteArray={setDeleteArray}
                    data={data}
                    postProductArray={postProductArray}
                    setPostProductArray={setPostProductArray}
                    addedProduct={addedProduct}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="flex flex-col justify-center items-center w-[96%] h-[62vh]">
          <Bars color="#1982F8" />
          <p className=" font-inter text-[14px] font-medium text-neutralBlack mb-[1rem] mt-[1rem]">
            Updating Portfolio ...
          </p>
        </div>
      )}
      <div className="flex w-full mt-[24px] pr-[24px] justify-end">
        <InvestedgeButton
          disabled={!submitActive || emptyFields}
          onClick={() => {
            if (
              patchProductArray.length ||
              postProductArray.length ||
              deleteArray.length
            ) {
              setSubmitActive(false);
              handleSubmit();
            }
          }}
        >
          Submit
        </InvestedgeButton>
      </div>
    </div>
  );
}
