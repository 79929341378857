import { GET_MARKET_EXPERTS_CONSOLIDATED } from "../actions/type";
const initialState = {
  id: null,
  expert_name: "",
  type: "",
  published_date: null,
  flag: "",
  asset_preference: "",
  mcap_preference: "",
  sector_preference: "",
  credit_preference: "",
  duration_preference: "",
};
export default function MarketExpertsConsolidated(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_MARKET_EXPERTS_CONSOLIDATED:
      return action.payload;
    default:
      return state;
  }
}
