import React, { useState, useEffect } from "react";
import DisableSorted from "../../../../assets/img/disabled_sort.svg";
import AscendingSorted from "../../../../assets/img/ascending_sort.svg";
import DescendingSorted from "../../../../assets/img/descending_sort.svg";
import OverviewSection from "./OverviewSection";
import DebtSection from "./DebtSection";
import EquitySection from "./EquitySection";
import { useSelector, useDispatch } from "react-redux";
import {
  GetReviewPortfolioIaa,
  GetReviewPortfolioIpsData,
  PostEventLoggingData,
} from "../../../../actions/actions";
import InvestedgePagination from "../../../../components/pagination/InvestedgePagination";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
import AlternateSection from "./AlternateSection";
import Dropdown from "../../../../components/Dropdowns/DropdownWhiteSingle";
export default function PortfolioFirstStep({
  handleNext,
  formValue,
  portfolioProducts,
  setPortfolioProducts,
  clientId,
}) {
  const dispatch = useDispatch();
  const preferenceData = useSelector((state) => state.portfolioReviewGetSumary);
  const mergedpreferenceData = [
    ...preferenceData["MF"],
    ...preferenceData["aif"],
    ...preferenceData["bond"],
    ...preferenceData["cash_or_saving"],
    ...preferenceData["commodity"],
    ...preferenceData["fixed_deposit"],
    ...preferenceData["real_estate"],
    ...preferenceData["stock_portfolio"],
    ...preferenceData["unlisted"],
    ...preferenceData["offshore"],
    ...preferenceData["pms"],
  ];
  const [nameSorted, setNameSorted] = useState(0);
  const [amountSorted, setAmountSorted] = useState(0);
  const rationalizeProducts = (productsArray) => {
    let temp = Array.isArray(productsArray)
      ? productsArray.map((product) => {
          return {
            ...product,
            individual_asset_pct: (product.individual_asset_pct * 100).toFixed(
              1
            ),
          };
        })
      : [];

    return temp;
  };
  // const derationalizeProducts = (productsArray) => {
  //   let temp = productsArray.map((product) => {
  //     return {
  //       ...product,
  //       individual_asset_pct: product.individual_asset_pct / 100,
  //     };
  //   });

  //   return temp;
  // };
  const [parsedData, setParsedData] = useState(
    rationalizeProducts(
      portfolioProducts.length === 0 ? mergedpreferenceData : portfolioProducts
    )
  );
  useEffect(() => {
    if (preferenceData["MF"] && mergedpreferenceData.length > 0) {
      let tempArray = rationalizeProducts(mergedpreferenceData);
      let tempSum = 0;
      tempArray.forEach(
        (value) => (tempSum += parseFloat(value.individual_asset_pct))
      );
      tempSum = tempSum.toFixed(1);
      tempArray[0].individual_asset_pct =
        parseFloat(tempArray[0].individual_asset_pct) +
        (100.0 - parseFloat(tempSum));
      tempArray[0].individual_asset_pct =
        tempArray[0].individual_asset_pct.toFixed(1);
      setParsedData(tempArray);
    } // eslint-disable-next-line
  }, [preferenceData]);

  const analytics = useSelector((state) => state.portfolioReviewGetAnalytics);
  const [section, setSection] = useState("Overview");
  // const [afmodal, setAfmodal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const clientsPerPage = 10;
  const pagesVisited = pageNumber * clientsPerPage;
  var portfolioSum = 0;
  const addedFundsMap = {};
  parsedData.forEach((value) => {
    addedFundsMap[value.product_id] = true;
    portfolioSum += parseFloat(value.individual_asset_pct);
  });

  var portfolioValue = preferenceData ? preferenceData.portfolio_value : 0;
  var portfolioDenotion = "";
  const sortedArray = (array) => {
    if (nameSorted)
      switch (nameSorted) {
        case 0:
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_type === asset);
        case 1:
          array.sort((a, b) => {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
            // names must be equal
          });
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_type === asset);
        case 2:
          array.sort((a, b) => {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase

            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            return 0;
          });
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_type === asset);
        default:
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_type === asset);
      }
    else
      switch (amountSorted) {
        case 0:
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_type === asset);
        case 1:
          array.sort((a, b) => {
            const nameA = parseFloat(a.market_value); // ignore upper and lowercase
            const nameB = parseFloat(b.market_value); // ignore upper and lowercase

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
            // names must be equal
          });
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_type === asset);
        case 2:
          array.sort((a, b) => {
            const nameA = parseFloat(a.market_value); // ignore upper and lowercase
            const nameB = parseFloat(b.market_value); // ignore upper and lowercase

            if (nameA > nameB) {
              return -1;
            }
            if (nameA < nameB) {
              return 1;
            }
            return 0;
          });
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_type === asset);
        default:
          return asset === "All"
            ? array
            : array.filter((item) => item.asset_type === asset);
      }
  };
  const [asset, setAsset] = useState("All");
  const assetSet = new Set(parsedData.map((product) => product.asset_type));

  const pageCount = Math.ceil(parsedData.length / clientsPerPage);
  const changePage = (e, val) => {
    setPageNumber(val - 1);
  };
  // const handleCross = (id) => {
  //   delete addedFundsMap[id];
  //   let index = parsedData.findIndex((x) => x.product_id === id);
  //   let newParsedData = [...parsedData];
  //   newParsedData.splice(index, 1);
  //   setParsedData(newParsedData);
  // };
  const handleSections = () => {
    switch (section) {
      case "Overview":
        return <OverviewSection analytics={analytics} />;

      case "Debt":
        return <DebtSection analytics={analytics} />;

      case "Equity":
        return <EquitySection analytics={analytics} />;

      case "Alternate":
        return <AlternateSection analytics={analytics} />;
      default:
        console.log(section);
    }
  };
  // const handleWeightChange = (e, id) => {
  //   if (
  //     e.target.value <= 100 &&
  //     (!e.target.value.split(".")[1] ||
  //       e.target.value.split(".")[1].length <= 1)
  //   ) {
  //     let index = parsedData.findIndex((x) => x.product_id === id);
  //     let newProducts = [...parsedData];
  //     newProducts[index].individual_asset_pct = e.target.value;
  //     setParsedData(newProducts);
  //   }
  // };
  useEffect(() => {
    dispatch(
      PostEventLoggingData({
        module_name: "Portfolio Review",
        event_type: "Information",
        url: window.location.href,
        note: `Checked first screen ${section} Analytics`,
      })
    );
    // eslint-disable-next-line
  }, [section]);
  if (portfolioValue / 1000 < 100) {
    portfolioValue = parseFloat(portfolioValue / 1000).toFixed(1);
    portfolioDenotion = "K";
  } else if (portfolioValue / 100000 < 100) {
    portfolioValue = parseFloat(portfolioValue / 100000).toFixed(1);
    portfolioDenotion = "L";
  } else {
    portfolioValue = parseFloat(portfolioValue / 10000000).toFixed(1);
    portfolioDenotion = "C";
  }
  const parsePortfolio = (portfolio) => {
    if (portfolio / 1000 < 100)
      return `${parseFloat(portfolio / 1000).toFixed(1)} K`;
    if (portfolio / 100000 < 100)
      return `${parseFloat(portfolio / 100000).toFixed(1)} L`;
    return `${parseFloat(portfolio / 10000000).toFixed(1)} C`;
  };
  const displaydata = sortedArray(parsedData)
    .slice(pagesVisited, pagesVisited + clientsPerPage)
    .map((client, ind) => {
      return (
        <tr
          className="h-[40px] border-b border-primarytablebottomClr"
          key={ind}
        >
          <td className=" font-inter text-[#3d3d3d] text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-left pl-[1rem]">
            {client.name}
          </td>

          <td className=" font-inter text-[#3d3d3d] text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
            {`${client.asset_type} - ${client.product_type}`}
          </td>
          <td className=" font-inter text-[#3d3d3d] text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
            {parsePortfolio(client.market_value)}
          </td>
          <td className=" font-inter text-[#3d3d3d] text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
            {/* <input
              type="text"
              value={client.individual_asset_pct}
              style={{
                width: "100%",
                height: "25px",
                textAlign: "center",
                fontFamily: "Inter, sans-serif",
                fontSize: "14px",
                border: "1px solid #e5e5e5",
              }}
              onChange={(e) => handleWeightChange(e, client.product_id)}
            /> */}
            {client.individual_asset_pct}
          </td>
        </tr>
      );
    });
  const sortingIcon = (state) =>
    state === 0
      ? DisableSorted
      : state === 1
      ? AscendingSorted
      : DescendingSorted;
  return (
    <>
      <div className="px-8 rounded-[8px] w-full bg-white py-8 mt-[16px]">
        <div className="flex justify-between w-full">
          <h2 className="card-heading">Current Portfolio Summary</h2>
          <InvestedgeButton
            onClick={() => {
              dispatch(
                GetReviewPortfolioIaa({
                  client_id: clientId,
                })
              );
              dispatch(
                GetReviewPortfolioIpsData({
                  params: {
                    user_id: clientId,
                  },
                })
              );
              dispatch(
                PostEventLoggingData({
                  module_name: "Portfolio Review",
                  event_type: "Next Button",
                  url: window.location.href,
                  note: `First screen`,
                })
              );
              handleNext();
            }}
          >
            Next
          </InvestedgeButton>
        </div>
      </div>

      <div className="flex justify-between w-full">
        <div className="px-[16px] rounded-[8px] w-[52.5%] bg-white py-[16px] mt-4">
          <div className="flex justify-between items-center border-b border-[#e0dddd] w-full pb-2">
            <h3 className=" card-heading">Portfolio</h3>
            <div className="w-[25%]">
              <Dropdown
                options={["All", ...assetSet]}
                selected={asset}
                setSelected={setAsset}
                labelName="Asset Class"
              />
            </div>
          </div>
          {!analytics.loading && (
            <>
              <div className="w-full bg-[#fafafa] mt-[1rem] p-[1rem] flex rounded-[6px]">
                <div className="w-[20%] text-center border-r border-r-dividerColor">
                  <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0">
                    {`${(analytics.asset_allocation.Equity * 100).toFixed(1)}%`}
                  </h3>
                  <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                    Equity
                  </label>
                </div>
                <div className="w-[20%] text-center border-r border-r-dividerColor">
                  <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0">
                    {`${(analytics.asset_allocation.Debt * 100).toFixed(1)}%`}
                  </h3>
                  <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                    Debt
                  </label>
                </div>
                <div className="w-[20%] text-center border-r border-r-dividerColor">
                  <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0">
                    {`${(analytics.asset_allocation.Alternate * 100).toFixed(
                      1
                    )}%`}
                  </h3>
                  <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                    Alternates
                  </label>
                </div>
                <div className="w-[20%] text-center border-r border-r-dividerColor">
                  <h3 className=" font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#1982f8] mb-0">
                    {`₹ ${portfolioValue} ${portfolioDenotion}`}
                  </h3>
                  <label className=" font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                    Value
                  </label>
                </div>
                <div className="w-[20%] text-center">
                  <h3
                    className="font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center text-[#6a6a76] mb-0"
                    style={
                      Math.round(portfolioSum * 10) / 10 !== 100
                        ? { color: "red" }
                        : { color: "#00B031" }
                    }
                  >
                    {portfolioSum.toFixed(1)}%
                  </h3>
                  <label className="font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                    Total
                  </label>
                </div>
              </div>
              <div className="rounded-[6px] mt-[1rem] min-h-[680px]">
                <table className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] text-center py-[0.25rem] px-0 w-full h-full border-none shadow-none investedge-table">
                  <colgroup>
                    <col width="42%" />
                    <col width="22%" />
                    <col width="18%" />
                    <col width="18%" />
                  </colgroup>
                  <tbody>
                    <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                      <th
                        style={{
                          textAlign: "left",
                          paddingLeft: "1rem",
                        }}
                        className="font-inter text-[12px] font-medium leading-[20px] tracking-[0em] text-left py-[0.25rem] pl-[1rem] pr-0 flex items-center"
                      >
                        Name{" "}
                        <img
                          src={sortingIcon(nameSorted)}
                          onClick={() => {
                            setAmountSorted(0);
                            setNameSorted((nameSorted + 1) % 3);
                          }}
                          alt="err"
                          className="w-6 ml-2 cursor-pointer"
                        />
                      </th>
                      <th className="font-inter text-[12px] not-italic font-medium leading-[20px] tracking-[0em] text-center py-[4px]">
                        Asset Class/ <br />
                        Product Category
                      </th>
                      <th className="font-inter text-[12px] font-medium leading-[20px] tracking-[0em] text-center py-[0.25rem] px-0 flex items-center justify-center">
                        Amount
                        <br /> (in ₹){" "}
                        <img
                          src={sortingIcon(amountSorted)}
                          onClick={() => {
                            setNameSorted(0);
                            setAmountSorted((amountSorted + 1) % 3);
                          }}
                          alt="err"
                          className="w-6 ml-1 cursor-pointer"
                        />
                      </th>
                      <th className="font-inter text-[12px] not-italic font-medium leading-[20px] tracking-[0em] text-center py-[4px]">
                        Weight
                        <br /> (% of Total)
                      </th>
                    </tr>
                    {displaydata}
                  </tbody>
                </table>
              </div>
              <div className="w-full right-8 flex justify-end mt-4">
                <InvestedgePagination
                  count={pageCount}
                  defaultPage={1}
                  page={pageNumber + 1}
                  onChange={changePage}
                  color="primary"
                />
              </div>
            </>
          )}
        </div>
        <div className="rounded-[8px] w-[46%] bg-white p-[16px] mt-4 pt-8">
          <div className="flex justify-between w-full border-b border-[#e0dddd]">
            <div className="flex">
              <h3 className="card-heading">Analytics</h3>
              <p
                className={
                  section === "Overview"
                    ? "border-b-[2px] border-primary-200 ml-[2rem] font-medium mr-[1rem] text-[#3d3d3d] font-inter text-[12px]  leading-[31px] tracking-[0em] text-left  cursor-pointer"
                    : "font-inter text-[#6a6a76] text-[12px] leading-[31px] tracking-[0em] text-left  ml-[2rem] mr-[1rem]  cursor-pointer"
                }
                onClick={() => setSection("Overview")}
              >
                Overview
              </p>
              <p
                className={
                  section === "Equity"
                    ? "border-b-[2px] border-primary-200 ml-[2rem] font-medium mr-[1rem] text-[#3d3d3d] font-inter text-[12px] leading-[31px] tracking-[0em] text-left cursor-pointer "
                    : "font-inter text-[12px] text-[#6a6a76] leading-[31px] tracking-[0em] text-left  ml-[2rem] mr-[1rem]  cursor-pointer"
                }
                onClick={() => setSection("Equity")}
              >
                Equity
              </p>
              <p
                className={
                  section === "Debt"
                    ? "border-b-[2px] border-primary-200 ml-[2rem] font-medium mr-[1rem] text-[#3d3d3d] font-inter text-[12px] leading-[31px] tracking-[0em] text-left cursor-pointer"
                    : "font-inter text-[12px] text-[#6a6a76] leading-[31px] tracking-[0em] text-left  ml-[2rem] mr-[1rem]  cursor-pointer"
                }
                onClick={() => setSection("Debt")}
              >
                Debt
              </p>
              <p
                className={
                  section === "Alternate"
                    ? "border-b border-primary-200 ml-[2rem] text-primary-200 font-inter text-[12px] font-medium leading-[31px] tracking-[0em] text-left  my-0 mr-[1rem] cursor-pointer"
                    : "ml-[2rem] font-inter text-[12px] font-medium leading-[31px] tracking-[0em] text-[#6a6a76] text-left  my-0 mr-[1rem] cursor-pointer"
                }
                onClick={() => setSection("Alternate")}
              >
                Alternate
              </p>
            </div>
            {/* <div
              style={{
                display: "flex",
                cursor: "pointer",
                alignItems: "baseline",
                cursor: "pointer",
              }}
              onClick={() => {
                let temp = derationalizeProducts(parsedData);
                dispatch(GetAnalyticsAPI({ "Mutual Fund": temp }));
              }}
            >
              <img src={Reload} alt="err" style={{ width: "10px" }} />
              <p className="portfolio-stepper-fourth-form-reload-text">
                Refresh
              </p>
            </div> */}
          </div>
          {!analytics.loading && handleSections(section)}
        </div>
      </div>
    </>
  );
}
