import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import InvestedgeLogo from "../assets/img/investedge_header.svg";
import Illustration from "../assets/img/login_illustration.svg";
import "../assets/css/style.css";
// import "../assets/vendor/icofont/icofont.min.css";
import { useHistory } from "react-router-dom";
import BackToHome from "../assets/img/mobile_back_to_home.svg";
import api from "../api";
import {
  LoginUser,
  UserDetails,
  EmptyInvalidCredentials,
  GetSettingsAbout,
} from "../actions/actions";
import { useDispatch, useSelector } from "react-redux";
import WhiteLogo from "../assets/img/beyondirr_header_logo.png";
import { REACT_APP_development } from "../env";
function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const invalidCred = useSelector((state) => state.inavlidCredentials);
  const tokendata = useSelector((state) => state.tokendata);
  const [appState, setAppState] = useState({
    display: "hide",
    isLoggedIn: false,
    user: null,
    loading: false,
  });
  const [formState, setFormState] = useState({
    username: "",
    password: "",
    display: "show",
  });
  useEffect(() => {
    dispatch(EmptyInvalidCredentials());
    (async () => {
      setAppState({ ...appState, loading: true });
      let accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        try {
          const res = await api.getProtected();
          setAppState({
            ...appState,
            display: "show",
            isLoggedIn: true,
            user: res.data.user,
            loading: false,
          });
          setFormState({ ...formState, display: "hide" });
        } catch (error) {
          alert(error.response.data.error);
          setAppState({ ...appState, loading: false });
        }
      }
    })(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      dispatch(UserDetails(history));
      dispatch(GetSettingsAbout());
    }
    return () => {
      dispatch(EmptyInvalidCredentials());
    }; // eslint-disable-next-line
  }, [tokendata]);

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setAppState({ ...appState, loading: true });
    const { username, password } = formState;
    dispatch(
      LoginUser({ email: username, password: password }, appState, setAppState)
    );
  };
  return (
    <>
      <div
        className="p-[20px] bg-primarybg-300 md:hidden"
        style={{ boxShadow: "inset 0px -1px 0px #1F2032" }}
      >
        <div className="flex">
          <Link to="/" className="mt-[6px]">
            <img src={BackToHome} alt="err" />
          </Link>

          <div className="flex ml-[20px]">
            <img
              src={InvestedgeLogo}
              alt="logo"
              className="w-4"
              style={{
                filter:
                  "drop-shadow(0px 0px 40px #F02763) drop-shadow(0px 0px 24px #D74E77)",
              }}
            />
            <p className="font-montserrat font-sm leading-[21px] font-medium text-[#FCFCFD] ml-2 mb-0">
              InvestEdge
            </p>
          </div>
        </div>
      </div>
      <div
        className="min-w-[100vw] relative md:hidden bg-primarybg-300 p-[20px] pt-[32px]"
        style={{ minHeight: "calc(100vh - 61px)" }}
      >
        <p className="mb-0  text-xs font-montserrat  text-[#B1B5C4]">
          Username / Mobile Number
        </p>
        <input
          name="username"
          value={formState.username}
          onChange={(e) => handleChange(e)}
          type="email"
          placeholder="Enter your email"
          className="w-full h-[48px] px-3 py-[16px] rounded-xl bg-primarybg-100 border border-[#D9D9D9] focus:border-2 focus:border-primaryblue-200 placeholder:font-montserrat placeholder:text-[#B1B5C4] text-[#B1B5C4] mt-2"
        />
        <p className="mb-0  text-xs font-montserrat  text-[#B1B5C4] pt-[24px]">
          Password
        </p>
        <input
          type="password"
          name="password"
          title="Must contain at least one number and one  and lowercase letter, and at least 8 or more characters"
          required
          data-eye
          value={formState.password}
          onChange={(e) => handleChange(e)}
          placeholder="Enter your Password"
          className="w-full h-[48px] px-3 py-[16px] rounded-xl bg-primarybg-100 border border-[#D9D9D9] focus:border-2 focus:border-primaryblue-200 placeholder:font-montserrat placeholder:text-[#B1B5C4] text-[#B1B5C4] mt-2"
        />
        <div className="w-full flex justify-end  mt-[24px]">
          <Link
            to="/generateotp"
            exact="true"
            className="text-right text-[#B1B5C4] text-sm font-semibold leading-[24px] hover:text-[#FCFCF4] active:text-[#B1B5C4] decoration-[#FCFCF4]"
          >
            Forgot password?
          </Link>
        </div>
        {invalidCred === "Invalid credentials" ? (
          <div
            className="form-group mb-0"
            style={{
              fontSize: "13px",
              color: "red",
              textAlign: "center",
            }}
          >
            Invalid credentials
          </div>
        ) : (
          ""
        )}
        {invalidCred === "Password updated successfully !" ? (
          <div
            className="form-group mb-[0px]"
            style={{
              fontSize: "13px",
              color: "green",
            }}
          >
            Password updated successfully !
          </div>
        ) : (
          ""
        )}
        <button
          type="submit"
          className="bg-primaryblue-200 w-full text-center py-[12px] rounded-[90px] font-montserrat text-[#FCFCFD] text-sm  mt-[24px]"
          onClick={(e) => handleSubmit(e)}
        >
          Login
        </button>
        {REACT_APP_development !== "production" ? (
          <div className="flex justify-center items-center absolute bottom-0 w-full h-[40px] bg-transparent left-0">
            <Link
              to="/"
              exact="true"
              className="hover:text-[#FCFCF4] active:text-[#B1B5C4] decoration-[#FCFCF4]"
            >
              <p className="font-light text-sm text-[#9B9CA4] hover:text-[#FCFCF4]  mb-0">
                Home
              </p>
            </Link>
            <Link
              to="/about"
              exact="true"
              className="hover:text-[#FCFCF4] active:text-[#B1B5C4] decoration-[#FCFCF4]"
            >
              <p className="font-light text-sm text-[#9B9CA4] hover:text-[#FCFCF4] mb-0 ml-[16px]">
                About
              </p>
            </Link>
            <Link
              to="/terms"
              exact="true"
              className="hover:text-[#FCFCF4] active:text-[#B1B5C4] decoration-[#FCFCF4]"
            >
              <p className="font-light text-sm text-[#9B9CA4] hover:text-[#FCFCF4] mb-0 ml-[16px]">
                Terms of Use
              </p>
            </Link>
            <a
              href={process.env.REACT_APP_beyondirrhome}
              className="hover:text-[#FCFCF4] active:text-[#B1B5C4] decoration-[#FCFCF4]"
            >
              <p className="font-light text-sm text-[#9B9CA4] hover:text-[#FCFCF4] mb-0 ml-[16px]">
                BeyondIRR
              </p>
            </a>
            <a
              href={process.env.REACT_APP_investlane}
              className="hover:text-[#FCFCF4] active:text-[#B1B5C4] decoration-[#FCFCF4]"
            >
              <p className="font-light text-sm text-[#9B9CA4] hover:text-[#FCFCF4] mb-0 ml-[16px]">
                InvestLane
              </p>
            </a>
          </div>
        ) : null}
      </div>
      <div
        className="hidden md:flex justify-between md:fixed md:top-0 md:z-50 md:w-full md:px-20 md:py-5"
        style={{ boxShadow: "inset 0px -1px 0px #1F2032" }}
      >
        <a href={process.env.REACT_APP_beyondirrhome}>
          <img src={WhiteLogo} alt="err" className="h-[35px]" />
        </a>

        <Link to="/" className="flex w-[170px] items-center">
          <img
            src={InvestedgeLogo}
            alt="err"
            className="ml-6 w-5"
            style={{
              filter:
                "drop-shadow(0px 0px 40px #F02763) drop-shadow(0px 0px 24px #D74E77)",
            }}
          />
          <p className="font-montserrat font-medium text-[#FCFCFD] ml-3 mb-0">
            InvestEdge
          </p>
        </Link>
      </div>
      <div className="hidden bg-primarybg-300 min-h-full min-w-full pt-28 pb-10 px-20 md:flex justify-between relative">
        <div className="pt-[1%]">
          <p className="text-[#FCFCFD] font-montserrat font-medium text-2xl leading-[36px] pt-4 mb-0">
            Exceed Client Expectations,
            <br />
            Deliver Highest Quality Insights
          </p>
          <p className="font-montserrat text-sm leading-[21px] text-[#B1B5C4] mt-4">
            The fastest way for you to impress your wealth clients -<br />
            Access market views, product stories, and portfolio insights on
            InvestEdge <br /> and serve the clients better with your
            capabilities and deliverables.
          </p>
          <img src={Illustration} alt="err" className=" w-[80%]" />
        </div>
        <form
          className="bg-primarybg-100 min-h-full w-[40%] rounded-xl flex flex-col justify-center items-center px-[64px]"
          onSubmit={handleSubmit}
        >
          <h2 className="font-montserrat text-2xl leading-[36px] text-[#FCFCFD]">
            Login
          </h2>
          <div className="pt-6 w-full">
            <p className="mb-0  text-xs font-montserrat  text-[#B1B5C4]">
              Username / Mobile Number
            </p>
            <input
              name="username"
              value={formState.username}
              onChange={(e) => handleChange(e)}
              type="email"
              placeholder="Enter your email"
              className="w-full h-[48px] px-3 py-[16px] rounded-xl bg-primarybg-100 border border-[#D9D9D9] focus:border-2 focus:border-primaryblue-200 placeholder:font-montserrat placeholder:text-[#B1B5C4] text-[#B1B5C4] mt-2"
            />
          </div>
          <div className="pt-6 w-full">
            <p className="mb-0  text-xs font-montserrat  text-[#B1B5C4]">
              Password
            </p>
            <input
              type="password"
              name="password"
              title="Must contain at least one number and one  and lowercase letter, and at least 8 or more characters"
              required
              data-eye
              value={formState.password}
              onChange={(e) => handleChange(e)}
              placeholder="Enter your Password"
              className="w-full h-[48px] px-3 py-[16px] rounded-xl bg-primarybg-100 border border-[#D9D9D9] focus:border-2 focus:border-primaryblue-200 placeholder:font-montserrat placeholder:text-[#B1B5C4] text-[#B1B5C4] mt-2"
            />
          </div>

          <div className="w-full flex justify-end  my-[24px]">
            <Link
              to="/generateotp"
              exact="true"
              className="text-right text-[#B1B5C4] text-sm font-semibold leading-[24px] hover:text-[#FCFCF4] active:text-[#B1B5C4] decoration-[#FCFCF4]"
            >
              Forgot password?
            </Link>
          </div>
          {invalidCred === "Invalid credentials" ? (
            <div
              className="form-group mb-4"
              style={{
                fontSize: "13px",
                color: "red",
                textAlign: "center",
              }}
            >
              Invalid credentials
            </div>
          ) : (
            ""
          )}
          {invalidCred === "Password updated successfully !" ? (
            <div
              className="form-group mb-4"
              style={{
                fontSize: "13px",
                color: "green",
              }}
            >
              Password updated successfully !
            </div>
          ) : (
            ""
          )}
          <button
            type="submit"
            className="bg-primaryblue-200 w-full text-center py-[16px] rounded-[90px] font-montserrat text-[#FCFCFD] "
          >
            Login
          </button>
        </form>
        {REACT_APP_development !== "production" ? (
          <div className="hidden md:flex justify-center items-center absolute bottom-0 w-full h-[40px] bg-transparent left-[-20px]">
            <Link
              to="/"
              exact="true"
              className="hover:text-[#FCFCF4] active:text-[#B1B5C4] decoration-[#FCFCF4]"
            >
              <p className="font-light text-sm text-[#9B9CA4] hover:text-[#FCFCF4]  mb-0">
                Home
              </p>
            </Link>
            <Link
              to="/about"
              exact="true"
              className="hover:text-[#FCFCF4] active:text-[#B1B5C4] decoration-[#FCFCF4]"
            >
              <p className="font-light text-sm text-[#9B9CA4] hover:text-[#FCFCF4] mb-0 ml-[16px]">
                About
              </p>
            </Link>
            <Link
              to="/terms"
              exact="true"
              className="hover:text-[#FCFCF4] active:text-[#B1B5C4] decoration-[#FCFCF4]"
            >
              <p className="font-light text-sm text-[#9B9CA4] hover:text-[#FCFCF4] mb-0 ml-[16px]">
                Terms of Use
              </p>
            </Link>
            <a
              href={process.env.REACT_APP_beyondirrhome}
              className="hover:text-[#FCFCF4] active:text-[#B1B5C4] decoration-[#FCFCF4]"
            >
              <p className="font-light text-sm text-[#9B9CA4] hover:text-[#FCFCF4] mb-0 ml-[16px]">
                BeyondIRR
              </p>
            </a>
            <a
              href={process.env.REACT_APP_investlane}
              className="hover:text-[#FCFCF4] active:text-[#B1B5C4] decoration-[#FCFCF4]"
            >
              <p className="font-light text-sm text-[#9B9CA4] hover:text-[#FCFCF4] mb-0 ml-[16px]">
                InvestLane
              </p>
            </a>
          </div>
        ) : null}
      </div>
    </>
    // <section
    //   style={{
    //     backgroundColor: "#FFFEF1",
    //     padding: "0rem 0rem",
    //     height: "100vh",
    //     width: "100%",
    //     display: "flex",
    //   }}
    // >
    //   <img src={WomenImage} />
    //   <div
    //     style={{
    //       width: "60%",
    //       height: "100%",
    //     }}
    //   >
    //     <div
    //       style={{
    //         height: "95%",
    //         display: "flex",
    //         flexDirection: "column",
    //         justifyContent: "center",
    //         alignItems: "center",
    //       }}
    //     >
    //       <div className="login-screen-card">
    //         <Link to="/">
    //           <img
    //             src={Logo}
    //             alt="err"
    //             style={{ height: "60px", objectFit: "contain" }}
    //           />
    //         </Link>

    //         <div style={{ width: "95%", marginTop: "1rem" }}>
    //           <form className="my-login-validation">
    //             <div className="form-group">
    //               <label htmlFor="email" className="login-screen-label">
    //                 EMAIL ID
    //               </label>
    //               <input
    //                 id="username"
    //                 className="form-control"
    //                 name="username"
    //                 value={formState.username}
    //                 onChange={(e) => handleChange(e)}
    //                 type="email"
    //                 required
    //                 autoFocus
    //               ></input>
    //             </div>

    //             <div className="form-group" style={{ marginTop: "2rem" }}>
    //               <label htmlFor="password" className="login-screen-label">
    //                 PASSWORD
    //               </label>
    //               <input
    //                 id="password"
    //                 type="password"
    //                 className="form-control"
    //                 name="password"
    //                 pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
    //                 title="Must contain at least one number and one  and lowercase letter, and at least 8 or more characters"
    //                 required
    //                 data-eye
    //                 value={formState.password}
    //                 onChange={(e) => handleChange(e)}
    //               ></input>
    //             </div>

    //             <div
    //               className="form-group m-0 mb-3"
    //               style={{
    //                 display: "flex",
    //                 justifyContent: "center",
    //                 alignItems: "center",
    //                 width: "100%",
    //               }}
    //             >
    //               <button
    //                 type="submit"
    //                 className="login-screen-login-button"
    //                 onClick={(e) => handleSubmit(e)}
    //               >
    //                 Login
    //               </button>
    //             </div>
    // {invalidCred === "Invalid credentials" ? (
    //   <div
    //     className="form-group mb-0"
    //     style={{
    //       fontSize: "13px",
    //       color: "red",
    //       textAlign: "center",
    //     }}
    //   >
    //     Invalid credentials
    //   </div>
    // ) : (
    //   ""
    // )}
    // {invalidCred === "Password updated successfully !" ? (
    //   <div
    //     className="form-group mb-0"
    //     style={{
    //       fontSize: "13px",
    //       color: "green",
    //     }}
    //   >
    //     Password updated successfully !
    //   </div>
    // ) : (
    //   ""
    // )}

    //             <div className="mt-1 text-left" style={{ width: "100%" }}>
    //               <div className="login-screen-forget-password">
    //                 <Link
    //                   to="/generateotp"
    //                   exact="true"
    //                   style={{
    //                     color: "#000",
    //                   }}
    //                 >
    //                   Forgot password?
    //                 </Link>
    //               </div>
    //             </div>
    //             <div className="text-left" style={{ width: "100%" }}>
    //               <div
    //                 className="login-screen-forget-password"
    //                 style={{ marginTop: "0.75rem" }}
    //               >
    //                 Want an early access?{" "}
    //                 <span data-toggle="modal" data-target="#exampleModal">
    //                   Join the waitlist.
    //                 </span>
    //               </div>
    //             </div>
    //           </form>
    //         </div>
    //         <p className="login-screen-card-footer-text">
    //           This site is protected and the Privacy Policy and Terms of
    //           Service apply.
    //         </p>
    //       </div>
    //     </div>
    //     <div
    //       style={{
    //         width: "100%",
    //         display: "flex",
    //         justifyContent: "center",
    //       }}
    //     >
    //       <div
    //         style={{
    //           width: "45%",
    //           display: "flex",
    //           justifyContent: "space-between",
    //         }}
    //       >
    //         {footerLinks.map((item, index) => {
    //           if (item.name === "Team")
    //             return (
    //               <HashLink
    //                 className="login-screen-footer-elements"
    //                 to="/#team"
    //                 key={index}
    //               >
    //                 {item.name}
    //               </HashLink>
    //             );
    //           else if (item.name !== "Careers")
    //             return (
    //               <Link
    //                 to={item.link}
    //                 className="login-screen-footer-elements"
    //                 key={index}
    //               >
    //                 {item.name}
    //               </Link>
    //             );
    //           else
    //             return (
    //               <a
    //                 href={item.link}
    //                 target="_blank"
    //                 className="login-screen-footer-elements"
    //                 key={index}
    //               >
    //                 {item.name}
    //               </a>
    //             );
    //         })}
    //       </div>
    //     </div>
    //   </div>
    // </section>
  );
}

export default Login;
