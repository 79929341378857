import { GET_ACQUIRE_CLIENTS_ANALYTICS } from "../actions/type";
const initialState = {
  "Mutual Fund": [],
};
export default function GetAcquireClientsAnalytics(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ACQUIRE_CLIENTS_ANALYTICS:
      return action.payload;
    default:
      return state;
  }
}
