import React from "react";
import "../../../static/css/existing/dashboard_styles.css";
import ArrowDown from "../../../assets/img/down_arrow_icon.svg";

export default function faqComponent() {
  return (
    <>
      <div
        style={{
          padding: "1rem",
          backgroundColor: "#fff",
          borderRadius: "6px",
          boxShadow:
            " 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(0, 0, 0, 0.05)",
          minheight: "274px",
          marginTop: "1.5rem",
        }}
      >
        <div className="accordion" id="accordionExample">
          <div
            className="accordion-item bg-white border border-gray-200"
            style={{ justifyContent: "space-between" }}
          >
            <div
              className="faq-question"
              style={{
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <h2 className="accordion-header mb-0" id="headingOne">
                <button
                  style={{ fontFamily: "Inter , sans-serif" }}
                  className="
                      
          accordion-button
          relative
          flex
          items-center
          w-full
          py-2
          px-3
          text-base text-gray-800 text-left
          bg-white
          border-0
          rounded-none
          transition
          focus:outline-none
        "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Which type of investors should invest in this product?
                </button>
              </h2>
              <img
                style={{ padding: "1rem" }}
                src={ArrowDown}
                alt="ArrowDown"
              />
            </div>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <p className="accordion-body  py-2 px-3 onepager-description-text">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum
              </p>
            </div>
          </div>

          {/* 2nd start  */}
          <div
            className="accordion-item bg-white border border-gray-200"
            style={{ justifyContent: "space-between" }}
          >
            <div
              className="faq-question"
              style={{
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <h2 className="accordion-header mb-0" id="headingOne">
                <button
                  style={{ fontFamily: "Inter , sans-serif" }}
                  className="
                 
                  accordion-button
                  relative
                  flex
                  items-center
                  w-full
                  py-2
                  px-3
                  text-base text-gray-800 text-left
                  bg-white
                  border-0
                  rounded-none
                  transition
                  focus:outline-none
        "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Which type of investors should invest in this product?
                </button>
              </h2>
              <img
                style={{ padding: "1rem" }}
                src={ArrowDown}
                alt="ArrowDown"
              />
            </div>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <p className="accordion-body  py-2 px-3 onepager-description-text">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum
              </p>
            </div>
          </div>

          <div
            className="accordion-item bg-white border border-gray-200"
            style={{ justifyContent: "space-between" }}
          >
            <div
              className="faq-question"
              style={{
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <h2 className="accordion-header mb-0" id="headingOne">
                <button
                  style={{ fontFamily: "Inter , sans-serif" }}
                  className="
                 
          accordion-button
          relative
          flex
          items-center
          w-full
          py-2
          px-3
          text-base text-gray-800 text-left
          bg-white
          border-0
          rounded-none
          transition
          focus:outline-none
        "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                >
                  Which type of investors should invest in this product?
                </button>
              </h2>
              <img
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                // aria-expanded="true"
                aria-controls="collapseThree"
                style={{ padding: "1rem" }}
                src={ArrowDown}
                alt="ArrowDown"
              />
            </div>
            <div
              id="collapseThree"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <p className="accordion-body  py-2 px-3 onepager-description-text">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
