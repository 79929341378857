import axios from "axios";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Bars } from "react-loader-spinner";
// import InvestedgeToggleButtonGroup from "../../../components/toggle/InvestedgeToggle";
import urls from "../../urls";
import AskMeAnything from "./AskMeAnything";
import MeetingSummary from "./MeetingSummary";
import PortfolioReview from "./PortfolioReview";
import Clientsidebar from "../ClientSidebar";
import Copy from "../../assets/img/copytoclipboard.svg";
import parse from "html-react-parser";
import AfterloginApp from "../../rmdashboard/AfterloginApp";
export default function BeyondAI() {
  const [meetings, setMeetings] = useState([]);
  const [meeting, setMeeting] = useState({});
  // const toggletabs = [
  //   { label: "Meeting Summary", value: "Meeting Summary" },
  //   { label: "Portfolio Review", value: "Portfolio Review" },
  //   { label: "Ask Me Anything", value: "Ask Me Anything" },
  // ];
  // const [pilot, setPilot] = useState("Portfolio Review");
  const pilot = "Portfolio Review";
  // const handleTabClick = (e, val) => {
  //   if (val) {
  //     setPilot(val);
  //   }
  // };
  const handlePilot = (pilot) => {
    switch (pilot) {
      case "Meeting Summary":
        return (
          <MeetingSummary
            meetings={meetings}
            meeting={meeting}
            setMeeting={setMeeting}
            meetingSummaryQuery={meetingSummaryQuery}
            setMeetingSummaryQuery={setMeetingSummaryQuery}
            handleMeetingSummary={handleMeetingSummary}
          />
        );
      case "Portfolio Review":
        return (
          <PortfolioReview
            portfolioReviewQuery={portfolioReviewQuery}
            setPortfolioReviewQuery={setPortfolioReviewQuery}
            handlePortfolioReview={handlePortfolioReview}
            portfolioReviewResponse={portfolioReviewResponse}
          />
        );
      case "Ask Me Anything":
        return (
          <AskMeAnything
            askMeAnythingQuery={askMeAnythingQuery}
            setAskMeAnythingQuery={setAskMeAnythingQuery}
            handleAskAnything={handleAskAnything}
          />
        );
      default:
        return null;
    }
  };

  const [meetingSummaryQuery, setMeetingSummaryQuery] = useState("");
  const [portfolioReviewQuery, setPortfolioReviewQuery] = useState("");
  const [askMeAnythingQuery, setAskMeAnythingQuery] = useState("");
  const [meetingSummaryResponse, setMeetingSummaryResponse] = useState({
    summarised: "",
  });
  const [portfolioReviewResponse, setPortfolioReviewResponse] = useState({
    portfolio_analysis: "empty",
  });
  const [askMeAnythingResponse, setAskMeAnythingResponse] = useState({
    answer_anything: "",
  });

  useEffect(() => {
    axios
      .get(urls.dashboardMeetingSummaryURL, {
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
      .then((response) => {
        setMeetings(response.data);
        setMeeting(response.data[0]);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleMeetingSummary = async () => {
    // axios
    //   .get(urls.dashboardMeetingSummaryURL, {
    //     params: {
    //       client_id: localStorage.getItem("clientId"),
    //       date: meeting.date,
    //       query: meetingSummaryQuery,
    //       id: meeting.id,
    //     },
    //   })
    //   .then((response) => {
    //     setMeetingSummaryResponse({
    //       summarised: response.data.summarised.replaceAll("\n", "<br />"),
    //     });
    //   })
    //   .catch((err) => console.log(err));
    try {
      setMeetingSummaryResponse({ summarised: "loading" });
      const response = await fetch(
        `${urls.dashboardMeetingSummaryURL}?client_id=${localStorage.getItem(
          "clientId"
        )}&query=${meetingSummaryQuery}&date=${meeting.date}&id=${meeting.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (!response.ok || !response.body) {
        throw response.statusText;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          // setLoading(false);
          break;
        }

        const decodedChunk = decoder.decode(value, { stream: true });
        setMeetingSummaryResponse((prevValue) => {
          return {
            summarised:
              prevValue.summarised === "loading"
                ? decodedChunk
                : `${prevValue.summarised}${decodedChunk}`.replaceAll(
                    "\n",
                    "<br/>"
                  ),
          };
        });
      }
    } catch (error) {
      // setLoading(false);
      // Handle other errors
    }
  };
  const handlePortfolioReview = async () => {
    // axios
    //   .get(urls.dashboardPortfolioAnalysisURL, {
    //     params: {
    //       client_id: localStorage.getItem("clientId"),
    //       query: portfolioReviewQuery,
    //     },
    //   })
    //   .then((response) => {
    //     setPortfolioReviewResponse({
    //       portfolio_analysis: response.data.portfolio_analysis.replaceAll(
    //         "\n",
    //         "<br />"
    //       ),
    //     });
    //   })
    //   .catch((err) => console.log(err));
    try {
      setPortfolioReviewResponse({ portfolio_analysis: "loading" });
      const response = await fetch(
        `${urls.dashboardPortfolioAnalysisURL}?client_id=${localStorage.getItem(
          "clientId"
        )}&query=${portfolioReviewQuery}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (!response.ok || !response.body) {
        throw response.statusText;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          // setLoading(false);
          break;
        }

        const decodedChunk = decoder.decode(value, { stream: true });
        setPortfolioReviewResponse((prevValue) => {
          return {
            portfolio_analysis:
              prevValue.portfolio_analysis === "loading"
                ? decodedChunk
                : `${prevValue.portfolio_analysis}${decodedChunk}`.replaceAll(
                    "\n",
                    "<br/>"
                  ),
          };
        });
      }
    } catch (error) {
      let refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken && error === "Unauthorized") {
        await axios
          .post(urls.refreshTokenURL, {
            refresh: refreshToken,
          })
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem("accessToken", res.data.access);
              handlePortfolioReview();
            }
          })
          .catch((err) => {
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("accessToken");
            window.location.pathname = "/login";
          });
      }
    }
  };
  const handleAskAnything = async () => {
    // setAskMeAnythingResponse({ answer_anything: "loading" });
    // axios
    //   .get(urls.dashboardAskMeAnythingURL, {
    //     params: {
    //       client_id: localStorage.getItem("clientId"),
    //       query: askMeAnythingQuery,
    //     },
    //   })
    //   .then((response) => {
    //     setAskMeAnythingResponse({
    //       answer_anything: response.data.answer_anything.replaceAll(
    //         "\n",
    //         "<br />"
    //       ),
    //     });
    //   })
    //   .catch((err) => console.log(err));
    try {
      setAskMeAnythingResponse({ answer_anything: "loading" });
      const response = await fetch(
        `${urls.dashboardAskMeAnythingURL}?client_id=${localStorage.getItem(
          "clientId"
        )}&query=${meetingSummaryQuery}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (!response.ok || !response.body) {
        throw response.statusText;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          // setLoading(false);
          break;
        }

        const decodedChunk = decoder.decode(value, { stream: true });
        setAskMeAnythingResponse((prevValue) => {
          return {
            answer_anything:
              prevValue.answer_anything === "loading"
                ? decodedChunk
                : `${prevValue.answer_anything}${decodedChunk}`.replaceAll(
                    "\n",
                    "<br/>"
                  ),
          };
        });
      }
    } catch (error) {
      // setLoading(false);
      // Handle other errors
    }
  };
  const handleSuggestion = async (e) => {
    setPortfolioReviewQuery(e.target.innerText);
    try {
      setPortfolioReviewResponse({ portfolio_analysis: "loading" });
      const response = await fetch(
        `${urls.dashboardPortfolioAnalysisURL}?client_id=${localStorage.getItem(
          "clientId"
        )}&query=${e.target.innerText}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (!response.ok || !response.body) {
        throw response.statusText;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          // setLoading(false);
          break;
        }

        const decodedChunk = decoder.decode(value, { stream: true });
        setPortfolioReviewResponse((prevValue) => {
          return {
            portfolio_analysis:
              prevValue.portfolio_analysis === "loading"
                ? decodedChunk
                : `${prevValue.portfolio_analysis}${decodedChunk}`.replaceAll(
                    "\n",
                    "<br/>"
                  ),
          };
        });
      }
    } catch (error) {
      let refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken && error === "Unauthorized") {
        await axios
          .post(urls.refreshTokenURL, {
            refresh: refreshToken,
          })
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem("accessToken", res.data.access);
              handleSuggestion(e);
            }
          })
          .catch((err) => {
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("accessToken");
            window.location.pathname = "/login";
          });
      }
    }
  };
  const handleOutput = (pilot) => {
    switch (pilot) {
      case "Meeting Summary":
        return meetingSummaryResponse.summarised ? (
          <div className="p-[1rem] bg-[#fff] rounded-[0.5rem] h-[46%] w-full ml-0 mt-4 overflow-auto scrollablediv">
            {meetingSummaryResponse.summarised === "loading" ? (
              <div className="h-[100px] flex justify-center items-center">
                <Bars color="#1982f8" height="60" width="60" />
              </div>
            ) : (
              <>
                <div className="w-full flex justify-between cursor-pointer">
                  <p className="font-medium text-neutralBlack">
                    Meeting Summary
                  </p>
                  <CopyToClipboard
                    text={meetingSummaryResponse.summarised.replaceAll(
                      "<br/>",
                      ""
                    )}
                  >
                    <div className="flex items-center">
                      <img src={Copy} alt="err" className="mr-2" />
                      <p className="text-sm text-primary-200">
                        Copy to Clipboard
                      </p>
                    </div>
                  </CopyToClipboard>
                </div>
                <p className="text-sm text-justify mt-4 leading-[20px]">
                  {parse(meetingSummaryResponse.summarised)}
                </p>
              </>
            )}
          </div>
        ) : null;
      case "Portfolio Review":
        return portfolioReviewResponse.portfolio_analysis ? (
          <>
            {portfolioReviewResponse.portfolio_analysis === "loading" ? (
              <div className="h-[400px] flex justify-center items-center">
                <Bars color="#1982f8" height="60" width="60" />
              </div>
            ) : portfolioReviewResponse.portfolio_analysis === "empty" ? (
              <div className="space-y-6">
                <p className="font-medium text-neutralBlack">
                  Suggested Queries{" "}
                </p>
                <div className="space-y-4 text-primary-200 text-sm font-medium ">
                  <p
                    className="cursor-pointer inline-block"
                    onClick={(e) => handleSuggestion(e)}
                  >
                    Where should I invest now lumpsum money of INR 50 lacs ?
                  </p>
                  <br />
                  <p
                    className="cursor-pointer inline-block"
                    onClick={(e) => handleSuggestion(e)}
                  >
                    Why is my portfolio not generating good returns ?
                  </p>
                  <br />
                  <p
                    className="cursor-pointer inline-block"
                    onClick={(e) => handleSuggestion(e)}
                  >
                    Which is a good stock to put money in right now ?
                  </p>
                  <br />
                  <p
                    className="cursor-pointer inline-block"
                    onClick={(e) => handleSuggestion(e)}
                  >
                    Is my portfolio in line with my risk profile ?
                  </p>
                  <br />
                  <p
                    className="cursor-pointer inline-block"
                    onClick={(e) => handleSuggestion(e)}
                  >
                    Is my portfolio well positioned to take advantage of market
                    conditions ?
                  </p>
                </div>
              </div>
            ) : (
              <div className="flex-1 space-y-4">
                <div className="w-full flex justify-between cursor-pointer">
                  <p className="font-medium text-neutralBlack">
                    Portfolio Actionable based on current Market Views
                  </p>
                  <CopyToClipboard
                    text={portfolioReviewResponse.portfolio_analysis.replaceAll(
                      "<br/>",
                      ""
                    )}
                  >
                    <div className="flex items-center">
                      <img src={Copy} alt="err" className="mr-2" />
                      <p className="text-sm text-primary-200">
                        Copy to Clipboard
                      </p>
                    </div>
                  </CopyToClipboard>
                </div>

                <div className="bg-[#FAFAFA] flex-1 border border-[#e2e2e2] p-[1rem] w-full min-h-[90%] overflow-auto thinner-scrollablediv">
                  <p className="text-sm text-justify leading-[20px]">
                    {parse(portfolioReviewResponse.portfolio_analysis)}
                  </p>
                </div>
              </div>
            )}
          </>
        ) : null;
      case "Ask Me Anything":
        return askMeAnythingResponse.answer_anything ? (
          <div className="p-[1rem] bg-[#fff] rounded-[0.5rem] h-[56%] w-full ml-0 mt-4 overflow-auto scrollablediv">
            {askMeAnythingResponse.answer_anything === "loading" ? (
              <div className="h-[100px] flex justify-center items-center">
                <Bars color="#1982f8" height="60" width="60" />
              </div>
            ) : (
              <>
                <div className="w-full flex justify-between cursor-pointer">
                  <p className="font-medium text-neutralBlack">
                    Portfolio Actionable based on current Market Views
                  </p>
                  <CopyToClipboard
                    text={askMeAnythingResponse.answer_anything.replaceAll(
                      "<br/>",
                      ""
                    )}
                  >
                    <div className="flex items-center">
                      <img src={Copy} alt="err" className="mr-2" />
                      <p className="text-sm text-primary-200">
                        Copy to Clipboard
                      </p>
                    </div>
                  </CopyToClipboard>
                </div>
                <p className="text-sm text-justify mt-4 leading-[20px]">
                  {parse(askMeAnythingResponse.answer_anything)}
                </p>
              </>
            )}
          </div>
        ) : null;
      default:
        return null;
    }
  };
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp />
      <div className="loggedinscreen-container pt-0">
        <div className="loggedinscreen flex flex-row justify-between">
          <Clientsidebar />
          <div className="p-[1rem] bg-[#fff] rounded-[0.5rem] min-h-full client-input-parent-card mt-4 w-[79%] ml-0 relative">
            <div className="flex flex-col space-y-6 h-full">
              <div className="bg-[#fff] rounded-[0.5rem] w-full ml-0">
                {/* <h1 className="card-heading">BeyondIRR Copilot</h1> */}
                {/* <InvestedgeToggleButtonGroup
          tabs={toggletabs}
          value={pilot}
          onChange={handleTabClick}
          sx={{
            height: "40px",
            "& button": { marginTop: "20px" },
          }}
          exclusive
        /> */}
                {handlePilot(pilot)}
              </div>
              {handleOutput(pilot)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
