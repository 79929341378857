import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import Folder from "../../assets/img/manage_links.svg";
import AfterloginApp from "../AfterloginApp";
import Dropdown from "../../components/Dropdowns/Dropdown";
import InvestedgeSearchField from "../../components/searchfield/InvestedgeSearchField";
import InvestedgeTextField from "../../components/textfield/InvestedgeTextField";
import dayjs from "dayjs";
import InvestedgeDatepicker from "../../components/datepicker/InvestedgeDatepicker";
import { GetAcquireClientsDashboardAPI } from "../../actions/actions";
import PPT from "../../assets/img/ppt_file_type.svg";
import XLS from "../../assets/img/xls_file_type.svg";
import { useDispatch, useSelector } from "react-redux";
import { Bars } from "react-loader-spinner";
import InvestedgePagination from "../../components/pagination/InvestedgePagination";
import ManageLinksModal from "./ManageLinksModal";

export default function AcquireDashboard() {
  const dispatch = useDispatch();
  let options = [
    { option: "mfcentral", description: "MF Central" },
    { option: "cams", description: "CAMS CAS" },
  ];
  const proposalHistory = useSelector(
    (state) => state.getAcquireClientsDashboard
  );
  const [type, setType] = useState("mfcentral");
  const [manageModal, setManageModal] = useState(false);
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState(new Date("01-01-2023"));
  const [toDate, setToDate] = useState(new Date());
  const [proposalList, setProposalList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const proposalsPerPage = 7;
  // const pagesVisited = pageNumber * proposalsPerPage;
  const pageCount = Math.ceil(proposalHistory.count / proposalsPerPage);
  const changePage = (e, val) => {
    setPageNumber(val);
  };
  console.log(proposalHistory);
  useEffect(() => {
    var to_dd = String(toDate.getDate()).padStart(2, "0");
    var to_mm = String(toDate.getMonth() + 1).padStart(2, "0");
    var to_yyyy = toDate.getFullYear();

    var from_dd = String(fromDate.getDate()).padStart(2, "0");
    var from_mm = String(fromDate.getMonth() + 1).padStart(2, "0");
    var from_yyyy = fromDate.getFullYear();
    dispatch(
      GetAcquireClientsDashboardAPI({
        params: {
          from: `${from_yyyy}-${from_mm}-${from_dd}`,
          to: `${to_yyyy}-${to_mm}-${to_dd}`,
          page: pageNumber,
          size: proposalsPerPage,
          client_name: search,
        },
        type: type,
      })
    ); //eslint-disable-next-line
  }, [fromDate, toDate, type, pageNumber]);

  useEffect(() => {
    setProposalList(
      proposalHistory?.results.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      })
    );
    //eslint-disable-next-line
  }, [proposalHistory]);

  useEffect(() => {
    setProposalList(
      proposalHistory?.data?.results
        .filter((data) =>
          data.client_name.toLowerCase().includes(search.toLowerCase())
        )
        .sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        })
    );
    setPageNumber(1);
    //eslint-disable-next-line
  }, [search]);

  const return_date = (val) => {
    try {
      let temp = dayjs(new Date(val).toISOString());
      return temp;
    } catch (err) {
      return val;
    }
  };
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp />
      <ManageLinksModal
        manageModal={manageModal}
        onClose={() => setManageModal(false)}
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className="main-panel">
            <div className="portfolio-proposal-body-card min-h-[calc(100vh_-_116px)] shadow-none border-none relative">
              <div className="w-full flex justify-between">
                <h2 className="card-heading">Instant Review</h2>
                <div className="flex items-end space-x-4">
                  <div
                    className="flex items-end cursor-pointer mb-2"
                    onClick={() => setManageModal(true)}
                  >
                    <img src={Folder} alt="err" className="w-[22px]" />

                    <p className="font-inter font-semibold text-sm text-primary-200 mb-0 ml-1">
                      Manage Links
                    </p>
                  </div>
                  <Link to="/instant-review">
                    <InvestedgeButton
                    // className="mt-6"
                    // onClick={handleClick}
                    // disabled={result.loading}
                    >
                      Acquire New
                    </InvestedgeButton>
                  </Link>
                </div>
              </div>
              <div className="w-full flex justify-between mt-6">
                <div className="w-[28%]">
                  <Dropdown
                    options={options}
                    selected={type}
                    setSelected={setType}
                    attribute="description"
                    labelName={null}
                  />
                </div>
                <InvestedgeSearchField
                  type="text"
                  className="w-[39%]"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search Client"
                />
                <InvestedgeDatepicker
                  format="DD/MM/YYYY"
                  label="From"
                  value={return_date(fromDate)}
                  className="w-[14%]"
                  onChange={(date) => setFromDate(new Date(date))}
                  maxDate={dayjs(new Date().toISOString())}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  renderInput={(params) => <InvestedgeTextField {...params} />}
                />
                <InvestedgeDatepicker
                  format="DD/MM/YYYY"
                  label="To"
                  className="w-[14%]"
                  value={return_date(toDate)}
                  minDate={return_date(fromDate)}
                  onChange={(date) => setToDate(new Date(date))}
                  maxDate={dayjs(new Date().toISOString())}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  renderInput={(params) => <InvestedgeTextField {...params} />}
                />
              </div>
              {proposalHistory.loading ? (
                <div className="h-[400px] flex justify-center items-center">
                  <Bars color="#1982f8" height="60" width="60" />
                </div>
              ) : (
                <table className="mt-[32px] w-full investedge-table">
                  <colgroup>
                    <col width="40%" />
                    <col width="15%" />
                    <col width="15%" />
                    <col width="15%" />
                    <col width="15%" />
                  </colgroup>
                  <tbody className="font-sm">
                    <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                      <th className=" py-3 text-left font-medium">
                        Client Name
                      </th>
                      <th className=" py-3 font-medium">PAN</th>
                      <th className=" py-3 font-medium">Mobile No.</th>
                      <th className=" py-3 font-medium">Date</th>

                      <th className=" py-3 font-medium">Download</th>
                    </tr>
                    {proposalList
                      // .slice(pagesVisited, pagesVisited + proposalsPerPage)
                      .map((element) => {
                        const item = new Date(element.created_at);
                        var dd = String(item.getDate()).padStart(2, "0");
                        var mm = String(item.getMonth() + 1).padStart(2, "0");
                        var yyyy = item.getFullYear();
                        return (
                          <tr
                            className="border-bottom py-0 h-[55px]"
                            key={element.id}
                          >
                            <td className="px-0 py-[12px]">
                              <div className="flex w-full items-center">
                                <p className="mb-0 text-[14px]">
                                  {element.client_name}
                                </p>
                              </div>
                            </td>
                            <td className="px-0 py-[12px] text-center text-sm">
                              {element?.pan_mobile?.pan}
                            </td>
                            <td className="px-0 py-[12px] text-center text-sm">
                              {element?.pan_mobile?.mobile}
                            </td>
                            <td className="px-0 py-[12px] text-center text-sm">{`${dd}-${mm}-${yyyy}`}</td>
                            {/* <td className="px-0 py-[12px] text-center text-sm">
                      {item.toLocaleTimeString("en-US")}
                    </td> */}
                            <td className="px-0 py-3 ">
                              <div className="flex w-full items-center justify-center">
                                <img
                                  src={element.file_type === "1" ? PPT : XLS}
                                  alt="err"
                                />{" "}
                                <a
                                  href={element.file}
                                  className="mb-0 text-sm mt-0 ml-2 text-primary-200 cursor-pointer leading-0"
                                >
                                  Link
                                </a>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}
              <div className="w-full flex justify-end absolute bottom-4 right-4">
                <InvestedgePagination
                  count={pageCount}
                  defaultPage={1}
                  page={pageNumber}
                  onChange={changePage}
                  color="primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
