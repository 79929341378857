import { OutlinedInput, InputAdornment } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { Line } from "react-chartjs-2";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import InvestedgeDatepicker from "../../../components/datepicker/InvestedgeDatepicker";
import DropdownForAttributes from "../../../components/Dropdowns/DropdownForAttributes";
import InvestedgeTextField from "../../../components/textfield/InvestedgeTextField";
import urls from "../../../urls";
import dayjs from "dayjs";
export default function ScenerioAnalysis({ goalData, clientInvestments, id }) {
  const [goalDetails, setGoalDetails] = useState({
    goalEnd: new Date(),
    return: "",
    sipDenotion: "₹ L",
    sip: "",
  });
  const numToMonthMap = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };
  const dateParser = (date) => {
    var dd = String(date.getDate()).padStart(2, "0");
    var mm = String(date.getMonth() + 1).padStart(2, "0");
    var yyyy = date.getFullYear();
    return dd + " " + numToMonthMap[parseInt(mm)].slice(0, 3) + " " + yyyy;
  };
  const denotionParser = (denotion, value) => {
    if (denotion[2] === "K") return parseInt(value) * 1000;
    else if (denotion[2] === "L") return parseInt(value) * 100000;
    else return value * 10000000;
  };
  const [backendData, setBackendData] = useState({
    current_value_of_portfolio: 0,
    expected_corpus: 0,
    graph: {
      dates: [],
      value1: {
        label: "Total Amount Invested",
        values: [],
      },
      value2: {
        label: "Expected Investment Value",
        values: [],
      },
      value3: {
        label: "Value of Existing Investment",
        values: [],
      },
    },
  });
  const diff_years = (dt2, dt1) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24;
    return Math.abs(Math.round(diff / 365.25));
  };
  const handleCalculate = () => {
    axios
      .post(urls.goalsScenarioAnalysisURL, {
        goal_id: id,
        client_id: localStorage.getItem("clientId"),
        expected_monthly_investment: denotionParser(
          goalDetails.sipDenotion,
          goalDetails.sip
        ),
        expected_return: goalDetails.return / 100,
        goal_ends_on: diff_years(new Date(), goalDetails.goalEnd),
      })
      .then((response) => setBackendData(response.data))
      .catch(() =>
        setBackendData({
          current_value_of_portfolio: 0,
          expected_corpus: 0,
          graph: {
            dates: [],
            value1: {
              label: "Total Amount Invested",
              values: [],
            },
            value2: {
              label: "Expected Investment Value",
              values: [],
            },
            value3: {
              label: "Value of Existing Investment",
              values: [],
            },
          },
        })
      );
  };
  const data = {
    backgroundColor: "#fafafa",
    labels: backendData.graph.dates,
    datasets: [
      {
        label: "Total Amount Invested",
        // data: backendData.graph.value3.values,
        data: backendData.graph.value1.values,
        fill: false,
        spanGaps: true,
        borderColor: "#1982f8",
        tension: 0.1,
        opacity: "0.3",
        borderWidth: 3,
      },
      {
        label: "Expected Corpus",
        data: backendData.graph.value2.values.map(function (num, idx) {
          return num + backendData.graph.value3.values[idx];
        }),
        // data: backendData.graph.value2.values,
        borderColor: "#34D399",
        borderWidth: 3,
        tension: 0.1,
        opacity: "0.3",
        fill: true,
        backgroundColor: "rgba(53, 162, 235, 0.2)",
        spanGaps: true,
      },
    ],
  };
  const options = {
    animation: {
      duration: 1000,
    },

    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            autoSkip: true,
            maxTicksLimit: 7,
            maxRotation: 0,
            fontSize: 10,
            callback: (val) => dateParser(new Date(val)),
          },
          gridLines: {
            color: "white",
          },
        },
      ],
      yAxes: [
        {
          stepSize: 100,
          type: "linear",
          display: true,
          gridLines: {
            color: "white",
          },
          ticks: {
            callback: (val) => val.toLocaleString("en-IN"),
          },
        },
      ],
    },
    elements: {
      bar: {
        borderWidth: 3,
      },
      point: {
        radius: 0,
      },
    },
    legend: {
      display: false,
    },
    plugins: {
      title: {
        display: true,
      },
      labels: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      points: {
        display: false,
      },

      interaction: {
        mode: "index",
        intersect: false,
      },
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem, data) =>
          dateParser(new Date(data.labels[tooltipItem[0].index])),
      },
    },
  };

  const return_date = (val) => {
    try {
      let temp = dayjs(new Date(val).toISOString());
      return temp;
    } catch (err) {
      return val;
    }
  };
  return clientInvestments && clientInvestments.length ? (
    <div className="mt-16">
      <p className="card-heading">Scenario Analysis</p>
      <div className="w-full justify-between flex">
        <div className="w-[48%]">
          <div className="flex justify-between items-center mt-6">
            <p className="font-inter text-sm">SIP Amount</p>
            <div className="w-[35%] flex justify-between">
              <div className="w-[40%]">
                <DropdownForAttributes
                  labelName={null}
                  options={["₹ L", "₹ K", "₹ C"]}
                  selected={goalDetails}
                  setSelected={setGoalDetails}
                  attribute="sipDenotion"
                />
              </div>
              <div className="w-[58%]">
                <InvestedgeTextField
                  variant="outlined"
                  fullWidth
                  style={{
                    fontFamily: "Inter,sans-sarif",
                    fontWeight: 400,
                  }}
                  label={null}
                  value={goalDetails.sip}
                  onChange={(e) => {
                    const re = /^\d*[0-9]+\d*$/;
                    if (e.target.value === "" || re.test(e.target.value))
                      setGoalDetails({
                        ...goalDetails,
                        sip: e.target.value,
                      });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full flex justify-between items-center mt-6">
            <div className="flex items-center w-[70%]">
              <p className="font-inter text-sm">Expected Return</p>
            </div>
            <OutlinedInput
              size="small"
              className="text-sm"
              required
              style={{
                fontFamily: "Inter,sans-sarif",
                fontWeight: 400,
                width: "37%",
              }}
              value={goalDetails.return}
              onChange={(e) => {
                const re = /^\d*\.?\d*$/;
                if (e.target.value === "" || re.test(e.target.value))
                  setGoalDetails({
                    ...goalDetails,
                    return: e.target.value,
                  });
              }}
              name="target"
              endAdornment={
                <InputAdornment position="end">
                  <span className="text-sm font-inter">%</span>
                </InputAdornment>
              }
            />
          </div>
          <div className="w-full flex justify-between items-center mt-6">
            <p className="font-inter text-sm">When does the goal end? </p>
            <InvestedgeDatepicker
              inputFormat="DD-MM-YYYY"
              className="w-[35%]"
              label={null}
              minDate={dayjs(new Date().toISOString())}
              value={return_date(goalDetails.goalEnd)}
              onChange={(date) => {
                setGoalDetails({ ...goalDetails, goalEnd: new Date(date) });
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              renderInput={(params) => <InvestedgeTextField {...params} />}
            />
          </div>
          <div className="w-full flex justify-between items-center mt-6">
            <div className="flex items-center w-[70%]">
              <p className="font-inter text-sm">Current Value of Portfolio</p>
            </div>
            <p className="text-sm text-greyBorder">
              {goalData.current_value_of_portfolio
                ? `₹ ${parseFloat(
                    goalData.current_value_of_portfolio.toFixed(1)
                  ).toLocaleString("en-IN")}`
                : "₹ 0"}
            </p>
          </div>
          <InvestedgeButton
            onClick={handleCalculate}
            disabled={!goalDetails.sip || !goalDetails.return}
            sx={{
              marginTop: "3rem",
              width: "100px",
            }}
          >
            Calculate
          </InvestedgeButton>
        </div>
        <div className="w-[50%] mt-[16px]">
          {" "}
          <div className="bg-[#fafafa] p-4">
            <Line data={data} options={options} height={250} />
            <div className="flex justify-center w-full mt-[12px]">
              <div className="flex items-center ml-[16px]">
                <div className="bg-primary-200 rounded w-[14px] h-[14px]" />
                <h2 className="text-xs ml-[8px] mb-0">Total Amount Invested</h2>
              </div>
              <div className="flex items-center ml-[16px]">
                <div className="bg-success-200 rounded w-[14px] h-[14px]" />
                <h2 className="text-xs ml-[8px] mb-0">Expected Corpus</h2>
              </div>
            </div>
          </div>
          <div>
            <p className="font-inter text-[#6a6a76] mb-[4px] mt-4">
              Expected Corpus
            </p>
            <p className="font-inter font-semibold text-[20px] text-primary-200 mb-[4px]">
              {`₹ ${parseInt(backendData.expected_corpus).toLocaleString(
                "en-IN"
              )}`}
            </p>
            <p className="font-inter font-medium text-[#3D3D3D] mb-[4px]">
              {`in ${
                numToMonthMap[
                  backendData.graph.dates.length
                    ? parseInt(
                        backendData.graph.dates[
                          backendData.graph.dates.length - 1
                        ].split("-")[1]
                      )
                    : new Date().getMonth() + 1
                ]
              } ${
                backendData.graph.dates.length
                  ? backendData.graph.dates[
                      backendData.graph.dates.length - 1
                    ].split("-")[0]
                  : new Date().getFullYear()
              }`}
            </p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}
