import React from "react";
import DefaultProfilePic from "../../assets/img/defaultprofilepic.png";
import HangingGifts from "../../assets/img/hanging_gifts.svg";
export default function ClientCard({ client }) {
  return (
    <div className="bg-gradient-180 from-[#fafafa] to-[#f6f6f6] p-[1rem] min-h-[155px] w-full shadow-none m-0 duration-300 relative">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "97%",
          }}
        >
          <img
            src={client.photo ? client.photo : DefaultProfilePic}
            style={{
              height: "60px",
              width: "60px",
              borderRadius: "100%",
            }}
            alt="err"
          />
          <p className="client-input-client-name">{client.client_name}</p>
          <p className="font-inter text-[12px] not-italic  leading-[12px] tracking-[0.015em] text-center mb-0 text-primary-200 mt-[0.5rem] bg-[#EDF5FE] py-[0.25rem] px-[0.5rem] rounded-[2px]">
            {client.client_dob}
          </p>
        </div>
      </div>
      {String(new Date(client.client_dob).getDate()).padStart(2, "0") <
        String(new Date().getDate()).padStart(2, "0") && (
        <div className="absolute top-0 right-0 w-full h-full bg-black/20 z-30" />
      )}
      <img
        src={HangingGifts}
        alt="err"
        className="absolute top-0 right-3 z-20"
      />
    </div>
  );
}
