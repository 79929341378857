import { UPDATE_PRODUCT_UNDERSTANDING_DATA } from "../actions/type";
const initialState = [
  {
    ips: {
      equity: {
        target: 0,
        flexibility: 0,
        min: 0,
        max: 0,
      },
      debt: {
        target: 0,
        flexibility: 0,
        min: 0,
        max: 0,
      },
      alternate: {
        target: 0,
        flexibility: 0,
        min: 0,
        max: 0,
      },
      large_cap: {
        target: 0,
        flexibility: 0,
        min: 0,
        max: 0,
      },
      mid_cap: {
        target: 0,
        flexibility: 0,
        min: 0,
        max: 0,
      },
      small_cap: {
        target: 0,
        flexibility: 0,
        min: 0,
        max: 0,
      },
      duration: {
        target: 0,
        flexibility: 0,
        min: 0,
        max: 0,
      },
      credit_risk: {
        target: 0,
        flexibility: 0,
        min: 0,
        max: 0,
      },
      alternates: {
        target: 0,
        flexibility: 0,
        min: 0,
        max: 0,
      },
    },
    understanding: {
      mf: {
        checked: false,
        value: 3,
      },
      stocks: {
        checked: false,
        value: 3,
      },
      bonds: {
        checked: false,
        value: 3,
      },
      pms: {
        checked: false,
        value: 3,
      },
      aif: {
        checked: false,
        value: 3,
      },
      cash: {
        checked: false,
        value: 3,
      },
      deposit: {
        checked: false,
        value: 3,
      },
      gold: {
        checked: false,
        value: 3,
      },
      savingsschemes: {
        checked: false,
        value: 3,
      },
      realestate: {
        checked: false,
        value: 3,
      },
      epf: {
        checked: false,
        value: 3,
      },
      ppf: {
        checked: false,
        value: 3,
      },
      unlisted: {
        checked: false,
        value: 3,
      },
    },
  },
];
export default function UpdateProductUnderstandingData(
  state = initialState,
  action
) {
  switch (action.type) {
    case UPDATE_PRODUCT_UNDERSTANDING_DATA:
      return action.payload;
    default:
      return state;
  }
}
