import React, { useState, useEffect } from "react";
import Cross from "../../assets/img/standardcross.svg";
import Add from "../../assets/img/portfolioaddfunds.svg";
import Reload from "../../assets/img/portfolioreload.svg";
import InputBg from "../../assets/img/model_portfolio_input_title.svg";
import OverviewSection from "./OverviewSection";
import DebtSection from "./DebtSection";
import EquitySection from "./EquitySection";
import { useSelector, useDispatch } from "react-redux";
import {
  DeleteModelPortfolioProducts,
  GetModelPortfolioAnalytics,
  OpenSnackbar,
  PatchModelPortfolio,
  PatchModelPortfolioProducts,
  PostEventLoggingData,
  PostModelPortfolio,
  PostModelPortfolioProducts,
} from "../../actions/actions";
import AddFundsModal from "./AddFundsModal";
import { Bars } from "react-loader-spinner";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import InvestedgePagination from "../../components/pagination/InvestedgePagination";
import AfterloginApp from "../AfterloginApp";
import InvestedgeTextField from "../../components/textfield/InvestedgeTextField";
import { BsArrowUpRightSquare } from "react-icons/bs";
import DescriptionModel from "./DescriptionModel";
import { useHistory, useLocation } from "react-router-dom";
export default function DetailedPortfolo() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const preferenceData = (location.state && location.state.products) || [];
  const readOnly = location.state && location.state.readOnly;
  const [desModal, setDesModal] = useState(false);
  const rationalizeProducts = (productsArray) => {
    let temp = productsArray.map((product) => {
      return {
        ...product,
        individual_asset_pct: (product.individual_asset_pct * 100).toFixed(1),
      };
    });

    return temp;
  };
  const derationalizeProducts = (productsArray) => {
    let temp = productsArray.map((product) => {
      return {
        ...product,
        individual_asset_pct: product.individual_asset_pct / 100,
      };
    });

    return temp;
  };
  const [parsedData, setParsedData] = useState(
    rationalizeProducts(preferenceData)
  );
  useEffect(() => {
    if (preferenceData.length > 0) {
      dispatch(GetModelPortfolioAnalytics(preferenceData));
      let tempArray = rationalizeProducts(preferenceData);
      let tempSum = 0;
      tempArray.forEach(
        (value) => (tempSum += parseFloat(value.individual_asset_pct))
      );
      tempSum = tempSum.toFixed(1);
      tempArray[0].individual_asset_pct =
        parseFloat(tempArray[0].individual_asset_pct) +
        (100.0 - parseFloat(tempSum));
      tempArray[0].individual_asset_pct =
        tempArray[0].individual_asset_pct.toFixed(1);
      setParsedData(tempArray);
    } // eslint-disable-next-line
  }, [preferenceData]);

  const analytics = useSelector((state) => state.getModelPortfolioAnalytics);
  const [section, setSection] = useState("Overview");
  const [afmodal, setAfmodal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [name, setName] = useState(
    location.state && location.state.portfolio
      ? location.state.portfolio.portfolio_name
      : ""
  );
  const clientsPerPage = 10;
  const pagesVisited = pageNumber * clientsPerPage;
  var portfolioSum = 0;
  const addedFundsMap = {};
  // eslint-disable-next-line
  parsedData.forEach((value) => {
    addedFundsMap[value.product_id] = true;
    portfolioSum += parseFloat(value.individual_asset_pct);
  });
  const displaydata = parsedData
    .slice(pagesVisited, pagesVisited + clientsPerPage)
    .map((client) => {
      return (
        <tr
          className="h-[40px] border-b border-primarytablebottomClr"
          key={client.product_id}
        >
          <td
            className="dashboard-body-second-card-table-data"
            style={{
              paddingLeft: "1rem",
              textAlign: "left",
            }}
          >
            {client.name}
          </td>

          <td className="dashboard-body-second-card-table-data">
            {`${client.asset_type} - ${client.product_type}`}
          </td>
          <td className="dashboard-body-second-card-table-data">
            {readOnly ? (
              client.individual_asset_pct
            ) : (
              <input
                type="text"
                value={client.individual_asset_pct}
                style={{
                  width: "100%",
                  height: "40px",
                  boxShadow: "none",
                  textAlign: "center",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "14px",
                  background: "#F3F3F3",
                  border: "none",
                }}
                onChange={(e) =>
                  handleWeightChange(e, client.product_id, client)
                }
              />
            )}
          </td>
          {!readOnly && (
            <td>
              <img
                src={Cross}
                alt="err"
                style={{ width: "14px", objectFit: "contain" }}
                onClick={() => handleCross(client.product_id, client)}
              />
            </td>
          )}
        </tr>
      );
    });
  const pageCount = Math.ceil(parsedData.length / clientsPerPage);
  const changePage = (e, val) => {
    setPageNumber(val - 1);
  };
  const handleCross = (id, fund) => {
    delete addedFundsMap[id];
    let index = parsedData.findIndex((x) => x.product_id === id);
    let newParsedData = [...parsedData];
    newParsedData.splice(index, 1);
    setParsedData(newParsedData);
    if (location.state && location.state.portfolio) {
      //editing in patch mf array
      if (
        preferenceData.some(
          (getProduct) => getProduct.product_id === fund.product_id
        )
      ) {
        let newPatchMfArray = [...patchMfArray];
        let patchindex = newPatchMfArray.findIndex(
          (pid) => pid.product_id === fund.product_id
        );
        if (patchindex !== -1) newPatchMfArray.splice(patchindex, 1);
        setPatchMfArray(newPatchMfArray);
        setDeleteArray([...deleteArray, fund]);
      } //editing in post mf array
      else {
        let newPostMfArray = [...postMfArray];
        let postindex = newPostMfArray.findIndex(
          (pid) => pid.product_id === fund.product_id
        );
        newPostMfArray.splice(postindex, 1);
        setPostMfArray(newPostMfArray);
      }
    }
  };
  const handleSections = () => {
    switch (section) {
      case "Overview":
        return <OverviewSection analytics={analytics} />;

      case "Debt":
        return <DebtSection analytics={analytics} />;

      case "Equity":
        return <EquitySection analytics={analytics} />;

      default:
        console.log(section);
    }
  };
  const handleWeightChange = (e, id, fund) => {
    const re = /^\d*\.?\d*$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      let index = parsedData.findIndex((x) => x.product_id === id);
      let newProducts = [...parsedData];
      newProducts[index].individual_asset_pct = e.target.value;
      setParsedData(newProducts);
      if (location.state && location.state.portfolio) {
        if (
          preferenceData.some(
            (getProduct) => getProduct.product_id === fund.product_id
          )
        ) {
          let newPatchMfArray = [...patchMfArray];
          let patchindex = newPatchMfArray.findIndex(
            (pid) => pid.product_id === fund.product_id
          );
          if (patchindex === -1) {
            newPatchMfArray.push(fund);
            newPatchMfArray[newPatchMfArray.length - 1].individual_asset_pct =
              e.target.value;
          } else
            newPatchMfArray[patchindex].individual_asset_pct = e.target.value;
          setPatchMfArray(newPatchMfArray);
        } //editing in post mf array
        else {
          let newPostMfArray = [...postMfArray];
          let postindex = newPostMfArray.findIndex(
            (pid) => pid.product_id === fund.product_id
          );
          newPostMfArray[postindex].individual_asset_pct = e.target.value;
          setPostMfArray(newPostMfArray);
        }
      }
    }
  };
  const [portfolioDescription, setPortfolioDescription] = useState({
    purposeOfPortfolio:
      location.state && location.state.portfolio
        ? location.state.portfolio.purpose_of_portfolio
        : "Retirement Planning",
    riskProfileType:
      location.state && location.state.portfolio
        ? location.state.portfolio.risk_profile_type
        : "Conservative",
    portfolioValueRange:
      location.state && location.state.portfolio
        ? location.state.portfolio.portfolio_value_range
        : "Less than 25 lakhs",
    productType:
      location.state && location.state.portfolio
        ? location.state.portfolio.product_type
        : "Mutual Fund Portfolio",
    returnProfileType:
      location.state && location.state.portfolio
        ? location.state.portfolio.return_portfolio_type
        : "Less than 8%",
    investmentHorizon:
      location.state && location.state.portfolio
        ? location.state.portfolio.investment_horizon
        : "Less than 3 Years",
  });
  useEffect(() => {
    dispatch(
      PostEventLoggingData({
        module_name: "Portfolio Insights",
        event_type: "Information",
        url: window.location.href,
        note: `Checked out ${section} of analytics`,
      })
    ); // eslint-disable-next-line
  }, [section]);
  const [patchMfArray, setPatchMfArray] = useState([]);
  const [postMfArray, setPostMfArray] = useState([]);
  const [deleteArray, setDeleteArray] = useState([]);
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className="px-8 rounded-[8px] w-full bg-white pt-4 pb-4">
            <div className="flex justify-between items-center w-full">
              <div className="flex items-center">
                <img src={InputBg} alt="err" />{" "}
                {readOnly ? (
                  <h2 className="card-heading ml-4">
                    {location.state && location.state.portfolio.portfolio_name}
                  </h2>
                ) : (
                  <InvestedgeTextField
                    sx={{
                      fontFamily: "Inter,sans-sarif",
                      fontWeight: 400,
                      marginLeft: "1rem",
                      width: "300px",
                    }}
                    label="Enter title here..."
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                )}
                <BsArrowUpRightSquare
                  size={14}
                  color="#00003F"
                  style={{ marginLeft: "0.5rem", cursor: "pointer" }}
                  onClick={() => setDesModal(true)}
                />
              </div>
              {!readOnly && !location.state && (
                <InvestedgeButton
                  disabled={
                    Math.round(portfolioSum * 10) / 10 !== 100 || !name.length
                  }
                  onClick={() => {
                    dispatch(
                      PostModelPortfolio(
                        {
                          portfolio_name: name,
                          risk_profile_type:
                            portfolioDescription.riskProfileType,
                          purpose_of_portfolio:
                            portfolioDescription.purposeOfPortfolio,
                          product_type: portfolioDescription.productType,
                          portfolio_value_range:
                            portfolioDescription.portfolioValueRange,
                          return_portfolio_type:
                            portfolioDescription.returnProfileType,
                          investment_horizon:
                            portfolioDescription.investmentHorizon,
                        },
                        derationalizeProducts(parsedData),
                        history
                      )
                    );
                  }}
                >
                  Create
                </InvestedgeButton>
              )}
              {!readOnly && location.state && (
                <InvestedgeButton
                  disabled={Math.round(portfolioSum * 10) / 10 !== 100}
                  onClick={() => {
                    let newPatchList = derationalizeProducts(patchMfArray);
                    newPatchList = newPatchList.map((item) => {
                      return {
                        id: item.id,
                        individual_asset_pct: item.individual_asset_pct,
                      };
                    });
                    let newDeleteList = deleteArray.map((item) => {
                      return { id: item.id };
                    });
                    let newPostList = derationalizeProducts(postMfArray);
                    newPostList = newPostList.map((item) => {
                      return {
                        ...item,
                        portfolio_id: location.state.portfolio.id,
                      };
                    });
                    newDeleteList.length &&
                      dispatch(DeleteModelPortfolioProducts(newDeleteList));
                    newPostList.length &&
                      dispatch(PostModelPortfolioProducts(newPostList));
                    newPatchList.length &&
                      dispatch(PatchModelPortfolioProducts(newPatchList));
                    dispatch(
                      PatchModelPortfolio(
                        {
                          id: location.state.portfolio.id,
                          risk_profile_type:
                            portfolioDescription.riskProfileType,
                          purpose_of_portfolio:
                            portfolioDescription.purposeOfPortfolio,
                          product_type: portfolioDescription.productType,
                          portfolio_value_range:
                            portfolioDescription.portfolioValueRange,
                          return_portfolio_type:
                            portfolioDescription.returnProfileType,
                          investment_horizon:
                            portfolioDescription.investmentHorizon,
                          portfolio_name: name,
                        },
                        history
                      )
                    );
                    dispatch(
                      OpenSnackbar({
                        severity: "success",
                        message: "Portfolio Updated Successfully",
                      })
                    );
                  }}
                >
                  Save Changes
                </InvestedgeButton>
              )}
            </div>
          </div>
          <DescriptionModel
            desModal={desModal}
            readOnly={readOnly}
            onClose={() => setDesModal(false)}
            portfolioDescription={portfolioDescription}
            setPortfolioDescription={setPortfolioDescription}
          />
          <div className="px-8 rounded-[8px] w-full bg-white pt-8 pb-4 mt-[16px]">
            <div className="flex justify-between w-full">
              <div className="w-[52.5%]">
                <div className="w-full flex border-b border-[#e0dddd]">
                  <h3 className=" font-inter text-[16px] font-medium leading-[20px] tracking-[0em] text-left pb-[0.3rem]">
                    Portfolio
                  </h3>
                </div>
                {!analytics.loading ? (
                  <>
                    <div className="w-full bg-[#fafafa] mt-[1rem] p-[1rem] flex rounded-[6px]">
                      <div className="w-[25%] text-center border-r border-r-dividerColor">
                        <h3 className="text-[#6a6a76] font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center mb-0">
                          {`${(analytics.asset_allocation.Equity * 100).toFixed(
                            1
                          )}%`}
                        </h3>
                        <label className="font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                          Equity
                        </label>
                      </div>
                      <div className="w-[25%] text-center border-r border-r-dividerColor">
                        <h3 className="text-[#6a6a76] font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center mb-0">
                          {`${(analytics.asset_allocation.Debt * 100).toFixed(
                            1
                          )}%`}
                        </h3>
                        <label className="font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                          Debt
                        </label>
                      </div>
                      <div className="w-[25%] text-center border-r border-r-dividerColor">
                        <h3 className="text-[#6a6a76] font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center mb-0">
                          {`${(
                            analytics.asset_allocation.Alternate * 100
                          ).toFixed(1)}%`}
                        </h3>
                        <label className="font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                          Alternates
                        </label>
                      </div>
                      <div className="w-[25%] text-center">
                        <h3
                          className="font-inter text-[24px] font-semibold leading-[20px] tracking-[0em] text-center mb-0"
                          style={
                            Math.round(portfolioSum * 10) / 10 !== 100
                              ? { color: "red" }
                              : { color: "#00B031" }
                          }
                        >
                          {portfolioSum.toFixed(1)}%
                        </h3>
                        <label className="font-inter text-[13px] font-normal leading-[20px] tracking-[0em] text-center mb-0 text-[#6a6a76] mt-[0.5rem]">
                          Total
                        </label>
                      </div>
                    </div>
                    <div
                      className={
                        parsedData.length
                          ? "mt-[1rem] min-h-[680px]"
                          : "mt-[1rem] min-h-[100px]"
                      }
                    >
                      <table
                        style={{ width: "100%", height: "100%" }}
                        className="investedge-table"
                      >
                        <colgroup>
                          <col width="46%" />
                          <col width="30%" />
                          <col width={readOnly ? "24%" : "16%"} />
                          {!readOnly && <col width="8%" />}
                        </colgroup>

                        <tbody>
                          <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                            <th
                              style={{
                                textAlign: "left",
                                paddingLeft: "1rem",
                              }}
                              className="font-inter text-[12px] font-medium leading-[20px] tracking-[0em] text-left py-[0.25rem] pl-[1rem] pr-0"
                            >
                              Name
                            </th>
                            <th className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] text-center py-[0.25rem] px-0">
                              Asset Class/ <br />
                              Product Category
                            </th>
                            <th className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] text-center py-[0.25rem] px-0">
                              Weight
                              <br /> (% of Total)
                            </th>
                            <th className=" font-inter text-[12px] font-medium leading-[20px] tracking-[0em] text-center py-[0.25rem] px-0"></th>
                          </tr>
                          {displaydata}
                        </tbody>
                      </table>
                    </div>
                    <div className="flex w-full justify-between pt-0 items-center">
                      {!readOnly && (
                        <InvestedgeButton
                          className="mt-4 w-[175px]"
                          onClick={() => {
                            dispatch(
                              PostEventLoggingData({
                                module_name: "Portfolio Insights",
                                event_type: "Add button",
                                url: window.location.href,
                                note: `Fourth Screen fund added`,
                              })
                            );
                            setAfmodal(true);
                          }}
                        >
                          <img
                            className="w-[14px] object-contain mr-[0.25rem]"
                            src={Add}
                            alt="err"
                          />
                          Add Products
                        </InvestedgeButton>
                      )}
                      <AddFundsModal
                        onClose={() => setAfmodal(false)}
                        afmodal={afmodal}
                        parsedData={parsedData}
                        setParsedData={setParsedData}
                        addedFundsMap={addedFundsMap}
                        postMfArray={postMfArray}
                        setPostMfArray={setPostMfArray}
                      />
                      <div className="flex justify-end w-full mt-4 items-center">
                        <InvestedgePagination
                          count={pageCount}
                          defaultPage={1}
                          page={pageNumber + 1}
                          onChange={changePage}
                          color="primary"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="w-[45%]">
                <div className="w-[100%] flex justify-between border-b  border-[#e0dddd]">
                  <div className="flex">
                    <h3 className=" font-inter text-[16px] font-medium leading-[20px] tracking-[0em] text-left pb-[0.3rem]">
                      Analytics
                    </h3>
                    <p
                      className={
                        section === "Overview"
                          ? "border-b border-primary-200 ml-[2rem] text-primary-200 font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-left  my-0 mr-[1rem] cursor-pointer"
                          : "ml-[2rem] font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-[#000] text-left  my-0 mr-[1rem] cursor-pointer"
                      }
                      onClick={() => setSection("Overview")}
                    >
                      Overview
                    </p>
                    <p
                      className={
                        section === "Equity"
                          ? "border-b border-primary-200 ml-[2rem] text-primary-200 font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-left  my-0 mr-[1rem] cursor-pointer"
                          : "ml-[2rem] font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-[#000] text-left  my-0 mr-[1rem] cursor-pointer"
                      }
                      onClick={() => setSection("Equity")}
                    >
                      Equity
                    </p>
                    <p
                      className={
                        section === "Debt"
                          ? "border-b border-primary-200 ml-[2rem] text-primary-200 font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-left  my-0 mr-[1rem] cursor-pointer"
                          : "ml-[2rem] font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-[#000] text-left  my-0 mr-[1rem] cursor-pointer"
                      }
                      onClick={() => setSection("Debt")}
                    >
                      Debt
                    </p>
                  </div>
                  {!readOnly && (
                    <div
                      className="flex cursor-pointer items-baseline"
                      onClick={() => {
                        let temp = derationalizeProducts(parsedData);
                        dispatch(
                          PostEventLoggingData({
                            module_name: "Portfolio Insights",
                            event_type: "Refresh",
                            url: window.location.href,
                            note: `Fourth Screen analytics refreshed`,
                          })
                        );
                        dispatch(
                          GetModelPortfolioAnalytics({ "Mutual Fund": temp })
                        );
                      }}
                    >
                      <img src={Reload} alt="err" className="w-[10px]" />
                      <p className=" font-inter text-[12px] font-medium tracking-[0em] text-left text-primary-200 mb-0 align-bottom ml-[0.2rem]">
                        Simulate
                      </p>
                    </div>
                  )}
                </div>
                {!analytics.loading ? handleSections(section) : ""}
              </div>
            </div>
            {analytics.loading ? (
              <div className="w-full h-[525px] flex justify-center items-center">
                <Bars color="#1982F8" />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
