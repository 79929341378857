import React from "react";
// import Test from "../../../assets/img/test.svg";
import "../../../static/css/existing/dashboard_styles.css";

export default function fundDetailsComponent() {
  return (
    <>
      <div
        style={{
          padding: "2rem",
          backgroundColor: "#fff",
          borderRadius: "6px",
          boxShadow:
            " 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(0, 0, 0, 0.05)",
          minheight: "274px",
        }}
      >
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            width: "100%",
          }}
        >
          <div>
            <p
              className="onepager-description-text"
              style={{ color: "#999999", marginBottom: "0.4rem" }}
            >
              Inception Date
            </p>
            <h4 className="onepager-inside-card-data">12-01-2009</h4>
          </div>
          <div>
            <p
              className="onepager-description-text"
              style={{ color: "#999999", marginBottom: "0.4rem" }}
            >
              Benchmark Index
            </p>
            <h4 className="onepager-inside-card-data">S&P BSE 200 TR Index</h4>
          </div>
          <div>
            <p
              className="onepager-description-text"
              style={{ color: "#999999", marginBottom: "0.4rem" }}
            >
              Exit Load
            </p>
            <h4 className="onepager-inside-card-data">
              Exit load of 1% if redeemed less than 12 months
            </h4>
          </div>
          <div>
            <p
              className="onepager-description-text"
              style={{ color: "#999999", marginBottom: "0.4rem" }}
            >
              Options Available
            </p>
            <h4 className="onepager-inside-card-data">Growth & IDCW</h4>
          </div>
        </div>

        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            width: "100%",
            marginTop: "2rem",
          }}
        >
          <div>
            <p
              className="onepager-description-text"
              style={{ color: "#999999", marginBottom: "0.4rem" }}
            >
              Plans Available
            </p>
            <h4 className="onepager-inside-card-data">Direct & Regular</h4>
          </div>
          <div>
            <p
              className="onepager-description-text"
              style={{ color: "#999999", marginBottom: "0.4rem" }}
            >
              Minimum Application - Fresh Purchase
            </p>
            <h4 className="onepager-inside-card-data">
              ₹ 1,000 and in multiples of ₹ 100 thereafter
            </h4>
          </div>
          <div>
            <p
              className="onepager-description-text"
              style={{ color: "#999999", marginBottom: "0.4rem" }}
            >
              Additional Application - Fresh Purchase
            </p>
            <h4 className="onepager-inside-card-data">
              ₹ 1,000 and in multiples of ₹ 100 thereafter
            </h4>
          </div>
        </div>

        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            width: "100%",
            marginTop: "2rem",
          }}
        >
          <div>
            <p
              className="onepager-description-text"
              style={{ color: "#999999", marginBottom: "0.4rem" }}
            >
              Minimum Application - Monthly SIP
            </p>
            <h4 className="onepager-inside-card-data">
              ₹ 1,000 per month for minimum of six month
            </h4>
          </div>
          <div>
            <p
              className="onepager-description-text"
              style={{ color: "#999999", marginBottom: "0.4rem" }}
            >
              Minimum Application - Quarterly SIP
            </p>
            <h4 className="onepager-inside-card-data">
              ₹ 1,000 per month for minimum of six Quarterly
            </h4>
          </div>
        </div>

        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            width: "100%",
            marginTop: "2rem",
          }}
        >
          <div>
            <p
              className="onepager-description-text"
              style={{ color: "#999999", marginBottom: "0.4rem" }}
            >
              Plans Available
            </p>
            <h4 className="onepager-inside-card-data">Direct & Regular</h4>
          </div>
          <div>
            <p
              className="onepager-description-text"
              style={{ color: "#999999", marginBottom: "0.4rem" }}
            >
              Minimum Application - Fresh Purchase
            </p>
            <h4 className="onepager-inside-card-data">
              ₹ 1,000 and in multiples of ₹ 100 thereafter
            </h4>
          </div>
          <div>
            <p
              className="onepager-description-text"
              style={{ color: "#999999", marginBottom: "0.4rem" }}
            >
              Additional Application - Fresh Purchase
            </p>
            <h4 className="onepager-inside-card-data">
              ₹ 1,000 and in multiples of ₹ 100 thereafter
            </h4>
          </div>
          <div>
            <p
              className="onepager-description-text"
              style={{ color: "#999999", marginBottom: "0.4rem" }}
            >
              Inception Date
            </p>
            <h4 className="onepager-inside-card-data">12-01-2009</h4>
          </div>
          <div>
            <p
              className="onepager-description-text"
              style={{ color: "#999999", marginBottom: "0.4rem" }}
            >
              Inception Date
            </p>
            <h4 className="onepager-inside-card-data">12-01-2009</h4>
          </div>
        </div>
      </div>
    </>
  );
}
