import { GET_ORG_MEMBER } from "../actions/type";
const initialState = {};
export default function GetOrgMember(state = initialState, action) {
  switch (action.type) {
    case GET_ORG_MEMBER:
      return action.payload;

    default:
      return state;
  }
}
