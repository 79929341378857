import { POST_DASHBOARD_ANALYTICS } from "../actions/type";
const initialState = {
  asset_allocation: {
    alternate: 10,
    equity: 20,
    debt: 70,
  },
  product_allocation: {
    mf: 10,
    aif: 20,
    others: 70,
  },
  liquidity: {
    liquid: 10,
    semi_liquid: 20,
    ill_liquid: 20,
  },
  top_holding: [
    {
      label: "Tata",
      type: "equity",
      value: 10,
    },
    {
      label: "Tata",
      type: "debt",
      value: 20,
    },
    {
      label: "Bajaj Ltd.",
      type: "equity",
      value: 11,
    },
    {
      label: "Avenue SuperMarkets",
      type: "others",
      value: 3.5,
    },
  ],
  mcap_allocation: {
    large_cap: 10,
    mid_cap: 20,
    small_cap: 70,
  },
  sector_allocation: {
    financials: 10,
    consumer: 20,
    information: 70,
    materials: 12,
  },
  maturity_profile: {
    ">7": 6.2,
    ">3 <=7": 8.9,
    ">1 <=3": 4,
    ">0 <=1": 2.4,
  },
  credit_quality: {
    A: 10,
    AA: 20,
    AAA: 20,
    B: 10,
    BB: 10,
    BBB: 10,
    C: 10,
    cash: 4,
    D: 5,
    SOV: 19,
  },
};
export default function PostDashboardAnalytics(state = initialState, action) {
  switch (action.type) {
    case POST_DASHBOARD_ANALYTICS:
      return action.payload;
    default:
      return state;
  }
}
