import { POST_SAVED_MARKET_FAQS } from "../actions/type";
const initialState = [];

export default function PostSavedFaq(state = initialState, action) {
  switch (action.type) {
    case POST_SAVED_MARKET_FAQS:
      return action.payload;
    default:
      return state;
  }
}
