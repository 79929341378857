import React, { useRef } from "react";
import Hourglass from "../../assets/img/wait.gif";
import Women from "../../assets/img/modal_chr.svg";
import { useSelector, useDispatch } from "react-redux";
import { PostEventLoggingData } from "../../actions/actions";
import InvestedgeModal from "../../components/modal/InvestedgeModal";
import { IoIosClose } from "react-icons/io";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";

export default function HoldingReportModal({ holdingReportModal, onClose }) {
  const dispatch = useDispatch();
  const excelRef = useRef(null);
  const holdingReport = useSelector((state) => state.dashboardHoldingReport);
  const downloadHoldingReport = async () => await excelRef.current.click();
  return (
    <InvestedgeModal
      aria-labelledby="Holding Report Modal"
      aria-describedby="Holding Report Modal"
      sx={{ height: "60vh", margin: "auto" }}
      open={holdingReportModal}
      handleclose={onClose}
    >
      <div className="flex justify-center flex-col h-full items-center">
        <div className="modal-header border-0 p-0 flex justify-between w-full">
          <h5 className="card-heading">Holding Report</h5>
          <IoIosClose onClick={onClose} size={30} />
        </div>
        <div className="w-[94%] flex justify-center h-full">
          <div className="w-[80%] h-[350px] rounded-[10px] y-8 flex flex-col items-center justify-center">
            <img
              alt="err"
              src={!holdingReport.excel ? Hourglass : Women}
              className="h-[200px] object-contain"
            />
            <h3 className="card-heading text-center my-4">
              {!holdingReport.excel
                ? "Your request is under process. Please wait for sometime.."
                : "Client's Holding Report is ready."}
            </h3>
            {holdingReport.excel && (
              <div className="w-[85%] flex justify-center">
                <InvestedgeButton
                  className="mt-4"
                  onClick={() => {
                    dispatch(
                      PostEventLoggingData({
                        module_name: "Portfolio Insights",
                        event_type: "Download button",
                        url: window.location.href,
                        note: `Download excel`,
                      })
                    );
                    downloadHoldingReport();
                  }}
                >
                  Download
                </InvestedgeButton>
              </div>
            )}
            <a href={holdingReport.excel} ref={excelRef} className="hidden">
              Holding Report
            </a>
          </div>
        </div>
      </div>
    </InvestedgeModal>
  );
}
