import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetResearch } from "../../../actions/actions";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import InvestedgeTextField from "../../../components/textfield/InvestedgeTextField";
import PublishModal from "../ResearchModal/PublishModal";
import ResearchSliderInput from "./ResearchSliderInput";

export default function SectorStylesView({
  changes,
  setChanges,
  sliderData,
  setSliderData,
  setDisplayResearchData,
}) {
  const [publishModal, setPublishModal] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.myresearchData);
  let handleReset = () => {
    if (!changes) return;
    setChanges(false);
    dispatch(GetResearch());
    setSliderData({
      ...sliderData,
      information_technology: data[0].information_technology,
      financials: data[0].financials,
      consumer_staples: data[0].consumer_staples,
      consumer_discretionary: data[0].consumer_discretionary,
      industrials: data[0].industrials,
      health_care: data[0].health_care,
      materials: data[0].materials,
      energy: data[0].energy,
      real_estate: data[0].real_estate,
      communication_services: data[0].communication_services,
      utilities: data[0].utilities,
      momentum: data[0].momentum,
      low_vol: data[0].low_vol,
      growth: data[0].growth,
      value: data[0].value,
      quality: data[0].quality,
      dividend_yield: data[0].dividend_yield,
      leverage: data[0].leverage,
      sector_view: data[0].sector_view,
      style_view: data[0].style_view,
    });
  };
  return (
    <>
      <div className="w-full">
        <div className="w-full flex justify-between">
          <div className="w-[49%]">
            <h3 className="font-inter font-medium leading-[20px] text-neutralBlack mt-6">
              Your views on different asset classes
            </h3>
            <div className="z-[10000] w-full h-[30px] pt-[0.5rem]">
              <p className="font-inter text-[13px] not-italic font-normal leading-[20px] tracking-[0em] text-left text-neutralBlack">
                Input your asset allocation view here by rating each asset or
                sub-asset on a scale of -5 to 5.
              </p>
            </div>
          </div>
          <div className="w-[49%]">
            <h3 className="font-inter font-medium leading-[20px] text-neutralBlack mt-6">
              Your views on different Styles
            </h3>
            <div className="z-[10000] w-full h-[30px] pt-[0.5rem]">
              <p className="font-inter text-[13px] not-italic font-normal leading-[20px] tracking-[0em] text-left text-neutralBlack">
                Input your asset allocation view here by rating each asset or
                sub-asset on a scale of -5 to 5.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between">
          {" "}
          <fieldset className="border border-[#e0dddd] w-[49%] mt-[22px] overflow-y-auto rounded-[10px] p-[1rem] min-h-[270px]">
            <ResearchSliderInput
              label="Information Technology"
              name="information_technology"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
            <ResearchSliderInput
              label="Financials"
              name="financials"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
            <ResearchSliderInput
              label="Consumer Staples"
              name="consumer_staples"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
            <ResearchSliderInput
              label="Consumer Discretionary"
              name="consumer_discretionary"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
            <ResearchSliderInput
              label="Industrials"
              name="industrials"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
            <ResearchSliderInput
              label="Health Care"
              name="health_care"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
            <ResearchSliderInput
              label="Materials"
              name="materials"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
            <ResearchSliderInput
              label="Energy"
              name="energy"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
            <ResearchSliderInput
              label="Real Estate"
              name="real_estate"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
            <ResearchSliderInput
              label="Communication Services"
              name="communication_services"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
            <ResearchSliderInput
              label="utilities"
              name="utilities"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
          </fieldset>
          <fieldset className="border border-[#e0dddd] w-[49%] mt-[22px] overflow-y-auto rounded-[10px] p-[1rem] min-h-[270px]">
            <ResearchSliderInput
              label="Momentum"
              name="momentum"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
            <ResearchSliderInput
              label="Low Volume"
              name="low_vol"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
            <ResearchSliderInput
              label="Growth"
              name="growth"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
            <ResearchSliderInput
              label="Value"
              name="value"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
            <ResearchSliderInput
              label="Quality"
              name="quality"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
            <ResearchSliderInput
              label="Dividend Yield"
              name="dividend_yield"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
            <ResearchSliderInput
              label="Leverage"
              name="leverage"
              sliderData={sliderData}
              setSliderData={setSliderData}
              changes={changes}
              setChanges={setChanges}
            />
          </fieldset>
        </div>
        <div className="w-full flex justify-between mt-4">
          <InvestedgeTextField
            label="Combined Sector View"
            className="w-[49%] p-[1rem]"
            multiline
            rows={12}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
              },
            }}
            value={sliderData.sector_view}
            onChange={(e) => {
              setChanges(true);
              setSliderData({ ...sliderData, sector_view: e.target.value });
            }}
          />
          <InvestedgeTextField
            label="Combined Style View"
            className="w-[49%] p-[1rem]"
            multiline
            rows={12}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
              },
            }}
            value={sliderData.style_view}
            onChange={(e) => {
              setChanges(true);
              setSliderData({ ...sliderData, style_view: e.target.value });
            }}
          />
        </div>
      </div>
      {/* <div className="w-[48%]">
      <h3 className="font-inter text-[16px] not-italic font-medium leading-[20px] tracking-[0em] text-left text-neutralBlack">
        Select pre-defined views of famous fund houses
      </h3>
      <InvestedgeTabs
        tabs={tabs}
        tabvalue={companyValue}
        handletabchange={handleCarousalClick}
        variant="scrollable"
        TabIndicatorProps={{
          children: <span className="MuiTabs-indicatorSpan" />,
        }}
        scrollButtons
        allowScrollButtonsMobile
        sx={{
          marginTop: "4px",
          "& .MuiTabs-root": {
            minHeight: "40px",
          },
          "& .MuiTab-root": {
            padding: "8px",
            minHeight: "40px",
            fontSize: 13,
          },
          "& .MuiTabs-indicator": {
            display: "flex",
            justifyContent: "center",
            backgroundColor: "transparent",
            bottom: "auto",
          },
          "& .MuiTabs-indicatorSpan": {
            maxWidth: 60,
            width: "100%",
            backgroundColor: "#1982F8",
          },
        }}
        aria-label="research entities tab"
      />
    </div> */}

      <div className="flex flex-row gap-5 mt-5 w-full justify-end">
        <InvestedgeButton
          disabled={!changes}
          onClick={() => {
            // dispatch(
            //   PostEventLoggingData({
            //     module_name: "Research Views",
            //     event_type: "Refresh",
            //     url: window.location.href,
            //     note: `Reseting Research views`,
            //   })
            // );
            handleReset();
          }}
        >
          Reset
        </InvestedgeButton>

        {/* <InvestedgeButton
          disabled={!changes}
          onClick={() => {
            if (!changes) return;
            setSaved(true);
            setDisplayResearchData(sliderData);

            var elmnt = document.getElementById("research-input-parent-screen");
            elmnt.scrollTo({ top: 810, behavior: "smooth" });
            setActiveStep(0);
            dispatch(
              PostEventLoggingData({
                module_name: "Research Views",
                event_type: "Start",
                url: window.location.href,
                note: `Starting Simulation`,
              })
            );
          }}
        >
          Simulate
        </InvestedgeButton> */}

        <InvestedgeButton
          disabled={!changes}
          onClick={() => {
            // dispatch(
            //   PostEventLoggingData({
            //     module_name: "Research Views",
            //     event_type: "Save",
            //     url: window.location.href,
            //     note: `Saving research views`,
            //   })
            // );
            setPublishModal(true);
          }}
        >
          Save
        </InvestedgeButton>
        <PublishModal
          saveType="sector"
          setChanges={setChanges}
          onClose={() => setPublishModal(false)}
          publishModal={publishModal}
          sliderData={sliderData}
          setDisplayResearchData={setDisplayResearchData}
        />
      </div>
    </>
  );
}
