import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import InvestedgeModal from "../../../../../../components/modal/InvestedgeModal";
import DebtSection from "./DebtSection";
import EquitySection from "./EquitySection";
import AlternateSection from "./AlternateSection";
import { useSelector } from "react-redux";
import { Bars } from "react-loader-spinner";
export default function AdvanceAnalytics(props) {
  const [section, setSection] = useState("Equity");
  const analytics = useSelector((state) => state.proposalAdvanceAnalytics);
  const handleSections = () => {
    switch (section) {
      case "Alternate":
        return <AlternateSection analytics={analytics} />;

      case "Debt":
        return <DebtSection analytics={analytics} />;

      case "Equity":
        return <EquitySection analytics={analytics} />;

      default:
        console.log(section);
    }
  };
  return (
    <InvestedgeModal
      aria-labelledby="Portfolio Input Modal"
      aria-describedby="Portfolio Input Modal"
      sx={{
        width: "78vw",
        marginRight: "auto",
        marginLeft: "auto",
        minHeight: "90vh",
      }}
      open={props.open}
      handleclose={props.onClose}
    >
      <div className="flex justify-between items-start">
        <h3 className="card-heading">Advance Analytics</h3>
        <IoIosClose size={30} onClick={props.onClose} />
      </div>
      <div className="w-[100%]  border-b border-[#e0dddd] mt-4">
        <div className="flex">
          <p
            className={
              section === "Equity"
                ? "border-b border-primary-200  text-primary-200 font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-left  my-0 mr-[1rem] cursor-pointer"
                : " font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-[#000] text-left  my-0 mr-[1rem] cursor-pointer"
            }
            onClick={() => setSection("Equity")}
          >
            Equity
          </p>

          <p
            className={
              section === "Debt"
                ? "border-b border-primary-200 ml-[2rem] text-primary-200 font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-left  my-0 mr-[1rem] cursor-pointer"
                : "ml-[2rem] font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-[#000] text-left  my-0 mr-[1rem] cursor-pointer"
            }
            onClick={() => setSection("Debt")}
          >
            Debt
          </p>
          <p
            className={
              section === "Alternate"
                ? "border-b border-primary-200 ml-[2rem] text-primary-200 font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-left  my-0 mr-[1rem] cursor-pointer"
                : "ml-[2rem] font-inter text-[12px] font-medium leading-[25px] tracking-[0em] text-[#000] text-left  my-0 mr-[1rem] cursor-pointer"
            }
            onClick={() => setSection("Alternate")}
          >
            Alternate
          </p>
        </div>
      </div>
      {!analytics.loading ? (
        handleSections(section)
      ) : (
        <div className="h-[500px] flex justify-center items-center w-full">
          <Bars color="#1982f8" height="60" width="60" />
        </div>
      )}
    </InvestedgeModal>
  );
}
