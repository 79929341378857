import { LOAD_PPT_API } from "../actions/type";
const initialState = false;
export default function LoadingPptReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_PPT_API:
      return action.payload;

    default:
      return state;
  }
}
