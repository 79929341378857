// import React from "react";
// import Dashboard from "../assets/img/dashboard_icon.svg";
// import ClientDetails from "../assets/img/client_details_icon.svg";
// import ProductInsights from "../assets/img/productinsights.svg";
// import PortfolioProposal from "../assets/img/portfolio_proposal.svg";
// import ProductInput from "../assets/img/product_input.svg";
// import Tools from "../assets/img/tools.svg";
// import InvestmentProfile from "../assets/img/investment_profile.svg";
// import Cashflows from "../assets/img/cashflows.svg";
// import Back from "../assets/img/back.svg";
// import * as MdIcons from "react-icons/md";
// import Goalplanning from "../assets/img/goal_planning.svg";

// import { FiChevronDown, FiChevronUp } from "react-icons/fi";

// const BackIcon = () => {
//   const styles = {
//     img: {
//       width: "20px",
//       height: "20px",
//     },
//   };
//   return <img style={styles.img} src={Back} alt="err" />;
// };
// const DashboardIcon = () => {
//   const styles = {
//     img: {
//       width: "20px",
//       height: "20px",
//     },
//   };
//   return <img style={styles.img} src={Dashboard} alt="err" />;
// };

// const ClientDetailsIcon = () => {
//   const styles = {
//     img: {
//       width: "20px",
//       height: "20px",
//     },
//   };
//   return <img style={styles.img} src={ClientDetails} alt="err" />;
// };

// const InvestmentProfileIcon = () => {
//   const styles = {
//     img: {
//       width: "20px",
//       height: "20px",
//     },
//   };
//   return <img style={styles.img} src={InvestmentProfile} alt="err" />;
// };

// const CashflowsIcon = () => {
//   const styles = {
//     img: {
//       width: "20px",
//       height: "20px",
//     },
//   };
//   return <img style={styles.img} src={Cashflows} alt="err" />;
// };

// const PortfolioProposalIcon = () => {
//   const styles = {
//     img: {
//       width: "20px",
//       height: "20px",
//     },
//   };
//   return <img style={styles.img} src={PortfolioProposal} alt="err" />;
// };

// const ToolsIcon = () => {
//   const styles = {
//     img: {
//       width: "20px",
//       height: "20px",
//     },
//   };
//   return <img style={styles.img} src={Tools} alt="err" />;
// };

// const GoalplanningIcon = () => {
//   const styles = {
//     img: {
//       width: "20px",
//       height: "20px",
//     },
//   };
//   return <img style={styles.img} src={Goalplanning} alt="err" />;
// };

// const TaxationIcon = () => {
//   const styles = {
//     img: {
//       width: "20px",
//       height: "20px",
//     },
//   };
//   return <img style={styles.img} src={Taxation} alt="err" />;
// };
export const InvestedgeGPTSidebarData = [
  {
    title: "Markets & Economy",
    path: "/investgpt/markets",
    links: {
      "/investgpt/markets": true,
    },
    locked: false,
    externalLink: false,
  },
  // {
  //   title: "Generic Questions",
  //   path: "/investgpt/generic",
  //   links: {
  //     "/investgpt/generic": true,
  //   },
  //   locked: false,
  //   externalLink: false,
  // },
  {
    title: "Products & Strategies",
    path: "/investgpt/products",
    links: {
      "/investgpt/products": true,
    },
    locked: false,
    externalLink: false,
  },
  {
    title: "Client Portfolios",
    path: "/investgpt/clients",
    links: {
      "/investgpt/clients": true,
    },
    locked: false,
    externalLink: false,
  },

  // {
  //   title: "Client Portfolio",
  //   path: "/clientportfolio",
  //   links: {
  //     "/clientportfolio": true,
  //   },
  //   locked: false,
  //   externalLink: false,
  // },
  // {
  //   title: "Cashflows",
  //   /*path: '/reports',*/
  //   mapkey: "Cashflows",
  //   externalLink: false,
  //   iconClosed: <FiChevronDown />,
  //   iconOpened: <FiChevronUp />,
  //   locked: false,
  //   // subNav: [
  //   //   {
  //   //     title: "Redeem Capital",
  //   //     path: "/cashflow/redeem",
  //   //     locked: true,
  //   //   },
  //   //   {
  //   //     title: "Deploy Capital",
  //   //     path: "/cashflow/deploy",
  //   //     locked: true,
  //   //   },
  //   // ],
  // },

  // {
  //   title: "Suitable Products",
  //   // path: "/suitableproducts",
  //   locked: true,
  //   externalLink: false,
  // },
  // {
  //   title: "Portfolio Review",
  //   path: "/portfolioproposal",
  //   links: {
  //     "/portfolioproposal": true,
  //   },
  //   locked: false,
  //   externalLink: true,
  // },
  // {
  //   title: "Sales Pitch (Beta)",
  //   path: "/salespitch",
  //   links: {
  //     "/salespitch": true,
  //   },
  //   locked: false,
  //   externalLink: false,
  // },
  // {
  //   title: "Goal Mapping",
  //   locked: false,
  //   path: "/goalplanning",
  //   links: {
  //     "/goalplanning": true,
  //     "/goalplanning/view": true,
  //     "/goalplanning/create": true,
  //   },
  // },
  // {
  //   title: "Meeting Notes",
  //   path: "/meetingnotes",
  //   links: {
  //     "/meetingnotes": true,
  //   },
  //   locked: false,
  //   externalLink: false,
  // },
  // {
  //   title: "BeyondIRR Copilot",
  //   path: "/beyondai",
  //   links: {
  //     "/beyondai": true,
  //   },
  //   locked: false,
  //   externalLink: false,
  // },
  // {
  //   title: "Taxation",
  //   locked: true,
  //   icon: <TaxationIcon />,
  // },
];
