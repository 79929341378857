import React from "react";
import AfterloginApp from "../AfterloginApp";
import ClientContent from "./ClientContent";
import "../../static/css/existing/dashboard_styles.css";
export default function ClientInput() {
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Clients Input"}
        description={
          "Browse for portfolio insights, actionable, cash flow planning, and much more"
        }
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <ClientContent />
        </div>
      </div>
    </div>
  );
}
