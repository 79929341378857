import { GET_MF_CENTRAL_OTP } from "../actions/type";
const initialState = {
  loading: true,
};
export default function getMFCentralOtp(state = initialState, action) {
  switch (action.type) {
    case GET_MF_CENTRAL_OTP:
      return action.payload;
    default:
      return state;
  }
}
