import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import Login from "./landing/Login";
import Insights from "./landing/Insights";
import Individualblog from "./landing/IndividualBlog";
import SuitableProducts from "./clientdashboard/suitableproducts/SuitableProducts";
import Termsofuse from "./landing/TermsOfUse";
import Dashboard from "./rmdashboard/dashboard/Dashboard";
import ResearchInput from "./rmdashboard/researchinput/ResearchInput";
import ProductInput from "./rmdashboard/productinput/ProductInput";
import PortfolioProposal from "./rmdashboard/portfolioproposal/PortfolioProposal";
import ClientInput from "./rmdashboard/clientinput/ClientInput";
import About from "./settings/about/About";
import General from "./settings/general/General";
import Comparisons from "./rmdashboard/productinsights/ComparisonFiles/Comparisons";
import Access from "./settings/access/Access";
import Module from "./settings/module/Module";
import ScrollToTop from "./landing/ScrollToTop";
import Coverfaqpage from "./rmdashboard/faq/CoverFaqPage";
import Covermarketmodule from "./rmdashboard/marketupdates/CoverMarketModule/CoverMarketModule";
import Latestmarketevent from "./rmdashboard/marketupdates/LatestMarketEvents/LatestMarketEvents";
import Marketdashboard from "./rmdashboard/marketupdates/MarketDashboard/MarketDashboard";
import IndividualDetails from "./rmdashboard/clientinput/individualdetails/IndividualDetails";
import FamilyDetails from "./rmdashboard/clientinput/familydetails/FamilyDetails";
import InvestmentProfile from "./rmdashboard/clientinput/investmentprofile/InvestmentProfile";
import UpdatePortfolios from "./rmdashboard/clientinput/updateportfolios/UpdatePortfolios";
import ClientPortfolio from "./clientdashboard/clientportfolio/UpdatePortfolios";
import UpdateInputScreen from "./rmdashboard/clientinput/updateportfolios/portfolioinput2/UpdateInputScreen";
import ClientPortfolioUpdateScreen from "./clientdashboard/clientportfolio/portfolioinput2/UpdateInputScreen";
import Onepager from "./rmdashboard/productinsights/OnePager";
import Clientdashboard from "./clientdashboard/dashboard/ClientDashboard";
import GenerateOTP from "./forgotpassword/generateOTP";
import ConfirmOTP from "./forgotpassword/confirmOTP";
import Changepassword from "./forgotpassword/changepassword";
import Clientdetails from "./clientdashboard/details/ClientDetails";
import ClientInvestmentProfile from "./clientdashboard/investment profile/ClientInvestmentProfile";
import ProductInsights from "./rmdashboard/productinsights/ProductInsights";
import Screeners from "./rmdashboard/productinsights/ScreenerFiles/Screeners";
import Search from "./rmdashboard/search/Search";
import SearchResults from "./rmdashboard/search/SearchResults";
import SearchViewQuestion from "./rmdashboard/search/SearchViewQuestion";
import CreatePortfolioProposal from "./rmdashboard/portfolioproposal/createproposal/PortfolioProposalStepperSection";
import ReviewePortfolioProposal from "./rmdashboard/portfolioproposal/reviewproposal/ReviewPortfolio";
import ReviewePortfolioProposalStepper from "./rmdashboard/portfolioproposal/reviewproposal/ReviewPortfolioStepper";
import SimulateProposalStepperSection from "./rmdashboard/portfolioproposal/simulateproposal/SimulateProposalStepper";
import CreatePitchbook from "./rmdashboard/portfolioproposal/createpitchbook/CreatePitchbook";
import RedeemCapital from "./clientdashboard/cashflows/redeem/RedeemCapital";
import DeployCapital from "./clientdashboard/cashflows/deploy/DeployCapital";
import ProductOnepager from "./rmdashboard/Productonepager/ProductOnepager";
import Home from "./newlanding";
import SnackbarCustomized from "./components/Snackbar/Snackbar";
import "./assets/css/styles.css";
import Calculators from "./rmdashboard/calculators/Calculators";
import MarriageExpensePlanner from "./rmdashboard/calculators/MarriageExpensePlanner/MarriageExpensePlanner";
import ChildEducationPlanner from "./rmdashboard/calculators/ChildEducationPlanner/ChildEducationPlanner";
import RetirementPlanner from "./rmdashboard/calculators/RetirementPlanner/RetirementPlanner";
import WealthCreation from "./rmdashboard/calculators/WealthCreation/WealthCreation";
import BuildYourOwnGoal from "./rmdashboard/calculators/BuildYourOwnGoal/BuildYourOwnGoal";
import AutomatedInvestmentPlanner from "./rmdashboard/calculators/AutomatedInvestmentPlanner/AutomatedInvestmentPlanner";
import AutomatedSwitchPlanner from "./rmdashboard/calculators/AutomatedSwitchPlanner/AutomatedSwitchPlanner";
import AutomatedCashflowPlanner from "./rmdashboard/calculators/AutomatedCashflowPlanner/AutomatedCashflowPlanner";
import SipProposalStepper from "./rmdashboard/portfolioproposal/sipproposal/SipProposalStepper";
import ModelPortfolio from "./rmdashboard/modelportfolio/ModelPortfolio";
import DetailedPortfolo from "./rmdashboard/modelportfolio/DetailedPortfolio";
import SalesPitch from "./clientdashboard/salespitch/SalesPitch";
import GoalPlanning from "./clientdashboard/goalplanning/GoalPlanning";
import GoalDetails from "./clientdashboard/goalplanning/GoalDetails";
import ViewGoal from "./clientdashboard/goalplanning/ViewGoal/ViewGoal";
import LumpsumPlusSip from "./rmdashboard/portfolioproposal/lumpsumplussip/LumpsumPlusSip";
import MeetingNotes from "./clientdashboard/meetingnotes/MeetingNotes";
import BeyondAI from "./clientdashboard/beyondai/BeyondAI";
import MutualFundAnalytics from "./rmdashboard/portfolioproposal/mutualfund_analytics/MutualFundAnalytics";
import MutualFundAnalyticsTemplate from "./rmdashboard/portfolioproposal/mutualfund_analytics/MutualFundAnalyticsTemplate";
import MutualFundAnalyticsPPT from "./rmdashboard/portfolioproposal/mutualfund_analytics/MutualFundAnalyticsPPT";
import Markets from "./rmdashboard/investedgeGPT/markets";
import Products from "./rmdashboard/investedgeGPT/products";
import Clients from "./rmdashboard/investedgeGPT/clients";
import Generic from "./rmdashboard/investedgeGPT/generic";
import AcquireClients from "./rmdashboard/acquire-clients/AcquireClients";
import Type from "./rmdashboard/acquire-clients/Type";
import Analytics from "./rmdashboard/acquire-clients/Analytics";
import AcquireDashboard from "./rmdashboard/acquire-clients/AcquireDashboard";
import Authenticate from "./rmdashboard/thirdPartyLogin";
if (process.env.NODE_ENV !== "development") console.log = function () {};
function App() {
  return (
    <>
      <Router>
        <ScrollToTop>
          <Switch>
            {/* <Route exact path="/home" component={Mainsection} /> */}
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/newsletter" component={Insights} />
            <Route
              exact
              path="/individualblog/:id"
              component={Individualblog}
            />
            <Route exact path="/termsofuse" component={Termsofuse} />
            <Route exact path="/authenticate/:otc" component={Authenticate} />
            <ProtectedRoute
              exact
              path="/search"
              component={Search}
              module="common"
            />
            <ProtectedRoute
              exact
              path="/searchresults"
              component={SearchResults}
              module="common"
            />
            <ProtectedRoute
              exact
              path="/searchviewquestion"
              component={SearchViewQuestion}
              module="common"
            />
            <ProtectedRoute
              exact
              path="/investgpt/markets"
              component={Markets}
              module="common"
            />
            <ProtectedRoute
              exact
              path="/investgpt/products"
              component={Products}
              module="common"
            />
            <ProtectedRoute
              exact
              path="/investgpt/generic"
              component={Generic}
              module="common"
            />
            <ProtectedRoute
              exact
              path="/investgpt/clients"
              component={Clients}
              module="common"
            />
            <ProtectedRoute
              exact
              path="/dashboard"
              component={Dashboard}
              module="crm"
            />
            <Route exact path="/research" component={ResearchInput} />
            <ProtectedRoute
              exact
              path="/productinput"
              component={ProductInput}
              module="product_input"
            />
            <ProtectedRoute
              exact
              path="/portfolioproposal"
              component={PortfolioProposal}
              module="port_analytics"
            />
            <ProtectedRoute
              exact
              path="/portfolioproposal/create"
              component={CreatePortfolioProposal}
              module="port_analytics"
            />
            <ProtectedRoute
              exact
              path="/portfolioproposal/simulate"
              component={SimulateProposalStepperSection}
              module="port_analytics"
            />

            <ProtectedRoute
              exact
              path="/portfolioproposal/review"
              component={ReviewePortfolioProposal}
              module="port_analytics"
            />
            <ProtectedRoute
              exact
              path="/portfolioproposal/reviewstepper"
              component={ReviewePortfolioProposalStepper}
              module="port_analytics"
            />
            <ProtectedRoute
              exact
              path="/portfolioproposal/createpitchbook"
              component={CreatePitchbook}
              module="port_analytics"
            />
            <ProtectedRoute
              exact
              path="/portfolioproposal/sipproposal"
              component={SipProposalStepper}
              module="port_analytics"
            />
            <ProtectedRoute
              exact
              path="/portfolioproposal/lumpsumandsip"
              component={LumpsumPlusSip}
              module="port_analytics"
            />
            <ProtectedRoute
              exact
              path="/portfolioproposal/mfanalytics"
              component={MutualFundAnalytics}
              module="port_analytics"
            />
            <ProtectedRoute
              exact
              path="/portfolioproposal/mfanalytics/template"
              component={MutualFundAnalyticsTemplate}
              module="port_analytics"
            />
            <ProtectedRoute
              exact
              path="/portfolioproposal/mfanalytics/files"
              component={MutualFundAnalyticsPPT}
              module="port_analytics"
            />
            <ProtectedRoute
              exact
              path="/clientinput"
              component={ClientInput}
              module="client_input"
            />
            <ProtectedRoute
              exact
              path="/settings/about"
              component={About}
              module="common"
            />
            <ProtectedRoute
              exact
              path="/settings/general"
              component={General}
              module="common"
            />
            <ProtectedRoute
              exact
              path="/settings/access"
              component={Access}
              module="common"
            />
            <ProtectedRoute
              exact
              path="/settings/module"
              component={Module}
              module="common"
            />
            <ProtectedRoute
              exact
              path="/faq"
              component={Coverfaqpage}
              module="research"
            />
            <Route
              exact
              path="/productinsights/suitableproducts"
              component={SuitableProducts}
            />
            <ProtectedRoute
              exact
              path="/marketmodule"
              component={Covermarketmodule}
              module="research"
            />
            <ProtectedRoute
              exact
              path="/latestmarketevent"
              component={Latestmarketevent}
              module="research"
            />
            <ProtectedRoute
              exact
              path="/marketdashboard"
              component={Marketdashboard}
              module="research"
            />

            <ProtectedRoute
              exact
              path="/clientinput/familydetails"
              component={FamilyDetails}
              module="client_input"
            />
            <ProtectedRoute
              exact
              path="/clientinput/individualdetails"
              component={IndividualDetails}
              module="client_input"
            />
            <ProtectedRoute
              exact
              path="/clientinput/investmentprofile"
              component={InvestmentProfile}
              module="client_input"
            />
            <ProtectedRoute
              exact
              path="/clientinput/updateportfolios"
              component={UpdatePortfolios}
              module="client_input"
            />
            <ProtectedRoute
              exact
              path="/clientinput/updateportfoliomodal"
              component={UpdateInputScreen}
              module="client_input"
            />
            <ProtectedRoute
              exact
              path="/onepager"
              component={Onepager}
              module="research"
            />
            <ProtectedRoute
              exact
              path="/clientdashboard"
              component={Clientdashboard}
              module="client_dashboard"
            />
            <ProtectedRoute
              exact
              path="/clientdetails"
              component={Clientdetails}
              module="client_dashboard"
            />
            <ProtectedRoute
              exact
              path="/clientinvestmentprofile"
              component={ClientInvestmentProfile}
              module="client_dashboard"
            />
            <ProtectedRoute
              exact
              path="/clientportfolio"
              component={ClientPortfolio}
              module="client_dashboard"
            />
            <ProtectedRoute
              exact
              path="/calculators"
              component={Calculators}
              module="calculators"
            />
            <ProtectedRoute
              exact
              path="/calculator/marriage_expense_planner"
              component={MarriageExpensePlanner}
              module="calculators"
            />
            <ProtectedRoute
              exact
              path="/calculator/child_education_planner"
              component={ChildEducationPlanner}
              module="calculators"
            />
            <ProtectedRoute
              exact
              path="/calculator/retirement_planner"
              component={RetirementPlanner}
              module="calculators"
            />
            <ProtectedRoute
              exact
              path="/calculator/wealth_creation"
              component={WealthCreation}
              module="calculators"
            />
            <ProtectedRoute
              exact
              path="/calculator/build_your_own_goal"
              component={BuildYourOwnGoal}
              module="calculators"
            />
            <ProtectedRoute
              exact
              path="/calculator/automated_investment_planner"
              component={AutomatedInvestmentPlanner}
              module="calculators"
            />
            <ProtectedRoute
              exact
              path="/calculator/automated_switch_planner"
              component={AutomatedSwitchPlanner}
              module="calculators"
            />
            <ProtectedRoute
              exact
              path="/calculator/automated_cashflow_planner"
              component={AutomatedCashflowPlanner}
              module="calculators"
            />
            <ProtectedRoute
              exact
              path="/modelportfolio"
              component={ModelPortfolio}
              module="port_analytics"
            />
            <ProtectedRoute
              exact
              path="/modelportfolio/open"
              component={DetailedPortfolo}
              module="port_analytics"
            />
            <ProtectedRoute
              exact
              path="/clientportfoliomodal"
              component={ClientPortfolioUpdateScreen}
              module="client_dashboard"
            />
            <ProtectedRoute
              exact
              path="/cashflow/redeem"
              component={RedeemCapital}
              module="client_dashboard"
            />
            <ProtectedRoute
              exact
              path="/cashflow/deploy"
              component={DeployCapital}
              module="client_dashboard"
            />
            <ProtectedRoute
              exact
              path="/suitableproducts"
              component={SuitableProducts}
              module="client_dashboard"
            />
            <ProtectedRoute
              exact
              path="/salespitch"
              component={SalesPitch}
              module="client_dashboard"
            />
            <ProtectedRoute
              exact
              path="/goalplanning"
              component={GoalPlanning}
              module="client_dashboard"
            />
            <ProtectedRoute
              exact
              path="/goalplanning/create"
              component={GoalDetails}
              module="client_dashboard"
            />
            <ProtectedRoute
              exact
              path="/goalplanning/view"
              component={ViewGoal}
              module="client_dashboard"
            />
            <ProtectedRoute
              exact
              path="/meetingnotes"
              component={MeetingNotes}
              module="client_dashboard"
            />
            <ProtectedRoute
              exact
              path="/beyondai"
              component={BeyondAI}
              module="client_dashboard"
            />
            <ProtectedRoute
              exact
              path="/instant-review"
              component={AcquireClients}
              module="common"
            />
            <ProtectedRoute
              exact
              path="/instant-review/dashboard"
              component={AcquireDashboard}
              module="common"
            />
            <Route exact path="/instant-review/:rmID/:type" component={Type} />
            <Route
              exact
              path="/instant-review/analytics"
              component={Analytics}
            />
            <Route exact path="/generateotp" component={GenerateOTP} />
            <Route exact path="/confirmotp" component={ConfirmOTP} />
            <Route exact path="/changepassword" component={Changepassword} />
            <Route exact path="/productinsights" component={ProductInsights} />
            <Route
              exact
              path="/productinsights/screener"
              component={Screeners}
            />

            <Route
              exact
              path="/productinsights/comparison"
              component={Comparisons}
            />
            <Route exact path="/productonepager" component={ProductOnepager} />

            <Route
              path="/about"
              component={() => {
                window.location.replace(
                  process.env.REACT_APP_beyondirrhome + "about"
                );

                return null;
              }}
            />
            <Route
              path="/careers"
              component={() => {
                window.open(
                  "https://peppermint-prose-e38.notion.site/BeyondIRR-is-Hiring-63983dd1646045949aeff63bee684422",
                  "_blank"
                );
                window.history.back();
                return null;
              }}
            />
            <Route
              path="/mediakit"
              component={() => {
                window.open(
                  "https://dev.investedge.tech/static/media/mediakit.3479ea02d3c48194cf57.pdf",
                  "_blank"
                );
                window.history.back();
                return null;
              }}
            />
            <Route
              path="/investlane"
              component={() => {
                window.location.replace(process.env.REACT_APP_investlane);
                return null;
              }}
            />
            <Route
              path="/terms"
              component={() => {
                window.location.replace(
                  process.env.REACT_APP_beyondirrhome + "terms"
                );
                return null;
              }}
            />
            <Route
              path="/privacy"
              component={() => {
                window.location.replace(
                  process.env.REACT_APP_beyondirrhome + "privacy"
                );
                return null;
              }}
            />
            <Route
              path="/news"
              component={() => {
                window.location.replace(
                  process.env.REACT_APP_beyondirrhome + "home#featuredon"
                );
                return null;
              }}
            />
            <Route
              path="/insights"
              component={() => {
                window.location.replace(
                  process.env.REACT_APP_beyondirrhome + "home#insights"
                );
                return null;
              }}
            />
            <Route
              path="/investalpha"
              component={() => {
                window.location.replace(
                  process.env.REACT_APP_beyondirrhome + "home#investalpha"
                );
                return null;
              }}
            />
            <Route
              path="/contactus"
              component={() => {
                window.open("mailto:contact@beyondirr.tech");
                window.history.back();
                return null;
              }}
            />
            <Route
              path="/insights"
              component={() => {
                window.location.href =
                  process.env.REACT_APP_beyondirrhome + "home#insights";
                return null;
              }}
            />
            <Redirect to="/" />
          </Switch>
        </ScrollToTop>
        <SnackbarCustomized />
      </Router>
    </>
  );
}

export default App;
