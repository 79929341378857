import { GET_SETTINGS_GENERAL } from "../actions/type";
const initialState = {
  username: "",
  email: "",
  organization: "",
  role: "",
  product_input: false,
  research_input: false,
  client_input: false,
  product: false,
  research: false,
  client_dashboard: false,
  port_analytics: false,
  crm: false,
  client_module: false,
  contact: "",
  dob: new Date(),
  profile_pic: null,
};
export default function GetSettingsGeneral(state = initialState, action) {
  switch (action.type) {
    case GET_SETTINGS_GENERAL:
      return action.payload;

    default:
      return state;
  }
}
