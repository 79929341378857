import { POST_DASHBOARD_PERFORMANCE } from "../actions/type";
const initialState = [];
export default function PostDashboardPerformance(state = initialState, action) {
  switch (action.type) {
    case POST_DASHBOARD_PERFORMANCE:
      return action.payload;
    default:
      return state;
  }
}
