import React from "react";
import NavigatorImg from "../assets/img/Navigator.svg";
import Navigation from "../assets/img/Navigation.svg";
import ActiveNavigation from "../assets/img/active_navigation.svg";
import VisibilitySensor from "react-visibility-sensor";
export default function Navigator({ setBg, setBackground, bg }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 md:px-20">
      <div className="md:flex flex-col justify-center hidden">
        <VisibilitySensor partialVisibility>
          <>
            <div
              className="w-[200px] flex pb-[16px] mb-8"
              style={{
                background: bg[0],
                transitionProperty: "width,background",
                transitionDuration: "0.2s,0.2s",
                transitionTimingFunction: "linear",
              }}
            >
              <img
                src={bg[0].length ? ActiveNavigation : Navigation}
                alt="err"
              />
              <p
                className="font-montserrat mb-0 ml-2 text-xl"
                style={
                  bg[0].length
                    ? { color: "#FCFCFD", fontWeight: 600 }
                    : { color: "7777E91" }
                }
              >
                Navigator
              </p>
            </div>
            <h2 className="hidden md:block font-montserrat font-medium text-4xl text-[#FCFCFD] leading-[54px]">
              Navigate Client Meetings and Leave Them Impressed
            </h2>
            <p className="font-montserrat text-[#B1B5C4] font-normal text-sm md:text-xl mt-[16px] md:mt-6 md:px-0 px-[20px]">
              Go through the pre-meeting preparation document to navigate client
              meetings your way. Whether it's about how markets are going to
              impact client portfolio or about how a products fills the gaps in
              client portfolios - it's focussed around having conversations
              relevant for that client. <br /> <br />
              Be it with a savvy client or a client that asks many details – you
              are always well prepared to exceed client expectations.
            </p>
          </>
        </VisibilitySensor>
      </div>
      <div className="md:hidden flex flex-col justify-center">
        <VisibilitySensor partialVisibility>
          <>
            <div
              className="w-[200px] flex pb-[16px] mt-[16px] ml-[16px]"
              style={{
                background: bg[0],
                transitionProperty: "width,background",
                transitionDuration: "0.2s,0.2s",
                transitionTimingFunction: "linear",
              }}
            >
              <img
                src={bg[0].length ? ActiveNavigation : Navigation}
                alt="err"
              />
              <p
                className="font-montserrat mb-0 ml-2"
                style={
                  bg[0].length
                    ? { color: "#FCFCFD", fontWeight: 600 }
                    : { color: "7777E91" }
                }
              >
                Navigator
              </p>
            </div>
            <h2 className="md:hidden font-montserrat font-medium text-2xl md:text-4xl text-[#FCFCFD] mt-6 leading-9 sm:leading-[54px] md:px-0 px-[20px]">
              Navigate Client Meetings and Leave Them Impressed
            </h2>
            <p className="font-montserrat text-[#B1B5C4] font-normal text-sm md:text-xl mt-[16px] md:mt-6 md:px-0 px-[20px]">
              Go through the pre-meeting preparation document to navigate client
              meetings your way. Whether it's about how markets are going to
              impact client portfolio or about how a products fills the gaps in
              client portfolios - it's focussed around having conversations
              relevant for that client. <br /> <br />
              Be it with a savvy client or a client that asks many details – you
              are always well prepared to exceed client expectations.
            </p>
          </>
        </VisibilitySensor>
      </div>
      <div className="bg-[url('../img/red_mesh.svg')] bg-cover flex justify-center items-center bg-center">
        <img src={NavigatorImg} alt="err" />
      </div>
    </div>
  );
}
