import React from "react";
import { whatsBad } from "../onePagerConsts";
import "../../../static/css/existing/dashboard_styles.css";

export default function whatsBadComponent() {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {Object.entries(whatsBad.data)
          .slice(0, -1)
          .map(([key, value]) => (
            <div
              style={{
                padding: "1rem",
                backgroundColor: "#fff",
                borderRadius: "6px",
                boxShadow:
                  " 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(0, 0, 0, 0.05)",
                minheight: "274px",
                minWidth: "49.2%",
              }}
            >
              <h5 className="onepager-small-heading mb-4">{value["title"]}</h5>
              <p className="onepager-description-text mt-1 ">{value["text"]}</p>
              <p
                className="mb-2 onepager-description-text"
                style={{ color: "#0000F5" }}
              >
                Learn more
              </p>
            </div>
          ))}
      </div>
      <div
        style={{
          padding: "1rem",
          backgroundColor: "#fff",
          borderRadius: "6px",
          boxShadow:
            " 2px 2px 6px rgba(0, 0, 0, 0.2), -2px -2px 6px rgba(0, 0, 0, 0.05)",
          minheight: "274px",
          marginTop: "1.5rem",
        }}
      >
        <h5 className="onepager-small-heading">
          {whatsBad.data[whatsBad.data.length - 1]["title"]}
        </h5>
        <p className="onepager-description-text mt-4">
          {whatsBad.data[whatsBad.data.length - 1]["text"]}
        </p>
        <p
          className="mb-2 onepager-description-text"
          style={{ color: "#0000F5" }}
        >
          Learn more
        </p>
      </div>
    </>
  );
}
