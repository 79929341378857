import { GET_SIP_ADVANCE_ANALYTICS } from "../actions/type";
const initialState = {
  equity_cagr: 0.28102675271601807,
  equity_bm_cagr: 0.25090799331665037,
  equity_category_allocation: {
    "Flexi Cap": 0.27888323339713994,
    ETF: 0.16393442622950816,
    "Multi Cap": 0.16393442622950813,
    "Pharma & Health Care": 0.1639344262295081,
    "FoFs (Overseas)": 0.08606557377049183,
    Others: 0.14324791414384408,
  },
  equity_product_allocation: {
    "Mutual Fund": 1.0,
  },
  full_sector_allocation: {
    "Health Care": 0.2562940036573252,
    Financials: 0.25120566390713417,
    Industrials: 0.10050624843167878,
    Materials: 0.09586499609101654,
    "Consumer Discretionary": 0.08753629413579035,
    "Consumer Staples": 0.07040976166230023,
    Energy: 0.044958145994652234,
    "Information Technology": 0.03888923436996799,
    Utilities: 0.02949640509397072,
    "Communication Services": 0.020238217142890966,
    "Real Estate": 0.004601029513272696,
  },
  geographical_allocation: {
    "Domestic Equities": 0.7806262312512386,
    "Overseas Equities": 0.21937376874876138,
  },
  equity_products_count: 8,
  debt_cagr: 0.06539327561929503,
  debt_bm_cagr: 0.05938215039540151,
  debt_category_allocation: {
    "Corporate Bond": 0.5698005698005701,
    "Short Duration": 0.1452991452991449,
    ETF: 0.07186783257141309,
    "Debt Oriented": 0.21303245232887186,
  },
  debt_product_allocation: {
    "Mutual Fund": 1.0,
  },
  debt_products_count: 8,
  alternate_cagr: 0.09,
  alternate_bm_cagr: 0.05370800018310547,
  alternate_category_allocation: {
    ETF: 1.0,
  },
  alternate_product_allocation: {
    "Mutual Fund": 1.0,
  },
  alternate_products_count: 1,
  loading: true,
};
export default function getSIPAdvanceAnalytics(state = initialState, action) {
  switch (action.type) {
    case GET_SIP_ADVANCE_ANALYTICS:
      return action.payload;
    default:
      return state;
  }
}
