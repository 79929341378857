import React from "react";
import { Bar } from "react-chartjs-2";
const BarChart = ({ data }) => {
  return (
    <>
      <div>
        <Bar
          data={data}
          height={336}
          options={{
            maintainAspectRatio: false,
            cornerRadius: 20,
            legend: {
              display: false,
            },
            layout: {
              padding: {
                left: 0,
                right: 0,
                top: 30,
                bottom: 0,
              },
            },
            scales: {
              yAxes: [
                {
                  gridLines: {
                    drawOnChartArea: false,
                  },
                  ticks: {
                    beginAtZero: true,
                    callback: function (value) {
                      if (value % 1 === 0) {
                        return value;
                      }
                    },
                  },
                },
              ],
              xAxes: [
                {
                  gridLines: {
                    drawOnChartArea: false,
                  },
                },
              ],
            },
            plugins: {
              labels: {
                // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
                render: "value",
                fontColor: "#000",
                position: "default",
                outsidePadding: 14,
                textMargin: 3,
              },
              datalabels: {
                display: false,
              },
            },
          }}
        />
      </div>
    </>
  );
};
export default BarChart;
