import { POST_SETTINGS_GENERAL } from "../actions/type";
const initialState = null;
export default function PostSettingsGeneralReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case POST_SETTINGS_GENERAL:
      return action.payload;
    default:
      return state;
  }
}
