import React, { useEffect } from "react";
import AfterloginApp from "../../rmdashboard/AfterloginApp";
import UpdatePortfoliosContent from "./UpdatePortfoliosContent";
import {
  GetDashboardPortfolioList,
  GetReviewPortfolioSummary,
} from "../../actions/actions";
import { useDispatch, useSelector } from "react-redux";
import Clientsidebar from "../ClientSidebar";
export default function UpdatePortfolios() {
  const portfolioUpload = useSelector(
    (store) => store.postClientInputUploadFile
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      GetDashboardPortfolioList({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    dispatch(
      GetReviewPortfolioSummary({
        params: {
          client_id: localStorage.getItem("clientId"),
        },
      })
    );
    // eslint-disable-next-line
  }, [portfolioUpload]);
  const portfolioValue = useSelector(
    (store) => store.dashboardPortfolioPriceList.total_portfolio_value
  );
  const familyMembers = [
    {
      name: localStorage.getItem("clientName"),
      portfolio_value: portfolioValue || 0,
    },
  ];
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp />
      <div className="loggedinscreen-container pt-0">
        <div className="loggedinscreen flex flex-row justify-between">
          <Clientsidebar />
          <div className=" p-[1rem] bg-[#fff] rounded-[0.5rem] min-h-full w-[79%] ml-0 mt-4">
            <UpdatePortfoliosContent familyMembers={familyMembers} />
          </div>
        </div>
      </div>
    </div>
  );
}
