export const SUBMIT_CONTACT = "SUBMIT_CONTACT";
export const LOGIN_USER = "LOGIN_USER";
export const THIRD_PARTY_LOGIN = "THIRD_PARTY_LOGIN";
export const INVALID_CRED = "INVALID_CRED";
export const GET_ERRORS = "GET_ERRORS";
export const USER_DETAILS = "USER_DETAILS";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const INVALID_EMAIL = "INVALID_EMAIL";
export const INVALID_OTP = "INVALID_OTP";
export const GET_MARKET_EVENTS = "GET_MARKET_EVENTS";
export const GET_MARKET_FAQS = "GET_MARKET_FAQS";
export const GET_MARKET_SECTOR = "GET_MARKET_SECTOR";
export const GET_SAVED_MARKET_FAQS = "GET_SAVED_MARKET_FAQS";
export const GET_TRENDING_MARKET_FAQS = "GET_TRENDING_MARKET_FAQS";
export const POST_SAVED_MARKET_FAQS = "POST_SAVED_MARKET_FAQS";
export const GET_MARKET_EXPERTS = "GET_MARKET_EXPERTS";
export const GET_MARKET_EXPERTS_CONSOLIDATED =
  "GET_MARKET_EXPERTS_CONSOLIDATED";
export const GET_MARKET_CONVERSATION = "GET_MARKET_CONVERSATION";
export const GET_RESEARCH = "GET_RESEARCH";
export const GET_FUNDHOUSE_RESEARCH = "GET_FUNDHOUSE_RESEARCH";
export const GET_IPS_DATA = "GET_IPS_DATA";
export const GET_SIP_IPS_DATA = "GET_SIP_IPS_DATA";
export const EDIT_IPS_DATA = "EDIT_IPS_DATA";
export const POST_RESEARCH = "POST_RESEARCH";
export const POST_RESEARCH_ASSETS = "POST_RESEARCH_ASSETS";
export const LOAD_PRODUCT_INPUT = "LOAD_PRODUCT_INPUT";
export const GET_PRODUCT_INPUT = "GET_PRODUCT_INPUT";
export const RESET_PRODUCT_INPUT = "RESET_PRODUCT_INPUT";
export const POST_PRODUCT_INPUT = "POST_PRODUCT_INPUT";
export const POST_SETTINGS = "POST_SETTINGS";
export const GET_ORG = "GET_ORG";
export const GET_ORG_MEMBER = "GET_ORG_MEMBER";
export const UPDATE_CLIENT_DATA = "UPDATE_CLIENT_DATA";
// AUM Types
export const GET_ANNUAL_TARGET = "GET_ANNUAL_TARGET";
export const GET_AUM_BREAKUP = "GET_AUM_BREAKUP";
export const GET_AUM_BREAKUP_PRODUCT = "GET_AUM_BREAKUP_PRODUCT";
export const GET_AUM_BREAKUP_ASSET = "GET_AUM_BREAKUP_ASSET";
export const GET_AUM_BREAKUP_CLIENT = "GET_AUM_BREAKUP_CLIENT";
export const GET_BIRTHDAY_THIS_MONTH = "GET_BIRTHDAY_THIS_MONTH";
export const GET_DASHBOARD_YOUR_CLIENT = "GET_DASHBOARD_YOUR_CLIENT";
export const GET_DASHBOARD_ALL_CLIENT = "GET_DASHBOARD_ALL_CLIENT";
// Popular Products Types
export const GET_POPULAR_PRODUCTS = "GET_POPULAR_PRODUCTS";
// Settings types
export const GET_SETTINGS_ABOUT = "GET_SETTINGS_ABOUT";
export const GET_SETTINGS_GENERAL = "GET_SETTINGS_GENERAL";
export const GET_SETTINGS_MODULE = "GET_SETTINGS_MODULE";
export const POST_SETTINGS_GENERAL = "POST_SETTINGS_GENERAL";
export const GET_SETTINGS_RMINFO = "GET_SETTINGS_RMINFO";
export const GET_SETTINGS_OPERATIONS = "GET_SETTINGS_OPERATIONS";
export const GET_SETTINGS_TEAMLEAD = "GET_SETTINGS_TEAMLEAD";
export const GET_SETTINGS_RMS_ARRAY = "GET_SETTINGS_RMS_ARRAY";
export const GET_SETTINGS_OPERATIONS_ARRAY = "GET_SETTINGS_OPERATIONS_ARRAY";
export const PATCH_SETTINGS_TEAMLEAD = "PATCH_SETTINGS_TEAMLEAD ";
// proposal types
export const GET_ALLOCATION_PREFERENCE = "GET_ALLOCATION_PREFERENCE";
export const GET_ANALYTICS_API = "GET_ANALYTICS_API";
export const LOAD_PPT_API = "LOAD_PPT_API";
export const GET_PPT_API = "GET_PPT_API";
export const GET_PROPOSAL_PREVIOUS_HISTORY = "GET_PROPOSAL_PREVIOUS_HISTORY";
export const GET_PROPOSAL_ADVANCE_ANALYTICS = "GET_PROPOSAL_ADVANCE_ANALYTICS";
//client input types
export const GET_CLIENTS = "GET_CLIENTS";
export const GET_RMS = "GET_RMS";
export const POST_CLIENT_INPUT_NEW = "POST_CLIENT_INPUT_NEW";
export const POST_CLIENT_INPUT_PROFILE_PICTURE =
  "POST_CLIENT_INPUT_PROFILE_PICTURE";
export const GET_CLIENT_INPUT_TEMPLATE = "GET_CLIENT_INPUT_TEMPLATE";
export const POST_CLIENT_INPUT_UPLOAD_FILE = "POST_CLIENT_INPUT_UPLOAD_FILE";
// product insightsData
export const GET_COMPARISON_DATA = "GET_COMPARISON_DATA";
// productreckonerData
export const GET_PRODUCT_RECKONER_DATA = "GET_PRODUCT_RECKONER_DATA";
// productonepager
export const GET_PRODUCTONEPAGER_DATA = "GET_PRODUCTONEPAGER_DATA";
// logging
export const POST_EVENT_LOGGING_DATA = "POST_EVENT_LOGGING_DATA";
// client Dashboard types
export const POST_DASHBOARD_PORTFOLIO = "POST_DASHBOARD_PORTFOLIO";
export const POST_DASHBOARD_PORTFOLIO_SUMMARY =
  "POST_DASHBOARD_PORTFOLIO_SUMMARY";
export const POST_DASHBOARD_HOLDING_REPORT = "POST_DASHBOARD_HOLDING_REPORT";
export const POST_DASHBOARD_ANALYTICS = "POST_DASHBOARD_ANALYTICS";
export const POST_DASHBOARD_INSIGHTS = "POST_DASHBOARD_INSIGHTS";
export const POST_DASHBOARD_PERFORMANCE = "POST_DASHBOARD_PERFORMANCE";
export const GET_DASHBOARD_DETAILS = "GET_DASHBOARD_DETAILS";
export const POST_DASHBOARD_DETAILS = "POST_DASHBOARD_DETAILS";
export const GET_DASHBOARD_INVESTMENT = "GET_DASHBOARD_INVESTMENT";
export const POST_DASHBOARD_INVESTMENT = "POST_DASHBOARD_INVESTMENT";
export const POST_DASHBOARD_REDEEM_CAPITAL = "POST_DASHBOARD_REDEEM_CAPITAL";
export const POST_DASHBOARD_DEPLOY_CAPITAL = "POST_DASHBOARD_DEPLOY_CAPITAL";
export const POST_DASHBOARD_SUITABLE_PRODUCTS =
  "POST_DASHBOARD_SUITABLE_PRODUCTS";
export const GET_MF_CENTRAL_OTP = "GET_MF_CENTRAL_OTP";
export const GET_MF_CENTRAL_PAYLOAD = "GET_MF_CENTRAL_PAYLOAD";
export const GET_DASHBOARD_MF_PORTFOLIO = "GET_DASHBOARD_MF_PORTFOLIO";
export const GET_DASHBOARD_STOCKS_PORTFOLIO = "GET_DASHBOARD_STOCKS_PORTFOLIO";
export const GET_DASHBOARD_BONDS_PORTFOLIO = "GET_DASHBOARD_BONDS_PORTFOLIO";
export const GET_DASHBOARD_PMS_PORTFOLIO = "GET_DASHBOARD_PMS_PORTFOLIO";
export const GET_DASHBOARD_AIF_PORTFOLIO = "GET_DASHBOARD_AIF_PORTFOLIO";
export const GET_DASHBOARD_UNLISTED_PORTFOLIO =
  "GET_DASHBOARD_UNLISTED_PORTFOLIO";
export const GET_DASHBOARD_CASH_PORTFOLIO = "GET_DASHBOARD_CASH_PORTFOLIO";
export const GET_DASHBOARD_DEPOSITS_PORTFOLIO =
  "GET_DASHBOARD_DEPOSITS_PORTFOLIO";
export const GET_DASHBOARD_GOLD_PORTFOLIO = "GET_DASHBOARD_GOLD_PORTFOLIO";
export const GET_DASHBOARD_REAL_ESTATE_PORTFOLIO =
  "GET_DASHBOARD_REAL_ESTATE_PORTFOLIO";
export const GET_DASHBOARD_OFFSHORE_PORTFOLIO =
  "GET_DASHBOARD_OFFSHORE_PORTFOLIO";
export const GET_DASHBOARD_BENCHMARK_PRICE = "GET_DASHBOARD_BENCHMARK_PRICE";
export const GET_DASHBOARD_PORTFOLIO_LIST = "GET_DASHBOARD_PORTFOLIO_LIST";
//Review Portfolio types
export const GET_REVIEW_PORTFOLIO_SUMMARY = "GET_REVIEW_PORTFOLIO_SUMMARY";
export const PATCH_REVIEW_PORTFOLIO_SUMMARY = "PATCH_REVIEW_PORTFOLIO_SUMMARY";
export const GET_REVIEW_PORTFOLIO_ANALYTICS = "GET_REVIEW_PORTFOLIO_ANALYTICS";
export const GET_REVIEW_PORTFOLIO_IPS_DATA = "GET_REVIEW_PORTFOLIO_IPS_DATA";
export const GET_REVIEW_PORTFOLIO_IAA = "GET_REVIEW_PORTFOLIO_IAA";
export const GET_REVIEW_PORTFOLIO_IPS_TRACKER =
  "GET_REVIEW_PORTFOLIO_IPS_TRACKER";
export const GET_REVIEW_PORTFOLIO_ACTIONABLE =
  "GET_REVIEW_PORTFOLIO_ACTIONABLE";
export const POST_REVIEW_PORTFOLIO_ACTIONABLE =
  "POST_REVIEW_PORTFOLIO_ACTIONABLE";
export const GET_REVIEW_PORTFOLIO_ANALYTICS_FINAL =
  "GET_REVIEW_PORTFOLIO_ANALYTICS_FINAL";
export const GET_REVIEW_PORTFOLIO_PPT = "GET_REVIEW_PORTFOLIO_PPT";
export const GET_REVIEW_PREVIOUS_HISTORY = "GET_REVIEW_PREVIOUS_HISTORY";
export const GET_REVIEW_ADVANCE_ANALYTICS = "GET_REVIEW_ADVANCE_ANALYTICS";
export const UPDATE_SNACKBAR_PROPS = "UPDATE_SNACKBAR_PROPS";
// Model Portfolio
export const POST_MODEL_PORTFOLIO_BOOKMARK = "POST_MODEL_PORTFOLIO_BOOKMARK";
export const GET_MODEL_PORTFOLIO = "GET_MODEL_PORTFOLIO";
export const GET_MODEL_PORTFOLIO_ANALYTICS = "GET_MODEL_PORTFOLIO_ANALYTICS";
export const POST_MODEL_PORTFOLIO = "POST_MODEL_PORTFOLIO";
export const PATCH_MODEL_PORTFOLIO = "PATCH_MODEL_PORTFOLIO";
export const DELETE_MODEL_PORTFOLIO = "DELETE_MODEL_PORTFOLIO";
export const GET_MODEL_PORTFOLIO_PRODUCTS = "GET_MODEL_PORTFOLIO_PRODUCTS";
export const POST_MODEL_PORTFOLIO_PRODUCTS = "POST_MODEL_PORTFOLIO_PRODUCTS";
export const PATCH_MODEL_PORTFOLIO_PRODUCTS = "PATCH_MODEL_PORTFOLIO_PRODUCTS";
export const DELETE_MODEL_PORTFOLIO_PRODUCTS =
  "DELETE_MODEL_PORTFOLIO_PRODUCTS";
// SIP proposal
export const GET_SIP_MODEL_PORTFOLIO_PRODUCTS =
  "GET_SIP_MODEL_PORTFOLIO_PRODUCTS";
export const GET_SIP_ALLOCATION_PREFERENCE = "GET_SIP_ALLOCATION_PREFERENCE";
export const GET_SIP_ANALYTICS_API = "GET_SIP_ANALYTICS_API";
export const GET_SIP_PPT_API = "GET_SIP_PPT_API";
export const GET_SIP_PREVIOUS_HISTORY = "GET_SIP_PREVIOUS_HISTORY";
export const GET_SIP_ADVANCE_ANALYTICS = "GET_SIP_ADVANCE_ANALYTICS";
// goal planning
export const GET_GOALS_LIST = "GET_GOALS_LIST";
export const GET_GOAL_DETAILS = "GET_GOAL_DETAILS";
// handling modal
export const UPDATE_INVESTMENT_POLICY_MODEL_DATA =
  "UPDATE_INVESTMENT_POLICY_MODEL_DATA";
export const UPDATE_RESEARCH_INVESTMENT_POLICY_MODEL_DATA =
  "UPDATE_RESEARCH_INVESTMENT_POLICY_MODEL_DATA";
export const UPDATE_PRODUCT_UNDERSTANDING_DATA =
  "UPDATE_PRODUCT_UNDERSTANDING_DATA";
// Instant Review
export const GET_ACQUIRE_CLIENTS_ANALYTICS = "GET_ACQUIRE_CLIENTS_ANALYTICS";
export const GET_ACQUIRE_CLIENTS_PPT = "GET_ACQUIRE_CLIENTS_PPT";
export const GET_ACQUIRE_CLIENTS_CAMS_PPT = "GET_ACQUIRE_CLIENTS_CAMS_PPT";
export const GET_ACQUIRE_CLIENTS_DASHBOARD = "GET_ACQUIRE_CLIENTS_DASHBOARD";
