import { GET_DASHBOARD_BENCHMARK_PRICE } from "../actions/type";
const initialState = {};
export default function getDashboardBenchmark(state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_BENCHMARK_PRICE:
      return action.payload;
    default:
      return state;
  }
}
