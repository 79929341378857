import React, { useState, useEffect } from "react";
import ManageLinksModalRow from "./ManageLinksModalRow";
import InvestedgeModal from "../../components/modal/InvestedgeModal";
import { IoIosClose } from "react-icons/io";
import InvestedgePagination from "../../components/pagination/InvestedgePagination";
import urls from "../../urls";
import axios from "axios";
import { Bars } from "react-loader-spinner";
export default function ManageLinksModal({ manageModal, onClose }) {
  const [list, setList] = useState({ loading: true, results: [] });
  const [pageNumber, setPageNumber] = useState(1);
  const proposalsPerPage = 7;
  // const pagesVisited = pageNumber * proposalsPerPage;
  const pageCount = Math.ceil(list.count / proposalsPerPage);
  const changePage = (e, val) => {
    setList({ loading: true, results: [] });
    setPageNumber(val);
    axios
      .get(urls.acquireClientsManageLinksURL, {
        params: { page: val, size: proposalsPerPage },
      })
      .then((res) => {
        let temp = res.data.results.sort((a, b) => {
          return new Date(b.meta_created_at) - new Date(a.meta_created_at);
        });
        setList({ ...res.data, results: temp });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    axios
      .get(urls.acquireClientsManageLinksURL, {
        params: { page: 1, size: proposalsPerPage },
      })
      .then((res) => {
        let temp = res.data.results.sort((a, b) => {
          return new Date(b.meta_created_at) - new Date(a.meta_created_at);
        });
        setList({ ...res.data, results: temp });
      })
      .catch((err) => console.log(err));
  }, []); // eslint-disable-next-line

  return (
    <InvestedgeModal
      aria-labelledby="Previous History Modal"
      aria-describedby="Previous History Modal"
      sx={{
        width: "80vw",
        marginRight: "auto",
        marginLeft: "auto",
        minHeight: "80vh",
        padding: "1rem",
      }}
      open={manageModal}
      handleclose={onClose}
    >
      <div
        className="flex justify-between border-0 pl-0"
        style={{ paddingTop: "0" }}
      >
        <h5 className="card-heading">Manage Links</h5>
        <IoIosClose size={30} onClick={onClose} />
      </div>
      {list.loading ? (
        <div className="h-[400px] w-full flex items-center justify-center">
          {" "}
          <Bars color="#1982f8" height="50" width="50" />
        </div>
      ) : (
        <table className="mt-[32px] w-full investedge-table">
          <colgroup>
            <col width="15%" />
            <col width="40%" />
            <col width="15%" />
            <col width="15%" />
            <col width="15%" />
          </colgroup>
          <tbody className="font-sm">
            <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
              <th className=" py-3 text-left font-medium">Generation Date</th>
              <th className=" py-3 font-medium">Link</th>
              <th className=" py-3 font-medium">Report Type</th>
              <th className=" py-3 font-medium">Download Count</th>
              <th className=" py-3 font-medium">Action</th>
            </tr>
            {list.results.map((element, index) => (
              <ManageLinksModalRow
                element={element}
                key={index}
                setList={setList}
              />
            ))}
          </tbody>
        </table>
      )}

      <div className="w-full flex justify-end absolute bottom-4 right-4">
        <InvestedgePagination
          count={pageCount}
          defaultPage={1}
          page={pageNumber}
          onChange={changePage}
          color="primary"
        />
      </div>
    </InvestedgeModal>
  );
}
