import { POST_DASHBOARD_INSIGHTS } from "../actions/type";
const initialState = {
  investment_policy: [],
  product: [],
  portfolio: [],
  taxation: [],
  cashflow_and_liquidity: [],
};
export default function PostDashboardInsights(state = initialState, action) {
  switch (action.type) {
    case POST_DASHBOARD_INSIGHTS:
      return action.payload;
    default:
      return state;
  }
}
