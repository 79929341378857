import React from "react";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import InvestedgeTextField from "../../../components/textfield/InvestedgeTextField";

export default function PortfolioReview({
  portfolioReviewQuery,
  setPortfolioReviewQuery,
  handlePortfolioReview,
  portfolioReviewResponse,
}) {
  return (
    <div>
      <h1 className="card-heading text-base">
        Get Portfolio Actionable based on current Market Views
      </h1>
      <div className="w-full flex justify-between items-center">
        <div className="w-[70%] mt-4 ">
          <InvestedgeTextField
            value={portfolioReviewQuery}
            onChange={(e) => setPortfolioReviewQuery(e.target.value)}
            label={`Enter Query Here ( for ${localStorage.getItem(
              "clientName"
            )})`}
            fullWidth
          />
        </div>{" "}
        <InvestedgeButton
          onClick={handlePortfolioReview}
          className="h-full mt-2"
          disabled={portfolioReviewResponse.portfolio_analysis === "loading"}
        >
          Submit
        </InvestedgeButton>
      </div>
    </div>
  );
}
