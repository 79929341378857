import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
function Dropdown({
  options,
  selected,
  setSelected,
  attribute,
  index,
  labelName = "Select",
}) {
  /*const [isActive, setIsActive] = useState(false);
  const [referenceRef, setReferenceRef] = useState(null);
  const [popperRef, setPopperRef] = useState(null);

  const { styles, attributes } = usePopper(referenceRef, popperRef, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        enabled: true,
      },
      {
        name: "flip",
        options: {
          fallbackPlacements: ["bottom", "top"],
        },
      },
    ],
  });*/
  const [val, setVal] = useState(selected[index][attribute]);

  const handleChange = (e) => {
    let newSelected = selected;
    newSelected[index][attribute] = e.target.value;
    setVal(e.target.value);
    setSelected(newSelected);
  };

  return (
    <FormControl
      size="small"
      sx={{
        "& .MuiInputBase-inputSizeSmall": {
          paddingTop: "10px",
          paddingBottom: "10px",
        },
      }}
      fullWidth
    >
      <InputLabel className="font-inter font-normal text-sm">
        {labelName}
      </InputLabel>
      <Select
        value={val}
        defaultValue={selected[index][attribute]}
        label={labelName}
        onChange={handleChange}
        className="font-inter text-sm"
      >
        {options.map((option, ind) => (
          <MenuItem key={ind} value={option} id={ind}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    /*<div className="family-details-dropdown" style={{ width: "100%" }}>
      <div
        className="portfolio-proposal-dropdown-btn"
        ref={setReferenceRef}
        onClick={(e) => setIsActive(!isActive)}
        style={{
          boxShadow: "0px 0px 3px rgb(0 0 0 / 15%)",
          border: "1px solid #e0dddd",
        }}
      >
        {selected ? selected[index][attribute] : "Choose One"}
        <MdIcons.MdKeyboardArrowDown
          style={{ marginTop: "0.2rem" }}
          size={17}
        />
      </div>
      {isActive && (
        <ClickAwayListener onClickAway={() => setIsActive(false)}>
          <div
            ref={setPopperRef}
            style={styles.popper}
            {...attributes.popper}
            className="family-details-dropdown-content"
          >
            {options.map((option, ind) => (
              <div
                key={ind}
                onClick={() => {
                  
                  setIsActive(false);
                }}
                className="family-details-dropdown-item"
              >
                {option}
              </div>
            ))}
          </div>
        </ClickAwayListener>
      )}
    </div>*/
  );
}

export default Dropdown;
