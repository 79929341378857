import React, { useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import AfterloginApp from "../../AfterloginApp";
import PortfolioFirstStep from "./firstStep/PortfolioFirstStep";
import PortfolioSecondStep from "./secondStep/PortfolioSecondStep";
import PortfolioThirdStep from "./thirdStep/PortfolioThirdStep";
import PortfolioFourthStep from "./fourthStep/PortfolioFourthStep";
import PortfolioFifthStep from "./fifthStep/PortfolioFifthStep";
import PortfolioSixthStep from "./sixthStep/PortfolioSixthStep";
export default function SipProposalStepper() {
  const labels = [
    "Client Details",
    "Proposal Requirements",
    "Asset Allocation & Return Simulator",
    "Portfolio & Analysis",
    "Template Selection",
    "Download",
  ];
  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <PortfolioFirstStep
            handleNext={handleNext}
            formValue={formValue}
            setFormValue={setFormValue}
          />
        );
      case 1:
        return (
          <PortfolioSecondStep
            handleBack={handleBack}
            handleNext={handleNext}
            formValue={formValue}
            setFormValue={setFormValue}
            proposalType={proposalType}
            setProposalType={setProposalType}
            portfolioId={portfolioId}
            setPortfolioId={setPortfolioId}
            handleModelPortfolioNext={handleModelPortfolioNext}
            setPortfolioProducts={setPortfolio}
          />
        );
      case 2:
        return (
          <PortfolioThirdStep
            handleBack={handleBack}
            handleNext={handleNext}
            formValue={formValue}
            setFormValue={setFormValue}
            setPortfolio={setPortfolio}
          />
        );
      case 3:
        return (
          <PortfolioFourthStep
            handleBack={handleBack}
            handleNext={handleNext}
            formValue={formValue}
            portfolioProducts={portfolio}
            setPortfolioProducts={setPortfolio}
            portfolioId={portfolioId}
            proposalType={proposalType}
            handleModelPortfolioBack={handleModelPortfolioBack}
          />
        );
      case 4:
        return (
          <PortfolioFifthStep
            handleBack={handleBack}
            handleNext={handleNext}
            formValue={formValue}
            portfolioProducts={portfolio}
          />
        );
      case 5:
        return <PortfolioSixthStep handleBack={handleBack} />;

      default:
        throw new Error("Unknown step");
    }
  };
  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleModelPortfolioNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 2);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleModelPortfolioBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 2);
  };

  //   const QontoConnector = styled(StepConnector)(({ theme }) => ({
  //     [`&.${stepConnectorClasses.active}`]: {
  //       [`& .${stepConnectorClasses.line}`]: {
  //         borderColor: "#784af4",
  //         outerWidth: 3,
  //       },
  //     },
  //     [`&.${stepConnectorClasses.completed}`]: {
  //       [`& .${stepConnectorClasses.line}`]: {
  //         borderColor: "#784af4",
  //       },
  //     },
  //   }));
  const [formValue, setFormValue] = useState({
    allocation_preference: {
      AAA: 0.75,
      alternate: 0.039,
      debt: 0.351,
      duration: 3,
      equity: 0.61,
      large: 0.5,
      low_volatility: 0.75,
      mid: 0.4,
      small: 0.1,
    },
    client_name: "",
    investment_horizon: "",
    purpose_of_sip: "Child Education",
    sip_proposal_value: {
      amount: "",
      denomination: "",
    },
    risk_score: 50,
    duration_of_sip: 1,
    frequency_of_sip: "Monthly",
    top_up: { type: "L", value: "" },
    frequency_of_topup: "Quarterly",
    number_of_schemes_for_asset_allocation: "",
  });
  // const [portfolioProducts, setPortfolioProducts] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const [proposalType, setProposalType] = useState(0);
  const [portfolioId, setPortfolioId] = useState("");
  return (
    <>
      <div className="parentLoggedinScreen">
        <AfterloginApp />
        <div className="loggedinscreen-container">
          <div className="loggedinscreen">
            <div className="px-8 rounded-[8px] w-full bg-white">
              <div className="h-[20%]">
                <Stepper
                  activeStep={activeStep}
                  sx={{ py: 3 }}
                  alternativeLabel
                >
                  {labels.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </div>
            <div className="h-[80%]">{handleSteps(activeStep)}</div>
          </div>
        </div>
      </div>
    </>
  );
}
