import { GET_PPT_API } from "../actions/type";
const initialState = [];
export default function GetPptAPI(state = initialState, action) {
  switch (action.type) {
    case GET_PPT_API:
      return action.payload;
    default:
      return state;
  }
}
