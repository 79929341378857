import React, { useEffect, useState } from "react";
import Dropdown from "../../rmdashboard/clientinput/individualdetails/Dropdown";
import { FormControlLabel, Radio } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Dropdown2 from "../../rmdashboard/clientinput/individualdetails/DropdownObjectFIelds";
import UpdatedClientCard from "../../rmdashboard/clientinput/individualdetails/UpdatedClientCard";
import {
  PostDashboardDetails,
  PostEventLoggingData,
} from "../../actions/actions";
import InvestedgeTextField from "../../components/textfield/InvestedgeTextField";
import InvestedgeDatepicker from "../../components/datepicker/InvestedgeDatepicker";
import InvestedgeButton from "../../components/buttons/InvestedgeButton";
import dayjs from "dayjs";
export default function IndividualContent() {
  const dispatch = useDispatch();
  const clientData = useSelector((state) => state.dashboardGetDetails);
  const [options, setOptions] = useState([
    {
      optionname: localStorage.getItem("clientName"),
      optionid: 0,
    },
  ]);
  // const [validationFlag, setValidationFlag] = useState(false);
  const [updatedOptions, setUpdatedOptions] = useState([]);
  const [selected, setSelected] = useState(options[0]);
  // const [errorPan, setErrorPan] = useState(false);
  const [familyMembers, setFamilyMembers] = useState([
    {
      ...clientData.general_details,
      ...clientData.tax_details,
      dob: new Date(clientData.dob),
      taxResidency:
        clientData.tax_details.taxResidency === "I" ? "Indian" : "Others",
      nationality:
        clientData.tax_details.nationality === "I" ? "Indian" : "Others",
      residencyStatus:
        clientData.tax_details.residencyStatus === "I" ? "Indian" : "Others",
      carryftcl: clientData.tax_details.carryFwdLongTermCapitalLoss.amount,
      carrystcl: clientData.tax_details.carryFwdShortTermCapitalLoss.amount,
      currentftcl: clientData.tax_details.currentLongTermCapitalLoss.amount,
      currentstcl: clientData.tax_details.curentShortTermCapitalLoss.amount,
      carryftcldenotion: `₹ ${clientData.tax_details.carryFwdLongTermCapitalLoss.denomination}`,
      carrystcldenotion: `₹ ${clientData.tax_details.carryFwdShortTermCapitalLoss.denomination}`,
      currentftcldenotion: `₹ ${clientData.tax_details.currentLongTermCapitalLoss.denomination}`,
      currentstcldenotion: `₹ ${clientData.tax_details.curentShortTermCapitalLoss.denomination}`,
    },
  ]);
  // let handleValidation = () => {
  //   let temp = true;
  //   if (updatedOptions.length !== 1) temp = false;
  //   for (let i = 0; i < familyMembers.length; i++)
  //     if (familyMembers[i].pan_number.length !== 10) temp = false;
  //   // familyMembers.map((element) => {
  //   //   if (element.pan_number.length !== 10) temp = false;
  //   // });
  //   // setValidationFlag(temp);
  // };
  let handleChange = (e) => {
    if (
      e.target.name === "pincode" ||
      e.target.name === "carryftcl" ||
      e.target.name === "currentftcl" ||
      e.target.name === "currentstcl" ||
      e.target.name === "carrystcl"
    ) {
      const re = /^\d+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        let newfamilyMembers = [...familyMembers];
        newfamilyMembers[selected.optionid][e.target.name] = e.target.value;
        setFamilyMembers(newfamilyMembers);
        // handleValidation();
      }
    } else if (e.target.name === "sector") {
      const re = /^[A-Za-z]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        let newfamilyMembers = [...familyMembers];
        newfamilyMembers[selected.optionid][e.target.name] = e.target.value;
        setFamilyMembers(newfamilyMembers);
      }
    } else {
      let newfamilyMembers = [...familyMembers];
      newfamilyMembers[selected.optionid][e.target.name] = e.target.value;
      setFamilyMembers(newfamilyMembers);
      // handleValidation();
    }
  };
  // let handleUpdateClick = () => {
  //   let newOptions = [...options];
  //   for (var i = 0; i < newOptions.length; i++) {
  //     if (newOptions[i] === selected) {
  //       newOptions.splice(i, 1);
  //     }
  //   }
  //   let newUpdatedOptions = updatedOptions;
  //   newUpdatedOptions.push(selected);
  //   setOptions(newOptions);
  //   setUpdatedOptions(newUpdatedOptions);
  //   setSelected(newOptions[0]);
  //   handleValidation();
  // };
  let handleCancelDetails = (element) => {
    let newOptions = [...options];
    newOptions.push(element);
    let newUpdatedOptions = updatedOptions;
    for (var i = 0; i < newUpdatedOptions.length; i++) {
      if (newUpdatedOptions[i] === element) {
        newUpdatedOptions.splice(i, 1);
      }
    }
    setOptions(newOptions);
    if (newOptions.length === 1) setSelected(newOptions[0]);
    setUpdatedOptions(newUpdatedOptions);
    // setValidationFlag(false);
  };
  const handleDateChange = (date) => {
    date = new Date(date);
    let newfamilyMembers = [...familyMembers];
    newfamilyMembers[selected.optionid].dob = date;
    if (date) {
      let today = new Date();
      let aget = today.getFullYear() - date.getFullYear();
      var m = today.getMonth() - date.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
        aget--;
      }
      newfamilyMembers[selected.optionid].age = aget;
    }

    setFamilyMembers(newfamilyMembers);
  };

  let handleNextClick = () => {
    var dd = String(familyMembers[0].dob.getDate()).padStart(2, "0");
    var mm = String(familyMembers[0].dob.getMonth() + 1).padStart(2, "0");
    var yyyy = familyMembers[0].dob.getFullYear();
    dispatch(
      PostDashboardDetails([
        {
          client_id: localStorage.getItem("clientId"),
        },

        {
          user: localStorage.getItem("clientId"),
          dob: yyyy + "-" + mm + "-" + dd,
          gender: familyMembers[0].gender,
          marital_status: familyMembers[0].marital_status,
          pan_number: familyMembers[0].pan_number,
          occupation: familyMembers[0].occupation,
          sector: familyMembers[0].sector,
          address: familyMembers[0].address,
          pincode: familyMembers[0].pincode.length
            ? familyMembers[0].pincode
            : 0,
          family_id_id: "eeae8174-4fdd-4264-a105-c8f67bbe1ed8",
          is_primary: true,
          relation_with_primary: "Self",
          taxResidency: familyMembers[0].taxResidency[0],
          birthCountry: familyMembers[0].birthCountry[0],
          residencyStatus: familyMembers[0].residencyStatus[0],
          nationality: familyMembers[0].nationality[0],
          investmentEntity: "MF",
          isProfitMaking: true,
          incomeTaxSlab: "200",
          marginalTaxRate: familyMembers[0].marginalTaxRate,
          carryFwdShortTermCapitalLoss: {
            amount: parseInt(familyMembers[0].carrystcl),
            denomination: familyMembers[0].carrystcldenotion[2],
          },
          carryFwdLongTermCapitalLoss: {
            amount: parseInt(familyMembers[0].carryftcl),
            denomination: familyMembers[0].carryftcldenotion[2],
          },
          curentShortTermCapitalLoss: {
            amount: parseInt(familyMembers[0].currentstcl),
            denomination: familyMembers[0].currentstcldenotion[2],
          },
          currentLongTermCapitalLoss: {
            amount: parseInt(familyMembers[0].currentftcl),
            denomination: familyMembers[0].currentftcldenotion[2],
          },
        },
      ])
    );
    dispatch(
      PostEventLoggingData({
        module_name: "Client Dashboard",
        event_type: "Save button",
        url: window.location.href,
        note: `Client Details for ${localStorage.getItem(
          "clientName"
        )} updated`,
      })
    );
  };
  useEffect(() => {
    setFamilyMembers([
      {
        ...clientData.general_details,
        ...clientData.tax_details,
        dob: new Date(clientData.dob),
        taxResidency:
          clientData.tax_details.taxResidency === "I" ? "Indian" : "Others",
        nationality:
          clientData.tax_details.nationality === "I" ? "Indian" : "Others",
        residencyStatus:
          clientData.tax_details.residencyStatus === "I" ? "Indian" : "Others",
        carryftcl: clientData.tax_details.carryFwdLongTermCapitalLoss.amount,
        carrystcl: clientData.tax_details.carryFwdShortTermCapitalLoss.amount,
        currentftcl: clientData.tax_details.currentLongTermCapitalLoss.amount,
        currentstcl: clientData.tax_details.curentShortTermCapitalLoss.amount,
        carryftcldenotion: `₹ ${clientData.tax_details.carryFwdLongTermCapitalLoss.denomination}`,
        carrystcldenotion: `₹ ${clientData.tax_details.carryFwdShortTermCapitalLoss.denomination}`,
        currentftcldenotion: `₹ ${clientData.tax_details.currentLongTermCapitalLoss.denomination}`,
        currentstcldenotion: `₹ ${clientData.tax_details.curentShortTermCapitalLoss.denomination}`,
      },
    ]); // eslint-disable-next-line
  }, [clientData]);

  const return_date = (val) => {
    try {
      let temp = dayjs(new Date(val).toISOString());
      return temp;
    } catch (err) {
      return val;
    }
  };

  return (
    <>
      <div className="sticky top-0 bg-[#f3f3f3] mx-[-22px] z-[10] pt-[1rem] px-[6px]">
        <div className="w-full h-full bg-[#fff] p-[1rem] rounded-t-[0.5rem] min-h-full">
          <h1 className="card-heading">Edit Individual Details</h1>
          <div className="w-[28%] mt-[1.5rem]">
            <Dropdown
              options={options}
              selected={selected}
              setSelected={setSelected}
              labelName="Client"
            />
          </div>
          <h2 className="w-full text-left border-b-[2px] border-[#ece7e7] leading-[0.1em] mt-[2.7rem]">
            <span className="bg-[#fff] font-medium  text-[16px] font-inter pr-[10px] pb-[10px] text-neutralBlack">
              Edit Details
            </span>
          </h2>
        </div>
      </div>
      <div className="bg-[#fff] m-[-1rem] p-[1rem] rounded-b-[0.5rem]">
        {familyMembers.length !== updatedOptions.length ? (
          <div>
            <div className="font-medium mt-[0.5rem] text-[#939393] text-[15px]">
              General details
            </div>
            <table className="w-full">
              <tbody>
                <tr className="w-full flex justify-between mt-[0.5rem]">
                  <td className="w-[30%] p-0 flex items-center">
                    <InvestedgeDatepicker
                      format="DD/MM/YYYY"
                      label="Date of birth"
                      className="font-inter font-normal w-full"
                      value={return_date(familyMembers[selected.optionid].dob)}
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <InvestedgeTextField {...params} />
                      )}
                    />
                  </td>
                  <td className="w-[30%] p-0 h-full flex flex-col justify-between">
                    <label className="font-inter text-[14px] font-normal tracking-[0em] leading-5">
                      Gender
                    </label>
                    <div className="flex justify-between items-center w-[95%]">
                      <FormControlLabel
                        control={
                          <Radio
                            size="small"
                            checked={
                              familyMembers[selected.optionid].gender === "M"
                            }
                            onChange={(e) => handleChange(e)}
                            value="M"
                            name="gender"
                            inputProps={{ "aria-label": "Male" }}
                          />
                        }
                        label="Male"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            size="small"
                            checked={
                              familyMembers[selected.optionid].gender === "F"
                            }
                            onChange={(e) => handleChange(e)}
                            value="F"
                            name="gender"
                            inputProps={{ "aria-label": "Female" }}
                          />
                        }
                        label="Female"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            size="small"
                            checked={
                              familyMembers[selected.optionid].gender === "O"
                            }
                            onChange={(e) => handleChange(e)}
                            value="O"
                            name="gender"
                            inputProps={{ "aria-label": "Other" }}
                          />
                        }
                        label="Other"
                      />
                    </div>
                  </td>
                  <td className="w-[30%] p-0">
                    <label className="font-inter text-[14px] font-normal tracking-[0em] leading-5">
                      Marriage Status
                    </label>
                    <div className="flex justify-between items-center w-[70%]">
                      <FormControlLabel
                        control={
                          <Radio
                            size="small"
                            checked={
                              familyMembers[selected.optionid]
                                .marital_status === "M"
                            }
                            onChange={(e) => handleChange(e)}
                            value="M"
                            name="marital_status"
                            inputProps={{ "aria-label": "Married" }}
                          />
                        }
                        label="Married"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            size="small"
                            checked={
                              familyMembers[selected.optionid]
                                .marital_status === "S"
                            }
                            onChange={(e) => handleChange(e)}
                            value="S"
                            name="marital_status"
                            inputProps={{ "aria-label": "Single" }}
                          />
                        }
                        label="Single"
                      />
                    </div>
                  </td>
                </tr>

                <tr className="w-[100%] flex justify-between mt-[1.5rem]">
                  <td className="w-[30%] p-0">
                    <InvestedgeTextField
                      variant="outlined"
                      fullWidth
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                      }}
                      label="PAN No."
                      // error={errorPan ? true : false}
                      // helperText={
                      //   errorPan
                      //     ? "Please enter a valid 10 digit PAN number"
                      //     : ""
                      // }
                      name="pan_number"
                      value={familyMembers[selected.optionid].pan_number}
                      onChange={(e) => {
                        // let re = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
                        // let reg = e.target.value.length === 10;
                        // if (!reg && !re.test(e.target.value)) setErrorPan(true);
                        // else setErrorPan(false);

                        handleChange(e);
                      }}
                    />
                  </td>
                  <td className="w-[30%] p-0">
                    <InvestedgeTextField
                      variant="outlined"
                      fullWidth
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                      }}
                      label="Occupation"
                      name="occupation"
                      value={familyMembers[selected.optionid].occupation}
                      onChange={(e) => handleChange(e)}
                    />
                  </td>
                  <td className="w-[30%] p-0">
                    <InvestedgeTextField
                      variant="outlined"
                      fullWidth
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                      }}
                      label="Sector"
                      name="sector"
                      value={familyMembers[selected.optionid].sector}
                      onChange={(e) => handleChange(e)}
                    />
                  </td>
                </tr>

                <tr className="w-full flex justify-between mt-[1.5rem]">
                  <td className="w-[65%] p-0">
                    <InvestedgeTextField
                      variant="outlined"
                      fullWidth
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                      }}
                      label="Address"
                      name="address"
                      value={familyMembers[selected.optionid].address}
                      onChange={(e) => handleChange(e)}
                    />
                  </td>

                  <td className="w-[30%] p-0">
                    <InvestedgeTextField
                      variant="outlined"
                      fullWidth
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                      }}
                      label="PIN Code"
                      name="pincode"
                      value={familyMembers[selected.optionid].pincode}
                      onChange={(e) => handleChange(e)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="text-[15px] text-[#939393] font-medium mt-[2rem]">
              Tax-related details
            </div>

            <table className="w-full">
              <tbody>
                <tr className="w-[100%] flex justify-between mt-6">
                  <td className="w-[30%] p-0">
                    <Dropdown2
                      options={["Indian", "Others"]}
                      selected={familyMembers}
                      setSelected={setFamilyMembers}
                      attribute="taxResidency"
                      index={selected.optionid}
                      labelName="Tax Residency"
                    />
                  </td>
                  <td className="w-[30%] p-0">
                    <Dropdown2
                      options={["Indian", "Others"]}
                      selected={familyMembers}
                      setSelected={setFamilyMembers}
                      attribute="nationality"
                      index={selected.optionid}
                      labelName="Nationality"
                    />
                  </td>
                  <td className="w-[30%] p-0">
                    <Dropdown2
                      options={["Indian", "Others"]}
                      selected={familyMembers}
                      setSelected={setFamilyMembers}
                      attribute="residencyStatus"
                      index={selected.optionid}
                      labelName="Residency Status"
                    />
                  </td>
                </tr>

                {/* <tr className="w-full flex justify-between mt-6">
                  <td className="w-[47%] p-0">
                    <Dropdown2
                      options={["5%", "10%", "20%", "30%"]}
                      selected={familyMembers}
                      setSelected={setFamilyMembers}
                      attribute="taxrate"
                      labelName="Marginal Tax Rate"
                      index={selected.optionid}
                    />
                  </td>
                  <td className="w-[47%] p-0">
                    <Dropdown2
                      options={["0%", "10%", "25%", "37%"]}
                      selected={familyMembers}
                      setSelected={setFamilyMembers}
                      attribute="surcharge"
                      labelName="Surcharge"
                      index={selected.optionid}
                    />
                  </td>
                </tr> */}

                <tr className="flex justify-between w-full mt-6">
                  <td className="flex justify-between w-[47%] p-0">
                    <div className="w-[25%]">
                      <Dropdown2
                        options={["₹ K", "₹ L", "₹ C"]}
                        selected={familyMembers}
                        setSelected={setFamilyMembers}
                        attribute="carrystcldenotion"
                        index={selected.optionid}
                      />
                    </div>
                    <InvestedgeTextField
                      variant="outlined"
                      type="number"
                      fullWidth
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "73%",
                      }}
                      label="Carry forward short-term capital loss"
                      name="carrystcl"
                      value={familyMembers[selected.optionid].carrystcl}
                      onChange={(e) => handleChange(e)}
                    />
                  </td>
                  <td className="p-0 flex justify-between w-[47%]">
                    <div className="w-[25%]">
                      <Dropdown2
                        options={["₹ K", "₹ L", "₹ C"]}
                        selected={familyMembers}
                        setSelected={setFamilyMembers}
                        attribute="currentstcldenotion"
                        index={selected.optionid}
                      />
                    </div>
                    <InvestedgeTextField
                      variant="outlined"
                      type="number"
                      fullWidth
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "73%",
                      }}
                      label="Current forward short-term capital loss"
                      name="currentstcl"
                      value={familyMembers[selected.optionid].currentstcl}
                      onChange={(e) => handleChange(e)}
                    />
                  </td>
                </tr>

                <tr className="flex justify-between w-full mt-6">
                  <td className=" flex justify-between w-[47%] p-0">
                    <div className="w-[25%]">
                      <Dropdown2
                        options={["₹ K", "₹ L", "₹ C"]}
                        selected={familyMembers}
                        setSelected={setFamilyMembers}
                        attribute="carryftcldenotion"
                        index={selected.optionid}
                      />
                    </div>
                    <InvestedgeTextField
                      variant="outlined"
                      fullWidth
                      type="number"
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "73%",
                      }}
                      label="Carry forward long-term capital loss"
                      name="carryftcl"
                      value={familyMembers[selected.optionid].carryftcl}
                      onChange={(e) => handleChange(e)}
                    />
                  </td>
                  <td className="flex justify-between w-[47%] p-0">
                    <div className="w-[25%]">
                      <Dropdown2
                        options={["₹ K", "₹ L", "₹ C"]}
                        selected={familyMembers}
                        setSelected={setFamilyMembers}
                        attribute="currentftcldenotion"
                        index={selected.optionid}
                      />
                    </div>
                    <InvestedgeTextField
                      variant="outlined"
                      type="number"
                      fullWidth
                      style={{
                        fontFamily: "Inter,sans-sarif",
                        fontWeight: 400,
                        width: "73%",
                      }}
                      label="Current long-term capital loss"
                      name="currentftcl"
                      value={familyMembers[selected.optionid].currentftcl}
                      onChange={(e) => handleChange(e)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div
                className="input-client-update-details-button"
                onClick={handleUpdateClick}
              >
                Update details
              </div>
            </div> */}
            {updatedOptions.length !== 0 && (
              <>
                <h2 className="font-medium text-neutralBlack text-[16px] mt-[0.3rem]">
                  Updated data for
                </h2>
                <div className="flex flex-wrap">
                  {updatedOptions.map((client, index) => {
                    return (
                      <UpdatedClientCard
                        key={index}
                        client={client}
                        handleCancelDetails={handleCancelDetails}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </div>
        ) : (
          <>
            <div className="flex justify-center items-center h-[300px]">
              <h2 className="text-[20px] text-primary-200 font-medium font-inter">
                Successfully updated details for all family members !
              </h2>
            </div>

            <h2 className=" font-medium text-neutralBlack text-[16px] mt-[0.3rem]">
              Updated data for
            </h2>
            <div className="flex flex-wrap">
              {updatedOptions.map((client, index) => {
                return (
                  <UpdatedClientCard
                    key={index}
                    client={client}
                    handleCancelDetails={handleCancelDetails}
                  />
                );
              })}
            </div>
          </>
        )}
        <div className=" flex w-full items-center justify-end mt-[1.5rem]">
          {/* <Link
            className="client-account-type-button"
            to="/clientinput/familydetails"
          >
            <div className="create-client-button-create">Back</div>
          </Link> */}
          {/* {validationFlag ? (
            <Link
              className="client-account-type-button create-client-button-prospect"
              to={{
                pathname: "/clientinput/investmentprofile",
                state: {
                  familyMembers: familyMembers,
                },
              }}
              onClick={handleNextClick}
            >
              <div>Save</div>
            </Link>
          ) : (
            <div
              className="client-account-type-button create-client-button-prospect"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.12)",
                color: "rgba(0, 0, 0, 0.5)",
              }}
            >
              Save
            </div>
          )} */}
          <InvestedgeButton onClick={handleNextClick}>Save</InvestedgeButton>
        </div>
      </div>
    </>
  );
}
