import React from "react";

const ProgressBar = ({ progress }) => {
  //   const Parentdiv = {
  //     height: 6,
  //     width: "100%",
  //     backgroundColor: "#56B787",
  //     opacity: 0.1,
  //     borderRadius: 40,
  //     marginTop: "8px",
  //   };
  const Childdiv = {
    height: 6,
    width: `${Math.min(progress, 100)}%`,
    background: "#56B787",
    borderRadius: 40,
  };

  return (
    <>
      <div className="h-[6px] w-[90%] rounded-[40px] mt-2 bg-[#56B787]/10">
        <div style={Childdiv}></div>
      </div>
      <p className="mb-0 font-inter text-xs text-center mt-3">{`${progress.toFixed(
        1
      )}% attained`}</p>
    </>
  );
};

export default ProgressBar;
