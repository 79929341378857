import AfterloginApp from "../../AfterloginApp";
import React from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import "../../../static/css/existing/dashboard_styles.css";
import Query from "../../../assets/img/telegram_app.svg";
export default function Comparisons() {
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Comparison"}
        description={"Compare up to three product on various parameterss"}
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen px-[20px] pt-4 pb-[40px]">
          <div className="bg-[#fff] p-4 min-h-[30%] rounded-[0.5rem] border border-primaryBlackborder shadow-primaryShadow w-full">
            <h3 className=" font-inter text-[24px] not-italic font-medium leading-[30px] mb-4">
              Comparisons
            </h3>
            <div className="flex  flex-row justify-evenly w-full">
              <div className=" w-[28%] flex flex-col justify-between items-center h-auto py-4 px-8 shadow-screenerTableCont">
                <BsFillPlusCircleFill
                  data-toggle="modal"
                  data-target="#searchModal"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    color: "#999999",
                  }}
                  size={32}
                />
                <p className=" ml-auto  mr-auto text-[#999999] text-[18px]  mb-0 font-semibold font-inter not-italic">
                  Add Fund 1
                </p>
              </div>
              <div className=" w-[28%] flex flex-col justify-between items-center py-4 px-8 h-auto shadow-screenerTableCont">
                <BsFillPlusCircleFill
                  data-toggle="modal"
                  data-target="#searchModal"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    color: "#999999",
                  }}
                  size={32}
                />
                <p className=" ml-auto  mr-auto text-[#999999] text-[18px]  mb-0 font-semibold font-inter not-italic">
                  Add Fund 2
                </p>
              </div>
              <div className=" w-[28%] flex flex-col justify-between items-center py-4 px-8 h-auto shadow-screenerTableCont ">
                <BsFillPlusCircleFill
                  data-toggle="modal"
                  data-target="#searchModal"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    color: "#999999",
                  }}
                  size={32}
                />
                <p className=" ml-auto  mr-auto text-[#999999] text-[18px]  mb-0 font-semibold font-inter not-italic">
                  Add Fund 3
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#fff] p-4 mt-4 rounded-[0.5rem] min-h-[30%] border border-primaryBlackborder shadow-primaryShadow w-full">
            <h3 className=" font-inter text-[24px] leading-[30px]  font-medium not-italic">
              Results
            </h3>
            <div className=" w-full min-h-[350px] flex flex-col justify-center items-center">
              <img src={Query} alt="err" />
              <br />
              <h3 className=" font-inter text-[24px] leading-[30px]  font-medium m-4 text-center tracking-[0.1133817583322525px]">
                Run Query for results
              </h3>
            </div>
            <br></br>
          </div>
        </div>
      </div>
    </div>
  );
}
