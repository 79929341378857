import { POST_REVIEW_PORTFOLIO_ACTIONABLE } from "../actions/type";
const initialState = {};
export default function PostReviewPortfolioActionable(
  state = initialState,
  action
) {
  switch (action.type) {
    case POST_REVIEW_PORTFOLIO_ACTIONABLE:
      return action.payload;
    default:
      return state;
  }
}
