import React, { useRef } from "react";
import Hourglass from "../../../../assets/img/wait.gif";
import Women from "../../../../assets/img/modal_chr.svg";
import { useSelector, useDispatch } from "react-redux";
import { PostEventLoggingData } from "../../../../actions/actions";
import InvestedgeButton from "../../../../components/buttons/InvestedgeButton";
import { Link } from "react-router-dom";

export default function PortfolioSixthStep({ handleBack }) {
  const pptRef = useRef(null);
  const excelRef = useRef(null);
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(true);
  const fileUrls = useSelector((state) => state.portfolioReviewGetPPT);
  // setTimeout(() => {
  //   setLoading(false);
  // }, 6000);
  // const loadingPpt = false;
  const downloadexcel = async () => await excelRef.current.click();
  const downloadppt = async () => await pptRef.current.click();

  return (
    <div className="w-[100%] flex mt-[16px] min-h-[calc(100vh_-_260px)]">
      <div className=" w-full flex-col justify-center items-center flex p-[32px] rounded-[8px] bg-white min-h-[calc(100vh_-_260px)]">
        <img
          alt="err"
          src={fileUrls.loading ? Hourglass : Women}
          className=" min-h-[250px] object-contain"
        />
        <h3 className="portfolio-stepper-sixth-form-status">
          {fileUrls.loading
            ? "Sometimes, great things take time."
            : "Happy Investment. Thanks for reviewing the portfolio"}
        </h3>
        {fileUrls.loading && (
          <h3 className=" font-inter text-[14px] font-medium leading-[16px] tracking-[0em] text-center">
            Your request is under process. Please wait for sometime.
          </h3>
        )}
        {!fileUrls.loading && (
          <div className=" w-[65%] flex justify-between">
            <div className="flex">
              <Link to="/portfolioproposal/review">
                <InvestedgeButton
                  onClick={() => {
                    dispatch(
                      PostEventLoggingData({
                        module_name: "Portfolio Review",
                        event_type: "Repeat",
                        url: window.location.href,
                        note: `Sixth screen`,
                      })
                    );
                  }}
                >
                  Review Another
                </InvestedgeButton>
              </Link>
              <InvestedgeButton
                className="ml-4"
                onClick={() => {
                  dispatch(
                    PostEventLoggingData({
                      module_name: "Portfolio Insights sixth screen",
                      event_type: "Prev",
                      url: window.location.href,
                      note: `Previous button`,
                    })
                  );
                  handleBack();
                }}
              >
                Go Back
              </InvestedgeButton>
            </div>
            <div className="flex">
              {fileUrls.excel && (
                <InvestedgeButton
                  onClick={() => {
                    dispatch(
                      PostEventLoggingData({
                        module_name: "Portfolio Review",
                        event_type: "Download excel",
                        url: window.location.href,
                        note: `Sixth screen`,
                      })
                    );
                    downloadexcel();
                  }}
                >
                  Download Excel
                </InvestedgeButton>
              )}
              <InvestedgeButton
                className="ml-4"
                onClick={() => {
                  dispatch(
                    PostEventLoggingData({
                      module_name: "Portfolio Review",
                      event_type: "Download ppt",
                      url: window.location.href,
                      note: `Sixth screen`,
                    })
                  );
                  downloadppt();
                }}
              >
                Download PPT
              </InvestedgeButton>
            </div>
          </div>
        )}
        <a href={fileUrls.ppt} ref={pptRef} className="hidden">
          File URL
        </a>
        <a href={fileUrls.excel} ref={excelRef} className="hidden">
          File URL
        </a>
      </div>
    </div>
    // <div
    //   style={{
    //     display: "flex",
    //     justifyContent: "center",
    //     height: "100%",
    //     alignItems: "center",
    //   }}
    // >
    //   <div
    //     style={{
    //       width: "94%",
    //       borderTop: "1px solid #E5E5E5",
    //       paddingTop: "3rem",
    //       display: "flex",
    //       justifyContent: "center",
    //       height: "100%",
    //     }}
    //   >
    //     <div
    //       className="portfolio-stepper-first-form-container"
    //       style={{
    //         width: "80%",
    //         height: "100%",
    //         borderRadius: "10px",
    //         padding: "2rem",
    //         display: "flex",
    //         flexDirection: "column",
    //         justifyContent: "center",
    //         alignItems: "center",
    //       }}
    //     >

    //     </div>
    //   </div>
    // </div>
  );
}
