import React from "react";
import "../../static/css/existing/dashboard_styles.css";
import Cross from "../../assets/img/module_access_type_cross.svg";
import Tick from "../../assets/img/module_access_type_tick.svg";
import { useSelector } from "react-redux";
export default function AccessContent() {
  const userData = useSelector((state) => state.userdata);
  return (
    <>
      <div className="flex  flex-row justify-between flex-wrap w-full">
        <div className="mt-[1rem] w-[47%] border border-globalBorderColor rounded-[6px] pt-[0.5rem] pr-[1rem] pb-[1.5rem] pl-[1rem] ">
          <div className="flex w-full justify-between">
            <h3 className="font-inter text-[20px] font-medium leading-[40px] tracking-[0.01em] text-neutralBlack text-left">
              Relationship Manager Module
            </h3>
            <img src={userData.crm ? Tick : Cross} className="w-5" alt="err" />
          </div>
          <p className="font-inter text-[14px] font-normal leading-5 tracking-[0.01em] text-neutralBlack text-left">
            This module gives you access to all clients’ data and portfolio. You
            can run portfolio reviews, create portfolio proposals, view all
            product insights, and read all market updates.
          </p>
        </div>
        <div className="mt-[1rem] w-[47%] border border-globalBorderColor rounded-[6px] pt-[0.5rem] pr-[1rem] pb-[1.5rem] pl-[1rem]">
          <div className="flex w-full justify-between">
            <h3 className="font-inter text-[20px] font-medium leading-[40px] tracking-[0.01em] text-neutralBlack text-left">
              Research Analyst Module
            </h3>
            <img
              src={userData.research_input ? Tick : Cross}
              className="w-5"
              alt="err"
            />
          </div>
          <p className="font-inter text-[14px] font-normal leading-5 tracking-[0.01em] text-neutralBlack text-left">
            This module helps you in changing view on broad asset-allocation.
            You can put your custom asset views and the system will analyze all
            clients’ portfolios basis your inputs.
          </p>
        </div>
        <div className=" mt-8 w-[47%] border border-globalBorderColor rounded-[6px] pt-[0.5rem] pr-[1rem] pb-[1.5rem] pl-[1rem]">
          <div className="flex w-full justify-between">
            <h3 className="font-inter text-[20px] font-medium leading-[40px] tracking-[0.01em] text-neutralBlack text-left">
              Product Manager Module
            </h3>
            <img
              src={userData.product_input ? Tick : Cross}
              className="w-5"
              alt="err"
            />
          </div>
          <p className="font-inter text-[14px] font-normal leading-5 tracking-[0.01em] text-neutralBlack text-left">
            You can review, search, and change the view on the products to Buy,
            Sell, or Hold. Basis your recommendation our system will construct
            portfolios for clients as per their IPS.
          </p>
        </div>
        <div className="mt-8 w-[47%] border border-globalBorderColor rounded-[6px] pt-[0.5rem] pr-[1rem] pb-[1.5rem] pl-[1rem]">
          <div className="flex w-full justify-between">
            <h3 className="font-inter text-[20px] font-medium leading-[40px] tracking-[0.01em] text-neutralBlack text-left">
              Operations Analyst Module
            </h3>
            <img
              src={userData.client_input ? Tick : Cross}
              className="w-5"
              alt="err"
            />
          </div>
          <p className="font-inter text-[14px] font-normal leading-5 tracking-[0.01em] text-neutralBlack text-left">
            Through this module you can upload, change, and track clients’
            portfolio and other details. Our system does not read sensitive
            client information like email, PAN card, bank account number, Aadhar
            card, etc.
          </p>
        </div>
      </div>
    </>
  );
}
