import React from "react";
import Add from "../../assets/img/circle_over_plus_blue.svg";
import { productArray } from "../../arrays/productArray";
import PPFrow from "./PPFrow";
import { useState } from "react";

export default function PPFscreen() {
  const [ppfarray, setppfarray] = useState(productArray.ppf.items);
  const removeppfarray = (i) => {
    let newppfarray = [...ppfarray];
    newppfarray.splice(i, 1);
    setppfarray(newppfarray);
  };
  return (
    <div className="w-full">
      <div className="font-medium font-inter text-[18px] text-neutralBlack">
        PPF - ₹ 62.5 L
      </div>
      <div className="flex justify-between w-[96%] mb-[1.5rem] mt-[4px]">
        <div className="flex justify-center">
          <img src={Add} alt="err" />
          <p
            className="text-primary-200 text-[14px] font-medium mb-0 ml-[0.3rem]"
            onClick={(e) => {
              let newppfarray = [...ppfarray];
              newppfarray.unshift({ name: "New PPF", price: "0" });
              setppfarray(newppfarray);
            }}
          >
            Add PPF
          </p>
        </div>
        <p
          className="text-primary-200 text-[14px] font-medium mb-0"
          onClick={() => setppfarray([])}
        >
          Clear All
        </p>
      </div>
      <div className="w-[100%]">
        {ppfarray.map((fund, index) => (
          <PPFrow
            key={index}
            fund={fund}
            id={index}
            removeppfarray={removeppfarray}
            ppfarray
            setppfarray
          />
        ))}
      </div>
    </div>
  );
}
