import { GET_REVIEW_PORTFOLIO_IPS_DATA } from "../actions/type";
const initialState = {
  id: 1,
  flag: true,
  equity: {
    max: 0.7,
    min: 0.3,
    target: 0.5,
  },
  debt: {
    max: 0.65,
    min: 0.25,
    target: 0.45,
  },
  alternate: {
    max: 0.25,
    min: 0,
    target: 0.05,
  },
  multi_assets: {
    max: 0.2,
    min: 0,
    target: 0,
  },
  large_cap: {
    max: 0.7,
    min: 0.3,
    target: 0.5,
  },
  mid_cap: {
    max: 0.6,
    min: 0.2,
    target: 0.4,
  },
  small_cap: {
    max: 0.3,
    min: 0,
    target: 0.1,
  },
  duration: {
    max: 4,
    min: 2,
    target: 3,
  },
  aaa_equiv: {
    max: 0.95,
    min: 0.55,
    target: 0.75,
  },
  alternates: {
    max: 0.95,
    min: 0.55,
    target: 0.75,
  },
  user: "079d0a47-9d8a-4e83-b090-d7ff1758e05a",
};
export default function GetReviewPortfolioIpsData(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_REVIEW_PORTFOLIO_IPS_DATA:
      return action.payload;
    default:
      return state;
  }
}
