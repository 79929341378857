import { GET_SETTINGS_ABOUT } from "../actions/type";
const initialState = {};
export default function GetSettingsAbout(state = initialState, action) {
  switch (action.type) {
    case GET_SETTINGS_ABOUT:
      return action.payload;

    default:
      return state;
  }
}
