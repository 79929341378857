import { GET_FUNDHOUSE_RESEARCH } from "../actions/type";
const initialState = [];
export default function GetFundHouseResearchReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_FUNDHOUSE_RESEARCH:
      return action.payload;
    default:
      return state;
  }
}
