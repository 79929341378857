import { GET_ACQUIRE_CLIENTS_PPT } from "../actions/type";
const initialState = [];
export default function getAcquireClientsPptAPI(state = initialState, action) {
  switch (action.type) {
    case GET_ACQUIRE_CLIENTS_PPT:
      return action.payload;
    default:
      return state;
  }
}
