import { GET_MODEL_PORTFOLIO_ANALYTICS } from "../actions/type";
const initialState = {
  asset_allocation: {
    Equity: 0,
    Debt: 0,
    Alternate: 0,
  },
  product_allocation: {
    "Mutual Fund": 0,
  },
  port_return: 0,
  bm_blended_return: 0,
  total_products: 1,
  liquidity: 0,
  market_cap: {
    "Large Cap": [0, 0],
    "Mid Cap": [0, 0],
    "Small Cap": [0, 0],
  },
  top5_sector: {
    Materials: [0, 0],
    "Consumer Staples": [0, 0],
    Industrials: [0, 0],
    Financials: [0, 0],
    "Consumer Discretionary": [0, 0],
  },
  top5_stocks: {
    "ITC Ltd.": 0,
    "Ambuja Cements Ltd.": 0,
    "Adani Ports and Special Economic Zone Ltd.": 0,
    "State Bank Of India": 0,
    "Larsen & Toubro Ltd.": 0,
  },
  debt_quants: {
    duration: 0,
    gross_ytm: 0,
    maturity: 0,
  },
  rating_allocation: {},
  top5_debt_instruments: {},
};
export default function getModelPortfolioAnalytics(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_MODEL_PORTFOLIO_ANALYTICS:
      return action.payload;
    default:
      return state;
  }
}
