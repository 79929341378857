import React from "react";
import AfterloginApp from "../../AfterloginApp";
import ClientSidebar from "../ClientSidebar";
import "../../../static/css/existing/dashboard_styles.css";
import InvestmentContent from "./InvestmentContent";
export default function InvestmentProfile(props) {
  const { familyMembers } = (props.location && props.location.state) || {};
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Client Input"}
        description={
          "Browse for portfolio insights, actionable, cash flow planning, and much more"
        }
      />
      <div
        className="loggedinscreen-container pt-0"
        id="client-details-investment-details-screen"
      >
        <div className="loggedinscreen flex flex-row justify-between pt-0">
          <ClientSidebar />
          <div className=" w-[79%] ml-0 pt-0 p-[1rem] bg-[#fff] rounded-[0.5rem] min-h-[100vh_-_116px]">
            <InvestmentContent familyMembers={familyMembers} />
          </div>
        </div>
      </div>
    </div>
  );
}
