import { GET_DASHBOARD_UNLISTED_PORTFOLIO } from "../actions/type";
const initialState = [];
export default function getDashboardUnlistedPortfolio(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_DASHBOARD_UNLISTED_PORTFOLIO:
      return action.payload;
    default:
      return state;
  }
}
