import React, { useState } from "react";
import * as RiIcons from "react-icons/ri";
import Fade from "@mui/material/Fade";
export default function DeployOption({ option, index, queryResults }) {
  //   const handleClick = () => {
  //     console.log(optionsState);
  //     let temp = optionsState;
  //     temp[index] = !temp[index];
  //     console.log(temp);
  //     setOptionsState(temp);
  //   };
  //   const [open, setOpen] = useState(optionsState[index]);
  //   useEffect(() => {
  //     setOpen(optionsState[index]);
  //   }, [optionsState]);

  //   console.log("open is", open);
  const [open, setOpen] = useState(index ? false : true);
  return (
    <>
      <div
        className="flex items-center cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <h2 className="font-inter font-medium text-base mt-[16px]">{`Option ${
          index + 1
        }`}</h2>
        <RiIcons.RiArrowDownSFill className="ml-[8px] mt-[8px]" size={18} />
      </div>
      {open && (
        <Fade in={open} unmountOnExit>
          <div className="rounded-[6px] min-h-full mt-[0.5rem]">
            <table className="w-full font-inter investedge-table">
              <col width="25%" />
              <col width="12.5%" />
              <col width="12.5%" />
              <col width="12.5%" />
              <col width="12.5%" />
              <col width="25%" />

              <tbody>
                <tr className="rounded-[10px] h-10 font-inter font-medium border-b-[1px] border-b-[#6A6A76]">
                  <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-left pl-[1rem]">
                    Product Name
                  </th>
                  <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-center">
                    Product Type
                  </th>
                  <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-center">
                    Asset Type
                  </th>
                  <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-center">
                    Amount (₹)
                  </th>
                  <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-center">
                    Allocation (%)
                  </th>
                  <th className="font-inter text-[15px] not-italic font-medium leading-[16px] tracking-[0em] text-center">
                    IPS Deviation post Redemption
                  </th>
                </tr>
                {queryResults[option].map((client, index) => {
                  return (
                    <tr className="border-b border-[#e0dddd]" key={index}>
                      <td className=" font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] pl-[1rem] text-left">
                        {client.product_name}
                      </td>
                      <td className=" font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
                        {client.product_type}
                      </td>
                      <td className=" font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
                        {client.asset_type}
                      </td>
                      <td className=" font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
                        {`${client.aum.amount} ${client.aum.denomination}`}
                      </td>
                      <td className="font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center">
                        {(client.allocation * 100).toFixed(2)}
                      </td>
                      <td
                        className={
                          client.ips_deviation_post_redemption === "Low"
                            ? "font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center text-[#EC3F3F]"
                            : client.ips_deviation_post_redemption === "High"
                            ? "font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center text-[#269E4F]"
                            : "font-inter text-[14px] not-italic font-normal leading-[16px] tracking-[0em] p-[1rem] text-center text-neutralBlack"
                        }
                        // className="dashboard-body-second-card-table-data"
                        // style={
                        //   client.ips_deviation_post_redemption === "Low"
                        //     ? { color: "#EC3F3F" }
                        //     : client.ips_deviation_post_redemption === "High"
                        //     ? { color: "#269E4F" }
                        //     : { color: "#323232" }
                        // }
                      >
                        {client.ips_deviation_post_redemption}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Fade>
      )}
    </>
  );
}
