import React, { useRef } from "react";
import Hourglass from "../../../assets/img/wait.gif";
import Women from "../../../assets/img/modal_chr.svg";
import { useSelector, useDispatch } from "react-redux";
import { PostEventLoggingData } from "../../../actions/actions";
import InvestedgeButton from "../../../components/buttons/InvestedgeButton";
import { Link } from "react-router-dom";
import AfterloginApp from "../../AfterloginApp";
export default function MutualFundAnalyticsPPT() {
  const pptRef = useRef(null);
  const excelRef = useRef(null);
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(true);
  const fileUrls = useSelector((state) => state.portfolioReviewGetPPT);
  // setTimeout(() => {
  //   setLoading(false);
  // }, 6000);
  // const loadingPpt = false;
  const downloadexcel = async () => await excelRef.current.click();
  const downloadppt = async () => await pptRef.current.click();
  return (
    <div className="parentLoggedinScreen">
      <AfterloginApp
        title={"Portfolio Insights"}
        description={"Create and Customize Portfolio Recommendations"}
      />
      <div className="loggedinscreen-container">
        <div className="loggedinscreen">
          <div className=" w-full flex-col justify-center items-center flex p-[32px] rounded-[8px] bg-white min-h-[calc(100vh_-_116px)]">
            <img
              alt="err"
              src={fileUrls.loading ? Hourglass : Women}
              className=" min-h-[250px] object-contain"
            />
            <h3 className="portfolio-stepper-sixth-form-status">
              {fileUrls.loading
                ? "Sometimes, great things take time."
                : "Happy Investment. Thanks for reviewing the portfolio"}
            </h3>
            {fileUrls.loading && (
              <h3 className=" font-inter text-[14px] font-medium leading-[16px] tracking-[0em] text-center">
                Your request is under process. Please wait for sometime.
              </h3>
            )}
            {!fileUrls.loading && (
              <div className=" w-[45%] flex justify-between mt-4">
                <div className="flex">
                  <Link to="/portfolioproposal/mfanalytics/template">
                    <InvestedgeButton
                      onClick={() => {
                        dispatch(
                          PostEventLoggingData({
                            module_name: "Portfolio Review",
                            event_type: "Repeat",
                            url: window.location.href,
                            note: `Sixth screen`,
                          })
                        );
                      }}
                    >
                      Review Another
                    </InvestedgeButton>
                  </Link>
                </div>
                <div className="flex">
                  {fileUrls.excel && (
                    <InvestedgeButton
                      onClick={() => {
                        dispatch(
                          PostEventLoggingData({
                            module_name: "Portfolio Review",
                            event_type: "Download excel",
                            url: window.location.href,
                            note: `Sixth screen`,
                          })
                        );
                        downloadexcel();
                      }}
                    >
                      Download Excel
                    </InvestedgeButton>
                  )}
                  <InvestedgeButton
                    className="ml-4"
                    onClick={() => {
                      dispatch(
                        PostEventLoggingData({
                          module_name: "Portfolio Review",
                          event_type: "Download ppt",
                          url: window.location.href,
                          note: `Sixth screen`,
                        })
                      );
                      downloadppt();
                    }}
                  >
                    Download PPT
                  </InvestedgeButton>
                </div>
              </div>
            )}
            <a href={fileUrls.ppt} ref={pptRef} className="hidden">
              File URL
            </a>
            <a href={fileUrls.excel} ref={excelRef} className="hidden">
              File URL
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
