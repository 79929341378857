import { GET_MODEL_PORTFOLIO_PRODUCTS } from "../actions/type";
const initialState = [];
export default function getModelPortfolioProducts(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_MODEL_PORTFOLIO_PRODUCTS:
      return action.payload;
    default:
      return state;
  }
}
