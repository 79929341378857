import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers/index";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "persist-key",
  storage,
  blacklist: [
    "productInputData",
    "goalsList",
    "goalData",
    "researchAssets",
    "popularProducts",
    "settingsGeneral",
    "allocationPreference",
    "analyticsAPI",
    "proposalHistory",
    "proposalAdvanceAnalytics",
    "clients",
    "clientinputpostnew",
    "postClientInputUploadFile",
    "getComparisonData",
    "getProductOnepagerData",
    "dashboardPortfolio",
    "dashboardInsights",
    "dashboardAnalytics",
    "dashboardPerformance",
    "dashboardPortfolioSummary",
    "dashboardHoldingReport",
    "dashboardGetDetails",
    "dashboardPostDetails",
    "dashboardGetInvestment",
    "dashboardPostInvestment",
    "clientDashboardData",
    "clientInputData",
    "dashboardRedeemCapital",
    "dashboardDeployCapital",
    "dashboardMFPortfolio",
    "dashboardStocksPortfolio",
    "dashboardAifPortfolio",
    "dashboardPmsPortfolio",
    "dashboardUnlistedPortfolio",
    "dashboardBondsPortfolio",
    "dashboardCashPortfolio",
    "dashboardDepositsPortfolio",
    "dashboardGoldPortfolio",
    "dashboardRealEstatePortfolio",
    "dashboardOffshorePortfolio",
    "dashboardPortfolioPriceList",
    "dashboardBenchmark",
    "portfolioReviewGetSumary",
    "portfolioReviewPatchSummary",
    "portfolioReviewGetAnalytics",
    "portfolioReviewGetIpsData",
    "portfolioReviewGetIAA",
    "portfolioReviewGetIpsTracker",
    "portfolioReviewGetActionable",
    "portfolioReviewGetFinalAnalytics",
    "reviewHistory",
    "reviewAdvanceAnalytics",
    "sipallocationPreference",
    "sipModelPortfolio",
    "sipanalyticsAPI",
    "sippptAPI",
    "sipHistory",
    "sipAdvanceAnalytics",
    "getModelPortfolio",
    "getModelPortfolioAnalytics",
    "getModelPortfolioProducts",
    "rms",
    "clients",
  ],
};
const initialState = {};

const middleware = [thunk];
const persistedReducer = persistReducer(persistConfig, rootReducer);
const devTools =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(...middleware)
    : composeWithDevTools(applyMiddleware(...middleware));

const store = createStore(persistedReducer, initialState, devTools);
const persistor = persistStore(store);
export default store;
export { persistor };
